import React from 'react';

export const Moon = ({ classNames }) => {
  return (
    <svg width="477" height="478" viewBox="0 0 477 478" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames}>
      <circle cx="238.5" cy="239" r="238.5" fill="#FEFCE3"/>
      <mask id="mask0_18_75" maskUnits="userSpaceOnUse" x="0" y="0" width="477" height="478">
        <circle cx="238.5" cy="239" r="238.5" fill="white"/>
      </mask>
      <g mask="url(#mask0_18_75)">
        <path fillRule="evenodd" clipRule="evenodd" d="M408.047 452.536C408.047 452.536 456.536 401.346 480.064 345.771C490.078 322.118 499.14 263.807 493.004 210.349C489.529 180.074 421.835 66.5 421.835 66.5C421.835 66.5 423.083 166.128 407.977 200.805C373.588 279.748 320.426 315.602 306.863 324.858C276.067 345.875 218.871 381.758 145.697 381.758C107.716 381.758 22 356.64 22 356.64C22 356.64 91.6481 472.568 118.854 485.689C181.495 515.899 240.014 515.649 267.951 511.537C328.057 502.691 408.047 452.536 408.047 452.536Z" fill="#F4F2CF"/>
        <ellipse cx="269.973" cy="420.685" rx="47.7317" ry="37.1179" fill="#E2E0C1"/>
        <ellipse cx="75.6616" cy="284.733" rx="36.1357" ry="64.7879" fill="#F4F2CF"/>
        <ellipse cx="156.82" cy="331.211" rx="24.1561" ry="33.6828" fill="#F4F2CF"/>
        <ellipse cx="169.656" cy="423.12" rx="32.937" ry="25.1635" fill="#E2E0C1"/>
      </g>
    </svg>
  );
};
