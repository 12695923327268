async function promiseHandler(promise) {
  try {
    const data = await promise;
    return [null, data];
  } catch (error) {
    return [error];
  }
}

export async function compositePromiseHandler(promises) {
  return promiseHandler(Promise.all(promises));
}

export default promiseHandler;
