import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const lionTimeline = new gsap.timeline({ paused: true });

export const PayoffLion = () => {

  useEffect(() => {
    
    lionTimeline
      .add(gsap.to('#diagram-lion', { duration: 1, transformOrigin: '50% 50%', rotate: 32, y: -20, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#sun-2', { duration: 1, y: -15, x: 120, scale: 0.3, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#star1-2', { duration: 1, y: 25, x: -10, scale: 0.3, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#star2-2', { duration: 1, y: 180, x: 180, scale: 0.3, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#star3-2', { duration: 1, y: 40, x: -15, scale: 0.3, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#connect1-2', {
        duration: 1,
        attr: {
          d: 'M464.44 150.35L587.51 275',
        },
        strokeWidth: 1,
        ease: 'sine.inOut' }), 0)
      .add(gsap.to('#connect2-2', {
        duration: 1,
        attr: {
          d: 'M581.67 294.52L406.43 476.63',
        },
        strokeWidth: 1,
        ease: 'sine.inOut' }), 0)
      .add(gsap.to('#connect3-2', {
        duration: 1,
        attr: {
          d: 'M625.38 483.64L409.65 480.07',
        },
        strokeWidth: 1,
        ease: 'sine.inOut' }), 0)
      .add(gsap.to('#lion', { duration: 1, opacity: 1, ease: 'sine.inOut' }), 1);

    return () => {
      if (lionTimeline) lionTimeline.kill();
    };
  });

  return (
    <svg id="payoff-lion" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680" width="1024" height="680">
      <g id="lion" opacity="0">
        <g>
          <path d="M294.5,491.5c-9,15-15,48,8,71-8-52,28-76,63-52-17,20-31,65-9,94-1-46,27-68,67-46-1,24,7,59,48,82-13-32-11-90,59-76-3,27,6,82,77,76-30-27-30-72,23-69,5,28,32,43,73,44-40-34-46-85,2-90,26,26,66,26,85-1-49,2-68-42-90-177,27,25,63,18,82-11-20,2-36-4-49-31s-30-52-47-66c51,12,97-4,80-42-9,13-17,19-35,17s-73-24-104-58-76-71-141-53c2-9,1-25-16-37-2,15-9,32-53,38s-97,29-58,59c-6-3-20-10-25-11-9,8-6,36,4,51-28,28-50,42-67,33,7,18,37,22,52,13-7,5-8,15-5,37-29,44-41,59-30,73-3,10,1,22,6,31s10,17,10,17c-6,15-3,29,0,42-21,18-49,41-80,26C238.5,493.5,254.5,499.5,294.5,491.5Z"/>
          <path d="M648.5,271.5c22,15,53,52,81,62-22-15-46-42-59-60s-32-40-41-43c-4-8-13-22-13-22l-19-7c22,28,55,102,62,174s6.21,117.64,36.6,151.32c.11.12.21.22.31.34a57.74,57.74,0,0,1,9.09-1.66c-9-12-20-36-23-80S658.5,305.5,648.5,271.5Z" fill="#9b3ae1"/>
          <path d="M623.5,462.5c21-113,21-201-53-270l-11-2c38,36,60,78,64,141s-5,90-15,125c-9.28,32.49-1.33,89.1,7.86,115.46a73.1,73.1,0,0,1,14.14-.46C619.5,541.5,613.07,518.61,623.5,462.5Z" fill="#9b3ae1"/>
          <path d="M311.5,447.5c4-3,11-13,13-17s13-3,19,2c-2-4-15-18-11-27C324.5,409.5,308.5,439.5,311.5,447.5Z" fill="#9b3ae1"/>
          <path d="M314.5,418.5c-3-3-5-17,3-19,0,0-7-2-11-1C306.5,398.5,301.5,409.5,314.5,418.5Z" fill="#9b3ae1"/>
          <path d="M348.5,426.5c-1-4,0-20,11-24-4-3-15-4-18-3S338.5,415.5,348.5,426.5Z" fill="#9b3ae1"/>
          <path d="M397.5,173.5c-8,11-38.5,59.5-44,79,10-17,69-69,81-75,20-3,75-3,92,0s20,8,20,11-5,11-9,18c37,11,62,53,68,95s-12,117-33,158-37,71-42,105A109.75,109.75,0,0,0,517,562.61c1.95-18.49,10.39-42.12,33.53-84.11,27-49,32-75,42-154s-41-106-59-108c-1,3-6,14-6,14,10,7,42,36,42,102s-43,115-94,145-49,50-52,81a83.41,83.41,0,0,0-9.67-4.56c-7.57-63.24,70.28-100.86,95.67-118.44,26-18,37-49,44-94s-12-90-34-101c0,0-10,14-15,18,15-2,18,19,16,56s-14.75,78.41-68,110c-59,35-70,57-87,86a60,60,0,0,0-9.93-5.54c14.09-44.58,55-66.1,94.93-90.46,41-25,49-55,55-89s-12-75-27-86l-16,7c33,10,27,49,14,77s-53,61-84,78c-7-3-46-10-52-10-28,14-29-11-27-19,25-13,27-23,25-26s-10-1-19,3c-3,0-21,2-28,0,3-15,28-57,40-70,1-6,7-19,11-24-5-1-12,2-15,3,3-10,30-48,34-52s5-7,5-14-9-11-20-16c0,6,4,22,4,22l-6,2c-6-10-8-29-8-37,5,1,24,10,28,12l7-6c8-39,54-52,74-52-37,6-57,36-60,47l8-1v-5c8-4,16-15,82-15s150,33,196,70c-30-18-88-40-145-53S427.5,159.5,397.5,173.5Zm50,90c-9-8-8-36,23-49,0,0-9,14-9,18,10-6,23-10,31-10-4,5,2,17,9,26,12-11,25-29,24-43,0-2,10-16,11-19-21-8-47-2-66,8s-32,12-37,13C420.5,223.5,416.5,251.5,447.5,263.5Zm-61-13-8,9c-1-5,1-20,1-20l-18,12-3,9c18,16,12,22,2,26l6,3c-2,3-19.5,26.5-31,48h6c7-13,26-30,32-40,1,25,13,36,35,43-21-17-17-33-1-48-4,0-19-3-19-3,5,0,15-2,24-4s18-3,33,0c-22-17-53-18-58-22C387.5,259.5,387.5,252.5,386.5,250.5Zm-40,138c14,1,38,5,43,7l7-7C377.5,385.5,365.5,385.5,346.5,388.5Zm57-8-24-11c-10,0-35,4-42,7C352.5,376.5,392.5,379.5,403.5,380.5Z" fill="#9b3ae1"/>
        </g>
      </g>
      <g id="diagram-lion">
        <path id="connect3-2" d="M662 518L272 378" stroke="#FFFCE3" strokeWidth="4.5"/>
        <path id="connect1-2" d="M532 182L523.33 295" stroke="#FFFCE3" strokeWidth="4.5"/>
        <path id="connect2-2" d="M490.5 356.5L276 357" stroke="#FFFCE3" strokeWidth="4.5"/>
        <path id="sun-2" d="M524.1,422.28l-15.9,13.15-9.65-18.23-19.72,6.07-1.94-20.55-20.55-1.94,6.07-19.73-18.24-9.65,13.16-15.9-13.16-15.9L462.41,330l-6.07-19.73,20.55-1.94,1.94-20.55,19.72,6.07,9.65-18.23,15.9,13.15L540,275.57l9.66,18.23,19.72-6.07,1.94,20.55,20.55,1.94L585.8,330,604,339.6l-13.16,15.9L604,371.4l-18.24,9.65,6.07,19.73-20.55,1.94-1.94,20.55-19.72-6.07L540,435.43Z" fill="#fff17c"/>
        <path id="star3-2" d="M704,589a10.86,10.86,0,0,1-9.65-5.72l-23.56-43.08-43.08-23.56a11,11,0,0,1,0-19.3l43.08-23.56,23.56-43.08a11,11,0,0,1,19.3,0l23.56,43.08,43.08,23.56a11,11,0,0,1,0,19.3l-43.08,23.56-23.56,43.08A10.86,10.86,0,0,1,704,589Z" fill="#fffce3"/>
        <path id="star2-2" d="M278.67,440.34a10.85,10.85,0,0,1-9.65-5.72l-23.56-43.08L202.38,368a11,11,0,0,1,0-19.3l43.08-23.56L269,282.05a11,11,0,0,1,19.3,0l23.56,43.07L355,348.68a11,11,0,0,1,0,19.3l-43.07,23.56-23.56,43.08A10.86,10.86,0,0,1,278.67,440.34Z" fill="#fffce3"/>
        <path id="star1-2" d="M527.33,258.34a10.86,10.86,0,0,1-9.65-5.72l-23.56-43.08L451.05,186a11,11,0,0,1,0-19.3l43.07-23.56,23.56-43.07a11,11,0,0,1,19.3,0l23.56,43.07,43.08,23.56a11,11,0,0,1,0,19.3l-43.08,23.56L537,252.62A10.85,10.85,0,0,1,527.33,258.34Z" fill="#fffce3"/>
      </g>
    </svg>
  );
};
