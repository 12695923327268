import React, { useEffect } from 'react';

import { element, string } from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import 'app/components/error-screen/error-screen.scss';

// has also useErrorHandler for caching non-lifecycle errors
export const ErrorScreen = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    resetErrorBoundary();
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
  }, [location.pathname]);

  return (
    <div className="error-screen">
      <div className="error-content">
        <div>
          <p className="error-title">Whoops!</p>
          <p className="error-message">
          There was an <strong>error</strong>.<br/>
          An <strong>error</strong> is when we make a mistake and something goes wrong. 
          </p>
          <div className="error-dude">
            <svg width="128" height="127" viewBox="0 0 128 127" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M90.41 45.29L105.41 66.79L124.41 48.79" stroke="black" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M66.91 78.79V123.79H80.91" stroke="black" strokeWidth="6" strokeLinecap="round"/>
              <path d="M37.91 78.79V123.79H23.91" stroke="black" strokeWidth="6" strokeLinecap="round"/>
              <path d="M14.91 47.79L3.41 68.79V91.79" stroke="black" strokeWidth="6" strokeLinecap="round"/>
              <path d="M52.41 78.79C59.8268 78.79 67.0771 76.5907 73.2439 72.4701C79.4107 68.3496 84.2172 62.4928 87.0555 55.6406C89.8938 48.7884 90.6364 41.2484 89.1895 33.9741C87.7425 26.6998 84.171 20.018 78.9265 14.7735C73.682 9.52902 67.0002 5.95749 59.7259 4.51055C52.4516 3.0636 44.9116 3.80623 38.0594 6.64452C31.2071 9.4828 25.3504 14.2893 21.2299 20.4561C17.1093 26.6229 14.91 33.8732 14.91 41.29C14.91 51.2356 18.8609 60.7739 25.8935 67.8065C32.9261 74.8391 42.4644 78.79 52.41 78.79Z" fill="#FFE748" stroke="black" strokeWidth="6"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M51.61 74.49C59.9643 74.49 67.9765 71.1713 73.8839 65.2639C79.7912 59.3565 83.11 51.3443 83.11 42.99C83.11 25.59 68.51 44.19 51.11 44.19C33.71 44.19 20 25.59 20 43C20.0238 51.3651 23.3657 59.3791 29.292 65.2829C35.2183 71.1867 43.2448 74.498 51.61 74.49Z" fill="#E4CC40"/>
              <path d="M51.91 73.79C58.239 73.79 64.4259 71.9132 69.6882 68.397C74.9506 64.8808 79.0521 59.8831 81.4742 54.0358C83.8962 48.1886 84.5299 41.7545 83.2951 35.5471C82.0604 29.3397 79.0127 23.6379 74.5374 19.1626C70.0621 14.6873 64.3603 11.6396 58.1529 10.4049C51.9455 9.17014 45.5114 9.80384 39.6641 12.2258C33.8169 14.6479 28.8192 18.7494 25.303 24.0117C21.7868 29.2741 19.91 35.461 19.91 41.79C19.91 50.2769 23.2814 58.4162 29.2826 64.4174C35.2838 70.4186 43.4231 73.79 51.91 73.79Z" stroke="white" strokeWidth="6"/>
              <path d="M71 29.32C72.32 27.21 69.78 23.25 65.33 20.47C60.88 17.69 56.21 17.14 54.89 19.25C53.57 21.36 56.11 25.32 60.56 28.1C65.01 30.88 69.66 31.43 71 29.32Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="error-footer" >
        <button className="error-button" onClick={goBack}>Click Here to go back</button>
      </div>
      <div>{ error.message }</div>
    </div>
  );
};

ErrorScreen.defaultProps = {
  children: null,
  className: ''
};

ErrorScreen.propTypes = {
  children: element,
  classNames: string,
};


