import React from 'react';

import { AmpNavigation } from '@amplify/amp-navigation';
import { object } from 'prop-types';

import 'app/components/navbar/navbar.scss';

export const Navbar = (props) => {
  const { config } = props;
  return <AmpNavigation config={config} />;
};

Navbar.propTypes = {
  config: object,
};
