import React, { useState, useCallback, createContext } from 'react';

export const DescendantContext = createContext({ keys: [], register: () => {}, deregister: () => {}, getIndex: () => {} });

export const DescendantProvider = ({ children }) => {
  const [keys, setKeys] = useState([]);

  return (
    <DescendantContext.Provider
      value={{
        keys,
        register: useCallback((key) => {
          setKeys((keys) => keys.filter(getKeyFilter(key)).concat(key));
        }, []),
        deregister: useCallback((key) => {
          setKeys((keys) => keys.filter(getKeyFilter(key)));
        }, []),
        getIndex: useCallback(
          (key) => {
            return keys.indexOf(key);
          },
          [keys]
        ),
      }}
    >
      {children}
    </DescendantContext.Provider>
  );
};

function getKeyFilter(key) {
  return (k) => k !== key;
}

