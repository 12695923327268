import React from 'react';

import { HelperDude } from 'app/components/helper/helper-dude/helper-dude.component';
import { HelperMessage } from 'app/components/helper/helper-message/helper-message.component';

import 'app/components/helper/helper-box/helper-box.scss';

export const HelperBox = ({ messages, hideMessage, showRandomMessage }) => {
  return (
    <div className='helper-container'>
      <HelperMessage key='helper-messages-static' messagesToShow={messages} hideMessage={hideMessage} />
      <HelperDude key='helper-dude-static' showRandomMessage={showRandomMessage} />
    </div>
  );
};
