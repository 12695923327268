import { useCallback } from 'react';

export function useComposedRefs(...refs) {
  return useCallback((node) => {
    for (const ref of refs) {
      // Passes or assigns an arbitrary value to a ref function or object.
      if (ref == null) return;
      if (ref && {}.toString.call(ref) == '[object Function]') {
        ref(node);
      } else {
        try {
          ref.current = node;
        } catch (error) {
          throw new Error(`Cannot assign value "${node}" to ref "${ref}"`);
        }
      }
    }
  }, refs);
}