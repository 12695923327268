import React from 'react';

import 'app/components/next-button/next-button.scss';
import clsx from 'clsx';
import { bool, string } from 'prop-types';

export const NextButton = (props) => {
  const { isBack = false } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49px" height="69px" viewBox="0 0 49 69" version="1.1" className={`next-btn ${clsx(isBack ? 'back' : '')}`}>
      <g id="Hub-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Right-Arrow---Hub" fill="#14405C" stroke="#FFFFFF" strokeWidth="5">
          <path d="M23.9127023,17.4589174 C24.79573,17.3086148 25.7360963,17.495176 26.5245007,18.0542264 C26.8461665,18.2823167 27.1269748,18.563125 27.3550651,18.8847908 L27.3550651,18.8847908 L49.7815344,50.5118629 C50.3405849,51.3002674 50.527146,52.2406336 50.3768434,53.1236614 C50.2265408,54.0066891 49.7393745,54.8323783 48.95097,55.3914288 C48.3592703,55.8109977 47.651829,56.0363636 46.9264693,56.0363636 L46.9264693,56.0363636 L2.07353069,56.0363636 C1.10703238,56.0363636 0.232032378,55.6446128 -0.401343044,55.0112374 C-1.03471847,54.3778619 -1.42646931,53.5028619 -1.42646931,52.5363636 C-1.42646931,51.8110039 -1.20110335,51.1035627 -0.781534429,50.5118629 L-0.781534429,50.5118629 L21.6449349,18.8847908 C22.2039853,18.0963863 23.0296745,17.60922 23.9127023,17.4589174 Z" id="Triangle" transform="translate(24.500000, 34.536364) rotate(90.000000) translate(-24.500000, -34.536364) "/>
        </g>
      </g>
    </svg>
  );
};

NextButton.propTypes = {
  buttonId: string,
  isBack: bool,
};
