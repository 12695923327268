export const STAGES = {
  WELCOME: 'welcome',
  LOADING_WORDS: 'loadingWords',
  DAILY_DELIVERY: 'dailyDelivery',
  NO_WORDS: 'noWords',
  READY_TO_GO: 'readyToGo',
  DAILY_RANDOM: 'dailyRandom'
};

export const constantMessages = {
  'onboardingStats': [{
    'msg': 'This is the Stats page, where you can keep an eye on everything you\'re doing here in the world of Vocabulary.',
    'anim': ['Talk_Welcome-01', 'Talk_OneLine-02']
  }, {
    'msg': 'THAT is a chart where you can see how far you\'ve progressed',
    'anim': 'Talk_OneLine-Whoa'
  }, {
    'msg': 'It shows how many activities you did each day, and how many were done correctly. Cool!',
    'anim': 'Talk_TwoLine-01'
  }, {
    'msg': 'Why don\'t you take some time to explore? Click around to see what\'s what!',
    'anim': ['Talk_TwoLine-02', 'Rest_02-ShiftWeight']
  }],
  'onboardingHome': [{
    'msg': 'Hey there! I\'m over here! Come join me',
    'anim': 'Talk_Attention'
  }, {
    'msg': 'They tell me we\'re going to be partners. Allow me to introduce myself.',
    'anim': 'Talk_OneLine-02'
  }, {
    'msg': 'I\'m the Helper. I\'m here to help you do vocabulary activities. All you have to do is try your best. Sound good?',
    'anim': ['Talk_Me', 'Talk_OneLine-01']
  }, {
    'msg': 'You are looking at the Home Screen. This is where you get your daily special Word Delivery. A new one every day!',
    'anim': 'Talk_TwoLine-01'
  }, {
    'msg': 'WHOA! There it is. Inside each box is a gateway to your vocabulary word activities.',
    'anim': ['Talk_OneLine-Whoa', 'Talk_OneLine-01']
  }, {
    'msg': 'Are you feeling the excitement? Alright--whenever you\'re ready, hit GO and let\'s get started!',
    'anim': ['Talk_TwoLine-01', 'Rest_03_FootTap']
  }, {
    'msg': 'Hey, you\'re back! Good job completing those activities.',
    'anim': 'Talk_TwoLine-01'
  }, {
    'msg': 'Usually a new box of words will arrive when you come back to the Home Screen, if you have more words to do.',
    'anim': 'Talk_OneLine-Whoa'
  }, {
    'msg': 'If you did a really good job, I might react like this:',
    'anim': ['Talk_TwoLine-01', 'Emo_02-Dance']
  }, {
    'msg': 'If you hit a lot of road bumps, I might get kind of emotional...',
    'anim': 'Talk_TwoLine-02'
  }, {
    'msg': 'Boo Hoo! I was rooting for you, kid! Aw, you almost had it! You were this close! Sniff. Just wait till next time. You\'ll show \'em, won\'t you?',
    'anim': ['Emo_01-Cry', 'Emo_01-Cry']
  }, {
    'msg': 'ANYWAY -- More words are being cooked up, and they\'ll be ready soon. Click the STATS button up top to see more!',
    'anim': ['Talk_OneLine-01', 'Talk_Attention']
  }],
  'welcome': [
    'Oh, hi! I didn\'t hear you come in. Make yourself at home!',
    'Hey there! I was wondering when you were coming back!',
    'You\'re back sooner than I expected! Glad to see you. I missed you!',
    'There\'s no place like home screen. Don\'t you agree?'
  ],
  'loadingWords': [
    'Hold on a second, I\'m getting words for you',
    'I\'m preparing words for you, please give me a second...',
  ],
  'dailyDelivery': [
    'Wish we had more time for chit chat. Are you excited to meet a herd of words?',
    'And now the big question -- are you psyched for some more words?',
    'Good news from the word warehouse - new words incoming!',
    'Are you wondering where the new words are? Look up!',
    'So, are you ready for more?'
  ],
  'noWords': [{
    'msg': 'Oh hey! No words to do right now. Be sure to check back in tomorrow!',
    'anim': ['Talk_Welcome-01', 'Talk_Shrug']
  }, {
    'msg': 'Hey! That\'s all the words we\'ve got for today. Take the day off and come back tomorrow!',
    'anim': ['Emo_09-Hey', 'Emo_05-JumpJoy']
  }, {
    'msg': 'Well, no more words to do right now. Fresh ones arriving tomorrow!',
    'anim': ['Talk_Hmm', 'Talk_OneLine-02']
  }],
  'wordsLeft': {
    'msg': 'Hey there. There are still a few words here left to explore',
    'anim': ['Talk_Attention', 'Rest_03_FootTap']
  },
  'vocabLicense': {
    'msg': 'Whoops! You\'re not licensed for Vocab App. Use the back button to go back to Reading.',
    'anim': ['Talk_Hmm', 'Talk_OneLine-02']
  },
  'newStream': [
    'Whoa, check this out. Here\'s a whole NEW set of words to explore',
    'Let\'s change things up a little bit and check out this new set of words.',
    'Guess what? They tell me we\'re switching up some words for you. Let\'s dive into these new ones and see what they\'re all about.'
  ],
  'dailyRandom': [
    'In other news, did you know astronauts get taller in space? So if you want to add some height, try going into orbit.',
    '“The T-rex\'s closest living relatives are chickens and ostriches. I can\'t wait for Jurassic Park: Rise of the Chickens.',
    'By the way, never go bowling with a grizzly bear. Its jaws are strong enough to crush a bowling ball!',
    'Did you know sea otters can use tools? That otter come in handy!',
    'And uum… did you know that Oxford University in England is older than the Aztec Empire? Talk about old school!',
    'Completely unrelated, but did you know that for each human on Earth, there are 1.6 MILLION ants? That makes me feel antsy.',
    'Did you know dinosaurs swallowed rocks to help them digest? Kind of like a Mesozoic after-dinner mint.',
    'Psst...did you know the largest snowflake measured 15 inches across? It never showed up on time because it was a huge flake.',
    'This is random, but every human has a unique tongue print. Like a fingerprint. I guess burglars should be careful not to lick the doorknob?',
    'Did you know seals laugh? They\'re so seal-y!',
    'Check it out: The word “facetiously has all 5 vowels (and y!) in alphabetical order. And I\'m not being facetious.',
    'Hey, did you know honey never goes bad? You could theoretically eat 1,000-year-old honey. Orrrr you could just buy a new jar. It\'s really your call.',
    'Psst… did you know cats spend 70% of their lives asleep? Think about that next time you take a cat nap.',
    'Ketchup was once sold as medicine. Does that mean french fries were once considered a disease?',
    'Did you know a shrimp\'s heart is near its head? So when their hearts get broken, do they get headaches?',
    'It is physically impossible for a pig to look straight up at the sky. Which I guess is why they never seem to talk about the weather?',
    'Did you know that dolphins sleep with one eye open? I found this out the hard way while trying to play a prank on a sleeping dolphin.',
    'Psst...wearing headphones for an hour could increase the amount of bacteria in your ear by 700%. Ear buds? More like ear bugs!',
    'Did you know the planet Venus spins backward? You do you, Venus!',
    'Cool fact: A million Earths can fit inside the Sun. Although honestly, I\'m just as happy to stay outside of it.',
    'Did you know Gorillas burp when they are happy? Which makes me wonder, do they sneeze when they are sad?',
    'Psst… “odontophobia means the fear of going to the dentist. If you suffer from it, I hope you brush and floss on the regular.',
    'A duck\'s quack doesn\'t noticeably echo. Which is a shame, because it would be cool to hear \'QUACK-ack-ack-ack-ack-ack-ack\'.',
    'Kangaroos cannot hop backwards. Which makes it awkward when they\'re trying to get into an elevator while you\'re trying to get off.',
    'Did you know starfish don\'t have brains? I\'m not being rude. They just literally don\'t have them.',
    'Did you know a rabbit\'s teeth never stop growing? This must keep rabbit dentists very busy.',
    'I just learned octopuses have three hearts. Which must be why they\'re so clingy. Get it?.',
    'Did you know some goldfish can live up to 30 years? More like OLDfish, am I right?',
    'Guess what? An elephant\'s tusks are actually teeth. Good luck with that, if you\'re an elephant\'s dentist!',
    'Did you know they have eel-flavored ice cream in Japan? Which makes me wonder if there are ice-cream-flavored eel.',
    'A group of jellyfish that increases in number is called a bloom! So instead of flowers, can you send someone a bouquet of jellyfish for their birthday?',
    'Did you know your hair contains traces of gold? So, when barbers sweep up hair clippings, do they take them to the bank?',
    'The word \'eigengrau\' is the name for the color you see in a pitch-black room. But it\'s not pitch-black. It\'s dark gray! Whoa.',
    'Did you know sea otters hold hands when they sleep so they don\'t drift apart? In case you needed another reason to find them adorable.',
    'Crazy fact: The oldest star is 13.7 billion years old. Kind of puts things in perspective, huh?',
    'Apparently, outer space smells like diesel fumes, gunpowder, and barbecue. It\'s not because aliens are shooting fireworks at a cookout inside of a monster truck rally. It\'s (possibly) because of all the dying stars.',
    'Did you know that baby carrots are made from larger carrots that are cut and shaped? It\'s like a carrot makeover.',
    'Fun fact: The national animal of Scotland is a unicorn. The national weather, however, is not blue skies and rainbows.',
    'Apparently, some cats might be allergic to humans. Meow-CHOO!',
    'Did you know, penguins use eye muscles to change the shape of the lenses in their eyes to see underwater? Which is why you never see a penguin wearing goggles.',
    'Hey, did you know a bolt of lighting is about four times hotter than the sun? That\'s hot stuff.',
    'Did you know you can train an armadillo? Clever critters!',
    'The average person has over 1,400 dreams a year. Don\'t\' let anyone tell you that you\'re not doing anything when you\'re asleep!',
    'Hey! Did you know owls can\'t move their eyes? HOO would have guessed?',
    'Did you know the banana slug can grow to be a foot long? It looks like a ripe banana, so be careful not to eat it.',
    'Did you know a snail can have thousands of teeth? I never used to be afraid of snails until I learned that.',
    'Ever heard of a reversible coat? Turns out a starfish has a reversible stomach. It can turn it inside out.',
    'If you keep a goldfish in the dark, it will start to lose its color. My question: is it still a goldfish, or is it now a palefish?',
    'Ever wondered why you never see chocolate-flavored cat food? Cats can\'t taste anything sweet!',
    'If you\'re impatient for the school day to be over, just be glad you don\'t go to school on Venus. One day on Venus is 243 Earth days.',
    'Hey, did you know elephants sing, but at a pitch too low for people to hear? That\'s a low blow!',
    'What has hands but cannot clap? A clock! A clock, my friends. A clock. What can I say, I\'m a helper, not a comedian.',
    'Hey, did you know, the oldest soup recipe was written in 6,0000 B.C.E.? It was for a hippopotamus and sparrow soup. Funny, I\'ve never seen that flavor on a soup can!',
    'Did you know that apples belong to the rose family? But for some reason, rose-petal pie never seemed to catch on.',
    'I just learned people used to use chocolate as money. I guess they were okay with having sticky wallets.',
    'Interesting fact: Cucumbers are 96% water. But it never seems to work when I try to go for a swim in a cucumber.',
    'You know that little squirt of toothpaste you put on your toothbrush? It\'s called a \'nurdle.\' Vocab is everywhere!',
    'I found this out the hard way: Pearls dissolve in vinegar. So don\'t put them in a salad.',
    'Did you know? You can\'t tickle yourself. No matter how hard you try. That\'s why you need friends.',
    'Have you ever noticed, your fingernails grow faster than your toenails? So if you want to make your toenails feel bad, call them slownails.',
    'Amazing! Wood frogs actually freeze to survive the winter. They stop breathing, their hearts stop beating, and their cells produce antifreeze! Personally, I prefer to put on a warm coat.',
    'I just found out only female mosquitoes bite. Reason? They need your blood to produce eggs!',
    'Interesting fact: The word \'girl\' used to describe both male and female children, until around 600 years ago, when it started being used just for females. There\'s more to vocab than meets the eye!',
    'People, I don\'t want to alarm you but...a group of frogs is called an army.',
    'A group of owls is called a parliament. Which: perfect!',
    'A group of foxes is called a skulk. Just to make sure you know how shady they are.',
    'Hole-y phobias! Trypophobia is the fear of clustered holes.',
    'Did you know some people form a stone in their belly button called an umbolith? You could call it a belly boulder.',
    'Did you know the binturong, or bearcat, of Southeast Asia smells like popcorn? But trust me on this, they are harder to toss into your mouth.',
    'A song you can\'t get out of your head is called an earworm! So if you catch an earworm, you don\'t need an MD, you need a DJ.',
    'A mondegreen is when you mishear a song lyric. I always thought Gimme Shelter was Vocab Helper.',
    'I\'ve heard there\'s a species of jellyfish that can live forever. All hail the Immortal Jellyfish!',
    'Cymothoa exigua is a species of parasite that replaces a host fish\'s tongue with its own body. Invasion of the tongue snatcher!',
    'What\'s the US\'s official language? If you guessed English you\'re wrong. The U.S. has no official language.',
    'Spiciness in food is measured in Scoville Heat Units, although personally I measure it in YOW! units.',
    'Don\'t get crabby if I tell you that x-ray intensity from outer space is measured in units called Crabs.',
    'A snail can sleep for three years. But if you\'re within earshot and they\'re snoring, it feels more like six.',
    'Have you heard? Chimpanzees and bonobos are the closest animals relative to humans. So I guess going to the zoo is sort of like visiting the extended family.',
    'Did you know the oldest known melody is from the Hurrian Songs, dating to 1400 BCE from the ancient city of Ugarit, in modern Syria. Talk about a golden oldie!',
    'The earliest known use of an emoticon was apparently in 1648. A smiley face appears in the poem “To Fortune by Robert Herrick :).',
    'Did you know brontology is the study of thunder? So then, what do they call the study of brontosauruses?',
    'Melissophobia is the fear of bees. Apologies to anyone named Melissa (which means \'honeybee,\' by the way!).',
    'Gulp! Frogs can\'t swallow with their eyes open! So that\'s always a good time to surprise them.',
    'Did you know a duck can\'t walk without bobbing its head? And I always thought they were listening to music on invisible duck earbuds.',
    'Did you know a crocodile can\'t move its tongue?This makes it so hard to share ice cream with them.',
    'Did you know hummingbirds can\'t walk? Seems fair, because I can\'t beat my wings up to 5400 times per minute.',
    'Check this out: Bowhead whales may live for more than 200 years. Talk about the old man and old woman of the sea!',
    'Did you know giraffes are bad swimmers? Luckily, their necks are so long they can just stand in the deep end.',
    'Did you know snakes can\'t blink? Which is one of many reasons not to get into a staring contest with one.',
    'Apparently, giraffes only need to drink water once every few days. This may account for their dry sense of humor.',
    'Ha! Did you know that on average, adults laugh 17 times a day, and children laugh 300 times a day? And that\'s for people who don\'t even get to hear my jokes!',
    'Humans shed up to a pound and a half of skin each year. Do you miss it?',
    'Did you know rabbits have a sweet tooth? That\'s why they love fruit. How sweet is that?',
    'Did you know all reindeer have red noses… on the inside? Their noses are filled with blood vessels to warm the air before it reaches their lungs. Rudolph, you were never alone!',
    'Did you know a giraffe can clean its ear with its tongue? On the one hand, cool. On the other hand, YUCK',
    'Did you know that camels are born without humps? So, is the day their humps come in known as Hump Day?',
    'Caterpillars have way more muscles than humans do. And they don\'t even pump iron!',
    'Did you know koala bear fingerprints look exactly the same as a human\'s? Makes you wonder how many crimes were really committed by a Koala…',
    'Did you know some rats can tread water for three days? Instead of the doggy paddle, maybe they ought to teach the rat stroke.',
    'There are more stars in space than there are grains of sand on every beach on earth. But walking barefoot in the stars doesn\'t feel as nice between your toes.',
    'Apparently ants can carry more than 50 times their own weight. I think that\'s f-ANT-astic!',
    'Did you know that there was a dinosaur called a Pegomastax, with a face like a parrot and body covered in bristles like a porcupine? It was a prehistoric parrot prickler.',
    'Did you know that besides fruit, gorillas enjoy eating plants such as celery? Which is why I make a banana-and-celery salad whenever I have gorillas over for dinner.',
    'Interesting fact: Popcorn originated with the Aztecs. I love a snack that\'s historic AND delicious!',
    'Did you know pigs don\'t sweat? So if someone says they\'re \'sweating like a pig,\' it technically means they roll in the mud to keep cool.',
    'Contrary to what I always believed, the inventor of the cat door was not a cat. The feline portal has been credited to Isaac Newton.',
    'Ding dong! The doorbell was invented in 1831. Luckily, they had already invented the door by then.',
    'You\'ve heard of \'old world,\' but how about old words? The first English dictionary was written in 1604.',
    'It took Leonardo Da Vinci at least four years to paint Mona Lisa. Maybe her famous \'smile\' is actually a grimace because her leg fell asleep.',
    'Guess where German Chocolate Cake was invented? Texas! The \'German\' comes from Samuel German, whose name was on the baking chocolate originally used in the recipe.',
    'Whoa! Jellyfish have been on earth for over 500 million years! That\'s a lot longer than their cousin, the peanutbutterfish.',
    'Did you know, map-making is older than writing? Getting lost is even older than that.',
    'Did you know whales are color blind? You can wear a shirt that clashes with your pants and they won\'t even notice.',
    'Here\'s a cute fact: A newborn kangaroo is about 1 inch tall. Maybe that\'s why they learn to hop--to seem taller.',
    'Did you know the Hawaiian alphabet has 13 letters? That\'s half as many as these vocab words.',
    'Guess what? The first novel was written in the early 11th century by a Japanese noblewoman, Murasaki Shikibu. Talk about a groundbreaking story!',
    'Did you know the largest muscle in your body is in your rear end? Makes you look at it with a little more respect, doesn\'t it?',
    'Your body makes about six cups of snot each day. Why can\'t it make something nice, like hot chocolate?',
    'Did you know that if you could drive a car to space, it would only take about an hour? But alas, there is no Earth-to-Space highway.',
    'Interesting fact: 25% of your bones are located in your feet. One more reason to be kind to your feet!',
    'After thirty, humans gradually start shrinking. Although I don\'t think anyone has lived long enough to completely disappear.',
    'Did you know Edgar Allen Poe\'s poem “The Raven was originally about a parrot? But I guess it wasn\'t creepy enough.',
    'Whoa. There are more ways to shuffle a deck of cards than there are atoms in the entire Earth. Which makes me wonder who counted all the atoms in the entire Earth.',
    'In the early \'90s, 60,0000 jellyfish orbited the Earth. But there was no one to sting up there, so they came back down.',
    'Some snakes can fly! Which seems fair, since they don\'t get to have hands or feet.',
    'Whaaa? Maine is the closest U.S. state to Africa! Geography gets my head spinning.',
    'Guess what? Hippo milk is pink. But don\'t make the same mistake I did. It\'s not strawberry flavored!',
    'The moon is moving away from the Earth, at about an inch and a half a year. Is it something we said?',
    'It rains diamonds on Saturn and Jupiter! So if you ever go there, make sure you bring a strong umbrella.',
    'Did you know that trees can count, learn, remember, nurse each other to health and warn each other of danger? So be careful--you mess with one tree, you\'re messing with them all.',
    'Time passes a little faster for your face than your feet. But that is no excuse not to change your socks daily.',
    'Did you know Monarch butterflies migrate 2000 miles from Canada and the United States to Mexico and California? And they don\'t even get an in-flight movie!',
    'The oldest-known land animal is a 190-year-old tortoise named Jonathan. He hasn\'t slowed down a bit! Or maybe he has and I just couldn\'t tell, because all tortoises are slow.',
    'Horseshoe crabs have eyes on their tails. Which is why they\'re always sitting on their eyeglasses.',
    'Did you know cows have best friends? How MOO-ving.',
    'Did you know rats laugh when you tickle them? If you did know that, I gotta ask, why were you tickling them?',
    'A group of flamingos is called a flamboyance. Fabulous!',
    'A group of pugs is called a grumble. How could that dog be any cuter?',
    'At one point, very briefly, you were the youngest person on the planet. Whoa.',
    'Your largest organ is not your liver, heart, or stomach. It\'s your skin. Skin for the win!',
    'Earthworms communicate by snuggling. Which, honestly, so do some humans.',
    'Did you know butterflies taste with their feet? You can never bring them to a fancy restaurant.',
    'Did you know dolphins have names for each other? I have a name for that: Amazing!',
    'There could be over six billion bacteria living in your mouth right now. I wonder if they\'re talking about you.'
  ],
  'readyToGo': [
    'That\'s all you - go for it!',
    'Couldn\'t ask for better words. Let\'s go!',
    'I set \'em up, you knock \'em down. Let\'s go!',
    'That\'s a good looking group. Go anytime you\'re ready.',
    'You\'re up, kid - jump on it!'
  ],
  'activitiesCompleted': {
    'description': [
      'This shows how many activities you\'ve completed, total.',
      'There\'s a ton of activities to do - here\'s how many you\'ve done so far.',
      'This number goes up the more activities you complete.'
    ],
    'analysis': {
      '0': [{
        'msg': 'You\'ve done {number} activities this week. Let\'s get that number ticking upwards!',
        'anim': ['Emo_10-MindBlown', 'Talk_Attitude']
      }, {
        'msg': 'You\'ve done {number} activities this week. Let\'s see how big we can make that number grow!',
        'anim': ['Talk_Hmm', 'Talk_OneLine-01']
      }, {
        'msg': 'You\'ve done {number} activities this week. That\'s a start. Let\'s push for more!',
        'anim': ['Talk_Slump', 'Talk_Me']
      }],
      '20': [{
        'msg': 'You\'ve done {number} so far this week. Hey, that\'s not bad! Keep it up.',
        'anim': ['Talk_Hmm', 'Talk_OneLine-01']
      }, {
        'msg': 'You\'ve done {number} activities. Good job! Keep on rolling, my friend.',
        'anim': ['Talk_Attention', 'Talk_Shrug']
      }, {
        'msg': 'You\'ve done {number} activities this week. All systems are go. Target is locked. Warp speed ahead, Captain!',
        'anim': ['Talk_OneLine-02', 'Emo_02-Dance']
      }],
      '70': [{
        'msg': 'You\'ve done {number} activities this week. If I had a dime for every activity you did… I\'d give it to you, because you\'ve earned it!',
        'anim': ['Talk_Hmm', 'Talk_Shrug']
      }, {
        'msg': 'You are running HOT! You\'ve done {number} activities this week! Nice work.',
        'anim': ['Emo_05-JumpJoy', 'Emo_09-Hey']
      }, {
        'msg': 'You\'re doing GREAT! {number} activities this week! You keep doing you, because it\'s working, my friend. It\'s working.',
        'anim': ['Talk_Hmm', 'Talk_OneLine-Whoa']
      }],
      '120': [{
        'msg': 'Wait…. You\'ve done {number} this week? If I can put it plainly, you are Blowing. My. Mind..',
        'anim': ['Emo_09-Hey', 'Emo_10-MindBlown']
      }, {
        'msg': 'Wow! {number} activities this week! You are a superstar.',
        'anim': ['Emo_10-MindBlown', 'Talk_Attention']
      }, {
        'msg': 'So. Many. ACTIVITIES. {number} this week. You\'ve done what no one else has done. You\'ve rendered me speechless!',
        'anim': 'Emo_06-BoredDead'
      }]
    }
  },
  'longestStreak': {
    'description': [
      'A streak is the highest number of correct answers back-to-back.',
      'Getting a bunch of answers right without getting one wrong increases your streak.',
      'How many right answers have you gotten in a row? The streak\'ll tell ya.'
    ],
    'analysis': {
      '0': {
        'msg': 'I bet you can get a longer streak than that. Let\'s get back in there!',
        'anim': 'Talk_Attitude'
      },
      '4': {
        'msg': 'That streak is a good start. Let\'s try for a longer one. Show \'em whatcha got!',
        'anim': ['Talk_Hmm', 'Talk_Shrug']
      },
      '7': {
        'msg': 'You\'ve got talent, for sure! Let\'s see where it can take you. Let\'s get that streak higher!',
        'anim': 'Talk_OneLine-Whoa'
      },
      '10': {
        'msg': 'You are heating up! That\'s a solid streak, for sure. Think you can do even better?',
        'anim': ['Talk_LetsGo', 'Talk_OneLine-01']
      },
      '13': {
        'msg': 'What? No, these are tears of joy! You make me so proud!',
        'anim': ['Emo_01-Cry', 'Talk_Me']
      },
      '17': {
        'msg': 'I\'ve seen some streaks in my time, but this is really something. Way to go!',
        'anim': ['Emo_05-JumpJoy', 'Talk_Welcome-02']
      },
      '20': {
        'msg': 'Now that\'s a streak to SHOUT about! Maybe wait until you\'re someplace alone, though.',
        'anim': ['Emo_02-Dance', 'Emo_08-RunningMan', 'Emo_08-RunningMan']
      },
      '30': {
        'msg': 'You\'re great. I tip my hat to you… Except whoever drew me didn\'t give me a hat.',
        'anim': ['Emo_03-SlowClap', 'Emo_03-SlowClap', 'Talk_Hmm', 'Talk_Hmm']
      }
    }
  },
  'accuracyPercentage': {
    'description': [
      'Accuracy shows what percentage of your activities you\'re getting correct.',
      'You get some right, some wrong. This chart shows you how many.',
      'This chart you want to get as high as possible. It means you\'re doing it right.'
    ],
    'analysis': {
      '0': {
        'msg': 'I KNOW you can get that number higher!',
        'anim': 'Talk_Slump'
      },
      '11': {
        'msg': 'Hm. Here\'s a tip--sloowwww down and focus! Good things will start to happen.',
        'anim': 'Talk_Attitude'
      },
      '41': {
        'msg': 'I\'m sensing it\'s time to kick things into another gear. Really go for it! You\'re almost there.',
        'anim': 'Emo_04-Knees'
      },
      '51': {
        'msg': 'Right now you\'re pretty much 50-50. I have a feeling you\'re going to tip those odds going forward. Go get \'em!',
        'anim': ['Talk_Hmm', 'Talk_Shrug']
      },
      '61': {
        'msg': 'Hey, you\'re doing well! Let\'s kick it up a notch and push it further. You got this!',
        'anim': ['Emo_03-SlowClap', 'Talk_Hmm', 'Talk_LetsGo']
      },
      '71': {
        'msg': 'You\'re doing pretty great! And like a jet taking off, you can take it even higher.',
        'anim': ['Talk_LetsGo', 'Talk_Shrug']
      },
      '81': {
        'msg': 'Look at that number! Somebody hand me a bucket of water because you are on FIRE',
        'anim': ['Emo_05-JumpJoy', 'Emo_02-Dance']
      },
      '91': {
        'msg': 'Are my eyes deceiving me? Because I have never seen a number that BEAUTIFUL.',
        'anim': ['Emo_07-Shocked', 'Emo_06-BoredDead']
      },
      '99': {
        'msg': 'I always knew you were IMPOSSIBLY PERFECT! Whaaat!',
        'anim': ['Emo_10-MindBlown', 'Emo_05-JumpJoy']
      }
    }
  },
  'wordsMastered': {
    'description': [
      'Do enough activities with a word correctly, and consider it mastered!',
      'It takes time to master a word. Here\'s how many you\'ve done so far.',
      'Get enough activities correct and you master that word. Then you\'re done with it!'
    ],
    'analysis': {
      '0': [{
        'msg': 'Nothing here yet, but there will be when you finish more activities.',
        'anim': ['Talk_Hmm', 'Talk_OneLine-02']
      }, {
        'msg': 'Nothing to see here, folks. Just a big old zero. Do some activities and watch that zero hatch a number.',
        'anim': ['Talk_Shrug', 'Talk_OneLine-01']
      }, {
        'msg': 'Not yet a master. You must keep trying, grasshopper.',
        'anim': ['Talk_Attitude', 'Talk_Attention']
      }],
      '1': [{
        'msg': 'Would you look at that… Like a pop star, you\'ve made it onto the charts!',
        'anim': ['Emo_03-SlowClap', 'Emo_09-Hey']
      }, {
        'msg': 'That\'s a good start. Keep it up and hit those double digits!',
        'anim': ['Emo_03-SlowClap', 'Emo_09-Hey']
      }, {
        'msg': 'I sense many words mastered in you. Out come they must.',
        'anim': ['Talk_Hmm', 'Emo_10-MindBlown']
      }],
      '11': [{
        'msg': 'That number looks good. Like an express train, you\'re right on track. Stay on those rails, boss!',
        'anim': ['Talk_Attention', 'Talk_Attitude']
      }, {
        'msg': 'If I had thumbs, I\'d give you two thumbs up, kid!',
        'anim': ['Emo_03-SlowClap', 'Talk_Hmm', 'Talk_Shrug']
      }, {
        'msg': 'Get a load of this kid! You\'re really something special.',
        'anim': ['Emo_09-Hey', 'Talk_Attitude']
      }],
      '51': [{
        'msg': 'I just want to say, you are doing REALLY well. I knew you had it in you.',
        'anim': ['Talk_OneLine-Whoa', 'Emo_09-Hey']
      }, {
        'msg': 'Now that\'s the kind of number I love to see. Nice work!',
        'anim': ['Talk_Attention', 'Emo_03-SlowClap']
      }],
      '151': [{
        'msg': 'Didn\'t I just see you? In the encyclopedia next to the word UNSTOPPABLE?',
        'anim': ['Talk_Attitude', 'Emo_02-Dance']
      }, {
        'msg': 'Last time I saw a number like that was… well, never!',
        'anim': ['Talk_Hmm', 'Talk_OneLine-Whoa']
      }, {
        'msg': 'I would say something but I don\'t want to jinx ya! Just… mmm hmm!',
        'anim': ['Emo_05-JumpJoy', 'Emo_07-Shocked']
      }],
      '351': [{
        'msg': 'Could anyone be more perfect? Never change.',
        'anim': ['Talk_Attitude', 'Emo_03-SlowClap']
      }, {
        'msg': 'Just as the legend foretold - you are the word MASTER. All shall know of thee!',
        'anim': ['Emo_09-Hey', 'Emo_04-Knees']
      }, {
        'msg': 'You know what? You\'re really good! All the spheres are talking about you.',
        'anim': ['Talk_Attitude', 'Emo_03-SlowClap']
      }]
    }
  },
  'randomPhrases': {
    'offHelper': [{
      'msg': 'And that\'s all I need to say about that.',
      'anim': 'Talk_OneLine-01'
    }, {
      'msg': 'Sometimes, you just feel like clicking, am I right? Click away, my friend, click away.',
      'anim': 'Rest_02-Yawn'
    }, {
      'msg': 'Hm, I don\'t think you\'re going to find what you\'re looking for there.',
      'anim': 'Talk_Shrug'
    }, {
      'msg': 'I see your finger likes the clicker!',
      'anim': 'Talk_Attitude'
    }, {
      'msg': 'Did somebody tap me? Can I help you?',
      'anim': 'Talk_Shrug'
    }, {
      'msg': 'Haha. I think you\'re doing that on purpose.',
      'anim': 'Talk_Slump'
    }, {
      'msg': 'It\'s cool with me if you keep clicking, but I don\'t think it\'s going to do anything',
      'anim': 'Talk_Attitude'
    }],
    'onHelper': [
      'May I help you?',
      'Haha. That tickles!',
      'Oooo hahahaha! It tickles, I tell you',
      'Okay, okay, I give up!',
      'Actually I have an itch a little higher, can you try clicking up there?',
      'Ouch!',
      'Okay, yeah, that\'s the spot.'
    ]
  },
  'accuracyFeedback': {
    '0': {
      'msg': 'Keep trying! You can do this!',
      'anim': 'Emo_06-BoredDead'
    },
    '1': {
      'msg': 'Let\'s try again. Take your time and really concentrate.',
      'anim': ['Talk_Slump', 'Talk_OneLine-01']
    },
    '50': {
      'msg': 'Nice work! You\'ve been studying, haven\'t you?',
      'anim': ['Emo_02-Dance', 'Talk_Shrug']
    },
    '100': {
      'msg': 'Wow! Is it just me, or was that perfect?',
      'anim': ['Emo_05-JumpJoy', 'Talk_Attention']
    }
  },
  'offline': {
    'moreWords': {
      'msg': 'An Internet connection is required to get more words. Please refresh this page when you are reconnected.',
      'anim': 'Talk_Shrug'
    },
    'chooseProfile': {
      'msg': 'An Internet connection is required. Please try again when you are connected.',
      'anim': 'Talk_Shrug'
    },
    'noProfile': {
      'msg': 'Your Internet connection was lost. Please refresh this page when you are reconnected.',
      'anim': 'Talk_Shrug'
    },
    'login': {
      'msg': 'An Internet connection is required. Please try again when you are connected.',
      'anim': 'Talk_Shrug'
    }
  },
  'levels': {
    '1': {
      'msg': 'Now wait… things look a bit different here… and I LOOK DIFFERENT!',
      'anim': 'Talk_Hmm'
    },
    '2': {
      'msg': 'The signs say… You\'ve finished enough activities that I can officially welcome you to Level 2! Woo!',
      'anim': 'Emo_05-JumpJoy'
    },
    '3': {
      'msg': 'Dive on into Level 3! The water\'s fine.',
      'anim': ['Talk_Welcome-02', 'Talk_Shrug']
    },
    '4': {
      'msg': 'Welcome to Level 4. Pretty sweet huh?',
      'anim': ['Talk_Welcome-01', 'Talk_Shrug']
    },
    '5': {
      'msg': 'Greetings! Thou hast arrived at Level 5. Will you help me find my contact lens?',
      'anim': ['Talk_Attention', 'Talk_Me']
    },
    '6': {
      'msg': 'You\'ve made it to level 6. Eye knew you could do it!',
      'anim': ['Emo_09-Hey', 'Emo_03-SlowClap']
    },
    '7': {
      'msg': 'Oh heyyyy you made it to Level 7! Level 7 rhymes with heaven… for a reason!',
      'anim': 'Rest_02-Yawn'
    },
    '8': {
      'msg': 'It\'s just the Sun, the Earth, the stars, and us, up here on Level 8. DONT LEAVE ME!.',
      'anim': ['Talk_Attitude', 'Emo_04-Knees']
    },
    '9': {
      'msg': 'Welcome. Ready to try your luck on Level 9?',
      'anim': 'Emo_02-Dance'
    },
    '10': {
      'msg': 'Yawnnnn….What a nice surprise to wake up after 4000 years to find you on Level 10!',
      'anim': ['Rest_02-Yawn', 'Talk_Shrug']
    },
    '11': {
      'msg': 'Welcome to Level 11! How modern is this place? So clean and logical!',
      'anim': ['Talk_Welcome-02', 'Talk_Hmm']
    },
    '12': {
      'msg': 'BOO! welcome to Level 12, the spookiest and most haunted level of all! There are sodas in the fridge',
      'anim': ['Emo_07-Shocked', 'Talk_OneLine-02']
    },
    '13': {
      'msg': 'Well howdy, welcome to Level 13! Grab a lasso and go wrangle y\'self some more activities!',
      'anim': ['Emo_09-Hey', 'Talk_Attitude']
    },
    '14': {
      'msg': 'You\'d expect meow to say how purr-fect Level 14 is, but I\'m just not feline like it.',
      'anim': ['Talk_Me', 'Talk_Shrug']
    },
    '15': {
      'msg': 'Nothing but net, kid! You made it to Level 15.',
      'anim': ['Talk_Welcome-02', 'Emo_10-MindBlown']
    },
    '16': {
      'msg': 'Shipwrecked on Level 16! Well, matey, ye might as well get to hunting for that hidden treasure',
      'anim': ['Talk_Welcome-01', 'Talk_Shrug']
    },
    '17': {
      'msg': 'Welcome to Level 17! We\'re hanging in Washington D.C. honoring one of our great Presidents, who you might recognize as me.',
      'anim': ['Talk_Welcome-02', 'Talk_Me']
    },
    '18': {
      'msg': 'Another beautiful day here on 18.  Mind if I play through?',
      'anim': ['Talk_Welcome-01', 'Talk_Hmm']
    },
    '19': {
      'msg': 'Far out! Welcome to Level 19. We\'re now approximately 890 million miles from the Sun, but I still have my tan!',
      'anim': ['Emo_10-MindBlown', 'Talk_Me']
    },
    '20': {
      'msg': 'You made it to magical Level 20. Or is it just an illusion?',
      'anim': ['Talk_OneLine-Whoa', 'Talk_Hmm']
    },
    '21': {
      'msg': 'Welcome to Level 21. Where everything is subatomic.',
      'anim': ['Emo_09-Hey', 'Talk_Hmm']
    },
    '22': {
      'msg': 'Ahh, welcome to level 22! It\'s goth to see you!',
      'anim': ['Emo_05-Jump', 'Talk_Welcome-02']
    },
    '23': {
      'msg': 'You made it to Level 23, doggone it! Let\'s go for a walk.',
      'anim': ['Emo_05-Jump', 'Emo_05-Jump', 'Emo_06-BoredDead']
    },
    '24': {
      'msg': 'Welcome to Level 24. Things are a little abstract here, but they\'re colorful!',
      'anim': ['Talk_Welcome-01', 'Talk_Attitude']
    },
    '25': {
      'msg': 'Bloop! Welcome to Level 25. This aquarium has it all! I don\'t mind not being in the shark tank',
      'anim': ['Emo_09-Hey', 'Talk_Welcome-02']
    },
    '26': {
      'msg': 'Welcome to Sherwood Forest, also known as Level 26! Green is going to look good on you.',
      'anim': ['Talk_Welcome-01', 'Talk_Me']
    },
    '27': {
      'msg': 'Here, deep in the heart of the jungle, the monkeys seem to have uncovered the secrets of Level 27. Smart monkeys!',
      'anim': ['Talk_OneLine-Whoa', 'Talk_OneLine-02']
    },
    '28': {
      'msg': 'You made it to icy Level 28! Brrrrrrrrring it on!',
      'anim': ['Talk_Welcome-02', 'Emo_07-Shocked']
    },
    '29': {
      'msg': 'Welcome to Level 29, where nothing is what it seems! That\'s why I have to wear this amazing disguise.',
      'anim': ['Emo_09-Hey', 'Talk_DoNot']
    },
    '30': {
      'msg': 'Whoaaa. The colors, the shapes… Welcome to psychedelic Level 30.',
      'anim': ['Talk_Hmm', 'Talk_Attitude']
    },
    '31': {
      'msg': 'Welcome to the dawn of humankind, also known as Level 31. Put down your club, have a seat by the fire, and enjoy the view.',
      'anim': ['Talk_Me', 'Talk_OneLine-02']
    },
    '32': {
      'msg': 'Level 32 is pretty normal. Yep-totally, totally normal. Nothing unusual here. Just uh - why are you standing on your head?',
      'anim': ['Talk_TwoLine-01', 'Talk_Hmm']
    },
    '33': {
      'msg': 'Welcome to Level 33! Isn\'t it a-MAZE-ing?',
      'anim': ['Emo_09-Hey', 'Talk_Slump']
    },
    '34': {
      'msg': 'Welcome to the concrete jungle, also known as Level 34! Bright lights, big city, and us. Are you psyched?',
      'anim': ['Talk_LetsGo', 'Emo_09-Hey', 'Talk_Shrug']
    },
    '35': {
      'msg': 'Level 35 is full of secrets. The answers may lie behind these doors… If I could figure out how to get to them.',
      'anim': ['Emo_09-Hey', 'Emo_10-MindBlown']
    },
    '36': {
      'msg': 'Welcome to Level 36! Enjoy your fifteen minutes of fame. I\'m enjoying mine!',
      'anim': ['Talk_Attitude', 'Talk_Hmm']
    },
    '37': {
      'msg': 'The sun and moon are aligned. Something momentous is about to happen here on Level 37',
      'anim': ['Talk_TwoLine-01', 'Talk_Hmm']
    },
    '38': {
      'msg': 'The manatees are saying: Welcome to Level 38!',
      'anim': ['Emo_10-MindBlown', 'Talk_Me']
    },
    '39': {
      'msg': 'Carve those gnarly waves! You know the surf is cranking here on Level 39!',
      'anim': ['Talk_Me', 'Talk_OneLine-02']
    },
    '40': {
      'msg': 'Suit up and get ready to roll. We\'re cruising down Interspace Highway 40!',
      'anim': ['Emo_05-Jump', 'Talk_Welcome-02']
    },
    '41': {
      'msg': 'Here on level 41, what do you call a dinosaur that knows vocabulary? A THESAURUS. Ba-dump-chah... ugh.',
      'anim': ['Emo_09-Hey', 'Talk_DoNot']
    },
    '42': {
      'msg': 'Ahhh! Level 42. Now you\'ve really taken off!',
      'anim': ['Talk_LetsGo', 'Emo_09-Hey', 'Talk_Shrug']
    },
    '43': {
      'msg': 'Start spreading the news…. If you can make it on Level 43, you can make it anywhere!',
      'anim': ['Talk_Hmm', 'Talk_Attitude']
    },
    '44': {
      'msg': 'As I like to say, Level 44 is for when you really want to chill out. Welcome.',
      'anim': ['Emo_09-Hey', 'Emo_03-SlowClap']
    },
    '45': {
      'msg': 'An excellent playlist… a carload of snacks… and six hundred miles of open highway. Let\'s take a road trip through Level 45!',
      'anim': ['Talk_Welcome-01', 'Talk_Shrug']
    },
    '46': {
      'msg': 'I can finally cross \'Selfie at the Taj Mahal\' off my bucket list! Right after \'Experience massive jet lag.\'',
      'anim': ['Emo_05-Jump', 'Talk_Welcome-02']
    },
    '47': {
      'msg': 'Level 47\'s Rapa Nui heads are known as moai. See, you just got here and you\'re already smarter!',
      'anim': ['Emo_07-Shocked', 'Talk_OneLine-02']

    },
    'generic': {
      'msg': 'Welcome to Level {number}!',
      'anim': ['Emo_02-Dance', 'Emo_05-JumpJoy']
    }
  },
  'trial': [{
    'msg': 'Guess what? You\'ve done all the activities for this edition! Your teacher can tell you how to get more!',
    'anim': ['Talk_Me', 'Talk_Welcome-02']
  }, {
    'msg': 'You\'ve reached the end of the activity train! Your teacher can tell you how to find more.',
    'anim': ['Talk_Shrug', 'Talk_Loop']
  }, {
    'msg': 'We\'re all out of activities in this edition! Ask your teacher about accessing the full edition.',
    'anim': ['Talk_DoNot', 'Talk_TwoLine-01']
  }, {
    'msg': 'That\'s the last of the activities! Check with your teacher about how to find more.',
    'anim': ['Talk_Welcome-02', 'Talk_TwoLine-01']
  }, {
    'msg': 'Sorry, no more activities in this edition! Your teacher can tell you how to access more.',
    'anim': ['Talk_Shrug', 'Talk_OneLine-01']
  }, {
    'msg': 'Whoops, no more activities left! Talk with your teacher if you\'d like more.',
    'anim': ['Talk_DoNot', 'Talk_OneLine-01']
  }],
  'onboardingButtonTips': {
    'grade': {
      'msg': 'The blue button will start you with literary words for your current grade level.',
      'anim': 'Talk_TwoLine-01'
    },
    'ell': {
      'msg': 'The green button will start you with words to help with English language learning.',
      'anim': 'Talk_TwoLine-01'
    },
    'academic': {
      'msg': 'The red button will start you with academic words.',
      'anim': 'Talk_TwoLine-01'
    },
    'lastTip': {
      'msg': 'Ask your teacher which of these three buttons you should push.',
      'anim': 'Talk_TwoLine-01'
    }
  }
};
