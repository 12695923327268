import { all, put, takeEvery } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { classesDataActions, classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { addStudentNamesToDict, convertCDAClasses, convertStandaloneClasses, getActiveClasses, getTeacherDemoSection, mergeTeacherAndClassData, transformStudentsToDict } from 'app/util/classes';
import promiseHandler from 'app/util/promise-handler';
import { addGradeFromStreamsToStudents, addStreamPopupNameToStudents } from 'app/util/streams';

function* getLatestStudentProfile() {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getLatestStudentProfile());

    if (error) throw error;

    const latestProfile = response.data;

    yield put(classesDataActionCreators.getLatestStudentProfile.success({ latestProfile }));
  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getLatestStudentProfile.failure({
        error,
      })
    );
  }
}

function* getStandaloneClassesSections() {
  try {
    const [classesError, classes] = yield promiseHandler(
      ApiClient.getStandaloneClasses()
    );

    if (classesError) throw classesError;

    let activeClasses = getActiveClasses(classes.data);
    activeClasses = convertStandaloneClasses(activeClasses);
    activeClasses = mergeTeacherAndClassData(activeClasses, getTeacherDemoSection());

    yield put(
      classesDataActionCreators.getStandaloneClassesSections.success({
        activeClasses: activeClasses,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getStandaloneClassesSections.failure({
        error,
      })
    );
  }
}

function* getCDAClassesSections() {
  try {
    const [classesError, classes] = yield promiseHandler(
      ApiClient.getCDAClasses()
    );

    if (classesError) throw classesError;

    let activeClasses = getActiveClasses(classes.data.classes);
    activeClasses = convertCDAClasses(activeClasses);
    activeClasses = mergeTeacherAndClassData(activeClasses, getTeacherDemoSection());

    yield put(
      classesDataActionCreators.getCDAClassesSections.success({
        activeClasses: activeClasses,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getCDAClassesSections.failure({
        error,
      })
    );
  }
}

function* getStandaloneStudentProfiles() {
  try {
    let [studentsError, students] = yield promiseHandler(
      ApiClient.getStandaloneStudents()
    );

    if(studentsError && studentsError.response.data.code == 404) {
      // On 404, means that the class is empty
      studentsError = false;
      students = { data: { members: [] } };
    }

    if (studentsError) throw studentsError;

    let fullDict;
    if(students.data.members && students.data.members.length > 0) {
      const studentsDict = transformStudentsToDict(students.data.members, 'businessKey');
      fullDict = addStudentNamesToDict(studentsDict);
    } else {
      fullDict = [];
    }

    yield put(
      classesDataActionCreators.getStandaloneStudentProfiles.success({
        students: fullDict,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getStandaloneStudentProfiles.failure({
        error,
      })
    );
  }
}

function* getCDAStudentProfiles() {
  try {
    const [studentsError, students] = yield promiseHandler(
      ApiClient.getCDAStudents()
    );

    if (studentsError) throw studentsError;

    const studentsDict = transformStudentsToDict(students.data.users, 'businessKey');
    const fullDict = addStudentNamesToDict(studentsDict);

    yield put(
      classesDataActionCreators.getCDAStudentProfiles.success({
        students: fullDict,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getCDAStudentProfiles.failure({
        error,
      })
    );
  }
}

function* getStudentsClasses() {
  try {
    const [classesError, classes] = yield promiseHandler(
      ApiClient.getStudentsClasses()
    );

    if (classesError) throw classesError;

    let activeClasses = getActiveClasses(classes.data);
    activeClasses = convertStandaloneClasses(activeClasses);

    yield put(
      classesDataActionCreators.getStudentsClasses.success({
        activeClasses: activeClasses,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getStudentsClasses.failure({
        error,
      })
    );
  }
}

function* getActivitiesLimitation() {
  try {
    const [error, activitiesLimitation] = yield promiseHandler(
      ApiClient.getActivitiesLimitation()
    );

    if (error) throw error;

    yield put(
      classesDataActionCreators.getActivitiesLimitation.success({
        activitiesLimitation: activitiesLimitation.data,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getActivitiesLimitation.failure({
        error,
      })
    );
  }
}

function* getDisabledActivities() {
  try {
    const [error, disabledActivities] = yield promiseHandler(
      ApiClient.getDisabledActivities()
    );

    if (error) throw error;

    yield put(
      classesDataActionCreators.getDisabledActivities.success({
        disabledActivities: disabledActivities.data,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getDisabledActivities.failure({
        error,
      })
    );
  }
}

function* getTeacherSectionStats(action) {
  try {
    const streams = action.payload;

    const [error, sectionStats] = yield promiseHandler(
      ApiClient.getSectionStats()
    );

    if (error) throw error;

    if(sectionStats) {
      const performanceDict = transformStudentsToDict(sectionStats.data.studentPerformanceList, 'csUserBusinessKey');
      const streamUIPerformanceDict = addStreamPopupNameToStudents(performanceDict);
      const gradePerformanceDict = streams ? addGradeFromStreamsToStudents(streamUIPerformanceDict, streams) : streams;

      yield put(
        classesDataActionCreators.getTeacherSectionStats.success({
          performanceDict: gradePerformanceDict,
        })
      );
    }

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getTeacherSectionStats.failure({
        error,
      })
    );
  }
}

function* getStudentStats() {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getStudentStats());

    if (error) throw error;

    const stats = response.data;

    yield put(classesDataActionCreators.getUserStats.success({ studentStats: stats }));
  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getUserStats.failure({
        error,
      })
    );
  }
}

function* getStudentLatestProfileForTeacher(action) {
  const studentBK = action.payload;

  try {
    const [error, latestProfile] = yield promiseHandler(
      ApiClient.getLatestProfile(studentBK)
    );

    if (error) throw error;

    yield put(
      classesDataActionCreators.getStudentLatestProfileForTeacher.success({
        latestProfile: latestProfile.data,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      classesDataActionCreators.getStudentLatestProfileForTeacher.failure({
        error,
      })
    );
  }
}

export default function* classesDataSaga() {
  yield all([
    takeEvery(classesDataActions.GET_LATEST_STUDENT_PROFILE.REQUEST, getLatestStudentProfile),
    takeEvery(classesDataActions.GET_CDA_CLASSES_SECTIONS.REQUEST, getCDAClassesSections),
    takeEvery(classesDataActions.GET_STUDENT_STANDALONE_PROFILES.REQUEST, getStandaloneStudentProfiles),
    takeEvery(classesDataActions.GET_STUDENT_CDA_PROFILES.REQUEST, getCDAStudentProfiles),
    takeEvery(classesDataActions.GET_STUDENTS_CLASSES.REQUEST, getStudentsClasses),
    takeEvery(classesDataActions.GET_STANDLONE_CLASSES_SECTIONS.REQUEST, getStandaloneClassesSections),
    takeEvery(classesDataActions.GET_ACTIVITIES_LIMITATION.REQUEST, getActivitiesLimitation),
    takeEvery(classesDataActions.GET_DISABLED_ACTIVITIES.REQUEST, getDisabledActivities),
    takeEvery(classesDataActions.GET_TEACHER_SECTION_STATS.REQUEST, getTeacherSectionStats),
    takeEvery(classesDataActions.GET_STUDENT_LATEST_PROFILE_FOR_TEACHER.REQUEST, getStudentLatestProfileForTeacher),
    takeEvery(classesDataActions.GET_STUDENT_STATS.REQUEST, getStudentStats),
  ]);
}
