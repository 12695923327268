import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const QueryFeedbackTransition = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .to('#emojis > g', { duration: 1.5, transformOrigin: '50% 50%', scale: 3, yoyo: true, repeat: 1, ease: 'sine.inOut' })
      .add(gsap.from('#sky', { duration: .5, opacity: 0, yoyo: true, repeat: 1, ease: 'sine.inOut' }), 1);

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <g id="background">
        <rect id="sky" width="1024" height="680" fill="#090f30" />
      </g>
      <g id="emojis">
        <g>
          <polygon points="750.72 584.73 750.72 596.17 756.43 596.17 756.43 607.6 762.15 607.6 762.15 613.32 767.87 613.32 767.87 590.45 762.15 590.45 762.15 567.58 767.87 567.58 767.87 561.87 773.58 561.87 773.58 567.58 779.3 567.58 779.3 561.87 785.02 561.87 785.02 567.58 790.73 567.58 790.73 561.87 796.45 561.87 796.45 590.45 790.73 590.45 790.73 613.32 796.45 613.32 796.45 607.6 802.17 607.6 802.17 596.17 807.88 596.17 807.88 584.73 813.6 584.73 813.6 561.87 807.88 561.87 807.88 550.43 802.17 550.43 802.17 544.72 790.73 544.72 790.73 539 767.87 539 767.87 544.72 756.43 544.72 756.43 550.43 750.72 550.43 750.72 561.87 745 561.87 745 584.73 750.72 584.73" fill="#fdae35" />
          <polygon points="790.73 573.3 785.02 573.3 785.02 567.58 779.3 567.58 779.3 573.3 773.58 573.3 773.58 567.58 767.87 567.58 767.87 590.45 773.58 590.45 773.58 613.32 785.02 613.32 785.02 590.45 790.73 590.45 790.73 573.3" fill="#fdae35" />
          <rect x="762.15" y="619.03" width="34.3" height="5.72" fill="#3a4467" />
          <rect x="762.15" y="630.47" width="34.3" height="5.72" fill="#3a4467" />
          <polygon points="762.15 641.9 762.15 647.62 773.58 647.62 773.58 653.33 785.02 653.33 785.02 647.62 796.45 647.62 796.45 641.9 762.15 641.9" fill="#3a4467" />
          <rect x="767.87" y="561.87" width="5.72" height="5.72" fill="#f77622" />
          <rect x="779.3" y="561.87" width="5.72" height="5.72" fill="#f77622" />
          <rect x="773.58" y="567.58" width="5.72" height="5.72" fill="#f77622" />
          <polygon points="790.73 573.3 790.73 590.45 796.45 590.45 796.45 561.87 790.73 561.87 790.73 567.58 785.02 567.58 785.02 573.3 790.73 573.3" fill="#f77622" />
          <rect x="762.15" y="567.58" width="5.72" height="22.87" fill="#f77622" />
          <rect x="767.87" y="590.45" width="5.72" height="22.87" fill="#f77622" />
          <rect x="785.02" y="590.45" width="5.72" height="22.87" fill="#f77622" />
          <rect x="756.43" y="613.32" width="11.43" height="5.72" fill="#8b9bb5" />
          <polygon points="785.02 613.32 773.58 613.32 773.58 619.03 802.17 619.03 802.17 613.32 790.73 613.32 785.02 613.32" fill="#8b9bb5" />
          <rect x="756.43" y="624.75" width="11.43" height="5.72" fill="#8b9bb5" />
          <rect x="773.58" y="624.75" width="28.58" height="5.72" fill="#8b9bb5" />
          <rect x="756.43" y="636.18" width="11.43" height="5.72" fill="#8b9bb5" />
          <rect x="773.58" y="636.18" width="28.58" height="5.72" fill="#8b9bb5" />
          <rect x="767.87" y="613.32" width="5.72" height="5.72" fill="#ecf0f1" />
          <rect x="767.87" y="624.75" width="5.72" height="5.72" fill="#ecf0f1" />
          <rect x="767.87" y="636.18" width="5.72" height="5.72" fill="#ecf0f1" />
        </g>
        <g>
          <g>
            <polygon points="366.52 19.38 366.52 13.19 360.33 13.19 360.33 7 335.57 7 335.57 13.19 329.38 13.19 329.38 19.38 323.19 19.38 323.19 50.33 317 50.33 317 99.86 372.71 99.86 372.71 19.38 366.52 19.38" fill="#e43d44" />
            <polygon points="323.19 99.86 323.19 93.67 317 93.67 317 112.24 323.19 112.24 323.19 118.43 329.38 118.43 329.38 124.62 366.52 124.62 366.52 118.43 372.71 118.43 372.71 99.86 323.19 99.86" fill="#ecf0f1" />
            <polygon points="366.52 118.43 366.52 124.62 329.38 124.62 329.38 118.43 323.19 118.43 323.19 112.24 317 112.24 317 124.62 323.19 124.62 323.19 130.81 329.38 130.81 329.38 137 366.52 137 366.52 130.81 372.71 130.81 372.71 118.43 366.52 118.43" fill="#8b9bb5" />
            <rect x="323.19" y="112.24" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="317" y="106.05" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="329.38" y="118.43" width="37.14" height="6.19" fill="#5b6986" />
            <rect x="366.52" y="112.24" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="323.19" y="93.67" width="12.38" height="6.19" fill="#8b9bb5" />
            <rect x="360.33" y="93.67" width="12.38" height="6.19" fill="#8b9bb5" />
            <rect x="335.57" y="44.14" width="6.19" height="49.52" fill="#8b9bb5" />
            <rect x="354.14" y="44.14" width="6.19" height="49.52" fill="#8b9bb5" />
            <polygon points="360.33 19.38 360.33 13.19 335.57 13.19 335.57 19.38 329.38 19.38 329.38 37.95 335.57 37.95 335.57 44.14 341.76 44.14 341.76 99.86 354.14 99.86 354.14 44.14 360.33 44.14 360.33 37.95 366.52 37.95 366.52 19.38 360.33 19.38" fill="#422632" />
            <polygon points="360.33 44.14 360.33 31.76 335.57 31.76 335.57 44.14 341.76 44.14 341.76 93.67 335.57 93.67 335.57 99.86 360.33 99.86 360.33 93.67 354.14 93.67 354.14 44.14 360.33 44.14" fill="#733d3b" />
            <rect x="360.33" y="37.95" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="329.38" y="37.95" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="317" y="87.48" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="329.38" y="81.29" width="37.14" height="6.19" fill="#ecf0f1" />
            <rect x="329.38" y="68.9" width="37.14" height="6.19" fill="#ecf0f1" />
            <rect x="329.38" y="56.52" width="37.14" height="6.19" fill="#ecf0f1" />
          </g>
          <g>
            <polygon points="397.48 19.38 397.48 13.19 403.67 13.19 403.67 7 428.43 7 428.43 13.19 434.62 13.19 434.62 19.38 440.81 19.38 440.81 50.33 447 50.33 447 99.86 391.29 99.86 391.29 19.38 397.48 19.38" fill="#e43d44" />
            <polygon points="440.81 99.86 440.81 93.67 447 93.67 447 112.24 440.81 112.24 440.81 118.43 434.62 118.43 434.62 124.62 397.48 124.62 397.48 118.43 391.29 118.43 391.29 99.86 440.81 99.86" fill="#ecf0f1" />
            <polygon points="397.48 118.43 397.48 124.62 434.62 124.62 434.62 118.43 440.81 118.43 440.81 112.24 447 112.24 447 124.62 440.81 124.62 440.81 130.81 434.62 130.81 434.62 137 397.48 137 397.48 130.81 391.29 130.81 391.29 118.43 397.48 118.43" fill="#8b9bb5" />
            <rect x="434.62" y="112.24" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="440.81" y="106.05" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="397.48" y="118.43" width="37.14" height="6.19" fill="#5b6986" />
            <rect x="391.29" y="112.24" width="6.19" height="6.19" fill="#5b6986" />
            <rect x="428.43" y="93.67" width="12.38" height="6.19" fill="#8b9bb5" />
            <rect x="391.29" y="93.67" width="12.38" height="6.19" fill="#8b9bb5" />
            <rect x="422.24" y="44.14" width="6.19" height="49.52" fill="#8b9bb5" />
            <rect x="403.67" y="44.14" width="6.19" height="49.52" fill="#8b9bb5" />
            <polygon points="403.67 19.38 403.67 13.19 428.43 13.19 428.43 19.38 434.62 19.38 434.62 37.95 428.43 37.95 428.43 44.14 422.24 44.14 422.24 99.86 409.86 99.86 409.86 44.14 403.67 44.14 403.67 37.95 397.48 37.95 397.48 19.38 403.67 19.38" fill="#422632" />
            <polygon points="403.67 44.14 403.67 31.76 428.43 31.76 428.43 44.14 422.24 44.14 422.24 93.67 428.43 93.67 428.43 99.86 403.67 99.86 403.67 93.67 409.86 93.67 409.86 44.14 403.67 44.14" fill="#733d3b" />
            <rect x="397.48" y="37.95" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="428.43" y="37.95" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="440.81" y="87.48" width="6.19" height="6.19" fill="#8b9bb5" />
            <rect x="397.48" y="81.29" width="37.14" height="6.19" fill="#ecf0f1" />
            <rect x="397.48" y="68.9" width="37.14" height="6.19" fill="#ecf0f1" />
            <rect x="397.48" y="56.52" width="37.14" height="6.19" fill="#ecf0f1" />
          </g>
          <rect x="403.67" y="93.67" width="24.76" height="6.19" fill="#ecf0f1" />
          <rect x="335.57" y="93.67" width="24.76" height="6.19" fill="#ecf0f1" />
        </g>
        <g>
          <polygon points="752.33 323.33 752.33 319.67 748.67 319.67 748.67 316 745 316 745 312.33 741.33 312.33 741.33 308.67 737.67 308.67 737.67 305 726.67 305 726.67 308.67 723 308.67 723 312.33 719.33 312.33 719.33 316 715.67 316 715.67 319.67 712 319.67 712 323.33 708.33 323.33 708.33 327 715.67 327 715.67 323.33 719.33 323.33 719.33 319.67 723 319.67 723 316 726.67 316 726.67 312.33 730.33 312.33 730.33 308.67 734 308.67 734 312.33 737.67 312.33 737.67 316 741.33 316 741.33 319.67 745 319.67 745 323.33 748.67 323.33 748.67 327 756 327 756 323.33 752.33 323.33" fill="#252b43" />
          <rect x="690" y="261" width="84.33" height="36.67" fill="#3a4467" />
          <rect x="697.33" y="275.67" width="69.67" height="22" fill="#ecf0f1" />
          <g>
            <rect x="701" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="708.33" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="726.67" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="719.33" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="734" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="745" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="752.33" y="275.67" width="3.67" height="14.67" fill="#252b43" />
            <rect x="759.67" y="275.67" width="3.67" height="14.67" fill="#252b43" />
          </g>
          <rect x="690" y="297.67" width="84.33" height="7.33" fill="#252b43" />
          <rect x="697.33" y="297.67" width="69.67" height="3.67" fill="#8b9bb5" />
          <rect x="712" y="261" width="40.33" height="11" fill="#252b43" />
          <rect x="697.33" y="264.67" width="7.33" height="7.33" fill="#252b43" />
          <rect x="759.67" y="264.67" width="7.33" height="7.33" fill="#252b43" />
          <rect x="723" y="264.67" width="14.67" height="7.33" fill="#3e8948" />
          <rect x="741.33" y="264.67" width="3.67" height="3.67" fill="#f77622" />
          <rect x="737.67" y="316" width="7.33" height="3.67" fill="#252b43" />
          <rect x="704.67" y="323.33" width="3.67" height="25.67" fill="#252b43" />
          <rect x="708.33" y="323.33" width="3.67" height="25.67" fill="#252b43" />
          <rect x="756" y="323.33" width="3.67" height="25.67" fill="#252b43" />
          <rect x="752.33" y="323.33" width="3.67" height="25.67" fill="#252b43" />
          <polygon points="745 305 745 308.67 741.33 308.67 741.33 312.33 737.67 312.33 737.67 316 734 316 734 319.67 730.33 319.67 730.33 323.33 726.67 323.33 726.67 327 723 327 723 330.67 719.33 330.67 719.33 334.33 715.67 334.33 715.67 338 712 338 712 341.67 708.33 341.67 708.33 345.33 715.67 345.33 715.67 341.67 719.33 341.67 719.33 338 723 338 723 334.33 726.67 334.33 726.67 330.67 730.33 330.67 730.33 327 734 327 734 323.33 737.67 323.33 737.67 319.67 741.33 319.67 741.33 316 745 316 745 312.33 748.67 312.33 748.67 308.67 752.33 308.67 752.33 305 745 305" fill="#252b43" />
          <polygon points="752.33 341.67 752.33 338 748.67 338 748.67 334.33 745 334.33 745 330.67 741.33 330.67 741.33 327 737.67 327 737.67 323.33 734 323.33 734 319.67 730.33 319.67 730.33 316 726.67 316 726.67 312.33 723 312.33 723 308.67 719.33 308.67 719.33 305 712 305 712 308.67 715.67 308.67 715.67 312.33 719.33 312.33 719.33 316 723 316 723 319.67 726.67 319.67 726.67 323.33 730.33 323.33 730.33 327 734 327 734 330.67 737.67 330.67 737.67 334.33 741.33 334.33 741.33 338 745 338 745 341.67 748.67 341.67 748.67 345.33 756 345.33 756 341.67 752.33 341.67" fill="#252b43" />
          <rect x="741.33" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
          <rect x="723" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
          <rect x="730.33" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
          <rect x="756" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
          <rect x="712" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
          <rect x="697.33" y="275.67" width="3.67" height="22" fill="#c0ccdc" />
        </g>
        <g>
          <g>
            <rect x="647" y="393" width="68" height="4" fill="#e4a570" />
            <rect x="647" y="397" width="68" height="4" fill="#b9704f" />
            <rect x="715" y="393" width="4" height="4" fill="#ecf0f1" />
            <rect x="647" y="381" width="68" height="4" fill="#e4a570" />
            <rect x="647" y="385" width="68" height="4" fill="#b9704f" />
            <rect x="715" y="381" width="4" height="4" fill="#ecf0f1" />
            <g>
              <polygon points="715 433 715 457 711 457 711 461 703 461 703 465 663 465 663 461 655 461 655 457 651 457 651 433 667 433 667 437 699 437 699 433 715 433" fill="#e43d44" />
              <polygon points="703 417 703 413 663 413 663 417 655 417 655 433 663 433 663 437 707 437 707 433 711 433 711 417 703 417" fill="#ecf0f1" />
              <rect x="667" y="441" width="4" height="24" fill="#f6757a" />
              <g>
                <rect x="699" y="441" width="4" height="20" fill="#a32732" />
                <polygon points="711 433 711 437 707 437 707 461 711 461 711 457 715 457 715 433 711 433" fill="#a32732" />
              </g>
            </g>
          </g>
          <g>
            <rect x="715" y="397" width="4" height="4" fill="#8b9bb5" />
            <rect x="715" y="385" width="4" height="4" fill="#8b9bb5" />
            <rect x="711" y="417" width="4" height="16" fill="#8b9bb5" />
            <rect x="711" y="457" width="4" height="4" fill="#8b9bb5" />
            <path d="M703,433v4H663v4h16v24H667v-4h-8V437h4v-4h-8v24h-4v4h4v4h8v4h40v-4h8v-4h-4V437h4v-4Zm0,28h-4v4H683V441h20Z" fill="#8b9bb5" />
            <rect x="651" y="417" width="4" height="16" fill="#8b9bb5" />
            <rect x="655" y="413" width="8" height="4" fill="#8b9bb5" />
            <rect x="703" y="413" width="8" height="4" fill="#8b9bb5" />
            <rect x="663" y="409" width="40" height="4" fill="#8b9bb5" />
          </g>
          <rect x="675" y="421" width="16" height="4" fill="#c0ccdc" />
          <rect x="671" y="425" width="24" height="4" fill="#c0ccdc" />
        </g>
        <g>
          <rect x="604" y="303" width="8" height="92" fill="#c0ccdc" />
          <rect x="612" y="303" width="8" height="92" fill="#ecf0f1" />
          <rect x="604" y="395" width="16" height="4" fill="#67386c" />
          <rect x="604" y="299" width="16" height="4" fill="#67386c" />
          <rect x="520" y="299" width="4" height="100" fill="#a32732" />
          <rect x="600" y="299" width="4" height="100" fill="#a32732" />
          <rect x="620" y="299" width="4" height="100" fill="#a32732" />
          <g>
            <rect x="524" y="299" width="76" height="100" fill="#e43d44" />
            <rect x="516" y="299" width="4" height="100" fill="#e43d44" />
          </g>
          <g>
            <rect x="576" y="379" width="4" height="4" fill="#ecf0f1" />
            <rect x="552" y="379" width="20" height="4" fill="#ecf0f1" />
            <rect x="544" y="379" width="4" height="4" fill="#ecf0f1" />
            <polygon points="536 359 540 359 540 355 536 355 536 351 540 351 540 347 532 347 532 371 540 371 540 367 536 367 536 359" fill="#ecf0f1" />
            <rect x="588" y="359" width="4" height="12" fill="#ecf0f1" />
            <rect x="540" y="359" width="4" height="8" fill="#ecf0f1" />
            <polygon points="584 355 584 347 580 347 580 371 584 371 584 359 588 359 588 355 584 355" fill="#ecf0f1" />
            <rect x="540" y="351" width="4" height="4" fill="#ecf0f1" />
            <rect x="588" y="347" width="4" height="8" fill="#ecf0f1" />
            <path d="M564,371h12V347H564Zm4-20h4v16h-4Z" fill="#ecf0f1" />
            <path d="M560,347H548v24h12Zm-4,20h-4V351h4Z" fill="#ecf0f1" />
            <polygon points="580 327 584 327 584 323 580 323 580 315 576 315 576 339 580 339 580 327" fill="#ecf0f1" />
            <path d="M572,315H560v24h12Zm-4,20h-4V319h4Z" fill="#ecf0f1" />
            <path d="M556,315H544v24h12Zm-4,20h-4V319h4Z" fill="#ecf0f1" />
            <polygon points="540 335 536 335 536 319 540 319 540 315 532 315 532 339 540 339 540 335" fill="#ecf0f1" />
            <rect x="584" y="327" width="4" height="12" fill="#ecf0f1" />
            <rect x="584" y="315" width="4" height="8" fill="#ecf0f1" />
          </g>
        </g>
        <g>
          <rect x="427" y="370" width="8" height="8" fill="#ecf0f1" />
          <path d="M467,354h-4v-8h-4v-8h-4v-8h-4v-8h-4v-4h-4v4h-4v8h-4v8h-4v8h-4v8h-4v8h-4v16h4v8h4v4h4v4h8v4h12v-4h8v-4h4v-4h4v-8h4V362h-4Zm-40,16h8v8h-8Z" fill="#2ee8f7" />
        </g>
        <g>
          <polygon points="882 61 882 57 878 57 878 17 874 17 874 13 822 13 822 17 830 17 830 21 838 21 838 25 846 25 846 29 854 29 854 33 862 33 862 93 878 93 878 89 882 89 882 85 886 85 886 61 882 61" fill="#f77622" />
          <polygon points="854 33 854 29 846 29 846 25 838 25 838 21 830 21 830 17 822 17 822 13 814 13 814 93 822 93 822 97 830 97 830 101 838 101 838 105 846 105 846 109 854 109 854 113 862 113 862 33 854 33" fill="#fdae35" />
        </g>
        <g>
          <polygon points="365 614 365 606 361 606 361 602 357 602 357 598 353 598 353 594 337 594 337 598 333 598 333 602 329 602 329 598 325 598 325 594 309 594 309 598 305 598 305 602 301 602 301 606 297 606 297 614 293 614 293 630 297 630 297 638 301 638 301 642 305 642 305 646 309 646 309 650 317 650 317 654 345 654 345 650 353 650 353 646 357 646 357 642 361 642 361 638 365 638 365 630 369 630 369 614 365 614" fill="#8baf32" />
          <polygon points="329 578 329 574 325 574 325 570 321 570 321 566 317 566 317 562 309 562 309 558 305 558 305 578 309 578 309 586 313 586 313 590 317 590 317 594 325 594 325 598 333 598 333 578 329 578" fill="#265c42" />
          <rect x="341" y="602" width="4" height="4" fill="#fee861" />
        </g>
        <g>
          <path d="M739,684h92V600H739Z" fill="#ecf0f1" />
          <polygon points="815 636 815 632 755 632 755 636 751 636 751 668 755 668 755 672 815 672 815 668 819 668 819 636 815 636" fill="#3a4467" />
          <polygon points="799 636 799 640 795 640 795 644 791 644 791 648 787 648 787 652 783 652 783 656 779 656 779 660 775 660 775 664 771 664 771 668 779 668 779 664 783 664 783 660 787 660 787 656 791 656 791 652 795 652 795 648 799 648 799 644 803 644 803 640 807 640 807 636 799 636" fill="#8b9bb5" />
          <rect x="747" y="676" width="76" height="4" fill="#8b9bb5" />
          <rect x="743" y="620" width="4" height="56" fill="#8b9bb5" />
          <rect x="823" y="620" width="4" height="56" fill="#8b9bb5" />
          <rect x="747" y="616" width="76" height="4" fill="#8b9bb5" />
          <rect x="711" y="600" width="28" height="84" fill="#8b9bb5" />
          <g>
            <rect x="739" y="600" width="92" height="12" fill="#3a4467" />
            <rect x="811" y="604" width="4" height="4" fill="#8b9bb5" />
            <rect x="803" y="604" width="4" height="4" fill="#8b9bb5" />
            <rect x="795" y="604" width="4" height="4" fill="#8b9bb5" />
            <rect x="763" y="604" width="4" height="4" fill="#e43d44" />
            <rect x="755" y="604" width="4" height="4" fill="#e43d44" />
          </g>
          <rect x="739" y="596" width="92" height="4" fill="#252b43" />
          <rect x="711" y="596" width="28" height="4" fill="#252b43" />
          <rect x="759" y="624" width="52" height="4" fill="#3a4467" />
        </g>
        <g>
          <g>
            <rect x="599" y="565" width="36" height="12" fill="#8b9bb5" />
            <rect x="559" y="477" width="116" height="88" fill="#ecf0f1" />
            <rect x="559" y="481" width="116" height="72" fill="#0099dc" />
            <rect x="599" y="573" width="36" height="4" fill="#ecf0f1" />
            <g>
              <rect x="571" y="493" width="48" height="32" fill="#c0ccdc" />
              <rect x="571" y="493" width="48" height="4" fill="#5b6986" />
              <rect x="615" y="493" width="4" height="4" fill="#c0ccdc" />
              <rect x="579" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="587" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="595" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="603" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="579" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="587" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="595" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="579" y="501" width="48" height="36" fill="#ecf0f1" />
              <rect x="579" y="501" width="48" height="4" fill="#0099dc" />
              <rect x="623" y="501" width="4" height="4" fill="#c0ccdc" />
              <rect x="587" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="595" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="603" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="611" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="587" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="595" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="587" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="595" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="603" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="611" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="603" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="611" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="611" y="509" width="52" height="20" fill="#5b6986" />
              <rect x="615" y="513" width="44" height="12" fill="#ecf0f1" />
              <rect x="619" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="627" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="635" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="643" y="517" width="4" height="4" fill="#5b6986" />
            </g>
            <rect x="655" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="571" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="655" y="493" width="4" height="4" fill="#c0ccdc" />
            <rect x="647" y="493" width="4" height="4" fill="#c0ccdc" />
            <rect x="579" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="615" y="557" width="4" height="4" fill="#8b9bb5" />
          </g>
          <g>
            <rect x="723" y="565" width="28" height="12" fill="#c0ccdc" />
            <rect x="751" y="565" width="4" height="12" fill="#8b9bb5" />
            <rect x="683" y="477" width="108" height="88" fill="#ecf0f1" />
            <rect x="715" y="573" width="28" height="4" fill="#ecf0f1" />
            <rect x="743" y="573" width="12" height="4" fill="#8b9bb5" />
            <rect x="735" y="557" width="4" height="4" fill="#8b9bb5" />
            <rect x="683" y="481" width="108" height="72" fill="#0099dc" />
            <g>
              <rect x="691" y="493" width="48" height="32" fill="#c0ccdc" />
              <rect x="691" y="493" width="48" height="4" fill="#5b6986" />
              <rect x="735" y="493" width="4" height="4" fill="#c0ccdc" />
              <rect x="699" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="707" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="715" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="723" y="505" width="4" height="4" fill="#e09e34" />
              <rect x="699" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="707" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="715" y="513" width="4" height="4" fill="#e09e34" />
              <rect x="699" y="501" width="48" height="36" fill="#ecf0f1" />
              <rect x="699" y="501" width="48" height="4" fill="#0099dc" />
              <rect x="743" y="501" width="4" height="4" fill="#c0ccdc" />
              <rect x="707" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="715" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="723" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="731" y="513" width="4" height="4" fill="#fdae35" />
              <rect x="707" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="715" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="707" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="715" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="723" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="731" y="529" width="4" height="4" fill="#fdae35" />
              <rect x="723" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="731" y="521" width="4" height="4" fill="#fdae35" />
              <rect x="731" y="509" width="52" height="20" fill="#5b6986" />
              <rect x="735" y="513" width="44" height="12" fill="#ecf0f1" />
              <rect x="739" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="747" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="755" y="517" width="4" height="4" fill="#5b6986" />
              <rect x="763" y="517" width="4" height="4" fill="#5b6986" />
            </g>
            <rect x="775" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="691" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="775" y="493" width="4" height="4" fill="#c0ccdc" />
            <rect x="767" y="493" width="4" height="4" fill="#c0ccdc" />
            <rect x="699" y="541" width="4" height="4" fill="#c0ccdc" />
            <rect x="791" y="477" width="4" height="88" fill="#8b9bb5" />
          </g>
        </g>
        <g>
          <polygon points="752 346 748 346 748 342 744 342 744 338 736 338 736 334 712 334 712 338 704 338 704 342 700 342 700 346 696 346 696 350 692 350 692 358 688 358 688 382 684 382 684 378 680 378 680 374 668 374 668 378 664 378 664 394 668 394 668 402 672 402 672 406 676 406 676 410 680 410 680 414 684 414 684 418 688 418 688 438 752 438 752 394 756 394 756 354 752 354 752 346" fill="#0099dc" />
          <rect x="748" y="422" width="4" height="4" fill="#104e89" />
          <rect x="736" y="434" width="4" height="4" fill="#104e89" />
          <rect x="712" y="434" width="4" height="4" fill="#104e89" />
          <rect x="688" y="434" width="4" height="4" fill="#104e89" />
          <rect x="740" y="430" width="4" height="4" fill="#104e89" />
          <rect x="732" y="430" width="4" height="4" fill="#104e89" />
          <rect x="716" y="430" width="4" height="4" fill="#104e89" />
          <rect x="708" y="430" width="4" height="4" fill="#104e89" />
          <rect x="692" y="430" width="4" height="4" fill="#104e89" />
          <rect x="744" y="426" width="4" height="4" fill="#104e89" />
          <rect x="728" y="426" width="4" height="4" fill="#104e89" />
          <rect x="720" y="426" width="4" height="4" fill="#104e89" />
          <rect x="704" y="426" width="4" height="4" fill="#104e89" />
          <rect x="696" y="426" width="4" height="4" fill="#104e89" />
          <rect x="724" y="422" width="4" height="4" fill="#104e89" />
          <rect x="700" y="422" width="4" height="4" fill="#104e89" />
          <rect x="744" y="418" width="4" height="4" fill="#104e89" />
          <rect x="728" y="418" width="4" height="4" fill="#104e89" />
          <rect x="720" y="418" width="4" height="4" fill="#104e89" />
          <rect x="704" y="418" width="4" height="4" fill="#104e89" />
          <rect x="696" y="418" width="4" height="4" fill="#104e89" />
          <rect x="740" y="414" width="4" height="4" fill="#104e89" />
          <rect x="732" y="414" width="4" height="4" fill="#104e89" />
          <rect x="716" y="414" width="4" height="4" fill="#104e89" />
          <rect x="708" y="414" width="4" height="4" fill="#104e89" />
          <rect x="692" y="414" width="4" height="4" fill="#104e89" />
          <rect x="684" y="414" width="4" height="4" fill="#104e89" />
          <rect x="736" y="410" width="4" height="4" fill="#104e89" />
          <rect x="712" y="410" width="4" height="4" fill="#104e89" />
          <rect x="688" y="410" width="4" height="4" fill="#104e89" />
          <rect x="740" y="406" width="4" height="4" fill="#104e89" />
          <rect x="732" y="406" width="4" height="4" fill="#104e89" />
          <rect x="716" y="406" width="4" height="4" fill="#104e89" />
          <rect x="708" y="406" width="4" height="4" fill="#104e89" />
          <rect x="692" y="406" width="4" height="4" fill="#104e89" />
          <rect x="684" y="406" width="4" height="4" fill="#104e89" />
          <rect x="744" y="402" width="4" height="4" fill="#104e89" />
          <rect x="728" y="402" width="4" height="4" fill="#104e89" />
          <rect x="720" y="402" width="4" height="4" fill="#104e89" />
          <rect x="704" y="402" width="4" height="4" fill="#104e89" />
          <rect x="696" y="402" width="4" height="4" fill="#104e89" />
          <rect x="680" y="402" width="4" height="4" fill="#104e89" />
          <rect x="672" y="402" width="4" height="4" fill="#104e89" />
          <rect x="748" y="398" width="4" height="4" fill="#104e89" />
          <rect x="724" y="398" width="4" height="4" fill="#104e89" />
          <rect x="700" y="398" width="4" height="4" fill="#104e89" />
          <rect x="676" y="398" width="4" height="4" fill="#104e89" />
          <rect x="744" y="394" width="4" height="4" fill="#104e89" />
          <rect x="728" y="394" width="4" height="4" fill="#104e89" />
          <rect x="720" y="394" width="4" height="4" fill="#104e89" />
          <rect x="704" y="394" width="4" height="4" fill="#104e89" />
          <rect x="696" y="394" width="4" height="4" fill="#104e89" />
          <rect x="680" y="394" width="4" height="4" fill="#104e89" />
          <rect x="672" y="394" width="4" height="4" fill="#104e89" />
          <rect x="740" y="390" width="4" height="4" fill="#104e89" />
          <rect x="732" y="390" width="4" height="4" fill="#104e89" />
          <rect x="716" y="390" width="4" height="4" fill="#104e89" />
          <rect x="708" y="390" width="4" height="4" fill="#104e89" />
          <rect x="692" y="390" width="4" height="4" fill="#104e89" />
          <rect x="684" y="390" width="4" height="4" fill="#104e89" />
          <rect x="668" y="390" width="4" height="4" fill="#104e89" />
          <rect x="736" y="386" width="4" height="4" fill="#104e89" />
          <rect x="712" y="386" width="4" height="4" fill="#104e89" />
          <rect x="688" y="386" width="4" height="4" fill="#104e89" />
          <rect x="664" y="386" width="4" height="4" fill="#104e89" />
          <rect x="740" y="382" width="4" height="4" fill="#104e89" />
          <rect x="732" y="382" width="4" height="4" fill="#104e89" />
          <rect x="716" y="382" width="4" height="4" fill="#104e89" />
          <rect x="708" y="382" width="4" height="4" fill="#104e89" />
          <rect x="692" y="382" width="4" height="4" fill="#104e89" />
          <rect x="684" y="382" width="4" height="4" fill="#104e89" />
          <rect x="668" y="382" width="4" height="4" fill="#104e89" />
          <rect x="752" y="378" width="4" height="4" fill="#104e89" />
          <rect x="744" y="378" width="4" height="4" fill="#104e89" />
          <rect x="728" y="378" width="4" height="4" fill="#104e89" />
          <rect x="720" y="378" width="4" height="4" fill="#104e89" />
          <rect x="704" y="378" width="4" height="4" fill="#104e89" />
          <rect x="696" y="378" width="4" height="4" fill="#104e89" />
          <rect x="680" y="378" width="4" height="4" fill="#104e89" />
          <rect x="672" y="378" width="4" height="4" fill="#104e89" />
          <rect x="748" y="374" width="4" height="4" fill="#104e89" />
          <rect x="724" y="374" width="4" height="4" fill="#104e89" />
          <rect x="700" y="374" width="4" height="4" fill="#104e89" />
          <rect x="676" y="374" width="4" height="4" fill="#104e89" />
          <rect x="752" y="370" width="4" height="4" fill="#104e89" />
          <rect x="744" y="370" width="4" height="4" fill="#104e89" />
          <rect x="728" y="370" width="4" height="4" fill="#104e89" />
          <rect x="720" y="370" width="4" height="4" fill="#104e89" />
          <rect x="704" y="370" width="4" height="4" fill="#104e89" />
          <rect x="696" y="370" width="4" height="4" fill="#104e89" />
          <rect x="740" y="366" width="4" height="4" fill="#104e89" />
          <rect x="732" y="366" width="4" height="4" fill="#104e89" />
          <rect x="716" y="366" width="4" height="4" fill="#104e89" />
          <rect x="708" y="366" width="4" height="4" fill="#104e89" />
          <rect x="692" y="366" width="4" height="4" fill="#104e89" />
          <rect x="736" y="362" width="4" height="4" fill="#104e89" />
          <rect x="712" y="362" width="4" height="4" fill="#104e89" />
          <rect x="688" y="362" width="4" height="4" fill="#104e89" />
          <rect x="740" y="358" width="4" height="4" fill="#104e89" />
          <rect x="732" y="358" width="4" height="4" fill="#104e89" />
          <rect x="716" y="358" width="4" height="4" fill="#104e89" />
          <rect x="708" y="358" width="4" height="4" fill="#104e89" />
          <rect x="692" y="358" width="4" height="4" fill="#104e89" />
          <rect x="752" y="354" width="4" height="4" fill="#104e89" />
          <rect x="744" y="354" width="4" height="4" fill="#104e89" />
          <rect x="728" y="354" width="4" height="4" fill="#104e89" />
          <rect x="720" y="354" width="4" height="4" fill="#104e89" />
          <rect x="704" y="354" width="4" height="4" fill="#104e89" />
          <rect x="696" y="354" width="4" height="4" fill="#104e89" />
          <rect x="748" y="350" width="4" height="4" fill="#104e89" />
          <rect x="724" y="350" width="4" height="4" fill="#104e89" />
          <rect x="700" y="350" width="4" height="4" fill="#104e89" />
          <rect x="744" y="346" width="4" height="4" fill="#104e89" />
          <rect x="728" y="346" width="4" height="4" fill="#104e89" />
          <rect x="720" y="346" width="4" height="4" fill="#104e89" />
          <rect x="704" y="346" width="4" height="4" fill="#104e89" />
          <rect x="696" y="346" width="4" height="4" fill="#104e89" />
          <rect x="740" y="342" width="4" height="4" fill="#104e89" />
          <rect x="732" y="342" width="4" height="4" fill="#104e89" />
          <rect x="716" y="342" width="4" height="4" fill="#104e89" />
          <rect x="708" y="342" width="4" height="4" fill="#104e89" />
          <rect x="736" y="338" width="4" height="4" fill="#104e89" />
          <rect x="712" y="338" width="4" height="4" fill="#104e89" />
          <rect x="732" y="334" width="4" height="4" fill="#104e89" />
          <rect x="716" y="334" width="4" height="4" fill="#104e89" />
        </g>
        <g>
          <g>
            <rect x="144" y="619" width="20" height="4" fill="#5b6986" />
            <rect x="80" y="619" width="60" height="4" fill="#5b6986" />
            <rect x="36" y="619" width="40" height="4" fill="#5b6986" />
            <rect x="112" y="587" width="52" height="4" fill="#5b6986" />
            <rect x="48" y="587" width="60" height="4" fill="#5b6986" />
            <rect x="36" y="587" width="8" height="4" fill="#5b6986" />
            <rect x="152" y="555" width="12" height="4" fill="#5b6986" />
            <rect x="88" y="555" width="60" height="4" fill="#5b6986" />
            <rect x="36" y="555" width="48" height="4" fill="#5b6986" />
            <rect x="136" y="523" width="28" height="4" fill="#5b6986" />
            <rect x="68" y="523" width="64" height="4" fill="#5b6986" />
            <rect x="36" y="523" width="28" height="4" fill="#5b6986" />
          </g>
          <g>
            <polygon points="148 615 148 595 144 595 144 619 164 619 164 615 148 615" fill="#733d3b" />
            <polygon points="84 615 84 595 80 595 80 619 136 619 136 615 84 615" fill="#733d3b" />
            <rect x="36" y="615" width="36" height="4" fill="#733d3b" />
            <polygon points="116 583 116 563 112 563 112 587 164 587 164 583 116 583" fill="#733d3b" />
            <polygon points="108 587 108 583 52 583 52 563 48 563 48 587 108 587" fill="#733d3b" />
            <rect x="36" y="583" width="4" height="4" fill="#733d3b" />
            <polygon points="156 551 156 531 152 531 152 555 164 555 164 551 156 551" fill="#733d3b" />
            <polygon points="92 551 92 531 88 531 88 555 144 555 144 551 92 551" fill="#733d3b" />
            <rect x="36" y="551" width="44" height="4" fill="#733d3b" />
            <polygon points="140 519 140 499 136 499 136 523 164 523 164 519 140 519" fill="#733d3b" />
            <polygon points="128 523 128 519 72 519 72 499 68 499 68 523 128 523" fill="#733d3b" />
            <rect x="36" y="519" width="24" height="4" fill="#733d3b" />
          </g>
          <g>
            <rect x="148" y="595" width="16" height="20" fill="#bf4830" />
            <rect x="84" y="595" width="52" height="20" fill="#bf4830" />
            <rect x="36" y="595" width="36" height="20" fill="#bf4830" />
            <rect x="52" y="563" width="52" height="20" fill="#bf4830" />
            <rect x="36" y="563" width="4" height="20" fill="#bf4830" />
            <rect x="116" y="563" width="48" height="20" fill="#bf4830" />
            <rect x="36" y="531" width="44" height="20" fill="#bf4830" />
            <rect x="156" y="531" width="8" height="20" fill="#bf4830" />
            <rect x="92" y="531" width="52" height="20" fill="#bf4830" />
            <rect x="72" y="499" width="56" height="20" fill="#bf4830" />
            <rect x="36" y="499" width="24" height="20" fill="#bf4830" />
            <rect x="140" y="499" width="24" height="20" fill="#bf4830" />
          </g>
          <g>
            <rect x="140" y="591" width="4" height="32" fill="#8b9bb5" />
            <rect x="76" y="591" width="4" height="32" fill="#8b9bb5" />
            <rect x="108" y="559" width="4" height="32" fill="#8b9bb5" />
            <rect x="44" y="559" width="4" height="32" fill="#8b9bb5" />
            <rect x="148" y="527" width="4" height="32" fill="#8b9bb5" />
            <rect x="84" y="527" width="4" height="32" fill="#8b9bb5" />
            <rect x="132" y="495" width="4" height="32" fill="#8b9bb5" />
            <rect x="64" y="495" width="4" height="32" fill="#8b9bb5" />
          </g>
          <g>
            <rect x="144" y="591" width="20" height="4" fill="#c28569" />
            <polygon points="80 591 80 595 136 595 136 619 140 619 140 591 80 591" fill="#c28569" />
            <polygon points="36 591 36 595 72 595 72 619 76 619 76 591 36 591" fill="#c28569" />
            <polygon points="40 587 44 587 44 559 36 559 36 563 40 563 40 587" fill="#c28569" />
            <rect x="112" y="559" width="52" height="4" fill="#c28569" />
            <polygon points="104 563 104 583 108 583 108 559 48 559 48 563 104 563" fill="#c28569" />
            <polygon points="144 555 148 555 148 527 88 527 88 531 144 531 144 555" fill="#c28569" />
            <rect x="152" y="527" width="12" height="4" fill="#c28569" />
            <polygon points="80 531 80 555 84 555 84 527 36 527 36 531 80 531" fill="#c28569" />
            <rect x="136" y="495" width="28" height="4" fill="#c28569" />
            <polygon points="128 499 128 523 132 523 132 495 68 495 68 499 128 499" fill="#c28569" />
            <polygon points="60 499 60 523 64 523 64 495 36 495 36 499 60 499" fill="#c28569" />
          </g>
        </g>
        <g>
          <polygon points="508 652 508 668 512 668 512 664 516 664 516 660 520 660 520 656 524 656 524 652 508 652" fill="#e4a570" />
          <rect x="492" y="636" width="20" height="4" fill="#67386c" />
          <rect x="472" y="636" width="16" height="4" fill="#67386c" />
          <rect x="504" y="624" width="8" height="4" fill="#67386c" />
          <rect x="472" y="624" width="28" height="4" fill="#67386c" />
          <rect x="472" y="612" width="40" height="4" fill="#67386c" />
          <path d="M484,584v16h-4v4h-4v-4h-4V588h4v12h4V584H464v84h44V652h16V584Zm-12,52h16v4H472Zm0-12h28v4H472Zm0-12h40v4H472Zm40,16h-8v-4h8Zm0,12H492v-4h20Z" fill="#ebd4ab" />
          <rect x="476" y="600" width="4" height="4" fill="#8b9bb5" />
          <rect x="472" y="588" width="4" height="12" fill="#8b9bb5" />
          <rect x="480" y="580" width="4" height="20" fill="#8b9bb5" />
          <rect x="468" y="580" width="4" height="4" fill="#8b9bb5" />
          <rect x="472" y="576" width="8" height="4" fill="#8b9bb5" />
        </g>
        <g>
          <path d="M407,584V528H335v56Z" fill="#c0ccdc" />
          <path d="M407,520V484H335v36Z" fill="#c0ccdc" />
          <rect x="339" y="584" width="68" height="8" fill="#252b43" />
          <rect x="347" y="536" width="4" height="20" fill="#252b43" />
          <rect x="335" y="520" width="76" height="4" fill="#8b9bb5" />
          <rect x="347" y="500" width="4" height="12" fill="#252b43" />
          <polygon points="335 524 335 528 407 528 407 584 411 584 411 524 335 524" fill="#ecf0f1" />
          <polygon points="407 484 407 480 339 480 339 484 407 484 407 520 411 520 411 484 407 484" fill="#ecf0f1" />
        </g>
        <g>
          <rect x="310" y="334" width="68" height="76" fill="#0099dc" />
          <path d="M370,350h-4v-4h-4v-4h-8v-4H334v4h-8v4h-4v4h-4v8h-4v20h4v8h4v4h4v4h8v4h20v-4h8v-4h4v-4h4v-8h4V358h-4Zm-12-4v4h-4v-4Zm0,12h-4v4h-4v4h-4v4H330v-4h16v-4h4v-4h4v-4h4Zm-12-16v4h-4v-4Zm-12,4v4h-4v-4Zm-12,8h4v4h-4Zm-4,12h4v4h-4Zm4,12h4v4h-4Zm8,12v-4h4v4Zm12,4v-4h4v4Zm12-4v-4h4v4Zm12-8h-4v-4h4Zm4-12h-4v-4h4Zm-8-12v-4h4v4Z" fill="#ecf0f1" />
          <rect x="342" y="390" width="4" height="4" fill="#252b43" />
          <rect x="354" y="386" width="4" height="4" fill="#252b43" />
          <rect x="330" y="386" width="4" height="4" fill="#252b43" />
          <rect x="362" y="378" width="4" height="4" fill="#252b43" />
          <rect x="322" y="378" width="4" height="4" fill="#252b43" />
          <rect x="366" y="366" width="4" height="4" fill="#252b43" />
          <rect x="330" y="366" width="16" height="4" fill="#252b43" />
          <rect x="318" y="366" width="4" height="4" fill="#252b43" />
          <rect x="346" y="362" width="4" height="4" fill="#252b43" />
          <rect x="350" y="358" width="4" height="4" fill="#252b43" />
          <rect x="362" y="354" width="4" height="4" fill="#252b43" />
          <rect x="354" y="354" width="4" height="4" fill="#252b43" />
          <rect x="322" y="354" width="4" height="4" fill="#252b43" />
          <rect x="354" y="346" width="4" height="4" fill="#252b43" />
          <rect x="330" y="346" width="4" height="4" fill="#252b43" />
          <rect x="342" y="342" width="4" height="4" fill="#252b43" />
          <rect x="314" y="330" width="60" height="4" fill="#0099dc" />
          <rect x="378" y="338" width="4" height="68" fill="#0099dc" />
          <rect x="306" y="338" width="4" height="68" fill="#0099dc" />
          <rect x="310" y="406" width="68" height="4" fill="#104e89" />
        </g>
        <g>
          <polygon points="419 338 419 334 415 334 415 370 403 370 403 374 399 374 399 378 403 378 403 382 415 382 415 378 419 378 419 342 427 342 427 338 419 338" fill="#3a4467" />
          <rect x="427" y="342" width="8" height="4" fill="#3a4467" />
          <rect x="435" y="346" width="4" height="4" fill="#3a4467" />
        </g>
        <g>
          <polygon points="136.75 119.81 136.75 109.11 133.19 109.11 133.19 105.54 129.62 105.54 129.62 101.98 126.05 101.98 126.05 98.41 122.49 98.41 122.49 94.84 118.92 94.84 118.92 91.28 108.22 91.28 108.22 87.71 86.83 87.71 86.83 91.28 76.13 91.28 76.13 94.84 72.56 94.84 72.56 98.41 68.99 98.41 68.99 101.98 65.43 101.98 65.43 105.54 61.86 105.54 61.86 109.11 58.3 109.11 58.3 119.81 54.73 119.81 54.73 141.21 58.3 141.21 58.3 151.9 61.86 151.9 61.86 155.47 65.43 155.47 65.43 159.04 68.99 159.04 68.99 162.6 72.56 162.6 72.56 166.17 76.13 166.17 76.13 169.74 86.83 169.74 86.83 173.3 108.22 173.3 108.22 169.74 118.92 169.74 118.92 166.17 122.49 166.17 122.49 162.6 126.05 162.6 126.05 159.04 129.62 159.04 129.62 155.47 133.19 155.47 133.19 151.9 136.75 151.9 136.75 141.21 140.32 141.21 140.32 119.81 136.75 119.81" fill="#0099dc" />
          <g>
            <rect x="72.56" y="151.9" width="3.57" height="3.57" fill="#2ee8f7" />
            <polygon points="129.62 144.77 126.05 144.77 126.05 148.34 122.49 148.34 122.49 155.47 126.05 155.47 126.05 159.04 129.62 159.04 129.62 155.47 133.19 155.47 133.19 151.9 136.75 151.9 136.75 148.34 129.62 148.34 129.62 144.77" fill="#2ee8f7" />
            <polygon points="136.75 116.24 136.75 109.11 133.19 109.11 133.19 105.54 126.05 105.54 126.05 109.11 118.92 109.11 118.92 105.54 101.09 105.54 101.09 109.11 93.96 109.11 93.96 112.67 90.39 112.67 90.39 116.24 93.96 116.24 93.96 119.81 97.52 119.81 97.52 123.37 101.09 123.37 101.09 126.94 90.39 126.94 90.39 134.07 93.96 134.07 93.96 144.77 97.52 144.77 97.52 148.34 93.96 148.34 93.96 155.47 101.09 155.47 101.09 151.9 104.66 151.9 104.66 144.77 108.22 144.77 108.22 130.51 104.66 130.51 104.66 123.37 108.22 123.37 108.22 126.94 115.36 126.94 115.36 130.51 122.49 130.51 122.49 134.07 126.05 134.07 126.05 126.94 129.62 126.94 129.62 130.51 133.19 130.51 133.19 137.64 136.75 137.64 136.75 126.94 133.19 126.94 133.19 116.24 136.75 116.24" fill="#2ee8f7" />
            <rect x="90.39" y="105.54" width="3.57" height="3.57" fill="#2ee8f7" />
            <polygon points="76.13 101.98 65.43 101.98 65.43 105.54 61.86 105.54 61.86 109.11 58.3 109.11 58.3 116.24 61.86 116.24 61.86 123.37 65.43 123.37 65.43 126.94 58.3 126.94 58.3 137.64 61.86 137.64 61.86 144.77 65.43 144.77 65.43 148.34 68.99 148.34 68.99 151.9 72.56 151.9 72.56 144.77 68.99 144.77 68.99 141.21 72.56 141.21 72.56 134.07 76.13 134.07 76.13 130.51 72.56 130.51 72.56 126.94 68.99 126.94 68.99 119.81 72.56 119.81 72.56 123.37 76.13 123.37 76.13 119.81 79.69 119.81 79.69 116.24 83.26 116.24 83.26 105.54 76.13 105.54 76.13 101.98" fill="#2ee8f7" />
          </g>
        </g>
        <g>
          <rect x="928.63" y="408" width="3.72" height="3.72" transform="translate(111.86 1012.89) rotate(-60.15)" fill="#a32732" />
          <rect x="883.41" y="382.05" width="3.72" height="3.72" transform="translate(111.65 960.64) rotate(-60.15)" fill="#a32732" />
          <rect x="927.25" y="402.92" width="3.72" height="3.72" transform="translate(115.57 1009.15) rotate(-60.15)" fill="#a32732" />
          <rect x="888.49" y="380.68" width="3.72" height="3.72" transform="translate(115.4 964.36) rotate(-60.15)" fill="#a32732" />
          <rect x="925.88" y="397.84" width="3.72" height="3.72" transform="translate(119.29 1005.4) rotate(-60.15)" fill="#a32732" />
          <rect x="893.58" y="379.3" width="3.72" height="3.72" transform="translate(119.15 968.07) rotate(-60.15)" fill="#a32732" />
          <rect x="923.57" y="391.14" width="7.45" height="3.72" transform="translate(124.88 1001.64) rotate(-60.15)" fill="#a32732" />
          <rect x="897.73" y="376.31" width="7.45" height="3.72" transform="translate(124.76 971.78) rotate(-60.15)" fill="#a32732" />
          <polygon points="933.09 379.15 936.8 372.69 933.57 370.84 929.86 377.3 923.4 373.59 941.94 341.29 938.71 339.44 920.17 371.74 913.71 368.03 917.42 361.57 914.19 359.72 910.48 366.18 887.87 353.2 886.02 356.43 908.63 369.41 904.92 375.87 908.15 377.72 911.86 371.26 918.32 374.97 899.78 407.27 903.01 409.12 921.55 376.82 928.01 380.53 924.3 386.99 927.53 388.84 931.24 382.38 953.85 395.36 955.7 392.13 933.09 379.15" fill="#a32732" />
          <rect x="936.54" y="368.53" width="7.45" height="3.72" transform="translate(151 1001.54) rotate(-60.15)" fill="#a32732" />
          <rect x="910.7" y="353.7" width="7.45" height="3.72" transform="translate(150.89 971.68) rotate(-60.15)" fill="#a32732" />
          <rect x="944.42" y="365.54" width="3.72" height="3.72" transform="translate(156.62 1005.25) rotate(-60.15)" fill="#a32732" />
          <rect x="912.12" y="347" width="3.72" height="3.72" transform="translate(156.47 967.93) rotate(-60.15)" fill="#a32732" />
          <rect x="949.5" y="364.16" width="3.72" height="3.72" transform="translate(160.36 1008.97) rotate(-60.15)" fill="#a32732" />
          <rect x="910.74" y="341.92" width="3.72" height="3.72" transform="translate(160.19 964.18) rotate(-60.15)" fill="#a32732" />
          <rect x="954.58" y="362.78" width="3.72" height="3.72" transform="translate(164.11 1012.69) rotate(-60.15)" fill="#a32732" />
          <rect x="909.36" y="336.83" width="3.72" height="3.72" transform="translate(163.91 960.43) rotate(-60.15)" fill="#a32732" />
          <polygon points="928.43 402.24 925.2 400.39 927.05 397.16 923.82 395.3 927.53 388.84 924.3 386.99 928.01 380.53 921.55 376.82 903.01 409.12 912.7 414.68 914.55 411.45 921.01 415.16 922.87 411.93 926.1 413.79 929.8 407.32 926.58 405.47 928.43 402.24" fill="#f77622" />
          <polygon points="892.9 381.85 894.75 378.62 897.98 380.48 901.69 374.01 904.92 375.87 908.63 369.41 886.02 356.43 880.46 366.12 883.69 367.98 879.98 374.44 883.21 376.29 881.36 379.52 887.82 383.23 889.67 380 892.9 381.85" fill="#f77622" />
          <polygon points="931.24 382.38 927.53 388.84 930.76 390.7 927.05 397.16 930.28 399.01 928.43 402.24 931.66 404.1 929.8 407.32 936.26 411.03 938.12 407.8 941.35 409.66 945.06 403.2 948.29 405.05 953.85 395.36 931.24 382.38" fill="#f77622" />
          <polygon points="911.86 371.26 908.15 377.72 904.92 375.87 901.21 382.33 897.98 380.48 896.13 383.7 892.9 381.85 891.04 385.08 887.82 383.23 884.11 389.69 887.34 391.54 885.48 394.77 891.94 398.48 890.09 401.71 899.78 407.27 918.32 374.97 911.86 371.26" fill="#f77622" />
          <polygon points="958.03 380.59 961.74 374.13 958.51 372.27 960.36 369.04 953.9 365.33 952.05 368.56 948.82 366.71 946.97 369.94 943.74 368.09 940.03 374.55 936.8 372.69 933.09 379.15 955.7 392.13 961.26 382.44 958.03 380.59" fill="#f77622" />
          <polygon points="914.66 351.4 911.43 349.55 913.29 346.32 910.06 344.47 911.91 341.24 905.45 337.53 903.6 340.76 900.37 338.91 896.66 345.37 893.43 343.51 887.87 353.2 910.48 366.18 914.19 359.72 910.96 357.86 914.66 351.4" fill="#f77622" />
          <polygon points="940.51 366.23 943.74 368.09 945.59 364.86 948.82 366.71 950.67 363.48 953.9 365.33 957.61 358.88 954.38 357.02 956.23 353.79 949.77 350.08 951.63 346.85 941.94 341.29 923.4 373.59 929.86 377.3 933.57 370.84 936.8 372.69 940.51 366.23" fill="#f77622" />
          <polygon points="929.02 333.88 927.16 337.11 920.7 333.4 918.85 336.63 915.62 334.78 911.91 341.24 915.14 343.09 913.29 346.32 916.52 348.17 914.66 351.4 917.89 353.26 914.19 359.72 917.42 361.57 913.71 368.03 920.17 371.74 938.71 339.44 929.02 333.88" fill="#f77622" />
        </g>
        <g>
          <polygon points="911.65 121.21 911.65 107 897.44 107 897.44 92.8 869.04 92.8 869.04 107 854.83 107 854.83 121.21 840.63 121.21 840.63 107 826.42 107 826.42 92.8 798.02 92.8 798.02 107 783.81 107 783.81 121.21 769.61 121.21 769.61 163.82 783.81 163.82 783.81 178.02 798.02 178.02 798.02 192.23 812.22 192.23 812.22 206.43 826.42 206.43 826.42 220.64 840.63 220.64 840.63 234.84 854.83 234.84 854.83 220.64 869.04 220.64 869.04 206.43 883.24 206.43 883.24 192.23 897.44 192.23 897.44 178.02 911.65 178.02 911.65 163.82 925.85 163.82 925.85 121.21 911.65 121.21" fill="#a32732" />
          <polygon points="911.65 107 911.65 92.8 897.44 92.8 897.44 78.6 869.04 78.6 869.04 92.8 854.83 92.8 854.83 107 840.63 107 840.63 92.8 826.42 92.8 826.42 78.6 798.02 78.6 798.02 92.8 783.81 92.8 783.81 107 769.61 107 769.61 149.62 783.81 149.62 783.81 163.82 798.02 163.82 798.02 178.02 812.22 178.02 812.22 192.23 826.42 192.23 826.42 206.43 840.63 206.43 840.63 220.64 854.83 220.64 854.83 206.43 869.04 206.43 869.04 192.23 883.24 192.23 883.24 178.02 897.44 178.02 897.44 163.82 911.65 163.82 911.65 149.62 925.85 149.62 925.85 107 911.65 107" fill="#e43d44" />
          <polygon points="812.22 121.21 812.22 107 798.02 107 798.02 121.21 783.81 121.21 783.81 135.41 798.02 135.41 798.02 149.62 812.22 149.62 812.22 135.41 826.42 135.41 826.42 121.21 812.22 121.21" fill="#f6757a" />
          <rect x="869.04" y="107" width="14.2" height="14.2" fill="#f6757a" />
        </g>
        <g>
          <polygon points="638.94 218.46 633.71 218.46 633.71 223.69 612.78 223.69 612.78 218.46 607.55 218.46 607.55 223.69 602.32 223.69 602.32 228.92 607.55 228.92 607.55 234.15 638.94 234.15 638.94 228.92 644.17 228.92 644.17 223.69 638.94 223.69 638.94 218.46" fill="#b9704f" />
          <polygon points="638.94 218.46 644.17 218.46 644.17 223.69 649.4 223.69 649.4 208 638.94 208 638.94 218.46" fill="#b9704f" />
          <polygon points="607.55 218.46 607.55 208 597.09 208 597.09 223.69 602.32 223.69 602.32 218.46 607.55 218.46" fill="#b9704f" />
          <rect x="612.79" y="208" width="20.92" height="10.46" fill="#b9704f" />
          <rect x="633.71" y="202.77" width="5.23" height="5.23" fill="#b9704f" />
          <rect x="607.55" y="202.77" width="5.23" height="5.23" fill="#b9704f" />
          <rect x="638.94" y="192.31" width="10.46" height="10.46" fill="#b9704f" />
          <rect x="612.79" y="192.31" width="20.92" height="10.46" fill="#b9704f" />
          <rect x="597.09" y="192.31" width="10.46" height="10.46" fill="#b9704f" />
          <rect x="633.71" y="187.08" width="5.23" height="5.23" fill="#b9704f" />
          <rect x="607.55" y="187.08" width="5.23" height="5.23" fill="#b9704f" />
          <polygon points="638.94 187.08 649.4 187.08 649.4 181.85 644.17 181.85 644.17 176.62 633.71 176.62 633.71 181.85 638.94 181.85 638.94 187.08" fill="#b9704f" />
          <polygon points="633.71 187.08 633.71 181.85 628.48 181.85 628.48 171.38 618.02 171.38 618.02 181.85 612.78 181.85 612.78 187.08 633.71 187.08" fill="#b9704f" />
          <polygon points="612.78 181.85 612.78 176.62 602.32 176.62 602.32 181.85 597.09 181.85 597.09 187.08 607.55 187.08 607.55 181.85 612.78 181.85" fill="#b9704f" />
          <rect x="607.55" y="234.15" width="31.38" height="5.23" fill="#733d3b" />
          <rect x="638.94" y="228.92" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="602.32" y="228.92" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="644.17" y="223.69" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="597.09" y="223.69" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="638.94" y="218.46" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="612.79" y="218.46" width="20.92" height="5.23" fill="#733d3b" />
          <rect x="602.32" y="218.46" width="5.23" height="5.23" fill="#733d3b" />
          <polygon points="597.09 208 607.55 208 607.55 202.77 597.09 202.77 597.09 192.31 607.55 192.31 607.55 187.08 597.09 187.08 597.09 181.85 591.86 181.85 591.86 223.69 597.09 223.69 597.09 208" fill="#733d3b" />
          <rect x="633.71" y="208" width="5.23" height="10.46" fill="#733d3b" />
          <rect x="607.55" y="208" width="5.23" height="10.46" fill="#733d3b" />
          <rect x="612.79" y="202.77" width="20.92" height="5.23" fill="#733d3b" />
          <rect x="607.55" y="192.31" width="5.23" height="10.46" fill="#733d3b" />
          <rect x="633.71" y="192.31" width="5.23" height="10.46" fill="#733d3b" />
          <rect x="612.79" y="187.08" width="20.92" height="5.23" fill="#733d3b" />
          <polygon points="649.4 181.85 649.4 187.08 638.94 187.08 638.94 192.31 649.4 192.31 649.4 202.77 638.94 202.77 638.94 208 649.4 208 649.4 223.69 654.63 223.69 654.63 181.85 649.4 181.85" fill="#733d3b" />
          <rect x="633.71" y="181.85" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="607.55" y="181.85" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="644.17" y="176.62" width="5.23" height="5.23" fill="#733d3b" />
          <polygon points="628.48 181.85 633.71 181.85 633.71 176.62 644.17 176.62 644.17 171.38 628.48 171.38 628.48 181.85" fill="#733d3b" />
          <rect x="597.09" y="176.62" width="5.23" height="5.23" fill="#733d3b" />
          <polygon points="612.78 176.62 612.78 181.85 618.02 181.85 618.02 171.38 602.32 171.38 602.32 176.62 612.78 176.62" fill="#733d3b" />
          <rect x="618.02" y="166.15" width="10.46" height="5.23" fill="#733d3b" />
          <rect x="628.48" y="155.69" width="5.23" height="5.23" fill="#733d3b" />
          <rect x="612.79" y="155.69" width="5.23" height="5.23" fill="#733d3b" />
          <polygon points="618.02 239.38 618.02 244.61 623.25 244.61 623.25 249.84 628.48 249.84 628.48 239.38 618.02 239.38" fill="#3e8948" />
          <polygon points="597.09 244.61 597.09 239.38 602.32 239.38 602.32 228.92 591.86 228.92 591.86 234.15 586.63 234.15 586.63 244.61 597.09 244.61" fill="#3e8948" />
          <polygon points="654.63 228.92 644.17 228.92 644.17 239.38 649.4 239.38 649.4 244.61 659.86 244.61 659.86 234.15 654.63 234.15 654.63 228.92" fill="#3e8948" />
          <polygon points="591.86 176.62 602.32 176.62 602.32 166.16 597.09 166.16 597.09 160.92 586.63 160.92 586.63 171.38 591.86 171.38 591.86 176.62" fill="#3e8948" />
          <polygon points="649.4 160.92 649.4 166.16 644.17 166.16 644.17 176.62 654.63 176.62 654.63 171.38 659.86 171.38 659.86 160.92 649.4 160.92" fill="#3e8948" />
          <polygon points="628.48 150.46 618.02 150.46 618.02 160.92 612.78 160.92 612.78 171.38 618.02 171.38 618.02 166.16 628.48 166.16 628.48 171.38 633.71 171.38 633.71 160.92 628.48 160.92 628.48 150.46" fill="#3e8948" />
        </g>
        <g>
          <g>
            <rect x="416.43" y="87.37" width="5.23" height="10.46" fill="#733d3b" />
            <rect x="468.74" y="87.37" width="5.23" height="10.46" fill="#733d3b" />
            <polygon points="479.2 123.98 479.2 134.44 473.97 134.44 473.97 139.68 468.74 139.68 468.74 144.91 458.28 144.91 458.28 150.14 432.13 150.14 432.13 144.91 421.67 144.91 421.67 139.68 416.44 139.68 416.44 134.44 411.2 134.44 411.2 123.98 416.44 123.98 416.44 129.21 421.67 129.21 421.67 134.44 432.13 134.44 432.13 139.68 458.28 139.68 458.28 134.44 468.74 134.44 468.74 129.21 473.97 129.21 473.97 123.98 479.2 123.98" fill="#733d3b" />
            <polygon points="479.2 103.06 479.2 123.98 473.97 123.98 473.97 129.21 468.74 129.21 468.74 134.44 458.28 134.44 458.28 139.68 432.13 139.68 432.13 134.44 421.67 134.44 421.67 129.21 416.44 129.21 416.44 123.98 411.2 123.98 411.2 103.06 416.44 103.06 416.44 97.83 421.67 97.83 421.67 87.37 416.44 87.37 416.44 71.68 421.67 71.68 421.67 66.45 426.9 66.45 426.9 61.22 463.51 61.22 463.51 66.45 468.74 66.45 468.74 71.68 473.97 71.68 473.97 87.37 468.74 87.37 468.74 97.83 473.97 97.83 473.97 103.06 479.2 103.06" fill="#f77622" />
          </g>
          <rect x="437.36" y="8.91" width="15.69" height="26.15" fill="#f77622" />
          <g>
            <rect x="453.05" y="24.6" width="5.23" height="5.23" fill="#8b9bb5" />
            <rect x="453.05" y="14.14" width="5.23" height="5.23" fill="#8b9bb5" />
            <rect x="432.13" y="14.14" width="5.23" height="5.23" fill="#8b9bb5" />
            <rect x="432.13" y="24.6" width="5.23" height="5.23" fill="#8b9bb5" />
          </g>
          <rect x="432.13" y="113.52" width="26.15" height="5.23" fill="#422632" />
          <g>
            <rect x="437.36" y="76.91" width="15.69" height="5.23" fill="#733d3b" />
            <rect x="437.36" y="66.45" width="15.69" height="5.23" fill="#733d3b" />
            <rect x="437.36" y="55.99" width="15.69" height="5.23" fill="#733d3b" />
            <rect x="437.36" y="45.53" width="15.69" height="5.23" fill="#733d3b" />
            <rect x="437.36" y="50.76" width="15.69" height="5.23" fill="#422632" />
            <rect x="437.36" y="61.22" width="15.69" height="5.23" fill="#422632" />
            <rect x="437.36" y="71.68" width="15.69" height="5.23" fill="#422632" />
            <rect x="437.36" y="35.07" width="15.69" height="5.23" fill="#733d3b" />
            <rect x="437.36" y="40.3" width="15.69" height="5.23" fill="#422632" />
          </g>
          <polygon points="453.05 87.37 453.05 82.14 437.36 82.14 437.36 87.37 432.13 87.37 432.13 97.83 437.36 97.83 437.36 103.06 453.05 103.06 453.05 97.83 458.28 97.83 458.28 87.37 453.05 87.37" fill="#422632" />
        </g>
        <g>
          <rect x="293.83" y="183.74" width="8.66" height="8.66" fill="#5b6986" />
          <rect x="241.88" y="183.74" width="8.66" height="8.66" fill="#5b6986" />
          <rect x="224.57" y="183.74" width="8.66" height="8.66" fill="#5b6986" />
          <polygon points="224.57 153.44 224.57 162.1 220.24 162.1 220.24 166.43 241.88 166.43 241.88 153.44 224.57 153.44" fill="#5b6986" />
          <rect x="202.92" y="188.07" width="12.99" height="4.33" fill="#ecf0f1" />
          <polygon points="215.91 166.43 211.58 166.43 211.58 170.75 207.25 170.75 207.25 183.74 220.24 183.74 220.24 170.75 215.91 170.75 215.91 166.43" fill="#ecf0f1" />
          <polygon points="194.27 183.74 194.27 192.4 202.93 192.4 202.93 188.07 215.91 188.07 215.91 192.4 224.57 192.4 224.57 183.74 194.27 183.74" fill="#c0ccdc" />
          <polygon points="211.58 153.44 211.58 166.43 220.24 166.43 220.24 162.1 224.57 162.1 224.57 153.44 211.58 153.44" fill="#8b9bb5" />
          <path d="M246.21,140.45v4.33h-4.33v21.64h-13v17.32h4.33v8.66h4.32v-8.66h4.33v-4.33h13v4.33h4.33v8.66h30.3v-8.66h4.33v-4.33h13v4.33h4.33v8.66h8.66v-8.66h-4.33v-4.33h4.33v-39Zm0,13h13v13h-8.66V162.1h-4.33Z" fill="#104e89" />
          <rect x="224.57" y="166.42" width="4.33" height="4.33" fill="#ecf0f1" />
          <rect x="198.6" y="166.42" width="4.33" height="4.33" fill="#ecf0f1" />
          <rect x="285.17" y="192.4" width="8.66" height="4.33" fill="#252b43" />
          <rect x="233.23" y="192.4" width="8.66" height="4.33" fill="#252b43" />
          <rect x="207.25" y="192.4" width="12.99" height="4.33" fill="#252b43" />
          <polygon points="246.21 153.44 246.21 162.1 250.54 162.1 250.54 166.43 259.2 166.43 259.2 153.44 246.21 153.44" fill="#252b43" />
          <rect x="315.47" y="179.41" width="4.33" height="4.33" fill="#e43d44" />
          <polygon points="224.57 166.43 215.91 166.43 215.91 170.75 220.24 170.75 220.24 183.74 224.57 183.74 224.57 179.41 228.9 179.41 228.9 170.75 224.57 170.75 224.57 166.43" fill="#0099dc" />
          <polygon points="202.93 166.43 202.93 170.75 198.6 170.75 198.6 179.41 202.93 179.41 202.93 183.74 207.25 183.74 207.25 170.75 211.58 170.75 211.58 166.43 202.93 166.43" fill="#0099dc" />
          <polygon points="315.47 157.77 315.47 153.44 267.86 153.44 267.86 166.43 315.47 166.43 315.47 162.1 289.5 162.1 289.5 157.77 315.47 157.77" fill="#252b43" />
          <polygon points="215.91 140.45 215.91 144.78 211.58 144.78 211.58 153.44 241.88 153.44 241.88 144.78 246.21 144.78 246.21 140.45 215.91 140.45" fill="#0099dc" />
          <rect x="224.57" y="179.41" width="4.33" height="4.33" fill="#8b9bb5" />
          <rect x="198.6" y="179.41" width="4.33" height="4.33" fill="#8b9bb5" />
          <rect x="293.83" y="192.4" width="8.66" height="4.33" fill="#252b43" />
          <rect x="241.88" y="192.4" width="8.66" height="4.33" fill="#252b43" />
          <rect x="302.48" y="183.74" width="4.33" height="8.66" fill="#252b43" />
          <rect x="289.5" y="183.74" width="4.33" height="8.66" fill="#252b43" />
          <rect x="250.54" y="183.74" width="4.33" height="8.66" fill="#252b43" />
          <rect x="237.55" y="183.74" width="4.33" height="8.66" fill="#252b43" />
          <rect x="293.83" y="179.41" width="8.66" height="4.33" fill="#252b43" />
          <rect x="241.88" y="179.41" width="8.66" height="4.33" fill="#252b43" />
          <rect x="306.81" y="183.74" width="4.33" height="8.66" fill="#104e89" />
          <rect x="254.87" y="183.74" width="4.33" height="8.66" fill="#104e89" />
          <rect x="302.48" y="179.41" width="4.33" height="4.33" fill="#104e89" />
          <rect x="250.54" y="179.41" width="4.33" height="4.33" fill="#104e89" />
        </g>
        <g>
          <polygon points="957 585 957 583 953 583 953 581 951 581 951 579 949 579 949 585 951 585 951 587 953 587 953 589 959 589 959 587 963 587 963 585 957 585" fill="#8b9bb5" />
          <polygon points="969 575 969 573 961 573 961 575 957 575 957 579 961 579 961 581 969 581 969 579 973 579 973 575 969 575" fill="#5b6986" />
          <path d="M979,575v-2h-2v-2h-4v-2H957v2h-4v2h-2v2h-2v4h2v2h2v2h4v2h6v2h-4v2h-6v-2h-2v-2h-6v2h-2v26h2v-2h4v2h2v2h8v-2h8v-2h6v-2h4v-2h2v-2h2V575Zm-22,4v-4h4v-2h8v2h4v4h-4v2h-8v-2Z" fill="#ecf0f1" />
        </g>
        <g>
          <g>
            <rect x="537.5" y="574.5" width="2" height="12" fill="#5b6986" />
            <rect x="539.5" y="572.5" width="12" height="2" fill="#5b6986" />
            <rect x="537.5" y="572.5" width="2" height="2" fill="#2ee8f7" />
            <path d="M535.5,570.5v20h22v-20Zm2,2h14v2h-12v12h-2Z" fill="#252b43" />
            <path d="M533.5,568.5v24h26v-24Zm24,22h-22v-20h22Z" fill="#3a4467" />
            <rect x="531.5" y="568.5" width="2" height="24" fill="#733d3b" />
            <path d="M567.5,566.5h-34v2h26v24h-26v2h34v-2h2v-24h-2Zm0,22h-6v-2h6Zm0-4h-6v-2h6Zm0-10h-2v2h2v2h-2v-2h-2v2h-2v-2h2v-2h-2v-2h2v2h2v-2h2Z" fill="#733d3b" />
          </g>
          <rect x="561.5" y="586.5" width="6" height="2" fill="#422632" />
          <rect x="561.5" y="582.5" width="6" height="2" fill="#422632" />
          <rect x="565.5" y="576.5" width="2" height="2" fill="#422632" />
          <rect x="561.5" y="576.5" width="2" height="2" fill="#422632" />
          <rect x="563.5" y="574.5" width="2" height="2" fill="#422632" />
          <rect x="565.5" y="572.5" width="2" height="2" fill="#422632" />
          <rect x="561.5" y="572.5" width="2" height="2" fill="#422632" />
          <rect x="547.5" y="562.5" width="2" height="4" fill="#5b6986" />
          <rect x="549.5" y="560.5" width="2" height="2" fill="#5b6986" />
          <rect x="545.5" y="560.5" width="2" height="2" fill="#5b6986" />
          <rect x="551.5" y="558.5" width="2" height="2" fill="#5b6986" />
          <rect x="543.5" y="558.5" width="2" height="2" fill="#5b6986" />
          <rect x="553.5" y="556.5" width="2" height="2" fill="#5b6986" />
          <rect x="541.5" y="556.5" width="2" height="2" fill="#5b6986" />
          <rect x="555.5" y="554.5" width="2" height="2" fill="#5b6986" />
          <rect x="539.5" y="554.5" width="2" height="2" fill="#5b6986" />
          <rect x="557.5" y="552.5" width="2" height="2" fill="#5b6986" />
          <rect x="537.5" y="552.5" width="2" height="2" fill="#5b6986" />
        </g>
        <g>
          <rect x="540" y="27" width="22" height="20" fill="#2ee8f7" />
          <rect x="536" y="25" width="2" height="24" fill="#733d3b" />
          <path d="M572,23H538v2h26V49H538v2h34V49h2V25h-2Z" fill="#733d3b" />
          <rect x="552" y="43" width="4" height="2" fill="#0099dc" />
          <rect x="544" y="43" width="4" height="2" fill="#0099dc" />
          <rect x="556" y="41" width="4" height="2" fill="#0099dc" />
          <rect x="548" y="41" width="4" height="2" fill="#0099dc" />
          <rect x="554" y="37" width="4" height="2" fill="#0099dc" />
          <rect x="546" y="37" width="4" height="2" fill="#0099dc" />
          <rect x="550" y="35" width="4" height="2" fill="#0099dc" />
          <rect x="542" y="35" width="4" height="2" fill="#0099dc" />
          <rect x="556" y="31" width="4" height="2" fill="#0099dc" />
          <rect x="548" y="31" width="4" height="2" fill="#0099dc" />
          <rect x="564" y="25" width="8" height="24" fill="#422632" />
          <g>
            <rect x="566" y="45" width="4" height="2" fill="#a32732" />
            <rect x="566" y="27" width="4" height="2" fill="#5b6986" />
            <rect x="566" y="39" width="4" height="4" fill="#3a4467" />
          </g>
          <rect x="552" y="29" width="4" height="2" fill="#0099dc" />
          <rect x="544" y="29" width="4" height="2" fill="#0099dc" />
          <rect x="540" y="31" width="4" height="2" fill="#0099dc" />
          <path d="M538,25V49h26V25Zm24,22H540V27h22Z" fill="#3a4467" />
          <rect x="562" y="9" width="2" height="2" fill="#8b9bb5" />
          <rect x="542" y="9" width="2" height="2" fill="#8b9bb5" />
          <rect x="552" y="19" width="2" height="4" fill="#8b9bb5" />
          <rect x="554" y="17" width="2" height="2" fill="#5b6986" />
          <rect x="550" y="17" width="2" height="2" fill="#5b6986" />
          <rect x="556" y="15" width="2" height="2" fill="#5b6986" />
          <rect x="548" y="15" width="2" height="2" fill="#5b6986" />
          <rect x="558" y="13" width="2" height="2" fill="#5b6986" />
          <rect x="546" y="13" width="2" height="2" fill="#5b6986" />
          <rect x="560" y="11" width="2" height="2" fill="#8b9bb5" />
          <rect x="544" y="11" width="2" height="2" fill="#8b9bb5" />
          <rect x="560" y="29" width="2" height="2" fill="#0099dc" />
          <rect x="540" y="37" width="2" height="2" fill="#0099dc" />
          <rect x="558" y="35" width="4" height="2" fill="#0099dc" />
          <rect x="540" y="41" width="4" height="2" fill="#0099dc" />
          <rect x="560" y="43" width="2" height="2" fill="#0099dc" />
        </g>
        <g>
          <polygon points="982 248 984 248 984 258 982 258 982 262 980 262 980 264 978 264 978 266 974 266 974 268 964 268 964 266 960 266 960 264 958 264 958 262 956 262 956 258 954 258 954 248 956 248 956 244 958 244 958 242 960 242 960 240 964 240 964 238 974 238 974 240 978 240 978 242 980 242 980 244 982 244 982 248" fill="#fdae35" />
          <rect x="966" y="256" width="6" height="2" fill="#a32732" />
          <rect x="972" y="254" width="2" height="2" fill="#a32732" />
          <rect x="964" y="254" width="2" height="2" fill="#a32732" />
          <rect x="972" y="248" width="4" height="2" fill="#a32732" />
          <rect x="964" y="246" width="2" height="4" fill="#a32732" />
        </g>
        <g>
          <polygon points="202 628 196 628 196 598 202 598 202 586 208 586 208 580 214 580 214 574 226 574 226 568 256 568 256 574 268 574 268 580 274 580 274 586 280 586 280 598 286 598 286 628 280 628 280 640 274 640 274 646 268 646 268 652 256 652 256 658 226 658 226 652 214 652 214 646 208 646 208 640 202 640 202 628" fill="#fdae35" />
          <rect x="232" y="616" width="18" height="6" fill="#a32732" />
          <rect x="250" y="610" width="6" height="6" fill="#a32732" />
          <rect x="226" y="610" width="6" height="6" fill="#a32732" />
          <rect x="268" y="598" width="6" height="6" fill="#a32732" />
          <rect x="250" y="598" width="6" height="6" fill="#a32732" />
          <rect x="226" y="598" width="6" height="6" fill="#a32732" />
          <rect x="208" y="598" width="6" height="6" fill="#a32732" />
          <rect x="256" y="592" width="12" height="6" fill="#a32732" />
          <rect x="214" y="592" width="12" height="6" fill="#a32732" />
          <rect x="262" y="610" width="12" height="18" fill="#e43d44" />
          <rect x="208" y="610" width="12" height="18" fill="#e43d44" />
        </g>
        <g>
          <polygon points="190 338 194 338 194 342 198 342 198 350 202 350 202 370 198 370 198 378 194 378 194 382 190 382 190 386 182 386 182 390 162 390 162 386 154 386 154 382 150 382 150 378 146 378 146 370 142 370 142 350 146 350 146 342 150 342 150 338 154 338 154 334 162 334 162 330 182 330 182 334 190 334 190 338" fill="#fdae35" />
          <polygon points="190 354 154 354 154 350 150 350 150 370 154 370 154 374 158 374 158 370 166 370 166 366 178 366 178 370 186 370 186 374 190 374 190 370 194 370 194 350 190 350 190 354" fill="#a32732" />
          <rect x="186" y="342" width="4" height="4" fill="#a32732" />
          <rect x="174" y="342" width="4" height="4" fill="#a32732" />
          <rect x="166" y="342" width="4" height="4" fill="#a32732" />
          <rect x="154" y="342" width="4" height="4" fill="#a32732" />
          <rect x="178" y="338" width="8" height="4" fill="#a32732" />
          <rect x="158" y="338" width="8" height="4" fill="#a32732" />
          <rect x="166" y="366" width="12" height="4" fill="#e43d44" />
          <polygon points="158 370 158 374 162 374 162 378 182 378 182 374 186 374 186 370 158 370" fill="#ecf0f1" />
          <rect x="154" y="350" width="36" height="4" fill="#ecf0f1" />
          <rect x="202" y="338" width="4" height="4" fill="#ecf0f1" />
          <rect x="190" y="338" width="4" height="4" fill="#ecf0f1" />
          <rect x="150" y="338" width="4" height="4" fill="#ecf0f1" />
          <rect x="138" y="338" width="4" height="4" fill="#ecf0f1" />
          <rect x="194" y="334" width="8" height="4" fill="#ecf0f1" />
          <rect x="142" y="334" width="8" height="4" fill="#ecf0f1" />
        </g>
        <g>
          <rect x="374" y="335" width="16" height="4" fill="#c0ccdc" />
          <rect x="358" y="335" width="12" height="4" fill="#c0ccdc" />
          <rect x="382" y="263" width="8" height="4" fill="#3a4467" />
          <rect x="390" y="259" width="12" height="4" fill="#3a4467" />
          <rect x="402" y="255" width="8" height="4" fill="#3a4467" />
          <rect x="422" y="247" width="4" height="4" fill="#3a4467" />
          <rect x="426" y="243" width="4" height="4" fill="#3a4467" />
          <polygon points="390 243 390 239 378 239 378 247 374 247 374 243 370 243 370 239 354 239 354 243 350 243 350 247 358 247 358 255 354 255 354 259 362 259 362 255 374 255 374 259 378 259 378 255 382 255 382 251 390 251 390 247 394 247 394 243 390 243" fill="#c0ccdc" />
          <rect x="374" y="231" width="4" height="4" fill="#c0ccdc" />
          <rect x="370" y="223" width="8" height="4" fill="#bf4830" />
          <polygon points="362 287 362 283 358 283 358 291 362 291 362 307 358 307 358 331 362 331 362 319 366 319 366 307 370 307 370 287 362 287" fill="#67386c" />
          <polygon points="382 283 382 287 378 287 378 303 374 303 374 331 378 331 378 319 382 319 382 303 386 303 386 283 382 283" fill="#67386c" />
          <rect x="374" y="331" width="8" height="4" fill="#252b43" />
          <rect x="358" y="331" width="8" height="4" fill="#252b43" />
          <polygon points="382 267 382 271 378 271 378 275 370 275 370 271 358 271 358 267 354 267 354 279 358 279 358 283 362 283 362 287 382 287 382 283 386 283 386 279 390 279 390 275 398 275 398 271 402 271 402 263 390 263 390 267 382 267" fill="#252b43" />
          <rect x="406" y="251" width="8" height="4" fill="#252b43" />
          <polygon points="398 251 382 251 382 255 378 255 378 259 374 259 374 255 362 255 362 259 358 259 358 263 366 263 366 267 374 267 374 263 390 263 390 259 402 259 402 255 398 255 398 251" fill="#252b43" />
          <polygon points="430 239 430 247 426 247 426 251 434 251 434 247 438 247 438 239 430 239" fill="#252b43" />
          <polygon points="370 239 370 219 378 219 378 239 390 239 390 231 386 231 386 223 382 223 382 215 378 215 378 211 366 211 366 215 362 215 362 223 358 223 358 231 354 231 354 239 370 239" fill="#252b43" />
          <polygon points="346 267 358 267 358 271 370 271 370 275 378 275 378 271 382 271 382 263 374 263 374 267 366 267 366 263 358 263 358 259 354 259 354 255 358 255 358 247 350 247 350 251 346 251 346 259 342 259 342 263 346 263 346 267" fill="#d77643" />
          <rect x="398" y="251" width="4" height="4" fill="#d77643" />
          <polygon points="418 247 418 251 414 251 414 255 410 255 410 259 402 259 402 263 414 263 414 259 418 259 418 255 422 255 422 247 418 247" fill="#d77643" />
          <rect x="390" y="247" width="4" height="4" fill="#d77643" />
          <polygon points="374 247 378 247 378 235 374 235 374 231 378 231 378 227 370 227 370 243 374 243 374 247" fill="#d77643" />
          <rect x="370" y="219" width="8" height="4" fill="#d77643" />
        </g>
        <g>
          <rect x="969" y="545" width="2" height="26" fill="#8b9bb5" />
          <rect x="971" y="545" width="2" height="26" fill="#ecf0f1" />
          <polygon points="955 533 955 535 957 535 957 537 959 537 959 539 961 539 961 541 963 541 963 537 961 537 961 535 987 535 987 533 955 533" fill="#a32732" />
          <rect x="955" y="525" width="8" height="6" fill="#a32732" />
          <polygon points="961 521 961 517 959 517 959 519 957 519 957 521 961 521" fill="#a32732" />
          <rect x="979" y="525" width="2" height="4" fill="#ecf0f1" />
          <rect x="975" y="521" width="8" height="2" fill="#ecf0f1" />
          <rect x="957" y="521" width="2" height="2" fill="#ecf0f1" />
          <rect x="979" y="515" width="2" height="2" fill="#ecf0f1" />
          <rect x="975" y="513" width="4" height="2" fill="#ecf0f1" />
          <path d="M975,523v8h8v-8Zm6,6h-2v-4h2Z" fill="#f6757a" />
          <rect x="983" y="521" width="6" height="2" fill="#f6757a" />
          <rect x="959" y="521" width="16" height="2" fill="#f6757a" />
          <rect x="953" y="521" width="4" height="2" fill="#f6757a" />
          <rect x="981" y="517" width="2" height="2" fill="#f6757a" />
          <rect x="969" y="511" width="6" height="2" fill="#f6757a" />
          <rect x="981" y="539" width="2" height="2" fill="#e43d44" />
          <polygon points="979 539 981 539 981 535 969 535 969 539 973 539 973 541 969 541 969 543 981 543 981 541 979 541 979 539" fill="#e43d44" />
          <polygon points="967 539 967 537 965 537 965 535 963 535 963 539 965 539 965 541 969 541 969 539 967 539" fill="#e43d44" />
          <rect x="983" y="537" width="2" height="2" fill="#e43d44" />
          <rect x="985" y="535" width="2" height="2" fill="#e43d44" />
          <polygon points="983 523 983 531 975 531 975 523 967 523 967 533 989 533 989 523 983 523" fill="#e43d44" />
          <rect x="963" y="523" width="2" height="10" fill="#e43d44" />
          <rect x="953" y="523" width="2" height="10" fill="#e43d44" />
          <rect x="955" y="517" width="2" height="4" fill="#e43d44" />
          <rect x="957" y="515" width="2" height="2" fill="#e43d44" />
          <rect x="959" y="513" width="2" height="2" fill="#e43d44" />
          <polygon points="967 513 965 513 965 517 963 517 963 521 965 521 965 519 967 519 967 515 969 515 969 521 981 521 981 517 979 517 979 515 975 515 975 513 969 513 969 511 975 511 975 513 979 513 979 515 981 515 981 517 983 517 983 519 985 519 985 521 987 521 987 517 985 517 985 515 983 515 983 513 981 513 981 511 977 511 977 509 965 509 965 511 967 511 967 513" fill="#e43d44" />
          <rect x="961" y="511" width="4" height="2" fill="#e43d44" />
          <polygon points="969 541 965 541 965 539 963 539 963 541 961 541 961 543 965 543 965 545 977 545 977 543 969 543 969 541" fill="#a32732" />
          <rect x="979" y="539" width="2" height="2" fill="#a32732" />
          <rect x="969" y="539" width="4" height="2" fill="#a32732" />
          <rect x="959" y="539" width="2" height="2" fill="#a32732" />
          <rect x="957" y="537" width="2" height="2" fill="#a32732" />
          <polygon points="981 539 983 539 983 537 985 537 985 535 981 535 981 539" fill="#a32732" />
          <polygon points="965 535 965 537 967 537 967 539 969 539 969 535 965 535" fill="#a32732" />
          <rect x="961" y="535" width="2" height="2" fill="#a32732" />
          <rect x="955" y="535" width="2" height="2" fill="#a32732" />
          <rect x="965" y="523" width="2" height="10" fill="#a32732" />
          <rect x="955" y="531" width="8" height="2" fill="#a32732" />
          <rect x="955" y="523" width="8" height="2" fill="#a32732" />
          <rect x="981" y="519" width="4" height="2" fill="#a32732" />
          <polygon points="969 515 967 515 967 519 965 519 965 521 969 521 969 515" fill="#a32732" />
          <polygon points="963 517 965 517 965 513 961 513 961 515 959 515 959 517 961 517 961 521 963 521 963 517" fill="#a32732" />
          <rect x="957" y="517" width="2" height="2" fill="#a32732" />
          <rect x="965" y="511" width="2" height="2" fill="#a32732" />
        </g>
        <g>
          <polygon points="49 602 49 604 51 604 51 606 61 606 61 604 63 604 63 602 49 602" fill="#c28569" />
          <rect x="59" y="582" width="4" height="20" fill="#fee861" />
          <rect x="49" y="582" width="4" height="20" fill="#f77622" />
          <polygon points="61 574 61 572 51 572 51 574 49 574 49 578 63 578 63 574 61 574" fill="#f6757a" />
          <rect x="59" y="578" width="2" height="4" fill="#c0ccdc" />
          <polygon points="53 606 53 608 55 608 55 610 57 610 57 608 59 608 59 606 53 606" fill="#3a4467" />
          <rect x="53" y="582" width="6" height="20" fill="#fdae35" />
          <rect x="61" y="578" width="2" height="4" fill="#8b9bb5" />
          <rect x="49" y="578" width="6" height="4" fill="#3a4467" />
          <rect x="55" y="578" width="4" height="4" fill="#8b9bb5" />
          <rect x="49" y="574" width="4" height="4" fill="#e43d44" />
          <rect x="51" y="572" width="2" height="2" fill="#e43d44" />
        </g>
        <g>
          <rect x="443" y="514" width="2" height="2" fill="#bf4830" />
          <rect x="445" y="512" width="2" height="2" fill="#bf4830" />
          <rect x="447" y="510" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="510" width="2" height="2" fill="#bf4830" />
          <rect x="439" y="510" width="2" height="2" fill="#bf4830" />
          <rect x="441" y="508" width="2" height="2" fill="#bf4830" />
          <rect x="447" y="506" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="506" width="2" height="2" fill="#bf4830" />
          <rect x="439" y="506" width="2" height="2" fill="#bf4830" />
          <rect x="445" y="504" width="2" height="2" fill="#bf4830" />
          <rect x="437" y="504" width="2" height="2" fill="#bf4830" />
          <rect x="451" y="502" width="2" height="2" fill="#bf4830" />
          <rect x="447" y="502" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="502" width="2" height="2" fill="#bf4830" />
          <rect x="439" y="502" width="2" height="2" fill="#bf4830" />
          <rect x="435" y="502" width="2" height="2" fill="#bf4830" />
          <rect x="449" y="500" width="2" height="2" fill="#bf4830" />
          <rect x="441" y="500" width="2" height="2" fill="#bf4830" />
          <rect x="451" y="498" width="2" height="2" fill="#bf4830" />
          <rect x="447" y="498" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="498" width="2" height="2" fill="#bf4830" />
          <rect x="439" y="498" width="2" height="2" fill="#bf4830" />
          <rect x="435" y="498" width="2" height="2" fill="#bf4830" />
          <rect x="453" y="496" width="2" height="2" fill="#bf4830" />
          <rect x="445" y="496" width="2" height="2" fill="#bf4830" />
          <rect x="437" y="496" width="2" height="2" fill="#bf4830" />
          <rect x="451" y="494" width="2" height="2" fill="#bf4830" />
          <rect x="447" y="494" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="494" width="2" height="2" fill="#bf4830" />
          <rect x="439" y="494" width="2" height="2" fill="#bf4830" />
          <rect x="435" y="494" width="2" height="2" fill="#bf4830" />
          <rect x="443" y="516" width="2" height="4" fill="#f77622" />
          <rect x="445" y="514" width="2" height="2" fill="#f77622" />
          <polygon points="443 510 441 510 441 516 443 516 443 514 445 514 445 512 443 512 443 510" fill="#f77622" />
          <rect x="439" y="508" width="2" height="2" fill="#f77622" />
          <polygon points="447 506 445 506 445 508 443 508 443 510 445 510 445 512 447 512 447 510 449 510 449 508 447 508 447 506" fill="#f77622" />
          <polygon points="441 508 443 508 443 506 445 506 445 504 443 504 443 502 441 502 441 504 439 504 439 506 441 506 441 508" fill="#f77622" />
          <rect x="437" y="506" width="2" height="2" fill="#f77622" />
          <polygon points="449 504 447 504 447 506 449 506 449 508 451 508 451 502 449 502 449 504" fill="#f77622" />
          <rect x="451" y="500" width="2" height="2" fill="#f77622" />
          <polygon points="441 502 441 500 439 500 439 498 437 498 437 500 435 500 435 502 437 502 437 504 439 504 439 502 441 502" fill="#f77622" />
          <rect x="453" y="498" width="2" height="2" fill="#f77622" />
          <polygon points="445 500 443 500 443 502 445 502 445 504 447 504 447 502 449 502 449 500 447 500 447 498 445 498 445 500" fill="#f77622" />
          <polygon points="451 496 451 494 449 494 449 496 447 496 447 498 449 498 449 500 451 500 451 498 453 498 453 496 451 496" fill="#f77622" />
          <polygon points="441 498 441 500 443 500 443 498 445 498 445 496 443 496 443 494 441 494 441 496 439 496 439 498 441 498" fill="#f77622" />
          <polygon points="437 498 437 496 435 496 435 494 433 494 433 500 435 500 435 498 437 498" fill="#f77622" />
          <rect x="453" y="494" width="2" height="2" fill="#f77622" />
          <rect x="445" y="494" width="2" height="2" fill="#f77622" />
          <rect x="437" y="494" width="2" height="2" fill="#f77622" />
          <polygon points="455 490 455 484 453 484 453 482 451 482 451 480 447 480 447 478 441 478 441 480 437 480 437 482 435 482 435 484 433 484 433 490 431 490 431 494 457 494 457 490 455 490" fill="#ebd4ab" />
        </g>
        <g>
          <polygon points="242 470 240 470 240 466 238 466 238 470 236 470 236 474 234 474 234 480 244 480 244 474 242 474 242 470" fill="#fdae35" />
          <polygon points="234 470 236 470 236 466 232 466 232 468 228 468 228 470 224 470 224 472 226 472 226 476 230 476 230 478 232 478 232 474 234 474 234 470" fill="#fdae35" />
          <polygon points="246 468 246 466 242 466 242 470 244 470 244 474 246 474 246 478 248 478 248 476 252 476 252 472 254 472 254 470 250 470 250 468 246 468" fill="#fdae35" />
          <rect x="240" y="464" width="2" height="2" fill="#fdae35" />
          <rect x="236" y="464" width="2" height="2" fill="#fdae35" />
          <polygon points="232 466 232 464 236 464 236 462 232 462 232 460 228 460 228 458 222 458 222 468 228 468 228 466 232 466" fill="#fdae35" />
          <rect x="240" y="460" width="2" height="2" fill="#fdae35" />
          <rect x="236" y="460" width="2" height="2" fill="#fdae35" />
          <polygon points="250 458 250 460 246 460 246 462 242 462 242 464 246 464 246 466 250 466 250 468 256 468 256 458 250 458" fill="#fdae35" />
          <polygon points="232 458 232 460 236 460 236 456 234 456 234 452 232 452 232 448 230 448 230 450 226 450 226 454 224 454 224 456 228 456 228 458 232 458" fill="#fdae35" />
          <polygon points="244 456 242 456 242 460 246 460 246 458 250 458 250 456 254 456 254 454 252 454 252 450 248 450 248 448 246 448 246 452 244 452 244 456" fill="#fdae35" />
          <polygon points="236 456 238 456 238 460 240 460 240 456 242 456 242 452 244 452 244 446 234 446 234 452 236 452 236 456" fill="#fdae35" />
          <rect x="234" y="480" width="10" height="2" fill="#ecf0f1" />
          <rect x="248" y="476" width="4" height="2" fill="#ecf0f1" />
          <polygon points="246 474 244 474 244 480 248 480 248 478 246 478 246 474" fill="#ecf0f1" />
          <polygon points="234 474 232 474 232 478 230 478 230 480 234 480 234 474" fill="#ecf0f1" />
          <rect x="226" y="476" width="4" height="2" fill="#ecf0f1" />
          <rect x="252" y="472" width="2" height="4" fill="#ecf0f1" />
          <rect x="242" y="470" width="2" height="4" fill="#ecf0f1" />
          <rect x="224" y="472" width="2" height="4" fill="#ecf0f1" />
          <rect x="234" y="470" width="2" height="4" fill="#ecf0f1" />
          <polygon points="250 468 250 470 254 470 254 472 256 472 256 468 250 468" fill="#ecf0f1" />
          <polygon points="228 470 228 468 222 468 222 472 224 472 224 470 228 470" fill="#ecf0f1" />
          <rect x="246" y="466" width="4" height="2" fill="#ecf0f1" />
          <rect x="240" y="466" width="2" height="4" fill="#ecf0f1" />
          <rect x="236" y="466" width="2" height="4" fill="#ecf0f1" />
          <rect x="228" y="466" width="4" height="2" fill="#ecf0f1" />
          <rect x="242" y="464" width="4" height="2" fill="#ecf0f1" />
          <rect x="232" y="464" width="4" height="2" fill="#ecf0f1" />
          <rect x="220" y="458" width="2" height="10" fill="#ecf0f1" />
          <rect x="242" y="460" width="4" height="2" fill="#ecf0f1" />
          <polygon points="240 460 238 460 238 462 236 462 236 464 238 464 238 466 240 466 240 464 242 464 242 462 240 462 240 460" fill="#ecf0f1" />
          <rect x="232" y="460" width="4" height="2" fill="#ecf0f1" />
          <rect x="256" y="458" width="2" height="10" fill="#ecf0f1" />
          <rect x="246" y="458" width="4" height="2" fill="#ecf0f1" />
          <rect x="240" y="456" width="2" height="4" fill="#ecf0f1" />
          <rect x="236" y="456" width="2" height="4" fill="#ecf0f1" />
          <rect x="228" y="458" width="4" height="2" fill="#ecf0f1" />
          <polygon points="250 456 250 458 256 458 256 454 254 454 254 456 250 456" fill="#ecf0f1" />
          <polygon points="228 458 228 456 224 456 224 454 222 454 222 458 228 458" fill="#ecf0f1" />
          <rect x="242" y="452" width="2" height="4" fill="#ecf0f1" />
          <rect x="252" y="450" width="2" height="4" fill="#ecf0f1" />
          <rect x="234" y="452" width="2" height="4" fill="#ecf0f1" />
          <rect x="224" y="450" width="2" height="4" fill="#ecf0f1" />
          <rect x="248" y="448" width="4" height="2" fill="#ecf0f1" />
          <polygon points="244 452 246 452 246 448 248 448 248 446 244 446 244 452" fill="#ecf0f1" />
          <polygon points="232 452 234 452 234 446 230 446 230 448 232 448 232 452" fill="#ecf0f1" />
          <rect x="226" y="448" width="4" height="2" fill="#ecf0f1" />
          <rect x="234" y="444" width="10" height="2" fill="#ecf0f1" />
          <path d="M258,456v-4h-2v-4h-2v-2h-4v-2h-4v-2H232v2h-4v2h-4v2h-2v4h-2v4h-2v14h2v4h2v4h2v2h4v2h4v2h14v-2h4v-2h4v-2h2v-4h2v-4h2V456Zm0,12h-2v4h-2v4h-2v2h-4v2h-4v2H234v-2h-4v-2h-4v-2h-2v-4h-2v-4h-2V458h2v-4h2v-4h2v-2h4v-2h4v-2h10v2h4v2h4v2h2v4h2v4h2Z" fill="#f77622" />
        </g>
        <g>
          <rect x="80" y="345" width="2" height="2" fill="#c0ccdc" />
          <rect x="82" y="343" width="2" height="2" fill="#c0ccdc" />
          <g>
            <polygon points="72 339 72 337 68 337 68 333 66 333 66 331 64 331 64 337 66 337 66 341 70 341 70 343 76 343 76 341 80 341 80 339 72 339" fill="#f77622" />
            <polygon points="54 327 54 325 50 325 50 321 48 321 48 319 46 319 46 325 48 325 48 329 52 329 52 331 58 331 58 329 62 329 62 327 54 327" fill="#f77622" />
            <rect x="76" y="329" width="2" height="2" fill="#fee861" />
            <rect x="58" y="317" width="2" height="2" fill="#fee861" />
            <rect x="58" y="351" width="12" height="2" fill="#c0ccdc" />
            <rect x="70" y="349" width="6" height="2" fill="#c0ccdc" />
            <rect x="52" y="349" width="6" height="2" fill="#c0ccdc" />
            <rect x="76" y="347" width="4" height="2" fill="#c0ccdc" />
            <rect x="48" y="347" width="4" height="2" fill="#c0ccdc" />
            <rect x="46" y="345" width="2" height="2" fill="#c0ccdc" />
            <rect x="44" y="341" width="2" height="4" fill="#c0ccdc" />
            <rect x="42" y="335" width="2" height="6" fill="#c0ccdc" />
            <rect x="40" y="323" width="2" height="12" fill="#c0ccdc" />
            <rect x="42" y="317" width="2" height="6" fill="#c0ccdc" />
            <path d="M80,331v-4H76v-2H70v2H66v6h2v4h4v2h8v-2h2v-6Zm-2-2v2H76v-2Z" fill="#fdae35" />
            <path d="M62,315H58v-2H52v2H48v6h2v4h4v2h8v-2h2v-6H62Zm-4,4v-2h2v2Z" fill="#fdae35" />
            <path d="M70,351v-2h6v-2h4v-2h2v-2h2v-2h2v-6h2V323H86v-6H84v-4H82v-2H80v-2H76v-2H70v-2H58v2H52v2H48v2H46v2H44v10H42v12h2v6h2v4h2v2h4v2h6v2Zm-4-20v-4h4v-2h6v2h4v4h2v6H80v4H76v2H70v-2H66v-4H64v-6ZM46,319h2v-4h4v-2h6v2h4v4h2v6H62v4H58v2H52v-2H48v-4H46Z" fill="#ecf0f1" />
          </g>
        </g>
        <g>
          <polygon points="775.93 41.14 775.93 38.07 772.86 38.07 772.86 35 693 35 693 62.64 696.07 62.64 696.07 65.71 699.14 65.71 699.14 68.79 714.5 68.79 714.5 65.71 717.57 65.71 717.57 62.64 720.64 62.64 720.64 53.43 723.71 53.43 723.71 62.64 726.79 62.64 726.79 65.71 729.86 65.71 729.86 68.79 745.21 68.79 745.21 65.71 748.29 65.71 748.29 62.64 751.36 62.64 751.36 44.21 769.79 44.21 769.79 47.29 772.86 47.29 772.86 53.43 779 53.43 779 41.14 775.93 41.14" fill="#252b43" />
          <g>
            <polygon points="696.07 41.14 696.07 62.64 699.14 62.64 699.14 65.71 714.5 65.71 714.5 62.64 717.57 62.64 717.57 41.14 696.07 41.14" fill="#3a4467" />
            <polygon points="726.79 41.14 726.79 62.64 729.86 62.64 729.86 65.71 745.21 65.71 745.21 62.64 748.29 62.64 748.29 41.14 726.79 41.14" fill="#3a4467" />
          </g>
        </g>
        <g>
          <rect x="939" y="69" width="36" height="44" fill="#ecf0f1" />
          <rect x="941" y="71" width="32" height="40" fill="#888b9e" />
          <rect x="941" y="85" width="32" height="16" fill="#3a4467" />
          <rect x="941" y="101" width="32" height="10" fill="#ecf0f1" />
          <rect x="941" y="71" width="32" height="14" fill="#252b43" />
          <rect x="939" y="113" width="36" height="8" fill="#c28569" />
          <rect x="945" y="85" width="24" height="16" fill="#ecf0f1" />
          <rect x="947" y="87" width="20" height="12" fill="#5b6986" />
          <rect x="949" y="89" width="16" height="8" fill="#3a4467" />
          <rect x="967" y="107" width="4" height="6" fill="#252b43" />
          <rect x="955" y="107" width="4" height="6" fill="#ebd4ab" />
          <rect x="963" y="107" width="4" height="6" fill="#ebd4ab" />
          <rect x="959" y="107" width="4" height="6" fill="#e43d44" />
          <rect x="967" y="113" width="4" height="2" fill="#252b43" />
          <rect x="951" y="107" width="4" height="6" fill="#ebd4ab" />
          <rect x="951" y="113" width="4" height="2" fill="#b9704f" />
          <rect x="955" y="113" width="4" height="2" fill="#b9704f" />
          <rect x="963" y="113" width="4" height="2" fill="#b9704f" />
          <rect x="959" y="113" width="4" height="2" fill="#a32732" />
          <rect x="943" y="107" width="6" height="2" fill="#252b43" />
          <rect x="943" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="943" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="943" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="945" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="945" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="947" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="947" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="947" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="949" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="949" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="951" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="951" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="951" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="953" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="953" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="955" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="955" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="955" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="957" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="957" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="959" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="959" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="959" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="961" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="961" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="963" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="963" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="963" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="965" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="965" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="967" y="73" width="2" height="2" fill="#3a4467" />
          <rect x="967" y="77" width="2" height="2" fill="#3a4467" />
          <rect x="967" y="81" width="2" height="2" fill="#3a4467" />
          <rect x="969" y="75" width="2" height="2" fill="#3a4467" />
          <rect x="969" y="79" width="2" height="2" fill="#3a4467" />
          <rect x="943" y="103" width="2" height="2" fill="#63c64f" />
          <rect x="951" y="103" width="2" height="2" fill="#5b6986" />
          <rect x="955" y="103" width="2" height="2" fill="#5b6986" />
          <rect x="959" y="103" width="2" height="2" fill="#5b6986" />
          <rect x="967" y="103" width="2" height="2" fill="#5b6986" />
          <rect x="963" y="103" width="2" height="2" fill="#5b6986" />
          <rect x="951" y="91" width="2" height="2" fill="#252b43" />
          <rect x="961" y="91" width="2" height="2" fill="#252b43" />
          <rect x="955" y="91" width="4" height="2" fill="#252b43" />
        </g>
        <g>
          <rect x="224" y="62" width="2" height="2" fill="#5b6986" />
          <rect x="238" y="48" width="2" height="2" fill="#5b6986" />
          <rect x="228" y="48" width="8" height="2" fill="#5b6986" />
          <rect x="224" y="48" width="2" height="2" fill="#5b6986" />
          <rect x="210" y="48" width="2" height="2" fill="#5b6986" />
          <rect x="224" y="38" width="2" height="8" fill="#5b6986" />
          <rect x="224" y="34" width="2" height="2" fill="#5b6986" />
          <path d="M216,60v2h4v2h4V62h2v2h4V62h4V60h2V58h2V54h2V50h-2V48h2V44h-2V40h-2V38h-2V36h-4V34h-4v2h-2V34h-4v2h-4v2h-2v2h-2v4h-2v4h2v2h-2v4h2v4h2v2Zm10-10h-2V48h2Zm10,0h-8V48h8ZM226,38v8h-2V38Z" fill="#ecf0f1" />
          <rect x="218" y="76" width="2" height="4" fill="#733d3b" />
          <rect x="230" y="76" width="2" height="4" fill="#733d3b" />
          <rect x="216" y="70" width="2" height="6" fill="#733d3b" />
          <rect x="232" y="70" width="2" height="6" fill="#733d3b" />
          <rect x="232" y="22" width="2" height="6" fill="#733d3b" />
          <rect x="216" y="22" width="2" height="6" fill="#733d3b" />
          <rect x="230" y="18" width="2" height="4" fill="#733d3b" />
          <rect x="218" y="18" width="2" height="4" fill="#733d3b" />
          <rect x="232" y="76" width="2" height="4" fill="#b9704f" />
          <rect x="216" y="76" width="2" height="4" fill="#b9704f" />
          <polygon points="220 80 230 80 230 76 232 76 232 70 230 70 230 68 220 68 220 70 218 70 218 76 220 76 220 80" fill="#b9704f" />
          <rect x="234" y="68" width="2" height="8" fill="#b9704f" />
          <rect x="214" y="68" width="2" height="8" fill="#b9704f" />
          <rect x="220" y="64" width="10" height="2" fill="#f77622" />
          <rect x="230" y="62" width="4" height="2" fill="#f77622" />
          <rect x="216" y="62" width="4" height="2" fill="#f77622" />
          <rect x="234" y="60" width="2" height="2" fill="#f77622" />
          <rect x="214" y="60" width="2" height="2" fill="#f77622" />
          <rect x="236" y="58" width="2" height="2" fill="#f77622" />
          <rect x="212" y="58" width="2" height="2" fill="#f77622" />
          <rect x="238" y="54" width="2" height="4" fill="#f77622" />
          <rect x="210" y="54" width="2" height="4" fill="#f77622" />
          <rect x="208" y="44" width="2" height="10" fill="#f77622" />
          <rect x="240" y="44" width="2" height="10" fill="#f77622" />
          <rect x="238" y="40" width="2" height="4" fill="#f77622" />
          <rect x="210" y="40" width="2" height="4" fill="#f77622" />
          <rect x="236" y="38" width="2" height="2" fill="#f77622" />
          <rect x="212" y="38" width="2" height="2" fill="#f77622" />
          <rect x="234" y="36" width="2" height="2" fill="#f77622" />
          <rect x="214" y="36" width="2" height="2" fill="#f77622" />
          <rect x="230" y="34" width="4" height="2" fill="#f77622" />
          <rect x="216" y="34" width="4" height="2" fill="#f77622" />
          <rect x="220" y="32" width="10" height="2" fill="#f77622" />
          <rect x="214" y="22" width="2" height="8" fill="#b9704f" />
          <rect x="234" y="22" width="2" height="8" fill="#b9704f" />
          <rect x="216" y="18" width="2" height="4" fill="#b9704f" />
          <rect x="232" y="18" width="2" height="4" fill="#b9704f" />
          <polygon points="218 22 218 28 220 28 220 30 230 30 230 28 232 28 232 22 230 22 230 18 220 18 220 22 218 22" fill="#b9704f" />
          <polygon points="238 60 236 60 236 62 234 62 234 64 230 64 230 66 220 66 220 64 216 64 216 62 214 62 214 60 212 60 212 58 210 58 210 62 212 62 212 66 214 66 214 68 216 68 216 70 220 70 220 68 230 68 230 70 234 70 234 68 236 68 236 66 238 66 238 62 240 62 240 58 238 58 238 60" fill="#fdae35" />
          <rect x="240" y="54" width="2" height="4" fill="#fdae35" />
          <rect x="208" y="54" width="2" height="4" fill="#fdae35" />
          <rect x="206" y="44" width="2" height="10" fill="#fdae35" />
          <polygon points="244 44 242 44 242 54 244 54 244 52 246 52 246 46 244 46 244 44" fill="#fdae35" />
          <rect x="240" y="40" width="2" height="4" fill="#fdae35" />
          <rect x="208" y="40" width="2" height="4" fill="#fdae35" />
          <polygon points="212 38 214 38 214 36 216 36 216 34 220 34 220 32 230 32 230 34 234 34 234 36 236 36 236 38 238 38 238 40 240 40 240 36 238 36 238 32 236 32 236 30 234 30 234 28 230 28 230 30 220 30 220 28 216 28 216 30 214 30 214 32 212 32 212 36 210 36 210 40 212 40 212 38" fill="#fdae35" />
        </g>
        <g>
          <rect x="61" y="325" width="2" height="2" fill="#67386c" />
          <rect x="63" y="321" width="2" height="4" fill="#67386c" />
          <polygon points="67 311 65 311 65 321 67 321 67 317 69 317 69 313 67 313 67 311" fill="#67386c" />
          <polygon points="65 297 65 305 67 305 67 307 69 307 69 301 71 301 71 297 65 297" fill="#c0ccdc" />
          <rect x="75" y="329" width="2" height="2" fill="#a32732" />
          <rect x="63" y="329" width="2" height="2" fill="#a32732" />
          <rect x="73" y="327" width="2" height="2" fill="#a32732" />
          <rect x="61" y="327" width="2" height="2" fill="#a32732" />
          <rect x="69" y="289" width="2" height="2" fill="#a32732" />
          <polygon points="73 313 71 313 71 309 69 309 69 307 67 307 67 305 63 305 63 309 65 309 65 311 67 311 67 313 69 313 69 315 71 315 71 323 73 323 73 327 75 327 75 321 73 321 73 313" fill="#0099dc" />
          <rect x="69" y="285" width="2" height="2" fill="#bf4830" />
          <rect x="75" y="307" width="2" height="2" fill="#f77622" />
          <rect x="73" y="305" width="2" height="2" fill="#f77622" />
          <rect x="59" y="305" width="2" height="6" fill="#f77622" />
          <rect x="71" y="303" width="2" height="2" fill="#f77622" />
          <rect x="69" y="301" width="2" height="2" fill="#f77622" />
          <polygon points="71 297 71 295 69 295 69 289 71 289 71 287 67 287 67 295 63 295 63 299 61 299 61 305 63 305 63 301 65 301 65 297 71 297" fill="#f77622" />
          <rect x="69" y="291" width="2" height="2" fill="#fee861" />
          <polygon points="65 281 65 283 63 283 63 287 61 287 61 293 63 293 63 295 67 295 67 287 69 287 69 285 73 285 73 281 65 281" fill="#fee861" />
        </g>
        <g>
          <polygon points="206 308 206 306 200 306 200 304 194 304 194 302 188 302 188 300 184 300 184 308 190 308 190 310 196 310 196 312 202 312 202 314 208 314 208 316 214 316 214 318 216 318 216 310 212 310 212 308 206 308" fill="#c28569" />
          <polygon points="206 300 206 298 200 298 200 296 194 296 194 294 188 294 188 292 184 292 184 298 188 298 188 300 194 300 194 302 200 302 200 304 206 304 206 306 212 306 212 308 216 308 216 302 212 302 212 300 206 300" fill="#c28569" />
          <polygon points="214 292 208 292 208 290 202 290 202 288 196 288 196 286 190 286 190 284 184 284 184 290 188 290 188 292 194 292 194 294 200 294 200 296 206 296 206 298 212 298 212 300 216 300 216 294 214 294 214 292" fill="#c28569" />
          <rect x="214" y="290" width="2" height="2" fill="#422632" />
          <rect x="216" y="288" width="2" height="2" fill="#733d3b" />
          <rect x="218" y="284" width="2" height="4" fill="#733d3b" />
          <rect x="212" y="308" width="4" height="2" fill="#733d3b" />
          <rect x="206" y="306" width="6" height="2" fill="#733d3b" />
          <rect x="200" y="304" width="6" height="2" fill="#733d3b" />
          <rect x="194" y="302" width="6" height="2" fill="#733d3b" />
          <rect x="212" y="300" width="4" height="2" fill="#733d3b" />
          <rect x="188" y="300" width="6" height="2" fill="#733d3b" />
          <rect x="206" y="298" width="6" height="2" fill="#733d3b" />
          <rect x="184" y="298" width="4" height="2" fill="#733d3b" />
          <rect x="200" y="296" width="6" height="2" fill="#733d3b" />
          <rect x="194" y="294" width="6" height="2" fill="#733d3b" />
          <rect x="188" y="292" width="6" height="2" fill="#733d3b" />
          <rect x="184" y="290" width="4" height="2" fill="#733d3b" />
          <rect x="214" y="292" width="2" height="2" fill="#733d3b" />
          <rect x="216" y="290" width="2" height="2" fill="#733d3b" />
          <rect x="208" y="290" width="6" height="2" fill="#733d3b" />
          <rect x="202" y="288" width="6" height="2" fill="#733d3b" />
          <rect x="196" y="286" width="6" height="2" fill="#733d3b" />
          <rect x="190" y="284" width="6" height="2" fill="#733d3b" />
          <rect x="184" y="282" width="6" height="2" fill="#733d3b" />
          <rect x="220" y="280" width="2" height="4" fill="#733d3b" />
          <polygon points="220 288 218 288 218 292 216 292 216 316 218 316 218 312 220 312 220 308 222 308 222 284 220 284 220 288" fill="#422632" />
          <polygon points="218 284 220 284 220 280 184 280 184 282 190 282 190 284 196 284 196 286 202 286 202 288 208 288 208 290 216 290 216 288 218 288 218 284" fill="#422632" />
        </g>
        <g>
          <polygon points="89 59 89 53 87 53 87 51 85 51 85 49 83 49 83 47 81 47 81 45 75 45 75 43 65 43 65 45 59 45 59 47 57 47 57 49 55 49 55 51 53 51 53 53 51 53 51 59 49 59 49 69 51 69 51 75 53 75 53 77 55 77 55 79 57 79 57 81 59 81 59 83 65 83 65 85 75 85 75 83 81 83 81 81 83 81 83 79 85 79 85 77 87 77 87 75 89 75 89 69 91 69 91 59 89 59" fill="#252b43" />
          <path d="M75,61V59H73V57H67v2H65v2H63v6h2v2h2v2h6V69h2V67h2V61Zm-4,4H69V63h2Z" fill="#e43d44" />
          <rect x="59" y="55" width="2" height="2" fill="#8b9bb5" />
          <rect x="57" y="51" width="2" height="2" fill="#8b9bb5" />
          <rect x="61" y="53" width="2" height="2" fill="#8b9bb5" />
          <rect x="63" y="57" width="2" height="2" fill="#8b9bb5" />
          <rect x="79" y="71" width="2" height="2" fill="#8b9bb5" />
          <rect x="75" y="69" width="2" height="2" fill="#8b9bb5" />
          <rect x="77" y="73" width="2" height="2" fill="#8b9bb5" />
          <rect x="81" y="75" width="2" height="2" fill="#8b9bb5" />
          <rect x="83" y="73" width="2" height="2" fill="#8b9bb5" />
          <rect x="79" y="77" width="2" height="2" fill="#8b9bb5" />
          <rect x="55" y="53" width="2" height="2" fill="#8b9bb5" />
          <rect x="59" y="49" width="2" height="2" fill="#8b9bb5" />
        </g>
        <polygon points="686 635 686 633 684 633 684 631 682 631 682 629 680 629 680 627 678 627 678 625 676 625 676 623 682 623 682 621 680 621 680 619 678 619 678 617 676 617 676 615 674 615 674 613 678 613 678 611 676 611 676 609 674 609 674 607 672 607 672 605 670 605 670 603 668 603 668 605 666 605 666 607 664 607 664 609 662 609 662 611 660 611 660 613 664 613 664 615 662 615 662 617 660 617 660 619 658 619 658 621 656 621 656 623 662 623 662 625 660 625 660 627 658 627 658 629 656 629 656 631 654 631 654 633 652 633 652 635 650 635 650 637 688 637 688 635 686 635" fill="#1a3c3d" />
        <g>
          <rect x="784" y="295" width="2" height="2" fill="#67386c" />
          <polygon points="794 291 792 291 792 293 790 293 790 297 786 297 786 299 788 299 788 301 790 301 790 299 792 299 792 297 794 297 794 295 796 295 796 293 794 293 794 291" fill="#67386c" />
          <polygon points="794 279 794 281 796 281 796 287 794 287 794 289 798 289 798 287 800 287 800 279 794 279" fill="#c0ccdc" />
          <rect x="806" y="311" width="2" height="2" fill="#a32732" />
          <rect x="804" y="309" width="2" height="2" fill="#a32732" />
          <rect x="780" y="295" width="2" height="2" fill="#a32732" />
          <rect x="782" y="293" width="2" height="2" fill="#a32732" />
          <rect x="800" y="271" width="2" height="2" fill="#a32732" />
          <rect x="804" y="301" width="2" height="8" fill="#0099dc" />
          <polygon points="804 297 802 297 802 293 800 293 800 291 798 291 798 289 792 289 792 291 794 291 794 293 796 293 796 295 798 295 798 297 800 297 800 299 802 299 802 301 804 301 804 297" fill="#0099dc" />
          <rect x="800" y="267" width="2" height="2" fill="#bf4830" />
          <polygon points="802 283 802 277 800 277 800 271 802 271 802 269 798 269 798 277 794 277 794 279 800 279 800 285 810 285 810 283 802 283" fill="#f77622" />
          <rect x="790" y="281" width="2" height="8" fill="#f77622" />
          <rect x="792" y="279" width="2" height="2" fill="#f77622" />
          <polygon points="796 263 796 265 794 265 794 269 792 269 792 271 790 271 790 273 792 273 792 275 798 275 798 269 800 269 800 267 802 267 802 265 804 265 804 263 796 263" fill="#fee861" />
        </g>
        <g>
          <polygon points="343 615 347 615 347 619 355 619 355 623 407 623 407 619 415 619 415 615 419 615 419 611 343 611 343 615" fill="#ecf0f1" />
          <polygon points="343 599 343 603 355 603 355 599 351 599 351 595 343 595 343 591 339 591 339 579 347 579 347 571 335 571 335 575 331 575 331 591 335 591 335 599 343 599" fill="#c0ccdc" />
          <polygon points="403 567 403 587 399 587 399 595 395 595 395 599 391 599 391 603 387 603 387 599 391 599 391 595 395 595 395 587 399 587 399 567 347 567 347 591 351 591 351 599 355 599 355 603 359 603 359 607 363 607 363 611 399 611 399 607 403 607 403 603 407 603 407 599 411 599 411 591 415 591 415 567 403 567" fill="#0099dc" />
          <rect x="387" y="599" width="4" height="4" fill="#2ee8f7" />
          <rect x="391" y="595" width="4" height="4" fill="#2ee8f7" />
          <rect x="395" y="587" width="4" height="8" fill="#2ee8f7" />
          <rect x="399" y="567" width="4" height="20" fill="#2ee8f7" />
          <rect x="391" y="559" width="4" height="4" fill="#8b9bb5" />
          <rect x="379" y="559" width="4" height="4" fill="#8b9bb5" />
          <rect x="367" y="559" width="4" height="4" fill="#8b9bb5" />
          <rect x="383" y="551" width="4" height="8" fill="#8b9bb5" />
          <rect x="371" y="551" width="4" height="8" fill="#8b9bb5" />
          <rect x="395" y="551" width="4" height="8" fill="#8b9bb5" />
          <rect x="391" y="547" width="4" height="4" fill="#8b9bb5" />
          <rect x="379" y="547" width="4" height="4" fill="#8b9bb5" />
          <rect x="367" y="547" width="4" height="4" fill="#8b9bb5" />
          <rect x="375" y="539" width="4" height="8" fill="#8b9bb5" />
          <rect x="363" y="539" width="4" height="8" fill="#8b9bb5" />
          <rect x="387" y="539" width="4" height="8" fill="#8b9bb5" />
          <rect x="391" y="535" width="4" height="4" fill="#8b9bb5" />
          <rect x="379" y="535" width="4" height="4" fill="#8b9bb5" />
          <rect x="367" y="535" width="4" height="4" fill="#8b9bb5" />
          <rect x="355" y="619" width="52" height="4" fill="#8b9bb5" />
          <rect x="347" y="615" width="68" height="4" fill="#8b9bb5" />
          <polygon points="375 607 375 599 371 599 371 567 347 567 347 591 351 591 351 599 355 599 355 603 359 603 359 607 363 607 363 611 399 611 399 607 375 607" fill="#7f1938" opacity="0.3" />
        </g>
      </g>
    </svg>
  );
};
