import React from 'react';

export const DropzoneGlow = () => {

  return (
    <svg id="droplet-glow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 250" width="200" height="250" fill="#fff">
      <g className="bursting">
        <line x1="143.68" y1="84.99" x2="162.48" y2="75.07" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="160.66" y1="109.61" x2="179.45" y2="99.68" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="152.08" y1="97.52" x2="162.21" y2="91.59" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="127.64" y1="62.65" x2="148.54" y2="48.92" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="118.64" y1="51.32" x2="128.54" y2="42.92" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="136.57" y1="73.9" x2="148.88" y2="66.59" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="98.9" y1="27.51" x2="97.66" y2="6.75" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="110.38" y1="40.2" x2="124.54" y2="24.92" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="165.76" y1="120.55" x2="175.16" y2="116.2" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="170.25" y1="131.25" x2="190.03" y2="123.32" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="173.68" y1="143.56" x2="183.9" y2="141.64" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="174.39" y1="155.26" x2="195.71" y2="156.92" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="172.47" y1="167.91" x2="182.47" y2="170.72" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="169.54" y1="179.92" x2="187.88" y2="191.44" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="162.92" y1="191.48" x2="170.4" y2="198.51" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="152.94" y1="201.98" x2="169.4" y2="215.25" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="143.81" y1="210.81" x2="150.33" y2="218.69" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="133.1" y1="216.37" x2="143.28" y2="234.65" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="121.24" y1="219.71" x2="124.27" y2="229.39" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="106.79" y1="222.8" x2="109.48" y2="243.42" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="93.91" y1="223.18" x2="93.13" y2="233.27" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="79.61" y1="221.5" x2="76.54" y2="242.08" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="67.1" y1="218.54" x2="63.58" y2="228.05" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="55.43" y1="211.94" x2="44.38" y2="229.74" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="45.08" y1="204.49" x2="38.03" y2="211.93" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="37.04" y1="195.68" x2="19.29" y2="207.28" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="30.91" y1="184.68" x2="21.28" y2="188.53" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="26.1" y1="171.42" x2="5.07" y2="175.23" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="24.95" y1="158.95" x2="14.54" y2="158.78" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="24.94" y1="146.32" x2="3.66" y2="144.13" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="27.5" y1="134.05" x2="17.58" y2="131" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="31.74" y1="120.01" x2="12.03" y2="111.93" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="37.85" y1="108.99" x2="29.26" y2="103.28" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="45.54" y1="95.92" x2="26.58" y2="82.86" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="52.54" y1="84.92" x2="45.18" y2="77.51" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="61.54" y1="72.92" x2="42.79" y2="60.12" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="69.54" y1="62.25" x2="61.39" y2="54.1" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="78.54" y1="50.59" x2="60.54" y2="34.59" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
        <line x1="87.54" y1="39.92" x2="76.54" y2="26.92" fill="none" stroke="#fff" strokeWidth="2.5" strokeDasharray="40" strokeDashoffset="40"/>
      </g>
    </svg>
  );
};
