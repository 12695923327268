import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_CKLA_FIRST_ENCOUNTERS = createRequestTypes(API_ACTION_TYPES.GET_CKLA_FIRST_ENCOUNTERS);
const GET_AR_FIRST_ENCOUNTERS = createRequestTypes(API_ACTION_TYPES.GET_AR_FIRST_ENCOUNTERS);
const GET_KS_WORD_LIST = createRequestTypes(API_ACTION_TYPES.GET_KS_WORD_LIST);
const GET_WORDS_BY_STREAM = createRequestTypes(API_ACTION_TYPES.GET_WORDS_BY_STREAM);
const GET_WORD_DATA = createRequestTypes(API_ACTION_TYPES.GET_WORD_DATA);
const GET_UNIT_SUBUNIT = createRequestTypes(API_ACTION_TYPES.GET_UNIT_SUBUNIT);
const GET_WORDS_BY_UNIT_SUBUNIT = createRequestTypes(API_ACTION_TYPES.GET_WORDS_BY_UNIT_SUBUNIT);

export const wordsDataActions = {
  GET_CKLA_FIRST_ENCOUNTERS,
  GET_AR_FIRST_ENCOUNTERS,
  GET_KS_WORD_LIST,
  GET_WORDS_BY_STREAM,
  GET_WORDS_BY_UNIT_SUBUNIT,
  GET_WORD_DATA,
  GET_UNIT_SUBUNIT,
};

export const wordsDataActionCreators = {
  getCklaFirstEncounters: { ...createActions(GET_CKLA_FIRST_ENCOUNTERS) },
  getArFirstEncounters: { ...createActions(GET_AR_FIRST_ENCOUNTERS) },
  getKSWordList: { ...createActions(GET_KS_WORD_LIST) },
  getWordsByStream: { ...createActions(GET_WORDS_BY_STREAM) },
  getWordsByUnitSubunit: { ...createActions(GET_WORDS_BY_UNIT_SUBUNIT) },
  getWordData: { ...createActions(GET_WORD_DATA) },
  getUnitSubunit: { ...createActions(GET_UNIT_SUBUNIT) },
};

// Non Fetch

const SET_WORDS = '[Static Words] SET_WORDS';
const SET_SHOW_WORDS = '[Static Words] SET_SHOW_WORDS';
const SET_LESSONS = '[Static Words] SET_LESSONS';
const SET_SHOW_LESSONS = '[Static Words] SET_SHOW_LESSONS';
const SET_WORDS_GRADE_FILTER = '[Static Words] SET_WORDS_GRADE_FILTER';
const SET_WORDS_STREAM_FILTER = '[Static Words] SET_WORDS_STREAM_FILTER';
const SET_WORDS_UNIT_FILTER = '[Static Words] SET_WORDS_UNIT_FILTER';
const SET_WORDS_SUBUNIT_FILTER = '[Static Words] SET_WORDS_SUBUNIT_FILTER';
const SET_VIEW_OPTION_FILTER = '[Static Words] SET_VIEW_OPTION_FILTER';
const SET_IS_CORE = '[Static Words] SET_IS_CORE';
const SET_BATCH_NUMBER = '[Static Words] SET_BATCH_NUMBER';
const SET_FILTER_WORDS_MODE = '[Static Words] SET_FILTER_WORDS_MODE';
const SET_ACTIVE_WORDS_LIST_BK = '[Static Words] SET_ACTIVE_WORDS_LIST_BK';
const RESET_WORD_DETAILS = '[Static Words] RESET_WORD_DETAILS';
const SET_FINISHED_PLAY = '[Static Words] SET_FINISH_PLAY';
const SET_NO_FIRST_ENCOUNTERS = '[Static Words] SET_NO_FIRST_ENCOUNTERS';

export const wordsStaticActions = {
  SET_WORDS,
  SET_SHOW_WORDS,
  SET_LESSONS,
  SET_SHOW_LESSONS,
  SET_WORDS_GRADE_FILTER,
  SET_WORDS_STREAM_FILTER,
  SET_WORDS_UNIT_FILTER,
  SET_WORDS_SUBUNIT_FILTER,
  SET_IS_CORE,
  SET_BATCH_NUMBER,
  SET_FILTER_WORDS_MODE,
  SET_ACTIVE_WORDS_LIST_BK,
  SET_VIEW_OPTION_FILTER,
  RESET_WORD_DETAILS,
  SET_FINISHED_PLAY,
  SET_NO_FIRST_ENCOUNTERS,
};

export const setNoFirstEncounters = () => {
  return {
    type: SET_NO_FIRST_ENCOUNTERS,
  };
};

export const setFinishedPlay = (newFinishedPlay) => {
  return {
    type: SET_FINISHED_PLAY,
    payload: newFinishedPlay,
  };
};

export const resetWordDetails = () => {
  return {
    type: RESET_WORD_DETAILS,
  };
};

export const setActiveWordsListBK = (wordsList) => {
  return {
    type: SET_ACTIVE_WORDS_LIST_BK,
    payload: wordsList,
  };
};

export const setFilterWordsMode = (filterMode) => {
  return {
    type: SET_FILTER_WORDS_MODE,
    payload: filterMode,
  };
};

export const setBatchNumber = (batchNumber) => {
  return {
    type: SET_BATCH_NUMBER,
    payload: batchNumber,
  };
};

export const setWords = (words) => {
  return {
    type: SET_WORDS,
    payload: words,
  };
};

export const setShowWords = (words) => {
  return {
    type: SET_SHOW_WORDS,
    payload: words,
  };
};

export const setLessons = (lessons) => {
  return {
    type: SET_LESSONS,
    payload: lessons,
  };
};

export const setShowLessons = (lessons) => {
  return {
    type: SET_SHOW_LESSONS,
    payload: lessons,
  };
};

export const setWordsGradeFilter = (gradeOption) => {
  return {
    type: SET_WORDS_GRADE_FILTER,
    payload: gradeOption,
  };
};

export const setWordsStreamFilter = (streamOption) => {
  return {
    type: SET_WORDS_STREAM_FILTER,
    payload: streamOption,
  };
};

export const setViewFilter = (viewFilter) => {
  return {
    type: SET_VIEW_OPTION_FILTER,
    payload: viewFilter,
  };
};

export const setUnitFilter = (unitFilter) => {
  return {
    type: SET_WORDS_UNIT_FILTER,
    payload: unitFilter,
  };
};

export const setSubunitFilter = (subunitFilter) => {
  return {
    type: SET_WORDS_SUBUNIT_FILTER,
    payload: subunitFilter,
  };
};

export const setIsCore = (wordsView) => {
  return {
    type: SET_IS_CORE,
    payload: wordsView,
  };
};
