import React, { useLayoutEffect } from 'react';

import { gsap } from 'gsap';

export const Transition = () => {

  useLayoutEffect(() => {
    const tl = gsap.timeline()
      .from('#letters1 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' })
      .add(gsap.from('#letters2 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' }), 0.5)
      .add(gsap.from('#letters3 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' }), 1)
      .add(gsap.from('#letters4 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' }), 1.5)
      .add(gsap.from('#letters5 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' }), 2)
      .add(gsap.from('#letters6 > g', { duration: 1, rotate: -10, y:-830, stagger: 0.05, ease: 'sine.in' }), 2.5)
      .to('#letters1, #letters2, #letters3, #letters4, #letters5, #letters6', { duration: 1, y:830, stagger: 0.05, delay: 1, ease: 'sine.in' });

    tl.timeScale(1.5).play(0);

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg id="undelivered-transition" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <g id="letters6">
        <g>
          <path fill="#E2070F" d="M684.3,18.9l10.6,248.9l136-5.8L820.3,13.1L684.3,18.9z
             M692.8,27l119.8-5.1l9.9,232L702.6,259L692.8,27z"/>
          <rect x="697.6" y="24.3" transform="matrix(0.9991 -4.257289e-02 4.257289e-02 0.9991 -5.2904 32.3808)" fill="#E0DCC1" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="684.8,29.8 711.5,17.7 688.6,18.7 684.4,20.6     "/>
          <path fill="#0B99D6" d="M685.5,48.1l8-3.6l-0.4-9.2l-8,3.6L685.5,48.1z M736.5,25.1
            l20.7-9.4l-22.9,1l-20.8,9.4L736.5,25.1z"/>
          <path fill="#0B99D6" d="M686.3,66.4l8-3.6l-0.4-9.2l-8,3.6L686.3,66.4z M782.3,23.2
            l20.7-9.4l-22.9,1l-20.7,9.4L782.3,23.2z"/>
          <path fill="#0B99D6" d="M687.1,84.8l8-3.6l-0.4-9.2l-8,3.6L687.1,84.8z M812.9,28.1
            l8-3.6l-0.4-9.2l-15.2,6.8l7.3-0.3L812.9,28.1z"/>
          <path fill="#0B99D6" d="M687.9,103.2l8-3.6l-0.4-9.2l-8,3.6L687.9,103.2z M813.6,46.5
            l8-3.6l-0.4-9.2l-8,3.6L813.6,46.5z"/>
          <path fill="#0B99D6" d="M688.7,121.5l8-3.6l-0.4-9.2l-8,3.6
            C688.3,112.4,688.7,121.5,688.7,121.5z M814.4,64.8l8-3.6L822,52l-8,3.6L814.4,64.8z"/>
          <path fill="#0B99D6" d="M689.4,139.9l8-3.6l-0.4-9.2l-8,3.6L689.4,139.9z M815.2,83.2
            l8-3.6l-0.4-9.2l-8,3.6L815.2,83.2z"/>
          <path fill="#0B99D6" d="M690.2,158.2l8-3.6l-0.4-9.2l-8,3.6L690.2,158.2z M816,101.5
            l8-3.6l-0.4-9.2l-8,3.6L816,101.5z"/>
          <path fill="#0B99D6" d="M691,176.6l8-3.6l-0.4-9.2l-8,3.6L691,176.6z M816.8,119.9l8-3.6
            l-0.4-9.2l-8,3.6L816.8,119.9z"/>
          <path fill="#0B99D6" d="M691.8,194.9l8-3.6l-0.4-9.2l-8,3.6L691.8,194.9z M817.6,138.2
            l8-3.6l-0.4-9.2l-8,3.6L817.6,138.2z"/>
          <path fill="#0B99D6" d="M692.6,213.3l8-3.6l-0.4-9.2l-8,3.6L692.6,213.3z M818.3,156.6
            l8-3.6l-0.4-9.2l-8,3.6L818.3,156.6z"/>
          <path fill="#0B99D6" d="M693.3,231.6l8-3.6l-0.4-9.2l-8,3.6L693.3,231.6z M819.1,174.9
            l8-3.6l-0.4-9.2l-8,3.6L819.1,174.9z"/>
          <path fill="#0B99D6" d="M694.1,250l8-3.6l-0.4-9.2l-8,3.6L694.1,250z M819.9,193.3l8-3.6
            l-0.4-9.2l-8,3.6L819.9,193.3z"/>
          <path fill="#0B99D6" d="M696.2,267.7l20.8-9.3l-14.3,0.6l-0.2-3.4l-8,3.6l0.4,8.6
            L696.2,267.7L696.2,267.7z M820.7,211.6l8-3.6l-0.4-9.2l-8,3.6L820.7,211.6z"/>
          <path fill="#0B99D6" d="M742,265.8l20.7-9.4l-22.9,1l-20.7,9.4L742,265.8z M821.5,230
            l8-3.6l-0.4-9.2l-8,3.6L821.5,230z"/>
          <g>
            <path fill="#80CAF0" d="M718.8,181.3c0.4,8.7,7.7,15.4,16.4,15s15.4-7.7,15-16.4
              s-7.7-15.4-16.4-15c-4.1,0.2-8,2-10.8,5.1C720.1,173.1,718.6,177.1,718.8,181.3L718.8,181.3z M725.5,190.4
              c-5.4-4.9-5.7-13.3-0.8-18.7s13.3-5.7,18.7-0.8s5.7,13.3,0.8,18.7c-2.4,2.6-5.7,4.1-9.2,4.3C731.5,194,728.1,192.8,725.5,190.4z"
            />
            <path fill="#80CAF0" d="M728.3,182.8c0.4-0.2,0.5-0.7,0.3-1.1s-0.7-0.5-1.1-0.3
              c0,0,0,0-0.1,0c0,0-9.8,6.5-4.2,14c1.9,2.5,3.2,4,4.1,5c1.3,1.4,1.5,1.7,1.2,2.9c-0.4,0.8-0.9,1.5-1.6,2.1c-2,2-4.9,4.9-4.5,10.9
              c0.3,5.2,1.8,6.2,3.7,7.5c1.7,1.1,3,2.6,3.7,4.5c1.4,3.1,1.2,5.5,0.4,7.3c-0.8,1.6-2.1,3-3.6,4c-3.1,2.2-2.9,6.6-2.9,6.6
              c0,0.5,0.4,0.8,0.9,0.8s0.8-0.4,0.8-0.9l0,0c0,0-0.2-3.6,2.2-5.2c1.8-1.1,3.2-2.8,4.2-4.7c1-2.2,1.2-5-0.4-8.7
              c-0.8-2.1-2.3-3.9-4.3-5.2c-1.5-1-2.7-1.9-3-6.3c-0.3-5.3,2.2-7.8,4-9.6c1-0.8,1.7-1.8,2.1-2.9c0.4-2,0.1-2.4-1.6-4.4
              c-0.9-1-2.1-2.4-4-4.9C720,188.3,728.3,182.8,728.3,182.8L728.3,182.8z M743.7,182.1c0,0-8.3,5.5-3.8,11.6c1.8,2.5,3.1,3.9,4,4.9
              c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.3,4.3-4,9.6c0.3,4.4,1.5,5.2,3,6.3c1.9,1.2,3.4,3,4.3,5.2
              c1.6,3.6,1.4,6.5,0.4,8.7c-0.9,1.9-2.4,3.5-4.2,4.7c-2.4,1.6-2.2,5.2-2.2,5.2c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.3-0.9-0.8l0,0
              c0,0-0.2-4.5,2.9-6.6c1.5-1,2.8-2.4,3.6-4c0.8-1.8,1-4.2-0.4-7.3c-0.7-1.9-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.3-3.7-7.5
              c-0.4-6.1,2.4-8.9,4.5-10.9c0.7-0.6,1.3-1.3,1.6-2.1c0.2-1.2,0-1.5-1.2-3c-0.9-1-2.2-2.5-4.1-5c-5.5-7.5,4.2-14,4.2-14
              c0.4-0.3,0.9-0.2,1.2,0.2S744.1,181.9,743.7,182.1C743.8,182,743.8,182.1,743.7,182.1L743.7,182.1z M739.9,182.3
              c0,0-8.3,5.5-3.8,11.6c1.8,2.5,3.1,3.9,4,4.9c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.3,4.3-4,9.6
              c0.3,4.4,1.5,5.2,3,6.3c1.9,1.2,3.4,3,4.3,5.2c1.6,3.6,1.4,6.5,0.4,8.7c-0.9,1.9-2.4,3.5-4.2,4.7c-2.4,1.6-2.2,5.2-2.2,5.2
              c0,0.5-0.3,0.8-0.8,0.9l0,0c-0.5,0-0.8-0.3-0.9-0.8l0,0c0,0-0.2-4.5,2.9-6.6c1.5-1,2.8-2.4,3.6-4c0.8-1.8,1-4.2-0.4-7.3
              c-0.7-1.8-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.3-3.7-7.5c-0.4-6.1,2.4-8.9,4.5-10.9c0.7-0.6,1.3-1.3,1.6-2.1c0.2-1.2,0-1.5-1.2-2.9
              c-0.9-1-2.2-2.5-4.1-5c-5.5-7.5,4.2-14,4.2-14c0.4-0.2,0.9-0.1,1.1,0.3C740.4,181.5,740.3,182,739.9,182.3L739.9,182.3z
               M736,182.4c0,0-8.3,5.5-3.8,11.6c1.8,2.5,3.1,3.9,4,4.9c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.3,4.3-4,9.7
              c0.3,4.4,1.5,5.2,3,6.3c1.9,1.2,3.4,3,4.3,5.2c1.6,3.6,1.4,6.5,0.4,8.7c-0.9,1.9-2.4,3.5-4.2,4.7c-2.3,1.6-2.2,5.2-2.2,5.2
              c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.3-0.9-0.8l0,0c0,0-0.2-4.5,2.9-6.6c1.5-1,2.8-2.4,3.6-4c0.8-1.8,1-4.2-0.4-7.3
              c-0.7-1.8-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.3-3.7-7.5c-0.4-6.1,2.4-8.9,4.5-10.9c0.7-0.6,1.3-1.3,1.6-2.1c0.2-1.2,0-1.5-1.2-2.9
              c-0.9-1-2.2-2.5-4.1-5c-5.5-7.5,4.2-14,4.2-14c0.4-0.3,0.9-0.2,1.2,0.2C736.6,181.7,736.5,182.2,736,182.4L736,182.4z
               M732.2,182.6c0,0-8.3,5.5-3.8,11.6c1.8,2.5,3.1,3.9,4,4.9c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.3,4.3-4,9.6
              c0.3,4.4,1.5,5.2,3,6.3c1.9,1.2,3.4,3,4.3,5.2c1.6,3.6,1.4,6.5,0.4,8.7c-0.9,1.9-2.4,3.5-4.2,4.7c-2.4,1.6-2.2,5.2-2.2,5.2
              c0,0.5-0.4,0.8-0.9,0.8c-0.4,0-0.7-0.3-0.8-0.7c0,0-0.2-4.5,2.9-6.6c1.5-1,2.8-2.4,3.6-4c0.8-1.8,1-4.2-0.4-7.3
              c-0.7-1.8-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.3-3.7-7.5c-0.4-6.1,2.4-8.9,4.5-10.9c0.7-0.6,1.3-1.3,1.6-2.1c0.2-1.2,0-1.5-1.2-2.9
              c-0.9-1-2.2-2.5-4.1-5c-5.5-7.5,4.2-14,4.2-14c0.4-0.3,0.9-0.2,1.2,0.2C732.7,181.8,732.6,182.4,732.2,182.6L732.2,182.6
              L732.2,182.6z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M796.7,168.6l0.1,2.1l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0,0l0.1,1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4
              l0,0l0.1,1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0,0v1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0,0v1.2
              l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0,0l0.1,2.1l-1.8,0.1c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2
              c0-0.8-0.7-1.4-1.5-1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l-1.2,0.1c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4
              s-1.4,0.7-1.4,1.5l-1.4,0.1c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l-1.2,0.1c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5H771
              c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l-1.2,0.1c0-0.8-0.7-1.4-1.5-1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0l-1.8,0.1l-0.1-2.1l0,0
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0l-0.1-1.2l0,0
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0l-0.1-1.4l0,0
              c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0l-0.1-2.1l1.7-0.1c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l1.2-0.1
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l1.2-0.1c0,0.8,0.7,1.4,1.5,1.4
              s1.4-0.7,1.4-1.5l1.4-0.1c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5L796.7,168.6z"/>

            <rect x="765.1" y="172.3" transform="matrix(0.9991 -4.239882e-02 4.239882e-02 0.9991 -6.9859 33.215)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M788.8,241.8l-2.5,0.1l0,0c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.8,1.7c0,0.1,0,0.1,0,0.2l0,0l-1.7,0.1l0,0
              c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.8,1.7c0,0.1,0,0.1,0,0.2l0,0l-1.5,0.1l0,0c-0.1-0.9-0.9-1.6-1.9-1.5
              c-0.9,0.1-1.5,0.8-1.5,1.7l0,0l-1.5,0.1l0,0c-0.1-0.9-0.9-1.6-1.9-1.5c-0.9,0.1-1.5,0.8-1.5,1.7l0,0l-1.5,0.1l0,0
              c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.8,1.7c0,0.1,0,0.1,0,0.2l0,0l-2.5,0.1l-0.1-2.1c0.9,0,1.7-0.7,1.7-1.7
              c0-0.9-0.7-1.7-1.7-1.7c-0.1,0-0.1,0-0.2,0l-0.1-1.5c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0l-0.1-1.5
              c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0l-0.1-1.5c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0l-0.1-1.7
              c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.1,0-0.1,0-0.2,0l-0.1-1.5c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0
              l-0.1-1.5c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0l-0.1-1.5c0.9,0,1.7-0.8,1.6-1.8c0-0.9-0.8-1.7-1.8-1.6l0,0l-0.1-2.1
              l2.5-0.1l0,0c0,0.9,0.8,1.7,1.8,1.6c0.9,0,1.7-0.8,1.6-1.8l0,0l1.5-0.1l0,0c0,0.9,0.8,1.7,1.8,1.6s1.7-0.8,1.6-1.8l0,0l1.5-0.1
              l0,0c0,0.9,0.8,1.7,1.8,1.6s1.7-0.8,1.6-1.8l0,0l1.5-0.1l0,0c0,0.9,0.8,1.7,1.8,1.6c0.9,0,1.7-0.8,1.6-1.8l0,0l0,0l1.7-0.1l0,0
              c0,0.9,0.8,1.7,1.8,1.6s1.7-0.8,1.6-1.8l0,0l2.5-0.1l0.1,2.1c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0.1,1.5
              c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0.1,1.5c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0.1,1.5
              c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.1,0,0.1,0,0.2,0l0.1,1.7c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0.1,1.5
              c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0.1,1.5c-0.9,0-1.7,0.8-1.6,1.8c0,0.9,0.8,1.7,1.8,1.6l0,0l0.1,1.5
              c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.1,0,0.1,0,0.2,0L788.8,241.8z"/>

            <rect x="763" y="204.1" transform="matrix(0.9991 -4.239882e-02 4.239882e-02 0.9991 -8.6871 33.005)" fill="#FBD4A4" width="21.5" height="34.4"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M814,228.5l-2.1,0.1l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l0,0l-1.4,0.1l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5
              l0,0h-1.2l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l-1.2,0.1l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l0,0h-1.2
              l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l-2.1,0.1l-0.1-1.8c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2
              c0.8,0,1.4-0.6,1.5-1.4c0-0.8-0.6-1.4-1.4-1.5c-0.1,0-0.1,0-0.2,0v-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l-0.1-1.4c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4v-1.2c0.8,0,1.4-0.7,1.4-1.5
              s-0.7-1.4-1.5-1.4v-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l-0.1-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l-0.1-1.8
              l2.1-0.1l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l0,0l1.1,0.1l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l1.2-0.1l0,0
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l0,0h1.2l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l1.4-0.1l0,0
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l0,0l2.1-0.1l0.1,1.7c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0.1,1.2
              c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.6-1.5,1.4
              c0,0.8,0.6,1.4,1.4,1.5c0.1,0,0.1,0,0.2,0l0.1,1.4c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.7-1.4,1.5
              s0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4
              L814,228.5z"/>

            <rect x="792.4" y="196.9" transform="matrix(0.9991 -4.239882e-02 4.239882e-02 0.9991 -8.2403 34.1684)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="710.2" y="47.7" transform="matrix(0.9992 -3.996808e-02 3.996808e-02 0.9992 -3.1105 28.5128)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="798.7" y="37.5" transform="matrix(0.9991 -4.257289e-02 4.257289e-02 0.9991 -2.7592 34.1349)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="788.3" y="38.3" transform="matrix(0.9991 -4.257289e-02 4.257289e-02 0.9991 -2.8028 33.6929)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="777.5" y="38.2" transform="matrix(0.9991 -4.257289e-02 4.257289e-02 0.9991 -2.8083 33.233)" fill="#236095" width="2.7" height="88.7"/>
        </g>
        <g>
          <polygon fill="#FFFFFF" points="1036.7,184.9 916,0.5 1051.8,-50.6 1172.6,133.8     "/>
          <path fill="#E6E6E5" d="M916,0.5l119,46.3c17.1,6.7,28.6,23.3,25.4,36.9l-23.7,101.2
            L916,0.5z"/>
          <path fill="#0B99D6" d="M1053.2,75c0.7,0,1.1,0.5,1.1,1c-0.2,0.6-0.7,0.9-1.3,0.8
            c0,0-16.8-1-14.9-10.9c0.4-2.3,1-4.6,1.7-6.9c0.6-2,0.7-2.4-0.4-3.4c-1.1-0.6-2.3-1-3.5-1.1c-4-0.8-9.5-2-13.1-7.9
            c-3.1-5-2-6.8-0.5-9c1.4-1.8,1.9-4,1.6-6.2c-0.2-3-1.8-5.6-4.5-7.1c-2.3-1.1-4.8-1.7-7.3-1.8c-3.3-0.3-6.3-2-8.2-4.7
            c-0.3-0.4,0-1,0.6-1.2s1.2-0.1,1.6,0.4c1.5,2.1,3.8,3.4,6.3,3.7c2.9,0.1,5.8,0.9,8.5,2.2c3.1,1.7,5.2,4.8,5.4,8.4
            c0.4,2.5-0.3,5.1-1.9,7.1c-1.2,1.8-2.1,3.2,0.5,7.5c3.2,5.2,8.1,6.2,11.6,7c2,0.4,3.6,0.8,4.6,1.7c1.9,1.7,1.8,2.2,0.9,5
            c-0.7,2.2-1.2,4.4-1.7,6.7c-1.5,8.1,12.7,8.9,12.8,8.9L1053.2,75z M1073.2,67.5c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7
            c0.8-2.8,1-3.2-0.9-5c-1-0.9-2.6-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7c-2.6-4.3-1.7-5.7-0.5-7.4c1.6-2,2.2-4.6,1.9-7.1
            c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7c-0.4-0.5-1-0.6-1.6-0.4c-0.6,0.2-0.8,0.8-0.6,1.2
            c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.4,7.1c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9
            c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1,1.4,0.4,3.4c-0.7,2.3-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9
            c0.6,0.1,1.1-0.3,1.3-0.8C1074.4,68,1073.9,67.6,1073.2,67.5L1073.2,67.5z M1068.2,69.4c0,0-14.3-0.8-12.8-8.9
            c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.3-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7c-2.6-4.3-1.7-5.7-0.6-7.5
            c1.6-2,2.2-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.4-2.2c-2.5-0.3-4.9-1.6-6.3-3.7c-0.4-0.5-1-0.6-1.6-0.4
            c-0.6,0.2-0.8,0.8-0.5,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.4,7.1c0.3,2.2-0.3,4.4-1.6,6.2
            c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1.1,1.4,0.4,3.4c-0.7,2.3-1.3,4.5-1.7,6.9
            c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8C1069.3,69.8,1068.9,69.4,1068.2,69.4L1068.2,69.4z M1063.2,71.2
            c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.2-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7
            c-2.6-4.3-1.7-5.7-0.6-7.5c1.6-2,2.2-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7
            c-0.4-0.5-1-0.6-1.6-0.4c-0.6,0.2-0.8,0.8-0.5,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1
            c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1.1,1.4,0.4,3.4
            c-0.7,2.3-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8c0.1-0.5-0.4-0.9-1.1-1L1063.2,71.2z M1058.2,73.1
            c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.3-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7
            c-2.6-4.3-1.7-5.7-0.5-7.5c1.5-2,2.2-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7
            c-0.4-0.5-1-0.6-1.6-0.4c-0.6,0.2-0.8,0.8-0.5,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1
            c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1.1,1.4,0.4,3.4
            c-0.7,2.3-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8C1059.3,73.6,1058.8,73.2,1058.2,73.1L1058.2,73.1
            z"/>
        </g>
        <g>
          <path fill="#A0D8F7" d="M916.6-47L742.7,46.2l50.9,95l173.8-93.1L916.6-47L916.6-47z
             M913.7-38.1l44.8,83.7l-162,86.8l-44.8-83.7L913.7-38.1z"/>

          <rect x="763.2" y="-0.3" transform="matrix(0.8816 -0.4721 0.4721 0.8816 79.024 409.2742)" fill="#CCCCCE" width="183.8" height="94.9"/>
          <g>
            <path fill="#0B99D6" d="M896.1-36l5.3,4.5l6.4-3.4l-5.3-4.5L896.1-36z M930.1-7.5
              L943.9,4l-8.5-16l-13.8-11.5L930.1-7.5z"/>
            <path fill="#0B99D6" d="M883.3-29.1l5.3,4.4l6.4-3.4l-5.3-4.4L883.3-29.1L883.3-29.1z
               M947.2,24.5L961,36l-8.6-16L938.7,8.5L947.2,24.5z"/>
            <path fill="#0B99D6" d="M870.5-22.3l5.3,4.4l6.4-3.4l-5.3-4.5
              C876.9-25.7,870.5-22.3,870.5-22.3z M954.2,47.9l5.3,4.4l6.4-3.4l-10.1-8.5l2.7,5.1L954.2,47.9z"/>
            <path fill="#0B99D6" d="M857.7-15.4L863-11l6.4-3.4l-5.3-4.4L857.7-15.4L857.7-15.4z
               M941.3,54.8l5.3,4.5l6.4-3.4l-5.3-4.5L941.3,54.8z"/>
            <path fill="#0B99D6" d="M844.9-8.6l5.3,4.4l6.4-3.4l-5.3-4.5L844.9-8.6L844.9-8.6z
               M928.5,61.6l5.3,4.5l6.4-3.4l-5.3-4.4L928.5,61.6z"/>
            <path fill="#0B99D6" d="M832.1-1.7l5.3,4.4l6.4-3.4l-5.3-4.4
              C838.5-5.1,832.1-1.7,832.1-1.7z M915.7,68.5l5.3,4.4l6.4-3.4l-5.3-4.4L915.7,68.5z"/>
            <path fill="#0B99D6" d="M819.2,5.2l5.3,4.4l6.5-3.4l-5.3-4.4L819.2,5.2L819.2,5.2z
               M902.9,75.4l5.3,4.4l6.4-3.4l-5.3-4.4L902.9,75.4z"/>
            <path fill="#0B99D6" d="M806.4,12l5.3,4.4l6.4-3.4l-5.3-4.4L806.4,12z M890.1,82.2
              l5.3,4.4l6.4-3.4l-5.3-4.4L890.1,82.2z"/>
            <path fill="#0B99D6" d="M793.6,18.9l5.3,4.4l6.4-3.4l-5.3-4.4L793.6,18.9L793.6,18.9z
               M877.3,89.1l5.3,4.4l6.4-3.4l-5.3-4.4L877.3,89.1z"/>
            <path fill="#0B99D6" d="M780.8,25.8l5.3,4.4l6.4-3.4l-5.3-4.5L780.8,25.8z M864.5,96
              l5.3,4.4l6.4-3.4l-5.3-4.4L864.5,96z"/>
            <path fill="#0B99D6" d="M768,32.6l5.3,4.5l6.4-3.4l-5.3-4.5L768,32.6z M851.7,102.8
              l5.3,4.4l6.4-3.4l-5.3-4.5L851.7,102.8z"/>
            <path fill="#0B99D6" d="M755.2,39.5l5.3,4.5l6.4-3.4l-5.3-4.4
              C761.6,36.1,755.2,39.5,755.2,39.5z M838.8,109.7l5.3,4.4l6.4-3.4l-5.3-4.4L838.8,109.7z"/>
            <path fill="#0B99D6" d="M743.2,47.1L757,58.7l-5.4-10l2.4-1.3l-5.3-4.5l-6,3.2
              L743.2,47.1L743.2,47.1z M826,116.5l5.3,4.4l6.4-3.4l-5.3-4.4L826,116.5z"/>
            <path fill="#0B99D6" d="M760.4,79.1l13.8,11.6l-8.5-16l-13.8-11.6L760.4,79.1z
               M813.2,123.4l5.3,4.4l6.4-3.4l-5.3-4.4L813.2,123.4z"/>
            <path fill="#0B99D6" d="M777.5,111.1l13.8,11.6l-8.5-16L769,95.1L777.5,111.1
              L777.5,111.1z M800.4,130.3l5.3,4.4l6.4-3.4l-5.3-4.4L800.4,130.3z"/>
            <polygon fill="#0B99D6" points="799.3,138.2 786.1,127.1 793.6,141.2       "/>
          </g>
        </g>
        <g>

          <rect x="385.9" y="-62" transform="matrix(0.1821 -0.9833 0.9833 0.1821 327.06 453.7485)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M536-2.5l-81.5,38.7c-11.7,5.6-28.3,2.8-36.8-6.1l-63.1-66.2
            L536-2.5z"/>
          <g>
            <path fill="#0B99D6" d="M426.8,21.6c8.2,1.5,13.6,9.4,12.1,17.6s-9.4,13.6-17.6,12.1
              s-13.6-9.4-12.1-17.6c0.7-3.9,3-7.4,6.3-9.7C418.8,21.7,422.9,20.9,426.8,21.6L426.8,21.6z M434.5,29.2c-4-5.8-11.9-7.3-17.6-3.3
              s-7.3,11.9-3.3,17.6c4,5.8,11.9,7.3,17.6,3.3c2.8-1.9,4.7-4.8,5.3-8.1C437.2,35.4,436.4,32,434.5,29.2z"/>
            <path fill="#0B99D6" d="M426.9,30.9c-0.3,0.3-0.8,0.4-1.1,0.1c-0.3-0.3-0.4-0.8-0.1-1.1
              l0,0c0,0,7.5-8.4,13.9-2.1c2.1,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.7,2.6,1.6c0.8-0.2,1.6-0.7,2.2-1.3c2.2-1.7,5.3-4,11-2.8
              c4.9,1,5.7,2.6,6.7,4.5c0.8,1.7,2.1,3.2,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4c1.7-0.6,3.1-1.6,4.3-2.9c2.5-2.7,6.7-1.9,6.7-1.9
              c0.4,0.1,0.7,0.5,0.6,1c-0.1,0.4-0.5,0.6-0.9,0.6c0,0-3.4-0.6-5.2,1.4c-1.3,1.5-3.1,2.7-5,3.3c-2.2,0.6-4.9,0.5-8.2-1.6
              c-1.9-1.1-3.4-2.7-4.3-4.7c-0.8-1.5-1.4-2.8-5.6-3.7c-5-1-7.7,1-9.7,2.5c-0.9,0.8-1.9,1.4-3.1,1.6c-2,0.1-2.3-0.2-3.9-2.1
              c-0.8-1-2-2.4-4.1-4.4C433.3,23.7,426.9,30.9,426.9,30.9L426.9,30.9z M424.2,45.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.9,2,2.4,3.7,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.3,0.9-0.7c0-0.4-0.2-0.8-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.2-13.9,2.1-13.9,2.1
              c-0.3,0.4-0.2,0.9,0.2,1.1C423.5,45.7,423.9,45.7,424.2,45.4L424.2,45.4z M424.9,41.8c0,0,6.4-7.2,11.6-2.1
              c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7
              c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.3,0.9-0.7
              c0-0.4-0.2-0.8-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.2,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1
              c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5
              c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1C424.1,42.1,424.6,42.1,424.9,41.8L424.9,41.8z M425.6,38.1
              c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5
              c4.1,0.9,4.8,2.1,5.6,3.7c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4
              c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4
              c-1.6-0.9-3-2.4-3.7-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6
              c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1S425.3,38.5,425.6,38.1L425.6,38.1L425.6,38.1z
               M426.2,34.5c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1h-0.1c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9
              c-1.8,0.5-4.1,0.3-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1S426,34.8,426.2,34.5
              L426.2,34.5L426.2,34.5z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M171.9-27L297,78.1l-57.4,68.4L114.5,41.4L171.9-27z
               M172.7-19.4l-50.6,60.2l116.6,98l50.6-60.2L172.7-19.4z"/>

            <rect x="166.4" y="-16.4" transform="matrix(0.6432 -0.7657 0.7657 0.6432 27.6519 178.8528)" fill="#FBD4A4" width="78.7" height="152.3"/>
            <g>
              <path fill="#0B99D6" d="M186.6-14.6l-5.1,2.7l-4.6-3.9l5-2.7L186.6-14.6L186.6-14.6z
                 M154.2,2.7l-13.2,7l9.7-11.5l13.2-7L154.2,2.7z"/>
              <path fill="#0B99D6" d="M195.8-6.9l-5.1,2.7L186.2-8l5-2.7L195.8-6.9L195.8-6.9z
                 M134.9,25.7l-13.2,7l9.6-11.5l13.2-7L134.9,25.7z"/>
              <path fill="#0B99D6" d="M205.1,0.9L200,3.6l-4.6-3.9l5.1-2.7L205.1,0.9L205.1,0.9z
                 M125.3,43.5l-5,2.7l-4.6-3.9l9.6-5.1l-3.1,3.7L125.3,43.5z"/>
              <path fill="#0B99D6" d="M214.3,8.6l-5.1,2.7l-4.6-3.9l5.1-2.7L214.3,8.6z M134.5,51.3
                l-5.1,2.7l-4.6-3.9l5-2.7L134.5,51.3z"/>
              <path fill="#0B99D6" d="M223.5,16.4l-5,2.7l-4.6-3.9l5.1-2.7L223.5,16.4L223.5,16.4z
                 M143.7,59l-5.1,2.7l-4.6-3.9l5.1-2.7L143.7,59z"/>
              <path fill="#0B99D6" d="M232.7,24.1l-5.1,2.7L223,23l5-2.7L232.7,24.1L232.7,24.1z
                 M153,66.8l-5.1,2.7l-4.6-3.9l5.1-2.7L153,66.8z"/>
              <path fill="#0B99D6" d="M242,31.9l-5.1,2.7l-4.6-3.9l5.1-2.7L242,31.9L242,31.9z
                 M162.2,74.5l-5,2.7l-4.6-3.9l5.1-2.7L162.2,74.5z"/>
              <path fill="#0B99D6" d="M251.2,39.6l-5.1,2.7l-4.6-3.9l5.1-2.7L251.2,39.6z
                 M171.4,82.3l-5.1,2.7l-4.6-3.9l5.1-2.7L171.4,82.3z"/>
              <path fill="#0B99D6" d="M260.4,47.4l-5.1,2.7l-4.6-3.9l5.1-2.7L260.4,47.4z M180.6,90
                l-5.1,2.7l-4.6-3.8l5-2.7L180.6,90z"/>
              <path fill="#0B99D6" d="M269.6,55.1l-5,2.7L260,54l5.1-2.7L269.6,55.1L269.6,55.1z
                 M189.8,97.8l-5.1,2.7l-4.6-3.9l5.1-2.7L189.8,97.8z"/>
              <path fill="#0B99D6" d="M278.9,62.9l-5.1,2.7l-4.6-3.9l5-2.7L278.9,62.9L278.9,62.9z
                 M199.1,105.5l-5.1,2.7l-4.6-3.9l5.1-2.7L199.1,105.5z"/>
              <path fill="#0B99D6" d="M288.1,70.6l-5.1,2.7l-4.6-3.9l5.1-2.7L288.1,70.6L288.1,70.6z
                 M208.3,113.3l-5,2.7l-4.6-3.9l5.1-2.7L208.3,113.3z"/>
              <path fill="#0B99D6" d="M296.5,78.8l-13.2,7l6-7.2l-1.7-1.4l5-2.7l4.3,3.7L296.5,78.8
                L296.5,78.8z M217.5,121l-5.1,2.7l-4.6-3.9l5-2.7L217.5,121z"/>
              <path fill="#0B99D6" d="M277.1,101.8l-13.2,7l9.6-11.5l13.2-7L277.1,101.8z
                 M226.7,128.8l-5.1,2.7l-4.6-3.9l5.1-2.7L226.7,128.8z"/>
              <path fill="#0B99D6" d="M257.8,124.9l-13.2,7l9.7-11.5l13.2-7L257.8,124.9L257.8,124.9
                z M236,136.5l-5.1,2.7l-4.6-3.9l5.1-2.7L236,136.5z"/>
              <polygon fill="#0B99D6" points="235.5,143.1 248.1,136.4 239.6,146.5         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M242.5,56c4.3,3.7,4.9,10.2,1.2,14.5s-10.2,4.9-14.5,1.2
              c-4.3-3.7-4.9-10.2-1.2-14.5c1.8-2.1,4.3-3.4,7-3.6S240.4,54.2,242.5,56z M244.5,63.1c-0.4-4.8-4.6-8.3-9.4-7.9
              c-4.8,0.4-8.3,4.6-7.9,9.4c0.4,4.8,4.6,8.3,9.4,7.9c2.3-0.2,4.4-1.3,5.9-3.1C244,67.7,244.8,65.4,244.5,63.1L244.5,63.1z"/>
            <path fill="#80CAF0" d="M239.5,61.5c-0.3,0.1-0.6-0.1-0.7-0.3c-0.1-0.3,0.1-0.6,0.3-0.7
              l0,0c0,0,7.3-2.5,9,3.4c0.6,2,0.8,3.3,1,4.1c0.2,1.2,0.3,1.5,1,1.8c0.6,0.1,1.2,0.1,1.7,0c2.7-0.6,5.5,0.1,7.5,2
              c2.6,2.3,2.5,3.4,2.5,4.9c-0.1,1.3,0.2,2.6,0.9,3.7c0.7,1.5,2,2.7,3.6,3.2c1.2,0.2,2.4,0.1,3.5-0.3c2.4-0.8,4.6,1.1,4.6,1.1
              c0.2,0.2,0.2,0.6,0,0.8c-0.2,0.2-0.5,0.2-0.7,0.1c0,0-1.8-1.5-3.6-0.9c-1.3,0.5-2.7,0.6-4.1,0.3c-1.9-0.5-3.5-1.9-4.3-3.7
              c-0.8-1.3-1.1-2.8-1-4.3c0-1.2,0.1-2.1-2.1-4c-1.8-1.7-4.2-2.3-6.6-1.8c-0.8,0.2-1.6,0.2-2.4-0.1c-1.2-0.6-1.3-0.9-1.6-2.6
              c-0.2-0.8-0.4-2.1-1-4C245.7,59.4,239.5,61.5,239.5,61.5L239.5,61.5z M233,69.2c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4
              c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4c-0.1,1.5,0.2,3,1,4.3
              c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1l0,0c0.2-0.2,0.2-0.6-0.1-0.8
              c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.2c-0.7-1.1-1-2.4-0.8-3.7c0.1-1.5,0.1-2.7-2.4-4.9
              c-2-1.9-4.8-2.7-7.5-2.1c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1c-1.7-5.9-9-3.4-9-3.4
              c-0.2,0.2-0.3,0.5-0.1,0.8c0.1,0.2,0.3,0.2,0.5,0.2L233,69.2z M234.6,67.3c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4
              c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4.1c-0.1,1.5,0.2,3,1,4.3
              c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.3-0.3,2.6,0.1,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1c0.2-0.2,0.2-0.6-0.1-0.8l0,0
              c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.2c-0.7-1.1-1-2.4-0.9-3.7c0.1-1.5,0.1-2.7-2.4-4.9
              c-2-1.9-4.8-2.6-7.5-2c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1c-1.7-5.9-9-3.4-9-3.4
              c-0.3,0.1-0.4,0.4-0.3,0.7l0,0C234.1,67.3,234.4,67.4,234.6,67.3C234.7,67.3,234.7,67.3,234.6,67.3L234.6,67.3z M236.2,65.4
              c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8
              c2.2,1.9,2.1,2.9,2.1,4c-0.1,1.5,0.2,3,1,4.3c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9
              c0.2,0.2,0.6,0.2,0.8-0.1c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.1
              c-0.7-1.1-1-2.4-0.9-3.7c0.1-1.5,0.1-2.7-2.5-4.9c-2-1.9-4.8-2.6-7.5-2c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8
              c-0.2-0.9-0.4-2.2-1-4.1c-1.7-5.9-9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.4,0.7C235.7,65.3,236,65.4,236.2,65.4
              C236.3,65.3,236.3,65.3,236.2,65.4L236.2,65.4z M237.9,63.4c0,0,6.2-2.1,7.6,2.7c0.6,2,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6
              c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.2,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4c-0.2,1.5,0.1,3.1,0.9,4.4c0.8,1.8,2.4,3.2,4.3,3.7
              c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1l0,0c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1
              c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.2c-0.7-1.1-1-2.4-0.8-3.7c0.1-1.5,0.1-2.7-2.5-4.9c-2-1.9-4.8-2.7-7.5-2.1
              c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1c-1.7-5.9-9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7
              C237.3,63.4,237.6,63.5,237.9,63.4L237.9,63.4z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M-40.1-78.4L129,23l-55.4,92.4L-95.5,14.1L-40.1-78.4z
               M-37.7-69.4L-86.5,12l157.7,94.5L120,25L-37.7-69.4z"/>

            <rect x="-30.7" y="-73.4" transform="matrix(0.514 -0.8578 0.8578 0.514 -7.7393 23.3586)" fill="#FFFFFF" width="94.9" height="183.8"/>
            <g>
              <path fill="#0B99D6" d="M4.7-51.5l-5.5,4.2L-7-51.1l5.5-4.2L4.7-51.5L4.7-51.5z
                 M-82.2,14.5l-5.5,4.2L-94,15l10.5-8l-3,5L-82.2,14.5z"/>
              <path fill="#0B99D6" d="M17.2-44.1l-5.5,4.2l-6.2-3.7l5.5-4.2
                C10.9-47.8,17.2-44.1,17.2-44.1z M-69.8,22l-5.5,4.2l-6.2-3.7l5.5-4.2L-69.8,22z"/>
              <path fill="#0B99D6" d="M29.6-36.6l-5.5,4.2l-6.2-3.7l5.5-4.2
                C23.4-40.3,29.6-36.6,29.6-36.6z M-57.3,29.5l-5.5,4.2L-69,30l5.5-4.2L-57.3,29.5z"/>
              <path fill="#0B99D6" d="M42.1-29.1l-5.5,4.2l-6.2-3.7l5.5-4.2
                C35.9-32.9,42.1-29.1,42.1-29.1z M-44.8,37l-5.5,4.2l-6.2-3.7l5.6-4.2L-44.8,37z"/>
              <path fill="#0B99D6" d="M54.6-21.7l-5.5,4.2l-6.2-3.7l5.5-4.2
                C48.3-25.4,54.6-21.7,54.6-21.7z M-32.4,44.4l-5.5,4.2l-6.2-3.7l5.5-4.2L-32.4,44.4z"/>
              <path fill="#0B99D6" d="M67-14.2L61.5-10l-6.2-3.7l5.5-4.2L67-14.2z M-19.9,51.9
                l-5.5,4.2l-6.2-3.7l5.5-4.2L-19.9,51.9z"/>
              <path fill="#0B99D6" d="M79.5-6.7L74-2.5l-6.2-3.7l5.5-4.2L79.5-6.7z M-7.4,59.4
                l-5.5,4.2l-6.2-3.7l5.5-4.2L-7.4,59.4z"/>
              <path fill="#0B99D6" d="M92,0.8L86.5,5l-6.2-3.8L85.8-3L92,0.8z M5.1,66.8L-0.4,71
                l-6.2-3.7l5.5-4.2L5.1,66.8z"/>
              <path fill="#0B99D6" d="M104.4,8.2l-5.5,4.2l-6.2-3.7l5.5-4.2
                C98.2,4.5,104.5,8.2,104.4,8.2z M17.5,74.3L12,78.5l-6.2-3.7l5.5-4.2L17.5,74.3z"/>
              <path fill="#0B99D6" d="M116.9,15.7l-5.5,4.2l-6.2-3.7l5.5-4.2L116.9,15.7L116.9,15.7z
                 M30,81.8L24.5,86l-6.2-3.7l5.5-4.2L30,81.8z"/>
              <path fill="#0B99D6" d="M128.5,23.9l-14.3,10.9L120,25l-2.3-1.4l5.5-4.2L129,23
                L128.5,23.9L128.5,23.9z M42.5,89.3L37,93.4l-6.2-3.7l5.5-4.2L42.5,89.3z"/>
              <path fill="#0B99D6" d="M109.8,55L95.5,65.9l9.3-15.5l14.3-10.9L109.8,55z M54.9,96.7
                l-5.5,4.2l-6.2-3.7l5.5-4.2L54.9,96.7z"/>
              <path fill="#0B99D6" d="M91.2,86.1L76.8,97l9.3-15.5l14.3-10.9L91.2,86.1z M67.4,104.2
                l-5.5,4.2l-6.2-3.7l5.5-4.2L67.4,104.2z"/>
              <polygon fill="#0B99D6" points="68.1,112.1 81.8,101.7 73.6,115.4         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M59.9,6.9c5.9,3.5,7.8,11.1,4.3,17s-11.1,7.8-17,4.3
              s-7.8-11.1-4.3-17C46.3,5.3,53.9,3.4,59.9,6.9C59.8,6.9,59.8,6.9,59.9,6.9z M63.6,15.1c-1.4-5.6-7.1-9-12.7-7.6s-9,7.1-7.6,12.7
              s7.1,9,12.7,7.6c2.7-0.7,5-2.4,6.4-4.8C63.9,20.6,64.3,17.8,63.6,15.1z"/>
            <path fill="#80CAF0" d="M57.2,14.1c-0.3,0.2-0.7,0-0.9-0.3s0-0.7,0.3-0.9l0,0
              c0,0,8.2-4.3,11.4,2.3c1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.7,0.1,1.4-0.1,2.1-0.3c3.1-1.2,6.5-0.8,9.3,1
              c3.5,2.2,3.7,3.6,3.9,5.4c0.1,1.6,0.7,3.1,1.7,4.3c1.7,2.2,3.4,2.9,4.9,3c1.4,0,2.9-0.3,4.1-1c2.7-1.3,5.7,0.5,5.7,0.5
              c0.3,0.2,0.4,0.6,0.2,0.9c-0.2,0.3-0.6,0.4-0.9,0.2c0,0-2.4-1.5-4.4-0.4c-1.5,0.8-3.1,1.2-4.8,1.1c-1.9-0.1-3.9-1.1-5.9-3.6
              c-1.2-1.4-1.9-3.1-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-2.4-1.6-5.5-1.9-8.2-0.8c-0.9,0.4-1.9,0.5-2.8,0.3c-1.6-0.5-1.7-0.8-2.4-2.8
              c-0.4-1-0.9-2.4-1.9-4.6C64.3,10.4,57.3,14.1,57.2,14.1L57.2,14.1z M51,24.6c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.6,1.9,4.6
              c0.7,2,0.8,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c2.9,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9
              c1.9,2.5,4,3.4,5.9,3.6c1.7,0,3.3-0.4,4.8-1.2c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0,0.9-0.3c0.1-0.3,0-0.6-0.2-0.8
              c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
              c-2.7-1.8-6.2-2.2-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
              c-0.3,0.2-0.4,0.6-0.3,0.9C50.2,24.6,50.6,24.8,51,24.6C50.9,24.6,50.9,24.6,51,24.6L51,24.6z M52.5,22c0,0,7-3.7,9.5,1.7
              c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4
              c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.2,0.9-0.1
              c0.2-0.3,0.2-0.7-0.1-0.9c0,0-0.1,0-0.1-0.1c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-2.7-1.8-6.2-2.2-9.3-1c-0.7,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.2-2.3-11.3-2.3c-0.3,0.2-0.5,0.6-0.3,0.9C51.8,22,52.2,22.2,52.5,22z M54.1,19.4
              c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.8
              c2.9,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.4-0.3,4.9-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2l0,0c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-2-0.2-3.8-1.4-4.9-3.1
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.8-5.4c-2.7-1.9-6.2-2.2-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-0.9-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7C61.7,13.7,53.5,18,53.5,18c-0.3,0.2-0.4,0.6-0.3,0.9C53.4,19.4,53.8,19.5,54.1,19.4L54.1,19.4z M55.7,16.8
              c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c1,0.2,1.9,0.1,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2l0,0c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-2-0.2-3.8-1.3-4.9-3
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.8-5.4c-2.7-1.9-6.2-2.2-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7C63.2,11.3,55,15.6,55,15.6c-0.3,0.2-0.4,0.6-0.3,0.9l0,0C54.9,16.8,55.3,16.9,55.7,16.8
              C55.7,16.7,55.7,16.7,55.7,16.8L55.7,16.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M819.6,173.2l69-171.8l93.9,37.7l-69,171.8L819.6,173.2z
               M827.6,169.6l82.7,33.2l64.3-160.2L891.9,9.4L827.6,169.6z"/>

            <rect x="814.8" y="61.6" transform="matrix(0.3728 -0.9279 0.9279 0.3728 466.6702 902.7247)" fill="#D1CCE6" width="172.6" height="89.1"/>
            <g>
              <polygon fill="#0B99D6" points="822.7,165.6 838.4,180.7 822.6,174.4 820.1,172
                "/>
              <path fill="#0B99D6" d="M827.7,153l4.7,4.5l-2.5,6.3l-4.7-4.5L827.7,153L827.7,153z
                 M857.7,181.7l12.3,11.7l-15.8-6.4L842,175.4L857.7,181.7z"/>
              <path fill="#0B99D6" d="M832.8,140.3l4.7,4.5l-2.5,6.3l-4.7-4.5L832.8,140.3
                L832.8,140.3z M889.4,194.4l12.2,11.7l-15.8-6.3l-12.2-11.7L889.4,194.4z"/>
              <path fill="#0B99D6" d="M837.9,127.6l4.7,4.5l-2.5,6.3l-4.7-4.5L837.9,127.6
                L837.9,127.6z M912,198.5l4.7,4.5l-2.5,6.3l-8.9-8.5l5,2L912,198.5z"/>
              <path fill="#0B99D6" d="M843,115l4.7,4.5l-2.5,6.3l-4.7-4.5L843,115z M917.1,185.9
                l4.7,4.5l-2.5,6.3l-4.7-4.5L917.1,185.9z"/>
              <path fill="#0B99D6" d="M848.1,102.3l4.7,4.5l-2.5,6.3l-4.7-4.5L848.1,102.3z
                 M922.2,173.2l4.7,4.5l-2.5,6.3l-4.7-4.5L922.2,173.2z"/>
              <path fill="#0B99D6" d="M853.2,89.6l4.7,4.5l-2.5,6.3l-4.7-4.5L853.2,89.6L853.2,89.6z
                 M927.3,160.5l4.7,4.5l-2.5,6.3l-4.7-4.5L927.3,160.5z"/>
              <path fill="#0B99D6" d="M858.3,77l4.7,4.5l-2.5,6.3l-4.7-4.5L858.3,77z M932.4,147.9
                l4.7,4.5l-2.5,6.3l-4.7-4.5L932.4,147.9z"/>
              <path fill="#0B99D6" d="M863.4,64.3l4.7,4.5l-2.5,6.3l-4.7-4.5L863.4,64.3z
                 M937.4,135.2l4.7,4.5l-2.5,6.3l-4.7-4.5L937.4,135.2z"/>
              <path fill="#0B99D6" d="M868.5,51.6l4.7,4.5l-2.5,6.3l-4.7-4.5L868.5,51.6L868.5,51.6z
                 M942.5,122.5l4.7,4.5l-2.5,6.3l-4.7-4.5L942.5,122.5z"/>
              <path fill="#0B99D6" d="M873.5,39l4.7,4.5l-2.5,6.3l-4.7-4.5L873.5,39z M947.6,109.9
                l4.7,4.5l-2.5,6.3l-4.7-4.5L947.6,109.9z"/>
              <path fill="#0B99D6" d="M878.6,26.3l4.7,4.5l-2.5,6.3l-4.7-4.5L878.6,26.3z
                 M952.7,97.2l4.7,4.5l-2.5,6.3l-4.7-4.5L952.7,97.2z"/>
              <path fill="#0B99D6" d="M883.7,13.6l4.7,4.5l-2.5,6.3l-4.7-4.5L883.7,13.6L883.7,13.6z
                 M957.8,84.5l4.7,4.5l-2.5,6.3l-4.7-4.5L957.8,84.5z"/>
              <path fill="#0B99D6" d="M889.6,1.7l12.2,11.7l-9.9-4l-0.9,2.4l-4.7-4.5l2.4-6
                L889.6,1.7L889.6,1.7z M962.9,71.8l4.7,4.5l-2.5,6.3l-4.7-4.5L962.9,71.8z"/>
              <path fill="#0B99D6" d="M921.2,14.4l12.2,11.7l-15.8-6.3L905.4,8.1L921.2,14.4z
                 M968,59.2l4.7,4.5l-2.5,6.3l-4.7-4.5L968,59.2z"/>
              <path fill="#0B99D6" d="M952.8,27.1L965,38.8l-15.8-6.3L937,20.8L952.8,27.1
                L952.8,27.1z M973.1,46.5l4.7,4.5l-2.5,6.3l-4.7-4.5L973.1,46.5z"/>
              <polygon fill="#0B99D6" points="980.3,44.7 968.6,33.5 982.6,39.1         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M884,67.8c2.4-6,9.2-8.9,15.2-6.5s8.9,9.2,6.5,15.2
              s-9.2,8.9-15.2,6.5c-2.9-1.2-5.2-3.4-6.4-6.3C882.9,73.9,882.8,70.7,884,67.8z M891,63.2c-5,2.1-7.3,7.9-5.2,12.9
              s7.9,7.3,12.9,5.2c5-2.1,7.3-7.9,5.2-12.9c-1-2.4-3-4.3-5.4-5.3C896.1,62.1,893.4,62.1,891,63.2z"/>
            <path fill="#80CAF0" d="M891,69.2c0.2,0.3,0.2,0.7-0.1,0.9l0,0
              c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-5.2-7,0.5-10.9c1.9-1.3,3.2-2,4.1-2.5c1.3-0.7,1.5-0.8,1.6-1.8c-0.1-0.7-0.3-1.3-0.6-1.9
              c-1.6-2.7-1.7-6-0.4-8.8c1.5-3.5,2.8-3.9,4.4-4.4c1.4-0.3,2.7-1.1,3.7-2.2c1.4-1.3,2.2-3.1,2.1-5c-0.2-1.3-0.7-2.6-1.5-3.7
              c-1.6-2.3-0.4-5.4-0.4-5.4c0.1-0.3,0.5-0.5,0.8-0.4s0.5,0.5,0.3,0.8c0,0-1,2.5,0.3,4.2c1,1.2,1.6,2.7,1.8,4.3
              c0.1,2.3-0.8,4.5-2.4,6c-1.1,1.3-2.6,2.1-4.2,2.5c-1.3,0.4-2.3,0.7-3.6,3.7c-1.2,2.5-1,5.4,0.4,7.8c0.5,0.8,0.7,1.7,0.7,2.6
              c-0.2,1.5-0.5,1.7-2.2,2.7c-0.8,0.5-2.1,1.2-4,2.5C886.6,63.3,891.1,69.2,891,69.2L891,69.2z M901.6,73.5c0,0-4.5-5.9,0.2-9.1
              c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7
              c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8
              c-0.3-0.1-0.7,0-0.8,0.3l0,0c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2
              c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5
              c-5.7,3.9-0.5,10.8-0.5,10.9c0.2,0.3,0.6,0.4,0.9,0.2S901.9,73.8,901.6,73.5C901.7,73.5,901.7,73.5,901.6,73.5L901.6,73.5z
               M899,72.4c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8
              c1.3-3,2.3-3.3,3.6-3.7c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2
              c0.1-0.3,0-0.7-0.3-0.8c-0.3-0.1-0.7,0-0.8,0.3l0,0c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5
              c-1,1.1-2.3,1.9-3.7,2.3c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8
              c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.9-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1C899.2,73.1,899.2,72.7,899,72.4L899,72.4z
               M896.3,71.3c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8
              c1.3-3,2.3-3.3,3.6-3.7c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2
              c0.1-0.3,0-0.7-0.3-0.8l0,0c-0.3-0.1-0.7,0-0.8,0.3l0,0c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5
              c-0.9,1.1-2.2,1.9-3.6,2.3c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8
              c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.9-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1l0,0C896.6,72.1,896.6,71.7,896.3,71.3
              C896.4,71.4,896.4,71.4,896.3,71.3L896.3,71.3z M893.7,70.3c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7
              c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6
              c-0.2-1.6-0.8-3.1-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8c-0.3-0.1-0.7,0-0.8,0.3c0,0-1.2,3.1,0.4,5.4
              c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8
              c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1
              l0,0C893.8,70.9,893.9,70.5,893.7,70.3L893.7,70.3z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#D4410B" d="M-3.2,123.2L166,198.4l41.1-92.4L37.9,30.7L-3.2,123.2z
               M5,120.2l36.2-81.4L198.9,109l-36.2,81.4L5,120.2z"/>

            <rect x="57.4" y="28.2" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -44.1168 161.1494)" fill="#CCCCCE" width="89.2" height="172.6"/>
            <g>
              <polygon fill="#39BEEE" points="4.2,126.5 5,104.7 -1.9,120.2 -2,123.7         "/>
              <path fill="#39BEEE" d="M16.7,132l0.2-6.5l-6.2-2.8l-0.2,6.5L16.7,132z M18.2,90.4
                l0.6-16.9L12,89.1L11.3,106L18.2,90.4z"/>
              <path fill="#39BEEE" d="M29.1,137.6l0.2-6.5l-6.2-2.8l-0.2,6.5L29.1,137.6z M32.1,59.3
                l0.6-16.9L25.8,58l-0.6,16.9L32.1,59.3z"/>
              <path fill="#39BEEE" d="M41.6,143.1l0.2-6.5l-6.2-2.8l-0.2,6.5L41.6,143.1z M45.5,40.7
                l0.2-6.5l-6.2-2.8L39,43.7l2.2-5L45.5,40.7z"/>
              <path fill="#39BEEE" d="M54.1,148.7l0.2-6.5l-6.2-2.8l-0.2,6.5
                C47.9,145.9,54.1,148.7,54.1,148.7z M57.9,46.2l0.2-6.5L52,36.9l-0.2,6.5L57.9,46.2z"/>
              <path fill="#39BEEE" d="M66.6,154.2l0.2-6.5l-6.2-2.8l-0.2,6.5L66.6,154.2z M70.4,51.8
                l0.2-6.5l-6.2-2.8L64.2,49L70.4,51.8z"/>
              <path fill="#39BEEE" d="M79,159.8l0.2-6.5l-6.2-2.8l-0.2,6.5L79,159.8z M82.9,57.3
                l0.2-6.5L76.9,48l-0.2,6.5L82.9,57.3z"/>
              <path fill="#39BEEE" d="M91.5,165.3l0.2-6.5l-6.2-2.8l-0.2,6.5L91.5,165.3L91.5,165.3z
                 M95.3,62.8l0.2-6.5l-6.2-2.8L89.1,60L95.3,62.8z"/>
              <path fill="#39BEEE" d="M104,170.9l0.2-6.5l-6.2-2.8l-0.2,6.5L104,170.9L104,170.9z
                 M107.9,68.4l0.2-6.5l-6.2-2.8l-0.2,6.5L107.9,68.4z"/>
              <path fill="#39BEEE" d="M116.4,176.4l0.2-6.5l-6.2-2.8l-0.2,6.5L116.4,176.4z
                 M120.3,73.9l0.2-6.5l-6.2-2.8l-0.2,6.5L120.3,73.9z"/>
              <path fill="#39BEEE" d="M128.9,182l0.2-6.5l-6.2-2.8l-0.2,6.5L128.9,182z M132.8,79.5
                L133,73l-6.2-2.8l-0.2,6.5L132.8,79.5z"/>
              <path fill="#39BEEE" d="M141.4,187.5l0.2-6.5l-6.2-2.8l-0.2,6.5L141.4,187.5z
                 M145.2,85l0.2-6.5l-6.2-2.8l-0.2,6.5L145.2,85z"/>
              <path fill="#39BEEE" d="M153.9,193.1l0.2-6.5l-6.2-2.8l-0.2,6.5L153.9,193.1z
                 M157.7,90.6l0.2-6.5l-6.2-2.8l-0.2,6.5L157.7,90.6z"/>
              <path fill="#39BEEE" d="M166.4,197.5l0.6-16.9l-4.3,9.7l-2.3-1l-0.2,6.5l5.9,2.6
                L166.4,197.5L166.4,197.5z M170.2,96.1l0.2-6.5l-6.2-2.8l-0.2,6.5L170.2,96.1z"/>
              <path fill="#39BEEE" d="M180.2,166.4l0.6-16.9l-6.9,15.6l-0.6,16.9L180.2,166.4z
                 M182.6,101.7l0.2-6.5l-6.2-2.8l-0.2,6.5L182.6,101.7z"/>
              <path fill="#39BEEE" d="M194.1,135.3l0.6-16.9l-6.9,15.5l-0.6,16.9L194.1,135.3z
                 M195.1,107.3l0.2-6.5l-6.2-2.8l-0.2,6.5L195.1,107.3z"/>
              <polygon fill="#39BEEE" points="201.6,103.5 201,119.7 207.1,106         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M130.9,83.8l2,0.9l0,0c-0.4,0.7-0.1,1.6,0.6,1.9
              c0.7,0.4,1.6,0.1,1.9-0.6c0,0,0-0.1,0.1-0.1l0,0l1.3,0.6l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0l1.1,0.5l0,0
              c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0l1.1,0.5l0,0
              c-0.4,0.7-0.1,1.6,0.6,1.9c0.7,0.4,1.6,0.1,1.9-0.6c0,0,0-0.1,0.1-0.1l0,0l1.9,0.9l-0.8,1.6c-0.7-0.3-1.6,0-1.9,0.7
              c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1c-0.7-0.4-1.6-0.1-1.9,0.6c-0.4,0.7-0.1,1.6,0.6,1.9c0,0,0.1,0,0.1,0.1l-0.5,1.1
              c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9l-0.6,1.3
              c-0.7-0.4-1.6-0.1-1.9,0.6c-0.4,0.7-0.1,1.6,0.6,1.9c0,0,0.1,0,0.1,0.1L143,114c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9
              l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9l0,0l-0.5,1.1c-0.7-0.4-1.6-0.1-1.9,0.6c-0.4,0.7-0.1,1.6,0.6,1.9
              c0,0,0.1,0,0.1,0.1l-0.7,1.6l-2-0.9l0,0c0.4-0.7,0.1-1.6-0.6-1.9c-0.7-0.4-1.6-0.1-1.9,0.6c0,0,0,0.1-0.1,0.1l0,0L132,123l0,0
              c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0c0.4-0.7,0.1-1.6-0.6-1.9c-0.7-0.4-1.6-0.1-1.9,0.6c0,0,0,0.1-0.1,0.1
              l0,0l-1.1-0.5l0,0c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-1-0.4l0,0c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-2-0.9
              l0.7-1.6c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0,0l0.5-1.1c0.7,0.4,1.6,0.1,1.9-0.6s0.1-1.6-0.6-1.9c0,0-0.1,0-0.1-0.1l0.5-1.1
              c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0,0l0.6-1.3c0.7,0.3,1.6,0,1.9-0.7
              s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0,0l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9L129,88
              c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.7-1.9L130.9,83.8z"/>

            <rect x="120.1" y="95.7" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -15.8172 185.0263)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>

          <rect x="343.8" y="-47.6" transform="matrix(0.2256 -0.9742 0.9742 0.2256 261.6126 419.0017)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M315.6-25.2L375.2,43c8.6,9.8,24.9,13.9,36.3,9.1L496,16.6
            L315.6-25.2z"/>
          <g>
            <path fill="#0B99D6" d="M406.5,40.7c-8.1-1.9-16.3,3.2-18.2,11.3
              c-1.9,8.1,3.2,16.3,11.3,18.2s16.3-3.2,18.2-11.3c0.9-3.9,0.2-8-1.9-11.4C413.8,44.1,410.4,41.6,406.5,40.7z M396.3,44.7
              c6-3.7,13.8-1.9,17.5,4.1s1.9,13.8-4.1,17.5c-6,3.7-13.8,1.9-17.5-4.1c-1.8-2.9-2.4-6.3-1.6-9.6
              C391.4,49.3,393.5,46.5,396.3,44.7L396.3,44.7z"/>
            <path fill="#0B99D6" d="M402.7,49.2c0.1,0.4,0.6,0.6,1,0.5s0.6-0.6,0.5-1
              c0,0-3.5-10.7-11.9-7.5c-2.8,1.1-4.5,1.9-5.7,2.5c-1.7,0.8-2,0.9-3,0.4c-0.7-0.5-1.2-1.3-1.6-2.1c-1.4-2.4-3.2-5.8-9-7
              c-4.9-1-6.3,0.1-7.9,1.5c-1.4,1.3-3.2,2.1-5.1,2.4c-3.3,0.5-5.4-0.3-6.9-1.5c-1.3-1.2-2.3-2.7-2.8-4.3c-1.2-3.4-5.4-4.4-5.4-4.4
              c-0.4-0.1-0.9,0.2-1,0.6s0.2,0.9,0.6,1c0,0,3.3,0.8,4.2,3.4c0.6,2,1.7,3.7,3.3,5.1c1.8,1.5,4.3,2.4,8.1,1.8
              c2.2-0.2,4.3-1.2,5.9-2.6c1.3-1.1,2.4-2,6.6-1.1c5,1,6.7,4,7.9,6.2c0.5,1.1,1.2,2,2.2,2.7c1.8,0.9,2.2,0.7,4.5-0.4
              c1.1-0.5,2.8-1.4,5.6-2.4C399.7,40.1,402.7,49.2,402.7,49.2L402.7,49.2z M399.4,63.7c0,0-3-9.2-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4
              c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6
              c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6
              c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.1,0.4-0.1,0.9-0.5,1l0,0C400,64.3,399.5,64.1,399.4,63.7L399.4,63.7z M400.2,60.1c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4
              c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6
              c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.3-3.4c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6
              c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.1,0.4-0.1,0.9-0.5,1C400.8,60.8,400.3,60.5,400.2,60.1L400.2,60.1L400.2,60.1z M401,56.5c0,0-3-9.2-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.1-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.3-3.4c-0.4-0.1-0.7-0.5-0.6-1
              l0,0c0.1-0.4,0.5-0.7,1-0.6l0,0c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C401.7,57.1,401.2,56.9,401,56.5L401,56.5L401,56.5z M401.9,52.8
              c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2
              c-4.2-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.2-3.4
              c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5
              c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4
              c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C402.5,53.5,402.1,53.3,401.9,52.8
              C401.9,52.9,401.9,52.9,401.9,52.8L401.9,52.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#80CAF0" d="M353,98.6c5.9,3.5,7.8,11.2,4.3,17c-3.5,5.9-11.2,7.8-17,4.3
              c-5.9-3.5-7.8-11.2-4.3-17c1.7-2.8,4.4-4.9,7.6-5.7C346.8,96.4,350.2,96.9,353,98.6z M356.8,106.7c-1.4-5.6-7.1-9-12.7-7.6
              s-9,7.1-7.6,12.7s7.1,9,12.7,7.6c2.7-0.7,5-2.4,6.4-4.8C357,112.3,357.4,109.4,356.8,106.7z"/>
            <path fill="#80CAF0" d="M350.4,105.8c-0.3,0.2-0.7,0-0.9-0.3c-0.2-0.3-0.1-0.7,0.3-0.9
              l0,0c0,0,8.2-4.3,11.3,2.4c1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.7,0.1,1.4-0.1,2.1-0.3c3.1-1.2,6.5-0.8,9.3,1
              c3.5,2.2,3.6,3.6,3.9,5.4c0.1,1.6,0.7,3.1,1.7,4.3c1.1,1.7,2.9,2.8,4.9,3.1c1.4,0,2.9-0.3,4.1-1c2.7-1.3,5.7,0.5,5.7,0.5
              c0.3,0.2,0.4,0.6,0.2,0.9l0,0c-0.2,0.3-0.6,0.4-0.9,0.2l0,0c0,0-2.4-1.4-4.4-0.4c-1.5,0.8-3.1,1.2-4.8,1.1
              c-1.9-0.1-3.9-1.1-5.9-3.6c-1.2-1.4-1.9-3.1-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-2.4-1.6-5.5-2-8.2-0.9c-0.9,0.4-1.9,0.5-2.8,0.3
              c-1.6-0.5-1.7-0.8-2.4-2.8c-0.4-1-0.9-2.4-1.9-4.6C357.4,102.1,350.4,105.8,350.4,105.8L350.4,105.8z M344.1,116.3
              c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.3c-0.3,0.2-0.4,0.6-0.3,0.9C343.4,116.3,343.8,116.5,344.1,116.3L344.1,116.3z
               M345.7,113.6c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2l0,0c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.5-0.1-3.3-0.9-4.9-3
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.3c-0.3,0.1-0.5,0.5-0.4,0.9c0.1,0.3,0.5,0.5,0.9,0.4
              C345.6,113.7,345.6,113.7,345.7,113.6L345.7,113.6z M347.3,111c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8
              c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6
              c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9l0,0c0,0-3-1.8-5.7-0.5
              c-1.3,0.7-2.7,1-4.1,1c-1.5-0.1-3.3-0.9-4.9-3.1c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1
              c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.3c-0.3,0.2-0.4,0.6-0.3,0.9
              C346.5,111.1,346.9,111.2,347.3,111L347.3,111z M348.8,108.4c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8
              c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6
              c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2l0,0c0.2-0.3,0.1-0.7-0.2-0.9l0,0c0,0-3-1.8-5.7-0.5
              c-1.3,0.7-2.7,1-4.1,1c-2-0.2-3.8-1.4-4.9-3.1c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-2.7-1.8-6.2-2.2-9.3-1
              c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9
              C348.1,108.4,348.5,108.6,348.8,108.4L348.8,108.4z"/>
          </g>
          <g>
            <path fill="#D4410B" d="M215.8,41.4l126.9,134.8l73.7-69.4L289.5-28L215.8,41.4z
               M224.5,41.8l64.9-61.1l118.3,125.7l-64.9,61.1L224.5,41.8z"/>

            <rect x="271.5" y="-12.2" transform="matrix(0.7281 -0.6854 0.6854 0.7281 35.103 236.7858)" fill="#FBD4A4" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#39BEEE" points="221.4,47.3 230.5,27.5 218.1,39.2 216.7,42.3         "/>
              <path fill="#39BEEE" d="M230.7,57.2l2.7-5.9l-4.7-5l-2.7,5.9L230.7,57.2z M248.2,19.5
                l7.1-15.3L243,15.8l-7.1,15.3L248.2,19.5z"/>
              <path fill="#39BEEE" d="M240.1,67.2l2.7-5.9l-4.7-5l-2.7,5.9L240.1,67.2L240.1,67.2z
                 M273.1-3.8l7.1-15.3L267.8-7.5l-7.1,15.3L273.1-3.8z"/>
              <path fill="#39BEEE" d="M249.4,77.1l2.7-5.9l-4.7-5l-2.7,5.9L249.4,77.1L249.4,77.1z
                 M292.6-15.9l2.7-5.9l-4.7-5l-5.2,11.2l4-3.7L292.6-15.9z"/>
              <path fill="#39BEEE" d="M258.8,87l2.7-5.9l-4.7-5l-2.7,5.9L258.8,87z M302-6l2.7-5.9
                l-4.7-5l-2.7,5.9L302-6z"/>
              <path fill="#39BEEE" d="M268.1,97l2.7-5.9l-4.7-5l-2.7,5.9L268.1,97z M311.3,4l2.7-5.9
                l-4.7-5L306.6-1L311.3,4z"/>
              <path fill="#39BEEE" d="M277.5,106.9l2.7-5.9l-4.7-5l-2.7,6L277.5,106.9L277.5,106.9z
                 M320.7,13.9l2.7-5.9l-4.7-5L316,9L320.7,13.9z"/>
              <path fill="#39BEEE" d="M286.9,116.9l2.7-5.9l-4.7-5l-2.7,5.9L286.9,116.9z M330,23.9
                l2.7-5.9l-4.7-5l-2.7,5.9L330,23.9z"/>
              <path fill="#39BEEE" d="M296.2,126.8l2.7-5.9l-4.7-5l-2.7,5.9L296.2,126.8z
                 M339.4,33.8l2.7-5.9l-4.7-5l-2.7,5.9L339.4,33.8z"/>
              <path fill="#39BEEE" d="M305.6,136.7l2.7-5.9l-4.7-5l-2.7,5.9L305.6,136.7z
                 M348.7,43.7l2.7-5.9l-4.7-5l-2.7,5.9L348.7,43.7z"/>
              <path fill="#39BEEE" d="M314.9,146.7l2.7-5.9l-4.7-5l-2.7,5.9L314.9,146.7L314.9,146.7
                z M358.1,53.7l2.7-5.9l-4.7-5l-2.7,5.9L358.1,53.7z"/>
              <path fill="#39BEEE" d="M324.3,156.6l2.7-5.9l-4.7-5l-2.7,5.9L324.3,156.6L324.3,156.6
                z M367.4,63.6l2.7-5.9l-4.7-5l-2.7,5.9L367.4,63.6z"/>
              <path fill="#39BEEE" d="M333.6,166.6l2.7-5.9l-4.7-5l-2.7,5.9L333.6,166.6L333.6,166.6
                z M376.8,73.6l2.7-5.9l-4.7-5l-2.7,5.9L376.8,73.6z"/>
              <path fill="#39BEEE" d="M343.4,175.5l7.1-15.3l-7.8,7.3l-1.8-1.9l-2.7,5.9l4.4,4.7
                L343.4,175.5L343.4,175.5z M386.1,83.5l2.7-5.9l-4.7-5l-2.7,5.9L386.1,83.5z"/>
              <path fill="#39BEEE" d="M368.2,152.2l7.1-15.3L363,148.5l-7.1,15.3L368.2,152.2
                L368.2,152.2z M395.5,93.4l2.7-5.9l-4.7-5l-2.7,5.9L395.5,93.4z"/>
              <path fill="#39BEEE" d="M393,128.8l7.1-15.3l-12.4,11.7l-7.1,15.3L393,128.8z
                 M404.9,103.4l2.7-5.9l-4.7-5l-2.7,5.9L404.9,103.4z"/>
              <polygon fill="#39BEEE" points="412.3,102.5 405.5,117.1 416.4,106.8         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M314.1,116c4.4,4.7,11.8,4.9,16.5,0.5s4.9-11.8,0.5-16.5
              s-11.8-4.9-16.5-0.5c-2.2,2.1-3.6,5-3.7,8.1C310.9,110.7,312,113.8,314.1,116z M322.4,117.8c-5.4-0.1-9.7-4.7-9.6-10.1
              s4.7-9.7,10.1-9.6s9.7,4.7,9.6,10.1c-0.1,2.6-1.2,5.1-3.1,6.9C327.5,116.9,324.9,117.9,322.4,117.8L322.4,117.8z"/>
            <path fill="#80CAF0" d="M320.2,112.1c0.1-0.3,0-0.7-0.4-0.8s-0.7,0-0.8,0.4v0.1
              c0,0-2.3,8.4,4.4,9.9c2.3,0.5,3.7,0.7,4.7,0.9c1.4,0.2,1.7,0.2,2.1,1.1c0.2,0.6,0.2,1.3,0.1,2c-0.5,3.1,0.5,6.2,2.8,8.3
              c2.7,2.8,4.1,2.6,5.7,2.5c1.5-0.2,3,0,4.3,0.7c1.8,0.7,3.2,2.1,3.8,3.8c0.3,1.3,0.3,2.7-0.1,4c-0.7,2.7,1.6,5.1,1.6,5.1
              c0.2,0.2,0.6,0.2,0.9,0c0.2-0.2,0.2-0.6,0-0.9c0,0-1.8-1.9-1.3-4c0.4-1.5,0.5-3.1,0.1-4.6c-0.7-2.1-2.4-3.8-4.5-4.6
              c-1.5-0.8-3.2-1.1-4.9-0.8c-1.3,0.1-2.4,0.2-4.7-2.1c-2-1.9-2.9-4.7-2.5-7.4c0.2-0.9,0.1-1.8-0.3-2.7c-0.8-1.3-1.1-1.4-3-1.7
              c-1-0.1-2.4-0.3-4.6-0.8C318.2,119.3,320.2,112.2,320.2,112.1L320.2,112.1z M328.6,104.3c0,0-2,7.2,3.5,8.4
              c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7c-0.5,2.7,0.5,5.5,2.5,7.4c2.3,2.3,3.4,2.2,4.7,2.1
              c1.7-0.2,3.4,0,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9
              c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7
              c-1.6,0.2-3,0.3-5.7-2.5c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2c-0.5-0.8-0.7-0.8-2.1-1.1c-1-0.1-2.5-0.3-4.7-0.8
              c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.6,0.7-0.5C328.4,103.7,328.7,104,328.6,104.3C328.6,104.2,328.6,104.2,328.6,104.3
              L328.6,104.3z M326.5,106.3c0,0-2,7.2,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7
              c-0.4,2.7,0.5,5.5,2.5,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.7-0.2,3.4,0,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6
              c-0.5,2.1,1.3,4,1.3,4c0.2,0.3,0.2,0.7-0.1,0.9c-0.2,0.2-0.6,0.2-0.8,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4
              c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7c-1.6,0.2-3,0.3-5.7-2.4c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2
              c-0.5-0.8-0.7-0.8-2.1-1.1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.6,0.7-0.5
              C326.3,105.7,326.6,106,326.5,106.3C326.5,106.2,326.4,106.2,326.5,106.3L326.5,106.3z M324.4,108.3c0,0-2,7.2,3.5,8.4
              c2.2,0.5,3.7,0.7,4.6,0.8c2,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.2,2.7c-0.4,2.7,0.5,5.5,2.5,7.4c2.3,2.3,3.4,2.2,4.7,2.1
              c1.7-0.2,3.4,0,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9l0,0
              c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7
              c-1.7,0.2-3,0.3-5.7-2.5c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2c-0.5-0.8-0.7-0.8-2.1-1c-1-0.1-2.5-0.3-4.7-0.9
              c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.6,0.7-0.5C324.2,107.5,324.4,107.8,324.4,108.3C324.4,108.2,324.4,108.3,324.4,108.3
              L324.4,108.3z M322.3,110.3c0,0-2,7.2,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7
              c-0.5,2.7,0.5,5.5,2.5,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.7-0.2,3.4,0,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6
              c-0.5,2.1,1.3,4,1.3,4c0.2,0.3,0.2,0.7-0.1,0.9c-0.2,0.2-0.6,0.2-0.8,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4
              c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7c-1.6,0.2-3,0.3-5.7-2.4c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2
              c-0.5-0.8-0.7-0.8-2.1-1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4
              C322.2,109.6,322.3,109.9,322.3,110.3L322.3,110.3z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M354.6,56.9l1.5,1.6l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1.5,1.6L370,75c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-1,1c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0
              l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-1.3,1.2l-1.5-1.6l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-1-1l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1.6-1.8l1.3-1.2c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2L354.6,56.9z"/>

            <rect x="335.5" y="68.6" transform="matrix(0.7281 -0.6854 0.6854 0.7281 41.939 260.9543)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M362.6,78l1.4,1.5l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l1.5,1.6l-1.3,1.2c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-1,1
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.8
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-1.3,1.2l-1.5-1.6l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l0,0l-0.9-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.9-0.9l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1-1l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1.5-1.6l1.3-1.2c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0,0l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2
              l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2L362.6,78z"/>

            <rect x="343.4" y="89.7" transform="matrix(0.7281 -0.6854 0.6854 0.7281 29.6364 272.1159)" fill="#F5A049" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M380.1,84.7l1.5,1.6l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2
              s1.4,0.6,2,0.1l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.8,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1.5,1.6l-1.3,1.2c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2
              l-1,1c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-1.3,1.2l-1.5-1.6l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1-1l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1.5-1.6l1.3-1.2c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.8c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0,0l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2L380.1,84.7z"/>

            <rect x="360.9" y="96.3" transform="matrix(0.7281 -0.6854 0.6854 0.7281 29.8856 285.9335)" fill="#EF805F" width="28.9" height="18"/>
          </g>

          <rect x="262" y="38.2" transform="matrix(0.7281 -0.6854 0.6854 0.7281 21.6492 200.0624)" fill="#236095" width="2.1" height="69.1"/>

          <rect x="267" y="33.4" transform="matrix(0.7281 -0.6854 0.6854 0.7281 26.2609 202.2049)" fill="#236095" width="2.1" height="69.1"/>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M378,78.3L193,72l3.4-101.2l185,6.3L378,78.3z M371.9,72.1
                L375-17l-172.5-5.9l-3,89.1L371.9,72.1z"/>

              <rect x="242.6" y="-61.7" transform="matrix(3.385387e-02 -0.9994 0.9994 3.385387e-02 252.8194 310.8228)" fill="#FFFFFF" width="89.1" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="369.9,78.1 378.7,58.1 378.1,75.1 376.7,78.3
                  "/>
                <path fill="#0B99D6" d="M356.3,77.6l2.6-5.9l6.8,0.2l-2.6,5.9L356.3,77.6z M373,39.5
                  l6.8-15.5l-0.6,17l-6.8,15.5L373,39.5z"/>
                <path fill="#0B99D6" d="M342.6,77.1l2.6-5.9l6.8,0.2l-2.6,6L342.6,77.1L342.6,77.1z
                   M374.2,5.5L381-10l-0.6,17l-6.8,15.5L374.2,5.5z"/>
                <path fill="#0B99D6" d="M329,76.7l2.6-6l6.8,0.2l-2.6,6L329,76.7z M370.3-17.2l2.6-6
                  l6.8,0.2l-5,11.3L375-17L370.3-17.2z"/>
                <path fill="#0B99D6" d="M315.3,76.2l2.7-5.9l6.8,0.2l-2.6,5.9L315.3,76.2L315.3,76.2z
                   M356.7-17.6l2.6-5.9l6.8,0.2l-2.6,5.9L356.7-17.6z"/>
                <path fill="#0B99D6" d="M301.7,75.7l2.6-5.9l6.8,0.2l-2.6,6L301.7,75.7L301.7,75.7z
                   M343-18.1l2.6-5.9l6.8,0.2l-2.6,5.9L343-18.1z"/>
                <path fill="#0B99D6" d="M288.1,75.3l2.6-6l6.8,0.2l-2.6,6L288.1,75.3z M329.4-18.6
                  l2.6-5.9l6.8,0.2l-2.6,6L329.4-18.6z"/>
                <path fill="#0B99D6" d="M274.4,74.8l2.6-5.9l6.8,0.2l-2.6,6L274.4,74.8L274.4,74.8z
                   M315.7-19l2.6-6l6.8,0.2l-2.6,6L315.7-19z"/>
                <path fill="#0B99D6" d="M260.8,74.3l2.6-5.9l6.8,0.2l-2.6,5.9L260.8,74.3z
                   M302.1-19.5l2.6-5.9l6.8,0.2l-2.6,5.9L302.1-19.5z"/>
                <path fill="#0B99D6" d="M247.1,73.9l2.6-6l6.8,0.2l-2.6,5.9L247.1,73.9L247.1,73.9z
                   M288.5-19.9l2.6-6l6.8,0.2l-2.6,5.9L288.5-19.9z"/>
                <path fill="#0B99D6" d="M233.5,73.4l2.6-6l6.8,0.2l-2.6,6L233.5,73.4z M274.8-20.4
                  l2.6-6l6.8,0.2l-2.6,5.9L274.8-20.4z"/>
                <path fill="#0B99D6" d="M219.9,73l2.6-6l6.8,0.2l-2.6,5.9L219.9,73z M261.2-20.8
                  l2.6-5.9l6.8,0.2l-2.6,5.9L261.2-20.8z"/>
                <path fill="#0B99D6" d="M206.2,72.5l2.6-5.9l6.8,0.2l-2.6,5.9L206.2,72.5L206.2,72.5z
                   M247.5-21.3l2.6-5.9L257-27l-2.6,5.9L247.5-21.3z"/>
                <path fill="#0B99D6" d="M193,71.1l6.8-15.5l-0.4,10.7l2.6,0.1l-2.6,6l-6.4-0.3
                  L193,71.1L193,71.1z M233.9-21.8l2.6-6l6.8,0.2l-2.6,6L233.9-21.8z"/>
                <path fill="#0B99D6" d="M194.1,37l6.9-15.5l-0.6,17L193.6,54L194.1,37L194.1,37z
                   M220.2-22.3l2.6-6l6.8,0.2l-2.6,6L220.2-22.3z"/>
                <path fill="#0B99D6" d="M195.3,3l6.8-15.5l-0.6,17L194.7,20L195.3,3z M206.6-22.7
                  l2.6-5.9l6.8,0.2l-2.6,5.9L206.6-22.7z"/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M257.1,53.7c-6.4-0.2-11.5-5.6-11.3-12.1
                c0.2-6.4,5.6-11.5,12.1-11.3c6.5,0.2,11.5,5.6,11.3,12.1c-0.1,3.1-1.4,6-3.7,8.1C263.2,52.7,260.2,53.8,257.1,53.7z M250.3,48.7
                c3.7,4,9.9,4.2,13.9,0.5s4.2-9.9,0.5-13.9s-9.9-4.2-13.9-0.5c-1.9,1.8-3,4.3-3.1,6.9C247.6,44.3,248.5,46.9,250.3,48.7
                L250.3,48.7z"/>
              <path fill="#80CAF0" d="M255.9,46.7c0.1-0.3,0.5-0.4,0.8-0.3c0.3,0.1,0.4,0.5,0.3,0.8
                c0,0.1-0.1,0.1-0.1,0.2c0,0-4.8,7.3-10.4,3.2c-1.9-1.4-3-2.3-3.8-3c-1.1-0.9-1.3-1.1-2.2-0.9c-0.6,0.3-1.1,0.7-1.5,1.2
                c-2,2.4-5,3.7-8.1,3.4c-3.9-0.2-4.6-1.3-5.6-2.7c-0.8-1.2-2-2.2-3.4-2.7c-1.7-0.9-3.7-1-5.4-0.3c-1.2,0.6-2.2,1.6-2.9,2.7
                c-1.6,2.3-4.9,2.2-4.9,2.2c-0.3,0-0.6-0.3-0.6-0.6l0,0c0-0.3,0.3-0.6,0.6-0.6l0,0c0,0,2.6,0.1,3.8-1.7c0.8-1.3,2-2.4,3.4-3.1
                c2.1-0.9,4.4-0.8,6.4,0.2c1.6,0.6,2.9,1.7,3.9,3.1c0.8,1.1,1.4,2,4.7,2.2c2.7,0.2,5.4-0.9,7.1-3c0.6-0.7,1.3-1.3,2.2-1.6
                c1.5-0.3,1.8-0.1,3.2,1.2c0.7,0.6,1.8,1.6,3.7,2.9C251.9,52.9,255.9,46.7,255.9,46.7L255.9,46.7z M256.3,35.2
                c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.3-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3
                c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.2c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.5,3.1c-1.2,1.8-3.8,1.7-3.8,1.7
                c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6l0,0c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7c1.8-0.7,3.7-0.6,5.4,0.3
                c1.4,0.5,2.6,1.5,3.4,2.7c1,1.3,1.8,2.4,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9
                c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9l0,0C256.9,34.9,256.5,34.9,256.3,35.2L256.3,35.2
                L256.3,35.2z M256.2,38.1c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.2-1.2c-0.9,0.3-1.6,0.8-2.2,1.6
                c-1.7,2.1-4.4,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.1c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.4,3.1
                c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.6,0.6l0,0c0,0.3,0.3,0.6,0.6,0.6c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7
                c1.8-0.7,3.7-0.6,5.4,0.3c1.4,0.5,2.6,1.5,3.4,2.7c1,1.3,1.8,2.5,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2
                c0.9-0.2,1.1,0,2.2,0.9c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.8l0,0
                C256.8,37.7,256.4,37.8,256.2,38.1L256.2,38.1z M256.1,40.9c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9
                c-1.5-1.3-1.7-1.5-3.2-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.1
                c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6l0,0
                c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7c1.8-0.7,3.8-0.6,5.4,0.3c1.4,0.5,2.5,1.5,3.4,2.7c1,1.3,1.8,2.5,5.6,2.7
                c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2
                c0.2-0.3,0.1-0.7-0.2-0.9C256.8,40.6,256.4,40.7,256.1,40.9C256.2,40.9,256.2,40.9,256.1,40.9L256.1,40.9z M256,43.8
                c0,0-4,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.8-1.5-3.3-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3
                c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.1c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7
                c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7c1.8-0.7,3.7-0.6,5.4,0.3
                c1.4,0.5,2.5,1.5,3.4,2.7c1,1.3,1.8,2.5,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9
                c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.8l0,0C256.6,43.4,256.2,43.5,256,43.8L256,43.8
                L256,43.8z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M335.7,18.5l-0.5-2.1l0,0c0.8-0.2,1.2-1,1-1.8
              c-0.2-0.7-0.9-1.2-1.6-1l0,0l-0.3-1.4l0,0c0.8-0.2,1.2-0.9,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l0,0l-0.3-1.2l0,0
              c0.8-0.2,1.2-0.9,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.3-1.2l0,0c0.8-0.2,1.2-0.9,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0
              l-0.3-1.2l0,0c0.8-0.2,1.2-0.9,1.1-1.7l0,0c-0.2-0.8-1-1.2-1.7-1.1l0,0l-0.5-2.1l1.7-0.4c0.2,0.8,0.9,1.2,1.7,1.1l0,0
              c0.8-0.2,1.2-1,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1l0,0c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.2-0.3c0.2,0.8,1,1.2,1.7,1.1
              c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1l0,0c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.4-0.3c0.2,0.8,0.9,1.2,1.7,1.1
              l0,0c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1l0,0c0.8-0.2,1.2-1,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1
              l0,0c0.8-0.2,1.2-1,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1l0,0c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.7-0.4l0.5,2.1l0,0
              c-0.8,0.2-1.3,0.9-1.1,1.7l0,0c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0,0l0.3,1.2l0,0c-0.8,0.2-1.2,0.9-1.1,1.7l0,0
              c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0,0l0.3,1.2l0,0c-0.8,0.2-1.3,0.9-1.1,1.7l0,0c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0,0l0.3,1.2l0,0
              c-0.8,0.2-1.2,1-1,1.8c0.2,0.7,0.9,1.2,1.6,1l0,0l0.3,1.4l0,0c-0.8,0.2-1.2,0.9-1.1,1.7c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0,0l0.5,2.1
              l-1.7,0.4c-0.1-0.8-0.9-1.3-1.7-1.2s-1.3,0.9-1.2,1.7c0,0.1,0,0.1,0,0.2l-1.2,0.3c-0.2-0.8-1-1.2-1.7-1.1s-1.2,1-1.1,1.7
              l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1s-1.2,0.9-1.1,1.7l0,0l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1l0,0c-0.8,0.2-1.2,0.9-1.1,1.7l0,0
              l-1.4,0.3c-0.1-0.8-0.9-1.3-1.7-1.2c-0.8,0.1-1.3,0.9-1.2,1.7c0,0.1,0,0.1,0,0.2l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1
              s-1.2,0.9-1.1,1.7l0,0l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1
              c-0.8,0.2-1.2,0.9-1.1,1.7L335.7,18.5z"/>

            <rect x="335.7" y="-6" transform="matrix(0.9737 -0.2278 0.2278 0.9737 8.5219 79.8446)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M321.2,83.7L432.1,232l81-60.6L402.3,23.1L321.2,83.7z
                 M329.8,85.1l71.4-53.4L504.6,170l-71.4,53.4L329.8,85.1z"/>

              <rect x="372.6" y="41.3" transform="matrix(0.801 -0.5986 0.5986 0.801 6.5943 275.1113)" fill="#D1CCE6" width="89.1" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="326.1,90.2 337.5,71.6 323.8,81.8 322,84.7           "/>
                <path fill="#0B99D6" d="M334.3,101.1l3.4-5.5l-4.1-5.5l-3.4,5.6L334.3,101.1z
                   M355.9,65.6l8.8-14.4l-13.6,10.2l-8.8,14.4L355.9,65.6z"/>
                <path fill="#0B99D6" d="M342.4,112.1l3.4-5.6l-4.1-5.4l-3.4,5.5L342.4,112.1z
                   M383.2,45.2l8.8-14.4L378.4,41l-8.8,14.4L383.2,45.2z"/>
                <path fill="#0B99D6" d="M350.6,123l3.4-5.5l-4.1-5.5l-3.4,5.5L350.6,123z M404,35.5
                  l3.4-5.5l-4.1-5.5L396.9,35l4.4-3.3L404,35.5z"/>
                <path fill="#0B99D6" d="M358.8,133.9l3.4-5.6l-4.1-5.5l-3.4,5.5L358.8,133.9z
                   M412.2,46.4l3.4-5.5l-4.1-5.5l-3.4,5.5L412.2,46.4z"/>
                <path fill="#0B99D6" d="M367,144.9l3.4-5.5l-4.1-5.5l-3.4,5.5L367,144.9z M420.4,57.3
                  l3.4-5.5l-4.1-5.5l-3.4,5.5L420.4,57.3z"/>
                <path fill="#0B99D6" d="M375.1,155.8l3.4-5.5l-4.1-5.5l-3.4,5.6L375.1,155.8z
                   M428.5,68.3l3.4-5.5l-4.1-5.5l-3.4,5.5L428.5,68.3z"/>
                <path fill="#0B99D6" d="M383.3,166.7l3.4-5.6l-4.1-5.5l-3.4,5.6L383.3,166.7z
                   M436.7,79.2l3.4-5.5l-4.1-5.5l-3.4,5.6L436.7,79.2z"/>
                <path fill="#0B99D6" d="M391.5,177.6l3.4-5.5l-4.1-5.5l-3.4,5.5L391.5,177.6z
                   M444.9,90.1l3.4-5.6l-4.1-5.5l-3.4,5.6L444.9,90.1z"/>
                <path fill="#0B99D6" d="M399.6,188.6l3.4-5.6l-4-5.4l-3.4,5.6L399.6,188.6
                  L399.6,188.6z M453,101.1l3.4-5.5l-4.1-5.5l-3.4,5.5L453,101.1z"/>
                <path fill="#0B99D6" d="M407.8,199.5l3.4-5.5l-4.1-5.4l-3.4,5.5L407.8,199.5
                  L407.8,199.5z M461.2,112l3.4-5.6l-4.1-5.4l-3.4,5.6L461.2,112z"/>
                <path fill="#0B99D6" d="M416,210.4l3.4-5.5l-4.1-5.5l-3.4,5.6L416,210.4L416,210.4z
                   M469.4,122.9l3.4-5.6l-4.1-5.4l-3.4,5.5L469.4,122.9z"/>
                <path fill="#0B99D6" d="M424.2,221.4l3.4-5.6l-4.1-5.5l-3.4,5.6L424.2,221.4z
                   M477.6,133.9l3.4-5.5l-4.1-5.5l-3.4,5.5L477.6,133.9z"/>
                <path fill="#0B99D6" d="M432.9,231.4l8.8-14.4l-8.5,6.4l-1.5-2l-3.4,5.5l3.8,5.1
                  L432.9,231.4z M485.7,144.8l3.4-5.6l-4.1-5.5l-3.4,5.6L485.7,144.8z"/>
                <path fill="#0B99D6" d="M460.2,211l8.8-14.4l-13.6,10.2l-8.8,14.4L460.2,211z
                   M493.9,155.7l3.4-5.5l-4.1-5.5l-3.4,5.5L493.9,155.7z"/>
                <path fill="#0B99D6" d="M487.5,190.6l8.8-14.4l-13.6,10.2l-8.8,14.4L487.5,190.6z
                   M502.1,166.7l3.4-5.6l-4.1-5.5l-3.4,5.6L502.1,166.7z"/>
                <polygon fill="#0B99D6" points="509.5,166.6 501.1,180.4 513.2,171.4           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M410.5,168.9c3.9,5.2,11.2,6.2,16.3,2.4
                c5.2-3.9,6.2-11.2,2.4-16.3c-3.9-5.2-11.2-6.2-16.3-2.4c-2.5,1.9-4.1,4.6-4.6,7.7C407.9,163.3,408.7,166.5,410.5,168.9z
                 M418.5,171.6c-5.4-0.8-9.1-5.8-8.3-11.1s5.8-9.1,11.1-8.3c5.4,0.8,9.1,5.8,8.3,11.1c-0.4,2.6-1.8,4.9-3.8,6.4
                C423.7,171.4,421.1,172,418.5,171.6L418.5,171.6z"/>
              <path fill="#80CAF0" d="M417,165.8c0.1-0.3-0.1-0.7-0.4-0.8s-0.6,0-0.8,0.3
                c0,0-3.3,8.1,3.3,10.4c2.2,0.8,3.6,1.1,4.6,1.4c1.4,0.4,1.6,0.4,2,1.3c0.1,0.7,0.1,1.4-0.1,2c-0.8,3-0.2,6.2,1.8,8.6
                c2.4,3.1,3.7,3.1,5.4,3.1c1.5-0.1,2.9,0.4,4.1,1.2c1.7,0.9,2.9,2.4,3.3,4.3c0.2,1.4,0,2.7-0.5,4c-1,2.6,1,5.3,1,5.3
                c0.2,0.3,0.6,0.3,0.9,0.1s0.3-0.6,0.1-0.9c0,0-1.6-2.1-0.8-4.1c0.6-1.5,0.8-3,0.6-4.6c-0.5-2.2-1.9-4.1-3.9-5.1
                c-1.4-1-3.1-1.4-4.8-1.4c-1.3,0-2.4,0-4.5-2.6c-1.8-2.1-2.4-5-1.6-7.6c0.3-0.9,0.3-1.8,0-2.7c-0.6-1.4-0.9-1.5-2.8-2
                c-0.9-0.2-2.3-0.6-4.5-1.4C414.2,172.8,417,165.9,417,165.8L417,165.8z M426.2,158.9c0,0-2.8,6.9,2.5,8.7
                c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.5,2.6
                c1.7-0.1,3.4,0.4,4.8,1.4c2,1.1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.2-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.2,0.7-0.1,0.9l0,0
                c-0.3,0.2-0.7,0.2-0.9-0.1l0,0c0,0-2-2.7-1-5.3c0.5-1.2,0.7-2.6,0.5-3.9c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.1-1.2
                c-1.7,0-3,0-5.4-3.1c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.3-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4
                c-6.5-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.4C426.2,158.2,426.3,158.6,426.2,158.9L426.2,158.9z M423.9,160.7
                c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.5,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.8,2.6-0.2,5.5,1.6,7.6
                c2,2.6,3.1,2.6,4.4,2.6c1.7-0.1,3.4,0.4,4.8,1.4c2,1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.1-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1
                c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1l0,0c0,0-2-2.7-1-5.3c0.5-1.2,0.7-2.6,0.5-3.9c-0.4-1.9-1.6-3.4-3.3-4.3
                c-1.2-0.8-2.7-1.3-4.2-1.2c-1.7,0-3,0-5.4-3.1c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.2-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3
                c-1-0.2-2.4-0.6-4.6-1.4c-6.5-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3C423.9,159.9,424,160.3,423.9,160.7L423.9,160.7
                L423.9,160.7z M421.6,162.4c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.2,0.9,0.2,1.8,0,2.7
                c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.5,2.6c1.7-0.1,3.4,0.4,4.8,1.4c2,1.1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.2-0.6,4.6
                c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1l0,0c0,0-2-2.7-1-5.3c0.5-1.2,0.7-2.6,0.5-3.9
                c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.1-1.2c-1.7,0-3-0.1-5.4-3.1c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.2-1.3,0.1-2
                c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.5-2.3-3.3-10.4-3.3-10.4c0.1-0.3,0.5-0.4,0.8-0.3
                C421.5,161.7,421.7,162,421.6,162.4L421.6,162.4z M419.3,164.1c0,0-2.8,6.9,2.5,8.8c2.2,0.8,3.6,1.1,4.5,1.4
                c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6c1.7-0.1,3.4,0.4,4.8,1.4
                c2,1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.2-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.2,0.7-0.1,0.9l0,0c-0.3,0.2-0.7,0.2-0.9,0
                l-0.1-0.1c0,0-2-2.7-1-5.3c0.5-1.3,0.7-2.6,0.5-4c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1
                c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.3-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.5-2.3-3.3-10.3-3.3-10.4
                c0.1-0.3,0.5-0.5,0.8-0.3c0.3,0.1,0.5,0.5,0.3,0.8l0,0H419.3z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M451.3,113.2l1.3,1.7l0,0c-0.6,0.5-0.8,1.4-0.3,2
                s1.4,0.8,2,0.3l0,0l0,0l0.9,1.1l0,0c-0.6,0.5-0.7,1.4-0.1,2c0.5,0.5,1.3,0.7,1.9,0.3l0,0l0.7,1l0,0c-0.6,0.5-0.8,1.4-0.3,2
                s1.4,0.8,2,0.3l0,0l0,0l0.8,1l0,0c-0.6,0.5-0.7,1.4-0.1,2c0.5,0.5,1.3,0.7,1.9,0.3l0,0l0.7,1l0,0c-0.7,0.4-0.8,1.3-0.4,2
                s1.3,0.8,2,0.4c0,0,0.1,0,0.1-0.1l0,0l1.3,1.7l-1.4,1.1c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.7-2-0.2
                s-0.7,1.3-0.3,1.9l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l0,0l-1,0.7c-0.4-0.6-1.3-0.8-2-0.4c-0.6,0.4-0.8,1.3-0.4,2
                c0,0,0,0.1,0.1,0.1l-1.1,0.9c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7
                c-0.3-0.7-1.2-1-1.9-0.7s-1,1.2-0.7,1.9c0.1,0.2,0.2,0.4,0.4,0.5l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1.4,1l-1.3-1.7
                l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l0,0l-0.7-1l0,0
                c0.6-0.5,0.7-1.4,0.1-2c-0.5-0.5-1.3-0.7-1.9-0.3l0,0l-0.7-1l0,0c0.6-0.5,0.7-1.4,0.2-2s-1.3-0.7-1.9-0.3l0,0l-0.8-1.1l0,0
                c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-1.3-1.7l1.4-1.1c0.5,0.6,1.4,0.8,2,0.3l0,0c0.6-0.5,0.8-1.4,0.3-2l1-0.7
                c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l0,0l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l0,0l1-0.7c0.5,0.6,1.4,0.8,2,0.3
                s0.8-1.4,0.3-2l1.1-0.8c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3l0,0c0.6-0.5,0.8-1.4,0.3-2l1-0.7
                c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l0,0L451.3,113.2z"/>

              <rect x="429.8" y="124.2" transform="matrix(0.8009 -0.5987 0.5987 0.8009 8.6545 292.4879)" fill="#EF805F" width="28.9" height="18"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M489.7,174.1l-2.2,1.7l0,0c-0.6-0.8-1.8-1-2.6-0.4
                c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.5,1.1l0,0c-0.6-0.8-1.8-0.9-2.6-0.3c-0.8,0.6-0.9,1.7-0.4,2.5l0,0l-1.3,1l0,0
                c-0.6-0.8-1.8-0.9-2.6-0.3c-0.8,0.6-0.9,1.7-0.4,2.5l0,0l-1.3,1l0,0c-0.6-0.8-1.8-0.9-2.6-0.3c-0.8,0.6-0.9,1.7-0.4,2.5l0,0
                l-1.3,1l0,0c-0.6-0.8-1.8-1-2.6-0.4c-0.8,0.6-1,1.8-0.4,2.6l0,0l-2.1,1.6l-1.4-1.8c0.8-0.6,0.9-1.8,0.3-2.6s-1.7-0.9-2.5-0.4
                l-1-1.3c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1.1-1.8,0.5-2.6s-1.8-1.1-2.6-0.5c0,0-0.1,0-0.1,0.1l-1-1.3
                c0.8-0.6,1.1-1.8,0.5-2.6s-1.8-1.1-2.6-0.5c0,0-0.1,0-0.1,0.1l-1.1-1.5c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l0,0l-1-1.3
                c0.8-0.6,0.9-1.8,0.3-2.6s-1.7-0.9-2.5-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6
                s-1.8-1-2.6-0.4l-1.4-1.8l2.2-1.7l0,0c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l0,0l1.3-1l0,0c0.6,0.8,1.8,1,2.6,0.4
                c0.8-0.6,1-1.8,0.4-2.6l0,0l1.3-1l0,0c0.6,0.8,1.8,1.1,2.6,0.5c0.8-0.6,1.1-1.8,0.5-2.6c0,0,0-0.1-0.1-0.1l0,0l1.3-1l0,0
                c0.6,0.8,1.8,1.1,2.6,0.5c0.8-0.6,1.1-1.8,0.5-2.6c0,0,0-0.1-0.1-0.1l0,0l1.5-1.1l0,0c0.6,0.8,1.8,1,2.6,0.4
                c0.8-0.6,1-1.8,0.4-2.6l0,0l2.2-1.7l1.4,1.8c-0.8,0.6-1.1,1.8-0.5,2.6s1.8,1.1,2.6,0.5c0,0,0.1,0,0.1-0.1l1,1.3
                c-0.8,0.6-1,1.8-0.4,2.6l0,0c0.6,0.8,1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1.1,1.8-0.5,2.6s1.8,1.1,2.6,0.5c0,0,0.1,0,0.1-0.1l1,1.3
                c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1.1,1.5c-0.8,0.6-0.9,1.8-0.3,2.6s1.7,0.9,2.5,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6
                s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l0,0l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l0,0L489.7,174.1z"
              />

              <rect x="451.8" y="146.1" transform="matrix(0.8009 -0.5987 0.5987 0.8009 -6.4909 310.3488)" fill="#F5A049" width="23.5" height="37.6"/>
            </g>

            <rect x="369.2" y="82.3" transform="matrix(0.801 -0.5986 0.5986 0.801 5.5339 244.2825)" fill="#A76C2B" width="2" height="63.1"/>
          </g>

          <rect x="374.4" y="78.5" transform="matrix(0.801 -0.5986 0.5986 0.801 8.8256 246.5869)" fill="#A76C2B" width="2" height="63.1"/>
        </g>
        <g>
          <path fill="#E2070F" d="M48-42.7l-93.3,231l126.2,51l93.3-231L48-42.7z M52.3-31.8
            l111.2,45l-87,215.3l-111.2-45L52.3-31.8z"/>

          <rect x="-51.6" y="38.4" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -50.8452 121.2787)" fill="#E0DCC1" width="232.2" height="119.9"/>
          <path fill="#0B99D6" d="M30.2,1.5h8.7L42.4-7h-8.7L30.2,1.5L30.2,1.5z M135.5,1.8
            l22.8,0.1l-21.2-8.6l-22.8-0.1L135.5,1.8z"/>
          <path fill="#0B99D6" d="M23.3,18.5H32l3.4-8.5h-8.7L23.3,18.5z M161.3,19h8.7l3.4-8.5
            l-16.6-0.1l6.8,2.7L161.3,19z"/>
          <path fill="#0B99D6" d="M16.4,35.6h8.7l3.4-8.5h-8.7L16.4,35.6z M154.4,36h8.7l3.4-8.5
            h-8.7L154.4,36z"/>
          <path fill="#0B99D6" d="M9.5,52.6h8.7l3.4-8.5h-8.7L9.5,52.6z M147.5,53h8.7l3.4-8.5H151
            L147.5,53z"/>
          <path fill="#0B99D6" d="M2.7,69.6h8.7l3.4-8.5H6.1L2.7,69.6z M140.6,70.1h8.7l3.4-8.5
            H144L140.6,70.1z"/>
          <path fill="#0B99D6" d="M-4.2,86.7h8.7L8,78.2h-8.7L-4.2,86.7L-4.2,86.7z M133.8,87.1
            h8.7l3.4-8.5h-8.7L133.8,87.1z"/>
          <path fill="#0B99D6" d="M-11.1,103.7h8.7L1,95.2h-8.7L-11.1,103.7z M126.9,104.1h8.7
            l3.4-8.5h-8.7L126.9,104.1z"/>
          <path fill="#0B99D6" d="M-18,120.7h8.7l3.4-8.5h-8.7L-18,120.7L-18,120.7z M120,121.2
            h8.7l3.4-8.5h-8.7L120,121.2z"/>
          <path fill="#0B99D6" d="M-24.9,137.8h8.7l3.4-8.5h-8.7L-24.9,137.8L-24.9,137.8z
             M113.1,138.2h8.7l3.4-8.5h-8.7L113.1,138.2z"/>
          <path fill="#0B99D6" d="M-31.8,154.8h8.7l3.4-8.5h-8.7L-31.8,154.8L-31.8,154.8z
             M106.2,155.2h8.7l3.4-8.5h-8.7L106.2,155.2z"/>
          <path fill="#0B99D6" d="M-38.6,171.8h8.7l3.4-8.5h-8.7
            C-35.2,163.3-38.6,171.8-38.6,171.8z M99.4,172.2h8.7l3.4-8.5h-8.7L99.4,172.2z"/>
          <path fill="#0B99D6" d="M-44.1,188.9l22.8,0.1l-13.3-5.4l1.3-3.2H-42l-3.2,8L-44.1,188.9
            L-44.1,188.9z M92.4,189.3h8.7l3.4-8.5h-8.7L92.4,189.3z"/>
          <path fill="#0B99D6" d="M-1.6,206l22.8,0.1L0,197.5l-22.8-0.1L-1.6,206z M85.5,206.3h8.7
            l3.4-8.5H89L85.5,206.3z"/>
          <g>
            <path fill="#80CAF0" d="M12.2,119.5c-3.3,8,0.6,17.2,8.6,20.5s17.2-0.6,20.5-8.6
              s-0.6-17.2-8.6-20.5c-3.9-1.6-8.2-1.6-12.1,0.1C16.8,112.6,13.8,115.7,12.2,119.5L12.2,119.5z M14.6,130.6
              c-2.8-6.7,0.3-14.5,7-17.3s14.5,0.3,17.3,7c2.8,6.7-0.3,14.5-7,17.3c-3.2,1.4-6.9,1.4-10.1,0.1C18.6,136.4,16,133.8,14.6,130.6z"
            />
            <path fill="#80CAF0" d="M20.3,124.8c0.5-0.1,0.8-0.5,0.7-0.9c0,0,0,0,0-0.1
              c-0.1-0.5-0.5-0.8-1-0.7l0,0c0,0-11.6,1.9-9.6,11c0.6,3.1,1.2,5,1.6,6.3c0.6,1.9,0.6,2.2-0.1,3.2c-0.7,0.6-1.5,1-2.3,1.2
              c-2.7,1-6.4,2.4-8.6,8.1c-1.8,4.9-0.9,6.4,0.2,8.4c1.1,1.7,1.6,3.6,1.5,5.6c0,3.4-1.1,5.5-2.6,6.8c-1.4,1.1-3.1,1.9-4.9,2.1
              c-3.7,0.7-5.4,4.8-5.4,4.8c-0.2,0.4,0,0.9,0.5,1.1l0,0c0.4,0.2,0.9,0,1.1-0.4c0,0,1.3-3.3,4.2-3.8c2.1-0.3,4.1-1.1,5.8-2.4
              c1.8-1.6,3.2-4.1,3.2-8.1c0.1-2.3-0.5-4.6-1.8-6.5c-0.9-1.6-1.6-2.8-0.1-6.9c1.9-5,5.2-6.2,7.6-7.1c1.2-0.3,2.3-0.9,3.1-1.8
              c1.2-1.7,1.1-2.1,0.3-4.6c-0.4-1.2-0.9-3.1-1.6-6.1C10.4,126.4,20.3,124.8,20.3,124.8L20.3,124.8z M34.6,130.6c0,0-9.9,1.6-8.3,9
              c0.6,3,1.2,4.9,1.6,6.1c0.8,2.5,0.9,3-0.3,4.6c-0.8,0.9-1.9,1.5-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9
              c1.3,1.9,1.9,4.2,1.8,6.5c0,4-1.4,6.5-3.2,8.1c-1.6,1.3-3.6,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.4
              c-0.4-0.2-0.5-0.6-0.4-1c0,0,1.7-4.1,5.4-4.8c1.8-0.3,3.5-1,4.9-2.1c1.5-1.3,2.6-3.4,2.6-6.8c0.1-2-0.4-4-1.5-5.6
              c-1.1-1.9-2.1-3.5-0.2-8.4c2.2-5.7,5.9-7.1,8.6-8.1c0.9-0.2,1.7-0.6,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3
              c-1.9-9.1,9.6-11,9.6-11c0.5-0.1,0.9,0.2,1,0.7C35.4,130,35.1,130.5,34.6,130.6L34.6,130.6z M31,129.1c0,0-9.9,1.6-8.3,9
              c0.6,3,1.2,4.9,1.6,6.1c0.8,2.5,0.9,3-0.3,4.6c-0.8,0.9-1.9,1.5-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9
              c1.2,1.9,1.9,4.2,1.8,6.5c0,4-1.4,6.5-3.2,8c-1.6,1.3-3.6,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.1,0.5-0.5,0.8-0.9,0.7
              c-0.5-0.1-0.8-0.5-0.7-0.9c0-0.1,0.1-0.2,0.1-0.3c0,0,1.7-4.1,5.4-4.8c1.8-0.3,3.5-1,4.9-2.1c1.5-1.3,2.6-3.4,2.6-6.8
              c0.1-2-0.4-4-1.5-5.6c-1.1-1.9-2-3.5-0.2-8.4c2.2-5.7,5.9-7.1,8.6-8.1c0.9-0.2,1.7-0.6,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2
              c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11c0.4-0.1,0.9,0.2,0.9,0.7l0,0C31.8,128.6,31.5,129,31,129.1
              C31.1,129.1,31,129.1,31,129.1L31,129.1z M27.4,127.7c0,0-9.9,1.6-8.3,9c0.6,3,1.2,4.9,1.6,6.1c0.8,2.5,0.9,3-0.3,4.6
              c-0.8,0.9-1.9,1.5-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9c1.3,1.9,1.9,4.2,1.7,6.5c0,4-1.4,6.5-3.2,8.1
              c-1.6,1.3-3.6,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.6-0.7-0.5-1.1c0,0,1.7-4.1,5.4-4.8
              c1.8-0.3,3.5-1,4.9-2.1c1.5-1.3,2.6-3.4,2.6-6.8c0.1-2-0.4-4-1.5-5.6c-1.1-1.9-2-3.5-0.2-8.4c2.2-5.7,5.9-7.1,8.6-8.1
              c0.9-0.2,1.7-0.6,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11c0.5-0.1,0.9,0.2,1,0.7l0,0
              C28.2,127.1,28,127.6,27.4,127.7C27.5,127.7,27.5,127.7,27.4,127.7L27.4,127.7z M23.9,126.2c0,0-9.9,1.6-8.3,9
              c0.6,3,1.2,4.9,1.6,6.1c0.8,2.5,0.9,3-0.3,4.6c-0.8,0.9-1.9,1.5-3.1,1.8c-2.4,0.9-5.7,2.2-7.6,7.2c-1.6,4.1-0.8,5.4,0.1,6.9
              c1.3,1.9,1.9,4.2,1.8,6.4c0,4-1.4,6.5-3.2,8.1c-1.7,1.4-3.7,2.3-5.8,2.6c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5l0,0
              c-0.4-0.2-0.6-0.7-0.5-1.1l0,0c0,0,1.7-4.2,5.4-4.9c1.8-0.3,3.5-1,4.9-2.1c1.5-1.3,2.6-3.4,2.6-6.8c0.1-2-0.4-4-1.5-5.6
              c-1.1-1.9-2-3.5-0.2-8.4c2.2-5.7,5.9-7.1,8.6-8.1c0.9-0.2,1.7-0.7,2.3-1.3c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3
              c-1.9-9.1,9.6-11,9.6-11c0.4-0.1,0.9,0.2,0.9,0.7l0,0C24.7,125.7,24.4,126.1,23.9,126.2L23.9,126.2L23.9,126.2z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M88.4,140.2l-0.8,2l0,0c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9h0.1l0,0l-0.5,1.3l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9
              l0,0l-0.5,1.2l0,0c-0.8-0.2-1.6,0.2-1.8,0.9c-0.2,0.7,0.1,1.4,0.7,1.7l0,0l-0.5,1.1l0,0c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9
              h0.1l0,0l-0.5,1.1l0,0c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9h0.1l0,0l-0.8,2l-1.6-0.6c0.3-0.7,0-1.6-0.7-1.9
              c-0.7-0.3-1.6,0-1.9,0.7v0.1l-1.2-0.5c0.3-0.7-0.1-1.6-0.9-1.8c-0.7-0.3-1.5,0.1-1.8,0.8l-1.2-0.5c0.3-0.7-0.1-1.6-0.8-1.9
              c-0.7-0.3-1.6,0.1-1.9,0.8l-1.2-0.5c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7v0.1l-1.3-0.5c0.2-0.8-0.2-1.6-0.9-1.8
              s-1.4,0.1-1.7,0.7l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l-1.2-0.5c0.3-0.7-0.1-1.6-0.9-1.8
              c-0.7-0.3-1.5,0.1-1.8,0.8l-1.2-0.5c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7v0.1l-1.6-0.6l0.8-2l0,0
              c0.7,0.3,1.6-0.1,1.8-0.9c0.3-0.7-0.1-1.5-0.8-1.8l0,0l0.5-1.1l0,0c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0,0l0.5-1.1
              l0,0c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0,0l0.5-1.1l0,0c0.7,0.4,1.5,0.3,2-0.4c0.4-0.7,0.3-1.5-0.4-2
              c-0.2-0.1-0.3-0.2-0.5-0.2l0,0l0.5-1.3l0,0c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.8-2l1.6,0.7
              c-0.2,0.8,0.2,1.6,1,1.8c0.7,0.2,1.3-0.1,1.7-0.7L61,129c-0.3,0.7,0,1.6,0.7,1.9c0.7,0.3,1.6,0,1.9-0.7v-0.1l1.2,0.5
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.2,0.5c-0.3,0.7,0.1,1.6,0.9,1.8c0.7,0.3,1.5-0.1,1.8-0.8l1.3,0.5
              c-0.3,0.7,0.1,1.6,0.9,1.8c0.7,0.3,1.5-0.1,1.8-0.8l1.2,0.5c-0.3,0.7,0.1,1.6,0.8,1.9s1.6-0.1,1.9-0.8l1.1,0.5
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.2,0.5c-0.3,0.7,0.1,1.6,0.9,1.8c0.7,0.3,1.5-0.1,1.8-0.8L88.4,140.2z"/>

            <rect x="58.7" y="129.3" transform="matrix(0.3697 -0.9292 0.9292 0.3697 -90.8525 153.5393)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M51,203.5l-2.4-1l0,0c0.3-0.9-0.1-1.9-1-2.2s-1.8,0.1-2.2,0.9l0,0l-1.6-0.6l0,0c0.3-0.9-0.1-1.9-1-2.2s-1.8,0.1-2.2,0.9l0,0
              l-1.4-0.6l0,0c0.4-0.9,0-1.9-0.9-2.3c-0.9-0.4-1.9,0-2.3,0.9v0.1l0,0l-1.4-0.5l0,0c0.4-0.9-0.1-1.9-0.9-2.2
              c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-1.4-0.6l0,0c0.4-0.9-0.1-1.9-0.9-2.2c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-2.4-1l0.8-1.9
              c0.9,0.4,1.9,0,2.3-0.9s0-1.9-0.9-2.3h-0.1l0.6-1.4c0.9,0.3,1.9-0.1,2.2-1c0.3-0.9-0.1-1.8-0.9-2.2l0.5-1.4
              c0.9,0.3,1.9-0.1,2.2-1c0.3-0.9-0.1-1.8-0.9-2.2l0.5-1.4c0.9,0.4,1.9-0.1,2.2-0.9s-0.1-1.9-0.9-2.2l0.6-1.6
              c0.9,0.4,1.9-0.1,2.2-0.9l0,0c0.4-0.9-0.1-1.9-0.9-2.2l0.5-1.4c0.9,0.3,1.9-0.1,2.2-1s-0.1-1.9-1-2.2l0.5-1.4
              c0.9,0.4,1.9,0,2.3-0.9c0.4-0.9,0-1.9-0.9-2.3h-0.1l0.6-1.4c0.9,0.3,1.9-0.1,2.2-1s-0.1-1.8-0.9-2.2l0.8-1.9l2.4,1l0,0
              c-0.4,0.9,0.1,1.9,1,2.2c0.9,0.4,1.9-0.1,2.2-1l0,0l0,0l1.4,0.6l0,0c-0.3,0.9,0.2,1.9,1.1,2.2c0.8,0.3,1.7-0.1,2.1-0.9l0,0
              l1.4,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2c0.9,0.4,1.9-0.1,2.2-0.9l0,0l1.4,0.6l0,0c-0.4,0.9,0,1.9,0.9,2.3c0.9,0.4,1.9,0,2.3-0.9
              v-0.1l0,0l1.6,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2s1.9-0.1,2.2-0.9l0,0l2.4,1l-0.8,1.9c-0.9-0.4-1.9,0.1-2.2,0.9s0.1,1.9,0.9,2.2
              l0,0l-0.5,1.3c-0.9-0.3-1.9,0.1-2.2,1s0.1,1.8,0.9,2.2l-0.5,1.4c-0.9-0.3-1.9,0.2-2.1,1.1c-0.2,0.8,0.1,1.6,0.9,2l-0.5,1.4
              c-0.9-0.4-1.9,0-2.3,0.9c-0.4,0.9,0,1.9,0.9,2.3h0.1l-0.6,1.6c-0.9-0.4-1.9,0.1-2.2,1c-0.4,0.9,0.1,1.9,1,2.2l0,0l-0.5,1.4
              c-0.9-0.3-1.9,0.1-2.2,1s0.1,1.8,0.9,2.2l-0.5,1.4c-0.9-0.4-1.9,0-2.3,0.9c-0.4,0.9,0,1.9,0.9,2.3h0.1l-0.6,1.3
              c-0.9-0.4-1.9,0.1-2.2,0.9l0,0c-0.4,0.9,0.1,1.9,0.9,2.2L51,203.5z"/>

            <rect x="28.5" y="168" transform="matrix(0.3748 -0.9271 0.9271 0.3748 -137.0804 154.1597)" fill="#FBD4A4" width="34.5" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M79.4,201.8l-2-0.8l0,0c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7v0.1l0,0l-1.3-0.5l0,0c0.3-0.7,0-1.6-0.7-1.9
              c-0.7-0.3-1.6,0-1.9,0.7v0.1l0,0l-1.2-0.5l0,0c0.4-0.7,0.2-1.6-0.5-2s-1.6-0.2-2,0.5c-0.1,0.1-0.1,0.3-0.2,0.4l0,0l-1.2-0.5l0,0
              c0.2-0.8-0.2-1.6-0.9-1.8c-0.7-0.2-1.4,0.1-1.7,0.7l0,0l-1.2-0.4l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l0,0
              l-2-0.8l0.7-1.6c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.7-1.9h-0.1l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9
              l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.5-1.3
              c0.7,0.3,1.6-0.1,1.8-0.9c0.3-0.7-0.1-1.5-0.8-1.8l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0.5-1.1
              c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0,0l0.7-1.6l2,0.8l0,0
              c-0.3,0.7,0.1,1.6,0.8,1.9s1.6-0.1,1.9-0.8l0,0l1.2,0.5l0,0c-0.3,0.7,0,1.6,0.8,1.9c0.7,0.3,1.6,0,1.9-0.8l0,0l1.2,0.5l0,0
              c-0.3,0.7,0.1,1.6,0.9,1.8c0.7,0.3,1.5-0.1,1.8-0.8l0,0l1.2,0.5l0,0c-0.3,0.7,0.1,1.6,0.9,1.8c0.7,0.3,1.5-0.1,1.8-0.8l0,0
              l1.3,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9s1.6-0.1,1.9-0.8l0,0l2,0.8l-0.7,1.6c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9h0.1l-0.5,1.1
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.8-0.2-1.5,0.3-1.7,1.1c-0.1,0.6,0.1,1.2,0.6,1.5l-0.5,1.1
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.3c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9h0.1l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7
              s0,1.6,0.7,1.9h0.1l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l0,0
              L79.4,201.8z"/>

            <rect x="60.5" y="172" transform="matrix(0.3748 -0.9271 0.9271 0.3748 -121.0044 182.6553)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>

          <rect x="-1.4" y="32" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -4.1133 60.6767)" fill="#236095" width="88.7" height="2.7"/>

          <rect x="83" y="61.3" transform="matrix(0.3746 -0.9272 0.9272 0.3746 21.5953 157.2169)" fill="#236095" width="88.7" height="2.7"/>

          <rect x="73.2" y="57.7" transform="matrix(0.3746 -0.9272 0.9272 0.3746 18.7661 145.9249)" fill="#236095" width="88.7" height="2.7"/>

          <rect x="63.4" y="53.1" transform="matrix(0.3746 -0.9272 0.9272 0.3746 16.8434 133.9617)" fill="#236095" width="88.7" height="2.7"/>
        </g>
        <g>

          <rect x="690.4" y="56.9" transform="matrix(0.8398 -0.5429 0.5429 0.8398 67.0944 442.2917)" fill="#A3DAF7" width="185.2" height="101.2"/>
          <path fill="#80CAF0" d="M833.2,14.7l-32.8,84.4c-4.7,12.1-18.7,21.6-31,20.9l-91.6-4.8
            L833.2,14.7z"/>
          <g>
            <path fill="#0B99D6" d="M770.1,107.6c7-4.5,16.4-2.5,20.9,4.5s2.5,16.4-4.5,20.9
              s-16.4,2.5-20.9-4.5c-2.2-3.4-2.9-7.5-2.1-11.4C764.4,113.2,766.8,109.8,770.1,107.6z M781,107.8c-6.9-1.5-13.7,2.9-15.2,9.7
              c-1.5,6.9,2.9,13.7,9.7,15.2c6.9,1.5,13.7-2.9,15.2-9.7c0.7-3.3,0.1-6.8-1.7-9.6C787.2,110.5,784.3,108.5,781,107.8L781,107.8z"
            />
            <path fill="#0B99D6" d="M776.6,114.3c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8
              c0,0-0.3-11.3,8.7-11.1c3,0.1,4.9,0.2,6.2,0.4c1.9,0.2,2.2,0.2,3-0.7c0.4-0.7,0.7-1.6,0.7-2.4c0.5-2.7,1.1-6.5,6.1-9.6
              c4.3-2.6,5.9-2,8-1.3c1.8,0.7,3.7,0.9,5.6,0.4c3.2-0.7,5-2.1,6-3.7c0.8-1.5,1.2-3.3,1.1-5c0-3.7,3.6-6,3.6-6
              c0.4-0.2,0.9-0.1,1.1,0.2c0.2,0.4,0.1,0.9-0.2,1.1c0,0-2.9,1.9-2.8,4.6c0.1,2-0.4,4.1-1.3,5.9c-1.2,2-3.3,3.7-7,4.5
              c-2.1,0.5-4.4,0.4-6.4-0.5c-1.6-0.6-3-1-6.6,1.2c-4.4,2.7-5,6.1-5.4,8.5c-0.1,1.2-0.5,2.3-1.2,3.3c-1.4,1.5-1.8,1.4-4.3,1.2
              c-1.2-0.1-3.1-0.3-6.1-0.4C776.3,104.6,776.6,114.2,776.6,114.3L776.6,114.3z M784.6,126.8c0,0-0.3-9.6,7-9.5
              c3,0,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,5-1.8,6.6-1.2
              c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.5-3.8,1.4-5.9c0-2.8,2.8-4.6,2.8-4.6c0.4-0.2,0.6-0.7,0.4-1.1
              c-0.2-0.4-0.7-0.6-1.1-0.4c-0.1,0-0.1,0.1-0.2,0.1c0,0-3.6,2.3-3.6,6c0.1,1.7-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7
              c-1.9,0.5-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.7,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7
              c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8C784.2,127.6,784.6,127.2,784.6,126.8L784.6,126.8z
               M782.6,123.6c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5
              c3.6-2.2,5-1.8,6.6-1.2c2,0.8,4.3,1,6.5,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.5-3.8,1.4-5.9c0-2.8,2.8-4.6,2.8-4.6
              c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6c0.1,1.7-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7
              c-1.9,0.4-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.7,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.8-3,0.7
              c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8C782.2,124.4,782.6,124.1,782.6,123.6L782.6,123.6z
               M780.6,120.5c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5
              c3.6-2.2,5-1.8,6.6-1.2c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.5-3.8,1.4-5.9c0-2.8,2.8-4.6,2.8-4.6
              c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2l0,0c0,0-3.6,2.3-3.6,6c0.1,1.7-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7
              c-1.9,0.5-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.7,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.8-3,0.7
              c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8C780.2,121.3,780.6,121,780.6,120.5L780.6,120.5
              L780.6,120.5z M778.6,117.4c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2c0.7-1,1.1-2.1,1.2-3.3
              c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,5-1.8,6.6-1.2c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.5-3.8,1.4-5.9
              c0-2.8,2.8-4.6,2.9-4.6c0.3-0.3,0.4-0.8,0.1-1.1c-0.2-0.3-0.7-0.4-1-0.2c0,0-3.6,2.3-3.6,6c0.1,1.8-0.3,3.5-1.1,5.1
              c-1,1.6-2.7,3.1-6,3.7c-1.9,0.5-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4
              c-0.8,0.9-1.2,0.8-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8l0,0
              C778.2,118.2,778.6,117.9,778.6,117.4L778.6,117.4L778.6,117.4z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M129.6,50.2l126.8,214.5l117.2-69.3L246.8-19.1L129.6,50.2z
             M140.9,53.3l103.2-61l118.2,199.9l-103.2,61.1L140.9,53.3z"/>

          <rect x="191.6" y="6.6" transform="matrix(0.8607 -0.509 0.509 0.8607 -27.4355 145.1398)" fill="#D3AC89" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="135.1,59.6 153,36.3 133.3,48 130.5,51.7     "/>
          <path fill="#0B99D6" d="M144.5,75.4l5.3-6.9l-4.7-7.9l-5.3,6.9L144.5,75.4z M178.6,31
            l13.9-18l-19.7,11.7l-13.9,18L178.6,31z"/>
          <path fill="#0B99D6" d="M153.8,91.2l5.3-6.9l-4.7-7.9l-5.3,6.9L153.8,91.2z M218.1,7.7
            l13.9-18L212.2,1.3l-13.9,18L218.1,7.7z"/>
          <path fill="#0B99D6" d="M163.2,107l5.3-6.9l-4.7-7.9l-5.3,6.9L163.2,107z M247.3-2.4
            l5.3-6.9l-4.6-7.9L237.8-4l6.3-3.7L247.3-2.4z"/>
          <path fill="#0B99D6" d="M172.5,122.8l5.3-6.9l-4.7-7.9l-5.3,6.9L172.5,122.8z
             M256.6,13.5l5.4-6.9l-4.7-7.9L252,5.6L256.6,13.5z"/>
          <path fill="#0B99D6" d="M181.9,138.6l5.3-6.9l-4.7-7.9l-5.3,6.9L181.9,138.6z M266,29.3
            l5.3-6.9l-4.7-7.9l-5.3,6.9L266,29.3z"/>
          <path fill="#0B99D6" d="M191.2,154.4l5.3-6.9l-4.7-7.9l-5.3,6.9L191.2,154.4z
             M275.3,45.1l5.3-6.9l-4.7-7.9l-5.3,6.9L275.3,45.1z"/>
          <path fill="#0B99D6" d="M200.6,170.2l5.3-6.9l-4.7-7.9l-5.3,6.9L200.6,170.2z
             M284.7,60.9L290,54l-4.7-7.9L280,53L284.7,60.9z"/>
          <path fill="#0B99D6" d="M209.9,186.1l5.3-6.9l-4.7-7.9l-5.3,6.9L209.9,186.1z M294,76.7
            l5.3-6.9l-4.7-7.9l-5.3,6.9L294,76.7z"/>
          <path fill="#0B99D6" d="M219.3,201.9l5.3-6.9l-4.7-7.9l-5.3,6.9L219.3,201.9L219.3,201.9
            z M303.4,92.5l5.3-6.9l-4.7-7.9l-5.3,6.9L303.4,92.5z"/>
          <path fill="#0B99D6" d="M228.6,217.7l5.3-6.9l-4.7-7.9l-5.3,6.9L228.6,217.7L228.6,217.7
            z M312.7,108.3l5.3-6.9l-4.7-7.9l-5.3,6.9L312.7,108.3z"/>
          <path fill="#0B99D6" d="M238,233.5l5.3-6.9l-4.7-7.9l-5.3,6.9L238,233.5z M322.1,124.1
            l5.3-6.9l-4.7-7.9l-5.3,6.9L322.1,124.1z"/>
          <path fill="#0B99D6" d="M247.3,249.3l5.3-6.9l-4.7-7.9l-5.3,6.9L247.3,249.3L247.3,249.3
            z M331.4,140l5.3-6.9l-4.7-7.9l-5.3,6.9L331.4,140z"/>
          <path fill="#0B99D6" d="M257.6,264l13.9-18l-12.3,7.3l-1.8-2.9l-5.3,6.9l4.4,7.4
            L257.6,264L257.6,264z M340.8,155.8l5.3-6.9l-4.7-7.9l-5.3,6.9L340.8,155.8z"/>
          <path fill="#0B99D6" d="M297,240.6l13.9-18l-19.7,11.6l-13.9,18L297,240.6z M350.1,171.6
            l5.3-6.9l-4.7-7.9l-5.3,6.9L350.1,171.6z"/>
          <path fill="#0B99D6" d="M336.5,217.3l13.9-18L330.6,211l-13.9,18L336.5,217.3
            L336.5,217.3z M359.5,187.4l5.3-6.9l-4.7-7.9l-5.3,6.9L359.5,187.4z"/>
          <polygon fill="#0B99D6" points="369.5,188.4 356.2,205.6 373.6,195.3     "/>
          <g>
            <path fill="#80CAF0" d="M236.7,177.1c4.4,7.5,14.1,10,21.5,5.6
              c7.4-4.4,10-14.1,5.6-21.5c-4.4-7.5-14.1-10-21.5-5.6c-3.6,2.1-6.2,5.6-7.2,9.6C233.9,169.3,234.6,173.6,236.7,177.1z
               M246.9,181.9c-7.1-1.8-11.3-9-9.5-16.1s9-11.3,16.1-9.5s11.3,9,9.5,16.1c-0.9,3.4-3.1,6.3-6.1,8.1S250.3,182.9,246.9,181.9
              L246.9,181.9z"/>
            <path fill="#80CAF0" d="M245.8,173.9c0.2-0.4,0-0.9-0.4-1.1c-0.4-0.2-0.8,0-1,0.3
              c0,0-5.6,10.3,2.9,14.4c2.8,1.3,4.7,2,5.9,2.5c1.8,0.7,2.1,0.8,2.5,2c0.1,0.9-0.1,1.8-0.4,2.6c-0.8,2.8-2,6.6,1.2,11.8
              c2.8,4.4,4.6,4.6,6.8,4.9c2,0.1,3.9,0.9,5.4,2.2c2.7,2.1,3.7,4.3,3.8,6.2c0,1.8-0.4,3.6-1.3,5.2c-1.7,3.4,0.5,7.2,0.6,7.2
              c0.2,0.4,0.7,0.5,1.1,0.3s0.5-0.7,0.3-1.1c0,0-1.8-3.1-0.5-5.6c1-1.9,1.5-4,1.5-6.1c-0.2-2.4-1.3-5-4.5-7.4
              c-1.7-1.5-3.9-2.4-6.2-2.5c-1.8-0.2-3.2-0.4-5.6-4.1c-2.8-4.5-1.8-7.9-1-10.4c0.5-1.1,0.6-2.4,0.5-3.6c-0.6-2-1-2.1-3.5-3.1
              c-1.2-0.5-3-1.1-5.8-2.5C241.1,182.7,245.8,174,245.8,173.9L245.8,173.9z M259,166.1c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5
              c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0.1,2.4-0.4,3.6c-0.8,2.4-1.8,5.9,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c2.3,0.2,4.5,1.1,6.2,2.5
              c3.1,2.4,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3l0,0
              c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9
              c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5c-8.4-4-2.9-14.3-2.9-14.3
              c0.2-0.4,0.7-0.6,1.1-0.4C259,165.2,259.1,165.7,259,166.1L259,166.1z M255.7,168.1c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5
              c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0.1,2.4-0.4,3.6c-0.8,2.5-1.8,5.9,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c2.3,0.2,4.5,1.1,6.2,2.5
              c3.1,2.5,4.3,5.1,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3
              c0,0-2.3-3.9-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9
              c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5c-8.4-4-2.9-14.3-2.9-14.4
              c0.2-0.4,0.8-0.5,1.1-0.3C255.7,167.2,255.8,167.7,255.7,168.1L255.7,168.1z M252.4,170.1c0,0-4.7,8.8,2.1,12.1
              c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0.1,2.4-0.4,3.6c-0.8,2.4-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1
              c2.3,0.2,4.5,1.1,6.2,2.6c3.1,2.4,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1
              c-0.4,0.2-0.9,0.1-1.1-0.3l0,0c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2
              c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.5-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-5.9-2.5
              c-8.4-4-2.9-14.3-2.9-14.4c0.2-0.4,0.7-0.6,1.1-0.4c0.4,0.2,0.6,0.7,0.4,1.1C252.4,170,252.3,170,252.4,170.1L252.4,170.1z
               M249,172.1c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0,2.4-0.5,3.6c-0.8,2.4-1.8,5.9,1,10.4
              c2.3,3.7,3.8,3.9,5.6,4.1c2.3,0.2,4.5,1.1,6.2,2.5c3.1,2.4,4.3,5.1,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6
              c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3l0,0c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2
              c-1.5-1.3-3.4-2.1-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.5-2.6c-0.4-1.2-0.7-1.3-2.5-2
              c-1.3-0.5-3.1-1.2-5.9-2.5c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.4C249,171.2,249.2,171.7,249,172.1
              C249,172,249,172,249,172.1L249,172.1z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M299.4,129.2l1.1,1.8l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.7,1.2l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0,0
              l0.6,1.1l0,0c-0.7,0.4-1,1.2-0.6,1.9s1.2,1,1.9,0.6l0.1-0.1l0,0l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0,0
              l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0,0l1.1,1.8l-1.5,0.9c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0
              l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.2,0.7c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5
              s-0.9,1.3-0.5,2l0,0l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.5,0.9
              l-1.1-1.8l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l0,0l-0.6-1.1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.6-1.1
              l0,0c0.7-0.4,1-1.2,0.6-1.9s-1.2-1-1.9-0.6l-0.1,0.1l0,0l-0.6-1.1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.7-1.2l0,0
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l0,0l-1.1-1.9l1.5-0.9c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5
              s0.9-1.3,0.5-2l1.2-0.7c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.2,1,1.9,0.6c0.7-0.4,1-1.2,0.6-1.9l-0.1-0.1L299.4,129.2z"/>

            <rect x="275.8" y="139.3" transform="matrix(0.8607 -0.509 0.509 0.8607 -35.0763 168.374)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M326.9,197.4l-2.2,1.3l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3l0,0l0,0l-1.5,0.9l0,0c-0.4-0.8-1.5-1.2-2.3-0.7
              c-0.8,0.4-1.2,1.5-0.7,2.3l0.1,0.1l0,0l-1.3,0.8l0,0c-0.5-0.8-1.6-1-2.4-0.5c-0.7,0.5-1,1.5-0.6,2.2l0,0l-1.3,0.8l0,0
              c-0.4-0.9-1.4-1.2-2.3-0.8s-1.2,1.4-0.8,2.3c0,0.1,0.1,0.2,0.2,0.3l0,0l-1.3,0.8l0,0c-0.4-0.8-1.5-1.2-2.3-0.7
              c-0.8,0.4-1.2,1.5-0.7,2.3l0.1,0.1l0,0l-2.2,1.3l-1.1-1.8c0.8-0.4,1.2-1.4,0.8-2.3c-0.4-0.8-1.4-1.2-2.3-0.8
              c-0.1,0-0.1,0.1-0.2,0.1l-0.8-1.3c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3c0.8-0.4,1.2-1.5,0.7-2.3
              s-1.5-1.2-2.3-0.7l-0.1,0.1l-0.8-1.3c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.9-1.5c0.8-0.5,1-1.6,0.5-2.4
              c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3c0.8-0.4,1.2-1.5,0.7-2.3s-1.5-1.2-2.3-0.7l-0.1,0.1l-0.8-1.3c0.8-0.4,1.2-1.5,0.7-2.3
              s-1.5-1.2-2.3-0.7l-0.1,0.1l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-1.1-1.8l2.2-1.3l0,0c0.5,0.8,1.6,1,2.4,0.5
              c0.7-0.5,1-1.5,0.6-2.2l0,0l1.3-0.8l0,0c0.4,0.8,1.5,1.2,2.3,0.7c0.8-0.4,1.2-1.5,0.7-2.3l-0.1-0.1l0,0l1.3-0.8l0,0
              c0.4,0.8,1.4,1.2,2.3,0.8s1.2-1.4,0.8-2.3c0-0.1-0.1-0.1-0.1-0.2l0,0l1.3-0.8l0,0c0.4,0.8,1.5,1.2,2.3,0.7
              c0.8-0.4,1.2-1.5,0.7-2.3l-0.1-0.1l0,0l1.5-0.9l0,0c0.4,0.8,1.4,1.2,2.3,0.8s1.2-1.4,0.8-2.3c0-0.1-0.1-0.1-0.1-0.2l0,0l2.2-1.3
              l1.1,1.8c-0.8,0.5-1.1,1.5-0.6,2.4s1.5,1.1,2.4,0.6l0.8,1.3c-0.8,0.4-1.2,1.5-0.7,2.3s1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3
              c-0.8,0.5-1,1.6-0.5,2.4c0.5,0.7,1.5,1,2.2,0.6l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.9,1.4
              c-0.8,0.4-1.2,1.5-0.7,2.3s1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3c-0.8,0.4-1.2,1.5-0.7,2.3s1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3
              c-0.8,0.5-1,1.6-0.5,2.4c0.5,0.7,1.5,1,2.2,0.6l0.8,1.3c-0.8,0.5-1,1.6-0.5,2.4c0.5,0.7,1.5,1,2.2,0.6L326.9,197.4z"/>

            <rect x="293.3" y="169.3" transform="matrix(0.8607 -0.509 0.509 0.8607 -52.5956 180.7314)" fill="#FBD4A4" width="21.5" height="34.4"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M342.8,173.8l-1.8,1.1l0,0c-0.4-0.7-1.2-1-1.9-0.6c-0.7,0.4-1,1.2-0.6,1.9l0.1,0.1l0,0l-1.2,0.7l0,0c-0.4-0.7-1.3-0.9-2-0.5
              s-0.9,1.3-0.5,2l0,0l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l0,0l-1.1,0.6l0,0c-0.4-0.7-1.2-1-1.9-0.6
              c-0.7,0.4-1,1.2-0.6,1.9l0.1,0.1l0,0l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l0,0l-1.8,1.1l-0.9-1.5
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,1-1.2,0.6-1.9
              s-1.2-1-1.9-0.6l-0.1,0.1l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.7-1.2c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5
              l-0.6-1.1c0.7-0.4,1-1.2,0.6-1.9s-1.2-1-1.9-0.6l-0.1,0.1L309,163c0.7-0.4,1-1.2,0.6-1.9s-1.2-1-1.9-0.6l-0.1,0.1l-0.6-1.1
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.9-1.5l1.8-1.1l0,0c0.5,0.6,1.4,0.7,2,0.2c0.5-0.4,0.6-1,0.4-1.6l0,0l1.1-0.6l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l0,0l1.1-0.6l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l0,0l1.1-0.6l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.2-0.7l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.8-1.1l0.9,1.5
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1c-0.7,0.4-1,1.2-0.6,1.9s1.2,1,1.9,0.6l0.1-0.1l0.6,1c-0.7,0.4-0.9,1.3-0.5,2
              s1.3,0.9,2,0.5l0,0l0.6,1.1c-0.6,0.5-0.8,1.4-0.3,2c0.4,0.5,1.1,0.7,1.7,0.5l0.7,1.2c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5
              l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5L342.8,173.8z"/>

            <rect x="314.6" y="150.2" transform="matrix(0.8607 -0.509 0.509 0.8607 -38.7698 187.6477)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="223.2,34.8 273.7,114.5 260.2,117.8 212.7,39.9     "/>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M600.2,162l175.6-58.6l-32-96L568.2,65.9L600.2,162L600.2,162z
               M604.3,154.3l-28.2-84.6l163.7-54.6L768,99.7L604.3,154.3z"/>

            <rect x="586.8" y="37.7" transform="matrix(0.9477 -0.3192 0.3192 0.9477 8.9203 219.2348)" fill="#D1CCE6" width="173" height="89.4"/>
            <g>
              <polygon fill="#0B99D6" points="607.9,159.4 593.8,142.8 599.2,158.9 601.5,161.5
                "/>
              <path fill="#0B99D6" d="M620.9,155.1l-4.2-5l-6.5,2.2l4.2,4.9L620.9,155.1L620.9,155.1
                z M594,123.3l-10.9-12.9l5.4,16.1l10.9,12.9L594,123.3z"/>
              <path fill="#0B99D6" d="M633.8,150.8l-4.2-5l-6.5,2.2l4.2,5L633.8,150.8L633.8,150.8z
                 M583.2,91l-10.9-12.9l5.4,16.1l10.9,12.9L583.2,91z"/>
              <path fill="#0B99D6" d="M646.8,146.4l-4.2-5l-6.5,2.2l4.2,5L646.8,146.4L646.8,146.4z
                 M580.5,68.2l-4.2-5l-6.5,2.2l8,9.4l-1.7-5.2L580.5,68.2z"/>
              <path fill="#0B99D6" d="M659.7,142.1l-4.2-5l-6.5,2.1l4.2,5L659.7,142.1L659.7,142.1z
                 M593.4,63.9l-4.2-5l-6.5,2.2l4.2,5L593.4,63.9z"/>
              <path fill="#0B99D6" d="M672.7,137.8l-4.2-4.9L662,135l4.2,5L672.7,137.8L672.7,137.8z
                 M606.4,59.6l-4.2-5l-6.5,2.2l4.2,5L606.4,59.6z"/>
              <path fill="#0B99D6" d="M685.6,133.5l-4.2-4.9l-6.4,2.1l4.2,5L685.6,133.5L685.6,133.5
                z M619.3,55.2l-4.2-5l-6.5,2.2l4.2,5L619.3,55.2z"/>
              <path fill="#0B99D6" d="M698.6,129.2l-4.2-5l-6.5,2.2l4.2,5L698.6,129.2L698.6,129.2z
                 M632.3,50.9l-4.2-5l-6.5,2.2l4.2,5L632.3,50.9z"/>
              <path fill="#0B99D6" d="M711.5,124.8l-4.2-5l-6.5,2.2l4.2,5L711.5,124.8L711.5,124.8z
                 M645.2,46.6l-4.2-5l-6.5,2.2l4.2,5L645.2,46.6z"/>
              <path fill="#0B99D6" d="M724.5,120.5l-4.2-5l-6.5,2.2l4.2,5L724.5,120.5z M658.2,42.3
                l-4.2-5l-6.5,2.2l4.2,5L658.2,42.3z"/>
              <path fill="#0B99D6" d="M737.4,116.2l-4.2-5l-6.5,2.2l4.2,5L737.4,116.2L737.4,116.2z
                 M671.1,38l-4.2-5l-6.5,2.2l4.2,5L671.1,38z"/>
              <path fill="#0B99D6" d="M750.3,111.9l-4.2-4.9l-6.5,2.2l4.2,5L750.3,111.9L750.3,111.9
                z M684.1,33.7l-4.2-5l-6.5,2.2l4.2,5L684.1,33.7z"/>
              <path fill="#0B99D6" d="M763.3,107.6l-4.2-5l-6.5,2.2l4.2,5L763.3,107.6L763.3,107.6z
                 M697,29.3l-4.2-5l-6.5,2.2l4.2,5L697,29.3z"/>
              <path fill="#0B99D6" d="M775.6,102.4l-10.9-12.9l3.4,10.1l-2.4,0.8l4.2,5l6.1-2
                L775.6,102.4L775.6,102.4z M710,25l-4.2-5l-6.5,2.2l4.2,5L710,25z"/>
              <path fill="#0B99D6" d="M764.8,70.1l-10.9-12.9l5.4,16.1l10.9,12.9L764.8,70.1z
                 M722.9,20.7l-4.2-5l-6.5,2.2l4.2,5L722.9,20.7z"/>
              <path fill="#0B99D6" d="M754,37.8l-10.9-12.9l5.4,16.1l10.9,12.9L754,37.8z
                 M735.9,16.4l-4.2-4.9l-6.5,2.2l4.2,5L735.9,16.4z"/>
              <polygon fill="#0B99D6" points="738.2,9.3 748.6,21.6 743.9,7.4         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M669.2,47.8l2-0.7l0,0c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8
              c0-0.1,0-0.1-0.1-0.2l0,0l1.3-0.5l0,0c0.2,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l0,0l0,0l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9
              s1.2-1.1,0.9-1.8l0,0l0,0L683,43l0,0c0.3,0.8,1.1,1.2,1.8,0.9c0.8-0.3,1.2-1.1,0.9-1.8l0,0l0,0l1.2-0.4l0,0
              c0.3,0.8,1.1,1.2,1.8,0.9c0.8-0.3,1.2-1.1,0.9-1.8l0,0l0,0l2-0.7l0.6,1.7c-0.8,0.2-1.2,1.1-0.9,1.8s1.1,1.2,1.8,0.9l0.4,1.2
              c-0.8,0.3-1.2,1.1-0.9,1.8s1.1,1.2,1.8,0.9l0.4,1.2c-0.8,0.3-1.2,1.1-0.9,1.8s1.1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1.1-0.9,1.8
              s1.1,1.2,1.8,0.9l0,0l0.5,1.3c-0.7,0.3-1.1,1.1-0.8,1.8s1,1.1,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1
              c0.1,0,0.1,0,0.2-0.1l0.4,1.2c-0.7,0.3-1.1,1.1-0.8,1.8c0.3,0.7,1,1.1,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1h0.1
              l0.5,1.7l-2,0.7l0,0c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8c0,0.1,0,0.1,0.1,0.2l0,0l-1.2,0.4l0,0c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8
              c0,0.1,0,0.1,0.1,0.2l0,0L693,77l0,0c-0.3-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.3-0.8-1.1-1.2-1.8-0.9
              s-1.2,1.1-0.9,1.8l0,0l0,0l-1.3,0.4l0,0c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8v0.1l0,0l-2,0.7l-0.6-1.7c0.8-0.2,1.2-1,1-1.8
              s-1-1.2-1.8-1c-0.1,0-0.1,0-0.2,0.1l-0.4-1.2c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l-0.4-1.2c0.7-0.3,1.1-1.1,0.8-1.8
              c-0.3-0.7-1-1.1-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1c-0.1,0-0.1,0-0.2,0.1l-0.5-1.3c0.8-0.2,1.2-1.1,0.9-1.8
              s-1.1-1.2-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1.1,0.9-1.8s-1.1-1.2-1.8-0.9l-0.4-1.2c0.8-0.3,1.2-1.1,0.9-1.8s-1.1-1.2-1.8-0.9
              l-0.4-1.2c0.8-0.3,1.2-1.1,0.9-1.8s-1.1-1.2-1.8-0.9L669.2,47.8z"/>

            <rect x="677" y="46.4" transform="matrix(0.9487 -0.3163 0.3163 0.9487 15.9721 220.1119)" fill="#EF805F" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="601.5,89.6 643.7,60.7 645.7,67.9 604.5,95.2     "/>
          <polygon fill="#189AD7" points="607.5,98.1 649.7,69.2 651.7,76.4 610.5,103.7     "/>
          <polygon fill="#189AD7" points="614.7,109 639.5,92 640.7,96.2 616.4,112.3     "/>
        </g>
        <g>

          <rect x="928.4" y="47.8" transform="matrix(0.8645 -0.5026 0.5026 0.8645 88.9608 525.999)" fill="#96CBCC" width="183.5" height="100.3"/>
          <path fill="#8BB3B2" d="M918.8,96.3l89.8,8.7c12.9,1.2,27.6-6.9,32.5-18.2L1078,3.1
            L918.8,96.3z"/>
          <g>
            <path fill="#0B99D6" d="M1029.7,81.9c-7.2,4.2-9.6,13.4-5.4,20.6s13.4,9.6,20.6,5.4
              s9.6-13.4,5.4-20.6c-2-3.4-5.3-6-9.2-7C1037.3,79.3,1033.2,79.9,1029.7,81.9z M1025.1,91.7c1.8-6.8,8.7-10.8,15.5-9
              s10.8,8.7,9,15.5s-8.7,10.8-15.5,9c-3.2-0.9-6-3-7.7-5.9C1024.7,98.4,1024.2,94.9,1025.1,91.7L1025.1,91.7z"/>
            <path fill="#0B99D6" d="M1032.8,90.6c0.4,0.2,0.9,0.1,1.1-0.3s0.1-0.9-0.3-1.1
              c0,0-9.9-5.4-13.8,2.7c-1.3,2.7-2,4.5-2.5,5.7c-0.7,1.7-0.8,2-1.9,2.4c-0.9,0.1-1.7-0.1-2.5-0.4c-2.7-0.8-6.3-2-11.3,1.1
              c-4.2,2.6-4.5,4.4-4.8,6.5c-0.1,1.9-0.9,3.7-2.1,5.2c-2,2.6-4.1,3.5-6,3.7c-1.7,0-3.5-0.4-5-1.3c-3.2-1.7-6.9,0.5-6.9,0.5
              c-0.4,0.2-0.5,0.7-0.3,1.1s0.7,0.5,1.1,0.3l0,0c0,0,2.9-1.7,5.4-0.5c1.8,1,3.8,1.5,5.8,1.4c2.3-0.2,4.8-1.2,7.2-4.2
              c1.4-1.7,2.3-3.7,2.5-5.9c0.2-1.7,0.4-3.1,4-5.3c4.4-2.7,7.6-1.7,10-1c1.1,0.5,2.3,0.6,3.4,0.4c1.9-0.6,2.1-1,3-3.3
              c0.5-1.2,1.1-2.9,2.4-5.6C1024.4,86,1032.8,90.5,1032.8,90.6L1032.8,90.6z M1040.3,103.4c0,0-8.4-4.6-11.6,2
              c-1.3,2.7-2,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.4c-2.3-0.7-5.6-1.7-10,1c-3.6,2.2-3.8,3.6-4,5.3
              c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.4-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3
              c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1.1,6-3.6c1.2-1.4,2-3.3,2.1-5.2
              c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.4-5.7
              c3.9-8.2,13.8-2.8,13.8-2.8c0.4,0.2,0.5,0.7,0.3,1.1C1041.1,103.5,1040.6,103.6,1040.3,103.4
              C1040.2,103.4,1040.2,103.4,1040.3,103.4L1040.3,103.4z M1038.4,100.2c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6
              c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.4c-2.3-0.7-5.6-1.8-10,1c-3.6,2.2-3.8,3.6-4,5.3c-0.2,2.2-1,4.3-2.5,5.9
              c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.5-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1
              c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1.1,6-3.6c1.2-1.4,2-3.3,2.1-5.2c0.3-2.1,0.5-3.9,4.8-6.5
              c5-3.1,8.6-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.4-5.7c3.9-8.1,13.8-2.7,13.8-2.7
              c0.4,0.2,0.5,0.7,0.3,1.1C1039.2,100.3,1038.7,100.5,1038.4,100.2L1038.4,100.2z M1036.5,97c0,0-8.4-4.6-11.6,2
              c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.4c-2.3-0.7-5.6-1.7-10,1c-3.6,2.2-3.8,3.6-4,5.3
              c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.4-5.4,0.4c-0.4,0.3-0.9,0.2-1.1-0.2
              c-0.3-0.4-0.2-0.9,0.2-1.1l0.1-0.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1,6-3.6c1.2-1.4,2-3.3,2.1-5.2
              c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.5-5.7
              c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1l0,0C1037.3,97.1,1036.8,97.2,1036.5,97L1036.5,97z M1034.7,93.8
              c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.4c-2.3-0.7-5.6-1.7-10,1
              c-3.6,2.2-3.8,3.6-4,5.3c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.4-5.4,0.5
              c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1.1,6-3.6
              c1.2-1.4,2-3.3,2.1-5.2c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4
              c0.5-1.2,1.1-3,2.5-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.2,1.1C1035.4,93.9,1035,94,1034.7,93.8L1034.7,93.8z"/>
          </g>
        </g>
        <g>

          <rect x="425.5" y="81.9" transform="matrix(0.9942 -0.108 0.108 0.9942 -13.2445 60.8386)" fill="#96CBCC" width="259.1" height="141.6"/>
          <path fill="#8BB3B2" d="M676.1,68.2L582,153.1c-13.5,12.2-36.9,15.2-52,6.6L418.6,96.2
            L676.1,68.2z"/>
          <g>
            <path fill="#0B99D6" d="M538.9,144.7c11.6-1.3,22.1,7.1,23.4,18.8
              c1.3,11.6-7.1,22.1-18.8,23.4c-11.6,1.3-22.1-7.1-23.4-18.8c-0.6-5.6,1-11.2,4.5-15.6C528.2,148.1,533.3,145.3,538.9,144.7z
               M552.4,151.9c-7.7-6.2-18.9-5-25.1,2.7s-5,18.9,2.7,25.1c7.7,6.2,18.9,5,25.1-2.7c3-3.7,4.3-8.4,3.8-13.1
              C558.4,159.2,556.1,154.9,552.4,151.9z"/>
            <path fill="#0B99D6" d="M542.7,157.1c-0.3,0.6-0.9,0.8-1.5,0.5l0,0
              c-0.6-0.3-0.8-0.9-0.5-1.5l0,0c0,0,6.7-14.3,17.8-8.5c3.7,2,6,3.4,7.5,4.4c2.2,1.4,2.6,1.6,4.2,1.1c0.8-0.3,1.5-1.3,2.5-2.6
              c2.3-3.1,5.5-7.5,13.7-8.2c7-0.6,8.7,1.2,10.8,3.4c1.8,2,4.1,3.4,6.7,4c4.5,1.2,7.6,0.5,9.8-0.9c2-1.4,3.6-3.4,4.6-5.6
              c2.2-4.6,8.2-5.2,8.2-5.2c0.6-0.1,1.2,0.3,1.3,0.9c0.1,0.6-0.3,1.2-0.9,1.3c-0.1,0-0.1,0-0.2,0c0,0-4.8,0.5-6.5,4
              c-1.2,2.6-3,4.9-5.4,6.5c-2.7,1.8-6.4,2.6-11.6,1.2c-3-0.7-5.7-2.3-7.8-4.6c-1.7-1.8-3-3.2-9-2.7c-7.2,0.6-10,4.5-12.1,7.3
              c-1.2,1.6-2.1,2.9-3.5,3.4c-2.6,1-3.2,0.6-6.2-1.3c-1.5-0.9-3.7-2.4-7.4-4.3C548.5,144.9,542.7,157.1,542.7,157.1L542.7,157.1z
               M545,177.8c0,0,5.7-12.2,14.8-7.4c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4
              c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5
              c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1-0.7,0.9-1.3c-0.1-0.5-0.6-0.9-1.1-0.9c0,0-6,0.7-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6
              c-2.2,1.4-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4c-2.1-2.2-3.8-4-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.4,2.6
              c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.5-0.1,1.2,0.4,1.5s1.2,0.1,1.5-0.4
              C545,178,545,177.9,545,177.8L545,177.8z M544.4,172.7c0,0,5.7-12.2,14.8-7.4c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.2,1.3
              c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2
              c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1-0.7,0.9-1.3c-0.1-0.5-0.6-0.9-1.1-0.9c0,0-6,0.7-8.2,5.2
              c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4c-2.1-2.2-3.8-3.9-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2
              c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5c-0.2,0.6,0.2,1.2,0.8,1.4
              C543.7,173.3,544.2,173.1,544.4,172.7L544.4,172.7z M543.9,167.5c0,0,5.7-12.2,14.7-7.4c3.7,1.9,5.9,3.4,7.4,4.3
              c3,1.9,3.5,2.2,6.2,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,4,7.8,4.6
              c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1-0.7,0.9-1.3s-0.6-0.9-1.2-0.9
              c0,0-6,0.7-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4c-2.1-2.2-3.8-4-10.8-3.4
              c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5
              c-0.3,0.5-0.1,1.2,0.4,1.5s1.2,0.1,1.5-0.4C543.8,167.6,543.9,167.6,543.9,167.5L543.9,167.5z M543.3,162.3
              c0,0,5.7-12.2,14.8-7.4c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.2,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3
              c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4
              c0.6-0.1,1-0.7,0.9-1.3s-0.6-0.9-1.2-0.9c0,0-6,0.7-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4
              c-2.1-2.2-3.8-3.9-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4
              c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.5-0.2,1.2,0.3,1.6c0.5,0.3,1.2,0.2,1.6-0.3C543.2,162.6,543.3,162.4,543.3,162.3
              L543.3,162.3z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M509.8,103l-136.2,0.3l0.5,249.2l136.2-0.3L509.8,103z
             M502.1,343.7l-119.9,0.2l-0.4-232.2l120-0.2L502.1,343.7z"/>

          <rect x="382" y="111.6" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.4363 0.849)" fill="#E0DCC1" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="509.8,113.9 482.6,103 505.5,103 509.8,104.7     "/>
          <path fill="#0B99D6" d="M509.9,132.2V123l-8.1-3.2v9.2L509.9,132.2z M480.8,111.5
            l-21.1-8.4h-22.9l21.1,8.4H480.8z"/>
          <path fill="#0B99D6" d="M509.9,150.6v-9.2l-8.1-3.2v9.2L509.9,150.6z M434.9,111.6
            l-21.1-8.4h-22.9l21.1,8.4H434.9z"/>
          <path fill="#0B99D6" d="M509.9,169v-9.2l-8.1-3.2v9.2L509.9,169z M381.8,111.7h7.3
            l-15.4-6.2v9.2l8.1,3.2V111.7z"/>
          <path fill="#0B99D6" d="M510,187.4v-9.2l-8.2-3.2v9.2L510,187.4L510,187.4z M381.8,127.1
            l-8.1-3.2v9.2l8.1,3.2V127.1z"/>
          <path fill="#0B99D6" d="M510,205.7v-9.2l-8.1-3.2v9.2L510,205.7z M381.8,145.5l-8.1-3.2
            v9.2l8.1,3.2V145.5z"/>
          <path fill="#0B99D6" d="M510,224.1v-9.2l-8.1-3.2v9.2L510,224.1L510,224.1z M381.9,163.9
            l-8.1-3.2v9.2l8.1,3.2V163.9z"/>
          <path fill="#0B99D6" d="M510.1,242.5v-9.2l-8.1-3.2v9.2L510.1,242.5z M381.9,182.2
            l-8.1-3.2v9.2l8.1,3.2V182.2z"/>
          <path fill="#0B99D6" d="M510.1,260.8v-9.2l-8.1-3.2v9.2L510.1,260.8L510.1,260.8z
             M381.9,200.6l-8.1-3.2v9.2l8.1,3.2V200.6z"/>
          <path fill="#0B99D6" d="M510.1,279.2V270l-8.1-3.2v9.2L510.1,279.2L510.1,279.2z
             M382,219l-8.1-3.2v9.2l8.1,3.2V219z"/>
          <path fill="#0B99D6" d="M510.2,297.6v-9.2l-8.2-3.2v9.2L510.2,297.6L510.2,297.6z
             M382,237.3l-8.1-3.2v9.2l8.1,3.2V237.3z"/>
          <path fill="#0B99D6" d="M510.2,315.9v-9.2l-8.1-3.2v9.2L510.2,315.9z M382,255.7
            l-8.1-3.2v9.2l8.1,3.2V255.7z"/>
          <path fill="#0B99D6" d="M510.2,334.3v-9.2l-8.1-3.2v9.2L510.2,334.3z M382.1,274.1
            l-8.1-3.2v9.1l8.1,3.2V274.1z"/>
          <path fill="#0B99D6" d="M509,352.1h1.3v-8.6l-8.1-3.2v3.4h-14.3L509,352.1z M382.1,292.5
            l-8.1-3.2v9.2l8.1,3.2V292.5z"/>
          <path fill="#0B99D6" d="M463.1,352.2H486l-21.1-8.4H442L463.1,352.2L463.1,352.2z
             M382.1,310.8l-8.1-3.2v9.2l8.1,3.2V310.8z"/>
          <g>
            <path fill="#80CAF0" d="M482.5,266.8c0-8.7-7.1-15.7-15.8-15.7
              c-8.7,0-15.7,7.1-15.7,15.8s7.1,15.7,15.8,15.7c4.2,0,8.2-1.7,11.1-4.7C480.9,275,482.6,271,482.5,266.8L482.5,266.8z
               M466.9,280.1c-7.3,0-13.2-5.9-13.2-13.2c0-7.3,5.9-13.2,13.2-13.2c7.3,0,13.2,5.9,13.2,13.2c0,3.5-1.4,6.9-3.8,9.3
              C473.7,278.7,470.4,280.1,466.9,280.1L466.9,280.1z"/>
            <path fill="#80CAF0" d="M473.1,268.7c0,0,8.6,5.2,4.3,11.5c-1.7,2.6-2.9,4.1-3.8,5.1
              c-1.6,2.1-1.9,2.4-1.4,4.4c0.4,1.1,1.2,2.1,2.2,2.8c1.9,1.7,4.5,4.1,4.4,9.5c-0.1,4.4-1.2,5.3-2.7,6.4c-1.9,1.3-3.3,3.2-4,5.4
              c-1.5,3.7-1.1,6.5,0,8.7c1,1.9,2.5,3.4,4.4,4.5c2.4,1.5,2.4,5.1,2.4,5.1c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
              c0,0,0-4.5-3.2-6.5c-1.6-0.9-2.9-2.2-3.8-3.8c-0.9-1.8-1.1-4.1,0.1-7.3c0.7-1.9,1.9-3.5,3.5-4.6c1.8-1.4,3.2-2.5,3.3-7.7
              c0.1-6.1-2.8-8.8-5-10.7c-0.7-0.5-1.3-1.2-1.7-2c-0.3-1.2-0.1-1.5,1.1-3c0.9-1,2.1-2.6,3.8-5.2c5.2-7.8-4.8-13.8-4.8-13.8
              c-0.4-0.2-0.9-0.1-1.1,0.3C472.6,267.9,472.7,268.4,473.1,268.7L473.1,268.7z M457.7,268.7c-0.4-0.2-0.5-0.7-0.3-1.1
              s0.7-0.5,1.1-0.3c0,0,10,6.1,4.8,13.8c-1.7,2.6-3,4.2-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2
              c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.6c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8
              c3.2,2,3.2,6.5,3.2,6.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-3.6-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5
              c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4c0.1-5.4-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8
              c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.8-5.1C466.1,273.8,457.6,268.7,457.7,268.7L457.7,268.7z M461.5,268.7
              c-0.4-0.2-0.5-0.8-0.3-1.1c0.2-0.4,0.8-0.5,1.1-0.3c0,0,10,6.1,4.8,13.8c-1.8,2.6-3,4.2-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3
              c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.6c-1.2,3.2-1,5.6-0.1,7.3
              c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8l0,0c0,0,0-3.5-2.4-5.1
              c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4c0.1-5.4-2.5-7.7-4.4-9.5
              c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.8-5.1C470,273.9,461.5,268.7,461.5,268.7L461.5,268.7z
               M465.4,268.7c-0.4-0.2-0.5-0.8-0.3-1.1c0.2-0.4,0.8-0.5,1.1-0.3c0,0,10,6,4.8,13.8c-1.7,2.6-3,4.2-3.8,5.2
              c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.6
              c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5c-0.1,0.5-0.5,0.8-0.9,0.7c-0.4,0-0.7-0.4-0.7-0.7
              c0,0,0-3.5-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4
              c0.1-5.4-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.7-5.1
              C473.9,273.8,465.3,268.7,465.4,268.7L465.4,268.7z M469,267.5c0.2-0.4,0.7-0.5,1.1-0.3l0,0c0,0,10,6,4.8,13.8
              c-1.7,2.6-3,4.1-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7
              c-1.6,1.1-2.9,2.8-3.5,4.6c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5c0,0.5-0.4,0.8-0.8,0.8
              c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-3.6-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.4
              c1.4-1.1,2.6-2,2.7-6.4c0.1-5.4-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.8-5.1
              c4.2-6.3-4.3-11.5-4.3-11.5C468.9,268.4,468.8,267.9,469,267.5L469,267.5z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M404.2,257.6v2.1l0,0c0.8,0.1,1.4,0.8,1.3,1.6c-0.1,0.7-0.6,1.3-1.3,1.3l0,0v1.4l0,0c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0
              v1.2l0,0c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0l0,0v1.2l0,0c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0v1.2l0,0
              c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0v2.1h1.8c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4
              s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4h1.4
              c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3h1.2c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3h1.2
              c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4h1.8v-2.2l0,0c-0.8,0-1.4-0.6-1.4-1.4l0,0
              c0-0.8,0.6-1.4,1.4-1.4l0,0V275l0,0c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0l0,0V271l0,0c-0.8,0-1.4-0.6-1.4-1.4
              s0.6-1.4,1.4-1.4l0,0l0,0V267l0,0c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0v-1.4l0,0c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
              l0,0v-2.1h-1.8c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4h-1.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.2
              c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4h-1.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4H421c0,0.8-0.6,1.4-1.4,1.4
              s-1.4-0.6-1.4-1.4l0,0H417c0.1,0.8-0.5,1.5-1.3,1.6s-1.5-0.5-1.6-1.3c0-0.1,0-0.2,0-0.2h-1.2c-0.1,0.8-0.8,1.4-1.6,1.3
              c-0.7-0.1-1.3-0.6-1.3-1.3h-1.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4L404.2,257.6z"/>

            <rect x="407.4" y="260.4" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.5164 0.8104)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M415.3,330.3h2.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h1.7l0,0c0.1-0.9,0.9-1.6,1.8-1.6c0.8,0.1,1.5,0.7,1.6,1.6
              l0,0h1.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h1.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h1.5l0,0
              c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h2.6v-2.1c-0.9,0.1-1.8-0.6-1.8-1.6c-0.1-0.9,0.6-1.8,1.6-1.8
              c0.1,0,0.2,0,0.3,0v-1.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7v-1.5c-0.9,0.1-1.8-0.6-1.8-1.6c-0.1-0.9,0.6-1.8,1.6-1.8
              c0.1,0,0.2,0,0.3,0v-1.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7v-1.7c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7v-1.5
              c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7v-1.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7v-1.5c-0.9,0-1.7-0.8-1.7-1.7
              c0-0.9,0.8-1.7,1.7-1.7v-2.1H441l0,0c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7l0,0h-1.5l0,0c0.1,0.9-0.6,1.8-1.6,1.8
              c-0.9,0.1-1.8-0.6-1.8-1.6c0-0.1,0-0.2,0-0.3l0,0h-1.5l0,0c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7l0,0h-1.5l0,0
              c0.1,0.9-0.6,1.8-1.6,1.8c-0.9,0.1-1.8-0.6-1.8-1.6c0-0.1,0-0.2,0-0.3l0,0h-1.7l0,0c0,0.9-0.8,1.7-1.7,1.7
              c-0.9,0-1.7-0.8-1.7-1.7l0,0h-2.6v2.1c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7v1.5c0.9,0,1.7,0.8,1.7,1.7
              c0,0.9-0.8,1.7-1.7,1.7v1.5c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7v1.5c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7v1.7
              c0.9-0.1,1.8,0.6,1.8,1.6c0.1,0.9-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7v1.5
              c0.9-0.1,1.8,0.6,1.8,1.6c0.1,0.9-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7
              L415.3,330.3z"/>

            <rect x="418.6" y="292" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.5929 0.8249)" fill="#FBD4A4" width="21.5" height="34.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M389.6,318.1h2.1l0,0c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.4l0,0c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2l0,0
              c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3l0,0h1.2l0,0c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3l0,0h1.2l0,0
              c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3l0,0h2.1v-1.8c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0v-1.2
              c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0v-1.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0V304c-0.8,0-1.4-0.6-1.4-1.4
              s0.6-1.4,1.4-1.4l0,0v-1.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0v-1.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4v-1.2
              c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4v-1.2c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4l0,0v-1.8H411l0,0c0,0.8-0.6,1.4-1.4,1.4
              s-1.4-0.6-1.4-1.4l0,0H407l0,0c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0l0,0H403l0,0c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4
              l0,0l0,0H399l0,0c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0l0,0h-1.4l0,0c0,0.8-0.6,1.4-1.4,1.4l0,0c-0.8,0-1.4-0.6-1.4-1.4
              l0,0h-2.2v1.8c0.8,0,1.4,0.6,1.4,1.4l0,0c0,0.8-0.6,1.4-1.4,1.4l0,0v1.2c0.8,0,1.4,0.6,1.4,1.4l0,0c0,0.8-0.6,1.4-1.4,1.4v1.2
              c0.8,0,1.4,0.6,1.4,1.4l0,0c0,0.8-0.6,1.4-1.4,1.4l0,0v1.4c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0v1.4
              c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4l0,0v1.2c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4v1.2c0.8,0,1.4,0.6,1.4,1.4
              s-0.6,1.4-1.4,1.4v1.2c0.8-0.1,1.5,0.5,1.6,1.3s-0.5,1.5-1.3,1.6c-0.1,0-0.2,0-0.2,0L389.6,318.1z"/>

            <rect x="392.4" y="286.1" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.5763 0.7712)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="484.8" y="131.1" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.3359 0.9337)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="395.6" y="126.8" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.3279 0.7624)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="406" y="127.1" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.3284 0.7824)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="416.8" y="126.5" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.3272 0.8031)" fill="#236095" width="2.7" height="88.7"/>
        </g>
        <g>
          <polygon fill="#FFFFFF" points="41.1,277.2 161.9,92.8 26.1,41.8 -94.7,226.1     "/>
          <path fill="#E6E6E5" d="M161.9,92.8L42.8,139.1c-17.1,6.7-28.5,23.3-25.4,36.9L41,277.2
            L161.9,92.8z"/>
          <g>
            <path fill="#0B99D6" d="M35.8,173.7c-2.7,4.2-7.5,7.1-12.9,8.4c-5.7,1.4-11.7,1-17.3-1
              C0,179-4,175.4-5.9,171.3c-2-4.2-1.6-9.1,1-12.9c2.7-4.2,7.5-7.1,12.9-8.4c5.7-1.4,11.7-1,17.3,1c5.6,2.1,9.6,5.7,11.5,9.8
              C38.7,165,38.4,169.9,35.8,173.7L35.8,173.7z M23.8,153.4c-4.6-1.7-9.7-2-14.5-0.8c-4.6,1.1-8.6,3.6-10.9,7.1
              c-2.2,3.2-2.5,7.3-0.9,10.8c1.6,3.4,4.9,6.5,9.6,8.2c4.6,1.7,9.7,2,14.5,0.8c4.6-1.1,8.6-3.6,10.9-7.1c2.2-3.2,2.5-7.3,0.9-10.8
              C31.8,158.2,28.5,155.1,23.8,153.4z"/>
            <path fill="#0B99D6" d="M24.7,167.3c0,0,14.3-0.8,12.8-8.9c-0.4-2.3-1-4.5-1.7-6.7
              c-0.8-2.8-1-3.3,0.9-5c1-0.9,2.7-1.2,4.6-1.7c3.5-0.7,8.4-1.8,11.6-7c2.6-4.3,1.7-5.7,0.5-7.4c-1.6-2-2.2-4.6-1.9-7.1
              c0.3-3.5,2.3-6.6,5.4-8.2c2.6-1.3,5.5-2.1,8.4-2.2c2.5-0.3,4.9-1.6,6.3-3.7c0.4-0.5,1-0.6,1.6-0.4s0.8,0.8,0.6,1.2
              c-1.9,2.7-4.9,4.4-8.2,4.7c-2.5,0.1-5,0.7-7.3,1.8c-2.6,1.4-4.3,4.1-4.4,7.1c-0.3,2.2,0.3,4.4,1.6,6.2c1.4,2.2,2.6,3.9-0.5,9
              c-3.6,5.9-9.1,7.1-13.1,7.9c-1.2,0.1-2.4,0.5-3.5,1.1c-1.2,1-1.1,1.4-0.4,3.4c0.7,2.2,1.3,4.5,1.7,6.9
              c1.9,10-14.9,10.9-14.9,10.9c-0.6,0.1-1.1-0.3-1.3-0.8C23.5,167.8,24,167.4,24.7,167.3L24.7,167.3z M4.7,159.8
              c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4
              c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,2-6.8,0.5-9c-1.4-1.7-1.9-4-1.6-6.2c0.2-3,1.8-5.6,4.5-7.1
              c2.3-1.1,4.7-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.5-1.2c-0.6-0.2-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7
              c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.2,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.4c-3.2,5.2-8.1,6.2-11.6,7
              c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7C19,159,4.8,159.8,4.7,159.8L4.7,159.8z M9.7,161.7
              c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4
              c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,2-6.8,0.5-9c-1.4-1.7-1.9-4-1.6-6.2c0.2-3,1.8-5.6,4.5-7.1
              c2.3-1.1,4.8-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.6-1.2s-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7
              c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.4,8.4c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.4
              c-3.2,5.2-8.1,6.2-11.6,6.9c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7C24,161,9.7,161.8,9.7,161.7
              L9.7,161.7z M14.7,163.6c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9
              c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5.1,2-6.8,0.5-9c-1.4-1.8-1.9-4-1.6-6.2
              c0.2-2.9,1.9-5.6,4.4-7c2.3-1.1,4.8-1.8,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.5-1.2c-0.6-0.2-1.2-0.1-1.6,0.4
              c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.4
              c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7
              C29,162.9,14.7,163.7,14.7,163.6L14.7,163.6z M18.6,166.4c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9
              c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-0.9,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,2-6.8,0.5-9c-1.3-1.8-1.9-4-1.6-6.2
              c0.2-3,1.8-5.7,4.5-7.1c2.3-1.1,4.8-1.8,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.6-1.2s-1.2-0.1-1.6,0.4
              c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.2,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.4
              c-3.2,5.2-8.1,6.2-11.6,6.9c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7c1.5,8.1-12.7,8.9-12.8,8.9
              C19,165.5,18.5,165.9,18.6,166.4L18.6,166.4z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M685-111.3L477.5-25.6l-0.7,130.7l207.5-85.7L685-111.3z
               M677.9-100.6l-0.6,115.1L483.9,94.4l0.6-115.1L677.9-100.6z"/>
            <polygon fill="#D1CCE6" points="677.9,-100.6 484.5,-20.7 483.9,94.4 677.3,14.5       "/>
            <g>
              <path fill="#0B99D6" d="M645.3-94.9l2.7,6.7l7.6-3.2l-2.6-6.7L645.3-94.9L645.3-94.9z
                 M677.5-14.5l7,17.4l0.1-22l-7-17.3L677.5-14.5z"/>
              <path fill="#0B99D6" d="M630-88.6l2.7,6.7l7.6-3.2l-2.7-6.7L630-88.6z M672.1,16.6
                l2.7,6.7l7.6-3.1l-5.1-12.7v7L672.1,16.6z"/>
              <path fill="#0B99D6" d="M614.7-82.3l2.7,6.7l7.6-3.2l-2.7-6.7L614.7-82.3L614.7-82.3z
                 M656.8,23l2.7,6.7l7.6-3.1l-2.7-6.7L656.8,23z"/>
              <path fill="#0B99D6" d="M599.4-76l2.7,6.7l7.6-3.2l-2.7-6.7L599.4-76z M641.5,29.3
                l2.7,6.7l7.6-3.2l-2.7-6.7L641.5,29.3z"/>
              <path fill="#0B99D6" d="M584.1-69.6l2.7,6.6l7.6-3.2l-2.7-6.7L584.1-69.6L584.1-69.6z
                 M626.2,35.6l2.7,6.7l7.6-3.2l-2.7-6.7L626.2,35.6z"/>
              <path fill="#0B99D6" d="M568.8-63.3l2.7,6.7l7.6-3.2l-2.7-6.7L568.8-63.3z M610.9,41.9
                l2.7,6.7l7.6-3.2l-2.7-6.7L610.9,41.9z"/>
              <path fill="#0B99D6" d="M553.5-57l2.7,6.7l7.6-3.2l-2.7-6.7L553.5-57z M595.6,48.3
                l2.7,6.7l7.7-3.1l-2.7-6.7L595.6,48.3z"/>
              <path fill="#0B99D6" d="M538.2-50.7l2.7,6.7l7.6-3.2l-2.7-6.7L538.2-50.7L538.2-50.7z
                 M580.3,54.6l2.7,6.7l7.6-3.2l-2.6-6.7L580.3,54.6z"/>
              <path fill="#0B99D6" d="M522.9-44.3l2.7,6.7l7.6-3.2l-2.7-6.7
                C530.6-47.5,522.9-44.4,522.9-44.3z M565,60.9l2.7,6.7l7.6-3.2l-2.7-6.7L565,60.9z"/>
              <path fill="#0B99D6" d="M507.6-38l2.7,6.7l7.6-3.2l-2.7-6.7L507.6-38z M549.7,67.2
                l2.7,6.7l7.6-3.2l-2.7-6.7L549.7,67.2z"/>
              <path fill="#0B99D6" d="M492.3-31.7L495-25l7.6-3.2l-2.6-6.7L492.3-31.7L492.3-31.7z
                 M534.4,73.5l2.7,6.7l7.6-3.2l-2.7-6.7L534.4,73.5z"/>
              <path fill="#0B99D6" d="M477.5-24.3l6.9,17.4l0.1-13.8l2.9-1.2l-2.7-6.7l-7.2,3
                L477.5-24.3L477.5-24.3z M519.1,79.9l2.7,6.7l7.6-3.2l-2.7-6.7L519.1,79.9z"/>
              <path fill="#0B99D6" d="M477.2,19.7l7,17.4l0.1-22l-7-17.4L477.2,19.7L477.2,19.7z
                 M503.8,86.2l2.7,6.7l7.6-3.2l-2.7-6.7L503.8,86.2z"/>
              <path fill="#0B99D6" d="M477,63.7l7,17.4l0.1-22l-7-17.4L477,63.7L477,63.7z
                 M488.5,92.5l2.7,6.7l7.6-3.2l-2.7-6.7L488.5,92.5z"/>
              <polygon fill="#0B99D6" points="483.5,102.3 476.9,85.7 476.8,105.1         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M548.4-28.5c-3.8,1.8-7.1,4.6-9.3,8.2
              c-2.4,3.6-3.8,7.9-3.9,12.3c0,4.2,1.4,7.3,3.8,9.1s5.6,2.1,9.2,0.6c3.8-1.8,7.1-4.6,9.3-8.2c2.4-3.6,3.7-7.9,3.9-12.2
              c0-4.2-1.4-7.3-3.8-9.1S552.1-30,548.4-28.5L548.4-28.5z M540.6-19.2c1.8-3,4.6-5.4,7.8-6.9c3-1.3,5.8-1,7.8,0.5s3.2,4.1,3.2,7.6
              c-0.1,3.7-1.2,7.2-3.3,10.3c-1.8,3-4.6,5.5-7.8,6.9c-3,1.2-5.8,1-7.8-0.5s-3.2-4.1-3.2-7.6C537.5-12.6,538.6-16.1,540.6-19.2z"/>
            <path fill="#80CAF0" d="M546.9-18.8c0.2,0.3,0.6,0.2,1-0.1c0.3-0.3,0.4-0.8,0.2-1.2
              c0,0-5-7.5-11.5,0.1c-2.2,2.6-3.5,4.3-4.3,5.5c-1.3,1.7-1.5,2-2.5,2.1c-0.5,0.1-1-0.4-1.7-0.9c-1.6-1.4-3.8-3.3-8.9-1
              c-4.3,1.9-5.3,3.7-6.4,5.8c-0.9,1.9-2.2,3.6-3.9,5c-2.7,2.2-4.7,2.8-6.1,2.6c-1.3-0.3-2.5-1.1-3.2-2.3c-1.7-2.4-5.4-0.8-5.4-0.8
              c-0.4,0.2-0.7,0.6-0.7,1.1c0,0.4,0.3,0.7,0.7,0.5c0,0,3-1.2,4.2,0.6c0.8,1.4,2.2,2.3,3.7,2.6c1.8,0.3,4.1-0.4,7.2-3
              c1.9-1.6,3.4-3.5,4.5-5.7c0.9-1.8,1.7-3.2,5.3-4.8c4.5-2,6.4-0.3,7.9,1c0.8,0.7,1.5,1.2,2.3,1.1c1.7-0.2,2-0.6,3.7-2.9
              c0.9-1.1,2.1-2.8,4.3-5.4C542.6-25.1,546.9-18.7,546.9-18.8L546.9-18.8z M546.8-4c0,0-4.3-6.4-9.5-0.2c-2.1,2.5-3.4,4.2-4.2,5.4
              c-1.7,2.3-2,2.7-3.7,2.9c-0.9,0.1-1.5-0.4-2.4-1.1c-1.4-1.2-3.4-2.9-7.9-1c-3.7,1.6-4.4,3-5.3,4.8c-1.1,2.2-2.6,4.2-4.5,5.7
              c-3.1,2.7-5.5,3.3-7.2,3c-1.5-0.3-2.9-1.3-3.7-2.6c-1.3-1.8-4.2-0.6-4.2-0.6c-0.4,0.2-0.7-0.1-0.7-0.5c0-0.5,0.3-0.9,0.7-1.1
              c0,0,3.7-1.5,5.4,0.8c0.7,1.2,1.8,2,3.2,2.3c1.5,0.2,3.4-0.3,6.1-2.6c1.6-1.4,3-3.1,3.9-5c1.1-2.2,2.1-3.9,6.4-5.8
              c5.1-2.2,7.3-0.3,8.9,1c0.7,0.6,1.2,1,1.7,1c1-0.1,1.2-0.4,2.5-2.1c0.9-1.2,2.2-2.9,4.3-5.5c6.5-7.6,11.5,0,11.5,0
              c0.2,0.4,0.1,0.9-0.2,1.2C547.4-3.8,547-3.7,546.8-4L546.8-4z M546.8-7.7c0,0-4.3-6.4-9.5-0.2c-2.1,2.5-3.4,4.2-4.2,5.4
              c-1.7,2.3-2,2.7-3.7,2.9c-0.9,0.1-1.5-0.4-2.4-1.1c-1.4-1.2-3.4-2.9-7.9-1c-3.7,1.6-4.4,3-5.3,4.8c-1.1,2.2-2.6,4.2-4.5,5.7
              c-3.1,2.7-5.4,3.3-7.2,3c-1.5-0.3-2.9-1.3-3.7-2.6c-1.3-1.8-4.2-0.6-4.2-0.6c-0.4,0.2-0.7-0.1-0.7-0.5s0.3-0.8,0.7-1
              c0,0,3.7-1.5,5.4,0.8c0.7,1.2,1.8,2,3.2,2.3c1.5,0.2,3.4-0.3,6.1-2.6c1.6-1.4,3-3.1,3.9-5c1.1-2.2,2.1-3.9,6.4-5.8
              c5.1-2.2,7.3-0.3,8.9,1c0.7,0.6,1.2,1,1.7,1c1-0.1,1.2-0.4,2.5-2.1c0.9-1.2,2.2-2.9,4.4-5.5C543-16.5,548-9,548-9
              c0.2,0.4,0.1,0.9-0.2,1.2C547.4-7.4,547-7.4,546.8-7.7L546.8-7.7z M546.8-11.4c0,0-4.3-6.4-9.5-0.2c-2.1,2.6-3.4,4.2-4.3,5.4
              c-1.7,2.3-2,2.7-3.7,2.9c-0.9,0.1-1.6-0.5-2.4-1.1c-1.4-1.2-3.4-2.9-7.9-1c-3.7,1.6-4.4,3-5.3,4.8c-1.1,2.2-2.6,4.2-4.5,5.7
              c-3.1,2.7-5.4,3.3-7.2,3c-1.5-0.3-2.9-1.3-3.7-2.7c-1.3-1.8-4.2-0.6-4.2-0.6c-0.4,0.2-0.7-0.1-0.7-0.5c0-0.5,0.3-0.9,0.7-1.1
              c0,0,3.7-1.5,5.4,0.8c0.7,1.2,1.8,2,3.2,2.3c1.5,0.2,3.4-0.3,6.1-2.6c1.6-1.4,3-3.1,3.9-5c1.1-2.2,2.1-3.9,6.4-5.8
              c5.1-2.2,7.3-0.3,8.9,1c0.7,0.6,1.2,1,1.7,1c1-0.1,1.2-0.4,2.5-2.1c0.9-1.2,2.2-2.9,4.3-5.5c6.5-7.6,11.5-0.1,11.5,0
              c0.2,0.4,0.1,0.9-0.2,1.2C547.4-11.2,547-11.1,546.8-11.4L546.8-11.4z M546.8-15.1c0,0-4.3-6.4-9.5-0.2c-2.1,2.5-3.4,4.2-4.3,5.4
              c-1.7,2.3-2,2.7-3.7,2.9c-0.9,0.1-1.6-0.4-2.4-1.1c-1.4-1.2-3.4-2.9-7.9-1c-3.7,1.6-4.4,3-5.3,4.8c-1.1,2.2-2.6,4.2-4.5,5.7
              c-3.1,2.7-5.4,3.3-7.2,3c-1.5-0.3-2.9-1.3-3.7-2.6c-1.3-1.8-4.2-0.6-4.2-0.6c-0.4,0.2-0.7-0.1-0.7-0.5c0-0.5,0.3-0.9,0.7-1.1
              c0,0,3.7-1.5,5.4,0.8c0.7,1.2,1.8,2,3.2,2.3c1.5,0.2,3.4-0.3,6.1-2.6c1.6-1.4,3-3.1,3.9-5c1.1-2.2,2.1-3.9,6.4-5.8
              c5.1-2.2,7.3-0.3,8.9,1c0.7,0.6,1.2,1,1.7,1c1-0.1,1.2-0.4,2.5-2.1c0.9-1.2,2.2-2.9,4.3-5.5C543-24,548-16.4,548-16.4
              c0.2,0.4,0.1,0.9-0.2,1.2S547-14.8,546.8-15.1L546.8-15.1z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M570.7,47.4l-2.4,1l0,0c0-1-0.7-1.5-1.6-1.2
              c-1,0.5-1.6,1.4-1.6,2.5l0,0l-1.6,0.7l0,0c0-1-0.7-1.5-1.6-1.2c-1,0.5-1.6,1.4-1.6,2.5l0,0l-1.4,0.6l0,0c0-1-0.7-1.6-1.6-1.2
              c-1,0.5-1.6,1.4-1.6,2.5l0,0l-1.4,0.6l0,0c0-1-0.7-1.5-1.6-1.2c-1,0.5-1.6,1.4-1.6,2.5l0,0l-1.4,0.6l0,0c0-1-0.7-1.5-1.6-1.2
              c-1,0.5-1.6,1.4-1.6,2.5l0,0l-2.4,1v-2.2c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.6-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5
              c0-1-0.7-1.5-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.5-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.5-1.6-1.2v-1.8
              c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.5-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.5-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5
              c0-1-0.7-1.5-1.6-1.2v-1.6c1-0.5,1.6-1.4,1.6-2.5c0-1-0.7-1.6-1.6-1.2v-2.3l2.4-1l0,0c0,1,0.7,1.6,1.6,1.2c1-0.5,1.6-1.4,1.6-2.5
              l0,0l1.4-0.6l0,0c0,1,0.7,1.6,1.6,1.2c1-0.5,1.6-1.4,1.6-2.5l0,0l1.4-0.6l0,0c0,1,0.7,1.6,1.6,1.2c1-0.5,1.6-1.4,1.6-2.5l0,0
              l1.4-0.6l0,0c0,1,0.7,1.6,1.6,1.2c1-0.5,1.6-1.4,1.6-2.5l0,0l1.6-0.7l0,0c0,1,0.7,1.6,1.6,1.2c1-0.5,1.6-1.4,1.7-2.4l0,0l2.4-1
              v2.3c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.6,1.6,1.2v1.6c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.6,1.6,1.2v1.6c-1,0.5-1.6,1.4-1.6,2.5
              c0,1,0.7,1.5,1.6,1.2V20c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.6,1.6,1.2v1.8c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.6,1.6,1.2v1.6
              c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.5,1.6,1.2v1.6c-1,0.5-1.6,1.4-1.6,2.5c0,1,0.7,1.6,1.6,1.2v1.6c-1,0.5-1.6,1.4-1.6,2.5
              c0,1,0.7,1.5,1.6,1.2L570.7,47.4z"/>
            <polygon fill="#EF805F" points="567.5,44.6 547.3,52.9 547.5,15.7 567.7,7.3       "/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M490.3,73.3v-3.6l0,0c1.3-0.6,2.1-1.9,2.1-3.3
              c0-1.3-0.9-2-2.1-1.5l0,0v-2.4l0,0c1.2-0.6,2.1-1.9,2.1-3.3c0-1.3-0.9-2-2.1-1.5l0,0v-2.1l0,0c1.3-0.6,2.1-1.9,2.1-3.3
              c0-1.3-0.9-2-2.1-1.5l0,0v-2.1l0,0c1.2-0.6,2.1-1.9,2.1-3.3c0-1.3-0.9-2-2.1-1.5l0,0v-2.1l0,0c1.3-0.6,2.1-1.9,2.1-3.3
              c0-1.3-0.9-2-2.1-1.5l0,0v-3.6l2.6-1c0,1.3,0.9,2,2.1,1.5c1.2-0.6,2.1-1.9,2.1-3.3l1.8-0.8c0,1.3,0.9,2,2.1,1.5
              c1.3-0.6,2.1-1.9,2.1-3.3l1.8-0.8c0,1.3,0.9,2,2.1,1.5c1.3-0.6,2.1-1.9,2.1-3.3l1.8-0.7c0,1.3,0.9,2,2.1,1.5
              c1.2-0.6,2.1-1.9,2.1-3.3l2.1-0.9c0,1.3,0.9,2,2.1,1.5c1.3-0.6,2.1-1.9,2.1-3.3l1.8-0.8c0,1.3,0.9,2,2.1,1.5
              c1.2-0.6,2.1-1.9,2.1-3.3l1.8-0.8c0,1.3,0.9,2,2.1,1.5c1.2-0.6,2.1-1.9,2.1-3.3l1.8-0.7c0,1.3,0.9,2,2.1,1.5
              c1.2-0.6,2.1-1.9,2.1-3.3l2.7-1.1V15l0,0c-1.2,0.6-2.1,1.9-2.1,3.3c0,1.3,0.9,2,2.1,1.5l0,0v2.1l0,0c-1.3,0.6-2.1,1.9-2.1,3.3
              c0,1.3,0.9,2,2.1,1.5l0,0v2.1l0,0c-1.2,0.6-2.1,1.9-2.1,3.3c0,1.3,0.9,2,2.1,1.5l0,0v2.1l0,0c-1.2,0.6-2.1,1.9-2.1,3.3
              c0,1.3,0.9,2,2.1,1.5l0,0v2.4l0,0c-1.2,0.6-2,1.9-2.1,3.3c0,1.3,0.9,2,2.1,1.5l0,0V52l-2.5,1c0-1.3-0.9-2-2.1-1.5
              c-1.3,0.6-2.1,1.9-2.2,3.3l-1.8,0.8c0-1.3-0.9-2-2.1-1.5c-1.2,0.6-2.1,1.9-2.1,3.3l-1.8,0.8c0-1.3-0.9-2-2.1-1.5
              c-1.2,0.6-2.1,1.9-2.1,3.3l-1.8,0.7c0-1.3-0.9-2-2.1-1.5c-1.3,0.6-2.1,1.9-2.1,3.3l-2.1,0.8c0-1.3-0.9-2-2.1-1.5
              c-1.2,0.6-2.1,1.9-2.1,3.3l-1.8,0.8c0-1.3-0.9-2-2.1-1.6c-1.2,0.6-2.1,1.9-2.1,3.3l-2,0.4c0-1.3-0.9-2-2.1-1.5
              c-1.3,0.6-2.1,1.9-2.1,3.3l-1.8,0.7c0-1.3-0.9-2-2.1-1.5c-1.2,0.6-2.1,1.9-2.1,3.3L490.3,73.3z"/>
            <polygon fill="#F5A049" points="495,66.6 495.1,36.2 537.3,18.8 537.1,49.2       "/>
          </g>
        </g>
      </g>
      <g id="letters5">
        <g>
          <path fill="#A0D8F7" d="M294.9,228.8l54.2-93.2L178.6,36.5l-54.2,93.2L294.9,228.8z
             M133.4,127.5l47.7-82.1L340,137.8l-47.7,82.1L133.4,127.5z"/>

          <rect x="189.3" y="40.7" transform="matrix(0.5027 -0.8645 0.8645 0.5027 3.1133 270.5738)" fill="#CCCCCE" width="94.9" height="183.8"/>
          <g>
            <polygon fill="#0B99D6" points="287.4,224.4 305.7,210.2 296.6,225.8 293.7,228.1
              "/>
            <path fill="#0B99D6" d="M274.9,217.1l6.3,3.6l5.5-4.2l-6.3-3.6L274.9,217.1L274.9,217.1
              z M300.6,205.5l14.2-11.1l9.2-15.7l-14.2,11.1L300.6,205.5z"/>
            <path fill="#0B99D6" d="M262.3,209.8l6.3,3.6l5.5-4.3l-6.3-3.6L262.3,209.8L262.3,209.8
              z M318.9,174.2l14.2-11.1l9.1-15.7L328,158.5L318.9,174.2z"/>
            <path fill="#0B99D6" d="M249.7,202.5l6.3,3.6l5.4-4.3l-6.3-3.6L249.7,202.5z M340,137.8
              l-2.9,5l10.4-8.1l-6.3-3.6l-5.5,4.2L340,137.8z"/>
            <path fill="#0B99D6" d="M237.1,195.2l6.3,3.6l5.5-4.3l-6.3-3.6L237.1,195.2L237.1,195.2
              z M329.5,131.6l5.5-4.2l-6.3-3.7l-5.4,4.3L329.5,131.6z"/>
            <path fill="#0B99D6" d="M224.6,187.9l6.3,3.6l5.4-4.3l-6.3-3.6L224.6,187.9L224.6,187.9
              z M316.9,124.3l5.5-4.3l-6.3-3.7l-5.5,4.3L316.9,124.3z"/>
            <path fill="#0B99D6" d="M212,180.6l6.3,3.6l5.4-4.3l-6.3-3.6L212,180.6z M304.3,117
              l5.5-4.3l-6.3-3.7l-5.5,4.3L304.3,117z"/>
            <path fill="#0B99D6" d="M199.4,173.3l6.3,3.6l5.4-4.2l-6.3-3.7L199.4,173.3L199.4,173.3
              z M291.8,109.7l5.5-4.3l-6.2-3.7l-5.5,4.3L291.8,109.7z"/>
            <path fill="#0B99D6" d="M186.9,166l6.3,3.6l5.4-4.2l-6.3-3.6L186.9,166z M279.2,102.4
              l5.5-4.3l-6.3-3.7l-5.5,4.3L279.2,102.4z"/>
            <path fill="#0B99D6" d="M174.3,158.7l6.3,3.6l5.4-4.3l-6.3-3.6L174.3,158.7z
               M266.6,95.1l5.5-4.3l-6.3-3.6l-5.5,4.2L266.6,95.1z"/>
            <path fill="#0B99D6" d="M161.8,151.4l6.2,3.6l5.4-4.3l-6.3-3.6L161.8,151.4L161.8,151.4
              z M254.1,87.8l5.4-4.2l-6.3-3.7l-5.4,4.3L254.1,87.8z"/>
            <path fill="#0B99D6" d="M149.2,144.1l6.3,3.6l5.4-4.3l-6.3-3.6L149.2,144.1z
               M241.5,80.5l5.5-4.3l-6.3-3.7l-5.4,4.3L241.5,80.5z"/>
            <path fill="#0B99D6" d="M136.6,136.7l6.3,3.6l5.4-4.3l-6.3-3.6L136.6,136.7z
               M228.9,73.2l5.4-4.3l-6.3-3.7l-5.4,4.3L228.9,73.2z"/>
            <path fill="#0B99D6" d="M124.9,128.7l-0.5,0.9l5.9,3.4l5.4-4.3l-2.4-1.4l5.7-9.8
              L124.9,128.7z M216.4,65.9l5.4-4.3l-6.3-3.6l-5.4,4.3L216.4,65.9z"/>
            <path fill="#0B99D6" d="M143.2,97.4l-9.1,15.6l14.2-11.1l9.1-15.7L143.2,97.4z
               M203.8,58.6l5.4-4.3l-6.3-3.7l-5.4,4.3L203.8,58.6z"/>
            <path fill="#0B99D6" d="M161.4,66l-9.1,15.7l14.2-11.1l9.1-15.7L161.4,66z M191.2,51.3
              l5.5-4.3l-6.3-3.7l-5.4,4.2L191.2,51.3z"/>
            <polygon fill="#0B99D6" points="184.1,39.7 170.6,50.3 178.6,36.5       "/>
          </g>
        </g>
        <g>

          <rect x="564.5" y="68.1" transform="matrix(0.9856 -0.1692 0.1692 0.9856 -10.803 113.3191)" fill="#96CBCC" width="190" height="103.9"/>
          <path fill="#8BB3B2" d="M557.1,84.9l83.4,40.9c12,5.9,29.1,3.3,38-5.8l65.9-67.3
            L557.1,84.9z"/>
          <g>
            <path fill="#0B99D6" d="M669.3,111.2c-8.5,1.5-14.1,9.5-12.7,17.9
              c1.5,8.5,9.5,14.1,17.9,12.7c8.5-1.5,14.1-9.5,12.7-17.9c-0.7-4.1-3-7.7-6.3-10.1C677.5,111.4,673.4,110.5,669.3,111.2z
               M661.3,119c4.2-5.9,12.3-7.3,18.2-3.1c5.9,4.2,7.3,12.3,3.1,18.2c-4.2,5.9-12.3,7.3-18.2,3.1c-2.8-2-4.7-5-5.3-8.4
              C658.5,125.3,659.3,121.8,661.3,119L661.3,119z"/>
            <path fill="#0B99D6" d="M669.1,120.7c0.3,0.3,0.8,0.4,1.2,0.1l0,0
              c0.3-0.3,0.4-0.8,0.1-1.1l0,0c0,0-7.6-8.8-14.3-2.4c-2.2,2.1-3.5,3.6-4.4,4.6c-1.3,1.4-1.5,1.7-2.7,1.6c-0.8-0.3-1.6-0.7-2.2-1.3
              c-2.2-1.8-5.4-4.2-11.3-3c-5.1,1-5.9,2.6-6.9,4.5c-0.8,1.8-2.2,3.3-3.9,4.2c-2.9,1.8-5.2,1.9-7.1,1.3c-1.7-0.6-3.2-1.6-4.3-3
              c-2.5-2.8-6.9-2-6.9-2c-0.4,0.1-0.8,0.5-0.7,0.9v0.1c0.1,0.5,0.5,0.8,1,0.7l0,0c0,0,3.5-0.6,5.4,1.5c1.3,1.6,3.1,2.8,5.1,3.5
              c2.3,0.7,5.1,0.6,8.4-1.5c2-1.1,3.6-2.8,4.5-4.8c0.8-1.6,1.5-2.8,5.8-3.7c5.2-1,8,1.1,10,2.7c0.9,0.8,2,1.4,3.1,1.7
              c2.1,0.2,2.3-0.2,4.1-2.1c0.9-1,2.1-2.4,4.3-4.5c5.4-5.2,11.9,2.2,11.9,2.3L669.1,120.7z M671.7,135.7c0,0-6.5-7.5-11.9-2.3
              c-2.2,2.1-3.5,3.6-4.3,4.5c-1.7,1.9-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7
              c-0.9,2.1-2.5,3.8-4.5,4.9c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7
              c-0.1-0.4,0.2-0.9,0.6-1h0.1c0,0,4.4-0.8,6.9,2c1.2,1.4,2.7,2.4,4.3,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2
              c1-1.9,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6
              c6.7-6.4,14.2,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2l0,0C672.6,136,672.1,136,671.7,135.7C671.8,135.6,671.8,135.6,671.7,135.7
              L671.7,135.7z M671,132c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.6-4.3,4.5c-1.7,1.9-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7
              c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-1,2.1-2.5,3.7-4.5,4.8c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5
              c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.6-1c0,0,0,0,0.1,0c0,0,4.3-0.8,6.9,2
              c1.2,1.4,2.7,2.4,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3
              c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.3,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2
              c-0.3,0.3-0.9,0.3-1.2-0.1l0,0L671,132z M670.4,128.2c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.6-4.3,4.5c-1.7,2-2,2.3-4.1,2.1
              c-1.2-0.2-2.2-0.8-3.1-1.6c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-1,2.1-2.6,3.7-4.5,4.8c-3.4,2.1-6.2,2.2-8.4,1.5
              c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.6l0,0c-0.1-0.4,0.2-0.9,0.6-0.9l0,0c0,0,4.4-0.8,6.9,2
              c1.2,1.4,2.7,2.4,4.3,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-1.9,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3
              c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.2,2.4,14.3,2.4c0.3,0.4,0.2,0.9-0.1,1.2
              C671.1,128.5,670.7,128.5,670.4,128.2L670.4,128.2z M669.8,124.4c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.6-4.3,4.5
              c-1.7,1.9-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-1,2.1-2.5,3.8-4.5,4.9
              c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-0.9-0.7c-0.1-0.4,0.2-0.9,0.7-0.9
              c0,0,4.3-0.8,6.9,2c1.2,1.4,2.7,2.4,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5
              c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.2,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.2,2.4,14.3,2.4
              c0.3,0.3,0.3,0.9-0.1,1.2S670,124.7,669.8,124.4L669.8,124.4z"/>
          </g>
        </g>
        <g>

          <rect x="603.6" y="77.2" transform="matrix(0.9726 -0.2324 0.2324 0.9726 -11.1432 166.543)" fill="#A3DAF7" width="195.3" height="106.7"/>
          <path fill="#80CAF0" d="M783.8,56l-62.3,72.4c-9,10.4-26.2,14.9-38.2,9.9l-89.5-36.9
            L783.8,56z"/>
          <g>
            <path fill="#0B99D6" d="M688.5,126.2c8.6-2.1,17.2,3.2,19.3,11.8s-3.2,17.2-11.8,19.3
              s-17.2-3.2-19.3-11.8c-1-4.1-0.3-8.5,1.9-12.1C680.8,129.8,684.3,127.2,688.5,126.2z M699.2,130.3c-6.3-3.9-14.6-2-18.5,4.4
              c-3.9,6.3-2,14.6,4.4,18.5c6.3,3.9,14.6,2,18.5-4.4c1.9-3,2.5-6.7,1.6-10.2C704.4,135.2,702.2,132.2,699.2,130.3L699.2,130.3z"/>
            <path fill="#0B99D6" d="M692.5,135.2c-0.1,0.4-0.6,0.7-1,0.5l0,0
              c-0.4-0.1-0.7-0.6-0.5-1.1l0,0c0,0,3.6-11.3,12.5-8c3,1.1,4.8,2,6,2.6c1.8,0.8,2.1,1,3.2,0.4c0.7-0.6,1.2-1.3,1.6-2.2
              c1.4-2.6,3.4-6.1,9.4-7.4c5.2-1.1,6.6,0,8.4,1.5c1.5,1.3,3.4,2.2,5.4,2.4c3.5,0.5,5.7-0.3,7.3-1.6c1.4-1.3,2.4-2.9,2.9-4.6
              c1.2-3.6,5.7-4.7,5.7-4.7c0.4-0.1,0.9,0.2,1,0.6s-0.2,0.9-0.6,1c0,0-3.5,0.8-4.5,3.6c-0.6,2.1-1.8,3.9-3.4,5.4
              c-1.9,1.6-4.6,2.6-8.6,2c-2.3-0.2-4.5-1.2-6.2-2.7c-1.4-1.2-2.6-2.1-7-1.1c-5.3,1.1-7.1,4.3-8.3,6.6c-0.5,1.1-1.3,2.1-2.3,2.9
              c-1.9,1-2.3,0.8-4.7-0.4c-1.2-0.6-3-1.4-5.9-2.5C695.5,125.5,692.4,135.1,692.5,135.2L692.5,135.2z M696.1,150.4
              c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,2,5.9,2.5c2.4,1.1,2.8,1.4,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.7,1.5,3.9,2.5,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c0.9-2.8,4.5-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.4-0.5-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.9-9.5,7.4c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.5,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.4-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.2,0.4,0,0.9,0.4,1.1C695.4,151.1,695.9,150.9,696.1,150.4
              C696.2,150.5,696.2,150.4,696.1,150.4L696.1,150.4z M695.2,146.6c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5
              c2.4,1.1,2.8,1.4,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.1c1.7,1.6,3.9,2.5,6.2,2.8
              c4,0.6,6.7-0.4,8.6-2c1.6-1.5,2.8-3.3,3.4-5.4c0.9-2.8,4.5-3.6,4.5-3.6c0.5-0.1,0.7-0.6,0.6-1s-0.6-0.7-1-0.6c0,0-4.4,1-5.7,4.7
              c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.9-9.5,7.4
              c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.5,0.2,0.9,0.6,1
              C694.7,147.2,695.1,147,695.2,146.6L695.2,146.6z M694.3,142.8c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5
              c2.4,1.1,2.8,1.3,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.1c1.7,1.5,3.9,2.5,6.2,2.7
              c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c1-2.8,4.5-3.6,4.5-3.6c0.5-0.1,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.7-1-0.6
              c0,0-4.4,1-5.7,4.7c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5
              c-6.1,1.3-8,4.8-9.5,7.4c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4c-1.2-0.6-3.1-1.4-6-2.6c-8.9-3.3-12.5,8-12.5,8
              c-0.1,0.4,0.1,0.9,0.5,1.1l0,0C693.8,143.4,694.3,143.2,694.3,142.8L694.3,142.8z M693.4,139c0,0,3.1-9.7,10.3-7
              c2.9,1.1,4.7,2,5.9,2.5c2.4,1.1,2.8,1.3,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.2
              c1.7,1.5,3.9,2.5,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c1-2.8,4.5-3.6,4.5-3.6c0.5-0.1,0.8-0.5,0.7-1
              s-0.5-0.8-1-0.7h-0.1c0,0-4.4,1.1-5.7,4.7c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.5-2.3
              c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.5,7.4c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4c-1.2-0.6-3.1-1.4-6-2.6
              c-8.9-3.3-12.5,8-12.5,8c-0.2,0.4,0,0.9,0.4,1.1c0.4,0.2,0.9,0,1.1-0.4C693.4,139.1,693.4,139,693.4,139L693.4,139z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M711.7,165l-3.4-101.2l185-6.3l3.4,101.1L711.7,165L711.7,165z
                 M890.2,152.9l-3-89.1l-172.5,5.8l3,89.1L890.2,152.9z"/>

              <rect x="716.2" y="69.8" transform="matrix(0.9996 -2.998502e-02 2.998502e-02 0.9996 -3.0696 24.1156)" fill="#FFFFFF" width="172.6" height="89.2"/>
              <g>
                <polygon fill="#0B99D6" points="719.8,164.7 711,144.7 711.5,161.7 713,164.9
                  "/>
                <path fill="#0B99D6" d="M733.4,164.2l-6.8,0.2l-2.6-5.9l6.8-0.2L733.4,164.2
                  L733.4,164.2z M717.2,143.2l-6.8-15.5l-0.6-17l6.8,15.5L717.2,143.2z"/>
                <path fill="#0B99D6" d="M747,163.8l-6.8,0.2l-2.6-6l6.8-0.2L747,163.8L747,163.8z
                   M716,109.1l-6.8-15.5l-0.6-17l6.8,15.5L716,109.1z"/>
                <path fill="#0B99D6" d="M760.7,163.3l-6.8,0.2l-2.6-5.9l6.8-0.2L760.7,163.3z
                   M714.7,69.6l0.2,5.4l-5-11.3l6.8-0.2l2.6,5.9L714.7,69.6z"/>
                <path fill="#0B99D6" d="M774.3,162.8l-6.8,0.2l-2.6-5.9l6.8-0.2L774.3,162.8z
                   M726.2,69.2l-2.6-5.9l6.8-0.2L733,69L726.2,69.2z"/>
                <path fill="#0B99D6" d="M788,162.4l-6.8,0.2l-2.6-6l6.8-0.2L788,162.4z M739.9,68.8
                  l-2.6-6l6.8-0.2l2.6,6L739.9,68.8z"/>
                <path fill="#0B99D6" d="M801.6,161.9l-6.8,0.2l-2.6-5.9l6.8-0.2l2.6,6V161.9z
                   M753.5,68.3l-2.6-6l6.8-0.2l2.6,5.9L753.5,68.3z"/>
                <path fill="#0B99D6" d="M815.2,161.4l-6.8,0.2l-2.6-5.9l6.8-0.2L815.2,161.4z
                   M767.1,67.8l-2.6-5.9l6.8-0.2l2.6,5.9L767.1,67.8z"/>
                <path fill="#0B99D6" d="M828.9,161l-6.8,0.2l-2.6-5.9l6.8-0.2L828.9,161z M780.8,67.4
                  l-2.6-5.9l6.9-0.3l2.6,5.9L780.8,67.4z"/>
                <path fill="#0B99D6" d="M842.5,160.5l-6.8,0.2l-2.6-6l6.8-0.2L842.5,160.5
                  L842.5,160.5z M794.4,66.9l-2.6-5.9l6.8-0.2l2.6,6L794.4,66.9z"/>
                <path fill="#0B99D6" d="M856.2,160l-6.8,0.2l-2.6-5.9l6.8-0.2L856.2,160L856.2,160z
                   M808,66.4l-2.6-6l6.8-0.2l2.6,5.9L808,66.4z"/>
                <path fill="#0B99D6" d="M869.8,159.6l-6.8,0.2l-2.6-5.9l6.8-0.2L869.8,159.6z
                   M821.7,66l-2.6-6l6.8-0.2l2.6,5.9L821.7,66z"/>
                <path fill="#0B99D6" d="M883.5,159.1l-6.8,0.2l-2.7-5.9l6.8-0.2L883.5,159.1
                  L883.5,159.1z M835.3,65.5l-2.6-5.9l6.8-0.2l2.6,6L835.3,65.5z"/>
                <path fill="#0B99D6" d="M896.7,157.7v1l-6.4,0.2l-2.6-5.9l2.5-0.1l-0.4-10.6
                  L896.7,157.7L896.7,157.7z M849,65.1l-2.6-6l6.8-0.2l2.6,6L849,65.1z"/>
                <path fill="#0B99D6" d="M895.5,123.6l0.6,17l-6.8-15.5l-0.6-17
                  C888.7,108.2,895.5,123.6,895.5,123.6z M862.6,64.6l-2.6-6l6.8-0.2l2.6,5.9L862.6,64.6z"/>
                <path fill="#0B99D6" d="M894.4,89.6l0.6,17l-6.8-15.5l-0.6-17L894.4,89.6z
                   M876.2,64.1l-2.6-5.9l6.8-0.2l2.6,5.9L876.2,64.1z"/>
                <polygon fill="#0B99D6" points="887.3,57.7 893.8,72.5 893.3,57.5           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M832.6,140.4c-6.4,0.2-11.9-4.8-12.1-11.3
                c-0.2-6.4,4.8-11.9,11.3-12.1c6.4-0.2,11.9,4.8,12.1,11.3c0.1,3.1-1,6.1-3.1,8.4C838.6,138.9,835.7,140.2,832.6,140.4z
                 M842,128.4c-0.2-5.4-4.7-9.7-10.1-9.5c-5.4,0.2-9.7,4.7-9.5,10.1c0.2,5.4,4.7,9.7,10.1,9.5c2.6-0.1,5.1-1.2,6.9-3.1
                C841.2,133.5,842.1,130.9,842,128.4L842,128.4z"/>
              <path fill="#80CAF0" d="M833.7,133.3c0,0,4.1,6.2,8.6,2.9c1.9-1.3,3-2.3,3.7-2.9
                c1.5-1.3,1.7-1.5,3.2-1.2c0.9,0.3,1.6,0.8,2.2,1.6c1.7,2.1,4.4,3.2,7.2,3c3.3-0.2,3.9-1.1,4.7-2.2c0.9-1.4,2.3-2.5,3.9-3.1
                c2-1,4.4-1.1,6.4-0.2c1.4,0.7,2.6,1.8,3.4,3.1c1.2,1.8,3.8,1.7,3.8,1.7c0.3,0,0.6,0.2,0.6,0.6l0,0c0,0.3-0.3,0.6-0.6,0.6l0,0
                c0,0-3.3,0.1-4.9-2.2c-0.7-1.1-1.7-2.1-2.9-2.7c-1.8-0.7-3.8-0.6-5.4,0.3c-1.4,0.5-2.6,1.5-3.4,2.7c-1,1.4-1.8,2.4-5.6,2.7
                c-3.1,0.3-6.1-1-8.1-3.4c-0.4-0.5-0.9-0.9-1.5-1.2c-0.9-0.2-1.1,0-2.2,0.9c-0.8,0.6-1.9,1.6-3.8,3c-5.6,4.1-10.4-3.2-10.4-3.2
                c-0.2-0.3-0.1-0.7,0.2-0.8l0,0C833.2,132.9,833.5,133,833.7,133.3z M833.3,121.8c-0.1-0.3-0.5-0.4-0.8-0.3s-0.4,0.5-0.3,0.8
                c0,0.1,0.1,0.1,0.1,0.2c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2
                c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.4-2.7c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7
                c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.4,0.5-0.7c0-0.3-0.3-0.5-0.5-0.5c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1
                c-2.1-0.9-4.4-0.8-6.4,0.2c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.2-3c-0.6-0.7-1.3-1.3-2.2-1.6
                c-1.5-0.3-1.8-0.1-3.2,1.2c-0.7,0.6-1.8,1.6-3.7,2.9C837.5,128,833.4,121.8,833.3,121.8L833.3,121.8z M833.4,124.7
                c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9
                c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.4-2.7c1.7-0.9,3.7-1,5.4-0.3
                c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6l0,0c0,0-2.6,0.1-3.8-1.7
                c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.3c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.1-3
                c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.2,1.2c-0.7,0.6-1.8,1.6-3.7,2.9C837.5,130.9,833.5,124.7,833.4,124.7L833.4,124.7
                z M833.5,127.6c-0.2-0.3-0.6-0.4-0.8-0.2l0,0c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3
                c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.3-2.7
                c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6l0,0
                c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.2c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2
                c-2.7,0.2-5.4-0.9-7.1-3c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.2,1.2c-0.7,0.6-1.8,1.6-3.7,2.9
                C837.6,133.8,833.5,127.6,833.5,127.6L833.5,127.6z M832.8,130.2c-0.3,0.2-0.4,0.6-0.2,0.9l0,0c0,0,4.8,7.3,10.4,3.2
                c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7
                c0.8-1.2,2-2.2,3.4-2.7c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6l0,0
                c0-0.3-0.3-0.6-0.6-0.6l0,0c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.3c-1.6,0.6-2.9,1.7-3.9,3.1
                c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.2-3c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.3,1.2c-0.7,0.6-1.8,1.6-3.7,2.9
                c-4.6,3.3-8.6-2.9-8.6-2.9C833.4,130.1,833.1,130.1,832.8,130.2L832.8,130.2z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M754,105.2l0.5-2.1l0,0c-0.8-0.1-1.3-0.9-1.2-1.7
              c0.1-0.8,0.9-1.3,1.7-1.2c0.1,0,0.1,0,0.2,0l0,0l0.3-1.4l0,0c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,1-1.2,1.7-1.1l0,0l0.3-1.2l0,0
              c-0.8-0.2-1.2-0.9-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0,0l0.3-1.2l0,0c-0.8-0.2-1.2-1-1-1.8c0.2-0.7,0.9-1.2,1.6-1l0,0l0.3-1.2
              l0,0c-0.8-0.2-1.2-0.9-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.5-2.1l-1.7-0.4c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l0,0l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.1,0.8-0.9,1.3-1.7,1.2c-0.8-0.1-1.3-0.9-1.2-1.7c0-0.1,0-0.1,0-0.2l-1.4-0.3c-0.1,0.8-0.9,1.3-1.7,1.2
              c-0.8-0.1-1.3-0.9-1.2-1.7c0-0.1,0-0.1,0-0.2l-1.2-0.2c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.2-0.3
              c-0.2,0.8-1,1.2-1.7,1.1c-0.8-0.2-1.2-1-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.7-0.4l-0.5,2.1
              l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l0,0l-0.3,1.2l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1
              l0,0l0,0l-0.3,1.2l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l0,0l-0.3,1.2l0,0c0.8,0.1,1.3,0.9,1.2,1.7
              c-0.1,0.8-0.9,1.3-1.7,1.2c-0.1,0-0.1,0-0.2,0l0,0l-0.3,1.4l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.5,2.1
              l1.7,0.4c0.1-0.8,0.9-1.3,1.7-1.2c0.8,0.1,1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1
              c0.8,0.2,1.2,1,1.1,1.7l0,0l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l0,0l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1
              c0.8,0.2,1.2,0.9,1.1,1.7l1.4,0.3c0.1-0.8,0.9-1.3,1.7-1.2c0.8,0.1,1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2l1.2,0.3
              c0.1-0.8,0.9-1.3,1.7-1.2c0.8,0.1,1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,1,1.1,1.7l0,0
              l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l0,0L754,105.2z"/>

            <rect x="730.6" y="75.2" transform="matrix(0.2278 -0.9737 0.9737 0.2278 483.7836 789.35)" fill="#A0D8F7" width="18" height="28.9"/>
          </g>
        </g>
        <g>

          <rect x="17" y="91.3" transform="matrix(0.5255 -0.8508 0.8508 0.5255 -132.1467 157.133)" fill="#96CBCC" width="115.6" height="211.5"/>
          <path fill="#8BB3B2" d="M15.2,92.3l39.5,95.6c5.7,13.8,21.9,24.2,36,23.1l104.5-7.6
            L15.2,92.3z"/>
          <g>
            <path fill="#0B99D6" d="M89.4,196.9c8.1,5,10.7,15.7,5.6,23.8s-15.7,10.7-23.8,5.6
              s-10.7-15.7-5.6-23.8c2.4-3.9,6.3-6.7,10.7-7.7C80.8,193.7,85.5,194.5,89.4,196.9z M68,204c-4.2,6.8-2.1,15.8,4.7,20
              c6.8,4.2,15.8,2.1,20-4.7s2.1-15.8-4.7-20c-3.3-2-7.2-2.7-11-1.8C73.3,198.4,70,200.7,68,204z"/>
            <path fill="#0B99D6" d="M82.2,204.7c0,0,0.1-11-8.2-10.7c-3.4,0.1-5.5,0.4-6.9,0.6
              c-2.9,0.4-3.4,0.4-5-1.2c-0.8-0.9-1.1-2.1-1.4-3.7c-0.5-2.8-1.2-6.6-6.3-9.6c-4.2-2.4-5.7-1.9-7.5-1.2c-2.3,1-4.9,1.2-7.3,0.7
              c-4.3-0.8-6.8-2.7-8.1-5c-1.1-2-1.7-4.3-1.6-6.6c0-3.2-3.4-5.2-3.4-5.2c-0.4-0.3-0.6-0.8-0.3-1.3s0.8-0.6,1.3-0.3l0,0
              c0,0,4.2,2.6,4.2,6.8c-0.1,2,0.4,4,1.4,5.7c1.1,1.9,3.2,3.5,6.9,4.1c2.1,0.5,4.4,0.3,6.4-0.6c2.3-0.9,4.2-1.6,9.1,1.3
              c5.8,3.4,6.6,7.7,7.2,10.8c0.1,1,0.4,1.9,0.9,2.8c1,1,1.3,1,3.4,0.7c1.5-0.2,3.7-0.5,7.1-0.6c10.3-0.4,10.2,12.5,10.2,12.5
              c0,0.5-0.4,0.9-0.9,0.9C82.7,205.6,82.3,205.2,82.2,204.7L82.2,204.7z M73.3,219.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
              c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.5-0.7c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7c0-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5
              c2.5,0.5,5,0.3,7.3-0.7c1.8-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2
              c1.4-0.2,3.5-0.4,6.9-0.6C73.4,208.2,73.3,219.1,73.3,219.1L73.3,219.1z M75.5,215.5c0.1,0.5,0.5,0.9,1,0.8
              c0.4,0,0.8-0.4,0.8-0.8c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.5-0.7c-0.5-0.8-0.8-1.8-0.9-2.8
              c-0.6-3.1-1.4-7.4-7.2-10.8c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7
              c0-4.2-4.2-6.7-4.2-6.8c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3l0,0c0,0,3.3,2,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7
              c1.4,2.2,3.8,4.2,8.1,5c2.5,0.5,5,0.3,7.3-0.7c1.8-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7
              c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6C75.6,204.5,75.5,215.5,75.5,215.5L75.5,215.5z M77.8,211.9c-0.1,0.5,0.3,1,0.8,1
              c0.5,0.1,1-0.3,1-0.8c0-0.1,0-0.1,0-0.2c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.5-0.7
              c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1
              c-1-1.8-1.5-3.7-1.4-5.7c0-4.2-4.2-6.8-4.2-6.8c-0.4-0.3-1-0.2-1.3,0.3c-0.3,0.4-0.2,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2
              c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5c2.5,0.6,5,0.3,7.3-0.7c1.8-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6
              c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6C77.8,200.9,77.7,211.9,77.8,211.9L77.8,211.9z
               M80.9,209.2c0.5,0,0.9-0.4,0.9-0.9l0,0c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.5-0.7
              c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1
              c-1-1.8-1.5-3.7-1.4-5.7c0-4.2-4.2-6.8-4.2-6.8c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2
              c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5c2.5,0.5,5,0.3,7.3-0.7c1.8-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6
              c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6c8.4-0.4,8.3,10.6,8.3,10.6
              C80,208.8,80.4,209.2,80.9,209.2z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M271,258.8l-93.9,37.7l-69-171.8L202,87L271,258.8L271,258.8z
               M198.8,95L116,128.2l64.3,160.2l82.7-33.2L198.8,95z"/>

            <rect x="145" y="105.4" transform="matrix(0.9279 -0.3728 0.3728 0.9279 -57.8088 84.4811)" fill="#D1CCE6" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#0B99D6" points="268,251.3 252.3,266.3 268,260 270.6,257.6         "/>
              <path fill="#0B99D6" d="M262.9,238.6l2.5,6.3l-4.7,4.5l-2.5-6.3L262.9,238.6z
                 M248.7,261l-12.2,11.7l-15.8,6.3l12.2-11.7L248.7,261z"/>
              <path fill="#0B99D6" d="M257.8,225.9l2.5,6.3l-4.7,4.5l-2.5-6.3L257.8,225.9
                L257.8,225.9z M217,273.7l-12.2,11.7l-15.8,6.3l12.3-11.7L217,273.7z"/>
              <path fill="#0B99D6" d="M252.8,213.3l2.5,6.3l-4.7,4.5l-2.5-6.3L252.8,213.3z
                 M180.4,288.4l5-2l-8.9,8.5l-2.5-6.4l4.7-4.5L180.4,288.4z"/>
              <path fill="#0B99D6" d="M247.7,200.6l2.5,6.3l-4.7,4.5l-2.5-6.3L247.7,200.6
                L247.7,200.6z M176.1,277.8l-4.7,4.5l-2.5-6.3l4.7-4.5L176.1,277.8z"/>
              <path fill="#0B99D6" d="M242.6,187.9l2.5,6.3l-4.7,4.5l-2.5-6.3L242.6,187.9z
                 M171,265.1l-4.7,4.5l-2.5-6.3l4.7-4.5L171,265.1z"/>
              <path fill="#0B99D6" d="M237.5,175.3l2.5,6.3l-4.7,4.5l-2.5-6.3L237.5,175.3z
                 M165.9,252.4l-4.7,4.5l-2.5-6.3l4.7-4.5L165.9,252.4z"/>
              <path fill="#0B99D6" d="M232.4,162.6l2.5,6.3l-4.7,4.5l-2.5-6.3L232.4,162.6z
                 M160.8,239.8l-4.7,4.5l-2.5-6.3l4.7-4.5L160.8,239.8z"/>
              <path fill="#0B99D6" d="M227.3,149.9l2.6,6.3l-4.7,4.5l-2.5-6.3L227.3,149.9
                L227.3,149.9z M155.8,227.1l-4.7,4.5l-2.5-6.3l4.7-4.5L155.8,227.1z"/>
              <path fill="#0B99D6" d="M222.2,137.3l2.5,6.3l-4.7,4.5l-2.5-6.3L222.2,137.3z
                 M150.7,214.4l-4.7,4.5l-2.5-6.3l4.7-4.5L150.7,214.4z"/>
              <path fill="#0B99D6" d="M217.1,124.6l2.5,6.3l-4.7,4.5l-2.5-6.3
                C212.4,129.1,217.1,124.6,217.1,124.6z M145.6,201.8l-4.7,4.5l-2.5-6.3l4.7-4.5L145.6,201.8z"/>
              <path fill="#0B99D6" d="M212,111.9l2.5,6.3l-4.7,4.5l-2.5-6.3L212,111.9z M140.4,189.1
                l-4.7,4.5l-2.5-6.3l4.7-4.5L140.4,189.1z"/>
              <path fill="#0B99D6" d="M207,99.3l2.5,6.3l-4.7,4.5l-2.5-6.3L207,99.3z M135.4,176.4
                l-4.7,4.5l-2.5-6.3l4.7-4.5L135.4,176.4z"/>
              <path fill="#0B99D6" d="M201.1,87.3L202,87l2.4,6l-4.7,4.5l-1-2.4l-9.9,4L201.1,87.3
                L201.1,87.3z M130.3,163.8l-4.7,4.5l-2.5-6.3l4.7-4.5L130.3,163.8z"/>
              <path fill="#0B99D6" d="M169.5,100l15.8-6.3L173,105.4l-15.8,6.3L169.5,100z
                 M125.2,151.1l-4.7,4.5l-2.5-6.3l4.7-4.5L125.2,151.1z"/>
              <path fill="#0B99D6" d="M137.9,112.7l15.8-6.3l-12.2,11.7l-15.8,6.3L137.9,112.7
                L137.9,112.7z M120.1,138.4l-4.7,4.5l-2.5-6.3l4.7-4.5L120.1,138.4z"/>
              <polygon fill="#0B99D6" points="110.3,130.3 122,119.1 108.1,124.7         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M206.6,153.5c2.4,6-0.5,12.7-6.5,15.1c-6,2.4-12.7-0.5-15.1-6.5
              c-2.4-6,0.5-12.7,6.5-15.1c2.9-1.1,6.1-1.1,8.9,0.1C203.3,148.3,205.5,150.6,206.6,153.5z M192.2,148.7c-5,2-7.5,7.7-5.4,12.8
              c2,5,7.7,7.5,12.8,5.4c5-2,7.5-7.7,5.4-12.8c-1-2.4-2.9-4.3-5.2-5.4C197.3,147.8,194.6,147.7,192.2,148.7z"/>
            <path fill="#80CAF0" d="M199.6,154.8c0,0,4.4-5.9-0.2-9.1c-1.9-1.3-3.1-2-4-2.5
              c-1.7-1-2-1.1-2.2-2.7c0-0.9,0.2-1.8,0.7-2.6c1.4-2.4,1.6-5.3,0.4-7.8c-1.3-3-2.3-3.3-3.6-3.7c-1.7-0.4-3.2-1.3-4.3-2.6
              c-2-2.2-2.6-4.2-2.4-6c0.2-1.6,0.8-3.1,1.8-4.3c1.2-1.7,0.3-4.2,0.3-4.2c-0.1-0.3,0-0.7,0.4-0.8c0.3-0.1,0.7,0,0.8,0.3
              c0,0,1.2,3.1-0.4,5.4c-0.8,1.1-1.4,2.3-1.5,3.7c-0.1,1.9,0.7,3.7,2.1,5c1,1.1,2.3,1.9,3.7,2.2c1.6,0.4,2.9,0.8,4.4,4.4
              c1.3,2.8,1.2,6.1-0.4,8.8c-0.4,0.6-0.6,1.2-0.6,1.9c0.1,0.9,0.3,1,1.6,1.8c0.9,0.5,2.2,1.2,4.1,2.5c5.7,3.9,0.5,10.8,0.5,10.9
              c-0.2,0.3-0.6,0.3-0.9,0.1C199.5,155.4,199.4,155.1,199.6,154.8L199.6,154.8L199.6,154.8z M189,159.1c-0.2,0.3-0.2,0.7,0.1,0.9
              l0,0c0.3,0.2,0.7,0.1,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c0.1-0.7,0.3-1.3,0.6-1.9
              c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7
              c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3c-0.3,0.1-0.5,0.5-0.3,0.8c0,0,1,2.4-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3
              c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6
              c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5C193.4,153.2,189,159.2,189,159.1L189,159.1z M191.7,158
              c-0.2,0.3-0.2,0.7,0.1,0.9l0,0c0.3,0.2,0.7,0.1,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8
              c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5
              c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.4-0.8-0.3s-0.4,0.5-0.3,0.8c0,0,1,2.4-0.3,4.2
              c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8
              c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5C196.1,152.1,191.7,158.1,191.7,158L191.7,158z
               M194.3,157c-0.2,0.3-0.2,0.7,0.1,0.9l0,0c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5
              c-1.3-0.7-1.5-0.8-1.6-1.8c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2
              c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.4-0.8-0.3s-0.4,0.5-0.3,0.8
              c0,0,1,2.5-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7
              c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c1.4,0.8,2.7,1.6,4,2.5
              C198.8,151.1,194.3,157,194.3,157L194.3,157z M197.1,156.8c0.3,0.2,0.7,0.2,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9
              c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4
              c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3
              c-0.3,0.1-0.5,0.5-0.4,0.8c0,0,1,2.4-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6
              c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5
              c4.7,3.2,0.2,9.1,0.2,9.1C196.8,156.2,196.8,156.6,197.1,156.8L197.1,156.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#D4410B" d="M1095.9,209.8l-41.1-92.5l-169.2,75.3l41.1,92.5L1095.9,209.8z
               M930,277l-36.2-81.4l157.7-70.2l36.2,81.4L930,277z"/>

            <rect x="904.4" y="156.6" transform="matrix(0.9136 -0.4066 0.4066 0.9136 3.7922 420.2066)" fill="#CCCCCE" width="172.6" height="89.2"/>
            <g>
              <path fill="#39BEEE" d="M1026.1,240.8l6.2-2.8l-0.2-6.5l-6.2,2.8L1026.1,240.8z
                 M1028.5,135.6l-0.2-6.5l-6.2,2.8l0.2,6.5L1028.5,135.6z"/>
              <path fill="#39BEEE" d="M1013.7,246.4l6.2-2.8l-0.2-6.5l-6.2,2.8L1013.7,246.4z
                 M1016,141.1l-0.2-6.5l-6.2,2.8l0.2,6.5L1016,141.1z"/>
              <path fill="#39BEEE" d="M1001.2,251.9l6.2-2.8l-0.2-6.5l-6.2,2.8L1001.2,251.9z
                 M1003.5,146.7l-0.2-6.5l-6.2,2.8l0.2,6.5L1003.5,146.7z"/>
              <path fill="#39BEEE" d="M988.7,257.5l6.2-2.8l-0.2-6.5l-6.2,2.8L988.7,257.5
                L988.7,257.5z M991.1,152.2l-0.2-6.5l-6.2,2.8l0.2,6.5L991.1,152.2z"/>
              <path fill="#39BEEE" d="M976.2,263l6.2-2.8l-0.2-6.5l-6.2,2.8L976.2,263L976.2,263z
                 M978.6,157.8l-0.2-6.5l-6.2,2.8l0.2,6.5L978.6,157.8z"/>
              <path fill="#39BEEE" d="M963.8,268.6l6.2-2.8l-0.2-6.5l-6.2,2.8L963.8,268.6
                L963.8,268.6z M966.1,163.3l-0.2-6.5l-6.2,2.8l0.2,6.5L966.1,163.3z"/>
              <path fill="#39BEEE" d="M951.3,274.1l6.2-2.8l-0.2-6.5l-6.2,2.8L951.3,274.1z
                 M953.7,168.9l-0.2-6.5l-6.2,2.8l0.2,6.5L953.7,168.9z"/>
              <path fill="#39BEEE" d="M938.8,279.7l6.2-2.8l-0.2-6.5l-6.2,2.8L938.8,279.7z
                 M941.2,174.4L941,168l-6.2,2.8l0.2,6.5L941.2,174.4z"/>
              <path fill="#39BEEE" d="M926.3,284.1l0.4,0.9l5.9-2.6l-0.2-6.5l-2.3,1l-4.3-9.7
                L926.3,284.1L926.3,284.1z M928.7,180l-0.2-6.5l-6.2,2.8l0.2,6.5L928.7,180z"/>
              <path fill="#39BEEE" d="M912.5,253l6.9,15.5l-0.6-16.9l-6.9-15.6L912.5,253z
                 M916.2,185.5L916,179l-6.2,2.8l0.2,6.5L916.2,185.5z"/>
              <path fill="#39BEEE" d="M898.6,221.9l6.9,15.5l-0.6-16.9L898,205L898.6,221.9
                L898.6,221.9z M903.8,191.1l-0.2-6.5l-6.2,2.8l0.2,6.5L903.8,191.1z"/>
              <polygon fill="#39BEEE" points="891.1,190.1 891.7,206.3 885.6,192.6         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M961.8,170.4l-2,0.9l0,0c0.4,0.7,0.1,1.6-0.6,1.9
              s-1.6,0.1-1.9-0.6c0,0,0-0.1-0.1-0.1l0,0L956,173l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7l0,0l-1.1,0.5l0,0
              c0.4,0.7,0.1,1.6-0.6,1.9s-1.6,0.1-1.9-0.6c0,0,0-0.1-0.1-0.1l0,0l-1.1,0.5l0,0c0.4,0.7,0.1,1.6-0.6,1.9s-1.6,0.1-1.9-0.6
              c0,0,0-0.1-0.1-0.1l0,0l-1.1,0.5l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7l0,0l-2,0.9l0.7,1.6c0.7-0.4,1.6-0.1,1.9,0.6
              s0.1,1.6-0.6,1.9c0,0-0.1,0-0.1,0.1l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0,0l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7
              c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1c0.7-0.4,1.6-0.1,1.9,0.6c0.4,0.7,0.1,1.6-0.6,1.9c-0.1,0-0.1,0.1-0.2,0.1l0.6,1.3
              c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0,0l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1
              c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0,0l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.7,1.6l2-0.9l0,0
              c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l1.1-0.5l0,0c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l0,0l1.1-0.5
              l0,0c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l0,0l1.1-0.5l0,0c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0
              l1.3-0.6l0,0c-0.4-0.7-0.1-1.6,0.6-1.9s1.6-0.1,1.9,0.6c0,0,0,0.1,0.1,0.1l0,0l2-0.9l-0.7-1.6c-0.7,0.3-1.6,0-1.9-0.7
              c-0.3-0.7,0-1.6,0.7-1.9l0,0l-0.5-1.1c-0.7,0.4-1.6,0.1-1.9-0.6s-0.1-1.6,0.6-1.9c0,0,0.1,0,0.1-0.1l-0.5-1.1
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.6-1.3
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l0,0l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1
              c-0.7,0.4-1.6,0.1-1.9-0.6s-0.1-1.6,0.6-1.9c0,0,0.1,0,0.1-0.1l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l0,0
              L961.8,170.4z"/>

            <rect x="949.1" y="176.9" transform="matrix(0.9136 -0.4066 0.4066 0.9136 4.9776 406.1035)" fill="#A0D8F7" width="18" height="28.9"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M1044,44.2l-126.2,51l93.3,231l126.2-51L1044,44.2z
             M1126.7,270.4l-111.2,45L928.4,100l111.2-44.9L1126.7,270.4z"/>

          <rect x="967.5" y="69.1" transform="matrix(0.9272 -0.3746 0.3746 0.9272 5.4222 398.3642)" fill="#E0DCC1" width="119.9" height="232.2"/>
          <polygon fill="#0B99D6" points="1048,54.3 1018.7,54.4 1039.9,45.8 1044.6,45.8     "/>
          <path fill="#0B99D6" d="M1054.9,71.3l-3.4-8.5h-8.7l3.4,8.5H1054.9L1054.9,71.3z
             M1020.2,62.9L997.4,63l-21.2,8.6l22.8-0.1L1020.2,62.9z"/>
          <path fill="#0B99D6" d="M1061.8,88.3l-3.4-8.5h-8.7l3.4,8.5H1061.8L1061.8,88.3z
             M977.7,80.1l-22.8,0.1l-21.2,8.6l22.8-0.1L977.7,80.1z"/>
          <path fill="#0B99D6" d="M1068.7,105.4l-3.4-8.5h-8.7l3.4,8.5H1068.7z M928.4,100l6.8-2.7
            h-16.6l3.4,8.5h8.7L928.4,100z"/>
          <path fill="#0B99D6" d="M1075.6,122.4l-3.4-8.5h-8.7l3.4,8.5H1075.6z M934.2,114.3h-8.7
            l3.4,8.5h8.7L934.2,114.3z"/>
          <path fill="#0B99D6" d="M1082.4,139.4l-3.4-8.5h-8.7l3.4,8.5H1082.4z M941,131.4h-8.7
            l3.4,8.5h8.7L941,131.4z"/>
          <path fill="#0B99D6" d="M1089.3,156.5l-3.4-8.5h-8.7l3.4,8.5H1089.3z M947.9,148.4h-8.7
            l3.4,8.5h8.7L947.9,148.4z"/>
          <path fill="#0B99D6" d="M1096.2,173.5l-3.4-8.5h-8.7l3.4,8.5H1096.2z M954.8,165.4h-8.7
            l3.4,8.5h8.7L954.8,165.4z"/>
          <path fill="#0B99D6" d="M1103.1,190.5l-3.4-8.5h-8.7l3.4,8.5H1103.1L1103.1,190.5z
             M961.7,182.4H953l3.4,8.5h8.7L961.7,182.4z"/>
          <path fill="#0B99D6" d="M1110,207.6l-3.4-8.5h-8.7l3.4,8.5H1110L1110,207.6z
             M968.6,199.5h-8.7l3.4,8.5h8.7L968.6,199.5z"/>
          <path fill="#0B99D6" d="M1116.8,224.6l-3.4-8.5h-8.7l3.4,8.5H1116.8L1116.8,224.6z
             M975.5,216.5h-8.7l3.4,8.5h8.7L975.5,216.5z"/>
          <path fill="#0B99D6" d="M1123.7,241.6l-3.4-8.5h-8.7l3.4,8.5H1123.7z M982.3,233.6h-8.7
            l3.4,8.5h8.7L982.3,233.6z"/>
          <path fill="#0B99D6" d="M1130.6,258.7l-3.4-8.5h-8.7l3.4,8.5H1130.6L1130.6,258.7z
             M989.2,250.6h-8.7l3.4,8.5h8.7L989.2,250.6z"/>
          <path fill="#0B99D6" d="M1136.1,275.7l1.2-0.5l-3.2-8h-8.7l1.3,3.2l-13.3,5.4
            L1136.1,275.7L1136.1,275.7z M996.1,267.6h-8.7l3.4,8.5h8.7L996.1,267.6z"/>
          <path fill="#0B99D6" d="M1093.6,292.9l21.2-8.6l-22.8,0.1l-21.2,8.6L1093.6,292.9z
             M1003,284.6h-8.7l3.4,8.5h8.7L1003,284.6z"/>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M1003.6,227l0.8,2l0,0c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9h-0.1l0,0l0.5,1.4l0,0c0.7-0.3,1.6,0.1,1.9,0.8
              c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.5,1.1l0,0c0.7-0.3,1.6,0.1,1.9,0.8s-0.1,1.6-0.8,1.9l0,0l0,0l0.5,1.1l0,0
              c0.7-0.3,1.6,0.1,1.9,0.8s-0.1,1.6-0.8,1.9l0,0l0,0l0.5,1.1l0,0c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0,0l0.8,2
              l1.6-0.6c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.2-0.5c-0.3-0.7,0.1-1.6,0.8-1.9s1.6,0.1,1.9,0.8l0,0l1.1-0.5
              c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.2-0.5c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.3-0.5
              c-0.3-0.7,0.1-1.6,0.9-1.8c0.7-0.3,1.5,0.1,1.8,0.8l1.2-0.5c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7v0.1l1.2-0.5
              c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5c-0.2-0.8,0.2-1.6,0.9-1.8c0.7-0.2,1.4,0.1,1.7,0.7l1.6-0.6l-0.8-2
              l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l0,0l-0.5-1.1l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.5-1.1l0,0
              c-0.7,0.3-1.6-0.1-1.8-0.9c-0.3-0.7,0.1-1.5,0.8-1.8l0,0l-0.5-1.1l0,0c-0.7,0.3-1.6,0-1.9-0.7s0-1.6,0.7-1.9h0.1l0,0l-0.5-1.3
              l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.8-2l-1.6,0.7c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7v-0.1l-1.2,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0l-1.2,0.5c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7v-0.1l-1.2,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9s-1.6-0.1-1.9-0.8l-1.3,0.5c0.3,0.7-0.1,1.6-0.9,1.8c-0.7,0.3-1.5-0.1-1.8-0.8l-1.2,0.5
              c0.3,0.7,0,1.6-0.8,1.9l0,0c-0.7,0.3-1.6-0.1-1.9-0.8l-1.2,0.5c0.3,0.7-0.1,1.6-0.9,1.8c-0.7,0.3-1.5-0.1-1.8-0.8l-1.2,0.5
              c0.3,0.7-0.1,1.6-0.9,1.8c-0.7,0.3-1.5-0.1-1.8-0.8L1003.6,227z"/>

            <rect x="1010" y="222.5" transform="matrix(0.9272 -0.3746 0.3746 0.9272 -12.12 400.6144)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M1012.6,288.7l2-0.8l0,0c-0.3-0.7,0.1-1.6,0.8-1.9s1.6,0.1,1.9,0.8l0,0l0,0l1.3-0.5l0,0c-0.3-0.7,0.1-1.6,0.8-1.9
              s1.6,0.1,1.9,0.8l0,0l1.2-0.5l0,0c-0.3-0.7,0.1-1.6,0.9-1.8c0.7-0.3,1.5,0.1,1.8,0.8l0,0l1.2-0.5l0,0c-0.3-0.7,0.1-1.6,0.9-1.8
              c0.7-0.3,1.5,0.1,1.8,0.8l0,0l1.2-0.5l0,0c-0.3-0.7,0-1.6,0.7-1.9s1.6,0,1.9,0.7v0.1l0,0l2-0.8l-0.7-1.6c-0.7,0.3-1.6,0-1.9-0.7
              s0-1.6,0.7-1.9h0.1l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8c-0.3-0.7,0.1-1.6,0.8-1.9l-0.5-1.1c-0.8,0.2-1.6-0.2-1.8-0.9
              c-0.2-0.7,0.1-1.4,0.7-1.7l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7s0-1.6,0.7-1.9h0.1l-0.5-1.3c-0.7,0.3-1.6-0.1-1.8-0.9
              c-0.3-0.7,0.1-1.5,0.8-1.8l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8c-0.3-0.7,0.1-1.6,0.8-1.9l-0.5-1.1c-0.7,0.4-1.6,0.1-1.9-0.6
              c-0.4-0.7-0.1-1.6,0.6-1.9c0.1,0,0.1-0.1,0.2-0.1l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9h0.1l-0.7-1.6l-2,0.8
              l0,0c0.3,0.7-0.1,1.6-0.9,1.8c-0.7,0.3-1.5-0.1-1.8-0.8l0,0l-1.2,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0
              l-1.2,0.5l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7v-0.1l0,0l-1.2,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9s-1.6-0.1-1.9-0.8
              l0,0l-1.3,0.5l0,0c0.3,0.7-0.1,1.6-0.9,1.8c-0.7,0.3-1.5-0.1-1.8-0.8l0,0l-2,0.8l0.7,1.6c0.7-0.3,1.6,0.1,1.9,0.8
              c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0.1,1.8,0.9c0.3,0.7-0.1,1.5-0.8,1.8l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7
              c0.3,0.7,0,1.6-0.7,1.9h-0.1l0.5,1.1c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.3c0.7-0.3,1.6,0.1,1.9,0.8
              c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9h-0.1l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7
              c0.3,0.7,0,1.6-0.7,1.9h-0.1l0.5,1.1c0.7-0.4,1.6-0.2,2,0.5s0.2,1.6-0.5,2c-0.1,0.1-0.2,0.1-0.4,0.2L1012.6,288.7z"/>

            <rect x="1008" y="253.4" transform="matrix(0.9271 -0.3748 0.3748 0.9271 -26.2426 400.6925)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="963.3" y="105.2" transform="matrix(0.9272 -0.3746 0.3746 0.9272 14.2128 372.252)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="973.1" y="101.6" transform="matrix(0.9272 -0.3746 0.3746 0.9272 16.2954 375.6479)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="982.9" y="97.1" transform="matrix(0.9272 -0.3746 0.3746 0.9272 18.6827 379.0089)" fill="#236095" width="2.7" height="88.7"/>
        </g>
        <g>
          <path fill="#E2070F" d="M962.3,136.2l-117.4-69L718.8,282.1L836.2,351L962.3,136.2z
             M833.5,339.7L730.1,279L847.6,78.6L951,139.4L833.5,339.7z"/>

          <rect x="724.5" y="149.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 234.6471 828.2675)" fill="#D3AC89" width="232.2" height="120"/>
          <polygon fill="#0B99D6" points="956.8,145.6 938.8,122.4 958.6,134 961.4,137.7     "/>
          <path fill="#0B99D6" d="M947.5,161.4l4.6-7.9l-5.4-6.9l-4.6,7.9L947.5,161.4z M933,128.8
            l-13.9-18l-19.8-11.6l13.9,18L933,128.8z"/>
          <path fill="#0B99D6" d="M938.2,177.3l4.6-7.9l-5.3-6.9l-4.6,7.9L938.2,177.3z
             M893.4,105.5l-13.9-18L859.8,76l13.9,18L893.4,105.5z"/>
          <path fill="#0B99D6" d="M928.9,193.1l4.6-7.9l-5.3-6.9l-4.6,7.9L928.9,193.1z
             M847.6,78.6l6.3,3.7l-10.2-13.1l-4.6,7.9l5.4,6.9L847.6,78.6z"/>
          <path fill="#0B99D6" d="M919.6,208.9l4.6-7.9l-5.4-6.9l-4.6,7.9L919.6,208.9z M839.8,92
            l-5.3-6.9l-4.6,7.9l5.3,6.9L839.8,92z"/>
          <path fill="#0B99D6" d="M910.3,224.8l4.6-7.9l-5.4-6.9l-4.6,7.9L910.3,224.8L910.3,224.8
            z M830.5,107.8l-5.4-6.9l-4.6,7.9l5.3,6.9L830.5,107.8z"/>
          <path fill="#0B99D6" d="M901,240.6l4.6-7.9l-5.3-6.9l-4.6,7.9L901,240.6z M821.2,123.6
            l-5.3-6.9l-4.6,7.9l5.3,6.9L821.2,123.6z"/>
          <path fill="#0B99D6" d="M891.7,256.5l4.6-7.9l-5.4-6.9l-4.7,7.9L891.7,256.5L891.7,256.5
            z M811.9,139.5l-5.3-6.9l-4.6,7.9l5.3,6.9L811.9,139.5z"/>
          <path fill="#0B99D6" d="M882.4,272.3l4.6-7.9l-5.3-6.9l-4.6,7.9L882.4,272.3z
             M802.6,155.3l-5.4-6.9l-4.6,7.9l5.3,6.9L802.6,155.3z"/>
          <path fill="#0B99D6" d="M873.1,288.2l4.6-7.9l-5.3-6.9l-4.6,7.9L873.1,288.2z
             M793.3,171.2l-5.3-6.9l-4.6,7.9l5.3,6.9L793.3,171.2z"/>
          <path fill="#0B99D6" d="M863.8,304l4.6-7.9l-5.3-6.9l-4.7,7.9L863.8,304z M784,187
            l-5.3-6.9L774,188l5.3,6.9L784,187z"/>
          <path fill="#0B99D6" d="M854.5,319.8l4.6-7.9l-5.3-6.9l-4.6,7.9L854.5,319.8L854.5,319.8
            z M774.7,202.8l-5.4-6.8l-4.6,7.9l5.3,6.9L774.7,202.8z"/>
          <path fill="#0B99D6" d="M845.2,335.7l4.6-7.9l-5.3-6.9l-4.6,7.9L845.2,335.7z
             M765.4,218.7l-5.3-6.9l-4.6,7.9l5.3,6.9L765.4,218.7z"/>
          <path fill="#0B99D6" d="M835.1,350.4l1.2,0.7l4.4-7.4l-5.3-6.9l-1.7,3l-12.4-7.3
            L835.1,350.4L835.1,350.4z M756.1,234.5l-5.3-6.9l-4.6,7.9l5.3,6.9L756.1,234.5z"/>
          <path fill="#0B99D6" d="M795.5,327.2l19.7,11.6l-13.9-18l-19.7-11.6L795.5,327.2
            L795.5,327.2z M746.8,250.4l-5.4-6.9l-4.6,7.9l5.3,6.9L746.8,250.4z"/>
          <path fill="#0B99D6" d="M756,304l19.7,11.6l-13.9-18L742.1,286L756,304z M737.5,266.2
            l-5.3-6.9l-4.6,7.9l5.3,6.9L737.5,266.2z"/>
          <polygon fill="#0B99D6" points="722.9,275.2 736.2,292.4 718.8,282.1     "/>
          <g>
            <path fill="#80CAF0" d="M855.7,263.5c4.4-7.5,1.9-17.1-5.6-21.5s-17.1-1.9-21.5,5.6
              s-1.9,17.1,5.6,21.5c3.6,2.1,7.9,2.7,11.9,1.6C850.1,269.7,853.5,267.1,855.7,263.5z M835.4,266.9c-6.3-3.7-8.4-11.8-4.7-18.1
              s11.8-8.4,18.1-4.7s8.4,11.8,4.7,18.1c-1.8,3-4.7,5.2-8.1,6.1C842,269.2,838.4,268.7,835.4,266.9L835.4,266.9z"/>
            <path fill="#80CAF0" d="M846.6,260.3c0,0,4.8,8.8-2.1,12.1c-2.8,1.3-4.6,2-5.8,2.5
              c-2.5,0.9-2.9,1.1-3.5,3.1c-0.2,1.2,0,2.5,0.5,3.6c0.8,2.5,1.8,5.8-1,10.4c-2.3,3.8-3.8,3.9-5.6,4.2c-2.3,0.2-4.4,1.1-6.2,2.6
              c-3.1,2.5-4.3,5.1-4.4,7.5c-0.1,2.1,0.5,4.2,1.5,6.1c1.3,2.6-0.5,5.6-0.5,5.6c-0.2,0.4-0.1,0.9,0.2,1.1c0.4,0.2,0.9,0.1,1.1-0.2
              c0,0,0,0,0-0.1c0,0,2.3-3.9,0.5-7.2c-0.9-1.6-1.3-3.4-1.3-5.2c0.1-2,1.1-4.1,3.8-6.2c1.5-1.3,3.4-2.1,5.4-2.2
              c2.2-0.3,4-0.5,6.8-4.9c3.2-5.2,2-9,1.2-11.8c-0.4-0.8-0.5-1.7-0.5-2.6c0.4-1.2,0.7-1.3,2.5-2c1.3-0.5,3.1-1.2,5.9-2.5
              c8.4-4,2.9-14.3,2.8-14.4c-0.2-0.4-0.7-0.6-1.1-0.3l0,0C846.5,259.4,846.4,259.9,846.6,260.3L846.6,260.3z M833.3,252.5
              c-0.2-0.4-0.1-0.9,0.3-1.1s0.9-0.1,1.1,0.3c0,0,0,0.1,0.1,0.1c0,0,5.6,10.3-2.8,14.3c-2.8,1.4-4.7,2.1-6,2.5
              c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9c-2,0.1-3.9,0.9-5.4,2.2
              c-2.7,2.1-3.7,4.3-3.8,6.2c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1
              c0,0,1.8-3.1,0.5-5.6c-1-1.8-1.6-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.2
              c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.1,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5C838,261.2,833.3,252.5,833.3,252.5
              L833.3,252.5z M836.6,254.4c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3l0,0c0,0,5.6,10.4-2.9,14.4
              c-2.8,1.4-4.7,2.1-5.9,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9
              c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.2c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3l0,0
              c-0.4-0.2-0.5-0.7-0.3-1.1c0,0,1.8-3.1,0.5-5.6c-1-1.9-1.5-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c1.7-1.5,3.9-2.4,6.2-2.6
              c1.8-0.2,3.2-0.4,5.5-4.2c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.1,3.5-3.1c1.2-0.5,3-1.1,5.8-2.5
              C841.3,263.2,836.6,254.5,836.6,254.4L836.6,254.4z M839.9,256.4c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3l0,0
              c0,0,5.6,10.3-2.8,14.3c-2.8,1.4-4.7,2.1-6,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8
              c-2.7,4.4-4.5,4.7-6.8,4.9c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2
              c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0,0,1.8-3.1,0.5-5.6c-1-1.9-1.5-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5
              c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.1c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.2,3.5-3.1
              c1.2-0.5,3-1.1,5.8-2.5C844.7,265.2,839.9,256.4,839.9,256.4L839.9,256.4z M843.6,257.2c0.4-0.2,0.9-0.1,1.1,0.3
              c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-6,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8
              c-2.7,4.4-4.5,4.6-6.8,4.9c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2
              c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0,0,1.8-3.1,0.5-5.6c-1-1.8-1.6-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5
              c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.5-4.2c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.2,3.5-3.1
              c1.2-0.5,3-1.2,5.8-2.5c6.8-3.3,2.1-12,2.1-12.1C843,257.9,843.1,257.4,843.6,257.2L843.6,257.2L843.6,257.2z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M792.8,215.7l-1.1,1.8l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.7,1.2l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0
              l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l0,0l-0.6,1.1
              l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l0,0l-1.1,1.9l1.5,0.9c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2
              l1.2,0.7c0.4-0.7,1.2-1,1.9-0.6s1,1.2,0.6,1.9l-0.1,0.1l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.5,0.9l1.1-1.8l0,0
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l0.6-1.1l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l0.6-1.1l0,0
              c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0,0l0.6-1.1l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l0.7-1.2l0,0
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l1.1-1.9l-1.5-0.9c-0.4,0.7-1.2,1-1.9,0.6s-1-1.2-0.6-1.9l0.1-0.1l-1.1-0.6
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5
              s-0.9-1.3-0.5-2l-1.2-0.7c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0
              l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0L792.8,215.7z"/>

            <rect x="793.1" y="220.5" transform="matrix(0.5062 -0.8624 0.8624 0.5062 193.4736 807.7605)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M765.5,284l2.2,1.3l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l0,0l1.5,0.9l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3
              l0,0l0,0l1.3,0.8l0,0c0.5-0.8,1.5-1.1,2.4-0.6c0.8,0.5,1.1,1.5,0.6,2.4l0,0l1.3,0.8l0,0c0.5-0.8,1.5-1.1,2.4-0.6
              c0.8,0.5,1.1,1.5,0.6,2.4l0,0l0,0l1.3,0.8l0,0c0.7-0.7,1.8-0.6,2.4,0.1c0.4,0.4,0.6,1.1,0.4,1.6l0,0l2.2,1.3l1.1-1.8
              c-0.8-0.4-1.2-1.4-0.8-2.3c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3l0,0
              c0.5-0.8,1.5-1.1,2.3-0.6l0.8-1.3c-0.8-0.5-1-1.6-0.5-2.4c0.5-0.7,1.4-1,2.2-0.6l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3
              s1.5-1.1,2.3-0.6l0.9-1.5c-0.8-0.5-1.1-1.5-0.6-2.4s1.5-1.1,2.4-0.6l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0,0
              l0.8-1.3c-0.8-0.4-1.2-1.4-0.8-2.3c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l0.8-1.3c-0.8-0.4-1.2-1.4-0.8-2.3
              c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l1-1.8l-2.2-1.3l0,0c-0.5,0.8-1.5,1.1-2.4,0.6c-0.8-0.5-1.1-1.5-0.6-2.4l0,0
              l-1.3-0.7l0,0c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l0,0l-1.3-0.8l0,0c-0.4,0.8-1.4,1.2-2.3,0.8
              c-0.8-0.4-1.2-1.4-0.8-2.3c0-0.1,0.1-0.1,0.1-0.2l0,0l-1.3-0.8l0,0c-0.4,0.8-1.4,1.2-2.3,0.8c-0.8-0.4-1.2-1.4-0.8-2.3
              c0-0.1,0.1-0.1,0.1-0.2l0,0l-1.5-0.9l0,0c-0.4,0.8-1.4,1.2-2.3,0.8c-0.8-0.4-1.2-1.4-0.8-2.3c0-0.1,0.1-0.1,0.1-0.2l0,0l-2.2-1.3
              l-1,1.8c0.8,0.4,1.2,1.4,0.8,2.3c-0.4,0.8-1.4,1.2-2.3,0.8c-0.1,0-0.1-0.1-0.2-0.1l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3
              s-1.5,1.1-2.3,0.6l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6
              l-0.9,1.5c0.8,0.5,1.1,1.5,0.6,2.4s-1.5,1.1-2.4,0.6l-0.7,1.3c0.8,0.5,1,1.6,0.4,2.4c-0.5,0.7-1.4,0.9-2.2,0.6l-0.8,1.3
              c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l0,0L765.5,284z"/>

            <rect x="771" y="262.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 153.8459 814.6163)" fill="#FBD4A4" width="34.5" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M749.5,260.5l1.8,1.1l0,0c0.4-0.7,1.2-1,1.9-0.6s1,1.2,0.6,1.9l-0.1,0.1l0,0l1.2,0.7l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2
              l0,0l1.1,0.6l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.8,1.1l0.9-1.5c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1
              c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.7-1.2c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0.6-1.1
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1c-0.7-0.4-1-1.2-0.6-1.9
              s1.2-1,1.9-0.6l0.1,0.1l0.9-1.5L786,241l0,0c-0.4,0.7-1.2,1-1.9,0.6s-1-1.2-0.6-1.9l0.1-0.1l0,0l-1.1-0.6l0,0
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l0,0l-1.1-0.6l0,0
              c-0.4,0.7-1.3,0.9-2,0.5c-0.6-0.4-0.9-1.2-0.5-1.9l0,0l-1.2-0.7l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l0,0l-1.8-1.1
              l-0.9,1.5c0.6,0.5,0.7,1.4,0.2,2c-0.4,0.5-1.1,0.6-1.6,0.4l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5L758,246c0.7,0.4,0.9,1.3,0.5,2
              s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5L749.5,260.5z"/>

            <rect x="754.2" y="242.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 162.8642 786.908)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="868.7,121.1 818.5,200.9 831.9,204.2 879.2,126.1     "/>
        </g>
        <g>

          <rect x="381.2" y="161.7" transform="matrix(0.1821 -0.9833 0.9833 0.1821 103.3059 632.0602)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M531.4,221.1l-81.5,38.7c-11.7,5.6-28.3,2.8-36.8-6.1L350,187.5
            L531.4,221.1z"/>
          <g>
            <path fill="#0B99D6" d="M422.2,245.2c8.2,1.5,13.6,9.4,12.1,17.6
              c-1.5,8.2-9.4,13.6-17.6,12.1s-13.6-9.4-12.1-17.6c0.7-3.9,3-7.4,6.3-9.7C414.2,245.4,418.2,244.5,422.2,245.2z M429.9,252.9
              c-4-5.8-11.9-7.3-17.6-3.3c-5.8,4-7.3,11.9-3.3,17.6c4,5.8,11.9,7.3,17.6,3.3c2.8-1.9,4.7-4.8,5.3-8.1
              C432.5,259.1,431.8,255.6,429.9,252.9L429.9,252.9z"/>
            <path fill="#0B99D6" d="M422.2,254.5c-0.3,0.4-0.8,0.4-1.1,0.2
              c-0.4-0.3-0.4-0.8-0.2-1.1l0.1-0.1c0,0,7.5-8.4,13.9-2.1c2.1,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.7,2.6,1.6
              c0.8-0.2,1.6-0.7,2.2-1.3c2.2-1.7,5.3-4,11-2.8c4.9,1,5.7,2.6,6.7,4.5c0.8,1.7,2.1,3.2,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4
              c1.7-0.6,3.1-1.6,4.3-2.9c2.5-2.7,6.7-1.9,6.7-1.9c0.4,0.1,0.7,0.5,0.7,0.9c-0.1,0.4-0.5,0.7-0.9,0.7c0,0,0,0-0.1,0
              c0,0-3.4-0.6-5.2,1.4c-1.3,1.5-3.1,2.7-5,3.3c-2.2,0.6-4.9,0.5-8.2-1.6c-1.9-1.1-3.4-2.7-4.3-4.8c-0.8-1.5-1.4-2.8-5.6-3.7
              c-5-1-7.7,1-9.7,2.5c-0.8,0.8-1.9,1.4-3.1,1.6c-2,0.1-2.3-0.2-3.9-2.1c-0.8-1-2-2.4-4.1-4.4C428.6,247.3,422.3,254.4,422.2,254.5
              L422.2,254.5z M419.5,269c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6s-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.4-0.2,0.9,0.2,1.1
              C418.8,269.3,419.3,269.3,419.5,269L419.5,269z M420.2,265.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6l0,0c0.1-0.4-0.2-0.8-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0,1.1l0,0C419.4,265.7,419.9,265.7,420.2,265.4L420.2,265.4L420.2,265.4z M420.9,261.7c0,0,6.4-7.2,11.6-2.1
              c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6
              c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1
              h-0.1c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0.1,1.1S420.6,262,420.9,261.7L420.9,261.7z M421.6,258.1c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.9,2,2.4,3.7,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1h-0.1
              c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8-0.1,1.1c0.3,0.3,0.8,0.3,1.1,0.1C421.5,258.2,421.5,258.1,421.6,258.1L421.6,258.1z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M772,168.1l-80.9-60.8L579.9,255.4l80.9,60.8L772,168.1z
                 M659.8,307.5L588.5,254l103.6-138l71.3,53.5L659.8,307.5z"/>

              <rect x="589.7" y="167.2" transform="matrix(0.6006 -0.7996 0.7996 0.6006 100.6925 625.0557)" fill="#D1CCE6" width="172.6" height="89.1"/>
              <g>
                <polygon fill="#0B99D6" points="767.1,174.6 755.8,156 769.4,166.2 771.2,169.2
                  "/>
                <path fill="#0B99D6" d="M758.9,185.5l4.1-5.4l-3.4-5.6l-4.1,5.5L758.9,185.5
                  L758.9,185.5z M750.9,160.2l-8.8-14.4l-13.6-10.2l8.8,14.5L750.9,160.2z"/>
                <path fill="#0B99D6" d="M750.7,196.4l4.1-5.4l-3.4-5.6l-4.1,5.4L750.7,196.4z
                   M723.7,139.7l-8.8-14.5L701.3,115l8.8,14.4L723.7,139.7z"/>
                <path fill="#0B99D6" d="M742.5,207.4l4.1-5.4l-3.4-5.6l-4.1,5.4L742.5,207.4z
                   M692.1,116l4.3,3.3l-6.4-10.6l-4.1,5.4l3.4,5.6L692.1,116z"/>
                <path fill="#0B99D6" d="M734.3,218.3l4.1-5.4l-3.4-5.6l-4.1,5.4L734.3,218.3
                  L734.3,218.3z M685.2,125.2l-3.4-5.6l-4.1,5.4l3.4,5.6L685.2,125.2z"/>
                <path fill="#0B99D6" d="M726.1,229.2l4.1-5.4l-3.4-5.6l-4.1,5.4L726.1,229.2z
                   M677,136.1l-3.4-5.6l-4.1,5.5l3.4,5.6L677,136.1z"/>
                <path fill="#0B99D6" d="M717.9,240.1l4.1-5.4l-3.4-5.6l-4.1,5.4L717.9,240.1z
                   M668.8,147l-3.4-5.6l-4.1,5.4l3.4,5.6L668.8,147z"/>
                <path fill="#0B99D6" d="M709.7,251l4.1-5.4l-3.4-5.6l-4.1,5.4L709.7,251z
                   M660.6,157.9l-3.4-5.6l-4.1,5.4l3.4,5.6L660.6,157.9z"/>
                <path fill="#0B99D6" d="M701.5,261.9l4.1-5.4l-3.4-5.6l-4.1,5.5L701.5,261.9z
                   M652.4,168.8l-3.4-5.6l-4.1,5.5l3.4,5.6L652.4,168.8z"/>
                <path fill="#0B99D6" d="M693.3,272.9l4.1-5.5l-3.4-5.5l-4.1,5.4L693.3,272.9
                  L693.3,272.9z M644.2,179.8l-3.4-5.6l-4.1,5.4l3.4,5.6L644.2,179.8z"/>
                <path fill="#0B99D6" d="M685.2,283.8l4.1-5.4l-3.4-5.6l-4.1,5.5L685.2,283.8z
                   M636,190.7l-3.4-5.6l-4.1,5.4l3.4,5.6L636,190.7z"/>
                <path fill="#0B99D6" d="M677,294.7l4.1-5.5l-3.4-5.5l-4.1,5.5L677,294.7z
                   M627.9,201.6l-3.4-5.6l-4.1,5.4l3.4,5.6L627.9,201.6z"/>
                <path fill="#0B99D6" d="M668.8,305.6l4.1-5.5l-3.4-5.6l-4.1,5.4L668.8,305.6
                  L668.8,305.6z M619.7,212.5l-3.4-5.5l-4.1,5.4l3.4,5.6L619.7,212.5z"/>
                <path fill="#0B99D6" d="M660,315.6l0.8,0.6l3.9-5.1l-3.4-5.5l-1.5,2l-8.5-6.4
                  L660,315.6z M611.5,223.4l-3.4-5.6l-4.1,5.4l3.4,5.6L611.5,223.4z"/>
                <path fill="#0B99D6" d="M632.8,295.1l13.6,10.2l-8.8-14.5L624,280.7L632.8,295.1
                  L632.8,295.1z M603.2,234.3l-3.4-5.6l-4.1,5.4l3.4,5.6L603.2,234.3z"/>
                <path fill="#0B99D6" d="M605.5,274.7l13.6,10.2l-8.8-14.5l-13.6-10.2L605.5,274.7
                  L605.5,274.7z M595.1,245.3l-3.4-5.6l-4.1,5.4l3.4,5.6L595.1,245.3z"/>
                <polygon fill="#0B99D6" points="583.5,250.6 591.9,264.4 579.9,255.4           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M682.5,253.2c3.9-5.2,2.8-12.5-2.3-16.3s-12.5-2.8-16.3,2.3
                s-2.8,12.5,2.3,16.3c2.5,1.9,5.6,2.7,8.6,2.2C677.9,257.3,680.7,255.6,682.5,253.2L682.5,253.2z M667.3,254
                c-4.3-3.3-5.2-9.4-2-13.7c3.3-4.3,9.4-5.2,13.7-2c4.3,3.3,5.2,9.4,2,13.7c-1.6,2.1-3.9,3.5-6.5,3.8
                C672,256.2,669.4,255.6,667.3,254L667.3,254z"/>
              <path fill="#80CAF0" d="M676.1,250c0,0,2.8,6.9-2.6,8.7c-2.2,0.8-3.6,1.1-4.5,1.4
                c-1.9,0.5-2.2,0.6-2.8,2c-0.3,0.9-0.3,1.8,0,2.7c0.7,2.6,0.1,5.5-1.6,7.6c-2,2.6-3.1,2.6-4.5,2.6c-1.7-0.1-3.4,0.4-4.8,1.4
                c-2,1-3.4,2.9-3.9,5.1c-0.2,1.6,0,3.1,0.6,4.6c0.8,2-0.8,4.1-0.8,4.1c-0.2,0.3-0.1,0.7,0.1,0.9s0.7,0.1,0.9-0.1c0,0,2-2.6,1-5.3
                c-0.5-1.2-0.7-2.6-0.5-4c0.4-1.9,1.6-3.4,3.3-4.3c1.2-0.8,2.7-1.2,4.2-1.2c1.7,0,3,0,5.4-3.1c2-2.4,2.7-5.6,1.9-8.6
                c-0.2-0.6-0.2-1.3-0.1-2c0.4-0.9,0.6-0.9,2-1.3c1-0.2,2.4-0.6,4.6-1.4c6.5-2.3,3.3-10.3,3.3-10.4c-0.1-0.3-0.5-0.5-0.8-0.3
                C676.1,249.3,675.9,249.7,676.1,250L676.1,250z M666.9,243.1c-0.1-0.3,0-0.7,0.3-0.8l0,0c0.3-0.1,0.7,0,0.8,0.3
                c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2c0.8,3,0.1,6.2-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.5-0.1-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.3,0,2.7,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1s-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6c0.5-2.2,1.9-4.1,3.9-5.1
                c1.4-1,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3C669.7,250,667,243.2,666.9,243.1L666.9,243.1z M669.2,244.8c-0.1-0.3,0-0.7,0.3-0.8l0,0
                c0.3-0.1,0.7,0,0.8,0.3c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.2,6.2-1.8,8.6c-2.4,3-3.8,3-5.4,3.1c-1.5,0-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.4,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-1,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c1-0.2,2.3-0.6,4.5-1.3c5.3-1.9,2.6-8.7,2.6-8.7L669.2,244.8z M671.5,246.6c-0.2-0.3,0-0.7,0.3-0.8
                c0.3-0.2,0.7,0,0.8,0.3v0.1c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.1,6.2-1.9,8.6c-2.4,3-3.8,3-5.4,3.1c-1.5,0-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.3,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-0.9,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c0.9-0.2,2.3-0.6,4.5-1.4C674.3,253.5,671.5,246.6,671.5,246.6L671.5,246.6z M674.1,247.5
                c0.3-0.1,0.7,0,0.8,0.4c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.1,6.2-1.9,8.6c-2.4,3-3.8,3.1-5.4,3.1c-1.5-0.1-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.4,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-0.9,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c0.9-0.2,2.3-0.6,4.5-1.4c5.3-1.9,2.6-8.7,2.6-8.7C673.7,248.1,673.8,247.7,674.1,247.5L674.1,247.5
                L674.1,247.5z"/>
            </g>

            <rect x="691.4" y="197.3" transform="matrix(0.6006 -0.7996 0.7996 0.6006 130.2583 657.2784)" fill="#A76C2B" width="63.1" height="1.9"/>
          </g>

          <rect x="686.3" y="193.4" transform="matrix(0.6006 -0.7996 0.7996 0.6006 131.2923 651.6019)" fill="#A76C2B" width="63.1" height="1.9"/>
        </g>
        <g>

          <rect x="262.4" y="97.3" transform="matrix(0.5114 -0.8593 0.8593 0.5114 -10.1878 361.6924)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M259.3,99l35.9,83.1c5.2,12,19.5,20.9,31.8,19.8l91.4-8.2
            L259.3,99z"/>
          <g>
            <path fill="#0B99D6" d="M325.8,189.5c-7.2-4.3-16.5-1.9-20.7,5.3
              c-4.3,7.2-1.9,16.5,5.3,20.7s16.5,1.9,20.7-5.3c2.1-3.4,2.6-7.6,1.7-11.5C331.7,194.9,329.2,191.5,325.8,189.5z M314.9,190.1
              c6.8-1.7,13.7,2.4,15.5,9.2s-2.4,13.7-9.2,15.5c-6.8,1.7-13.7-2.4-15.5-9.2c-0.8-3.3-0.3-6.7,1.4-9.6
              C308.8,193.1,311.6,191,314.9,190.1z"/>
            <path fill="#0B99D6" d="M319.6,196.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8
              c0,0-0.1-11.3-9.1-10.8c-3,0.2-4.9,0.4-6.2,0.6c-1.9,0.3-2.2,0.3-3-0.6c-0.5-0.7-0.7-1.5-0.8-2.4c-0.6-2.7-1.3-6.5-6.5-9.4
              c-4.4-2.5-6-1.8-8-1c-1.7,0.8-3.7,1-5.6,0.6c-3.3-0.5-5.1-1.9-6.1-3.5c-0.9-1.5-1.3-3.2-1.3-5c-0.1-3.6-3.8-5.9-3.8-5.9
              c-0.4-0.2-0.9-0.1-1.1,0.3s-0.1,0.9,0.3,1.1c0,0,2.9,1.8,3,4.5c0,2,0.5,4.1,1.6,5.8c1.2,1.9,3.4,3.6,7.2,4.2
              c2.2,0.4,4.4,0.2,6.4-0.7c1.6-0.6,2.9-1.2,6.6,0.9c4.5,2.5,5.2,5.9,5.7,8.3c0.1,1.2,0.5,2.3,1.3,3.2c1.4,1.4,1.9,1.4,4.4,1
              c1.2-0.2,3.1-0.4,6.1-0.6C319.5,186.8,319.6,196.4,319.6,196.4L319.6,196.4z M312,209.2c0,0-0.1-9.6-7.4-9.2
              c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9
              c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1
              s0.7-0.5,1.1-0.3c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1
              c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.6c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8
              c0,0.4-0.3,0.8-0.8,0.8l0,0C312.4,209.9,312,209.6,312,209.2C312,209.1,312,209.1,312,209.2L312,209.2z M313.9,206
              c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3
              c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5
              c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3l0,0c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5
              c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.7,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.6
              c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.4,0.8-0.8,0.8C314.2,206.8,313.9,206.4,313.9,206L313.9,206L313.9,206
              z M315.8,202.8c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2
              c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8
              c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0.2-0.4,0.7-0.5,1.1-0.3l0,0c0,0,3.7,2.2,3.8,5.8c0,1.8,0.4,3.5,1.3,5
              c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4
              c0.9,0.9,1.2,0.8,3,0.6c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.4,0.8-0.8,0.8
              C316.1,203.5,315.8,203.2,315.8,202.8L315.8,202.8z M317.7,199.6c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6
              c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7
              c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3l0,0
              c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1
              c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8
              c0,0.4-0.4,0.8-0.8,0.8C318,200.3,317.7,200,317.7,199.6L317.7,199.6z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M493,250.6l33-97.2L348.2,93.1l-33,97.2L493,250.6z
               M323.3,186.6l29-85.6L518,157.2l-29,85.6L323.3,186.6z"/>

            <rect x="375.5" y="84.4" transform="matrix(0.3211 -0.947 0.947 0.3211 122.7866 515.0699)" fill="#D1CCE6" width="90.4" height="175"/>
            <g>
              <polygon fill="#0B99D6" points="485.3,248 499.6,231.2 494.1,247.5 491.8,250.2
                "/>
              <path fill="#0B99D6" d="M472.2,243.5l6.5,2.2l4.3-5l-6.5-2.2L472.2,243.5z M494,227.8
                l11.1-13l5.5-16.3l-11.1,13L494,227.8z"/>
              <path fill="#0B99D6" d="M459.1,239.1l6.5,2.2l4.3-5l-6.5-2.2L459.1,239.1L459.1,239.1z
                 M505.1,195.1l11.2-13l5.5-16.3l-11.1,13L505.1,195.1z"/>
              <path fill="#0B99D6" d="M446,234.6l6.5,2.2l4.3-5l-6.5-2.2L446,234.6L446,234.6z
                 M518,157.2l-1.8,5.2l8.1-9.5l-6.5-2.2l-4.3,5L518,157.2z"/>
              <path fill="#0B99D6" d="M432.9,230.2l6.5,2.2l4.3-5l-6.5-2.2L432.9,230.2z M507,153.4
                l4.3-5l-6.5-2.2l-4.3,5L507,153.4z"/>
              <path fill="#0B99D6" d="M419.7,225.7l6.5,2.2l4.3-5l-6.6-2.2L419.7,225.7L419.7,225.7z
                 M493.9,149l4.3-5l-6.5-2.2l-4.3,5L493.9,149z"/>
              <path fill="#0B99D6" d="M406.6,221.3l6.5,2.2l4.3-5l-6.5-2.2L406.6,221.3z
                 M480.8,144.5l4.3-5l-6.5-2.2l-4.3,5L480.8,144.5z"/>
              <path fill="#0B99D6" d="M393.5,216.9l6.5,2.2l4.3-5l-6.5-2.2L393.5,216.9L393.5,216.9z
                 M467.7,140.1l4.3-5l-6.5-2.2l-4.3,5L467.7,140.1z"/>
              <path fill="#0B99D6" d="M380.4,212.4l6.5,2.2l4.3-5l-6.5-2.2L380.4,212.4z
                 M454.6,135.6l4.3-5l-6.5-2.2l-4.3,5L454.6,135.6z"/>
              <path fill="#0B99D6" d="M367.3,208l6.5,2.2l4.3-5l-6.5-2.2L367.3,208z M441.5,131.2
                l4.3-5l-6.6-2.2l-4.3,5L441.5,131.2z"/>
              <path fill="#0B99D6" d="M354.2,203.5l6.5,2.2l4.3-5l-6.5-2.2L354.2,203.5L354.2,203.5z
                 M428.3,126.8l4.3-5l-6.5-2.2l-4.3,5L428.3,126.8z"/>
              <path fill="#0B99D6" d="M341.1,199.1l6.5,2.2l4.3-5l-6.5-2.2L341.1,199.1z
                 M415.2,122.3l4.3-5l-6.5-2.2l-4.3,5L415.2,122.3z"/>
              <path fill="#0B99D6" d="M328,194.6l6.5,2.2l4.3-5l-6.5-2.2L328,194.6L328,194.6z
                 M402.1,117.9l4.3-5l-6.5-2.2l-4.3,5L402.1,117.9z"/>
              <path fill="#0B99D6" d="M315.6,189.4l-0.3,0.9l6.2,2.1l4.3-5l-2.4-0.8l3.5-10.2
                L315.6,189.4L315.6,189.4z M389,113.4l4.3-5l-6.5-2.2l-4.3,5L389,113.4z"/>
              <path fill="#0B99D6" d="M326.7,156.6l-5.5,16.4l11.1-13l5.5-16.3L326.7,156.6
                L326.7,156.6z M375.9,109l4.3-5l-6.5-2.2l-4.3,5L375.9,109z"/>
              <path fill="#0B99D6" d="M337.8,123.9l-5.5,16.3l11.1-13l5.5-16.3L337.8,123.9
                L337.8,123.9z M362.8,104.5l4.3-5l-6.5-2.2l-4.3,5L362.8,104.5z"/>
              <polygon fill="#0B99D6" points="354,95.1 343.3,107.5 348.2,93.1         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M423.8,134.5l-2-0.7l0,0c-0.3,0.8-1.1,1.2-1.8,0.9
              s-1.2-1.1-0.9-1.8l0,0l0,0l-1.4-0.5l0,0c-0.3,0.8-1.1,1.2-1.8,0.9s-1.2-1.1-0.9-1.8l0,0l0,0l-1.2-0.4l0,0
              c-0.3,0.8-1.1,1.2-1.8,0.9s-1.2-1.1-0.9-1.8l0,0l-1.2-0.4l0,0c-0.2,0.8-1,1.2-1.8,1s-1.2-1-1-1.8c0-0.1,0-0.1,0.1-0.2l0,0
              l-1.2-0.4l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-2-0.7l-0.6,1.7c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9
              l-0.4,1.2c0.8,0.2,1.2,1,1,1.8s-1,1.2-1.8,1c-0.1,0-0.1,0-0.2-0.1l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l-0.4,1.2
              c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l0,0l-0.5,1.4c0.8,0.2,1.2,1,1,1.8s-1,1.2-1.8,1c-0.1,0-0.1,0-0.2-0.1l-0.4,1.2
              c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l0,0l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l0,0l-0.4,1.2
              c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l0,0l-0.6,1.7l2.1,0.7l0,0c0.3-0.8,1.1-1.2,1.8-0.9s1.2,1.1,0.9,1.8l0,0l0,0l1.2,0.4
              l0,0c0.3-0.8,1.1-1.2,1.8-0.9s1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9s1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0
              c0.3-0.8,1.1-1.2,1.8-0.9s1.2,1.1,0.9,1.8l0,0l1.4,0.5l0,0c0.3-0.8,1.1-1.2,1.8-0.9s1.2,1.1,0.9,1.8l0,0l2,0.7l0.6-1.7
              c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0,0l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0.5-1.4c-0.8-0.3-1.2-1.1-0.9-1.8
              s1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8s1.1-1.2,1.8-0.9L423.8,134.5z"/>

            <rect x="392" y="138.4" transform="matrix(0.3211 -0.947 0.947 0.3211 136.3336 485.2325)" fill="#EF805F" width="29.3" height="18.3"/>
          </g>
          <polygon fill="#189AD7" points="492.1,177.2 449.5,147.7 447.5,155 489.1,182.9     "/>
          <polygon fill="#189AD7" points="486,185.9 443.4,156.3 441.3,163.6 482.9,191.5     "/>
          <polygon fill="#189AD7" points="478.7,196.8 453.6,179.4 452.4,183.8 476.9,200.1     "/>
        </g>
        <g>
          <polygon fill="#96CBCC" points="679,183.7 413.5,147.7 392.9,286 658.3,322     "/>
          <path fill="#8BB3B2" d="M413.5,147.7l94.4,86.8c13.6,12.5,37.7,16.2,53.5,8.2l117.6-59
            L413.5,147.7z"/>
          <g>
            <path fill="#0B99D6" d="M552.8,227.6c5.7,0.7,10.9,3.7,14.4,8.1c3.5,4.4,5,10,4.2,15.5
              c-0.9,5.6-3.9,10.6-8.5,13.8c-4.7,3.4-10.5,4.8-16.2,4c-5.7-0.7-10.9-3.6-14.4-8.1c-3.5-4.4-5-10-4.2-15.5
              c0.9-5.6,3.9-10.5,8.5-13.8C541.2,228.2,547.1,226.8,552.8,227.6L552.8,227.6z M531.5,245.8c-0.7,4.6,0.6,9.3,3.5,13
              c3,3.8,7.4,6.2,12.2,6.9c4.8,0.7,9.7-0.5,13.7-3.4c3.9-2.7,6.5-6.9,7.2-11.6c0.7-4.6-0.6-9.3-3.5-13c-3-3.8-7.4-6.2-12.2-6.9
              s-9.7,0.5-13.7,3.3C534.8,236.9,532.2,241.1,531.5,245.8z"/>
            <path fill="#0B99D6" d="M548.4,239.7c0,0-5.5-12.2-15-7.8c-3.9,1.8-6.2,3.1-7.8,4
              c-3.2,1.8-3.7,2.1-6.4,1.1c-1.4-0.6-2.3-1.8-3.5-3.4c-2-2.8-4.8-6.7-12.2-7.6c-6.1-0.7-7.6,0.7-9.4,2.3c-2.2,2.3-5.1,3.8-8.2,4.3
              c-5.4,1.2-9.2,0.3-12-1.6c-2.3-1.7-4.2-3.9-5.3-6.6c-1.6-3.5-6.5-4.1-6.5-4.1c-0.6-0.1-1.1-0.6-1-1.2l0,0c0.1-0.6,0.7-1,1.3-0.9
              c0,0,6.2,0.8,8.4,5.4c1,2.3,2.5,4.2,4.5,5.7c2.2,1.5,5.5,2.3,10.1,1.2c2.7-0.5,5.2-1.8,7.1-3.8c2.2-2.1,4-3.8,11.3-2.9
              c8.5,1,11.6,5.3,13.9,8.5c0.9,1.3,1.7,2.3,2.5,2.6c1.6,0.6,2,0.4,4.3-0.9c1.6-0.9,4-2.3,8-4.1c11.7-5.4,18.1,8.9,18.1,8.9
              c0.2,0.6,0,1.2-0.6,1.4l0,0C549.4,240.5,548.7,240.3,548.4,239.7L548.4,239.7z M545.4,260c0.3,0.6,0.9,0.8,1.5,0.6
              c0.6-0.2,0.8-0.8,0.6-1.4l0,0c0,0-6.4-14.4-18.2-8.9c-3.9,1.8-6.3,3.2-7.9,4.1c-2.3,1.3-2.7,1.5-4.3,0.9
              c-0.8-0.3-1.5-1.4-2.5-2.6c-2.3-3.2-5.4-7.5-13.9-8.5c-7.2-0.8-9,0.9-11.3,2.9c-1.9,2-4.4,3.3-7.1,3.8c-4.7,1-7.9,0.3-10.1-1.2
              c-2-1.4-3.6-3.4-4.5-5.7c-2.2-4.6-8.3-5.4-8.3-5.4c-0.6-0.1-1.2,0.3-1.3,0.9c-0.1,0.6,0.3,1.2,1,1.2l0,0c0,0,4.9,0.7,6.6,4.1
              c1.1,2.6,3,4.9,5.3,6.6c2.7,1.8,6.6,2.8,12,1.6c3.1-0.6,6-2.1,8.2-4.3c1.8-1.7,3.3-3,9.4-2.3c7.4,0.8,10.2,4.7,12.2,7.6
              c1.1,1.6,2.1,2.9,3.5,3.4c2.7,1,3.2,0.7,6.4-1.1c1.6-0.9,3.9-2.2,7.8-4C540,247.8,545.5,260,545.4,260L545.4,260z M546.2,254.9
              c0.3,0.6,0.9,0.8,1.5,0.6s0.8-0.8,0.6-1.4l0,0c0,0-6.4-14.4-18.2-8.9c-3.9,1.8-6.3,3.2-7.9,4.1c-2.3,1.3-2.7,1.5-4.3,0.9
              c-0.8-0.3-1.5-1.4-2.5-2.6c-2.3-3.2-5.4-7.6-13.9-8.5c-7.2-0.8-9,0.9-11.3,2.9c-1.9,2-4.4,3.3-7.1,3.8c-4.6,1.1-7.9,0.3-10.1-1.2
              c-2-1.4-3.6-3.4-4.5-5.7c-2.2-4.6-8.3-5.4-8.3-5.4c-0.6-0.1-1.2,0.3-1.3,0.9c-0.1,0.6,0.3,1.2,0.9,1.2c0,0,0,0,0.1,0
              c0,0,4.9,0.7,6.6,4.1c1.1,2.6,3,4.9,5.3,6.6c2.7,1.8,6.6,2.8,12,1.6c3.1-0.6,6-2.1,8.2-4.3c1.8-1.7,3.3-3,9.4-2.3
              c7.4,0.8,10.2,4.7,12.2,7.6c1.1,1.6,2.1,2.9,3.5,3.4c2.7,1,3.2,0.7,6.4-1.1c1.6-0.9,3.9-2.2,7.8-4
              C540.8,242.7,546.2,254.9,546.2,254.9L546.2,254.9z M547,249.9c0.3,0.6,0.9,0.8,1.5,0.6s0.8-0.8,0.6-1.4V249
              c0,0-6.4-14.4-18.1-8.9c-3.9,1.8-6.3,3.2-7.9,4.1c-2.3,1.3-2.7,1.5-4.4,0.9c-0.8-0.3-1.5-1.3-2.4-2.6c-2.3-3.2-5.4-7.6-13.9-8.5
              c-7.2-0.8-9,0.9-11.3,3c-1.9,2-4.4,3.3-7.1,3.8c-4.7,1.1-7.9,0.3-10.1-1.2c-2-1.4-3.6-3.4-4.5-5.7c-2.2-4.6-8.3-5.4-8.3-5.4
              c-0.6-0.1-1.2,0.3-1.3,0.9c-0.1,0.6,0.3,1.2,1,1.2l0,0c0,0,4.9,0.7,6.6,4.1c1.1,2.6,3,4.9,5.3,6.6c2.7,1.8,6.6,2.8,12,1.6
              c3.1-0.6,6-2.1,8.2-4.3c1.8-1.7,3.3-3,9.4-2.3c7.4,0.8,10.2,4.7,12.2,7.6c1.1,1.6,2.1,2.9,3.5,3.4c2.7,1,3.2,0.7,6.4-1.1
              c1.6-0.9,3.9-2.2,7.8-4C541.5,237.7,547,249.9,547,249.9L547,249.9z M549.2,245.4c0.6-0.2,0.8-0.8,0.6-1.4c0,0,0,0,0-0.1
              c0,0-6.4-14.4-18.1-8.9c-3.9,1.8-6.3,3.2-7.9,4.1c-2.3,1.3-2.7,1.5-4.4,0.9c-0.8-0.3-1.5-1.3-2.4-2.6c-2.3-3.2-5.4-7.6-13.9-8.5
              c-7.2-0.8-9,0.9-11.3,2.9c-1.9,2-4.4,3.3-7.1,3.8c-4.7,1.1-7.9,0.3-10.1-1.2c-2-1.4-3.6-3.4-4.5-5.7c-2.2-4.6-8.3-5.4-8.4-5.4
              c-0.6-0.1-1.2,0.3-1.3,0.9c-0.1,0.6,0.4,1.2,1,1.3l0,0c0,0,4.9,0.7,6.5,4.1c1.1,2.6,3,4.9,5.3,6.6c2.7,1.8,6.6,2.8,12,1.6
              c3.1-0.6,6-2.1,8.2-4.3c1.8-1.7,3.3-3,9.4-2.3c7.4,0.8,10.2,4.7,12.2,7.6c1.1,1.6,2.1,2.9,3.5,3.4c2.7,1,3.2,0.7,6.4-1.1
              c1.6-0.9,3.9-2.2,7.8-4c9.5-4.4,15,7.8,15,7.8C548,245.3,548.6,245.6,549.2,245.4L549.2,245.4z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M-93.9,415.2l237.1-76.5l-41.8-129.6l-237.1,76.5L-93.9,415.2
            L-93.9,415.2z M-88.4,404.9l-36.8-114.1l221-71.3l36.8,114.1L-88.4,404.9z"/>

          <rect x="-112.4" y="252.2" transform="matrix(0.9517 -0.307 0.307 0.9517 -95.6562 16.2041)" fill="#D3AC89" width="232.2" height="119.9"/>
          <path fill="#0B99D6" d="M3.8,383.7l-5.6-6.7l-8.7,2.8l5.6,6.7L3.8,383.7L3.8,383.7z
             M-84.3,277.6l-5.6-6.7l-8.7,2.8l5.6,6.7L-84.3,277.6z"/>
          <path fill="#0B99D6" d="M21.3,378.1l-5.6-6.7L7,374.1l5.6,6.7
            C12.6,380.9,21.3,378.1,21.3,378.1z M-66.8,271.9l-5.6-6.7l-8.7,2.8l5.6,6.7L-66.8,271.9z"/>
          <path fill="#0B99D6" d="M38.8,372.4l-5.6-6.7l-8.7,2.8l5.6,6.7L38.8,372.4z M-49.4,266.3
            l-5.6-6.7l-8.7,2.8l5.6,6.7L-49.4,266.3z"/>
          <path fill="#0B99D6" d="M56.3,366.8l-5.6-6.7l-8.7,2.8l5.6,6.7L56.3,366.8L56.3,366.8z
             M-31.9,260.7l-5.6-6.7l-8.7,2.8l5.6,6.7L-31.9,260.7z"/>
          <path fill="#0B99D6" d="M73.8,361.1l-5.6-6.7l-8.7,2.8L65,364L73.8,361.1L73.8,361.1z
             M-14.4,255l-5.6-6.7l-8.7,2.8l5.6,6.7L-14.4,255z"/>
          <path fill="#0B99D6" d="M91.2,355.5l-5.6-6.7l-8.7,2.8l5.6,6.7L91.2,355.5z M3.1,249.4
            l-5.6-6.7l-8.7,2.8l5.6,6.7L3.1,249.4z"/>
          <path fill="#0B99D6" d="M108.7,349.9l-5.6-6.7l-8.7,2.9l5.6,6.7L108.7,349.9L108.7,349.9
            z M20.6,243.8L15,237l-8.7,2.8l5.6,6.7L20.6,243.8z"/>
          <path fill="#0B99D6" d="M126.2,344.2l-5.6-6.7l-8.7,2.8l5.6,6.7L126.2,344.2z M38,238.1
            l-5.6-6.7l-8.7,2.8l5.6,6.7L38,238.1z"/>
          <path fill="#0B99D6" d="M142.8,337.5L128.2,320l4.4,13.6l-3.3,1l5.6,6.7l8.2-2.6
            L142.8,337.5L142.8,337.5z M55.5,232.5l-5.5-6.7l-8.7,2.8l5.6,6.7L55.5,232.5z"/>
          <path fill="#0B99D6" d="M128.7,293.9l-14.5-17.5l7,21.8l14.5,17.5L128.7,293.9z
             M73,226.8l-5.6-6.7l-8.7,2.8l5.6,6.7L73,226.8z"/>
          <path fill="#0B99D6" d="M114.6,250.2l-14.5-17.5l7,21.8l14.6,17.5L114.6,250.2
            L114.6,250.2z M90.5,221.2l-5.6-6.7l-8.7,2.8l5.6,6.7L90.5,221.2z"/>
          <polygon fill="#0B99D6" points="93.7,211.6 107.6,228.4 101.4,209.2     "/>
          <g>
            <path fill="#80CAF0" d="M53.5,338.7c8.3-2.6,12.8-11.5,10.1-19.7s-11.5-12.8-19.7-10.1
              c-8.3,2.6-12.8,11.5-10.1,19.7c1.3,4,4,7.2,7.7,9.1C45.2,339.6,49.5,340,53.5,338.7z M60.4,329.8c-3.3,6.5-11.3,9-17.8,5.7
              s-9-11.3-5.7-17.8s11.3-9,17.8-5.7c3.1,1.6,5.5,4.4,6.5,7.7C62.3,323.1,62,326.7,60.4,329.8z"/>
            <path fill="#80CAF0" d="M52.3,329.2c-0.3-0.3-0.9-0.3-1.2,0.1c-0.3,0.3-0.3,0.9,0.1,1.2
              l0,0c0,0,8.9,7.7,14.6,0.3c1.9-2.5,3-4.1,3.8-5.3c1.1-1.6,1.2-1.9,2.5-2c0.9,0.1,1.7,0.5,2.4,1c2.5,1.4,6,3.4,11.7,1.4
              c4.9-1.7,5.5-3.4,6.3-5.5c0.6-1.9,1.8-3.6,3.3-4.8c2.7-2.2,5-2.7,6.9-2.4c1.8,0.4,3.4,1.2,4.8,2.4c2.9,2.4,7.2,1,7.2,1
              c0.4-0.1,0.7-0.6,0.5-1c-0.1-0.4-0.6-0.7-1-0.5c0,0-3.4,1.1-5.6-0.8c-1.6-1.4-3.5-2.4-5.6-2.8c-2.4-0.4-5.2,0.2-8.2,2.7
              c-1.8,1.4-3.2,3.3-3.9,5.5c-0.6,1.7-1.1,3.1-5.3,4.5c-5,1.8-8.1,0-10.4-1.3c-1-0.7-2.2-1.2-3.4-1.2c-2.1,0.1-2.3,0.5-3.8,2.7
              c-0.7,1.1-1.8,2.7-3.7,5.1C59.9,335.7,52.3,329.2,52.3,329.2z M47.6,314.5c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1
              c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5
              c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.8,5.6,0.8c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1l0,0
              c0,0-4.2,1.4-7.2-1c-1.4-1.2-3-2.1-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5
              c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3
              c-0.4-0.3-0.4-0.8-0.1-1.2C46.6,314.3,47.1,314.2,47.6,314.5C47.5,314.5,47.5,314.5,47.6,314.5L47.6,314.5z M48.7,318.2
              c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3
              c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.8,5.6,0.8
              c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.2,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4c-2-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.4,4.8
              c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3
              c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2C47.9,317.9,48.4,317.9,48.7,318.2L48.7,318.2z M49.9,321.8
              c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.4-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3
              c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.8,5.6,0.8
              c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.3,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4c-2-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.3,4.8
              c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3
              c-5.8,7.3-14.6-0.3-14.6-0.3c-0.4-0.3-0.4-0.8-0.1-1.2C49,321.6,49.5,321.5,49.9,321.8C49.9,321.8,49.9,321.9,49.9,321.8
              L49.9,321.8z M51.1,325.5c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.4-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2
              c2.2,1.3,5.3,3,10.4,1.3c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8
              c2.2,1.8,5.6,0.8,5.6,0.8c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.2,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4
              c-1.9-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1
              c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2
              C50.2,325.3,50.8,325.2,51.1,325.5L51.1,325.5L51.1,325.5z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M20.5,267l2-0.7l0,0c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l0,0l1.4-0.4l0,0c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l0,0l1.2-0.4
              l0,0c0.2,0.8,1,1.2,1.8,1c0.8-0.2,1.2-1,1-1.8v-0.1l0,0l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9c0.8-0.2,1.2-1.1,0.9-1.8l0,0
              l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9c0.8-0.2,1.2-1.1,0.9-1.8l0,0l2-0.7l0.5,1.7c-0.8,0.2-1.2,1.1-0.9,1.8
              c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1H46l0.4,1.2c-0.8,0.3-1.2,1.1-0.9,1.9s1.1,1.2,1.9,0.9l0,0
              l0.4,1.2c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0.4,1.4c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1h0.1l0.4,1.2
              c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2
              c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0.5,1.7l-2,0.7l0,0c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l0,0
              l-1.2,0.4l0,0c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.2-0.8-1.1-1.2-1.8-0.9
              c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l0,0l-1.2,0.4l0,0c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l0,0l-1.3,0.4l0,0
              c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l0,0l-2,0.6l-0.5-1.7c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9
              L30,295c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l-0.4-1.4c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l-0.4-1.2
              c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l-0.4-1.2c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l-0.4-1.2
              c0.8-0.3,1.2-1.1,0.9-1.8c-0.3-0.8-1.1-1.2-1.8-0.9L20.5,267z"/>

            <rect x="28.2" y="265.7" transform="matrix(0.9517 -0.307 0.307 0.9517 -84.2095 24.945)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M93.1,255.1l0.8,2.4l0,0c-0.9,0.4-1.3,1.4-0.9,2.2c0.3,0.7,1.1,1.2,1.9,1l0,0l0.5,1.6l0,0c-0.9,0.3-1.3,1.3-1,2.2
              c0.3,0.8,1.2,1.3,2,1l0,0l0.5,1.4l0,0c-0.9,0.2-1.5,1.2-1.2,2.1c0.2,0.9,1.2,1.5,2.1,1.2c0.1,0,0.1,0,0.2-0.1l0,0l0.4,1.4l0,0
              c-0.9,0.2-1.5,1.1-1.4,2c0.2,0.9,1.1,1.5,2,1.4c0.1,0,0.2-0.1,0.3-0.1l0,0l0.4,1.4l0,0c-0.9,0.4-1.3,1.4-0.9,2.2
              c0.3,0.7,1.1,1.2,1.9,1l0,0l0.8,2.4l-2,0.6c-0.4-0.9-1.4-1.3-2.2-0.9c-0.7,0.3-1.2,1.1-1,1.9l-1.4,0.5c-0.2-0.9-1.1-1.5-2-1.4
              s-1.5,1.1-1.4,2c0,0.1,0.1,0.2,0.1,0.3l-1.4,0.5c-0.2-0.9-1.1-1.5-2-1.4s-1.5,1.1-1.4,2c0,0.1,0.1,0.2,0.1,0.3l-1.4,0.5
              c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.6,0.5c-0.3-0.9-1.3-1.4-2.2-1.1
              c-0.9,0.3-1.4,1.3-1.1,2.2l0,0l-1.4,0.5c-0.5-0.8-1.6-1-2.4-0.5c-0.5,0.4-0.8,1-0.7,1.6l-1.4,0.5c-0.2-0.9-1.2-1.5-2.1-1.2
              c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.4,0.5c-0.3-0.9-1.3-1.3-2.2-1c-0.8,0.3-1.3,1.2-1,2l-2,0.6l-0.8-2.4l0,0
              c0.9-0.4,1.3-1.4,0.9-2.2c-0.3-0.7-1.1-1.2-1.9-1l0,0l-0.5-1.4l0,0c0.9-0.3,1.3-1.3,1-2.2c-0.3-0.8-1.1-1.2-2-1l0,0l-0.5-1.4l0,0
              c0.9-0.2,1.5-1.1,1.4-2c-0.2-0.9-1.1-1.5-2-1.4c-0.1,0-0.2,0.1-0.3,0.1l0,0l-0.5-1.4l0,0c0.9-0.3,1.3-1.3,1-2.2
              c-0.3-0.8-1.2-1.3-2-1l0,0l-0.5-1.6l0,0c0.9-0.2,1.5-1.1,1.4-2c-0.2-0.9-1.1-1.5-2-1.4c-0.1,0-0.2,0.1-0.3,0.1l0,0l-1-2.4l2-0.6
              c0.2,0.9,1.1,1.5,2,1.4c0.9-0.2,1.5-1.1,1.4-2c0-0.1-0.1-0.2-0.1-0.3l1.4-0.5c0.2,0.9,1.2,1.5,2.1,1.2c0.9-0.2,1.5-1.2,1.2-2.1
              c0-0.1,0-0.1-0.1-0.2l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l0,0L69,263c0.2,0.9,1.2,1.5,2.1,1.2
              c0.9-0.2,1.5-1.2,1.2-2.1c0-0.1,0-0.1-0.1-0.2l1.6-0.5c0.2,0.9,1.2,1.5,2.1,1.2c0.9-0.2,1.5-1.2,1.2-2.1c0-0.1,0-0.1-0.1-0.2
              l1.4-0.5c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.3-1.2,1-2l1.4-0.5c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.3-1.2,1-2l1.4-0.5
              c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.2-1.1,1-2L93.1,255.1z"/>

            <rect x="60.2" y="264.3" transform="matrix(0.9517 -0.307 0.307 0.9517 -80.7038 37.0382)" fill="#FBD4A4" width="34.4" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M73.5,234.4l0.7,2l0,0c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0,0l0.4,1.3l0,0c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1
              h0.1l0,0l0.4,1.2l0,0c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0,0l0.4,1.2l0,0c-0.8,0.2-1.2,1.1-0.9,1.8
              c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0,0l0.4,1.2l0,0c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0,0l0.7,2l-1.7,0.5
              c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.2,0.4c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l-1.2,0.4
              c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l-1.2,0.4c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.3,0.4
              c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8v0.1l-1.2,0.4c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l-1.2,0.4
              c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.7-1c-0.8,0.2-1.2,1-1,1.7c0,0.1,0,0.1,0,0.1
              l-1.7,0.5l-0.7-2l0,0c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l0,0l-0.4-1.2l0,0c0.8-0.2,1.2-1.1,0.9-1.8
              c-0.2-0.8-1.1-1.2-1.8-0.9l0,0l0,0l-0.4-1.2l0,0c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l0,0l-0.4-1.2l0,0
              c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l0,0l-0.4-1.4l0,0c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l0,0l-0.7-2l1.7-0.5
              c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l1.2-0.4c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l1.2-0.4c0.2,0.8,1.1,1.2,1.8,0.9
              c0.8-0.2,1.2-1.1,0.9-1.8l1.2-0.4c0.2,0.8,1,1.2,1.8,0.9l0,0c0.8-0.2,1.2-1.1,0.9-1.8l1.3-0.4c0.2,0.8,1.1,1.2,1.8,0.9
              c0.8-0.2,1.2-1.1,0.9-1.8l1.2-0.4c0.3,0.7,1.1,1.1,1.8,0.9c0.7-0.3,1.1-1,0.9-1.7l1.2-0.4c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1
              l1.2-0.4c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8L73.5,234.4z"/>

            <rect x="46" y="242.1" transform="matrix(0.9517 -0.307 0.307 0.9517 -74.1661 30.6773)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="-88.4,320.6 0.5,288.9 0.7,302.7 -85.7,331.9     "/>
        </g>
        <g>
          <path fill="#E2070F" d="M650,207.3l-248.9-10.5l-5.8,136l248.9,10.5L650,207.3L650,207.3
            z M641.2,215.1l-5.1,119.8l-232-9.8l5.1-119.8L641.2,215.1z"/>

          <rect x="462.6" y="153.9" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 230.745 780.6918)" fill="#E0DCC1" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="639.1,206.9 648.8,234.5 649.8,211.6 648.3,207.2     "/>
          <path fill="#0B99D6" d="M620.8,206.1l2.9,8.2l9.2,0.4l-2.9-8.2L620.8,206.1z
             M639.4,258.9l7.6,21.5l1-22.9l-7.6-21.4L639.4,258.9z"/>
          <path fill="#0B99D6" d="M602.4,205.3l2.9,8.2l9.2,0.4l-2.9-8.2L602.4,205.3z
             M637.4,304.7l7.6,21.5l1-22.9l-7.6-21.5L637.4,304.7z"/>
          <path fill="#0B99D6" d="M584,204.5l2.9,8.2l9.2,0.4l-2.9-8.2L584,204.5L584,204.5z
             M629.9,334.6l2.9,8.2l9.2,0.4l-5.5-15.7l-0.3,7.3L629.9,334.6z"/>
          <path fill="#0B99D6" d="M565.7,203.8l2.9,8.2l9.2,0.4l-2.9-8.2L565.7,203.8z
             M611.5,333.9l2.9,8.2l9.2,0.4l-2.9-8.2L611.5,333.9z"/>
          <path fill="#0B99D6" d="M547.3,203l2.9,8.2l9.2,0.4l-2.9-8.2L547.3,203L547.3,203z
             M593.2,333.1l2.9,8.2l9.2,0.4l-2.9-8.2L593.2,333.1z"/>
          <path fill="#0B99D6" d="M529,202.2l2.9,8.2l9.2,0.4l-2.9-8.2L529,202.2z M574.8,332.3
            l2.9,8.2l9.2,0.4l-2.9-8.2L574.8,332.3z"/>
          <path fill="#0B99D6" d="M510.6,201.4l2.9,8.2l9.2,0.4l-2.9-8.2L510.6,201.4L510.6,201.4z
             M556.5,331.5l2.9,8.2l9.2,0.4l-2.9-8.2L556.5,331.5z"/>
          <path fill="#0B99D6" d="M492.3,200.6l2.9,8.2l9.2,0.4l-2.9-8.3L492.3,200.6L492.3,200.6z
             M538.1,330.8l2.9,8.2l9.2,0.4l-2.9-8.2L538.1,330.8z"/>
          <path fill="#0B99D6" d="M473.9,199.9l2.9,8.2l9.2,0.4l-2.9-8.2L473.9,199.9z M519.8,330
            l2.9,8.2l9.2,0.4l-2.9-8.2L519.8,330z"/>
          <path fill="#0B99D6" d="M455.6,199.1l2.9,8.2l9.2,0.4l-2.9-8.2L455.6,199.1z
             M501.4,329.2l2.9,8.2l9.2,0.4l-2.9-8.2L501.4,329.2z"/>
          <path fill="#0B99D6" d="M437.2,198.3l2.9,8.2l9.2,0.4l-2.9-8.2L437.2,198.3z
             M483.1,328.4l2.9,8.2l9.2,0.4l-2.9-8.2L483.1,328.4z"/>
          <path fill="#0B99D6" d="M418.9,197.5l2.9,8.2l9.2,0.4l-2.9-8.2L418.9,197.5z
             M464.7,327.7l2.9,8.2l9.2,0.4l-2.9-8.3L464.7,327.7z"/>
          <path fill="#0B99D6" d="M401,198.1l7.6,21.5l0.6-14.3l3.4,0.1l-2.9-8.2l-8.6-0.4
            L401,198.1L401,198.1z M446.4,326.9l2.9,8.2l9.2,0.4l-2.9-8.2L446.4,326.9z"/>
          <path fill="#0B99D6" d="M399.1,243.9l7.6,21.5l1-22.9l-7.6-21.5L399.1,243.9L399.1,243.9
            z M428,326.1l2.9,8.2l9.2,0.4l-2.9-8.2L428,326.1z"/>
          <g>
            <path fill="#80CAF0" d="M485.2,227.9c-8.7-0.4-16,6.4-16.4,15c-0.4,8.7,6.4,16,15,16.4
              c8.7,0.4,16-6.4,16.4-15c0.2-4.2-1.3-8.2-4.1-11.3C493.3,229.9,489.4,228.1,485.2,227.9z M475.6,233.9c5.4-4.9,13.7-4.6,18.7,0.8
              c4.9,5.4,4.6,13.7-0.8,18.7c-5.4,4.9-13.7,4.6-18.7-0.8c-2.4-2.6-3.6-6-3.5-9.5C471.5,239.6,473,236.3,475.6,233.9L475.6,233.9z"
            />
            <path fill="#80CAF0" d="M483,237.3c0.2,0.4,0.7,0.5,1.1,0.3l0,0
              c0.4-0.2,0.6-0.7,0.3-1.1l0,0c0,0-5.6-10.3-13.6-5.4c-2.7,1.6-4.3,2.8-5.4,3.6c-1.6,1.1-1.8,1.3-3,1c-0.6-0.2-1.2-0.9-1.9-1.8
              c-1.9-2.2-4.4-5.3-10.5-5.4c-5.2-0.1-6.4,1.3-7.8,3c-1.2,1.6-2.9,2.8-4.8,3.3c-3.2,1.1-5.6,0.8-7.3-0.2c-1.6-1-2.8-2.3-3.7-3.9
              c-1.9-3.3-6.3-3.5-6.4-3.5c-0.5,0-0.8,0.4-0.8,0.9c0,0.4,0.3,0.7,0.7,0.8c0,0,3.5,0.1,5,2.6c1,1.9,2.5,3.5,4.3,4.6
              c2.1,1.2,4.9,1.6,8.7,0.3c2.2-0.7,4.1-2,5.5-3.8c1.2-1.4,2.1-2.5,6.5-2.4c5.4,0.1,7.6,2.8,9.3,4.8c0.7,1,1.6,1.8,2.8,2.3
              c2,0.6,2.4,0.3,4.5-1.2c1.1-0.8,2.6-1.9,5.2-3.5C478.2,228.5,483,237.3,483,237.3L483,237.3z M482.3,252.7c0,0-4.8-8.7-11.3-4.8
              c-2.6,1.6-4.2,2.8-5.2,3.5c-2.1,1.6-2.5,1.8-4.5,1.2c-1.1-0.5-2.1-1.3-2.8-2.3c-1.6-2-3.9-4.7-9.3-4.8c-4.4-0.1-5.4,1-6.5,2.4
              c-1.4,1.8-3.3,3.2-5.5,3.8c-3.8,1.3-6.6,0.9-8.7-0.3c-1.8-1.1-3.3-2.7-4.3-4.5c-1.4-2.5-5-2.6-5-2.6c-0.5,0-0.8-0.4-0.8-0.9
              s0.4-0.8,0.9-0.8l0,0c0,0,4.5,0.2,6.4,3.5c0.9,1.6,2.1,3,3.7,3.9c1.7,1,4.1,1.3,7.3,0.2c1.9-0.6,3.6-1.7,4.8-3.3
              c1.4-1.7,2.6-3.1,7.8-3c6.1,0.1,8.7,3.2,10.5,5.4c0.8,0.9,1.4,1.6,1.9,1.8c1.2,0.4,1.5,0.2,3-1c1.1-0.8,2.7-2,5.4-3.6
              c8-4.9,13.6,5.4,13.6,5.4c0.2,0.4,0.1,0.9-0.3,1.1l0,0C483,253.3,482.5,253.1,482.3,252.7L482.3,252.7z M482.5,248.9
              c0,0-4.8-8.8-11.3-4.8c-2.6,1.6-4.2,2.8-5.2,3.5c-2.1,1.6-2.5,1.8-4.5,1.2c-1.1-0.5-2.1-1.3-2.8-2.3c-1.6-2-3.9-4.7-9.3-4.8
              c-4.4-0.1-5.4,1-6.5,2.4c-1.4,1.8-3.3,3.2-5.5,3.8c-3.8,1.3-6.6,0.9-8.7-0.3c-1.8-1.1-3.3-2.7-4.3-4.6c-1.4-2.5-5-2.6-5-2.6
              c-0.5-0.1-0.8-0.5-0.7-1c0.1-0.4,0.4-0.7,0.8-0.7c0,0,4.5,0.2,6.4,3.5c0.8,1.6,2.1,3,3.7,3.9c1.7,1,4.1,1.3,7.3,0.2
              c1.9-0.6,3.6-1.7,4.8-3.3c1.4-1.7,2.6-3.1,7.8-3c6.1,0.1,8.7,3.2,10.5,5.4c0.8,0.9,1.4,1.6,1.9,1.8c1.2,0.4,1.5,0.2,3-1
              c1.1-0.8,2.7-2,5.4-3.6c8-4.9,13.6,5.4,13.6,5.4c0.2,0.4,0.1,0.9-0.3,1.1C483.2,249.4,482.7,249.3,482.5,248.9L482.5,248.9
              L482.5,248.9z M482.7,245c0,0-4.8-8.8-11.3-4.8c-2.6,1.6-4.2,2.8-5.2,3.5c-2.1,1.6-2.5,1.8-4.5,1.2c-1.1-0.5-2.1-1.3-2.8-2.3
              c-1.6-2-3.9-4.7-9.3-4.8c-4.4-0.1-5.4,1-6.5,2.4c-1.4,1.8-3.3,3.2-5.5,3.8c-3.8,1.3-6.6,0.9-8.7-0.3c-1.8-1.1-3.3-2.7-4.3-4.5
              c-1.4-2.5-5-2.6-5-2.6c-0.5,0-0.8-0.4-0.8-0.9l0,0c0-0.5,0.4-0.8,0.9-0.8c0,0,4.5,0.2,6.4,3.5c0.8,1.6,2.1,3,3.7,3.9
              c1.7,1,4.1,1.3,7.3,0.2c1.9-0.6,3.6-1.7,4.8-3.3c1.4-1.7,2.6-3.1,7.8-3c6.1,0.1,8.7,3.2,10.5,5.4c0.8,0.9,1.4,1.6,1.9,1.8
              c1.2,0.4,1.5,0.1,3-1c1.1-0.8,2.7-2,5.4-3.6c8-4.9,13.6,5.4,13.6,5.4c0.2,0.4,0.1,0.9-0.2,1.1C483.5,245.5,483,245.4,482.7,245
              C482.6,245.1,482.6,245,482.7,245L482.7,245z M482.8,241.2c0,0-4.8-8.8-11.3-4.8c-2.6,1.6-4.2,2.8-5.2,3.5
              c-2.1,1.6-2.5,1.8-4.5,1.2c-1.1-0.5-2.1-1.3-2.8-2.3c-1.6-2-3.9-4.7-9.3-4.8c-4.4-0.1-5.4,1-6.5,2.4c-1.4,1.8-3.3,3.2-5.5,3.8
              c-3.8,1.3-6.6,0.9-8.7-0.3c-1.8-1.1-3.3-2.7-4.3-4.5c-1.4-2.5-5-2.6-5-2.6c-0.5,0-0.8-0.4-0.8-0.9s0.4-0.8,0.9-0.8
              c0,0,4.5,0.2,6.4,3.5c0.9,1.6,2.1,3,3.7,3.9c1.7,1,4.1,1.3,7.3,0.2c1.9-0.6,3.6-1.7,4.8-3.3c1.4-1.7,2.6-3.1,7.8-3
              c6.1,0.1,8.7,3.2,10.5,5.4c0.8,0.9,1.4,1.6,1.9,1.8c1.2,0.4,1.5,0.2,3-1c1.1-0.8,2.7-2,5.4-3.6c8-4.9,13.6,5.4,13.6,5.4
              c0.2,0.4,0.1,0.9-0.3,1.1l0,0C483.5,241.7,483,241.5,482.8,241.2L482.8,241.2z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M491.2,306.6l-2.1-0.1l0,0c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l0,0l-1.4-0.1l0,0c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l0,0
              l-1.2-0.1l0,0c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l0,0l0,0h-1.2l0,0c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l0,0h-1.2l0,0
              c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l0,0l-2.1-0.1l0.1-1.8c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5v-1.2
              c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0.1-1.2c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0v-1.2c0.8,0,1.5-0.6,1.5-1.4
              s-0.6-1.5-1.4-1.5l0.1-1.4c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0v-1.2c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0l0.1-1.2
              c0.8,0.1,1.5-0.5,1.6-1.3s-0.5-1.5-1.3-1.6c-0.1,0-0.1,0-0.2,0V275c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0.1-1.8l2.1,0.1l0,0
              c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0h1.2l0,0c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0h1.2l0,0c0,0.8,0.6,1.5,1.4,1.5
              s1.5-0.6,1.5-1.4l0,0l0,0h1.2l0,0c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0l0,0l1.4,0.1l0,0c0,0.8,0.6,1.5,1.4,1.5
              s1.5-0.6,1.5-1.4l0,0l0,0l2.1,0.1l-0.1,1.8c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0v1.2c-0.8,0-1.5,0.6-1.5,1.4
              s0.6,1.5,1.4,1.5v1.2c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l-0.1,1.2c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l-0.1,1.4
              c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0v1.2c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l-0.1,1.2c-0.8,0-1.5,0.6-1.5,1.4
              s0.6,1.5,1.4,1.5v1.2c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5L491.2,306.6z"/>

            <rect x="465.7" y="279.5" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 171.6282 755.9871)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M419,292.6l0.1-2.5l0,0c0.9,0,1.7-0.7,1.8-1.6c0-0.9-0.7-1.7-1.6-1.8l0,0l0.1-1.7l0,0c0.9,0,1.7-0.8,1.7-1.7
              c0-0.9-0.7-1.6-1.5-1.7l0,0l0.1-1.5l0,0c0.9,0.1,1.8-0.6,1.9-1.5s-0.6-1.8-1.5-1.9c-0.1,0-0.1,0-0.2,0l0,0l0.1-1.5l0,0
              c0.9,0.1,1.8-0.6,1.9-1.5s-0.6-1.8-1.5-1.9c-0.1,0-0.1,0-0.2,0l0,0l0.1-1.5l0,0c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.7-1.6-1.5-1.7l0,0
              l0.1-2.5l2.1,0.1c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l1.5,0.1c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l1.5,0.1
              c-0.1,0.9,0.6,1.8,1.5,1.9s1.8-0.6,1.9-1.5c0-0.1,0-0.1,0-0.2h1.5c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l1.7,0.1
              c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l1.5,0.1c-0.1,0.9,0.6,1.8,1.5,1.9s1.8-0.6,1.9-1.5c0-0.1,0-0.1,0-0.2l1.5,0.1
              c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l1.5,0.1c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.6-0.7,1.7-1.5l2.1,0.1l-0.1,2.5l0,0
              c-0.9,0.1-1.6,0.9-1.6,1.8c0.1,0.8,0.6,1.4,1.4,1.6l0,0l-0.1,1.5l0,0c-0.9-0.1-1.8,0.6-1.9,1.5s0.6,1.8,1.5,1.9
              c0.1,0,0.1,0,0.2,0l0,0l-0.1,1.5l0,0c-0.9-0.1-1.8,0.6-1.9,1.5s0.6,1.8,1.5,1.9c0.1,0,0.1,0,0.2,0l0,0l-0.1,1.5l0,0
              c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.7,1.6,1.5,1.7l0,0l-0.1,1.7l0,0c-0.9-0.1-1.8,0.6-1.9,1.5s0.6,1.8,1.5,1.9c0.1,0,0.1,0,0.2,0
              l0,0l-0.1,2.5l-2.1-0.1c0-0.9-0.7-1.7-1.6-1.8c-0.9,0-1.7,0.7-1.8,1.6l0,0l-1.5-0.1c0.1-0.9-0.6-1.8-1.5-1.9s-1.8,0.6-1.9,1.5
              c0,0.1,0,0.1,0,0.2l-1.5-0.1c0.1-0.9-0.6-1.8-1.5-1.9s-1.8,0.6-1.9,1.5c0,0.1,0,0.1,0,0.2l-1.5-0.1c0.1-0.9-0.6-1.8-1.5-1.9
              s-1.8,0.6-1.9,1.5c0,0.1,0,0.1,0,0.2l-1.7-0.1c0-0.9-0.7-1.7-1.6-1.8s-1.7,0.7-1.8,1.6l0,0l-1.5-0.1c0.1-0.9-0.6-1.8-1.5-1.9
              s-1.8,0.6-1.9,1.5c0,0.1,0,0.1,0,0.2l-1.5-0.1c0.1-0.9-0.6-1.8-1.5-1.9s-1.8,0.6-1.9,1.5c0,0.1,0,0.1,0,0.2l-1.5-0.1
              c0.1-0.9-0.6-1.8-1.5-1.9s-1.8,0.6-1.9,1.5c0,0.1,0,0.1,0,0.2L419,292.6z"/>

            <rect x="429.9" y="262.1" transform="matrix(4.239882e-02 -0.9991 0.9991 4.239882e-02 142.8659 707.8119)" fill="#FBD4A4" width="21.5" height="34.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M430.2,318.8l0.1-2.1l0,0c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0l0,0l0.1-1.4l0,0c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5
              l0,0v-1.2l0,0c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0l0.1-1.2l0,0c0.8,0,1.5-0.6,1.5-1.4l0,0c0-0.8-0.6-1.5-1.3-1.5l0,0l0,0
              v-1.2l0,0c0.8,0,1.5-0.6,1.5-1.4s-0.6-1.5-1.4-1.5l0,0l0,0l0.1-2.1l1.8,0.1c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4h1.2
              c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0l1.2,0.1c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0h1.2c0,0.8,0.6,1.5,1.4,1.5
              s1.5-0.6,1.5-1.4l0,0l1.4,0.1c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4h1.2c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0l1.2,0.1
              c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l0,0h1.2c0,0.8,0.6,1.5,1.4,1.5s1.5-0.6,1.5-1.4l1.8,0.1l-0.1,2.1l0,0
              c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0v1.2l0,0c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0v1.2l0,0
              c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0l0,0v1.2l0,0c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0l-0.1,1.4l0,0
              c-0.8,0-1.5,0.6-1.5,1.4s0.6,1.5,1.4,1.5l0,0l-0.1,2.1L464,320c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4h-1.2
              c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l-1.2-0.1c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4h-1.2c0-0.8-0.6-1.5-1.4-1.5
              s-1.5,0.6-1.5,1.4l-1.4-0.1c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4h-1.2c0-0.8-0.6-1.5-1.4-1.5s-1.5,0.6-1.5,1.4l-1.2-0.1
              c0.1-0.8-0.4-1.5-1.2-1.7s-1.5,0.4-1.7,1.2c0,0.1,0,0.3,0,0.4h-1.2c0-0.8-0.6-1.5-1.3-1.5l0,0c-0.8,0-1.5,0.6-1.5,1.4
              L430.2,318.8z"/>

            <rect x="439.4" y="293.2" transform="matrix(4.239882e-02 -0.9991 0.9991 4.239882e-02 121.9463 742.6035)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="575.2" y="183.7" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 324.4557 794.4524)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="576" y="273" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 235.9616 880.7227)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="576.1" y="262.6" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 246.4443 870.8882)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="577.1" y="251.9" transform="matrix(4.222472e-02 -0.9991 0.9991 4.222472e-02 258.0826 861.5623)" fill="#236095" width="2.7" height="88.6"/>
        </g>
        <g>

          <rect x="609.4" y="265.5" transform="matrix(0.9935 -0.1139 0.1139 0.9935 -31.6797 82.526)" fill="#FFFFFF" width="193.9" height="106"/>
          <path fill="#E6E6E5" d="M616,382.2l70-64c10.1-9.2,27.6-11.5,38.9-5.2l83.8,47.1
            L616,382.2z"/>
        </g>
      </g>
      <g id="letters4">
        <g>
          <polygon fill="#FFFFFF" points="189.4,411 310.2,226.7 174.4,175.6 53.6,360     "/>
          <path fill="#E6E6E5" d="M310.2,226.7L191.1,273c-17.1,6.7-28.5,23.3-25.4,36.9L189.4,411
            L310.2,226.7z"/>
          <g>
            <path fill="#0B99D6" d="M184,307.6c-2.7,4.2-7.5,7.1-12.9,8.4c-5.7,1.4-11.7,1-17.3-1
              c-5.6-2.1-9.6-5.7-11.5-9.8c-2-4.2-1.6-9.1,1-12.9c2.7-4.2,7.5-7.1,12.9-8.4c5.7-1.4,11.7-1,17.3,1c5.6,2.1,9.6,5.7,11.5,9.8
              C187,298.8,186.6,303.7,184,307.6L184,307.6z M172,287.2c-4.6-1.7-9.7-2-14.5-0.8c-4.6,1.1-8.6,3.6-10.9,7.1
              c-2.2,3.2-2.5,7.3-0.9,10.8s4.9,6.5,9.6,8.2c4.6,1.7,9.7,2,14.5,0.8c4.6-1.1,8.6-3.6,10.9-7.1c2.2-3.2,2.5-7.3,0.9-10.9
              C180.1,292,176.7,289,172,287.2z"/>
            <path fill="#0B99D6" d="M172.9,301.2c0,0,14.3-0.8,12.8-8.9c-0.4-2.3-1-4.5-1.7-6.7
              c-0.9-2.8-1-3.2,0.9-5c1-0.9,2.6-1.2,4.6-1.6c3.5-0.7,8.4-1.8,11.6-7c2.6-4.3,1.7-5.7,0.6-7.4c-1.6-2-2.2-4.6-1.9-7.1
              c0.2-3.5,2.3-6.7,5.4-8.3c2.6-1.3,5.5-2.1,8.4-2.2c2.5-0.3,4.9-1.6,6.3-3.7c0.4-0.5,1-0.6,1.6-0.4c0.6,0.2,0.8,0.8,0.6,1.2
              c-1.9,2.7-4.9,4.4-8.2,4.7c-2.5,0.1-5,0.7-7.3,1.8c-2.6,1.4-4.3,4.1-4.5,7.1c-0.3,2.2,0.3,4.4,1.6,6.2c1.4,2.2,2.6,3.9-0.5,9
              c-3.7,5.9-9.1,7.1-13.1,7.9c-1.2,0.1-2.4,0.5-3.5,1.1c-1.2,1-1.1,1.4-0.4,3.4c0.7,2.3,1.3,4.5,1.7,6.9c1.9,10-14.9,11-14.9,11
              c-0.6,0.1-1.1-0.3-1.3-0.8C171.8,301.7,172.3,301.3,172.9,301.2L172.9,301.2z M152.9,293.7c-0.6,0-1.1,0.5-1.1,1
              c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1
              c4-0.8,9.5-2,13.1-7.9c3.1-5.1,2-6.8,0.5-9c-1.4-1.8-1.9-4-1.6-6.2c0.2-3,1.8-5.6,4.5-7.1c2.3-1.1,4.7-1.7,7.3-1.8
              c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.6-1.2s-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.4,2.2
              c-3.1,1.7-5.1,4.8-5.3,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.7,2.1,3.2-0.5,7.4c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7
              c-1.9,1.7-1.8,2.2-0.9,4.9c0.7,2.2,1.2,4.4,1.7,6.7C167.3,293,153.1,293.8,152.9,293.7L152.9,293.7z M157.9,295.6
              c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8c0.1,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4
              c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,1.9-6.8,0.5-9c-1.3-1.7-1.9-4-1.6-6.1c0.2-3,1.8-5.6,4.4-7.1
              c2.3-1.1,4.8-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.6-1.2s-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7
              c-2.9,0.1-5.8,0.9-8.4,2.2c-3.1,1.7-5.2,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.5c-3.2,5.2-8.1,6.2-11.6,7
              c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7C172.2,294.9,158,295.7,157.9,295.6L157.9,295.6z
               M162.9,297.5c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9
              c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5.1,1.9-6.8,0.5-9c-1.4-1.7-1.9-4-1.6-6.2
              c0.2-3,1.8-5.6,4.4-7.1c2.3-1.1,4.8-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0.1-1-0.6-1.2c-0.6-0.2-1.2-0.1-1.6,0.4
              c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1c1.1,1.7,2.1,3.2-0.5,7.4
              c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,4.9c0.7,2.2,1.2,4.4,1.7,6.7
              C177.2,296.7,163,297.5,162.9,297.5L162.9,297.5z M166.8,300.3c0.2,0.6,0.7,0.9,1.3,0.8c0.1,0,16.8-1,14.9-10.9
              c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,2-6.8,0.5-9
              c-1.4-1.7-1.9-4-1.6-6.1c0.2-3,1.8-5.7,4.4-7.1c2.3-1.1,4.7-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.6-1.2
              s-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.4,2.2c-3.1,1.7-5.2,4.8-5.4,8.3c-0.4,2.5,0.3,5.1,1.9,7.1
              c1.1,1.8,2.1,3.2-0.6,7.5c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5c0.7,2.2,1.2,4.4,1.7,6.7
              c1.5,8.1-12.7,8.9-12.8,8.9C167.3,299.4,166.8,299.8,166.8,300.3L166.8,300.3z"/>
          </g>
        </g>
        <g>
          <path fill="#A0D8F7" d="M445.9,365.8l54.2-93.2l-170.5-99.1l-54.2,93.2L445.9,365.8z
             M284.4,264.5l47.7-82.1L491,274.8l-47.7,82.1L284.4,264.5z"/>

          <rect x="338.3" y="179.2" transform="matrix(0.5026 -0.8645 0.8645 0.5026 -42.1987 467.826)" fill="#CCCCCE" width="94.4" height="182.8"/>
          <g>
            <polygon fill="#0B99D6" points="438.4,361.4 456.7,347.2 447.6,362.8 444.7,365.1
              "/>
            <path fill="#0B99D6" d="M425.9,354.1l6.3,3.6l5.5-4.2l-6.3-3.6L425.9,354.1L425.9,354.1
              z M451.6,342.5l14.2-11.1l9.2-15.7l-14.2,11.1L451.6,342.5z"/>
            <path fill="#0B99D6" d="M413.3,346.8l6.3,3.6l5.5-4.3l-6.3-3.6L413.3,346.8L413.3,346.8
              z M469.9,311.2l14.2-11.1l9.1-15.7L479,295.5L469.9,311.2z"/>
            <path fill="#0B99D6" d="M400.7,339.5l6.3,3.6l5.5-4.3l-6.3-3.6L400.7,339.5z M491,274.8
              l-2.9,5l10.4-8.1l-6.3-3.6l-5.5,4.2L491,274.8z"/>
            <path fill="#0B99D6" d="M388.1,332.2l6.3,3.6l5.5-4.3l-6.3-3.6L388.1,332.2L388.1,332.2
              z M480.5,268.6l5.5-4.2l-6.3-3.6l-5.4,4.3L480.5,268.6z"/>
            <path fill="#0B99D6" d="M375.6,324.9l6.3,3.6l5.5-4.3l-6.3-3.6L375.6,324.9L375.6,324.9
              z M467.9,261.3l5.5-4.3l-6.3-3.6l-5.5,4.3L467.9,261.3z"/>
            <path fill="#0B99D6" d="M363,317.6l6.3,3.6l5.5-4.3l-6.3-3.6L363,317.6z M455.3,254
              l5.5-4.3l-6.3-3.6l-5.5,4.3L455.3,254z"/>
            <path fill="#0B99D6" d="M350.5,310.3l6.3,3.6l5.5-4.2L356,306L350.5,310.3L350.5,310.3z
               M442.8,246.7l5.5-4.3l-6.2-3.7l-5.5,4.3L442.8,246.7z"/>
            <path fill="#0B99D6" d="M337.9,303l6.3,3.6l5.5-4.2l-6.3-3.6L337.9,303z M430.2,239.4
              l5.5-4.3l-6.3-3.6l-5.5,4.3L430.2,239.4z"/>
            <path fill="#0B99D6" d="M325.3,295.7l6.3,3.6l5.5-4.3l-6.3-3.6L325.3,295.7z
               M417.6,232.1l5.5-4.3l-6.3-3.6l-5.5,4.2L417.6,232.1z"/>
            <path fill="#0B99D6" d="M312.8,288.4l6.2,3.6l5.5-4.3l-6.3-3.6L312.8,288.4L312.8,288.4
              z M405.1,224.8l5.5-4.2l-6.3-3.6l-5.5,4.3L405.1,224.8z"/>
            <path fill="#0B99D6" d="M300.2,281l6.3,3.6l5.5-4.3l-6.3-3.6L300.2,281z M392.5,217.5
              l5.5-4.3l-6.3-3.6l-5.5,4.3L392.5,217.5z"/>
            <path fill="#0B99D6" d="M287.6,273.7l6.3,3.6l5.5-4.3l-6.3-3.6L287.6,273.7z
               M379.9,210.2l5.5-4.3l-6.3-3.6l-5.5,4.3L379.9,210.2z"/>
            <path fill="#0B99D6" d="M275.9,265.7l-0.5,0.9l5.9,3.4l5.5-4.3l-2.4-1.4l5.7-9.8
              L275.9,265.7z M367.4,202.9l5.5-4.3l-6.3-3.6l-5.5,4.3L367.4,202.9z"/>
            <path fill="#0B99D6" d="M294.2,234.4l-9.1,15.6l14.2-11.1l9.1-15.7L294.2,234.4
              L294.2,234.4z M354.8,195.6l5.5-4.3l-6.3-3.7l-5.5,4.3L354.8,195.6z"/>
            <path fill="#0B99D6" d="M312.4,203l-9.1,15.7l14.2-11.1l9.1-15.7L312.4,203z
               M342.2,188.3l5.5-4.3l-6.3-3.6l-5.4,4.2L342.2,188.3z"/>
            <polygon fill="#0B99D6" points="335.1,176.7 321.6,187.3 329.6,173.5       "/>
          </g>
        </g>
        <g>

          <rect x="715.5" y="205.1" transform="matrix(0.9856 -0.1692 0.1692 0.9856 -31.8054 140.8439)" fill="#96CBCC" width="190" height="103.9"/>
          <path fill="#8BB3B2" d="M708.1,221.9l83.4,40.9c12,5.9,29.1,3.3,38-5.8l65.9-67.3
            L708.1,221.9z"/>
          <g>
            <path fill="#0B99D6" d="M820.3,248.2c-8.5,1.5-14.1,9.5-12.7,17.9
              c1.5,8.5,9.5,14.1,17.9,12.7c8.5-1.5,14.1-9.5,12.7-17.9c-0.7-4.1-3-7.7-6.3-10.1C828.5,248.4,824.4,247.5,820.3,248.2z
               M812.3,255.9c4.2-5.9,12.3-7.3,18.2-3.1c5.9,4.2,7.3,12.3,3.1,18.2c-4.2,5.9-12.3,7.3-18.2,3.1c-2.8-2-4.7-5-5.3-8.4
              C809.5,262.3,810.3,258.8,812.3,255.9L812.3,255.9z"/>
            <path fill="#0B99D6" d="M820.1,257.7c0.3,0.3,0.8,0.4,1.2,0.1l0,0
              c0.3-0.3,0.4-0.8,0.1-1.1l0,0c0,0-7.6-8.8-14.3-2.4c-2.2,2.1-3.5,3.6-4.4,4.6c-1.3,1.4-1.5,1.7-2.7,1.6c-0.8-0.3-1.6-0.7-2.2-1.3
              c-2.2-1.8-5.4-4.2-11.3-3c-5.1,1-5.9,2.6-6.9,4.5c-0.8,1.8-2.2,3.3-3.9,4.2c-2.9,1.8-5.2,1.9-7.1,1.3c-1.7-0.6-3.2-1.6-4.3-3
              c-2.5-2.8-6.9-2-6.9-2c-0.4,0.1-0.7,0.5-0.7,0.9l0,0c0.1,0.5,0.5,0.8,1,0.7l0,0c0,0,3.5-0.6,5.4,1.5c1.3,1.6,3.1,2.8,5.1,3.5
              c2.3,0.7,5.1,0.5,8.4-1.5c2-1.1,3.6-2.8,4.5-4.8c0.8-1.6,1.5-2.9,5.8-3.7c5.2-1,8,1.1,10,2.7c0.9,0.8,2,1.4,3.1,1.7
              c2.1,0.1,2.3-0.2,4.1-2.1c0.9-1,2.1-2.4,4.3-4.5C813.7,250.3,820.2,257.8,820.1,257.7L820.1,257.7z M822.7,272.7
              c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5c-1.7,2-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7
              c-4.3,0.8-5,2.1-5.8,3.7c-0.9,2.1-2.5,3.8-4.5,4.9c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5
              c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.6-0.9l0,0c0,0,4.4-0.8,6.9,2c1.2,1.4,2.7,2.4,4.3,3c1.9,0.6,4.2,0.4,7.1-1.3
              c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6
              c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.2,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2l0,0C823.6,273.1,823.1,273,822.7,272.7
              C822.8,272.7,822.8,272.7,822.7,272.7L822.7,272.7z M822,269c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5
              c-1.7,2-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-1,2.1-2.5,3.7-4.5,4.8
              c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.7-1
              c0,0,4.3-0.8,6.9,2c1.2,1.4,2.7,2.4,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5
              c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.3,2.4,14.3,2.4
              c0.3,0.3,0.3,0.9-0.1,1.2C822.8,269.3,822.3,269.2,822,269L822,269z M821.4,265.2c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5
              c-1.7,2-2,2.3-4.1,2.1c-1.2-0.2-2.2-0.8-3.1-1.6c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-1,2.1-2.6,3.7-4.5,4.8
              c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.6l0,0c-0.1-0.4,0.2-0.9,0.6-1
              h0.1c0,0,4.4-0.8,6.9,2c1.2,1.4,2.7,2.4,4.3,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5
              c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.2,2.4,14.3,2.4
              c0.3,0.4,0.2,0.9-0.1,1.2C822.1,265.5,821.7,265.5,821.4,265.2L821.4,265.2z M820.8,261.4c0,0-6.5-7.5-11.9-2.3
              c-2.2,2.1-3.5,3.5-4.3,4.5c-1.7,2-2,2.3-4.1,2.1c-1.2-0.2-2.3-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7
              c-1,2.1-2.5,3.8-4.5,4.9c-3.4,2-6.2,2.2-8.4,1.5c-2-0.7-3.7-1.9-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-0.9-0.7
              s0.2-0.9,0.7-0.9c0,0,4.3-0.8,6.9,2c1.2,1.4,2.7,2.4,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c1.7-1,3.1-2.4,3.9-4.2c1-2,1.9-3.5,6.9-4.5
              c5.9-1.2,9,1.3,11.3,3c0.6,0.6,1.4,1.1,2.2,1.3c1.2,0.1,1.5-0.1,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.2,2.4,14.3,2.4
              c0.3,0.3,0.3,0.9-0.1,1.2C821.6,261.8,821,261.7,820.8,261.4L820.8,261.4z"/>
          </g>
        </g>
        <g>

          <rect x="754.6" y="214.2" transform="matrix(0.9726 -0.2324 0.2324 0.9726 -38.8492 205.386)" fill="#A3DAF7" width="195.3" height="106.7"/>
          <path fill="#80CAF0" d="M934.8,193l-62.3,72.4c-9,10.4-26.2,14.9-38.2,9.9l-89.5-36.9
            L934.8,193z"/>
          <g>
            <path fill="#0B99D6" d="M839.5,263.2c8.6-2.1,17.2,3.2,19.3,11.8
              c2.1,8.6-3.2,17.2-11.8,19.3c-8.6,2.1-17.2-3.2-19.3-11.8c-1-4.1-0.3-8.5,1.9-12.1C831.8,266.8,835.3,264.2,839.5,263.2z
               M850.2,267.3c-6.3-3.9-14.6-2-18.5,4.4c-3.9,6.3-2,14.6,4.4,18.5c6.3,3.9,14.6,2,18.5-4.4c1.9-3,2.5-6.7,1.6-10.2
              C855.4,272.2,853.2,269.2,850.2,267.3L850.2,267.3z"/>
            <path fill="#0B99D6" d="M843.5,272.2c-0.1,0.4-0.6,0.7-1,0.5l0,0
              c-0.4-0.1-0.7-0.6-0.5-1.1l0,0c0,0,3.6-11.4,12.5-8c3,1.1,4.8,2,6,2.6c1.8,0.8,2.1,1,3.2,0.4c0.7-0.6,1.2-1.3,1.6-2.2
              c1.4-2.6,3.4-6.1,9.4-7.4c5.2-1.1,6.6,0,8.4,1.5c1.5,1.3,3.4,2.2,5.4,2.4c3.5,0.5,5.7-0.3,7.3-1.6c1.4-1.3,2.4-2.9,2.9-4.6
              c1.2-3.6,5.7-4.7,5.7-4.7c0.4-0.1,0.9,0.2,1,0.6c0.1,0.4-0.2,0.9-0.6,1c0,0-3.5,0.8-4.5,3.6c-0.6,2.1-1.8,3.9-3.4,5.4
              c-1.9,1.6-4.6,2.6-8.6,2c-2.3-0.2-4.5-1.2-6.2-2.7c-1.4-1.1-2.6-2.1-7-1.1c-5.3,1.1-7.1,4.3-8.3,6.6c-0.5,1.1-1.3,2.1-2.3,2.9
              c-1.9,1-2.3,0.8-4.7-0.4c-1.2-0.6-3-1.4-5.9-2.5C846.5,262.5,843.4,272.1,843.5,272.2L843.5,272.2z M847.1,287.4
              c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,2,5.9,2.5c2.4,1.1,2.8,1.4,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.7,1.5,3.9,2.5,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c0.9-2.8,4.5-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.4-0.5-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.9-9.5,7.4c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.5,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.2,0.4,0,0.9,0.4,1.1C846.4,288.1,846.9,287.9,847.1,287.4
              C847.2,287.5,847.2,287.4,847.1,287.4L847.1,287.4z M846.2,283.6c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5
              c2.4,1.1,2.8,1.4,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.1c1.7,1.6,3.9,2.5,6.2,2.8
              c4,0.6,6.7-0.4,8.6-2c1.6-1.5,2.8-3.3,3.4-5.4c0.9-2.8,4.5-3.6,4.5-3.6c0.5-0.1,0.7-0.6,0.6-1s-0.6-0.7-1-0.6c0,0-4.4,1-5.7,4.7
              c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.9-9.5,7.4
              c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.5,0.2,0.9,0.6,1
              C845.7,284.2,846.1,284,846.2,283.6L846.2,283.6z M845.3,279.8c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5
              c2.4,1.1,2.8,1.3,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.1c1.7,1.5,3.9,2.5,6.2,2.7
              c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c1-2.8,4.5-3.6,4.5-3.6c0.5-0.1,0.7-0.6,0.6-1s-0.6-0.7-1-0.6c0,0-4.4,1-5.7,4.7
              c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.5,7.4
              c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.5-3.2-0.4c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.4,0.1,0.9,0.5,1.1
              l0,0C844.8,280.4,845.3,280.2,845.3,279.8L845.3,279.8z M844.4,276c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,2,5.9,2.5
              c2.4,1.1,2.8,1.3,4.7,0.4c1-0.7,1.8-1.7,2.3-2.9c1.3-2.3,3-5.4,8.3-6.6c4.4-0.9,5.5,0,7,1.1c1.7,1.5,3.9,2.5,6.2,2.7
              c4,0.6,6.7-0.4,8.6-2c1.6-1.4,2.8-3.3,3.4-5.4c1-2.8,4.5-3.6,4.5-3.6c0.4-0.1,0.7-0.6,0.6-1.1c-0.1-0.4-0.5-0.7-0.9-0.6
              c0,0-4.4,1.1-5.7,4.7c-0.5,1.8-1.5,3.4-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6c-2-0.2-3.9-1-5.5-2.3c-1.8-1.4-3.2-2.6-8.4-1.5
              c-6.1,1.3-8,4.9-9.5,7.4c-0.3,0.8-0.9,1.6-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8
              c-0.2,0.4,0,0.9,0.4,1.1s0.9,0,1.1-0.4C844.4,276.1,844.4,276,844.4,276L844.4,276z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M862.7,302l-3.4-101.2l185-6.3l3.4,101.2L862.7,302L862.7,302z
                 M1041.2,289.9l-3-89.1l-172.5,5.9l3,89.1L1041.2,289.9z"/>

              <rect x="866.7" y="207.5" transform="matrix(0.9996 -2.998502e-02 2.998502e-02 0.9996 -7.1311 28.6905)" fill="#FFFFFF" width="172.6" height="89.2"/>
              <g>
                <polygon fill="#0B99D6" points="870.8,301.7 862,281.7 862.5,298.7 864,301.9
                  "/>
                <path fill="#0B99D6" d="M884.4,301.2l-6.8,0.2l-2.6-5.9l6.8-0.2L884.4,301.2
                  L884.4,301.2z M868.2,280.1l-6.8-15.5l-0.6-17l6.8,15.5L868.2,280.1z"/>
                <path fill="#0B99D6" d="M898,300.8l-6.8,0.2l-2.6-6l6.8-0.2L898,300.8L898,300.8z
                   M867,246.1l-6.8-15.5l-0.6-17l6.8,15.5L867,246.1z"/>
                <path fill="#0B99D6" d="M911.7,300.3l-6.8,0.2l-2.6-5.9l6.8-0.2L911.7,300.3z
                   M865.7,206.6l0.2,5.4l-5-11.3l6.8-0.2l2.6,5.9L865.7,206.6z"/>
                <path fill="#0B99D6" d="M925.3,299.8l-6.8,0.2l-2.6-5.9l6.8-0.2L925.3,299.8z
                   M877.2,206.2l-2.6-5.9l6.8-0.2l2.6,5.9L877.2,206.2z"/>
                <path fill="#0B99D6" d="M939,299.4l-6.8,0.2l-2.6-6l6.8-0.2L939,299.4z M890.9,205.8
                  l-2.6-5.9l6.8-0.2l2.6,5.9L890.9,205.8z"/>
                <path fill="#0B99D6" d="M952.6,298.9l-6.8,0.2l-2.6-6l6.8-0.2L952.6,298.9
                  L952.6,298.9z M904.5,205.3l-2.6-6l6.8-0.2l2.6,5.9L904.5,205.3z"/>
                <path fill="#0B99D6" d="M966.2,298.4l-6.8,0.2l-2.6-5.9l6.8-0.2L966.2,298.4z
                   M918.1,204.8l-2.6-5.9l6.8-0.2l2.6,5.9L918.1,204.8z"/>
                <path fill="#0B99D6" d="M979.9,298l-6.8,0.2l-2.6-5.9l6.8-0.2L979.9,298z
                   M931.8,204.4l-2.6-5.9l6.8-0.2l2.6,5.9L931.8,204.4z"/>
                <path fill="#0B99D6" d="M993.5,297.5l-6.8,0.2l-2.6-6l6.8-0.2L993.5,297.5z
                   M945.4,203.9l-2.6-5.9l6.8-0.2l2.6,6L945.4,203.9z"/>
                <path fill="#0B99D6" d="M1007.2,297l-6.8,0.2l-2.6-6l6.8-0.2L1007.2,297L1007.2,297z
                   M959,203.4l-2.6-5.9l6.8-0.2l2.6,5.9L959,203.4z"/>
                <path fill="#0B99D6" d="M1020.8,296.6l-6.8,0.2l-2.6-5.9l6.8-0.2L1020.8,296.6z
                   M972.7,203l-2.6-6l6.8-0.2l2.6,5.9L972.7,203z"/>
                <path fill="#0B99D6" d="M1034.5,296.1l-6.8,0.2l-2.6-5.9l6.8-0.2L1034.5,296.1
                  L1034.5,296.1z M986.3,202.5l-2.6-5.9l6.8-0.2l2.6,5.9L986.3,202.5z"/>
                <path fill="#0B99D6" d="M1047.7,294.7v1l-6.4,0.2l-2.6-6l2.6-0.1l-0.4-10.6
                  L1047.7,294.7L1047.7,294.7z M1000,202.1l-2.6-5.9l6.8-0.2l2.6,6L1000,202.1z"/>
                <path fill="#0B99D6" d="M1046.5,260.6l0.6,17l-6.8-15.5l-0.6-17L1046.5,260.6
                  L1046.5,260.6z M1013.6,201.6l-2.6-5.9l6.8-0.2l2.6,5.9L1013.6,201.6z"/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M983.6,277.4c-6.4,0.2-11.9-4.8-12.1-11.3
                c-0.2-6.4,4.8-11.9,11.3-12.1c6.4-0.2,11.9,4.8,12.1,11.3c0.1,3.1-1,6.1-3.1,8.4C989.6,275.9,986.7,277.2,983.6,277.4z
                 M993,265.4c-0.2-5.4-4.7-9.7-10.1-9.5c-5.4,0.2-9.7,4.7-9.5,10.1s4.7,9.7,10.1,9.5c2.6-0.1,5.1-1.2,6.9-3.1
                C992.2,270.5,993.1,267.9,993,265.4L993,265.4z"/>
              <path fill="#80CAF0" d="M984.7,270.3c0,0,4.1,6.2,8.6,2.9c1.9-1.3,3-2.3,3.7-2.9
                c1.5-1.3,1.7-1.5,3.2-1.2c0.9,0.3,1.6,0.8,2.2,1.6c1.7,2.1,4.4,3.2,7.2,3c3.3-0.2,3.9-1.1,4.7-2.2c0.9-1.4,2.3-2.5,3.9-3.1
                c2-1,4.4-1.1,6.4-0.2c1.4,0.7,2.6,1.8,3.4,3.1c1.2,1.8,3.8,1.7,3.8,1.7c0.3,0,0.6,0.2,0.6,0.6l0,0c0,0.3-0.3,0.6-0.6,0.6l0,0
                c0,0-3.3,0.1-4.9-2.2c-0.7-1.1-1.7-2.1-2.9-2.7c-1.8-0.7-3.8-0.6-5.4,0.3c-1.4,0.5-2.6,1.5-3.4,2.7c-1,1.4-1.8,2.5-5.6,2.7
                c-3.1,0.3-6.1-1-8.1-3.4c-0.4-0.5-0.9-0.9-1.5-1.2c-0.9-0.2-1.1,0-2.2,0.9c-0.8,0.6-1.9,1.6-3.8,3c-5.6,4.1-10.4-3.2-10.4-3.2
                c-0.2-0.3-0.1-0.7,0.2-0.8l0,0C984.2,269.9,984.5,270,984.7,270.3L984.7,270.3z M984.3,258.8c-0.1-0.3-0.5-0.4-0.8-0.3
                s-0.4,0.5-0.3,0.8c0,0.1,0.1,0.1,0.1,0.2c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9
                c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.4-2.7c1.7-0.9,3.7-1,5.4-0.3
                c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.4,0.5-0.7c0-0.3-0.3-0.5-0.5-0.5c0,0-2.6,0.1-3.8-1.7
                c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.2c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.2-3
                c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.2,1.2c-0.7,0.6-1.8,1.6-3.7,2.9C988.5,265,984.4,258.8,984.3,258.8L984.3,258.8z
                 M984.4,261.7c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3
                c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.4-2.7
                c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6l0,0
                c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.3c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2
                c-2.7,0.2-5.4-0.9-7.1-3c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.2,1.2c-0.7,0.6-1.8,1.6-3.7,2.9
                C988.6,267.9,984.5,261.7,984.4,261.7L984.4,261.7z M984.5,264.5c-0.2-0.3-0.6-0.4-0.8-0.2l0,0c-0.3,0.2-0.4,0.6-0.2,0.9
                c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4
                c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.3-2.7c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2
                c0.3,0,0.6-0.3,0.6-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6l0,0c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.2
                c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.1-3c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.2,1.2
                c-0.7,0.6-1.8,1.6-3.7,2.9C988.6,270.8,984.5,264.6,984.5,264.5L984.5,264.5z M983.8,267.2c-0.3,0.2-0.4,0.6-0.2,0.9l0,0
                c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.6,0.3,1.1,0.7,1.5,1.2c2,2.4,5,3.7,8.1,3.4
                c3.9-0.2,4.7-1.3,5.6-2.7c0.8-1.2,2-2.2,3.4-2.7c1.7-0.9,3.7-1,5.4-0.3c1.2,0.6,2.2,1.6,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2
                c0.3,0,0.6-0.3,0.6-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6l0,0c0,0-2.6,0.1-3.8-1.7c-0.8-1.3-2-2.4-3.4-3.1c-2.1-0.9-4.4-0.8-6.4,0.3
                c-1.6,0.6-2.9,1.7-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-2.7,0.2-5.4-0.9-7.2-3c-0.6-0.7-1.3-1.3-2.2-1.6c-1.5-0.3-1.8-0.1-3.3,1.2
                c-0.7,0.6-1.8,1.6-3.7,2.9c-4.6,3.3-8.6-2.9-8.6-2.9C984.4,267.1,984.1,267.1,983.8,267.2L983.8,267.2z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M905,242.2l0.5-2.1l0,0c-0.8-0.1-1.3-0.9-1.2-1.7
              s0.9-1.3,1.7-1.2c0.1,0,0.1,0,0.2,0l0,0l0.3-1.4l0,0c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,1-1.2,1.7-1.1l0,0l0.3-1.2l0,0
              c-0.8-0.2-1.2-0.9-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.3-1.2l0,0c-0.8-0.2-1.2-1-1-1.8c0.2-0.7,0.9-1.2,1.6-1l0,0l0.3-1.2l0,0
              c-0.8-0.2-1.2-0.9-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.5-2.1l-1.7-0.4c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.1,0.8-0.9,1.3-1.7,1.2s-1.3-0.9-1.2-1.7c0-0.1,0-0.1,0-0.2l-1.4-0.3c-0.1,0.8-0.9,1.3-1.7,1.2s-1.3-0.9-1.2-1.7
              c0-0.1,0-0.1,0-0.2l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.2-0.3c-0.2,0.8-1,1.2-1.7,1.1
              c-0.8-0.2-1.2-1-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.7-0.4l-0.5,2.1l0,0
              c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.3,1.2l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0
              l-0.3,1.2l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.3,1.2l0,0c0.8,0.1,1.3,0.9,1.2,1.7s-0.9,1.3-1.7,1.2
              c-0.1,0-0.1,0-0.2,0l0,0l-0.3,1.4l0,0c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l0,0l-0.5,2.1l1.7,0.4
              c0.1-0.8,0.9-1.3,1.7-1.2s1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,1,1.1,1.7l0,0l1.2,0.3
              c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l0,0l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l0,0l1.4,0.3
              c0.1-0.8,0.9-1.3,1.7-1.2s1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2l1.2,0.3c0.1-0.8,0.9-1.3,1.7-1.2s1.3,0.9,1.2,1.7c0,0.1,0,0.1,0,0.2
              l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,1,1.1,1.7l0,0l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l0,0
              L905,242.2z"/>

            <rect x="881.5" y="212.2" transform="matrix(0.2278 -0.9737 0.9737 0.2278 466.9797 1042.1597)" fill="#A0D8F7" width="18" height="28.9"/>
          </g>
        </g>
        <g>

          <rect x="168" y="228.3" transform="matrix(0.5255 -0.8508 0.8508 0.5255 -177.0686 350.6174)" fill="#96CBCC" width="115.6" height="211.5"/>
          <path fill="#8BB3B2" d="M166.2,229.3l39.5,95.6c5.7,13.8,21.9,24.2,36,23.1l104.5-7.6
            L166.2,229.3z"/>
          <g>
            <path fill="#0B99D6" d="M240.4,333.9c8.1,5,10.7,15.7,5.6,23.8
              c-5,8.1-15.7,10.7-23.8,5.6c-8.1-5-10.7-15.7-5.6-23.8c2.4-3.9,6.3-6.7,10.7-7.7C231.8,330.7,236.5,331.5,240.4,333.9z M219,341
              c-4.2,6.8-2.1,15.8,4.7,20s15.8,2.1,20-4.7c4.2-6.8,2.1-15.8-4.7-20c-3.3-2-7.2-2.7-11-1.8C224.3,335.4,221,337.7,219,341z"/>
            <path fill="#0B99D6" d="M233.2,341.7c0,0,0.1-11-8.2-10.7c-3.4,0.1-5.5,0.4-6.9,0.6
              c-2.9,0.4-3.4,0.4-5-1.2c-0.9-0.9-1.1-2.1-1.4-3.7c-0.5-2.8-1.2-6.6-6.3-9.6c-4.2-2.5-5.7-1.9-7.6-1.2c-2.3,1-4.9,1.2-7.4,0.7
              c-4.3-0.8-6.8-2.7-8.1-5c-1.1-2-1.7-4.3-1.7-6.7c0-3.2-3.4-5.2-3.4-5.2c-0.4-0.3-0.6-0.8-0.3-1.3s0.8-0.6,1.3-0.3l0,0
              c0,0,4.2,2.6,4.2,6.8c-0.1,2,0.4,4,1.4,5.7c1.1,1.9,3.2,3.5,6.9,4.1c2.1,0.5,4.4,0.3,6.4-0.6c2.3-0.9,4.2-1.6,9.1,1.3
              c5.8,3.4,6.6,7.7,7.2,10.8c0.1,1,0.4,1.9,0.9,2.8c1,1,1.3,1,3.4,0.7c1.5-0.2,3.6-0.5,7.1-0.6c10.3-0.4,10.2,12.5,10.2,12.5
              c0,0.5-0.4,0.9-0.9,0.9C233.6,342.6,233.2,342.2,233.2,341.7L233.2,341.7z M224.3,356.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
              c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7c-0.1-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5
              c2.5,0.5,5,0.3,7.4-0.7c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2
              c1.4-0.2,3.6-0.4,6.9-0.6C224.4,345.2,224.3,356.1,224.3,356.1L224.3,356.1z M226.5,352.5c0.1,0.5,0.5,0.9,1,0.8
              c0.4,0,0.8-0.4,0.8-0.8c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.8-0.8-1.8-0.9-2.8
              c-0.6-3.1-1.4-7.4-7.2-10.8c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7
              c-0.1-4.2-4.2-6.7-4.2-6.8c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3c0,0,3.3,2,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7
              c1.4,2.2,3.8,4.2,8.1,5c2.5,0.5,5,0.3,7.4-0.7c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7
              c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6C226.6,341.5,226.5,352.5,226.5,352.5L226.5,352.5z M228.8,348.9
              c-0.1,0.5,0.3,1,0.8,1c0.5,0.1,1-0.3,1-0.8c0-0.1,0-0.1,0-0.2c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6
              c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6
              c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7c0-4.2-4.2-6.8-4.2-6.8c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3l0,0
              c0,0,3.3,2.1,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5c2.5,0.6,5,0.3,7.4-0.7c1.9-0.7,3.4-1.3,7.6,1.2
              c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6
              C228.8,337.9,228.7,348.9,228.8,348.9L228.8,348.9z M231.9,346.2c0.5,0,0.9-0.4,0.9-0.9l0,0c0,0,0.1-12.9-10.2-12.5
              c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.8-0.8-1.8-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-2,0.9-4.2,1.1-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1-1.8-1.5-3.7-1.4-5.7c-0.1-4.2-4.2-6.8-4.2-6.8
              c-0.4-0.3-1-0.2-1.3,0.3s-0.2,1,0.3,1.3l0,0c0,0,3.3,2.1,3.4,5.2c-0.1,2.3,0.5,4.6,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5
              c2.5,0.5,5,0.3,7.4-0.7c1.9-0.7,3.4-1.3,7.6,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2
              c1.4-0.2,3.5-0.4,6.9-0.6c8.4-0.3,8.2,10.6,8.2,10.7C231,345.8,231.4,346.2,231.9,346.2L231.9,346.2z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M422,395.8l-93.9,37.7l-69-171.8L353,224L422,395.8L422,395.8z
               M349.8,232l-82.7,33.2l64.3,160.1l82.7-33.2L349.8,232z"/>

            <rect x="296" y="242.4" transform="matrix(0.9279 -0.3728 0.3728 0.9279 -97.9988 150.6503)" fill="#D1CCE6" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#0B99D6" points="419,388.3 403.3,403.3 419,397 421.6,394.6         "/>
              <path fill="#0B99D6" d="M413.9,375.6l2.5,6.3l-4.7,4.5l-2.5-6.3L413.9,375.6z
                 M399.7,398l-12.2,11.7l-15.8,6.3l12.2-11.7L399.7,398z"/>
              <path fill="#0B99D6" d="M408.8,362.9l2.5,6.3l-4.7,4.5l-2.5-6.3L408.8,362.9z
                 M368,410.7l-12.2,11.7l-15.8,6.3l12.3-11.7L368,410.7z"/>
              <path fill="#0B99D6" d="M403.8,350.3l2.5,6.3l-4.7,4.5l-2.5-6.3L403.8,350.3z
                 M331.4,425.4l5-2l-8.9,8.5l-2.5-6.4l4.7-4.5L331.4,425.4z"/>
              <path fill="#0B99D6" d="M398.7,337.6l2.5,6.3l-4.7,4.5l-2.5-6.3L398.7,337.6
                L398.7,337.6z M327.1,414.8l-4.7,4.5l-2.5-6.3l4.7-4.5L327.1,414.8z"/>
              <path fill="#0B99D6" d="M393.6,324.9l2.5,6.3l-4.7,4.5l-2.5-6.3L393.6,324.9z
                 M322,402.1l-4.7,4.5l-2.5-6.3l4.7-4.5L322,402.1z"/>
              <path fill="#0B99D6" d="M388.5,312.3l2.5,6.3l-4.7,4.5l-2.5-6.3L388.5,312.3z
                 M316.9,389.5l-4.7,4.5l-2.5-6.3l4.7-4.5L316.9,389.5z"/>
              <path fill="#0B99D6" d="M383.4,299.6l2.5,6.3l-4.7,4.5l-2.5-6.3L383.4,299.6z
                 M311.8,376.8l-4.7,4.5l-2.5-6.3l4.7-4.5L311.8,376.8z"/>
              <path fill="#0B99D6" d="M378.3,286.9l2.5,6.3l-4.7,4.5l-2.5-6.3L378.3,286.9
                L378.3,286.9z M306.8,364.1l-4.7,4.5l-2.5-6.3l4.7-4.5L306.8,364.1z"/>
              <path fill="#0B99D6" d="M373.2,274.3l2.5,6.3l-4.7,4.5l-2.5-6.3L373.2,274.3z
                 M301.7,351.5L297,356l-2.5-6.3l4.7-4.5L301.7,351.5z"/>
              <path fill="#0B99D6" d="M368.1,261.6l2.5,6.3l-4.7,4.5l-2.5-6.3L368.1,261.6z
                 M296.6,338.8l-4.7,4.5l-2.5-6.3l4.7-4.5L296.6,338.8z"/>
              <path fill="#0B99D6" d="M363,248.9l2.5,6.3l-4.7,4.5l-2.5-6.3L363,248.9L363,248.9z
                 M291.5,326.1l-4.7,4.5l-2.5-6.3l4.7-4.5L291.5,326.1z"/>
              <path fill="#0B99D6" d="M358,236.3l2.5,6.3l-4.7,4.5l-2.5-6.3L358,236.3z M286.4,313.5
                l-4.7,4.5l-2.5-6.3l4.7-4.5L286.4,313.5z"/>
              <path fill="#0B99D6" d="M352.1,224.3l0.9-0.3l2.4,6l-4.7,4.5l-1-2.4l-9.9,4l12.2-11.7
                L352.1,224.3z M281.3,300.8l-4.7,4.5l-2.5-6.3l4.7-4.5L281.3,300.8z"/>
              <path fill="#0B99D6" d="M320.5,237l15.8-6.3L324,242.4l-15.8,6.3L320.5,237z
                 M276.2,288.1l-4.7,4.5l-2.5-6.3l4.7-4.5L276.2,288.1z"/>
              <path fill="#0B99D6" d="M288.9,249.7l15.8-6.3l-12.2,11.7l-15.8,6.4L288.9,249.7
                L288.9,249.7z M271.1,275.5l-4.7,4.5l-2.5-6.3l4.7-4.5L271.1,275.5z"/>
              <polygon fill="#0B99D6" points="261.4,267.3 273,256.1 259.1,261.7         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M357.6,290.5c2.4,6-0.5,12.7-6.5,15.1s-12.7-0.5-15.1-6.5
              s0.5-12.7,6.5-15.1c2.9-1.1,6.1-1.1,8.9,0.1C354.3,285.3,356.5,287.6,357.6,290.5z M343.2,285.7c-5,2-7.5,7.7-5.4,12.8
              s7.7,7.5,12.8,5.4s7.5-7.7,5.4-12.8c-1-2.4-2.9-4.3-5.2-5.4C348.3,284.8,345.6,284.7,343.2,285.7L343.2,285.7z"/>
            <path fill="#80CAF0" d="M350.6,291.8c0,0,4.5-5.9-0.2-9.1c-1.9-1.3-3.1-2-4-2.5
              c-1.7-1-2-1.1-2.2-2.7c0-0.9,0.2-1.8,0.7-2.6c1.4-2.4,1.6-5.3,0.4-7.8c-1.3-3-2.3-3.3-3.6-3.7c-1.7-0.4-3.2-1.3-4.3-2.6
              c-2-2.2-2.6-4.2-2.4-6c0.2-1.6,0.8-3.1,1.8-4.3c1.2-1.7,0.3-4.2,0.3-4.2c-0.1-0.3,0-0.7,0.4-0.8c0.3-0.1,0.7,0,0.8,0.4
              c0,0,1.2,3.1-0.4,5.4c-0.8,1.1-1.4,2.3-1.5,3.7c-0.1,1.9,0.7,3.7,2.1,5c1,1.1,2.3,1.9,3.7,2.2c1.6,0.5,2.9,0.8,4.4,4.4
              c1.3,2.8,1.2,6.1-0.4,8.8c-0.4,0.6-0.6,1.2-0.6,1.9c0.1,0.9,0.3,1,1.6,1.8c0.9,0.5,2.2,1.2,4.1,2.5c5.7,3.9,0.5,10.8,0.5,10.9
              c-0.2,0.3-0.6,0.3-0.9,0.1S350.4,292.1,350.6,291.8L350.6,291.8z M340,296.1c-0.2,0.3-0.2,0.7,0.1,0.9l0,0
              c0.3,0.2,0.7,0.1,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c0.1-0.7,0.3-1.3,0.6-1.9
              c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7
              c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3c-0.3,0.1-0.5,0.5-0.3,0.8c0,0,1,2.4-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3
              c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6
              c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5C344.4,290.2,340,296.1,340,296.1L340,296.1z M342.7,295
              c-0.2,0.3-0.2,0.7,0.1,0.9l0,0c0.3,0.2,0.7,0.1,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8
              c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5
              c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.4-0.8-0.3c-0.3,0.1-0.4,0.5-0.3,0.8c0,0,1,2.4-0.3,4.2
              c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8
              c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5C347.1,289.1,342.7,295.1,342.7,295L342.7,295z
               M345.3,294c-0.2,0.3-0.2,0.7,0.1,0.9l0,0c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5
              c-1.3-0.7-1.5-0.8-1.6-1.8c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1.4-0.3-2.7-1.1-3.7-2.2
              c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.4-0.8-0.3c-0.3,0.1-0.4,0.5-0.3,0.8
              c0,0,1,2.5-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7
              c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c1.4,0.8,2.7,1.6,4,2.5
              C349.8,288.1,345.3,294,345.3,294L345.3,294z M348.1,293.8c0.3,0.2,0.7,0.2,0.9-0.1l0,0c0,0,5.2-7-0.5-10.9
              c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c0-0.7,0.3-1.3,0.6-1.9c1.6-2.7,1.7-6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4
              c-1.4-0.3-2.7-1.1-3.7-2.2c-1.4-1.3-2.2-3.1-2.1-5c0.2-1.3,0.7-2.6,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3
              c-0.3,0.1-0.5,0.5-0.4,0.8c0,0,1,2.4-0.3,4.2c-1,1.2-1.6,2.7-1.8,4.3c-0.1,2.3,0.8,4.5,2.4,6c1.1,1.3,2.6,2.2,4.3,2.6
              c1.3,0.4,2.3,0.7,3.6,3.7c1.2,2.5,1,5.4-0.4,7.8c-0.5,0.8-0.7,1.7-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c0.9,0.5,2.1,1.2,4,2.5
              c4.7,3.2,0.2,9.1,0.2,9.1C347.8,293.2,347.8,293.6,348.1,293.8L348.1,293.8z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M1113.3,273.2l-117.4-68.9L869.8,419.1L987.2,488L1113.3,273.2
            L1113.3,273.2z M984.5,476.7L881.1,416l117.5-200.3l103.4,60.7L984.5,476.7z"/>

          <rect x="875.5" y="286.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 191.0462 1026.1345)" fill="#D3AC89" width="232.2" height="120"/>
          <path fill="#0B99D6" d="M1089.2,314.3l4.6-7.9l-5.3-6.9l-4.6,7.9L1089.2,314.3z
             M1044.4,242.6l-13.9-18l-19.7-11.6l13.9,18L1044.4,242.6z"/>
          <path fill="#0B99D6" d="M1079.9,330.1l4.6-7.9l-5.3-6.9l-4.6,7.9L1079.9,330.1z
             M998.6,215.6l6.3,3.7l-10.2-13.1l-4.6,7.9l5.3,6.9L998.6,215.6z"/>
          <path fill="#0B99D6" d="M1070.6,345.9l4.6-7.9l-5.4-6.9l-4.6,7.9L1070.6,345.9z
             M990.8,229l-5.3-6.9l-4.6,7.9l5.3,6.9L990.8,229z"/>
          <path fill="#0B99D6" d="M1061.3,361.8l4.6-7.9l-5.3-6.9l-4.6,7.9L1061.3,361.8z
             M981.5,244.8l-5.4-6.9l-4.6,7.9l5.3,6.9L981.5,244.8z"/>
          <path fill="#0B99D6" d="M1052,377.6l4.6-7.9l-5.3-6.9l-4.6,7.9L1052,377.6z M972.2,260.6
            l-5.3-6.9l-4.6,7.9l5.3,6.9L972.2,260.6z"/>
          <path fill="#0B99D6" d="M1042.7,393.5l4.6-7.9l-5.3-6.9l-4.7,7.9L1042.7,393.5z
             M962.9,276.5l-5.3-6.9l-4.6,7.9l5.3,6.9L962.9,276.5z"/>
          <path fill="#0B99D6" d="M1033.4,409.3l4.6-7.9l-5.3-6.9l-4.6,7.9L1033.4,409.3z
             M953.6,292.3l-5.4-6.9l-4.6,7.9l5.3,6.9L953.6,292.3z"/>
          <path fill="#0B99D6" d="M1024.1,425.2l4.6-7.9l-5.3-6.9l-4.6,7.9L1024.1,425.2z
             M944.3,308.2l-5.3-6.9l-4.6,7.9l5.3,6.9L944.3,308.2z"/>
          <path fill="#0B99D6" d="M1014.8,441l4.6-7.9l-5.3-6.9l-4.7,7.9L1014.8,441z M935,324
            l-5.3-6.9L925,325l5.3,6.9L935,324z"/>
          <path fill="#0B99D6" d="M1005.5,456.8l4.6-7.9l-5.3-6.9l-4.6,7.9L1005.5,456.8z
             M925.7,339.8l-5.4-6.8l-4.6,7.9l5.3,6.9L925.7,339.8z"/>
          <path fill="#0B99D6" d="M996.2,472.7l4.6-7.9l-5.3-6.9l-4.6,7.9L996.2,472.7z
             M916.4,355.7l-5.3-6.9l-4.6,7.9l5.3,6.9L916.4,355.7z"/>
          <path fill="#0B99D6" d="M986.1,487.4l1.2,0.7l4.4-7.4l-5.3-6.9l-1.7,3l-12.4-7.3
            L986.1,487.4L986.1,487.4z M907.1,371.5l-5.3-6.9l-4.6,7.9l5.3,6.9L907.1,371.5z"/>
          <path fill="#0B99D6" d="M946.5,464.2l19.7,11.6l-13.9-18l-19.7-11.6L946.5,464.2
            L946.5,464.2z M897.8,387.4l-5.4-6.9l-4.6,7.9l5.3,6.9L897.8,387.4z"/>
          <path fill="#0B99D6" d="M907,441l19.7,11.6l-13.9-18L893.1,423L907,441z M888.5,403.2
            l-5.3-6.9l-4.6,7.9l5.3,6.9L888.5,403.2z"/>
          <polygon fill="#0B99D6" points="873.9,412.2 887.2,429.4 869.8,419.1     "/>
          <g>
            <path fill="#80CAF0" d="M1006.7,400.5c4.4-7.5,1.9-17.1-5.6-21.5s-17.1-1.9-21.5,5.6
              s-1.9,17.1,5.6,21.5c3.6,2.1,7.9,2.7,11.9,1.6C1001.1,406.7,1004.5,404.1,1006.7,400.5z M986.4,403.9c-6.3-3.7-8.4-11.8-4.7-18.1
              c3.7-6.3,11.8-8.4,18.1-4.7c6.3,3.7,8.4,11.8,4.7,18.1c-1.8,3-4.7,5.2-8.1,6.1C993,406.2,989.4,405.7,986.4,403.9L986.4,403.9z"
            />
            <path fill="#80CAF0" d="M997.6,397.3c0,0,4.8,8.8-2.1,12.1c-2.8,1.3-4.6,2-5.8,2.5
              c-2.5,0.9-2.9,1.1-3.5,3.1c-0.2,1.2,0,2.5,0.5,3.6c0.8,2.5,1.8,5.8-1,10.4c-2.3,3.8-3.8,3.9-5.6,4.2c-2.3,0.2-4.4,1.1-6.2,2.6
              c-3.1,2.5-4.3,5.1-4.4,7.5c-0.1,2.1,0.5,4.2,1.5,6.1c1.3,2.6-0.5,5.6-0.5,5.6c-0.2,0.4-0.1,0.9,0.2,1.1c0.4,0.2,0.9,0.1,1.1-0.2
              c0,0,0,0,0-0.1c0,0,2.3-3.9,0.5-7.2c-0.9-1.6-1.3-3.4-1.3-5.2c0.1-2,1.1-4.1,3.8-6.2c1.5-1.3,3.4-2.1,5.4-2.2
              c2.2-0.3,4-0.5,6.8-4.9c3.2-5.2,2-9,1.2-11.8c-0.4-0.8-0.5-1.7-0.5-2.6c0.4-1.2,0.7-1.3,2.5-2c1.3-0.5,3.1-1.2,5.9-2.5
              c8.4-4,2.9-14.3,2.8-14.4c-0.2-0.4-0.7-0.6-1.1-0.3l0,0C997.5,396.4,997.4,396.9,997.6,397.3L997.6,397.3z M984.3,389.5
              c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3c0,0,0,0.1,0.1,0.1c0,0,5.6,10.3-2.8,14.3c-2.8,1.4-4.7,2.1-6,2.5
              c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9c-2,0.1-3.9,0.9-5.4,2.2
              c-2.7,2.1-3.7,4.3-3.8,6.2c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1
              c0,0,1.8-3.1,0.5-5.6c-1-1.8-1.6-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.2
              c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.1,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5C989,398.2,984.3,389.5,984.3,389.5
              L984.3,389.5z M987.6,391.5c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3l0,0c0,0,5.6,10.3-2.9,14.3
              c-2.8,1.4-4.7,2.1-5.9,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9
              c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.2c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3l0,0
              c-0.4-0.2-0.5-0.7-0.3-1.1c0,0,1.8-3.1,0.5-5.6c-1-1.9-1.5-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c1.7-1.5,3.9-2.4,6.2-2.6
              c1.8-0.2,3.2-0.4,5.5-4.2c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.1,3.5-3.1c1.2-0.5,3-1.1,5.8-2.5
              C992.3,400.2,987.6,391.5,987.6,391.5L987.6,391.5z M990.9,393.5c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3l0,0
              c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-6,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8
              c-2.7,4.4-4.5,4.7-6.8,4.9c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2
              c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0,0,1.8-3.1,0.5-5.6c-1-1.9-1.5-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5
              c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.1c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.2,3.5-3.1
              c1.2-0.5,3-1.1,5.8-2.5C995.7,402.2,990.9,393.5,990.9,393.5L990.9,393.5z M994.6,394.3c0.4-0.2,0.9-0.1,1.1,0.3
              c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-6,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.1,0.9,0.1,1.8,0.5,2.6c0.8,2.8,2,6.6-1.2,11.8
              c-2.7,4.4-4.5,4.6-6.8,4.9c-2,0.2-3.9,0.9-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3c0,1.8,0.4,3.6,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2
              c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0,0,1.8-3.1,0.5-5.6c-1-1.8-1.6-3.9-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5
              c1.7-1.5,3.9-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.5-4.2c2.8-4.5,1.8-7.9,1-10.4c-0.5-1.1-0.7-2.4-0.5-3.6c0.6-2,1-2.2,3.5-3.1
              c1.2-0.5,3-1.2,5.8-2.5c6.8-3.3,2.1-12,2.1-12.1C993.9,395,994.1,394.5,994.6,394.3C994.5,394.2,994.5,394.2,994.6,394.3
              L994.6,394.3z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M943.8,352.7l-1.1,1.8l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.7,1.2l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0
              l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.6,1.1l0,0
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-1.1,1.9l1.5,0.9c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5
              s0.9,1.3,0.5,2l0,0l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.2,0.7
              c0.4-0.7,1.2-1,1.9-0.6s1,1.2,0.6,1.9l-0.1,0.1l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5
              s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.5,0.9l1.1-1.8l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0
              l0.6-1.1l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1l0,0c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0,0l0.6-1.1
              l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l0.7-1.2l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0,0l1.1-1.9l-1.5-0.9
              c-0.4,0.7-1.2,1-1.9,0.6s-1-1.2-0.6-1.9l0.1-0.1l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.2-0.7c-0.4,0.7-1.3,0.9-2,0.5
              s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0L943.8,352.7z"/>

            <rect x="944.1" y="357.5" transform="matrix(0.5062 -0.8624 0.8624 0.5062 149.8592 1005.6311)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M916.5,421l2.2,1.3l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l1.5,0.9l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0
              l1.3,0.8l0,0c0.5-0.8,1.5-1.1,2.4-0.6c0.8,0.5,1.1,1.5,0.6,2.4l0,0l0,0l1.3,0.8l0,0c0.5-0.8,1.5-1.1,2.4-0.6
              c0.8,0.5,1.1,1.5,0.6,2.4l0,0l0,0l1.3,0.8l0,0c0.7-0.7,1.8-0.6,2.4,0.1c0.4,0.4,0.6,1.1,0.4,1.6l0,0l2.2,1.3l1.1-1.8
              c-0.8-0.4-1.2-1.4-0.8-2.3c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3l0,0
              c0.5-0.8,1.5-1.1,2.3-0.6l0.8-1.3c-0.8-0.5-1-1.6-0.5-2.4c0.5-0.7,1.4-1,2.2-0.6l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3
              s1.5-1.1,2.3-0.6l0.9-1.5c-0.8-0.5-1.1-1.5-0.6-2.4s1.5-1.1,2.4-0.6l0.8-1.3c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0,0
              l0.8-1.3c-0.8-0.4-1.2-1.4-0.8-2.3c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l0.8-1.3c-0.8-0.4-1.2-1.4-0.8-2.3
              c0.4-0.8,1.4-1.2,2.3-0.8c0.1,0,0.1,0.1,0.2,0.1l1-1.8l-2.2-1.3l0,0c-0.5,0.8-1.5,1.1-2.4,0.6c-0.8-0.5-1.1-1.5-0.6-2.4l0,0
              l-1.3-0.7l0,0c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l0,0l-1.3-0.8l0,0c-0.4,0.8-1.4,1.2-2.3,0.8
              c-0.8-0.4-1.2-1.4-0.8-2.3c0-0.1,0.1-0.1,0.1-0.2l0,0l-1.3-0.8l0,0c-0.4,0.8-1.4,1.2-2.3,0.8c-0.8-0.4-1.2-1.4-0.8-2.3
              c0-0.1,0.1-0.1,0.1-0.2l0,0l-1.5-0.9l0,0c-0.4,0.8-1.4,1.2-2.3,0.8c-0.8-0.4-1.2-1.4-0.8-2.3c0-0.1,0.1-0.1,0.1-0.2l0,0l-2.2-1.3
              l-1,1.8c0.8,0.4,1.2,1.4,0.8,2.3c-0.4,0.8-1.4,1.2-2.3,0.8c-0.1,0-0.1-0.1-0.2-0.1l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3
              s-1.5,1.1-2.3,0.6l0,0l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l0,0l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3
              s-1.5,1.1-2.3,0.6l-0.9,1.5c0.8,0.5,1.1,1.5,0.6,2.4s-1.5,1.1-2.4,0.6l0,0l-0.7,1.3c0.8,0.5,1,1.6,0.4,2.4
              c-0.5,0.7-1.4,0.9-2.2,0.6l-0.8,1.3c0.8,0.5,1.1,1.5,0.7,2.3c-0.5,0.8-1.5,1.1-2.3,0.7l0,0l-0.8,1.3c0.8,0.5,1.1,1.5,0.6,2.3
              s-1.5,1.1-2.3,0.6l0,0L916.5,421z"/>

            <rect x="922" y="399.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 110.2621 1012.4933)" fill="#FBD4A4" width="34.5" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M900.5,397.5l1.8,1.1l0,0c0.4-0.7,1.2-1,1.9-0.6s1,1.2,0.6,1.9l-0.1,0.1l0,0l1.2,0.7l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2
              l0,0l1.1,0.6l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.8,1.1l0.9-1.5c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1
              c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.7-1.2c-0.7-0.4-1-1.2-0.6-1.9s1.2-1,1.9-0.6l0.1,0.1l0.6-1.1
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1c-0.7-0.4-1-1.2-0.6-1.9
              s1.2-1,1.9-0.6l0.1,0.1l0.9-1.5L937,378l0,0c-0.4,0.7-1.2,1-1.9,0.6s-1-1.2-0.6-1.9l0.1-0.1l0,0l-1.1-0.6l0,0
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l0,0l-1.1-0.6l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l0,0l-1.1-0.6l0,0
              c-0.4,0.7-1.3,0.9-2,0.5c-0.6-0.4-0.9-1.2-0.5-1.9l0,0l-1.2-0.7l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.8-1.1
              l-0.9,1.5c0.7,0.3,1.1,1.1,0.8,1.9s-1.1,1.1-1.9,0.8c-0.1-0.1-0.3-0.1-0.4-0.2l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5
              l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.7,1.2c0.7,0.4,0.9,1.3,0.5,2
              s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5L900.5,397.5z"/>

            <rect x="905.2" y="379.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 119.2803 984.7851)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="1019.7,258.1 969.5,337.9 982.9,341.2 1030.2,263.1
            "/>
        </g>
        <g>

          <rect x="532.2" y="298.7" transform="matrix(0.1821 -0.9833 0.9833 0.1821 92.0918 892.5992)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M682.4,358.1l-81.5,38.7c-11.7,5.6-28.3,2.8-36.8-6.1L501,324.5
            L682.4,358.1z"/>
          <g>
            <path fill="#0B99D6" d="M573.2,382.2c8.2,1.5,13.6,9.4,12.1,17.6s-9.4,13.6-17.6,12.1
              c-8.2-1.5-13.6-9.4-12.1-17.6c0.7-3.9,3-7.4,6.3-9.7C565.2,382.4,569.2,381.5,573.2,382.2z M580.9,389.9
              c-4-5.8-11.9-7.3-17.6-3.3s-7.3,11.9-3.3,17.6c4,5.8,11.9,7.3,17.6,3.3c2.8-1.9,4.7-4.8,5.3-8.1
              C583.5,396.1,582.8,392.6,580.9,389.9L580.9,389.9z"/>
            <path fill="#0B99D6" d="M573.2,391.5c-0.3,0.4-0.8,0.4-1.1,0.2
              c-0.4-0.3-0.4-0.8-0.2-1.1l0.1-0.1c0,0,7.5-8.4,13.9-2.1c2.2,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.6,2.6,1.6
              c0.8-0.2,1.6-0.7,2.2-1.3c2.2-1.7,5.3-4,11-2.8c4.9,1,5.7,2.6,6.7,4.5c0.8,1.7,2.1,3.2,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4
              c1.7-0.6,3.1-1.6,4.3-2.9c2.5-2.7,6.7-1.9,6.7-1.9c0.4,0.1,0.7,0.5,0.7,0.9c-0.1,0.4-0.5,0.7-0.9,0.7c0,0,0,0-0.1,0
              c0,0-3.3-0.6-5.2,1.4c-1.3,1.5-3.1,2.7-5,3.3c-2.2,0.6-4.9,0.5-8.2-1.6c-1.9-1.1-3.4-2.7-4.3-4.8c-0.8-1.5-1.4-2.8-5.6-3.7
              c-5-1-7.7,1-9.7,2.5c-0.8,0.8-1.9,1.4-3.1,1.6c-2,0.1-2.3-0.2-3.9-2.1c-0.8-1-2-2.4-4.1-4.4C579.6,384.3,573.3,391.4,573.2,391.5
              L573.2,391.5z M570.5,406c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.2,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.4-0.2,0.9,0.2,1.1
              C569.8,406.3,570.3,406.3,570.5,406L570.5,406z M571.2,402.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.2,4.4
              c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6l0,0c0.1-0.4-0.2-0.8-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0,1.1l0,0C570.4,402.7,570.9,402.7,571.2,402.4L571.2,402.4L571.2,402.4z M571.9,398.7c0,0,6.4-7.2,11.6-2.1
              c2.1,2.1,3.3,3.5,4.2,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.2,0.9,4.8,2.1,5.6,3.6
              c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1
              H633c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0.1,1.1S571.6,399,571.9,398.7L571.9,398.7L571.9,398.7z M572.6,395.1c0,0,6.4-7.2,11.6-2.1
              c2.1,2.1,3.3,3.5,4.2,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7
              c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.3,0.9-0.7
              c0-0.4-0.2-0.8-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1
              c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5
              c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8-0.1,1.1c0.3,0.3,0.8,0.3,1.1,0.1C572.5,395.2,572.5,395.1,572.6,395.1L572.6,395.1
              z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M923,305.1l-80.9-60.8L730.9,392.4l80.9,60.8L923,305.1z
                 M810.8,444.5L739.5,391l103.6-138l71.3,53.5L810.8,444.5z"/>

              <rect x="740.7" y="304.3" transform="matrix(0.6006 -0.7996 0.7996 0.6006 51.4388 800.5958)" fill="#D1CCE6" width="172.6" height="89.1"/>
              <g>
                <polygon fill="#0B99D6" points="918.1,311.6 906.8,293 920.4,303.2 922.2,306.2
                  "/>
                <path fill="#0B99D6" d="M909.9,322.5l4.1-5.5l-3.4-5.5l-4.1,5.5L909.9,322.5
                  L909.9,322.5z M901.9,297.2l-8.8-14.5l-13.6-10.2l8.8,14.5L901.9,297.2z"/>
                <path fill="#0B99D6" d="M901.7,333.4l4.1-5.5l-3.4-5.5l-4.1,5.5L901.7,333.4z
                   M874.7,276.7l-8.8-14.5L852.3,252l8.8,14.4L874.7,276.7z"/>
                <path fill="#0B99D6" d="M893.5,344.4l4.1-5.5l-3.4-5.5l-4.1,5.5L893.5,344.4z
                   M843.1,253l4.3,3.3l-6.4-10.6l-4.1,5.4l3.4,5.6L843.1,253z"/>
                <path fill="#0B99D6" d="M885.3,355.3l4.1-5.5l-3.4-5.5l-4.1,5.5L885.3,355.3
                  L885.3,355.3z M836.2,262.2l-3.4-5.5l-4.1,5.5l3.4,5.5L836.2,262.2z"/>
                <path fill="#0B99D6" d="M877.1,366.2l4.1-5.5l-3.4-5.5l-4.1,5.5L877.1,366.2z
                   M828,273.1l-3.4-5.5l-4.1,5.5l3.4,5.5L828,273.1z"/>
                <path fill="#0B99D6" d="M868.9,377.1l4.1-5.5l-3.4-5.5l-4.1,5.5L868.9,377.1z
                   M819.8,284l-3.4-5.5l-4.1,5.5l3.4,5.5L819.8,284z"/>
                <path fill="#0B99D6" d="M860.7,388l4.1-5.5l-3.4-5.5l-4.1,5.5L860.7,388z
                   M811.6,294.9l-3.4-5.5l-4.1,5.5l3.4,5.5L811.6,294.9z"/>
                <path fill="#0B99D6" d="M852.5,398.9l4.1-5.4l-3.4-5.6l-4.1,5.5L852.5,398.9z
                   M803.4,305.8l-3.4-5.5l-4.1,5.4l3.4,5.5L803.4,305.8z"/>
                <path fill="#0B99D6" d="M844.3,409.9l4.1-5.5l-3.4-5.5l-4.1,5.4L844.3,409.9
                  L844.3,409.9z M795.2,316.8l-3.4-5.6l-4.1,5.5l3.4,5.5L795.2,316.8z"/>
                <path fill="#0B99D6" d="M836.2,420.8l4.1-5.4l-3.4-5.6l-4.1,5.5L836.2,420.8z
                   M787,327.7l-3.4-5.5l-4.1,5.5l3.4,5.5L787,327.7z"/>
                <path fill="#0B99D6" d="M828,431.7l4.1-5.5l-3.4-5.5l-4.1,5.5L828,431.7z
                   M778.9,338.6l-3.4-5.6l-4.1,5.5l3.4,5.5L778.9,338.6z"/>
                <path fill="#0B99D6" d="M819.8,442.6l4.1-5.5l-3.4-5.6l-4.1,5.4L819.8,442.6
                  L819.8,442.6z M770.7,349.5l-3.4-5.5l-4.1,5.5l3.4,5.5L770.7,349.5z"/>
                <path fill="#0B99D6" d="M811,452.6l0.8,0.6l3.9-5.1l-3.4-5.5l-1.5,2l-8.5-6.4
                  L811,452.6z M762.5,360.4l-3.4-5.6l-4.1,5.4l3.4,5.6L762.5,360.4z"/>
                <path fill="#0B99D6" d="M783.8,432.1l13.6,10.2l-8.8-14.5L775,417.7L783.8,432.1
                  L783.8,432.1z M754.2,371.3l-3.4-5.5l-4.1,5.5l3.4,5.5L754.2,371.3z"/>
                <path fill="#0B99D6" d="M756.5,411.7l13.6,10.2l-8.8-14.5l-13.6-10.2L756.5,411.7
                  L756.5,411.7z M746.1,382.2l-3.4-5.6l-4.1,5.5l3.4,5.6L746.1,382.2z"/>
                <polygon fill="#0B99D6" points="734.5,387.6 742.9,401.4 730.9,392.4           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M833.5,390.2c3.9-5.2,2.8-12.5-2.3-16.3s-12.5-2.8-16.3,2.3
                s-2.8,12.5,2.3,16.3c2.5,1.9,5.6,2.7,8.6,2.2C828.9,394.3,831.7,392.6,833.5,390.2z M818.3,391c-4.3-3.3-5.2-9.4-2-13.7
                c3.3-4.3,9.4-5.2,13.7-2c4.3,3.3,5.2,9.4,2,13.7c-1.6,2.1-3.9,3.5-6.5,3.8C823,393.2,820.4,392.6,818.3,391L818.3,391z"/>
              <path fill="#80CAF0" d="M827.1,387c0,0,2.8,6.9-2.6,8.7c-2.2,0.8-3.6,1.1-4.5,1.4
                c-1.9,0.5-2.2,0.6-2.8,2c-0.3,0.9-0.3,1.8,0,2.7c0.7,2.6,0.1,5.5-1.6,7.6c-2,2.6-3.1,2.6-4.5,2.6c-1.7-0.1-3.4,0.4-4.8,1.4
                c-2,1-3.4,2.9-3.9,5.1c-0.2,1.6,0,3.1,0.6,4.6c0.8,2-0.8,4.1-0.8,4.1c-0.2,0.3-0.1,0.7,0.1,0.9s0.7,0.1,0.9-0.1c0,0,2-2.6,1-5.3
                c-0.5-1.2-0.7-2.6-0.5-4c0.4-1.9,1.6-3.4,3.3-4.3c1.2-0.8,2.7-1.2,4.2-1.2c1.7,0,3,0,5.4-3.1c2-2.4,2.7-5.6,1.9-8.6
                c-0.2-0.6-0.2-1.3-0.1-2c0.4-0.9,0.6-0.9,2-1.3c1-0.2,2.4-0.6,4.6-1.4c6.5-2.3,3.3-10.3,3.3-10.4c-0.1-0.3-0.5-0.5-0.8-0.3
                C827.1,386.3,826.9,386.7,827.1,387L827.1,387z M817.9,380.1c-0.1-0.3,0-0.7,0.3-0.8l0,0c0.3-0.1,0.7,0,0.8,0.3
                c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2c0.8,3,0.1,6.2-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.5-0.1-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.3,0,2.7,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1s-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6c0.5-2.2,1.9-4.1,3.9-5.1
                c1.4-1,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3C820.7,387,818,380.2,817.9,380.1L817.9,380.1z M820.2,381.8c-0.1-0.3,0-0.7,0.3-0.8l0,0
                c0.3-0.1,0.7,0,0.8,0.3c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.2,6.2-1.8,8.6c-2.4,3-3.8,3-5.4,3.1c-1.5,0-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.4,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-1,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c1-0.2,2.3-0.6,4.5-1.3c5.3-1.9,2.6-8.7,2.6-8.7L820.2,381.8z M822.5,383.6c-0.2-0.3,0-0.7,0.3-0.8
                c0.3-0.2,0.7,0,0.8,0.3v0.1c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.1,6.2-1.9,8.6c-2.4,3-3.8,3-5.4,3.1c-1.5,0-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.3,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-0.9,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c0.9-0.2,2.3-0.6,4.5-1.4C825.3,390.5,822.5,383.6,822.5,383.6L822.5,383.6z M825.1,384.5
                c0.3-0.1,0.7,0,0.8,0.4c0,0,3.2,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.1,0.7-0.1,1.4,0.1,2
                c0.8,3,0.1,6.2-1.9,8.6c-2.4,3-3.8,3.1-5.4,3.1c-1.5-0.1-2.9,0.4-4.2,1.2c-1.7,0.9-2.9,2.4-3.3,4.3c-0.2,1.4,0,2.7,0.5,4
                c1,2.6-1,5.3-1,5.3c-0.2,0.3-0.6,0.3-0.9,0.1l0,0c-0.3-0.2-0.3-0.6-0.1-0.9l0,0c0,0,1.6-2.1,0.8-4.1c-0.6-1.5-0.8-3-0.6-4.6
                c0.5-2.2,1.9-4.1,3.9-5.1c1.4-0.9,3.1-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c1.8-2.1,2.4-4.9,1.6-7.6c-0.3-0.9-0.3-1.8,0-2.7
                c0.6-1.4,0.9-1.5,2.8-2c0.9-0.2,2.3-0.6,4.5-1.4c5.3-1.9,2.6-8.7,2.6-8.7C824.7,385.1,824.8,384.7,825.1,384.5L825.1,384.5
                L825.1,384.5z"/>
            </g>

            <rect x="842.5" y="334.3" transform="matrix(0.6006 -0.7996 0.7996 0.6006 81.0126 832.7747)" fill="#A76C2B" width="63.1" height="2"/>
          </g>

          <rect x="837.4" y="330.5" transform="matrix(0.6006 -0.7996 0.7996 0.6006 82.0321 827.1973)" fill="#A76C2B" width="63.1" height="2"/>
        </g>
        <g>

          <rect x="413.4" y="234.3" transform="matrix(0.5114 -0.8593 0.8593 0.5114 -54.1447 558.3812)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M410.3,236l35.9,83.1c5.2,12,19.5,20.9,31.8,19.8l91.4-8.2
            L410.3,236z"/>
          <g>
            <path fill="#0B99D6" d="M476.8,326.5c-7.2-4.3-16.5-1.9-20.7,5.3
              c-4.3,7.2-1.9,16.5,5.3,20.7c7.2,4.3,16.5,1.9,20.7-5.3c2.1-3.4,2.6-7.6,1.7-11.5C482.7,331.9,480.2,328.5,476.8,326.5z
               M465.9,327.1c6.8-1.7,13.7,2.4,15.5,9.2c1.7,6.8-2.4,13.7-9.2,15.5c-6.8,1.7-13.7-2.4-15.5-9.2c-0.8-3.3-0.3-6.7,1.4-9.6
              C459.8,330.1,462.6,328,465.9,327.1z"/>
            <path fill="#0B99D6" d="M470.6,333.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8
              c0,0-0.1-11.3-9.1-10.8c-3,0.2-4.9,0.4-6.2,0.6c-1.9,0.3-2.2,0.3-3-0.5c-0.5-0.7-0.7-1.5-0.8-2.4c-0.6-2.7-1.3-6.5-6.5-9.4
              c-4.4-2.5-6-1.8-8-1c-1.7,0.8-3.7,1-5.6,0.6c-3.3-0.5-5.1-1.9-6.1-3.5c-0.9-1.5-1.3-3.2-1.3-5c-0.1-3.6-3.8-5.9-3.8-5.9
              c-0.4-0.2-0.9-0.1-1.1,0.3s-0.1,0.9,0.3,1.1c0,0,2.9,1.8,3,4.5c0,2,0.5,4.1,1.6,5.8c1.2,2,3.4,3.6,7.2,4.2
              c2.2,0.4,4.4,0.2,6.4-0.7c1.6-0.6,2.9-1.2,6.6,0.9c4.5,2.5,5.2,5.9,5.7,8.3c0.1,1.2,0.5,2.3,1.3,3.2c1.4,1.4,1.9,1.4,4.4,1
              c1.2-0.2,3.1-0.4,6.1-0.6C470.5,323.8,470.6,333.4,470.6,333.4L470.6,333.4z M463,346.2c0,0-0.1-9.6-7.4-9.2
              c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9
              c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1
              s0.7-0.5,1.1-0.3l0,0c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1
              c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.5,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8
              c0,0.4-0.3,0.8-0.8,0.8l0,0C463.4,346.9,463,346.6,463,346.2C463,346.1,463,346.1,463,346.2L463,346.2z M464.9,343
              c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3
              c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5
              c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3l0,0c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5
              c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.7,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5
              c1.3-0.2,3.2-0.5,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.4,0.8-0.8,0.8C465.2,343.8,464.9,343.4,464.9,343L464.9,343L464.9,343
              z M466.8,339.8c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2
              c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8
              c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1l0,0c0.2-0.4,0.7-0.5,1.1-0.3l0,0c0,0,3.7,2.2,3.8,5.8c0,1.8,0.4,3.5,1.3,5
              c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.5,8,1c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4
              c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.5,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.4,0.8-0.8,0.8
              C467.1,340.5,466.8,340.2,466.8,339.8L466.8,339.8z M468.7,336.6c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6
              c-2.5,0.4-3,0.4-4.4-1c-0.7-0.9-1.2-2.1-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-2,0.9-4.3,1.2-6.4,0.7
              c-3.8-0.6-6-2.3-7.2-4.2c-1-1.8-1.6-3.8-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3
              c0,0,3.7,2.2,3.8,5.9c0,1.8,0.4,3.5,1.3,5c1,1.6,2.9,3,6.1,3.5c1.9,0.4,3.8,0.2,5.6-0.6c2-0.8,3.6-1.4,8,1
              c5.1,2.9,5.9,6.6,6.5,9.4c0.1,0.9,0.4,1.7,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8
              c0,0.4-0.4,0.8-0.8,0.8C469,337.3,468.7,337,468.7,336.6L468.7,336.6z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M644,387.6l33-97.2l-177.8-60.3l-33,97.2L644,387.6z
               M474.3,323.6l29-85.6L669,294.2l-29,85.6L474.3,323.6z"/>

            <rect x="526.4" y="221.4" transform="matrix(0.3211 -0.947 0.947 0.3211 95.5284 750.9608)" fill="#D1CCE6" width="90.4" height="175"/>
            <g>
              <polygon fill="#0B99D6" points="636.3,385 650.6,368.2 645.1,384.5 642.8,387.2
                "/>
              <path fill="#0B99D6" d="M623.2,380.5l6.5,2.2l4.3-5l-6.5-2.2L623.2,380.5z M645,364.8
                l11.2-13l5.5-16.3l-11.2,13L645,364.8z"/>
              <path fill="#0B99D6" d="M610.1,376.1l6.5,2.2l4.3-5l-6.5-2.2L610.1,376.1L610.1,376.1z
                 M656.1,332.1l11.2-13l5.5-16.3l-11.1,13L656.1,332.1z"/>
              <path fill="#0B99D6" d="M597,371.6l6.5,2.2l4.3-5l-6.5-2.2L597,371.6L597,371.6z
                 M669,294.2l-1.8,5.2l8.1-9.5l-6.5-2.2l-4.3,5L669,294.2z"/>
              <path fill="#0B99D6" d="M583.8,367.2l6.5,2.2l4.3-5l-6.5-2.2L583.8,367.2z M658,290.4
                l4.3-5l-6.5-2.2l-4.3,5L658,290.4z"/>
              <path fill="#0B99D6" d="M570.7,362.7l6.5,2.2l4.3-5l-6.6-2.2L570.7,362.7L570.7,362.7z
                 M644.9,286l4.3-5l-6.5-2.2l-4.3,5L644.9,286z"/>
              <path fill="#0B99D6" d="M557.6,358.3l6.5,2.2l4.3-5l-6.5-2.2L557.6,358.3z
                 M631.8,281.5l4.3-5l-6.5-2.2l-4.3,5L631.8,281.5z"/>
              <path fill="#0B99D6" d="M544.5,353.9l6.5,2.2l4.3-5l-6.5-2.2L544.5,353.9L544.5,353.9z
                 M618.7,277.1l4.3-5l-6.5-2.2l-4.3,5L618.7,277.1z"/>
              <path fill="#0B99D6" d="M531.4,349.4l6.5,2.2l4.3-5l-6.5-2.2L531.4,349.4z
                 M605.6,272.6l4.3-5l-6.5-2.2l-4.3,5L605.6,272.6z"/>
              <path fill="#0B99D6" d="M518.3,345l6.5,2.2l4.3-5l-6.5-2.2L518.3,345L518.3,345z
                 M592.5,268.2l4.3-5l-6.5-2.2l-4.3,5L592.5,268.2z"/>
              <path fill="#0B99D6" d="M505.2,340.5l6.5,2.2l4.3-5l-6.5-2.2L505.2,340.5L505.2,340.5z
                 M579.3,263.8l4.3-5l-6.5-2.2l-4.3,5L579.3,263.8z"/>
              <path fill="#0B99D6" d="M492.1,336.1l6.5,2.2l4.3-5l-6.5-2.2L492.1,336.1z
                 M566.2,259.3l4.3-5l-6.5-2.2l-4.3,5L566.2,259.3z"/>
              <path fill="#0B99D6" d="M479,331.6l6.5,2.2l4.3-5l-6.5-2.2L479,331.6L479,331.6z
                 M553.1,254.9l4.3-5l-6.5-2.2l-4.3,5L553.1,254.9z"/>
              <path fill="#0B99D6" d="M466.6,326.4l-0.3,0.9l6.2,2.1l4.3-5l-2.4-0.8l3.5-10.2
                L466.6,326.4L466.6,326.4z M540,250.4l4.3-5l-6.5-2.2l-4.3,5L540,250.4z"/>
              <path fill="#0B99D6" d="M477.7,293.6l-5.5,16.4l11.1-13l5.5-16.3L477.7,293.6
                L477.7,293.6z M526.9,246l4.3-5l-6.5-2.2l-4.3,5L526.9,246z"/>
              <path fill="#0B99D6" d="M488.8,260.9l-5.5,16.3l11.1-13l5.5-16.3L488.8,260.9
                L488.8,260.9z M513.8,241.5l4.3-5l-6.5-2.2l-4.3,5L513.8,241.5z"/>
              <polygon fill="#0B99D6" points="505,232.1 494.3,244.5 499.2,230.1         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M574.8,271.5l-2-0.7l0,0c-0.3,0.8-1.1,1.2-1.8,0.9
              c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l0,0l-1.3-0.5l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-1.2-0.4l0,0
              c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-1.2-0.4l0,0c-0.2,0.8-1,1.2-1.8,1s-1.2-1-1-1.8c0-0.1,0-0.1,0.1-0.2
              l0,0l-1.2-0.4l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-2-0.7l-0.6,1.7c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.2,1.2,1,1,1.8s-1,1.2-1.8,1c-0.1,0-0.1,0-0.2-0.1l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l-0.5,1.4c0.8,0.2,1.2,1,1,1.8
              s-1,1.2-1.8,1c-0.1,0-0.1,0-0.2-0.1l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l0,0l-0.4,1.2
              c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l0,0l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l0,0l-0.6,1.7
              l2.1,0.7l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8
              l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9
              c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.4,0.5l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l2,0.7l0.6-1.7
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.5-1.4
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9L574.8,271.5z"/>

            <rect x="543" y="275.4" transform="matrix(0.3211 -0.947 0.947 0.3211 109.1049 721.2477)" fill="#EF805F" width="29.3" height="18.3"/>
          </g>
          <polygon fill="#189AD7" points="643.1,314.2 600.5,284.7 598.5,292 640.1,319.9     "/>
          <polygon fill="#189AD7" points="637,322.9 594.4,293.3 592.3,300.6 633.9,328.5     "/>
          <polygon fill="#189AD7" points="629.7,333.8 604.6,316.5 603.4,320.8 627.9,337.1     "/>
        </g>
        <g>

          <rect x="598.8" y="251.1" transform="matrix(0.1409 -0.99 0.99 0.1409 210.8291 956.6173)" fill="#96CBCC" width="115.6" height="211.5"/>
          <path fill="#8BB3B2" d="M560.1,284.7l74.5,71.8c10.7,10.3,29.7,13.4,42.2,6.8l92.7-48.8
            L560.1,284.7z"/>
          <g>
            <path fill="#0B99D6" d="M670,350.8c9.5,1.3,16,10.1,14.7,19.6s-10.1,16-19.6,14.7
              s-16-10.1-14.7-19.6c0.6-4.5,3.1-8.6,6.7-11.4C660.8,351.3,665.5,350.1,670,350.8z M653.2,365.9c-1.1,8,4.4,15.3,12.4,16.4
              s15.3-4.4,16.4-12.4c1.1-8-4.4-15.3-12.4-16.4c-3.8-0.5-7.7,0.5-10.8,2.8C655.8,358.6,653.7,362.1,653.2,365.9z"/>
            <path fill="#0B99D6" d="M666.6,360.8c0,0-4.3-10.1-11.8-6.5c-3.1,1.5-4.9,2.6-6.1,3.3
              c-2.5,1.5-2.9,1.7-5,0.9c-1.1-0.5-1.9-1.5-2.8-2.8c-1.6-2.3-3.8-5.6-9.6-6.3c-4.8-0.6-6,0.5-7.4,1.9c-1.7,1.8-4,3.1-6.5,3.6
              c-4.3,1-7.3,0.2-9.5-1.3c-1.9-1.4-3.3-3.3-4.2-5.4c-1.3-2.9-5.2-3.4-5.2-3.4c-0.5-0.1-0.9-0.5-0.8-1s0.5-0.9,1-0.8c0,0,0,0,0.1,0
              c0,0,4.9,0.7,6.6,4.5c0.8,1.9,2,3.5,3.6,4.7c1.8,1.2,4.3,1.9,8,1c2.1-0.4,4.1-1.5,5.6-3.1c1.8-1.7,3.2-3.1,8.9-2.4
              c6.7,0.8,9.1,4.4,10.9,7c0.5,0.9,1.1,1.6,1.9,2.2c1.3,0.5,1.6,0.3,3.4-0.8c1.3-0.8,3.2-1.9,6.3-3.4c9.2-4.5,14.3,7.4,14.3,7.4
              c0.2,0.5,0,1-0.5,1.2C667.3,361.4,666.8,361.2,666.6,360.8L666.6,360.8L666.6,360.8z M664.2,377.6c0.2,0.4,0.8,0.6,1.3,0.4
              c0.4-0.2,0.6-0.7,0.4-1.1c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.2,1.3-3.4,0.8c-0.8-0.6-1.5-1.3-1.9-2.2
              c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.5,1.6-3.5,2.7-5.6,3.1c-3.7,0.9-6.2,0.2-8-1c-1.6-1.2-2.8-2.8-3.6-4.7
              c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-0.9,0.3-1,0.8c0,0.5,0.3,0.9,0.8,1c0,0,3.9,0.5,5.2,3.4c0.9,2.2,2.3,4,4.2,5.5
              c2.2,1.5,5.2,2.3,9.5,1.3c2.5-0.5,4.7-1.7,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8,3.9,9.7,6.3c0.9,1.3,1.6,2.4,2.8,2.8
              c2.1,0.9,2.5,0.6,5-0.9c1.2-0.7,3.1-1.8,6.1-3.3C659.9,367.5,664.2,377.6,664.2,377.6L664.2,377.6z M664.8,373.4
              c0.2,0.5,0.7,0.7,1.2,0.5s0.7-0.7,0.5-1.2c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.2,1.3-3.4,0.8
              c-0.8-0.6-1.5-1.3-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.5,1.6-3.5,2.7-5.6,3.1c-3.7,0.9-6.2,0.2-8-1
              c-1.6-1.2-2.8-2.8-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5,0-0.9,0.3-1,0.8c0,0.5,0.3,0.9,0.8,1c0,0,3.9,0.5,5.2,3.4
              c0.9,2.2,2.3,4,4.2,5.4c2.2,1.5,5.2,2.3,9.5,1.3c2.5-0.5,4.7-1.7,6.4-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8,3.9,9.6,6.3
              c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.5,0.6,5-0.9c1.2-0.7,3.1-1.8,6.1-3.3C660.4,363.3,664.7,373.4,664.8,373.4L664.8,373.4z
               M665.4,369.2c0.2,0.5,0.7,0.7,1.2,0.5s0.7-0.7,0.5-1.2l0,0c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4
              c-1.8,1.1-2.2,1.3-3.4,0.8c-0.8-0.6-1.5-1.3-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.5,1.6-3.5,2.7-5.6,3.1
              c-3.7,0.9-6.2,0.2-8-1c-1.6-1.2-2.8-2.8-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-1,0.3-1,0.8c-0.1,0.5,0.3,1,0.8,1
              c0,0,3.9,0.5,5.2,3.4c0.9,2.2,2.3,4,4.2,5.4c2.2,1.5,5.2,2.3,9.5,1.3c2.5-0.5,4.7-1.7,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9
              c5.8,0.7,8,3.9,9.6,6.2c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.5,0.6,5-0.9c1.2-0.7,3.1-1.8,6.1-3.3
              C661,359.2,665.3,369.3,665.4,369.2L665.4,369.2z M667.2,365.5c0.5-0.2,0.7-0.7,0.5-1.2l0,0c0,0-5.1-11.9-14.3-7.4
              c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.1,1.3-3.4,0.8c-0.8-0.6-1.5-1.3-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4
              c-1.5,1.6-3.4,2.7-5.6,3.1c-3.7,0.9-6.2,0.2-8-1c-1.6-1.2-2.8-2.8-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-1,0.3-1,0.8
              s0.3,0.9,0.7,1c0,0,3.9,0.5,5.2,3.4c0.9,2.2,2.3,4,4.2,5.5c2.2,1.5,5.2,2.3,9.5,1.3c2.5-0.5,4.7-1.7,6.5-3.6
              c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8,3.9,9.6,6.3c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.5,0.6,5-0.9c1.2-0.7,3.1-1.8,6.1-3.3
              c7.6-3.7,11.9,6.4,11.9,6.4C666.2,365.5,666.7,365.7,667.2,365.5L667.2,365.5z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M604.2,496.5L841.3,420l-41.8-129.6l-237.1,76.5L604.2,496.5z
             M609.8,486.1L572.9,372l221-71.3l36.8,114.2L609.8,486.1z"/>

          <rect x="585.7" y="333.4" transform="matrix(0.9517 -0.307 0.307 0.9517 -86.8936 234.4553)" fill="#D3AC89" width="232.2" height="120"/>
          <polygon fill="#0B99D6" points="614.6,493.1 595.8,470.5 602.9,492.3 605.8,495.9     "/>
          <path fill="#0B99D6" d="M632,487.5l-5.6-6.7l-8.7,2.8l5.6,6.7L632,487.5z M596.3,444.5
            L581.8,427l7,21.8l14.5,17.5L596.3,444.5z"/>
          <path fill="#0B99D6" d="M649.5,481.8l-5.5-6.7l-8.7,2.8l5.6,6.7L649.5,481.8L649.5,481.8
            z M582.2,400.8l-14.5-17.5l7,21.8l14.5,17.5L582.2,400.8z"/>
          <path fill="#0B99D6" d="M667,476.2l-5.6-6.7l-8.7,2.8l5.6,6.7L667,476.2z M578.9,370.1
            l-5.6-6.7l-8.7,2.8l10.6,12.8l-2.2-7L578.9,370.1z"/>
          <path fill="#0B99D6" d="M684.5,470.5l-5.6-6.7l-8.7,2.8l5.6,6.7L684.5,470.5z
             M596.3,364.4l-5.6-6.7l-8.8,2.8l5.6,6.7L596.3,364.4z"/>
          <path fill="#0B99D6" d="M702,464.9l-5.6-6.7l-8.8,2.8l5.6,6.7L702,464.9L702,464.9z
             M613.8,358.8l-5.6-6.7l-8.7,2.8l5.6,6.7L613.8,358.8z"/>
          <path fill="#0B99D6" d="M719.5,459.3l-5.6-6.7l-8.7,2.8l5.6,6.7L719.5,459.3z
             M631.3,353.1l-5.6-6.7l-8.7,2.8l5.6,6.7L631.3,353.1z"/>
          <path fill="#0B99D6" d="M736.9,453.6l-5.6-6.7l-8.7,2.8l5.6,6.7L736.9,453.6z
             M648.8,347.5l-5.6-6.7l-8.7,2.8l5.6,6.7L648.8,347.5z"/>
          <path fill="#0B99D6" d="M754.4,448l-5.6-6.7l-8.7,2.8l5.6,6.7L754.4,448z M666.3,341.9
            l-5.6-6.7L652,338l5.6,6.7L666.3,341.9z"/>
          <path fill="#0B99D6" d="M771.9,442.4l-5.6-6.7l-8.7,2.8l5.6,6.7L771.9,442.4z
             M683.8,336.2l-5.6-6.7l-8.7,2.8l5.6,6.7L683.8,336.2z"/>
          <path fill="#0B99D6" d="M789.4,436.7l-5.6-6.7l-8.7,2.8l5.6,6.7L789.4,436.7L789.4,436.7
            z M701.2,330.6l-5.6-6.7l-8.7,2.8l5.6,6.7L701.2,330.6z"/>
          <path fill="#0B99D6" d="M806.9,431.1l-5.6-6.7l-8.7,2.8l5.6,6.7L806.9,431.1z M718.7,325
            l-5.6-6.7l-8.7,2.7l5.6,6.7L718.7,325z"/>
          <path fill="#0B99D6" d="M824.3,425.4l-5.6-6.7l-8.8,2.8l5.6,6.7L824.3,425.4L824.3,425.4
            z M736.2,319.3l-5.6-6.7l-8.7,2.8l5.6,6.7L736.2,319.3z"/>
          <path fill="#0B99D6" d="M840.9,418.7l-14.5-17.5l4.4,13.6l-3.3,1l5.6,6.7l8.2-2.6
            L840.9,418.7L840.9,418.7z M753.7,313.7l-5.6-6.7l-8.7,2.8l5.6,6.7L753.7,313.7z"/>
          <path fill="#0B99D6" d="M826.8,375.1l-14.5-17.5l7,21.8l14.5,17.5L826.8,375.1
            L826.8,375.1z M771.2,308.1l-5.6-6.7l-8.7,2.8l5.6,6.7L771.2,308.1z"/>
          <path fill="#0B99D6" d="M812.8,331.4l-14.5-17.5l7,21.8l14.5,17.5L812.8,331.4z
             M788.6,302.4l-5.6-6.7l-8.7,2.8l5.6,6.7L788.6,302.4z"/>
          <polygon fill="#0B99D6" points="791.8,292.9 805.7,309.6 799.5,290.4     "/>
          <g>
            <path fill="#80CAF0" d="M751.6,419.9c8.3-2.6,12.8-11.5,10.2-19.8s-11.5-12.8-19.8-10.2
              c-8.3,2.6-12.8,11.5-10.2,19.8c1.3,4,4.1,7.3,7.8,9.2C743.3,420.9,747.6,421.2,751.6,419.9z M758.5,411
              c-3.3,6.5-11.3,9.1-17.8,5.7s-9.1-11.3-5.7-17.8c3.3-6.5,11.3-9.1,17.8-5.7c3.1,1.6,5.5,4.4,6.6,7.7
              C760.4,404.3,760.1,407.9,758.5,411L758.5,411z"/>
            <path fill="#80CAF0" d="M750.4,410.4c-0.4-0.3-0.9-0.2-1.2,0.1
              c-0.3,0.3-0.2,0.8,0.1,1.1c0,0,8.9,7.7,14.6,0.3c1.9-2.5,3-4.1,3.8-5.2c1.1-1.6,1.2-1.9,2.5-2c0.9,0.1,1.7,0.5,2.4,1
              c2.5,1.4,6,3.4,11.7,1.4c4.9-1.7,5.5-3.4,6.3-5.5c0.6-1.9,1.7-3.6,3.3-4.8c2.7-2.2,5-2.7,6.9-2.4c1.8,0.4,3.4,1.2,4.8,2.4
              c2.9,2.4,7.2,1,7.2,1c0.4-0.1,0.7-0.6,0.5-1.1c-0.1-0.4-0.6-0.7-1-0.5c0,0-3.4,1.1-5.6-0.7c-1.6-1.4-3.5-2.4-5.6-2.8
              c-2.4-0.4-5.2,0.2-8.2,2.7c-1.8,1.4-3.2,3.3-3.8,5.5c-0.6,1.7-1.1,3.1-5.3,4.5c-5,1.8-8.1,0-10.4-1.3c-1-0.7-2.2-1.1-3.4-1.2
              c-2.1,0.1-2.3,0.5-3.8,2.7c-0.7,1.1-1.8,2.7-3.7,5.1C758,416.9,750.5,410.3,750.4,410.4L750.4,410.4z M745.7,395.7
              c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3
              c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7
              c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.2,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4
              c-1.6,1.2-2.8,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.3,0.1-1.4,0.4-2.5,2
              c-0.7,1.1-1.8,2.8-3.8,5.2c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2C744.8,395.5,745.4,395.5,745.7,395.7
              L745.7,395.7z M746.9,399.4c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2
              c2.2,1.3,5.3,3,10.4,1.3c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8
              c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1l0,0c0,0-4.3,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4
              c-2-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1
              c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.2c-5.8,7.3-14.6-0.3-14.6-0.3c-0.4-0.3-0.4-0.8-0.1-1.2
              C746,399.1,746.5,399.1,746.9,399.4L746.9,399.4L746.9,399.4z M748.1,403c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1
              c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5
              c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1
              c0,0-4.2,1.4-7.2-1c-1.4-1.2-3-2-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.7,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5
              c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.2,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.2c-5.8,7.3-14.6-0.3-14.6-0.3
              c-0.3-0.3-0.4-0.8-0.1-1.2C747.2,402.8,747.7,402.7,748.1,403L748.1,403z M749.3,406.7c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1
              c1.5-2.2,1.7-2.6,3.8-2.7c1.2,0.1,2.4,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3c4.2-1.5,4.7-2.8,5.3-4.5c0.7-2.2,2-4.1,3.8-5.5
              c3.1-2.5,5.9-3.1,8.2-2.7c2.1,0.4,4,1.4,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1.1
              c0,0-4.2,1.4-7.2-1.1c-1.4-1.2-3-2-4.8-2.4c-2-0.3-4.3,0.2-6.9,2.4c-1.6,1.2-2.8,2.9-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5
              c-5.8,2-9.2,0-11.7-1.4c-0.7-0.5-1.6-0.9-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.2c-5.8,7.4-14.6-0.3-14.6-0.3
              c-0.3-0.3-0.4-0.8-0.1-1.2C748.4,406.5,748.9,406.4,749.3,406.7L749.3,406.7z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M718.6,348.2l2-0.6l0,0c0.2,0.8,1,1.2,1.8,0.9s1.2-1,0.9-1.8l0,0l1.3-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l0,0l0,0
              l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1,1.2,1.7,1c0.8-0.2,1.2-1,1-1.7
              c0-0.1,0-0.1,0-0.1l0,0l1.2-0.4l0,0c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l0,0l2-0.7l0.5,1.7c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1
              h0.1l0.4,1.2c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0
              l0.4,1.2c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0.4,1.3c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0.4,1.2
              c-0.7,0.3-1.1,1.1-0.8,1.9c0.3,0.7,1,1,1.8,0.8l0.4,1.2c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1h0.1l0.4,1.2c-0.7,0.3-1.1,1.1-0.9,1.8
              c0.3,0.7,1,1.1,1.7,0.9l0.5,1.7l-2,0.6l0,0c-0.2-0.8-1-1.2-1.8-0.9l0,0c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.2,0.4l0,0
              c-0.2-0.8-1-1.2-1.7-1c-0.8,0.2-1.2,1-1,1.7c0,0.1,0,0.1,0,0.1l0,0l-1.2,0.4l0,0c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8v0.1l0,0
              l-1.2,0.4l0,0c-0.2-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l0,0l0,0l-1.3,0.4l0,0c-0.2-0.8-1-1.2-1.8-0.9s-1.2,1-0.9,1.8l0,0
              l-2,0.7l-0.5-1.7c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l-0.4-1.2c0.8-0.2,1.2-1,1-1.7c-0.2-0.8-1-1.2-1.7-1c-0.1,0-0.1,0-0.1,0
              l-0.4-1.2c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.4-1.2c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9
              l-0.4-1.3c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,1-1.7c-0.2-0.8-1-1.2-1.7-1c-0.1,0-0.1,0-0.1,0l-0.4-1.2c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1L718.6,348.2z"/>

            <rect x="726.3" y="346.9" transform="matrix(0.9517 -0.307 0.307 0.9517 -75.4335 243.1907)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M791.2,336.4l0.8,2.4l0,0c-0.9,0.2-1.5,1.1-1.4,2s1.1,1.5,2,1.4c0.1,0,0.2-0.1,0.3-0.1l0,0l0.5,1.6l0,0c-0.9,0.3-1.3,1.3-1,2.2
              c0.3,0.8,1.2,1.3,2,1l0,0l0.5,1.4l0,0c-0.9,0.2-1.5,1.2-1.2,2.1s1.2,1.5,2.1,1.2c0.1,0,0.1,0,0.2-0.1l0,0l0.5,1.4l0,0
              c-0.9,0.2-1.5,1.2-1.2,2.1s1.2,1.5,2.1,1.2c0.1,0,0.1,0,0.2-0.1l0,0l0.5,1.4l0,0c-0.9,0.2-1.5,1.2-1.2,2.1s1.2,1.5,2.1,1.2
              c0.1,0,0.1,0,0.2-0.1l0,0l0.8,2.4l-2,0.6c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.4,0.5
              c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.4,0.5c-0.2-0.9-1.2-1.5-2.1-1.2
              c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1s-1.4,1.3-1.1,2.2l0,0L779,370
              c-0.3-0.9-1.3-1.3-2.2-1c-0.8,0.3-1.3,1.2-1,2l-1.4,0.5c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2
              l-1.4,0.5c-0.2-0.9-1.2-1.5-2.1-1.2c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-1.4,0.5c-0.2-0.9-1.2-1.5-2.1-1.2
              c-0.9,0.2-1.5,1.2-1.2,2.1c0,0.1,0,0.1,0.1,0.2l-2,0.6l-0.8-2.4l0,0c0.9-0.2,1.5-1.1,1.4-2s-1.1-1.5-2-1.4
              c-0.1,0-0.2,0.1-0.3,0.1l0,0l-0.5-1.4l0,0c0.9-0.2,1.5-1.2,1.2-2.1s-1.2-1.5-2.1-1.2c-0.1,0-0.1,0-0.2,0.1l0,0l-0.5-1.4l0,0
              c0.9-0.2,1.5-1.2,1.2-2.1s-1.2-1.5-2.1-1.2c-0.1,0-0.1,0-0.2,0.1l0,0l-0.5-1.4l0,0c0.9-0.2,1.5-1.2,1.2-2.1s-1.2-1.5-2.1-1.2
              c-0.1,0-0.1,0-0.2,0.1l0,0L753,355l0,0c0.9-0.4,1.3-1.4,0.9-2.2c-0.3-0.7-1.1-1.2-1.9-1l0,0l-0.8-2.4l2-0.6
              c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.3-1.2,1-2l1.4-0.5c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.3-1.2,1-2l1.4-0.5c0.2,0.9,1.2,1.5,2.1,1.2
              c0.9-0.2,1.5-1.2,1.2-2.1c0-0.1,0-0.1-0.1-0.2l1.4-0.5c0.3,0.9,1.3,1.3,2.2,1c0.8-0.3,1.3-1.2,1-2l1.6-0.5c0.2,0.9,1.1,1.5,2,1.4
              c0.9-0.2,1.5-1.1,1.4-2c0-0.1-0.1-0.2-0.1-0.3l1.4-0.5c0.2,0.9,1.1,1.5,2.1,1.3c0.9-0.2,1.5-1.1,1.3-2.1c0-0.1-0.1-0.3-0.1-0.4
              l1.4-0.5c0.2,0.9,1.2,1.5,2.1,1.2c0.9-0.2,1.5-1.2,1.2-2.1c0-0.1,0-0.1-0.1-0.2l1.4-0.5c0.3,0.9,1.3,1.3,2.2,1
              c0.8-0.3,1.3-1.2,1-2L791.2,336.4z"/>

            <rect x="758.3" y="345.6" transform="matrix(0.9517 -0.307 0.307 0.9517 -71.9514 255.3032)" fill="#FBD4A4" width="34.5" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M771.7,315.6l0.7,2l0,0c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1h0.1l0,0l0.4,1.4l0,0c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0,0
              l0.4,1.2l0,0c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1h0.1l0,0l0.4,1.2l0,0c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0,0l0.4,1.2
              l0,0c-0.7,0.3-1.1,1.1-0.9,1.8c0.3,0.7,1,1.1,1.7,0.9l0,0l0.7,2l-1.7,0.5c-0.2-0.8-1-1.2-1.7-1c-0.8,0.2-1.2,1-1,1.7
              c0,0.1,0,0.1,0,0.1l-1.2,0.4c-0.2-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8v0.1
              l-1.2,0.4c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l-1.3,0.4c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8v0.1l-1.2,0.4
              c-0.3-0.7-1.1-1.1-1.8-0.9c-0.7,0.3-1.1,1-0.9,1.7l-1.2,0.4c-0.2-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l-1.2,0.4
              c-0.2-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l0,0l-1.7,0.5l-0.7-2l0,0c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l0,0
              l-0.4-1.2l0,0c0.7-0.3,1.1-1.1,0.9-1.8c-0.3-0.7-1-1.1-1.7-0.9l0,0l-0.4-1.2l0,0c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1l0,0
              l-0.4-1.2l0,0c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l0,0l0,0l-0.4-1.3l0,0c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1h-0.1
              l0,0l-0.7-2l1.7-0.5c0.2,0.8,1,1.2,1.8,0.9s1.2-1,0.9-1.8l1.2-0.4c0.2,0.8,1,1.2,1.8,0.9s1.2-1,0.9-1.8l0,0l1.2-0.4
              c0.3,0.7,1.1,1.1,1.8,0.9c0.7-0.3,1.1-1,0.9-1.7l1.2-0.4c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l1.3-0.4c0.3,0.7,1.1,1.1,1.8,0.9
              c0.7-0.3,1.1-1,0.9-1.7l1.2-0.4c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8v-0.1l1.2-0.4c0.2,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l1.2-0.4
              c0.3,0.7,1.1,1.1,1.8,0.9c0.7-0.3,1.1-1,0.9-1.7L771.7,315.6z"/>

            <rect x="744.1" y="323.4" transform="matrix(0.9517 -0.307 0.307 0.9517 -65.4229 248.9286)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="609.8,401.8 698.6,370.1 698.9,383.9 612.4,413.1     "/>
        </g>
        <g>
          <path fill="#E2070F" d="M824.3,375.1l7.6,249L968,620l-7.6-249L824.3,375.1L824.3,375.1z
             M832.7,383.3l119.9-3.6l7.1,232.1l-119.9,3.6L832.7,383.3z"/>

          <rect x="836.2" y="381.4" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -14.7749 27.6006)" fill="#E0DCC1" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="824.6,386 851.5,374.3 828.6,375 824.3,376.9     "/>
          <path fill="#0B99D6" d="M825.2,404.4l8-3.5l-0.3-9.2l-8,3.5L825.2,404.4z M876.5,382
            l20.8-9.1l-22.9,0.7l-20.8,9.1L876.5,382z"/>
          <path fill="#0B99D6" d="M825.7,422.8l8-3.5l-0.3-9.2l-8,3.5L825.7,422.8z M922.3,380.6
            l20.9-9.1l-22.9,0.7l-20.8,9.1L922.3,380.6z"/>
          <path fill="#0B99D6" d="M826.3,441.1l8-3.5l-0.3-9.2l-8,3.5L826.3,441.1z M952.7,385.9
            l8-3.5l-0.3-9.2l-15.2,6.6l7.3-0.2L952.7,385.9z"/>
          <path fill="#0B99D6" d="M826.8,459.5l8-3.5l-0.3-9.2l-8,3.5L826.8,459.5z M953.3,404.3
            l8-3.5l-0.3-9.2l-8,3.5L953.3,404.3z"/>
          <path fill="#0B99D6" d="M827.4,477.8l8-3.5l-0.3-9.2l-8,3.5L827.4,477.8z M953.8,422.6
            l8-3.5l-0.3-9.2l-8,3.5L953.8,422.6z"/>
          <path fill="#0B99D6" d="M828,496.2l8-3.5l-0.3-9.2l-8,3.5L828,496.2z M954.4,441l8-3.5
            l-0.3-9.2l-8,3.5L954.4,441z"/>
          <path fill="#0B99D6" d="M828.5,514.5l8-3.5l-0.3-9.2l-8,3.5L828.5,514.5z M955,459.4
            l8-3.5l-0.3-9.2l-8,3.5L955,459.4z"/>
          <path fill="#0B99D6" d="M829.1,532.9l8-3.5l-0.3-9.2l-8,3.5L829.1,532.9z M955.5,477.7
            l8-3.5l-0.3-9.2l-8,3.5L955.5,477.7z"/>
          <path fill="#0B99D6" d="M829.7,551.3l8-3.5l-0.3-9.2l-8,3.5L829.7,551.3z M956.1,496.1
            l8-3.5l-0.3-9.2l-8,3.5L956.1,496.1z"/>
          <path fill="#0B99D6" d="M830.2,569.6l8-3.5l-0.3-9.2l-8,3.5L830.2,569.6z M956.7,514.5
            l8-3.5l-0.3-9.2l-8,3.5L956.7,514.5z"/>
          <path fill="#0B99D6" d="M830.8,588l8-3.5l-0.3-9.2l-8,3.5L830.8,588z M957.2,532.8l8-3.5
            l-0.3-9.2l-8,3.5L957.2,532.8z"/>
          <path fill="#0B99D6" d="M831.3,606.3l8-3.5l-0.3-9.2l-8,3.5L831.3,606.3z M957.8,551.2
            l8-3.5l-0.3-9.2l-8,3.5L957.8,551.2z"/>
          <path fill="#0B99D6" d="M833.2,624.1L854,615l-14.3,0.4l-0.1-3.4l-8,3.5l0.3,8.6H833.2
            L833.2,624.1z M958.3,569.5l8-3.5l-0.3-9.2l-8,3.5L958.3,569.5z"/>
          <path fill="#0B99D6" d="M879,622.7l20.8-9.1l-22.9,0.7l-20.8,9.1L879,622.7z
             M958.8,587.9l8-3.5l-0.3-9.2l-8,3.5L958.8,587.9z"/>
          <g>
            <path fill="#80CAF0" d="M856.8,538c0.3,8.7,7.5,15.5,16.2,15.2s15.5-7.5,15.2-16.2
              s-7.5-15.5-16.2-15.2c-4.2,0.1-8.1,1.9-11,4.9C858.2,529.8,856.7,533.8,856.8,538z M863.5,547.1c-5.3-5-5.6-13.4-0.6-18.7
              s13.4-5.6,18.7-0.6s5.6,13.4,0.6,18.7c-2.4,2.6-5.7,4-9.2,4.2C869.4,550.8,866,549.6,863.5,547.1L863.5,547.1z"/>
            <path fill="#80CAF0" d="M866.3,539.5c0.4-0.3,0.5-0.8,0.2-1.1c-0.3-0.4-0.8-0.5-1.1-0.2
              l0,0c0,0-9.8,6.4-4.4,14c1.8,2.5,3.1,4.1,4,5.1c1.3,1.5,1.5,1.7,1.2,3c-0.4,0.8-0.9,1.5-1.7,2.1c-2.1,2-4.9,4.8-4.6,10.9
              c0.3,5.2,1.8,6.3,3.6,7.6c1.7,1.1,3,2.7,3.7,4.5c1.3,3.2,1.2,5.5,0.3,7.3c-0.8,1.6-2.1,3-3.7,3.9c-3.1,2.1-3,6.6-3,6.6
              c0,0.4,0.4,0.8,0.8,0.8l0,0c0.5,0,0.8-0.4,0.8-0.8l0,0c0,0-0.1-3.5,2.3-5.2c1.8-1.1,3.3-2.7,4.2-4.6c1-2.2,1.3-5-0.3-8.7
              c-0.8-2.1-2.3-4-4.2-5.2c-1.5-1-2.7-1.9-2.9-6.3c-0.3-5.3,2.3-7.8,4.1-9.6c1-0.7,1.7-1.8,2.1-2.9c0.4-2,0.2-2.4-1.6-4.4
              c-0.8-1-2.1-2.5-3.9-5C857.9,545,866.3,539.6,866.3,539.5L866.3,539.5z M881.8,539.1c0,0-8.4,5.4-4,11.6c1.8,2.5,3.1,4,3.9,5
              c1.7,2,2,2.4,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6c0.2,4.4,1.4,5.3,2.9,6.3c1.9,1.3,3.4,3.1,4.2,5.2
              c1.6,3.7,1.3,6.5,0.3,8.7c-1,1.9-2.4,3.5-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2c-0.1,0.5-0.5,0.8-0.9,0.7c-0.4,0-0.7-0.4-0.7-0.7
              c0,0-0.1-4.5,3-6.6c1.6-1,2.9-2.3,3.7-3.9c0.8-1.8,1-4.2-0.3-7.3c-0.7-1.8-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6
              c-0.3-6.1,2.5-8.9,4.6-10.9c0.7-0.5,1.3-1.2,1.7-2.1c0.3-1.2,0-1.5-1.2-3c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14
              c0.4-0.3,0.9-0.2,1.1,0.2l0,0C882.3,538.3,882.2,538.8,881.8,539.1L881.8,539.1z M877.9,539.2c0,0-8.4,5.4-4,11.6
              c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.2,2.2-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6c0.2,4.4,1.4,5.3,2.9,6.3
              c1.9,1.3,3.4,3.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7c-1,1.9-2.4,3.5-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2c0,0.4-0.3,0.8-0.8,0.8l0,0
              c-0.5,0-0.8-0.3-0.9-0.8l0,0c0,0-0.1-4.5,3-6.6c1.6-1,2.8-2.3,3.7-3.9c0.8-1.8,1-4.2-0.3-7.3c-0.7-1.9-2-3.4-3.7-4.5
              c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.7-0.5,1.3-1.2,1.6-2.1c0.3-1.2,0-1.5-1.2-3c-0.9-1-2.2-2.5-4-5.1
              c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.2,1.2,0.2S878.3,538.8,877.9,539.2C877.9,539.1,877.9,539.1,877.9,539.2L877.9,539.2z
               M874,539.3c0,0-8.4,5.4-4,11.6c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6
              c0.2,4.4,1.4,5.3,2.9,6.3c1.9,1.3,3.4,3.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7c-1,1.9-2.4,3.5-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2
              c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.4-0.8-0.8l0,0c0,0-0.1-4.5,3-6.6c1.5-1,2.8-2.3,3.7-3.9c0.8-1.8,1-4.2-0.3-7.3
              c-0.7-1.9-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.7-0.5,1.3-1.3,1.7-2.1c0.3-1.2,0-1.5-1.2-3
              c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.1,1.1,0.2C874.5,538.5,874.4,539,874,539.3L874,539.3L874,539.3z
               M870.2,539.4c0,0-8.4,5.4-4,11.6c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.3,1.6,4.4c-0.4,1.2-1.1,2.2-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6
              c0.2,4.4,1.4,5.3,2.9,6.3c1.9,1.3,3.4,3.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7c-1,1.9-2.4,3.5-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2
              c0,0.5-0.3,0.8-0.8,0.9l0,0c-0.5,0-0.8-0.3-0.9-0.8c0,0-0.1-4.5,3-6.6c1.5-1,2.8-2.3,3.7-3.9c0.8-1.8,1-4.2-0.3-7.3
              c-0.7-1.9-2-3.4-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.7-0.5,1.3-1.3,1.6-2.1c0.3-1.2,0-1.5-1.2-3
              c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.2,1.2,0.2S870.6,539.1,870.2,539.4L870.2,539.4L870.2,539.4z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M934.8,526.2l0.1,2.1l0,0c-0.8,0.1-1.4,0.8-1.3,1.6c0.1,0.7,0.7,1.2,1.4,1.3l0,0v1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0
              c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0
              c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0,0l0.1,2.1h-1.8c0-0.8-0.7-1.4-1.5-1.4
              s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.2c0-0.8-0.7-1.4-1.5-1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0
              h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.4c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0H913c0-0.8-0.7-1.4-1.5-1.4
              l0,0c-0.8,0-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.8
              l-0.1-2.1l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4
              l0,0l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4
              l0,0l0,0V532l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0l0,0l-0.1-2.1h1.8c0,0.8,0.7,1.4,1.5,1.4l0,0
              c0.8,0,1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4l0,0c0.8,0,1.4-0.7,1.4-1.5
              l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.4c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4l0,0
              c0.8,0,1.4-0.7,1.4-1.5l0,0h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4l0,0c0.8,0,1.4-0.7,1.4-1.5
              L934.8,526.2z"/>

            <rect x="903.2" y="529.5" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -16.017 28.2748)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M926.1,599.3l-2.5,0.1l0,0c0-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.7,0.8-1.7,1.8l0,0l0,0h-1.7l0,0c0-0.9-0.7-1.7-1.6-1.8
              s-1.7,0.7-1.8,1.6c0,0.1,0,0.2,0,0.2l0,0h-1.5l0,0c0-0.9-0.7-1.7-1.6-1.8s-1.7,0.7-1.8,1.6c0,0.1,0,0.2,0,0.2l0,0h-1.5l0,0
              c-0.1-0.9-0.9-1.6-1.9-1.5c-0.8,0.1-1.5,0.8-1.5,1.6l0,0h-1.5l0,0c0-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.7,0.8-1.7,1.8l0,0l-2.5,0.1
              l-0.1-2.1c0.9,0,1.7-0.7,1.8-1.6s-0.7-1.7-1.6-1.8c-0.1,0-0.2,0-0.2,0V593c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7l0,0V588
              c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7V583c0.9,0,1.7-0.7,1.8-1.6s-0.7-1.7-1.6-1.8c-0.1,0-0.2,0-0.2,0v-1.7
              c0.9-0.1,1.6-0.9,1.5-1.9c-0.1-0.8-0.8-1.5-1.6-1.5V573c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7V568c0.9,0,1.7-0.8,1.7-1.8
              c0-0.9-0.8-1.7-1.8-1.7l0,0V563c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7l0,0l-0.1-2.1l2.5-0.1l0,0c0,0.9,0.8,1.7,1.8,1.7
              c0.9,0,1.7-0.8,1.7-1.8l0,0l0,0h1.5l0,0c0,0.9,0.7,1.7,1.6,1.8s1.7-0.7,1.8-1.6c0-0.1,0-0.2,0-0.2l0,0h1.5l0,0
              c0,0.9,0.7,1.7,1.6,1.8s1.7-0.7,1.8-1.6c0-0.1,0-0.2,0-0.2l0,0h1.5l0,0c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0l0,0
              l1.7-0.1l0,0c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0l2.5-0.1l0.1,2.1c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7
              v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5
              c-0.9,0-1.7,0.7-1.8,1.6s0.7,1.7,1.6,1.8c0.1,0,0.2,0,0.2,0v1.7c-0.9,0.1-1.6,0.9-1.5,1.9c0.1,0.8,0.8,1.5,1.6,1.5v1.5
              c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7l0,0v1.5
              c-0.9,0-1.7,0.7-1.8,1.6s0.7,1.7,1.6,1.8c0.1,0,0.2,0,0.2,0V599.3z"/>

            <rect x="900.6" y="561.5" transform="matrix(0.9995 -3.036524e-02 3.036524e-02 0.9995 -17.1529 27.9392)" fill="#FBD4A4" width="21.5" height="34.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M951.4,586.3l-2.1,0.1l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0H945l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l0,0
              h-1.2l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0h-1.2l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l0,0h-1.2
              l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5l0,0l-2.1,0.1v-1.8c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0
              V581c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4v-1.2c0.8-0.1,1.4-0.8,1.3-1.6c-0.1-0.7-0.7-1.2-1.4-1.3v-1.2
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.4c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4v-1.2
              c0.8-0.1,1.4-0.8,1.3-1.6c-0.1-0.7-0.7-1.2-1.4-1.3v-1.2c0.7-0.1,1.1-0.7,1.2-1.4c0-0.8-0.7-1.4-1.5-1.4l0,0v-1.2
              c0.8,0,1.4-0.7,1.4-1.5l0,0c0-0.8-0.7-1.4-1.5-1.4l0,0v-1.8l2.1-0.1l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0l0,0h1.2l0,0
              c0,0.8,0.7,1.4,1.5,1.4l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0l0,0h1.2l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2l0,0
              c0,0.8,0.7,1.4,1.5,1.4l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0h1.4l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0c0.8,0,1.4-0.7,1.4-1.5l0,0l2.1-0.1
              l0.1,1.8c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4v1.2c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2
              c-0.8,0.1-1.4,0.8-1.3,1.6c0.1,0.7,0.7,1.2,1.4,1.3v1.2c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0v1.4
              c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0.1-1.4,0.8-1.3,1.6c0.1,0.7,0.7,1.2,1.4,1.3v1.2
              c-0.8,0-1.4,0.7-1.4,1.5l0,0c0,0.8,0.7,1.4,1.5,1.4l0,0v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0L951.4,586.3z"/>

            <rect x="930" y="554.6" transform="matrix(0.9995 -3.036524e-02 3.036524e-02 0.9995 -16.8457 28.7743)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="848.9" y="402.4" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -13.245 26.1737)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="937.9" y="395.2" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -12.9836 28.8883)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="927.5" y="395.8" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -13.0068 28.5709)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="916.7" y="395.6" transform="matrix(0.9995 -3.053954e-02 3.053954e-02 0.9995 -13.0058 28.241)" fill="#236095" width="2.7" height="88.6"/>
        </g>
      </g>
      <g id="letters3">
        <g>
          <polygon fill="#FFFFFF" points="970.5,523.2 849.7,338.9 985.5,287.8 1106.3,472.2     "/>
          <path fill="#E6E6E5" d="M849.7,338.9l119.1,46.3c17.1,6.7,28.5,23.3,25.4,36.9
            l-23.7,101.2L849.7,338.9z"/>
          <g>
            <path fill="#0B99D6" d="M975.9,419.8c-2.6-3.8-3-8.7-1-12.9c1.9-4.1,5.9-7.7,11.5-9.8
              c5.5-2,11.5-2.4,17.2-1c5.5,1.4,10.2,4.3,12.9,8.4c2.6,3.8,3,8.7,1,12.9c-1.9,4.1-5.9,7.7-11.5,9.8c-5.5,2-11.5,2.4-17.2,1
              C983.3,426.8,978.6,423.9,975.9,419.8L975.9,419.8z M978.2,407.7c-1.7,3.5-1.3,7.6,0.9,10.8c2.3,3.5,6.3,6,10.9,7.1
              c4.8,1.2,9.9,0.9,14.5-0.8c4.7-1.8,8.1-4.8,9.7-8.3c1.7-3.5,1.3-7.6-0.9-10.8c-2.3-3.5-6.3-6-10.9-7.1c-4.8-1.2-9.9-0.9-14.5,0.8
              C983.2,401.2,979.8,404.2,978.2,407.7z"/>
            <path fill="#0B99D6" d="M987,413.4c0.7,0,1.1,0.5,1.1,1c-0.2,0.6-0.7,0.9-1.3,0.8
              c0,0-16.8-1-14.9-10.9c0.4-2.3,1-4.6,1.7-6.9c0.6-2,0.7-2.4-0.4-3.4c-1.1-0.6-2.3-1-3.5-1.1c-4-0.8-9.5-2-13.1-7.9
              c-3.1-5-2-6.8-0.5-9c1.4-1.7,1.9-4,1.6-6.2c-0.2-3-1.8-5.7-4.5-7.1c-2.3-1.1-4.8-1.7-7.3-1.8c-3.3-0.3-6.3-2-8.2-4.7
              c-0.3-0.4,0-1,0.6-1.2s1.2-0.1,1.6,0.4c1.5,2.1,3.8,3.4,6.3,3.7c2.9,0.1,5.8,0.9,8.5,2.2c3.1,1.7,5.2,4.8,5.4,8.3
              c0.4,2.5-0.3,5.1-1.9,7.1c-1.2,1.8-2.1,3.2,0.5,7.5c3.2,5.2,8.1,6.2,11.6,7c2,0.4,3.6,0.8,4.6,1.7c1.9,1.7,1.8,2.2,0.9,5
              c-0.7,2.2-1.2,4.4-1.7,6.7C972.7,412.7,987,413.5,987,413.4L987,413.4z M1007,405.9c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7
              c0.8-2.8,1-3.2-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7c-2.6-4.3-1.7-5.7-0.5-7.5c1.6-2,2.2-4.6,1.9-7.1
              c-0.2-3.5-2.3-6.7-5.4-8.4c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7c-0.4-0.5-1-0.6-1.6-0.4s-0.8,0.8-0.5,1.2
              c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9
              c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1,1.4,0.4,3.4c-0.7,2.3-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9
              c0.6,0.1,1.1-0.3,1.3-0.8C1008.1,406.3,1007.6,405.9,1007,405.9L1007,405.9z M1002,407.8c0,0-14.3-0.8-12.8-8.9
              c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.3-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7c-2.6-4.3-1.7-5.7-0.5-7.4
              c1.6-2,2.3-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7c-0.4-0.5-1-0.6-1.6-0.4
              s-0.8,0.8-0.5,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1c0.3,2.2-0.3,4.4-1.6,6.2
              c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1.1,1.4,0.4,3.4c-0.7,2.2-1.3,4.5-1.7,6.9
              c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8C1003.1,408.2,1002.6,407.8,1002,407.8L1002,407.8z M997,409.6
              c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.2-0.9-5c-1-0.9-2.7-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7
              c-2.6-4.3-1.7-5.7-0.5-7.5c1.6-2,2.2-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.4c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7
              c-0.4-0.5-1-0.6-1.6-0.4s-0.8,0.8-0.5,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1
              c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1.1,1.4,0.4,3.4
              c-0.7,2.3-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8C998.1,410.1,997.6,409.7,997,409.6L997,409.6z
               M992,411.5c0,0-14.3-0.8-12.8-8.9c0.4-2.3,1-4.5,1.7-6.7c0.8-2.8,1-3.2-0.9-4.9c-1-0.9-2.6-1.2-4.6-1.7c-3.5-0.7-8.4-1.8-11.6-7
              c-2.6-4.3-1.7-5.7-0.5-7.4c1.6-2,2.2-4.6,1.9-7.1c-0.2-3.5-2.3-6.7-5.4-8.3c-2.6-1.3-5.5-2.1-8.5-2.2c-2.5-0.3-4.9-1.6-6.3-3.7
              c-0.4-0.5-1-0.6-1.6-0.4s-0.8,0.8-0.6,1.2c1.9,2.7,4.9,4.4,8.2,4.7c2.5,0.1,5,0.7,7.3,1.8c2.6,1.4,4.3,4.1,4.5,7.1
              c0.3,2.2-0.3,4.4-1.6,6.2c-1.4,2.2-2.6,3.9,0.5,9c3.7,5.9,9.2,7.1,13.1,7.9c1.2,0.1,2.4,0.5,3.5,1.1c1.2,1,1,1.4,0.4,3.4
              c-0.7,2.2-1.3,4.5-1.7,6.9c-1.9,10,14.9,10.9,14.9,10.9c0.6,0.1,1.1-0.3,1.3-0.8C993.1,412,992.6,411.6,992,411.5L992,411.5z"/>
          </g>
        </g>
        <g>
          <path fill="#A0D8F7" d="M824.8,289.9L651,383l50.9,95l173.8-93L824.8,289.9L824.8,289.9z
             M822,298.8l44.8,83.7l-162,86.8L660,385.5L822,298.8z"/>

          <rect x="671.4" y="336.6" transform="matrix(0.8816 -0.4721 0.4721 0.8816 -90.9051 405.8493)" fill="#CCCCCE" width="183.8" height="94.9"/>
          <g>
            <polygon fill="#0B99D6" points="817.2,294 835,308.9 826.4,293 823.6,290.6       "/>
            <path fill="#0B99D6" d="M804.4,300.9l5.3,4.5l6.4-3.4l-5.3-4.5L804.4,300.9z
               M838.3,329.3l13.8,11.6l-8.6-16l-13.8-11.6L838.3,329.3z"/>
            <path fill="#0B99D6" d="M791.6,307.7l5.3,4.5l6.4-3.4l-5.3-4.4L791.6,307.7L791.6,307.7
              z M855.5,361.3l13.8,11.6l-8.6-16l-13.8-11.6L855.5,361.3z"/>
            <path fill="#0B99D6" d="M778.8,314.6l5.3,4.4l6.4-3.4l-5.3-4.5L778.8,314.6L778.8,314.6
              z M862.4,384.8l5.3,4.5l6.4-3.4l-10.1-8.5l2.7,5.1L862.4,384.8z"/>
            <path fill="#0B99D6" d="M766,321.5l5.3,4.5l6.4-3.4l-5.3-4.5L766,321.5z M849.6,391.6
              l5.3,4.4l6.4-3.4l-5.3-4.5L849.6,391.6z"/>
            <path fill="#0B99D6" d="M753.1,328.3l5.3,4.5l6.4-3.4l-5.3-4.4L753.1,328.3L753.1,328.3
              z M836.8,398.5l5.3,4.5l6.4-3.4l-5.3-4.4L836.8,398.5z"/>
            <path fill="#0B99D6" d="M740.3,335.2l5.3,4.5l6.4-3.4l-5.3-4.5L740.3,335.2z M824,405.4
              l5.3,4.4l6.4-3.4l-5.3-4.5L824,405.4z"/>
            <path fill="#0B99D6" d="M727.5,342l5.3,4.5l6.4-3.4l-5.3-4.4L727.5,342L727.5,342z
               M811.2,412.2l5.3,4.5l6.4-3.4l-5.3-4.5L811.2,412.2z"/>
            <path fill="#0B99D6" d="M714.7,348.9l5.3,4.4l6.4-3.4l-5.3-4.5L714.7,348.9L714.7,348.9
              z M798.3,419.1l5.3,4.5l6.4-3.4l-5.3-4.4L798.3,419.1z"/>
            <path fill="#0B99D6" d="M701.9,355.8l5.3,4.5l6.4-3.4l-5.3-4.5L701.9,355.8z M785.5,426
              l5.3,4.4l6.4-3.4l-5.3-4.5L785.5,426z"/>
            <path fill="#0B99D6" d="M689.1,362.6l5.3,4.5l6.4-3.4l-5.3-4.4L689.1,362.6L689.1,362.6
              z M772.7,432.8l5.3,4.5l6.4-3.4l-5.3-4.5L772.7,432.8z"/>
            <path fill="#0B99D6" d="M676.3,369.5l5.3,4.4l6.4-3.4l-5.3-4.5L676.3,369.5L676.3,369.5
              z M759.9,439.7l5.3,4.5l6.4-3.4l-5.3-4.4L759.9,439.7z"/>
            <path fill="#0B99D6" d="M663.4,376.4l5.3,4.5l6.4-3.4l-5.3-4.4L663.4,376.4L663.4,376.4
              z M747.1,446.6l5.3,4.5l6.4-3.4l-5.3-4.5L747.1,446.6z"/>
            <path fill="#0B99D6" d="M651.5,384l13.8,11.6l-5.4-10l2.4-1.3l-5.3-4.5l-6,3.2
              L651.5,384L651.5,384z M734.3,453.5l5.3,4.5l6.4-3.4l-5.3-4.4L734.3,453.5z"/>
            <path fill="#0B99D6" d="M668.6,416l13.8,11.6l-8.5-16L660.1,400L668.6,416z
               M721.5,460.3l5.3,4.4l6.4-3.4l-5.3-4.5L721.5,460.3z"/>
            <path fill="#0B99D6" d="M685.8,448l13.8,11.6l-8.5-16L677.2,432L685.8,448z
               M708.6,467.2l5.3,4.5l6.4-3.4l-5.3-4.5L708.6,467.2z"/>
            <polygon fill="#0B99D6" points="707.5,475 694.3,464 701.9,478.1       "/>
          </g>
        </g>
        <g>

          <rect x="294" y="275" transform="matrix(0.1821 -0.9833 0.9833 0.1821 -79.3599 639.0262)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M444.3,334.4L362.8,373c-11.7,5.6-28.3,2.8-36.8-6.1l-63.1-66.1
            L444.3,334.4z"/>
          <g>
            <path fill="#0B99D6" d="M335.1,358.5c8.2,1.5,13.6,9.4,12.1,17.6s-9.4,13.6-17.6,12.1
              s-13.6-9.4-12.1-17.6c0.7-3.9,3-7.4,6.3-9.7C327.1,358.6,331.2,357.8,335.1,358.5z M342.8,366.1c-4-5.8-11.9-7.3-17.6-3.3
              s-7.3,11.9-3.3,17.6c4,5.8,11.9,7.3,17.6,3.3c2.8-1.9,4.7-4.8,5.3-8.1C345.4,372.3,344.7,368.9,342.8,366.1L342.8,366.1z"/>
            <path fill="#0B99D6" d="M335.2,367.7c-0.3,0.4-0.8,0.4-1.1,0.2
              c-0.4-0.3-0.4-0.8-0.2-1.1l0.1-0.1c0,0,7.5-8.4,13.9-2.1c2.1,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.6,2.6,1.6
              c0.8-0.2,1.6-0.7,2.2-1.3c2.2-1.7,5.3-4,11-2.8c4.9,1,5.7,2.6,6.7,4.5c0.8,1.7,2.1,3.2,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4
              c1.7-0.6,3.1-1.6,4.3-2.9c2.5-2.7,6.7-1.9,6.7-1.9c0.4,0.1,0.8,0.4,0.7,0.9c-0.1,0.4-0.4,0.8-0.9,0.7H396c0,0-3.4-0.6-5.2,1.4
              c-1.3,1.5-3.1,2.7-5,3.3c-2.2,0.6-4.9,0.5-8.2-1.6c-1.9-1.1-3.4-2.7-4.3-4.8c-0.8-1.5-1.4-2.8-5.6-3.7c-5-1-7.7,1-9.7,2.5
              c-0.8,0.8-1.9,1.4-3.1,1.6c-2,0.1-2.3-0.2-3.9-2.1c-0.8-1-2-2.4-4.1-4.4C341.5,360.6,335.2,367.7,335.2,367.7L335.2,367.7z
               M332.5,382.3c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6s-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.4-0.2,0.9,0.2,1.1
              C331.8,382.6,332.2,382.6,332.5,382.3L332.5,382.3z M333.1,378.7c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.2,0.9-0.6l0,0c0.1-0.4-0.2-0.8-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8-0.1,1.1c0.3,0.3,0.8,0.3,1.1,0.1C333.1,378.7,333.1,378.7,333.1,378.7L333.1,378.7z M333.8,375
              c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5
              c4.1,0.9,4.8,2.1,5.6,3.6c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3c1.9-2,5.2-1.4,5.2-1.4
              c0.4,0.1,0.9-0.2,1-0.6s-0.2-0.9-0.6-1h-0.1c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4
              c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6
              c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1S333.5,375.3,333.8,375L333.8,375L333.8,375z
               M334.5,371.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.6,1.9,1.9,2.2,3.9,2.1c1.2-0.2,2.2-0.8,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.9,2,2.4,3.7,4.3,4.8c3.2,2,6,2.2,8.2,1.6c1.9-0.6,3.7-1.8,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.8-0.3,0.9-0.7c0-0.4-0.2-0.8-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1.1,1.3-2.6,2.3-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-1.7-0.9-3-2.4-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.6,0.6-1.4,1-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.9-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8-0.1,1.1c0.3,0.3,0.8,0.3,1.1,0.1
              C334.4,371.5,334.5,371.4,334.5,371.4L334.5,371.4z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M80.2,309.9L205.3,415l-57.4,68.4L22.7,378.3L80.2,309.9z
               M81,317.5l-50.6,60.2l116.6,98l50.6-60.2L81,317.5z"/>

            <rect x="74.7" y="320.5" transform="matrix(0.6432 -0.7657 0.7657 0.6432 -263.0034 228.8499)" fill="#FBD4A4" width="78.7" height="152.3"/>
            <g>
              <polygon fill="#0B99D6" points="85.7,314.5 68.7,323.6 78.3,312.1 81.1,310.6         "/>
              <path fill="#0B99D6" d="M94.9,322.2l-5.1,2.8l-4.6-3.9l5-2.7L94.9,322.2L94.9,322.2z
                 M62.5,339.6l-13.2,7l9.7-11.5l13.2-7L62.5,339.6z"/>
              <path fill="#0B99D6" d="M104.1,330l-5,2.7l-4.6-3.9l5.1-2.7L104.1,330L104.1,330z
                 M43.2,362.6l-13.2,7l9.7-11.5l13.2-7L43.2,362.6z"/>
              <path fill="#0B99D6" d="M113.3,337.8l-5.1,2.7l-4.6-3.9l5.1-2.7L113.3,337.8z
                 M33.5,380.4l-5,2.7l-4.6-3.9l9.6-5.1l-3.1,3.7L33.5,380.4z"/>
              <path fill="#0B99D6" d="M122.6,345.5l-5.1,2.7l-4.6-3.9l5-2.7L122.6,345.5L122.6,345.5
                z M42.8,388.2l-5.1,2.7l-4.6-3.9l5-2.7L42.8,388.2z"/>
              <path fill="#0B99D6" d="M131.8,353.2l-5.1,2.7l-4.6-3.9l5.1-2.7L131.8,353.2z
                 M52,395.9l-5,2.7l-4.6-3.9l5.1-2.7L52,395.9z"/>
              <path fill="#0B99D6" d="M141,361l-5.1,2.7l-4.6-3.9l5-2.7L141,361z M61.2,403.7
                l-5.1,2.7l-4.6-3.9l5-2.7L61.2,403.7z"/>
              <path fill="#0B99D6" d="M150.2,368.8l-5.1,2.7l-4.6-3.9l5.1-2.7L150.2,368.8z
                 M70.4,411.4l-5.1,2.7l-4.6-3.9l5.1-2.7L70.4,411.4z"/>
              <path fill="#0B99D6" d="M159.4,376.5l-5.1,2.7l-4.6-3.9l5.1-2.7L159.4,376.5z
                 M79.7,419.2l-5.1,2.7L70,418l5-2.7L79.7,419.2z"/>
              <path fill="#0B99D6" d="M168.7,384.2l-5,2.7L159,383l5.1-2.7L168.7,384.2L168.7,384.2z
                 M88.9,426.9l-5,2.7l-4.6-3.9l5.1-2.7L88.9,426.9z"/>
              <path fill="#0B99D6" d="M177.9,392l-5.1,2.7l-4.6-3.9l5-2.7L177.9,392z M98.1,434.7
                l-5,2.7l-4.6-3.9l5.1-2.7L98.1,434.7z"/>
              <path fill="#0B99D6" d="M187.1,399.7l-5.1,2.7l-4.6-3.9l5-2.7L187.1,399.7L187.1,399.7
                z M107.3,442.4l-5.1,2.7l-4.6-3.9l5-2.7L107.3,442.4z"/>
              <path fill="#0B99D6" d="M196.3,407.5l-5.1,2.7l-4.6-3.9l5.1-2.7L196.3,407.5
                L196.3,407.5z M116.5,450.2l-5,2.7l-4.7-3.9l5.1-2.7L116.5,450.2z"/>
              <path fill="#0B99D6" d="M204.7,415.7l-13.2,7l6-7.2l-1.7-1.4l5.1-2.7l4.4,3.7
                L204.7,415.7L204.7,415.7z M125.7,457.9l-5.1,2.7l-4.6-3.9l5-2.7L125.7,457.9z"/>
              <path fill="#0B99D6" d="M185.4,438.7l-13.2,7l9.6-11.5l13.2-7L185.4,438.7L185.4,438.7
                z M135,465.7l-5.1,2.7l-4.6-3.9l5.1-2.7L135,465.7z"/>
              <path fill="#0B99D6" d="M166.1,461.7l-13.2,7l9.6-11.5l13.2-7L166.1,461.7z
                 M144.2,473.4l-5.1,2.7l-4.6-3.9l5.1-2.7L144.2,473.4z"/>
              <polygon fill="#0B99D6" points="143.8,480 156.4,473.3 147.9,483.4         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M150.8,392.8c4.4,3.7,4.9,10.2,1.3,14.5
              c-3.7,4.4-10.2,4.9-14.5,1.3c-4.4-3.7-4.9-10.2-1.3-14.5c1.8-2.1,4.3-3.4,7-3.6C146,390.2,148.7,391.1,150.8,392.8z M152.8,400
              c-0.4-4.8-4.6-8.3-9.4-7.9s-8.3,4.6-7.9,9.4c0.4,4.8,4.6,8.3,9.4,7.9c2.3-0.2,4.4-1.3,5.9-3.1C152.3,404.6,153,402.3,152.8,400
              L152.8,400z"/>
            <path fill="#80CAF0" d="M147.7,398.4c-0.3,0-0.6-0.2-0.6-0.5c0-0.2,0.1-0.4,0.2-0.5
              c0,0,7.3-2.5,9,3.4c0.6,2,0.8,3.2,1,4.1c0.2,1.2,0.3,1.5,1,1.8c0.6,0.1,1.2,0.1,1.7,0c2.7-0.6,5.5,0.2,7.5,2
              c2.6,2.2,2.5,3.5,2.4,4.9c-0.1,1.3,0.2,2.6,0.9,3.7c0.7,1.5,2,2.7,3.6,3.1c1.2,0.2,2.4,0.1,3.5-0.3c2.4-0.8,4.6,1.1,4.6,1.1
              c0.2,0.2,0.3,0.5,0.1,0.8c-0.2,0.2-0.5,0.3-0.8,0.1l0,0c0,0-1.8-1.5-3.6-0.9c-1.3,0.5-2.7,0.6-4.1,0.3c-1.9-0.5-3.5-1.9-4.3-3.7
              c-0.8-1.3-1.1-2.8-1-4.3c0.1-1.2,0.1-2.1-2.1-4c-1.8-1.7-4.2-2.3-6.6-1.8c-0.8,0.2-1.6,0.2-2.4-0.1c-1.2-0.6-1.3-0.9-1.6-2.6
              c-0.2-0.8-0.4-2.1-1-4C153.9,396.3,147.7,398.4,147.7,398.4L147.7,398.4z M141.2,406.1c0,0,6.2-2.1,7.6,2.7c0.6,2,0.8,3.2,1,4
              c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4c-0.1,1.5,0.2,3,1,4.3
              c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1l0,0c0.2-0.2,0.2-0.6-0.1-0.8
              c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.1c-0.7-1.1-1-2.4-0.8-3.7c0.1-1.5,0.1-2.7-2.4-4.9
              c-2-1.9-4.8-2.7-7.5-2.1c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.1-1-4.1c-1.7-5.9-9-3.4-9-3.4
              c-0.2,0.2-0.3,0.5-0.1,0.8C140.9,406,141.1,406,141.2,406.1L141.2,406.1z M142.9,404.2c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4
              c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4c-0.1,1.5,0.2,3,1,4.3
              c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.3,0.2,0.6,0.1,0.8-0.2c0.1-0.2,0.1-0.5-0.1-0.7
              c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.1c-0.7-1.1-1-2.4-0.9-3.7c0.1-1.5,0.1-2.6-2.4-4.9
              c-2-1.9-4.8-2.7-7.5-2c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.1-1-4.1c-1.7-5.9-8.9-3.4-9-3.4
              c-0.3,0.1-0.4,0.4-0.3,0.7l0,0C142.3,404.1,142.6,404.2,142.9,404.2L142.9,404.2z M144.5,402.2c0,0,6.2-2.1,7.6,2.7
              c0.6,2,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4
              c-0.1,1.5,0.2,3,1,4.3c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.3-0.3,2.6,0.1,3.6,0.9c0.2,0.2,0.6,0.2,0.8,0
              s0.2-0.6,0-0.8l-0.1-0.1c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.1c-0.7-1.1-1-2.4-0.9-3.7
              c0.1-1.5,0.1-2.6-2.4-4.9c-2-1.9-4.8-2.6-7.5-2c-0.6,0.1-1.2,0.1-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.1-1-4.1
              c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0-0.5,0.3-0.5,0.6s0.3,0.5,0.6,0.5C144.4,402.2,144.4,402.2,144.5,402.2L144.5,402.2z M146.1,400.3
              c0,0,6.2-2.1,7.6,2.7c0.6,2,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.8,0.3,1.6,0.3,2.4,0.1c2.4-0.5,4.8,0.1,6.6,1.8
              c2.2,1.9,2.1,2.9,2.1,4c-0.1,1.5,0.2,3,1,4.3c0.8,1.8,2.4,3.2,4.3,3.7c1.4,0.3,2.8,0.2,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9
              c0.2,0.2,0.6,0.2,0.8-0.1l0,0c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1.1,0.4-2.3,0.5-3.5,0.3c-1.6-0.5-2.9-1.6-3.6-3.1
              c-0.7-1.1-1-2.4-0.9-3.7c0.1-1.5,0.1-2.7-2.4-4.9c-2-1.9-4.8-2.6-7.5-2c-0.6,0.1-1.2,0.1-1.7,0c-0.8-0.4-0.8-0.6-1-1.8
              c-0.2-0.9-0.4-2.1-1-4.1c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7C145.5,400.2,145.8,400.4,146.1,400.3z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M-131.9,258.5L37.3,359.8l-55.4,92.5l-169.1-101.4L-131.9,258.5
              L-131.9,258.5z M-129.4,267.4l-48.8,81.4l157.7,94.5l48.8-81.5L-129.4,267.4z"/>

            <rect x="-122.4" y="263.5" transform="matrix(0.514 -0.8578 0.8578 0.514 -341.2634 108.4268)" fill="#FFFFFF" width="94.9" height="183.8"/>
            <g>
              <path fill="#0B99D6" d="M0.2,337.6l-5.5,4.2l-6.2-3.7l5.5-4.2L0.2,337.6L0.2,337.6z
                 M-86.7,403.7l-5.5,4.2l-6.2-3.7l5.5-4.2L-86.7,403.7z"/>
              <path fill="#0B99D6" d="M12.7,345.1l-5.5,4.2L1,345.6l5.5-4.2L12.7,345.1L12.7,345.1z
                 M-74.2,411.2l-5.5,4.2l-6.2-3.7l5.5-4.2L-74.2,411.2z"/>
              <path fill="#0B99D6" d="M25.2,352.6l-5.5,4.2l-6.2-3.8l5.6-4.1L25.2,352.6L25.2,352.6z
                 M-61.8,418.7l-5.5,4.2l-6.2-3.7l5.5-4.2L-61.8,418.7z"/>
              <path fill="#0B99D6" d="M36.7,360.7l-14.3,10.9l5.8-9.7l-2.3-1.4l5.5-4.2l5.9,3.5
                L36.7,360.7L36.7,360.7z M-49.3,426.1l-5.5,4.2l-6.2-3.7l5.5-4.2L-49.3,426.1z"/>
              <path fill="#0B99D6" d="M18.1,391.9L3.8,402.8l9.3-15.5l14.3-10.9L18.1,391.9z
                 M-36.8,433.6l-5.5,4.2l-6.2-3.7l5.5-4.2L-36.8,433.6z"/>
              <path fill="#0B99D6" d="M-0.6,423l-14.3,10.9l9.3-15.5l14.3-10.9L-0.6,423z
                 M-24.3,441.1l-5.5,4.2l-6.2-3.7l5.5-4.2L-24.3,441.1z"/>
            </g>
          </g>
          <path fill="#80CAF0" d="M-34.5,351c-0.3,0.2-0.7,0-0.9-0.3s0-0.7,0.3-0.9
            c0,0,8.2-4.3,11.3,2.4c1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.7,0.1,1.4-0.1,2.1-0.3c3.1-1.2,6.6-0.9,9.3,1
            c3.5,2.2,3.7,3.6,3.8,5.4c0.1,1.6,0.7,3.1,1.7,4.3c1.1,1.7,2.9,2.8,4.9,3.1c1.4,0,2.9-0.3,4.1-1c2.7-1.3,5.7,0.5,5.7,0.5
            c0.3,0.2,0.4,0.6,0.2,0.9s-0.6,0.4-0.9,0.2l0,0c0,0-2.4-1.4-4.4-0.4c-1.5,0.8-3.1,1.2-4.8,1.1c-1.9-0.1-3.9-1.1-5.9-3.6
            c-1.2-1.4-1.9-3.1-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-2.4-1.6-5.5-2-8.2-0.9c-0.9,0.4-1.9,0.5-2.8,0.3c-1.6-0.5-1.7-0.8-2.4-2.8
            c-0.4-1-0.9-2.4-1.9-4.6C-27.5,347.3-34.5,351-34.5,351L-34.5,351z M-40.8,361.5c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.6,1.9,4.6
            c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c2.9,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9
            c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.3c0.2-0.3,0.1-0.7-0.2-0.9
            c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
            c-2.7-1.8-6.2-2.2-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
            c-0.3,0.2-0.4,0.6-0.3,0.9C-41.5,361.5-41.1,361.6-40.8,361.5L-40.8,361.5L-40.8,361.5z M-39.2,358.9c0,0,7-3.7,9.5,1.8
            c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c2.9,1.9,3.1,3,3.2,4.4
            c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.2,0.9-0.1
            c0.2-0.3,0.2-0.7-0.1-0.9c0,0-0.1,0-0.1-0.1c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3
            c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-2.7-1.8-6.2-2.2-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
            c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C-39.9,358.9-39.6,359-39.2,358.9
            C-39.2,358.8-39.2,358.8-39.2,358.9L-39.2,358.9z M-37.6,356.2c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8
            c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c2.9,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.5
            c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2l0,0c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5
            c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1
            c-0.6,0.3-1.4,0.4-2.1,0.4c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9
            C-38.4,356.3-38,356.4-37.6,356.2L-37.6,356.2z M-36.1,353.6c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8
            c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6
            c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9l0,0c0,0-3-1.8-5.7-0.5
            c-1.3,0.7-2.7,1-4.1,1c-2-0.2-3.8-1.4-4.9-3.1c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-2.7-1.9-6.2-2.2-9.3-1
            c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9
            C-36.8,353.6-36.4,353.8-36.1,353.6L-36.1,353.6L-36.1,353.6z"/>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M727.9,510l69-171.8l93.9,37.8l-69,171.8L727.9,510z
               M735.8,506.4l82.7,33.2l64.3-160.1l-82.7-33.2L735.8,506.4z"/>

            <rect x="723.1" y="398.5" transform="matrix(0.3728 -0.9279 0.9279 0.3728 96.5825 1028.8923)" fill="#D1CCE6" width="172.6" height="89.1"/>
            <g>
              <polygon fill="#0B99D6" points="730.9,502.5 746.7,517.6 730.9,511.2 728.4,508.8
                "/>
              <path fill="#0B99D6" d="M736,489.9l4.7,4.5l-2.5,6.3l-4.7-4.5L736,489.9z M766,518.6
                l12.2,11.7l-15.8-6.3l-12.2-11.7L766,518.6z"/>
              <path fill="#0B99D6" d="M741.1,477.2l4.7,4.5l-2.5,6.3l-4.7-4.5L741.1,477.2
                L741.1,477.2z M797.7,531.3l12.2,11.7l-15.8-6.3L781.9,525L797.7,531.3z"/>
              <path fill="#0B99D6" d="M746.2,464.5l4.7,4.5l-2.5,6.3l-4.7-4.5L746.2,464.5z
                 M820.3,535.4l4.7,4.5l-2.5,6.3l-8.9-8.5l5,2L820.3,535.4z"/>
              <path fill="#0B99D6" d="M751.3,451.9l4.7,4.5l-2.5,6.3l-4.7-4.5L751.3,451.9z
                 M825.4,522.7l4.7,4.5l-2.5,6.3l-4.7-4.5L825.4,522.7z"/>
              <path fill="#0B99D6" d="M756.4,439.2l4.7,4.5l-2.5,6.3l-4.7-4.5L756.4,439.2z
                 M830.4,510l4.7,4.5l-2.5,6.3l-4.7-4.5L830.4,510z"/>
              <path fill="#0B99D6" d="M761.5,426.5l4.7,4.5l-2.5,6.3l-4.7-4.5L761.5,426.5z
                 M835.5,497.4l4.7,4.5l-2.5,6.3l-4.7-4.5L835.5,497.4z"/>
              <path fill="#0B99D6" d="M766.5,413.9l4.7,4.5l-2.5,6.3l-4.7-4.5L766.5,413.9z
                 M840.6,484.7l4.7,4.5l-2.5,6.3l-4.7-4.5L840.6,484.7z"/>
              <path fill="#0B99D6" d="M771.6,401.2l4.7,4.5l-2.5,6.3l-4.7-4.5L771.6,401.2z
                 M845.7,472l4.7,4.5l-2.5,6.3l-4.7-4.5L845.7,472z"/>
              <path fill="#0B99D6" d="M776.7,388.5l4.7,4.5l-2.5,6.3l-4.7-4.5L776.7,388.5z
                 M850.8,459.4l4.7,4.5l-2.5,6.3l-4.7-4.5L850.8,459.4z"/>
              <path fill="#0B99D6" d="M781.8,375.9l4.7,4.5l-2.5,6.3l-4.7-4.5L781.8,375.9
                L781.8,375.9z M855.9,446.7l4.7,4.5l-2.5,6.3l-4.7-4.5L855.9,446.7z"/>
              <path fill="#0B99D6" d="M786.9,363.2l4.7,4.5L789,374l-4.7-4.5L786.9,363.2
                L786.9,363.2z M861,434l4.7,4.5l-2.5,6.3l-4.7-4.5L861,434z"/>
              <path fill="#0B99D6" d="M792,350.5l4.7,4.5l-2.5,6.3l-4.7-4.5L792,350.5z M866.1,421.4
                l4.7,4.5l-2.5,6.3l-4.7-4.5L866.1,421.4z"/>
              <path fill="#0B99D6" d="M797.8,338.6l12.2,11.7l-9.9-4l-1,2.4l-4.7-4.5l2.4-6
                L797.8,338.6L797.8,338.6z M871.2,408.7l4.7,4.5l-2.5,6.3l-4.7-4.5L871.2,408.7z"/>
              <path fill="#0B99D6" d="M829.5,351.3l12.2,11.7l-15.8-6.3L813.7,345L829.5,351.3
                L829.5,351.3z M876.2,396.1l4.7,4.5l-2.5,6.3l-4.7-4.5L876.2,396.1z"/>
              <path fill="#0B99D6" d="M861.1,364l12.2,11.7l-15.8-6.4l-12.2-11.7L861.1,364z
                 M881.3,383.4l4.7,4.5l-2.5,6.3l-4.7-4.5L881.3,383.4z"/>
              <polygon fill="#0B99D6" points="888.6,381.5 876.9,370.4 890.8,376         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M792.3,404.7c2.4-6,9.2-8.9,15.2-6.5s8.9,9.2,6.5,15.2
              s-9.2,8.9-15.2,6.5c-2.9-1.2-5.2-3.4-6.4-6.3C791.2,410.8,791.1,407.6,792.3,404.7z M799.3,400.1c-5,2.1-7.3,7.9-5.2,12.9
              s7.9,7.3,12.9,5.2c5-2.1,7.3-7.9,5.2-12.9c-1-2.4-3-4.3-5.4-5.3C804.4,399,801.6,399,799.3,400.1L799.3,400.1z"/>
            <path fill="#80CAF0" d="M799.3,406.1c0.2,0.3,0.1,0.7-0.1,0.9s-0.7,0.1-0.9-0.1l0,0
              c0,0-5.2-7,0.5-10.9c1.9-1.3,3.2-2,4.1-2.5c1.3-0.7,1.5-0.8,1.6-1.8c0-0.7-0.3-1.3-0.6-1.9c-1.6-2.7-1.7-6-0.4-8.8
              c1.5-3.6,2.8-3.9,4.4-4.4c1.4-0.3,2.7-1.1,3.7-2.2c1.4-1.3,2.2-3.1,2.1-5c-0.2-1.3-0.7-2.6-1.5-3.7c-1.6-2.3-0.4-5.4-0.4-5.4
              c0.1-0.3,0.5-0.5,0.8-0.4s0.5,0.5,0.3,0.8c0,0-1,2.5,0.3,4.2c1,1.2,1.6,2.7,1.8,4.3c0.1,2.3-0.8,4.5-2.4,6
              c-1.1,1.3-2.6,2.2-4.3,2.6c-1.3,0.4-2.3,0.6-3.6,3.7c-1.2,2.5-1,5.4,0.4,7.8c0.5,0.8,0.7,1.7,0.7,2.6c-0.2,1.5-0.5,1.7-2.2,2.7
              c-0.8,0.5-2.1,1.2-4,2.5C794.8,400.2,799.3,406.1,799.3,406.1L799.3,406.1z M809.9,410.4c0,0-4.5-5.9,0.2-9.1
              c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7
              c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3.1-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8
              c-0.3-0.1-0.7,0-0.8,0.3c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2
              c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5
              c-5.7,3.9-0.5,10.8-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1l0,0C810.1,411,810.1,410.6,809.9,410.4
              C809.9,410.3,809.9,410.3,809.9,410.4L809.9,410.4z M807.3,409.3c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7
              c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6
              c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8c-0.3-0.1-0.7,0-0.8,0.3c0,0-1.2,3.1,0.4,5.4
              c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2c-1.6,0.4-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8
              c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1
              l0,0C807.4,409.9,807.5,409.5,807.3,409.3L807.3,409.3L807.3,409.3z M804.6,408.2c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5
              c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7c1.7-0.4,3.2-1.3,4.3-2.6
              c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8c-0.3-0.1-0.7,0-0.8,0.3
              c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2c-1.6,0.5-2.9,0.8-4.4,4.4
              c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.9-0.5,10.9
              c0.2,0.3,0.6,0.3,0.9,0.1C804.8,408.8,804.8,408.5,804.6,408.2L804.6,408.2L804.6,408.2z M802,407.2c0,0-4.5-5.9,0.2-9.1
              c1.9-1.3,3.2-2,4-2.5c1.7-1,2-1.1,2.2-2.7c0-0.9-0.2-1.8-0.7-2.6c-1.4-2.4-1.6-5.3-0.4-7.8c1.3-3,2.3-3.3,3.6-3.7
              c1.7-0.4,3.2-1.3,4.3-2.6c1.7-1.5,2.6-3.7,2.4-6c-0.2-1.6-0.8-3-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8
              c-0.3-0.1-0.7,0-0.8,0.3c0,0-1.2,3.1,0.4,5.4c0.8,1.1,1.4,2.3,1.5,3.7c0.1,1.9-0.7,3.7-2.1,5c-1,1.1-2.3,1.9-3.7,2.2
              c-1.6,0.5-2.9,0.8-4.4,4.4c-1.3,2.8-1.2,6.1,0.4,8.8c0.4,0.6,0.6,1.2,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5
              c-5.7,3.9-0.5,10.9-0.5,10.9c0.2,0.3,0.6,0.3,0.9,0.1l0,0C802.1,407.8,802.2,407.4,802,407.2z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#D4410B" d="M-94.9,460l169.2,75.3l41.1-92.5l-169.2-75.3L-94.9,460z
               M-86.8,457l36.2-81.5l157.7,70.2l-36.2,81.5L-86.8,457z"/>

            <rect x="-34.4" y="365.2" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -406.4174 277.2554)" fill="#CCCCCE" width="89.2" height="172.6"/>
            <g>
              <path fill="#39BEEE" d="M-0.2,502.2l0.2-6.5l-6.2-2.8l-0.2,6.5L-0.2,502.2z M3.6,399.7
                l0.2-6.5l-6.2-2.8l-0.2,6.5L3.6,399.7z"/>
              <path fill="#39BEEE" d="M12.2,507.7l0.2-6.5l-6.2-2.8L6,505L12.2,507.7L12.2,507.7z
                 M16.1,405.3l0.2-6.5l-6.2-2.8l-0.2,6.5L16.1,405.3z"/>
              <path fill="#39BEEE" d="M24.7,513.3l0.2-6.5l-6.2-2.8l-0.2,6.5L24.7,513.3L24.7,513.3z
                 M28.6,410.8l0.2-6.5l-6.2-2.8l-0.2,6.5L28.6,410.8z"/>
              <path fill="#39BEEE" d="M37.2,518.8l0.2-6.5l-6.2-2.8L31,516L37.2,518.8L37.2,518.8z
                 M41,416.4l0.2-6.5l-6.2-2.8l-0.2,6.5L41,416.4z"/>
              <path fill="#39BEEE" d="M49.6,524.4l0.2-6.5l-6.2-2.8l-0.2,6.5L49.6,524.4z
                 M53.5,421.9l0.2-6.5l-6.2-2.8l-0.2,6.5L53.5,421.9z"/>
              <path fill="#39BEEE" d="M62.1,529.9l0.2-6.5l-6.2-2.8l-0.2,6.5L62.1,529.9z M66,427.5
                l0.2-6.5l-6.2-2.8l-0.2,6.5L66,427.5z"/>
              <path fill="#39BEEE" d="M74.6,534.4l0.6-16.9l-4.3,9.7l-2.3-1l-0.2,6.5l5.9,2.6
                L74.6,534.4L74.6,534.4z M78.4,433l0.2-6.5l-6.2-2.8l-0.2,6.5L78.4,433z"/>
              <path fill="#39BEEE" d="M88.5,503.3l0.6-16.9l-6.9,15.5l-0.6,16.9L88.5,503.3z
                 M90.9,438.6l0.2-6.5l-6.2-2.8l-0.2,6.5L90.9,438.6z"/>
              <path fill="#39BEEE" d="M102.3,472.1l0.6-16.9L96,470.8l-0.6,16.9L102.3,472.1
                L102.3,472.1z M103.4,444.1l0.2-6.5l-6.2-2.8l-0.2,6.5L103.4,444.1z"/>
              <polygon fill="#39BEEE" points="109.9,440.4 109.2,456.6 115.3,442.9         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M39.1,420.7l2,0.9l0,0c-0.4,0.7-0.1,1.6,0.6,1.9
              c0.7,0.4,1.6,0.1,1.9-0.6c0,0,0-0.1,0.1-0.1l0,0l1.3,0.6l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0l1.1,0.5l0,0
              c-0.4,0.7-0.1,1.6,0.6,1.9c0.7,0.4,1.6,0.1,1.9-0.6c0,0,0-0.1,0.1-0.1l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0
              l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7l0,0l2,0.9l-0.7,1.6c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9l-0.5,1.1
              c-0.7-0.4-1.6-0.1-1.9,0.6s-0.1,1.6,0.6,1.9c0,0,0.1,0,0.1,0.1l-0.5,1.1c-0.7-0.3-1.6-0.1-1.9,0.7c-0.3,0.7-0.1,1.6,0.7,1.9
              l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9l-0.6,1.3c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l0,0l-0.5,1.1
              c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l0,0l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7s0,1.6,0.7,1.9l0,0l-0.5,1.1
              c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l0,0l-0.7,1.6l-2-0.9l0,0c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0
              c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0
              c0.3-0.7,0-1.6-0.7-1.9s-1.6,0-1.9,0.7l0,0l0,0l-1.3-0.6l0,0c0.4-0.7,0.1-1.6-0.6-1.9c-0.7-0.4-1.6-0.1-1.9,0.6
              c0,0,0,0.1-0.1,0.1l0,0l-2-0.9l0.7-1.6c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0,0l0.5-1.1c0.7,0.4,1.6,0.1,1.9-0.6
              c0.4-0.7,0.1-1.6-0.6-1.9c0,0-0.1,0-0.1-0.1l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7
              s0-1.6-0.7-1.9l0.6-1.3c0.7,0.4,1.6,0.1,1.9-0.6s0.1-1.6-0.6-1.9c0,0-0.1,0-0.1-0.1l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7
              c0.3-0.7,0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.7-1.9l0.5-1.1c0.7,0.4,1.6,0.1,1.9-0.6s0.1-1.6-0.6-1.9
              c0,0-0.1,0-0.1-0.1L39.1,420.7z"/>

            <rect x="28.3" y="432.6" transform="matrix(0.4066 -0.9136 0.9136 0.4066 -378.1091 301.1245)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>

          <rect x="252" y="289.2" transform="matrix(0.2256 -0.9742 0.9742 0.2256 -137.5732 590.4232)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M223.9,311.6l59.5,68.3c8.6,9.8,24.9,13.9,36.3,9.1l84.6-35.6
            L223.9,311.6z"/>
          <g>
            <path fill="#0B99D6" d="M314.8,377.6c-8.1-1.9-16.3,3.2-18.2,11.3s3.2,16.3,11.3,18.2
              s16.3-3.2,18.2-11.3c0.9-3.9,0.2-8-1.9-11.4C322.1,380.9,318.7,378.5,314.8,377.6z M304.6,381.5c6-3.7,13.8-1.9,17.6,4.1
              s1.9,13.8-4.1,17.6c-6,3.7-13.8,1.9-17.6-4.1c-1.8-2.9-2.4-6.3-1.6-9.6C299.7,386.2,301.7,383.3,304.6,381.5z"/>
            <path fill="#0B99D6" d="M311,386.1c0.1,0.4,0.6,0.6,1,0.5s0.6-0.6,0.5-1
              c0,0-3.5-10.7-11.9-7.5c-2.8,1.1-4.5,1.9-5.7,2.5c-1.7,0.8-2,1-3,0.4c-0.7-0.5-1.2-1.2-1.5-2c-1.4-2.4-3.2-5.8-9-7
              c-4.9-1-6.3,0.1-7.9,1.5c-1.4,1.3-3.2,2.1-5.1,2.3c-3.3,0.5-5.4-0.3-6.9-1.5c-1.3-1.2-2.2-2.7-2.8-4.4c-1.2-3.4-5.4-4.4-5.4-4.4
              c-0.4-0.1-0.9,0.2-1,0.6s0.2,0.9,0.6,1c0,0,3.3,0.8,4.3,3.4c0.6,2,1.7,3.7,3.3,5.1c1.8,1.5,4.4,2.4,8.1,1.8
              c2.2-0.2,4.3-1.2,5.9-2.6c1.3-1.1,2.4-2,6.6-1.1c5,1,6.7,4,7.9,6.2c0.5,1.1,1.2,2,2.2,2.7c1.8,0.9,2.2,0.7,4.5-0.4
              c1.1-0.5,2.8-1.4,5.6-2.4C308,377,311,386.1,311,386.1z M307.6,400.6c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4
              c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6
              c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6
              c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.2,0.4,0,0.9-0.4,1.1S307.9,401,307.6,400.6C307.7,400.7,307.7,400.7,307.6,400.6L307.6,400.6z M308.5,397c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1
              c0.1-0.4,0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.6,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C309,397.6,308.6,397.4,308.5,397L308.5,397z M309.3,393.3c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1
              c0.1-0.4,0.5-0.7,1-0.6l0,0c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.6,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C309.9,394,309.5,393.8,309.3,393.3L309.3,393.3z M310.2,389.7
              c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.1-2.9-5.1-7.9-6.2
              c-4.2-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.3-3.4
              c-0.4-0.1-0.7-0.5-0.6-1s0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C310.7,390.4,310.3,390.1,310.2,389.7L310.2,389.7z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#80CAF0" d="M261.3,435.5c5.9,3.5,7.8,11.2,4.3,17.1s-11.2,7.8-17.1,4.3
              s-7.8-11.2-4.3-17.1c1.7-2.8,4.4-4.9,7.7-5.7C255,433.3,258.4,433.8,261.3,435.5z M265,443.6c-1.4-5.6-7.1-9-12.7-7.6
              c-5.6,1.4-9,7.1-7.6,12.7s7.1,9,12.7,7.6c2.7-0.7,5-2.4,6.4-4.8C265.3,449.2,265.7,446.3,265,443.6L265,443.6z"/>
            <path fill="#80CAF0" d="M258.6,442.7c-0.3,0.2-0.7,0.1-0.9-0.3
              c-0.2-0.3-0.1-0.7,0.3-0.9l0,0c0,0,8.2-4.3,11.3,2.4c1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.7,0.1,1.4-0.1,2.1-0.3
              c3.1-1.2,6.5-0.8,9.3,1c3.5,2.2,3.7,3.6,3.9,5.4c0.1,1.6,0.7,3.1,1.7,4.3c1.7,2.1,3.4,2.9,4.9,3c1.4,0,2.9-0.3,4.1-1
              c2.7-1.4,5.7,0.5,5.7,0.5c0.3,0.2,0.4,0.6,0.2,0.9l0,0c-0.2,0.3-0.6,0.4-0.9,0.2l0,0c0,0-2.4-1.4-4.4-0.4
              c-1.5,0.8-3.1,1.2-4.8,1.1c-1.9-0.1-3.9-1.1-5.9-3.6c-1.2-1.4-1.9-3.1-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-2.4-1.6-5.5-2-8.2-0.9
              c-0.9,0.4-1.9,0.5-2.8,0.3c-1.6-0.5-1.7-0.8-2.4-2.8c-0.4-1-0.9-2.4-1.9-4.6C265.7,439,258.7,442.7,258.6,442.7L258.6,442.7z
               M252.4,453.1c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.5,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9l0,0c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3
              c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.3-11.3-2.3c-0.3,0.1-0.5,0.5-0.4,0.9c0.1,0.3,0.5,0.5,0.9,0.4
              C252.3,453.2,252.3,453.2,252.4,453.1L252.4,453.1z M253.9,450.5c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8
              c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6
              c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5
              c-1.3,0.7-2.7,1-4.1,1c-1.5-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1
              c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9
              C253.2,450.6,253.6,450.7,253.9,450.5L253.9,450.5z M255.5,447.9c0,0,7-3.7,9.5,1.8c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8
              c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.8,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.6
              c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9l0,0c0,0-3-1.8-5.7-0.5
              c-1.3,0.7-2.7,1-4.1,1c-1.5-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1
              c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.1-0.5,0.5-0.4,0.9
              c0.1,0.3,0.5,0.5,0.9,0.4C255.4,447.9,255.4,447.9,255.5,447.9L255.5,447.9z M257.1,445.3c0,0,7-3.7,9.5,1.7
              c1,2.2,1.6,3.6,1.9,4.6c0.7,2,0.9,2.3,2.4,2.8c1,0.2,1.9,0,2.8-0.3c2.7-1.1,5.8-0.7,8.2,0.9c3,1.9,3.1,3,3.2,4.4
              c0.1,1.8,0.8,3.5,2,4.9c1.9,2.5,4,3.4,5.9,3.5c1.7,0.1,3.3-0.3,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0,0.9-0.3
              c0.2-0.3,0-0.7-0.3-0.9l0,0c0,0-3-1.8-5.7-0.5c-1.3,0.7-2.7,1-4.1,1c-1.5-0.1-3.3-0.9-4.9-3c-1-1.2-1.6-2.7-1.7-4.3
              c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.6,0.3-1.4,0.4-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7
              c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C256.2,445.3,256.6,445.5,257.1,445.3C257,445.3,257,445.3,257.1,445.3
              L257.1,445.3z"/>
          </g>
          <g>
            <path fill="#D4410B" d="M124.1,378.3L251,513.1l73.7-69.4L197.8,308.9L124.1,378.3z
               M132.8,378.7l64.9-61.1L316,443.3l-64.9,61.1L132.8,378.7z"/>

            <rect x="181.9" y="324" transform="matrix(0.7267 -0.6869 0.6869 0.7267 -220.1948 267.9077)" fill="#FBD4A4" width="89.5" height="173.4"/>
            <g>
              <polygon fill="#39BEEE" points="129.6,384.2 138.8,364.4 126.4,376.1 124.9,379.2
                "/>
              <path fill="#39BEEE" d="M139,394.1l2.7-5.9l-4.7-5l-2.7,5.9L139,394.1z M156.5,356.4
                l7.1-15.4l-12.4,11.7l-7.1,15.3L156.5,356.4z"/>
              <path fill="#39BEEE" d="M148.3,404l2.7-5.9l-4.7-5l-2.7,5.9L148.3,404L148.3,404z
                 M181.3,333l7.1-15.3L176,329.3l-7.1,15.3L181.3,333z"/>
              <path fill="#39BEEE" d="M157.7,414l2.7-5.9l-4.7-5L153,409L157.7,414L157.7,414z
                 M200.9,321l2.7-5.9l-4.7-5l-5.2,11.2l3.9-3.7L200.9,321z"/>
              <path fill="#39BEEE" d="M167.1,423.9l2.7-5.9l-4.7-5l-2.7,6L167.1,423.9L167.1,423.9z
                 M210.2,330.9l2.7-5.9l-4.7-5l-2.7,5.9L210.2,330.9z"/>
              <path fill="#39BEEE" d="M176.4,433.9l2.7-5.9l-4.7-5l-2.7,5.9L176.4,433.9z
                 M219.6,340.9l2.7-5.9l-4.7-5l-2.7,5.9L219.6,340.9z"/>
              <path fill="#39BEEE" d="M185.8,443.8l2.7-5.9l-4.7-5l-2.7,5.9L185.8,443.8z
                 M228.9,350.8l2.7-5.9l-4.7-5l-2.7,5.9L228.9,350.8z"/>
              <path fill="#39BEEE" d="M195.1,453.7l2.7-5.9l-4.7-5l-2.7,5.9L195.1,453.7L195.1,453.7
                z M238.3,360.7l2.7-5.9l-4.7-5l-2.7,5.9L238.3,360.7z"/>
              <path fill="#39BEEE" d="M204.5,463.7l2.7-5.9l-4.7-5l-2.7,5.9L204.5,463.7L204.5,463.7
                z M247.6,370.7l2.7-5.9l-4.7-5l-2.7,5.9L247.6,370.7z"/>
              <path fill="#39BEEE" d="M213.8,473.6l2.7-5.9l-4.7-5l-2.7,5.9L213.8,473.6L213.8,473.6
                z M257,380.6l2.7-5.9l-4.7-5l-2.7,5.9L257,380.6z"/>
              <path fill="#39BEEE" d="M223.2,483.6l2.7-5.9l-4.7-5l-2.7,5.9L223.2,483.6z
                 M266.3,390.6l2.7-5.9l-4.7-5l-2.7,5.9L266.3,390.6z"/>
              <path fill="#39BEEE" d="M232.5,493.5l2.7-5.9l-4.7-5l-2.7,5.9L232.5,493.5L232.5,493.5
                z M275.7,400.5l2.7-5.9l-4.7-5l-2.8,5.9L275.7,400.5z"/>
              <path fill="#39BEEE" d="M241.9,503.4l2.7-5.9l-4.7-5l-2.7,5.9L241.9,503.4L241.9,503.4
                z M285,410.4l2.7-5.9l-4.7-5l-2.7,5.9L285,410.4z"/>
              <path fill="#39BEEE" d="M251.7,512.4l7.1-15.3l-7.8,7.3l-1.7-1.9l-2.7,5.9l4.4,4.7
                L251.7,512.4L251.7,512.4z M294.4,420.4l2.7-5.9l-4.7-5l-2.7,5.9L294.4,420.4z"/>
              <path fill="#39BEEE" d="M276.5,489l7.1-15.3l-12.4,11.7l-7.1,15.3L276.5,489z
                 M303.8,430.3l2.7-5.9l-4.7-5l-2.7,5.9L303.8,430.3z"/>
              <path fill="#39BEEE" d="M301.3,465.7l7.1-15.3L296,462l-7.1,15.3L301.3,465.7
                L301.3,465.7z M313.1,440.2l2.7-5.9l-4.7-5l-2.7,5.9L313.1,440.2z"/>
              <polygon fill="#39BEEE" points="320.5,439.3 313.7,454 324.7,443.7         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M222.4,452.9c4.4,4.7,11.8,4.9,16.5,0.5s4.9-11.8,0.5-16.5
              s-11.8-4.9-16.5-0.5c-2.2,2.1-3.6,5-3.7,8.1S220.3,450.6,222.4,452.9z M230.6,454.7c-5.4-0.2-9.7-4.7-9.5-10.1s4.7-9.7,10.1-9.5
              c5.4,0.2,9.7,4.7,9.5,10.1c-0.1,2.6-1.2,5.1-3.1,6.9C235.7,453.8,233.2,454.7,230.6,454.7z"/>
            <path fill="#80CAF0" d="M228.5,449c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.7,0.1-0.8,0.4
              c0,0-2.3,8.4,4.4,9.9c2.3,0.5,3.7,0.7,4.7,0.9c1.4,0.2,1.7,0.2,2.1,1c0.2,0.6,0.2,1.3,0.1,2c-0.5,3.1,0.5,6.2,2.8,8.3
              c2.7,2.8,4.1,2.6,5.7,2.5c1.5-0.2,3,0,4.3,0.7c1.8,0.7,3.2,2.1,3.8,3.9c0.3,1.3,0.3,2.7-0.1,4c-0.7,2.7,1.6,5.1,1.6,5.1
              c0.2,0.2,0.6,0.2,0.9,0c0.2-0.2,0.2-0.6,0-0.9c0,0-1.8-1.9-1.3-4c0.4-1.5,0.5-3.1,0.1-4.6c-0.7-2.1-2.3-3.8-4.4-4.7
              c-1.5-0.8-3.2-1.1-4.9-0.8c-1.3,0.1-2.4,0.2-4.7-2.1c-2-1.9-2.9-4.7-2.4-7.4c0.2-0.9,0.1-1.8-0.3-2.7c-0.8-1.3-1.1-1.4-3-1.7
              c-1-0.1-2.4-0.3-4.6-0.8C226.5,456.1,228.4,449,228.5,449L228.5,449z M236.8,441.1c0,0-2,7.1,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8
              c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7c-0.5,2.7,0.5,5.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.7-0.2,3.4,0,4.9,0.8
              c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9c-0.2,0.2-0.6,0.2-0.9,0
              c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7c-1.6,0.2-3,0.3-5.7-2.5
              c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2c-0.5-0.8-0.7-0.9-2.1-1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9
              c0.1-0.3,0.5-0.5,0.8-0.4C236.7,440.5,236.9,440.8,236.8,441.1L236.8,441.1z M234.7,443.1c0,0-2,7.1,3.5,8.4
              c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7c-0.4,2.7,0.5,5.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1
              c1.7-0.2,3.4,0.1,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9l0,0
              c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7
              c-1.6,0.2-3,0.3-5.7-2.5c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2c-0.5-0.8-0.7-0.9-2.1-1c-1-0.1-2.5-0.4-4.7-0.9
              c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.6,0.7-0.5S234.8,442.7,234.7,443.1L234.7,443.1L234.7,443.1z M232.6,445.1
              c0,0-2,7.1,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.2,2.7c-0.4,2.7,0.5,5.5,2.4,7.4
              c2.3,2.3,3.4,2.2,4.7,2.1c1.7-0.2,3.4,0,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6c-0.5,2.1,1.3,4,1.3,4
              c0.2,0.2,0.2,0.6,0,0.9l0,0c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4c-0.6-1.8-2-3.2-3.8-3.9
              c-1.3-0.7-2.8-0.9-4.3-0.7c-1.7,0.2-3,0.3-5.7-2.5c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2c-0.5-0.8-0.7-0.9-2.1-1
              c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4C232.5,444.5,232.7,444.8,232.6,445.1L232.6,445.1z
               M230.6,447.1c0,0-2,7.1,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.3,0.8,0.4,1.8,0.3,2.7
              c-0.5,2.7,0.5,5.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.7-0.2,3.4,0.1,4.9,0.8c2.1,0.8,3.7,2.5,4.4,4.7c0.4,1.5,0.4,3.1-0.1,4.6
              c-0.5,2.1,1.3,4,1.3,4c0.2,0.3,0.1,0.7-0.1,0.9c-0.2,0.2-0.5,0.2-0.8,0c0,0-2.3-2.4-1.6-5.1c0.4-1.3,0.4-2.7,0.1-4
              c-0.6-1.8-2-3.2-3.8-3.9c-1.3-0.7-2.8-0.9-4.3-0.7c-1.6,0.2-3,0.3-5.7-2.5c-2.3-2.1-3.3-5.3-2.8-8.3c0.1-0.7,0.1-1.4-0.1-2
              c-0.5-0.8-0.7-0.9-2.1-1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.7-0.4l0,0
              C230.4,446.4,230.6,446.7,230.6,447.1L230.6,447.1z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M262.9,393.8l1.5,1.6l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1.5,1.6l-1.3,1.2c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.9c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2
              l-1,1c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-1.3,1.2l-1.5-1.5l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.9-0.9l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1-1l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1.5-1.5l1.3-1.2c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0,0l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2
              l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2L262.9,393.8z"/>

            <rect x="243.8" y="405.5" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -213.8832 289.6867)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M270.8,414.9l1.5,1.5l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2
              c0.6,0.6,1.5,0.6,2,0l0,0l1.5,1.5l-1.3,1.2c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2
              l-1,1c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.9
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-1.3,1.2l-1.7-1.8l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1-1l0,0
              c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-1.3-1.6l1.3-1.2c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9
              c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.9c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0,0l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2
              l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2L270.8,414.9z"/>

            <rect x="251.6" y="426.5" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -226.156 300.7775)" fill="#F5A049" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M288.4,421.5l1.5,1.6l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1
              l0,0l1,1l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0,0l0.8,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0
              l0.8,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l0.9,0.9l0,0c-0.6,0.6-0.6,1.5,0,2c0.6,0.6,1.5,0.6,2,0l0,0l1.5,1.6
              l-1.3,1.2c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-0.9,0.9
              c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l0,0l-1,1
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.9c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0
              c-0.6,0.6-0.6,1.5,0,2l-0.9,0.8c-0.6-0.6-1.5-0.6-2,0c-0.6,0.6-0.6,1.5,0,2l-1.4,1l-1.5-1.6l0,0c0.6-0.6,0.6-1.5,0-2
              c-0.6-0.6-1.5-0.6-2,0l0,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2
              c-0.6-0.6-1.5-0.6-2,0l0,0l0,0l-0.9-0.9l0,0c0.6-0.6,0.6-1.5,0-2c-0.6-0.6-1.5-0.6-2,0l0,0l0,0l-1-1l0,0c0.6-0.6,0.6-1.5,0-2
              c-0.6-0.6-1.5-0.6-2,0l0,0l-1.5-1.6l1.3-1.2c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.9c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0,0l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.9c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0,0
              l1-1c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2l0.9-0.9c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.9c0.6,0.6,1.5,0.6,2,0
              c0.6-0.6,0.6-1.5,0-2l0.9-0.8c0.6,0.6,1.5,0.6,2,0c0.6-0.6,0.6-1.5,0-2L288.4,421.5z"/>

            <rect x="269.2" y="433.2" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -226.0045 314.6252)" fill="#EF805F" width="28.9" height="18"/>
          </g>

          <rect x="170.2" y="375.1" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -234.2409 228.7541)" fill="#236095" width="2.1" height="69.1"/>

          <rect x="175.3" y="370.3" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -229.5624 230.9278)" fill="#236095" width="2.1" height="69.1"/>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M286.3,415.2l-185-6.3l3.4-101.1l185,6.3L286.3,415.2z
                 M280.2,409l3-89.1L110.7,314l-3,89.1L280.2,409z"/>

              <rect x="150.9" y="275.1" transform="matrix(3.385387e-02 -0.9994 0.9994 3.385387e-02 -172.3741 544.5597)" fill="#FFFFFF" width="89.2" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="278.2,414.9 287,395 286.4,412 285,415.2           "/>
                <path fill="#0B99D6" d="M264.5,414.5l2.6-6l6.8,0.2l-2.6,5.9L264.5,414.5L264.5,414.5
                  z M281.3,376.4l6.8-15.5l-0.6,17l-6.8,15.5L281.3,376.4z"/>
                <path fill="#0B99D6" d="M250.9,414l2.6-6l6.8,0.2l-2.6,6L250.9,414z M282.4,342.3
                  l6.8-15.5l-0.6,17l-6.8,15.5L282.4,342.3z"/>
                <path fill="#0B99D6" d="M237.2,413.5l2.6-5.9l6.8,0.2l-2.6,5.9L237.2,413.5z
                   M278.6,319.7l2.6-5.9l6.8,0.2l-5,11.3l0.2-5.4L278.6,319.7z"/>
                <path fill="#0B99D6" d="M223.6,413.1l2.6-5.9l6.8,0.2l-2.6,5.9L223.6,413.1z
                   M264.9,319.2l2.6-5.9l6.8,0.2l-2.6,5.9L264.9,319.2z"/>
                <path fill="#0B99D6" d="M210,412.6l2.6-6l6.8,0.2l-2.6,6L210,412.6L210,412.6z
                   M251.3,318.8l2.6-6l6.8,0.2l-2.7,6L251.3,318.8z"/>
                <path fill="#0B99D6" d="M196.3,412.2l2.6-6l6.8,0.2l-2.6,6L196.3,412.2L196.3,412.2z
                   M237.7,318.3l2.6-6l6.8,0.2l-2.6,6L237.7,318.3z"/>
                <path fill="#0B99D6" d="M182.7,411.7l2.6-5.9l6.8,0.2l-2.6,5.9L182.7,411.7z
                   M224,317.9l2.6-5.9l6.8,0.2l-2.6,5.9L224,317.9z"/>
                <path fill="#0B99D6" d="M169,411.2l2.6-5.9l6.8,0.2l-2.6,5.9L169,411.2z M210.3,317.4
                  l2.6-5.9l6.8,0.2l-2.6,5.9L210.3,317.4z"/>
                <path fill="#0B99D6" d="M155.4,410.8l2.6-6l6.8,0.2l-2.6,6L155.4,410.8z M196.7,316.9
                  l2.6-6l6.8,0.2l-2.6,6L196.7,316.9z"/>
                <path fill="#0B99D6" d="M141.8,410.3l2.6-5.9l6.8,0.2l-2.6,6L141.8,410.3L141.8,410.3
                  z M183.1,316.5l2.6-5.9l6.8,0.2l-2.6,6L183.1,316.5z"/>
                <path fill="#0B99D6" d="M128.1,409.8l2.6-5.9l6.8,0.2l-2.6,5.9L128.1,409.8z
                   M169.4,316l2.6-5.9l6.8,0.2l-2.6,5.9L169.4,316z"/>
                <path fill="#0B99D6" d="M114.5,409.4l2.6-6l6.8,0.2l-2.6,5.9L114.5,409.4L114.5,409.4
                  z M155.8,315.5l2.6-6l6.8,0.2l-2.6,5.9L155.8,315.5z"/>
                <path fill="#0B99D6" d="M101.3,407.9l6.8-15.5l-0.4,10.6l2.6,0.1l-2.6,6l-6.4-0.2
                  V407.9L101.3,407.9z M142.2,315.1l2.6-6l6.8,0.2l-2.6,6L142.2,315.1z"/>
                <path fill="#0B99D6" d="M102.4,373.9l6.8-15.5l-0.6,17l-6.8,15.5L102.4,373.9z
                   M128.5,314.6l2.6-5.9l6.8,0.2l-2.6,5.9L128.5,314.6z"/>
                <path fill="#0B99D6" d="M103.6,339.8l6.8-15.5l-0.6,17l-6.8,15.5L103.6,339.8
                  L103.6,339.8z M114.9,314.2l2.6-5.9l6.8,0.2l-2.6,5.9L114.9,314.2z"/>
                <polygon fill="#0B99D6" points="110.7,308 104.2,322.8 104.7,307.8           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M165.3,390.6c-6.4-0.2-11.5-5.6-11.3-12.1
                c0.2-6.4,5.6-11.5,12.1-11.3c6.4,0.2,11.5,5.6,11.3,12.1c-0.1,3.1-1.4,6-3.7,8.1C171.4,389.6,168.4,390.7,165.3,390.6z
                 M158.5,385.6c3.7,4,9.9,4.2,13.9,0.5s4.2-9.9,0.5-13.9s-9.9-4.2-13.9-0.5c-1.9,1.8-3,4.3-3.1,6.9
                C155.8,381.2,156.8,383.7,158.5,385.6L158.5,385.6z"/>
              <path fill="#80CAF0" d="M164.2,383.5c0.2-0.3,0.6-0.4,0.9-0.2c0.3,0.2,0.4,0.6,0.2,0.8
                l0,0c0,0-4.8,7.3-10.4,3.2c-1.9-1.4-3-2.3-3.8-3c-1.1-0.9-1.3-1.1-2.2-0.9c-0.6,0.3-1.1,0.7-1.5,1.2c-2,2.4-5,3.7-8.1,3.4
                c-3.9-0.2-4.6-1.3-5.6-2.7c-0.8-1.2-2-2.2-3.4-2.7c-1.7-0.9-3.7-1-5.4-0.3c-1.2,0.6-2.2,1.6-2.9,2.7c-1.6,2.3-4.9,2.2-4.9,2.2
                c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6l0,0c0,0,2.6,0.1,3.8-1.7c0.8-1.3,2-2.4,3.4-3.1c2.1-0.9,4.4-0.8,6.4,0.2
                c1.6,0.6,2.9,1.7,3.9,3.1c0.8,1.1,1.4,2,4.7,2.2c2.7,0.2,5.4-0.9,7.1-3c0.6-0.7,1.3-1.3,2.2-1.6c1.5-0.3,1.8-0.1,3.2,1.2
                c0.7,0.6,1.8,1.6,3.7,2.9C160.1,389.8,164.2,383.6,164.2,383.5L164.2,383.5z M164.6,372.1c0,0-4.1,6.2-8.6,2.9
                c-1.9-1.3-3-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.3-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2
                c-0.9-1.4-2.3-2.5-3.9-3.1c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.2-0.6,0.6l0,0
                c0,0.3,0.2,0.6,0.6,0.6l0,0c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7c1.8-0.7,3.7-0.6,5.4,0.3c1.4,0.5,2.5,1.5,3.3,2.7
                c1,1.4,1.8,2.5,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9c0.8,0.6,1.9,1.6,3.8,3
                c5.6,4.1,10.4-3.2,10.4-3.2c0.1-0.3,0-0.7-0.3-0.8C165,371.8,164.8,371.9,164.6,372.1L164.6,372.1z M164.5,374.9
                c0,0-4.1,6.2-8.6,2.9c-1.9-1.4-3-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.2-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3
                c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.1c-2-1-4.4-1.1-6.4-0.3c-1.4,0.7-2.6,1.8-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7
                c-0.3,0-0.6,0.3-0.6,0.6l0,0c0,0.3,0.3,0.6,0.6,0.6l0,0c0,0,3.3,0.1,4.9-2.2c0.7-1.2,1.7-2.1,2.9-2.7c1.8-0.7,3.7-0.6,5.4,0.3
                c1.4,0.5,2.6,1.5,3.4,2.7c1,1.4,1.8,2.5,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9
                c0.8,0.6,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9C165.1,374.6,164.7,374.6,164.5,374.9L164.5,374.9z
                 M164.4,377.8c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-3-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.2-1.2c-0.9,0.3-1.6,0.8-2.2,1.6
                c-1.7,2.1-4.4,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.8-3.1c-2-1-4.4-1.1-6.4-0.2c-1.4,0.7-2.6,1.8-3.4,3.1
                c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.2-0.6,0.6l0,0c0,0.3,0.3,0.6,0.6,0.6l0,0c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7
                c1.8-0.7,3.8-0.6,5.4,0.3c1.4,0.5,2.5,1.5,3.4,2.7c1,1.4,1.8,2.5,5.6,2.7c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2
                c0.9-0.2,1.1,0,2.2,0.9c0.8,0.6,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9
                C165,377.5,164.6,377.5,164.4,377.8L164.4,377.8L164.4,377.8z M164.3,380.7c0,0-4.1,6.2-8.6,2.9c-1.9-1.4-2.9-2.3-3.7-2.9
                c-1.5-1.3-1.8-1.5-3.3-1.2c-0.9,0.3-1.6,0.8-2.2,1.6c-1.7,2.1-4.4,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.9-1.4-2.3-2.5-3.9-3.1
                c-2-1-4.4-1.1-6.4-0.3c-1.4,0.7-2.6,1.8-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.7,0.6c0,0.3,0.3,0.6,0.6,0.6l0,0
                c0,0,3.3,0.1,4.9-2.2c0.7-1.1,1.7-2.1,2.9-2.7c1.8-0.7,3.7-0.6,5.4,0.3c1.4,0.5,2.6,1.5,3.4,2.7c1,1.4,1.8,2.5,5.6,2.7
                c3.1,0.3,6.1-1,8.1-3.4c0.4-0.5,0.9-0.9,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9c0.8,0.6,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2
                c0.2-0.3,0.1-0.7-0.2-0.9l0,0C164.9,380.3,164.5,380.4,164.3,380.7L164.3,380.7z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M243.9,355.4l-0.5-2.1l0,0c0.8-0.1,1.3-0.9,1.2-1.7
              c-0.1-0.8-0.9-1.3-1.7-1.2c-0.1,0-0.1,0-0.2,0l0,0l-0.3-1.4l0,0c0.8-0.2,1.2-0.9,1.1-1.7s-0.9-1.2-1.7-1.1l0,0l0,0l-0.3-1.2l0,0
              c0.8-0.2,1.2-0.9,1.1-1.7s-0.9-1.2-1.7-1.1l0,0l-0.3-1.2l0,0c0.8-0.2,1.2-1,1-1.8c-0.2-0.7-0.9-1.2-1.6-1l0,0l-0.3-1.2l0,0
              c0.8-0.2,1.2-0.9,1.1-1.7s-0.9-1.2-1.7-1.1l0,0l0,0l-0.5-2.1l1.7-0.4c0.1,0.8,0.9,1.3,1.7,1.2c0.8-0.1,1.3-0.9,1.2-1.7
              c0-0.1,0-0.1,0-0.2l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1
              c0.8-0.2,1.2-0.9,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l0,0l1.4-0.3c0.1,0.8,0.9,1.3,1.7,1.2
              c0.8-0.1,1.3-0.9,1.2-1.7c0-0.1,0-0.1,0-0.2l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1s1.2-0.9,1.1-1.7l1.2-0.3c0.2,0.8,1,1.2,1.7,1.1
              s1.2-1,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1s1.2-0.9,1.1-1.7l1.7-0.4l0.5,2.1l0,0c-0.8,0.2-1.2,0.9-1.1,1.7s0.9,1.2,1.7,1.1
              l0,0l0.3,1.2l0,0c-0.8,0.2-1.2,0.9-1.1,1.7s0.9,1.2,1.7,1.1l0,0l0,0l0.3,1.2l0,0c-0.8,0.2-1.2,0.9-1.1,1.7s0.9,1.2,1.7,1.1l0,0
              l0.3,1.2l0,0c-0.8,0.2-1.2,1-1,1.8c0.2,0.7,0.9,1.2,1.6,1l0,0l0.3,1.4l0,0c-0.8,0.2-1.2,0.9-1.1,1.7s0.9,1.2,1.7,1.1l0,0l0.5,2.1
              l-1.7,0.4c-0.1-0.8-0.9-1.3-1.7-1.2c-0.8,0.1-1.3,0.9-1.2,1.7c0,0.1,0,0.1,0,0.2l-1.2,0.3c-0.2-0.8-1-1.2-1.7-1.1s-1.2,1-1.1,1.7
              l0,0l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1s-1.2,0.9-1.1,1.7l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1s-1.2,0.9-1.1,1.7l0,0l-1.4,0.3
              c-0.1-0.8-0.9-1.3-1.7-1.2c-0.8,0.1-1.3,0.9-1.2,1.7c0,0.1,0,0.1,0,0.2l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1
              c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3c-0.2-0.8-1-1.2-1.7-1.1c-0.8,0.2-1.2,1-1.1,1.7l0,0l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1
              c-0.8,0.2-1.2,0.9-1.1,1.7l0,0L243.9,355.4z"/>

            <rect x="243.9" y="330.8" transform="matrix(0.9737 -0.2278 0.2278 0.9737 -70.6189 67.7812)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M229.5,420.6l110.8,148.3l81-60.6L310.6,360L229.5,420.6
                L229.5,420.6z M238.1,422l71.4-53.4l103.3,138.2l-71.4,53.4L238.1,422z"/>

              <rect x="280.9" y="378.2" transform="matrix(0.801 -0.5986 0.5986 0.801 -213.2934 287.2353)" fill="#D1CCE6" width="89.1" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="234.4,427.1 245.7,408.5 232.1,418.6 230.3,421.6
                            "/>
                <path fill="#0B99D6" d="M242.5,438l3.4-5.5l-4.1-5.5l-3.4,5.5L242.5,438z
                   M264.2,402.5l8.8-14.4l-13.6,10.2l-8.8,14.4L264.2,402.5z"/>
                <path fill="#0B99D6" d="M250.7,448.9l3.4-5.5l-4.1-5.5l-3.4,5.5L250.7,448.9
                  L250.7,448.9z M291.5,382.1l8.8-14.4l-13.6,10.2l-8.8,14.4L291.5,382.1z"/>
                <path fill="#0B99D6" d="M258.9,459.9l3.4-5.5l-4.1-5.5l-3.4,5.5L258.9,459.9z
                   M312.3,372.3l3.4-5.5l-4.1-5.5l-6.4,10.5l4.4-3.2L312.3,372.3z"/>
                <path fill="#0B99D6" d="M267,470.8l3.4-5.5l-4.1-5.5l-3.4,5.5L267,470.8L267,470.8z
                   M320.4,383.3l3.4-5.5l-4.1-5.5l-3.4,5.5L320.4,383.3z"/>
                <path fill="#0B99D6" d="M275.2,481.7l3.4-5.5l-4.1-5.5l-3.4,5.5L275.2,481.7z
                   M328.6,394.2l3.4-5.5l-4.1-5.5l-3.4,5.5L328.6,394.2z"/>
                <path fill="#0B99D6" d="M283.4,492.7l3.4-5.5l-4.1-5.5l-3.4,5.5L283.4,492.7z
                   M336.8,405.1l3.4-5.5l-4.1-5.5l-3.4,5.5L336.8,405.1z"/>
                <path fill="#0B99D6" d="M291.6,503.6l3.4-5.5l-4.1-5.5l-3.4,5.5L291.6,503.6z
                   M345,416.1l3.4-5.5l-4.1-5.5l-3.4,5.5L345,416.1z"/>
                <path fill="#0B99D6" d="M299.7,514.5l3.4-5.5l-4.1-5.5l-3.4,5.5L299.7,514.5
                  L299.7,514.5z M353.1,427l3.4-5.5l-4.1-5.5l-3.4,5.5L353.1,427z"/>
                <path fill="#0B99D6" d="M307.9,525.5l3.4-5.5l-4.1-5.5l-3.4,5.5L307.9,525.5
                  L307.9,525.5z M361.3,437.9l3.4-5.5l-4.1-5.5l-3.4,5.5L361.3,437.9z"/>
                <path fill="#0B99D6" d="M316.1,536.4l3.4-5.5l-4.1-5.5l-3.4,5.5L316.1,536.4z
                   M369.5,448.9l3.4-5.5l-4.1-5.5l-3.4,5.5L369.5,448.9z"/>
                <path fill="#0B99D6" d="M324.2,547.3l3.4-5.5l-4.1-5.5l-3.4,5.5L324.2,547.3z
                   M377.6,459.8l3.4-5.5l-4-5.5l-3.4,5.5L377.6,459.8z"/>
                <path fill="#0B99D6" d="M332.4,558.3l3.4-5.5l-4.1-5.5l-3.4,5.5L332.4,558.3z
                   M385.8,470.7l3.4-5.5l-4.1-5.5l-3.4,5.5L385.8,470.7z"/>
                <path fill="#0B99D6" d="M341.1,568.3l8.9-14.4l-8.5,6.4l-1.5-2l-3.4,5.5l3.8,5.1
                  L341.1,568.3L341.1,568.3z M394,481.7l3.4-5.5l-4.1-5.5l-3.4,5.5L394,481.7z"/>
                <path fill="#0B99D6" d="M368.4,547.9l8.8-14.4l-13.6,10.2l-8.8,14.4L368.4,547.9z
                   M402.2,492.6l3.4-5.5l-4.1-5.5l-3.4,5.5L402.2,492.6z"/>
                <path fill="#0B99D6" d="M395.7,527.5l8.8-14.4l-13.6,10.2l-8.8,14.4L395.7,527.5z
                   M410.3,503.5l3.4-5.5l-4.1-5.5l-3.4,5.5L410.3,503.5z"/>
                <polygon fill="#0B99D6" points="417.8,503.5 409.4,517.3 421.4,508.3           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M318.8,505.8c3.9,5.2,11.2,6.2,16.3,2.4s6.2-11.2,2.4-16.3
                s-11.2-6.2-16.3-2.4c-2.5,1.9-4.1,4.6-4.6,7.7C316.1,500.2,316.9,503.3,318.8,505.8z M326.8,508.5c-5.4-0.8-9.1-5.8-8.3-11.1
                s5.8-9.1,11.1-8.3c5.4,0.8,9.1,5.8,8.3,11.1c-0.4,2.6-1.8,4.9-3.8,6.4C332,508.3,329.3,508.9,326.8,508.5L326.8,508.5z"/>
              <path fill="#80CAF0" d="M325.2,502.7c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.6,0-0.8,0.3
                c0,0-3.3,8.1,3.3,10.4c2.2,0.8,3.6,1.1,4.6,1.4c1.4,0.4,1.6,0.4,2,1.3c0.1,0.7,0.1,1.4-0.1,2c-0.8,3-0.2,6.2,1.8,8.6
                c2.4,3,3.7,3.1,5.4,3.1c1.5-0.1,2.9,0.4,4.1,1.2c1.7,0.9,2.9,2.5,3.3,4.3c0.2,1.4,0,2.7-0.5,4c-1,2.6,1,5.3,1,5.3
                c0.2,0.3,0.6,0.3,0.9,0.1s0.3-0.6,0.1-0.9l0,0c0,0-1.6-2.1-0.8-4.1c0.6-1.5,0.8-3,0.6-4.6c-0.5-2.2-1.9-4.1-3.9-5.1
                c-1.4-1-3.1-1.4-4.8-1.4c-1.3,0-2.4,0-4.5-2.6c-1.8-2.1-2.4-5-1.6-7.6c0.3-0.9,0.3-1.8,0-2.7c-0.6-1.4-0.9-1.5-2.8-2
                c-0.9-0.2-2.3-0.6-4.5-1.4c-5.3-1.9-2.5-8.7-2.5-8.7L325.2,502.7z M334.4,495.8c0,0-2.8,6.9,2.5,8.8c2.2,0.8,3.6,1.1,4.5,1.4
                c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.7,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.5,2.6c1.7-0.1,3.4,0.4,4.8,1.4
                c2,1.1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.2-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.2,0.7-0.1,0.9l0,0c-0.3,0.2-0.7,0.1-0.9-0.1
                c0,0-2-2.7-1-5.3c0.5-1.3,0.7-2.6,0.5-4c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1
                c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.3-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.6-2.3-3.3-10.3-3.3-10.4
                c0.1-0.3,0.5-0.5,0.8-0.4C334.4,495.1,334.6,495.4,334.4,495.8L334.4,495.8z M332.1,497.5c0,0-2.8,6.9,2.5,8.7
                c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6
                c1.7-0.1,3.4,0.4,4.8,1.4c2,1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.1-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9
                c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-2-2.7-1-5.3c0.5-1.3,0.7-2.6,0.5-4c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.3-4.1-1.2
                c-1.7,0-3,0-5.4-3.1c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.2-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4
                c-6.5-2.3-3.3-10.4-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3l0,0c0.3,0.1,0.5,0.5,0.4,0.8C332.1,497.4,332.1,497.4,332.1,497.5
                L332.1,497.5z M329.8,499.2c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.2,0.9,0.2,1.8,0,2.7
                c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.5,2.6c1.7-0.1,3.4,0.4,4.8,1.4c2,1.1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.2-0.6,4.6
                c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.2,0.7-0.1,0.9l0,0c-0.3,0.2-0.7,0.2-0.9-0.1l0,0c0,0-2-2.7-1-5.3c0.5-1.2,0.7-2.6,0.5-4
                c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.1-1.2c-1.7,0-3,0-5.4-3.1c-2-2.4-2.7-5.6-1.8-8.6c0.2-0.6,0.2-1.3,0.1-2
                c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.5-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.4
                C329.8,498.5,329.9,498.9,329.8,499.2L329.8,499.2z M327.5,501c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.5,1.1,4.5,1.4
                c1.9,0.5,2.2,0.6,2.8,2c0.3,0.9,0.3,1.8,0,2.7c-0.8,2.6-0.2,5.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6c1.7-0.1,3.4,0.4,4.8,1.4
                c2,1.1,3.4,2.9,3.9,5.1c0.2,1.6,0,3.1-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1l0,0
                c0,0-2-2.7-1-5.3c0.5-1.3,0.7-2.6,0.5-4c-0.4-1.9-1.6-3.4-3.3-4.3c-1.2-0.8-2.7-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1
                c-2-2.4-2.7-5.6-1.9-8.6c0.2-0.6,0.3-1.3,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.5-2.3-3.3-10.3-3.3-10.4
                c0.1-0.3,0.5-0.5,0.8-0.3l0,0c0.3,0.1,0.5,0.5,0.4,0.8C327.6,500.9,327.5,501,327.5,501L327.5,501z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M359.5,450.1l1.3,1.7l0,0c-0.6,0.5-0.8,1.4-0.3,2l0,0
                c0.5,0.6,1.4,0.8,2,0.3l0,0l0.9,1.1l0,0c-0.6,0.5-0.7,1.4-0.1,2c0.5,0.5,1.3,0.7,1.9,0.3l0,0l0.7,1l0,0c-0.6,0.5-0.7,1.4-0.2,2
                s1.3,0.7,1.9,0.3l0,0l0.7,1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5c0.1-0.1,0.2-0.1,0.2-0.2l0,0l0.7,1l0,0
                c-0.6,0.5-0.7,1.4-0.2,2s1.3,0.7,1.9,0.3l0,0l1.3,1.7l-1.4,1c-0.5-0.6-1.4-0.7-2-0.2s-0.7,1.3-0.3,1.9l-1,0.8
                c-0.5-0.6-1.4-0.7-2-0.2s-0.7,1.3-0.3,1.9l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l0,0l-1,0.8c-0.5-0.6-1.4-0.8-2-0.3l0,0
                c-0.6,0.5-0.8,1.4-0.3,2l-1.1,0.9c-0.4-0.6-1.3-0.8-2-0.4c-0.6,0.4-0.8,1.3-0.4,2c0,0,0,0.1,0.1,0.1l-1,0.7
                c-0.5-0.6-1.4-0.7-2-0.2s-0.7,1.3-0.3,1.9l-1,0.8c-0.5-0.6-1.4-0.7-2-0.2s-0.7,1.3-0.3,1.9l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3
                s-0.8,1.4-0.3,2l-1.4,1l-1.3-1.7l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2
                s-1.4-0.8-2-0.3l0,0l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2
                s-1.4-0.8-2-0.3l0,0l0,0l-0.9-1.1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-1.3-1.7l1.4-1c0.4,0.6,1.3,0.8,2,0.4
                c0.6-0.4,0.8-1.3,0.4-2c0,0,0-0.1-0.1-0.1l1-0.7c0.5,0.7,1.4,0.8,2,0.3c0.7-0.5,0.8-1.4,0.3-2l0,0l1-0.7c0.4,0.6,1.3,0.8,2,0.4
                c0.6-0.4,0.8-1.3,0.4-2c0,0,0-0.1-0.1-0.1l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1.1-0.8c0.4,0.7,1.3,0.9,2,0.4
                c0.7-0.4,0.9-1.3,0.4-2c0-0.1-0.1-0.1-0.1-0.2l1-0.7c0.4,0.6,1.3,0.8,2,0.4c0.6-0.4,0.8-1.3,0.4-2c0,0,0-0.1-0.1-0.1l1-0.7
                c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.4,0.6,1.3,0.8,2,0.4c0.6-0.4,0.8-1.3,0.4-2c0,0,0-0.1-0.1-0.1L359.5,450.1z"/>

              <rect x="338.1" y="461.1" transform="matrix(0.8009 -0.5987 0.5987 0.8009 -211.2928 304.6417)" fill="#EF805F" width="28.9" height="18"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M398,511l-2.2,1.7l0,0c-0.6-0.8-1.8-1-2.6-0.4
                c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.5,1.1l0,0c-0.6-0.8-1.8-1.1-2.6-0.5c-0.8,0.6-1.1,1.8-0.5,2.6c0,0,0,0.1,0.1,0.1l0,0l-1.3,1l0,0
                c-0.6-0.8-1.8-1.1-2.6-0.5c-0.8,0.6-1.1,1.8-0.5,2.6c0,0,0,0.1,0.1,0.1l0,0l-1.3,1l0,0c-0.6-0.8-1.8-1-2.6-0.4s-1,1.8-0.4,2.6
                l0,0l0,0l-1.3,1l0,0c-0.6-0.8-1.8-1-2.6-0.4s-1,1.8-0.4,2.6l0,0l-2.2,1.7l-1.4-1.8c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3
                c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l0,0l-1-1.3c0.8-0.6,1.1-1.8,0.5-2.6s-1.8-1.1-2.6-0.5c0,0-0.1,0-0.1,0.1l-1-1.3
                c0.8-0.6,1.1-1.8,0.5-2.6s-1.8-1.1-2.6-0.5c0,0-0.1,0-0.1,0.1l-1.1-1.5c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3
                c0.8-0.6,1.1-1.8,0.5-2.6c-0.6-0.8-1.8-1.1-2.6-0.5c0,0-0.1,0-0.1,0.1l-1-1.3c0.8-0.6,0.9-1.8,0.3-2.6c-0.6-0.8-1.7-0.9-2.5-0.4
                l-1-1.3c0.8-0.6,1-1.8,0.4-2.6c-0.6-0.8-1.8-1-2.6-0.4l-1.4-1.8l2.5-1.7l0,0c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0
                l0,0l1.3-1l0,0c0.6,0.8,1.8,1,2.6,0.4s1-1.8,0.4-2.6l0,0l1.3-1l0,0c0.6,0.8,1.8,1,2.6,0.4s1-1.8,0.4-2.6l0,0l0,0l1.3-1l0,0
                c0.6,0.8,1.8,0.9,2.6,0.3c0.8-0.6,0.9-1.7,0.4-2.5l0,0l1.5-1.1l0,0c0.6,0.8,1.8,0.9,2.6,0.3c0.8-0.6,0.9-1.7,0.4-2.5l0,0
                l2.2-1.7l1.4,1.8c-0.8,0.6-0.9,1.8-0.3,2.6c0.6,0.8,1.7,0.9,2.5,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6c0.6,0.8,1.8,1,2.6,0.4l1,1.3
                c-0.8,0.6-1,1.8-0.4,2.6c0.6,0.8,1.8,1,2.6,0.4l0,0l1,1.3c-0.8,0.6-1,1.8-0.4,2.6c0.6,0.8,1.8,1,2.6,0.4l1.1,1.5
                c-0.8,0.6-1,1.8-0.4,2.6c0.6,0.8,1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6c0.6,0.8,1.8,1,2.6,0.4l0,0l1,1.3
                c-0.8,0.6-1.1,1.8-0.5,2.6c0.6,0.8,1.8,1.1,2.6,0.5c0,0,0.1,0,0.1-0.1l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4L398,511z"/>

              <rect x="360" y="483" transform="matrix(0.8009 -0.5987 0.5987 0.8009 -226.4334 322.4892)" fill="#F5A049" width="23.5" height="37.6"/>
            </g>

            <rect x="277.5" y="419.2" transform="matrix(0.801 -0.5986 0.5986 0.801 -214.4088 256.3729)" fill="#A76C2B" width="2" height="63.1"/>
          </g>

          <rect x="282.6" y="415.4" transform="matrix(0.801 -0.5986 0.5986 0.801 -211.1171 258.6773)" fill="#A76C2B" width="2" height="63.1"/>
        </g>
        <g>
          <path fill="#E2070F" d="M-43.7,294.2l-93.3,231l126.2,51l93.3-231L-43.7,294.2
            L-43.7,294.2z M-39.4,305.1L71.8,350l-87,215.3l-111.2-45L-39.4,305.1z"/>

          <rect x="-143.4" y="375.3" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -420.6383 246.8631)" fill="#E0DCC1" width="232.2" height="119.9"/>
          <path fill="#0B99D6" d="M-54.7,321.4h8.7l3.4-8.5h-8.7L-54.7,321.4L-54.7,321.4z
             M1.3,321.5l22.7,0.1L2.8,313H-20L1.3,321.5z"/>
          <path fill="#0B99D6" d="M-61.6,338.4h8.7l3.4-8.5h-8.7L-61.6,338.4z M43.8,338.7
            l22.7,0.1l-21.2-8.6l-22.8-0.1L43.8,338.7z"/>
          <path fill="#0B99D6" d="M-68.4,355.4h8.7l3.4-8.5H-65L-68.4,355.4z M69.6,355.8h8.7
            l3.4-8.5H65.1l6.8,2.7L69.6,355.8z"/>
          <path fill="#0B99D6" d="M-75.3,372.5h8.7l3.4-8.5h-8.7L-75.3,372.5z M62.7,372.9h8.7
            l3.4-8.5h-8.7L62.7,372.9z"/>
          <path fill="#0B99D6" d="M-82.2,389.5h8.7l3.5-8.5h-8.7L-82.2,389.5L-82.2,389.5z
             M55.8,389.9h8.7l3.4-8.5h-8.7L55.8,389.9z"/>
          <path fill="#0B99D6" d="M-89.1,406.5h8.7l3.4-8.5h-8.7L-89.1,406.5z M48.9,406.9h8.7
            l3.4-8.5h-8.7L48.9,406.9z"/>
          <path fill="#0B99D6" d="M-96,423.5h8.7l3.4-8.5h-8.7L-96,423.5z M42,424h8.7l3.4-8.5
            h-8.7L42,424z"/>
          <path fill="#0B99D6" d="M-102.8,440.6h8.7l3.4-8.5h-8.7L-102.8,440.6L-102.8,440.6z
             M35.2,441h8.7l3.4-8.5h-8.7L35.2,441z"/>
          <path fill="#0B99D6" d="M-109.7,457.6h8.7l3.4-8.5h-8.7L-109.7,457.6L-109.7,457.6z
             M28.3,458H37l3.4-8.5h-8.7L28.3,458z"/>
          <path fill="#0B99D6" d="M-116.6,474.6h8.7l3.4-8.5h-8.7L-116.6,474.6z M21.4,475.1h8.7
            l3.4-8.5h-8.7L21.4,475.1z"/>
          <path fill="#0B99D6" d="M-123.5,491.7h8.7l3.4-8.5h-8.7L-123.5,491.7z M14.5,492.1h8.7
            l3.4-8.5h-8.7L14.5,492.1z"/>
          <path fill="#0B99D6" d="M-130.4,508.7h8.7l3.4-8.5h-8.7L-130.4,508.7L-130.4,508.7z
             M7.6,509.1h8.7l3.4-8.5H11L7.6,509.1z"/>
          <path fill="#0B99D6" d="M-135.8,525.7l22.8,0.1l-13.3-5.4l1.3-3.2h-8.7l-3.2,8
            L-135.8,525.7L-135.8,525.7z M0.7,526.1h8.7l3.4-8.5H4.1L0.7,526.1z"/>
          <path fill="#0B99D6" d="M-93.3,542.9l22.8,0.1l-21.2-8.6l-22.8-0.1L-93.3,542.9z
             M-6.2,543.2h8.7l3.5-8.5h-8.7L-6.2,543.2z"/>

          <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
            M-12.4,538.7l-2-0.8l0,0c0.3-0.7,0-1.6-0.8-1.9l0,0c-0.7-0.3-1.6,0-1.9,0.8l0,0l0,0l-1.3-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9
            c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l0,0l-1.1-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.1-0.5l0,0
            c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.1-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0-1.9,0.8l0,0l0,0
            l-2-0.8l0.7-1.6c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.2c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0.5-1.1
            c0.7,0.3,1.6-0.1,1.9-0.8s0-1.6-0.8-1.9l0,0l0.5-1.2c0.7,0.3,1.6,0,1.9-0.8l0,0c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.3
            c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0.5-1.2c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.1
            c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7,0-1.6-0.8-1.9l0,0l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8l0,0c0.3-0.7-0.1-1.6-0.8-1.9l0.7-1.6l2,0.8
            l0,0c-0.3,0.7,0,1.6,0.8,1.9l0,0c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.8,1.9l0,0c0.7,0.3,1.6-0.1,1.9-0.8
            l0,0l1.1,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9s1.6,0,1.9-0.8l0,0l0,0l1.2,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9c0,0,0,0,0.1,0
            c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.3,0.5l0,0c-0.3,0.7,0.1,1.5,0.8,1.8s1.6-0.1,1.9-0.8l0,0l2,0.8l-0.7,1.6c-0.7-0.3-1.6,0-1.9,0.8
            l0,0c-0.3,0.7,0,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8c-0.3,0.7,0.1,1.6,0.8,1.9l-0.5,1.2c-0.7-0.3-1.6,0.1-1.9,0.8
            s0,1.6,0.8,1.9l0,0l-0.5,1.2c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9L-6,523c-0.7-0.3-1.6,0-1.9,0.8l0,0
            c-0.3,0.7,0.1,1.6,0.8,1.9l-0.5,1.2c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.2c-0.7-0.3-1.6,0.1-1.9,0.8
            c-0.3,0.7,0,1.6,0.8,1.9l0,0l-0.5,1.2c-0.7-0.3-1.6,0.1-1.9,0.8c-0.3,0.7,0.1,1.6,0.8,1.9L-12.4,538.7z"/>

          <rect x="-8.8" y="398.1" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -348.0884 282.778)" fill="#236095" width="88.7" height="2.7"/>

          <rect x="-18.6" y="394.5" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -350.9176 271.4861)" fill="#236095" width="88.7" height="2.7"/>

          <rect x="-28.3" y="390" transform="matrix(0.3746 -0.9272 0.9272 0.3746 -352.8084 259.6233)" fill="#236095" width="88.7" height="2.7"/>
        </g>
        <g>

          <rect x="598.6" y="393.8" transform="matrix(0.8398 -0.5429 0.5429 0.8398 -130.5215 446.4389)" fill="#A3DAF7" width="185.2" height="101.2"/>
          <path fill="#80CAF0" d="M741.5,351.6L708.7,436c-4.7,12.1-18.7,21.6-31,20.9l-91.7-4.8
            L741.5,351.6z"/>
          <g>
            <path fill="#0B99D6" d="M678.4,444.5c7-4.5,16.4-2.5,20.9,4.5s2.5,16.4-4.5,20.9
              s-16.4,2.5-20.9-4.5c-2.2-3.4-2.9-7.5-2.1-11.4C672.6,450.1,675,446.6,678.4,444.5z M689.3,444.7c-6.9-1.5-13.6,2.9-15.1,9.7
              c-1.5,6.9,2.9,13.6,9.7,15.1c6.9,1.5,13.6-2.9,15.1-9.7c0.7-3.3,0.1-6.8-1.7-9.6S692.6,445.4,689.3,444.7z"/>
            <path fill="#0B99D6" d="M684.8,451.2c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8
              c0,0-0.3-11.3,8.7-11.1c3,0.1,4.9,0.2,6.2,0.4c1.9,0.2,2.2,0.2,3-0.7c0.4-0.7,0.7-1.6,0.7-2.4c0.5-2.7,1.1-6.5,6.1-9.6
              c4.3-2.6,5.9-2,8-1.3c1.8,0.7,3.7,0.9,5.6,0.4c3.2-0.7,5-2.1,6-3.7c0.8-1.6,1.2-3.3,1.1-5c0-3.6,3.6-6,3.6-6
              c0.4-0.2,0.9-0.1,1.1,0.2l0,0c0.2,0.4,0.1,0.9-0.2,1.1c0,0-2.9,1.9-2.8,4.6c0.1,2-0.4,4.1-1.3,5.9c-1.2,2-3.3,3.7-7,4.5
              c-2.1,0.5-4.4,0.4-6.4-0.5c-1.6-0.6-3-1-6.6,1.2c-4.4,2.7-5,6.1-5.4,8.5c-0.1,1.2-0.5,2.3-1.2,3.3c-1.4,1.5-1.8,1.4-4.3,1.2
              c-1.3-0.1-3.1-0.3-6.1-0.4C684.6,441.5,684.9,451.1,684.8,451.2L684.8,451.2z M692.9,463.6c0,0-0.3-9.6,7-9.5
              c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.1c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,5-1.8,6.6-1.2
              c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.4-3.8,1.3-5.9c0-2.8,2.8-4.6,2.8-4.6c0.4-0.2,0.5-0.7,0.2-1.1
              c-0.2-0.4-0.7-0.5-1.1-0.2l0,0c0,0-3.6,2.3-3.6,6c0.1,1.8-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7c-1.9,0.4-3.8,0.3-5.6-0.4
              c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.7,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4
              c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8l0,0H692.9z M690.9,460.5c0,0-0.3-9.6,7-9.5
              c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.1c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,5-1.8,6.6-1.2
              c2,0.8,4.3,1,6.5,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.4-3.8,1.3-5.9c0-2.8,2.8-4.6,2.8-4.6c0.4-0.2,0.5-0.7,0.2-1.1
              c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6c0.1,1.8-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7c-1.9,0.5-3.8,0.3-5.6-0.4
              c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.7,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4
              c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8l0,0C690.4,461.3,690.8,460.9,690.9,460.5L690.9,460.5z M688.9,457.4
              c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.1c0.7-1,1.1-2.1,1.1-3.3c0.4-2.4,1-5.8,5.4-8.5
              c3.6-2.2,5-1.8,6.6-1.2c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.4-3.8,1.3-5.9c0-2.8,2.8-4.6,2.8-4.6
              c0.4-0.2,0.5-0.7,0.2-1.1l0,0c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6c0.1,1.8-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7
              c-1.9,0.5-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7
              c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.3,0.8,0.7,0.9c0.4,0,0.8-0.3,0.9-0.7c0-0.1,0-0.1,0-0.2L688.9,457.4
              z M686.9,454.3c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2c0.7-1,1.1-2.1,1.2-3.3c0.4-2.4,1-5.8,5.4-8.5
              c3.6-2.2,4.9-1.8,6.6-1.2c2,0.8,4.3,1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5c1-1.8,1.4-3.8,1.3-5.9c0-2.8,2.8-4.6,2.8-4.6
              c0.4-0.2,0.5-0.7,0.2-1.1l0,0c-0.2-0.4-0.7-0.5-1.1-0.2l0,0c0,0-3.6,2.3-3.6,6c0.1,1.7-0.3,3.5-1.1,5c-1,1.6-2.7,3.1-6,3.7
              c-1.9,0.5-3.8,0.3-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6c0,0.9-0.3,1.7-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7
              c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8L686.9,454.3z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M37.8,387.1l126.8,214.5l117.2-69.3L155.1,317.7L37.8,387.1z
             M49.1,390.2l103.2-61.1L270.5,529l-103.2,61.1L49.1,390.2z"/>

          <rect x="100.2" y="343" transform="matrix(0.8601 -0.5101 0.5101 0.8601 -211.7935 145.9174)" fill="#D3AC89" width="119.9" height="232.2"/>
          <polygon fill="#0B99D6" points="43.4,396.5 61.3,373.2 41.6,384.9 38.7,388.6     "/>
          <path fill="#0B99D6" d="M52.8,412.3l5.3-6.9l-4.7-7.9l-5.3,6.9L52.8,412.3z M86.9,367.9
            l13.9-18L81,361.5l-13.9,18L86.9,367.9z"/>
          <path fill="#0B99D6" d="M62.1,428.1l5.3-6.9l-4.7-7.9l-5.3,6.9L62.1,428.1z M126.3,344.6
            l13.9-18l-19.7,11.6l-13.9,18L126.3,344.6z"/>
          <path fill="#0B99D6" d="M71.4,443.9l5.3-6.9l-4.7-7.9l-5.3,6.9L71.4,443.9L71.4,443.9z
             M155.6,334.5l5.3-6.9l-4.7-7.9l-10.1,13.2l6.3-3.7L155.6,334.5z"/>
          <path fill="#0B99D6" d="M80.8,459.7l5.3-6.9l-4.7-7.9l-5.3,6.9L80.8,459.7z M164.9,350.3
            l5.3-6.9l-4.7-7.9l-5.3,6.9L164.9,350.3z"/>
          <path fill="#0B99D6" d="M90.2,475.5l5.3-6.9l-4.7-7.9l-5.3,6.9L90.2,475.5z M174.3,366.2
            l5.3-6.9l-4.7-7.9l-5.3,6.9L174.3,366.2z"/>
          <path fill="#0B99D6" d="M99.5,491.3l5.3-6.9l-4.7-7.9l-5.3,6.9L99.5,491.3z M183.6,382
            l5.3-6.9l-4.7-7.9l-5.3,6.9L183.6,382z"/>
          <path fill="#0B99D6" d="M108.8,507.1l5.3-6.9l-4.7-7.9l-5.3,6.9L108.8,507.1z M193,397.8
            l5.3-6.9l-4.7-7.9l-5.3,6.9L193,397.8z"/>
          <path fill="#0B99D6" d="M118.2,522.9l5.3-6.9l-4.7-7.9l-5.3,6.9L118.2,522.9z
             M202.3,413.6l5.3-6.9l-4.7-7.9l-5.3,6.9L202.3,413.6z"/>
          <path fill="#0B99D6" d="M127.6,538.8l5.3-6.9l-4.7-7.9l-5.3,6.9L127.6,538.8z
             M211.6,429.4l5.3-6.9l-4.7-7.9l-5.3,6.9L211.6,429.4z"/>
          <path fill="#0B99D6" d="M136.9,554.6l5.3-6.9l-4.7-7.9l-5.3,6.9L136.9,554.6z M221,445.2
            l5.3-6.9l-4.7-7.9l-5.3,6.9L221,445.2z"/>
          <path fill="#0B99D6" d="M146.2,570.4l5.3-6.9l-4.7-7.9l-5.3,6.9L146.2,570.4z M230.4,461
            l5.3-6.9l-4.7-7.9l-5.3,6.9L230.4,461z"/>
          <path fill="#0B99D6" d="M155.6,586.2l5.3-6.9l-4.7-7.9l-5.3,6.9L155.6,586.2z
             M239.7,476.8l5.3-6.9l-4.6-7.9l-5.4,6.9L239.7,476.8z"/>
          <path fill="#0B99D6" d="M165.8,600.9l13.9-18l-12.3,7.3l-1.7-2.9l-5.3,6.9l4.4,7.4
            L165.8,600.9L165.8,600.9z M249.1,492.6l5.3-6.9l-4.7-7.9l-5.3,6.9L249.1,492.6z"/>
          <path fill="#0B99D6" d="M205.3,577.5l13.9-18l-19.7,11.7l-13.9,18L205.3,577.5
            L205.3,577.5z M258.4,508.5l5.3-6.9l-4.7-7.9l-5.3,6.9L258.4,508.5z"/>
          <path fill="#0B99D6" d="M244.7,554.2l13.9-18l-19.7,11.7l-13.9,18L244.7,554.2
            L244.7,554.2z M267.7,524.3l5.3-6.9l-4.7-7.9l-5.3,6.9L267.7,524.3z"/>
          <polygon fill="#0B99D6" points="277.8,525.2 264.5,542.5 281.9,532.2     "/>
          <g>
            <path fill="#80CAF0" d="M144.9,514c4.4,7.5,14.1,10,21.5,5.6c7.5-4.4,10-14.1,5.6-21.5
              c-4.4-7.5-14.1-10-21.5-5.6c-3.6,2.1-6.2,5.6-7.2,9.6C142.2,506.1,142.8,510.4,144.9,514z M155.2,518.8c-3.4-0.9-6.3-3.1-8.1-6.1
              c-3.7-6.3-1.6-14.4,4.6-18.1c6.3-3.7,14.4-1.6,18.1,4.6c3.7,6.3,1.6,14.4-4.6,18.1C162.2,519.2,158.6,519.7,155.2,518.8z"/>
            <path fill="#80CAF0" d="M154,510.8c0.2-0.4,0-0.9-0.4-1.1s-0.8,0-1,0.3
              c0,0-5.6,10.3,2.9,14.3c2.8,1.3,4.7,2,5.9,2.5c1.8,0.7,2.1,0.8,2.5,2c0.1,0.9-0.1,1.8-0.4,2.6c-0.8,2.8-2,6.6,1.2,11.8
              c2.8,4.4,4.6,4.6,6.8,4.9c2,0.1,3.9,0.9,5.4,2.2c2.7,2.1,3.7,4.3,3.8,6.2c0,1.8-0.4,3.6-1.3,5.2c-1.7,3.4,0.5,7.2,0.6,7.2
              c0.2,0.4,0.7,0.5,1.1,0.3c0.4-0.2,0.5-0.7,0.3-1.1l0,0c0,0-1.8-3.1-0.5-5.6c1-1.9,1.5-4,1.5-6.1c-0.2-2.4-1.3-5-4.4-7.4
              c-1.8-1.5-3.9-2.4-6.2-2.5c-1.8-0.2-3.2-0.4-5.6-4.1c-2.8-4.5-1.8-7.9-1-10.4c0.5-1.1,0.6-2.4,0.4-3.6c-0.6-2-1-2.2-3.5-3.1
              c-1.2-0.5-3-1.1-5.8-2.5C149.3,519.6,154,510.8,154,510.8L154,510.8z M167.3,502.9c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5
              c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0.1,2.4-0.4,3.6c-0.8,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c2.3,0.2,4.5,1.1,6.2,2.5
              c3.1,2.5,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3l0,0
              c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9
              c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-5.9-2.5c-8.4-4-2.9-14.3-2.9-14.3
              c0.2-0.4,0.7-0.6,1.1-0.4C167.3,502,167.5,502.5,167.3,502.9L167.3,502.9z M164,504.9c0,0-4.7,8.8,2.1,12.1
              c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0,2.4-0.4,3.6c-0.8,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1
              c2.3,0.2,4.5,1.1,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1
              c-0.4,0.2-0.9,0.1-1.1-0.3c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2
              c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-5.9-2.5
              c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.4C164,504,164.2,504.5,164,504.9C164,504.9,163.9,504.9,164,504.9L164,504.9z
               M160.6,506.9c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0,2.4-0.4,3.6
              c-0.8,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c2.3,0.2,4.5,1.1,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1
              c-1.3,2.5,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3l0,0c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2
              c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.7c0.4-0.8,0.5-1.7,0.4-2.6
              c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-5.9-2.5c-8.4-4-2.9-14.3-2.9-14.4c0.2-0.4,0.7-0.6,1.1-0.4
              C160.6,506,160.8,506.5,160.6,506.9C160.6,506.9,160.6,506.9,160.6,506.9L160.6,506.9z M157.3,508.9c0,0-4.7,8.8,2.1,12.1
              c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.2,1.2,0,2.4-0.4,3.6c-0.8,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1
              c2.3,0.2,4.5,1.1,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4c0.1,2.1-0.4,4.2-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1
              c-0.4,0.2-0.9,0.1-1.1-0.3l0,0c0,0-2.3-3.8-0.6-7.2c0.9-1.6,1.3-3.4,1.3-5.2c-0.1-2-1.1-4.1-3.8-6.2c-1.5-1.3-3.4-2.1-5.4-2.2
              c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.8c0.4-0.8,0.5-1.7,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5
              c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.4C157.2,508,157.4,508.5,157.3,508.9C157.3,508.9,157.3,508.9,157.3,508.9
              L157.3,508.9z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M207.6,466.1l1.1,1.8l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.7,1.2l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0
              l0.6,1.1l0,0c-0.7,0.4-1,1.2-0.6,1.9c0.4,0.7,1.2,1,1.9,0.6l0.1-0.1l0,0l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0
              l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l1.1,1.8l-1.5,0.9c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.1,0.6
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5
              s-0.9,1.3-0.5,2l-1.2,0.7c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.2-1-1.9-0.6s-1,1.2-0.6,1.9l0.1,0.1l-1.5,0.9l-1.1-1.8l0,0
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l0,0l-0.6-1.1l0,0c0.7-0.4,1-1.2,0.6-1.9c-0.4-0.7-1.2-1-1.9-0.6l-0.1,0.1l0,0
              l-0.6-1.1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.6-1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l0,0l-0.7-1.2l0,0
              c0.7-0.4,1-1.2,0.6-1.9c-0.4-0.7-1.2-1-1.9-0.6l-0.1,0.1l0,0l-1.1-1.9l1.5-0.9c0.4,0.7,1.2,1,1.9,0.6s1-1.2,0.6-1.9l-0.1-0.1
              l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5
              s0.9-1.3,0.5-2l0,0l1.2-0.7c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2L207.6,466.1z"/>

            <rect x="184.4" y="475.6" transform="matrix(0.8601 -0.5101 0.5101 0.8601 -219.3686 169.2049)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M235.2,534.2l-2.2,1.3l0,0c-0.5-0.8-1.6-1-2.4-0.5c-0.7,0.5-1,1.5-0.6,2.2l0,0l-1.4,0.9l0,0c-0.5-0.8-1.6-1-2.4-0.5
              c-0.7,0.5-1,1.5-0.6,2.2l0,0l-1.3,0.8l0,0c-0.5-0.8-1.6-1-2.4-0.5c-0.7,0.5-1,1.5-0.6,2.2l0,0l-1.3,0.8l0,0
              c-0.5-0.8-1.6-1-2.4-0.5c-0.7,0.5-1,1.5-0.6,2.2l0,0l-1.3,0.8l0,0c-0.4-0.8-1.4-1.2-2.3-0.8c-0.8,0.4-1.2,1.4-0.8,2.3
              c0,0.1,0.1,0.1,0.1,0.2l0,0l-2.2,1.3l-1.1-1.8c0.8-0.5,1.1-1.5,0.6-2.4c-0.5-0.8-1.5-1.1-2.4-0.6l0,0l-0.8-1.3
              c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3
              c0.8-0.5,1.1-1.5,0.6-2.4c-0.5-0.8-1.5-1.1-2.4-0.6l0,0l-0.9-1.5c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3
              c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-0.8-1.3
              c0.8-0.5,1-1.6,0.5-2.4c-0.5-0.7-1.5-1-2.2-0.6l-1.1-1.8l2.2-1.3l0,0c0.5,0.8,1.6,1,2.4,0.5c0.7-0.5,1-1.5,0.6-2.2l0,0l1.3-0.8
              l0,0c0.4,0.8,1.4,1.2,2.3,0.8c0.8-0.4,1.2-1.4,0.8-2.3c0-0.1-0.1-0.1-0.1-0.2l0,0l1.3-0.8l0,0c0.5,0.8,1.5,1.1,2.3,0.6
              s1.1-1.5,0.6-2.3l0,0l1.3-0.8l0,0c0.4,0.8,1.5,1.2,2.3,0.7s1.2-1.5,0.7-2.3l-0.1-0.1l0,0l1.4-0.9l0,0c0.5,0.8,1.6,1,2.4,0.5
              c0.7-0.5,1-1.5,0.6-2.2l0,0l2.2-1.3l1.1,1.8c-0.8,0.4-1.2,1.5-0.7,2.3c0.4,0.8,1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3
              c-0.8,0.4-1.2,1.5-0.7,2.3c0.4,0.8,1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3c-0.8,0.4-1.2,1.5-0.7,2.3c0.4,0.8,1.5,1.2,2.3,0.7l0.1-0.1
              l0.8,1.3c-0.8,0.4-1.2,1.4-0.8,2.3c0.4,0.8,1.4,1.2,2.3,0.8c0.1,0,0.1-0.1,0.2-0.1l0.9,1.5c-0.8,0.4-1.2,1.5-0.7,2.3
              c0.4,0.8,1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3c-0.8,0.4-1.2,1.5-0.7,2.3c0.4,0.8,1.5,1.2,2.3,0.7l0.1-0.1l0.8,1.3
              c-0.8,0.4-1.2,1.4-0.8,2.3c0.4,0.8,1.4,1.2,2.3,0.8c0.1,0,0.1-0.1,0.2-0.1l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.4
              c0.5,0.8,1.5,1.1,2.4,0.6l0,0L235.2,534.2z"/>

            <rect x="201.5" y="506.2" transform="matrix(0.8607 -0.509 0.509 0.8607 -236.8895 180.9369)" fill="#FBD4A4" width="21.5" height="34.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M251.1,510.6l-1.8,1.1l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.2,0.7l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0
              l0,0L242,516l0,0c-0.4-0.7-1.2-1-1.9-0.6s-1,1.2-0.6,1.9l0.1,0.1l0,0l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0
              l0,0l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.8,1.1l-0.9-1.5c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2
              s-1.3-0.9-2-0.5l0,0l-0.7-1.2c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.9-1.5l1.8-1.1l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l0,0l1.1-0.6l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.2-0.7l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l0,0l1.8-1.1l0.9,1.5c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2
              s1.3,0.9,2,0.5l0,0l0.7,1.2c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5L251.1,510.6z"/>

            <rect x="222.9" y="487.1" transform="matrix(0.8607 -0.509 0.509 0.8607 -223.0074 187.871)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="131.4,371.6 181.9,451.4 168.5,454.6 120.9,376.8     "/>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M508.5,498.8l175.6-58.6l-32-96l-175.6,58.6L508.5,498.8
              L508.5,498.8z M512.5,491.1l-28.2-84.6L648.1,352l28.2,84.6L512.5,491.1z"/>

            <rect x="496.1" y="375.3" transform="matrix(0.9477 -0.3192 0.3192 0.9477 -103.5941 207.9361)" fill="#D1CCE6" width="173" height="89.4"/>
            <g>
              <polygon fill="#0B99D6" points="516.2,496.3 502.1,479.6 507.5,495.8 509.7,498.4
                "/>
              <path fill="#0B99D6" d="M529.1,492l-4.2-5l-6.5,2.1l4.2,5L529.1,492L529.1,492z
                 M502.2,460.3l-10.9-12.9l5.4,16.1l10.9,12.9L502.2,460.3z"/>
              <path fill="#0B99D6" d="M542.1,487.6l-4.2-5l-6.5,2.2l4.2,5L542.1,487.6L542.1,487.6z
                 M491.5,427.9l-11-12.9l5.4,16.1l10.9,12.9L491.5,427.9z"/>
              <path fill="#0B99D6" d="M555,483.3l-4.2-5l-6.5,2.2l4.2,5L555,483.3L555,483.3z
                 M488.7,405.1l-4.2-5l-6.5,2.2l8,9.4l-1.7-5.1L488.7,405.1z"/>
              <path fill="#0B99D6" d="M568,479l-4.2-5l-6.5,2.2l4.2,5L568,479z M501.7,400.8l-4.2-5
                L491,398l4.2,5L501.7,400.8z"/>
              <path fill="#0B99D6" d="M580.9,474.7l-4.2-5l-6.5,2.1l4.2,5L580.9,474.7L580.9,474.7z
                 M514.6,396.4l-4.2-5l-6.4,2.2l4.2,5L514.6,396.4z"/>
              <path fill="#0B99D6" d="M593.9,470.4l-4.2-5l-6.5,2.1l4.2,5L593.9,470.4L593.9,470.4z
                 M527.6,392.1l-4.2-5l-6.5,2.2l4.2,5L527.6,392.1z"/>
              <path fill="#0B99D6" d="M606.8,466l-4.2-5l-6.5,2.1l4.2,5L606.8,466L606.8,466z
                 M540.5,387.8l-4.2-5l-6.5,2.2l4.2,5L540.5,387.8z"/>
              <path fill="#0B99D6" d="M619.8,461.7l-4.2-5l-6.5,2.2l4.2,5L619.8,461.7L619.8,461.7z
                 M553.5,383.5l-4.2-5l-6.5,2.2l4.2,5L553.5,383.5z"/>
              <path fill="#0B99D6" d="M632.7,457.4l-4.2-5l-6.5,2.2l4.2,5L632.7,457.4L632.7,457.4z
                 M566.4,379.2l-4.2-5l-6.5,2.2l4.2,5L566.4,379.2z"/>
              <path fill="#0B99D6" d="M645.7,453.1l-4.2-5l-6.5,2.2l4.2,5L645.7,453.1L645.7,453.1z
                 M579.4,374.8l-4.2-5l-6.5,2.1l4.2,5L579.4,374.8z"/>
              <path fill="#0B99D6" d="M658.6,448.8l-4.2-5L648,446l4.2,5L658.6,448.8L658.6,448.8z
                 M592.3,370.5l-4.2-5l-6.5,2.1l4.2,5L592.3,370.5z"/>
              <path fill="#0B99D6" d="M671.6,444.4l-4.2-5l-6.5,2.1l4.2,5L671.6,444.4L671.6,444.4z
                 M605.3,366.2l-4.2-5l-6.5,2.1l4.2,5L605.3,366.2z"/>
              <path fill="#0B99D6" d="M683.8,439.3l-10.9-12.9l3.4,10.1l-2.4,0.8l4.2,5l6.1-2
                L683.8,439.3L683.8,439.3z M618.2,361.9l-4.2-5l-6.5,2.2l4.2,5L618.2,361.9z"/>
              <path fill="#0B99D6" d="M673,407l-10.9-12.9l5.4,16.1l10.9,12.9L673,407z M631.1,357.6
                l-4.2-5l-6.5,2.2l4.2,5L631.1,357.6z"/>
              <path fill="#0B99D6" d="M662.3,374.7l-10.9-12.9l5.4,16.1l10.9,12.9L662.3,374.7
                L662.3,374.7z M644.1,353.3l-4.2-5l-6.5,2.2l4.2,5L644.1,353.3z"/>
              <polygon fill="#0B99D6" points="646.4,346.1 656.9,358.5 652.1,344.2         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M577.4,384.7l2-0.7l0,0c0.2,0.8,1,1.2,1.8,1s1.2-1,1-1.8
              c0-0.1,0-0.1-0.1-0.2l0,0l1.3-0.5l0,0c0.3,0.8,1.1,1.2,1.8,0.9c0.8-0.3,1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9
              c0.8-0.2,1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0c0.3,0.8,1.1,1.2,1.8,0.9c0.8-0.3,1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0
              c0.3,0.8,1.1,1.2,1.8,0.9c0.8-0.3,1.2-1.1,0.9-1.8l0,0l0,0l2-0.7l0.6,1.7c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1c0.1,0,0.1,0,0.2-0.1
              l0.4,1.2c-0.7,0.3-1.1,1.1-0.8,1.8c0.3,0.7,1,1.1,1.8,0.9l0.4,1.2c-0.7,0.3-1.1,1.1-0.8,1.8c0.3,0.7,1,1.1,1.8,0.9l0.4,1.2
              c-0.8,0.2-1.2,1-1,1.8s1,1.2,1.8,1c0.1,0,0.1,0,0.2-0.1l0.5,1.3c-0.8,0.3-1.2,1.1-0.9,1.8c0.3,0.8,1.1,1.2,1.8,0.9l0,0l0.4,1.2
              c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0.4,1.2c-0.8,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0,0l0.4,1.2
              c-0.8,0.3-1.2,1.1-0.9,1.8c0.3,0.8,1.1,1.2,1.8,0.9l0.5,1.7l-2,0.7l0,0c-0.2-0.8-1-1.2-1.8-1s-1.2,1-1,1.8c0,0.1,0,0.1,0.1,0.2
              l0,0l-1.2,0.4l0,0c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.3-0.8-1.1-1.2-1.8-0.9
              c-0.8,0.3-1.2,1.1-0.9,1.8l0,0l0,0l-1.2,0.4l0,0c-0.2-0.8-1.1-1.2-1.8-0.9c-0.8,0.2-1.2,1.1-0.9,1.8l0,0l-1.3,0.5l0,0
              c-0.3-0.7-1.1-1.1-1.8-0.8c-0.7,0.3-1.1,1-0.9,1.8l0,0l-2,0.7l-0.5-1.7c0.7-0.3,1.1-1.1,0.8-1.8c-0.3-0.7-1-1.1-1.8-0.9l-0.4-1.2
              c0.7-0.3,1.1-1.1,0.8-1.8c-0.3-0.7-1-1.1-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1c-0.1,0-0.1,0-0.2,0.1l-0.4-1.2
              c0.8-0.2,1.2-1,1-1.8s-1-1.2-1.8-1c-0.1,0-0.1,0-0.2,0.1l-0.5-1.3c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l0,0
              l-0.4-1.2c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9l-0.4-1.2c0.8-0.3,1.2-1.1,0.9-1.8c-0.3-0.8-1.1-1.2-1.8-0.9
              l-0.4-1.2c0.8-0.2,1.2-1.1,0.9-1.8c-0.2-0.8-1.1-1.2-1.8-0.9L577.4,384.7z"/>

            <rect x="585.2" y="383.2" transform="matrix(0.9487 -0.3163 0.3163 0.9487 -95.2779 208.3587)" fill="#EF805F" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="509.8,426.5 551.9,397.6 554,404.8 512.7,432.1     "/>
          <polygon fill="#189AD7" points="515.8,435 557.9,406.1 560,413.3 518.8,440.6     "/>
          <polygon fill="#189AD7" points="522.9,445.9 547.8,428.9 549,433.1 524.7,449.1     "/>
        </g>
        <g>

          <rect x="835.3" y="383.2" transform="matrix(0.8645 -0.5026 0.5026 0.8645 -92.2181 524.65)" fill="#96CBCC" width="183.5" height="100.3"/>
          <path fill="#8BB3B2" d="M827.1,433.1l89.8,8.7c12.9,1.2,27.6-7,32.5-18.2l36.9-83.6
            L827.1,433.1z"/>
          <g>
            <path fill="#0B99D6" d="M938,418.8c-7.2,4.2-9.6,13.4-5.4,20.6
              c4.2,7.2,13.4,9.6,20.6,5.4s9.6-13.4,5.4-20.6c-2-3.5-5.3-6-9.2-7C945.6,416.2,941.4,416.7,938,418.8z M933.3,428.6
              c1.8-6.8,8.7-10.8,15.5-9s10.8,8.7,9,15.5s-8.7,10.8-15.5,9c-3.2-0.9-6-3-7.7-5.9C933,435.3,932.5,431.8,933.3,428.6L933.3,428.6
              z"/>
            <path fill="#0B99D6" d="M941,427.5c0.4,0.2,0.9,0,1.1-0.4c0.2-0.4,0-0.8-0.3-1
              c0,0-9.9-5.4-13.8,2.7c-1.3,2.7-2,4.5-2.5,5.7c-0.7,1.7-0.8,2-1.9,2.4c-0.9,0.1-1.7-0.1-2.5-0.4c-2.6-0.8-6.3-2-11.3,1.1
              c-4.2,2.6-4.5,4.4-4.7,6.5c-0.2,1.9-0.9,3.7-2.2,5.2c-2,2.6-4.1,3.5-6,3.6c-1.8,0-3.5-0.4-5-1.3c-3.2-1.7-6.9,0.5-6.9,0.5
              c-0.4,0.2-0.5,0.7-0.3,1.1s0.7,0.5,1.1,0.3l0,0c0,0,2.9-1.7,5.4-0.5c1.8,1,3.8,1.5,5.8,1.4c2.3-0.1,4.8-1.2,7.2-4.2
              c1.4-1.7,2.3-3.7,2.5-5.9c0.2-1.7,0.4-3.1,4-5.3c4.4-2.7,7.6-1.7,10-1c1.1,0.5,2.3,0.6,3.4,0.5c1.9-0.6,2.1-1,3-3.3
              c0.5-1.2,1.1-2.9,2.4-5.6C932.7,422.9,941.1,427.4,941,427.5L941,427.5z M948.5,440.3c0,0-8.4-4.6-11.6,2
              c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.5c-2.3-0.7-5.6-1.7-10,1c-3.6,2.2-3.8,3.6-4,5.3
              c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.5-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3
              c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1,6-3.6c1.2-1.4,2-3.3,2.1-5.2
              c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.5-5.7
              c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1l0,0C949.3,440.4,948.9,440.5,948.5,440.3L948.5,440.3z M946.6,437.1
              c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.5c-2.3-0.7-5.6-1.7-10,1
              c-3.6,2.2-3.8,3.6-4,5.3c-0.2,2.2-1,4.3-2.5,5.9c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.5-5.4,0.5
              c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1,6-3.6
              c1.2-1.4,2-3.3,2.1-5.2c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.4,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4
              c0.5-1.2,1.1-3,2.5-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1l0,0C947.5,437.2,947,437.3,946.6,437.1L946.6,437.1z
               M944.8,433.9c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.3,0-3.4-0.4
              c-2.3-0.7-5.6-1.8-10,1c-3.6,2.2-3.8,3.6-4,5.3c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4
              c-2.5-1.3-5.4,0.5-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3l0,0c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.3,1.3,5,1.3
              c1.9-0.1,4-1.1,6-3.6c1.2-1.4,2-3.3,2.1-5.2c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.6-1.9,11.3-1.1c0.8,0.4,1.6,0.5,2.5,0.5
              c1.2-0.4,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.5-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1l0,0
              C945.6,433.9,945.1,434.1,944.8,433.9C944.8,433.9,944.7,433.9,944.8,433.9L944.8,433.9z M942.9,430.7c0,0-8.4-4.6-11.6,2
              c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1.2,0.2-2.4,0-3.4-0.5c-2.3-0.7-5.6-1.7-10,1c-3.6,2.2-3.8,3.6-4,5.3
              c-0.2,2.2-1,4.3-2.5,6c-2.4,3-4.9,4.1-7.2,4.2c-2,0.1-4-0.4-5.8-1.4c-2.5-1.3-5.4,0.5-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3
              c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.5,0.9,3.2,1.3,5,1.3c1.9-0.1,4-1,6-3.6c1.2-1.4,2-3.3,2.1-5.2
              c0.3-2.1,0.5-3.9,4.8-6.5c5-3.1,8.7-1.9,11.3-1.1c0.8,0.3,1.6,0.5,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.5-5.7
              c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.6,0.6,0.4,1C943.8,430.7,943.4,430.9,942.9,430.7C942.9,430.8,942.9,430.8,942.9,430.7
              L942.9,430.7z"/>
          </g>
        </g>
        <g>

          <rect x="333.7" y="418.7" transform="matrix(0.9942 -0.108 0.108 0.9942 -50.1563 52.8934)" fill="#96CBCC" width="259.1" height="141.6"/>
          <path fill="#8BB3B2" d="M584.4,405.1L490.3,490c-13.5,12.2-36.9,15.2-52,6.6l-111.5-63.5
            L584.4,405.1z"/>
          <g>
            <path fill="#0B99D6" d="M447.2,481.6c11.6-1.3,22.1,7.1,23.4,18.7s-7.1,22.1-18.7,23.4
              s-22.1-7.1-23.4-18.7c-0.6-5.6,1-11.2,4.5-15.6S441.6,482.2,447.2,481.6L447.2,481.6z M460.6,488.8c-7.7-6.2-18.9-5-25.1,2.7
              s-5,18.9,2.7,25.1c7.7,6.2,18.9,5,25.1-2.7c3-3.7,4.3-8.4,3.8-13.1C466.7,496.1,464.3,491.7,460.6,488.8z"/>
            <path fill="#0B99D6" d="M451,494c-0.2,0.6-0.8,0.9-1.4,0.8c-0.6-0.2-0.9-0.8-0.8-1.4
              c0-0.1,0.1-0.2,0.1-0.3c0,0,6.7-14.3,17.8-8.5c3.7,2,6,3.4,7.5,4.4c2.2,1.4,2.6,1.6,4.2,1.1c0.8-0.3,1.5-1.3,2.5-2.6
              c2.3-3.1,5.5-7.5,13.7-8.2c7-0.6,8.7,1.2,10.8,3.4c1.8,2,4.1,3.4,6.7,4c4.5,1.2,7.6,0.5,9.8-0.9c2-1.4,3.6-3.4,4.6-5.6
              c2.2-4.6,8.2-5.2,8.2-5.2c0.6-0.1,1.2,0.4,1.2,1c0.1,0.6-0.4,1.2-1,1.2l0,0c0,0-4.8,0.5-6.5,4c-1.2,2.6-3,4.9-5.4,6.5
              c-2.7,1.8-6.4,2.6-11.6,1.2c-3-0.7-5.7-2.3-7.8-4.6c-1.7-1.8-3-3.2-9-2.7c-7.2,0.6-10,4.5-12.1,7.3c-1.1,1.6-2.1,2.9-3.5,3.4
              c-2.6,1-3.1,0.6-6.1-1.3c-1.5-1-3.7-2.4-7.4-4.3C456.7,481.8,451,494,451,494z M453.2,514.7c0,0,5.7-12.2,14.8-7.4
              c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.4-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7
              c2,2.3,4.7,3.9,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2l0,0
              c-0.1-0.6-0.6-1.1-1.2-1l0,0c0,0-6,0.6-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.5-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4
              c-2.1-2.2-3.8-4-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4
              c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.5-0.1,1.2,0.4,1.5s1.2,0.1,1.5-0.4C453.2,514.8,453.2,514.8,453.2,514.7L453.2,514.7z
               M452.7,509.5c0,0,5.7-12.2,14.8-7.4c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4
              c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5
              c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2l0,0c-0.1-0.6-0.6-1.1-1.2-1l0,0c0,0-6,0.6-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6
              c-2.2,1.5-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4c-2.1-2.2-3.8-4-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6
              c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.5-0.1,1.2,0.4,1.5s1.2,0.1,1.5-0.4
              C452.7,509.7,452.7,509.6,452.7,509.5L452.7,509.5z M452.1,504.3c0,0,5.7-12.2,14.8-7.4c3.7,1.9,5.9,3.4,7.4,4.3
              c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c2,2.3,4.7,3.9,7.7,4.6
              c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2s-0.6-1.1-1.2-1l0,0
              c0,0-6,0.6-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.5-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4c-2.1-2.2-3.8-4-10.8-3.4
              c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5
              c-0.3,0.5-0.2,1.2,0.3,1.6s1.2,0.2,1.6-0.3C452,504.6,452.1,504.5,452.1,504.3L452.1,504.3z M451.6,499.2c0,0,5.7-12.2,14.7-7.4
              c3.7,1.9,5.9,3.4,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.6,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7
              c2,2.3,4.7,4,7.7,4.7c5.2,1.4,8.9,0.6,11.6-1.2c2.3-1.6,4.2-3.9,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2l0,0
              c-0.1-0.6-0.6-1.1-1.2-1c0,0-6,0.6-8.2,5.2c-1,2.2-2.6,4.2-4.6,5.6c-2.2,1.5-5.4,2.1-9.8,0.9c-2.6-0.6-5-2-6.7-4
              c-2.1-2.2-3.8-4-10.8-3.4c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4
              c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.5-0.2,1.2,0.3,1.6s1.2,0.2,1.6-0.3C451.5,499.4,451.5,499.3,451.6,499.2L451.6,499.2z"/>
          </g>
        </g>
        <g>

          <rect x="474.7" y="316.8" transform="matrix(0.2256 -0.9742 0.9742 0.2256 8.0248 828.7051)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M446.6,339.1l59.5,68.3c8.6,9.8,24.9,13.9,36.3,9.1l84.6-35.6
            L446.6,339.1z"/>
          <g>
            <path fill="#0B99D6" d="M537.5,405.1c-8.1-1.9-16.3,3.2-18.1,11.3
              c-1.9,8.1,3.2,16.3,11.3,18.1s16.3-3.2,18.1-11.3c0.9-3.9,0.2-8-1.9-11.4C544.8,408.4,541.4,406,537.5,405.1z M527.3,409
              c6-3.7,13.8-1.9,17.6,4.1c3.7,6,1.9,13.8-4.1,17.6c-6,3.7-13.8,1.9-17.6-4.1c-1.8-2.9-2.4-6.3-1.6-9.6
              C522.4,413.6,524.4,410.8,527.3,409z"/>
            <path fill="#0B99D6" d="M533.7,413.6c0.1,0.4,0.6,0.6,1,0.5s0.6-0.6,0.5-1
              c0,0-3.5-10.7-11.9-7.5c-2.8,1.1-4.5,1.9-5.7,2.5c-1.7,0.8-2,1-3,0.4c-0.7-0.5-1.2-1.2-1.5-2c-1.4-2.4-3.2-5.8-9-7
              c-4.9-1-6.3,0.1-7.9,1.5c-1.4,1.3-3.2,2.1-5.1,2.3c-3.3,0.5-5.4-0.3-6.9-1.5c-1.3-1.2-2.3-2.7-2.8-4.4c-1.2-3.4-5.4-4.4-5.4-4.4
              c-0.4-0.1-0.8,0.3-0.9,0.7c0,0.4,0.2,0.7,0.5,0.9c0,0,3.3,0.8,4.2,3.4c0.6,1.9,1.7,3.7,3.2,5.1c1.8,1.5,4.4,2.4,8.1,1.8
              c2.2-0.2,4.3-1.2,5.9-2.6c1.3-1.1,2.4-2,6.6-1.1c5,1,6.7,4,7.9,6.2c0.5,1.1,1.2,2,2.2,2.7c1.8,0.9,2.2,0.7,4.5-0.4
              c1.1-0.5,2.8-1.4,5.6-2.4C530.7,404.4,533.7,413.5,533.7,413.6L533.7,413.6z M530.3,428c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.7-3.1-3.3-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1
              s0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.1,0.4-0.1,0.9-0.5,1S530.5,428.5,530.3,428L530.3,428z M531.2,424.4c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4
              c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.1-2.9-5.1-7.9-6.2c-4.1-0.9-5.2,0-6.6,1.1c-1.6,1.5-3.7,2.4-5.9,2.6
              c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.7-3.1-3.3-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1s0.5-0.7,1-0.6
              c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3.1-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.1,0.4-0.1,0.9-0.5,1S531.3,424.8,531.2,424.4L531.2,424.4L531.2,424.4z M532,420.8c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.1-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.7-3.1-3.3-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1
              s0.5-0.7,1-0.6l0,0c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3.1-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1S532.1,421.2,532,420.8L532,420.8z M532.9,417.2c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.7-1.7-1.6-2.2-2.7c-1.2-2.1-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.6,1.5-3.7,2.4-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.5-1.4-2.6-3.1-3.2-5.1c-0.9-2.6-4.2-3.4-4.2-3.4c-0.4-0.1-0.7-0.5-0.6-1
              s0.5-0.7,1-0.6l0,0c0,0,4.2,1,5.4,4.4c0.5,1.7,1.5,3.2,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c1.9-0.2,3.7-1,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.3,0.8,0.9,1.5,1.5,2c1.1,0.5,1.4,0.4,3-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.2,0.4,0,0.9-0.4,1.1s-0.9,0-1.1-0.4C532.9,417.3,532.9,417.2,532.9,417.2L532.9,417.2z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M418.1,439.9l-136.2,0.3l0.5,249.2l136.2-0.3L418.1,439.9
            L418.1,439.9z M410.4,680.6l-120,0.2L290,448.6l120-0.2L410.4,680.6z"/>

          <rect x="290.3" y="448.4" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -1.0831 0.6736)" fill="#E0DCC1" width="120" height="232.2"/>
          <polygon fill="#0B99D6" points="418.1,450.8 390.9,439.9 413.7,439.9 418.1,441.6     "/>
          <path fill="#0B99D6" d="M418.1,469.1V460l-8.1-3.2v9.2L418.1,469.1z M389,448.4
            l-21.1-8.4H345l21.1,8.4H389z"/>
          <path fill="#0B99D6" d="M418.1,487.5v-9.2l-8.1-3.2v9.2L418.1,487.5L418.1,487.5z
             M343.1,448.5L322,440h-22.9l21.1,8.4L343.1,448.5z"/>
          <path fill="#0B99D6" d="M418.2,505.9v-9.2l-8.1-3.2v9.2L418.2,505.9z M290,448.6h7.3
            l-15.4-6.1v9.2l8.1,3.2V448.6z"/>
          <path fill="#0B99D6" d="M418.2,524.2V515l-8.1-3.2v9.2L418.2,524.2z M290,464l-8.1-3.2
            v9.2l8.1,3.2V464z"/>
          <path fill="#0B99D6" d="M418.3,542.6v-9.2l-8.1-3.2v9.2L418.3,542.6z M290.1,482.4
            l-8.1-3.2v9.2l8.1,3.2V482.4z"/>
          <path fill="#0B99D6" d="M418.3,561v-9.2l-8.1-3.2v9.2L418.3,561L418.3,561z M290.1,500.8
            l-8.1-3.2v9.2l8.1,3.2V500.8z"/>
          <path fill="#0B99D6" d="M418.3,579.3v-9.2l-8.1-3.2v9.2L418.3,579.3z M290.1,519.1
            l-8.1-3.2v9.2l8.1,3.2V519.1z"/>
          <path fill="#0B99D6" d="M418.4,597.7v-9.2l-8.1-3.2v9.2L418.4,597.7L418.4,597.7z
             M290.2,537.5l-8.1-3.2v9.2l8.1,3.2V537.5z"/>
          <path fill="#0B99D6" d="M418.4,616.1v-9.2l-8.1-3.2v9.2L418.4,616.1z M290.2,555.8
            l-8.1-3.2v9.2l8.1,3.2V555.8z"/>
          <path fill="#0B99D6" d="M418.4,634.5v-9.2l-8.1-3.3v9.2L418.4,634.5L418.4,634.5z
             M290.3,574.2l-8.1-3.2v9.2l8.1,3.2V574.2z"/>
          <path fill="#0B99D6" d="M418.5,652.8v-9.2l-8.1-3.2v9.2L418.5,652.8z M290.3,592.6
            l-8.1-3.2v9.2l8.1,3.2V592.6z"/>
          <path fill="#0B99D6" d="M418.5,671.2V662l-8.1-3.2v9.2L418.5,671.2z M290.3,611l-8.1-3.2
            v9.2l8.1,3.2V611z"/>
          <path fill="#0B99D6" d="M417.2,689h1.3v-8.6l-8.1-3.2v3.4h-14.3L417.2,689z M290.4,629.3
            l-8.1-3.2v9.2l8.1,3.2V629.3z"/>
          <path fill="#0B99D6" d="M371.4,689.1h22.9l-21.1-8.4h-22.9L371.4,689.1z M290.4,647.7
            l-8.1-3.2v9.2l8.1,3.2V647.7z"/>
          <g>
            <path fill="#80CAF0" d="M390.8,603.7c0-8.7-7.1-15.7-15.7-15.6s-15.7,7.1-15.6,15.7
              c0,8.7,7.1,15.7,15.7,15.6c4.2,0,8.1-1.7,11.1-4.6C389.2,611.9,390.8,607.9,390.8,603.7z M375.1,616.9c-7.3,0-13.2-5.9-13.2-13.2
              s5.9-13.2,13.2-13.2c7.3,0,13.2,5.9,13.2,13.2c0,3.5-1.4,6.9-3.9,9.3C382,615.5,378.6,616.9,375.1,616.9L375.1,616.9z"/>
            <path fill="#80CAF0" d="M381.4,605.5c0,0,8.5,5.2,4.3,11.5c-1.7,2.6-2.9,4.1-3.8,5.1
              c-1.6,2.1-1.9,2.4-1.4,4.4c0.4,1.1,1.2,2.1,2.2,2.8c1.9,1.7,4.5,4.1,4.4,9.5c-0.1,4.4-1.2,5.3-2.7,6.4c-1.9,1.3-3.3,3.2-4,5.3
              c-1.5,3.7-1.1,6.5,0,8.7c1,1.9,2.5,3.4,4.4,4.5c2.4,1.5,2.4,5.1,2.4,5.1c0,0.5,0.4,0.8,0.8,0.8l0,0c0.5,0,0.8-0.4,0.8-0.8
              c0,0,0-4.5-3.2-6.5c-1.6-0.9-2.9-2.2-3.8-3.8c-0.9-1.8-1.1-4.1,0.1-7.3c0.7-1.9,1.9-3.5,3.5-4.6c1.8-1.4,3.2-2.5,3.3-7.7
              c0.1-6.1-2.8-8.8-5-10.7c-0.7-0.5-1.3-1.2-1.7-2c-0.3-1.2-0.1-1.5,1.1-3c0.8-1.1,2.1-2.6,3.8-5.2c5.2-7.8-4.8-13.8-4.8-13.8
              c-0.4-0.2-0.9-0.1-1.1,0.3l0,0C380.9,604.8,381,605.3,381.4,605.5L381.4,605.5L381.4,605.5z M365.9,605.5
              c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3c0,0,10,6,4.8,13.8c-1.7,2.6-3,4.2-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3
              c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.6c-1.2,3.2-1,5.6-0.1,7.3
              c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8l0,0c0,0,0-3.6-2.4-5.1
              c-1.8-1.1-3.3-2.6-4.4-4.4c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.3c1.4-1.1,2.6-2,2.7-6.4c0.1-5.3-2.5-7.7-4.4-9.5
              c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.8-5.1C374.5,610.7,365.9,605.6,365.9,605.5L365.9,605.5z
               M369.8,605.5c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3c0,0,10,6.1,4.8,13.8c-1.8,2.6-3,4.2-3.8,5.2
              c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.7
              c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8
              c0,0,0-3.5-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4
              c0.1-5.3-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4c0.8-1,2-2.5,3.8-5.1
              C378.3,610.7,369.8,605.6,369.8,605.5L369.8,605.5z M373.6,605.5c-0.4-0.2-0.5-0.7-0.3-1.1c0.2-0.4,0.7-0.5,1.1-0.3
              c0,0,10,6.1,4.8,13.8c-1.7,2.6-3,4.2-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7
              c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.7c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5
              c0,0.5-0.4,0.8-0.8,0.8l0,0c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-3.5-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7
              c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4c0.1-5.3-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4
              c0.8-1,2-2.5,3.8-5.1C382.1,610.7,373.6,605.6,373.6,605.5L373.6,605.5z M377.2,604.4c0.2-0.4,0.7-0.5,1.1-0.3l0,0
              c0,0,10,6,4.8,13.8c-1.7,2.6-3,4.2-3.8,5.2c-1.2,1.5-1.4,1.8-1.1,3c0.4,0.8,1,1.5,1.7,2c2.1,1.9,5.1,4.6,5,10.7
              c-0.1,5.2-1.5,6.3-3.3,7.7c-1.6,1.1-2.9,2.8-3.5,4.6c-1.2,3.2-1,5.6-0.1,7.3c0.9,1.6,2.2,2.9,3.8,3.8c3.2,2,3.2,6.5,3.2,6.5
              c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8c0,0,0-3.5-2.4-5.1c-1.8-1.1-3.3-2.6-4.4-4.5c-1.1-2.1-1.4-5,0-8.7
              c0.7-2.2,2.1-4,4-5.4c1.4-1.1,2.6-2,2.7-6.4c0.1-5.3-2.5-7.7-4.4-9.5c-1-0.7-1.8-1.7-2.2-2.8c-0.5-2-0.2-2.4,1.4-4.4
              c0.8-1,2-2.5,3.8-5.1c4.2-6.3-4.3-11.5-4.3-11.5C377.1,605.3,377,604.8,377.2,604.4L377.2,604.4z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M312.4,594.5v2.1l0,0c0.8,0.1,1.4,0.8,1.3,1.6c-0.1,0.7-0.6,1.3-1.3,1.3l0,0v1.4l0,0c0.8,0,1.4,0.6,1.4,1.4
              c0,0.8-0.6,1.4-1.4,1.4l0,0v1.2l0,0c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4l0,0l0,0v1.2l0,0c0.8,0,1.4,0.6,1.4,1.4
              c0,0.8-0.6,1.4-1.4,1.5l0,0v1.2l0,0c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4l0,0l0,0v2.1h1.8c0-0.8,0.6-1.4,1.4-1.4
              s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.2
              c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4h1.4c0-0.8,0.6-1.4,1.4-1.4l0,0c0.8,0,1.4,0.6,1.4,1.4h1.2c0-0.8,0.6-1.4,1.4-1.4l0,0
              c0.8,0,1.4,0.6,1.4,1.4h1.2c0-0.8,0.6-1.4,1.4-1.4l0,0c0.8,0,1.4,0.6,1.4,1.4h1.2c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0h1.8
              V616l0,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0l0,0V612l0,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0l0,0
              V608l0,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0l0,0V604l0,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0v-1.4
              l0,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0v-2.1h-1c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4H342
              c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4H338c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4H334c0,0.8-0.6,1.4-1.4,1.4
              s-1.4-0.6-1.4-1.4l0,0h-1.4c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.2
              c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0L312.4,594.5z"/>

            <rect x="315.7" y="597.3" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -1.1634 0.635)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M323.5,667.2h2.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h1.7l0,0c-0.1-0.9,0.6-1.8,1.6-1.8
              c0.9-0.1,1.8,0.6,1.8,1.6c0,0.1,0,0.2,0,0.3l0,0h1.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7l0,0h1.5l0,0
              c-0.1-0.9,0.6-1.8,1.6-1.8c0.9-0.1,1.8,0.6,1.8,1.6c0,0.1,0,0.2,0,0.3l0,0h1.5l0,0c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
              l0,0h2.5V665c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7l0,0v-1.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7v-1.5
              c-0.9,0.1-1.8-0.6-1.8-1.6s0.6-1.8,1.6-1.8c0.1,0,0.2,0,0.3,0v-1.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7l0,0v-1.7
              c-0.9,0.1-1.8-0.6-1.8-1.6s0.6-1.8,1.6-1.8c0.1,0,0.2,0,0.3,0v-1.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7v-1.5
              c-0.9-0.1-1.6-0.9-1.6-1.8c0.1-0.8,0.7-1.5,1.6-1.6v-1.5c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7V625h-2.5l0,0
              c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7l0,0h-1.5l0,0c-0.1,0.9-0.9,1.6-1.8,1.6c-0.8-0.1-1.5-0.7-1.6-1.6l0,0h-1.5l0,0
              c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7l0,0h-1.5l0,0c-0.1,0.9-0.9,1.6-1.8,1.6c-0.8-0.1-1.5-0.7-1.6-1.6l0,0h-1.7l0,0
              c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7l0,0h-2.6v2.1c0.9-0.1,1.8,0.6,1.8,1.6s-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5
              c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7l0,0v1.6c0.9-0.1,1.8,0.6,1.8,1.6s-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5
              c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7v1.7c0.9-0.1,1.8,0.6,1.8,1.6s-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5
              c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7l0,0v1.5c0.9-0.1,1.8,0.6,1.8,1.6s-0.6,1.8-1.6,1.8c-0.1,0-0.2,0-0.3,0v1.5
              c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7L323.5,667.2z"/>

            <rect x="326.9" y="628.9" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -1.2399 0.6494)" fill="#FBD4A4" width="21.5" height="34.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.21" strokeMiterlimit="22.93" d="
              M297.8,655h2.2l0,0c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0l0,0h1.4l0,0c0.1-0.8,0.8-1.4,1.6-1.3c0.7,0.1,1.3,0.6,1.3,1.3l0,0
              h1.2l0,0c0-0.8,0.6-1.4,1.4-1.4l0,0c0.8,0,1.4,0.7,1.4,1.4l0,0h1.2l0,0c0-0.8,0.6-1.4,1.4-1.4l0,0c0.8,0,1.4,0.6,1.4,1.4l0,0h1.2
              l0,0c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0,0l0,0h2.1v-1.8c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4v-1.2
              c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4V645c-0.8,0.1-1.5-0.5-1.6-1.3s0.5-1.5,1.3-1.6c0.1,0,0.2,0,0.2,0v-1.2
              c-0.8,0.1-1.5-0.5-1.6-1.3s0.5-1.5,1.3-1.6c0.1,0,0.2,0,0.2,0v-1.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0v-1.2
              c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4v-1.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0v-1.2
              c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4l0,0V620h-2.1l0,0c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0l0,0h-1.2l0,0
              c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.2l0,0c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0H307l0,0
              c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4l0,0h-1.4l0,0c0.1,0.8-0.5,1.5-1.3,1.6s-1.5-0.5-1.6-1.3c0-0.1,0-0.2,0-0.2l0,0h-2.1v1.8
              c0.8,0.1,1.4,0.8,1.3,1.6c-0.1,0.7-0.6,1.3-1.3,1.3v1.2c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4v1.2
              c0.8,0.1,1.4,0.8,1.3,1.6c-0.1,0.7-0.6,1.3-1.3,1.3v1.2c0.8,0,1.4,0.6,1.4,1.4l0,0c0,0.8-0.6,1.4-1.4,1.4v1.4
              c0.8,0,1.4,0.6,1.4,1.4c0.1,0.8-0.5,1.5-1.3,1.5l0,0v1.2c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4l0,0v1.2
              c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4v1.2c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4l0,0L297.8,655z"/>

            <rect x="300.6" y="623" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -1.2232 0.5956)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="393" y="468" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.9829 0.758)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="303.9" y="463.7" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.9748 0.587)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="314.3" y="464" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.9754 0.6069)" fill="#236095" width="2.7" height="88.7"/>

          <rect x="325" y="463.4" transform="matrix(1 -1.919854e-03 1.919854e-03 1 -0.9742 0.6275)" fill="#236095" width="2.7" height="88.7"/>
        </g>
        <g>
          <polygon fill="#FFFFFF" points="36.4,617 157.2,432.7 21.4,381.6 -99.4,566     "/>
          <path fill="#E6E6E5" d="M157.2,432.7L38.1,479c-17.1,6.6-28.5,23.3-25.3,36.9L36.4,617
            L157.2,432.7z"/>
          <g>
            <path fill="#0B99D6" d="M31,513.6c-2.7,4.2-7.5,7.1-12.9,8.4c-5.7,1.4-11.7,1-17.2-1
              c-5.6-2.1-9.6-5.7-11.5-9.8c-2-4.2-1.6-9.1,1-12.9c2.7-4.2,7.5-7.1,12.9-8.4c5.7-1.4,11.7-1,17.3,1c5.6,2.1,9.6,5.7,11.5,9.8
              C34,504.8,33.6,509.8,31,513.6L31,513.6z M19,493.2c-4.6-1.7-9.7-2-14.5-0.8c-4.6,1.1-8.6,3.6-10.9,7.1
              c-2.2,3.2-2.5,7.3-0.9,10.8c1.6,3.4,4.9,6.5,9.6,8.2c4.6,1.7,9.7,2,14.5,0.8c4.6-1.1,8.6-3.6,10.9-7.1c2.2-3.2,2.5-7.3,0.9-10.9
              C27.1,498,23.7,495,19,493.2z"/>
            <path fill="#0B99D6" d="M19.9,507.2c0,0,14.3-0.8,12.8-8.9c-0.4-2.3-1-4.5-1.7-6.7
              c-0.9-2.8-1-3.2,0.9-5c1-0.9,2.6-1.2,4.6-1.6c3.5-0.7,8.4-1.8,11.6-7c2.6-4.3,1.7-5.7,0.5-7.4c-1.6-2-2.2-4.6-1.9-7.1
              c0.2-3.5,2.3-6.7,5.4-8.3c2.6-1.3,5.5-2,8.5-2.1c2.5-0.3,4.9-1.6,6.3-3.7c0.4-0.5,1-0.6,1.6-0.4s0.8,0.8,0.6,1.2
              c-1.9,2.7-5,4.4-8.2,4.6c-2.5,0.1-5,0.7-7.3,1.8c-2.6,1.4-4.3,4.1-4.5,7.1c-0.3,2.2,0.3,4.4,1.6,6.2c1.4,2.2,2.6,3.9-0.5,9
              c-3.7,5.9-9.1,7.1-13.1,7.9c-1.2,0.1-2.4,0.5-3.5,1.1c-1.2,1-1.1,1.4-0.4,3.4c0.7,2.3,1.3,4.5,1.7,6.9C37,508,20.2,509,20.2,509
              c-0.6,0.1-1.1-0.3-1.3-0.8C18.8,507.7,19.3,507.3,19.9,507.2L19.9,507.2z M0,499.7c-0.6,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8
              c0.1,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9
              c3.1-5.1,2-6.8,0.5-9c-1.4-1.8-1.9-4-1.6-6.2c0.2-3,1.8-5.6,4.5-7.1c2.3-1.1,4.7-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7
              c0.3-0.4,0-1-0.5-1.2c-0.6-0.2-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.4,8.3
              c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.7,2.1,3.2-0.5,7.4c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,4.9
              c0.7,2.2,1.2,4.4,1.7,6.7C14.3,498.9,0,499.7,0,499.7z M5,501.6c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8
              c0,0,16.8-1,14.9-10.9c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9
              c3.1-5,2-6.8,0.5-9c-1.4-1.8-2-4-1.7-6.2c0.2-2.9,1.9-5.6,4.4-7c2.3-1.1,4.8-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7
              c0.3-0.4,0-1-0.6-1.2s-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.3,8.3
              c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.5c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5
              c0.7,2.2,1.2,4.4,1.7,6.7C19.3,500.8,5,501.6,5,501.6z M10,503.5c-0.7,0-1.1,0.5-1.1,1c0.2,0.6,0.7,0.9,1.3,0.8
              c0,0,16.8-1,14.9-11c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9
              c3.1-5.1,2-6.8,0.5-9c-1.4-1.7-1.9-4-1.6-6.2c0.2-3,1.8-5.6,4.5-7.1c2.3-1.1,4.8-1.7,7.3-1.8c3.3-0.3,6.3-2,8.2-4.7
              c0.3-0.4,0-1-0.5-1.2c-0.6-0.2-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.1,4.8-5.4,8.3
              c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.7,2.1,3.2-0.5,7.4c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,4.9
              c0.7,2.2,1.2,4.4,1.7,6.7C24.3,502.7,10,503.5,10,503.5L10,503.5z M13.9,506.3c0.2,0.6,0.7,0.9,1.3,0.8c0.1,0,16.8-1,14.9-10.9
              c-0.4-2.3-1-4.6-1.7-6.9c-0.6-2-0.7-2.4,0.4-3.4c1.1-0.6,2.3-1,3.5-1.1c4-0.8,9.5-2,13.1-7.9c3.1-5,2-6.8,0.5-9
              c-1.4-1.7-1.9-4-1.6-6.1c0.2-3,1.8-5.7,4.5-7.1c2.2-1.1,4.7-1.7,7.2-1.8c3.3-0.3,6.3-2,8.2-4.7c0.3-0.4,0-1-0.5-1.2
              c-0.6-0.2-1.2-0.1-1.6,0.4c-1.5,2.1-3.8,3.4-6.3,3.7c-2.9,0.1-5.8,0.9-8.5,2.2c-3.1,1.7-5.2,4.8-5.4,8.3
              c-0.4,2.5,0.3,5.1,1.9,7.1c1.2,1.8,2.1,3.2-0.5,7.5c-3.2,5.2-8.1,6.2-11.6,7c-2,0.4-3.6,0.8-4.6,1.7c-1.9,1.7-1.8,2.2-0.9,5
              c0.7,2.2,1.2,4.4,1.7,6.7c1.5,8.1-12.7,8.9-12.8,8.9C14.3,505.4,13.8,505.8,13.9,506.3L13.9,506.3z"/>
          </g>
        </g>
      </g>
      <g id="letters2">
        <g>
          <path fill="#A0D8F7" d="M296.9,570.8l54.2-93.2l-170.5-99.1l-54.2,93.2L296.9,570.8
            L296.9,570.8z M135.4,469.5l47.7-82.1L342,479.8l-47.7,82.1L135.4,469.5z"/>

          <rect x="191.2" y="382.7" transform="matrix(0.5027 -0.8644 0.8644 0.5027 -291.5625 442.3402)" fill="#CCCCCE" width="94.9" height="183.8"/>
          <g>
            <polygon fill="#0B99D6" points="289.4,566.4 307.7,552.2 298.6,567.8 295.7,570.1
              "/>
            <path fill="#0B99D6" d="M276.9,559.1l6.3,3.6l5.5-4.3l-6.3-3.6L276.9,559.1L276.9,559.1
              z M302.6,547.5l14.2-11.1l9.1-15.7l-14.2,11.1L302.6,547.5z"/>
            <path fill="#0B99D6" d="M264.3,551.8l6.3,3.6l5.5-4.3l-6.3-3.6L264.3,551.8L264.3,551.8
              z M320.9,516.2l14.2-11.1l9.1-15.7L330,500.5L320.9,516.2z"/>
            <path fill="#0B99D6" d="M251.7,544.5l6.3,3.6l5.5-4.3l-6.3-3.6L251.7,544.5L251.7,544.5
              z M342,479.8l-2.9,5l10.4-8.1l-6.3-3.6l-5.5,4.3L342,479.8z"/>
            <path fill="#0B99D6" d="M239.2,537.2l6.3,3.6l5.5-4.3l-6.3-3.6L239.2,537.2L239.2,537.2
              z M331.5,473.6l5.5-4.3l-6.3-3.6l-5.5,4.3L331.5,473.6z"/>
            <path fill="#0B99D6" d="M226.6,529.9l6.3,3.6l5.5-4.3l-6.3-3.6L226.6,529.9L226.6,529.9
              z M318.9,466.3l5.5-4.3l-6.3-3.6l-5.5,4.3L318.9,466.3z"/>
            <path fill="#0B99D6" d="M214,522.6l6.3,3.6l5.5-4.3l-6.3-3.6L214,522.6L214,522.6z
               M306.3,459l5.5-4.3l-6.3-3.6l-5.5,4.3L306.3,459z"/>
            <path fill="#0B99D6" d="M201.5,515.3l6.3,3.6l5.5-4.3L207,511L201.5,515.3L201.5,515.3z
               M293.8,451.7l5.5-4.3l-6.3-3.6l-5.5,4.3L293.8,451.7z"/>
            <path fill="#0B99D6" d="M188.9,508l6.3,3.6l5.5-4.3l-6.3-3.6L188.9,508L188.9,508z
               M281.2,444.4l5.5-4.3l-6.3-3.6l-5.5,4.3L281.2,444.4z"/>
            <path fill="#0B99D6" d="M176.3,500.7l6.3,3.6l5.5-4.3l-6.3-3.6L176.3,500.7L176.3,500.7
              z M268.6,437.1l5.5-4.3l-6.3-3.6l-5.5,4.3L268.6,437.1z"/>
            <path fill="#0B99D6" d="M163.8,493.4l6.3,3.6l5.5-4.3l-6.3-3.6L163.8,493.4L163.8,493.4
              z M256.1,429.8l5.5-4.3l-6.3-3.6l-5.5,4.3L256.1,429.8z"/>
            <path fill="#0B99D6" d="M151.2,486.1l6.3,3.6l5.5-4.3l-6.3-3.6L151.2,486.1L151.2,486.1
              z M243.5,422.5l5.5-4.3l-6.3-3.6l-5.5,4.3L243.5,422.5z"/>
            <path fill="#0B99D6" d="M138.6,478.7l6.3,3.6l5.5-4.3l-6.3-3.6L138.6,478.7L138.6,478.7
              z M230.9,415.2l5.5-4.3l-6.3-3.6l-5.5,4.3L230.9,415.2z"/>
            <path fill="#0B99D6" d="M126.9,470.7l-0.5,0.9l5.9,3.4l5.5-4.3l-2.3-1.4l5.7-9.8
              L126.9,470.7L126.9,470.7z M218.4,407.9l5.5-4.3l-6.3-3.6l-5.5,4.3L218.4,407.9z"/>
            <path fill="#0B99D6" d="M145.2,439.4l-9.1,15.7l14.2-11.1l9.1-15.7L145.2,439.4
              L145.2,439.4z M205.8,400.6l5.5-4.3l-6.3-3.6l-5.5,4.3L205.8,400.6z"/>
            <path fill="#0B99D6" d="M163.4,408l-9.1,15.7l14.2-11.1l9.1-15.7L163.4,408L163.4,408z
               M193.2,393.3l5.5-4.3l-6.3-3.6l-5.5,4.3L193.2,393.3z"/>
            <polygon fill="#0B99D6" points="186.1,381.7 172.6,392.3 180.6,378.5       "/>
          </g>
        </g>
        <g>

          <rect x="566.5" y="410.1" transform="matrix(0.9856 -0.1692 0.1692 0.9856 -68.6448 118.5889)" fill="#96CBCC" width="190" height="103.9"/>
          <path fill="#8BB3B2" d="M559.1,426.9l83.4,40.9c12,5.9,29.1,3.3,38-5.8l65.9-67.3
            L559.1,426.9z"/>
          <g>
            <path fill="#0B99D6" d="M671.3,453.2c-4.2,0.7-7.8,3.1-10.1,6.3
              c-2.3,3.2-3.4,7.4-2.6,11.6c0.7,4.2,3.1,7.8,6.3,10.1c3.2,2.3,7.4,3.4,11.6,2.6c4.2-0.7,7.8-3.1,10.1-6.3
              c2.3-3.2,3.4-7.4,2.6-11.6c-0.7-4.2-3.1-7.8-6.3-10.1C679.7,453.5,675.5,452.5,671.3,453.2L671.3,453.2z M663.3,461
              c1.9-2.7,4.9-4.7,8.5-5.3c3.6-0.6,7,0.3,9.8,2.2c2.7,1.9,4.7,4.9,5.3,8.5s-0.3,7-2.2,9.8c-1.9,2.7-4.9,4.7-8.5,5.3
              c-3.6,0.6-7-0.3-9.8-2.2c-2.7-1.9-4.7-4.9-5.3-8.5C660.5,467.1,661.3,463.7,663.3,461z"/>
            <path fill="#0B99D6" d="M671.1,462.7c0.3,0.3,0.8,0.4,1.2,0.1c0.3-0.3,0.4-0.8,0.1-1.2
              c0,0-7.6-8.8-14.3-2.4c-2.2,2.1-3.5,3.6-4.4,4.6c-1.3,1.4-1.5,1.7-2.7,1.6c-0.6,0-1.3-0.6-2.2-1.3c-2.2-1.8-5.4-4.2-11.3-3
              c-5.1,1-5.9,2.6-6.9,4.5c-0.7,1.2-1.4,2.7-3.9,4.2c-2.9,1.8-5.3,1.9-7.1,1.3c-1.9-0.6-3.3-1.9-4.4-3c-2.5-2.8-6.9-2-6.9-2
              c-0.4,0.1-0.7,0.5-0.7,1c0.1,0.4,0.5,0.7,1,0.7c0,0,3.5-0.6,5.4,1.5c1.2,1.3,2.8,2.8,5.1,3.5s5.1,0.5,8.4-1.5
              c2.9-1.8,3.8-3.4,4.5-4.8c0.8-1.6,1.5-2.9,5.8-3.7c5.2-1,8,1.1,10,2.7c1.1,0.9,2.1,1.6,3.1,1.7c2.1,0.1,2.3-0.2,4.1-2.1
              c0.9-1,2.1-2.4,4.3-4.5C664.6,455.3,671.1,462.7,671.1,462.7L671.1,462.7z M673.7,477.8c0,0-6.5-7.5-11.9-2.3
              c-2.2,2.1-3.5,3.5-4.3,4.5c-1.7,2-2,2.3-4.1,2.1c-1.1-0.1-2-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7
              c-0.8,1.4-1.6,3.1-4.5,4.8c-3.4,2-6.2,2.2-8.4,1.5s-3.9-2.2-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7
              c-0.1-0.4,0.2-0.9,0.7-1c0,0,4.4-0.7,6.9,2c1,1.1,2.5,2.5,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c2.5-1.5,3.3-3,3.9-4.2
              c1-2,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3c0.9,0.7,1.7,1.3,2.2,1.3c1.2,0.1,1.5-0.2,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6
              c6.7-6.4,14.3,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2C674.5,478.1,674,478.1,673.7,477.8L673.7,477.8z M673.1,474
              c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5c-1.7,2-2,2.3-4.1,2.1c-1.1-0.1-2-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7
              c-4.3,0.8-5,2.1-5.8,3.7c-0.8,1.4-1.6,3.1-4.5,4.8c-3.4,2-6.2,2.2-8.4,1.5s-3.9-2.2-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5
              c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.7-1c0,0,4.4-0.7,6.9,2c1,1.1,2.5,2.5,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3
              c2.5-1.5,3.3-3,3.9-4.2c1-2,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3c0.9,0.7,1.7,1.3,2.2,1.3c1.2,0.1,1.5-0.2,2.7-1.6
              c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.3,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2C673.9,474.4,673.3,474.3,673.1,474L673.1,474z
               M672.4,470.2c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5c-1.7,2-2,2.3-4.1,2.1c-1.1-0.1-2-0.8-3.1-1.7
              c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-0.8,1.4-1.6,3.1-4.5,4.8c-3.4,2-6.2,2.2-8.4,1.5s-3.9-2.2-5.1-3.5
              c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.7-1c0,0,4.4-0.7,6.9,2c1,1.1,2.5,2.5,4.4,3
              c1.9,0.6,4.2,0.4,7.1-1.3c2.5-1.5,3.3-3,3.9-4.2c1-2,1.9-3.5,6.9-4.5c5.9-1.2,9,1.3,11.3,3c0.9,0.7,1.7,1.3,2.2,1.3
              c1.2,0.1,1.5-0.2,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.3,2.4,14.3,2.4c0.3,0.3,0.3,0.9-0.1,1.2
              C673.2,470.6,672.7,470.6,672.4,470.2L672.4,470.2z M671.8,466.5c0,0-6.5-7.5-11.9-2.3c-2.2,2.1-3.5,3.5-4.3,4.5
              c-1.7,2-2,2.3-4.1,2.1c-1.1-0.1-2-0.8-3.1-1.7c-2-1.6-4.8-3.7-10-2.7c-4.3,0.8-5,2.1-5.8,3.7c-0.8,1.4-1.6,3.1-4.5,4.8
              c-3.4,2-6.2,2.2-8.4,1.5s-3.9-2.2-5.1-3.5c-1.9-2.1-5.4-1.5-5.4-1.5c-0.4,0.1-0.9-0.2-1-0.7c-0.1-0.4,0.2-0.9,0.7-1
              c0,0,4.4-0.7,6.9,2c1,1.1,2.5,2.5,4.4,3c1.9,0.6,4.2,0.4,7.1-1.3c2.5-1.5,3.3-3,3.9-4.2c1-2,1.9-3.5,6.9-4.5
              c5.9-1.2,9,1.3,11.3,3c0.9,0.7,1.7,1.3,2.2,1.3c1.2,0.1,1.5-0.2,2.7-1.6c0.9-1,2.2-2.5,4.4-4.6c6.7-6.4,14.3,2.4,14.3,2.4
              c0.3,0.3,0.3,0.9-0.1,1.2C672.6,466.9,672.1,466.8,671.8,466.5z"/>
          </g>
        </g>
        <g>

          <rect x="605.6" y="419.2" transform="matrix(0.9726 -0.2324 0.2324 0.9726 -90.5768 176.3737)" fill="#A3DAF7" width="195.3" height="106.8"/>
          <path fill="#80CAF0" d="M785.8,398l-62.3,72.4c-9,10.4-26.2,14.9-38.2,9.9l-89.4-36.9
            L785.8,398z"/>
          <g>
            <path fill="#0B99D6" d="M690.5,468.2c4.3-1,8.6-0.2,12.1,1.9s6.1,5.6,7.2,9.9
              c1,4.3,0.2,8.6-1.9,12.1c-2.1,3.5-5.6,6.1-9.9,7.2c-4.3,1-8.6,0.2-12.1-1.9s-6.1-5.6-7.2-9.9c-1-4.3-0.2-8.6,1.9-12.1
              C682.7,471.9,686.2,469.3,690.5,468.2L690.5,468.2z M701.2,472.3c-2.9-1.8-6.5-2.5-10.1-1.6s-6.5,3.1-8.3,6s-2.5,6.5-1.6,10.1
              s3.1,6.5,6,8.3s6.5,2.5,10.1,1.6s6.5-3.1,8.3-6s2.5-6.5,1.6-10.1C706.4,477,704.1,474.1,701.2,472.3z"/>
            <path fill="#0B99D6" d="M694.5,477.2c-0.1,0.4-0.6,0.7-1.1,0.5
              c-0.4-0.1-0.7-0.6-0.5-1.1c0,0,3.6-11.4,12.5-8c3,1.1,4.8,2,6,2.6c1.8,0.8,2.1,1,3.2,0.4c0.5-0.3,1-1.1,1.6-2.2
              c1.4-2.6,3.4-6.1,9.4-7.4c5.2-1.1,6.6,0.1,8.4,1.5c1.1,0.9,2.4,2,5.4,2.4c3.5,0.5,5.7-0.3,7.3-1.6c1.5-1.3,2.4-3.1,2.9-4.6
              c1.2-3.6,5.7-4.7,5.7-4.7c0.5-0.1,0.9,0.2,1,0.6c0.1,0.5-0.2,0.9-0.6,1c0,0-3.5,0.8-4.5,3.6c-0.6,1.7-1.6,3.8-3.4,5.4
              c-1.9,1.6-4.6,2.6-8.6,2c-3.5-0.5-4.9-1.7-6.2-2.7c-1.4-1.2-2.6-2.1-7-1.1c-5.3,1.1-7.1,4.3-8.3,6.6c-0.7,1.3-1.3,2.3-2.3,2.8
              c-1.9,1-2.3,0.8-4.7-0.4c-1.2-0.6-3-1.4-5.9-2.5C697.6,467.5,694.5,477.1,694.5,477.2L694.5,477.2z M698.1,492.4
              c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5c2.4,1.1,2.8,1.3,4.7,0.4c1-0.5,1.6-1.6,2.3-2.8c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.3,1.1,2.8,2.3,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.8-1.6,2.8-3.7,3.4-5.4c0.9-2.8,4.4-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.5-1.3,3.3-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-3-0.4-4.3-1.5-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.4,7.4c-0.6,1-1,1.9-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.4,0.1,0.9,0.5,1.1C697.5,493.1,698,492.9,698.1,492.4L698.1,492.4z
               M697.2,488.6c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5c2.4,1.1,2.8,1.3,4.7,0.4c1-0.5,1.6-1.6,2.3-2.8c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.3,1.1,2.8,2.3,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.8-1.6,2.8-3.7,3.4-5.4c0.9-2.8,4.4-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.5-1.3,3.3-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-3-0.4-4.3-1.5-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.4,7.4c-0.6,1-1,1.9-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.4,0.1,0.9,0.5,1.1C696.6,489.3,697.1,489,697.2,488.6L697.2,488.6z
               M696.3,484.8c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5c2.4,1.1,2.8,1.3,4.7,0.4c1-0.5,1.6-1.6,2.3-2.8c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.3,1.1,2.8,2.3,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.8-1.6,2.8-3.7,3.4-5.4c0.9-2.8,4.4-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.5-1.3,3.3-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-3-0.4-4.3-1.5-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.4,7.4c-0.6,1-1,1.9-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.4,0.1,0.9,0.5,1.1C695.7,485.5,696.2,485.2,696.3,484.8L696.3,484.8z
               M695.4,481c0,0,3.1-9.7,10.3-7c2.9,1.1,4.7,1.9,5.9,2.5c2.4,1.1,2.8,1.3,4.7,0.4c1-0.5,1.6-1.6,2.3-2.8c1.3-2.3,3-5.4,8.3-6.6
              c4.4-0.9,5.5,0,7,1.1c1.3,1.1,2.8,2.3,6.2,2.7c4,0.6,6.7-0.4,8.6-2c1.8-1.6,2.8-3.7,3.4-5.4c0.9-2.8,4.4-3.6,4.5-3.6
              c0.5-0.1,0.7-0.6,0.6-1c-0.1-0.5-0.6-0.7-1-0.6c0,0-4.4,1.1-5.7,4.7c-0.5,1.5-1.3,3.3-2.9,4.6c-1.5,1.3-3.8,2.1-7.3,1.6
              c-3-0.4-4.3-1.5-5.4-2.4c-1.8-1.4-3.2-2.6-8.4-1.5c-6.1,1.3-8,4.8-9.4,7.4c-0.6,1-1,1.9-1.6,2.2c-1.1,0.6-1.4,0.4-3.2-0.4
              c-1.2-0.6-3.1-1.5-6-2.6c-8.9-3.3-12.5,8-12.5,8c-0.1,0.4,0.1,0.9,0.5,1.1C694.8,481.7,695.3,481.4,695.4,481z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M713.7,506.9l-3.4-101.1l185-6.3l3.4,101.1L713.7,506.9
                L713.7,506.9z M892.2,494.9l-3-89.1l-172.5,5.9l3,89.1L892.2,494.9z"/>

              <rect x="718.2" y="408.7" transform="matrix(0.9994 -3.390259e-02 3.390259e-02 0.9994 -14.9035 27.5345)" fill="#FFFFFF" width="172.6" height="89.1"/>
              <g>
                <polygon fill="#0B99D6" points="721.8,506.7 713,486.7 713.6,503.7 715,506.9
                  "/>
                <path fill="#0B99D6" d="M735.4,506.2l-6.8,0.2l-2.6-5.9l6.8-0.2L735.4,506.2
                  L735.4,506.2z M719.2,485.2l-6.8-15.5l-0.6-17l6.8,15.5L719.2,485.2z"/>
                <path fill="#0B99D6" d="M749,505.7l-6.8,0.2l-2.6-5.9l6.8-0.2L749,505.7L749,505.7z
                   M718.1,451.1l-6.8-15.5l-0.6-17l6.8,15.5L718.1,451.1z"/>
                <path fill="#0B99D6" d="M762.7,505.3l-6.8,0.2l-2.6-5.9l6.8-0.2L762.7,505.3
                  L762.7,505.3z M716.7,411.6l0.2,5.4l-5-11.3l6.8-0.2l2.6,5.9L716.7,411.6z"/>
                <path fill="#0B99D6" d="M776.3,504.8l-6.8,0.2l-2.6-5.9l6.8-0.2L776.3,504.8
                  L776.3,504.8z M728.2,411.2l-2.6-5.9l6.8-0.2l2.6,5.9L728.2,411.2z"/>
                <path fill="#0B99D6" d="M790,504.4l-6.8,0.2l-2.6-5.9l6.8-0.2L790,504.4L790,504.4z
                   M741.8,410.8l-2.6-5.9l6.8-0.2l2.6,5.9L741.8,410.8z"/>
                <path fill="#0B99D6" d="M803.6,503.9l-6.8,0.2l-2.6-5.9l6.8-0.2L803.6,503.9
                  L803.6,503.9z M755.5,410.3l-2.6-5.9l6.8-0.2l2.6,5.9L755.5,410.3z"/>
                <path fill="#0B99D6" d="M817.3,503.4l-6.8,0.2l-2.6-5.9l6.8-0.2L817.3,503.4
                  L817.3,503.4z M769.1,409.8l-2.6-5.9l6.8-0.2l2.6,5.9L769.1,409.8z"/>
                <path fill="#0B99D6" d="M830.9,503l-6.8,0.2l-2.6-5.9l6.8-0.2L830.9,503L830.9,503z
                   M782.8,409.4l-2.6-5.9l6.8-0.2l2.6,5.9L782.8,409.4z"/>
                <path fill="#0B99D6" d="M844.5,502.5l-6.8,0.2l-2.6-5.9l6.8-0.2L844.5,502.5
                  L844.5,502.5z M796.4,408.9l-2.6-5.9l6.8-0.2l2.6,5.9L796.4,408.9z"/>
                <path fill="#0B99D6" d="M858.2,502l-6.8,0.2l-2.6-5.9l6.8-0.2L858.2,502L858.2,502z
                   M810,408.4l-2.6-5.9l6.8-0.2l2.6,5.9L810,408.4z"/>
                <path fill="#0B99D6" d="M871.8,501.6l-6.8,0.2l-2.6-5.9l6.8-0.2L871.8,501.6
                  L871.8,501.6z M823.7,408l-2.6-5.9l6.8-0.2l2.6,5.9L823.7,408z"/>
                <path fill="#0B99D6" d="M885.5,501.1l-6.8,0.2l-2.6-5.9l6.8-0.2L885.5,501.1
                  L885.5,501.1z M837.3,407.5l-2.6-5.9l6.8-0.2l2.6,5.9L837.3,407.5z"/>
                <path fill="#0B99D6" d="M898.7,499.7v1l-6.4,0.2l-2.6-5.9l2.5-0.1l-0.4-10.6
                  L898.7,499.7L898.7,499.7z M851,407.1l-2.6-5.9l6.8-0.2l2.6,5.9L851,407.1z"/>
                <path fill="#0B99D6" d="M897.5,465.6l0.6,17l-6.8-15.5l-0.6-17L897.5,465.6
                  L897.5,465.6z M864.6,406.6l-2.6-5.9l6.8-0.2l2.6,5.9L864.6,406.6z"/>
                <path fill="#0B99D6" d="M896.4,431.6l0.6,17l-6.8-15.5l-0.6-17L896.4,431.6
                  L896.4,431.6z M878.2,406.1l-2.6-5.9l6.8-0.2l2.6,5.9L878.2,406.1z"/>
                <polygon fill="#0B99D6" points="889.3,399.7 895.8,414.5 895.3,399.5           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M834.6,482.3c-3.2,0.1-6.2-1.1-8.4-3.1s-3.6-4.9-3.7-8.1
                c-0.1-3.2,1.1-6.2,3.1-8.4c2-2.2,4.9-3.6,8.1-3.7c3.2-0.1,6.2,1.1,8.4,3.1s3.6,4.9,3.7,8.1c0.1,3.2-1.1,6.2-3.1,8.4
                C840.7,480.8,837.8,482.2,834.6,482.3L834.6,482.3z M844,470.4c-0.1-2.7-1.3-5.1-3.1-6.8c-1.8-1.7-4.3-2.7-7-2.6
                s-5.1,1.3-6.8,3.1c-1.7,1.8-2.7,4.3-2.6,7c0.1,2.7,1.3,5.1,3.1,6.8c1.8,1.7,4.3,2.7,7,2.6s5.1-1.3,6.8-3.1
                C843.1,475.6,844.1,473.1,844,470.4z"/>
              <path fill="#80CAF0" d="M835.7,475.3c0,0,4.1,6.2,8.6,2.9c1.9-1.3,2.9-2.3,3.7-2.9
                c1.5-1.3,1.7-1.5,3.3-1.2c0.8,0.2,1.4,0.8,2.2,1.6c1.3,1.4,3.2,3.2,7.1,3c3.3-0.2,3.9-1.1,4.7-2.2c0.7-1,1.5-2.1,3.9-3.1
                c2.7-1.2,4.8-1,6.4-0.3c1.6,0.7,2.7,2,3.4,3.1c1.2,1.8,3.8,1.7,3.8,1.7c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6
                c0,0-3.3,0.1-4.9-2.2c-0.7-1-1.6-2.1-2.9-2.7c-1.3-0.6-3.1-0.7-5.4,0.3c-2,0.9-2.7,1.9-3.4,2.7c-1,1.3-1.8,2.4-5.6,2.7
                c-4.5,0.3-6.6-1.9-8.1-3.4c-0.6-0.6-1.1-1.1-1.5-1.2c-0.9-0.2-1.1,0-2.2,0.9c-0.8,0.7-1.9,1.6-3.8,3c-5.6,4.1-10.4-3.2-10.4-3.2
                c-0.2-0.3-0.1-0.7,0.2-0.9C835.2,474.9,835.5,475,835.7,475.3L835.7,475.3z M835.3,463.8c-0.2-0.3-0.6-0.4-0.9-0.2
                c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.4,0.1,0.9,0.6,1.5,1.2
                c1.5,1.5,3.6,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.6-0.9,1.3-1.8,3.4-2.7c2.3-1,4.1-0.9,5.4-0.3c1.3,0.6,2.3,1.7,2.9,2.7
                c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0-2.6,0.1-3.8-1.7c-0.7-1.1-1.8-2.4-3.4-3.1
                c-1.6-0.7-3.7-0.9-6.4,0.3c-2.3,1-3.2,2.1-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-4,0.2-5.8-1.7-7.1-3c-0.7-0.8-1.4-1.4-2.2-1.6
                c-1.5-0.3-1.8-0.1-3.3,1.2c-0.7,0.6-1.8,1.6-3.7,2.9C839.4,470,835.4,463.8,835.3,463.8L835.3,463.8z M835.4,466.7
                c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9
                c0.4,0.1,0.9,0.6,1.5,1.2c1.5,1.5,3.6,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.6-0.9,1.3-1.8,3.4-2.7c2.3-1,4.1-0.9,5.4-0.3
                c1.3,0.6,2.3,1.7,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c0,0-2.6,0.1-3.8-1.7
                c-0.7-1.1-1.8-2.4-3.4-3.1c-1.6-0.7-3.7-0.9-6.4,0.3c-2.3,1-3.2,2.1-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2c-4,0.2-5.8-1.7-7.1-3
                c-0.8-0.8-1.4-1.4-2.2-1.6c-1.5-0.3-1.8-0.1-3.3,1.2c-0.7,0.6-1.8,1.6-3.7,2.9C839.5,472.9,835.4,466.7,835.4,466.7L835.4,466.7
                z M835.5,469.5c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2c1.9-1.4,3-2.3,3.8-3
                c1.1-0.9,1.3-1.1,2.2-0.9c0.4,0.1,0.9,0.6,1.5,1.2c1.5,1.5,3.6,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7c0.6-0.9,1.3-1.8,3.4-2.7
                c2.3-1,4.1-0.9,5.4-0.3c1.3,0.6,2.3,1.7,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6
                c0,0-2.6,0.1-3.8-1.7c-0.7-1.1-1.8-2.4-3.4-3.1c-1.6-0.7-3.7-0.9-6.4,0.3c-2.3,1-3.2,2.1-3.9,3.1c-0.8,1.1-1.4,2-4.7,2.2
                c-4,0.2-5.8-1.7-7.1-3c-0.7-0.8-1.4-1.4-2.2-1.6c-1.5-0.3-1.8-0.1-3.3,1.2c-0.7,0.6-1.8,1.6-3.7,2.9
                C839.6,475.8,835.5,469.6,835.5,469.5L835.5,469.5z M834.8,472.2c-0.3,0.2-0.4,0.6-0.2,0.9c0,0,4.8,7.3,10.4,3.2
                c1.9-1.4,3-2.3,3.8-3c1.1-0.9,1.3-1.1,2.2-0.9c0.4,0.1,0.9,0.6,1.5,1.2c1.5,1.5,3.6,3.7,8.1,3.4c3.9-0.2,4.7-1.3,5.6-2.7
                c0.6-0.9,1.3-1.8,3.4-2.7c2.3-1,4.1-0.9,5.4-0.3c1.3,0.6,2.3,1.7,2.9,2.7c1.6,2.3,4.9,2.2,4.9,2.2c0.3,0,0.6-0.3,0.6-0.6
                c0-0.3-0.3-0.6-0.6-0.6c0,0-2.6,0.1-3.8-1.7c-0.7-1.1-1.8-2.4-3.4-3.1c-1.6-0.7-3.7-0.9-6.4,0.3c-2.3,1-3.2,2.1-3.9,3.1
                c-0.8,1.1-1.4,2-4.7,2.2c-4,0.2-5.8-1.7-7.1-3c-0.7-0.8-1.4-1.4-2.2-1.6c-1.5-0.3-1.8-0.1-3.3,1.2c-0.7,0.6-1.8,1.6-3.7,2.9
                c-4.6,3.3-8.6-2.9-8.6-2.9C835.4,472.1,835.1,472,834.8,472.2z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M756,447.2l0.5-2.1l0,0c-0.8-0.2-1.2-0.9-1.1-1.7
              c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.3-1.4l0,0c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.3-1.2l0,0
              c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.3-1.2l0,0c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.3-1.2
              l0,0c-0.8-0.2-1.2-1-1.1-1.7c0.2-0.8,0.9-1.2,1.7-1.1l0,0l0.5-2.1l-1.7-0.4c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-1-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.4-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7
              l-1.2-0.3c-0.2,0.8-0.9,1.2-1.7,1.1c-0.8-0.2-1.2-0.9-1.1-1.7l-1.7-0.4l-0.5,2.1l0,0c0.8,0.2,1.2,0.9,1.1,1.7
              c-0.2,0.8-1,1.2-1.7,1.1l0,0l-0.3,1.2l0,0c0.8,0.2,1.2,1,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.3,1.2l0,0
              c0.8,0.2,1.2,0.9,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.3,1.2l0,0c0.8,0.2,1.2,1,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0
              l-0.3,1.4l0,0c0.8,0.2,1.2,1,1.1,1.7c-0.2,0.8-0.9,1.2-1.7,1.1l0,0l-0.5,2.1l1.7,0.4c0.2-0.8,0.9-1.2,1.7-1.1
              c0.8,0.2,1.2,1,1.1,1.7l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1
              c0.8,0.2,1.2,0.9,1.1,1.7l1.2,0.3c0.2-0.8,0.9-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7l1.4,0.3c0.2-0.8,0.9-1.2,1.7-1.1
              c0.8,0.2,1.2,0.9,1.1,1.7l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,1,1.1,1.7l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1
              c0.8,0.2,1.2,0.9,1.1,1.7l1.2,0.3c0.2-0.8,1-1.2,1.7-1.1c0.8,0.2,1.2,0.9,1.1,1.7L756,447.2z"/>

            <rect x="732.6" y="417.2" transform="matrix(0.2278 -0.9737 0.9737 0.2278 152.3337 1055.3856)" fill="#A0D8F7" width="18" height="28.9"/>
          </g>
        </g>
        <g>

          <rect x="19" y="433.3" transform="matrix(0.5255 -0.8508 0.8508 0.5255 -422.1874 321.1124)" fill="#96CBCC" width="115.6" height="211.5"/>
          <path fill="#8BB3B2" d="M17.2,434.3l39.5,95.6c5.7,13.8,21.9,24.2,36,23.1l104.5-7.6
            L17.2,434.3z"/>
          <g>
            <path fill="#0B99D6" d="M91.4,538.9c4.1,2.5,6.7,6.4,7.7,10.7s0.4,9-2.1,13.1
              s-6.4,6.7-10.7,7.7c-4.3,1-9,0.4-13.1-2.1s-6.7-6.4-7.7-10.7s-0.4-9,2.1-13.1s6.4-6.7,10.7-7.7C82.6,535.7,87.3,536.4,91.4,538.9
              L91.4,538.9z M70,546c-2.1,3.4-2.6,7.4-1.8,11c0.9,3.6,3.1,6.9,6.5,9s7.4,2.6,11,1.8c3.6-0.9,6.9-3.1,9-6.5s2.6-7.4,1.8-11
              c-0.9-3.6-3.1-6.9-6.5-9s-7.4-2.6-11-1.8S72.1,542.5,70,546z"/>
            <path fill="#0B99D6" d="M84.2,546.7c0,0,0.1-11-8.3-10.7c-3.4,0.1-5.5,0.4-6.9,0.6
              c-2.9,0.4-3.4,0.4-5-1.2c-0.8-0.9-1.1-2.1-1.4-3.7c-0.5-2.8-1.3-6.6-6.3-9.6c-4.2-2.5-5.7-1.9-7.5-1.2c-1.7,0.6-3.6,1.4-7.3,0.7
              c-4.3-0.8-6.8-2.7-8.1-5c-1.4-2.2-1.6-4.7-1.7-6.7c0-3.2-3.4-5.2-3.4-5.2c-0.4-0.3-0.6-0.8-0.3-1.3c0.3-0.4,0.8-0.6,1.3-0.3
              c0,0,4.2,2.6,4.2,6.8c0,1.7,0.3,3.9,1.4,5.7c1.1,1.8,3.2,3.5,6.9,4.1c3.2,0.6,4.9-0.1,6.4-0.6c2.3-0.9,4.2-1.6,9.1,1.3
              c5.8,3.4,6.6,7.7,7.2,10.8c0.2,1.3,0.4,2.3,0.9,2.8c1,1,1.3,0.9,3.4,0.7c1.5-0.2,3.7-0.5,7.1-0.6c10.3-0.4,10.2,12.5,10.2,12.5
              c0,0.5-0.4,0.9-0.9,0.9C84.6,547.6,84.2,547.2,84.2,546.7L84.2,546.7z M75.3,561.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
              c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.5-0.7-1.5-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-1.5,0.6-3.2,1.2-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1.1-1.9-1.4-4-1.4-5.7c0-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.1-1.3,0.3c-0.3,0.4-0.1,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c0,2,0.3,4.4,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5
              c3.7,0.7,5.7-0.1,7.3-0.7c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2
              c1.4-0.2,3.5-0.4,6.9-0.6C75.4,550.1,75.3,561.1,75.3,561.1L75.3,561.1z M77.5,557.5c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
              c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.5-0.7-1.5-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-1.5,0.6-3.2,1.2-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1.1-1.9-1.4-4-1.4-5.7c0-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.1-1.3,0.3c-0.3,0.4-0.1,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c0,2,0.3,4.4,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5
              c3.7,0.7,5.7-0.1,7.3-0.7c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2
              c1.4-0.2,3.5-0.4,6.9-0.6C77.6,546.5,77.5,557.5,77.5,557.5L77.5,557.5z M79.8,553.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
              c0,0,0.1-12.9-10.2-12.5c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.5-0.7-1.5-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-1.5,0.6-3.2,1.2-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1.1-1.9-1.4-4-1.4-5.7c0-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.1-1.3,0.3s-0.1,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c0,2,0.3,4.4,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5c3.7,0.7,5.7-0.1,7.3-0.7
              c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6
              C79.9,542.9,79.8,553.9,79.8,553.9L79.8,553.9z M82.9,551.2c0.5,0,0.9-0.4,0.9-0.9c0,0,0.1-12.9-10.2-12.5
              c-3.4,0.1-5.6,0.4-7.1,0.6c-2.1,0.3-2.5,0.3-3.4-0.7c-0.5-0.5-0.7-1.5-0.9-2.8c-0.6-3.1-1.4-7.4-7.2-10.8
              c-4.9-2.9-6.8-2.2-9.1-1.3c-1.5,0.6-3.2,1.2-6.4,0.6c-3.7-0.7-5.8-2.3-6.9-4.1c-1.1-1.9-1.4-4-1.4-5.7c0-4.2-4.2-6.7-4.2-6.8
              c-0.4-0.3-1-0.1-1.3,0.3s-0.1,1,0.3,1.3c0,0,3.3,2.1,3.4,5.2c0,2,0.3,4.4,1.7,6.7c1.4,2.2,3.8,4.2,8.1,5c3.7,0.7,5.7-0.1,7.3-0.7
              c1.9-0.7,3.4-1.3,7.5,1.2c5.1,3,5.8,6.8,6.3,9.6c0.3,1.6,0.5,2.8,1.4,3.7c1.6,1.6,2.1,1.6,5,1.2c1.4-0.2,3.5-0.4,6.9-0.6
              c8.3-0.3,8.3,10.6,8.3,10.7C82,550.8,82.4,551.2,82.9,551.2z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M273,600.8l-93.9,37.7l-69-171.8L204,429L273,600.8L273,600.8z
               M200.8,437L118,470.3l64.3,160.1l82.7-33.2L200.8,437z"/>

            <rect x="147" y="447.4" transform="matrix(0.9279 -0.3728 0.3728 0.9279 -185.1591 109.8804)" fill="#D1CCE6" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#0B99D6" points="270,593.3 254.3,608.3 270.1,602 272.6,599.6         "/>
              <path fill="#0B99D6" d="M264.9,580.6l2.5,6.3l-4.7,4.5l-2.5-6.3L264.9,580.6
                L264.9,580.6z M250.7,603l-12.2,11.7l-15.8,6.3l12.2-11.7L250.7,603z"/>
              <path fill="#0B99D6" d="M259.8,567.9l2.5,6.3l-4.7,4.5l-2.5-6.3L259.8,567.9
                L259.8,567.9z M219,615.7l-12.2,11.7l-15.8,6.3l12.2-11.7L219,615.7z"/>
              <path fill="#0B99D6" d="M254.7,555.3l2.5,6.3l-4.7,4.5l-2.5-6.3L254.7,555.3
                L254.7,555.3z M182.4,630.4l5-2l-8.9,8.5l-2.5-6.3l4.7-4.5L182.4,630.4z"/>
              <path fill="#0B99D6" d="M249.7,542.6l2.5,6.3l-4.7,4.5l-2.5-6.3L249.7,542.6
                L249.7,542.6z M178.1,619.8l-4.7,4.5l-2.5-6.3l4.7-4.5L178.1,619.8z"/>
              <path fill="#0B99D6" d="M244.6,529.9l2.5,6.3l-4.7,4.5l-2.5-6.3L244.6,529.9
                L244.6,529.9z M173,607.1l-4.7,4.5l-2.5-6.3l4.7-4.5L173,607.1z"/>
              <path fill="#0B99D6" d="M239.5,517.3l2.5,6.3l-4.7,4.5l-2.5-6.3L239.5,517.3
                L239.5,517.3z M167.9,594.4l-4.7,4.5l-2.5-6.3l4.7-4.5L167.9,594.4z"/>
              <path fill="#0B99D6" d="M234.4,504.6l2.5,6.3l-4.7,4.5l-2.5-6.3L234.4,504.6
                L234.4,504.6z M162.8,581.8l-4.7,4.5l-2.5-6.3l4.7-4.5L162.8,581.8z"/>
              <path fill="#0B99D6" d="M229.3,491.9l2.5,6.3l-4.7,4.5l-2.5-6.3L229.3,491.9
                L229.3,491.9z M157.8,569.1l-4.7,4.5l-2.5-6.3l4.7-4.5L157.8,569.1z"/>
              <path fill="#0B99D6" d="M224.2,479.3l2.5,6.3l-4.7,4.5l-2.5-6.3L224.2,479.3
                L224.2,479.3z M152.7,556.4l-4.7,4.5l-2.5-6.3l4.7-4.5L152.7,556.4z"/>
              <path fill="#0B99D6" d="M219.1,466.6l2.5,6.3l-4.7,4.5l-2.5-6.3L219.1,466.6
                L219.1,466.6z M147.6,543.8l-4.7,4.5l-2.5-6.3l4.7-4.5L147.6,543.8z"/>
              <path fill="#0B99D6" d="M214,453.9l2.5,6.3l-4.7,4.5l-2.5-6.3L214,453.9L214,453.9z
                 M142.5,531.1l-4.7,4.5l-2.5-6.3l4.7-4.5L142.5,531.1z"/>
              <path fill="#0B99D6" d="M208.9,441.3l2.5,6.3l-4.7,4.5l-2.5-6.3L208.9,441.3
                L208.9,441.3z M137.4,518.4l-4.7,4.5l-2.5-6.3l4.7-4.5L137.4,518.4z"/>
              <path fill="#0B99D6" d="M203.1,429.3l0.9-0.4l2.4,6l-4.7,4.5l-0.9-2.4l-9.9,4
                L203.1,429.3L203.1,429.3z M132.3,505.8l-4.7,4.5l-2.5-6.3l4.7-4.5L132.3,505.8z"/>
              <path fill="#0B99D6" d="M171.5,442l15.8-6.3L175,447.4l-15.8,6.3L171.5,442L171.5,442z
                 M127.2,493.1l-4.7,4.5l-2.5-6.3l4.7-4.5L127.2,493.1z"/>
              <path fill="#0B99D6" d="M139.9,454.7l15.8-6.3l-12.2,11.7l-15.8,6.3L139.9,454.7
                L139.9,454.7z M122.1,480.4l-4.7,4.5l-2.5-6.3l4.7-4.5L122.1,480.4z"/>
              <polygon fill="#0B99D6" points="112.4,472.3 124,461.1 110.1,466.7         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M208.7,495.5c1.2,3,1.1,6.2-0.1,8.9c-1.2,2.7-3.4,5-6.4,6.2
              s-6.2,1.1-8.9-0.1s-5-3.4-6.2-6.4s-1.1-6.2,0.1-8.9c1.2-2.7,3.4-5,6.4-6.2s6.2-1.1,8.9,0.1C205.2,490.3,207.5,492.5,208.7,495.5
              L208.7,495.5z M194.2,490.7c-2.5,1-4.4,2.9-5.4,5.3c-1,2.3-1.1,5-0.1,7.5s2.9,4.4,5.3,5.4c2.3,1,5,1.1,7.5,0.1s4.4-2.9,5.4-5.3
              c1-2.3,1.1-5,0.1-7.5s-2.9-4.4-5.3-5.4C199.4,489.8,196.7,489.7,194.2,490.7z"/>
            <path fill="#80CAF0" d="M201.6,496.8c0,0,4.5-5.9-0.2-9.1c-1.9-1.3-3.2-2-4-2.5
              c-1.7-1-2-1.1-2.2-2.7c-0.1-0.8,0.3-1.6,0.7-2.6c0.8-1.7,2-4.1,0.4-7.7c-1.3-3-2.3-3.3-3.6-3.7c-1.2-0.3-2.5-0.7-4.3-2.6
              c-2-2.2-2.6-4.2-2.4-6c0.2-1.8,1-3.2,1.8-4.3c1.2-1.7,0.3-4.2,0.3-4.2c-0.1-0.3,0-0.7,0.3-0.8c0.3-0.1,0.7,0,0.8,0.3
              c0,0,1.2,3.1-0.4,5.4c-0.7,0.9-1.4,2.2-1.5,3.7s0.4,3.2,2.1,5c1.5,1.6,2.7,1.9,3.7,2.2c1.6,0.4,2.9,0.8,4.4,4.4
              c1.8,4.2,0.5,6.8-0.4,8.8c-0.4,0.8-0.7,1.4-0.6,1.9c0.1,0.9,0.3,1,1.6,1.8c0.9,0.5,2.2,1.2,4.1,2.5c5.7,3.9,0.5,10.8,0.5,10.9
              c-0.2,0.3-0.6,0.3-0.9,0.1C201.5,497.5,201.4,497.1,201.6,496.8L201.6,496.8z M191,501.1c-0.2,0.3-0.1,0.7,0.1,0.9
              c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c-0.1-0.4,0.2-1.1,0.6-1.9
              c0.9-1.9,2.2-4.6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1-0.3-2.2-0.6-3.7-2.2c-1.7-1.9-2.2-3.6-2.1-5c0.1-1.5,0.9-2.7,1.5-3.7
              c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3s-0.5,0.5-0.3,0.8c0,0,1,2.5-0.3,4.2c-0.8,1.1-1.6,2.5-1.8,4.3s0.4,3.8,2.4,6
              c1.8,1.9,3.1,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.6,3.7,0.4,6-0.4,7.7c-0.5,1-0.8,1.8-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7
              c0.8,0.5,2.1,1.2,4,2.5C195.4,495.2,191,501.1,191,501.1L191,501.1z M193.6,500c-0.2,0.3-0.1,0.7,0.1,0.9
              c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c-0.1-0.4,0.2-1.1,0.6-1.9
              c0.9-1.9,2.2-4.6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1-0.3-2.2-0.6-3.7-2.2c-1.7-1.9-2.2-3.6-2.1-5c0.1-1.5,0.9-2.7,1.5-3.7
              c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3c-0.3,0.1-0.5,0.5-0.3,0.8c0,0,1,2.5-0.3,4.2c-0.8,1.1-1.6,2.5-1.8,4.3
              s0.4,3.8,2.4,6c1.8,1.9,3.1,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.6,3.7,0.4,6-0.4,7.7c-0.5,1-0.8,1.8-0.7,2.6
              c0.2,1.5,0.5,1.7,2.2,2.7c0.8,0.5,2.1,1.2,4,2.5C198.1,494.1,193.7,500,193.6,500L193.6,500z M196.3,499
              c-0.2,0.3-0.1,0.7,0.1,0.9c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8
              c-0.1-0.4,0.2-1.1,0.6-1.9c0.9-1.9,2.2-4.6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1-0.3-2.2-0.6-3.7-2.2c-1.7-1.9-2.2-3.6-2.1-5
              c0.1-1.5,0.9-2.7,1.5-3.7c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3c-0.3,0.1-0.5,0.5-0.3,0.8c0,0,1,2.5-0.3,4.2
              c-0.8,1.1-1.6,2.5-1.8,4.3s0.4,3.8,2.4,6c1.8,1.9,3.1,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.6,3.7,0.4,6-0.4,7.7
              c-0.5,1-0.8,1.8-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7c0.8,0.5,2.1,1.2,4,2.5C200.8,493,196.3,499,196.3,499L196.3,499z M199.1,498.8
              c0.3,0.2,0.7,0.1,0.9-0.1c0,0,5.2-7-0.5-10.9c-1.9-1.3-3.2-2-4.1-2.5c-1.3-0.7-1.5-0.8-1.6-1.8c-0.1-0.4,0.2-1.1,0.6-1.9
              c0.9-1.9,2.2-4.6,0.4-8.8c-1.5-3.6-2.8-3.9-4.4-4.4c-1-0.3-2.2-0.6-3.7-2.2c-1.7-1.9-2.2-3.6-2.1-5c0.1-1.5,0.9-2.7,1.5-3.7
              c1.6-2.3,0.4-5.4,0.4-5.4c-0.1-0.3-0.5-0.5-0.8-0.3s-0.5,0.5-0.3,0.8c0,0,1,2.5-0.3,4.2c-0.8,1.1-1.6,2.5-1.8,4.3s0.4,3.8,2.4,6
              c1.8,1.9,3.1,2.2,4.3,2.6c1.3,0.4,2.3,0.7,3.6,3.7c1.6,3.7,0.4,6-0.4,7.7c-0.5,1-0.8,1.8-0.7,2.6c0.2,1.5,0.5,1.7,2.2,2.7
              c0.8,0.5,2.1,1.2,4,2.5c4.7,3.2,0.2,9.1,0.2,9.1C198.8,498.2,198.8,498.6,199.1,498.8z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#D4410B" d="M1097.9,551.8l-41.1-92.5l-169.2,75.3l41.1,92.5L1097.9,551.8
              L1097.9,551.8z M932,619l-36.2-81.4l157.7-70.2l36.2,81.4L932,619z"/>

            <rect x="906.3" y="498.6" transform="matrix(0.9136 -0.4065 0.4065 0.9136 -135.0948 450.4323)" fill="#CCCCCE" width="172.6" height="89.1"/>
            <g>
              <polygon fill="#39BEEE" points="1090.5,555.1 1089.7,533.3 1096.6,548.8 1096.7,552.3
                        "/>
              <path fill="#39BEEE" d="M1078,560.6l6.2-2.8l-0.2-6.5l-6.2,2.8L1078,560.6L1078,560.6z
                 M1083.3,534.6l-0.6-16.9l-6.9-15.5l0.6,16.9L1083.3,534.6z"/>
              <path fill="#39BEEE" d="M1065.5,566.2l6.2-2.8l-0.2-6.5l-6.2,2.8L1065.5,566.2
                L1065.5,566.2z M1069.5,503.5l-0.6-16.9L1062,471l0.6,16.9L1069.5,503.5z"/>
              <path fill="#39BEEE" d="M1053.1,571.7l6.2-2.8l-0.2-6.5l-6.2,2.8L1053.1,571.7
                L1053.1,571.7z M1053.4,467.4l2.2,5l-0.5-12.3l-6.2,2.8l0.2,6.5L1053.4,467.4z"/>
              <path fill="#39BEEE" d="M1040.6,577.3l6.2-2.8l-0.2-6.5l-6.2,2.8L1040.6,577.3
                L1040.6,577.3z M1043,472l-0.2-6.5l-6.2,2.8l0.2,6.5L1043,472z"/>
              <path fill="#39BEEE" d="M1028.1,582.8l6.2-2.8l-0.2-6.5l-6.2,2.8L1028.1,582.8
                L1028.1,582.8z M1030.5,477.6l-0.2-6.5l-6.2,2.8l0.2,6.5L1030.5,477.6z"/>
              <path fill="#39BEEE" d="M1015.6,588.4l6.2-2.8l-0.2-6.5l-6.2,2.8L1015.6,588.4
                L1015.6,588.4z M1018,483.1l-0.2-6.5l-6.2,2.8l0.2,6.5L1018,483.1z"/>
              <path fill="#39BEEE" d="M1003.2,593.9l6.2-2.8l-0.2-6.5l-6.2,2.8L1003.2,593.9
                L1003.2,593.9z M1005.5,488.7l-0.2-6.5l-6.2,2.8l0.2,6.5L1005.5,488.7z"/>
              <path fill="#39BEEE" d="M990.7,599.5l6.2-2.8l-0.2-6.5l-6.2,2.8L990.7,599.5
                L990.7,599.5z M993.1,494.2l-0.2-6.5l-6.2,2.8l0.2,6.5L993.1,494.2z"/>
              <path fill="#39BEEE" d="M978.2,605l6.2-2.8l-0.2-6.5l-6.2,2.8L978.2,605L978.2,605z
                 M980.6,499.8l-0.2-6.5l-6.2,2.8l0.2,6.5L980.6,499.8z"/>
              <path fill="#39BEEE" d="M965.8,610.6l6.2-2.8l-0.2-6.5l-6.2,2.8L965.8,610.6
                L965.8,610.6z M968.1,505.3l-0.2-6.5l-6.2,2.8l0.2,6.5L968.1,505.3z"/>
              <path fill="#39BEEE" d="M953.3,616.1l6.2-2.8l-0.2-6.5l-6.2,2.8L953.3,616.1
                L953.3,616.1z M955.7,510.9l-0.2-6.5l-6.2,2.8l0.2,6.5L955.7,510.9z"/>
              <path fill="#39BEEE" d="M940.8,621.7l6.2-2.8l-0.2-6.5l-6.2,2.8L940.8,621.7
                L940.8,621.7z M943.2,516.4l-0.2-6.5l-6.2,2.8l0.2,6.5L943.2,516.4z"/>
              <path fill="#39BEEE" d="M928.3,626.2l0.4,0.9l5.9-2.6l-0.2-6.5l-2.3,1l-4.3-9.7
                L928.3,626.2L928.3,626.2z M930.7,522l-0.2-6.5l-6.2,2.8l0.2,6.5L930.7,522z"/>
              <path fill="#39BEEE" d="M914.5,595l6.9,15.5l-0.6-16.9l-6.9-15.5L914.5,595L914.5,595z
                 M918.3,527.5L918,521l-6.2,2.8l0.2,6.5L918.3,527.5z"/>
              <path fill="#39BEEE" d="M900.6,563.9l6.9,15.5l-0.6-16.9L900,547L900.6,563.9
                L900.6,563.9z M905.8,533.1l-0.2-6.5l-6.2,2.8l0.2,6.5L905.8,533.1z"/>
              <polygon fill="#39BEEE" points="893.1,532.1 893.7,548.3 887.6,534.6         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M963.8,512.4l-2,0.9l0,0c0.3,0.7,0,1.6-0.7,1.9s-1.6,0-1.9-0.7
              l0,0L958,515l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7l0,0l-1.1,0.5l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7
              l0,0l-1.1,0.5l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7l0,0l-1.1,0.5l0,0c0.3,0.7,0,1.6-0.7,1.9c-0.7,0.3-1.6,0-1.9-0.7
              l0,0l-2,0.9l0.7,1.6c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1
              c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.6,1.3
              c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1
              c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.5,1.1c0.7-0.3,1.6,0,1.9,0.7c0.3,0.7,0,1.6-0.7,1.9l0.7,1.6l2-0.9l0,0
              c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l1.1-0.5l0,0c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l1.1-0.5l0,0
              c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l1.1-0.5l0,0c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l1.3-0.6l0,0
              c-0.3-0.7,0-1.6,0.7-1.9c0.7-0.3,1.6,0,1.9,0.7l0,0l2-0.9l-0.7-1.6c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.6-1.3c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9l-0.5-1.1
              c-0.7,0.3-1.6,0-1.9-0.7c-0.3-0.7,0-1.6,0.7-1.9L963.8,512.4z"/>

            <rect x="951.1" y="518.9" transform="matrix(0.9136 -0.4065 0.4065 0.9136 -133.9259 436.345)" fill="#A0D8F7" width="18" height="28.9"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M1045.9,386.2l-126.2,51l93.4,231l126.2-51L1045.9,386.2
            L1045.9,386.2z M1128.6,612.4l-111.2,44.9l-87-215.3l111.2-44.9L1128.6,612.4z"/>

          <rect x="969.6" y="411.1" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -122.534 424.1433)" fill="#E0DCC1" width="119.9" height="232.2"/>
          <polygon fill="#0B99D6" points="1050,396.3 1020.7,396.4 1041.9,387.8 1046.6,387.8
            "/>
          <path fill="#0B99D6" d="M1056.9,413.3l-3.4-8.5h-8.7l3.4,8.5H1056.9L1056.9,413.3z
             M1022.2,404.9l-22.8,0.1l-21.2,8.6l22.8-0.1L1022.2,404.9z"/>
          <path fill="#0B99D6" d="M1063.8,430.4l-3.4-8.5h-8.7l3.4,8.5H1063.8L1063.8,430.4z
             M979.7,422.1l-22.8,0.1l-21.2,8.6l22.8-0.1L979.7,422.1z"/>
          <path fill="#0B99D6" d="M1070.7,447.4l-3.4-8.5h-8.7l3.4,8.5H1070.7L1070.7,447.4z
             M930.4,442l6.8-2.7l-16.6,0.1l3.4,8.5h8.7L930.4,442z"/>
          <path fill="#0B99D6" d="M1077.6,464.4l-3.4-8.5h-8.7l3.4,8.5H1077.6L1077.6,464.4z
             M936.2,456.3h-8.7l3.4,8.5h8.7L936.2,456.3z"/>
          <path fill="#0B99D6" d="M1084.4,481.4l-3.4-8.5h-8.7l3.4,8.5H1084.4L1084.4,481.4z
             M943.1,473.4h-8.7l3.4,8.5h8.7L943.1,473.4z"/>
          <path fill="#0B99D6" d="M1091.3,498.5l-3.4-8.5h-8.7l3.4,8.5H1091.3L1091.3,498.5z
             M949.9,490.4h-8.7l3.4,8.5h8.7L949.9,490.4z"/>
          <path fill="#0B99D6" d="M1098.2,515.5l-3.4-8.5h-8.7l3.4,8.5H1098.2L1098.2,515.5z
             M956.8,507.4h-8.7l3.4,8.5h8.7L956.8,507.4z"/>
          <path fill="#0B99D6" d="M1105.1,532.5l-3.4-8.5h-8.7l3.4,8.5H1105.1L1105.1,532.5z
             M963.7,524.5H955l3.4,8.5h8.7L963.7,524.5z"/>
          <path fill="#0B99D6" d="M1112,549.6l-3.4-8.5h-8.7l3.4,8.5H1112L1112,549.6z
             M970.6,541.5h-8.7l3.4,8.5h8.7L970.6,541.5z"/>
          <path fill="#0B99D6" d="M1118.9,566.6l-3.4-8.5h-8.7l3.4,8.5H1118.9L1118.9,566.6z
             M977.5,558.5h-8.7l3.4,8.5h8.7L977.5,558.5z"/>
          <path fill="#0B99D6" d="M1125.7,583.6l-3.4-8.5h-8.7l3.4,8.5H1125.7L1125.7,583.6z
             M984.3,575.5h-8.7l3.4,8.5h8.7L984.3,575.5z"/>
          <path fill="#0B99D6" d="M1132.6,600.7l-3.4-8.5h-8.7l3.4,8.5H1132.6L1132.6,600.7z
             M991.2,592.6h-8.7l3.4,8.5h8.7L991.2,592.6z"/>
          <path fill="#0B99D6" d="M1138.1,617.7l1.2-0.5l-3.2-8h-8.7l1.3,3.2l-13.3,5.4
            L1138.1,617.7L1138.1,617.7z M998.1,609.6h-8.7l3.4,8.5h8.7L998.1,609.6z"/>
          <path fill="#0B99D6" d="M1095.6,634.9l21.2-8.6l-22.8,0.1l-21.2,8.6L1095.6,634.9
            L1095.6,634.9z M1005,626.6h-8.7l3.4,8.5h8.7L1005,626.6z"/>
          <g>
            <path fill="#80CAF0" d="M1081.8,548.4c-1.6-4-4.7-7-8.4-8.6c-3.7-1.6-8-1.7-12-0.1
              c-4,1.6-7,4.7-8.6,8.4s-1.7,8-0.1,12s4.7,7,8.4,8.6c3.7,1.6,8,1.7,12,0.1c4-1.6,7-4.7,8.6-8.4
              C1083.2,556.7,1083.4,552.4,1081.8,548.4L1081.8,548.4z M1072.2,566.5c-3.4,1.4-7,1.2-10.1-0.1s-5.7-3.8-7.1-7.2s-1.2-7,0.1-10.1
              c1.3-3.1,3.8-5.7,7.2-7.1c3.4-1.4,7-1.2,10.1,0.1s5.7,3.8,7.1,7.2s1.2,7-0.1,10.1S1075.5,565.1,1072.2,566.5z"/>
            <path fill="#80CAF0" d="M1073.7,553.6c0,0,9.9,1.6,8.3,9c-0.6,3-1.2,4.9-1.6,6.1
              c-0.8,2.5-0.9,3,0.3,4.6c0.6,0.9,1.8,1.3,3.1,1.8c2.4,0.9,5.7,2.1,7.6,7.1c1.6,4.1,0.8,5.4-0.1,6.9c-0.8,1.4-1.8,3-1.7,6.5
              c0,4,1.4,6.5,3.2,8.1c1.8,1.6,4,2.2,5.7,2.5c2.8,0.5,4.2,3.8,4.2,3.8c0.2,0.4,0.7,0.6,1.1,0.5c0.4-0.2,0.6-0.7,0.5-1.1
              c0,0-1.7-4.1-5.4-4.8c-1.5-0.3-3.4-0.8-4.9-2.1s-2.6-3.4-2.6-6.8c0-3,0.8-4.4,1.5-5.6c1.1-1.9,2.1-3.5,0.2-8.4
              c-2.2-5.7-5.9-7.1-8.6-8.1c-1.1-0.4-2-0.7-2.3-1.2c-0.7-1-0.6-1.3-0.1-3.2c0.4-1.3,1-3.2,1.6-6.3c1.9-9.1-9.6-11-9.6-11
              c-0.5-0.1-0.9,0.2-1,0.7C1072.9,553.1,1073.2,553.5,1073.7,553.6L1073.7,553.6z M1059.4,559.4c-0.5-0.1-0.8-0.5-0.7-1
              c0.1-0.5,0.5-0.8,1-0.7c0,0,11.6,1.9,9.6,11c-0.6,3.1-1.2,5-1.6,6.3c-0.6,1.9-0.7,2.2,0.1,3.2c0.4,0.5,1.2,0.8,2.3,1.2
              c2.7,1,6.4,2.4,8.6,8.1c1.8,4.9,0.9,6.4-0.2,8.4c-0.7,1.2-1.5,2.6-1.5,5.6c0,3.4,1.2,5.5,2.6,6.8c1.5,1.3,3.4,1.9,4.9,2.1
              c3.7,0.7,5.4,4.8,5.4,4.8c0.2,0.4,0,0.9-0.5,1.1s-0.9,0-1.1-0.5c0,0-1.3-3.3-4.2-3.8c-1.7-0.3-3.9-1-5.7-2.5
              c-1.8-1.6-3.2-4.1-3.2-8.1c0-3.4,0.9-5.1,1.7-6.5c0.9-1.6,1.7-2.8,0.1-6.9c-1.9-5-5.2-6.2-7.6-7.1c-1.4-0.5-2.5-0.9-3.1-1.8
              c-1.2-1.7-1.1-2.1-0.3-4.6c0.4-1.3,0.9-3.1,1.6-6.1C1069.2,561,1059.4,559.4,1059.4,559.4L1059.4,559.4z M1062.9,557.9
              c-0.5-0.1-0.8-0.5-0.7-1c0.1-0.5,0.5-0.8,1-0.7c0,0,11.6,1.9,9.6,11c-0.6,3.1-1.2,5-1.6,6.3c-0.6,1.9-0.7,2.2,0.1,3.2
              c0.4,0.5,1.2,0.8,2.3,1.2c2.7,1,6.4,2.4,8.6,8.1c1.8,4.9,0.9,6.4-0.2,8.4c-0.7,1.2-1.5,2.6-1.5,5.6c0,3.4,1.2,5.5,2.6,6.8
              c1.5,1.3,3.4,1.9,4.9,2.1c3.7,0.7,5.4,4.8,5.4,4.8c0.2,0.4,0,0.9-0.5,1.1c-0.4,0.2-0.9,0-1.1-0.5c0,0-1.3-3.3-4.2-3.8
              c-1.7-0.3-3.9-1-5.7-2.5c-1.8-1.6-3.2-4.1-3.2-8.1c0-3.4,0.9-5.1,1.7-6.5c0.9-1.6,1.7-2.8,0.1-6.9c-1.9-5-5.2-6.2-7.6-7.1
              c-1.4-0.5-2.5-0.9-3.1-1.8c-1.2-1.7-1.1-2.1-0.3-4.6c0.4-1.3,0.9-3.1,1.6-6.1C1072.8,559.5,1063,557.9,1062.9,557.9L1062.9,557.9
              z M1066.5,556.5c-0.5-0.1-0.8-0.5-0.7-1c0.1-0.5,0.5-0.8,1-0.7c0,0,11.6,1.9,9.6,11c-0.6,3.1-1.2,5-1.6,6.3
              c-0.6,1.9-0.7,2.2,0.1,3.2c0.4,0.5,1.2,0.8,2.3,1.2c2.7,1,6.4,2.4,8.6,8.1c1.8,4.9,0.9,6.4-0.2,8.4c-0.7,1.2-1.5,2.6-1.5,5.6
              c0,3.4,1.2,5.5,2.6,6.8c1.5,1.3,3.4,1.9,4.9,2.1c3.7,0.7,5.4,4.8,5.4,4.8c0.2,0.4,0,0.9-0.5,1.1c-0.4,0.2-0.9,0-1.1-0.5
              c0,0-1.3-3.3-4.2-3.8c-1.7-0.3-3.9-1-5.7-2.5c-1.8-1.6-3.2-4.1-3.2-8.1c0-3.4,0.9-5.1,1.7-6.5c0.9-1.6,1.7-2.8,0.1-6.9
              c-1.9-5-5.2-6.2-7.6-7.1c-1.4-0.5-2.5-0.9-3.1-1.8c-1.2-1.7-1.1-2.1-0.3-4.6c0.4-1.3,0.9-3.1,1.6-6.1
              C1076.4,558.1,1066.5,556.5,1066.5,556.5L1066.5,556.5z M1069.4,554.1c0.1-0.5,0.5-0.8,1-0.7c0,0,11.6,1.9,9.6,11
              c-0.6,3.1-1.2,5-1.6,6.3c-0.6,1.9-0.7,2.2,0.1,3.2c0.4,0.5,1.2,0.8,2.3,1.2c2.7,1,6.4,2.4,8.6,8.1c1.8,4.9,0.9,6.4-0.2,8.4
              c-0.7,1.2-1.5,2.6-1.5,5.6c0,3.4,1.2,5.5,2.6,6.8c1.5,1.3,3.4,1.9,4.9,2.1c3.7,0.7,5.4,4.8,5.4,4.8c0.2,0.4,0,0.9-0.5,1.1
              c-0.4,0.2-0.9,0-1.1-0.5c0,0-1.3-3.3-4.2-3.8c-1.7-0.3-3.9-1-5.7-2.5c-1.8-1.6-3.2-4.1-3.2-8.1c0-3.4,0.9-5.1,1.7-6.5
              c0.9-1.6,1.7-2.8,0.1-6.9c-1.9-5-5.2-6.2-7.6-7.1c-1.4-0.5-2.5-0.9-3.1-1.8c-1.2-1.7-1.1-2.1-0.3-4.6c0.4-1.3,0.9-3.1,1.6-6.1
              c1.6-7.4-8.3-9-8.3-9C1069.6,555,1069.3,554.6,1069.4,554.1z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M1005.6,569l0.8,2l0,0c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.5,1.3l0,0c0.7-0.3,1.6,0.1,1.9,0.8
              c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.5,1.1l0,0c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.5,1.1l0,0
              c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.5,1.1l0,0c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0,0l0.8,2
              l1.6-0.7c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5
              c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.3-0.5
              c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5
              c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.1-0.5c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l1.6-0.7l-0.8-2
              l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.5-1.1l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.5-1.1l0,0
              c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.5-1.1l0,0c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.5-1.3l0,0
              c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l0,0l-0.8-2l-1.6,0.7c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.3,0.5c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l-1.1,0.5
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8L1005.6,569z"/>

            <rect x="1012" y="564.4" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -140.0694 426.3436)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M1043,632.4l2.4-1l0,0c-0.4-0.9,0.1-1.9,0.9-2.2c0.9-0.4,1.9,0.1,2.2,0.9l0,0l1.6-0.6l0,0c-0.4-0.9,0.1-1.9,0.9-2.2
              c0.9-0.4,1.9,0.1,2.2,0.9l0,0l1.4-0.6l0,0c-0.4-0.9,0.1-1.9,0.9-2.2c0.9-0.4,1.9,0.1,2.2,0.9l0,0l1.4-0.6l0,0
              c-0.4-0.9,0.1-1.9,0.9-2.2c0.9-0.4,1.9,0.1,2.2,0.9l0,0l1.4-0.6l0,0c-0.4-0.9,0.1-1.9,0.9-2.2c0.9-0.4,1.9,0.1,2.2,0.9l0,0l2.4-1
              l-0.8-1.9c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2
              l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2
              l-0.6-1.6c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2
              l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2l-0.6-1.4c-0.9,0.4-1.9-0.1-2.2-0.9c-0.4-0.9,0.1-1.9,0.9-2.2
              l-0.8-1.9l-2.4,1l0,0c0.4,0.9-0.1,1.9-0.9,2.2c-0.9,0.4-1.9-0.1-2.2-0.9l0,0l-1.4,0.6l0,0c0.4,0.9-0.1,1.9-0.9,2.2
              c-0.9,0.4-1.9-0.1-2.2-0.9l0,0l-1.4,0.6l0,0c0.4,0.9-0.1,1.9-0.9,2.2c-0.9,0.4-1.9-0.1-2.2-0.9l0,0l-1.4,0.6l0,0
              c0.4,0.9-0.1,1.9-0.9,2.2c-0.9,0.4-1.9-0.1-2.2-0.9l0,0l-1.6,0.6l0,0c0.4,0.9-0.1,1.9-0.9,2.2c-0.9,0.4-1.9-0.1-2.2-0.9l0,0
              l-2.4,1l0.8,1.9c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.4c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2
              l0.6,1.4c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.4c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.6
              c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.4c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.4
              c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2l0.6,1.4c0.9-0.4,1.9,0.1,2.2,0.9c0.4,0.9-0.1,1.9-0.9,2.2L1043,632.4z"/>

            <rect x="1037.4" y="590.3" transform="matrix(0.9271 -0.3747 0.3747 0.9271 -151.2786 437.0324)" fill="#FBD4A4" width="21.5" height="34.4"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M1014.6,630.7l2-0.8l0,0c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.3-0.5l0,0c-0.3-0.7,0.1-1.6,0.8-1.9
              c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.1-0.5l0,0c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l1.1-0.5l0,0
              c-0.3-0.7,0.1-1.6,0.8-1.9s1.6,0.1,1.9,0.8l0,0l1.1-0.5l0,0c-0.3-0.7,0.1-1.6,0.8-1.9c0.7-0.3,1.6,0.1,1.9,0.8l0,0l2-0.8
              l-0.7-1.6c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1
              c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.3
              c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1
              c-0.7,0.3-1.6-0.1-1.9-0.8s0.1-1.6,0.8-1.9l-0.5-1.1c-0.7,0.3-1.6-0.1-1.9-0.8c-0.3-0.7,0.1-1.6,0.8-1.9l-0.7-1.6l-2,0.8l0,0
              c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0l-1.1,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0
              l-1.1,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0l-1.1,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9
              c-0.7,0.3-1.6-0.1-1.9-0.8l0,0l-1.3,0.5l0,0c0.3,0.7-0.1,1.6-0.8,1.9c-0.7,0.3-1.6-0.1-1.9-0.8l0,0l-2,0.8l0.7,1.6
              c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1
              c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.3
              c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1
              c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9l0.5,1.1c0.7-0.3,1.6,0.1,1.9,0.8c0.3,0.7-0.1,1.6-0.8,1.9L1014.6,630.7z"/>

            <rect x="1009.9" y="595.4" transform="matrix(0.9271 -0.3747 0.3747 0.9271 -154.2899 426.247)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="1049.6" y="417.9" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -96.6161 427.4585)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="965.4" y="447.2" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -113.7272 398.0129)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="975.2" y="443.6" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -111.659 401.4189)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="984.8" y="439" transform="matrix(0.9272 -0.3747 0.3747 0.9272 -109.2423 404.7168)" fill="#236095" width="2.7" height="88.6"/>
        </g>
        <g>
          <path fill="#E2070F" d="M964.3,478.2l-117.4-68.9L720.8,624.1L838.2,693L964.3,478.2
            L964.3,478.2z M835.5,681.7L732.1,621l117.5-200.3L953,481.4L835.5,681.7z"/>

          <rect x="726.4" y="491.2" transform="matrix(0.5061 -0.8625 0.8625 0.5061 -59.226 998.8847)" fill="#D3AC89" width="232.2" height="119.9"/>
          <polygon fill="#0B99D6" points="958.8,487.6 940.8,464.4 960.6,476 963.4,479.7     "/>
          <path fill="#0B99D6" d="M949.5,503.4l4.6-7.9l-5.4-6.9l-4.6,7.9L949.5,503.4L949.5,503.4
            z M935,470.8l-13.9-18l-19.7-11.6l13.9,18L935,470.8z"/>
          <path fill="#0B99D6" d="M940.2,519.3l4.6-7.9l-5.4-6.9l-4.6,7.9L940.2,519.3L940.2,519.3
            z M895.4,447.6l-13.9-18L861.8,418l13.9,18L895.4,447.6z"/>
          <path fill="#0B99D6" d="M930.9,535.1l4.6-7.9l-5.4-6.9l-4.6,7.9L930.9,535.1L930.9,535.1
            z M849.6,420.6l6.3,3.7l-10.2-13.1l-4.6,7.9l5.4,6.9L849.6,420.6z"/>
          <path fill="#0B99D6" d="M921.6,550.9l4.6-7.9l-5.4-6.9l-4.6,7.9L921.6,550.9L921.6,550.9
            z M841.8,434l-5.4-6.9l-4.6,7.9l5.4,6.9L841.8,434z"/>
          <path fill="#0B99D6" d="M912.3,566.8l4.6-7.9l-5.4-6.9l-4.6,7.9L912.3,566.8L912.3,566.8
            z M832.5,449.8l-5.4-6.9l-4.6,7.9l5.4,6.9L832.5,449.8z"/>
          <path fill="#0B99D6" d="M903,582.6l4.6-7.9l-5.4-6.9l-4.6,7.9L903,582.6L903,582.6z
             M823.2,465.6l-5.4-6.9l-4.6,7.9l5.4,6.9L823.2,465.6z"/>
          <path fill="#0B99D6" d="M893.7,598.5l4.6-7.9l-5.4-6.9l-4.6,7.9L893.7,598.5L893.7,598.5
            z M813.9,481.5l-5.4-6.9l-4.6,7.9l5.4,6.9L813.9,481.5z"/>
          <path fill="#0B99D6" d="M884.4,614.3l4.6-7.9l-5.4-6.9l-4.6,7.9L884.4,614.3L884.4,614.3
            z M804.6,497.3l-5.4-6.9l-4.6,7.9l5.4,6.9L804.6,497.3z"/>
          <path fill="#0B99D6" d="M875.1,630.2l4.6-7.9l-5.4-6.9l-4.6,7.9L875.1,630.2L875.1,630.2
            z M795.3,513.2l-5.4-6.9l-4.6,7.9l5.4,6.9L795.3,513.2z"/>
          <path fill="#0B99D6" d="M865.8,646l4.6-7.9l-5.4-6.9l-4.6,7.9L865.8,646L865.8,646z
             M786,529l-5.4-6.9L776,530l5.4,6.9L786,529z"/>
          <path fill="#0B99D6" d="M856.5,661.8l4.6-7.9l-5.4-6.9l-4.6,7.9L856.5,661.8L856.5,661.8
            z M776.7,544.9l-5.4-6.9l-4.6,7.9l5.4,6.9L776.7,544.9z"/>
          <path fill="#0B99D6" d="M847.2,677.7l4.6-7.9l-5.4-6.9l-4.6,7.9L847.2,677.7L847.2,677.7
            z M767.4,560.7l-5.4-6.9l-4.6,7.9l5.4,6.9L767.4,560.7z"/>
          <path fill="#0B99D6" d="M837.1,692.4l1.1,0.7l4.4-7.4l-5.4-6.9l-1.7,3l-12.4-7.3
            L837.1,692.4L837.1,692.4z M758.1,576.5l-5.4-6.9l-4.6,7.9l5.4,6.9L758.1,576.5z"/>
          <path fill="#0B99D6" d="M797.5,669.2l19.7,11.6l-13.9-18l-19.7-11.6L797.5,669.2
            L797.5,669.2z M748.8,592.4l-5.4-6.9l-4.6,7.9l5.4,6.9L748.8,592.4z"/>
          <path fill="#0B99D6" d="M758,646l19.7,11.6l-13.9-18L744.1,628L758,646L758,646z
             M739.5,608.2l-5.4-6.9l-4.6,7.9l5.4,6.9L739.5,608.2z"/>
          <polygon fill="#0B99D6" points="724.9,617.2 738.2,634.4 720.8,624.1     "/>
          <g>
            <path fill="#80CAF0" d="M857.7,605.5c2.2-3.7,2.7-8,1.7-11.9s-3.5-7.4-7.2-9.6
              c-3.7-2.2-8-2.7-11.9-1.7s-7.4,3.5-9.6,7.2c-2.2,3.7-2.7,8-1.7,11.9s3.5,7.4,7.2,9.6c3.7,2.2,8,2.7,11.9,1.7
              C852,611.7,855.5,609.2,857.7,605.5L857.7,605.5z M837.4,608.9c-3.1-1.8-5.2-4.8-6.1-8.1c-0.9-3.3-0.5-6.9,1.4-10
              c1.8-3.1,4.8-5.2,8.1-6.1c3.3-0.9,6.9-0.5,10,1.4c3.1,1.8,5.2,4.8,6.1,8.1c0.9,3.3,0.5,6.9-1.4,10c-1.8,3.1-4.8,5.2-8.1,6.1
              C844.2,611.2,840.6,610.8,837.4,608.9z"/>
            <path fill="#80CAF0" d="M848.6,602.3c0,0,4.7,8.8-2.1,12.1c-2.8,1.3-4.6,2-5.8,2.5
              c-2.5,0.9-2.9,1.1-3.5,3.1c-0.3,1.1,0,2.2,0.5,3.6c0.8,2.5,1.8,5.8-1,10.4c-2.3,3.7-3.8,3.9-5.6,4.2c-1.6,0.2-3.5,0.4-6.2,2.6
              c-3.1,2.5-4.3,5.1-4.4,7.5c-0.2,2.4,0.7,4.5,1.5,6.1c1.3,2.6-0.5,5.6-0.5,5.6c-0.2,0.4-0.1,0.9,0.3,1.1c0.4,0.2,0.9,0.1,1.1-0.3
              c0,0,2.3-3.9,0.5-7.2c-0.7-1.4-1.4-3.2-1.3-5.2c0.1-2,1.1-4.1,3.8-6.3c2.3-1.8,4-2,5.4-2.2c2.2-0.3,4-0.5,6.8-4.9
              c3.2-5.2,2-9,1.2-11.8c-0.3-1.1-0.6-2-0.5-2.6c0.4-1.2,0.7-1.3,2.5-2c1.3-0.5,3.1-1.2,5.9-2.5c8.4-4,2.9-14.3,2.8-14.4
              c-0.2-0.4-0.7-0.6-1.1-0.3C848.5,601.4,848.4,601.9,848.6,602.3L848.6,602.3z M835.3,594.5c-0.2-0.4-0.1-0.9,0.3-1.1
              c0.4-0.2,0.9-0.1,1.1,0.3c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-5.9,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.2,0.6,0.1,1.5,0.5,2.6
              c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9c-1.4,0.2-3,0.4-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3s0.6,3.8,1.3,5.2
              c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3s-0.5-0.7-0.3-1.1c0,0,1.8-3.1,0.5-5.6c-0.8-1.6-1.6-3.7-1.5-6.1
              c0.2-2.4,1.3-5,4.4-7.5c2.7-2.1,4.6-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.2c2.8-4.6,1.8-7.9,1-10.4c-0.4-1.4-0.8-2.5-0.5-3.6
              c0.6-2,1-2.2,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5C840,603.3,835.3,594.5,835.3,594.5L835.3,594.5z M838.6,596.4
              c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-5.9,2.5c-1.8,0.7-2.1,0.8-2.5,2
              c-0.2,0.6,0.1,1.5,0.5,2.6c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9c-1.4,0.2-3,0.4-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3
              s0.6,3.8,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1c0,0,1.8-3.1,0.5-5.6
              c-0.8-1.6-1.6-3.7-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c2.7-2.1,4.6-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.2c2.8-4.6,1.8-7.9,1-10.4
              c-0.4-1.4-0.8-2.5-0.5-3.6c0.6-2,1-2.2,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5C843.3,605.2,838.6,596.4,838.6,596.4L838.6,596.4z
               M841.9,598.4c-0.2-0.4-0.1-0.9,0.3-1.1c0.4-0.2,0.9-0.1,1.1,0.3c0,0,5.6,10.3-2.8,14.4c-2.8,1.4-4.7,2.1-5.9,2.5
              c-1.8,0.7-2.1,0.8-2.5,2c-0.2,0.6,0.1,1.5,0.5,2.6c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9c-1.4,0.2-3,0.4-5.4,2.2
              c-2.7,2.1-3.7,4.3-3.8,6.3s0.6,3.8,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3c-0.4-0.2-0.5-0.7-0.3-1.1
              c0,0,1.8-3.1,0.5-5.6c-0.8-1.6-1.6-3.7-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c2.7-2.1,4.6-2.4,6.2-2.6c1.8-0.2,3.2-0.4,5.6-4.2
              c2.8-4.6,1.8-7.9,1-10.4c-0.4-1.4-0.8-2.5-0.5-3.6c0.6-2,1-2.2,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5
              C846.7,607.2,841.9,598.4,841.9,598.4L841.9,598.4z M845.6,599.2c0.4-0.2,0.9-0.1,1.1,0.3c0,0,5.6,10.3-2.8,14.4
              c-2.8,1.4-4.7,2.1-5.9,2.5c-1.8,0.7-2.1,0.8-2.5,2c-0.2,0.6,0.1,1.5,0.5,2.6c0.9,2.8,2,6.6-1.2,11.8c-2.7,4.4-4.5,4.7-6.8,4.9
              c-1.4,0.2-3,0.4-5.4,2.2c-2.7,2.1-3.7,4.3-3.8,6.3s0.6,3.8,1.3,5.2c1.7,3.4-0.5,7.2-0.5,7.2c-0.2,0.4-0.7,0.5-1.1,0.3
              c-0.4-0.2-0.5-0.7-0.3-1.1c0,0,1.8-3.1,0.5-5.6c-0.8-1.6-1.6-3.7-1.5-6.1c0.2-2.4,1.3-5,4.4-7.5c2.7-2.1,4.6-2.4,6.2-2.6
              c1.8-0.2,3.2-0.4,5.6-4.2c2.8-4.6,1.8-7.9,1-10.4c-0.4-1.4-0.8-2.5-0.5-3.6c0.6-2,1-2.2,3.5-3.1c1.2-0.5,3-1.2,5.8-2.5
              c6.8-3.3,2.1-12,2.1-12.1C845,599.9,845.2,599.4,845.6,599.2z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M794.8,557.7l-1.1,1.8l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.7,1.2l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0
              l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.6,1.1l0,0c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-0.6,1.1l0,0
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l0,0l-1.1,1.8l1.5,0.9c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5
              s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.2,0.7
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2
              l1.1,0.6c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l1.5,0.9l1.1-1.8l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1l0,0
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.6-1.1l0,0
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l0.7-1.2l0,0c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0,0l1.1-1.8l-1.5-0.9
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5
              s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.2-0.7c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l-1.1-0.6c-0.4,0.7-1.3,0.9-2,0.5
              s-0.9-1.3-0.5-2L794.8,557.7z"/>

            <rect x="795" y="562.4" transform="matrix(0.5061 -0.8625 0.8625 0.5061 -100.4099 978.3419)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M767.5,626l2.2,1.3l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l1.5,0.9l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0
              l1.3,0.7l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l1.3,0.7l0,0c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l1.3,0.7l0,0
              c0.5-0.8,1.5-1.1,2.3-0.6s1.1,1.5,0.6,2.3l0,0l2.2,1.3l1.1-1.8c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3
              c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3c-0.8-0.5-1.1-1.5-0.6-2.3
              s1.5-1.1,2.3-0.6l0.9-1.5c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3
              c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l0.7-1.3c-0.8-0.5-1.1-1.5-0.6-2.3s1.5-1.1,2.3-0.6l1.1-1.8l-2.2-1.3l0,0
              c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l-1.3-0.7l0,0c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l-1.3-0.7l0,0
              c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l-1.3-0.7l0,0c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l-1.5-0.9l0,0
              c-0.5,0.8-1.5,1.1-2.3,0.6s-1.1-1.5-0.6-2.3l0,0l-2.2-1.3l-1.1,1.8c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3
              c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3
              s-1.5,1.1-2.3,0.6l-0.9,1.5c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6
              l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6l-0.7,1.3c0.8,0.5,1.1,1.5,0.6,2.3s-1.5,1.1-2.3,0.6L767.5,626z"/>

            <rect x="773.1" y="604.3" transform="matrix(0.5062 -0.8624 0.8624 0.5062 -140.1806 985.2839)" fill="#FBD4A4" width="34.4" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M751.5,602.5l1.8,1.1l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.2,0.7l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6
              l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.1,0.6l0,0
              c0.4-0.7,1.3-0.9,2-0.5s0.9,1.3,0.5,2l0,0l1.8,1.1l0.9-1.5c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2
              s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.7-1.2
              c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2
              s1.3-0.9,2-0.5l0.6-1.1c-0.7-0.4-0.9-1.3-0.5-2s1.3-0.9,2-0.5l0.9-1.5l-1.8-1.1l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0
              l-1.1-0.6l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.1-0.6l0,0
              c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.2-0.7l0,0c-0.4,0.7-1.3,0.9-2,0.5s-0.9-1.3-0.5-2l0,0l-1.8-1.1l-0.9,1.5
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2
              s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.7,1.2c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1
              c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2s-1.3,0.9-2,0.5l-0.6,1.1c0.7,0.4,0.9,1.3,0.5,2
              s-1.3,0.9-2,0.5L751.5,602.5z"/>

            <rect x="756.1" y="584.2" transform="matrix(0.5062 -0.8624 0.8624 0.5062 -131.0999 957.4885)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="870.7,463.1 820.5,542.9 833.9,546.2 881.2,468.1     "/>
        </g>
        <g>

          <rect x="383.2" y="503.6" transform="matrix(0.182 -0.9833 0.9833 0.182 -231.2943 913.7531)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M533.4,563.1l-81.5,38.7c-11.7,5.6-28.3,2.8-36.8-6.1L352,529.5
            L533.4,563.1z"/>
          <g>
            <path fill="#0B99D6" d="M424.2,587.2c4.1,0.8,7.5,3.1,9.7,6.3s3.2,7.2,2.4,11.3
              c-0.8,4.1-3.1,7.5-6.3,9.7c-3.2,2.2-7.2,3.2-11.3,2.4c-4.1-0.8-7.5-3.1-9.7-6.3s-3.2-7.2-2.4-11.3c0.8-4.1,3.1-7.5,6.3-9.7
              S420.1,586.5,424.2,587.2L424.2,587.2z M431.9,594.9c-1.8-2.7-4.7-4.6-8.1-5.3c-3.4-0.6-6.8,0.2-9.5,2s-4.6,4.7-5.3,8.1
              s0.2,6.8,2,9.5s4.7,4.6,8.1,5.3c3.4,0.6,6.8-0.2,9.5-2s4.6-4.7,5.3-8.1C434.5,600.9,433.7,597.5,431.9,594.9z"/>
            <path fill="#0B99D6" d="M424.2,596.5c-0.3,0.3-0.8,0.4-1.1,0.1
              c-0.3-0.3-0.4-0.8-0.1-1.1c0,0,7.5-8.4,13.9-2.1c2.1,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.7,2.6,1.6c0.6,0,1.3-0.6,2.2-1.3
              c2.2-1.7,5.3-4,11-2.8c4.9,1,5.7,2.6,6.7,4.5c0.6,1.2,1.3,2.6,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4s3.3-1.8,4.3-2.9
              c2.5-2.7,6.7-1.9,6.7-1.9c0.4,0.1,0.7,0.5,0.6,0.9s-0.5,0.7-0.9,0.6c0,0-3.4-0.6-5.2,1.4c-1.2,1.2-2.8,2.7-5,3.3
              s-4.9,0.5-8.2-1.6c-2.8-1.7-3.6-3.4-4.3-4.8c-0.8-1.5-1.4-2.8-5.6-3.7c-5-1.1-7.7,1-9.7,2.5c-1.1,0.8-2,1.5-3.1,1.6
              c-2,0.1-2.3-0.2-3.9-2.1c-0.8-1-2-2.4-4.1-4.4C430.6,589.3,424.3,596.5,424.2,596.5L424.2,596.5z M421.5,611
              c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5
              c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3c1.9-2,5.2-1.4,5.2-1.4
              c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4
              c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6
              c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1C420.7,611.4,421.2,611.4,421.5,611L421.5,611z
               M422.2,607.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1S421.9,607.7,422.2,607.4
              L422.2,607.4z M422.9,603.8c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1
              C422.1,604.1,422.6,604.1,422.9,603.8L422.9,603.8z M423.6,600.1c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0.1,1.1C422.8,600.5,423.3,600.4,423.6,600.1z"/>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M774,510.1l-80.9-60.8L581.9,597.4l80.9,60.8L774,510.1
                L774,510.1z M661.7,649.5L590.5,596l103.6-138l71.3,53.5L661.7,649.5z"/>

              <rect x="591.6" y="509.3" transform="matrix(0.6005 -0.7996 0.7996 0.6005 -172.0283 763.2838)" fill="#D1CCE6" width="172.6" height="89.1"/>
              <g>
                <polygon fill="#0B99D6" points="769.1,516.6 757.8,498 771.4,508.2 773.2,511.2
                  "/>
                <path fill="#0B99D6" d="M760.9,527.5l4.1-5.4l-3.4-5.6l-4.1,5.4L760.9,527.5
                  L760.9,527.5z M752.9,502.2l-8.8-14.5l-13.6-10.2l8.8,14.5L752.9,502.2z"/>
                <path fill="#0B99D6" d="M752.7,538.4l4.1-5.4l-3.4-5.6l-4.1,5.4L752.7,538.4
                  L752.7,538.4z M725.7,481.7l-8.8-14.5L703.3,457l8.8,14.5L725.7,481.7z"/>
                <path fill="#0B99D6" d="M744.5,549.4l4.1-5.4l-3.4-5.6l-4.1,5.4L744.5,549.4
                  L744.5,549.4z M694.1,458l4.3,3.3l-6.4-10.6l-4.1,5.4l3.4,5.6L694.1,458z"/>
                <path fill="#0B99D6" d="M736.3,560.3l4.1-5.4l-3.4-5.6l-4.1,5.4L736.3,560.3
                  L736.3,560.3z M687.2,467.2l-3.4-5.6l-4.1,5.4l3.4,5.6L687.2,467.2z"/>
                <path fill="#0B99D6" d="M728.1,571.2l4.1-5.4l-3.4-5.6l-4.1,5.4L728.1,571.2
                  L728.1,571.2z M679,478.1l-3.4-5.6l-4.1,5.4l3.4,5.6L679,478.1z"/>
                <path fill="#0B99D6" d="M719.9,582.1l4.1-5.4l-3.4-5.6l-4.1,5.4L719.9,582.1
                  L719.9,582.1z M670.8,489l-3.4-5.6l-4.1,5.4l3.4,5.6L670.8,489z"/>
                <path fill="#0B99D6" d="M711.7,593l4.1-5.4l-3.4-5.6l-4.1,5.4L711.7,593L711.7,593z
                   M662.6,499.9l-3.4-5.6l-4.1,5.4l3.4,5.6L662.6,499.9z"/>
                <path fill="#0B99D6" d="M703.5,603.9l4.1-5.4l-3.4-5.6l-4.1,5.4L703.5,603.9
                  L703.5,603.9z M654.4,510.8l-3.4-5.6l-4.1,5.4l3.4,5.6L654.4,510.8z"/>
                <path fill="#0B99D6" d="M695.3,614.8l4.1-5.4l-3.4-5.6l-4.1,5.4L695.3,614.8
                  L695.3,614.8z M646.2,521.8l-3.4-5.6l-4.1,5.4l3.4,5.6L646.2,521.8z"/>
                <path fill="#0B99D6" d="M687.1,625.8l4.1-5.4l-3.4-5.6l-4.1,5.4L687.1,625.8
                  L687.1,625.8z M638,532.7l-3.4-5.6l-4.1,5.4l3.4,5.6L638,532.7z"/>
                <path fill="#0B99D6" d="M678.9,636.7l4.1-5.4l-3.4-5.6l-4.1,5.4L678.9,636.7
                  L678.9,636.7z M629.8,543.6l-3.4-5.6l-4.1,5.4l3.4,5.6L629.8,543.6z"/>
                <path fill="#0B99D6" d="M670.8,647.6l4.1-5.4l-3.4-5.6l-4.1,5.4L670.8,647.6
                  L670.8,647.6z M621.6,554.5l-3.4-5.6l-4.1,5.4l3.4,5.6L621.6,554.5z"/>
                <path fill="#0B99D6" d="M662,657.6l0.8,0.6l3.9-5.1l-3.4-5.6l-1.5,2l-8.5-6.4
                  L662,657.6L662,657.6z M613.4,565.4l-3.4-5.6l-4.1,5.4l3.4,5.6L613.4,565.4z"/>
                <path fill="#0B99D6" d="M634.8,637.1l13.6,10.2l-8.8-14.5L626,622.7L634.8,637.1
                  L634.8,637.1z M605.3,576.3l-3.4-5.6l-4.1,5.4l3.4,5.6L605.3,576.3z"/>
                <path fill="#0B99D6" d="M607.5,616.7l13.6,10.2l-8.8-14.5l-13.6-10.2L607.5,616.7
                  L607.5,616.7z M597.1,587.2l-3.4-5.6l-4.1,5.4l3.4,5.6L597.1,587.2z"/>
                <polygon fill="#0B99D6" points="585.5,592.6 593.9,606.4 581.9,597.4           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M684.5,595.2c1.9-2.6,2.6-5.7,2.2-8.7c-0.4-3-2-5.8-4.5-7.7
                c-2.6-1.9-5.7-2.6-8.7-2.2c-3,0.4-5.8,2-7.7,4.5c-1.9,2.6-2.6,5.7-2.2,8.7c0.4,3,2,5.8,4.5,7.7c2.6,1.9,5.7,2.6,8.7,2.2
                C679.8,599.3,682.6,597.7,684.5,595.2L684.5,595.2z M669.3,596c-2.2-1.6-3.5-4-3.8-6.5c-0.4-2.5,0.2-5.1,1.9-7.3
                c1.6-2.2,4-3.5,6.5-3.8c2.5-0.4,5.1,0.2,7.3,1.9c2.2,1.6,3.5,4,3.8,6.5c0.4,2.5-0.2,5.1-1.9,7.3c-1.6,2.2-4,3.5-6.5,3.8
                S671.5,597.6,669.3,596z"/>
              <path fill="#80CAF0" d="M678.1,592c0,0,2.8,6.9-2.6,8.7c-2.2,0.8-3.6,1.1-4.5,1.3
                c-1.9,0.5-2.2,0.6-2.8,2c-0.3,0.8-0.2,1.6,0,2.7c0.4,1.9,0.8,4.5-1.6,7.6c-2,2.6-3.1,2.6-4.5,2.6c-1.2,0-2.6,0-4.8,1.4
                c-2.5,1.6-3.6,3.4-3.9,5.1c-0.3,1.7,0.1,3.4,0.6,4.6c0.8,2-0.8,4.1-0.8,4.1c-0.2,0.3-0.2,0.7,0.1,0.9s0.7,0.1,0.9-0.1
                c0,0,2-2.7,1-5.3c-0.4-1.1-0.8-2.5-0.5-4c0.3-1.4,1.2-3,3.3-4.3c1.9-1.2,3.1-1.2,4.2-1.2c1.7,0,3,0,5.4-3.1
                c2.8-3.6,2.3-6.5,1.9-8.6c-0.2-0.8-0.3-1.5-0.1-2c0.4-0.9,0.6-0.9,2-1.3c1-0.2,2.4-0.6,4.6-1.4c6.6-2.3,3.3-10.3,3.3-10.4
                c-0.1-0.3-0.5-0.5-0.8-0.3C678.1,591.3,678,591.7,678.1,592L678.1,592z M668.9,585.1c-0.1-0.3,0-0.7,0.3-0.8s0.7,0,0.8,0.3
                c0,0,3.3,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.2,0.4,0,1.1,0.1,2c0.4,2.1,0.9,5-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.1,0-2.3,0-4.2,1.2c-2.2,1.3-3.1,2.9-3.3,4.3c-0.3,1.5,0.1,2.9,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1c-0.3-0.2-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.5-1.2-0.9-2.9-0.6-4.6c0.3-1.8,1.4-3.6,3.9-5.1
                c2.2-1.3,3.6-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c2.5-3.1,2-5.7,1.6-7.6c-0.2-1.1-0.4-1.9,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3C671.7,592,668.9,585.2,668.9,585.1L668.9,585.1z M671.2,586.9c-0.1-0.3,0-0.7,0.3-0.8s0.7,0,0.8,0.3
                c0,0,3.3,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.2,0.4,0,1.1,0.1,2c0.4,2.1,0.9,5-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.1,0-2.3,0-4.2,1.2c-2.2,1.3-3.1,2.9-3.3,4.3c-0.3,1.5,0.1,2.9,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1c-0.3-0.2-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.5-1.2-0.9-2.9-0.6-4.6c0.3-1.8,1.4-3.6,3.9-5.1
                c2.2-1.3,3.6-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c2.5-3.1,2-5.7,1.6-7.6c-0.2-1.1-0.4-1.9,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3C674,593.8,671.2,586.9,671.2,586.9L671.2,586.9z M673.5,588.6c-0.1-0.3,0-0.7,0.3-0.8s0.7,0,0.8,0.3
                c0,0,3.3,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.2,0.4,0,1.1,0.1,2c0.4,2.1,0.9,5-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.1,0-2.3,0-4.2,1.2c-2.2,1.3-3.1,2.9-3.3,4.3c-0.3,1.5,0.1,2.9,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1c-0.3-0.2-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.5-1.2-0.9-2.9-0.6-4.6c0.3-1.8,1.4-3.6,3.9-5.1
                c2.2-1.3,3.6-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c2.5-3.1,2-5.7,1.6-7.6c-0.2-1.1-0.4-1.9,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3C676.3,595.5,673.5,588.6,673.5,588.6L673.5,588.6z M676.1,589.5c0.3-0.1,0.7,0,0.8,0.3
                c0,0,3.3,8.1-3.3,10.4c-2.2,0.8-3.6,1.1-4.6,1.4c-1.4,0.4-1.6,0.4-2,1.3c-0.2,0.4,0,1.1,0.1,2c0.4,2.1,0.9,5-1.9,8.6
                c-2.4,3-3.7,3.1-5.4,3.1c-1.1,0-2.3,0-4.2,1.2c-2.2,1.3-3.1,2.9-3.3,4.3c-0.3,1.5,0.1,2.9,0.5,4c1,2.6-1,5.3-1,5.3
                c-0.2,0.3-0.6,0.3-0.9,0.1c-0.3-0.2-0.3-0.6-0.1-0.9c0,0,1.6-2.1,0.8-4.1c-0.5-1.2-0.9-2.9-0.6-4.6c0.3-1.8,1.4-3.6,3.9-5.1
                c2.2-1.3,3.6-1.4,4.8-1.4c1.3,0,2.4,0,4.5-2.6c2.5-3.1,2-5.7,1.6-7.6c-0.2-1.1-0.4-1.9,0-2.7c0.6-1.4,0.9-1.5,2.8-2
                c0.9-0.2,2.3-0.6,4.5-1.3c5.3-1.8,2.6-8.7,2.6-8.7C675.7,590,675.8,589.6,676.1,589.5z"/>
            </g>

            <rect x="693.3" y="539.2" transform="matrix(0.6005 -0.7996 0.7996 0.6005 -142.3505 795.4355)" fill="#A76C2B" width="63.1" height="1.9"/>
          </g>

          <rect x="688.3" y="535.4" transform="matrix(0.6005 -0.7996 0.7996 0.6005 -141.3331 789.8552)" fill="#A76C2B" width="63.1" height="1.9"/>
        </g>
        <g>

          <rect x="264.4" y="439.3" transform="matrix(0.5114 -0.8593 0.8593 0.5114 -303.1026 530.4969)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M261.3,441l35.9,83.1c5.2,12,19.5,20.8,31.8,19.7l91.4-8.2
            L261.3,441z"/>
          <g>
            <path fill="#0B99D6" d="M327.8,531.5c-3.6-2.1-7.7-2.6-11.5-1.7c-3.8,1-7.1,3.3-9.3,6.9
              c-2.1,3.6-2.6,7.7-1.7,11.5c1,3.8,3.3,7.1,6.9,9.3c3.6,2.1,7.7,2.6,11.5,1.7c3.8-1,7.1-3.3,9.3-6.9c2.1-3.6,2.6-7.7,1.7-11.5
              C333.7,537,331.3,533.6,327.8,531.5L327.8,531.5z M316.9,532.1c3.2-0.8,6.6-0.4,9.6,1.4s5,4.6,5.8,7.8s0.4,6.6-1.4,9.6
              s-4.6,5-7.8,5.8c-3.2,0.8-6.6,0.4-9.6-1.4c-3-1.8-5-4.6-5.8-7.8s-0.4-6.6,1.4-9.6C310.9,534.9,313.7,532.9,316.9,532.1z"/>
            <path fill="#0B99D6" d="M321.6,538.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8
              c0,0-0.1-11.3-9.1-10.8c-3,0.2-4.9,0.4-6.2,0.6c-1.8,0.3-2.2,0.3-3-0.5c-0.4-0.4-0.6-1.3-0.8-2.4c-0.6-2.7-1.3-6.5-6.5-9.4
              c-4.4-2.5-6-1.8-8-1c-1.3,0.5-2.7,1.1-5.6,0.6c-3.3-0.5-5.1-1.9-6.1-3.5c-1-1.6-1.3-3.5-1.3-5c-0.1-3.6-3.8-5.8-3.8-5.8
              c-0.4-0.2-0.9-0.1-1.1,0.3s-0.1,0.9,0.3,1.1c0,0,2.9,1.8,3,4.5c0,1.7,0.3,3.9,1.6,5.8c1.2,1.9,3.4,3.6,7.2,4.2
              c3.3,0.5,4.9-0.1,6.4-0.7c1.6-0.6,2.9-1.2,6.6,0.9c4.5,2.5,5.2,5.9,5.7,8.3c0.3,1.4,0.5,2.5,1.3,3.2c1.4,1.4,1.9,1.3,4.4,1
              c1.2-0.2,3.1-0.4,6.1-0.6C321.5,528.8,321.6,538.4,321.6,538.4L321.6,538.4z M314,551.2c0,0-0.1-9.6-7.4-9.2
              c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.8-0.7-1-1.9-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9
              c-1.5,0.6-3.2,1.3-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2s-1.5-4.1-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1
              c0.2-0.4,0.7-0.5,1.1-0.3c0,0,3.7,2.2,3.8,5.8c0,1.5,0.3,3.4,1.3,5s2.9,3,6.1,3.5c2.8,0.5,4.3-0.1,5.6-0.6c2-0.8,3.6-1.4,8,1
              c5.1,2.9,5.9,6.7,6.5,9.4c0.2,1.1,0.4,2,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8
              c0,0.4-0.3,0.8-0.8,0.8C314.3,552,314,551.6,314,551.2L314,551.2z M315.9,548c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6
              c-2.5,0.4-3,0.4-4.4-1c-0.8-0.7-1-1.9-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-1.5,0.6-3.2,1.3-6.4,0.7
              c-3.8-0.6-6-2.3-7.2-4.2s-1.5-4.1-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3c0,0,3.7,2.2,3.8,5.8
              c0,1.5,0.3,3.4,1.3,5s2.9,3,6.1,3.5c2.8,0.5,4.3-0.1,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.7,6.5,9.4c0.2,1.1,0.4,2,0.8,2.4
              c0.9,0.9,1.2,0.8,3,0.5c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.3,0.8-0.8,0.8
              C316.2,548.8,315.9,548.4,315.9,548L315.9,548z M317.8,544.8c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1
              c-0.8-0.8-1-1.9-1.3-3.2c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-1.5,0.6-3.2,1.3-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2
              s-1.5-4.1-1.6-5.8c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3c0,0,3.7,2.2,3.8,5.8c0,1.5,0.3,3.4,1.3,5
              s2.9,3,6.1,3.5c2.8,0.5,4.3-0.1,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.7,6.5,9.4c0.2,1.1,0.4,2,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5
              c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.3,0.8-0.8,0.8C318.1,545.6,317.8,545.2,317.8,544.8L317.8,544.8z
               M319.7,541.6c0,0-0.1-9.6-7.4-9.2c-3,0.2-4.8,0.4-6.1,0.6c-2.5,0.4-3,0.4-4.4-1c-0.8-0.8-1-1.9-1.3-3.2
              c-0.5-2.4-1.2-5.8-5.7-8.3c-3.7-2.1-5-1.6-6.6-0.9c-1.5,0.6-3.2,1.3-6.4,0.7c-3.8-0.6-6-2.3-7.2-4.2s-1.5-4.1-1.6-5.8
              c-0.1-2.8-3-4.5-3-4.5c-0.4-0.2-0.5-0.7-0.3-1.1s0.7-0.5,1.1-0.3c0,0,3.7,2.2,3.8,5.8c0,1.5,0.3,3.4,1.3,5c1,1.6,2.9,3,6.1,3.5
              c2.8,0.5,4.3-0.1,5.6-0.6c2-0.8,3.6-1.4,8,1c5.1,2.9,5.9,6.7,6.5,9.4c0.2,1.1,0.4,2,0.8,2.4c0.9,0.9,1.2,0.8,3,0.5
              c1.3-0.2,3.2-0.4,6.2-0.6c9-0.5,9.1,10.8,9.1,10.8c0,0.4-0.3,0.8-0.8,0.8C320,542.4,319.7,542,319.7,541.6z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M495.1,592.6l32.9-97.2l-177.8-60.3l-32.9,97.2L495.1,592.6
              L495.1,592.6z M325.3,528.6l29-85.6L520,499.2l-29,85.6L325.3,528.6z"/>

            <rect x="377.5" y="426.4" transform="matrix(0.3212 -0.947 0.947 0.3212 -199.8089 749.121)" fill="#D1CCE6" width="90.4" height="175"/>
            <g>
              <polygon fill="#0B99D6" points="487.3,590 501.6,573.2 496.1,589.5 493.8,592.2
                "/>
              <path fill="#0B99D6" d="M474.2,585.5l6.5,2.2l4.3-5l-6.5-2.2L474.2,585.5L474.2,585.5z
                 M496,569.8l11.1-13l5.5-16.3l-11.1,13L496,569.8z"/>
              <path fill="#0B99D6" d="M461.1,581.1l6.5,2.2l4.3-5l-6.5-2.2L461.1,581.1L461.1,581.1z
                 M507.1,537.1l11.1-13l5.5-16.3l-11.1,13L507.1,537.1z"/>
              <path fill="#0B99D6" d="M448,576.6l6.5,2.2l4.3-5l-6.5-2.2L448,576.6L448,576.6z
                 M520,499.2l-1.8,5.2l8.1-9.5l-6.5-2.2l-4.3,5L520,499.2z"/>
              <path fill="#0B99D6" d="M434.8,572.2l6.5,2.2l4.3-5l-6.5-2.2L434.8,572.2L434.8,572.2z
                 M509,495.4l4.3-5l-6.5-2.2l-4.3,5L509,495.4z"/>
              <path fill="#0B99D6" d="M421.7,567.7l6.5,2.2l4.3-5l-6.5-2.2L421.7,567.7L421.7,567.7z
                 M495.9,491l4.3-5l-6.5-2.2l-4.3,5L495.9,491z"/>
              <path fill="#0B99D6" d="M408.6,563.3l6.5,2.2l4.3-5l-6.5-2.2L408.6,563.3L408.6,563.3z
                 M482.8,486.5l4.3-5l-6.5-2.2l-4.3,5L482.8,486.5z"/>
              <path fill="#0B99D6" d="M395.5,558.9l6.5,2.2l4.3-5l-6.5-2.2L395.5,558.9L395.5,558.9z
                 M469.7,482.1l4.3-5l-6.5-2.2l-4.3,5L469.7,482.1z"/>
              <path fill="#0B99D6" d="M382.4,554.4l6.5,2.2l4.3-5l-6.5-2.2L382.4,554.4L382.4,554.4z
                 M456.6,477.7l4.3-5l-6.5-2.2l-4.3,5L456.6,477.7z"/>
              <path fill="#0B99D6" d="M369.3,550l6.5,2.2l4.3-5l-6.5-2.2L369.3,550L369.3,550z
                 M443.5,473.2l4.3-5l-6.5-2.2l-4.3,5L443.5,473.2z"/>
              <path fill="#0B99D6" d="M356.2,545.5l6.5,2.2l4.3-5l-6.5-2.2L356.2,545.5L356.2,545.5z
                 M430.3,468.8l4.3-5l-6.5-2.2l-4.3,5L430.3,468.8z"/>
              <path fill="#0B99D6" d="M343.1,541.1l6.5,2.2l4.3-5l-6.5-2.2L343.1,541.1L343.1,541.1z
                 M417.2,464.3l4.3-5l-6.5-2.2l-4.3,5L417.2,464.3z"/>
              <path fill="#0B99D6" d="M330,536.6l6.5,2.2l4.3-5l-6.5-2.2L330,536.6L330,536.6z
                 M404.1,459.9l4.3-5l-6.5-2.2l-4.3,5L404.1,459.9z"/>
              <path fill="#0B99D6" d="M317.6,531.4l-0.3,0.9l6.2,2.1l4.3-5l-2.4-0.8l3.5-10.2
                L317.6,531.4L317.6,531.4z M391,455.4l4.3-5l-6.5-2.2l-4.3,5L391,455.4z"/>
              <path fill="#0B99D6" d="M328.7,498.6l-5.5,16.3l11.1-13l5.5-16.3L328.7,498.6
                L328.7,498.6z M377.9,451l4.3-5l-6.5-2.2l-4.3,5L377.9,451z"/>
              <path fill="#0B99D6" d="M339.8,465.9l-5.5,16.3l11.1-13l5.5-16.3L339.8,465.9
                L339.8,465.9z M364.8,446.5l4.3-5l-6.5-2.2l-4.3,5L364.8,446.5z"/>
              <polygon fill="#0B99D6" points="356,437.1 345.3,449.5 350.2,435.1         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M425.8,476.5l-2.1-0.7l0,0c-0.3,0.8-1.1,1.2-1.8,0.9
              c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-1.4-0.5l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-1.2-0.4l0,0
              c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-1.2-0.4l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0
              l-1.2-0.4l0,0c-0.3,0.8-1.1,1.2-1.8,0.9c-0.8-0.3-1.2-1.1-0.9-1.8l0,0l-2.1-0.7l-0.6,1.7c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l-0.5,1.4c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8
              c-0.3,0.8-1.1,1.2-1.8,0.9l-0.4,1.2c0.8,0.3,1.2,1.1,0.9,1.8s-1.1,1.2-1.8,0.9l-0.6,1.7l2.1,0.7l0,0c0.3-0.8,1.1-1.2,1.8-0.9
              c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0
              c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.2,0.4l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l1.4,0.5
              l0,0c0.3-0.8,1.1-1.2,1.8-0.9c0.8,0.3,1.2,1.1,0.9,1.8l0,0l2.1,0.7l0.6-1.7c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9
              l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.5-1.4c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9l0.4-1.2
              c-0.8-0.3-1.2-1.1-0.9-1.8c0.3-0.8,1.1-1.2,1.8-0.9L425.8,476.5z"/>

            <rect x="394" y="480.4" transform="matrix(0.3212 -0.947 0.947 0.3212 -186.2434 719.293)" fill="#EF805F" width="29.3" height="18.3"/>
          </g>
          <polygon fill="#189AD7" points="494.1,519.3 451.6,489.7 449.5,497 491.1,524.9     "/>
          <polygon fill="#189AD7" points="488,527.9 445.4,498.3 443.3,505.6 484.9,533.5     "/>
          <polygon fill="#189AD7" points="480.7,538.8 455.6,521.5 454.4,525.7 478.9,542.2     "/>
        </g>
        <g>

          <rect x="449.9" y="456.1" transform="matrix(0.141 -0.99 0.99 0.141 -120.0698 985.2746)" fill="#96CBCC" width="115.6" height="211.5"/>
          <path fill="#8BB3B2" d="M411.1,489.7l74.5,71.8c10.7,10.3,29.7,13.4,42.2,6.8l92.7-48.8
            L411.1,489.7z"/>
          <g>
            <path fill="#0B99D6" d="M521,555.8c4.7,0.7,8.7,3.2,11.4,6.7s4,8.1,3.3,12.8
              s-3.2,8.7-6.7,11.4s-8.1,4-12.8,3.3s-8.7-3.2-11.4-6.7c-2.7-3.5-4-8.1-3.3-12.8s3.2-8.7,6.7-11.4
              C511.7,556.4,516.3,555.1,521,555.8L521,555.8z M504.2,570.9c-0.6,4,0.5,7.8,2.8,10.8c2.2,3,5.6,5.1,9.6,5.7
              c4,0.6,7.8-0.5,10.8-2.8c3-2.2,5.1-5.6,5.7-9.6c0.6-4-0.5-7.8-2.8-10.8c-2.2-3-5.6-5.1-9.6-5.7c-4-0.6-7.8,0.5-10.8,2.8
              C506.9,563.5,504.8,566.9,504.2,570.9z"/>
            <path fill="#0B99D6" d="M517.6,565.8c0,0-4.3-10.1-11.8-6.5c-3.1,1.5-4.9,2.6-6.1,3.3
              c-2.5,1.5-2.9,1.7-5.1,0.9c-1.1-0.5-1.9-1.5-2.8-2.8c-1.6-2.3-3.8-5.6-9.6-6.3c-4.8-0.6-6,0.6-7.4,1.9c-1.3,1.3-2.8,2.7-6.5,3.6
              c-4.3,1-7.3,0.2-9.5-1.3c-2.1-1.5-3.4-3.7-4.2-5.4c-1.3-2.9-5.2-3.4-5.2-3.4c-0.5-0.1-0.8-0.5-0.8-1c0.1-0.5,0.5-0.8,1-0.8
              c0,0,4.9,0.7,6.6,4.5c0.7,1.6,1.8,3.4,3.6,4.7c1.8,1.2,4.3,1.9,8,1c3.2-0.8,4.5-2,5.6-3.1c1.8-1.7,3.2-3.1,8.9-2.4
              c6.7,0.8,9.1,4.4,10.9,7c0.7,1.1,1.3,1.9,1.9,2.2c1.3,0.5,1.6,0.3,3.4-0.8c1.3-0.8,3.2-1.9,6.3-3.4c9.2-4.5,14.3,7.4,14.3,7.4
              c0.2,0.5,0,1-0.5,1.2C518.3,566.5,517.8,566.3,517.6,565.8L517.6,565.8z M515.2,582.6c0.2,0.5,0.7,0.7,1.2,0.5s0.7-0.7,0.5-1.2
              c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.1,1.3-3.4,0.8c-0.6-0.2-1.2-1.1-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7
              c-5.7-0.7-7.1,0.7-8.9,2.4c-1.1,1.1-2.4,2.4-5.6,3.1c-3.7,0.9-6.2,0.2-8-1c-1.8-1.3-2.9-3.1-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5
              c-0.5-0.1-1,0.3-1,0.8c-0.1,0.5,0.3,1,0.8,1c0,0,3.9,0.6,5.2,3.4c0.8,1.8,2.1,3.9,4.2,5.4c2.2,1.5,5.2,2.3,9.5,1.3
              c3.7-0.9,5.2-2.3,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8.1,3.9,9.6,6.3c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.6,0.6,5.1-0.9
              c1.2-0.7,3.1-1.8,6.1-3.3C510.9,572.5,515.2,582.6,515.2,582.6L515.2,582.6z M515.8,578.4c0.2,0.5,0.7,0.7,1.2,0.5
              s0.7-0.7,0.5-1.2c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.1,1.3-3.4,0.8c-0.6-0.2-1.2-1.1-1.9-2.2
              c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.1,1.1-2.4,2.4-5.6,3.1c-3.7,0.9-6.2,0.2-8-1c-1.8-1.3-2.9-3.1-3.6-4.7
              c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-1,0.3-1,0.8c-0.1,0.5,0.3,1,0.8,1c0,0,3.9,0.6,5.2,3.4c0.8,1.8,2.1,3.9,4.2,5.4
              c2.2,1.5,5.2,2.3,9.5,1.3c3.7-0.9,5.2-2.3,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8.1,3.9,9.6,6.3c0.9,1.3,1.6,2.4,2.8,2.8
              c2.1,0.9,2.6,0.6,5.1-0.9c1.2-0.7,3.1-1.8,6.1-3.3C511.5,568.3,515.8,578.4,515.8,578.4L515.8,578.4z M516.4,574.2
              c0.2,0.5,0.7,0.7,1.2,0.5s0.7-0.7,0.5-1.2c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.1,1.3-3.4,0.8
              c-0.6-0.2-1.2-1.1-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.1,1.1-2.4,2.4-5.6,3.1c-3.7,0.9-6.2,0.2-8-1
              c-1.8-1.3-2.9-3.1-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-1,0.3-1,0.8c-0.1,0.5,0.3,1,0.8,1c0,0,3.9,0.6,5.2,3.4
              c0.8,1.8,2.1,3.9,4.2,5.4c2.2,1.5,5.2,2.3,9.5,1.3c3.7-0.9,5.2-2.3,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8.1,3.9,9.6,6.3
              c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.6,0.6,5.1-0.9c1.2-0.7,3.1-1.8,6.1-3.3C512.1,564.1,516.4,574.2,516.4,574.2L516.4,574.2z
               M518.2,570.5c0.5-0.2,0.7-0.7,0.5-1.2c0,0-5.1-11.9-14.3-7.4c-3.1,1.5-5,2.6-6.3,3.4c-1.8,1.1-2.1,1.3-3.4,0.8
              c-0.6-0.2-1.2-1.1-1.9-2.2c-1.8-2.6-4.3-6.2-10.9-7c-5.7-0.7-7.1,0.7-8.9,2.4c-1.1,1.1-2.4,2.4-5.6,3.1c-3.7,0.9-6.2,0.2-8-1
              c-1.8-1.3-2.9-3.1-3.6-4.7c-1.7-3.8-6.6-4.5-6.6-4.5c-0.5-0.1-1,0.3-1,0.8c-0.1,0.5,0.3,1,0.8,1c0,0,3.9,0.6,5.2,3.4
              c0.8,1.8,2.1,3.9,4.2,5.4c2.2,1.5,5.2,2.3,9.5,1.3c3.7-0.9,5.2-2.3,6.5-3.6c1.4-1.4,2.6-2.5,7.4-1.9c5.8,0.7,8.1,3.9,9.6,6.3
              c0.9,1.3,1.6,2.4,2.8,2.8c2.1,0.9,2.6,0.6,5-0.9c1.2-0.7,3.1-1.8,6.1-3.3c7.5-3.6,11.8,6.4,11.8,6.5
              C517.2,570.5,517.7,570.7,518.2,570.5z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M455.2,701.4L692.3,625l-41.8-129.6l-237.1,76.5L455.2,701.4
            L455.2,701.4z M460.8,691.1L423.9,577l221-71.3l36.8,114.2L460.8,691.1z"/>

          <rect x="436.7" y="538.4" transform="matrix(0.9517 -0.307 0.307 0.9517 -156.997 198.6035)" fill="#D3AC89" width="232.2" height="119.9"/>
          <polygon fill="#0B99D6" points="465.6,698.1 446.8,675.5 453.9,697.3 456.8,700.9     "/>
          <path fill="#0B99D6" d="M483.1,692.5l-5.6-6.7l-8.7,2.8l5.6,6.7L483.1,692.5L483.1,692.5
            z M447.3,649.4l-14.5-17.5l7,21.8l14.5,17.5L447.3,649.4z"/>
          <path fill="#0B99D6" d="M500.5,686.8l-5.6-6.7l-8.7,2.8l5.6,6.7L500.5,686.8L500.5,686.8
            z M433.2,605.8l-14.5-17.5l7,21.8l14.5,17.5L433.2,605.8z"/>
          <path fill="#0B99D6" d="M518,681.2l-5.6-6.7l-8.7,2.8l5.6,6.7L518,681.2L518,681.2z
             M429.9,575.1l-5.6-6.7l-8.7,2.8l10.6,12.8l-2.2-7L429.9,575.1z"/>
          <path fill="#0B99D6" d="M535.5,675.5l-5.6-6.7l-8.7,2.8l5.6,6.7L535.5,675.5L535.5,675.5
            z M447.3,569.4l-5.6-6.7l-8.7,2.8l5.6,6.7L447.3,569.4z"/>
          <path fill="#0B99D6" d="M553,669.9l-5.6-6.7l-8.7,2.8l5.6,6.7L553,669.9L553,669.9z
             M464.8,563.8l-5.6-6.7l-8.7,2.8l5.6,6.7L464.8,563.8z"/>
          <path fill="#0B99D6" d="M570.5,664.3l-5.6-6.7l-8.7,2.8l5.6,6.7L570.5,664.3L570.5,664.3
            z M482.3,558.1l-5.6-6.7l-8.7,2.8l5.6,6.7L482.3,558.1z"/>
          <path fill="#0B99D6" d="M587.9,658.6l-5.6-6.7l-8.7,2.8l5.6,6.7L587.9,658.6L587.9,658.6
            z M499.8,552.5l-5.6-6.7l-8.7,2.8l5.6,6.7L499.8,552.5z"/>
          <path fill="#0B99D6" d="M605.4,653l-5.6-6.7l-8.7,2.8l5.6,6.7L605.4,653L605.4,653z
             M517.3,546.9l-5.6-6.7L503,543l5.6,6.7L517.3,546.9z"/>
          <path fill="#0B99D6" d="M622.9,647.4l-5.6-6.7l-8.7,2.8l5.6,6.7L622.9,647.4L622.9,647.4
            z M534.8,541.2l-5.6-6.7l-8.7,2.8l5.6,6.7L534.8,541.2z"/>
          <path fill="#0B99D6" d="M640.4,641.7l-5.6-6.7l-8.7,2.8l5.6,6.7L640.4,641.7L640.4,641.7
            z M552.2,535.6l-5.6-6.7l-8.7,2.8l5.6,6.7L552.2,535.6z"/>
          <path fill="#0B99D6" d="M657.9,636.1l-5.6-6.7l-8.7,2.8l5.6,6.7L657.9,636.1L657.9,636.1
            z M569.7,529.9l-5.6-6.7l-8.7,2.8l5.6,6.7L569.7,529.9z"/>
          <path fill="#0B99D6" d="M675.3,630.4l-5.6-6.7l-8.7,2.8l5.6,6.7L675.3,630.4L675.3,630.4
            z M587.2,524.3l-5.6-6.7l-8.7,2.8l5.6,6.7L587.2,524.3z"/>
          <path fill="#0B99D6" d="M691.9,623.7l-14.5-17.5l4.4,13.6l-3.3,1.1l5.6,6.7l8.2-2.7
            L691.9,623.7L691.9,623.7z M604.7,518.7l-5.6-6.7l-8.7,2.8l5.6,6.7L604.7,518.7z"/>
          <path fill="#0B99D6" d="M677.8,580.1l-14.5-17.5l7,21.8l14.5,17.5L677.8,580.1
            L677.8,580.1z M622.2,513l-5.6-6.7l-8.7,2.8l5.6,6.7L622.2,513z"/>
          <path fill="#0B99D6" d="M663.8,536.4l-14.5-17.5l7,21.8l14.5,17.5L663.8,536.4
            L663.8,536.4z M639.6,507.4l-5.6-6.7l-8.7,2.8l5.6,6.7L639.6,507.4z"/>
          <polygon fill="#0B99D6" points="642.8,497.8 656.7,514.6 650.5,495.4     "/>
          <g>
            <path fill="#80CAF0" d="M602.6,624.9c4.1-1.3,7.3-4.2,9.2-7.8c1.8-3.6,2.3-7.9,1-12
              s-4.2-7.3-7.8-9.2c-3.6-1.8-7.9-2.3-12-1s-7.3,4.2-9.2,7.8c-1.8,3.6-2.3,7.9-1,12s4.2,7.3,7.8,9.2
              C594.2,625.8,598.5,626.3,602.6,624.9L602.6,624.9z M609.5,616c-1.5,3-4.2,5.4-7.7,6.5s-7.1,0.7-10.1-0.8s-5.4-4.2-6.5-7.7
              s-0.7-7.1,0.8-10.1s4.2-5.4,7.7-6.5s7.1-0.7,10.1,0.8s5.4,4.2,6.5,7.7C611.5,609.4,611.1,613,609.5,616z"/>
            <path fill="#80CAF0" d="M601.4,615.4c-0.3-0.3-0.9-0.3-1.2,0.1
              c-0.3,0.3-0.3,0.9,0.1,1.2c0,0,8.9,7.7,14.6,0.3c1.9-2.5,3-4.1,3.8-5.3c1.1-1.6,1.2-1.9,2.5-2c0.6,0,1.4,0.4,2.4,1
              c2.5,1.4,6,3.4,11.7,1.4c4.9-1.7,5.5-3.4,6.3-5.5c0.5-1.3,1-2.9,3.3-4.8c2.7-2.2,5-2.7,6.9-2.4c2,0.3,3.6,1.4,4.8,2.4
              c2.9,2.4,7.2,1.1,7.2,1.1c0.4-0.1,0.7-0.6,0.5-1c-0.1-0.4-0.6-0.7-1-0.5c0,0-3.4,1.1-5.6-0.7c-1.4-1.1-3.3-2.4-5.6-2.8
              c-2.4-0.4-5.1,0.2-8.2,2.7c-2.7,2.2-3.3,3.9-3.8,5.5c-0.6,1.7-1.1,3.1-5.3,4.5c-5.1,1.8-8.1,0-10.4-1.3c-1.3-0.7-2.3-1.3-3.4-1.2
              c-2.1,0.1-2.3,0.5-3.8,2.7c-0.7,1.1-1.8,2.7-3.7,5.1C609,621.9,601.5,615.4,601.4,615.4L601.4,615.4z M596.7,600.7
              c0,0,7.6,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7c1.1-0.1,2.1,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3
              c4.2-1.4,4.7-2.8,5.3-4.5c0.6-1.5,1.2-3.3,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.3,0.4,4.2,1.6,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7
              c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.3,1.4-7.2-1.1c-1.2-1-2.8-2.1-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4
              c-2.3,1.9-2.9,3.4-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-1-0.6-1.8-1-2.4-1c-1.3,0.1-1.4,0.4-2.5,2
              c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2C595.8,600.4,596.4,600.4,596.7,600.7
              L596.7,600.7z M597.9,604.4c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7c1.1-0.1,2.1,0.5,3.4,1.2
              c2.2,1.3,5.3,3,10.4,1.3c4.2-1.4,4.7-2.8,5.3-4.5c0.6-1.5,1.2-3.3,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7c2.3,0.4,4.2,1.6,5.6,2.8
              c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.3,1.4-7.2-1c-1.2-1-2.8-2.1-4.8-2.4
              c-1.9-0.3-4.3,0.2-6.9,2.4c-2.3,1.9-2.9,3.4-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4c-1-0.6-1.8-1-2.4-1
              c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2
              C597,604.1,597.5,604.1,597.9,604.4L597.9,604.4z M599.1,608c0,0,7.6,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7
              c1.1-0.1,2.1,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3c4.2-1.4,4.7-2.8,5.3-4.5c0.6-1.5,1.2-3.3,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7
              c2.3,0.4,4.2,1.6,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.3,1.4-7.2-1
              c-1.2-1-2.8-2.1-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4c-2.3,1.9-2.9,3.4-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4
              c-1-0.6-1.8-1.1-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2
              C598.2,607.8,598.7,607.7,599.1,608L599.1,608z M600.3,611.7c0,0,7.5,6.5,12.2,0.6c1.9-2.4,3-4,3.7-5.1c1.5-2.2,1.7-2.6,3.8-2.7
              c1.1-0.1,2.1,0.5,3.4,1.2c2.2,1.3,5.3,3,10.4,1.3c4.2-1.4,4.7-2.8,5.3-4.5c0.6-1.5,1.2-3.3,3.8-5.5c3.1-2.5,5.9-3.1,8.2-2.7
              c2.4,0.4,4.2,1.6,5.6,2.8c2.2,1.8,5.6,0.7,5.6,0.7c0.4-0.1,0.9,0.1,1,0.5s-0.1,0.9-0.5,1c0,0-4.3,1.4-7.2-1.1
              c-1.2-1-2.8-2.1-4.8-2.4c-1.9-0.3-4.3,0.2-6.9,2.4c-2.3,1.9-2.9,3.4-3.3,4.8c-0.8,2.1-1.4,3.8-6.3,5.5c-5.8,2-9.2,0-11.7-1.4
              c-1-0.6-1.8-1.1-2.4-1c-1.3,0.1-1.4,0.4-2.5,2c-0.7,1.1-1.8,2.8-3.8,5.3c-5.8,7.3-14.6-0.3-14.6-0.3c-0.3-0.3-0.4-0.8-0.1-1.2
              C599.4,611.4,599.9,611.4,600.3,611.7z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M569.6,553.2l2-0.7l0,0c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l0,0l1.3-0.4l0,0c0.2,0.8,1,1.2,1.8,0.9
              c0.8-0.2,1.2-1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1,1.2,1.8,0.9
              c0.8-0.2,1.2-1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l0,0l2-0.7l0.5,1.7c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0.4,1.3c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0.4,1.2c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0.5,1.7l-2,0.7l0,0c-0.2-0.8-1-1.2-1.8-0.9
              c-0.8,0.2-1.2,1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l0,0l-1.2,0.4l0,0
              c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l0,0l-1.3,0.4
              l0,0c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l0,0l-2,0.7l-0.5-1.7c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.3c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l-0.4-1.2
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9L569.6,553.2z"/>

            <rect x="577.3" y="551.9" transform="matrix(0.9517 -0.307 0.307 0.9517 -145.5499 207.3447)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M642.2,541.4l0.8,2.4l0,0c-0.9,0.3-1.4,1.3-1.1,2.1c0.3,0.9,1.3,1.4,2.2,1.1l0,0l0.5,1.6l0,0c-0.9,0.3-1.4,1.3-1.1,2.2
              c0.3,0.9,1.3,1.4,2.2,1.1l0,0l0.5,1.4l0,0c-0.9,0.3-1.4,1.3-1.1,2.1c0.3,0.9,1.3,1.4,2.2,1.1l0,0l0.5,1.4l0,0
              c-0.9,0.3-1.4,1.3-1.1,2.2c0.3,0.9,1.3,1.4,2.2,1.1l0,0l0.5,1.4l0,0c-0.9,0.3-1.4,1.3-1.1,2.2c0.3,0.9,1.3,1.4,2.2,1.1l0,0
              l0.8,2.4l-2,0.6c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.2l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1
              l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1
              l-1.6,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1
              l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1l-1.4,0.5c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.1
              l-2,0.6l-0.8-2.4l0,0c0.9-0.3,1.4-1.3,1.1-2.1c-0.3-0.9-1.3-1.4-2.2-1.1l0,0l-0.5-1.4l0,0c0.9-0.3,1.4-1.3,1.1-2.2
              c-0.3-0.9-1.3-1.4-2.2-1.1l0,0l-0.5-1.4l0,0c0.9-0.3,1.4-1.3,1.1-2.2c-0.3-0.9-1.3-1.4-2.2-1.1l0,0l-0.5-1.4l0,0
              c0.9-0.3,1.4-1.3,1.1-2.1c-0.3-0.9-1.3-1.4-2.2-1.1l0,0l-0.5-1.6l0,0c0.9-0.3,1.4-1.3,1.1-2.1c-0.3-0.9-1.3-1.4-2.2-1.1l0,0
              l-0.8-2.4l2-0.6c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2
              l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.6-0.5
              c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.4-0.5
              c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.2l1.4-0.5c0.3,0.9,1.3,1.4,2.2,1.1c0.9-0.3,1.4-1.3,1.1-2.1L642.2,541.4z"/>

            <rect x="609.3" y="550.5" transform="matrix(0.9517 -0.3071 0.3071 0.9517 -142.0856 219.5184)" fill="#FBD4A4" width="34.4" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M622.7,520.7l0.7,2l0,0c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0,0l0.4,1.3l0,0c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0,0l0.4,1.2l0,0c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0,0l0.4,1.2l0,0c-0.8,0.2-1.2,1-0.9,1.8
              c0.2,0.8,1,1.2,1.8,0.9l0,0l0.4,1.2l0,0c-0.8,0.2-1.2,1-0.9,1.8c0.2,0.8,1,1.2,1.8,0.9l0,0l0.7,2l-1.7,0.5
              c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4
              c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.3,0.4
              c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4
              c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.2,0.4c-0.2-0.8-1-1.2-1.8-0.9c-0.8,0.2-1.2,1-0.9,1.8l-1.7,0.5l-0.7-2l0,0
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.4-1.2l0,0c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.4-1.2l0,0
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.4-1.2l0,0c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.4-1.3l0,0
              c0.8-0.2,1.2-1,0.9-1.8c-0.2-0.8-1-1.2-1.8-0.9l0,0l-0.7-2l1.7-0.5c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4
              c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4
              c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.3-0.4c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4
              c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8l1.2-0.4
              c0.2,0.8,1,1.2,1.8,0.9c0.8-0.2,1.2-1,0.9-1.8L622.7,520.7z"/>

            <rect x="595.1" y="528.3" transform="matrix(0.9517 -0.3071 0.3071 0.9517 -135.5444 213.1544)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>
          <polygon fill="#189AD7" points="460.7,606.8 549.6,575.1 549.9,588.9 463.4,618.1     "/>
        </g>
        <g>
          <path fill="#E2070F" d="M675.3,580.1l7.6,249L819,825l-7.6-249L675.3,580.1L675.3,580.1z
             M683.6,588.3l119.9-3.6l7.1,232.1l-119.9,3.6L683.6,588.3z"/>

          <rect x="687.2" y="586.5" transform="matrix(0.9995 -3.046645e-02 3.046645e-02 0.9995 -21.0581 23.0884)" fill="#E0DCC1" width="119.9" height="232.2"/>
          <polygon fill="#0B99D6" points="675.6,591 702.5,579.3 679.6,580 675.3,581.9     "/>
          <path fill="#0B99D6" d="M676.2,609.4l8-3.5l-0.3-9.2l-8,3.5L676.2,609.4L676.2,609.4z
             M727.5,587l20.9-9.1l-22.9,0.7l-20.9,9.1L727.5,587z"/>
          <path fill="#0B99D6" d="M676.7,627.7l8-3.5l-0.3-9.2l-8,3.5L676.7,627.7L676.7,627.7z
             M773.3,585.6l20.9-9.1l-22.9,0.7l-20.9,9.1L773.3,585.6z"/>
          <path fill="#0B99D6" d="M677.3,646.1l8-3.5l-0.3-9.2l-8,3.5L677.3,646.1L677.3,646.1z
             M803.7,590.9l8-3.5l-0.3-9.2l-15.2,6.6l7.3-0.2L803.7,590.9z"/>
          <path fill="#0B99D6" d="M677.9,664.5l8-3.5l-0.3-9.2l-8,3.5L677.9,664.5L677.9,664.5z
             M804.3,609.3l8-3.5l-0.3-9.2l-8,3.5L804.3,609.3z"/>
          <path fill="#0B99D6" d="M678.4,682.8l8-3.5l-0.3-9.2l-8,3.5L678.4,682.8L678.4,682.8z
             M804.8,627.7l8-3.5l-0.3-9.2l-8,3.5L804.8,627.7z"/>
          <path fill="#0B99D6" d="M679,701.2l8-3.5l-0.3-9.2l-8,3.5L679,701.2L679,701.2z
             M805.4,646l8-3.5l-0.3-9.2l-8,3.5L805.4,646z"/>
          <path fill="#0B99D6" d="M679.5,719.5l8-3.5l-0.3-9.2l-8,3.5L679.5,719.5L679.5,719.5z
             M806,664.4l8-3.5l-0.3-9.2l-8,3.5L806,664.4z"/>
          <path fill="#0B99D6" d="M680.1,737.9l8-3.5l-0.3-9.2l-8,3.5L680.1,737.9L680.1,737.9z
             M806.5,682.7l8-3.5l-0.3-9.2l-8,3.5L806.5,682.7z"/>
          <path fill="#0B99D6" d="M680.6,756.3l8-3.5l-0.3-9.2l-8,3.5L680.6,756.3L680.6,756.3z
             M807.1,701.1l8-3.5l-0.3-9.2l-8,3.5L807.1,701.1z"/>
          <path fill="#0B99D6" d="M681.2,774.6l8-3.5l-0.3-9.2l-8,3.5L681.2,774.6L681.2,774.6z
             M807.6,719.5l8-3.5l-0.3-9.2l-8,3.5L807.6,719.5z"/>
          <path fill="#0B99D6" d="M681.8,793l8-3.5l-0.3-9.2l-8,3.5L681.8,793L681.8,793z
             M808.2,737.8l8-3.5l-0.3-9.2l-8,3.5L808.2,737.8z"/>
          <path fill="#0B99D6" d="M682.3,811.3l8-3.5l-0.3-9.2l-8,3.5L682.3,811.3L682.3,811.3z
             M808.8,756.2l8-3.5l-0.3-9.2l-8,3.5L808.8,756.2z"/>
          <path fill="#0B99D6" d="M684.2,829.1l20.9-9.1l-14.3,0.4l-0.1-3.4l-8,3.5l0.3,8.6H684.2
            L684.2,829.1z M809.3,774.5l8-3.5l-0.3-9.2l-8,3.5L809.3,774.5z"/>
          <path fill="#0B99D6" d="M730,827.7l20.9-9.1l-22.9,0.7l-20.9,9.1L730,827.7L730,827.7z
             M809.9,792.9l8-3.5l-0.3-9.2l-8,3.5L809.9,792.9z"/>
          <g>
            <path fill="#80CAF0" d="M707.8,743c0.1,4.3,2,8.2,4.9,11s6.9,4.4,11.2,4.3
              c4.3-0.1,8.2-2,11-4.9s4.4-6.9,4.3-11.2c-0.1-4.3-2-8.2-4.9-11s-6.9-4.4-11.2-4.3c-4.3,0.1-8.2,2-11,4.9S707.7,738.7,707.8,743
              L707.8,743z M714.5,752.2c-2.5-2.3-4-5.6-4.2-9.2c-0.1-3.6,1.3-7,3.6-9.5s5.6-4,9.2-4.2c3.6-0.1,7,1.3,9.5,3.6s4,5.6,4.2,9.2
              s-1.3,7-3.6,9.5s-5.6,4-9.2,4.2C720.3,755.8,716.9,754.5,714.5,752.2z"/>
            <path fill="#80CAF0" d="M717.3,744.5c0.4-0.3,0.5-0.8,0.2-1.1c-0.3-0.4-0.8-0.5-1.1-0.2
              c0,0-9.8,6.4-4.4,14c1.8,2.5,3.1,4.1,4,5.1c1.3,1.5,1.5,1.7,1.2,3c-0.1,0.6-0.8,1.3-1.6,2.1c-2.1,2-4.9,4.8-4.6,10.9
              c0.3,5.2,1.8,6.3,3.6,7.6c1.2,0.8,2.5,1.8,3.7,4.5c1.4,3.2,1.2,5.5,0.3,7.3c-0.8,1.8-2.4,3.1-3.6,3.9c-3.1,2.1-3,6.6-3,6.6
              c0,0.5,0.4,0.8,0.9,0.8s0.8-0.4,0.8-0.9c0,0-0.1-3.6,2.3-5.2c1.5-1,3.2-2.5,4.2-4.6c1-2.2,1.3-5-0.3-8.7
              c-1.4-3.2-2.9-4.3-4.2-5.2c-1.5-1.1-2.6-1.9-2.9-6.3c-0.3-5.3,2.3-7.8,4.1-9.6c1-1,1.9-1.8,2.1-2.9c0.4-2,0.1-2.4-1.6-4.4
              c-0.9-1-2.1-2.5-3.9-5C708.9,750,717.3,744.6,717.3,744.5L717.3,744.5z M732.7,744.1c0,0-8.4,5.4-3.9,11.6c1.8,2.5,3.1,4,3.9,5
              c1.7,2,2,2.4,1.6,4.4c-0.2,1.1-1.1,1.9-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6c0.2,4.4,1.4,5.3,2.9,6.3c1.3,1,2.9,2.1,4.2,5.2
              c1.6,3.7,1.3,6.5,0.3,8.7s-2.8,3.6-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.3-0.9-0.8
              c0,0-0.1-4.5,3-6.6c1.3-0.9,2.8-2.1,3.6-3.9c0.8-1.8,1-4.2-0.3-7.3c-1.2-2.7-2.5-3.7-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6
              c-0.3-6.1,2.5-8.9,4.6-10.9c0.8-0.8,1.5-1.5,1.6-2.1c0.3-1.2,0.1-1.5-1.2-3c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14
              c0.4-0.3,0.9-0.1,1.1,0.2C733.2,743.3,733.1,743.8,732.7,744.1L732.7,744.1z M728.9,744.2c0,0-8.4,5.4-4,11.6
              c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.4,1.6,4.4c-0.2,1.1-1.1,1.9-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6c0.2,4.4,1.4,5.3,2.9,6.3
              c1.3,1,2.9,2.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7s-2.8,3.6-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2c0,0.5-0.3,0.8-0.8,0.9
              c-0.5,0-0.8-0.3-0.9-0.8c0,0-0.1-4.5,3-6.6c1.3-0.9,2.8-2.1,3.6-3.9c0.8-1.8,1-4.2-0.3-7.3c-1.2-2.7-2.5-3.7-3.7-4.5
              c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.8-0.8,1.5-1.5,1.6-2.1c0.3-1.2,0.1-1.5-1.2-3c-0.9-1-2.2-2.5-4-5.1
              c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.1,1.1,0.2C729.4,743.4,729.3,743.9,728.9,744.2L728.9,744.2z M725,744.3
              c0,0-8.4,5.4-4,11.6c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.4,1.6,4.4c-0.2,1.1-1.1,1.9-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6
              c0.2,4.4,1.4,5.3,2.9,6.3c1.3,1,2.9,2.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7s-2.8,3.6-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2
              c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.3-0.9-0.8c0,0-0.1-4.5,3-6.6c1.3-0.9,2.8-2.1,3.6-3.9c0.8-1.8,1-4.2-0.3-7.3
              c-1.2-2.7-2.5-3.7-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.8-0.8,1.5-1.5,1.6-2.1c0.3-1.2,0.1-1.5-1.2-3
              c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.1,1.1,0.2C725.5,743.5,725.4,744.1,725,744.3L725,744.3z
               M721.2,744.4c0,0-8.4,5.4-3.9,11.6c1.8,2.5,3.1,4,3.9,5c1.7,2,2,2.4,1.6,4.4c-0.2,1.1-1.1,1.9-2.1,2.9c-1.8,1.8-4.4,4.3-4.1,9.6
              c0.2,4.4,1.4,5.3,2.9,6.3c1.3,1,2.9,2.1,4.2,5.2c1.6,3.7,1.3,6.5,0.3,8.7s-2.8,3.6-4.2,4.6c-2.4,1.6-2.3,5.2-2.3,5.2
              c0,0.5-0.3,0.8-0.8,0.9c-0.5,0-0.8-0.3-0.9-0.8c0,0-0.1-4.5,3-6.6c1.3-0.9,2.8-2.1,3.6-3.9c0.8-1.8,1-4.2-0.3-7.3
              c-1.2-2.7-2.5-3.7-3.7-4.5c-1.8-1.3-3.3-2.4-3.6-7.6c-0.3-6.1,2.5-8.9,4.6-10.9c0.8-0.8,1.5-1.5,1.6-2.1c0.3-1.2,0-1.5-1.2-3
              c-0.9-1-2.2-2.5-4-5.1c-5.5-7.6,4.4-13.9,4.4-14c0.4-0.3,0.9-0.1,1.1,0.2C721.7,743.7,721.6,744.2,721.2,744.4z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M785.9,731.2l0.1,2.1l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.4l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2
              l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2l0,0c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0v1.2l0,0
              c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4l0,0l0.1,2.1l-1.7,0.1c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2
              c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4
              s-1.4,0.7-1.4,1.5h-1.4c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5h-1.2
              c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5H756c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l-1.7,0.1l-0.1-2.1l0,0
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.2l0,0
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.2l0,0c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0v-1.4l0,0
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l0,0l-0.1-2.1l1.7-0.1c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4
              s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.4
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5
              h1.2c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5L785.9,731.2z"/>

            <rect x="754.2" y="734.6" transform="matrix(0.9995 -3.046511e-02 3.046511e-02 0.9995 -22.2962 23.7613)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M777.1,804.3l-2.5,0.1l0,0c0-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.7,0.8-1.7,1.8l0,0l-1.7,0.1l0,0c0-0.9-0.8-1.7-1.8-1.7
              c-0.9,0-1.7,0.8-1.7,1.8l0,0h-1.5l0,0c0-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.7,0.8-1.7,1.8l0,0h-1.5l0,0c0-0.9-0.8-1.7-1.8-1.7
              c-0.9,0-1.7,0.8-1.7,1.8l0,0h-1.5l0,0c0-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.7,0.8-1.7,1.8l0,0l-2.5,0.1l-0.1-2.1
              c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7V798c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7V793c0.9,0,1.7-0.8,1.7-1.8
              c0-0.9-0.8-1.7-1.8-1.7V788c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7l-0.1-1.7c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7
              v-1.5c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7v-1.5c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7v-1.5
              c0.9,0,1.7-0.8,1.7-1.8c0-0.9-0.8-1.7-1.8-1.7l-0.1-2.1l2.5-0.1l0,0c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0h1.5l0,0
              c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0h1.5l0,0c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0h1.5l0,0
              c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0l1.7-0.1l0,0c0,0.9,0.8,1.7,1.8,1.7c0.9,0,1.7-0.8,1.7-1.8l0,0l2.6-0.1l0.1,2.1
              c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8
              c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7l0.1,1.7c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7
              v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7v1.5
              c-0.9,0-1.7,0.8-1.7,1.8c0,0.9,0.8,1.7,1.8,1.7V804.3z"/>

            <rect x="751.6" y="766.5" transform="matrix(0.9995 -3.038436e-02 3.038436e-02 0.9995 -23.4593 23.5245)" fill="#FBD4A4" width="21.5" height="34.4"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M802.4,791.3l-2.1,0.1l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0H796l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.2
              l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.2l0,0c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0h-1.2l0,0
              c0-0.8-0.7-1.4-1.5-1.4s-1.4,0.7-1.4,1.5l0,0l-2.1,0.1l-0.1-1.7c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4V778c0.8,0,1.4-0.7,1.4-1.5
              s-0.7-1.4-1.5-1.4v-1.4c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2
              c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4v-1.2c0.8,0,1.4-0.7,1.4-1.5s-0.7-1.4-1.5-1.4l-0.1-1.7l2.1-0.1l0,0
              c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.2l0,0c0,0.8,0.7,1.4,1.5,1.4
              s1.4-0.7,1.4-1.5l0,0h1.2l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0h1.4l0,0c0,0.8,0.7,1.4,1.5,1.4s1.4-0.7,1.4-1.5l0,0
              l2.1-0.1l0.1,1.7c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.2
              c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.4c-0.8,0-1.4,0.7-1.4,1.5
              s0.7,1.4,1.5,1.4v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.2c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4v1.2
              c-0.8,0-1.4,0.7-1.4,1.5s0.7,1.4,1.5,1.4L802.4,791.3z"/>

            <rect x="781" y="759.6" transform="matrix(0.9995 -3.037995e-02 3.037995e-02 0.9995 -23.1501 24.3565)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>

          <rect x="699.9" y="607.5" transform="matrix(0.9995 -3.046610e-02 3.046610e-02 0.9995 -19.5316 21.6662)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="788.9" y="600.3" transform="matrix(0.9995 -3.046710e-02 3.046710e-02 0.9995 -19.2715 24.375)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="778.5" y="600.9" transform="matrix(0.9995 -3.046710e-02 3.046710e-02 0.9995 -19.2947 24.0584)" fill="#236095" width="2.7" height="88.6"/>

          <rect x="767.7" y="600.7" transform="matrix(0.9995 -3.046710e-02 3.046710e-02 0.9995 -19.2936 23.7293)" fill="#236095" width="2.7" height="88.6"/>
        </g>
        <g>

          <rect x="979.3" y="535.6" transform="matrix(0.8963 -0.4434 0.4434 0.8963 -173.4483 523.3056)" fill="#FFFFFF" width="106" height="193.9"/>
          <path fill="#E6E6E5" d="M941.8,569.1l83.9,44.2c12.1,6.4,20.2,22,18,34.8l-15.9,94.8
            L941.8,569.1z"/>
        </g>
      </g>
      <g id="letters1">
        <g>
          <path fill="#A0D8F7" d="M913.9,518.7l-173.8,93.1l50.9,95l173.8-93.1L913.9,518.7
            L913.9,518.7z M911,527.5l44.8,83.7l-162,86.8L749,614.3L911,527.5z"/>

          <rect x="760.5" y="565.3" transform="matrix(0.8815 -0.4722 0.4722 0.8815 -188.3024 475.1223)" fill="#CCCCCE" width="183.8" height="94.9"/>
          <g>
            <polygon fill="#0B99D6" points="906.3,522.7 924.1,537.7 915.5,521.7 912.7,519.3
              "/>
            <path fill="#0B99D6" d="M893.5,529.6l5.3,4.4l6.4-3.4l-5.3-4.4L893.5,529.6L893.5,529.6
              z M927.4,558.1l13.8,11.6l-8.6-16l-13.8-11.6L927.4,558.1z"/>
            <path fill="#0B99D6" d="M880.7,536.5l5.3,4.4l6.4-3.4l-5.3-4.4L880.7,536.5L880.7,536.5
              z M944.5,590.1l13.8,11.6l-8.6-16L936,574.1L944.5,590.1z"/>
            <path fill="#0B99D6" d="M867.8,543.3l5.3,4.4l6.4-3.4l-5.3-4.4L867.8,543.3L867.8,543.3
              z M951.5,613.5l5.3,4.4l6.4-3.4l-10.1-8.5l2.7,5.1L951.5,613.5z"/>
            <path fill="#0B99D6" d="M855,550.2l5.3,4.4l6.4-3.4l-5.3-4.4L855,550.2L855,550.2z
               M938.7,620.4l5.3,4.4l6.4-3.4l-5.3-4.4L938.7,620.4z"/>
            <path fill="#0B99D6" d="M842.2,557.1l5.3,4.4l6.4-3.4l-5.3-4.4L842.2,557.1L842.2,557.1
              z M925.9,627.3l5.3,4.4l6.4-3.4l-5.3-4.4L925.9,627.3z"/>
            <path fill="#0B99D6" d="M829.4,563.9l5.3,4.4l6.4-3.4l-5.3-4.4L829.4,563.9L829.4,563.9
              z M913,634.1l5.3,4.4l6.4-3.4l-5.3-4.4L913,634.1z"/>
            <path fill="#0B99D6" d="M816.6,570.8l5.3,4.4l6.4-3.4l-5.3-4.4L816.6,570.8L816.6,570.8
              z M900.2,641l5.3,4.4l6.4-3.4l-5.3-4.4L900.2,641z"/>
            <path fill="#0B99D6" d="M803.8,577.7l5.3,4.4l6.4-3.4l-5.3-4.4L803.8,577.7L803.8,577.7
              z M887.4,647.8l5.3,4.4l6.4-3.4l-5.3-4.4L887.4,647.8z"/>
            <path fill="#0B99D6" d="M791,584.5l5.3,4.4l6.4-3.4l-5.3-4.4L791,584.5L791,584.5z
               M874.6,654.7l5.3,4.4l6.4-3.4l-5.3-4.4L874.6,654.7z"/>
            <path fill="#0B99D6" d="M778.1,591.4l5.3,4.4l6.4-3.4l-5.3-4.4L778.1,591.4L778.1,591.4
              z M861.8,661.6l5.3,4.4l6.4-3.4l-5.3-4.4L861.8,661.6z"/>
            <path fill="#0B99D6" d="M765.3,598.2l5.3,4.4l6.4-3.4l-5.3-4.4L765.3,598.2L765.3,598.2
              z M849,668.4l5.3,4.4l6.4-3.4l-5.3-4.4L849,668.4z"/>
            <path fill="#0B99D6" d="M752.5,605.1l5.3,4.4l6.4-3.4l-5.3-4.4L752.5,605.1L752.5,605.1
              z M836.2,675.3l5.3,4.4l6.4-3.4l-5.3-4.4L836.2,675.3z"/>
            <path fill="#0B99D6" d="M740.6,612.7l13.8,11.6l-5.4-10l2.4-1.3l-5.3-4.4l-6,3.2
              L740.6,612.7L740.6,612.7z M823.3,682.2l5.3,4.4l6.4-3.4l-5.3-4.4L823.3,682.2z"/>
            <path fill="#0B99D6" d="M757.7,644.7l13.8,11.6l-8.6-16l-13.8-11.6L757.7,644.7
              L757.7,644.7z M810.5,689l5.3,4.4l6.4-3.4l-5.3-4.4L810.5,689z"/>
            <path fill="#0B99D6" d="M774.8,676.7l13.8,11.6l-8.6-16l-13.8-11.6L774.8,676.7
              L774.8,676.7z M797.7,695.9l5.3,4.4l6.4-3.4l-5.3-4.4L797.7,695.9z"/>
            <polygon fill="#0B99D6" points="796.6,703.8 783.4,692.7 791,706.8       "/>
          </g>
        </g>
        <g>

          <rect x="383.2" y="503.6" transform="matrix(0.182 -0.9833 0.9833 0.182 -231.2943 913.7531)" fill="#96CBCC" width="100.8" height="184.5"/>
          <path fill="#8BB3B2" d="M533.4,563.1l-81.5,38.7c-11.7,5.6-28.3,2.8-36.8-6.1L352,529.5
            L533.4,563.1z"/>
          <g>
            <path fill="#0B99D6" d="M424.2,587.2c4.1,0.8,7.5,3.1,9.7,6.3s3.2,7.2,2.4,11.3
              c-0.8,4.1-3.1,7.5-6.3,9.7c-3.2,2.2-7.2,3.2-11.3,2.4c-4.1-0.8-7.5-3.1-9.7-6.3s-3.2-7.2-2.4-11.3c0.8-4.1,3.1-7.5,6.3-9.7
              S420.1,586.5,424.2,587.2L424.2,587.2z M431.9,594.9c-1.8-2.7-4.7-4.6-8.1-5.3c-3.4-0.6-6.8,0.2-9.5,2s-4.6,4.7-5.3,8.1
              s0.2,6.8,2,9.5s4.7,4.6,8.1,5.3c3.4,0.6,6.8-0.2,9.5-2s4.6-4.7,5.3-8.1C434.5,600.9,433.7,597.5,431.9,594.9z"/>
            <path fill="#0B99D6" d="M424.2,596.5c-0.3,0.3-0.8,0.4-1.1,0.1
              c-0.3-0.3-0.4-0.8-0.1-1.1c0,0,7.5-8.4,13.9-2.1c2.1,2.1,3.4,3.6,4.2,4.5c1.2,1.4,1.4,1.7,2.6,1.6c0.6,0,1.3-0.6,2.2-1.3
              c2.2-1.7,5.3-4,11-2.8c4.9,1,5.7,2.6,6.7,4.5c0.6,1.2,1.3,2.6,3.8,4.1c2.8,1.7,5.1,1.9,6.9,1.4s3.3-1.8,4.3-2.9
              c2.5-2.7,6.7-1.9,6.7-1.9c0.4,0.1,0.7,0.5,0.6,0.9s-0.5,0.7-0.9,0.6c0,0-3.4-0.6-5.2,1.4c-1.2,1.2-2.8,2.7-5,3.3
              s-4.9,0.5-8.2-1.6c-2.8-1.7-3.6-3.4-4.3-4.8c-0.8-1.5-1.4-2.8-5.6-3.7c-5-1.1-7.7,1-9.7,2.5c-1.1,0.8-2,1.5-3.1,1.6
              c-2,0.1-2.3-0.2-3.9-2.1c-0.8-1-2-2.4-4.1-4.4C430.6,589.3,424.3,596.5,424.2,596.5L424.2,596.5z M421.5,611
              c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5
              c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3c1.9-2,5.2-1.4,5.2-1.4
              c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4
              c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6
              c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1C420.7,611.4,421.2,611.4,421.5,611L421.5,611z
               M422.2,607.4c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1S421.9,607.7,422.2,607.4
              L422.2,607.4z M422.9,603.8c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6
              c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3
              c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9
              c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3
              c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1c-0.3,0.3-0.3,0.8,0.1,1.1
              C422.1,604.1,422.6,604.1,422.9,603.8L422.9,603.8z M423.6,600.1c0,0,6.4-7.2,11.6-2.1c2.1,2.1,3.3,3.5,4.1,4.4
              c1.7,1.9,1.9,2.2,3.9,2.1c1.1-0.1,2-0.7,3.1-1.6c2-1.5,4.7-3.5,9.7-2.5c4.1,0.9,4.8,2.1,5.6,3.7c0.7,1.4,1.5,3,4.3,4.8
              c3.2,2,6,2.2,8.2,1.6c2.2-0.6,3.8-2.1,5-3.3c1.9-2,5.2-1.4,5.2-1.4c0.4,0.1,0.9-0.2,0.9-0.6c0.1-0.4-0.2-0.9-0.6-0.9
              c0,0-4.2-0.8-6.7,1.9c-1,1.1-2.4,2.4-4.3,2.9c-1.8,0.5-4.1,0.4-6.9-1.4c-2.4-1.5-3.1-2.9-3.8-4.1c-1-1.9-1.8-3.5-6.7-4.5
              c-5.7-1.2-8.8,1.1-11,2.8c-0.9,0.7-1.6,1.2-2.2,1.3c-1.2,0.1-1.4-0.2-2.6-1.6c-0.8-1-2.1-2.4-4.2-4.5c-6.4-6.3-13.9,2.1-13.9,2.1
              c-0.3,0.3-0.3,0.8,0.1,1.1C422.8,600.5,423.3,600.4,423.6,600.1z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M169.2,538.6l125.1,105.1l-57.4,68.4L111.8,607L169.2,538.6
              L169.2,538.6z M170.1,546.3l-50.6,60.2l116.6,98l50.6-60.2L170.1,546.3z"/>

            <rect x="163.7" y="549.3" transform="matrix(0.6433 -0.7656 0.7656 0.6433 -406.4067 378.5885)" fill="#FBD4A4" width="78.7" height="152.3"/>
            <g>
              <polygon fill="#0B99D6" points="174.7,543.2 157.8,552.3 167.4,540.8 170.1,539.4
                "/>
              <path fill="#0B99D6" d="M183.9,551l-5.1,2.7l-4.6-3.9l5.1-2.7L183.9,551L183.9,551z
                 M151.6,568.3l-13.2,7l9.7-11.5l13.2-7L151.6,568.3z"/>
              <path fill="#0B99D6" d="M193.2,558.7l-5.1,2.7l-4.6-3.9l5.1-2.7L193.2,558.7
                L193.2,558.7z M132.2,591.3l-13.2,7l9.7-11.5l13.2-7L132.2,591.3z"/>
              <path fill="#0B99D6" d="M202.4,566.5l-5.1,2.7l-4.6-3.9l5.1-2.7L202.4,566.5
                L202.4,566.5z M122.6,609.2l-5.1,2.7l-4.6-3.9l9.6-5.1l-3.1,3.7L122.6,609.2z"/>
              <path fill="#0B99D6" d="M211.6,574.2l-5.1,2.7l-4.6-3.9l5.1-2.7L211.6,574.2
                L211.6,574.2z M131.8,616.9l-5.1,2.7l-4.6-3.9l5.1-2.7L131.8,616.9z"/>
              <path fill="#0B99D6" d="M220.8,582l-5.1,2.7l-4.6-3.9l5.1-2.7L220.8,582L220.8,582z
                 M141.1,624.7l-5.1,2.7l-4.6-3.9l5.1-2.7L141.1,624.7z"/>
              <path fill="#0B99D6" d="M230.1,589.7l-5.1,2.7l-4.6-3.9l5.1-2.7L230.1,589.7
                L230.1,589.7z M150.3,632.4l-5.1,2.7l-4.6-3.9l5.1-2.7L150.3,632.4z"/>
              <path fill="#0B99D6" d="M239.3,597.5l-5.1,2.7l-4.6-3.9l5.1-2.7L239.3,597.5
                L239.3,597.5z M159.5,640.2l-5.1,2.7l-4.6-3.9l5.1-2.7L159.5,640.2z"/>
              <path fill="#0B99D6" d="M248.5,605.2l-5.1,2.7l-4.6-3.9l5.1-2.7L248.5,605.2
                L248.5,605.2z M168.7,647.9l-5.1,2.7l-4.6-3.9l5.1-2.7L168.7,647.9z"/>
              <path fill="#0B99D6" d="M257.7,613l-5.1,2.7l-4.6-3.9l5.1-2.7L257.7,613L257.7,613z
                 M178,655.7l-5.1,2.7l-4.6-3.9l5.1-2.7L178,655.7z"/>
              <path fill="#0B99D6" d="M267,620.7l-5.1,2.7l-4.6-3.9l5.1-2.7L267,620.7L267,620.7z
                 M187.2,663.4l-5.1,2.7l-4.6-3.9l5.1-2.7L187.2,663.4z"/>
              <path fill="#0B99D6" d="M276.2,628.5l-5.1,2.7l-4.6-3.9l5.1-2.7L276.2,628.5
                L276.2,628.5z M196.4,671.2l-5.1,2.7l-4.6-3.9l5.1-2.7L196.4,671.2z"/>
              <path fill="#0B99D6" d="M285.4,636.2l-5.1,2.7l-4.6-3.9l5.1-2.7L285.4,636.2
                L285.4,636.2z M205.6,678.9l-5.1,2.7l-4.6-3.9l5.1-2.7L205.6,678.9z"/>
              <path fill="#0B99D6" d="M293.8,644.4l-13.2,7l6-7.2l-1.7-1.4l5.1-2.7l4.3,3.6
                L293.8,644.4L293.8,644.4z M214.8,686.7l-5.1,2.7l-4.6-3.9l5.1-2.7L214.8,686.7z"/>
              <path fill="#0B99D6" d="M274.5,667.5l-13.2,7L271,663l13.2-7L274.5,667.5L274.5,667.5z
                 M224.1,694.4l-5.1,2.7l-4.6-3.9l5.1-2.7L224.1,694.4z"/>
              <path fill="#0B99D6" d="M255.1,690.5l-13.2,7l9.7-11.5l13.2-7L255.1,690.5L255.1,690.5
                z M233.3,702.2l-5.1,2.7l-4.6-3.9l5.1-2.7L233.3,702.2z"/>
              <polygon fill="#0B99D6" points="232.9,708.7 245.4,702 236.9,712.2         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M239.9,621.6c2.2,1.8,3.4,4.4,3.6,7c0.2,2.6-0.5,5.3-2.4,7.5
              c-1.8,2.2-4.4,3.4-7,3.6c-2.6,0.2-5.3-0.5-7.5-2.4c-2.2-1.8-3.4-4.4-3.6-7c-0.2-2.6,0.5-5.3,2.4-7.5c1.8-2.2,4.4-3.4,7-3.6
              S237.7,619.8,239.9,621.6L239.9,621.6z M241.9,628.7c-0.2-2.2-1.2-4.3-3.1-5.9c-1.8-1.5-4.1-2.2-6.3-2s-4.3,1.2-5.9,3.1
              c-1.5,1.8-2.2,4.1-2,6.3s1.2,4.3,3.1,5.9c1.8,1.5,4.1,2.2,6.3,2s4.3-1.2,5.9-3.1C241.4,633.2,242.1,630.9,241.9,628.7z"/>
            <path fill="#80CAF0" d="M236.8,627.1c-0.3,0.1-0.6-0.1-0.7-0.3
              c-0.1-0.3,0.1-0.6,0.3-0.7c0,0,7.3-2.5,9,3.4c0.6,2,0.8,3.3,1,4.1c0.2,1.2,0.3,1.5,1,1.8c0.4,0.2,1,0.1,1.7,0
              c1.9-0.3,4.5-0.6,7.5,2.1c2.6,2.3,2.5,3.4,2.5,4.9c0,0.9-0.1,2,0.8,3.7c1.1,2,2.4,2.8,3.6,3.1c1.3,0.3,2.5,0,3.5-0.3
              c2.4-0.8,4.6,1.1,4.6,1.1c0.2,0.2,0.3,0.5,0.1,0.8c-0.2,0.2-0.5,0.3-0.8,0.1c0,0-1.8-1.5-3.6-0.9c-1.1,0.4-2.6,0.7-4.1,0.3
              s-3.1-1.4-4.3-3.7c-1.1-2-1-3.2-1-4.3c0.1-1.2,0.1-2.1-2.1-4c-2.6-2.3-4.9-2-6.6-1.8c-0.9,0.1-1.7,0.2-2.4-0.1
              c-1.2-0.6-1.3-0.9-1.6-2.6c-0.2-0.8-0.4-2.1-1-4C243,625,236.8,627.1,236.8,627.1L236.8,627.1z M230.3,634.9c0,0,6.2-2.1,7.6,2.7
              c0.6,1.9,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.7,0.3,1.4,0.2,2.4,0.1c1.7-0.2,4-0.5,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4
              s-0.1,2.3,1,4.3c1.2,2.3,2.8,3.3,4.3,3.7s3,0.1,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1
              c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1,0.3-2.2,0.6-3.5,0.3c-1.3-0.3-2.6-1.2-3.6-3.1c-0.9-1.7-0.9-2.8-0.8-3.7
              c0.1-1.5,0.1-2.7-2.5-4.9c-3-2.6-5.6-2.3-7.5-2.1c-0.8,0.1-1.4,0.2-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1
              c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7C229.7,634.8,230,635,230.3,634.9L230.3,634.9z M231.9,632.9
              c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.6,0.3,1.4,0.2,2.4,0.1c1.7-0.2,4-0.5,6.6,1.8
              c2.2,1.9,2.1,2.9,2.1,4s-0.1,2.3,1,4.3c1.2,2.3,2.8,3.3,4.3,3.7s3,0.1,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1
              c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1,0.3-2.2,0.6-3.5,0.3s-2.6-1.2-3.6-3.1c-0.9-1.7-0.9-2.8-0.8-3.7
              c0.1-1.5,0.1-2.7-2.5-4.9c-3-2.6-5.6-2.3-7.5-2.1c-0.8,0.1-1.4,0.2-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1
              c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7C231.3,632.9,231.6,633,231.9,632.9L231.9,632.9z M233.5,631
              c0,0,6.2-2.1,7.6,2.7c0.6,1.9,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.7,0.3,1.4,0.2,2.4,0.1c1.7-0.2,4-0.5,6.6,1.8
              c2.2,1.9,2.1,2.9,2.1,4s-0.1,2.3,1,4.3c1.2,2.3,2.8,3.3,4.3,3.7s3,0.1,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1
              c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1,0.3-2.2,0.6-3.5,0.3s-2.6-1.2-3.6-3.1c-0.9-1.7-0.9-2.8-0.8-3.7
              c0.1-1.5,0.1-2.7-2.5-4.9c-3-2.6-5.6-2.3-7.5-2.1c-0.8,0.1-1.4,0.2-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1
              c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7C233,630.9,233.3,631.1,233.5,631L233.5,631z M235.2,629c0,0,6.2-2.1,7.6,2.7
              c0.6,1.9,0.8,3.2,1,4c0.3,1.7,0.4,2,1.6,2.6c0.7,0.3,1.4,0.2,2.4,0.1c1.7-0.2,4-0.5,6.6,1.8c2.2,1.9,2.1,2.9,2.1,4
              s-0.1,2.3,1,4.3c1.2,2.3,2.8,3.3,4.3,3.7s3,0.1,4.1-0.3c1.8-0.6,3.6,0.9,3.6,0.9c0.2,0.2,0.6,0.2,0.8-0.1
              c0.2-0.2,0.2-0.6-0.1-0.8c0,0-2.2-1.9-4.6-1.1c-1,0.3-2.2,0.6-3.5,0.3s-2.6-1.2-3.6-3.1c-0.9-1.7-0.9-2.8-0.8-3.7
              c0.1-1.5,0.1-2.7-2.5-4.9c-3-2.6-5.6-2.3-7.5-2.1c-0.8,0.1-1.4,0.2-1.7,0c-0.7-0.4-0.8-0.6-1-1.8c-0.2-0.9-0.4-2.2-1-4.1
              c-1.7-5.9-8.9-3.4-9-3.4c-0.3,0.1-0.4,0.4-0.3,0.7C234.6,629,234.9,629.1,235.2,629z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#A0D8F7" d="M-42.8,487.2l169.1,101.4L71,681L-98.2,579.7L-42.8,487.2
              L-42.8,487.2z M-40.3,496.2l-48.8,81.4l157.7,94.5l48.8-81.4L-40.3,496.2z"/>

            <rect x="-33.4" y="492.2" transform="matrix(0.514 -0.8578 0.8578 0.514 -494.2248 295.9481)" fill="#FFFFFF" width="94.9" height="183.8"/>
            <g>
              <polygon fill="#0B99D6" points="-35.4,491.7 -53.9,505.7 -44.6,490.2 -41.6,487.9
                "/>
              <path fill="#0B99D6" d="M-22.9,499.1l-5.5,4.2l-6.2-3.7l5.5-4.2L-22.9,499.1
                L-22.9,499.1z M-58.2,525.9l-14.3,10.9l9.3-15.5l14.3-10.9L-58.2,525.9z"/>
              <path fill="#0B99D6" d="M-10.4,506.6l-5.5,4.2l-6.2-3.7l5.5-4.2L-10.4,506.6
                L-10.4,506.6z M-76.8,557.1L-91.2,568l9.3-15.5l14.3-10.9L-76.8,557.1z"/>
              <path fill="#0B99D6" d="M2,514.1l-5.5,4.2l-6.2-3.7l5.5-4.2L2,514.1L2,514.1z
                 M-84.9,580.1l-5.5,4.2l-6.2-3.7l10.5-8l-3,5L-84.9,580.1z"/>
              <path fill="#0B99D6" d="M14.5,521.5L9,525.7L2.8,522l5.5-4.2L14.5,521.5L14.5,521.5z
                 M-72.4,587.6l-5.5,4.2l-6.2-3.7l5.5-4.2L-72.4,587.6z"/>
              <path fill="#0B99D6" d="M27,529l-5.5,4.2l-6.2-3.7l5.5-4.2L27,529L27,529z M-60,595.1
                l-5.5,4.2l-6.2-3.7l5.5-4.2L-60,595.1z"/>
              <path fill="#0B99D6" d="M39.4,536.5l-5.5,4.2l-6.2-3.7l5.5-4.2L39.4,536.5L39.4,536.5z
                 M-47.5,602.6l-5.5,4.2l-6.2-3.7l5.5-4.2L-47.5,602.6z"/>
              <path fill="#0B99D6" d="M51.9,544l-5.5,4.2l-6.2-3.7l5.5-4.2L51.9,544L51.9,544z
                 M-35,610l-5.5,4.2l-6.2-3.7l5.5-4.2L-35,610z"/>
              <path fill="#0B99D6" d="M64.4,551.4l-5.5,4.2l-6.2-3.7l5.5-4.2L64.4,551.4L64.4,551.4z
                 M-22.6,617.5l-5.5,4.2l-6.2-3.7l5.5-4.2L-22.6,617.5z"/>
              <path fill="#0B99D6" d="M76.8,558.9l-5.5,4.2l-6.2-3.7l5.5-4.2L76.8,558.9L76.8,558.9z
                 M-10.1,625l-5.5,4.2l-6.2-3.7l5.5-4.2L-10.1,625z"/>
              <path fill="#0B99D6" d="M89.3,566.4l-5.5,4.2l-6.2-3.7l5.5-4.2L89.3,566.4L89.3,566.4z
                 M2.4,632.5l-5.5,4.2l-6.2-3.7l5.5-4.2L2.4,632.5z"/>
              <path fill="#0B99D6" d="M101.8,573.8l-5.5,4.2l-6.2-3.7l5.5-4.2L101.8,573.8
                L101.8,573.8z M14.9,639.9l-5.5,4.2l-6.2-3.7l5.5-4.2L14.9,639.9z"/>
              <path fill="#0B99D6" d="M114.2,581.3l-5.5,4.2l-6.2-3.7l5.5-4.2L114.2,581.3
                L114.2,581.3z M27.3,647.4l-5.5,4.2l-6.2-3.7l5.5-4.2L27.3,647.4z"/>
              <path fill="#0B99D6" d="M125.8,589.5l-14.3,10.9l5.8-9.7l-2.3-1.4l5.5-4.2l5.9,3.5
                L125.8,589.5L125.8,589.5z M39.8,654.9l-5.5,4.2l-6.2-3.7l5.5-4.2L39.8,654.9z"/>
              <path fill="#0B99D6" d="M107.2,620.6l-14.3,10.9l9.3-15.5l14.3-10.9L107.2,620.6
                L107.2,620.6z M52.3,662.3l-5.5,4.2l-6.2-3.7l5.5-4.2L52.3,662.3z"/>
              <path fill="#0B99D6" d="M88.5,651.7l-14.3,10.9l9.3-15.5l14.3-10.9L88.5,651.7
                L88.5,651.7z M64.7,669.8l-5.5,4.2l-6.2-3.7l5.5-4.2L64.7,669.8z"/>
              <polygon fill="#0B99D6" points="65.5,677.7 79.2,667.3 71,681         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M57.2,572.6c2.9,1.8,4.9,4.6,5.7,7.6c0.8,3.1,0.4,6.5-1.4,9.4
              c-1.8,2.9-4.6,4.9-7.6,5.7c-3.1,0.8-6.5,0.4-9.4-1.4c-2.9-1.8-4.9-4.6-5.7-7.6c-0.8-3.1-0.4-6.5,1.4-9.4c1.8-2.9,4.6-4.9,7.6-5.7
              C50.9,570.4,54.2,570.8,57.2,572.6L57.2,572.6z M60.9,580.7c-0.7-2.6-2.3-4.9-4.8-6.4s-5.3-1.8-7.9-1.2c-2.6,0.7-4.9,2.3-6.4,4.8
              s-1.8,5.3-1.2,7.9c0.7,2.6,2.3,4.9,4.8,6.4s5.3,1.8,7.9,1.2c2.6-0.7,4.9-2.3,6.4-4.8C61.3,586.1,61.6,583.3,60.9,580.7z"/>
            <path fill="#80CAF0" d="M54.6,579.7c-0.3,0.2-0.7,0-0.9-0.3s0-0.7,0.3-0.9
              c0,0,8.2-4.3,11.3,2.4c1.1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.5,0.1,1.2-0.1,2.1-0.3c2.2-0.7,5.2-1.6,9.3,1
              c3.5,2.2,3.7,3.6,3.9,5.4c0.1,1.1,0.3,2.4,1.7,4.3c1.7,2.2,3.4,2.9,4.9,3.1c1.6,0.1,3-0.5,4.1-1c2.7-1.3,5.7,0.5,5.7,0.5
              c0.3,0.2,0.4,0.6,0.2,0.9c-0.2,0.3-0.6,0.4-0.9,0.2c0,0-2.4-1.4-4.4-0.4c-1.3,0.6-2.9,1.3-4.8,1.1c-1.9-0.1-3.9-1.1-5.9-3.6
              c-1.7-2.2-1.8-3.6-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-3.6-2.3-6.3-1.5-8.2-0.9c-1.1,0.3-2,0.6-2.8,0.3c-1.6-0.5-1.7-0.8-2.4-2.8
              c-0.4-1-0.9-2.4-1.9-4.6C61.6,576,54.6,579.7,54.6,579.7L54.6,579.7z M48.3,590.2c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6
              c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9
              c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9
              c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
              c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
              c-0.3,0.2-0.4,0.6-0.3,0.9C47.6,590.2,48,590.4,48.3,590.2L48.3,590.2z M49.9,587.6c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6
              c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9
              c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9
              c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
              c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
              c-0.3,0.2-0.4,0.6-0.3,0.9C49.2,587.6,49.6,587.8,49.9,587.6L49.9,587.6z M51.4,585c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6
              c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9c2.9,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9
              c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9
              c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
              c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
              c-0.3,0.2-0.4,0.6-0.3,0.9C50.7,585,51.1,585.1,51.4,585L51.4,585z M53,582.3c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6
              c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9
              c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9
              c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4
              c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4
              c-0.3,0.2-0.4,0.6-0.3,0.9C52.3,582.4,52.7,582.5,53,582.3z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M817,738.8L886,567l93.9,37.7l-69,171.8L817,738.8L817,738.8z
               M824.9,735.2l82.7,33.2L972,608.3L889.2,575L824.9,735.2z"/>

            <rect x="812.1" y="627.2" transform="matrix(0.3728 -0.9279 0.9279 0.3728 -59.8643 1254.9929)" fill="#D1CCE6" width="172.6" height="89.1"/>
            <g>
              <polygon fill="#0B99D6" points="820,731.3 835.7,746.3 819.9,740 817.4,737.6         "/>
              <path fill="#0B99D6" d="M825.1,718.6l4.7,4.5l-2.5,6.3l-4.7-4.5L825.1,718.6
                L825.1,718.6z M855.1,747.3l12.2,11.7l-15.8-6.3L839.3,741L855.1,747.3z"/>
              <path fill="#0B99D6" d="M830.2,705.9l4.7,4.5l-2.5,6.3l-4.7-4.5L830.2,705.9
                L830.2,705.9z M886.7,760l12.2,11.7l-15.8-6.3L871,753.7L886.7,760z"/>
              <path fill="#0B99D6" d="M835.3,693.3l4.7,4.5l-2.5,6.3l-4.7-4.5L835.3,693.3
                L835.3,693.3z M909.3,764.1l4.7,4.5l-2.5,6.3l-8.9-8.5l5,2L909.3,764.1z"/>
              <path fill="#0B99D6" d="M840.3,680.6l4.7,4.5l-2.5,6.3l-4.7-4.5L840.3,680.6
                L840.3,680.6z M914.4,751.5l4.7,4.5l-2.5,6.3l-4.7-4.5L914.4,751.5z"/>
              <path fill="#0B99D6" d="M845.4,667.9l4.7,4.5l-2.5,6.3l-4.7-4.5L845.4,667.9
                L845.4,667.9z M919.5,738.8l4.7,4.5l-2.5,6.3l-4.7-4.5L919.5,738.8z"/>
              <path fill="#0B99D6" d="M850.5,655.3l4.7,4.5l-2.5,6.3l-4.7-4.5L850.5,655.3
                L850.5,655.3z M924.6,726.1l4.7,4.5l-2.5,6.3l-4.7-4.5L924.6,726.1z"/>
              <path fill="#0B99D6" d="M855.6,642.6l4.7,4.5l-2.5,6.3l-4.7-4.5L855.6,642.6
                L855.6,642.6z M929.7,713.5l4.7,4.5l-2.5,6.3l-4.7-4.5L929.7,713.5z"/>
              <path fill="#0B99D6" d="M860.7,629.9l4.7,4.5l-2.5,6.3l-4.7-4.5L860.7,629.9
                L860.7,629.9z M934.8,700.8l4.7,4.5l-2.5,6.3l-4.7-4.5L934.8,700.8z"/>
              <path fill="#0B99D6" d="M865.8,617.3l4.7,4.5l-2.5,6.3l-4.7-4.5L865.8,617.3
                L865.8,617.3z M939.9,688.1l4.7,4.5l-2.5,6.3l-4.7-4.5L939.9,688.1z"/>
              <path fill="#0B99D6" d="M870.9,604.6l4.7,4.5l-2.5,6.3l-4.7-4.5L870.9,604.6
                L870.9,604.6z M945,675.5l4.7,4.5l-2.5,6.3l-4.7-4.5L945,675.5z"/>
              <path fill="#0B99D6" d="M876,591.9l4.7,4.5l-2.5,6.3l-4.7-4.5L876,591.9L876,591.9z
                 M950.1,662.8l4.7,4.5l-2.5,6.3l-4.7-4.5L950.1,662.8z"/>
              <path fill="#0B99D6" d="M881.1,579.3l4.7,4.5l-2.5,6.3l-4.7-4.5L881.1,579.3
                L881.1,579.3z M955.1,650.1l4.7,4.5l-2.5,6.3l-4.7-4.5L955.1,650.1z"/>
              <path fill="#0B99D6" d="M886.9,567.3l12.2,11.7l-9.9-4l-0.9,2.4l-4.7-4.5l2.4-6
                L886.9,567.3L886.9,567.3z M960.2,637.5l4.7,4.5l-2.5,6.3l-4.7-4.5L960.2,637.5z"/>
              <path fill="#0B99D6" d="M918.5,580l12.2,11.7l-15.8-6.3l-12.2-11.7L918.5,580
                L918.5,580z M965.3,624.8l4.7,4.5l-2.5,6.3l-4.7-4.5L965.3,624.8z"/>
              <path fill="#0B99D6" d="M950.1,592.7l12.2,11.7l-15.8-6.3l-12.2-11.7L950.1,592.7
                L950.1,592.7z M970.4,612.1l4.7,4.5l-2.5,6.3l-4.7-4.5L970.4,612.1z"/>
              <polygon fill="#0B99D6" points="977.6,610.3 966,599.1 979.9,604.7         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M881.3,633.5c1.2-3,3.5-5.2,6.2-6.4s5.9-1.3,8.9-0.1
              s5.2,3.5,6.4,6.2s1.3,5.9,0.1,8.9s-3.5,5.2-6.2,6.4s-5.9,1.3-8.9,0.1s-5.2-3.5-6.4-6.2C880.3,639.7,880.1,636.5,881.3,633.5
              L881.3,633.5z M888.3,628.8c-2.3,1-4.2,2.9-5.3,5.4c-1,2.5-0.9,5.2,0.1,7.5s2.9,4.2,5.4,5.3c2.5,1,5.2,0.9,7.5-0.1
              s4.2-2.9,5.3-5.4c1-2.5,0.9-5.2-0.1-7.5s-2.9-4.2-5.4-5.3C893.3,627.7,890.6,627.8,888.3,628.8z"/>
            <path fill="#80CAF0" d="M888.4,634.8c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1
              c0,0-5.2-7,0.5-10.9c1.9-1.3,3.2-2,4.1-2.5c1.3-0.7,1.5-0.8,1.6-1.8c0.1-0.4-0.2-1.1-0.6-1.9c-0.9-1.9-2.2-4.6-0.4-8.8
              c1.5-3.6,2.8-3.9,4.4-4.4c1-0.3,2.2-0.6,3.7-2.2c1.7-1.9,2.2-3.6,2.1-5c-0.1-1.5-0.9-2.7-1.5-3.7c-1.6-2.3-0.4-5.4-0.4-5.4
              c0.1-0.3,0.5-0.5,0.8-0.3c0.3,0.1,0.5,0.5,0.3,0.8c0,0-1,2.5,0.3,4.2c0.8,1.1,1.6,2.5,1.8,4.3s-0.4,3.8-2.4,6
              c-1.8,1.9-3.1,2.2-4.3,2.6c-1.3,0.4-2.3,0.7-3.6,3.7c-1.6,3.7-0.4,6,0.4,7.7c0.5,1,0.8,1.8,0.7,2.6c-0.2,1.5-0.5,1.7-2.2,2.7
              c-0.8,0.5-2.1,1.2-4,2.5C883.9,628.9,888.4,634.8,888.4,634.8L888.4,634.8z M899,639.1c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5
              c1.7-1,2-1.1,2.2-2.7c0.1-0.8-0.3-1.6-0.7-2.6c-0.8-1.7-2-4.1-0.4-7.7c1.3-3,2.3-3.3,3.6-3.7c1.2-0.3,2.5-0.7,4.3-2.6
              c2-2.2,2.6-4.2,2.4-6c-0.2-1.8-1-3.2-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8s-0.7,0-0.8,0.3
              c0,0-1.2,3.1,0.4,5.4c0.7,0.9,1.4,2.2,1.5,3.7s-0.4,3.2-2.1,5c-1.5,1.6-2.7,1.9-3.7,2.2c-1.6,0.4-2.9,0.8-4.4,4.4
              c-1.8,4.2-0.5,6.8,0.4,8.8c0.4,0.8,0.7,1.4,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9
              c0.2,0.3,0.6,0.3,0.9,0.1C899.2,639.8,899.2,639.4,899,639.1L899,639.1z M896.4,638c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5
              c1.7-1,2-1.1,2.2-2.7c0.1-0.8-0.3-1.6-0.7-2.6c-0.8-1.7-2-4.1-0.4-7.7c1.3-3,2.3-3.3,3.6-3.7c1.2-0.3,2.5-0.7,4.3-2.6
              c2-2.2,2.6-4.2,2.4-6c-0.2-1.8-1-3.2-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8s-0.7,0-0.8,0.3
              c0,0-1.2,3.1,0.4,5.4c0.7,0.9,1.4,2.2,1.5,3.7s-0.4,3.2-2.1,5c-1.5,1.6-2.7,1.9-3.7,2.2c-1.6,0.4-2.9,0.8-4.4,4.4
              c-1.8,4.2-0.5,6.8,0.4,8.8c0.4,0.8,0.7,1.4,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9
              c0.2,0.3,0.6,0.3,0.9,0.1C896.5,638.7,896.6,638.3,896.4,638L896.4,638z M893.7,637c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5
              c1.7-1,2-1.1,2.2-2.7c0.1-0.8-0.3-1.6-0.7-2.6c-0.8-1.7-2-4.1-0.4-7.7c1.3-3,2.3-3.3,3.6-3.7c1.2-0.3,2.5-0.7,4.3-2.6
              c2-2.2,2.6-4.2,2.4-6c-0.2-1.8-1-3.2-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8s-0.7,0-0.8,0.3
              c0,0-1.2,3.1,0.4,5.4c0.7,0.9,1.4,2.2,1.5,3.7s-0.4,3.2-2.1,5c-1.5,1.6-2.7,1.9-3.7,2.2c-1.6,0.4-2.9,0.8-4.4,4.4
              c-1.8,4.2-0.5,6.8,0.4,8.8c0.4,0.8,0.7,1.4,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9
              c0.2,0.3,0.6,0.3,0.9,0.1C893.8,637.6,893.9,637.2,893.7,637L893.7,637z M891,635.9c0,0-4.5-5.9,0.2-9.1c1.9-1.3,3.2-2,4-2.5
              c1.7-1,2-1.1,2.2-2.7c0.1-0.8-0.3-1.6-0.7-2.6c-0.8-1.7-2-4.1-0.4-7.7c1.3-3,2.3-3.3,3.6-3.7c1.2-0.3,2.5-0.7,4.3-2.6
              c2-2.2,2.6-4.2,2.4-6c-0.2-1.8-1-3.2-1.8-4.3c-1.2-1.7-0.3-4.2-0.3-4.2c0.1-0.3,0-0.7-0.3-0.8s-0.7,0-0.8,0.3
              c0,0-1.2,3.1,0.4,5.4c0.7,0.9,1.4,2.2,1.5,3.7s-0.4,3.2-2.1,5c-1.5,1.6-2.7,1.9-3.7,2.2c-1.6,0.4-2.9,0.8-4.4,4.4
              c-1.8,4.2-0.5,6.8,0.4,8.8c0.4,0.8,0.7,1.4,0.6,1.9c-0.1,0.9-0.3,1-1.6,1.8c-0.9,0.5-2.2,1.2-4.1,2.5c-5.7,3.9-0.5,10.8-0.5,10.9
              c0.2,0.3,0.6,0.3,0.9,0.1C891.2,636.6,891.2,636.2,891,635.9z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#D4410B" d="M-5.9,688.8l169.2,75.3l41.1-92.5L35.3,596.3L-5.9,688.8
              L-5.9,688.8z M2.3,685.8l36.2-81.4l157.7,70.2L160,756L2.3,685.8z"/>

            <rect x="54.7" y="593.9" transform="matrix(0.4065 -0.9136 0.9136 0.4065 -562.5457 494.3611)" fill="#CCCCCE" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#39BEEE" points="1.5,692.1 2.3,670.3 -4.6,685.8 -4.7,689.3         "/>
              <path fill="#39BEEE" d="M14,697.6l0.2-6.5L8,688.4l-0.2,6.5L14,697.6L14,697.6z
                 M15.6,656.1l0.6-16.9l-6.9,15.5l-0.6,16.9L15.6,656.1z"/>
              <path fill="#39BEEE" d="M26.5,703.2l0.2-6.5l-6.2-2.8l-0.2,6.5L26.5,703.2L26.5,703.2z
                 M29.4,624.9L30,608l-6.9,15.5l-0.6,16.9L29.4,624.9z"/>
              <path fill="#39BEEE" d="M38.9,708.7l0.2-6.5l-6.2-2.8l-0.2,6.5L38.9,708.7L38.9,708.7z
                 M42.8,606.3l0.2-6.5l-6.2-2.8l-0.5,12.3l2.2-5L42.8,606.3z"/>
              <path fill="#39BEEE" d="M51.4,714.3l0.2-6.5l-6.2-2.8l-0.2,6.5L51.4,714.3L51.4,714.3z
                 M55.3,611.8l0.2-6.5l-6.2-2.8L49,609L55.3,611.8z"/>
              <path fill="#39BEEE" d="M63.9,719.8l0.2-6.5l-6.2-2.8l-0.2,6.5L63.9,719.8L63.9,719.8z
                 M67.7,617.4l0.2-6.5l-6.2-2.8l-0.2,6.5L67.7,617.4z"/>
              <path fill="#39BEEE" d="M76.4,725.4l0.2-6.5l-6.2-2.8l-0.2,6.5L76.4,725.4L76.4,725.4z
                 M80.2,622.9l0.2-6.5l-6.2-2.8l-0.2,6.5L80.2,622.9z"/>
              <path fill="#39BEEE" d="M88.8,730.9l0.2-6.5l-6.2-2.8l-0.2,6.5L88.8,730.9L88.8,730.9z
                 M92.7,628.5l0.2-6.5l-6.2-2.8l-0.2,6.5L92.7,628.5z"/>
              <path fill="#39BEEE" d="M101.3,736.5l0.2-6.5l-6.2-2.8l-0.2,6.5L101.3,736.5
                L101.3,736.5z M105.1,634l0.2-6.5l-6.2-2.8l-0.2,6.5L105.1,634z"/>
              <path fill="#39BEEE" d="M113.8,742l0.2-6.5l-6.2-2.8l-0.2,6.5L113.8,742L113.8,742z
                 M117.6,639.6l0.2-6.5l-6.2-2.8l-0.2,6.5L117.6,639.6z"/>
              <path fill="#39BEEE" d="M126.2,747.6l0.2-6.5l-6.2-2.8l-0.2,6.5L126.2,747.6
                L126.2,747.6z M130.1,645.1l0.2-6.5l-6.2-2.8l-0.2,6.5L130.1,645.1z"/>
              <path fill="#39BEEE" d="M138.7,753.1l0.2-6.5l-6.2-2.8l-0.2,6.5L138.7,753.1
                L138.7,753.1z M142.6,650.7l0.2-6.5l-6.2-2.8l-0.2,6.5L142.6,650.7z"/>
              <path fill="#39BEEE" d="M151.2,758.7l0.2-6.5l-6.2-2.8l-0.2,6.5L151.2,758.7
                L151.2,758.7z M155,656.2l0.2-6.5l-6.2-2.8l-0.2,6.5L155,656.2z"/>
              <path fill="#39BEEE" d="M163.7,763.2l0.6-16.9L160,756l-2.3-1l-0.2,6.5l5.9,2.6
                L163.7,763.2L163.7,763.2z M167.5,661.8l0.2-6.5l-6.2-2.8l-0.2,6.5L167.5,661.8z"/>
              <path fill="#39BEEE" d="M177.5,732l0.6-16.9l-6.9,15.5l-0.6,16.9L177.5,732L177.5,732z
                 M180,667.3l0.2-6.5L174,658l-0.2,6.5L180,667.3z"/>
              <path fill="#39BEEE" d="M191.4,700.9L192,684l-6.9,15.5l-0.6,16.9L191.4,700.9
                L191.4,700.9z M192.4,672.9l0.2-6.5l-6.2-2.8l-0.2,6.5L192.4,672.9z"/>
              <polygon fill="#39BEEE" points="198.9,669.1 198.3,685.3 204.4,671.6         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M128.2,649.4l2,0.9l0,0c-0.3,0.7,0,1.6,0.7,1.9s1.6,0,1.9-0.7
              l0,0l1.3,0.6l0,0c-0.3,0.7,0,1.6,0.7,1.9c0.7,0.3,1.6,0,1.9-0.7l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9c0.7,0.3,1.6,0,1.9-0.7
              l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9c0.7,0.3,1.6,0,1.9-0.7l0,0l1.1,0.5l0,0c-0.3,0.7,0,1.6,0.7,1.9c0.7,0.3,1.6,0,1.9-0.7
              l0,0l2,0.9l-0.7,1.6c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9
              l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9L144,672c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.6,1.3
              c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1
              c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.5,1.1c-0.7-0.3-1.6,0-1.9,0.7c-0.3,0.7,0,1.6,0.7,1.9l-0.7,1.6l-2-0.9l0,0
              c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0
              c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7l0,0l-1.1-0.5l0,0c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7l0,0l-1.3-0.6l0,0
              c0.3-0.7,0-1.6-0.7-1.9c-0.7-0.3-1.6,0-1.9,0.7l0,0l-2-0.9l0.7-1.6c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1
              c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9
              l0.6-1.3c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7
              s0-1.6-0.7-1.9l0.5-1.1c0.7,0.3,1.6,0,1.9-0.7c0.3-0.7,0-1.6-0.7-1.9L128.2,649.4z"/>

            <rect x="117.3" y="661.3" transform="matrix(0.4065 -0.9136 0.9136 0.4065 -534.188 518.2177)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>

          <rect x="341.1" y="518.1" transform="matrix(0.2257 -0.9742 0.9742 0.2257 -291.565 854.3915)" fill="#A3DAF7" width="101.2" height="185.1"/>
          <path fill="#80CAF0" d="M313,540.4l59.5,68.3c8.6,9.8,24.9,13.9,36.3,9.1l84.6-35.6
            L313,540.4z"/>
          <g>
            <path fill="#0B99D6" d="M403.8,606.3c-4.1-0.9-8.1-0.1-11.4,1.9s-5.8,5.4-6.7,9.4
              c-0.9,4.1-0.1,8.1,1.9,11.4c2,3.3,5.4,5.8,9.4,6.7c4.1,0.9,8.1,0.1,11.4-1.9s5.8-5.4,6.7-9.4c0.9-4.1,0.1-8.1-1.9-11.4
              C411.2,609.8,407.9,607.3,403.8,606.3L403.8,606.3z M393.7,610.3c2.8-1.7,6.2-2.4,9.6-1.6c3.4,0.8,6.2,2.9,7.9,5.7
              c1.7,2.8,2.4,6.2,1.6,9.6c-0.8,3.4-2.9,6.2-5.7,7.9c-2.8,1.7-6.2,2.4-9.6,1.6c-3.4-0.8-6.2-2.9-7.9-5.7c-1.7-2.8-2.4-6.2-1.6-9.6
              C388.8,614.8,390.9,612,393.7,610.3z"/>
            <path fill="#0B99D6" d="M400.1,614.8c0.1,0.4,0.6,0.6,1,0.5s0.6-0.6,0.5-1
              c0,0-3.5-10.7-11.9-7.5c-2.8,1.1-4.5,1.9-5.7,2.5c-1.7,0.8-2,0.9-3.1,0.4c-0.5-0.3-1-1.1-1.5-2c-1.4-2.4-3.2-5.8-9-7
              c-4.9-1-6.3,0.1-7.9,1.5c-1.1,0.9-2.3,1.9-5.1,2.3c-3.3,0.5-5.4-0.3-6.9-1.5s-2.3-3-2.8-4.4c-1.2-3.4-5.4-4.4-5.4-4.4
              c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.4,0.2,0.9,0.6,1c0,0,3.3,0.8,4.3,3.4c0.6,1.6,1.5,3.6,3.3,5.1s4.3,2.4,8.1,1.8
              c3.3-0.5,4.7-1.6,5.9-2.6c1.3-1.1,2.4-2,6.6-1.1c5,1,6.7,4,7.9,6.2c0.7,1.2,1.2,2.2,2.2,2.7c1.8,0.9,2.2,0.7,4.5-0.4
              c1.1-0.5,2.8-1.4,5.6-2.4C397.1,605.7,400,614.8,400.1,614.8L400.1,614.8z M396.7,629.3c0,0-3-9.1-9.8-6.5
              c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.5-1.5-1.5-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1
              c-1.2,1-2.6,2.2-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.8-1.5-2.7-3.5-3.3-5.1c-0.9-2.6-4.2-3.4-4.3-3.4c-0.4-0.1-0.7-0.5-0.6-1
              c0.1-0.4,0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.4,1.3,3.1,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c2.8-0.4,4.1-1.4,5.1-2.3
              c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.6,1,1,1.8,1.5,2c1.1,0.6,1.4,0.4,3.1-0.4c1.2-0.6,2.9-1.4,5.7-2.5
              c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C397.3,630,396.8,629.7,396.7,629.3L396.7,629.3z M397.5,625.7
              c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-0.9-0.5-1.5-1.5-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2
              c-4.2-0.9-5.2,0-6.6,1.1c-1.2,1-2.6,2.2-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.8-1.5-2.7-3.5-3.3-5.1c-0.9-2.6-4.2-3.4-4.3-3.4
              c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.4,1.3,3.1,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5
              c2.8-0.4,4.1-1.4,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.5,1,1,1.8,1.5,2c1.1,0.6,1.4,0.4,3.1-0.4
              c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1C398.1,626.3,397.7,626.1,397.5,625.7L397.5,625.7z
               M398.4,622.1c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.5-1.5-1.5-2.2-2.7
              c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1c-1.2,1-2.6,2.2-5.9,2.6c-3.8,0.6-6.4-0.4-8.1-1.8c-1.8-1.5-2.7-3.5-3.3-5.1
              c-0.9-2.6-4.2-3.4-4.3-3.4c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6c0,0,4.2,1,5.4,4.4c0.5,1.4,1.3,3.1,2.8,4.4
              c1.5,1.2,3.6,2,6.9,1.5c2.8-0.4,4.1-1.4,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5c5.8,1.2,7.6,4.5,9,7c0.6,1,1,1.8,1.5,2
              c1.1,0.6,1.4,0.4,3.1-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5c0.1,0.4-0.1,0.9-0.5,1
              C399,622.7,398.5,622.5,398.4,622.1L398.4,622.1z M399.2,618.5c0,0-3-9.1-9.8-6.5c-2.8,1.1-4.5,1.9-5.6,2.4
              c-2.3,1.1-2.7,1.3-4.5,0.4c-1-0.5-1.5-1.5-2.2-2.7c-1.2-2.2-2.9-5.1-7.9-6.2c-4.2-0.9-5.2,0-6.6,1.1c-1.2,1-2.6,2.2-5.9,2.6
              c-3.8,0.6-6.4-0.4-8.1-1.8c-1.8-1.5-2.7-3.5-3.3-5.1c-0.9-2.6-4.2-3.4-4.3-3.4c-0.4-0.1-0.7-0.5-0.6-1c0.1-0.4,0.5-0.7,1-0.6
              c0,0,4.2,1,5.4,4.4c0.5,1.4,1.3,3.1,2.8,4.4c1.5,1.2,3.6,2,6.9,1.5c2.8-0.4,4.1-1.4,5.1-2.3c1.7-1.4,3-2.5,7.9-1.5
              c5.8,1.2,7.6,4.5,9,7c0.6,1,1,1.8,1.5,2c1.1,0.6,1.4,0.4,3.1-0.4c1.2-0.6,2.9-1.4,5.7-2.5c8.4-3.2,11.9,7.5,11.9,7.5
              c0.1,0.4-0.1,0.9-0.5,1C399.8,619.1,399.4,618.9,399.2,618.5z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#80CAF0" d="M350.3,664.2c2.9,1.8,4.9,4.6,5.7,7.6c0.8,3.1,0.4,6.5-1.4,9.4
              s-4.6,4.9-7.6,5.7c-3.1,0.8-6.5,0.4-9.4-1.4c-2.9-1.8-4.9-4.6-5.7-7.6c-0.8-3.1-0.4-6.5,1.4-9.4s4.6-4.9,7.6-5.7
              C344,662.1,347.4,662.5,350.3,664.2L350.3,664.2z M354.1,672.4c-0.7-2.6-2.3-4.9-4.8-6.4s-5.3-1.8-7.9-1.2
              c-2.6,0.7-4.9,2.3-6.4,4.8s-1.8,5.3-1.2,7.9c0.7,2.6,2.3,4.9,4.8,6.4s5.3,1.8,7.9,1.2c2.6-0.7,4.9-2.3,6.4-4.8
              C354.4,677.8,354.7,675,354.1,672.4z"/>
            <path fill="#80CAF0" d="M347.7,671.4c-0.3,0.2-0.7,0-0.9-0.3c-0.2-0.3,0-0.7,0.3-0.9
              c0,0,8.2-4.3,11.3,2.4c1.1,2.2,1.6,3.7,2,4.7c0.5,1.4,0.6,1.7,1.6,2c0.5,0.1,1.2-0.1,2.1-0.3c2.2-0.7,5.2-1.6,9.3,1
              c3.5,2.2,3.7,3.6,3.9,5.4c0.1,1.1,0.3,2.4,1.7,4.3c1.7,2.2,3.4,2.9,4.9,3.1c1.6,0.1,3-0.5,4.1-1c2.7-1.3,5.7,0.5,5.7,0.5
              c0.3,0.2,0.4,0.6,0.2,0.9c-0.2,0.3-0.6,0.4-0.9,0.2c0,0-2.4-1.4-4.4-0.4c-1.3,0.6-2.9,1.3-4.8,1.1c-1.9-0.1-3.9-1.1-5.9-3.6
              c-1.7-2.2-1.8-3.6-2-4.9c-0.2-1.4-0.3-2.6-3.2-4.4c-3.6-2.3-6.3-1.5-8.2-0.9c-1.1,0.3-2,0.6-2.8,0.3c-1.6-0.5-1.7-0.8-2.4-2.8
              c-0.4-1-0.9-2.4-1.9-4.6C354.7,667.7,347.7,671.4,347.7,671.4L347.7,671.4z M341.4,681.9c0,0,7-3.7,9.5,1.7
              c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9c3,1.9,3.1,3,3.2,4.4
              c0.2,1.3,0.3,2.8,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4c0.3,0.2,0.7,0.1,0.9-0.2
              c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1c-1.4-1.9-1.6-3.1-1.7-4.3
              c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2c-0.4-1-0.9-2.5-2-4.7
              c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C340.7,681.9,341.1,682,341.4,681.9L341.4,681.9z M343,679.3
              c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1
              c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C342.3,679.3,342.7,679.4,343,679.3L343,679.3z
               M344.6,676.6c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1
              c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C343.9,676.7,344.3,676.8,344.6,676.6L344.6,676.6z
               M346.1,674c0,0,7-3.7,9.5,1.7c1,2.2,1.6,3.7,1.9,4.6c0.7,2,0.8,2.3,2.4,2.8c0.8,0.3,1.7,0,2.8-0.3c1.9-0.6,4.6-1.4,8.2,0.9
              c3,1.9,3.1,3,3.2,4.4c0.2,1.3,0.3,2.8,2,4.9c1.9,2.5,4,3.4,5.9,3.6c1.9,0.1,3.6-0.5,4.8-1.1c2-1,4.4,0.4,4.4,0.4
              c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.1-0.7-0.2-0.9c0,0-3-1.8-5.7-0.5c-1.1,0.6-2.6,1.1-4.1,1c-1.6-0.1-3.3-0.9-4.9-3.1
              c-1.4-1.9-1.6-3.1-1.7-4.3c-0.2-1.8-0.4-3.2-3.9-5.4c-4.1-2.6-7.1-1.7-9.3-1c-0.9,0.3-1.6,0.5-2.1,0.3c-1-0.3-1-0.5-1.6-2
              c-0.4-1-0.9-2.5-2-4.7c-3.1-6.7-11.3-2.4-11.3-2.4c-0.3,0.2-0.4,0.6-0.3,0.9C345.4,674.1,345.8,674.2,346.1,674z"/>
          </g>
          <g>
            <path fill="#D4410B" d="M213.1,607L340,741.8l73.7-69.4L286.8,537.6L213.1,607
              L213.1,607z M221.8,607.4l64.9-61.1L405,672l-64.9,61.1L221.8,607.4z"/>

            <rect x="268.9" y="553.4" transform="matrix(0.7282 -0.6854 0.6854 0.7282 -353.2681 388.7122)" fill="#FBD4A4" width="89.1" height="172.6"/>
            <g>
              <polygon fill="#39BEEE" points="218.7,612.9 227.9,593.1 215.5,604.8 214,607.9
                "/>
              <path fill="#39BEEE" d="M228.1,622.8l2.7-5.9l-4.7-5l-2.7,5.9L228.1,622.8L228.1,622.8
                z M245.6,585.1l7.1-15.3l-12.4,11.7l-7.1,15.3L245.6,585.1z"/>
              <path fill="#39BEEE" d="M237.4,632.8l2.7-5.9l-4.7-5l-2.7,5.9L237.4,632.8L237.4,632.8
                z M270.4,561.8l7.1-15.3l-12.4,11.7l-7.1,15.3L270.4,561.8z"/>
              <path fill="#39BEEE" d="M246.8,642.7l2.7-5.9l-4.7-5l-2.7,5.9L246.8,642.7L246.8,642.7
                z M289.9,549.7l2.7-5.9l-4.7-5l-5.2,11.2l4-3.7L289.9,549.7z"/>
              <path fill="#39BEEE" d="M256.1,652.7l2.7-5.9l-4.7-5l-2.7,5.9L256.1,652.7L256.1,652.7
                z M299.3,559.7l2.7-5.9l-4.7-5l-2.7,5.9L299.3,559.7z"/>
              <path fill="#39BEEE" d="M265.5,662.6l2.7-5.9l-4.7-5l-2.7,5.9L265.5,662.6L265.5,662.6
                z M308.6,569.6l2.7-5.9l-4.7-5l-2.7,5.9L308.6,569.6z"/>
              <path fill="#39BEEE" d="M274.8,672.5l2.7-5.9l-4.7-5l-2.7,5.9L274.8,672.5L274.8,672.5
                z M318,579.5l2.7-5.9l-4.7-5l-2.7,5.9L318,579.5z"/>
              <path fill="#39BEEE" d="M284.2,682.5l2.7-5.9l-4.7-5l-2.7,5.9L284.2,682.5L284.2,682.5
                z M327.3,589.5l2.7-5.9l-4.7-5l-2.7,5.9L327.3,589.5z"/>
              <path fill="#39BEEE" d="M293.5,692.4l2.7-5.9l-4.7-5l-2.7,5.9L293.5,692.4L293.5,692.4
                z M336.7,599.4l2.7-5.9l-4.7-5l-2.7,5.9L336.7,599.4z"/>
              <path fill="#39BEEE" d="M302.9,702.4l2.7-5.9l-4.7-5l-2.7,5.9L302.9,702.4L302.9,702.4
                z M346.1,609.4l2.7-5.9l-4.7-5l-2.7,5.9L346.1,609.4z"/>
              <path fill="#39BEEE" d="M312.3,712.3l2.7-5.9l-4.7-5l-2.7,5.9L312.3,712.3L312.3,712.3
                z M355.4,619.3l2.7-5.9l-4.7-5l-2.7,5.9L355.4,619.3z"/>
              <path fill="#39BEEE" d="M321.6,722.2l2.7-5.9l-4.7-5l-2.7,5.9L321.6,722.2L321.6,722.2
                z M364.8,629.2l2.7-5.9l-4.7-5l-2.7,5.9L364.8,629.2z"/>
              <path fill="#39BEEE" d="M331,732.2l2.7-5.9l-4.7-5l-2.7,5.9L331,732.2L331,732.2z
                 M374.1,639.2l2.7-5.9l-4.7-5l-2.7,5.9L374.1,639.2z"/>
              <path fill="#39BEEE" d="M340.8,741.2l7.1-15.3l-7.8,7.3l-1.7-1.9l-2.7,5.9l4.4,4.7
                L340.8,741.2L340.8,741.2z M383.5,649.1l2.7-5.9l-4.7-5l-2.7,5.9L383.5,649.1z"/>
              <path fill="#39BEEE" d="M365.6,717.8l7.1-15.3l-12.4,11.7l-7.1,15.3L365.6,717.8
                L365.6,717.8z M392.8,659.1l2.7-5.9l-4.7-5l-2.7,5.9L392.8,659.1z"/>
              <path fill="#39BEEE" d="M390.4,694.4l7.1-15.3l-12.4,11.7l-7.1,15.3L390.4,694.4
                L390.4,694.4z M402.2,669l2.7-5.9l-4.7-5l-2.7,5.9L402.2,669z"/>
              <polygon fill="#39BEEE" points="409.6,668.1 402.8,682.7 413.7,672.5         "/>
            </g>
          </g>
          <g>
            <path fill="#80CAF0" d="M311.5,681.6c2.2,2.3,5.2,3.6,8.1,3.7c3,0.1,6-1,8.4-3.2
              c2.3-2.2,3.6-5.2,3.7-8.1c0.1-3-1-6-3.2-8.4c-2.2-2.3-5.2-3.6-8.1-3.7c-3-0.1-6,1-8.4,3.2c-2.3,2.2-3.6,5.2-3.7,8.1
              C308.2,676.2,309.3,679.2,311.5,681.6L311.5,681.6z M319.7,683.4c-2.5-0.1-5-1.1-6.9-3.1s-2.7-4.5-2.7-7c0.1-2.5,1.1-5,3.1-6.9
              s4.5-2.7,7-2.7c2.5,0.1,5,1.1,6.9,3.1s2.7,4.5,2.7,7c-0.1,2.5-1.1,5-3.1,6.9C324.7,682.6,322.2,683.5,319.7,683.4z"/>
            <path fill="#80CAF0" d="M317.5,677.8c0.1-0.3-0.1-0.7-0.4-0.8s-0.7,0.1-0.8,0.4
              c0,0-2.3,8.4,4.4,9.9c2.3,0.5,3.7,0.7,4.7,0.9c1.4,0.2,1.7,0.2,2.1,1.1c0.2,0.4,0.2,1.1,0.1,2c-0.2,2.1-0.4,5.1,2.8,8.3
              c2.7,2.8,4.1,2.6,5.7,2.5c1.1-0.1,2.3-0.2,4.3,0.7c2.3,1.1,3.4,2.5,3.8,3.9s0.2,2.9-0.1,4c-0.7,2.7,1.6,5.1,1.6,5.1
              c0.2,0.2,0.6,0.3,0.9,0c0.2-0.2,0.3-0.6,0-0.9c0,0-1.8-1.9-1.3-4c0.3-1.3,0.6-2.9,0.1-4.6c-0.5-1.7-1.8-3.4-4.4-4.7
              c-2.3-1.1-3.7-1-4.9-0.8c-1.3,0.1-2.4,0.2-4.7-2.1c-2.8-2.8-2.6-5.5-2.4-7.4c0.1-1.1,0.1-1.9-0.3-2.7c-0.8-1.3-1.1-1.4-3-1.7
              c-1-0.1-2.4-0.3-4.6-0.8C315.5,684.9,317.5,677.8,317.5,677.8L317.5,677.8z M325.9,669.9c0,0-2,7.2,3.5,8.4
              c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.4,0.7,0.3,1.6,0.3,2.7c-0.1,1.9-0.3,4.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1
              c1.2-0.1,2.6-0.3,4.9,0.8c2.7,1.3,3.9,3,4.4,4.7s0.2,3.4-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9
              c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.3-1.1,0.5-2.6,0.1-4s-1.5-2.8-3.8-3.9c-2-0.9-3.2-0.8-4.3-0.7
              c-1.7,0.2-3,0.3-5.7-2.5c-3.2-3.2-3-6.2-2.8-8.3c0.1-0.9,0.1-1.6-0.1-2c-0.5-0.8-0.7-0.9-2.1-1.1c-1-0.1-2.5-0.3-4.7-0.9
              c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4C325.8,669.2,326,669.6,325.9,669.9L325.9,669.9z M323.8,671.9
              c0,0-2,7.2,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.4,0.7,0.3,1.6,0.3,2.7c-0.1,1.9-0.3,4.5,2.4,7.4
              c2.3,2.3,3.4,2.2,4.7,2.1c1.2-0.1,2.6-0.3,4.9,0.8c2.7,1.3,3.9,3,4.4,4.7s0.2,3.4-0.1,4.6c-0.5,2.1,1.3,4,1.3,4
              c0.2,0.2,0.2,0.6,0,0.9c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.3-1.1,0.5-2.6,0.1-4s-1.5-2.8-3.8-3.9
              c-2-0.9-3.2-0.8-4.3-0.7c-1.7,0.2-3,0.3-5.7-2.5c-3.2-3.2-3-6.2-2.8-8.3c0.1-0.9,0.1-1.6-0.1-2c-0.5-0.8-0.7-0.9-2.1-1
              c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4C323.7,671.2,323.9,671.5,323.8,671.9L323.8,671.9z
               M321.7,673.8c0,0-2,7.2,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8c1.9,0.3,2.3,0.3,3,1.7c0.4,0.7,0.3,1.6,0.3,2.7
              c-0.1,1.9-0.3,4.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.2-0.1,2.6-0.3,4.9,0.8c2.7,1.3,3.9,3,4.4,4.7s0.2,3.4-0.1,4.6
              c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1c0.3-1.1,0.5-2.6,0.1-4
              s-1.5-2.8-3.8-3.9c-2-0.9-3.2-0.8-4.3-0.7c-1.7,0.2-3,0.3-5.7-2.5c-3.2-3.2-3-6.2-2.8-8.3c0.1-0.9,0.1-1.6-0.1-2
              c-0.5-0.8-0.7-0.9-2.1-1.1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4
              C321.6,673.2,321.8,673.5,321.7,673.8L321.7,673.8z M319.6,675.8c0,0-2,7.2,3.5,8.4c2.2,0.5,3.7,0.7,4.6,0.8
              c1.9,0.3,2.3,0.3,3,1.7c0.4,0.7,0.3,1.6,0.3,2.7c-0.1,1.9-0.3,4.5,2.4,7.4c2.3,2.3,3.4,2.2,4.7,2.1c1.2-0.1,2.6-0.3,4.9,0.8
              c2.7,1.3,3.9,3,4.4,4.7s0.2,3.4-0.1,4.6c-0.5,2.1,1.3,4,1.3,4c0.2,0.2,0.2,0.6,0,0.9c-0.2,0.2-0.6,0.2-0.9,0c0,0-2.3-2.4-1.6-5.1
              c0.3-1.1,0.5-2.6,0.1-4s-1.5-2.8-3.8-3.9c-2-0.9-3.2-0.8-4.3-0.7c-1.7,0.2-3,0.3-5.7-2.5c-3.2-3.2-3-6.2-2.8-8.3
              c0.1-0.9,0.1-1.6-0.1-2c-0.5-0.8-0.7-0.9-2.1-1c-1-0.1-2.5-0.3-4.7-0.9c-6.8-1.5-4.4-9.9-4.4-9.9c0.1-0.3,0.4-0.5,0.8-0.4
              C319.5,675.1,319.7,675.5,319.6,675.8z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M352,622.5l1.5,1.6l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1
              l0,0l1,1l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0
              c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l1.5,1.6L367,641
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-1,1c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2l-1.3,1.2l-1.5-1.6l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2
              s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1
              l0,0l-1-1l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-1.5-1.6l1.3-1.2c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8
              c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2
              l1-1c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1
              s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2L352,622.5z"/>

            <rect x="332.9" y="634.2" transform="matrix(0.7282 -0.6854 0.6854 0.7282 -346.4332 412.8791)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M359.9,643.6l1.5,1.6l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1
              l0,0l1,1l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0
              c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l1.5,1.6l-1.3,1.2
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-1,1c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2l-1.3,1.2l-1.5-1.6l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2
              s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1
              l0,0l-1-1l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-1.5-1.6l1.3-1.2c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8
              c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2
              l1-1c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1
              s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2L359.9,643.6z"/>

            <rect x="340.8" y="655.2" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -358.7192 424.0486)" fill="#F5A049" width="28.9" height="18"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M377.4,650.3l1.5,1.6l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1
              l0,0l1,1l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0
              c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l0.8,0.9l0,0c-0.6,0.5-0.6,1.4-0.1,2s1.4,0.6,2,0.1l0,0l1.5,1.6l-1.3,1.2
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2L383,677c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-1,1c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8
              c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1s-0.6,1.4-0.1,2l-0.9,0.8c-0.5-0.6-1.4-0.6-2-0.1
              s-0.6,1.4-0.1,2l-1.3,1.2l-1.5-1.6l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2
              s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-0.8-0.9l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1
              l0,0l-1-1l0,0c0.6-0.5,0.6-1.4,0.1-2s-1.4-0.6-2-0.1l0,0l-1.5-1.6l1.3-1.2c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8
              c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2
              l1-1c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1
              s0.6-1.4,0.1-2l0.9-0.8c0.5,0.6,1.4,0.6,2,0.1s0.6-1.4,0.1-2L377.4,650.3z"/>

            <rect x="358.3" y="662" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -358.5661 437.8937)" fill="#EF805F" width="28.9" height="18"/>
          </g>

          <rect x="259.3" y="603.8" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -366.7713 351.9598)" fill="#236095" width="2.1" height="69.1"/>

          <rect x="264.4" y="599.1" transform="matrix(0.7281 -0.6854 0.6854 0.7281 -362.1234 354.2042)" fill="#236095" width="2.1" height="69.1"/>
        </g>
        <g>
          <g>
            <g>
              <path fill="#EF805F" d="M318.6,649.3l110.9,148.3l81.1-60.6l-111-148.3L318.6,649.3
                L318.6,649.3z M327.2,650.7l71.4-53.4l103.3,138.2L430.5,789L327.2,650.7z"/>

              <rect x="370" y="606.9" transform="matrix(0.801 -0.5987 0.5987 0.801 -332.484 386.1616)" fill="#D1CCE6" width="89.1" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="323.4,655.8 334.8,637.2 321.2,647.4 319.4,650.3
                            "/>
                <path fill="#0B99D6" d="M331.6,666.7l3.4-5.5l-4.1-5.5l-3.4,5.5L331.6,666.7
                  L331.6,666.7z M353.3,631.2l8.8-14.4L348.4,627l-8.8,14.4L353.3,631.2z"/>
                <path fill="#0B99D6" d="M339.8,677.7l3.4-5.5l-4.1-5.5l-3.4,5.5L339.8,677.7
                  L339.8,677.7z M380.6,610.8l8.8-14.4l-13.6,10.2L367,621L380.6,610.8z"/>
                <path fill="#0B99D6" d="M347.9,688.6l3.4-5.5l-4.1-5.5l-3.4,5.5L347.9,688.6
                  L347.9,688.6z M401.3,601.1l3.4-5.5l-4.1-5.5l-6.4,10.5l4.4-3.3L401.3,601.1z"/>
                <path fill="#0B99D6" d="M356.1,699.5l3.4-5.5l-4.1-5.5L352,694L356.1,699.5
                  L356.1,699.5z M409.5,612l3.4-5.5l-4.1-5.5l-3.4,5.5L409.5,612z"/>
                <path fill="#0B99D6" d="M364.3,710.5l3.4-5.5l-4.1-5.5l-3.4,5.5L364.3,710.5
                  L364.3,710.5z M417.7,622.9l3.4-5.5l-4.1-5.5l-3.4,5.5L417.7,622.9z"/>
                <path fill="#0B99D6" d="M372.5,721.4l3.4-5.5l-4.1-5.5l-3.4,5.5L372.5,721.4
                  L372.5,721.4z M425.9,633.9l3.4-5.5l-4.1-5.5l-3.4,5.5L425.9,633.9z"/>
                <path fill="#0B99D6" d="M380.6,732.3l3.4-5.5l-4.1-5.5l-3.4,5.5L380.6,732.3
                  L380.6,732.3z M434,644.8l3.4-5.5l-4.1-5.5l-3.4,5.5L434,644.8z"/>
                <path fill="#0B99D6" d="M388.8,743.3l3.4-5.5l-4.1-5.5l-3.4,5.5L388.8,743.3
                  L388.8,743.3z M442.2,655.7l3.4-5.5l-4.1-5.5l-3.4,5.5L442.2,655.7z"/>
                <path fill="#0B99D6" d="M397,754.2l3.4-5.5l-4.1-5.5l-3.4,5.5L397,754.2L397,754.2z
                   M450.4,666.7l3.4-5.5l-4.1-5.5l-3.4,5.5L450.4,666.7z"/>
                <path fill="#0B99D6" d="M405.2,765.1l3.4-5.5l-4.1-5.5l-3.4,5.5L405.2,765.1
                  L405.2,765.1z M458.5,677.6l3.4-5.5l-4.1-5.5l-3.4,5.5L458.5,677.6z"/>
                <path fill="#0B99D6" d="M413.3,776.1l3.4-5.5l-4.1-5.5l-3.4,5.5L413.3,776.1
                  L413.3,776.1z M466.7,688.5l3.4-5.5l-4.1-5.5l-3.4,5.5L466.7,688.5z"/>
                <path fill="#0B99D6" d="M421.5,787l3.4-5.5l-4.1-5.5l-3.4,5.5L421.5,787L421.5,787z
                   M474.9,699.5l3.4-5.5l-4.1-5.5l-3.4,5.5L474.9,699.5z"/>
                <path fill="#0B99D6" d="M430.2,797l8.8-14.4l-8.5,6.4l-1.5-2l-3.4,5.5l3.8,5.1
                  L430.2,797L430.2,797z M483.1,710.4l3.4-5.5l-4.1-5.5L479,705L483.1,710.4z"/>
                <path fill="#0B99D6" d="M457.5,776.6l8.8-14.4l-13.6,10.2l-8.8,14.4L457.5,776.6
                  L457.5,776.6z M491.2,721.3l3.4-5.5l-4.1-5.5l-3.4,5.5L491.2,721.3z"/>
                <path fill="#0B99D6" d="M484.8,756.2l8.8-14.4L480,752l-8.8,14.4L484.8,756.2
                  L484.8,756.2z M499.4,732.3l3.4-5.5l-4.1-5.5l-3.4,5.5L499.4,732.3z"/>
                <polygon fill="#0B99D6" points="506.9,732.2 498.5,746 510.5,737           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M407.9,734.5c1.9,2.6,4.7,4.1,7.7,4.6c3,0.4,6.1-0.3,8.7-2.2
                s4.1-4.7,4.6-7.7c0.4-3-0.3-6.1-2.2-8.7c-1.9-2.6-4.7-4.1-7.7-4.6c-3-0.4-6.1,0.3-8.7,2.2s-4.1,4.7-4.6,7.7
                C405.2,728.8,405.9,732,407.9,734.5L407.9,734.5z M415.8,737.3c-2.5-0.4-4.8-1.7-6.5-3.8c-1.6-2.2-2.2-4.8-1.9-7.3
                c0.4-2.5,1.7-4.8,3.8-6.5c2.2-1.6,4.8-2.2,7.3-1.9c2.5,0.4,4.8,1.7,6.5,3.8c1.6,2.2,2.2,4.8,1.9,7.3c-0.4,2.5-1.7,4.8-3.8,6.5
                C420.9,737,418.3,737.6,415.8,737.3z"/>
              <path fill="#80CAF0" d="M414.3,731.4c0.1-0.3,0-0.7-0.3-0.8s-0.7,0-0.8,0.3
                c0,0-3.3,8.1,3.3,10.4c2.2,0.8,3.6,1.1,4.6,1.4c1.4,0.4,1.6,0.4,2,1.3c0.2,0.4,0,1.1-0.1,2c-0.4,2.1-1,5,1.8,8.6
                c2.4,3,3.7,3.1,5.4,3.1c1.1,0,2.3,0,4.2,1.2c2.2,1.3,3.1,2.9,3.3,4.3c0.3,1.5-0.1,2.9-0.5,4c-1,2.6,1,5.3,1,5.3
                c0.2,0.3,0.6,0.3,0.9,0.1s0.3-0.6,0.1-0.9c0,0-1.6-2.1-0.8-4.1c0.5-1.2,0.9-2.9,0.6-4.6c-0.3-1.8-1.4-3.6-3.9-5.1
                c-2.2-1.3-3.6-1.4-4.8-1.4c-1.3,0-2.4,0-4.4-2.6c-2.5-3.1-2-5.7-1.6-7.6c0.2-1.1,0.4-1.9,0-2.7c-0.6-1.4-0.9-1.5-2.8-2
                c-0.9-0.2-2.3-0.6-4.5-1.4C411.5,738.3,414.3,731.4,414.3,731.4L414.3,731.4z M423.5,724.6c0,0-2.8,6.9,2.5,8.7
                c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.8,0.2,1.6,0,2.7c-0.4,1.9-0.9,4.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6
                c1.2,0,2.6,0,4.8,1.4c2.5,1.6,3.6,3.4,3.9,5.1c0.3,1.7-0.1,3.4-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9
                c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-2-2.7-1-5.3c0.4-1.1,0.8-2.5,0.5-4c-0.3-1.4-1.2-3-3.3-4.3c-1.9-1.2-3.1-1.2-4.2-1.2
                c-1.7,0-3,0-5.4-3.1c-2.8-3.6-2.2-6.5-1.8-8.6c0.2-0.9,0.3-1.5,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4
                c-6.6-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3C423.5,723.9,423.6,724.2,423.5,724.6L423.5,724.6z M421.2,726.3
                c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.8,0.2,1.6,0,2.7c-0.4,1.9-0.9,4.5,1.6,7.6
                c2,2.6,3.1,2.6,4.4,2.6c1.2,0,2.6,0,4.8,1.4c2.5,1.6,3.6,3.4,3.9,5.1c0.3,1.7-0.1,3.4-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1
                c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-2-2.7-1-5.3c0.4-1.1,0.8-2.5,0.5-4c-0.3-1.4-1.2-3-3.3-4.3
                c-1.9-1.2-3.1-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1c-2.8-3.6-2.2-6.5-1.8-8.6c0.2-0.8,0.3-1.5,0.1-2c-0.4-0.9-0.6-0.9-2-1.3
                c-1-0.2-2.4-0.6-4.6-1.4c-6.6-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3C421.2,725.6,421.3,726,421.2,726.3L421.2,726.3z
                 M418.9,728c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2c0.3,0.8,0.2,1.6,0,2.7
                c-0.4,1.9-0.9,4.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6c1.2,0,2.6,0,4.8,1.4c2.5,1.6,3.6,3.4,3.9,5.1c0.3,1.7-0.1,3.4-0.6,4.6
                c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-2-2.7-1-5.3c0.4-1.1,0.8-2.5,0.5-4
                c-0.3-1.4-1.2-3-3.3-4.3c-1.9-1.2-3.1-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1c-2.8-3.6-2.2-6.5-1.8-8.6c0.2-0.9,0.3-1.5,0.1-2
                c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.6-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3
                C418.9,727.3,419,727.7,418.9,728L418.9,728z M416.6,729.7c0,0-2.8,6.9,2.5,8.7c2.2,0.8,3.6,1.1,4.5,1.4c1.9,0.5,2.2,0.6,2.8,2
                c0.3,0.8,0.2,1.6,0,2.7c-0.4,1.9-0.9,4.5,1.6,7.6c2,2.6,3.1,2.6,4.4,2.6c1.2,0,2.6,0,4.8,1.4c2.5,1.6,3.6,3.4,3.9,5.1
                c0.3,1.7-0.1,3.4-0.6,4.6c-0.8,2,0.8,4.1,0.8,4.1c0.2,0.3,0.1,0.7-0.1,0.9c-0.3,0.2-0.7,0.1-0.9-0.1c0,0-2-2.7-1-5.3
                c0.4-1.1,0.8-2.5,0.5-4c-0.3-1.4-1.2-3-3.3-4.3c-1.9-1.2-3.1-1.2-4.2-1.2c-1.7,0-3,0-5.4-3.1c-2.8-3.6-2.2-6.5-1.8-8.6
                c0.2-0.9,0.3-1.5,0.1-2c-0.4-0.9-0.6-0.9-2-1.3c-1-0.2-2.4-0.6-4.6-1.4c-6.6-2.3-3.3-10.3-3.3-10.4c0.1-0.3,0.5-0.5,0.8-0.3
                C416.6,729,416.7,729.4,416.6,729.7z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M448.6,678.8l1.3,1.7l0,0c-0.6,0.5-0.8,1.4-0.3,2
                s1.4,0.8,2,0.3l0,0l0.8,1.1l0,0c-0.6,0.5-0.8,1.4-0.3,2s1.4,0.8,2,0.3l0,0l0.7,1l0,0c-0.6,0.5-0.8,1.4-0.3,2s1.4,0.8,2,0.3l0,0
                l0.7,1l0,0c-0.6,0.5-0.8,1.4-0.3,2s1.4,0.8,2,0.3l0,0l0.7,1l0,0c-0.6,0.5-0.8,1.4-0.3,2s1.4,0.8,2,0.3l0,0l1.3,1.7l-1.4,1
                c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3
                s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1.1,0.8c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7
                c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3s-0.8,1.4-0.3,2l-1,0.7c-0.5-0.6-1.4-0.8-2-0.3
                s-0.8,1.4-0.3,2l-1.4,1l-1.3-1.7l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2
                s-1.4-0.8-2-0.3l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-0.7-1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0
                l-0.8-1.1l0,0c0.6-0.5,0.8-1.4,0.3-2s-1.4-0.8-2-0.3l0,0l-1.3-1.7l1.4-1c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7
                c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2
                l1.1-0.8c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3
                s0.8-1.4,0.3-2l1-0.7c0.5,0.6,1.4,0.8,2,0.3s0.8-1.4,0.3-2L448.6,678.8z"/>

              <rect x="427.2" y="689.9" transform="matrix(0.801 -0.5987 0.5987 0.801 -330.5006 403.512)" fill="#EF805F" width="28.9" height="18"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M487,739.7l-2.2,1.7l0,0c-0.6-0.8-1.8-1-2.6-0.4
                c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.5,1.1l0,0c-0.6-0.8-1.8-1-2.6-0.4c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.3,1l0,0
                c-0.6-0.8-1.8-1-2.6-0.4c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.3,1l0,0c-0.6-0.8-1.8-1-2.6-0.4c-0.8,0.6-1,1.8-0.4,2.6l0,0l-1.3,1l0,0
                c-0.6-0.8-1.8-1-2.6-0.4c-0.8,0.6-1,1.8-0.4,2.6l0,0l-2.2,1.7l-1.4-1.8c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3
                c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6
                s-1.8-1-2.6-0.4l-1.1-1.5c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3
                c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1-1.3c0.8-0.6,1-1.8,0.4-2.6s-1.8-1-2.6-0.4l-1.4-1.8l2.2-1.7l0,0
                c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l1.3-1l0,0c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l1.3-1l0,0
                c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l1.3-1l0,0c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l1.5-1.1l0,0
                c0.6,0.8,1.8,1,2.6,0.4c0.8-0.6,1-1.8,0.4-2.6l0,0l2.2-1.7l1.4,1.8c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1,1.3
                c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4
                l1.1,1.5c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6
                s1.8,1,2.6,0.4l1,1.3c-0.8,0.6-1,1.8-0.4,2.6s1.8,1,2.6,0.4L487,739.7z"/>

              <rect x="449.2" y="711.7" transform="matrix(0.8009 -0.5988 0.5988 0.8009 -345.6559 421.4885)" fill="#F5A049" width="23.5" height="37.6"/>
            </g>

            <rect x="366.6" y="647.9" transform="matrix(0.801 -0.5987 0.5987 0.801 -333.6295 355.2733)" fill="#A76C2B" width="1.9" height="63.1"/>
          </g>

          <rect x="371.7" y="644.1" transform="matrix(0.801 -0.5987 0.5987 0.801 -330.3371 357.5767)" fill="#A76C2B" width="1.9" height="63.1"/>
        </g>
        <g>
          <g>
            <g>
              <path fill="#E2070F" d="M375.3,643.9l-185-6.3l3.4-101.1l185,6.3L375.3,643.9
                L375.3,643.9z M369.3,637.7l3-89.1l-172.5-5.9l-3,89.1L369.3,637.7z"/>

              <rect x="239.9" y="503.9" transform="matrix(3.390259e-02 -0.9994 0.9994 3.390259e-02 -315.0429 854.5308)" fill="#FFFFFF" width="89.1" height="172.6"/>
              <g>
                <polygon fill="#0B99D6" points="367.2,643.7 376,623.7 375.4,640.7 374,643.9
                  "/>
                <path fill="#0B99D6" d="M353.6,643.2l2.6-5.9l6.8,0.2l-2.6,5.9L353.6,643.2
                  L353.6,643.2z M370.4,605.1l6.8-15.5l-0.6,17l-6.8,15.5L370.4,605.1z"/>
                <path fill="#0B99D6" d="M340,642.7l2.6-5.9l6.8,0.2l-2.6,5.9L340,642.7L340,642.7z
                   M371.5,571.1l6.8-15.5l-0.6,17l-6.8,15.5L371.5,571.1z"/>
                <path fill="#0B99D6" d="M326.3,642.3l2.6-5.9l6.8,0.2l-2.6,5.9L326.3,642.3
                  L326.3,642.3z M367.6,548.5l2.6-5.9l6.8,0.2l-5,11.3l0.2-5.4L367.6,548.5z"/>
                <path fill="#0B99D6" d="M312.7,641.8l2.6-5.9l6.8,0.2l-2.6,5.9L312.7,641.8
                  L312.7,641.8z M354,548l2.6-5.9l6.8,0.2l-2.6,5.9L354,548z"/>
                <path fill="#0B99D6" d="M299,641.4l2.6-5.9l6.8,0.2l-2.6,5.9L299,641.4L299,641.4z
                   M340.4,547.5l2.6-5.9l6.8,0.2l-2.6,5.9L340.4,547.5z"/>
                <path fill="#0B99D6" d="M285.4,640.9l2.6-5.9l6.8,0.2l-2.6,5.9L285.4,640.9
                  L285.4,640.9z M326.7,547.1l2.6-5.9l6.8,0.2l-2.6,5.9L326.7,547.1z"/>
                <path fill="#0B99D6" d="M271.7,640.4l2.6-5.9l6.8,0.2l-2.6,5.9L271.7,640.4
                  L271.7,640.4z M313.1,546.6l2.6-5.9l6.8,0.2l-2.6,5.9L313.1,546.6z"/>
                <path fill="#0B99D6" d="M258.1,640l2.6-5.9l6.8,0.2l-2.6,5.9L258.1,640L258.1,640z
                   M299.4,546.1l2.6-5.9l6.8,0.2l-2.6,5.9L299.4,546.1z"/>
                <path fill="#0B99D6" d="M244.5,639.5l2.6-5.9l6.8,0.2l-2.6,5.9L244.5,639.5
                  L244.5,639.5z M285.8,545.7l2.6-5.9l6.8,0.2l-2.6,5.9L285.8,545.7z"/>
                <path fill="#0B99D6" d="M230.8,639l2.6-5.9l6.8,0.2l-2.6,5.9L230.8,639L230.8,639z
                   M272.2,545.2l2.6-5.9l6.8,0.2l-2.6,5.9L272.2,545.2z"/>
                <path fill="#0B99D6" d="M217.2,638.6l2.6-5.9l6.8,0.2l-2.6,5.9L217.2,638.6
                  L217.2,638.6z M258.5,544.8l2.6-5.9l6.8,0.2l-2.6,5.9L258.5,544.8z"/>
                <path fill="#0B99D6" d="M203.5,638.1l2.6-5.9l6.8,0.2l-2.6,5.9L203.5,638.1
                  L203.5,638.1z M244.9,544.3l2.6-5.9l6.8,0.2l-2.6,5.9L244.9,544.3z"/>
                <path fill="#0B99D6" d="M190.3,636.7l6.8-15.5l-0.4,10.6l2.5,0.1l-2.6,5.9l-6.4-0.2
                  L190.3,636.7L190.3,636.7z M231.2,543.8l2.6-5.9l6.8,0.2L238,544L231.2,543.8z"/>
                <path fill="#0B99D6" d="M191.5,602.6l6.8-15.5l-0.6,17l-6.8,15.5L191.5,602.6
                  L191.5,602.6z M217.6,543.4l2.6-5.9l6.8,0.2l-2.6,5.9L217.6,543.4z"/>
                <path fill="#0B99D6" d="M192.6,568.6l6.8-15.5l-0.6,17l-6.8,15.5L192.6,568.6
                  L192.6,568.6z M203.9,542.9l2.6-5.9l6.8,0.2l-2.6,5.9L203.9,542.9z"/>
                <polygon fill="#0B99D6" points="199.7,536.7 193.2,551.5 193.7,536.5           "/>
              </g>
            </g>
            <g>
              <path fill="#80CAF0" d="M254.4,619.3c-3.2-0.1-6.1-1.5-8.1-3.7s-3.2-5.1-3.1-8.4
                c0.1-3.2,1.5-6.1,3.7-8.1s5.1-3.2,8.4-3.1c3.2,0.1,6.1,1.5,8.1,3.7s3.2,5.1,3.1,8.4c-0.1,3.2-1.5,6.1-3.7,8.1
                C260.6,618.3,257.6,619.5,254.4,619.3L254.4,619.3z M247.6,614.4c1.7,1.8,4.1,3,6.8,3.1s5.2-0.9,7-2.6c1.8-1.7,3-4.1,3.1-6.8
                s-0.9-5.2-2.6-7s-4.1-3-6.8-3.1s-5.2,0.9-7,2.6c-1.8,1.7-3,4.1-3.1,6.8C244.9,610.1,245.9,612.6,247.6,614.4z"/>
              <path fill="#80CAF0" d="M253.3,612.3c0.2-0.3,0.6-0.4,0.9-0.2c0.3,0.2,0.4,0.6,0.2,0.9
                c0,0-4.8,7.3-10.4,3.2c-1.9-1.4-3-2.3-3.8-3c-1.1-0.9-1.3-1.1-2.2-0.9c-0.4,0.1-0.9,0.6-1.5,1.2c-1.5,1.5-3.6,3.7-8.1,3.4
                c-3.9-0.2-4.7-1.3-5.6-2.7c-0.6-0.9-1.3-1.8-3.4-2.7c-2.3-1-4.1-0.9-5.4-0.3c-1.3,0.6-2.3,1.7-2.9,2.7c-1.6,2.3-4.9,2.2-4.9,2.2
                c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6c0,0,2.6,0.1,3.8-1.7c0.7-1.1,1.8-2.4,3.4-3.1c1.6-0.7,3.7-0.9,6.4,0.3
                c2.3,1,3.2,2.1,3.9,3.1c0.8,1.1,1.4,2,4.7,2.2c4,0.2,5.8-1.7,7.1-3c0.8-0.8,1.4-1.4,2.2-1.6c1.5-0.3,1.8-0.1,3.3,1.2
                c0.7,0.6,1.8,1.6,3.7,2.9C249.2,618.5,253.3,612.3,253.3,612.3L253.3,612.3z M253.7,600.8c0,0-4.1,6.2-8.6,2.9
                c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.3-1.2c-0.8,0.2-1.4,0.8-2.2,1.6c-1.3,1.4-3.2,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2
                c-0.7-1-1.5-2.1-3.9-3.1c-2.7-1.2-4.8-1-6.4-0.3c-1.6,0.7-2.7,2-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.6,0.6
                s0.3,0.6,0.6,0.6c0,0,3.3,0.1,4.9-2.2c0.7-1,1.6-2.1,2.9-2.7c1.3-0.6,3.1-0.7,5.4,0.3c2,0.9,2.7,1.9,3.4,2.7
                c1,1.3,1.8,2.4,5.6,2.7c4.5,0.3,6.6-1.9,8.1-3.4c0.6-0.6,1.1-1.1,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9c0.8,0.7,1.9,1.6,3.8,3
                c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9C254.2,600.5,253.8,600.5,253.7,600.8L253.7,600.8z M253.6,603.7
                c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.3-1.2c-0.8,0.2-1.4,0.8-2.2,1.6c-1.3,1.4-3.2,3.2-7.1,3
                c-3.3-0.2-3.9-1.1-4.7-2.2c-0.7-1-1.5-2.1-3.9-3.1c-2.7-1.2-4.8-1-6.4-0.3c-1.6,0.7-2.7,2-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7
                c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0,0,3.3,0.1,4.9-2.2c0.7-1,1.6-2.1,2.9-2.7c1.3-0.6,3.1-0.7,5.4,0.3
                c2,0.9,2.7,1.9,3.4,2.7c1,1.3,1.8,2.4,5.6,2.7c4.5,0.3,6.6-1.9,8.1-3.4c0.6-0.6,1.1-1.1,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9
                c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9C254.1,603.3,253.8,603.4,253.6,603.7L253.6,603.7z
                 M253.5,606.5c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9c-1.5-1.3-1.7-1.5-3.3-1.2c-0.8,0.2-1.4,0.8-2.2,1.6
                c-1.3,1.4-3.2,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.7-1-1.5-2.1-3.9-3.1c-2.7-1.2-4.8-1-6.4-0.3c-1.6,0.7-2.7,2-3.4,3.1
                c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0,0,3.3,0.1,4.9-2.2c0.7-1,1.6-2.1,2.9-2.7
                c1.3-0.6,3.1-0.7,5.4,0.3c2,0.9,2.7,1.9,3.4,2.7c1,1.3,1.8,2.4,5.6,2.7c4.5,0.3,6.6-1.9,8.1-3.4c0.6-0.6,1.1-1.1,1.5-1.2
                c0.9-0.2,1.1,0,2.2,0.9c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2c0.2-0.3,0.1-0.7-0.2-0.9
                C254,606.2,253.7,606.3,253.5,606.5L253.5,606.5z M253.4,609.4c0,0-4.1,6.2-8.6,2.9c-1.9-1.3-2.9-2.3-3.7-2.9
                c-1.5-1.3-1.7-1.5-3.3-1.2c-0.8,0.2-1.4,0.8-2.2,1.6c-1.3,1.4-3.2,3.2-7.1,3c-3.3-0.2-3.9-1.1-4.7-2.2c-0.7-1-1.5-2.1-3.9-3.1
                c-2.7-1.2-4.8-1-6.4-0.3c-1.6,0.7-2.7,2-3.4,3.1c-1.2,1.8-3.8,1.7-3.8,1.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6
                c0,0,3.3,0.1,4.9-2.2c0.7-1,1.6-2.1,2.9-2.7c1.3-0.6,3.1-0.7,5.4,0.3c2,0.9,2.7,1.9,3.4,2.7c1,1.3,1.8,2.4,5.6,2.7
                c4.5,0.3,6.6-1.9,8.1-3.4c0.6-0.6,1.1-1.1,1.5-1.2c0.9-0.2,1.1,0,2.2,0.9c0.8,0.7,1.9,1.6,3.8,3c5.6,4.1,10.4-3.2,10.4-3.2
                c0.2-0.3,0.1-0.7-0.2-0.9C253.9,609,253.6,609.1,253.4,609.4z"/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M333,584.2l-0.5-2.1l0,0c0.8-0.2,1.2-0.9,1.1-1.7
              c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.3-1.4l0,0c0.8-0.2,1.2-1,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.3-1.2l0,0
              c0.8-0.2,1.2-1,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.3-1.2l0,0c0.8-0.2,1.2-1,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.3-1.2
              l0,0c0.8-0.2,1.2-1,1.1-1.7c-0.2-0.8-0.9-1.2-1.7-1.1l0,0l-0.5-2.1l1.7-0.4c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7
              l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-1,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.2-0.3
              c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.4-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.2-0.3
              c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.2-0.3c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.2-0.3
              c0.2,0.8,0.9,1.2,1.7,1.1c0.8-0.2,1.2-0.9,1.1-1.7l1.7-0.4l0.5,2.1l0,0c-0.8,0.2-1.2,0.9-1.1,1.7c0.2,0.8,0.9,1.2,1.7,1.1l0,0
              l0.3,1.2l0,0c-0.8,0.2-1.2,1-1.1,1.7c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0.3,1.2l0,0c-0.8,0.2-1.2,0.9-1.1,1.7c0.2,0.8,0.9,1.2,1.7,1.1
              l0,0l0.3,1.2l0,0c-0.8,0.2-1.2,1-1.1,1.7c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0.3,1.4l0,0c-0.8,0.2-1.2,1-1.1,1.7
              c0.2,0.8,0.9,1.2,1.7,1.1l0,0l0.5,2.1l-1.7,0.4c-0.2-0.8-0.9-1.2-1.7-1.1c-0.8,0.2-1.2,1-1.1,1.7l-1.2,0.3
              c-0.2-0.8-1-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3c-0.2-0.8-0.9-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3
              c-0.2-0.8-0.9-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.4,0.3c-0.2-0.8-0.9-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3
              c-0.2-0.8-1-1.2-1.7-1.1c-0.8,0.2-1.2,1-1.1,1.7l-1.2,0.3c-0.2-0.8-1-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7l-1.2,0.3
              c-0.2-0.8-1-1.2-1.7-1.1c-0.8,0.2-1.2,0.9-1.1,1.7L333,584.2z"/>

            <rect x="333" y="559.6" transform="matrix(0.9737 -0.2278 0.2278 0.9737 -120.3964 94.0947)" fill="#A0D8F7" width="28.9" height="18"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M45.4,523L-48,754l126.2,51l93.4-231L45.4,523L45.4,523z
             M49.7,533.8l111.2,44.9L73.9,794l-111.2-44.9L49.7,533.8z"/>

          <rect x="-54.3" y="604.1" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -576.9885 472.5179)" fill="#E0DCC1" width="232.2" height="119.9"/>
          <polygon fill="#0B99D6" points="41.3,533.1 70.6,533.2 49.4,524.6 44.7,524.6     "/>
          <path fill="#0B99D6" d="M34.4,550.1h8.7l3.4-8.5h-8.7L34.4,550.1L34.4,550.1z
             M90.4,550.3l22.8,0.1L92,541.8l-22.8-0.1L90.4,550.3z"/>
          <path fill="#0B99D6" d="M27.5,567.1h8.7l3.4-8.5h-8.7L27.5,567.1L27.5,567.1z
             M132.9,567.5l22.8,0.1l-21.2-8.6l-22.8-0.1L132.9,567.5z"/>
          <path fill="#0B99D6" d="M20.6,584.2h8.7l3.4-8.5H24L20.6,584.2L20.6,584.2z M158.6,584.6
            h8.7l3.4-8.5l-16.6-0.1l6.8,2.7L158.6,584.6z"/>
          <path fill="#0B99D6" d="M13.7,601.2h8.7l3.4-8.5h-8.7L13.7,601.2L13.7,601.2z
             M151.7,601.6h8.7l3.4-8.5h-8.7L151.7,601.6z"/>
          <path fill="#0B99D6" d="M6.9,618.2h8.7l3.4-8.5h-8.7L6.9,618.2L6.9,618.2z M144.8,618.6
            h8.7l3.4-8.5h-8.7L144.8,618.6z"/>
          <path fill="#0B99D6" d="M0,635.3h8.7l3.4-8.5H3.4L0,635.3L0,635.3z M137.9,635.7h8.7
            l3.4-8.5h-8.7L137.9,635.7z"/>
          <path fill="#0B99D6" d="M-6.9,652.3h8.7l3.4-8.5h-8.7L-6.9,652.3L-6.9,652.3z
             M131.1,652.7h8.7l3.4-8.5h-8.7L131.1,652.7z"/>
          <path fill="#0B99D6" d="M-13.8,669.3h8.7l3.4-8.5h-8.7L-13.8,669.3L-13.8,669.3z
             M124.2,669.7h8.7l3.4-8.5h-8.7L124.2,669.7z"/>
          <path fill="#0B99D6" d="M-20.7,686.3h8.7l3.4-8.5h-8.7L-20.7,686.3L-20.7,686.3z
             M117.3,686.8h8.7l3.4-8.5h-8.7L117.3,686.8z"/>
          <path fill="#0B99D6" d="M-27.5,703.4h8.7l3.4-8.5h-8.7L-27.5,703.4L-27.5,703.4z
             M110.4,703.8h8.7l3.4-8.5h-8.7L110.4,703.8z"/>
          <path fill="#0B99D6" d="M-34.4,720.4h8.7l3.4-8.5H-31L-34.4,720.4L-34.4,720.4z
             M103.5,720.8h8.7l3.4-8.5h-8.7L103.5,720.8z"/>
          <path fill="#0B99D6" d="M-41.3,737.4h8.7l3.4-8.5h-8.7L-41.3,737.4L-41.3,737.4z
             M96.6,737.9h8.7l3.4-8.5H100L96.6,737.9z"/>
          <path fill="#0B99D6" d="M-46.8,754.5l22.8,0.1l-13.3-5.4l1.3-3.2h-8.7l-3.2,8
            L-46.8,754.5L-46.8,754.5z M89.8,754.9h8.7l3.4-8.5h-8.7L89.8,754.9z"/>
          <path fill="#0B99D6" d="M-4.3,771.7l22.8,0.1l-21.2-8.6l-22.8-0.1L-4.3,771.7L-4.3,771.7
            z M82.9,771.9h8.7l3.4-8.5h-8.7L82.9,771.9z"/>
          <g>
            <path fill="#80CAF0" d="M9.5,685.2c-1.6,4-1.5,8.3,0.1,12s4.6,6.8,8.6,8.4
              c4,1.6,8.3,1.5,12-0.1s6.8-4.6,8.4-8.6s1.5-8.3-0.1-12s-4.6-6.8-8.6-8.4c-4-1.6-8.3-1.5-12,0.1C14.3,678.2,11.2,681.1,9.5,685.2
              L9.5,685.2z M11.9,696.2c-1.3-3.1-1.5-6.7-0.1-10.1c1.4-3.4,4-5.9,7.1-7.2s6.7-1.5,10.1-0.1s5.9,4,7.2,7.1s1.5,6.7,0.1,10.1
              c-1.4,3.4-4,5.9-7.1,7.2s-6.7,1.5-10.1,0.1C15.8,701.9,13.3,699.3,11.9,696.2z"/>
            <path fill="#80CAF0" d="M17.6,690.4c0.5-0.1,0.8-0.5,0.7-1c-0.1-0.5-0.5-0.8-1-0.7
              c0,0-11.6,1.9-9.6,11c0.6,3.1,1.2,5,1.6,6.3c0.6,1.9,0.7,2.2-0.1,3.2c-0.4,0.5-1.2,0.8-2.3,1.2c-2.7,1-6.4,2.4-8.6,8.1
              c-1.8,4.9-0.9,6.4,0.2,8.4c0.7,1.2,1.5,2.6,1.5,5.6c0,3.4-1.2,5.5-2.6,6.8c-1.5,1.3-3.4,1.9-4.9,2.1c-3.7,0.7-5.4,4.8-5.4,4.8
              c-0.2,0.4,0,0.9,0.5,1.1c0.4,0.2,0.9,0,1.1-0.5c0,0,1.3-3.3,4.2-3.8c1.7-0.3,3.9-1,5.7-2.5c1.8-1.6,3.2-4.1,3.2-8.1
              c0-3.4-0.9-5.1-1.7-6.5c-0.9-1.6-1.7-2.8-0.1-6.9c1.9-5,5.2-6.2,7.6-7.1c1.4-0.5,2.5-0.9,3.1-1.8c1.2-1.7,1.1-2.1,0.3-4.6
              c-0.4-1.3-0.9-3.1-1.6-6.1C7.8,692,17.6,690.4,17.6,690.4L17.6,690.4z M31.9,696.2c0,0-9.9,1.6-8.3,9c0.6,3,1.2,4.9,1.6,6.1
              c0.8,2.5,0.9,3-0.3,4.6c-0.6,0.9-1.8,1.3-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9c0.8,1.4,1.8,3,1.7,6.5
              c0,4-1.4,6.5-3.2,8.1s-4,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.6-0.7-0.5-1.1
              c0,0,1.7-4.1,5.4-4.8c1.5-0.3,3.4-0.8,4.9-2.1s2.6-3.4,2.6-6.8c0-3-0.8-4.4-1.5-5.6c-1.1-1.9-2.1-3.5-0.2-8.4
              c2.2-5.7,5.9-7.1,8.6-8.1c1.1-0.4,2-0.7,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11
              c0.5-0.1,0.9,0.2,1,0.7C32.7,695.7,32.4,696.1,31.9,696.2L31.9,696.2z M28.4,694.7c0,0-9.9,1.6-8.3,9c0.6,3,1.2,4.9,1.6,6.1
              c0.8,2.5,0.9,3-0.3,4.6c-0.6,0.9-1.8,1.3-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9c0.8,1.4,1.8,3,1.7,6.5
              c0,4-1.4,6.5-3.2,8.1s-4,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.6-0.7-0.5-1.1
              c0,0,1.7-4.1,5.4-4.8c1.5-0.3,3.4-0.8,4.9-2.1s2.6-3.4,2.6-6.8c0-3-0.8-4.4-1.5-5.6c-1.1-1.9-2.1-3.5-0.2-8.4
              c2.2-5.7,5.9-7.1,8.6-8.1c1.1-0.4,2-0.7,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11
              c0.5-0.1,0.9,0.2,1,0.7C29.1,694.2,28.8,694.7,28.4,694.7L28.4,694.7z M24.8,693.3c0,0-9.9,1.6-8.3,9c0.6,3,1.2,4.9,1.6,6.1
              c0.8,2.5,0.9,3-0.3,4.6c-0.6,0.9-1.8,1.3-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9c0.8,1.4,1.8,3,1.7,6.5
              c0,4-1.4,6.5-3.2,8.1s-4,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.6-0.7-0.5-1.1
              c0,0,1.7-4.1,5.4-4.8c1.5-0.3,3.4-0.8,4.9-2.1s2.6-3.4,2.6-6.8c0-3-0.8-4.4-1.5-5.6c-1.1-1.9-2.1-3.5-0.2-8.4
              c2.2-5.7,5.9-7.1,8.6-8.1c1.1-0.4,2-0.7,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11
              c0.5-0.1,0.9,0.2,1,0.7C25.5,692.8,25.2,693.2,24.8,693.3L24.8,693.3z M21.2,691.8c0,0-9.9,1.6-8.3,9c0.6,3,1.2,4.9,1.6,6.1
              c0.8,2.5,0.9,3-0.3,4.6c-0.6,0.9-1.8,1.3-3.1,1.8c-2.4,0.9-5.7,2.1-7.6,7.1c-1.6,4.1-0.8,5.4,0.1,6.9c0.8,1.4,1.8,3,1.7,6.5
              c0,4-1.4,6.5-3.2,8.1s-4,2.2-5.7,2.5c-2.8,0.5-4.2,3.8-4.2,3.8c-0.2,0.4-0.7,0.6-1.1,0.5c-0.4-0.2-0.6-0.7-0.5-1.1
              c0,0,1.7-4.1,5.4-4.8c1.5-0.3,3.4-0.8,4.9-2.1s2.6-3.4,2.6-6.8c0-3-0.8-4.4-1.5-5.6c-1.1-1.9-2.1-3.5-0.2-8.4
              c2.2-5.7,5.9-7.1,8.6-8.1c1.1-0.4,2-0.7,2.3-1.2c0.7-1,0.6-1.3,0.1-3.2c-0.4-1.3-1-3.2-1.6-6.3c-1.9-9.1,9.6-11,9.6-11
              c0.5-0.1,0.9,0.2,1,0.7C22,691.3,21.7,691.8,21.2,691.8z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M85.7,705.8l-0.8,2l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l0,0l-0.5,1.3l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9
              l0,0l-0.5,1.1l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l0,0l-0.5,1.1l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l0,0
              l-0.5,1.1l0,0c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l0,0l-0.8,2l-1.6-0.7c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8
              l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8
              l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l-1.3-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8
              l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8
              l-1.1-0.5c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l-1.6-0.7l0.8-2l0,0c0.7,0.3,1.6-0.1,1.9-0.8
              c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.5-1.1l0,0c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0L48,705l0,0
              c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.5-1.1l0,0c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.5-1.3
              l0,0c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0,0l0.8-2l1.6,0.7c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8
              l1.1,0.5c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.1,0.5c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.1,0.5
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.3,0.5c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.1,0.5
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.1,0.5c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l1.1,0.5
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8L85.7,705.8z"/>

            <rect x="56" y="695.8" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -617.9005 504.4101)" fill="#CCCCCE" width="18" height="28.9"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M48.3,769.1l-2.4-1l0,0c0.4-0.9-0.1-1.9-0.9-2.2c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-1.6-0.6l0,0c0.4-0.9-0.1-1.9-0.9-2.2
              c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-1.4-0.6l0,0c0.4-0.9-0.1-1.9-0.9-2.2c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-1.4-0.6l0,0
              c0.4-0.9-0.1-1.9-0.9-2.2c-0.9-0.4-1.9,0.1-2.2,0.9l0,0l-1.4-0.6l0,0c0.4-0.9-0.1-1.9-0.9-2.2c-0.9-0.4-1.9,0.1-2.2,0.9l0,0
              l-2.4-1l0.8-1.9c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.4c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2
              l0.6-1.4c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.4c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.6
              c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.4c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.4
              c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.6-1.4c0.9,0.4,1.9-0.1,2.2-0.9c0.4-0.9-0.1-1.9-0.9-2.2l0.8-1.9l2.4,1l0,0
              c-0.4,0.9,0.1,1.9,0.9,2.2c0.9,0.4,1.9-0.1,2.2-0.9l0,0l1.4,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2c0.9,0.4,1.9-0.1,2.2-0.9l0,0
              l1.4,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2c0.9,0.4,1.9-0.1,2.2-0.9l0,0l1.4,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2
              c0.9,0.4,1.9-0.1,2.2-0.9l0,0l1.6,0.6l0,0c-0.4,0.9,0.1,1.9,0.9,2.2c0.9,0.4,1.9-0.1,2.2-0.9l0,0l2.4,1l-0.8,1.9
              c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.4c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.4
              c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.4c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.6
              c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2L54,755c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.4
              c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2l-0.6,1.4c-0.9-0.4-1.9,0.1-2.2,0.9c-0.4,0.9,0.1,1.9,0.9,2.2L48.3,769.1z"/>

            <rect x="25.8" y="733.6" transform="matrix(0.3747 -0.9271 0.9271 0.3747 -663.2295 505.3257)" fill="#FBD4A4" width="34.4" height="21.5"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M76.7,767.4l-2-0.8l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.3-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9
              c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.1-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.1-0.5l0,0
              c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0l-1.1-0.5l0,0c0.3-0.7-0.1-1.6-0.8-1.9c-0.7-0.3-1.6,0.1-1.9,0.8l0,0
              l-2-0.8l0.7-1.6c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9
              l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.3
              c0.7,0.3,1.6-0.1,1.9-0.8c0.3-0.7-0.1-1.6-0.8-1.9l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0.5-1.1
              c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0.5-1.1c0.7,0.3,1.6-0.1,1.9-0.8s-0.1-1.6-0.8-1.9l0.7-1.6l2,0.8l0,0
              c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.1,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l0,0
              l1.1,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.1,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9
              c0.7,0.3,1.6-0.1,1.9-0.8l0,0l1.3,0.5l0,0c-0.3,0.7,0.1,1.6,0.8,1.9c0.7,0.3,1.6-0.1,1.9-0.8l0,0l2,0.8l-0.7,1.6
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.3
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1
              c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9l-0.5,1.1c-0.7-0.3-1.6,0.1-1.9,0.8s0.1,1.6,0.8,1.9L76.7,767.4z"/>

            <rect x="57.8" y="737.6" transform="matrix(0.3747 -0.9271 0.9271 0.3747 -647.0005 533.7972)" fill="#D1CCE6" width="28.9" height="18"/>
          </g>

          <rect x="-4" y="597.7" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -530.1841 412.0132)" fill="#236095" width="88.6" height="2.7"/>

          <rect x="80.3" y="627" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -504.6022 508.4687)" fill="#236095" width="88.6" height="2.7"/>

          <rect x="70.6" y="623.3" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -507.3151 497.1533)" fill="#236095" width="88.6" height="2.7"/>

          <rect x="60.8" y="618.8" transform="matrix(0.3747 -0.9272 0.9272 0.3747 -509.2381 485.1882)" fill="#236095" width="88.6" height="2.7"/>
        </g>
        <g>

          <rect x="687.7" y="622.5" transform="matrix(0.8398 -0.5429 0.5429 0.8398 -240.4371 531.4599)" fill="#A3DAF7" width="185.2" height="101.2"/>
          <path fill="#80CAF0" d="M830.5,580.3l-32.8,84.4c-4.7,12.1-18.7,21.6-31.1,20.9
            l-91.6-4.8L830.5,580.3z"/>
          <g>
            <path fill="#0B99D6" d="M767.5,673.2c3.5-2.3,7.6-2.9,11.4-2.1c3.8,0.8,7.3,3.1,9.5,6.6
              c2.3,3.5,2.9,7.6,2.1,11.4c-0.8,3.8-3.1,7.3-6.6,9.5c-3.5,2.3-7.6,2.9-11.4,2.1c-3.8-0.8-7.3-3.1-9.5-6.6
              c-2.3-3.5-2.9-7.6-2.1-11.4C761.7,678.9,764,675.5,767.5,673.2L767.5,673.2z M778.4,673.5c-3.2-0.7-6.6-0.2-9.6,1.8
              c-3,1.9-4.9,4.8-5.5,8c-0.7,3.2-0.2,6.6,1.8,9.6c1.9,3,4.8,4.9,8,5.5c3.2,0.7,6.6,0.2,9.6-1.8c3-1.9,4.9-4.8,5.5-8
              c0.7-3.2,0.2-6.6-1.8-9.6C784.5,676,781.5,674.1,778.4,673.5z"/>
            <path fill="#0B99D6" d="M773.9,679.9c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8
              c0,0-0.3-11.3,8.7-11.1c3,0.1,4.9,0.3,6.2,0.4c1.9,0.2,2.2,0.2,3-0.7c0.4-0.4,0.6-1.3,0.7-2.4c0.5-2.7,1.1-6.5,6.1-9.6
              c4.3-2.6,5.9-2,8-1.3c1.3,0.5,2.8,1,5.6,0.4c3.2-0.7,5-2.1,6-3.7c1-1.7,1.1-3.6,1.1-5c0-3.6,3.6-6,3.6-6c0.4-0.2,0.9-0.1,1.1,0.2
              c0.2,0.4,0.1,0.9-0.2,1.1c0,0-2.9,1.9-2.9,4.6c0,1.7-0.2,3.9-1.3,5.9c-1.2,2-3.3,3.7-7,4.5c-3.2,0.7-5,0.1-6.4-0.5
              c-1.6-0.6-3-1.1-6.6,1.2c-4.4,2.7-5,6.1-5.4,8.5c-0.2,1.4-0.4,2.5-1.1,3.3c-1.4,1.5-1.8,1.4-4.3,1.2c-1.3-0.1-3.1-0.3-6.1-0.4
              C773.6,670.3,773.9,679.9,773.9,679.9L773.9,679.9z M782,692.4c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2
              c0.7-0.8,0.9-1.9,1.1-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,4.9-1.8,6.6-1.2c1.5,0.5,3.2,1.1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5
              c1.2-2,1.4-4.2,1.3-5.9c0-2.8,2.8-4.6,2.9-4.6c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6
              c0,1.5-0.2,3.4-1.1,5c-1,1.6-2.7,3.1-6,3.7c-2.8,0.6-4.3,0-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6
              c-0.2,1.1-0.3,2-0.7,2.4c-0.8,0.9-1.1,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8
              C781.6,693.2,782,692.8,782,692.4L782,692.4z M779.9,689.3c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2
              c0.7-0.8,0.9-1.9,1.1-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,4.9-1.8,6.6-1.2c1.5,0.5,3.2,1.1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5
              c1.2-2,1.4-4.2,1.3-5.9c0-2.8,2.8-4.6,2.9-4.6c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6
              c0,1.5-0.2,3.4-1.1,5c-1,1.6-2.7,3.1-6,3.7c-2.8,0.6-4.3,0-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6
              c-0.2,1.1-0.3,2-0.7,2.4c-0.8,0.9-1.1,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8
              C779.6,690.1,780,689.7,779.9,689.3L779.9,689.3z M777.9,686.1c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2
              c0.7-0.8,0.9-1.9,1.1-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,4.9-1.8,6.6-1.2c1.5,0.5,3.2,1.1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5
              c1.2-2,1.4-4.2,1.3-5.9c0-2.8,2.8-4.6,2.9-4.6c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6
              c0,1.5-0.2,3.4-1.1,5c-1,1.6-2.7,3.1-6,3.7c-2.8,0.6-4.3,0-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6
              c-0.2,1.1-0.3,2-0.7,2.4c-0.8,0.9-1.1,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8
              C777.6,687,777.9,686.6,777.9,686.1L777.9,686.1z M775.9,683c0,0-0.3-9.6,7-9.5c3,0.1,4.8,0.2,6.1,0.4c2.5,0.3,3,0.3,4.3-1.2
              c0.7-0.8,0.9-1.9,1.1-3.3c0.4-2.4,1-5.8,5.4-8.5c3.6-2.2,4.9-1.8,6.6-1.2c1.5,0.5,3.2,1.1,6.4,0.5c3.8-0.8,5.9-2.5,7-4.5
              c1.2-2,1.4-4.2,1.3-5.9c0-2.8,2.8-4.6,2.9-4.6c0.4-0.2,0.5-0.7,0.2-1.1c-0.2-0.4-0.7-0.5-1.1-0.2c0,0-3.6,2.3-3.6,6
              c0,1.5-0.2,3.4-1.1,5c-1,1.6-2.7,3.1-6,3.7c-2.8,0.6-4.3,0-5.6-0.4c-2-0.7-3.7-1.3-8,1.3c-5,3.1-5.6,6.9-6.1,9.6
              c-0.2,1.1-0.3,2-0.7,2.4c-0.8,0.9-1.2,0.9-3,0.7c-1.3-0.1-3.2-0.3-6.2-0.4c-9-0.2-8.7,11.1-8.7,11.1c0,0.4,0.4,0.8,0.8,0.8
              C775.6,683.8,775.9,683.5,775.9,683z"/>
          </g>
        </g>
        <g>
          <path fill="#E2070F" d="M126.9,615.8l126.8,214.5L371,761L244.1,546.5L126.9,615.8
            L126.9,615.8z M138.2,619l103.2-61.1l118.2,199.9l-103.2,61.1L138.2,619z"/>

          <rect x="189" y="572.3" transform="matrix(0.8608 -0.509 0.509 0.8608 -315.732 222.5633)" fill="#D3AC89" width="119.9" height="232.2"/>
          <polygon fill="#0B99D6" points="132.5,625.2 150.3,602 130.6,613.6 127.8,617.3     "/>
          <path fill="#0B99D6" d="M141.8,641l5.3-6.9l-4.7-7.9l-5.3,6.9L141.8,641L141.8,641z
             M175.9,596.6l13.9-18l-19.7,11.7l-13.9,18L175.9,596.6z"/>
          <path fill="#0B99D6" d="M151.2,656.8l5.3-6.9l-4.7-7.9l-5.3,6.9L151.2,656.8L151.2,656.8
            z M215.4,573.3l13.9-18L209.6,567l-13.9,18L215.4,573.3z"/>
          <path fill="#0B99D6" d="M160.5,672.6l5.3-6.9l-4.7-7.9l-5.3,6.9L160.5,672.6L160.5,672.6
            z M244.6,563.3l5.3-6.9l-4.7-7.9l-10.1,13.2l6.3-3.7L244.6,563.3z"/>
          <path fill="#0B99D6" d="M169.9,688.4l5.3-6.9l-4.7-7.9l-5.3,6.9L169.9,688.4L169.9,688.4
            z M254,579.1l5.3-6.9l-4.7-7.9l-5.3,6.9L254,579.1z"/>
          <path fill="#0B99D6" d="M179.2,704.2l5.3-6.9l-4.7-7.9l-5.3,6.9L179.2,704.2L179.2,704.2
            z M263.3,594.9l5.3-6.9l-4.7-7.9l-5.3,6.9L263.3,594.9z"/>
          <path fill="#0B99D6" d="M188.6,720.1l5.3-6.9l-4.7-7.9l-5.3,6.9L188.6,720.1L188.6,720.1
            z M272.7,610.7l5.3-6.9l-4.7-7.9l-5.3,6.9L272.7,610.7z"/>
          <path fill="#0B99D6" d="M197.9,735.9l5.3-6.9l-4.7-7.9l-5.3,6.9L197.9,735.9L197.9,735.9
            z M282,626.5l5.3-6.9l-4.7-7.9l-5.3,6.9L282,626.5z"/>
          <path fill="#0B99D6" d="M207.3,751.7l5.3-6.9l-4.7-7.9l-5.3,6.9L207.3,751.7L207.3,751.7
            z M291.4,642.3l5.3-6.9l-4.7-7.9l-5.3,6.9L291.4,642.3z"/>
          <path fill="#0B99D6" d="M216.6,767.5l5.3-6.9l-4.7-7.9l-5.3,6.9L216.6,767.5L216.6,767.5
            z M300.7,658.1l5.3-6.9l-4.7-7.9l-5.3,6.9L300.7,658.1z"/>
          <path fill="#0B99D6" d="M226,783.3l5.3-6.9l-4.7-7.9l-5.3,6.9L226,783.3L226,783.3z
             M310.1,673.9l5.3-6.9l-4.7-7.9l-5.3,6.9L310.1,673.9z"/>
          <path fill="#0B99D6" d="M235.3,799.1l5.3-6.9l-4.7-7.9l-5.3,6.9L235.3,799.1L235.3,799.1
            z M319.4,689.8l5.3-6.9L320,675l-5.3,6.9L319.4,689.8z"/>
          <path fill="#0B99D6" d="M244.7,814.9l5.3-6.9l-4.7-7.9L240,807L244.7,814.9L244.7,814.9z
             M328.8,705.6l5.3-6.9l-4.7-7.9l-5.3,6.9L328.8,705.6z"/>
          <path fill="#0B99D6" d="M254.9,829.6l13.9-18l-12.3,7.3l-1.7-2.9l-5.3,6.9l4.4,7.4
            L254.9,829.6L254.9,829.6z M338.1,721.4l5.3-6.9l-4.7-7.9l-5.3,6.9L338.1,721.4z"/>
          <path fill="#0B99D6" d="M294.4,806.3l13.9-18L288.6,800l-13.9,18L294.4,806.3
            L294.4,806.3z M347.5,737.2l5.3-6.9l-4.7-7.9l-5.3,6.9L347.5,737.2z"/>
          <path fill="#0B99D6" d="M333.8,782.9l13.9-18L328,776.5l-13.9,18L333.8,782.9
            L333.8,782.9z M356.8,753l5.3-6.9l-4.7-7.9l-5.3,6.9L356.8,753z"/>
          <polygon fill="#0B99D6" points="366.8,754 353.6,771.2 371,761     "/>
          <g>
            <path fill="#80CAF0" d="M234,742.8c2.2,3.7,5.7,6.2,9.6,7.2c3.9,1,8.2,0.5,11.9-1.7
              c3.7-2.2,6.2-5.7,7.2-9.6c1-3.9,0.5-8.2-1.7-11.9s-5.7-6.2-9.6-7.2c-3.9-1-8.2-0.5-11.9,1.7s-6.2,5.7-7.2,9.6
              C231.3,734.8,231.8,739,234,742.8L234,742.8z M244.2,747.6c-3.3-0.8-6.2-2.9-8.1-6.1c-1.9-3.1-2.3-6.7-1.4-10
              c0.8-3.3,2.9-6.2,6.1-8.1c3.1-1.9,6.7-2.3,10-1.4c3.3,0.8,6.2,2.9,8.1,6.1c1.9,3.1,2.3,6.7,1.4,10c-0.8,3.3-2.9,6.2-6.1,8.1
              C251.1,748,247.5,748.4,244.2,747.6z"/>
            <path fill="#80CAF0" d="M243.1,739.5c0.2-0.4,0.1-0.9-0.3-1.1c-0.4-0.2-0.9-0.1-1.1,0.3
              c0,0-5.5,10.3,2.9,14.3c2.8,1.3,4.7,2,6,2.5c1.8,0.7,2.1,0.8,2.5,2c0.2,0.6-0.1,1.5-0.4,2.6c-0.8,2.8-2,6.6,1.2,11.7
              c2.8,4.4,4.6,4.6,6.8,4.9c1.4,0.2,3,0.4,5.4,2.2c2.7,2.1,3.7,4.3,3.8,6.2c0.1,2-0.6,3.8-1.3,5.2c-1.7,3.4,0.5,7.2,0.6,7.2
              c0.2,0.4,0.7,0.5,1.1,0.3s0.5-0.7,0.3-1.1c0,0-1.8-3.1-0.5-5.6c0.8-1.6,1.6-3.7,1.5-6.1c-0.2-2.4-1.3-5-4.5-7.4
              c-2.7-2.1-4.6-2.3-6.2-2.5c-1.8-0.2-3.2-0.4-5.6-4.1c-2.8-4.5-1.8-7.9-1-10.4c0.4-1.4,0.8-2.5,0.4-3.6c-0.6-2-1-2.2-3.5-3.1
              c-1.2-0.5-3-1.1-5.8-2.5C238.4,748.3,243.1,739.5,243.1,739.5L243.1,739.5z M256.4,731.7c0,0-4.7,8.8,2.1,12.1
              c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.3,1.1,0,2.2-0.4,3.6c-0.7,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1
              c1.6,0.2,3.5,0.4,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4s-0.7,4.5-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1
              c-0.4,0.2-0.9,0.1-1.1-0.3c0,0-2.3-3.8-0.6-7.2c0.7-1.4,1.4-3.2,1.3-5.2s-1.1-4.1-3.8-6.2c-2.3-1.8-4-2-5.4-2.2
              c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.7c0.3-1.1,0.6-2,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5
              c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.3C256.4,730.8,256.6,731.3,256.4,731.7L256.4,731.7z M253,733.6
              c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.3,1.1,0,2.2-0.4,3.6c-0.7,2.5-1.8,5.8,1,10.4
              c2.3,3.7,3.8,3.9,5.6,4.1c1.6,0.2,3.5,0.4,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4s-0.7,4.5-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6
              c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3c0,0-2.3-3.8-0.6-7.2c0.7-1.4,1.4-3.2,1.3-5.2s-1.1-4.1-3.8-6.2
              c-2.3-1.8-4-2-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.7c0.3-1.1,0.6-2,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2
              c-1.3-0.5-3.1-1.2-6-2.5c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.3C253.1,732.7,253.3,733.2,253,733.6L253,733.6z
               M249.7,735.6c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5c2.5,0.9,2.9,1.1,3.5,3.1c0.3,1.1,0,2.2-0.4,3.6
              c-0.7,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c1.6,0.2,3.5,0.4,6.2,2.5c3.1,2.5,4.3,5,4.5,7.4s-0.7,4.5-1.5,6.1
              c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3c0,0-2.3-3.8-0.6-7.2c0.7-1.4,1.4-3.2,1.3-5.2
              s-1.1-4.1-3.8-6.2c-2.3-1.8-4-2-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9c-3.2-5.2-2.1-9-1.2-11.7c0.3-1.1,0.6-2,0.4-2.6
              c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5c-8.4-4-2.9-14.3-2.9-14.3c0.2-0.4,0.7-0.6,1.1-0.3
              C249.8,734.7,249.9,735.2,249.7,735.6L249.7,735.6z M246.4,737.6c0,0-4.7,8.8,2.1,12.1c2.8,1.3,4.6,2,5.8,2.5
              c2.5,0.9,2.9,1.1,3.5,3.1c0.3,1.1,0,2.2-0.4,3.6c-0.7,2.5-1.8,5.8,1,10.4c2.3,3.7,3.8,3.9,5.6,4.1c1.6,0.2,3.5,0.4,6.2,2.5
              c3.1,2.5,4.3,5,4.5,7.4s-0.7,4.5-1.5,6.1c-1.3,2.6,0.5,5.6,0.5,5.6c0.2,0.4,0.1,0.9-0.3,1.1c-0.4,0.2-0.9,0.1-1.1-0.3
              c0,0-2.3-3.8-0.6-7.2c0.7-1.4,1.4-3.2,1.3-5.2s-1.1-4.1-3.8-6.2c-2.3-1.8-4-2-5.4-2.2c-2.2-0.3-4-0.5-6.8-4.9
              c-3.2-5.2-2.1-9-1.2-11.7c0.3-1.1,0.6-2,0.4-2.6c-0.4-1.2-0.7-1.3-2.5-2c-1.3-0.5-3.1-1.2-6-2.5c-8.4-4-2.9-14.3-2.9-14.3
              c0.2-0.4,0.7-0.6,1.1-0.3C246.5,736.6,246.6,737.2,246.4,737.6z"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M296.7,694.8l1.1,1.8l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.7,1.2l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0
              l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1l0,0c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l0.6,1.1l0,0
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0,0l1.1,1.8l-1.5,0.9c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5
              s-0.9,1.3-0.5,2l-1.2,0.7c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.1,0.6c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l-1.5,0.9l-1.1-1.8l0,0
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.6-1.1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.6-1.1l0,0
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.6-1.1l0,0c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-0.7-1.2l0,0
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l0,0l-1.1-1.8l1.5-0.9c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5
              s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.2-0.7
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2
              l1.1-0.6c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2L296.7,694.8z"/>

            <rect x="273.1" y="705" transform="matrix(0.8608 -0.509 0.509 0.8608 -323.3894 245.7807)" fill="#CCCCCE" width="28.9" height="18"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M324.3,763l-2.2,1.3l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3l0,0l-1.5,0.9l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3
              l0,0l-1.3,0.8l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3l0,0l-1.3,0.8l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3l0,0
              l-1.3,0.8l0,0c-0.5-0.8-1.5-1.1-2.3-0.6s-1.1,1.5-0.6,2.3l0,0l-2.2,1.3l-1.1-1.8c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6
              l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-0.8-1.3
              c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-0.9-1.5c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3
              s-1.5-1.1-2.3-0.6l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6l-0.8-1.3c0.8-0.5,1.1-1.5,0.6-2.3s-1.5-1.1-2.3-0.6
              l-1.1-1.8l2.2-1.3l0,0c0.5,0.8,1.5,1.1,2.3,0.6s1.1-1.5,0.6-2.3l0,0l1.3-0.8l0,0c0.5,0.8,1.5,1.1,2.3,0.6s1.1-1.5,0.6-2.3l0,0
              l1.3-0.8l0,0c0.5,0.8,1.5,1.1,2.3,0.6s1.1-1.5,0.6-2.3l0,0l1.3-0.8l0,0c0.5,0.8,1.5,1.1,2.3,0.6s1.1-1.5,0.6-2.3l0,0l1.5-0.9l0,0
              c0.5,0.8,1.5,1.1,2.3,0.6s1.1-1.5,0.6-2.3l0,0l2.2-1.3l1.1,1.8c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3
              c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.3
              s1.5,1.1,2.3,0.6l0.9,1.5c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3
              c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6l0.8,1.3c-0.8,0.5-1.1,1.5-0.6,2.3s1.5,1.1,2.3,0.6L324.3,763z"/>

            <rect x="290.6" y="734.9" transform="matrix(0.8607 -0.5091 0.5091 0.8607 -340.9233 258.1862)" fill="#FBD4A4" width="21.5" height="34.4"/>
          </g>
          <g>

            <path fill="#A0D8F7" stroke="#A0D8F7" strokeWidth="0.2085" strokeMiterlimit="22.9256" d="
              M340.2,739.4l-1.8,1.1l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.2,0.7l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0
              l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.1,0.6l0,0c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.1,0.6l0,0
              c-0.4-0.7-1.3-0.9-2-0.5s-0.9,1.3-0.5,2l0,0l-1.8,1.1l-0.9-1.5c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2
              s-1.3-0.9-2-0.5l-0.7-1.2c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1
              c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.6-1.1c0.7-0.4,0.9-1.3,0.5-2s-1.3-0.9-2-0.5l-0.9-1.5l1.8-1.1l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.1-0.6l0,0c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.2-0.7l0,0
              c0.4,0.7,1.3,0.9,2,0.5s0.9-1.3,0.5-2l0,0l1.8-1.1l0.9,1.5c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2
              s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.7,1.2
              c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2
              s1.3,0.9,2,0.5l0.6,1.1c-0.7,0.4-0.9,1.3-0.5,2s1.3,0.9,2,0.5L340.2,739.4z"/>

            <rect x="312" y="715.8" transform="matrix(0.8607 -0.5091 0.5091 0.8607 -327.0503 265.1389)" fill="#D1CCE6" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="220.5,600.4 271,680.1 257.6,683.4 210,605.5     "/>
        </g>
        <g>
          <g>
            <path fill="#EF805F" d="M597.6,727.6L773.2,669l-32-96l-175.6,58.6L597.6,727.6
              L597.6,727.6z M601.6,719.9l-28.2-84.6l163.7-54.6l28.2,84.6L601.6,719.9z"/>

            <rect x="583.1" y="605.7" transform="matrix(0.9486 -0.3164 0.3164 0.9486 -171.3397 245.2038)" fill="#D1CCE6" width="172.6" height="89.1"/>
            <g>
              <polygon fill="#0B99D6" points="605.3,725 591.2,708.4 596.5,724.5 598.8,727.2
                "/>
              <path fill="#0B99D6" d="M618.2,720.7l-4.2-5l-6.5,2.2l4.2,5L618.2,720.7L618.2,720.7z
                 M591.3,689l-10.9-12.9l5.4,16.1l10.9,12.9L591.3,689z"/>
              <path fill="#0B99D6" d="M631.1,716.4l-4.2-5l-6.5,2.2l4.2,5L631.1,716.4L631.1,716.4z
                 M580.5,656.6l-10.9-12.9l5.4,16.1l10.9,12.9L580.5,656.6z"/>
              <path fill="#0B99D6" d="M644.1,712l-4.2-5l-6.5,2.2l4.2,5L644.1,712L644.1,712z
                 M577.8,633.8l-4.2-5l-6.5,2.2l8,9.4l-1.7-5.2L577.8,633.8z"/>
              <path fill="#0B99D6" d="M657,707.7l-4.2-5l-6.5,2.2l4.2,5L657,707.7L657,707.7z
                 M590.8,629.5l-4.2-5l-6.5,2.2l4.2,5L590.8,629.5z"/>
              <path fill="#0B99D6" d="M670,703.4l-4.2-5l-6.5,2.2l4.2,5L670,703.4L670,703.4z
                 M603.7,625.2l-4.2-5l-6.5,2.2l4.2,5L603.7,625.2z"/>
              <path fill="#0B99D6" d="M682.9,699.1l-4.2-5l-6.5,2.2l4.2,5L682.9,699.1L682.9,699.1z
                 M616.7,620.9l-4.2-5l-6.5,2.2l4.2,5L616.7,620.9z"/>
              <path fill="#0B99D6" d="M695.9,694.8l-4.2-5l-6.5,2.2l4.2,5L695.9,694.8L695.9,694.8z
                 M629.6,616.5l-4.2-5l-6.5,2.2l4.2,5L629.6,616.5z"/>
              <path fill="#0B99D6" d="M708.8,690.5l-4.2-5l-6.5,2.2l4.2,5L708.8,690.5L708.8,690.5z
                 M642.6,612.2l-4.2-5l-6.5,2.2l4.2,5L642.6,612.2z"/>
              <path fill="#0B99D6" d="M721.8,686.1l-4.2-5l-6.5,2.2l4.2,5L721.8,686.1L721.8,686.1z
                 M655.5,607.9l-4.2-5l-6.5,2.2l4.2,5L655.5,607.9z"/>
              <path fill="#0B99D6" d="M734.7,681.8l-4.2-5L724,679l4.2,5L734.7,681.8L734.7,681.8z
                 M668.5,603.6l-4.2-5l-6.5,2.2l4.2,5L668.5,603.6z"/>
              <path fill="#0B99D6" d="M747.7,677.5l-4.2-5l-6.5,2.2l4.2,5L747.7,677.5L747.7,677.5z
                 M681.4,599.3l-4.2-5l-6.5,2.2l4.2,5L681.4,599.3z"/>
              <path fill="#0B99D6" d="M760.6,673.2l-4.2-5l-6.5,2.2l4.2,5L760.6,673.2L760.6,673.2z
                 M694.4,595l-4.2-5l-6.5,2.2l4.2,5L694.4,595z"/>
              <path fill="#0B99D6" d="M772.9,668.1L762,655.2l3.4,10.1l-2.4,0.8l4.2,5l6.1-2
                L772.9,668.1L772.9,668.1z M707.3,590.6l-4.2-5l-6.5,2.2l4.2,5L707.3,590.6z"/>
              <path fill="#0B99D6" d="M762.1,635.7l-10.9-12.9l5.4,16.1l10.9,12.9L762.1,635.7
                L762.1,635.7z M720.3,586.3l-4.2-5l-6.5,2.2l4.2,5L720.3,586.3z"/>
              <path fill="#0B99D6" d="M751.3,603.4l-10.9-12.9l5.4,16.1l10.9,12.9L751.3,603.4
                L751.3,603.4z M733.2,582l-4.2-5l-6.5,2.2l4.2,5L733.2,582z"/>
              <polygon fill="#0B99D6" points="735.5,574.9 745.9,587.2 741.2,573         "/>
            </g>
          </g>
          <g>
            <path fill="#FFFFFF" d="M666.5,613.4l2-0.7l0,0c0.2,0.8,1.1,1.2,1.8,0.9
              s1.2-1.1,0.9-1.8l0,0l1.3-0.4l0,0c0.3,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0c0.2,0.8,1.1,1.2,1.8,0.9
              c0.7-0.2,1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0c0.3,0.7,1.1,1.2,1.8,0.9c0.7-0.2,1.2-1.1,0.9-1.8l0,0l1.2-0.4l0,0
              c0.3,0.8,1.1,1.2,1.8,0.9s1.2-1.1,0.9-1.8l0,0l2-0.7l0.6,1.7c-0.7,0.3-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2
              c-0.7,0.3-1.2,1.1-0.9,1.8c0.2,0.7,1.1,1.2,1.8,0.9l0.4,1.2c-0.7,0.2-1.2,1.1-0.9,1.8c0.2,0.7,1.1,1.2,1.8,0.9l0.4,1.2
              c-0.7,0.3-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.3c-0.7,0.2-1.2,1.1-0.9,1.8c0.2,0.7,1.1,1.2,1.8,0.9l0.4,1.2
              c-0.7,0.3-1.2,1.1-0.9,1.8c0.2,0.7,1.1,1.2,1.8,0.9l0.4,1.2c-0.7,0.2-1.2,1.1-0.9,1.8c0.2,0.8,1.1,1.2,1.8,0.9l0.4,1.2
              c-0.7,0.3-1.2,1.1-0.9,1.8s1.1,1.2,1.8,0.9l0.6,1.7l-2,0.7l0,0c-0.2-0.7-1.1-1.2-1.8-0.9c-0.7,0.2-1.2,1.1-0.9,1.8l0,0l-1.2,0.4
              l0,0c-0.2-0.7-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l0,0l-1.2,0.4l0,0c-0.2-0.7-1.1-1.2-1.8-0.9c-0.7,0.2-1.2,1.1-0.9,1.8l0,0
              l-1.2,0.4l0,0c-0.3-0.8-1.1-1.2-1.8-0.9s-1.2,1.1-0.9,1.8l0,0l-1.3,0.4l0,0c-0.3-0.8-1.1-1.2-1.8-0.9c-0.7,0.2-1.2,1.1-0.9,1.8
              l0,0l-2,0.7l-0.6-1.7c0.8-0.2,1.2-1.1,0.9-1.8c-0.3-0.8-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.3,1.2-1.1,0.9-1.8
              c-0.2-0.7-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.2,1.2-1.1,0.9-1.8c-0.2-0.7-1.1-1.2-1.8-0.9l-0.4-1.2c0.8-0.3,1.2-1.1,0.9-1.8
              c-0.2-0.8-1.1-1.2-1.8-0.9l-0.4-1.3c0.8-0.3,1.2-1.1,0.9-1.8s-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.3,1.2-1.1,0.9-1.8
              c-0.2-0.7-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.3,1.2-1.1,0.9-1.8c-0.2-0.7-1.1-1.2-1.8-0.9l-0.4-1.2c0.7-0.3,1.2-1.1,0.9-1.8
              c-0.2-0.7-1.1-1.2-1.8-0.9L666.5,613.4z"/>

            <rect x="674.3" y="611.9" transform="matrix(0.9486 -0.3164 0.3164 0.9486 -163.0665 248.3757)" fill="#EF805F" width="18" height="28.9"/>
          </g>
          <polygon fill="#189AD7" points="598.8,655.2 641,626.3 643,633.5 601.8,660.8     "/>
          <polygon fill="#189AD7" points="604.9,663.7 647,634.8 649,642 607.8,669.3     "/>
          <polygon fill="#189AD7" points="612,674.6 636.8,657.6 638,661.8 613.8,677.9     "/>
        </g>
        <g>

          <rect x="906" y="630.3" transform="matrix(0.8631 -0.505 0.505 0.8631 -207.1198 597.2832)" fill="#96CBCC" width="184.5" height="100.8"/>
          <path fill="#8BB3B2" d="M893.1,683.9l89.8,8.7c12.9,1.3,27.6-6.9,32.5-18.2l36.9-83.6
            L893.1,683.9z"/>
          <g>
            <path fill="#0B99D6" d="M1004,669.5c-3.6,2.1-6,5.5-7,9.2s-0.5,7.8,1.6,11.4
              c2.1,3.6,5.5,6,9.2,7s7.8,0.5,11.4-1.6c3.6-2.1,6-5.5,7-9.2s0.5-7.8-1.6-11.4c-2.1-3.6-5.5-6-9.2-7
              C1011.7,667,1007.6,667.4,1004,669.5L1004,669.5z M999.4,679.3c0.8-3.1,2.8-6,5.9-7.7c3-1.8,6.5-2.1,9.6-1.3s6,2.8,7.7,5.9
              c1.8,3,2.1,6.5,1.3,9.6c-0.8,3.1-2.8,6-5.9,7.7c-3,1.8-6.5,2.1-9.6,1.3s-6-2.8-7.7-5.9C998.9,685.9,998.6,682.4,999.4,679.3z"/>
            <path fill="#0B99D6" d="M1007.1,678.2c0.4,0.2,0.9,0.1,1.1-0.3c0.2-0.4,0.1-0.9-0.3-1.1
              c0,0-9.9-5.4-13.8,2.7c-1.3,2.7-2,4.5-2.4,5.7c-0.7,1.7-0.8,2-1.9,2.4c-0.6,0.2-1.4-0.1-2.5-0.4c-2.6-0.8-6.3-2-11.3,1.1
              c-4.3,2.6-4.5,4.4-4.7,6.5c-0.2,1.4-0.4,2.9-2.1,5.2c-2,2.6-4.1,3.5-6,3.6s-3.7-0.6-5-1.3c-3.2-1.7-6.9,0.5-6.9,0.5
              c-0.4,0.2-0.5,0.7-0.3,1.1c0.2,0.4,0.7,0.5,1.1,0.3c0,0,2.9-1.7,5.4-0.5c1.5,0.8,3.5,1.6,5.8,1.4c2.3-0.1,4.8-1.2,7.2-4.2
              c2-2.6,2.3-4.4,2.5-5.9c0.2-1.7,0.4-3.1,4-5.3c4.4-2.7,7.6-1.7,10-1c1.3,0.4,2.4,0.7,3.4,0.4c1.9-0.6,2.1-1,3-3.3
              c0.4-1.2,1.1-2.9,2.4-5.6C998.7,673.7,1007.1,678.2,1007.1,678.2L1007.1,678.2z M1014.6,691c0,0-8.4-4.6-11.6,2
              c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1,0.3-2.1,0-3.4-0.4c-2.4-0.7-5.6-1.7-10,1c-3.6,2.2-3.8,3.6-4,5.3
              c-0.2,1.6-0.4,3.4-2.5,5.9c-2.4,3-4.9,4.1-7.2,4.2c-2.3,0.1-4.3-0.7-5.8-1.4c-2.4-1.3-5.4,0.5-5.4,0.5c-0.4,0.2-0.9,0.1-1.1-0.3
              c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.3,0.7,3.1,1.4,5,1.3c1.9-0.1,4-1.1,6-3.6c1.8-2.2,2-3.8,2.1-5.2
              c0.3-2.1,0.5-3.9,4.7-6.5c5-3.1,8.6-1.9,11.3-1.1c1.1,0.3,1.9,0.6,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4c0.5-1.2,1.1-3,2.4-5.7
              c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1C1015.5,691.1,1015,691.2,1014.6,691L1014.6,691z M1012.7,687.8
              c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1,0.3-2.1,0-3.4-0.4c-2.4-0.7-5.6-1.7-10,1
              c-3.6,2.2-3.8,3.6-4,5.3c-0.2,1.6-0.4,3.4-2.5,5.9c-2.4,3-4.9,4.1-7.2,4.2c-2.3,0.1-4.3-0.7-5.8-1.4c-2.4-1.3-5.4,0.5-5.4,0.5
              c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.3,0.7,3.1,1.4,5,1.3c1.9-0.1,4-1.1,6-3.6
              c1.8-2.2,2-3.8,2.1-5.2c0.3-2.1,0.5-3.9,4.7-6.5c5-3.1,8.6-1.9,11.3-1.1c1.1,0.3,1.9,0.6,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4
              c0.5-1.2,1.1-3,2.4-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1C1013.6,687.9,1013.1,688,1012.7,687.8L1012.7,687.8z
               M1010.9,684.6c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1,0.3-2.1,0-3.4-0.4c-2.4-0.7-5.6-1.7-10,1
              c-3.6,2.2-3.8,3.6-4,5.3c-0.2,1.6-0.4,3.4-2.5,5.9c-2.4,3-4.9,4.1-7.2,4.2c-2.3,0.1-4.3-0.7-5.8-1.4c-2.4-1.3-5.4,0.5-5.4,0.5
              c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.3,0.7,3.1,1.4,5,1.3c1.9-0.1,4-1.1,6-3.6
              c1.8-2.2,2-3.8,2.1-5.2c0.3-2.1,0.5-3.9,4.7-6.5c5-3.1,8.6-1.9,11.3-1.1c1.1,0.3,1.9,0.6,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4
              c0.5-1.2,1.1-3,2.4-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1C1011.7,684.7,1011.2,684.8,1010.9,684.6L1010.9,684.6
              z M1009,681.4c0,0-8.4-4.6-11.6,2c-1.3,2.7-1.9,4.4-2.4,5.6c-0.9,2.4-1.1,2.8-3,3.3c-1,0.3-2.1,0-3.4-0.4c-2.4-0.7-5.6-1.7-10,1
              c-3.6,2.2-3.8,3.6-4,5.3c-0.2,1.6-0.4,3.4-2.5,5.9c-2.4,3-4.9,4.1-7.2,4.2c-2.3,0.1-4.3-0.7-5.8-1.4c-2.4-1.3-5.4,0.5-5.4,0.5
              c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0,0,3.7-2.2,6.9-0.5c1.3,0.7,3.1,1.4,5,1.3c1.9-0.1,4-1.1,6-3.6
              c1.8-2.2,2-3.8,2.1-5.2c0.3-2.1,0.5-3.9,4.7-6.5c5-3.1,8.6-1.9,11.3-1.1c1.1,0.3,1.9,0.6,2.5,0.4c1.2-0.3,1.3-0.6,1.9-2.4
              c0.5-1.2,1.1-3,2.4-5.7c3.9-8.1,13.8-2.7,13.8-2.7c0.4,0.2,0.5,0.7,0.3,1.1C1009.9,681.5,1009.4,681.6,1009,681.4z"/>
          </g>
        </g>
        <g>

          <rect x="422.8" y="647.4" transform="matrix(0.9941 -0.1081 0.1081 0.9941 -74.3992 63.9182)" fill="#96CBCC" width="259.1" height="141.6"/>
          <path fill="#8BB3B2" d="M673.5,633.9l-94.1,84.9c-13.5,12.2-36.9,15.2-52,6.6
            l-111.5-63.5L673.5,633.9z"/>
          <g>
            <path fill="#0B99D6" d="M536.3,710.4c5.8-0.6,11.3,1.2,15.6,4.5
              c4.2,3.4,7.2,8.4,7.8,14.2s-1.2,11.3-4.5,15.6c-3.4,4.2-8.4,7.2-14.2,7.8s-11.3-1.2-15.6-4.5c-4.2-3.4-7.2-8.4-7.8-14.2
              s1.2-11.3,4.5-15.6C525.4,713.9,530.4,711,536.3,710.4L536.3,710.4z M549.7,717.5c-3.6-2.9-8.2-4.4-13.1-3.8
              c-4.9,0.5-9.1,3-12,6.5c-2.9,3.6-4.4,8.2-3.8,13.1c0.5,4.9,3,9.1,6.5,12c3.6,2.9,8.2,4.4,13.1,3.8c4.9-0.5,9.1-3,12-6.5
              c2.9-3.6,4.4-8.2,3.8-13.1C555.7,724.6,553.2,720.4,549.7,717.5z"/>
            <path fill="#0B99D6" d="M540.1,722.8c-0.3,0.6-0.9,0.8-1.5,0.5s-0.8-0.9-0.5-1.5
              c0,0,6.7-14.3,17.8-8.5c3.7,2,6,3.4,7.5,4.4c2.2,1.4,2.6,1.6,4.2,1.1c0.8-0.3,1.5-1.3,2.5-2.6c2.3-3.1,5.5-7.5,13.7-8.2
              c7-0.6,8.7,1.2,10.8,3.3c1.3,1.4,2.9,3,6.7,4c4.5,1.2,7.6,0.5,9.8-0.9c2.2-1.5,3.6-3.7,4.6-5.6c2.3-4.6,8.2-5.2,8.2-5.2
              c0.6-0.1,1.2,0.4,1.2,1c0.1,0.6-0.4,1.2-1,1.2c0,0-4.8,0.5-6.5,4c-1.1,2.1-2.7,4.8-5.4,6.5c-2.7,1.8-6.4,2.6-11.6,1.2
              c-4.5-1.2-6.2-3-7.8-4.6c-1.7-1.8-3-3.2-9-2.7c-7.2,0.6-10,4.5-12.1,7.3c-1.2,1.6-2.1,2.9-3.5,3.4c-2.6,1-3.1,0.6-6.1-1.3
              c-1.5-1-3.7-2.4-7.4-4.3C545.8,710.6,540.1,722.7,540.1,722.8L540.1,722.8z M542.3,743.5c0,0,5.7-12.2,14.7-7.4
              c3.7,1.9,5.9,3.3,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.7,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7
              c1.5,1.6,3.3,3.4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.7-1.7,4.3-4.3,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2
              c-0.1-0.6-0.6-1.1-1.2-1c0,0-6,0.7-8.2,5.2c-0.9,1.9-2.3,4.1-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9c-3.9-1.1-5.4-2.6-6.7-4
              c-2.1-2.2-3.8-3.9-10.8-3.3c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.4,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4
              c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.6,0,1.2,0.5,1.5C541.4,744.3,542.1,744,542.3,743.5L542.3,743.5z M541.8,738.3
              c0,0,5.7-12.2,14.7-7.4c3.7,1.9,5.9,3.3,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.7,12.1-7.3
              c5.9-0.5,7.3,0.9,9,2.7c1.5,1.6,3.3,3.4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.7-1.7,4.3-4.3,5.4-6.5c1.7-3.5,6.5-4,6.5-4
              c0.6-0.1,1.1-0.6,1-1.2c-0.1-0.6-0.6-1.1-1.2-1c0,0-6,0.7-8.2,5.2c-0.9,1.9-2.3,4.1-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9
              c-3.9-1.1-5.4-2.6-6.7-4c-2.1-2.2-3.8-3.9-10.8-3.3c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.5,2.6c-1.6,0.6-2,0.3-4.2-1.1
              c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.6,0,1.2,0.5,1.5C540.8,739.1,541.5,738.8,541.8,738.3L541.8,738.3z
               M541.2,733.1c0,0,5.7-12.2,14.7-7.4c3.7,1.9,5.9,3.3,7.4,4.3c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4
              c2-2.8,4.9-6.7,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c1.5,1.6,3.3,3.4,7.8,4.6c5.2,1.4,8.9,0.6,11.6-1.2c2.7-1.7,4.3-4.3,5.4-6.5
              c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2c-0.1-0.6-0.6-1.1-1.2-1c0,0-6,0.7-8.2,5.2c-0.9,1.9-2.3,4.1-4.6,5.6
              c-2.2,1.4-5.4,2.1-9.8,0.9c-3.9-1.1-5.4-2.6-6.7-4c-2.1-2.2-3.8-3.9-10.8-3.3c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.4,2.6
              c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5c-0.3,0.6,0,1.2,0.5,1.5
              C540.3,733.9,540.9,733.7,541.2,733.1L541.2,733.1z M540.6,727.9c0,0,5.7-12.2,14.7-7.4c3.7,1.9,5.9,3.3,7.4,4.3
              c3,1.9,3.5,2.2,6.1,1.3c1.4-0.5,2.3-1.8,3.5-3.4c2-2.8,4.9-6.7,12.1-7.3c5.9-0.5,7.3,0.9,9,2.7c1.5,1.6,3.3,3.4,7.8,4.6
              c5.2,1.4,8.9,0.6,11.6-1.2c2.7-1.7,4.3-4.3,5.4-6.5c1.7-3.5,6.5-4,6.5-4c0.6-0.1,1.1-0.6,1-1.2c-0.1-0.6-0.6-1.1-1.2-1
              c0,0-6,0.7-8.2,5.2c-0.9,1.9-2.3,4.1-4.6,5.6c-2.2,1.4-5.4,2.1-9.8,0.9c-3.9-1.1-5.4-2.6-6.7-4c-2.1-2.2-3.8-3.9-10.8-3.3
              c-8.2,0.7-11.4,5-13.7,8.2c-0.9,1.3-1.7,2.3-2.4,2.6c-1.6,0.6-2,0.3-4.2-1.1c-1.5-1-3.8-2.4-7.5-4.4c-11.1-5.9-17.8,8.4-17.8,8.5
              c-0.3,0.6,0,1.2,0.5,1.5C539.7,728.7,540.4,728.5,540.6,727.9z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
