import React from 'react';

import 'app/components/activities-list/activities-list.scss';
import clsx from 'clsx';
import { array, string } from 'prop-types';

import { Chest } from 'app/components/activities-list/chest/chest.component';

export const ActivitiesList = props => {
  const { classNames, firstEncounters, readyToDropChest } = props;

  return (
    <div className={clsx(
      'activity-list-box',
      classNames)}
    >
      <div className="vertical-line slide-left" />
      <ul className='activity-list'>
        {readyToDropChest && firstEncounters && firstEncounters.length ?
          firstEncounters.map((value, index) => {
            return (<li className='activity-item' key={index}>
              <div className='activity-word text-focus-in'>{value.wordActivity.word}</div>
            </li>);
          })
          : null
        }
        {readyToDropChest && <Chest classNames="slide-in-top" />}
      </ul>
      <div className="vertical-line slide-right" />
    </div>
  );
};

ActivitiesList.propTypes = {
  classNames: string,
  firstEncounters: array
};
