import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { EXTERNAL_SOURCES } from 'app/constants/external-sources.constant';
import { SessionStorageService } from 'app/services/session-storage.service';
import { resetRequest } from 'app/store/actions/api-requests.action';
import { profileDataActionCreators } from 'app/store/actions/profile-data.actions';
import { wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isIdle, isSuccess } from 'app/store/reducers/api-status.reducer';

export const useFirstEncounters = () => {
  const dispatch = useDispatch();

  const { currentProfile } = useSelector(state => state.profileData);
  const { finishedPlay } = useSelector(state => state.wordsData);
  const { setCklaProfileStatus, setArProfileStatus, getCklaFirstEncountersStatus, getArFirstEncountersStatus } = useSelector(state => state.apiReducer);

  useEffect(() => {
    const externalSource = SessionStorageService.getExternalSource();
    if (!SessionStorageService.getPendingActivities()
      || !SessionStorageService.getPendingActivities().length
      || SessionStorageService.getExternalSource() === EXTERNAL_SOURCES.READING) {
      switch (externalSource) {
      case EXTERNAL_SOURCES.READING:
        dispatch(profileDataActionCreators.setArProfile.request({
          teacherGrade: SessionStorageService.getTeacherGrade(),
          questId: SessionStorageService.getQuestId()
        }));
        break;
      case EXTERNAL_SOURCES.CKLA_HUB:
        dispatch(profileDataActionCreators.setCklaProfile.request());
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess(setCklaProfileStatus) && currentProfile.csUserBusinessKey && isIdle(getCklaFirstEncountersStatus)) {
      dispatch(wordsDataActionCreators.getCklaFirstEncounters.request(currentProfile));
    }
  }, [setCklaProfileStatus, getCklaFirstEncountersStatus]);

  useEffect(() => {
    if (isSuccess(setArProfileStatus) && currentProfile.csUserBusinessKey && isIdle(getArFirstEncountersStatus)) {
      dispatch(wordsDataActionCreators.getArFirstEncounters.request(currentProfile));
    }
  }, [setArProfileStatus, getArFirstEncountersStatus]);

  useEffect(() => {
    if (finishedPlay) {
      if (isSuccess(setCklaProfileStatus) && currentProfile.csUserBusinessKey)
        dispatch(resetRequest('getCklaFirstEncountersStatus'));
      if (isSuccess(setArProfileStatus) && currentProfile.csUserBusinessKey)
        dispatch(resetRequest('getArFirstEncountersStatus'));
    }
  }, [finishedPlay]);
  
};
