import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const DoctorTitle = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .set('#firstaidkit', { transformOrigin: '50% 50%', scale: 0.5, opacity: 0, x: 50, y:50 })
      .set('#stethescope', { transformOrigin: '50% 50%', scale: 0.5, opacity: 0, x: 40, y:40 })
      .set('#title-clip', { x: -420 })
      .set('#xray_head', { x: -350 })
      .set('#xray_hand', { y: 400 })
      .set('#xray_foot', { y: 350 })
      .set('#xray_foot-top', {  y: 350 })
      .set('#xray_knee', { x: 350 })
      .set('#xray_chest', { y: -350 })

      .to('#title-full', { duration: 0.1, opacity: 1, delay: 0.9 })
      .to('#firstaidkit', { duration: 0.5, scale: 1, opacity: 1, x: 0, y: 0, ease: 'back.out' })
      .add(gsap.to('#stethescope', { duration: 0.5, scale: 1, opacity: 1, x: 0, y: 0, ease: 'back.out' }), 1.1)
      .to('#title-clip', { duration: 2, x: 0, ease: 'none' })
      .add(gsap.to('#pulse-path', { duration: 2, strokeDashoffset: 0, ease: 'none' }), 2.5)
      .add(gsap.to('#xray_head', { duration: 3, x: 0, ease: 'elastic.out' }), 1.5)
      .add(gsap.to('#xray_hand', { duration: 3, y: 0, ease: 'elastic.out' }), 1.5)
      .add(gsap.to('#xray_foot', { duration: 3, y: 0, ease: 'elastic.out' }), 1.6)
      .add(gsap.to('#xray_foot-top', { duration: 3, y: 0, ease: 'elastic.out' }), 1.5)
      .add(gsap.to('#xray_knee', { duration: 3, x: 0, ease: 'elastic.out' }), 1.6)
      .add(gsap.to('#xray_chest', { duration: 3, y: 0, ease: 'elastic.out' }), 1.7);

    tl.play(0);

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg id="title-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680" opacity="0">
      <defs>
        <clipPath id="clip-path">
          <rect x="568" y="66" width="238" height="160" rx="3.31" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-2">
          <rect y="880" width="1024" height="680" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-3">
          <rect id="title-clip" x="331" y="362" width="423" height="78" fill="none"/>
        </clipPath>
      </defs>
      <title>Doctor</title>
      <rect width="1024" height="680" fill="#040000"/>
      <g id="xray_hand" opacity="0.7">
        <path d="M59.16,609.79,248.5,610a15.18,15.18,0,0,0,15.19-15.17L264,309.41a15.18,15.18,0,0,0-15.16-15.2L59.5,294a15.18,15.18,0,0,0-15.19,15.17L44,594.59A15.18,15.18,0,0,0,59.16,609.79Z" fill="#413c47"/>
        <g opacity="0.46">
          <path d="M188.93,609.93s0-51.35.07-58.41,8.39-13.69,12.57-40.1c3.89-24.55,5.83-55.8,7.57-66.81s14.43-52.41,12.37-59.89-7.15-5.1-10.83-.22S192.31,440,191.17,441.36c-2,2.37-3.36-1.9-2-6.94,1.64-6.28,16.65-68.92,17.11-82.21s-12.59-8.39-15-1.71-14.62,59.56-18.62,72-4.94,8-5.58,4.5c-1.45-7.81.17-53.22.64-68.58s-3.2-29.91-5.42-30.8c-6.89-2.74-14,4.57-14,25.34,0,10.39,2.2,34.06-2.4,81.18-.59,6-4.25-.48-7.84-10.73s-13.08-47.63-16.18-61.34-8.08-16.62-11.81-16.62-6.42,6.9-4.59,18.32c1.43,8.91,8.8,59.76,9.41,68.48s6.78,29.69,2,32.24c-8.66,4.64-19.39-11.13-21.47-12.56S89.77,441.64,81.48,436s-18.13-4.19-20.21-2.09c-1.71,1.71,8.52,15.85,13.91,22.18s20.54,27.32,24,38c1.42,4.34,8.65,27,16.24,37.81A67.42,67.42,0,0,1,127,560.17c1.44,7.89-3.17,49.69-3.17,49.69Z" fill="#a1b3c2"/>
        </g>
        <g>
          <path d="M218.62,389s-1.58,12.32-3,14.81-6.41.44-8.14-1.12,3.66-15.39,6.43-16S219.34,385.58,218.62,389Z" fill="#a1b3c2"/>
          <path d="M215.54,406.32s-5.8,2.23-9.48-1.72c-1-1-1.36,4.15-2.33,8.3s-3.49,7.26-3.88,9.13,3.09,2.7,4.45,3.12,3.48,2.08,5.22,2.5.78-4.15,2.14-9.34S217.05,407.56,215.54,406.32Z" fill="#a1b3c2"/>
          <path d="M204.82,428.33s3.54.6,3.72,2.47-2.61,5.4-4.67,11.21-1.13,11.42-2.44,14.74-4.84.2-5.77-1.05-5.4.21-6.33-1.46,2.43-7.86,4.86-12.22,2.48-15,4.7-16S204.82,428.33,204.82,428.33Z" fill="#a1b3c2"/>
          <path d="M201.6,354.7s-1.89,18.72-3.55,22.51-7.68.67-9.75-1.7,4.38-23.38,7.7-24.33S202.47,349.5,201.6,354.7Z" fill="#a1b3c2"/>
          <path d="M197.63,380s-6.22,3.4-10.17-2.61c-1-1.58-1.45,6.31-2.5,12.62s-4.17,13.88-4.58,16.72,4.57,1.76,6,2.4,2.88,2.66,4.75,3.29.84-6.31,2.3-14.2S199.25,381.89,197.63,380Z" fill="#a1b3c2"/>
          <path d="M187,411.73s3.94.8,4.15,3.28-2.91,7.15-5.21,14.86-1.26,15.14-2.72,19.55-4.91-.39-8-1c-3.58-.69-6.87,1.65-7.52-1.32-.52-2.38,3.13-11.69,5.83-17.47s3.4-18.42,5.89-19.79S187,411.73,187,411.73Z" fill="#a1b3c2"/>
          <path d="M108.12,353.73s1.85,18.73,3.51,22.52,7.67.69,9.75-1.68-4.33-23.39-7.65-24.34S107.27,348.53,108.12,353.73Z" fill="#a1b3c2"/>
          <path d="M112,379.06s6.21,3.4,10.17-2.59c1-1.58,1.44,6.31,2.48,12.62s4.13,13.89,4.54,16.73-4.58,1.75-6,2.38-2.9,2.66-4.76,3.28-.83-6.31-2.27-14.2S110.41,380.93,112,379.06Z" fill="#a1b3c2"/>
          <path d="M122.59,410.8s-4,.78-4.16,3.26,2.9,7.16,5.17,14.88,1.23,15.14,2.68,19.55,4.92-.38,8-1c3.59-.68,6.87,1.66,7.53-1.3.53-2.38-3.1-11.7-5.79-17.49s-3.36-18.42-5.84-19.8S122.59,410.8,122.59,410.8Z" fill="#a1b3c2"/>
          <path d="M161.09,338.72s2.29,20.09,1.5,24.42-7.37,2.28-9.92.22-.88-25.51,2.17-27.19S160.79,333.06,161.09,338.72Z" fill="#a1b3c2"/>
          <path d="M162.8,366.17s-5.34,4.86-10.53-.65c-1.36-1.45,0,6.94.34,13.79s-1,15.46-.79,18.54,4.87.91,6.43,1.28,3.41,2.2,5.38,2.48-.58-6.81-.89-15.41S164.8,367.82,162.8,366.17Z" fill="#a1b3c2"/>
          <path d="M159.58,402.51s4,0,4.78,2.59-1.27,8.13-1.81,16.71,2.11,16.19,1.65,21.13-4.9.6-8,.61c-3.66,0-6.36,3.15-7.66.16-1-2.39.48-12.94,1.85-19.58s-.74-20.08,1.39-22S159.58,402.51,159.58,402.51Z" fill="#a1b3c2"/>
          <path d="M191.12,456.08s4.17,2,5,5.17-2.71,7.91-4.17,11.44-3.23,21.8-4.22,25.12-7,3.31-9.71,2.48-3.32-2.29-5.39-2.08.51-6.37,3.62-8.85,4.82-15.85,5.33-20.21C182.36,462.74,183,456.07,191.12,456.08Z" fill="#a1b3c2"/>
          <path d="M169.59,451.25s4.78-2.73,8.93,0,.19,11.65-1.47,13.73-5.35,17.68-3.35,25.95-8.72,7.26-10.59,6.84-6-2.91-5.26-5.4,4.31-3.08,7.36-14C171.46,456.05,164,457.53,169.59,451.25Z" fill="#a1b3c2"/>
          <path d="M139.93,451.64s-4.73-2.81-8.92-.15-.42,11.65,1.21,13.75,5,17.78,2.86,26,8.59,7.42,10.46,7,6-2.81,5.36-5.31-4.24-3.16-7.09-14.12C138,456.41,145.42,458,139.93,451.64Z" fill="#a1b3c2"/>
          <path d="M149.9,447.21s4.37-4.08,9.37-2,3,12.76,1.71,15.41-1.42,20.63,2.64,29.25-7.43,9.95-9.49,9.92-5.92-2-5.91-7.28c0-6.15,2.89-1.82,3.51-14.48C153,452.06,145.5,455.38,149.9,447.21Z" fill="#a1b3c2"/>
          <path d="M131.77,502.14s-.24-7.6-2.91-9.27-3.72-.52-8.4-5.26c-5.61-5.68-6.56-6.1-6.91-9.76s-2.94-6.14-8.69-1.48c-6.64,5.4-7.72,8.4-1.5,10.88s12.61,6.23,13.77,11S126.57,509.74,131.77,502.14Z" fill="#a1b3c2"/>
          <path d="M107.77,470.78a8.15,8.15,0,0,0,0-4.78c-.83-2.28-6.5-1.25-9.06-2.71s-8.16-4.37-9.19-8.11-1.65-6.85-3.94-6.23-3.32,1.8-5.81,3.39-1.87,5.25,1.45,6,10,7.42,12,10.33,1.43,9.35,5.51,9.15S107.5,472,107.77,470.78Z" fill="#a1b3c2"/>
          <path d="M84.24,446.66s-.08-1.27-1.77-3.81-6-2.39-8.17-4.42-10.11-4.53-10.84-2.5,2.17,5.34,5.07,8.39,7,9.7,9,8.14S80.62,447.71,84.24,446.66Z" fill="#a1b3c2"/>
          <path d="M179.35,503.24s-3.37-1.64-7.44-3.45-8.77-.23-9.81,0,.63,5,1.25,8.52,1.14,8.93,3.57,9.77,5.55-4.77,7-7.05S181.48,504.25,179.35,503.24Z" fill="#a1b3c2"/>
          <path d="M157.84,499.77s.76,3.06,2.61,3.45.18,8.82,1.64,11.52,2.45,4.36,2.36,5.19-3.9,3.74-9.77,2.9-8.31-5.62-6-10.6-.32-11.19,1.14-11.51S156.32,500.72,157.84,499.77Z" fill="#a1b3c2"/>
          <path d="M147,499.76a10.19,10.19,0,0,1,1.78,6c-.13,3.53-.55,5.81-1.79,6.43s-6.23-1-10,1.65-7.06,3.32-7.89,2.07-3.52-5-1-7.8,9.07-4.86,9.83-6.73S147,499.76,147,499.76Z" fill="#a1b3c2"/>
          <path d="M178.7,508.55s3.46-1.06,6.64,3.93,1.23,8.45-.71,9.41-6.36,3.73-7.89,5.53-7-1.11-7.73-2.78-1.51-4.15,0-5.67S177.46,509.2,178.7,508.55Z" fill="#a1b3c2"/>
          <path d="M166.91,521.46s.9,5,3.85,6.52-1.77,7-5.09,7.46-10.1,1.37-10.79,0-1.38-9.83,0-10.66,10.39-3.31,11.21-3.31Z" fill="#a1b3c2"/>
          <path d="M153.37,524.21s-1,8.17-.57,9.83-7.88,0-9.68-2.23-4.7-4.43-6.49-6.65-3.73-6.09-2.76-7.61,5.54-4.29,11.76-4.28S140.37,518.52,153.37,524.21Z" fill="#a1b3c2"/>
          <path d="M182.54,542.65s.28-3.87-1.66-3.73-3.18,2.76-4.57,2.62-4.56-1.53-6.36-.77-6.83,5.19-2.38,9.62-.07,59.52-.07,59.52l13.58,0a133.22,133.22,0,0,1-1.81-34.2C180.72,556.91,182.54,542.65,182.54,542.65Z" fill="#a1b3c2"/>
          <path d="M162.86,550.52c1.89-2.52,2.25-7.47,0-12.59-.42-1-4,2.35-10.21,0s-14.11-4.42-16.87-9.15-6.14-2.23-5.77,3.58,2.85,11.63,4.63,26,2.3,51.5,2.3,51.5l18.54,0s-3.23-28.77-1.45-41.79S159.91,554.46,162.86,550.52Z" fill="#a1b3c2"/>
        </g>
      </g>
      <g id="xray_head" opacity="0.7">
        <rect x="44" y="75" width="220" height="208" rx="3.1" fill="#413c47"/>
        <path d="M225.85,136.24c-4.88-10.23-27.4-21.67-36.22-24.5-10.5-3.37-45.69-1.74-60.92,2.27-6.58,1.73-20,12.89-24.55,21s-5.47,18.38-5.32,23.43,2.51,7.26,2.07,9.78-7.24,7.57-7.24,7.57-.6,1,1,4.59,1.92,5.49,1.92,6.53,1.43,2.82,1,8.9-.92,6.12-2.31,8.77c-1,1.89-1.34,2.35-2.52,8.87-.56,3.11,1.3,1.14,2.71.81s1.35.78,3.81.68,4.63,1.1,6.37.34,11.24-1.71,15.67-1.3c2.1.19,2.45,2.3,2.74,2.88s-1.83,1-2,1.25-7.52.86-11.31,1.58a123.4,123.4,0,0,1-13.12,2c-2.65.08-4.67,1.27-5.36.17-.09-.15-.08.7,0,.91.39,2.67-.06,5.35.1,6.23.39,2.23,1.38,3.76.34,10.58s-1.18,9.19-1.77,12.31,4.73,4.3,9.31,4.15,12.13-1,16.27-4,11.23-6.08,22.32-7.71,18.78-1.49,21.29-5.94,3.11-20.76,4.74-26.69c0,0,10.2-.29,18.18,2.52s17.89,1.34,24.1-2.67,16.86-14.68,21.44-22.68S230.73,146.48,225.85,136.24Z" fill="#a1b3c2"/>
        <path d="M229.89,134.12c-4.88-10.23-28.15-29.07-37.06-31.64-13.74-4-51,3.56-65.45,9.89-6.23,2.73-18.63,14.53-23.22,22.69s-5.47,18.38-5.32,23.43,24.1,46.87,84.13,55.75c8.38,1.24,17.89,1.34,24.1-2.67s16.86-14.68,21.44-22.68S234.77,144.35,229.89,134.12Z" fill="#a1b3c2"/>
        <path d="M92.88,213.69s-.61,4.72-.26,5.43,1.85.45,2.23-.17.44-3.6.36-4.71S92.94,213.22,92.88,213.69Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M95.55,213.43a20.09,20.09,0,0,0-.11,4.39c.23,2.07.61,2.08,1.81,1.33s2-.73,1.88-1.54,0-3.94-.93-4.39S95.63,212.69,95.55,213.43Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M100.72,224.58a14.7,14.7,0,0,0,.21-3.59c-.16-1.7-.49-1.71-1.61-1.12s-1.86.58-1.76,1.24-.1,3.22.73,3.61S100.62,225.19,100.72,224.58Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M107.71,212.28a3.17,3.17,0,0,0-.47,2.31c.11,1.47.14,2.87.45,3.22s2.56,0,3.28-.25-.1-3.85-.72-4.55S108.28,211.52,107.71,212.28Z" fill="#dde6ec"/>
        <path d="M111.8,211.69a2.87,2.87,0,0,0-.57,2.31c.11,1.48.12,2.88.48,3.23s3.55-.08,4.37-.37-.2-4.36-.91-5.05S112.46,210.92,111.8,211.69Z" fill="#dde6ec"/>
        <path d="M103.86,212.92a3.16,3.16,0,0,0-.46,2.3c.11,1.48.14,2.88.44,3.23s2.5,0,3.19-.25-.09-3.84-.7-4.54S104.41,212.16,103.86,212.92Z" fill="#dde6ec"/>
        <path d="M100.11,213.26s-.61,0-.73,1.8-.26,3.51.27,3.62a8.51,8.51,0,0,0,3.11-.24c1.28-.32.22-2.77-.3-4S100.56,212.81,100.11,213.26Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M92.84,225s-.89-4.13-.67-4.9,1.15-.66,2.07-.59c.73.06.07,3.68.19,4.79S93,225.41,92.84,225Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M95.55,225s-.88-4.13-.66-4.9,1.15-.66,2.07-.59c.73.06.07,3.68.19,4.79S95.7,225.41,95.55,225Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M108.28,223.88c-.17-.18-1.54.33-1.69-1.14s-.76-3.32-.54-3.71,3.5-.7,4.19-.54,1,3.55.52,4.35S108.92,224.53,108.28,223.88Z" fill="#dde6ec"/>
        <path d="M112.4,222.69a3,3,0,0,1-1-2.18c-.15-1.48-.35-2.32.65-2.47a14.85,14.85,0,0,1,3.47-.36c.86.14.73,3.48.15,4.29S113.19,223.32,112.4,222.69Z" fill="#dde6ec"/>
        <path d="M102.46,224.64a3.42,3.42,0,0,1-.82-2.2,10.29,10.29,0,0,1-.14-3.25c.22-.39,2.29-.46,3-.3s1.12,4.05.68,4.84S103.1,225.29,102.46,224.64Z" fill="none" stroke="#aec1d1" strokeMiterlimit="10" strokeWidth="0.47"/>
        <path d="M122.37,177.91A19.53,19.53,0,0,1,112.18,183c-5.89.75-7.93.45-8.59,1.93s3.1,10.78,4,12.06,5.12-3,7.07-6.13,5.1-5.15,6.78-7.86,2.16-3.51,2.56-6.37-1.48-9.2-2.66-10.88a27.22,27.22,0,0,0,.59,4.95C122.55,173.56,123.07,174.85,122.37,177.91Z" fill="#dde6ec"/>
        <path d="M115.5,158.49s-4-3.86-8.37-5.19-4.47-6.38-3.72-12.31,5.12-11.87,5.12-11.87-7.62,6.49-9.12,20.75,1.71,13.36,1.6,15.88,4.14-.29,3.33-3.85S112.69,155.67,115.5,158.49Z" fill="#dde6ec"/>
        <path d="M130.88,187.7s-2.37,13.35-1.63,21.65S124.19,224,122,224.1s-2.79-.35-3.53,1.12-3,1.63-4.62,1.92-1.18,1-2.94,1.84-5.31-.2-7.52.83-2.84,2.52-4.23,1.93-3.26-.15-3.07.74,2.45,7.47,3.54,11.08-1.24,6.12,0,7.31,5.71,2.82,11.68,0,10.86-3.26,15.14-4.89,15.36-1.47,22.35-2.93,10.41-.42,12.66-7.94,2.64-18.79,3.34-23.39,2.91-21.35,1.87-22.39-6.36-2-6.14-.85,2.3,17-8.79,18.35-13-4.6-14-4.75-3-12.05-3.11-14S130.88,187.7,130.88,187.7Z" fill="#94a5b3"/>
        <path d="M106.41,160.41a13.09,13.09,0,0,0-1.19,4.3c-.36,2.3-1.7,3.49-1.84,5.12s3.32,9,4.43,10.23,9.15.67,11.27-1.92,2.19-5.12.64-9.87S108.4,158.39,106.41,160.41Z" fill="#413c47"/>
        <path d="M116.39,116.52c0,.4-15.2,12.65-17.38,22.94s-4.65,18.92-2.94,22.73c2,4.53,2.4,6.73,0,9.1S84.21,180.82,82,183s-7.65,4.23-5.19,9.55c.93,2,5.38,3.24,8.93,4.42s5.72,2.2,5,5.56-2.86,7.05-2.46,9.06a18,18,0,0,0,2.46,5,19.78,19.78,0,0,0-1.87,4.56c-.4,1.94-.59,2.31,1,5.49s3.25,5,2.22,8.41-4.39,4-4.58,12.5c-.17,7.16,3,10.79,9.69,13.19,8.09,2.88,24.45.22,28.42.28s3.53,2.06,5.83,10.61a31.39,31.39,0,0,0,6,11.37h76.79s-6.31-44.59-3-58,17.53-27.83,19.61-34.35,7.4-35.2,2.87-51-12.62-21.55-25.43-31-21.1-12.85-45.35-8.9S116.39,116.52,116.39,116.52Z" fill="#a1b3c2" opacity="0.37"/>
      </g>
      <g id="xray_foot" opacity="0.7">
        <rect x="283" y="482" width="294" height="126" rx="3.94" fill="#413c47"/>
        <g>
          <path d="M476.16,500.21c-4.73,1.45-19,2.89-19.85,6.66s.58,12.18,4.34,16.23,8.36,7.41,14,5.22,14.78-5.51,15.84-8.69S482.53,498.25,476.16,500.21Z" fill="#8b9ba8"/>
          <path d="M471.05,482a15.2,15.2,0,0,0,.85,11.62c1.4,2.88,9.32,1.85,15.51,0s12.21.91,16.06,1.72,8.77-.71,9.68-2.9S511.94,482,511.94,482Z" fill="#a1b3c2"/>
          <path d="M456.51,543.76c-1.26,1.18-5.07,2.79,2.53,6s8,4.24,11.85,2.58,7.3-2.77,5.87-6.85S474,532.6,469.6,533.11,456.51,543.76,456.51,543.76Z" fill="#8b9ba8"/>
          <path d="M508.55,499.54h0a6,6,0,0,0-1.77-1.08,5,5,0,0,0-1.48-.38L505,498c-.13-.15-.25-.3-.38-.43a3.43,3.43,0,0,0-2.1-.92,25.76,25.76,0,0,0-10-2.67c-6.89-.55-11.87,2.24-17.39,6.09-2.2.92-3.84,1.8-3.9,2.45-.16,1.84-4.18,22.66,18.1,19.86a10.38,10.38,0,0,0,8.12-1.73,27.71,27.71,0,0,1,7.83-4.31c1.91-.61,4.12-.66,5.94-1.55C518.16,511.41,513.28,502.6,508.55,499.54Z" fill="#a1b3c2"/>
          <path d="M516.79,516.85s-8.64-3.48-15.07,3.48-5.86,5.78-10,5.44-6.18-2.54-9.29-2.83-7.44,6.37-5,9.27,1.92,8.14,2.21,10.44,6.84,8.14,13.81,11.57c3.18,1.56,22.57,4.68,24.7,17.22,1.13,6.63,23.27,6.72,31.28-2.21s3.71-21.63,2.71-23.71.5-6-.11-9.8-5.24-6.12-9.58-6.41S527.87,520.62,516.79,516.85Z" fill="#a1b3c2"/>
          <path d="M453.91,508.68s.6,13.63,6.38,18,9.86,4.08,10,9.28-7.32,9.25-11.17,9.56-12.3-3.26-16.29-9.56-7.42-18.46-5.85-21.4S451.64,505.79,453.91,508.68Z" fill="#94a5b3"/>
          <path d="M381.19,568.44c-.26,1.17-1.73,3,1.74,6.14s11,3,16.8,0,20-7.36,31.85-7.78,20.61,5.61,26.3,1.55,7.93-11.55,4.29-13.58-7-4.75-11.69-6.14-32.79,11-42.64,11.94S382.35,563.27,381.19,568.44Z" fill="#8b9ba8"/>
          <path d="M434.21,515.94c-1.48-2.75-1.16-2.61-12.16,0S409.59,522,407,524.34s.58,8.07,3.19,14.47,5.21,17,6.66,20.26,13.07,2.92,20.15.62,12.87-7.32,15.77-9.84-9.85-10.14-12.17-15.36S436.24,519.7,434.21,515.94Z" fill="#94a5b3"/>
          <path d="M403.79,527.76c-1.91-.24-6.38-1.1-9.27,3.25s-23.18,17.28-29.84,18.78-10.14,2.35-10.14,9.9,8.4,13.83,13.33,14.89,6.21.81,9.56-3c5.23-6,8.69-9.8,20.56-10.11s20.51-.78,21.41-4.31-7.41-9.29-6.88-11.63,1.1-6.71-.62-6.71-2.88-3.17-2.89-5.19A6.3,6.3,0,0,0,403.79,527.76Z" fill="#a1b3c2"/>
          <path d="M352.23,559.69s.3,9.81,6.37,11.75,6.66,2,6.08,5.42-15.64,3.61-18.54,2.21a19.27,19.27,0,0,0-13.32-2.21c-6.95,1.1-9.85,1.59-11.59-2.28s-3.18-6.2,0-7.78,6.67-1.41,13-4.11S351.88,556.86,352.23,559.69Z" fill="#8b9ba8"/>
          <path d="M316.89,569.66c-.71-1.14-4.06.42-6.95,1.17s-9-1-10.43.61.29,6.6,6.08,8.45,12.74,2,13.76-.82S319.35,573.61,316.89,569.66Z" fill="#8b9ba8"/>
        </g>
        <path d="M465.32,482c-.44,1.16-.06,8.83-10.23,13.56s-36.54,12.72-70.59,37.53c-31.64,23.06-45.31,19.77-53.42,24.06s-11.29,6.9-18.82,8.06S295,567,294.2,568.44s-4.39,12,10.1,16.91,25.76-1.3,29.52-.26S359.57,588,372,587.52s34.91.65,44.18-2.43,25.47-6.5,47.73-6.6,37.17,2.55,41.52,5.07,27.71,2.35,37.46,1.57,17.57-10.94,18.44-20.5.83-21.36-7.12-37.92-14-44.71-14-44.71Z" fill="#a1b3c2" opacity="0.37"/>
      </g>
      <g id="xray_knee" opacity="0.7">
        <rect x="818" y="66" width="160" height="266" rx="3.68" fill="#413c47"/>
        <path d="M844,66s39.53,59.63,45.77,74.42,0,16.44,0,29.21c0,11.09,15.26,19.05,22.43,17.81,13.33-2.3,17.78-12.66,21.07-22.61s4.43-25.39-7.17-32.63S870,66,870,66Z" fill="#dde6ec"/>
        <path d="M938.8,143.71c-1.52,5.74,3.28,12,.32,21.12s2.2,13.6,7.06,13.23c7.42-.58,9.73-16.44,10.71-25.07S940.6,137,938.8,143.71Z" fill="#dde6ec"/>
        <path d="M879.18,210.79s-6.53,4.27-8.83,11.17-1,4.52-8.23,20.35S842,332,842,332h8.23s16.14-82.09,20.09-91.62,6.37-12.5,13.88-17.76S879.18,210.79,879.18,210.79Z" fill="#94a5b3"/>
        <path d="M889.7,182.66c-4.83-2.81-14.42,4.91-13.1,19.91.78,8.89,6.25,8.87,7.63,20.05S870.35,332,870.35,332h30.1s6.44-56.44,12-73.21,19.06-41.85,18.07-46.78S934,197.3,931,192.7,913.14,196.32,889.7,182.66Z" fill="#dde6ec"/>
        <path d="M903.34,158.15c3.39,0,9.47-3.59,12.44-7s8.06-6.74,4.36-11.67S865.74,66,865.74,66H851.58C914.13,145.68,895.85,158.12,903.34,158.15Z" fill="#94a5b3" opacity="0.57"/>
        <path d="M888.12,217.36s-5.14-8.88-3.89-15.78,5.67-10.85,9.23-7.89,18.37,2.63,25.61,2.3,9.66,9.53,7.63,16.11-5.92,13.48-9.57,22-6.11,21.7-6.11,21.7-5.77-2.34,3.11-33.54-4-11.5-12.59-17.27C888.66,196.44,893.46,206.18,888.12,217.36Z" fill="#94a5b3" opacity="0.57"/>
        <path d="M874.66,332s9.81-45.92,12-71.24.84-43.73,11.37-43.4,19.42-3,6.26,33.21S892.76,332,892.76,332Z" fill="#94a5b3" opacity="0.57"/>
        <path d="M943.27,143.38s7.49,4.88,4.32,14.77a46.78,46.78,0,0,0-1.41,19.91s6.81-2,8.67-12.78,5.67-16.18-1.82-20S943.27,143.38,943.27,143.38Z" fill="#94a5b3" opacity="0.57"/>
        <path d="M914.46,66s32.92,56,39.51,67.52,11.52,16.08,8.56,26.94S952.32,180.64,945.74,199s-13.38,38-24.31,75.68S905.71,332,905.71,332H826.89a8.88,8.88,0,0,1-8.89-8.88V228.54a212.56,212.56,0,0,1,18.11-32.88c12.18-18.41,15.4-26,7.86-33.86s-26-26.31-26-26.31V74.88A8.88,8.88,0,0,1,826.89,66Z" fill="#a1b3c2" opacity="0.37"/>
      </g>
      <g id="xray_foot-top" opacity="0.7">
        <rect x="814" y="346" width="170" height="262" rx="3.34" fill="#413c47"/>
        <path d="M866.39,608s-6-11.28-4.5-16.85,6.28-4.9,7.47-8.17,2.38-9.82,16.36-11.12,16.65,9.81,19.33,10.46,8.3,20,4.15,25.68Z" fill="#a1b3c2"/>
        <path d="M915.63,575.78c-5.62.17-16.21,4.25-19.79,11.78s0,20.44,0,20.44h45.91a36.25,36.25,0,0,0,0-10c-.72-6.21-8.46-10.79-9.94-15S920,575.65,915.63,575.78Z" fill="#dde6ec"/>
        <path d="M865.79,533.91s-5.43,13.74-3.9,18.65,3.61,8.83,8.66,6.54,10.71-4.58,11.3-8.18,2.38-15,0-17S867.24,529.88,865.79,533.91Z" fill="#a1b3c2"/>
        <path d="M884.53,552.26c-1.07,0-2.54-6.63-1.48-12.46s1-10.47,6.57-11.45,8-2,8.88,7.2,5.36,13.41,0,16S884.53,552.26,884.53,552.26Z" fill="#a1b3c2"/>
        <path d="M899.23,553.57c-1.06,0-2.53-6.63-1.47-12.46s.94-10.47,6.56-11.45,8-2,8.89,7.2,5.35,13.41,0,16S899.23,553.57,899.23,553.57Z" fill="#a1b3c2"/>
        <path d="M865.79,533.91c.31-2.94-4.24-5.23-3.9-9.16s1.76-11.77,1.94-15.37-10.65-35.33-13-37.94-7.08-3.93-8.27-7.53-.9-10.55-.3-13.16,18.33-7,20.76-5.73,4.58,13.74,2.21,20.69c-1.64,4.82,5,31.57,9.15,38.76s11,11.78,11.74,17.34-4,18.64-4.9,19.3S864.9,542.42,865.79,533.91Z" fill="#dde6ec"/>
        <path d="M869.36,583s-5.93-4.47-7.72-11.23.3-9.38,3.86-11.67a65.77,65.77,0,0,0,11.23-9.16c5.12-4.91,26.83-1.31,29.8,0s9.25,14.07,8.34,17.34-3,5.9-5.67,8.84S902,579,902,579s-1.3-9.46-12.33-9.14S867.88,576.76,869.36,583Z" fill="#dde6ec" opacity="0.75"/>
        <path d="M887.5,531.6s-.25-12.73.47-15.68-2.37-20.93-5.28-45.79-6.11-31.4-5.41-35-.16-6.54,5.77-7.85,9.07,2.29,8.84,7.19-3.13-.32-2.27,14.4,5.31,51.68,8.29,58.87,7.43,7.85,5.65,14.4-5.92,13.69-7.72,15S887.5,531.6,887.5,531.6Z" fill="#dde6ec"/>
        <path d="M904.16,534.05s.41-12.73,1.28-15.62-1.28-21-2.91-46-4.48-31.73-3.59-35.28.17-6.54,6.16-7.48,5.82,2.33,5.34,7.21-1.5,2.81-1.41,17.56,4.16,49.12,6.75,56.49,4.72,11.36,2.6,17.78-4.31,10.25-6.18,11.46S904.16,534.05,904.16,534.05Z" fill="#dde6ec"/>
        <path d="M916.61,555c-1,0-2.39-6.21-1.39-11.67s.89-9.8,6.2-10.72,7.56-1.83,8.41,6.74,5.06,12.56,0,15S916.61,555,916.61,555Z" fill="#a1b3c2"/>
        <path d="M918.65,536.69s.84-11.45,1.76-14-.48-19-1.15-41.6-3.12-28.77-2.16-31.93.39-5.88,6.08-6.49,5.42,2.34,4.8,6.72-1.52,2.47-1.94,15.76,2.2,44.42,4.4,51.16,4.06,10.42,1.83,16.12-4.43,9.06-6.23,10.08S918.65,536.69,918.65,536.69Z" fill="#dde6ec"/>
        <path d="M922.59,567c-1-.06-1.86-6.43-.4-11.76s1.72-9.66,7.08-10,7.68-1,7.8,7.57,4,13-1.27,14.94S922.59,567,922.59,567Z" fill="#a1b3c2"/>
        <path d="M926.18,548.37s1.84-10.88,3-13.25,1.19-18.23,2.5-39.89-.59-27.81.64-30.74.91-5.59,6.63-5.63,5.18,2.74,4.18,6.87-1.73,2.22-3.31,14.89-1.69,42.69-.09,49.35,3.13,10.34.41,15.58-5.2,8.25-7.09,9.05S926.18,548.37,926.18,548.37Z" fill="#dde6ec"/>
        <path d="M922.06,541.44c-12,4.24-15.35,9.08-15.83,15.7-.61,8.45-5.05,16.47,0,21.15s25.15,5.21,28,8.31,8.62-3.3,9.81-6.89-1.49-9.05,0-14,4.71-6.6,3.1-12.82-4.59-15.37-12.32-15.7S925.67,540.16,922.06,541.44Z" fill="#dde6ec" opacity="0.75"/>
        <path d="M843,445.09c-.66.11-1.48-2.3-2.14-6.87s5.23-11.23,5.92-14.51,1.94-14.63.6-17.67c-1.18-2.68-.68-9.36,4.83-7.82s7.12,3.17,10.32,4.06,7.93,5.7,3.65,11.83-5.83,11.55-4.29,16.27,6.46,10.69.6,12.47S852.35,443.56,843,445.09Z" fill="#dde6ec"/>
        <path d="M855,397.64s-4.14-.59-5.16-2.06,1-6.81,2.72-8.54a35.46,35.46,0,0,0,6.22-7.63c2.6-4.25,1.55-7.7,5.27-9.47s6.68-2,6.42.56,1.14,5.12-1.24,10.38,2.93,16.79-1.41,19.42C864.16,402.5,855,397.64,855,397.64Z" fill="#dde6ec"/>
        <path d="M882.74,426.16s-4.27,2-5.6-.23c-2.47-4.05,1.46-4.36,1.82-6.77s.95-7.49.47-10.35c-.74-4.38-3.31-5.34-3.34-7.4-.06-3.65,2.52-2.81,6.19-3s6.57-1.86,6,3.86c-1.78,17,5.41,20.8,3.54,24S884.8,425.07,882.74,426.16Z" fill="#dde6ec"/>
        <path d="M885.2,395.6s-6.18.48-6.58-.53c-1.57-3.92,1.67-1.16,1.86-6.93.22-7-2.3-5.11-1.86-7.8s6.19-2.78,7.32-2c3.31,2.23.78,3,.37,5.94-1.11,8.06,2.09,6.6,1.67,9.12A2.51,2.51,0,0,1,885.2,395.6Z" fill="#dde6ec"/>
        <path d="M885,377.06s-5.37.42-5.72-.46c-1.37-3.4.74-2.16.9-7.17.19-6.12.2-2.76.59-5.1s2.34-2.06,3.47-1.69c1.65.53,2.08,2,1.72,4.57-1,7,1.82,5.73,1.46,7.92A2.18,2.18,0,0,1,885,377.06Z" fill="#dde6ec"/>
        <path d="M901.86,374.55s5.37.42,5.72-.46c1.37-3.41-.74-2.16-.9-7.17-.19-6.12-.2-2.76-.59-5.1s-2.34-2.06-3.47-1.69c-1.65.53-2.08,2-1.72,4.57,1,7-1.82,5.73-1.46,7.92A2.18,2.18,0,0,0,901.86,374.55Z" fill="#dde6ec"/>
        <path d="M919.34,390.22s5.37-.4,5.61-1.29c.91-3.45-1-2-1.81-6.71-1-5.8-.56-2.59-1.23-4.77s-2.58-1.61-3.67-1.09c-1.56.75-1.8,2.2-1.11,4.61,1.85,6.53-1.07,5.74-.43,7.77A2.14,2.14,0,0,0,919.34,390.22Z" fill="#dde6ec"/>
        <path d="M904.2,428.47s-3.6,2-4.73-.23c-2.07-4.05,1.23-4.35,1.54-6.77s.8-7.49.39-10.35c-.62-4.38-2.79-5.34-2.81-7.4-.05-3.64,2.13-2.81,5.23-3s5.54-1.86,5,3.86c-1.5,17,4.56,20.8,3,24S905.94,427.38,904.2,428.47Z" fill="#dde6ec"/>
        <path d="M922.13,441.29s-3.45,1.75-4.45-.4c-1.83-4,1.3-4.14,1.67-6.46a47.9,47.9,0,0,0,.7-9.94c-.45-4.24-2.47-5.24-2.43-7.23.08-3.5,2.1-2.62,5-2.73s5.28-1.59,4.62,3.89c-1.95,16.27,3.64,20.18,2.05,23.21S923.81,440.29,922.13,441.29Z" fill="#dde6ec"/>
        <path d="M939.56,456.69s-2.95,1.81-3.87-.22c-1.7-3.74,1-4,1.26-6.25a51.51,51.51,0,0,0,.32-9.57c-.51-4.06-2.28-4.95-2.3-6.85,0-3.37,1.74-2.6,4.28-2.8s4.53-1.72,4.11,3.56c-1.23,15.69,3.74,19.25,2.44,22.21S941,455.68,939.56,456.69Z" fill="#dde6ec"/>
        <path d="M905.5,399.05s-6.81-.05-7.15-1.35c-1.33-5,1.95-1.26,2.72-8.37.94-8.67-2-6.56-1.26-9.84s7.05-2.78,8.21-1.72c3.4,3.11.56,3.75-.18,7.38-2,9.84,1.63,8.38.92,11.45S905.5,399.05,905.5,399.05Z" fill="#dde6ec"/>
        <path d="M925.57,412.59s-6.76.86-7.25-.34c-1.89-4.61,1.79-1.46,1.74-8.36-.07-8.41-2.75-6-2.39-9.23s6.67-3.6,7.94-2.74c3.74,2.51,1,3.5.68,7.07-.86,9.67,2.58,7.79,2.23,10.81A2.79,2.79,0,0,1,925.57,412.59Z" fill="#dde6ec"/>
        <path d="M941.59,430.06s-6.7,1.22-7.26.11c-2.14-4.31,1.7-1.51,1.27-8.12-.52-8.06-3.07-5.59-2.88-8.71s6.46-3.85,7.78-3.1c3.86,2.19,1.18,3.3,1.06,6.75-.33,9.32,3,7.31,2.82,10.23A2.76,2.76,0,0,1,941.59,430.06Z" fill="#dde6ec"/>
        <path d="M933,410.7s6.67-.48,7-1.54c1.13-4.11-1.25-2.33-2.24-8-1.22-6.91-.7-3.09-1.54-5.68s-3.2-1.92-4.54-1.31c-1.94.9-2.23,2.63-1.39,5.5,2.3,7.79-1.32,6.84-.52,9.26A2.67,2.67,0,0,0,933,410.7Z" fill="#dde6ec"/>
        <path d="M857.33,608s-7.55-40.77-7.81-52.39-1.89-46-7.74-64.23S826.81,448.57,830,427s7.27-31.09,10.83-42.28,4.92-19.61,13.54-23.82,16.69-3.51,19.49,0c0,0,1.24-8.07,8.87-9.12s9.41,6.85,9.41,6.85,1.83,10.57,2.15,14.9-1.33,9.11,0,7,.07-15.76,0-21.91,11.7-6,14.76-3.08,3.11,11.67,3.09,18.33-.7,11.16.69,10.84.19-7.72.33-11.19,10-4.86,12-1.06c1.66,3.17,2.26,5.38,2.56,12.29.19,4.37.36,20.62.91,19.8,1.41-2.1-2.65-14.61,4.16-15.08s9.43,2.45,11,12.71,3.72,10.62,7.08,31.31.85,68.86,0,94.23,1.2,31.86,0,40.71S949.26,608,949.26,608Z" fill="#a1b3c2" opacity="0.37"/>
      </g>
      <g id="xray_chest" opacity="0.7">
        <g clipPath="url(#clip-path)">
          <rect x="568" y="66" width="238" height="160" rx="3.31" fill="#413c47"/>
          <g opacity="0.57">
            <path d="M687,68.49a3.75,3.75,0,0,0-2.16-4.26c-2.54-1-7.41-2.73-10.29-2.81-4-.12-3.22,2.08-1.12,3.7s3,1,3.11,3.92,8.21,2.77,9.81.81A3.11,3.11,0,0,0,687,68.49Z" fill="#a1b3c2"/>
            <path d="M688.3,68.49a3.75,3.75,0,0,1,2.16-4.26c2.54-1,7.41-2.73,10.29-2.81,4-.12,3.22,2.08,1.12,3.7s-3,1-3.11,3.92-8.21,2.77-9.81.81A3.11,3.11,0,0,1,688.3,68.49Z" fill="#a1b3c2"/>
            <path d="M687.65,62.56a5.67,5.67,0,0,0-2.16,3.49,10.84,10.84,0,0,0,1.08,5.59c.57.86,2.31.29,3-1s.49-4.94,0-6.07S688.56,61.93,687.65,62.56Z" fill="#dde6ec"/>
            <path d="M686.34,76.09a16.43,16.43,0,0,0-8-4.81C673.22,70,672,67.77,671,69.39a2.79,2.79,0,0,0,1.26,4.27c1.84.81,3.65.58,4.65,2.43a7.24,7.24,0,0,0,6.21,3.69C685.84,79.67,687.06,76.83,686.34,76.09Z" fill="#a1b3c2"/>
            <path d="M688.41,76.09a16.43,16.43,0,0,1,8-4.81c5.11-1.31,6.31-3.51,7.31-1.89a2.79,2.79,0,0,1-1.26,4.27c-1.84.81-3.65.58-4.65,2.43a7.24,7.24,0,0,1-6.21,3.69C688.91,79.67,687.69,76.83,688.41,76.09Z" fill="#a1b3c2"/>
            <path d="M687.66,74.09a5.37,5.37,0,0,0-1.85,3.25,10.74,10.74,0,0,0,.93,5.21c.48.8,2,.26,2.56-1s.41-4.6,0-5.65S688.44,73.5,687.66,74.09Z" fill="#dde6ec"/>
            <path d="M686.34,85a16.72,16.72,0,0,0-8-6c-5.11-1.64-6.31-4.39-7.31-2.36s-.58,4.33,1.26,5.35,3.65.72,4.65,3,3.5,4.77,6.21,4.63S687.06,85.9,686.34,85Z" fill="#a1b3c2"/>
            <path d="M688.41,85a16.72,16.72,0,0,1,8-6c5.11-1.64,6.31-4.39,7.31-2.36s.58,4.33-1.26,5.35-3.65.72-4.65,3-3.5,4.77-6.21,4.63S687.69,85.9,688.41,85Z" fill="#a1b3c2"/>
            <path d="M687.64,83.71c-.58.45-2.86-1.62-3.11,0s1.72,5.1,2.21,5.89,2,.27,2.56-1,1.88-4.23,1.46-5.27S688.42,83.12,687.64,83.71Z" fill="#dde6ec"/>
            <path d="M686.34,94.68a16.71,16.71,0,0,0-8-6C673.22,87,672,84.27,671,86.29s-.58,4.34,1.26,5.35a9.19,9.19,0,0,1,4.55,4.66c1.69,4.28,7,3.81,9.63,2.53S687.06,95.61,686.34,94.68Z" fill="#a1b3c2"/>
            <path d="M688.93,94.7a16.79,16.79,0,0,1,8-6c5.11-1.64,6.31-4.39,7.32-2.36s.57,4.33-1.27,5.34a9.2,9.2,0,0,0-4.54,4.67c-1.69,4.27-5.85,3.79-8.45,2.51A2.54,2.54,0,0,1,688.93,94.7Z" fill="#a1b3c2"/>
            <path d="M687.72,91.13c-.69-.14-1.58,1.68-3.26,1.68-1.43,0,1.79,5.7,2.28,6.5s2,.26,2.56-1,3.41-5.87,2.45-5.54C689.68,93.53,688.69,91.34,687.72,91.13Z" fill="#dde6ec"/>
            <path d="M686.34,105.52a17.2,17.2,0,0,0-8-6.69c-5.11-1.83-6.84-3-7.84-.79s0,3,1.79,4.11a9.75,9.75,0,0,1,4.55,5.18c1.69,4.75,7,4.23,9.63,2.81S687.06,106.56,686.34,105.52Z" fill="#a1b3c2"/>
            <path d="M688.93,105.55a17.25,17.25,0,0,1,8-6.7c5.11-1.82,6.84-3.53,7.84-1.28s.05,3.48-1.79,4.6a9.81,9.81,0,0,0-4.54,5.18c-1.69,4.76-5.85,4.22-8.45,2.8A3,3,0,0,1,688.93,105.55Z" fill="#a1b3c2"/>
            <path d="M687.72,101.58c-.69-.16-1.58,1.87-3.26,1.87-1.43,0,1.79,6.34,2.28,7.22s2,.3,2.56-1.07,3.41-6.52,2.45-6.15C689.68,104.25,688.69,101.81,687.72,101.58Z" fill="#dde6ec"/>
            <path d="M688.93,119.89s2.59-6.93,9.13-6.93c5.43,0,6.63-3.8,8.57-.91,1.53,2.27-1.8,2.58-3.64,3.89a11,11,0,0,0-4.54,6.06c-1.69,5.55-5.85,4.92-8.45,3.27A3.81,3.81,0,0,1,688.93,119.89Z" fill="#a1b3c2"/>
            <path d="M685.87,119.89s-2.58-6.93-9.13-6.93c-5.43,0-7-2.9-9,0-1.53,2.26,2.19,1.67,4,3a11.06,11.06,0,0,1,4.55,6.06c1.69,5.55,5.85,4.92,8.44,3.27A3.79,3.79,0,0,0,685.87,119.89Z" fill="#a1b3c2"/>
            <path d="M687.52,113c-.69-.17-3.52,1.54-5.2,1.54-1.43,0,4.84,10.82,5.32,11.71s1.77-1,2.58-4.47c.34-1.46,4.15-7.76,3.2-7.39C691.34,115.16,688.49,113.19,687.52,113Z" fill="#dde6ec"/>
            <path d="M685.87,134.67s-2.58-7.45-9.13-7.45c-5.43,0-6.62-3.1-8.57,0-1.53,2.44,1.8,1.79,3.64,3.21s2.38,4.84,3.68,8c1.87,4.46,6.17,1,10.27,2.28C688.55,141.55,686.59,136,685.87,134.67Z" fill="#a1b3c2"/>
            <path d="M689.27,134.67s2.58-7.45,9.12-7.45c5.43,0,6.63-3.1,8.58,0,1.53,2.44-1.8,1.79-3.64,3.21s-2.39,4.84-3.68,8c-1.87,4.46-6.17,1-10.27,2.28C686.59,141.55,688.55,136,689.27,134.67Z" fill="#a1b3c2"/>
            <path d="M687.52,127.23c-.69-.17-3.52,1.67-5.2,1.67-1.43,0,4.84,11.62,5.32,12.57s1.77-1.13,2.58-4.8c.34-1.57,4.15-8.34,3.2-7.94C691.34,129.59,688.49,127.48,687.52,127.23Z" fill="#dde6ec"/>
            <path d="M685.87,150s-2.58-7.44-9.13-7.44c-5.43,0-6.62-3.11-8.57,0-1.53,2.44,1.8,1.79,3.64,3.2s2.38,4.85,3.68,8c1.87,4.47,6.17,1,10.27,2.29C688.55,156.88,686.59,151.29,685.87,150Z" fill="#a1b3c2"/>
            <path d="M689.27,150s2.58-7.44,9.12-7.44c5.43,0,6.3-2.7,8.24.41,1.53,2.44-1.46,1.38-3.3,2.79s-2.39,4.85-3.68,8c-1.87,4.47-6.17,1-10.27,2.29C686.59,156.88,688.55,151.29,689.27,150Z" fill="#a1b3c2"/>
            <path d="M687.52,142.56c-.69-.17-3.52,1.66-5.2,1.66-1.43,0,4.84,11.62,5.32,12.57s1.77-1.12,2.58-4.79c.34-1.58,4.15-8.34,3.2-7.94C691.34,144.91,688.49,142.8,687.52,142.56Z" fill="#dde6ec"/>
            <path d="M685.87,165.69s-2.58-7.45-9.13-7.45c-5.43,0-6.62-3.11-8.57,0-1.53,2.44,1.8,1.79,3.64,3.21s2.38,4.84,3.68,8c1.87,4.47,6.17,1,10.27,2.29C688.55,172.57,686.59,167,685.87,165.69Z" fill="#a1b3c2"/>
            <path d="M689.27,165.69s2.58-7.45,9.12-7.45c5.43,0,6.3-2.69,8.24.41,1.53,2.44-1.46,1.38-3.3,2.8s-2.39,4.84-3.68,8c-1.87,4.47-6.17,1-10.27,2.29C686.59,172.57,688.55,167,689.27,165.69Z" fill="#a1b3c2"/>
            <path d="M687.52,158.25c-.69-.17-3.52,1.66-5.2,1.66-1.43,0,4.84,11.63,5.32,12.58s1.77-1.13,2.58-4.8c.34-1.58,4.15-8.34,3.2-7.94C691.34,160.61,688.49,158.5,687.52,158.25Z" fill="#dde6ec"/>
            <path d="M686.79,182.32s-2.47-7.45-8.73-7.45c-5.19,0-6.34-3.1-8.2,0-1.46,2.44,1.73,1.8,3.49,3.21s2.27,4.85,3.52,8c1.78,4.46,5.89,1,9.81,2.28C689.36,189.2,687.48,183.62,686.79,182.32Z" fill="#a1b3c2"/>
            <path d="M690,182.32s2.47-7.45,8.73-7.45c5.2,0,6-2.69,7.88.41,1.46,2.44-1.4,1.39-3.16,2.8s-2.28,4.85-3.52,8c-1.79,4.46-5.9,1-9.82,2.28C687.48,189.2,689.36,183.62,690,182.32Z" fill="#a1b3c2"/>
            <path d="M688.37,174.89c-.67-.18-3.37,1.66-5,1.66-1.37,0,4.63,11.62,5.1,12.57s1.69-1.13,2.46-4.8c.33-1.57,4-8.33,3.06-7.94C692,177.24,689.29,175.13,688.37,174.89Z" fill="#dde6ec"/>
            <path d="M687.37,197.66s-2.33-7-8.22-7c-4.9,0-6-2.92-7.73,0-1.37,2.3,1.63,1.69,3.28,3s2.15,4.56,3.32,7.49c1.68,4.21,5.55.93,9.24,2.15C689.78,204.14,688,198.88,687.37,197.66Z" fill="#a1b3c2"/>
            <path d="M690.42,197.66s2.33-7,8.22-7c4.9,0,5.67-2.54,7.43.39,1.37,2.29-1.33,1.3-3,2.63s-2.15,4.56-3.32,7.49c-1.68,4.21-5.55.93-9.24,2.15C688,204.14,689.78,198.88,690.42,197.66Z" fill="#a1b3c2"/>
            <path d="M688.85,190.66c-.63-.16-3.17,1.57-4.68,1.57-1.29,0,4.36,10.94,4.79,11.83s1.6-1.06,2.32-4.51c.31-1.49,3.74-7.85,2.88-7.48C692.29,192.88,689.72,190.89,688.85,190.66Z" fill="#dde6ec"/>
            <path d="M688.57,213.4s-1.64-5.92-5.77-5.92c-3.43,0-4.28,1.43-3.11,2.55s1.5,3.85,2.32,6.33c1.18,3.55,3.89.78,6.48,1.81C690.26,218.87,689,214.43,688.57,213.4Z" fill="#a1b3c2"/>
            <path d="M690.82,213.3s1.64-6,5.81-6c3.45,0,4.3,1.46,3.13,2.6s-1.51,3.93-2.34,6.46c-1.18,3.62-3.92.8-6.53,1.85C689.11,218.88,690.36,214.35,690.82,213.3Z" fill="#a1b3c2"/>
            <path d="M689.65,207.27c-.54-.14-1.36,2.1-4,1.35-1.08-.3,3.75,9.43,4.13,10.2s1.37-.92,2-3.89c.27-1.28,3.24-6.71,2.48-6.44C691.42,209.48,690.4,207.47,689.65,207.27Z" fill="#dde6ec"/>
            <path d="M688.08,226.59s-1.75-6.79-6.1-6.26c-2.16.26-3.29,2.69-2.06,3.88s-1.15,4.86.41,7c1.78,2.43,4.93-.66,7.68.43C689.87,232.38,688.56,227.68,688.08,226.59Z" fill="#a1b3c2"/>
            <path d="M691.28,226.59s1.75-6.79,6.1-6.26c2.16.26,3.29,2.69,2.06,3.88s1.15,4.86-.41,7c-1.78,2.43-4.93-.66-7.68.43C689.49,232.38,690.8,227.68,691.28,226.59Z" fill="#a1b3c2"/>
            <path d="M689.23,220.11c-.57-.15-1.43,2.21-4.27,1.42-1.14-.32,4,10,4.37,10.79s1.46-1,2.11-4.11c.29-1.36,3.43-7.1,2.63-6.82C691.11,222.44,690,220.32,689.23,220.11Z" fill="#dde6ec"/>
            <path d="M697.25,232.58c.22-.05.88.58,1.93-1.39s4-1.69,4.36-2.23a1.5,1.5,0,0,0-2.2-1.9c-2.21,1-6.32.54-6.57,1.93S696.13,232.81,697.25,232.58Z" fill="#a1b3c2"/>
            <path d="M681.74,232.4c-.21-.05-.87.58-1.93-1.39s-3.95-1.69-4.35-2.23a1.5,1.5,0,0,1,2.2-1.9c2.2,1,6.32.53,6.56,1.93S682.86,232.63,681.74,232.4Z" fill="#a1b3c2"/>
            <path d="M695.84,218.18c.22,0,.88.59,1.94-1.38s4-1.7,4.37-2.24a1.51,1.51,0,0,0-2.21-1.91c-2.21,1-6.34.53-6.58,1.93S694.72,218.42,695.84,218.18Z" fill="#a1b3c2"/>
            <path d="M683.58,218.1c-.21,0-.88.58-1.93-1.39s-4-1.7-4.37-2.24a1.51,1.51,0,0,1,2.21-1.91c2.21,1,6.33.54,6.58,1.94S684.71,218.34,683.58,218.1Z" fill="#a1b3c2"/>
          </g>
          <path d="M671.76,87c1.31.67,1.93-4.12,1.39-5.49s-14-5.08-27-5.7S619.6,81.58,616.87,91.3,640.81,118.37,656,124s12.65,2.33,12.65,2.33,1.88-3.23-6.84-6.09-34.44-12.42-39.57-22.43,14.18-15.9,21.1-16.23C652.78,81.08,664.92,83.55,671.76,87Z" fill="#a1b3c2"/>
          <path d="M674.91,96.87s3.43-1.62-8.46-6.86-46.35-3.26-53.37,8.31,5.65,23.74,18.12,30.32,35.73,14,38.5,12.12,6-6.14-.28-5.94-38.93-7.12-49.5-18.85c-11.24-12.47,2.94-25.36,34.58-21.14C668.53,96.7,671.62,102.54,674.91,96.87Z" fill="#a1b3c2"/>
          <path d="M678.8,112.17s.73-3.19-12-8.43-49.54-3.26-57,8.31,6,23.74,19.37,30.32,38.19,14,41.15,12.12,6.44-6.14-.3-5.94-41.61-7.12-52.91-18.85c-12-12.47,3.14-25.36,37-21.14C669.05,110.43,675.28,117.83,678.8,112.17Z" fill="#a1b3c2"/>
          <path d="M676.71,127.89s.73-2.87-12-8.11-49.54-3.27-57,8.31,6,23.74,19.37,30.31,38.18,14,41.14,12.13,6.44-6.14-.3-5.94-41.61-7.12-52.91-18.85c-12-12.48,3.14-25.36,37-21.14C667,126.47,673.19,133.55,676.71,127.89Z" fill="#a1b3c2"/>
          <path d="M676.71,143.52s.73-3.2-12-9-49.54-3.64-57,9.24,6,26.41,19.37,33.73,38.18,15.6,41.14,13.49,6.44-6.83-.3-6.61-41.61-7.92-52.91-21c-12-13.88,3.14-28.22,37-23.52C667,141.94,673.19,149.82,676.71,143.52Z" fill="#a1b3c2"/>
          <path d="M675.88,163.49s.72-3.38-12-9.54-49.54-3.84-57,9.77,6,27.92,19.37,35.65,38.19,16.49,41.15,14.26,6.44-7.22-.3-7-41.61-8.36-52.91-22.16c-12-14.67,3.14-29.83,37-24.86C666.12,161.82,672.36,170.15,675.88,163.49Z" fill="#a1b3c2"/>
          <path d="M677.17,184.16s.67-3.94-11.13-11.13-46-4.48-53,11.4,5.6,32.57,18,41.6,35.46,19.24,38.21,16.64,6-8.43-.28-8.16-38.65-9.76-49.14-25.86c-11.15-17.11,5-37.11,36.43-31.32C670.21,179.9,673.9,191.93,677.17,184.16Z" fill="#a1b3c2"/>
          <path d="M683.19,200.68s-2.5-2.06-12.72-6.32c-12.86-5.36-47.19-4.25-54.15,12.54s5.6,34.45,18,44,35.46,20.35,38.21,17.6,6-8.92-.28-8.63-38.64-10.32-49.14-27.35c-11.15-18.1,5.2-38.84,36.61-32.71C673.64,202.52,679.92,208.9,683.19,200.68Z" fill="#a1b3c2"/>
          <path d="M703.52,87c-1.3.67-1.91-4.12-1.38-5.49s13.89-5.08,26.74-5.7,26.21,5.75,28.92,15.47S734.13,118.37,719.08,124s-12.52,2.33-12.52,2.33-1.86-3.23,6.77-6.09,34.06-12.42,39.13-22.43-14-15.9-20.87-16.23C722.29,81.08,710.28,83.55,703.52,87Z" fill="#a1b3c2"/>
          <path d="M701.53,97.42s-3.51-1.6,8.65-6.77,47.41-3.23,54.58,8.2-5.77,23.44-18.53,29.94-36.54,13.85-39.37,12-6.17-6.07.28-5.87,39.82-7,50.63-18.62c11.5-12.32-3-25-35.37-20.88C708.05,97.25,704.89,103,701.53,97.42Z" fill="#a1b3c2"/>
          <path d="M695.75,112.17s-.72-3.19,11.78-8.43,48.75-3.26,56.13,8.31-5.93,23.74-19.06,30.32-37.57,14-40.48,12.12-6.34-6.14.29-5.94,41-7.12,52.06-18.85c11.82-12.47-3.09-25.36-36.37-21.14C705.35,110.43,699.21,117.83,695.75,112.17Z" fill="#a1b3c2"/>
          <path d="M695.74,127.89s-.71-2.87,11.79-8.11,48.75-3.27,56.13,8.31-5.93,23.74-19.06,30.31-37.57,14-40.48,12.13-6.34-6.14.29-5.94,41-7.12,52.06-18.85c11.82-12.48-3.09-25.36-36.37-21.14C705.35,126.47,699.21,133.55,695.74,127.89Z" fill="#a1b3c2"/>
          <path d="M695.74,143.52s-.71-3.2,11.79-9,48.75-3.64,56.13,9.24-5.93,26.41-19.06,33.73S707,193.07,704.12,191s-6.34-6.83.29-6.61,41-7.92,52.06-21c11.82-13.88-3.09-28.22-36.37-23.52C705.35,141.94,699.21,149.82,695.74,143.52Z" fill="#a1b3c2"/>
          <path d="M695.75,157.91s-.72-3.38,11.79-9.54,48.75-3.84,56.12,9.78-5.93,27.91-19,35.65S707,210.28,704.12,208.05s-6.34-7.22.29-7,41-8.36,52.07-22.16c11.82-14.67-3.09-29.82-36.37-24.86C705.35,156.24,699.21,164.57,695.75,157.91Z" fill="#a1b3c2"/>
          <path d="M693,179.53s-.74-4.38,12.26-12.35,50.65-5,58.32,12.65S757.42,216,743.78,226s-39,21.33-42.07,18.46-6.59-9.35.3-9,42.55-10.83,54.1-28.68c12.28-19-5.53-41.16-40.11-34.74C700.67,174.8,696.6,188.15,693,179.53Z" fill="#a1b3c2"/>
          <path d="M689.42,199.83s-.74-4.63,12.26-13.06,50.65-5.26,58.32,13.37-6.17,38.21-19.8,48.8-39.05,22.56-42.08,19.51-6.58-9.89.31-9.57,42.55-11.44,54.1-30.33c12.28-20.08-5.72-43.07-40.3-36.28C696.89,195.28,693,208.94,689.42,199.83Z" fill="#a1b3c2"/>
          <path d="M673.34,76.28s.37,9.23,2,11.46,6.69,3.12,12.39,3.72,15.73-6.1,15.08-8-1-7.69-2.53-8.54-5.5,2.79-10.76,2.15-11.68-4-14.18-3.47A2.23,2.23,0,0,0,673.34,76.28Z" fill="#b7cbdb"/>
          <path d="M673.3,90.58c-2.28.72-6.69,7-8.42,9.83s4.89,7.61,8.42,9.77,2.8,9,4.2,11.16,3.31,2.46,7.3,1.5,8.17.93,10,.51,3.72-1.41,3.31-5.39-.86-6.24,1.29-7.44,3-5.17,5.84-5.83c6.32-1.47.09-13.32-3.24-14.11-2.49-.58-13.25,4.94-15.21,4S673.3,90.58,673.3,90.58Z" fill="#b7cbdb"/>
          <path d="M688.93,124.89c-1.22-.62-9.5-.47-12.57,2.11s-2.24,9.86-2.12,14.06-2.29,9.79-2.56,13.25,2.71,3.22,2.56,7.06-1.45,5.56-1.74,7.89,2.35,4.66,2.82,7.53a48.13,48.13,0,0,1,.63,6.5c0,1.06.31,4.22-.63,6S682,197.5,683.19,201s3.2,8,4.83,9.64,2.56-7,3.13-9.64,2.52-4.51,5.08-6.4,1.57-5.81,1.6-7.51.47-.12,2.12-4.77a18.66,18.66,0,0,0,1.11-9.25c-.17-2.19-.5-5.5.15-7.09s-.34-2.54-1-4.91,2.77-4.95,2-6.31-2-.51-1.23-4.07,2.67-5.49,1.74-7.2-3.43-2.82-2.85-5.85.84-8.81.31-9.78-.7-3.79-2.35-3.31S688.93,124.89,688.93,124.89Z" fill="#b7cbdb"/>
          <path d="M704.6,80.39c-.59,1.22-2,4.6-1.18,6.89s1.61,4,3.25,4.29,1.07-3.57,7.14-6.52,11.35,1.63,22.08-3.56,13.15-5.85,20.38-4.14,17,4.44,18,5.67c1.7,2,5.14-6.34.94-9.89-2.27-1.92-1.32,1.44-4.55,1.8S759,71.28,748.36,72.41s-22.13,5.76-26.16,6.33S706.31,76.89,704.6,80.39Z" fill="#b7cbdb"/>
          <path d="M673.2,80.86c.62,1.17,2.12,4.41,1.25,6.6s-1.71,3.82-3.45,4.11-1.14-3.42-7.58-6.24-12,1.56-23.43-3.42-14-5.6-21.63-4-18.06,4.25-19.15,5.43c-1.81,2-5.46-6.08-1-9.47,2.42-1.84,1.41,1.38,4.84,1.72s12.39-3.5,23.71-2.42,23.49,5.52,27.77,6.07S671.39,77.5,673.2,80.86Z" fill="#b7cbdb"/>
          <path d="M567.63,113.3s21.19-4,21.62,11.74a546.81,546.81,0,0,0,11.54,102.23h175s4.55-64.15,4.55-88,25.81-21.93,25.81-21.93l.6-45.75s-22.62-6.47-44.52-4-37,10.1-43.85,2.57-17.28-11.81-17.28-11.81-30.42.91-43.93,9.24-27.71.69-51.59.35-35.39-5.71-37.91-2.85S567.63,113.3,567.63,113.3Z" fill="#a1b3c2" opacity="0.37"/>
        </g>
      </g>
      <g id="firstaidkit">
        <path d="M452.62,158.67H415.16V146.6A20.61,20.61,0,0,0,394.57,126H375.69A20.62,20.62,0,0,0,355.1,146.6v12.07H317.65a15.11,15.11,0,0,0-15.11,15.11v89a15.11,15.11,0,0,0,15.11,15.11h135a15.12,15.12,0,0,0,15.11-15.11v-89A15.12,15.12,0,0,0,452.62,158.67Zm-48.41,0H366.06V146.6a9.64,9.64,0,0,1,9.63-9.63h18.88a9.64,9.64,0,0,1,9.64,9.63Z" fill="#040000" opacity="0.2"/>
        <rect x="298.76" y="155.27" width="165.19" height="119.23" rx="15.11" fill="#f1f2f2"/>
        <rect x="306" y="160.5" width="150.71" height="108.76" rx="13.78" fill="#fff"/>
        <path d="M390.8,121.71H371.91a20.62,20.62,0,0,0-20.59,20.59v13.22h11V142.3a9.64,9.64,0,0,1,9.63-9.63H390.8a9.64,9.64,0,0,1,9.63,9.63v13.22h10.95V142.3A20.61,20.61,0,0,0,390.8,121.71Z" fill="#fff"/>
        <g>
          <path d="M407.8,155.52h3.58V142.3a20.39,20.39,0,0,0-1-6.28C410,136.94,408.35,149.84,407.8,155.52Z" fill="#f1f2f2"/>
          <path d="M389.87,129.43c-3.81,0-9.34.07-17.57.07-2,0-16.17-.45-16.17,15.34,0,3.57-.1,7.11,0,10.68h6.15V142.3a9.64,9.64,0,0,1,9.63-9.63H390.8a9.48,9.48,0,0,1,4.18,1c1.25.71,2.43,1.31,5,5C399.51,137.27,397.58,129.43,389.87,129.43Z" fill="#f1f2f2"/>
        </g>
        <g opacity="0.05">
          <path d="M456.67,157.46s-155,102.51-157.91,101v1a15.11,15.11,0,0,0,15.11,15.11h135A15.11,15.11,0,0,0,464,259.39v-89A15.1,15.1,0,0,0,456.67,157.46Z" fill="#040000"/>
        </g>
        <g>
          <path d="M410.94,189.85c-10.29-3.71-21.92,2.41-26,13.66,4-11.25-1-23.38-11.29-27.09s-21.92,2.4-26,13.66a28.82,28.82,0,0,0-1.32,12.34l2.53.82,3.3,1.08,6.58,2.18,6.57,2.21,6.57,2.22,6.56,2.25,3.28,1.13,2.36.81.62-.71L386,213l1.23-1.41,1.24-1.4L391,207.4l2.5-2.78,3.56-3.94-.8,5.26-.27,1.74-.28,1.74-.55,3.47-.27,1.74-.29,1.74-.56,3.48-.29,1.73-.29,1.74-.59,3.47-.42,2.41,1.74-1.92,1.21-1.34,1.22-1.34,2.45-2.67.44-.47.56.2,9.88,3.56,5.52,2a28.93,28.93,0,0,0,6.81-10.29C426.28,205.69,421.23,193.56,410.94,189.85Z" fill="#ef4351"/>
          <path d="M400,223.6,398.16,226,397,227.37l-1.14,1.41-2.28,2.81-2.29,2.8L388.39,238l.6-4.64c.15-1.16.31-2.32.47-3.49l.48-3.49.5-3.48.25-1.75.25-1.74.52-3.48.26-1.74.26-1.74.27-1.77-1.51,1.63c-.42.45-.84.91-1.27,1.36L388.19,215l-1.28,1.36-1.29,1.35-.67.7-.85-.32-3.25-1.21-3.24-1.22-6.49-2.45-6.47-2.47-6.48-2.5-6.46-2.51-3.22-1.27-2-.78C349.07,225.15,368.2,250,368.2,250s30.53-6.92,46.2-21.79l-5-1.59Z" fill="#ef4351"/>
        </g>
      </g>
      <polyline id="pulse-path" strokeDasharray="1000" strokeDashoffset="1000" points="573.57 277.39 608.54 277.39 630.03 328.35 640.02 235.93 645.01 280.39 1015.68 279.39" fill="none" stroke="#ef4351" strokeMiterlimit="10" strokeWidth="3.5"/>
      <g id="stethescope">
        <path d="M440.74,139.73a7.75,7.75,0,0,0-4.22,4.51c-7.92,22.3-4.32,45.88.09,61.74a146.54,146.54,0,0,0,11.56,29.32l2.46-1.38a143.18,143.18,0,0,1-11.32-28.77c-4.29-15.44-7.8-38.38-.14-60a5,5,0,0,1,2.76-2.89c7.29-3.4,23.74,2.66,23.91,2.72l1-2.64C466.1,142.1,449.09,135.84,440.74,139.73Z" fill="#e6e7e8"/>
        <path d="M440.74,139.73a7.75,7.75,0,0,0-4.22,4.51c-7.57,21.32-4.61,43.81-.49,59.6-1.17-7.07-2.83-14.15-2.78-21.33.34-44,7.62-42.33,14-42.52,6-.19,11.74,1.29,17.57,2.65a7.22,7.22,0,0,1,1.69.64l.33-.91C466.1,142.1,449.09,135.84,440.74,139.73Z" fill="#fff"/>
        <path d="M472.74,142.86l-5-1.79a2.87,2.87,0,0,0-1.93,5.4l5,1.79a2.87,2.87,0,0,0,1.94-5.4Z" fill="#e6e7e8"/>
        <path d="M469.87,142.68l-3.12-1.12a1.8,1.8,0,0,0-1.22,3.39l3.13,1.12a1.8,1.8,0,0,0,1.21-3.39Z" fill="#fff"/>
        <g>
          <path d="M524,139.73c-8.35-3.89-25.36,2.37-26.08,2.64l1,2.64c.17-.06,16.63-6.12,23.91-2.72a5,5,0,0,1,2.76,2.89c7.66,21.59,4.15,44.53-.14,60a143.18,143.18,0,0,1-11.32,28.77l2.46,1.38A146.54,146.54,0,0,0,528.14,206c4.41-15.86,8-39.44.09-61.74A7.75,7.75,0,0,0,524,139.73Z" fill="#e6e7e8"/>
          <path d="M528.23,144.24a7.75,7.75,0,0,0-4.22-4.51c-8.35-3.89-25.36,2.37-26.08,2.64l.68,1.84c5.68-5,16.55-4.66,23.23-3.24,2.68.56,4.27,3.35,4.34,6,6.86,21.06,3.43,43.17-.74,58.19A152.58,152.58,0,0,1,517.52,227a11.29,11.29,0,0,0,2-3.46,132.69,132.69,0,0,0,11.92-43.71c.53-6.15-.11-12.3-.75-18.43C530.5,159.74,529.76,148.55,528.23,144.24Z" fill="#fff"/>
          <path d="M497,141.07l-5,1.79a2.87,2.87,0,1,0,1.94,5.4l5-1.79a2.87,2.87,0,0,0-1.94-5.4Z" fill="#e6e7e8"/>
          <path d="M495.11,142.42,492,143.54a1.79,1.79,0,1,0,1.21,3.37l3.11-1.12a1.79,1.79,0,1,0-1.21-3.37Z" fill="#fff"/>
          <path d="M482.37,257.32c-21.71,0-33.34-29.6-33.46-29.92l-2,.75-3,1.13a73.75,73.75,0,0,0,9.39,16.47c8.37,11,18.4,16.86,29,16.86s20.64-5.83,29-16.86a73.75,73.75,0,0,0,9.39-16.47l-4.93-1.88C515.72,227.7,504.14,257.32,482.37,257.32Z" fill="#f05661"/>
          <path d="M508.56,244.81c-22.51,25-43.35,11.7-51,4.34-2.08-2-4.24-4.06-6.22-6.23.64.93,1.32,1.88,2,2.83,8.37,11,18.4,16.86,29,16.86s20.64-5.83,29-16.86a73.75,73.75,0,0,0,9.39-16.47l-3.18-1.22A41.7,41.7,0,0,1,508.56,244.81Z" fill="#cc4651"/>
        </g>
        <path d="M561.93,308a25.56,25.56,0,0,1-25.54,25.53H510.56A25.56,25.56,0,0,1,485,308v-46h-5.29v46a30.86,30.86,0,0,0,30.83,30.82h25.83A30.85,30.85,0,0,0,567.21,308V277.66h-5.28Z" fill="#f05661"/>
        <g>
          <path d="M485,262.09h-2c.48.93.73,37.46.73,41.4s.63,12.1,2.84,13.27A25.46,25.46,0,0,1,485,308Z" fill="#cc4651"/>
          <path d="M565.28,281.94c-1.06,14.81,5.66,31.86-10.06,47.26-8.85,8.67-37.47,6.42-45.58,6.24a59.74,59.74,0,0,1-18-3.08,30.66,30.66,0,0,0,18.9,6.5h25.83A30.85,30.85,0,0,0,567.21,308V277.66h-3.13A6.11,6.11,0,0,1,565.28,281.94Z" fill="#cc4651"/>
        </g>
        <circle cx="563.39" cy="278.09" r="11.2" fill="#ef4351"/>
      </g>
      <g clipPath="url(#clip-path-2)">
        <g opacity="0.4">
          <path d="M540.92,756.83c-22.06,2.18-48.68-.46-86.64,6.85S408,788.83,392.49,783s-40-44.72-24.78-55.64,23.89-71.57,23.89-71.57H201.9s9.75,60.66,26.26,71.57-11.83,48.33-28.62,54.12S190,771,148.81,763.68,78.7,759,54.76,756.83s-60.28-9-60.28-9v176.2s38,13.48,48.71,52.22,36.5,217.19,43.44,259.44,33.19,85.27,30.8,123S47,1462.5,35.76,1564.12s2.48,195.33,2.48,195.33H571.67s8.67-104-1.65-205.64-84.63-157.26-86.84-195,22-80.8,28.38-123,30.12-220.7,40-259.44,44.87-52.22,44.87-52.22V747.86S563,754.65,540.92,756.83Z" fill="#83929f" opacity="0.6"/>
          <path d="M209.09,655.83s6.2,41.4,9.91,52.28,46.89,36.66,60.3,43.1,47.88-1.73,52-3.76,32-30.83,35.71-34.8,14.81-56.82,14.81-56.82Z" fill="#83929f"/>
        </g>
      </g>
      <g clipPath="url(#clip-path-3)">
        <g id="doctor_title">
          <path d="M382,400.67q0,10.6-5.89,16.22t-17,5.61h-12V379.67H360.4q10.22,0,15.9,5.51T382,400.67Zm-7.38.24q0-15.39-14.39-15.38h-6.06v31.08h5Q374.61,416.61,374.61,400.91Z" fill="#fff"/>
          <path d="M417.7,406.24q0,7.93-4.08,12.39t-11.33,4.46a15.6,15.6,0,0,1-8-2,13.51,13.51,0,0,1-5.36-5.89,20.07,20.07,0,0,1-1.88-8.91c0-5.25,1.35-9.36,4-12.3s6.5-4.43,11.4-4.43q7,0,11.13,4.53T417.7,406.24Zm-23.59,0q0,11.22,8.29,11.22t8.21-11.22q0-11.1-8.27-11.1c-2.89,0-5,.95-6.28,2.87S394.11,402.67,394.11,406.24Z" fill="#fff"/>
          <path d="M437.17,423.09q-7.35,0-11.17-4.3t-3.83-12.32q0-8.16,4-12.56t11.56-4.4a21.67,21.67,0,0,1,9.23,1.91L444.88,397a21,21,0,0,0-7.21-1.7q-8.42,0-8.41,11.17,0,5.44,2.1,8.18a7.23,7.23,0,0,0,6.13,2.74,17.68,17.68,0,0,0,8.71-2.28v6a13.46,13.46,0,0,1-3.95,1.56A23.74,23.74,0,0,1,437.17,423.09Z" fill="#fff"/>
          <path d="M464.38,417.52a16.66,16.66,0,0,0,5-.79v5.18a13.67,13.67,0,0,1-2.94.84,20.73,20.73,0,0,1-3.74.34q-9.78,0-9.78-10.32V395.31h-4.43v-3l4.75-2.52,2.34-6.86h4.25v7.21h9.23v5.21h-9.23v17.35a4.85,4.85,0,0,0,1.25,3.67A4.54,4.54,0,0,0,464.38,417.52Z" fill="#fff"/>
          <path d="M503.14,406.24q0,7.93-4.08,12.39t-11.33,4.46a15.6,15.6,0,0,1-8-2,13.51,13.51,0,0,1-5.36-5.89,20.07,20.07,0,0,1-1.88-8.91c0-5.25,1.35-9.36,4.05-12.3s6.49-4.43,11.39-4.43q7,0,11.14,4.53T503.14,406.24Zm-23.59,0q0,11.22,8.29,11.22t8.21-11.22q0-11.1-8.26-11.1c-2.9,0-5,.95-6.29,2.87S479.55,402.67,479.55,406.24Z" fill="#fff"/>
          <path d="M526,389.51a17,17,0,0,1,3.43.29l-.67,6.42a13.33,13.33,0,0,0-3-.35,8.82,8.82,0,0,0-6.69,2.69,9.77,9.77,0,0,0-2.57,7V422.5h-6.88V390.1h5.39l.91,5.71h.35a12.78,12.78,0,0,1,4.2-4.6A10,10,0,0,1,526,389.51Z" fill="#fff"/>
          <path d="M541.06,416.2a97.38,97.38,0,0,1-5.15,14h-5.07a145,145,0,0,0,3-14.7h6.76Z" fill="#fff"/>
          <path d="M598.09,400.67q0,10.6-5.88,16.22t-17,5.61h-12V379.67H576.5q10.23,0,15.91,5.51T598.09,400.67Zm-7.38.24q0-15.39-14.38-15.38h-6.07v31.08h5Q590.7,416.61,590.71,400.91Z" fill="#fff"/>
          <path d="M633.8,406.24q0,7.93-4.07,12.39t-11.34,4.46a15.62,15.62,0,0,1-8-2,13.57,13.57,0,0,1-5.36-5.89,20.07,20.07,0,0,1-1.87-8.91c0-5.25,1.34-9.36,4-12.3s6.49-4.43,11.4-4.43q7,0,11.13,4.53T633.8,406.24Zm-23.58,0q0,11.22,8.29,11.22t8.2-11.22q0-11.1-8.26-11.1c-2.89,0-5,.95-6.28,2.87S610.22,402.67,610.22,406.24Z" fill="#fff"/>
          <path d="M653.28,423.09q-7.37,0-11.18-4.3t-3.82-12.32q0-8.16,4-12.56t11.55-4.4a21.64,21.64,0,0,1,9.23,1.91L661,397a20.91,20.91,0,0,0-7.2-1.7q-8.41,0-8.41,11.17,0,5.44,2.09,8.18a7.24,7.24,0,0,0,6.14,2.74,17.61,17.61,0,0,0,8.7-2.28v6a13.3,13.3,0,0,1-3.94,1.56A23.81,23.81,0,0,1,653.28,423.09Z" fill="#fff"/>
          <path d="M680.49,417.52a16.66,16.66,0,0,0,5-.79v5.18a13.77,13.77,0,0,1-2.95.84,20.61,20.61,0,0,1-3.73.34q-9.79,0-9.79-10.32V395.31h-4.42v-3l4.74-2.52,2.35-6.86H676v7.21h9.23v5.21H676v17.35a4.85,4.85,0,0,0,1.24,3.67A4.55,4.55,0,0,0,680.49,417.52Z" fill="#fff"/>
          <path d="M719.24,406.24q0,7.93-4.07,12.39t-11.34,4.46a15.62,15.62,0,0,1-8-2,13.57,13.57,0,0,1-5.36-5.89,20.07,20.07,0,0,1-1.87-8.91c0-5.25,1.35-9.36,4-12.3s6.49-4.43,11.4-4.43q7,0,11.13,4.53T719.24,406.24Zm-23.58,0q0,11.22,8.29,11.22t8.2-11.22q0-11.1-8.26-11.1c-2.89,0-5,.95-6.28,2.87S695.66,402.67,695.66,406.24Z" fill="#fff"/>
          <path d="M742.09,389.51a17.06,17.06,0,0,1,3.43.29l-.68,6.42a13.26,13.26,0,0,0-3.05-.35,8.84,8.84,0,0,0-6.69,2.69,9.76,9.76,0,0,0-2.56,7V422.5h-6.89V390.1H731l.91,5.71h.35a12.9,12.9,0,0,1,4.21-4.6A10,10,0,0,1,742.09,389.51Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  );
};
