import React from 'react';

export const DropzoneGlow = () => {

  return (
    <svg id="star-vibration" opacity="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" width="250" height="250">
      <g>
        <path className="vibe1" d="M155.5,93.86l.23.41.41.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42L156.14,155.5l-.41.23-.23.41-26.29,48.07a4.8,4.8,0,0,1-8.42,0L94.5,156.14l-.23-.41-.41-.23L45.79,129.21a4.8,4.8,0,0,1,0-8.42L93.86,94.5l.41-.23.23-.41,26.29-48.07a4.8,4.8,0,0,1,8.42,0Z" fill="none" stroke="#fefce3" strokeOpacity="0.5" strokeWidth="3.2"/>
        <path className="vibe2" d="M155.5,93.86l.23.41.41.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42L156.14,155.5l-.41.23-.23.41-26.29,48.07a4.8,4.8,0,0,1-8.42,0L94.5,156.14l-.23-.41-.41-.23L45.79,129.21a4.8,4.8,0,0,1,0-8.42L93.86,94.5l.41-.23.23-.41,26.29-48.07a4.8,4.8,0,0,1,8.42,0Z" fill="none" stroke="#fefce3" strokeOpacity="0.5" strokeWidth="3.2"/>
      </g>
    </svg>
  );
};
