import { helperStateActions } from 'app/store/actions/helper-data.action';

export const initialState = {
  stage: [],
  settings: {},
  messagesToShow: [],
};

function helperStateReducer(state = initialState, action) {
  switch (action.type) {
  case helperStateActions.SET_HELPER_STAGE_AND_MESSAGE:
    return {
      ...state,
      stage: action.payload.stage,
      settings: action.payload.settings,
    };
  case helperStateActions.SET_SHOW_HELPER_WORDS:
    return {
      ...state,
      messagesToShow: [
        ...state.messagesToShow,
        ...action.payload,
      ],
    };
  default:
    return state;
  }
}

export default helperStateReducer;
