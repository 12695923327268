import React from 'react';

import 'app/components/footer/footer-stats/footer-stats.scss';
import clsx from 'clsx';
import { number, string } from 'prop-types';

import { ProgressBar } from 'app/components/progress-bar/progress-bar.component';

export const FooterStats = props => {
  const { classNames, completed, correct, level, percents } = props;

  return (
    <div className={clsx(classNames)}>
      <div className="footer-stats-item">
        <div>Completed Activities Today</div>
        <div className="big-font">{completed}</div>
      </div>
      <div className="footer-stats-item">
        <div>Correct Activities Today</div>
        <div className="big-font">{correct}</div>
      </div>
      <div className="footer-level">
        <div className="footer-level-title">Level {level}</div>
        <ProgressBar percents={percents}/>
      </div>
    </div>
  );
};

FooterStats.propTypes = {
  classNames: string,
  completed: number,
  correct: number,
  level: number,
  percents: number
};

