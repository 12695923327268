import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ActivitiesSettingsButton
} from 'app/components/footer/activities-settings/activities-settings-button.component';
import { ConnectionStatus } from 'app/components/footer/connection-status/connection-status.component';
import { ExportButton } from 'app/components/footer/export-button/export-button.component';
import { Footer } from 'app/components/footer/footer.component';
// import { SoundSettings } from 'app/components/footer/sound-settings/sound-settings.component';
import { TAB_KEYS } from 'app/constants/tabs.constants';
import { toggleSettings } from 'app/store/actions/user-settings.actions';

import 'app/components/footer/reporting-page-footer/reporting-page-footer.scss';

export const ReportingPageFooter = () => {
  const dispatch = useDispatch();

  const { optionDisplayed, open } = useSelector(state => state.userSettings.teacherSettings);

  const { activeTabs } = useSelector(state => state.tabState);
  const areInStudentsTab = activeTabs && activeTabs[TAB_KEYS.REPORTING_PAGE] == 'students';

  return (
    <Footer>
      <>
        {/* <SoundSettings classNames="vcentered hcentered"/> TODO uncomment when sound is added */}
        <ConnectionStatus classNames="vcentered hcentered"/>
        <div className="emptySection" />
        <div className="rightSection">
          <div className="gear-export hcentered vcentered">
            { optionDisplayed && areInStudentsTab && !open && <ActivitiesSettingsButton onClick={() => dispatch(toggleSettings())} />}
          </div>
          <ExportButton label="Export List" />
        </div>
      </>
    </Footer>
  );
};
