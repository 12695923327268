import { useEffect } from 'react';

/**
 * Hook attach and cleanup event listeners that used to track click events
 * outside of target element.
 * @param targetRef {Object} - react targetRef with DOM element as a targetRef.current.
 * @param callback {function} - to be called once outside click happens
 * @param closeOnTarget {Boolean} - flag that is used to treat target element
 * @param blockClick {Boolean} - flag that is used to block callback call
 * @param background {Object} - background event listened would be attached
 * during outside click event as not part of targetRef element
 */
export default function useOnClickOutside(
  targetRef,
  callback,
  closeOnTarget = false,
  blockClick = false,
  background = document
) {
  useEffect(() => {
    if (!background) return;
    function handleClick(e) {
      if (blockClick) return;
      if (closeOnTarget && e.target === targetRef.current) {
        callback();
      }

      if (targetRef.current && !targetRef.current.contains(e.target)) {
        callback();
      }
    }

    background.addEventListener('click', handleClick, true);

    return () => {
      background.removeEventListener('click', handleClick);
    };
  }, [callback, closeOnTarget, targetRef, blockClick, background]);
}
