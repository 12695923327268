import { INACTIVE_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';

export const BoxInactive = ({ classNames }) => {

  return (
    <svg className={`${INACTIVE_CLASS} ${classNames || ''}`} width="177" height="177" viewBox="0 0 177 177" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="m119.439 57.586 43.847 23.98a8 8 0 0 1 0 14.039l-43.847 23.98-23.982 43.849a8 8 0 0 1-14.037 0l-23.981-43.848L13.59 95.605a8 8 0 0 1 0-14.038l43.848-23.981 23.98-43.848a8 8 0 0 1 14.038 0l23.982 43.848z" fill="#000000"/>
      </g>
    </svg>
  );
};