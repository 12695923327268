import { useSelector } from 'react-redux';

import { ACCESS_ROLES } from 'app/constants/auth.constant';
import { isSuccess } from 'app/store/reducers/api-status.reducer';

// @todo Look like we need to move this to hook
export const AccessComponent = ({ children, allowedRoles, redirectComponent }) => {
  const { isStudent, isTeacher } = useSelector(store => store.userData?.kcInfo);
  const { getUserDataStatus }  = useSelector(state => state.apiReducer);

  // We don't want a return if we aren't actually done loading access roles
  if (!isSuccess(getUserDataStatus)) {
    return '';
  }

  if (
    (allowedRoles.includes(ACCESS_ROLES.STUDENT) && isStudent) ||
    (allowedRoles.includes(ACCESS_ROLES.TEACHER) && isTeacher)
  ) {
    return children;
  }

  return !redirectComponent ? 'No Access!' : redirectComponent;
};
