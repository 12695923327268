import { CONFIG } from 'app/config';
import { SessionStorageService } from 'app/services/session-storage.service';

export const getRedirectUrl = () => {
  const redirectUrl = CONFIG.READING;
  const redirectLink = SessionStorageService.getRedirectLink();
  if (redirectLink) {
    return  'https://' + redirectLink + CONFIG.CUSTOMER_SUBDOMAIN_PARENT;
  }
  return redirectUrl + '/pub/pages/play.html';
};
