import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const KeishaTitle = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .add(gsap.to('#title-lab-table', { duration: 2, transformOrigin: '50% 50%', scale: 2.75, y: 260, x: 700, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#title-keisha', { duration: 2, transformOrigin: '50% 50%', scale: 2.75, rotate: -20, y: 60, x: 300, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#title-robot', { duration: 2, transformOrigin: '50% 50%', scale: 3, y: -220, x: 40, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#title-lab-bg', { duration: 2, transformOrigin: '50% 50%', scale: 2, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#robot-head', { duration: 2, transformOrigin: '50% 100%', y: 76, rotate: 5, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#robot-eyes', { duration: 2, transformOrigin: '50% 50%', y: 35, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#robot-arm-l', { duration: 2, transformOrigin: '98% 98%', rotate: 30, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#robot-arm-r', { duration: 2, transformOrigin: '2% 98%', rotate: 20, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#k-arm-r', { duration: 2, transformOrigin: '10% 10%', y: 8, rotate: 90, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#k-forearm-r', { duration: 2, transformOrigin: '15% 80%', rotate: 160, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#k-arm-l', { duration: 2, transformOrigin: '70% 5%', rotate: -10, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#k-forearm-l', { duration: 2, transformOrigin: '20% 16%', rotate: 185, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#k-head', { duration: 2, transformOrigin: '30% 95%', rotate: -25, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#pulse1, #pulse2', { duration: 1, strokeDashoffset: -1000, ease: 'sine.inOut' }), 0.75)
      .add(gsap.to('#title-keisha-2', { duration: 0.5, opacity: 1, transformOrigin: '50% 50%', scale: 1.2, y: -4, ease: 'sine.inOut' }), 1.5)
      .add(gsap.to('#title-robot', { duration: 1, transformOrigin: '50% 50%', scale: 4.8, y: -416, x: 244, ease: 'sine.inOut' }), 4)
      .add(gsap.to('#title-lab-table', { duration: 1, transformOrigin: '50% 50%', scale: 3, y: 500, x: 800, ease: 'sine.inOut' }), 4)
      .add(gsap.to('#title-keisha', { duration: 1, transformOrigin: '50% 50%', scale: 3, rotate: -20, y: 200, x: 950, ease: 'sine.inOut' }), 4)
      .add(gsap.to('#title-keisha-2', { duration: 0.5, opacity: 0, ease: 'sine.inOut' }), 4.5);

    tl.play(0);

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg id="title-keisha-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <title>keisha</title>
      <g id="title-lab-bg">
        <rect width="1024" height="680" fill="#3d2134"/>
        <rect x="899.97" y="1.18" width="38.77" height="38.77" fill="#542d4d"/>
        <rect x="967.95" y="101.8" width="38.77" height="38.77" transform="translate(608.73 1041.07) rotate(-74.63)" fill="#542d4d"/>
        <path d="M1024,0V108.63a87.76,87.76,0,0,1-12,.82A86.47,86.47,0,0,1,928.75,0Z" fill="#542d4d"/>
        <path d="M1024,0V57.34A38.68,38.68,0,0,1,979.27,0Z" fill="#3d2134"/>
        <rect x="914.77" y="62.63" width="38.77" height="38.77" transform="translate(147.64 592.68) rotate(-38.01)" fill="#542d4d"/>
        <g>
          <rect y="310.69" width="77.61" height="11.14" fill="#5c2d7b"/>
          <g>
            <polygon points="66.62 173.05 63.62 229.94 74.76 229.94 71.76 173.05 66.62 173.05" fill="#ffad00"/>
            <g>
              <polygon points="48.11 464.64 89.51 464.64 83.52 225.32 54.1 225.32 48.11 464.64" fill="#5c2d7b"/>
              <polygon points="89.68 467.64 48.05 467.64 48.05 450.27 89.68 459.38 89.68 467.64" fill="#4f144a"/>
              <path d="M105.36,475.54h0A12.55,12.55,0,0,0,92.8,463H44.38a12.55,12.55,0,0,0-12.56,12.55h73.54Z" fill="#5c2d7b"/>
            </g>
            <circle cx="69.08" cy="158.69" r="16.72" fill="#4f144a"/>
            <line x1="57.71" y1="204.41" x2="80.66" y2="204.41" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
            <line x1="60.71" y1="193.27" x2="77.66" y2="193.27" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
            <line x1="63.7" y1="182.14" x2="74.67" y2="182.14" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
          </g>
          <polygon points="23.88 321.82 0 321.82 0 310.69 17.3 310.69 23.88 321.82" fill="#4f144a"/>
          <path d="M42.39,214.09A12.55,12.55,0,0,0,55,226.64H82.4A12.56,12.56,0,0,0,95,214.09Z" fill="#5c2d7b"/>
          <circle cx="65.39" cy="154.8" r="5.57" fill="#713693"/>
        </g>
        <rect width="636.7" height="89.1" fill="#2e1926"/>
        <polygon points="406.63 0.01 42.39 89.17 211.35 89.17 564.52 0 406.63 0.01" fill="#544750"/>
      </g>
      <g id="title-robot">
        <g id="robot-arm-l">
          <path d="M239.18,393.67H221.77A71.89,71.89,0,0,1,150,321.86V294.6h13.88v27.26a58,58,0,0,0,57.94,57.94h17.41Z" fill="#837392"/>
          <circle cx="156.45" cy="286.24" r="8.38" fill="#de4b25"/>
          <path d="M170.59,243.58v18a14,14,0,0,1-14,14h0a14,14,0,0,1-14-14v-18" fill="none" stroke="#837392" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3.02"/>
          <g>
            <g>
              <line x1="149.95" y1="318.83" x2="163.51" y2="318.83" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="149.95" y1="308.63" x2="163.51" y2="308.63" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="162.46" y1="362.36" x2="173.66" y2="354.7" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="150.44" y1="330.12" x2="163.91" y2="328.57" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="152.7" y1="341.53" x2="165.75" y2="337.82" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="157.07" y1="352.3" x2="169.33" y2="346.52" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            </g>
            <g>
              <line x1="181.59" y1="381.39" x2="189.18" y2="370.15" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="172.78" y1="374.35" x2="182.04" y2="364.44" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="225.15" y1="393.67" x2="225.15" y2="380.11" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="191.73" y1="387.09" x2="197.41" y2="374.77" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="202.66" y1="391.08" x2="206.26" y2="378.01" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="214.12" y1="392.95" x2="215.56" y2="379.46" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            </g>
          </g>
        </g>
        <g id="robot-arm-r">
          <path d="M443.77,393.67H426.36V379.8h17.41a58,58,0,0,0,57.94-57.94V294.6h13.88v27.26A71.89,71.89,0,0,1,443.77,393.67Z" fill="#837392"/>
          <g>
            <g>
              <line x1="501.71" y1="318.83" x2="515.27" y2="318.83" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="501.71" y1="308.63" x2="515.27" y2="308.63" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="491.62" y1="354.52" x2="502.81" y2="362.18" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="501.32" y1="328.54" x2="514.79" y2="330.08" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="499.49" y1="337.74" x2="512.53" y2="341.45" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="496.49" y1="346.65" x2="508.76" y2="352.43" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            </g>
            <g>
              <line x1="476.18" y1="369.89" x2="483.78" y2="381.13" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="483.29" y1="364.21" x2="492.54" y2="374.12" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="440.39" y1="379.8" x2="440.39" y2="393.36" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="468" y1="374.49" x2="473.68" y2="386.8" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="459.2" y1="377.71" x2="462.8" y2="390.78" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
              <line x1="450.02" y1="379.78" x2="451.45" y2="393.26" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            </g>
          </g>
          <circle cx="509.14" cy="286.55" r="8.38" fill="#de4b25"/>
          <path d="M495,243.58v18a14,14,0,0,0,14,14h0a14,14,0,0,0,14-14v-18" fill="none" stroke="#837392" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3.02"/>
        </g>
        <g id="robot-neck">
          <rect x="318.12" y="213.46" width="27.53" height="104.84" fill="#837392"/>
          <g>
            <line x1="338.53" y1="280.33" x2="324.97" y2="280.21" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            <line x1="338.44" y1="290.52" x2="324.88" y2="290.4" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            <line x1="338.9" y1="238.42" x2="325.34" y2="238.3" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            <line x1="338.62" y1="269.81" x2="325.06" y2="269.69" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            <line x1="338.71" y1="259.29" x2="325.16" y2="259.17" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
            <line x1="338.49" y1="248.76" x2="324.93" y2="248.64" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
          </g>
        </g>
        <g id="robot-torso">
          <g id="body">
            <rect x="247.07" y="315.12" width="171.44" height="140.07" fill="#e8e9f5"/>
            <polygon points="247.11 411.49 234.6 416.79 234.6 357.93 247.11 363.22 247.11 411.49" fill="#e8e9f5"/>
            <polygon points="418.32 411.49 430.83 416.79 430.83 357.93 418.32 363.22 418.32 411.49" fill="#e8e9f5"/>
            <polygon points="355.44 304.99 308.88 304.99 303.58 316.63 360.73 316.63 355.44 304.99" fill="#e8e9f5"/>
            <line x1="306.23" y1="315.05" x2="357.93" y2="315.05" fill="none" stroke="#c5c6da" strokeMiterlimit="10" strokeWidth="0.62"/>
            <polyline points="328.66 304.99 324.05 316.63 337.43 316.63 334.75 304.99" fill="#fff"/>
            <line x1="418.43" y1="364.81" x2="418.53" y2="409.15" fill="none" stroke="#c5c6da" strokeMiterlimit="10" strokeWidth="0.62"/>
            <line x1="246.51" y1="364.81" x2="246.61" y2="409.15" fill="none" stroke="#c5c6da" strokeMiterlimit="10" strokeWidth="0.62"/>
            <polygon points="324.05 316.63 412.24 320.35 413.3 367.94 424.58 364.65 424.58 414.14 427.26 415.27 427.26 361.38 415.28 364.87 415.28 316.63 337.43 316.63 324.05 316.63" fill="#fff"/>
            <circle cx="249.62" cy="317.65" r="1.4" fill="#c5c6da"/>
            <circle cx="253.66" cy="317.65" r="1.4" fill="#c5c6da"/>
          </g>
          <rect id="screen" x="256.36" y="327.78" width="152.18" height="107.57" fill="#2e1926"/>
          <polyline id="pulse1" points="257 370.66 303.15 370.66 312.68 348.04 323.27 384.15 335.98 348.04 343.39 370.45 348.69 362.98 353.98 370.45 408.44 370.45" fill="none" stroke="#de4b25" strokeMiterlimit="10" strokeWidth="3" strokeDasharray="1000" strokeDashoffset="0"/>
          <polyline id="pulse2" points="257 370.66 303.15 370.66 312.68 348.04 323.27 384.15 335.98 348.04 343.39 370.45 348.69 362.98 353.98 370.45 408.44 370.45" fill="none" stroke="#ffad00" strokeMiterlimit="10" strokeWidth="3" strokeDasharray="1000" strokeDashoffset="0"/>
          <g id="title-keisha-2" opacity="0">
            <g>
              <path d="M295.29,364c-1.16,0-1.74.79-1.74,2.36v4.78q0,2.55-1.68,2.55c-1.16,0-1.74-.78-1.74-2.36l.05-16.78c0-1.58.56-2.36,1.69-2.36s1.68.83,1.68,2.5V359c0,1.67.65,2.5,1.93,2.5.65,0,1.48-.65,2.51-2l3.18-5.74c.65-1.09,1.29-1.64,1.93-1.64a1.05,1.05,0,0,1,1,.55,2,2,0,0,1,.32.94,2.24,2.24,0,0,1-.2.92l-4.63,7.57a1.2,1.2,0,0,0-.43.82,1.1,1.1,0,0,0,.38.92l4.59,7.28a2.63,2.63,0,0,1,.29,1,1.59,1.59,0,0,1-.44,1,1.29,1.29,0,0,1-1,.5c-.58,0-1.18-.46-1.79-1.39l-4.24-6.8c-.39-.45-.72-.81-1-1.09S295.42,364,295.29,364Z" fill="#ffeb3e"/>
              <path d="M314.1,371.71H319q1.83,0,1.83.87c0,.51-.58.77-1.73.77h-6c-2.51,0-3.76-1.18-3.76-3.52V359.17c0-2.32,1.28-3.47,3.85-3.47h4a4.34,4.34,0,0,1,2.72.74,2.58,2.58,0,0,1,.94,2.15v5.55a3.1,3.1,0,0,1-.94,2.55,4.16,4.16,0,0,1-2.72.77h-2.95c-1,0-1.44.73-1.44,2.17S313.2,371.71,314.1,371.71Zm-1.35-12v3.61a2.12,2.12,0,0,0,2.36,2.42c1.48,0,2.22-.81,2.22-2.42v-3.61c0-1.61-.74-2.41-2.22-2.41A2.11,2.11,0,0,0,312.75,359.75Z" fill="#ffeb3e"/>
              <path d="M327.55,354.11c-1.22,0-1.83-.44-1.83-1.31s.61-1.3,1.83-1.3,1.74.44,1.74,1.3S328.71,354.11,327.55,354.11Zm0,19.43c-1.19,0-1.78-.74-1.78-2.22V360.66c0-1.51.59-2.26,1.78-2.26s1.69.72,1.69,2.17v10.7C329.24,372.79,328.68,373.54,327.55,373.54Z" fill="#ffeb3e"/>
              <path d="M341.3,366.5h-3.14q-4,0-4-2.75v-2q0-3.44,3.86-3.43h5.4c1.22,0,1.83.26,1.83.77s-.64.92-1.93.92H339c-.93,0-1.4.53-1.4,1.59v2.12c0,.84.47,1.26,1.4,1.26h2.75c2.44,0,3.76,1,4,3v1.93q0,3.47-3.81,3.47h-6c-1.19,0-1.78-.31-1.78-.92s.64-.87,1.93-.87h5.21c.93,0,1.4-.51,1.4-1.54v-2.17A1.22,1.22,0,0,0,341.3,366.5Z" fill="#ffeb3e"/>
              <path d="M358.37,362.79q0-2.7-2.07-2.7h-.63c-1.29,0-1.93,1-1.93,2.89v8.1c0,1.71-.56,2.56-1.69,2.56s-1.83-.79-1.83-2.37V354.15c0-1.51.61-2.26,1.83-2.26s1.69.83,1.69,2.5v1.74c0,1.51.93,2.27,2.8,2.27h1.54a3.86,3.86,0,0,1,2.75.87,3.14,3.14,0,0,1,.91,2.41v9.69c0,1.51-.56,2.27-1.68,2.27s-1.69-.85-1.69-2.56Z" fill="#ffeb3e"/>
              <path d="M372.84,357.38h-4.1c-1.23,0-1.84-.28-1.84-.86s.58-.82,1.74-.82h5.74q3.57,0,3.57,3.66v10.52q0,3.47-3.57,3.47h-4.05q-3.91,0-3.91-2.85v-6.27a2.41,2.41,0,0,1,.92-2,4.44,4.44,0,0,1,2.84-.75h2.56a1.53,1.53,0,0,0,1.74-1.73v-.49C374.48,358,373.93,357.38,372.84,357.38Zm-3,8.15v3.67c0,1.67.78,2.51,2.36,2.51s2.27-.84,2.27-2.51v-3.67c0-1.57-.76-2.36-2.27-2.36A2.08,2.08,0,0,0,369.85,365.53Z" fill="#ffeb3e"/>
            </g>
            <g>
              <path d="M274,382.71c0-.2.19-.31.56-.31h4.1c.39,0,.58.11.58.31s-.2.32-.59.32h-.57c-.61,0-.91.28-.91.84v4.69c0,.56-.19.84-.56.84s-.55-.28-.55-.84v-4.69c0-.56-.31-.84-.92-.84h-.53C274.21,383,274,382.92,274,382.71Z" fill="#ffeb3e"/>
              <path d="M283,385.78q0-.9-.69-.9h-.21c-.43,0-.64.32-.64,1v2.7c0,.57-.19.86-.57.86s-.61-.27-.61-.79V382.9c0-.5.21-.75.61-.75s.57.28.57.83v.58c0,.51.31.76.93.76h.51a1.26,1.26,0,0,1,.92.29,1.06,1.06,0,0,1,.3.8v3.23c0,.5-.18.76-.56.76s-.56-.29-.56-.86Z" fill="#ffeb3e"/>
              <path d="M287.24,388.75h1.62c.41,0,.62.1.62.29s-.2.26-.58.26h-2q-1.26,0-1.26-1.17v-3.56c0-.77.43-1.15,1.29-1.15h1.33a1.41,1.41,0,0,1,.91.25.84.84,0,0,1,.32.71v1.85a1,1,0,0,1-.32.85,1.41,1.41,0,0,1-.91.26h-1q-.48,0-.48.72T287.24,388.75Zm-.45-4V386a.71.71,0,0,0,.79.81c.49,0,.74-.27.74-.81v-1.2c0-.54-.25-.81-.74-.81A.71.71,0,0,0,286.79,384.77Z" fill="#ffeb3e"/>
              <path d="M295.68,383.16v4.69c0,.57.32.86,1,.86h1.13c.62,0,.93-.29.93-.86l0-4.69c0-.56.19-.84.56-.84s.56.27.56.79v5c0,.77-.44,1.16-1.33,1.16h-2.65q-1.32,0-1.32-1.14v-5.05c0-.52.19-.79.58-.79S295.68,382.6,295.68,383.16Z" fill="#ffeb3e"/>
              <path d="M304.1,385.76c0-.6-.22-.9-.67-.9h-.21c-.43,0-.64.32-.64,1v2.68c0,.57-.19.85-.57.85s-.61-.24-.61-.74v-3.21a1.09,1.09,0,0,1,.32-.82,1.32,1.32,0,0,1,.95-.31H304a1.26,1.26,0,0,1,.91.3,1.06,1.06,0,0,1,.31.81v3.2c0,.52-.2.77-.59.77s-.57-.28-.57-.85Z" fill="#ffeb3e"/>
              <path d="M309.15,387h-1c-.89,0-1.34-.31-1.34-.92v-.67c0-.76.43-1.15,1.29-1.15h1.8c.41,0,.61.09.61.26s-.22.31-.64.31H308.4c-.32,0-.47.17-.47.53v.7c0,.28.15.42.47.42h.91c.82,0,1.26.34,1.32,1v.64c0,.77-.42,1.16-1.27,1.16h-2c-.4,0-.6-.1-.6-.31s.22-.28.64-.28h1.74c.31,0,.47-.18.47-.52v-.72C309.62,387.17,309.46,387,309.15,387Z" fill="#ffeb3e"/>
              <path d="M312.75,382.07c.38,0,.56.27.56.83v.53c0,.57.31.85.94.85h.3c.43,0,.65.1.65.28s-.22.29-.65.29h-.3c-.63,0-.94.37-.94,1.12v1.77a.83.83,0,0,0,.92.95h.39c.41,0,.62.1.62.3s-.19.31-.58.31h-1.33a1,1,0,0,1-1.17-1.17v-5.31C312.16,382.32,312.35,382.07,312.75,382.07Z" fill="#ffeb3e"/>
              <path d="M320,388.19a1,1,0,0,1-.31.85,1.41,1.41,0,0,1-.91.26h-1.33q-1.29,0-1.29-1.11v-2.78a1.09,1.09,0,0,1,.31-.82,1.31,1.31,0,0,1,.95-.31h1.36a1.27,1.27,0,0,1,.91.31,1.1,1.1,0,0,1,.31.84Zm-1.15-.42v-2c0-.63-.23-.95-.68-.95H318c-.42,0-.62.35-.62,1v1.9c0,.64.21,1,.64,1h.24C318.67,388.75,318.88,388.43,318.88,387.77Z" fill="#ffeb3e"/>
              <path d="M321.54,385.41a1.1,1.1,0,0,1,.32-.83,1.34,1.34,0,0,1,.95-.3h1.37a1.26,1.26,0,0,1,.91.3,1.09,1.09,0,0,1,.31.83v2.76a1.06,1.06,0,0,1-.31.87,1.41,1.41,0,0,1-.91.26h-.51c-.63,0-.94.28-.94.84v.29c0,.55-.19.83-.57.83s-.62-.25-.62-.75Zm2.71,2.35v-1.95c0-.64-.23-1-.68-1h-.21c-.43,0-.64.34-.64,1v1.89c0,.66.22,1,.66,1h.22C324,388.75,324.25,388.42,324.25,387.76Z" fill="#ffeb3e"/>
              <path d="M326.91,385.41a1.1,1.1,0,0,1,.32-.83,1.32,1.32,0,0,1,.95-.3h1.37a1.26,1.26,0,0,1,.91.3,1.09,1.09,0,0,1,.31.83v2.76a1.06,1.06,0,0,1-.31.87,1.41,1.41,0,0,1-.91.26H329c-.63,0-.94.28-.94.84v.29c0,.55-.19.83-.58.83s-.61-.25-.61-.75Zm2.7,2.35v-1.95c0-.64-.22-1-.67-1h-.21c-.43,0-.64.34-.64,1v1.89c0,.66.22,1,.66,1H329C329.4,388.75,329.61,388.42,329.61,387.76Z" fill="#ffeb3e"/>
              <path d="M334.44,384h-1.37c-.41,0-.61-.1-.61-.29s.19-.27.58-.27H335a1.06,1.06,0,0,1,1.19,1.22v3.5A1,1,0,0,1,335,389.3H333.6c-.87,0-1.3-.32-1.3-.95v-2.09a.81.81,0,0,1,.3-.65,1.48,1.48,0,0,1,.95-.25h.85a.51.51,0,0,0,.58-.58v-.16C335,384.19,334.8,384,334.44,384Zm-1,2.72v1.22c0,.55.26.83.79.83s.75-.28.75-.83V386.7c0-.53-.25-.79-.75-.79A.7.7,0,0,0,333.44,386.7Z" fill="#ffeb3e"/>
              <path d="M339.77,384.28h.52a1.32,1.32,0,0,1,.91.29,1,1,0,0,1,.31.81v2.81a1.07,1.07,0,0,1-.32.86,1.43,1.43,0,0,1-.93.27h-1.34c-.85,0-1.27-.38-1.27-1.15V383c0-.51.21-.77.61-.77s.57.28.57.84v.56C338.84,384.06,339.15,384.28,339.77,384.28Zm.58,3.49v-1.94c0-.66-.22-1-.67-1h-.21c-.42,0-.63.35-.63,1.06v1.88c0,.65.21,1,.65,1h.23C340.14,388.77,340.35,388.44,340.35,387.77Z" fill="#ffeb3e"/>
              <path d="M343.7,389.38c-.39,0-.58-.26-.58-.77v-5.77c0-.5.19-.74.58-.74s.56.24.56.74v5.77C344.26,389.12,344.07,389.38,343.7,389.38Z" fill="#ffeb3e"/>
              <path d="M347.59,388.75h1.62c.41,0,.61.1.61.29s-.19.26-.58.26h-2c-.83,0-1.25-.39-1.25-1.17v-3.56c0-.77.43-1.15,1.28-1.15h1.34a1.41,1.41,0,0,1,.91.25.84.84,0,0,1,.31.71v1.85a1,1,0,0,1-.31.85,1.41,1.41,0,0,1-.91.26h-1q-.48,0-.48.72T347.59,388.75Zm-.45-4V386q0,.81.78.81c.5,0,.74-.27.74-.81v-1.2c0-.54-.24-.81-.74-.81S347.14,384.23,347.14,384.77Z" fill="#ffeb3e"/>
              <path d="M355.62,389.33c-.36,0-.54-.26-.54-.79l0-5.48c0-.5.18-.75.55-.75s.56.25.56.77l0,5.46C356.19,389.07,356,389.33,355.62,389.33Z" fill="#ffeb3e"/>
              <path d="M360.57,385.76c0-.6-.22-.9-.67-.9h-.21c-.43,0-.64.32-.64,1v2.68c0,.57-.19.85-.57.85s-.61-.24-.61-.74v-3.21a1.09,1.09,0,0,1,.32-.82,1.32,1.32,0,0,1,.95-.31h1.37a1.26,1.26,0,0,1,.91.3,1.06,1.06,0,0,1,.31.81v3.2c0,.52-.2.77-.59.77s-.57-.28-.57-.85Z" fill="#ffeb3e"/>
              <path d="M365.2,389.32c-.21,0-.46-.17-.74-.5l-.74-.89a1.61,1.61,0,0,1-.46-1.14V385c0-.47.19-.71.58-.71s.61.27.61.79V387a.82.82,0,0,0,0,.42,1.2,1.2,0,0,0,.22.35l.48.62.5-.62a1.27,1.27,0,0,0,.25-.77V385c0-.52.2-.79.6-.79s.58.24.58.71v1.83a1.5,1.5,0,0,1-.13.6,2.82,2.82,0,0,1-.36.54l-.7.89C365.59,389.15,365.35,389.32,365.2,389.32Z" fill="#ffeb3e"/>
              <path d="M370.24,388.75h1.62c.41,0,.61.1.61.29s-.19.26-.58.26h-2c-.83,0-1.25-.39-1.25-1.17v-3.56c0-.77.43-1.15,1.28-1.15h1.34a1.43,1.43,0,0,1,.91.25.84.84,0,0,1,.31.71v1.85a1,1,0,0,1-.31.85,1.43,1.43,0,0,1-.91.26h-1q-.48,0-.48.72T370.24,388.75Zm-.45-4V386q0,.81.78.81c.5,0,.74-.27.74-.81v-1.2c0-.54-.24-.81-.74-.81S369.79,384.23,369.79,384.77Z" fill="#ffeb3e"/>
              <path d="M376.68,385.76c0-.6-.22-.9-.67-.9h-.21c-.43,0-.65.32-.65,1v2.68c0,.57-.18.85-.56.85s-.61-.24-.61-.74v-3.21a1.12,1.12,0,0,1,.31-.82,1.35,1.35,0,0,1,1-.31h1.37a1.28,1.28,0,0,1,.91.3,1.1,1.1,0,0,1,.31.81v3.2c0,.52-.2.77-.6.77s-.56-.28-.56-.85Z" fill="#ffeb3e"/>
              <path d="M380,382.07c.38,0,.56.27.56.83v.53c0,.57.31.85.94.85h.3c.43,0,.64.1.64.28s-.21.29-.64.29h-.3c-.63,0-.94.37-.94,1.12v1.77a.83.83,0,0,0,.92.95h.39c.41,0,.62.1.62.3s-.19.31-.58.31h-1.33a1,1,0,0,1-1.17-1.17v-5.31C379.37,382.32,379.56,382.07,380,382.07Z" fill="#ffeb3e"/>
              <path d="M387.24,388.19a1,1,0,0,1-.31.85,1.41,1.41,0,0,1-.91.26h-1.33q-1.29,0-1.29-1.11v-2.78a1.09,1.09,0,0,1,.31-.82,1.3,1.3,0,0,1,.94-.31H386a1.27,1.27,0,0,1,.91.31,1.1,1.1,0,0,1,.31.84Zm-1.15-.42v-2c0-.63-.23-.95-.68-.95h-.21c-.42,0-.63.35-.63,1v1.9c0,.64.22,1,.65,1h.24C385.88,388.75,386.09,388.43,386.09,387.77Z" fill="#ffeb3e"/>
              <path d="M391.33,384.85h-.5c-.59,0-.89.28-.89.83v2.85c0,.56-.18.83-.56.83s-.61-.24-.61-.74v-3.23c0-.74.43-1.11,1.3-1.11h1.27c.41,0,.61.1.61.29S391.74,384.85,391.33,384.85Z" fill="#ffeb3e"/>
            </g>
          </g>
        </g>
        <g id="robot-head">
          <path d="M200.21,178.28a15.89,15.89,0,0,1,0-31.77h9v31.66Z" fill="#de4b25"/>
          <path d="M464.74,146.51a15.89,15.89,0,0,1,0,31.77h-9V146.62Z" fill="#de4b25"/>
          <rect x="203.18" y="112.1" width="254.15" height="97.42" rx="48.71" fill="#e8e9f5"/>
          <polygon points="356.04 220.01 309.45 220.01 304.15 208.36 361.33 208.36 356.04 220.01" fill="#e8e9f5"/>
          <g id="robot-eyes">
            <circle cx="383.14" cy="154.33" r="13.68" fill="#2e1926"/>
            <circle cx="282.56" cy="154.33" r="13.68" fill="#2e1926"/>
          </g>
          <g>
            <rect x="411.61" y="106.73" width="13.42" height="109.19" fill="#8579ff"/>
            <rect x="239.52" y="106.73" width="13.42" height="109.19" fill="#8579ff"/>
          </g>
          <line x1="329.96" y1="72.29" x2="329.96" y2="113.21" fill="none" stroke="#241e23" strokeMiterlimit="10" strokeWidth="0.6"/>
          <circle cx="329.84" cy="64.65" r="8.2" fill="#8579ff"/>
        </g>
      </g>
      <g id="title-keisha">
        <g id="k-arm-l">
          <g id="k-forearm-l">
            <g>
              <polygon points="608.61 420.11 545.75 366.63 561.17 347.55 624.02 401.04 608.61 420.11" fill="#b9673b"/>
              <path d="M628,399.72l12,10.51a3.19,3.19,0,0,1,.35,4.39l-16.09,19.69a3,3,0,0,1-4.25.38l-12-10.51a3.18,3.18,0,0,1-.35-4.39l16.09-19.69A3,3,0,0,1,628,399.72Z" fill="#b9673b"/>
              <polygon points="594.9 414.99 533.7 360.26 554.5 334.68 618.5 387.54 594.9 414.99" fill="#ddf2ed"/>
            </g>
            <circle cx="543.65" cy="347.1" r="16.5" fill="#ddf2ed"/>
          </g>
          <g id="k-shoulder-l">
            <polygon points="625.37 276.75 565 351.04 538.88 330.92 599.25 256.63 625.37 276.75" fill="#ddf2ed"/>
            <polygon points="625.37 276.75 575.53 338.08 582.7 277 599.25 256.63 625.37 276.75" fill="#d2e3df"/>
            <circle cx="610.02" cy="269.29" r="16.5" fill="#d2e3df"/>
          </g>
        </g>
        <g id="k-neck">
          <path d="M670.44,261.1l1.11-12a9.59,9.59,0,0,0-8.67-10.45l-4.53-.42a9.6,9.6,0,0,0-10.45,8.68l-1.11,12Z" fill="#b9673b"/>
          <path d="M648,246.18l.38-4.14a9.61,9.61,0,0,1,10.61-8.5l4.19.39A9.61,9.61,0,0,1,672,244.24l-.5,4.86s-7.43,1.25-12.17.81C654.9,249.5,648,246.18,648,246.18Z" fill="#2e1926"/>
          <path d="M670.44,260.59l-23.57-2.33s.86,13,10,13.84C666.67,273,670.44,260.59,670.44,260.59Z" fill="#b9673b"/>
        </g>
        <g id="k-torso">
          <circle id="belly" cx="644.48" cy="406.06" r="50.88" fill="#7058a8"/>
          <g id="pants">
            <polygon points="584.91 496.59 686.73 506.05 701.48 412.28 587.98 401.73 584.91 496.59" fill="#ffad00"/>
            <rect x="619.27" y="410.61" width="49.22" height="10.18" transform="translate(1246.51 889.2) rotate(-174.69)" fill="#ffca00"/>
          </g>
          <g>
            <g>
              <path d="M640.85,255.57l35.07,3.26c11.89,1.1,20.05,9.63,21.17,19.67L616.41,271C619.36,261.34,629,254.46,640.85,255.57Z" fill="#7058a8"/>
              <path d="M696.89,301.35c-.11,6.64-.24,14.46-.37,22.81l-88-8.17c1.41-8.24,2.72-15.95,3.84-22.5Z" fill="#7058a8"/>
              <path d="M695.44,409.46a4.87,4.87,0,0,1-5,5.46l-92.61-8.61A4.87,4.87,0,0,1,593.9,400c.25-.91,1.39-7.2,3-16.53l98.58,9.16C695.4,402.13,695.36,408.53,695.44,409.46Z" fill="#7058a8"/>
              <path d="M695.81,369.82l-95-8.82c1.24-7.17,2.55-14.82,3.87-22.51l91.44,8.5C696,354.79,695.92,362.55,695.81,369.82Z" fill="#7058a8"/>
              <path d="M695.51,392.66l-98.58-9.16c1.11-6.34,2.46-14.08,3.91-22.5l95,8.82C695.69,378.37,695.58,386.22,695.51,392.66Z" fill="#9193e8"/>
              <path d="M696.15,347l-91.44-8.5c1.31-7.61,2.61-15.26,3.85-22.5l88,8.17C696.4,331.51,696.28,339.27,696.15,347Z" fill="#9193e8"/>
              <path d="M615.85,273.22c.15-.75.34-1.49.56-2.22l80.68,7.5a21.82,21.82,0,0,1,.14,2.29c0,.26-.14,8.44-.34,20.56l-84.49-7.86C614.43,281.55,615.8,273.48,615.85,273.22Z" fill="#9193e8"/>
            </g>
            <polygon points="679.86 259.28 662.57 493.17 708.62 497.83 710.78 262.15 679.86 259.28" fill="#ddf2ed"/>
            <rect x="673.62" y="449.01" width="26.78" height="31.25" transform="translate(1328.08 990.86) rotate(-174.69)" fill="#d2e3df"/>
            <rect x="690.96" y="435.94" width="5.42" height="13.82" transform="translate(1343.38 948) rotate(-174.69)" fill="#ffad00"/>
            <rect x="682.53" y="435.16" width="5.42" height="13.82" transform="translate(1326.63 945.66) rotate(-174.69)" fill="#4f144a"/>
            <polygon points="697.09 278.5 712.24 262.29 709.74 347.58 697.09 278.5" fill="#d2e3df"/>
          </g>
          <polygon points="637.09 255.3 610.97 488.38 564.85 484.47 606.16 252.43 637.09 255.3" fill="#ddf2ed"/>
          <rect x="576.59" y="439.99" width="26.78" height="31.25" transform="translate(1135.26 963.88) rotate(-174.69)" fill="#d2e3df"/>
        </g>
        <g id="k-head">
          <ellipse cx="711.15" cy="109.55" rx="26.1" ry="35.31" transform="translate(344.56 728.7) rotate(-68.13)" fill="#2e1926"/>
          <path d="M718.34,230c22.07-24.36,54.69-83.34-4.9-107.26-37.84-15.2-30.07,122-30.07,122S701.14,249,718.34,230Z" fill="#2e1926"/>
          <ellipse cx="719.46" cy="207.39" rx="14.43" ry="14.93" transform="translate(258.98 797.8) rotate(-68.13)" fill="#b9673b"/>
          <path d="M642.54,140.66c4.41-8.3,19.32-27.88,57.18-12.69,38.76,15.56,36.54,38,30.91,52.05l-.16-.06-14.73,36.7c-9.6,23.91-37.44,35.25-62.19,25.31h0c-24.74-9.93-37-37.37-27.42-61.29l16.11-40.14Z" fill="#b9673b"/>
          <path d="M715.93,196.36c6.94-17.29,8.52-41.8-30.24-57.36-19-7.62-32.19-6.5-41.21-2.44l1-2.42.3.12c4.41-8.3,19.32-27.88,57.18-12.69,38.76,15.56,36.54,38,30.91,52.05l-.16-.06-8.13,20.26Z" fill="#2e1926"/>
          <g>
            <ellipse cx="687.63" cy="197.82" rx="12.24" ry="14.42" transform="translate(247.89 762.26) rotate(-68.13)" fill="#fff"/>
            <ellipse cx="647.98" cy="181.9" rx="12.24" ry="14.42" transform="translate(237.78 715.47) rotate(-68.13)" fill="#fff"/>
            <circle cx="684.1" cy="196.4" r="9.18" fill="#2e1926"/>
            <circle cx="644.44" cy="180.48" r="9.18" fill="#2e1926"/>
            <path d="M683.69,180.31a19.68,19.68,0,0,1,10,1.41,20,20,0,0,1,8.28,5.89l2-.85a11.2,11.2,0,0,0-3.28-5.53A16.7,16.7,0,0,0,689,176.58a11.14,11.14,0,0,0-6.18,1.77Z" fill="#2e1926"/>
            <path d="M643.85,164.31a21.42,21.42,0,0,1,18.32,7.31l2-.85a11.25,11.25,0,0,0-3.27-5.54,16.73,16.73,0,0,0-5.38-3.45,16.53,16.53,0,0,0-6.27-1.19,11.17,11.17,0,0,0-6.19,1.77Z" fill="#2e1926"/>
          </g>
          <path d="M649.53,135.27s-6.57,3.61-8.12,7.47,8.28-43,54.51-26.48l-37.1,9.45Z" fill="#2e1926"/>
          <path d="M716.72,194.12a7.75,7.75,0,0,0-1.62,3.76c-.24,1.3-.3,2.54-.4,3.64a10.35,10.35,0,0,1-.48,2.67,2.26,2.26,0,0,1-.41.68,2.36,2.36,0,0,1-.58.53c-1.61,1.16-3.7,2.26-5.76,4A8.83,8.83,0,0,0,704.8,213a4.69,4.69,0,0,0-.19,2.48,4.47,4.47,0,0,0,1,2l1.12-.41a4.49,4.49,0,0,1,.34-1.51,2.69,2.69,0,0,1,.73-.91,11.43,11.43,0,0,1,2.33-1.31,37.15,37.15,0,0,0,6.42-3.27,7.87,7.87,0,0,0,1.92-1.94,7.5,7.5,0,0,0,1.07-2.53,10.93,10.93,0,0,0-.08-4.38c-.5-2.54-1.44-4.39-1.61-6.65Z" fill="#2e1926"/>
          <path d="M695.92,116.26s55.17,10.61,43.57,57.85l-40.3-44.05-17.82-7.47,2.72-7.56Z" fill="#2e1926"/>
          <ellipse cx="711.15" cy="109.55" rx="26.1" ry="35.31" transform="translate(344.56 728.7) rotate(-68.13)" fill="#2e1926"/>
          <rect x="654.63" y="133.52" width="80.35" height="16.07" transform="translate(1286.86 531.75) rotate(-158.13)" fill="#2e1926"/>
          <g>
            <polygon points="624.65 175.26 732.6 217.02 737.57 204.65 630.16 161.53 624.65 175.26" fill="#7769c7"/>
            <polygon points="681.55 198.1 713.27 210.84 716.16 196.05 687.06 184.37 681.55 198.1" fill="#504595"/>
            <path d="M624.71,175.1l54.36,21.82a57.84,57.84,0,0,0,3.75-6.41,59.83,59.83,0,0,0,1.47-7.44l-54.07-21.7Z" fill="#504595"/>
            <circle cx="691.28" cy="194.19" r="18.15" fill="#7769c7"/>
            <circle cx="691.33" cy="194.06" r="9.31" fill="#ffad00"/>
            <circle cx="648.12" cy="176.86" r="18.15" fill="#7769c7"/>
            <circle cx="647.8" cy="176.58" r="9.31" fill="#ffad00"/>
            <rect x="647.26" y="167.01" width="3.75" height="17.94" transform="translate(-23.29 189.66) rotate(-16.33)" fill="#ffdc80"/>
            <rect x="689.22" y="185.4" width="3.75" height="17.79" transform="translate(-22.21 275.26) rotate(-22.18)" fill="#ffdc80"/>
          </g>
          <g id="k-mouth-happy">
            <path d="M675.21,218c-2.23-2.66-26.89-13.23-30.35-13-3.9.3-2.26,5-.74,8.69,3.6,8.83,17.4,14.63,26.23,11C674.06,223.22,677.72,221,675.21,218Z" fill="#680900"/>
            <path d="M645,215.67c2.55-1.37,7.38-1.08,12.3,1,5.6,2.35,9.47,6.23,9.48,9.22,0,0-5.21.81-12.42-2.23A21.81,21.81,0,0,1,645,215.67Z" fill="#b70000"/>
          </g>
        </g>
        <g id="k-arm-r">
          <g id="k-forearm-r">
            <g>
              <polygon points="845.71 275.42 780.75 326.33 795.39 346.01 860.35 295.09 845.71 275.42" fill="#b9673b"/>
              <path d="M864.22,296.57l12.46-10a3.18,3.18,0,0,0,.52-4.37l-15.28-20.32a3,3,0,0,0-4.23-.55l-12.46,10a3.19,3.19,0,0,0-.52,4.37L860,296A3,3,0,0,0,864.22,296.57Z" fill="#b9673b"/>
              <polygon points="831.81 279.98 768.45 332.2 788.21 358.6 854.28 308.35 831.81 279.98" fill="#ddf2ed"/>
            </g>
            <circle cx="777.87" cy="345.75" r="16.5" fill="#ddf2ed"/>
          </g>
          <g id="k-forearm-l-2" data-name="k-forearm-l">
            <polygon points="691.07 287.21 761.74 351.77 784.6 328.01 713.93 263.45 691.07 287.21" fill="#ddf2ed"/>
            <circle cx="705.15" cy="277.56" r="16.5" fill="#ddf2ed"/>
          </g>
        </g>
      </g>
      <g id="title-lab-table">
        <circle cx="304.11" cy="485.44" r="24.62" fill="#2e1926"/>
        <circle cx="304.11" cy="484.86" r="16.58" fill="#837392"/>
        <rect y="448" width="1024" height="232" fill="#ffed5f"/>
        <rect x="17.14" y="597.98" width="468.57" height="47.4" fill="#5c2d7b"/>
        <g>
          <path d="M370.2,509v61.46a10,10,0,0,1-10,10h0a10,10,0,0,1-10-10V509Z" fill="#5c2d7b"/>
          <path d="M366.85,532.29V569.1a6.66,6.66,0,0,1-6.66,6.66h0a6.66,6.66,0,0,1-6.65-6.66V532.29Z" fill="#4f144a"/>
          <rect x="346.65" y="502.08" width="27.09" height="7.68" fill="#5c2d7b"/>
          <path d="M413.29,509v61.46a10,10,0,0,1-10,10h0a10,10,0,0,1-10-10V509Z" fill="#5c2d7b"/>
          <rect x="389.73" y="502.08" width="27.09" height="7.68" fill="#5c2d7b"/>
          <path d="M456.37,509v61.46a10,10,0,0,1-10,10h0a10,10,0,0,1-10-10V509Z" fill="#5c2d7b"/>
          <rect x="432.82" y="502.08" width="27.09" height="7.68" fill="#5c2d7b"/>
        </g>
        <polygon points="146.77 607.56 57.91 607.56 90.23 497.16 114.45 497.16 146.77 607.56" fill="#5c2d7b"/>
        <polygon points="133.26 598.12 69.27 598.12 88.62 532.01 113.9 532.01 133.26 598.12" fill="#4f144a"/>
        <path d="M409.91,512.47V569.1a6.66,6.66,0,0,1-6.65,6.66h0a6.66,6.66,0,0,1-6.66-6.66V512.47Z" fill="#4f144a"/>
        <path d="M452.78,558.77V569.1a6.66,6.66,0,0,1-6.66,6.66h0a6.66,6.66,0,0,1-6.65-6.66V558.77Z" fill="#4f144a"/>
        <polyline points="336.48 598.6 336.48 550.48 466.69 550.48 466.69 598.6" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M290.38,567.8H229.72A14.71,14.71,0,0,1,215,553.11V490.63a7.15,7.15,0,0,0-7.15-7.14h-30.8v7.14a14.71,14.71,0,0,1-14.56,14.69h-.13a14.69,14.69,0,0,1,.13-29.37h7V465.16a7.16,7.16,0,0,0-7.15-7.15H113.26a7.16,7.16,0,0,0-7.15,7.15v32.75H98.57V465.16a14.71,14.71,0,0,1,14.69-14.69h49.13a14.71,14.71,0,0,1,14.69,14.69V476h30.8a14.7,14.7,0,0,1,14.69,14.68v62.48a7.16,7.16,0,0,0,7.15,7.15h60.66a7.16,7.16,0,0,0,7.15-7.15V480.93a14.7,14.7,0,0,1,14.68-14.69h36.46a14.71,14.71,0,0,1,14.69,14.69v21.23h-7.54V480.93a7.16,7.16,0,0,0-7.15-7.15H312.21a7.15,7.15,0,0,0-7.14,7.15v72.18A14.71,14.71,0,0,1,290.38,567.8ZM162.52,483.49a7.15,7.15,0,1,0,7,7.14v-7.14Z" fill="#8044a7"/>
        <rect x="82.19" y="490.63" width="38.82" height="9.1" fill="#5c2d7b"/>
        <rect x="168.9" y="483.25" width="8.61" height="3.24" fill="#4f144a"/>
        <polygon points="1024 556.32 685.6 556.24 685.6 535.34 1024 535.34 1024 556.32" fill="#5c2d7b"/>
        <rect x="695.6" y="541.96" width="328.39" height="105.87" fill="#5c2d7b"/>
        <polygon points="695.6 556.32 1024 556.32 1024 570.54 696.51 562.25 695.6 556.32" fill="#4f144a"/>
        <g>
          <path d="M929.67,415.92s-12.55,7.44-16.66,7.44a7.44,7.44,0,1,1,0-14.88C917.12,408.48,929.67,415.92,929.67,415.92Z" fill="#8044a7"/>
          <path d="M928.77,416s12.48-7.56,16.59-7.6a7.44,7.44,0,0,1,.14,14.88C941.39,423.36,928.77,416,928.77,416Z" fill="#8044a7"/>
          <rect x="813.87" y="450.21" width="163.05" height="86.17" fill="#4f144a"/>
          <rect x="838.66" y="450.21" width="163.05" height="86.17" fill="#5c2d7b"/>
          <line x1="928.96" y1="451.64" x2="928.96" y2="412.4" fill="none" stroke="#6b1c6b" strokeMiterlimit="10" strokeWidth="2"/>
          <circle cx="878.34" cy="495.47" r="17.36" fill="#8044a7"/>
          <circle cx="927.94" cy="495.47" r="17.36" fill="#8044a7"/>
          <circle cx="979.39" cy="495.47" r="17.36" fill="#8044a7"/>
          <polyline points="927.63 488.34 927.63 497.64 931.97 492.06" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
          <polyline points="886.09 495.16 878.03 495.16 878.03 503.84" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
          <polyline points="987.68 495.16 979.62 495.16 984.6 501.76" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="0.5"/>
          <path d="M979.39,482.29a13.18,13.18,0,1,1-13.18,13.18,13.19,13.19,0,0,1,13.18-13.18m0-4.18a17.36,17.36,0,1,0,17.36,17.36,17.36,17.36,0,0,0-17.36-17.36Z" fill="#4f144a"/>
          <path d="M927.94,482.29a13.18,13.18,0,1,1-13.18,13.18,13.2,13.2,0,0,1,13.18-13.18m0-4.18a17.36,17.36,0,1,0,17.36,17.36,17.36,17.36,0,0,0-17.36-17.36Z" fill="#4f144a"/>
          <path d="M878.34,482.29a13.18,13.18,0,1,1-13.18,13.18,13.19,13.19,0,0,1,13.18-13.18m0-4.18a17.36,17.36,0,1,0,17.36,17.36,17.36,17.36,0,0,0-17.36-17.36Z" fill="#4f144a"/>
          <circle cx="868.73" cy="523.68" r="4.03" fill="#4f144a"/>
          <circle cx="887.33" cy="523.68" r="4.03" fill="#4f144a"/>
          <circle cx="917.71" cy="523.68" r="4.03" fill="#4f144a"/>
          <circle cx="936.31" cy="523.68" r="4.03" fill="#4f144a"/>
          <circle cx="971.02" cy="523.68" r="4.03" fill="#4f144a"/>
          <circle cx="989.62" cy="523.68" r="4.03" fill="#4f144a"/>
        </g>
        <g>
          <circle cx="756.3" cy="470.22" r="30.65" fill="#5c2d7b"/>
          <path d="M781,470.54a24.7,24.7,0,0,1-49.39,0" fill="#4f144a"/>
          <rect x="750.72" y="402.66" width="13.23" height="52.94" fill="#5c2d7b"/>
          <rect x="745.85" y="395.69" width="22.29" height="7.66" fill="#5c2d7b"/>
          <line x1="724.83" y1="477.88" x2="788.37" y2="477.88" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="1.5"/>
          <line x1="755.82" y1="477.88" x2="755.82" y2="535.14" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="1.5"/>
          <line x1="785.24" y1="477.88" x2="802.89" y2="535.14" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="1.5"/>
          <line x1="727.66" y1="477.88" x2="710.01" y2="535.14" fill="none" stroke="#4f144a" strokeMiterlimit="10" strokeWidth="1.5"/>
          <circle cx="766.3" cy="455.52" r="7.06" fill="#8044a7"/>
        </g>
      </g>
    </svg>
  );
};
