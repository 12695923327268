import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import ApiClient from 'app/api/api';
import { ConfirmModal } from 'app/components/confirm-modal/confirm.modal';
import { SplitDropdownWrapper } from 'app/components/dropdowns/split-dropdown-wrapper/split-dropdown-wrapper.component';
import { KEY_NAMES } from 'app/constants/keys.constant';
import { NaN_stream } from 'app/constants/streams.constants';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { getGradeForStudentClass } from 'app/util/grade';
import { getStreamsBy, getStreamsForGrade, makeStandaloneStream, getStreamType } from 'app/util/streams';
import { sendMessageToVoiceover } from 'app/util/voiceover';

export const StreamsDropdown = () => {

  const dispatch = useDispatch();

  const [streamName, setStreamName] = useState(undefined);
  const [dropdownHead, setDropdownHead] = useState({ left: undefined, right: undefined, });
  const [confirmSettings, setConfirmSettings] = useState(undefined);
  const [grade, setGrade] = useState(undefined);
  const [menuOptions, setMenuOptions] = useState(undefined);

  const { students, currentStudentBK } = useSelector(state => state.classesData);
  const student = students[currentStudentBK];

  const { activeClasses, currentClassBK } = useSelector(state => state.classesData);
  const activeClass = activeClasses[currentClassBK];

  useEffect(() => {
    // Set the grade and the menu options from grade
    const grade = getGradeForStudentClass({ activeStudent: student, activeClass: activeClass  });
    setGrade(grade);
    setMenuOptions(getStreamsForGrade(grade));
  }, []);
  
  useEffect(() => {
    // Set Stream Name Loading the Element
    if(!streamName) {
      let stream = student.latestProfile.streamName || student.stats.streamName;
      stream = stream && stream.replace(NaN_stream, grade);
      setStreamName(stream);
    }
  }, [student]);

  useEffect(() => {
    // Build the options menu

    if (menuOptions) {
      // Strategy is to use the type of the stream given by stream name for extraction.
      // Example: S_G5_AtGrade we would check by AtGrade.
      // Gets around issue of assigned stream not being the same as the grade.
      setDropdownHead({
        right: (streamName && streamName != 'None') ? getStreamsBy(menuOptions, getStreamType(streamName)) : { uiName: 'None' },
        left: {
          value: `Grade ${grade}`
        }
      });
    }
  }, [streamName, menuOptions, grade]);

  // Handlers for stream selection
  const confirmReturn = (e, confirmed, newStreamName) => {
    if (typeof e == 'object')
      e.preventDefault();

    if (confirmed) {
      // const streamData = makeStandaloneStream(newStreamName, sectionGrade);
      const streamData = makeStandaloneStream(newStreamName, grade);
      ApiClient.setStream(streamData).then(() => {
        // Success
        setStreamName(newStreamName);
        setConfirmSettings(undefined);
        sendMessageToVoiceover('Changing Stream Succeeded');

        // Reset the stream
        dispatch(classesDataActionCreators.getLatestStudentProfile.request());
      }).catch(() => {
        // Failureg
        // @TODO - Informative Error Message for failing to set the stream
        sendMessageToVoiceover('Changing Stream Failed');
        setConfirmSettings(undefined);
      });
    } else {
      setConfirmSettings(undefined);
    }
  };
  const selectHandler = (e, newStreamName) => {
    e.preventDefault();

    if (e.key && e.key != KEY_NAMES.ENTER)
      return;

    const oldStreamObj = streamName ? getStreamsBy(menuOptions, getStreamType(streamName)) : { uiName: 'None' };
    const newStreamObj = getStreamsBy(menuOptions, getStreamType(newStreamName));

    setConfirmSettings({
      header: 'Confirm Stream Change',
      description: `You are about to change this student's stream from ${oldStreamObj.uiName} to ${newStreamObj.uiName}`,
      data: newStreamName,
    });
  };

  return (
    <>
      {confirmSettings != undefined && 
        <ConfirmModal
          header={confirmSettings.header}
          description={confirmSettings.description}
          data={confirmSettings.data}
          confirmCallback={confirmReturn}
        />
      }
      {
        dropdownHead.left != undefined && dropdownHead.right != undefined ?
          <SplitDropdownWrapper
            selectHandler={selectHandler}
            dropdownHead={dropdownHead}
            menuOptions={menuOptions}
          />
          :
          <></>
      }
    </>
  );
};
