import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const QueryTitle = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .to('#smoke', { duration: 5.5, x: -200, ease: 'none' })
      .to('#stars', { duration: 5.5, x: -300, ease: 'none' }, 0)
      .to('#title-clip', { duration: 1.5, width: 723, ease: 'none' }, 0.5)
      .to('#query-eyes', { duration: 0.25, x: -20, y: 20, ease: 'sine.inOut' }, 0.5)
      .to('#eye-right, #eye-left', { duration: 0.1, transformOrigin: '50% 50%', scaleY: 0.1, scaleX: 1.25, yoyo: true, repeat: 1, ease: 'sine.inOut' }, 0.75)
      .to('#query-eyes', { duration: 1, x: 20, ease: 'sine.inOut' }, 1)
      .to('#query-eyes', { duration: 0.5, x: 0,  y: 0, ease: 'sine.inOut' }, 2)
      .to('#query-large', { duration: 0.4, y: -40, yoyo: true, repeat: 1, ease: 'sine.inOut' }, 2.5)
      .to('#title-full', { duration: 0.3, y: -20, yoyo: true, repeat: 1, ease: 'sine.inOut' }, 2.6)
      .to('#eye-right, #eye-left', { duration: 0.1, transformOrigin: '50% 50%', scaleY: 0.1, scaleX: 1.25, yoyo: true, repeat: 3, ease: 'sine.inOut' }, 3.3)
      .to('#query-eyes', { duration: 0.25, x: -20, ease: 'sine.inOut' }, 3.7)
      .to('#query-eyes', { duration: 0.25, x: 20, ease: 'sine.inOut' }, 4.3)
      .to('#query-eyes', { duration: 0.25, x: 0, ease: 'sine.inOut' }, 4.75);

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg id="query_title-screen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <defs>
        <clipPath id="clip-path">
          <rect id="title-clip" x="150" y="496" width="0" height="159" fill="none"/>
        </clipPath>
      </defs>
      <title>AskQuery</title>
      <rect id="sky" width="1024" height="680" fill="#090f30"/>
      <g id="smoke">
        <g>
          <polygon points="8.26 360.87 12.39 360.87 16.52 360.87 20.64 360.87 20.64 364.99 24.77 364.99 28.9 364.99 33.03 364.99 33.03 369.12 37.16 369.12 41.29 369.12 45.42 369.12 45.42 373.24 49.55 373.24 53.68 373.24 57.81 373.24 61.94 373.24 66.06 373.24 70.19 373.24 74.32 373.24 78.45 373.24 78.45 377.36 82.58 377.36 86.71 377.36 90.84 377.36 94.97 377.36 99.1 377.36 103.23 377.36 107.36 377.36 111.48 377.36 115.61 377.36 115.61 373.24 111.48 373.24 107.36 373.24 103.23 373.24 99.1 373.24 94.97 373.24 90.84 373.24 90.84 369.12 86.71 369.12 82.58 369.12 78.45 369.12 78.45 364.99 74.32 364.99 70.19 364.99 66.06 364.99 61.94 364.99 57.81 364.99 57.81 360.87 53.68 360.87 49.55 360.87 45.42 360.87 45.42 356.75 41.29 356.75 37.16 356.75 33.03 356.75 33.03 352.62 28.9 352.62 24.77 352.62 20.64 352.62 20.64 348.5 16.52 348.5 12.39 348.5 8.26 348.5 4.13 348.5 0 348.5 0 352.62 0 356.75 0 360.87 4.13 360.87 8.26 360.87" fill="#7b9cff" opacity="0.7"/>
          <path d="M1019.87,344.6h-33v4.12H970.32v4.12h-28.9V357H900.13v4.12H846.45V357H829.94v-4.13H805.16v-4.12H784.52V344.6h-8.26v-4.12H763.87v-4.13H751.48v-4.12H735v-4.12H722.58V324H701.94v-4.12H685.42v-4.12H673v-4.13H656.52v-4.12H640v-4.12H611.1v-4.12h-33v-4.13H545V291H437.68v4.12H417v4.13H400.52v4.12H384v4.12H371.61v4.12H359.23v4.13H351v4.12H338.58V324h-4.13v4.13h-8.26v4.12h-8.25v4.12H305.55v4.13h-8.26v4.12H284.9v4.12h-8.25v4.12h-8.26V357H256v4.12h-4.13v4.12h-8.26v4.13h-8.26v4.12H227.1v4.12H223v4.12h-8.26v4.13h-8.26V390h-8.26v4.12h-8.25v4.13h-8.26v4.12H169.29v4.12H152.77v4.12H132.13v4.13H107.35v4.12H45.42v-4.12H24.77v-4.13H8.26v-4.12H0v37.11H45.42v-4.12H95V435.3H156.9v-4.12h24.78v-4.12h16.51v-4.13h16.52v-4.12H223v-4.12h16.51v-4.13h12.39v-4.12h16.52v-4.12h12.38V398.2h16.52v-4.13h16.52V390h16.51v-4.12H445.94V390h20.64v4.12H483.1v4.13h12.38v4.12h12.39v4.12h20.65v4.12H540.9v4.13h16.52v4.12h8.26v4.12h8.26v4.13h12.38v4.12h12.39v4.12H607v4.13h8.26v4.12h8.25v4.12h8.26v4.12H640v4.13h8.26V460h4.13v4.12H668.9v4.13h12.39v4.12h16.52v4.12h20.64v4.12h86.71v-4.12h16.52v-4.12h12.38v-4.12h12.39v-4.13h8.26V460H867.1v-4.12h12.38v-4.13h8.26v-4.12h16.52v-4.12h8.26v-4.12h8.25V435.3h57.81v4.13h33v4.12H1024V406.44H986.84v-4.12h-33V398.2H904.26v4.12H879.48v4.12H863v4.12H846.45v4.13H834.06v4.12H821.68v4.12H805.16v4.13h-33v4.12H689.55v-4.12H668.9v-4.13H656.52v-4.12h-4.13v-4.12h-4.13v-8.25h-4.13V390h4.13V381.7h4.13v-4.12h4.13v-4.12h4.13v-4.12h16.51v-4.13h78.45v4.13h20.65v4.12h12.39v4.12h16.51v4.12h70.19v-4.12h37.17v-4.12h20.64v-4.12h20.65v-4.13h24.77v-4.12h28.9V357H1024V344.6Zm-619.35-4.12h4.13v-4.13H417v-4.12h28.91v-4.12h33V324h95v4.13h12.38v4.12h4.13v4.12h4.13v4.13h4.13v8.24h4.13V357h-4.13v4.12h-4.13v4.12h-8.26v4.13H569.81v4.12h-33v-4.12h-28.9v-4.13H491.35v-4.12H474.84V357H462.45v-4.13H450.06v-4.12H437.68V344.6H396.39v-4.12Z" fill="#7b9cff" opacity="0.7"/>
        </g>
        <path d="M1019.87,200h-12.39v4.12H970.32v4.13H933.16v4.12H887.74v-4.12H809.29v-4.13H768V200H631.74v4.12H594.58v4.13H557.42v4.12H536.77v4.12H516.13v4.12H503.74v4.13H479v4.12h-33V233H421.16v4.13H379.87v4.12H317.94v-4.12h-70.2V233H206.45v-4.12H165.16v-4.12H144.52v-4.13H128v-4.12H103.23v-4.12H86.71v-4.12H66.06v-4.13H53.68V200H0v78.34H8.26v4.12H24.77v4.12H37.16v4.12H49.55v4.13H66.06V299H78.45v4.12H95v4.13h12.38v4.12H128v4.12H231.23v-4.12h37.16V307.2h24.77v-4.13h12.39V299h20.64v-4.12h12.39V290.7H355.1v-4.12h8.25v-4.12h12.39v-4.12H384v-4.13h12.39v-4.12h12.38V266H417v-4.13h16.52v-4.12h8.26V253.6h16.51v-4.12h28.91v-4.13h20.64v-4.12H545v-4.12h33V233H607v-4.12h49.55V233h24.77v4.13h24.77v4.12h20.65v4.12h16.52v4.13h16.51v4.12h20.65v4.12H796.9v4.12h16.52V266h16.52v4.12h20.64v4.12h37.16v4.13h16.52v4.12H929v4.12h49.55v-4.12h20.65v-4.12H1024V200ZM317.94,270.09h-8.26v4.12H264.26v-4.12H256V266h4.13v-4.13h74.32V266H322.06v4.12Zm689.54-20.61v8.24h-4.13v4.12H924.9v-4.12H904.26V253.6H896v-4.12H879.48v-4.13h-8.25v-4.12H858.84v-4.12H842.32V233H953.81v4.13h28.9v4.12h20.64v4.12h4.13ZM4.13,228.86H33V233H49.55v4.13H70.19v4.12H99.1v4.12h12.38v4.13h12.39v4.12h20.65v4.12h24.77v4.12h37.16V266h12.39v4.12H223v4.12h-4.13v4.13h-8.26v4.12H194.06v4.12H132.13v-4.12H115.61v-4.12H99.1v-4.13H82.58v-4.12H70.19V266H61.94v-4.13H53.68v-4.12H49.55V253.6H45.42v-4.12H37.16v-4.13H28.9v-4.12H20.65v-4.12H12.39V233H4.13Z" fill="#ad8bfc" opacity="0.7"/>
        <g>
          <polygon points="2039.74 360.87 2035.61 360.87 2031.48 360.87 2027.36 360.87 2027.36 364.99 2023.23 364.99 2019.1 364.99 2014.97 364.99 2014.97 369.12 2010.84 369.12 2006.71 369.12 2002.58 369.12 2002.58 373.24 1998.45 373.24 1994.32 373.24 1990.19 373.24 1986.07 373.24 1981.94 373.24 1977.81 373.24 1973.68 373.24 1969.55 373.24 1969.55 377.36 1965.42 377.36 1961.29 377.36 1957.16 377.36 1953.03 377.36 1948.9 377.36 1944.78 377.36 1940.64 377.36 1936.52 377.36 1932.39 377.36 1932.39 373.24 1936.52 373.24 1940.64 373.24 1944.78 373.24 1948.9 373.24 1953.03 373.24 1957.16 373.24 1957.16 369.12 1961.29 369.12 1965.42 369.12 1969.55 369.12 1969.55 364.99 1973.68 364.99 1977.81 364.99 1981.94 364.99 1986.07 364.99 1990.19 364.99 1990.19 360.87 1994.32 360.87 1998.45 360.87 2002.58 360.87 2002.58 356.75 2006.71 356.75 2010.84 356.75 2014.97 356.75 2014.97 352.62 2019.1 352.62 2023.23 352.62 2027.36 352.62 2027.36 348.5 2031.48 348.5 2035.61 348.5 2039.74 348.5 2043.87 348.5 2048 348.5 2048 352.62 2048 356.75 2048 360.87 2043.87 360.87 2039.74 360.87" fill="#7b9cff" opacity="0.7"/>
          <path d="M1024,344.6V357h16.52v4.12h28.9v4.12h24.77v4.13h20.65v4.12h20.64v4.12h37.17v4.12h70.19v-4.12h16.52v-4.12h12.38v-4.12h20.65v-4.13h78.45v4.13h16.52v4.12h4.12v4.12h4.13v4.12h4.13V390h4.13v16.49h-4.13v8.25h-4.13v4.12h-4.13v4.12H1379.1v4.13h-20.65v4.12h-82.58v-4.12h-33v-4.13h-16.52v-4.12h-12.38v-4.12h-12.39v-4.13H1185v-4.12h-16.51v-4.12h-24.78V398.2h-49.55v4.12h-33v4.12H1024v37.11h12.39v-4.12h33V435.3h57.81v4.13h8.25v4.12h8.26v4.12h16.52v4.12h8.26v4.13h12.38V460h12.39v4.12h8.26v4.13h12.39v4.12h12.38v4.12h16.52v4.12h86.71v-4.12h20.64v-4.12h16.52v-4.12h12.39v-4.13h16.51V460h4.13v-4.12H1408v-4.13h8.26v-4.12h8.26v-4.12h8.25v-4.12H1441V435.3h8.26v-4.12h12.39v-4.12h12.38v-4.13h8.26v-4.12h8.26v-4.12h16.52v-4.13h12.38v-4.12h20.65v-4.12h12.39V398.2h12.38v-4.13h16.52V390h20.64v-4.12h115.62V390h16.51v4.12h16.52v4.13h16.52v4.12h12.38v4.12h16.52v4.12h12.39v4.13H1825v4.12h8.26v4.12h16.52v4.13h16.51v4.12h24.78v4.12H1953v4.13h49.55v4.12H2048V406.44h-8.26v4.12h-16.51v4.13h-20.65v4.12h-61.93v-4.12h-24.78v-4.13h-20.64v-4.12h-16.52v-4.12h-12.39V398.2h-8.26v-4.13h-8.25V390h-8.26v-4.12h-8.26V381.7H1825v-4.12h-4.13v-4.12h-8.25v-4.12h-8.26v-4.13h-8.26v-4.12H1792V357h-12.39v-4.13h-8.25v-4.12h-8.26V344.6h-12.39v-4.12h-8.26v-4.13h-12.39v-4.12h-8.25v-4.12h-8.26V324h-4.13v-4.12H1697v-4.12h-8.26v-4.13h-12.38v-4.12H1664v-4.12h-16.52v-4.12H1631v-4.13h-20.65V291H1503v4.12h-33v4.13h-33v4.12H1408v4.12h-16.52v4.12H1375v4.13h-12.39v4.12h-16.52V324h-20.64v4.13H1313v4.12h-16.51v4.12h-12.39v4.13h-12.39v4.12h-8.26v4.12h-20.64v4.12h-24.78V357h-16.51v4.12h-53.68V357h-41.29v-4.13h-28.9v-4.12h-16.52V344.6H1024Zm627.61-4.12v4.12h-41.29v4.12h-12.38v4.12h-12.39V357h-12.39v4.12h-16.51v4.12h-16.52v4.13h-28.9v4.12h-33v-4.12h-16.51v-4.13h-8.26v-4.12h-4.13V357h-4.13v-8.25h4.13v-8.24h4.13v-4.13h4.13v-4.12h4.13v-4.12h12.38V324h95v4.13h33v4.12H1631v4.12h12.39v4.13h8.25Z" fill="#7b9cff" opacity="0.7"/>
        </g>
        <path d="M1024,200v78.34h24.77v4.12h20.65v4.12H1119v-4.12h24.77v-4.12h16.52v-4.13h37.16v-4.12h20.64V266h16.52v-4.13h16.52v-4.12h16.51V253.6h20.65v-4.12h16.51v-4.13h16.52v-4.12h20.65v-4.12h24.77V233h24.77v-4.12H1441V233h28.91v4.13h33v4.12h37.16v4.12h20.64v4.13h28.91v4.12h16.51v4.12h8.26v4.12H1631V266h8.26v4.12h12.38v4.12H1664v4.13h8.26v4.12h12.39v4.12h8.25v4.12h16.52v4.13h12.39V299h20.64v4.12h12.39v4.13h24.77v4.12h37.16v4.12H1920v-4.12h20.65V307.2H1953v-4.13h16.52V299h12.39v-4.12h16.51V290.7h12.39v-4.12h12.39v-4.12h16.51v-4.12H2048V200h-53.68v4.12h-12.38v4.13h-20.65v4.12h-16.52v4.12H1920v4.12h-16.52v4.13h-20.64v4.12h-41.29V233h-41.29v4.13h-70.2v4.12h-61.93v-4.12h-41.29V233h-24.78v-4.12h-33v-4.12h-24.77v-4.13h-12.39v-4.12h-20.64v-4.12h-20.65v-4.12h-37.16v-4.13h-37.16V200H1280v4.12h-41.29v4.13h-78.45v4.12h-45.42v-4.12h-37.16v-4.13h-37.16V200H1024Zm701.94,70.09V266h-12.39v-4.13h74.32V266H1792v4.12h-8.26v4.12h-45.42v-4.12h-12.38Zm-685.42-24.74h4.13v-4.12h20.64v-4.12h28.9V233h111.49v4.13h-16.52v4.12h-12.39v4.12h-8.25v4.13H1152v4.12h-8.26v4.12H1123.1v4.12h-78.45v-4.12h-4.13V245.35ZM2043.87,233h-8.26v4.13h-8.25v4.12h-8.26v4.12h-8.26v4.13h-8.26v4.12h-4.13v4.12h-4.13v4.12h-8.26V266h-8.25v4.12h-12.39v4.12H1948.9v4.13h-16.51v4.12h-16.52v4.12h-61.93v-4.12h-16.52v-4.12h-8.26v-4.13H1825v-4.12h4.13V266h12.39v-4.13h37.16v-4.12h24.77V253.6h20.65v-4.12h12.39v-4.13h12.38v-4.12h28.91v-4.12h20.64V233H2015v-4.12h28.9Z" fill="#ad8bfc" opacity="0.7"/>
      </g>
      <g id="stars">
        <g id="stars1">
          <rect x="335.12" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="363.99" y="297.06" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="331" y="227.04" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="269.12" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="318.62" y="255.87" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="293.87" y="29.33" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="161.87" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="207.25" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="347.49" y="292.94" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="141.25" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="149.5" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="260.87" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="302.12" y="214.68" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="277.37" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="240.25" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="161.87" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="322.75" y="66.4" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="133" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="260.87" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="355.74" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="83.5" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="62.87" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="363.99" y="247.63" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="34" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="289.75" y="78.76" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="248.5" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="17.5" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="174.25" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="302.12" y="272.34" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="318.62" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="67" y="0.5" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="326.87" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="236.12" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="236.12" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="87.62" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="71.12" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="211.37" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="104.12" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="17.5" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="91.75" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="46.37" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="166" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="128.87" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="95.87" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="273.25" y="272.34" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="215.5" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="42.25" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="186.62" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="79.37" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="128.87" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="190.75" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="5.12" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="343.37" y="0.5" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="368.12" y="346.48" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="108.25" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="29.87" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="355.74" y="107.59" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="9.25" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="265" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="100" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="372.24" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="50.5" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="314.5" y="107.59" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="248.5" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="17.5" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="194.87" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="265" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="153.62" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="199" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="87.62" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="236.12" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="310.37" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="363.99" y="157.02" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="186.62" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="112.37" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="67" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="265" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="116.5" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="347.49" y="198.2" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="186.62" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="161.87" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="227.87" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="203.12" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="137.12" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="141.25" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="42.25" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="9.25" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="137.12" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="95.87" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="34" y="25.21" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="91.75" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="108.25" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="145.37" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="104.12" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="50.5" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="112.37" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="91.75" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="83.5" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="38.12" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="95.87" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="54.62" y="49.93" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="91.75" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="34" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="50.5" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="34" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="42.25" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="112.37" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="62.87" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="13.37" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="29.87" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="5.12" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="50.5" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="293.87" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="289.75" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="285.62" y="247.63" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="285.62" y="243.51" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="285.62" y="235.27" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="285.62" y="231.16" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="281.5" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="277.37" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="124.75" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="120.62" y="350.6" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="120.62" y="346.48" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="120.62" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="120.62" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="116.5" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="256.75" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="252.62" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="252.62" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="252.62" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="252.62" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="248.5" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="25.75" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="21.62" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="21.62" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="17.5" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="145.37" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="141.25" y="157.02" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="141.25" y="148.78" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="137.12" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="298" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="293.87" y="321.77" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="293.87" y="317.65" width="4.12" height="4.12" fill="#fff"/>
            <rect x="293.87" y="313.53" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="289.75" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="302.12" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="298" y="49.93" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="298" y="45.81" width="4.12" height="4.12" fill="#fff"/>
            <rect x="298" y="41.69" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="293.87" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="161.87" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="157.75" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="157.75" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="157.75" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="153.62" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="326.87" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="339.24" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="335.12" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="335.12" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="335.12" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="331" y="144.66" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="331" y="140.54" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="331" y="136.42" width="4.12" height="4.12" fill="#fff"/>
              <rect x="331" y="132.3" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="331" y="128.18" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="326.87" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="326.87" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="322.75" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="50.5" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="62.87" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="58.75" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="58.75" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="58.75" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="54.62" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="54.62" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="54.62" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="54.62" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="54.62" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="50.5" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="50.5" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="46.37" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="71.12" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="67" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="75.25" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="62.87" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="79.37" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="71.12" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="67" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="58.75" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="79.37" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="71.12" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="67" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="58.75" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="75.25" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="62.87" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="71.12" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="67" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
          <rect x="718.74" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="747.61" y="297.06" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="714.61" y="227.04" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="652.74" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="702.24" y="255.87" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="677.49" y="29.33" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="545.49" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="590.87" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="731.11" y="292.94" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="524.87" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="533.12" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="644.49" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="685.74" y="214.68" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="660.99" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="623.87" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="545.49" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="706.36" y="66.4" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="516.62" y="0.5" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="644.49" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="739.36" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="467.12" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="446.49" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="747.61" y="247.63" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="417.62" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="384.62" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="673.36" y="78.76" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="632.12" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="401.12" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="557.87" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="685.74" y="272.34" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="702.24" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="446.49" y="12.86" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="710.49" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="619.74" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="619.74" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="471.24" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="458.87" y="82.88" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="594.99" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="487.74" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="401.12" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="475.37" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="429.99" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="549.62" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="512.49" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="479.49" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="656.87" y="272.34" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="599.12" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="425.87" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="570.24" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="462.99" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="512.49" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="574.37" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="388.74" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="722.86" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="751.74" y="346.48" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="491.87" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="314.5" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="739.36" y="107.59" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="392.87" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="648.62" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="483.62" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="755.86" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="434.12" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="698.11" y="107.59" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="632.12" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="401.12" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="578.49" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="648.62" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="537.24" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="582.62" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="471.24" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="619.74" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="693.99" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="747.61" y="157.02" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="570.24" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="495.99" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="450.62" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="384.62" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="648.62" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="500.12" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="731.11" y="198.2" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="570.24" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="545.49" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="611.49" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="586.74" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="520.74" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="524.87" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="425.87" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="392.87" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="520.74" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="479.49" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="355.74" y="21.09" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="475.37" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="491.87" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="528.99" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="487.74" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="434.12" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="495.99" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="475.37" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="467.12" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="421.74" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="479.49" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="438.24" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="475.37" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="384.62" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="417.62" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="434.12" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="417.62" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="425.87" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="495.99" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="446.49" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="396.99" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="413.49" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="388.74" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="434.12" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="677.49" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="673.36" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="669.24" y="247.63" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="669.24" y="243.51" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="669.24" y="235.27" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="669.24" y="231.16" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="665.12" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="660.99" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="545.49" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="541.37" y="346.48" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="541.37" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="541.37" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="541.37" y="330.01" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="537.24" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="640.37" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="636.24" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="636.24" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="636.24" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="636.24" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="632.12" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="409.37" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="405.24" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="405.24" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="401.12" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="528.99" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="524.87" y="157.02" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="524.87" y="148.78" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="520.74" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="681.61" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="677.49" y="321.77" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="677.49" y="317.65" width="4.12" height="4.12" fill="#fff"/>
            <rect x="677.49" y="313.53" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="673.36" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="685.74" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="681.61" y="49.93" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="681.61" y="45.81" width="4.12" height="4.12" fill="#fff"/>
            <rect x="681.61" y="41.69" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="677.49" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="545.49" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="541.37" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="541.37" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="541.37" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="537.24" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="710.49" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="722.86" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="718.74" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="718.74" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="718.74" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="714.61" y="144.66" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="714.61" y="140.54" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="714.61" y="136.42" width="4.12" height="4.12" fill="#fff"/>
              <rect x="714.61" y="132.3" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="714.61" y="128.18" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="710.49" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="710.49" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="706.36" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="434.12" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="446.49" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="442.37" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="442.37" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="442.37" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="438.24" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="438.24" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="438.24" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="438.24" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="438.24" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="434.12" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="434.12" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="429.99" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="458.87" y="54.04" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="454.74" y="54.04" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="462.99" y="49.93" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="450.62" y="49.93" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="467.12" y="45.81" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="458.87" y="45.81" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="454.74" y="45.81" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="446.49" y="45.81" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="467.12" y="41.69" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="458.87" y="41.69" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="454.74" y="41.69" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="446.49" y="41.69" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="462.99" y="37.57" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="450.62" y="37.57" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="458.87" y="33.45" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="454.74" y="33.45" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
          <rect x="1019.86" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="912.61" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="957.99" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="891.99" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="900.24" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1011.61" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="990.99" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="912.61" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="883.74" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1011.61" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="834.24" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="813.61" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="784.74" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="751.74" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="999.24" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="768.24" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="924.99" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="813.61" y="12.86" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="986.86" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="986.86" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="838.36" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="830.11" y="37.57" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="962.11" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="854.86" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="768.24" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="842.49" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="797.11" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="916.74" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="879.61" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="846.61" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="966.24" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="792.99" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="937.36" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="830.11" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="879.61" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="941.49" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="755.86" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="858.99" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="780.61" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="759.99" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1015.73" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="850.74" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="801.24" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="999.24" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="768.24" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="945.61" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1015.73" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="904.36" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="949.74" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="838.36" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="986.86" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="937.36" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="863.11" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="817.74" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="751.74" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1015.73" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="867.24" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="937.36" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="912.61" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="978.61" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="953.86" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="887.86" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="891.99" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="792.99" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="764.11" y="0.5" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="887.86" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="846.61" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="784.74" y="25.21" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="842.49" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="858.99" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="896.11" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="854.86" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="801.24" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="863.11" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="842.49" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="834.24" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="788.86" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="846.61" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="805.36" y="49.93" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="842.49" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="751.74" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="784.74" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="801.24" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="784.74" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="792.99" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="863.11" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="813.61" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="764.11" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="780.61" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="755.86" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="801.24" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="887.86" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="883.74" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="883.74" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="883.74" y="330.01" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="883.74" y="325.89" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="879.61" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="1007.48" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1003.36" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1003.36" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1003.36" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1003.36" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="999.24" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="776.49" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="772.36" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="772.36" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="768.24" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="904.36" y="169.37" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="900.24" y="173.49" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="900.24" y="165.25" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="896.11" y="169.37" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="912.61" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="908.49" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="908.49" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="908.49" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="904.36" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="801.24" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="813.61" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="809.49" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="809.49" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="809.49" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="805.36" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="805.36" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="805.36" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="805.36" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="805.36" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="801.24" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="801.24" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="797.11" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="825.99" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="821.86" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="830.11" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="817.74" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="834.24" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="825.99" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="821.86" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="813.61" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="834.24" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="825.99" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="821.86" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="813.61" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="830.11" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="817.74" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="825.99" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="821.86" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
        </g>
        <g id="stars2">
          <rect x="1359.12" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1387.99" y="297.06" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1355" y="227.04" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1293.12" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1342.62" y="255.87" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1317.87" y="29.33" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1185.87" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1231.25" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1371.49" y="292.94" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1165.25" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1173.5" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1284.87" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1326.12" y="214.68" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1301.37" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1264.25" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1185.87" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1346.75" y="66.4" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1157" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1284.87" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1379.74" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1107.5" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1086.87" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1387.99" y="247.63" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1058" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1025" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1313.75" y="78.76" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1272.5" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1041.5" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1198.25" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1326.12" y="272.34" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1342.62" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1091" y="0.5" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1350.87" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1260.12" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1260.12" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1111.62" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1095.12" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1235.37" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1128.12" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1041.5" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1115.75" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1070.37" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1190" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1152.87" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1119.87" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1297.25" y="272.34" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1239.5" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1066.25" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1210.62" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1103.37" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1152.87" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1214.75" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1029.12" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1367.37" y="0.5" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1392.12" y="346.48" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1132.25" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1053.87" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1379.74" y="107.59" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1033.25" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1289" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1124" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1396.24" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1074.5" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1338.5" y="107.59" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1272.5" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1041.5" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1218.87" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1289" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1177.62" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1223" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1111.62" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1260.12" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1334.37" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1387.99" y="157.02" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1210.62" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1136.37" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1091" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1025" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1289" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1140.5" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1371.49" y="198.2" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1210.62" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1185.87" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1251.87" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1227.12" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1161.12" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1165.25" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1066.25" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1033.25" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1161.12" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1119.87" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1058" y="25.21" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1115.75" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1132.25" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1169.37" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1128.12" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1074.5" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1136.37" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1115.75" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1107.5" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1062.12" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1119.87" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1078.62" y="49.93" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1115.75" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1025" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1058" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1074.5" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1058" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1066.25" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1136.37" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1086.87" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1037.37" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1053.87" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1029.12" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1074.5" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="1317.87" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1313.75" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1309.62" y="247.63" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1309.62" y="243.51" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1309.62" y="235.27" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1309.62" y="231.16" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1305.5" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1301.37" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="1148.75" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1144.62" y="350.6" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1144.62" y="346.48" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1144.62" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1144.62" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1140.5" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="1280.75" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1276.62" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1276.62" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1276.62" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1276.62" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1272.5" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="1049.75" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1045.62" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1045.62" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1041.5" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="1169.37" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1165.25" y="157.02" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1165.25" y="148.78" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1161.12" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="1322" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1317.87" y="321.77" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1317.87" y="317.65" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1317.87" y="313.53" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1313.75" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1326.12" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1322" y="49.93" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1322" y="45.81" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1322" y="41.69" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1317.87" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1185.87" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1181.75" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1181.75" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1181.75" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1177.62" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1350.87" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="1363.24" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1359.12" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1359.12" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1359.12" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1355" y="144.66" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1355" y="140.54" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1355" y="136.42" width="4.12" height="4.12" fill="#fff"/>
              <rect x="1355" y="132.3" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1355" y="128.18" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1350.87" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1350.87" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1346.75" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="1074.5" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="1086.87" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1082.75" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1082.75" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1082.75" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1078.62" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1078.62" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1078.62" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="1078.62" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1078.62" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1074.5" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1074.5" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1070.37" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="1095.12" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1091" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1099.25" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1086.87" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1103.37" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1095.12" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1091" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1082.75" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1103.37" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1095.12" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1091" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1082.75" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1099.25" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1086.87" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1095.12" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1091" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
          <rect x="1742.74" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1771.61" y="297.06" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1738.61" y="227.04" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1676.74" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1726.24" y="255.87" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1701.49" y="29.33" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1569.49" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1614.87" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1755.11" y="292.94" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1548.87" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1557.12" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1668.49" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1709.74" y="214.68" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1684.99" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1647.87" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1569.49" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1730.36" y="66.4" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1540.62" y="0.5" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1668.49" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1763.36" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1491.12" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1470.49" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1771.61" y="247.63" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1441.62" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1408.62" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1697.36" y="78.76" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1656.12" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1425.12" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1581.87" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1709.74" y="272.34" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1726.24" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1470.49" y="12.86" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1734.49" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1643.74" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1643.74" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1495.24" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1482.87" y="82.88" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1618.99" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1511.74" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1425.12" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1499.37" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1453.99" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1573.62" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1536.49" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1503.49" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1680.87" y="272.34" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1623.12" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1449.87" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1594.24" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1486.99" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1536.49" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1598.37" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1412.74" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1746.86" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1775.74" y="346.48" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1515.87" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1338.5" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1763.36" y="107.59" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1416.87" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1672.62" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1507.62" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1779.86" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1458.12" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1722.11" y="107.59" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1656.12" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1425.12" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1602.49" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1672.62" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1561.24" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1606.62" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1495.24" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1643.74" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1717.99" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1771.61" y="157.02" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1594.24" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1519.99" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1474.62" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1408.62" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1672.62" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1524.12" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1755.11" y="198.2" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1594.24" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1569.49" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1635.49" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1610.74" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1544.74" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1548.87" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1449.87" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1416.87" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1544.74" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1503.49" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1379.74" y="21.09" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1499.37" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1515.87" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1552.99" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1511.74" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1458.12" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1519.99" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1499.37" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1491.12" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1445.74" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1503.49" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1462.24" y="78.76" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1499.37" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1408.62" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1441.62" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1458.12" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1441.62" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1449.87" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1519.99" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1470.49" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1420.99" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1437.49" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1412.74" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1458.12" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="1701.49" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1697.36" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1693.24" y="247.63" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1693.24" y="243.51" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1693.24" y="235.27" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1693.24" y="231.16" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1689.12" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1684.99" y="239.39" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="1569.49" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1565.37" y="346.48" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1565.37" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1565.37" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1565.37" y="330.01" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1561.24" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="1664.37" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1660.24" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1660.24" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1660.24" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1660.24" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="1656.12" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="1433.37" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1429.24" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1429.24" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1425.12" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="1552.99" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1548.87" y="157.02" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1548.87" y="148.78" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1544.74" y="152.9" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="1705.61" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1701.49" y="321.77" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1701.49" y="317.65" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1701.49" y="313.53" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1697.36" y="317.65" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1709.74" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1705.61" y="49.93" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1705.61" y="45.81" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1705.61" y="41.69" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1701.49" y="45.81" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1569.49" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1565.37" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1565.37" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1565.37" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1561.24" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1734.49" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="1746.86" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1742.74" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1742.74" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1742.74" y="132.3" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1738.61" y="144.66" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1738.61" y="140.54" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1738.61" y="136.42" width="4.12" height="4.12" fill="#fff"/>
              <rect x="1738.61" y="132.3" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1738.61" y="128.18" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1734.49" y="140.54" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1734.49" y="136.42" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1730.36" y="136.42" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="1458.12" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="1470.49" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1466.37" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1466.37" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1466.37" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1462.24" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1462.24" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1462.24" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="1462.24" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1462.24" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1458.12" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1458.12" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1453.99" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="1482.87" y="54.04" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1478.74" y="54.04" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1486.99" y="49.93" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1474.62" y="49.93" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1491.12" y="45.81" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1482.87" y="45.81" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1478.74" y="45.81" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1470.49" y="45.81" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1491.12" y="41.69" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1482.87" y="41.69" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1478.74" y="41.69" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1470.49" y="41.69" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1486.99" y="37.57" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1474.62" y="37.57" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1482.87" y="33.45" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1478.74" y="33.45" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
          <rect x="2043.86" y="33.45" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1936.61" y="54.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1981.99" y="33.45" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1915.99" y="74.64" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1924.24" y="95.23" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="2035.61" y="235.27" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="2014.99" y="288.82" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1936.61" y="198.2" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1907.74" y="8.74" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="2035.61" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1858.24" y="272.34" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1837.61" y="161.13" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1808.74" y="280.58" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1775.74" y="70.52" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="2023.24" y="313.53" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1792.24" y="41.69" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1948.99" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1837.61" y="12.86" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="2010.86" y="29.33" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="2010.86" y="70.52" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1862.36" y="132.3" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1854.11" y="37.57" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1986.11" y="12.86" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1878.86" y="152.9" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1792.24" y="189.97" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1866.49" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1821.11" y="317.65" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1940.74" y="115.83" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1903.61" y="119.95" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1870.61" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1990.24" y="239.39" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1816.99" y="140.54" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1961.36" y="227.04" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1854.11" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1903.61" y="41.69" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1965.49" y="91.11" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1779.86" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1882.99" y="194.09" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1804.61" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1783.99" y="259.99" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="2039.73" y="8.74" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1874.74" y="321.77" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1825.24" y="99.35" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="2023.24" y="185.85" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1792.24" y="144.66" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1969.61" y="292.94" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="2039.73" y="210.56" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1928.36" y="305.29" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1973.74" y="338.24" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1862.36" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="2010.86" y="140.54" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1961.36" y="173.49" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1887.11" y="288.82" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1841.74" y="222.92" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1775.74" y="161.13" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="2039.73" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1891.24" y="222.92" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1961.36" y="41.69" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1936.61" y="82.88" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="2002.61" y="37.57" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1977.86" y="95.23" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1911.86" y="58.16" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1915.99" y="16.98" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1816.99" y="62.28" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1788.11" y="0.5" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1911.86" y="103.47" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1870.61" y="66.4" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1808.74" y="25.21" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1866.49" y="136.42" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1882.99" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1920.11" y="128.18" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1878.86" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1825.24" y="115.83" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1887.11" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1866.49" y="218.8" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1858.24" y="111.71" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1812.86" y="16.98" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1870.61" y="49.93" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1829.36" y="49.93" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1866.49" y="181.73" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1775.74" y="206.44" width="4.12" height="4.12" fill="#d2ed65"/>
          <rect x="1808.74" y="231.16" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1825.24" y="243.51" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1808.74" y="152.9" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1816.99" y="99.35" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1887.11" y="95.23" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1837.61" y="268.22" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1788.11" y="297.06" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1804.61" y="74.64" width="4.12" height="4.12" fill="#71ddf0"/>
          <rect x="1779.86" y="111.71" width="4.12" height="4.12" fill="#00aec9"/>
          <rect x="1825.24" y="189.97" width="4.12" height="4.12" fill="#00aec9"/>
          <g>
            <rect x="1911.86" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1907.74" y="342.36" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1907.74" y="338.24" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1907.74" y="330.01" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1907.74" y="325.89" width="4.12" height="4.12" fill="#26deff"/>
            <rect x="1903.61" y="334.13" width="4.12" height="4.12" fill="#26deff"/>
          </g>
          <g>
            <rect x="2031.48" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="2027.36" y="91.11" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="2027.36" y="87" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="2027.36" y="78.76" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="2027.36" y="74.64" width="4.12" height="4.12" fill="#00c4e8"/>
            <rect x="2023.24" y="82.88" width="4.12" height="4.12" fill="#00c4e8"/>
          </g>
          <g>
            <rect x="1800.49" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1796.36" y="132.3" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1796.36" y="124.07" width="4.12" height="4.12" fill="#7d7bff"/>
            <rect x="1792.24" y="128.18" width="4.12" height="4.12" fill="#7d7bff"/>
          </g>
          <g>
            <rect x="1928.36" y="169.37" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1924.24" y="173.49" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1924.24" y="165.25" width="4.12" height="4.12" fill="#6976ff"/>
            <rect x="1920.11" y="169.37" width="4.12" height="4.12" fill="#6976ff"/>
          </g>
          <g>
            <rect x="1936.61" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1932.49" y="29.33" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1932.49" y="25.21" width="4.12" height="4.12" fill="#fff"/>
            <rect x="1932.49" y="21.09" width="4.12" height="4.12" fill="#f1ff4d"/>
            <rect x="1928.36" y="25.21" width="4.12" height="4.12" fill="#f1ff4d"/>
          </g>
          <g>
            <rect x="1825.24" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
            <g>
              <rect x="1837.61" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1833.49" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1833.49" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1833.49" y="210.56" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1829.36" y="222.92" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1829.36" y="218.8" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1829.36" y="214.68" width="4.12" height="4.12" fill="#fff"/>
              <rect x="1829.36" y="210.56" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1829.36" y="206.44" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1825.24" y="218.8" width="4.12" height="4.12" fill="#008df0"/>
              <rect x="1825.24" y="214.68" width="4.12" height="4.12" fill="#52b7ff"/>
              <rect x="1821.11" y="214.68" width="4.12" height="4.12" fill="#008df0"/>
            </g>
          </g>
          <g>
            <rect x="1849.99" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1845.86" y="82.88" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1854.11" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1841.74" y="78.76" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1858.24" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1849.99" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1845.86" y="74.64" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1837.61" y="74.64" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1858.24" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1849.99" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1845.86" y="70.52" width="4.12" height="4.12" fill="#d4f8ff"/>
            <rect x="1837.61" y="70.52" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1854.11" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1841.74" y="66.4" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1849.99" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
            <rect x="1845.86" y="62.28" width="4.12" height="4.12" fill="#00bbe0"/>
          </g>
        </g>
      </g>
      <g clipPath="url(#clip-path)" id="title-full">
        <g id="title_query">
          <polygon points="847.99 525.96 847.99 507.39 176.01 507.39 176.01 525.96 160.52 525.96 160.52 627 176.01 627 176.01 645.13 847.99 645.13 847.99 627 863.49 627 863.49 525.96 847.99 525.96" fill="#fff"/>
          <g>
            <path d="M276.13,600.78V570.47h6.06v-6.06h6.06v-6.07h18.19v6.07h6.06v6.06h6.06v30.31H306.44V588.66H288.25v12.12Zm12.12-18.19h18.19V570.47h-6.06v-6.06h-6.07v6.06h-6.06Z" fill="#fdae35"/>
            <path d="M336.07,600.78v-6.06H330v-6.06h12.12v6.06h18.19V582.59H336.07v-6.06H330V564.41h6.06v-6.07h30.31v6.07h6.07v6.06H360.32v-6.06H342.13v12.12h24.25v6.06h6.07v12.13h-6.07v6.06Z" fill="#fdae35"/>
            <path d="M383.89,600.78V558.34H396v18.19h6.06v-6.06h6.06v-6.06h6.07v-6.07h12.12v6.07h-6.06v6.06h-6.06v6.06h-6.07v6.06h6.07v6.07h6.06v6.06h6.06v6.06H408.14v-6.06h-6.06v-6.06H396v12.12Z" fill="#fdae35"/>
            <path d="M534.1,564.41v24.25H528v6.06H522v6.06H497.72v-6.06h-6.06V564.41h6.06v-6.07H528v6.07Zm-12.13,0H503.79v30.31h12.12v-6.06h-6.06v-6.07H522ZM528,600.78v-6.06h6.06v6.06Z" fill="#fdae35"/>
            <path d="M551.61,600.78v-6.06h-6.07V558.34h12.13v36.38h18.19V558.34H588v36.38h-6.06v6.06Z" fill="#fdae35"/>
            <path d="M599.43,600.78V558.34h42.43v6.07H611.55v12.12H635.8v6.06H611.55v12.13h30.31v6.06Z" fill="#fdae35"/>
            <path d="M653.31,600.78V558.34h36.38v6.07h6.06v18.18H683.62v6.07h6.07v6.06h6.06v6.06H677.56v-6.06H671.5v-6.06h-6.06v12.12Zm12.13-18.19h12.12v-6.06h6.06V564.41H665.44Z" fill="#fdae35"/>
            <path d="M725.38,600.78V582.59h-6.06v-6.06h-6.06V558.34h12.12v18.19h12.13V558.34h12.12v18.19h-6.06v6.06h-6.06v18.19Z" fill="#fdae35"/>
          </g>
          <g>
            <path d="M272.22,596.32V566h6.06V560h6.06v-6.07h18.19V560h6.06V566h6.06v30.31H302.53V584.2H284.34v12.12Zm12.12-18.19h18.19V566h-6.06V560H290.4V566h-6.06Z"/>
            <path d="M332.16,596.32v-6.06H326.1V584.2h12.12v6.06h18.19V578.13H332.16v-6.06H326.1V560h6.06v-6.07h30.31V560h6.07V566H356.41V560H338.22v12.12h24.25v6.06h6.07v12.13h-6.07v6.06Z"/>
            <path d="M380,596.32V553.88h12.13v18.19h6.06V566h6.06V560h6.07v-6.07h12.12V560h-6.06V566H410.3v6.06h-6.07v6.06h6.07v6.07h6.06v6.06h6.06v6.06H404.23v-6.06h-6.06V584.2h-6.06v12.12Z"/>
            <path d="M530.19,560V584.2h-6.06v6.06h-6.07v6.06H493.81v-6.06h-6.06V560h6.06v-6.07h30.32V560Zm-12.13,0H499.88v30.31H512V584.2h-6.06v-6.07h12.12Zm6.07,36.37v-6.06h6.06v6.06Z"/>
            <path d="M547.7,596.32v-6.06h-6.07V553.88h12.13v36.38H572V553.88h12.12v36.38H578v6.06Z"/>
            <path d="M595.52,596.32V553.88H638V560H607.64v12.12h24.25v6.06H607.64v12.13H638v6.06Z"/>
            <path d="M649.4,596.32V553.88h36.38V560h6.06v18.18H679.71v6.07h6.07v6.06h6.06v6.06H673.65v-6.06h-6.06V584.2h-6.06v12.12Zm12.13-18.19h12.12v-6.06h6.06V560H661.53Z"/>
            <path d="M721.47,596.32V578.13h-6.06v-6.06h-6.06V553.88h12.12v18.19H733.6V553.88h12.12v18.19h-6.06v6.06H733.6v18.19Z"/>
          </g>
        </g>
      </g>
      <g id="query-large">
        <g>
          <polygon points="671.6 211.07 671.6 165.98 650.05 165.98 650.05 144.43 628.49 144.43 628.49 123.08 606.94 123.08 606.94 122.58 606.44 122.58 606.44 101.02 553.93 101.02 553.93 79.47 467.94 79.47 467.94 101.02 412.94 101.02 412.94 122.58 412.94 123.08 391.38 123.08 391.38 144.43 369.82 144.43 369.82 165.98 348.27 165.98 348.27 211.07 326.71 211.07 326.71 295.86 348.27 295.86 348.27 338.14 369.82 338.14 369.82 381.25 391.38 381.25 391.38 402.8 412.94 402.8 412.94 424.36 456.05 424.36 456.05 445.92 563.83 445.92 563.83 424.36 606.94 424.36 606.94 402.8 628.49 402.8 628.49 381.25 650.05 381.25 650.05 338.14 671.6 338.14 671.6 295.86 693.16 295.86 693.16 211.07 671.6 211.07" fill="#fdae35"/>
          <g>
            <polygon points="434.49 424.36 434.4 424.31 434.49 424.31 434.49 402.75 412.94 402.75 412.46 402.75 412.46 381.19 391.38 381.19 391.38 359.64 369.82 359.64 369.82 338.08 348.27 338.08 348.27 295.86 326.71 295.86 326.71 338.97 348.27 338.97 348.27 359.64 348.27 381.19 369.82 381.19 369.82 402.75 390.91 402.75 391.38 402.75 391.38 424.31 412.94 424.31 412.94 445.92 467.84 445.92 467.84 424.36 434.49 424.36" fill="#d38b2d"/>
            <rect x="467.94" y="445.97" width="85.99" height="21.56" fill="#d38b2d"/>
            <polygon points="671.6 295.86 671.6 338.08 650.05 338.08 650.05 359.64 628.49 359.64 628.49 381.19 606.94 381.19 606.94 402.75 585.38 402.75 585.38 424.31 585.5 424.31 585.38 424.36 553.83 424.36 553.83 445.92 606.94 445.92 606.94 424.31 628.49 424.31 628.49 402.75 650.05 402.75 650.05 381.19 671.6 381.19 671.6 359.64 671.6 338.97 693.16 338.97 693.16 295.86 671.6 295.86" fill="#d38b2d"/>
          </g>
          <rect x="585.38" y="143.69" width="21.56" height="21.56" fill="#ffebd4"/>
          <rect x="574.6" y="133.36" width="10.78" height="10.78" fill="#ffdaa9"/>
          <rect x="606.94" y="165.25" width="10.78" height="10.78" fill="#ffdaa9"/>
        </g>
        <g>
          <g>
            <rect x="542.27" y="291.04" width="86.22" height="21.56" fill="#a32732"/>
            <rect x="391.38" y="291.04" width="86.22" height="21.56" fill="#a32732"/>
            <polygon points="391.38 233.84 477.6 233.84 477.6 291.04 499.16 291.04 499.16 233.84 520.71 233.84 520.71 288.74 542.27 288.74 542.27 233.84 563.83 233.84 585.38 233.84 628.49 233.84 628.49 288.74 650.05 288.74 650.05 212.28 369.82 212.28 369.82 288.74 391.38 288.74 391.38 233.84" fill="#a32732"/>
          </g>
          <g>
            <rect x="391.38" y="233.84" width="86.22" height="57.2" fill="#ecf0f1"/>
            <rect x="542.27" y="233.84" width="86.22" height="57.2" fill="#ecf0f1"/>
          </g>
        </g>
        <g id="query-eyes">
          <rect id="eye-right" x="573.94" y="244.64" width="16.51" height="28.86" fill="#a32732"/>
          <rect id="eye-left" x="429.42" y="244.64" width="16.51" height="28.86" fill="#a32732"/>
        </g>
      </g>
    </svg>
  );
};
