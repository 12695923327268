import React from 'react';

import clsx from 'clsx';
import { string } from 'prop-types';
import { Offline, Online } from 'react-detect-offline';

import { InternetIcon } from 'app/components/icons/internet-icon/internet-icon.component';
import { InternetOffIcon } from 'app/components/icons/internet-icon/internet-off-icon.component';

export const ConnectionStatus = props => {
  const { classNames } = props;

  return (
    <div className={clsx(
      'internet-icon',
      classNames
    )}>
      <Online><InternetIcon /></Online>
      <Offline><InternetOffIcon /></Offline>
    </div>
  );
};

ConnectionStatus.propTypes = {
  classNames: string
};
