import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { setUnitFilter } from 'app/store/actions/words-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';

export const UnitDropdown = () => {
  const dispatch = useDispatch();

  const { unit } = useSelector(state => state.wordsData);
  const { unitOption } = useSelector(state => state.wordsData.filters);

  const { getWordsByUnitSubunitStatus } = useSelector(state => state.apiReducer);

  const [dropdownOptions, setDropdownOptions] = useState();

  
  useEffect(() => {
    if (unit) {
      const dropdownOptions = _.map(unit, iunit => {
        return {
          key: iunit.id,
          value: iunit.name,
        };
      });
      dropdownOptions.unshift({ key: '', value: 'All' });

      setDropdownOptions(dropdownOptions);
    }
  }, [unit]);

  useEffect(() => {
    if (dropdownOptions)
      dispatch(setUnitFilter(dropdownOptions[1]));
  }, [dropdownOptions]);

  const selectUnit = (e, key) => {
    e.preventDefault();
    dispatch(setUnitFilter(_.find(dropdownOptions, e => e.key == key)));
  };
  
  const isDisabled = () => {
    return isLoading(getWordsByUnitSubunitStatus);
  };

  return (
    dropdownOptions && unitOption ?
      <div className="wordsUnitDropdown">
        <DropdownWrapper
          isDisabled={isDisabled}
          menuOptions={dropdownOptions}
          dropdownName={unitOption.value}
          selectHandler={selectUnit}
        />
      </div>
      : 
      <></>
  );
};
