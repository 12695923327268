import { ENVIRONMENT, ENVIRONMENTS } from 'app/config';

export const ASSET_BUSINESS_KEYS = {
  paidAssetKey: '81bd128c-b49e-4ba2-95d1-53d68d0747b4',
  trialAssetKey: '8583efe0-b9db-4c2b-aa15-98444d7b0296',
  nationalAssetKey: '3b44d4a0-9e70-4e53-b49e-3f2b74fd4b51',
  readingAssetKey: ENVIRONMENT === ENVIRONMENTS.CI || ENVIRONMENT === ENVIRONMENTS.LOCAL ?
    'd384bb74-7acb-4da6-8f08-27c63b98841a' : '47aecbc8-f648-4390-af71-bd39951fbdaa',
  vocabReadingAssetKey: ENVIRONMENT === ENVIRONMENTS.CI || ENVIRONMENT === ENVIRONMENTS.LOCAL ?
    '8577e27c-78b7-4b37-9c82-de5f66e4deb7' : '10d865db-7225-4db1-afed-8bdff9bb6bd0',
};

export const ALL_POSSIBLE_ASSETS_KEYS = [
  '81bd128c-b49e-4ba2-95d1-53d68d0747b4',
  '8583efe0-b9db-4c2b-aa15-98444d7b0296',
  '3b44d4a0-9e70-4e53-b49e-3f2b74fd4b51',
  'd384bb74-7acb-4da6-8f08-27c63b98841a',
  '47aecbc8-f648-4390-af71-bd39951fbdaa',
  '8577e27c-78b7-4b37-9c82-de5f66e4deb7',
  '10d865db-7225-4db1-afed-8bdff9bb6bd0',
];

export const TEACHER_ROLES = ['ROLE_TEACHER', 'TEACHER'];
export const STUDENT_ROLES = ['ROLE_STUDENT', 'STUDENT'];
