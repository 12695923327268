import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_USER_STATS = createRequestTypes(API_ACTION_TYPES.GET_USER_STATS);
const GET_LATEST_PROFILE = createRequestTypes(API_ACTION_TYPES.GET_LATEST_PROFILE);
const SET_CKLA_PROFILE = createRequestTypes(API_ACTION_TYPES.SET_CKLA_PROFILE);
const SET_AR_PROFILE = createRequestTypes(API_ACTION_TYPES.SET_AR_PROFILE);
const GET_MASTERED_WORDS = createRequestTypes(API_ACTION_TYPES.GET_MASTERED_WORDS);

export const profileDataActions = {
  GET_USER_STATS,
  GET_LATEST_PROFILE,
  GET_MASTERED_WORDS,
  SET_CKLA_PROFILE,
  SET_AR_PROFILE
};

export const profileDataActionCreators = {
  getUserStats: { ...createActions(GET_USER_STATS) },
  getLatestProfile: { ...createActions(GET_LATEST_PROFILE) },
  setCklaProfile: { ...createActions(SET_CKLA_PROFILE) },
  setArProfile: { ...createActions(SET_AR_PROFILE) },
  getMasteredWords: { ...createActions(GET_MASTERED_WORDS) }
};
