import React, { forwardRef } from 'react';

import composeClassNames from 'clsx';

export const TabList = forwardRef(
  ({ as: Element = 'ul', className, children, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs_tablist';
		
    return (
      <Element className={composeClassNames(INTERNAL_CLASS_NAME, className)} role="tablist" ref={forwardedRef} {...props}>
        {children}
      </Element>
    );
  }
);

TabList.displayName = 'TabList';