import React from 'react';

import clsx from 'clsx';
import { func, string } from 'prop-types';

import { GearIcon } from 'app/components/icons/gear-icon/gear-icon.component';


export const ActivitiesSettingsButton = props => {
  const { classNames, onClick } = props;

  return (
    <button
      className={clsx(
        'gear-icon',
        classNames)}
      onClick={onClick}
      aria-label="Open Settings"
      aria-haspopup="true"
      aria-controls="settings-popup"
    >
      <GearIcon />
    </button>
  );
};

ActivitiesSettingsButton.propTypes = {
  classNames: string,
  onClick: func,
};
