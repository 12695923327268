import { datadogRum } from '@datadog/browser-rum';

import { userDataActions } from 'app/store/actions/user-data.actions';

export const initialState = {
  kcInfo: {
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    entitledLanguages: [],
    sessionUUID: null,
    businessKey: null,
    isTeacher: false,
    isStudent: false,
    permissions: [],
    isReadOnly: false,
    intercomHash: null,
  },
  licenses: {},
  assets: [],
};

function userDataReducer(state = initialState, action) {
  switch (action.type) {
  case userDataActions.GET_USER_DATA.SUCCESS: {
    datadogRum.setUser({
      id: action.payload.kcInfo.sub,
    });
    return {
      ...state,
      ...action.payload,
    };
  }

  case userDataActions.GET_INTERCOM_HASH.SUCCESS:
    return {
      ...state,
      kcInfo: {
        ...state.kcInfo,
        intercomHash: action.payload.key,
      },
    };

  case userDataActions.GET_LICENSES.SUCCESS:
    return {
      ...state,
      licenses: action.payload.licenseTypes,
      assets: action.payload.assets,
    };

  default:
    return state;
  }
}

export default userDataReducer;
