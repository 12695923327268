import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader } from 'app/components/loader/loader';
import { CONFIG } from 'app/config';
import { DEMO_SECTION_BK_PREFIX } from 'app/constants/demo-section.constant';
import { isSuccess } from 'app/store/reducers/api-status.reducer';

import 'app/components/reporting/classes/classes.page.scss';

export const ClassesPage = (props) => {

  const [showClassEmpty, setShowClassEmpty] = useState(false);

  const { handleClassSelection } = props;

  const { activeClasses } = useSelector(state => state.classesData);
  const { getStandaloneClassesStatus, getCDAClassesStatus } = useSelector(state => state.apiReducer);

  const classesLoaded = isSuccess(getStandaloneClassesStatus) || isSuccess(getCDAClassesStatus);

  const isEmptyClass = (activeClasses) => {
    // Length 1 is Demo
    return (activeClasses !== null) && (Object.keys(activeClasses).length <= 1);
  };

  useEffect(() => {
    setShowClassEmpty(isEmptyClass(activeClasses));
  }, [activeClasses]);

  const compareByName = (bk1, bk2) => {
    return activeClasses[bk1].name.localeCompare(activeClasses[bk2].name);
  };

  const compareByDemo = (bk1, bk2) => {
    const bk1Demo = bk1.includes(DEMO_SECTION_BK_PREFIX);
    const bk2Demo = bk2.includes(DEMO_SECTION_BK_PREFIX);
    return (bk1Demo ? 1 : (bk2Demo ? -1 : 0));
  };

  return (
    <div className="reportingPage">
      {
        classesLoaded
          ?
          <div className="centeredPage">
            {activeClasses && <h1 className="title" id="classes-table-description">Your Classes</h1>}
            <div className="table">
              <table role="grid" aria-labelledby='classes-table-description'>
                <tbody>
                  {
                    Object.keys(activeClasses)
                      .sort(compareByName)
                      .sort(compareByDemo)
                      .map((classBK) => {
                        return (
                          <tr
                            tabIndex='0'
                            key={classBK} 
                            className="listItem"
                            onClick={(e) => handleClassSelection(e, classBK)}
                            onKeyUp={(e) => handleClassSelection(e, classBK)}
                            aria-describedby="Select to open class"
                          >
                            <td
                              className="text"
                              data-dd-action-name="**class**"
                            >
                              {activeClasses[classBK].name}
                            </td>
                          </tr>
                        );
                      })
                  }
                  {
                    showClassEmpty ?
                      <tr key='empty-class' className={`emptyClassTr ${activeClasses.length == 0? '' : 'hasDemo'}`}>
                        <td colSpan={6} className='emptyClassTd'>
                          <center>
                            <div className="emptyClassCard center-vertical">
                              <h1>{'Looks like you don\'t have any classes yet'}</h1>
                              <button className="closeButton" onClick={() => setShowClassEmpty(false)} aria-label='Close Settings Modal Window' />
                              <hr />
                              <p>You can go to <a href={CONFIG.MY_ACCOUNT}>My Account</a> to add classes and invite your students using a class code.</p>
                            </div>
                          </center>
                        </td>
                      </tr>
                      :
                      <></>
                  }
                </tbody>
              </table>
            </div>
          </div> 
          :
          <Loader />
      }
    </div>
  );
};

ClassesPage.propTypes = {
  handleClassSelection: PropTypes.func,
};
