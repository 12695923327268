import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_GAME_STATE = createRequestTypes(API_ACTION_TYPES.GET_GAME_STATE);
const SET_GAME_STATE = createRequestTypes(API_ACTION_TYPES.SET_GAME_STATE);

export const gameStateActions = {
  GET_GAME_STATE,
  SET_GAME_STATE,
};

export const gameStateActionCreators = {
  getGameState: { ...createActions(GET_GAME_STATE) },
  setGameState: { ...createActions(SET_GAME_STATE) },
};
