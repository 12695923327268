import _ from 'lodash';
import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { wordsDataActionCreators, wordsDataActions } from 'app/store/actions/words-data.actions';
import promiseHandler, { compositePromiseHandler } from 'app/util/promise-handler';
import { getWordsByStreamParams } from 'app/util/words';

function* getCklaFirstEncounter(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getCklaFe(action.payload));

    if (error) throw error;

    yield put(wordsDataActionCreators.getCklaFirstEncounters.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getCklaFirstEncounters.failure({
        error,
      })
    );
  }
}

function* getArFirstEncounter(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getArFe({
      csUserBusinessKey: action.payload.csUserBusinessKey,
      questId: action.payload.questId,
      standaloneGrade: action.payload.standaloneGrade,
      streamName: action.payload.streamName,
    }));

    if (error) throw error;

    yield put(wordsDataActionCreators.getArFirstEncounters.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getArFirstEncounters.failure({
        error,
      })
    );
  }
}

function* getKSWordListByBusinessKeys(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getKSWordListByBusinessKeys(action.payload));

    if (error) throw error;

    yield put(wordsDataActionCreators.getKSWordList.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getKSWordList.failure({
        error,
      })
    );
  }
}

function* getWordsByStream(action) {
  try {
    const { grade, streamName, wordList } = action.payload;
    const params = getWordsByStreamParams(grade, streamName, wordList);

    const [error, response] = yield promiseHandler(ApiClient.getWordsByStream(params));

    const words = _.uniqBy(response.data.sort((a,b) => {
      return a.word.localeCompare(b.word);
    }), 'id');

    if (error) throw error;

    yield put(wordsDataActionCreators.getWordsByStream.success(words));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getWordsByStream.failure({
        error,
      })
    );
  }
}

function* getWordsByUnitSubunit(action) {
  try {
    const { unitID, subunitID } = action.payload;
    const [error, response] = yield promiseHandler(ApiClient.getWordsByUnitSubunit(unitID, subunitID));

    if (error) throw error;

    const lessons = response.data.map(lesson => {
      return {
        ...lesson,
        firstEncounterWords: lesson.firstEncounterWords.sort((a,b) => {
          return a.word.name.localeCompare(b.word.name);
        })
      };
    });


    yield put(wordsDataActionCreators.getWordsByUnitSubunit.success(lessons));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getWordsByUnitSubunit.failure({
        error,
      })
    );
  }
}

function* getWordData(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getWordData(action.payload));

    if (error) throw error;

    yield put(wordsDataActionCreators.getWordData.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getWordData.failure({
        error,
      })
    );
  }
}

function* getUnitSubunit() {
  try {
    const [error, responses] = yield compositePromiseHandler([ApiClient.getUnit(), ApiClient.getSubunit()]);

    if (error) throw error;

    yield put(wordsDataActionCreators.getUnitSubunit.success(_.map(responses, res => res.data)));
  } catch (error) {
    console.error(error);
    yield put(
      wordsDataActionCreators.getUnitSubunit.failure({
        error,
      })
    );
  }
}

export default function* wordsDataSaga() {
  yield all([
    takeEvery(wordsDataActions.GET_AR_FIRST_ENCOUNTERS.REQUEST, getArFirstEncounter),
    takeEvery(wordsDataActions.GET_CKLA_FIRST_ENCOUNTERS.REQUEST, getCklaFirstEncounter),
    takeEvery(wordsDataActions.GET_KS_WORD_LIST.REQUEST, getKSWordListByBusinessKeys),
    takeEvery(wordsDataActions.GET_WORDS_BY_STREAM.REQUEST, getWordsByStream),
    takeEvery(wordsDataActions.GET_WORDS_BY_UNIT_SUBUNIT.REQUEST, getWordsByUnitSubunit),
    takeEvery(wordsDataActions.GET_WORD_DATA.REQUEST, getWordData),
    takeEvery(wordsDataActions.GET_UNIT_SUBUNIT.REQUEST, getUnitSubunit),
  ]);
}
