import React from 'react';

import { func } from 'prop-types';

import { Header } from 'app/components/header/header.component';
import { SubNav } from 'app/components/header/subnav/subnav.component';
import { ProfileIcon } from 'app/components/icons/profile-icon/profile-icon.component';
import { TabList } from 'app/components/tabs/tab-list.component';
import { Tab } from 'app/components/tabs/tab.component';
import starHover from 'app/shared/images/icon_star-blue.svg';
import star from 'app/shared/images/icon_star.svg';

import 'app/components/header/home-page-header/home-page-header.scss';

export const HomePageHeader = (props) => {
  const { setIsModalVisible } = props;

  return (
    <Header>
      <SubNav classNames="home-page-header">
        <>
          <button id="profile-icon" className="nav-text" aria-label="Open Profile Page"><ProfileIcon /></button> {/* TODO */}
          <TabList>
            <Tab value="home" className="nav-item-style">Home</Tab>
            <Tab value="stats" className="nav-item-style">Stats</Tab>
          </TabList>
          <div>
            <button tabIndex="-1"  
              id="mastered_words_button"
              aria-haspopup="true" 
              aria-expanded="false" 
              className="button button-border" 
              aria-label="Open Mastered Words Popup" 
              onClick={() => setIsModalVisible(true)}>
              <div style={{ '--star': `url('${star}')`, '--hover-star': `url('${starHover}')` }} className="button star" />
            Mastered Words
            </button>
          </div>
        </>
      </SubNav>
    </Header>
  );
};

HomePageHeader.propTypes = {
  setIsModalVisible: func,
};
