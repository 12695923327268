import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Input } from 'app/components/date/date-picker/date-range-setter/TimePickerStyles';

export const get24HourFrom12Hour = (hour, isAM) => {
  if (typeof hour === 'string') {
    hour = parseInt(hour);
  }
  if (hour === 12) {
    return isAM ? 0 : 12;
  }
  return isAM ? hour : 12 + hour;
};

export const getCurrentPeriod = isAM => (isAM ? 'am' : 'pm');

export const get12HourFrom24Hour = hour => {
  const isAM = getIsAM(hour);
  const isMidnight = getIsMidnight(hour);
  const isNoon = getIsNoon(hour);
  return isMidnight || isNoon ? 12 : isAM ? hour : hour - 12;
};

export const getIsAM = twentyfourHourOrPeriod => {
  if (typeof twentyfourHourOrPeriod === 'number') {
    return twentyfourHourOrPeriod < 12;
  } else {
    return twentyfourHourOrPeriod?.toLowerCase() === 'am';
  }
};

export const getIsMidnight = hour => hour === 0;

export const getIsNoon = hour => hour === 12;

export const getIsValidPeriod = period => {
  return period?.toLowerCase() === 'am' || period?.toLowerCase() === 'pm';
};

export const padTime = (string, padding = 2) => {
  return string.toString().padStart(padding, '0');
};

const hourRegex = /^([1-9]|10|11|12)$/;
const minuteRegex = /^([0-9]|[0]*[0-5][0-9])$/;

const TimePicker = ({ date, handleTimeChange }) => {
  const [hourInput, setHourInput] = useState('');
  const [minuteInput, setMinuteInput] = useState('');
  const [isAM, setIsAM] = useState();

  useEffect(() => {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const twelveHour = get12HourFrom24Hour(hour);
    setHourInput(twelveHour);
    setMinuteInput(padTime(minute));
    setIsAM(getIsAM(hour));
  }, [date]);

  useEffect(() => {
    const isValidHour = hourRegex.test(hourInput);
    const isValidMinute = minuteRegex.test(minuteInput);
    const isInvalidTime = !isValidHour || !isValidMinute;

    if (!isInvalidTime) {
      const newDate = new Date(date);
      const twentyfourHour = get24HourFrom12Hour(hourInput, isAM);
      newDate.setHours(twentyfourHour);
      newDate.setMinutes(minuteInput);
      handleTimeChange(newDate);
    }
  }, [hourInput, minuteInput]);

  const togglePeriod = () => {
    const twentyfourHour = date.getHours();
    const toggledHour = isAM ? twentyfourHour + 12 : twentyfourHour - 12;
    const newDate = new Date(date);
    newDate.setHours(toggledHour);
    handleTimeChange(newDate);
  };

  const handlePeriodChange = period => {
    const currentPeriod = getCurrentPeriod(isAM);
    if (period === currentPeriod || !hourInput) {
      return;
    }
    togglePeriod();
  };

  const handleTimeInput = (e, timeSegment) => {
    e.persist();
    const value = e.target.value;

    const isInvalidTime =
      (timeSegment === 'hour' && !hourRegex.test(value)) ||
      (timeSegment === 'minute' && !minuteRegex.test(value));

    if (isInvalidTime && value !== '') {
      e.preventDefault();
      return;
    }

    if (timeSegment === 'hour') {
      setHourInput(value);
    } else {
      setMinuteInput(value);
    }
  };

  const handleOnBlur = () => {
    if (!hourInput) {
      const hour = date.getHours();
      const twelveHour = get12HourFrom24Hour(hour);
      setHourInput(twelveHour);
    }
  };

  return (
    <div className="date-panel-time-picker">
      <hr />
      <div>TIME</div>
      <Input
        className="time-picker-hour"
        type="text"
        value={hourInput}
        onBlur={() => handleOnBlur()}
        onChange={e => handleTimeInput(e, 'hour')}
      />
      {' : '}
      <Input
        className="time-picker-minute"
        type="text"
        value={minuteInput}
        onChange={e => handleTimeInput(e, 'minute')}
      />
      <Button
        className={`
            time-picker-am
            btn
            ${isAM ? 'btn-blue is-selected' : 'btn-secondary'}
          `}
        onClick={() => handlePeriodChange('am')}
      >
        AM
      </Button>
      <Button
        className={`
            time-picker-pm
            btn
            ${isAM ? 'btn-secondary' : 'btn-blue is-selected'}
          `}
        onClick={() => handlePeriodChange('pm')}
      >
        PM
      </Button>
    </div>
  );
};

TimePicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  handleTimeChange: PropTypes.func.isRequired,
};

export default TimePicker;