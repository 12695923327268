import React, { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import { COLOR_NAVI } from 'app/constants/colors.constants';

export const YAxis = ({ top, left, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current)
      .call(d3.axisLeft(scale).ticks(5))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line')
        .attr('x2', '700')
        .attr('stroke-dasharray', '2,5')
        .attr('stroke', COLOR_NAVI));
  });

  return (
    <g className="axis y" ref={axis} transform={`translate(${left}, ${top})`} />
  );
};
