
// NOTE: Add new api action type to both API_ACTION_TYPES and
// TYPE_TO_STATE in order to have it's status and error tracked in apiReducer
export const API_ACTION_TYPES = {
  GET_LATEST_STUDENT_PROFILE: '[Classes Data] GET_LATEST_STUDENT_PROFILE',
  GET_USER_DATA: '[User Data] GET_USER_DATA',
  GET_INTERCOM_HASH: '[User Data] GET_INTERCOM_HASH',
  GET_LICENSES: '[User Data] GET_LICENSES',
  GET_STANDLONE_CLASSES_SECTIONS: '[Classes Data] GET_STANDALONE_CLASSES_SECTIONS',
  GET_CDA_CLASSES_SECTIONS: '[Classes Data] GET_CDA_CLASSES_SECTIONS',
  GET_STUDENT_STANDALONE_PROFILES: '[Classes Data] GET_STUDENT_STANDALONE_PROFILES',
  GET_STUDENT_CDA_PROFILES: '[Classes Data] GET_STUDENT_CDA_PROFILES',
  GET_USER_STATS: '[Profile Data] GET_USER_STATS',
  GET_LATEST_PROFILE: '[Profile Data] GET_LATEST_PROFILE',
  SET_CKLA_PROFILE: '[Profile Data] SET_CKLA_PROFILE',
  SET_AR_PROFILE: '[Profile Data] SET_AR_PROFILE',
  GET_MASTERED_WORDS: '[Profile Data] GET_MASTERED_WORDS',
  GET_CKLA_FIRST_ENCOUNTERS: '[Words Data] GET_CKLA_FIRST_ENCOUNTERS',
  GET_AR_FIRST_ENCOUNTERS: '[Words Data] GET_AR_FIRST_ENCOUNTERS',
  GET_KS_WORD_LIST: '[Words Data] GET_KS_WORD_LIST',
  GET_WORDS_BY_STREAM: '[Words Data] GET_WORDS_BY_STREAM',
  GET_WORD_DATA: '[Words Data] GET_WORD_DATA',
  GET_STREAMS: '[General Data] GET_STREAMS',
  GET_STUDENTS_CLASSES: '[Classes Data] GET_STUDENTS_CLASSES',
  GET_DISABLED_ACTIVITIES: '[Classes Data] GET_DISABLED_ACTIVITIES',
  GET_ACTIVITIES_LIMITATION: '[Classes Data] GET_ACTIVITIES_LIMITATION',
  GET_TEACHER_SECTION_STATS: '[Classes Data] GET_TEACHER_SECTION_STATS',
  GET_STUDENT_LATEST_PROFILE_FOR_TEACHER: '[Classes Data] GET_STUDENT_LATEST_PROFILE_FOR_TEACHER',
  GET_STUDENT_STATS: '[Classes Data] GET_STUDENT_STATS',
  GET_UNIT_SUBUNIT: '[Words Data] GET_UNIT_SUBUNIT',
  GET_WORDS_BY_UNIT_SUBUNIT: '[Words Data] GET_WORDS_BY_UNIT_SUBUNIT',
  GET_GAME_STATE: '[Game State] GET_GAME_STATE',
  SET_GAME_STATE: '[Game State] SET_GAME_STATE',
  GET_ACTIVITY: '[Activity Data] GET_ACTIVITY',
  SET_ACTIVITY_OBSERVATION: '[Activity Data] SET_ACTIVITY_OBSERVATION'
};

// NOTE: This error will only be defined in the apiStatus reducer if FAILURE
// In order for the error to be in the reducer, you MUST return `error`in the payload.
// You can check isFail( status ) before then going for the error
export const TYPE_TO_STATE = {
  [API_ACTION_TYPES.GET_LATEST_STUDENT_PROFILE]: 'getLatestStudentProfileStatus',
  [API_ACTION_TYPES.GET_USER_DATA]: 'getUserDataStatus',
  [API_ACTION_TYPES.GET_INTERCOM_HASH]: 'getIntercomHashStatus',
  [API_ACTION_TYPES.GET_LICENSES]: 'getLicensesStatus',
  [API_ACTION_TYPES.GET_STANDLONE_CLASSES_SECTIONS]: 'getStandaloneClassesStatus',
  [API_ACTION_TYPES.GET_CDA_CLASSES_SECTIONS]: 'getCDAClassesStatus',
  [API_ACTION_TYPES.GET_STUDENT_STANDALONE_PROFILES]: 'getStudentStandaloneProfilesStatus',
  [API_ACTION_TYPES.GET_STUDENT_CDA_PROFILES]: 'getStudentCDAProfilesStatus',
  [API_ACTION_TYPES.GET_STREAMS]: 'getStreamsStatus',
  [API_ACTION_TYPES.GET_STUDENTS_CLASSES]: 'getStudentsClassesStatus',
  [API_ACTION_TYPES.GET_CKLA_FIRST_ENCOUNTERS]: 'getCklaFirstEncountersStatus',
  [API_ACTION_TYPES.GET_AR_FIRST_ENCOUNTERS]: 'getArFirstEncountersStatus',
  [API_ACTION_TYPES.GET_LATEST_PROFILE]: 'getLatestProfileStatus',
  [API_ACTION_TYPES.SET_CKLA_PROFILE]: 'setCklaProfileStatus',
  [API_ACTION_TYPES.SET_AR_PROFILE]: 'setArProfileStatus',
  [API_ACTION_TYPES.GET_USER_STATS]: 'getUserStatsStatus',
  [API_ACTION_TYPES.GET_DISABLED_ACTIVITIES]: 'getDisabledActivitiesStatus',
  [API_ACTION_TYPES.GET_ACTIVITIES_LIMITATION]: 'getActivitiesLimitationStatus',
  [API_ACTION_TYPES.GET_TEACHER_SECTION_STATS]: 'getTeacherSectionStatsStatus',
  [API_ACTION_TYPES.GET_STUDENT_LATEST_PROFILE_FOR_TEACHER]: 'getStudentLatestProfileForTeacherStatus',
  [API_ACTION_TYPES.GET_MASTERED_WORDS]: 'getMasteredWords',
  [API_ACTION_TYPES.GET_STUDENT_STATS]: 'getStudentStatStatus',
  [API_ACTION_TYPES.GET_KS_WORD_LIST]: 'getKSWordListStatus',
  [API_ACTION_TYPES.GET_WORDS_BY_STREAM]: 'getWordsByStreamStatus',
  [API_ACTION_TYPES.GET_WORD_DATA]: 'getWordDataStatus',
  [API_ACTION_TYPES.GET_UNIT_SUBUNIT]: 'getUnitSubunitStatus',
  [API_ACTION_TYPES.GET_WORDS_BY_UNIT_SUBUNIT]: 'getWordsByUnitSubunitStatus',
  [API_ACTION_TYPES.GET_AR_FIRST_ENCOUNTERS]: 'getArFirstEncountersStatus',
  [API_ACTION_TYPES.GET_GAME_STATE]: 'getGameStateStatus',
  [API_ACTION_TYPES.SET_GAME_STATE]: 'setGameStateStatus',
  [API_ACTION_TYPES.SET_ACTIVITY_OBSERVATION]: 'setActivityObservationStatus',
};

export const API_REQUEST_STATUS = {
  IDLE: 'IDLE',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};
