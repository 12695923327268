import React from 'react';

import { Navigate } from 'react-router-dom';

import { AccessComponent } from 'app/components/auth/AccessComponent';
import { ReportingPageWrapperComponent } from 'app/components/reporting/wrapper/reporting-page-wrapper.component';
import { ACCESS_ROLES } from 'app/constants/auth.constant';
import routesConstants from 'app/constants/routes.constant';
import HomePage from 'app/pages/home/home.page';
import ReportingPage from 'app/pages/reporting/reporting.page';
import TeacherGuidePage from 'app/pages/teacherguide/teacherguide.page';


const rootRoutes = [
  ...routesConstants.ACTIVITY_ROUTES.map(activityRoute => {
    return {
      exact: true,
      path: `${activityRoute.path}/:id`,
      element: activityRoute.component,
    };
  }),
  {
    exact: true,
    path: routesConstants.CLASSES,
    element: <AccessComponent
      allowedRoles={[ACCESS_ROLES.TEACHER]}
      redirectComponent={<Navigate to={routesConstants.HOME} />}
    >
      <ReportingPageWrapperComponent>
        <ReportingPage />
      </ReportingPageWrapperComponent>
    </AccessComponent>
  },
  {
    exact: true,
    path: routesConstants.TEACHER_GUIDE,
    element: <AccessComponent
      allowedRoles={[ACCESS_ROLES.TEACHER]}
      redirectComponent={<Navigate to={routesConstants.HOME} />}
    >
      <TeacherGuidePage />
    </AccessComponent>
  },
  {
    exact: true,
    path: '/408',
    element: 'ConnectionLostPage',
  },
  {
    exact: true,
    path: '/500',
    element: 'ServerErrorPage',
  },
  {
    exact: true,
    path: '/50x.html',
    element: 'ConnectionLostPage',
  },
  {
    path: '*',
    element: 'NotFoundPage',
  },
  {
    exact: true,
    path: routesConstants.HOME,
    element: <HomePage />,
  },
  {
    exact: true,
    path: routesConstants.INDEX,
    element: <Navigate to={routesConstants.HOME} />
  },
];

export default rootRoutes;
