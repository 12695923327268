import { gameStateActions } from 'app/store/actions/game-state.actions';

export const initialState = {
  gameState: {},
};

function gameStateReducer(state = initialState, action) {
  switch (action.type) {
  case gameStateActions.GET_GAME_STATE.SUCCESS:
    return {
      ...state,
      gameState: action.payload
    };
  default:
    return state;
  }
}

export default gameStateReducer;
