import routesConstants from 'app/constants/routes.constant';
import { SessionStorageService } from 'app/services/session-storage.service';

export const goToQueuedActivity = (navigate) => {
  let url = routesConstants.HOME;
  const pendingActivities = SessionStorageService.getPendingActivities();
  const teacherDemoSettings = SessionStorageService.getTeacherDoingActivity();

  if (pendingActivities && pendingActivities.length) {
    const nextActivity = pendingActivities[0].wordActivity;
    url = `/${nextActivity.activityType.toLowerCase()}/${nextActivity.id}`;
  } else if (!pendingActivities || pendingActivities.length == 0) {
    // Finished activities
    if (teacherDemoSettings) {
      url = routesConstants.CLASSES;
    }
  }
  navigate(url);
};
