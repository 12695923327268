import React from 'react';

import 'app/components/footer/home-page-footer/home-page-footer.scss';
import { useSelector } from 'react-redux';

import { FooterStats } from 'app/components/footer/footer-stats/footer-stats.component';
import { Footer } from 'app/components/footer/footer.component';
// import { SoundSettings } from 'app/components/footer/sound-settings/sound-settings.component';
import { InternetIcon } from 'app/components/icons/internet-icon/internet-icon.component';

export const HomePageFooter = () => {
  const { stats } = useSelector(state => state.profileData);
  return (
    <Footer classNames="home-page-footer">
      <>
        <div className="connection-block">
          {/* <SoundSettings classNames="connection-block-item"/> TODO uncomment when sound is added*/}
          <InternetIcon classNames="connection-block-item"/>
        </div>
        <FooterStats completed={stats?.statsPerDay[0]?.total} correct={stats.statsPerDay[0]?.correct} level={stats.level} percents={stats.expPercentToNextLevel} classNames="footer-stats"/>
      </>
    </Footer>
  );
}; 
