import React, { useState } from 'react';

import { gsap } from 'gsap';

import { MessageTitleScreen } from 'app/components/multiple-choice/askquery/animated/messages-titlescreen.component';
import { QueryFeedbackTransition } from 'app/components/multiple-choice/askquery/animated/transition.component';
import boxQuestion from 'app/components/multiple-choice/askquery/images/box_question.svg';
import catHead from 'app/components/multiple-choice/askquery/images/cat-head.svg';
import catDissapointed from 'app/components/multiple-choice/askquery/images/cat_dissapointed.svg';
import queryCorrect from 'app/components/multiple-choice/askquery/images/query_correct.svg';
import queryIncorrect from 'app/components/multiple-choice/askquery/images/query_incorrect.svg';
import queryStraight from 'app/components/multiple-choice/askquery/images/query_straight.svg';
import questionLeft from 'app/components/multiple-choice/askquery/images/question-left.svg';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

import 'app/components/multiple-choice/askquery/askquery.scss';

export const AskQuery = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const activityClassName = 'askquery';

  const [animationFinished, setAnimationFinished] = useState(false);

  const noShow = { opacity: 0, scale: 0 };
  const show = { scale: 1, opacity: 1 };

  const articleScreen = () => (
    <MessageTitleScreen />
  );

  const questionScreen = () => (
    <div id="questionPage">
      <div id="catHeadQuestion" style={{ ...show, top: 60, left: 100 }} ><img src={catHead} alt="" aria-hidden="true" /></div>
      <div id="boxQuestion" style={{ ...show, left: '15.33%', right: '15.33%', top: '28.38%', bottom: '31.18%', }}><img src={boxQuestion} alt="" aria-hidden="true" /></div>
      <div id="queryStraightQuestion" style={{ ...show, x: 0, y: 0, left: 860, top: 525, }}><img src={queryStraight} alt="" aria-hidden="true" /></div>
    </div>
  );

  const choicesScreen = () => (
    <div id='choicesScreen'>
      <div id="leftDropdown"><img src={questionLeft} alt="" aria-hidden="true" /></div>
    </div>
  );

  const feedbackScreen = ({ isCorrect }) => (
    <div id="feedbackScreen">
      <div id="catHeadFeedback" style={{ ...show }} >
        {
          isCorrect ?
            <img src={catHead} alt="" aria-hidden="true" />
            :
            <img src={catDissapointed} alt="" aria-hidden="true" />
        }
      </div>
      <div id="queryFeedbackIcon" style={{ ...show, }}>
        {
          isCorrect ?
            <img src={queryCorrect} alt="" aria-hidden="true" />
            :
            <img src={queryIncorrect} alt="" aria-hidden="true" />
        }
      </div>
      <div id="feedbackTransition"><QueryFeedbackTransition /></div>
    </div>
  );

  const animations = {
    [ARTICLE]: function () {
      // In the component
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('#queryStraight', { ...show, duration: 0.5, x: 0, y: 0, left: 860, top: 492, }, 'same-time-article-question')
        .to('#catHeadLarge', { ...show, x: 0, y: 0, top: 60, left: 100, }, 'same-time-article-question')
        .to('#balloonQueryLarge', { ...noShow, duration: 0.2 }, 'same-time-query-chat-large')
        .to('.passage-text', { ...noShow, duration: 0.2 }, 'same-time-query-chat-large')
        .to('.next-button', { ...noShow, duration: 0.2 })
        .call(() => {
          setAnimationFinished(true);
          tl.kill();
        });

      tl.play();
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .to('.activity-card', { ...show, duration: 0.1 })
        .to('#boxQuestion', { ...show, duration: 0.5 })
        .fromTo('.question-text', { ...noShow }, { ...show, duration: 0.5 }, 'same-time-transition-2')
        .fromTo('.next-button', { ...noShow, top: 900 }, { ...show, top: 578 });

      tl.play();
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { ...noShow, duration: 0.5 }, 'head-vanish')
        .to('.next-button', { ...noShow, top: 800, duration: 0.1 }, 'head-vanish')
        .to('.question-text', { ...noShow, duration: 0.5 }, 'head-vanish')
        .to('#catHeadQuestion', { ...noShow, y: -300, duration: 0.5 }, 'head-vanish')
        .to('#boxQuestion', { ...noShow, duration: 0.5 }, 'head-vanish')
        .to('#queryStraightQuestion', { ...noShow, duration: 0.5 }, 'head-vanish')
        .call(() => {
          setAnimationFinished(true);
          tl.kill();
        });

      tl.play();
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .set('.answers-container', { overflow: 'hidden' })
        .to('.activity-card', { ...show, duration: 0.1 })
        .to('#leftDropdown', { ...show, top: 35, duration: 0.5 })
        .from('.answers', { top: 700, duration: 0.5 })
        .fromTo('.prompt', { left: 520, top: 60 }, { left: 520, top: 60, duration: 0.5 })
        .from('.article', { ...show, x: -700, duration: 0.5 })
        .fromTo('.answers-container', { ...noShow }, { ...show, })
        .from('.answer-item', { x: 700, stagger: 0.2 })
        .set('.answers-container', { overflowY: 'auto' });
      tl.play();
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.answers', { top: 700, duration: 0.5 })
        .to('.article', { ...show, x: -700, duration: 0.5 })
        .call(() => {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .to('#feedbackScreen', { ...show, duration: 0.5 })
        .set('#feedbackTransition', { display: 'block' })
        .to('.feedback', { duration: 0.1, opacity: 1, delay: 1.5 })
        .to('#feedbackTransition', { delay: 1.5, duration: 0.1, opacity: 0 })
        .fromTo('.next-button', { ...noShow, top: 900 }, { ...show, top: 578 });
      tl.play();
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      ArticleScreen={articleScreen}
      QuestionScreen={questionScreen}
      ChoicesScreen={choicesScreen}
      FeedbackScreen={feedbackScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
