import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { streamDataActions, streamsDataActionCreators } from 'app/store/actions/streams-data.actions';
import promiseHandler from 'app/util/promise-handler';

function* getStreams() {
  try {
    const [streamsError, streams] = yield promiseHandler(
      ApiClient.getStreams()
    );

    if (streamsError) throw streamsError;

    yield put(
      streamsDataActionCreators.getStreams.success({
        streams: streams.data,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      streamsDataActionCreators.getStreams.failure({
        error,
      })
    );
  }
}

export default function* streamsDataSaga() {
  yield all([
    takeEvery(streamDataActions.GET_STREAMS.REQUEST, getStreams),
  ]);
}
