import { all } from 'redux-saga/effects';

import activityDataSaga from 'app/store/effects/activity-data.saga';
import classesDataSaga from 'app/store/effects/classes-data.saga';
import gameStateSaga from 'app/store/effects/game-state.saga';
import helperDataSaga from 'app/store/effects/helper-data.saga';
import profileDataSaga from 'app/store/effects/profile-data.saga';
import streamsDataSaga from 'app/store/effects/streams-data.saga';
import userDataSaga from 'app/store/effects/user-data.saga';
import wordsDataSaga from 'app/store/effects/words-data.saga';

export default function* rootSaga() {
  yield all([
    classesDataSaga(),
    userDataSaga(),
    profileDataSaga(),
    wordsDataSaga(),
    activityDataSaga(),
    streamsDataSaga(),
    gameStateSaga(),
    helperDataSaga(),
  ]);
}
