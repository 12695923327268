
import React, { forwardRef, useContext } from 'react';

import clsx from 'clsx';

import TabsContext from 'app/components/tabs/tabs-context';
import { makeId } from 'app/util/make-id';

export const TabPanel = forwardRef(
  ({ as: Element = 'div', className, children, value, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs_panel';
    const { tabsId, activeTab } = useContext(TabsContext);
    const panelId = makeId(tabsId, 'panel', value);
    const buttonId = makeId(tabsId, 'button', value);
		

    return (
      <Element
        id={panelId}
        aria-labelledby={buttonId}
        tabIndex={activeTab === value ? '0' : '-1'}
        hidden={activeTab !== value ? true : undefined}
        aria-hidden={activeTab !== value ? true : false}
        ref={forwardedRef}
        role="tabpanel"
        style={{ visibility: activeTab == value ? 'visible' : 'hidden' }}
        className={clsx(INTERNAL_CLASS_NAME, className, { active: activeTab === value })}
        {...props}
      >
        {children}
      </Element>
    );
  }
);

TabPanel.displayName = 'TabPanel';