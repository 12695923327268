/*
 * This file comes from amp-components library
 * @TODO: Use NPM instead once amp-components is ported to react v17
*/

import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { DateRangePicker, Calendar } from 'react-date-range';
import styled from 'styled-components';

import TimePicker from 'app/components/date/date-picker/date-range-setter/TimePicker';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';


const displayedDateFormat = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const SingleDatePanelDiv = styled.div`
    height: 100%;
`;

const DateRangeSpan = styled.span`
    display: inline-block;
`;

const DateRangeSelector = ({
  minDate,
  maxDate,
  months,
  isDisabled,
  handleDateRangeChange,
  handleSingleDateChange,
  handleShownDateChange,
  dateRange,
  date,
  defaultShow,
  isRangePicker,
  useDropdown,
  showTimePicker,
  fixedHeight,
}) => {
  const changeDateFormat = ranges => {
    const formattedDates = [];
    const fromDate = ranges.startDate.toISOString();
    const toDate = ranges.endDate.toISOString();
    formattedDates.push(fromDate, toDate);
    return formattedDates;
  };

  const handleDateRangeSelect = ranges => {
    handleDateRangeChange(ranges.selection);
    changeDateFormat(ranges.selection);
  };

  const setTimeForSelectedDate = selectedDate => {
    const [hours, minutes, seconds] = [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ];
    const dateWithTime = new Date(selectedDate);
    dateWithTime.setHours(hours);
    dateWithTime.setMinutes(minutes);
    dateWithTime.setSeconds(seconds);
    return dateWithTime;
  };

  const handleSingleDateSelect = selectedDate => {
    const dateToChange = showTimePicker
      ? setTimeForSelectedDate(selectedDate)
      : selectedDate;
    handleSingleDateChange(dateToChange);
  };

  const handleTimeChange = date => {
    handleSingleDateChange(date);
  };

  const createDateRange = () => (
    <DateRangeSpan>
      {dateRange.startDate.toLocaleString([], displayedDateFormat)}
      {' - '}
      {dateRange.endDate.toLocaleString([], displayedDateFormat)}
    </DateRangeSpan>
  );

  const createDateComponent = () => {
    if (isRangePicker) {
      return (
        <DateRangePicker
          onChange={handleDateRangeSelect}
          ranges={[{ ...dateRange, key: 'selection' }]}
          months={months}
          showDateDisplay={false}
          showSelectionPreview={false}
          minDate={minDate}
          maxDate={maxDate}
          showMonthAndYearPickers={false}
          shownDate={dateRange.endDate}
          onShownDateChange={handleShownDateChange}
          fixedHeight={fixedHeight}
        />
      );
    }

    return (
      <SingleDatePanelDiv className="single-date-panel">
        <Calendar
          onChange={handleSingleDateSelect}
          date={date}
          months={months}
          showDateDisplay={false}
          minDate={minDate}
          maxDate={maxDate}
          showMonthAndYearPickers={false}
          shownDate={date}
          onShownDateChange={handleShownDateChange}
          fixedHeight={fixedHeight}
        />
        {showTimePicker && (
          <TimePicker date={date} handleTimeChange={handleTimeChange} />
        )}
      </SingleDatePanelDiv>
    );
  };

  if (!useDropdown) {
    return createDateComponent();
  }

  return (
    <Dropdown
      id="dateDropdown"
      className="dateRangeDropdown squared"
      disabled={isDisabled}
      defaultShow={defaultShow}
    >
      <Dropdown.Toggle className="squared">{createDateRange()}</Dropdown.Toggle>
      <Dropdown.Menu key="we-are-here" className="squared">
        {createDateComponent()}
      </Dropdown.Menu>
    </Dropdown>
  );
  
};

DateRangeSelector.defaultProps = {
  isDisabled: false,
  minDate: new Date(1990, 1, 1),
  maxDate: new Date(2050, 12, 31),
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
  },
  date: null,
  months: 2,
  defaultShow: true,
  isRangePicker: true,
  handleShownDateChange: () => {},
  useDropdown: true,
  showTimePicker: false,
  fixedHeight: false,
};

DateRangeSelector.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  months: PropTypes.number,
  defaultShow: PropTypes.bool,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleSingleDateChange: PropTypes.func.isRequired,
  handleShownDateChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRangePicker: PropTypes.bool,
  useDropdown: PropTypes.bool,
  showTimePicker: PropTypes.bool,
  fixedHeight: PropTypes.bool,
};

export default DateRangeSelector;