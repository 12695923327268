import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { WordsTable } from 'app/pages/words/components/words/words.table';
import { wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isIdle } from 'app/store/reducers/api-status.reducer';
import 'app/pages/words/words.page.scss';

export const WordsPage = () => {
  const dispatch = useDispatch();

  const { licenses } = useSelector(state => state.userData);
  const { getKSWordListStatus } = useSelector(state => state.apiReducer);


  useEffect(() => {
    // Prepare the units (view options)
    if (licenses.ckla) {
      if (isIdle(getKSWordListStatus)) {
        dispatch(wordsDataActionCreators.getKSWordList.request(licenses.wordListBusinessKeys));
      }
    }
  }, [licenses, getKSWordListStatus]);

  return (
    <div className="wordsPage">
      <WordsTable />
    </div>
  );
};
