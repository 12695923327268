import axios from 'axios';

import { CONFIG } from 'app/config';
import {
  ALL_POSSIBLE_ASSETS_KEYS,
  STUDENT_ROLES,
  TEACHER_ROLES,
} from 'app/constants/entitlement.constant';
import AuthService from 'app/services/auth.service';
import { arrayContainsItemFromAnother } from 'app/util/array';

const getUserAssets = async () => {
  const token = AuthService.getAccessToken();
  const userBk = AuthService.getUserBk();
  const url = `${CONFIG.RETAIL_URL}/api/licensing/users/${userBk}/assets`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.get(url, config).then(({ data }) => data);
};

const checkIfAssetIsPresentByBk = (assets, assetToFindBk) => {
  const assetsByKey = assets.filter(
    asset => asset.businessKey === assetToFindBk
  );
  return assetsByKey.length > 0;
};

const isEntitled = assets => !!ALL_POSSIBLE_ASSETS_KEYS.find(key => checkIfAssetIsPresentByBk(assets, key));

const isTeacherInRoles = roles => roles && arrayContainsItemFromAnother(TEACHER_ROLES, roles);

const isStudentInRoles = roles => roles && arrayContainsItemFromAnother(STUDENT_ROLES, roles);

export const EntitlementService = {
  getUserAssets,
  isEntitled,
  isTeacherInRoles,
  isStudentInRoles,
};
