import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StreamsDropdown } from 'app/components/dropdowns/streams/streams-dropdown.component';
import { StudentsDropdown } from 'app/components/dropdowns/students/students-dropdown.component';
import { Loader } from 'app/components/loader/loader';
import { HomeStats } from 'app/components/stats/home-stats/home-stats.component';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';

import 'app/components/reporting/student/student.page.scss';

export const StudentPage = (props) => {

  const { handleStudentSelection } = props;
  const { students, currentStudentBK } = useSelector(state => state.classesData);

  const { getLatestStudentProfileStatus } = useSelector(state => state.apiReducer);

  const student = students[currentStudentBK];

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentStudentBK && !student.latestProfile)
      dispatch(classesDataActionCreators.getLatestStudentProfile.request());
  }, [student]);

  return (
    (currentStudentBK && student.latestProfile) ?
      <div className='reportingPage'>
        {
          student.latestProfile &&
          <div className='studentPage'>
            <div className='helperSide'>
              <div className='studentsDropdown'>
                <StudentsDropdown
                  handleStudentSelection={handleStudentSelection}
                />
              </div>
              <div className='streamsDropdown'>
                <StreamsDropdown />
              </div>
            </div>
            {
              (student.studentStats && isSuccess(getLatestStudentProfileStatus)) ?
                <HomeStats classNames="stats-box"
                  stats={student.studentStats}
                />
                :
                <Loader />
            }
          </div>
        }
      </div>
      :
      <Loader />
  );
};