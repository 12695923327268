import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const seagullTimeline = gsap.timeline({ paused: true });

export const RisingTransition = () => {

  useEffect(() => {

    seagullTimeline
      .add(gsap.to('#seagull-big', { duration: 2, scale: 1.25, transformOrigin: '50% 50%', x: -200, y: -100, delay: 0.5, ease: 'sine.out' }), 0)
      .add(gsap.to('#seagull-big', { duration: 2, scale: 4.5, x: 0, y: -420, ease: 'sine.in' }), 2)
      .add(gsap.to('#seagull-big', { duration: 1, x: -240, y: -1800, ease: 'sine.inOut' }), 4)
      .add(gsap.to('#blink', { duration: 0.1, opacity: 1, yoyo: true, repeat: 1 }), 2)
      .add(gsap.fromTo('#wing-left', { transformOrigin: '95% 80%', rotate: 20 }, { duration: 0.2, rotate: -20, yoyo: true, repeat: 26, ease: 'sine.inOut' }), 0)
      .add(gsap.fromTo('#wing-right', { transformOrigin: '5% 40%', rotate: -20 }, { duration: 0.2, rotate: 20, yoyo: true, repeat: 26, ease: 'sine.inOut' }), 0)
      .add(gsap.fromTo('#seagull-all', { y: 20 }, { duration: 0.2, y: -20, yoyo: true, repeat: 26, ease: 'sine.inOut' }), 0);

  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680" width="1024" height="680">
      <g id="seagull-big" transform="translate(-1200 -300)">
        <g id="seagull-all">
          <g id="wing-right">
            <path d="M865,558.14c-22.41-15.77-45-31.67-71.54-41.7-23.49-8.89-49.43-13-75.19-17l-3.84-.6C700,497,671.52,493,662,493c-29,0-46-.23-68,3-18.22,2.68-35.77,35.23-48,49-8.33,9.38-20,31,18.48,39.24,12.72,2.11,27.75,1.56,37.71,2.46,18.4,1.65,36.87,3.36,55.33,4,43.52,1.5,94,14.44,131,31.13,49.4,22.27,89.87,57,137.83,81.43s108.35,37.92,166.38,15.16Z" fill="#d1d3d4"/>
            <path d="M697,505c1.28.2,15.16,1.33,16.44,1.53,25.76,4,51.7,8.07,75.2,17,26.48,10,49.13,25.93,71.53,41.7l222.67,156.7c3.3-1.06,6.61-2.2,9.9-3.49L865,558.14c-22.41-15.77-45-31.67-71.54-41.7-23.49-8.89-49.43-13-75.19-17l-7.88-1.22C697,497,697.51,502.48,697,505Z" fill="#e6e7e8"/>
            <path d="M926.36,703.25c-17-8.66-33-18.62-48.87-28.85,12,2.61,24.2,4.65,36.5,4.72,17.77.11,36.14-4.21,49.94-15.42a51.91,51.91,0,0,0,16.53-24.32l112.28,79C1034.71,741.17,974.32,727.68,926.36,703.25Z" fill="#58595b"/>
            <path d="M978.5,648.47l104.34,73.43c3.3-1.06,6.61-2.2,9.9-3.49L982.08,640.52A51,51,0,0,1,978.5,648.47Z" fill="#6d6e71"/>
          </g>
          <g id="wing-left">
            <path d="M517,498c19.6-16.16,49-44,21.93-79.66l-82.81-96.55c-17-19.79-34.08-39.71-54-55-22.47-17.24-47.91-28.13-73.11-38.88L72.87,118.66C84.06,180,127.32,224.22,173.34,252.14s96.84,44.05,141.78,74.33c33.67,22.68,57.55,60.09,81.73,96.3,11,16.43,28.43,56.28,41.34,70.51C467,523,503.83,508.85,517,498Z" fill="#d1d3d4"/>
            <path d="M324.15,235c25.21,10.75,50.64,21.63,73.11,38.87,19.93,15.29,37,35.22,54,55l82.81,96.56c2.85-1,5.73-1.83,8.62-2.66L456.12,321.79c-17-19.79-34.08-39.71-54-55-22.47-17.24-47.91-28.13-73.11-38.88L72.87,118.66c.62,3.41,1.38,6.75,2.19,10.06Z" fill="#e6e7e8"/>
            <path d="M229,280.16c-18.92-8.91-37.76-18.06-55.85-29-45.75-27.77-88.76-71.68-100.24-132.47L78.18,120l147.1,62.74A132.53,132.53,0,0,0,229,280.16Z" fill="#58595b"/>
            <path d="M222.51,191.61c.75-2.71,1.58-5.4,2.51-8.05L72.87,118.66c.64,3.42,1.42,6.77,2.26,10.09Z" fill="#6d6e71"/>
          </g>
          <path d="M520.86,617.21a85.6,85.6,0,0,1-20.84,5.4,76.9,76.9,0,0,1-20.94-.34,1.21,1.21,0,0,0,0,.19,13.63,13.63,0,1,1-27.14-2.5,13.48,13.48,0,0,1,2.28-6.27,57.15,57.15,0,0,1-20.14-18.44,13.59,13.59,0,1,1-11.37-24.64,88.16,88.16,0,0,1-2.49-25.27c-37.5,13.88-76.62,29-113.32,42.3.14-.76,5.2,77,70,117.59,38.27,24,84.7,36.29,128.86,26.81A295.52,295.52,0,0,1,520.86,617.21Z" fill="#d1d3d4"/>
          <path d="M341,575.09C335.13,615,352.31,657.87,384.83,682c34.37,25.48,83.78,28.47,121.15,7.74a293.17,293.17,0,0,0-.27,42.33c-44.17,9.48-90.59-2.82-128.86-26.81-64.75-40.58-69.81-118.35-70-117.59C318.07,583.6,329.46,579.39,341,575.09Z" fill="#58595b"/>
          <g>
            <path d="M460.39,629.38l-7.77,15.82a4.89,4.89,0,0,0-3.91,8L435.5,680c0,.05,0,.1,0,.14-5.41,3.67-15.38,10.9-18.67,13.29-.34.25-.29.81.07.82l4.24.13c2.5.08,4.38,2,4.7,4.87l.47,4.06a.31.31,0,0,0,.5.25l3.5-2.12c2.45-1.49,5.13-1.19,6.72.75l2.7,3.28c.23.28.7,0,.69-.45-.12-4.2-.51-17.26-.93-23.57q6.5-13.25,13-26.49a4.87,4.87,0,0,0,3.9-8q3.83-7.77,7.64-15.55C465.2,629.08,461.58,627,460.39,629.38Z" fill="#f7941e"/>
            <g>
              <path d="M431.86,686.85l-6.21,11.56a5.25,5.25,0,0,0-2.26-3.29Z" fill="#f17c29"/>
              <path d="M431.08,701.07l5.34-12-1.36,11.76A5.24,5.24,0,0,0,431.08,701.07Z" fill="#f17c29"/>
            </g>
          </g>
          <g>
            <path d="M420.48,586.42l-15.32,8.28a4.87,4.87,0,0,0-7.78,4.22l-26.54,14.34c-6.54-.15-18.79,0-22.84,0-.42,0-.71.48-.42.7l3.38,2.57c2,1.52,2.4,4.18,1,6.69l-2,3.58a.31.31,0,0,0,.27.49l4.08.31c2.86.21,4.87,2,5,4.51l.3,4.23c0,.36.59.39.82,0,2.34-3.49,9.59-14.35,12.9-19.73l26-14a4.88,4.88,0,0,0,7.79-4.21l15.43-8.34C425,588.76,422.86,585.14,420.48,586.42Z" fill="#f7941e"/>
            <g>
              <path d="M364.11,616.61l-11.76,5.81a5.26,5.26,0,0,0,.07-4Z" fill="#f17c29"/>
              <path d="M355.23,627.74l11.3-6.66-7.93,8.79A5.2,5.2,0,0,0,355.23,627.74Z" fill="#f17c29"/>
            </g>
          </g>
          <path d="M422.71,570.61a89.16,89.16,0,0,1,.33-44.17c7.52-28.36,27.14-52.19,49.78-70.85,30.34-25,67.55-42.44,106.71-46s80.14,7.64,109.81,33.43c11.66,10.13,21.78,22.94,25,38s-1.78,32.64-14.81,40.92c-18.75,11.91-43.48,1-65.46,4.23-18.22,2.68-33.56,15.06-45.79,28.84s-22.4,29.41-35.87,42C538,610.53,519.63,620.18,500,622.61a76.9,76.9,0,0,1-20.94-.34,1.21,1.21,0,0,0,0,.19,13.63,13.63,0,1,1-27.14-2.5,13.48,13.48,0,0,1,2.28-6.27,57.15,57.15,0,0,1-20.14-18.44,13.59,13.59,0,1,1-11.37-24.64Z" fill="#e6e7e8"/>
          <path d="M530.61,421.35h0c2,2.28,34.39-4.27,39.35-4.57a216.39,216.39,0,0,1,41.11,1c27.45,3.55,52.52,14.15,73.43,32.31,11.66,10.13,21.77,22.94,25,38a44,44,0,0,1,.08,17.78l2.53.4a43.53,43.53,0,0,0,2.24-25.23c-3.25-15.1-13.37-27.91-25-38-29.67-25.79-70.65-37-109.81-33.43A178.5,178.5,0,0,0,530.61,421.35Z" fill="#fff"/>
          <path d="M663.57,479.27a15.91,15.91,0,1,0,22-4.86A15.92,15.92,0,0,0,663.57,479.27Z" fill="#fff"/>
          <path d="M666.71,481.27a12.19,12.19,0,1,0,16.83-3.72A12.18,12.18,0,0,0,666.71,481.27Z" fill="#414042"/>
          <path d="M685.71,483a3,3,0,1,0,4.21-.93A3,3,0,0,0,685.71,483Z" fill="#fff"/>
          <path d="M679.07,507.51c2.79,6.13,7.32,13.23,10.1,19.37,12.89,8.3,23.44,14.49,36.32,22.79,4.56,2.93,9.17,5.91,12.78,10s5.46,7.66,5.08,13.25c7.25-12.5,6.24-28.7-.54-41s-18.6-21-31.48-26.26C711.33,505.61,690,507.26,679.07,507.51Z" fill="#f7941e"/>
          <path d="M679.07,507.51c8.6,7.17,20.36,13.9,30,19.56,11,6.46,22,13.82,29.11,24.57a42.17,42.17,0,0,1,6.68,18,34.62,34.62,0,0,1-1.82,3.66c.38-5.59-1.47-9.18-5.08-13.25s-8.22-7.05-12.77-10l-36.06-23.21C686.51,521,681.56,513.26,679.07,507.51Z" fill="#f77c0c"/>
          <path d="M713.15,511.94a8.61,8.61,0,0,0,7,1.94,13.33,13.33,0,0,0-4.85-3.9,3.14,3.14,0,0,0-1.73-.41,1.28,1.28,0,0,0-1.17,1.16A1.7,1.7,0,0,0,713.15,511.94Z" fill="#3c2415"/>
          <path d="M711.33,505.61c12.88,5.22,24.71,13.94,31.48,26.26,3.49,6.35,6.26,15.4,5.47,24.17-2.15-9.93-4.8-21.43-11.1-29.4-6.82-8.64-17.56-16.52-27.67-20.9Z" fill="#fbb040"/>
          <g id="blink" opacity="0">
            <circle cx="677.08" cy="487.58" r="21.26" fill="#e6e7e8"/>
            <path d="M660.69,477.13a12,12,0,0,1,5.13-1.48,19.51,19.51,0,0,1,5.44.36,25.15,25.15,0,0,1,9.76,4.66,22.79,22.79,0,0,1,2.09,1.7l1.92,1.86a22.07,22.07,0,0,1,1.73,2c.55.69,1.1,1.38,1.62,2.09.94,1.49,1.88,3,2.68,4.52a44.48,44.48,0,0,1,1.94,4.87c-1.37-1.15-2.55-2.37-3.74-3.58s-2.43-2.33-3.57-3.52-2.4-2.26-3.63-3.32-2.42-2.13-3.74-3a38.8,38.8,0,0,0-8.11-4.89,32.31,32.31,0,0,0-4.54-1.54A21.07,21.07,0,0,0,660.69,477.13Z" fill="#414042"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
