import React, { useRef } from 'react';

import clsx from 'clsx';

import 'app/components/syn-ant-activity/modal/words-modals.scss';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';

export const WordsModal = ({ classNames, setIsModalVisible, activity, iconIdea, closeButton }) => {

  const modalRef = useRef(null);
  const middleIndex = Math.ceil(activity.words.length / 2);
  const words = activity && activity.words ? Object.assign([], activity.words) : [];

  const hideModal = () => {
    setIsModalVisible(false);
  };

  useOnClickOutside(modalRef, hideModal);

  return (
    <div className={clsx(classNames, 'words-modal-wrapper', 'fade-in')} ref={modalRef}>
      <img className="words-modal-icon" src={iconIdea} alt="" aria-hidden="true" />
      <div className="words-modal">
        <div className="words-modal-column">
          {
            words.splice(0, middleIndex).map((word, i) => {
              return (
                <div key={i + middleIndex} className="word">
                  <p className="word-word">{word.word}</p>
                  <p className="word-definition">{word.definition}</p>
                </div>
              );
            })
          }
        </div>
        <div className="words-modal-divider" />
        <div className="words-modal-column">
          {
            words.splice(-middleIndex).map((word, i) => {
              return (
                <div key={i + middleIndex} className="word">
                  <p className="word-word">{word.word}</p>
                  <p className="word-definition">{word.definition}</p>
                </div>
              );
            })
          }
        </div>
      </div>
      <button aria-label="Close Words Modal" className="close-button" style={{ backgroundImage: `url(${closeButton})` }} onClick={hideModal} />
    </div>
  );
};
