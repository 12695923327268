import React from 'react';

import 'app/components/go-button/go-button.scss';
import { array } from 'prop-types';
import { useNavigate } from 'react-router-dom';

function getNextActivityRoute(firstEncounters) {
  const { wordActivity } = firstEncounters[0];
  return `/${wordActivity.activityType.toLowerCase()}/${wordActivity.id}`;
}

export const GoButton = (props) => {
  const { firstEncounters, readyToDropChest } = props;
  const navigate = useNavigate();

  const startActivity = () => navigate(getNextActivityRoute(firstEncounters));

  const areFirstEncounters = firstEncounters?.length && firstEncounters?.length > 0;

  return (
    <div className='go-container'>
      <button onClick={startActivity} className={`go-btn jello-horizontal ${areFirstEncounters && readyToDropChest ? '' : 'disabled'}`} disabled={!areFirstEncounters || !readyToDropChest} aria-label='Start the activities'>
        GO
      </button>
    </div>
  );
};

GoButton.propTypes = {
  firstEncounters: array,
};
