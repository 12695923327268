import React, { useEffect, forwardRef, useContext, useRef } from 'react';

import clsx from 'clsx';

import { ConditionalWrapper } from 'app/components/conditional-wrapper/conditional-wrapper.component';
import { DescendantContext } from 'app/components/tabs/descendant-provider.component';
import TabsContext from 'app/components/tabs/tabs-context';
import { KEY_NAMES } from 'app/constants/keys.constant';
import { useComposedRefs } from 'app/hooks/use-composed-refs.hook';
import { makeId } from 'app/util/make-id'; 

export const Tab = forwardRef(
  ({ as: Element = 'button', className, children, value, isList = true, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs_tab';
    const { activeTab, setActiveValue } = useContext(TabsContext);
    const panelId = makeId('panel', value);
    const buttonId = makeId('button', value);
    const { getIndex, register, deregister, keys } = useContext(DescendantContext);

    const ownRef = useRef();
    const mounted = useRef();
    const ref = useComposedRefs(ownRef, forwardedRef);

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
        return;
      }

      if (activeTab === value && document.activeElement !== ownRef.current) {
        ownRef.current.focus();
      }
    }, [activeTab]);

    useEffect(() => {
      register(value);  

      return () => deregister(value);
    }, [value]);


    const handleKeyUp = (event) => {
      const index = getIndex(value);
      const lastIndex = keys.length - 1;
      const nextIndex = clamp(index + 1, 0, lastIndex);
      const previousIndex = clamp(index - 1, 0, lastIndex);

      switch (event.key) {
      case KEY_NAMES.ARROW_UP:
      case KEY_NAMES.ARROW_LEFT:
        setActiveValue(keys[previousIndex]);
        break;
      case KEY_NAMES.ARROW_DOWN:
      case KEY_NAMES.ARROW_RIGHT:
        setActiveValue(keys[nextIndex]);
        break;
      case KEY_NAMES.HOME:
        setActiveValue(keys[0]);
        break;
      case KEY_NAMES.END:
        setActiveValue(keys[lastIndex]);
        break;
      }

    };

    const clamp = (num, min, max) => {
      return Math.min(Math.max(num, min), max);
    };

    const handleClick = () => {
      setActiveValue(value);
    };

    return (
      <ConditionalWrapper
        condition={isList}
        wrapper={children => <li className="tabs_tab-container">{children}</li>}
      > 
        <Element
          role="tab"
          onClick={handleClick}
          onKeyUp={handleKeyUp}
          id={buttonId}
          aria-selected={activeTab === value}
          tabIndex={!isList || activeTab === value ? '0' : '-1'}
          aria-controls={panelId}
          className={clsx(INTERNAL_CLASS_NAME, className, { active: activeTab === value })}
          ref={ref}
          {...props}
        >
          {children}
        </Element>
      </ConditionalWrapper>
    );
  }
);

Tab.displayName = 'Tab';
Tab.contextType = TabsContext;
