import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { gameStateActionCreators, gameStateActions } from 'app/store/actions/game-state.actions';
import promiseHandler from 'app/util/promise-handler';

function* getGameState(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getGameState(action.payload.arId));

    if (error) throw error;

    const gameState = response.data;

    yield put(gameStateActionCreators.getGameState.success(gameState));
  } catch (error) {
    console.error(error);
    yield put(
      gameStateActionCreators.getGameState.failure({
        error,
      })
    );
  }
}

function* setGameState(action) {
  try {
    const [error, response] = yield promiseHandler(
      ApiClient.setGameState(action.payload.arId, action.payload.gameState)
    );

    if (error) throw error;

    yield put(
      gameStateActionCreators.setGameState.success({
        response,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      gameStateActionCreators.setGameState.failure({
        error,
      })
    );
  }
}

export default function* gameStateSaga() {
  yield all([
    takeEvery(gameStateActions.GET_GAME_STATE.REQUEST, getGameState),
    takeEvery(gameStateActions.SET_GAME_STATE.REQUEST, setGameState),
  ]);
}
