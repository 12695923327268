import React from 'react';

import clsx from 'clsx';
import { object, string } from 'prop-types';

import 'app/components/stats/progress-pie-charts/progress-pie-charts.scss';

export const ProgressPieCharts = props => {
  const { classNames, stats } = props;
  return (
    <div className={clsx('progress-pie-chart-container', classNames)}>
      {stats &&
      <svg xmlns="http://www.w3.org/2000/svg" width="323" height="312" viewBox="0 0 323 312">
        <g fill="none" fillRule="evenodd">
          <g>
            <path d="M248.522 306.792c34.75 0 69.5-31.116 69.5-69.5s-31.116-69.5-69.5-69.5-69.5 31.116-69.5 69.5 34.75 69.5 69.5 69.5z" stroke="#14405C" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0,29"/>
            <text fontFamily="OpenSans Regular, OpenSans" fontSize="21" fill="#14405C" transform="translate(179 167.5)">
              <tspan x="22" y="92">mastered </tspan>
              <tspan x="39" y="111">words</tspan>
            </text>
            <text  x="67" fontFamily="OpenSans Bold, OpenSans" fontSize="24" fontWeight="bold" fill="#14405C" transform="translate(179 167.5)">
              <tspan y="55.348" textAnchor="middle" id="wordsMastered">{stats.wordsMastered}</tspan>
            </text>
          </g>
          <g>
            <path d="M74.522 306.792c34.75 0 69.5-31.116 69.5-69.5s-31.116-69.5-69.5-69.5-69.5 31.116-69.5 69.5 34.75 69.5 69.5 69.5z" stroke="#14405C" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="212,61"/>
            <text fontFamily="OpenSans Regular, OpenSans" fontSize="21" fill="#14405C" transform="translate(5 167.5)">
              <tspan x="25" y="87.995">accuracy</tspan>
            </text>
            <text  x="67" fontFamily="OpenSans Bold, OpenSans" fontSize="24" fontWeight="bold" fill="#14405C" transform="translate(5 167.5)">
              <tspan y="55.348" textAnchor="middle" id="accuracyPercentage">{stats.accuracyPercentage + '%'}</tspan>
            </text>
          </g> 
          <g>
            <path d="M74.522 142.292c34.75 0 69.5-31.116 69.5-69.5s-31.116-69.5-69.5-69.5-69.5 31.116-69.5 69.5 34.75 69.5 69.5 69.5z" stroke="#14405C" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            <text fontFamily="OpenSans Regular, OpenSans" fontSize="21" fill="#14405C" transform="translate(5 3)">
              <tspan x="27" y="82">activities </tspan>
              <tspan x="17" y="101">completed</tspan>
            </text>
            <text  x="67" fontFamily="OpenSans Bold, OpenSans" fontSize="24" fontWeight="bold" fill="#14405C" transform="translate(5 3)">
              <tspan y="55.348" textAnchor="middle" id="activitiesCompleted">{stats.activitiesCompleted}</tspan>
            </text>
          </g>
          <g fill="#14405C">
            <text fontFamily="OpenSans Regular, OpenSans" fontSize="21" transform="translate(179 3)">
              <tspan x="30.153" y="87.995">longest </tspan>
              <tspan x="37.71" y="106.995">streak</tspan>
            </text>
            <text  x="67" fontFamily="OpenSans Bold, OpenSans" fontSize="24" fontWeight="bold" transform="translate(179 3)">
              <tspan y="55.348" textAnchor="middle" id="longestStreak">{stats.longestStreak}</tspan>
            </text>
            <path d="M188 129.5 310 116v27z"/>
          </g>
        </g>
      </svg>}
    </div>
  );
};

ProgressPieCharts.propTypes = {
  classNames: string,
  stats: object,
};
