export const KEY_NAMES = {
  ESCAPE: 'Escape',
  SPACE: 'Space',
  EMPTY: ' ',
  ENTER: 'Enter',
  TAB: 'Tab',
  SHIFT: 'shiftKey',
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
};

export const isSelectKey = (key) => {
  return key == KEY_NAMES.ENTER || key == KEY_NAMES.EMPTY || key == KEY_NAMES.SPACE;
};

export const isExitKey = (key) => {
  return key == KEY_NAMES.ESCAPE;
};
