import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const KeishaTransition = () => {

  useEffect(() => {
    const tl = gsap.timeline()    
      .set('#smoke-keisha', { y: 800 })
      .set('#smoke-bg > circle', { y: 800 })
      .set('#smoke-fg > circle', { y: 700 })
      .set('#smoke-floaters > circle', { y: 700 })
      .set('#bolt1', { transformOrigin: '100% 100%', scale: 1.5 })
      .set('#bolt2', { transformOrigin: '100% 100%', scale: 0.75 })
      .set('#bolt3', { transformOrigin: '100% 0%', scale: 1.5 })
      .set('#bolt4', { transformOrigin: '100% 0%', scale: 0.8 })
      .addPause(0.5)
      .to('#smoke-keisha', { duration: 1.5, y: 0, delay: 1, ease: 'sine.inOut' })
      .add(gsap.to('#keisha-transition', { duration: 0.25, opacity: 1, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#smoke-bg > circle', { duration: 3, y: 0, stagger: 0.05, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#smoke-fg > circle', { duration: 2, y: 0, stagger: 0.05, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#smoke-floaters > circle', { duration: 3, y: -500, stagger: 0.05, ease: 'sine.inOut' }), 1.5)
      .add(gsap.to('#smoke-arm-r', { duration: 0.5, transformOrigin: '20% 60%', rotate: 30, yoyo: true, repeat: 7, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#smoke-arm-l', { duration: 0.75, transformOrigin: '90% 70%', rotate: -20, yoyo: true, repeat: 6, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#smoke-head', { duration: 0.75, transformOrigin: '50% 95%', rotate: -20, yoyo: true, repeat: 6, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#haze', { duration: 0.5, opacity: 1, ease: 'sine.in' }), 4)
      .add(gsap.to('#bolt1', { duration: 1, scale: 1, yoyo: true, repeat: 3, ease: 'sine.in' }), 1)
      .add(gsap.to('#bolt2', { duration: 0.75, scale: 1, yoyo: true, repeat: 5, ease: 'sine.in' }), 1)
      .add(gsap.to('#bolt3', { duration: 1, scale: 1, yoyo: true, repeat: 3, ease: 'sine.in' }), 1)
      .add(gsap.to('#bolt4', { duration: 0.8, scale: 1, yoyo: true, repeat: 4, ease: 'sine.in' }), 1)
      .add(gsap.to('#smoke-keisha', { duration: 0.1, opacity: 0 }), 5)
      .add(gsap.to('#haze', { duration: 2, opacity: 0, ease: 'sine.inOut' }), 5.5)
      .add(gsap.to('#smoke-bg > circle', { duration: 1, y: 800, opacity: 0, ease: 'sine.in' }), 5.5)
      .add(gsap.to('#smoke-fg > circle', { duration: 2, y: 700, opacity: 0, ease: 'sine.in' }), 5.5);

    tl.play(1);

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <title>Keisha</title>
      <g id="keisha-transition" opacity="0">
        <g id="smoke-bg">
          <circle cx="936" cy="401.42" r="126.9" fill="#888"/>
          <circle cx="63.84" cy="419.41" r="126.9" fill="#888"/>
          <circle cx="286.49" cy="43.74" r="102.9" fill="#9b9b9b"/>
          <circle cx="156.22" cy="42.72" r="102.9" fill="#9b9b9b"/>
          <circle cx="808.37" cy="10.69" r="102.9" fill="#9b9b9b"/>
          <circle cx="376.92" cy="145.62" r="102.9" fill="#898989"/>
          <circle cx="203.32" cy="205.45" r="102.9" fill="#898989"/>
          <circle cx="29.65" cy="90.09" r="102.9" fill="#898989"/>
          <circle cx="753.75" cy="143.38" r="102.9" fill="#898989"/>
          <circle cx="957.94" cy="89.11" r="117.53" fill="#898989"/>
          <circle cx="305.49" cy="323.35" r="102.9" fill="#686868"/>
          <circle cx="16.84" cy="230.48" r="102.9" fill="#686868"/>
          <circle cx="178.07" cy="400.56" r="102.9" fill="#595559"/>
          <circle cx="-41.38" cy="347.31" r="102.9" fill="#595559"/>
          <circle cx="795.66" cy="262.78" r="102.9" fill="#686868"/>
          <circle cx="409.04" cy="421.21" r="102.9" fill="#595559"/>
          <circle cx="715.1" cy="361.41" r="102.9" fill="#686868"/>
          <circle cx="547.7" cy="284.87" r="102.9" fill="#595559"/>
          <circle cx="613.76" cy="450.57" r="102.9" fill="#595559"/>
          <circle cx="851.93" cy="439.07" r="102.9" fill="#595559"/>
          <circle cx="929.66" cy="211.96" r="102.9" fill="#686868"/>
          <circle cx="198.43" cy="634.37" r="102.9" fill="#595559"/>
          <circle cx="61.52" cy="686.94" r="102.9" fill="#494849"/>
          <circle cx="815.72" cy="634.37" r="102.9" fill="#595559"/>
          <circle cx="1042.21" cy="299.64" r="102.9" fill="#595559"/>
        </g>
        <g id="smoke-keisha">
          <g id="electricity">
            <polygon id="bolt1" points="309.01 143.67 294.72 74.7 283.12 85.25 263.62 39.58 283.12 109.42 290.11 101.31 309.01 143.67" fill="#ffeb3e"/>
            <polygon id="bolt2" points="302.52 156.13 266.58 118.89 263.52 130 233.49 109.28 273 145.01 274.17 137.23 302.52 156.13" fill="#ffeb3e"/>
            <polygon id="bolt3" points="777.81 123.48 798.28 56.08 808.88 67.64 832.43 23.92 806.69 91.71 800.46 83 777.81 123.48" fill="#ffeb3e"/>
            <polygon id="bolt4" points="786.4 138.14 822.36 99.58 825.68 110.84 855.91 89.22 816.28 126.3 814.96 118.4 786.4 138.14" fill="#ffeb3e"/>
          </g>
          <g id="smoke-arm-l">
            <g id="forearm-l">
              <path d="M188.12,796,149.74,313.8s-26.5-116.45,73.15-82.57c46.29,15.73,77.13,26.4,96.88,33.32,22.77,8,13.63,30.09,13.63,30.09l-6.49,7.24s5.51-24.35-14.32-30L218.7,243.55S148.49,213.71,161,310.39,200.89,796,200.89,796Z" fill="#ffed5f"/>
              <g>
                <g>
                  <g>
                    <polygon points="281.64 284.28 188.72 502.25 123.27 476.27 216.19 258.31 281.64 284.28" fill="#b9673b"/>
                    <path d="M209.21,248.66l18.53-42A9.15,9.15,0,0,1,239.48,202l67.7,27.29a8.45,8.45,0,0,1,4.68,11.31l-18.53,42c-1.95,4.42-12.51,16.09-17,14.27l-64.53-28.14C207.23,266.86,207.26,253.09,209.21,248.66Z" fill="#b9673b"/>
                    <polygon points="279.32 326.22 181.58 540.72 93.69 505.55 183.92 284.99 279.32 326.22" fill="#ddf2ed"/>
                  </g>
                  <circle cx="136.99" cy="524.68" r="47.38" fill="#ddf2ed"/>
                </g>
                <path d="M267.48,300s15.2,2.48,31.56-10,1.85-25.29,1.85-25.29,16.36,8.06,21.64-13.21.17-28.58-8.24-31-24,4.54-24,4.54Z" fill="#b9673b"/>
                <path d="M307.18,229.26s2.12-23.58-16.85-19-23.13,19-23.13,19Z" fill="#b9673b"/>
                <path d="M283.15,234.17s10-40-15.95-32.79c-26.71,7.45-6.67,39.05-6.67,39.05Z" fill="#b9673b"/>
                <path d="M266.67,212.93s-.1-33.56-27.19-11,.9,33.17.9,33.17Z" fill="#b9673b"/>
              </g>
              <line x1="311.86" y1="240.57" x2="293.33" y2="282.56" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
              <path d="M297.31,222.43s-15.08-1.71-14.16,11.74" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
              <path d="M274.73,214.65s-11.84.81-11.3,7.71" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
              <path d="M253.32,207.55s-6.54.79-6.15,5.38" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
              <g>
                <path d="M334.31,283.47s8.19-3.94,13,6.45,8,20.12,8,20.12l-29.75,9.63s-11.38-25.75.42-32Z" fill="#8044a7"/>
                <path d="M355.3,310s-.1-2.06-6.33,0-19.17,6.14-19.17,6.14-4.59,1-4.25,3.49h0L355.3,310" fill="#552e7a"/>
                <polygon points="350.88 310.2 347.06 311.53 353.1 326.82 354.17 327.62 356.57 326.64 356.57 325.58 350.88 310.2" fill="#796e84"/>
                <circle cx="354.22" cy="324.7" r="0.8" fill="#fff"/>
                <polygon points="334.55 315.61 330.7 316.86 336.39 332.28 337.44 333.11 339.87 332.18 339.89 331.12 334.55 315.61" fill="#796e84"/>
                <circle cx="337.55" cy="330.19" r="0.8" fill="#fff"/>
              </g>
            </g>
            <g id="bicep-l">
              <polygon points="429.76 512.99 136.21 573.07 112.9 472.53 406.45 412.44 429.76 512.99" fill="#ddf2ed"/>
              <polygon points="429.76 512.99 187.41 562.59 325.99 428.91 406.45 412.44 429.76 512.99" fill="#d2e3df"/>
              <circle cx="407.4" cy="464.45" r="51.66" fill="#d2e3df"/>
            </g>
          </g>
          <g id="smoke-arm-r">
            <g id="forearm-r">
              <path d="M740.09,323s-15.2,2.48-31.56-10-1.86-25.28-1.86-25.28-16.35,8.06-21.63-13.21-.17-28.59,8.24-31,24,4.54,24,4.54Z" fill="#b9673b"/>
              <g>
                <path d="M693.5,294.93,600.24,367.1s-6.38,5.37-10.68.88l-21,20.71,4.29,5.82,25.85-14.12s-4.37-4.11,4.37-9.89,96-68,96-68Z" fill="#9c9c9c"/>
                <g>
                  <path d="M714.17,284.67c5.76,7.82,26.34,2.43,46-12,14.71-10.85,35.54-26.15,37.19-35.21.55-3-1.54-7.93-3-9.88s-5.09-4.82-8.14-5.19c-9.14-1.11-32.23,11.12-47,22C719.64,258.79,708.41,276.85,714.17,284.67Z" fill="#3f1354"/>
                  <path d="M713,276.55s-5.3,17.57-17.91,6.53l-9.29,6.85,16.4,22.24,10.58-7.8s-9.27-10.33,9.42-16.09C738.72,283.19,713,276.55,713,276.55Z" fill="#3f1354"/>
                </g>
                <path d="M687.58,292.55l8.81-6.78s6.36,5.91,10.63,3.08a71,71,0,0,0,8.27-6.62s9.1-30.47,53.8-49c0,0,17.11-9,21-4.32s7.07,9.39,6.48,10.76c0,0,7.38-14.24-10-18.62,0,0-19,4.29-32.56,13.12-12.61,8.21-35.46,20.59-41,42.4,0,0-4.73,16.24-17.91,6.53l-9.29,6.85Z" fill="#ebcef2"/>
                <polygon points="570.43 386.89 574.71 393.52 572.89 394.51 568.6 388.69 570.43 386.89" fill="#dcdcdc"/>
              </g>
              <g>
                <g>
                  <polygon points="731.39 308.73 851.66 512.89 913.21 478.69 792.95 274.54 731.39 308.73" fill="#b9673b"/>
                  <path d="M798.62,264.07l-23.79-39.25a9.16,9.16,0,0,0-12.25-3.15L699,257.46a8.47,8.47,0,0,0-3.17,11.83l23.79,39.24a9.14,9.14,0,0,0,12.25,3.15L798,283.09C802.23,280.7,801.13,268.2,798.62,264.07Z" fill="#b9673b"/>
                  <polygon points="739.11 350.03 863.7 550.12 946.32 503.91 828.38 296.83 739.11 350.03" fill="#ddf2ed"/>
                </g>
                <circle cx="905.85" cy="528.46" r="47.38" fill="#ddf2ed"/>
              </g>
              <g>
                <path d="M700.38,252.19s-2.11-23.58,16.86-19,23.12,19,23.12,19Z" fill="#b9673b"/>
                <path d="M724.42,257.1s-9.95-40,15.94-32.79c26.72,7.46,6.67,39.06,6.67,39.06Z" fill="#b9673b"/>
                <path d="M740.9,235.87s.1-33.56,27.19-11-.9,33.17-.9,33.17Z" fill="#b9673b"/>
                <line x1="696.62" y1="270.62" x2="721.15" y2="309.4" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
                <path d="M709.39,245.48s14.67-3.92,15.75,9.52" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
                <path d="M730.44,237.44s11.83-.95,12.32,6" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
                <path d="M749.62,227.78s6.58-.18,6.88,4.41" fill="none" stroke="#84462a" strokeMiterlimit="10" strokeWidth="1.44"/>
              </g>
            </g>
            <g id="bicep-r">
              <polygon points="614.43 516.86 907.99 576.95 931.3 476.4 637.75 416.32 614.43 516.86" fill="#ddf2ed"/>
              <polygon points="614.43 516.86 856.78 566.47 718.21 432.79 637.75 416.32 614.43 516.86" fill="#d2e3df"/>
              <circle cx="636.8" cy="468.33" r="51.66" fill="#d2e3df"/>
            </g>
          </g>
          <g id="smoke-head">
            <ellipse cx="639.28" cy="268.69" rx="39.19" ry="40.55" transform="translate(203.47 808.08) rotate(-73.86)" fill="#b9673b"/>
            <ellipse cx="414.7" cy="201.38" rx="39.19" ry="40.55" transform="translate(105.98 543.75) rotate(-73.86)" fill="#b9673b"/>
            <path d="M426.8,104.17c9.66-23.62,44.66-80.62,151.12-49.81,109,31.54,109.09,92.82,97.66,132.31l-.42-.12L645.29,289.74C625.83,357,553.64,395.18,484.06,375h0c-69.59-20.13-110.22-91-90.76-158.23L426,103.93Z" fill="#b9673b"/>
            <path d="M640.29,234.83C654.36,186.21,652,119.51,543,88c-53.37-15.45-88.77-8.82-112,4.6l2-6.81.84.24C443.4,62.38,478.4,5.38,584.86,36.19,693.86,67.74,694,129,682.52,168.5l-.42-.12-16.49,57Z" fill="#2e1926"/>
            <path d="M638.5,318.13c53-71.82,133.72-209-24.9-281.59C518.91-6.8,358.35,25.18,408.05,299.25c8.82,48.66,143.51,66.52,143.51,66.52S597.17,374.08,638.5,318.13Z" fill="#2e1926"/>
            <path d="M444.23,87.71s-16.79,11.54-19.93,22.4S435-8.28,564.48,23.72L466.74,59.33Z" fill="#2e1926"/>
            <path d="M641.83,228.55c-2.3,3.11-3,7.09-3.37,10.62s-.12,6.94-.1,9.94a27.11,27.11,0,0,1-.58,7.34,6.08,6.08,0,0,1-.89,2,6.9,6.9,0,0,1-1.45,1.58c-4,3.57-9.39,7.12-14.46,12.4-2.45,2.68-5.14,5.94-6.26,10.49a12.68,12.68,0,0,0,.18,6.76,11.79,11.79,0,0,0,3.18,5l2.9-1.41a12.37,12.37,0,0,1,.51-4.16,7.37,7.37,0,0,1,1.74-2.65,29.16,29.16,0,0,1,5.94-4.17c4.87-2.87,10.77-5.72,16.46-10.59a21.59,21.59,0,0,0,4.68-5.78,20.4,20.4,0,0,0,2.19-7.12A29.36,29.36,0,0,0,651.09,247c-2-6.73-5.09-11.49-6.16-17.54Z" fill="#2e1926"/>
            <path d="M564.48,23.72s152,13.72,133.46,144.56L577.06,60.15l-50.2-15.37,5.28-21.17Z" fill="#2e1926"/>
            <g>
              <polygon points="379.89 202.57 683.04 286.15 693.11 251.35 391.07 163.94 379.89 202.57" fill="#7769c7"/>
              <polygon points="539.9 248.87 629.1 274.68 632.92 233.93 551.08 210.25 539.9 248.87" fill="#504595"/>
              <path d="M366.67,197.94l166.22,48.4s7.12-13.52,8.4-18.34,2-20.52,2-20.52l-165.4-48.17Z" fill="#504595"/>
            </g>
            <polygon points="473.3 371.5 470.46 412.46 526.17 419.14 525.97 370.27 473.3 371.5" fill="#b9673b"/>
            <ellipse cx="603.82" cy="1.46" rx="70.91" ry="95.94" transform="translate(434.57 581.08) rotate(-73.86)" fill="#2e1926"/>
          </g>
          <path id="torso" d="M367.46,420.36C340.93,478.46,286.14,631.87,248,732.42,209.62,833.81,789,745.56,789,745.56S683.84,489.68,637.75,416.32C637.75,416.32,451.73,393.09,367.46,420.36Z" fill="#ddf2ed"/>
        </g>
        <g id="smoke-floaters">
          <circle cx="398.59" cy="379.93" r="83.41" fill="#585354"/>
          <circle cx="483.32" cy="318.63" r="59.37" fill="#585354"/>
          <circle cx="469.95" cy="179.42" r="87.05" fill="#585354"/>
          <circle cx="705.77" cy="109.73" r="32.63" fill="#585354"/>
        </g>
        <g id="smoke-fg">
          <circle cx="972.57" cy="634.37" r="102.9" fill="#494849"/>
          <circle cx="67" cy="595" r="93.5" fill="#888"/>
          <circle cx="246" cy="655" r="93.5" fill="#686868"/>
          <circle cx="407" cy="705" r="93.5" fill="#888"/>
          <circle cx="188" cy="513" r="93.5" fill="#9b9b9b"/>
          <circle cx="381" cy="567" r="93.5" fill="#9b9b9b"/>
          <circle cx="787" cy="486" r="93.5" fill="#9b9b9b"/>
          <circle cx="986" cy="551" r="93.5" fill="#9b9b9b"/>
          <circle cx="809" cy="611" r="93.5" fill="#585357"/>
          <circle cx="916.25" cy="591.25" r="108.25" fill="#686868"/>
          <circle cx="710" cy="624" r="93.5" fill="#686868"/>
          <circle cx="544" cy="673" r="93.5" fill="#585357"/>
          <circle cx="899" cy="657" r="93.5" fill="#888"/>
          <circle cx="997" cy="705" r="93.5" fill="#585357"/>
          <circle cx="55.5" cy="687.5" r="104" fill="#585357"/>
        </g>
        <rect id="haze" x="0" y="0" width="1024" height="680" fill="#585357" opacity="0"/>
      </g>
    </svg>
  );
};
