import { useSelector, useDispatch } from 'react-redux';

import 'app/components/date/date-picker/date-picker.scss';

import DateRangeSetter from 'app/components/date/date-picker/date-range-setter/DateRangeSelector';
import triangle_svg from 'app/shared/images/teacher/arrow_down.svg';
import { changeUserDate } from 'app/store/actions/user-settings.actions';
import { shiftWindowBack, shiftWindowForward } from 'app/util/time';
import { sendMessageToVoiceover } from 'app/util/voiceover';

export const DatePicker = () => {

  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector(state => state.userSettings.date);

  const handleDateRangeChange = ({ startDate, endDate }) => {
    sendMessageToVoiceover(`Successfully set new date selection range from ${startDate} to ${endDate}`);
    dispatch(changeUserDate({ startDate, endDate }));
  };

  const handleSingleDateChange = (date) => {
    handleDateRangeChange({ startDate: date, endDate: shiftWindowForward(date) });
  };

  const DateSelectorProps = {
    minDate: new Date(2010, 1, 1),
    maxDate: new Date(),
    months: 1,
    defaultShow: false,
    showTimePicker: false,
    useDropdown: true,
    dateRange: {
      startDate,
      endDate,
    },
    isRangePicker: true,
  };


  const shiftTimeBack = () => {
    dispatch(changeUserDate(shiftWindowBack(startDate, endDate)));
  };

  const shiftTimeForward = () => {
    dispatch(changeUserDate(shiftWindowForward(startDate, endDate)));
  };

  return (
    <div id='datePicker' className='datePicker'>
      <div className="dateField" aria-label="Pick Date Window for Stats">
        {
          startDate && endDate ?
            <div>
              <DateRangeSetter {...DateSelectorProps}
                handleDateRangeChange={handleDateRangeChange}
                handleSingleDateChange={handleSingleDateChange}
              />
            </div>
            :
            <span className='dateText'>No Date Range</span>
        }
      </div>
      <div className="timeShifts">
        <button
          onClick={shiftTimeBack}
        >
          <img src={triangle_svg} alt="Left" aria-label="Move Time Backward" className='leftButton' />
        </button>

        <button
          onClick={shiftTimeForward}
        >
          <img src={triangle_svg} alt="Right" aria-label="Move Time Forward" className='rightButton' />
        </button>
      </div>
    </div>
  );
};
