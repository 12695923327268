import React, { useRef } from 'react';

import clsx from 'clsx';
import { func, string } from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary'; 
import { useNavigate } from 'react-router-dom';

import 'app/components/marginals/mastered-words-wrapper/mastered-words-wrapper.scss';
import { ErrorScreenModal } from 'app/components/error-screen/error-screen-modal.component';
import { MasteredWordsModal } from 'app/components/mastered-words-modal/mastered-words-modal.component';

export const MasteredWordsWrapper = (props) => {
  const { classNames, setIsModalVisible } = props;
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={clsx(classNames, 'mastered-words-modal-wrapper modal', 'fade-in')} ref={modalRef}>
      <ErrorBoundary
        FallbackComponent={ErrorScreenModal} 
        onReset={() => {hideModal(); navigate();} }>
        <MasteredWordsModal classNames={classNames} setIsModalVisible={setIsModalVisible}/>
      </ErrorBoundary>
    </div>

  );
};

MasteredWordsWrapper.propTypes = {
  classNames: string,
  setIsModalVisible: func,
};
