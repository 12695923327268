import { streamDataActions, streamsStaticActions } from 'app/store/actions/streams-data.actions';

export const initialState = {
  streams: null,
};

function streamsDataReducer(state = initialState, action) {
  switch (action.type) {
  case streamDataActions.GET_STREAMS.SUCCESS:
    return {
      ...state,
      streams: action.payload.streams,
    };
  case streamsStaticActions.GET_CKLA_STREAMS:
    return {
      ...state,
      streams: action.payload,
    };

  default:
    return state;
  }
}

export default streamsDataReducer;
