import _ from 'lodash';

import { getStudentId } from 'app/api/params-injectors';
import { academicStream, emptyGrade, emptyStream, standaloneELLStreamPrefix, streamMapping, streamOrderCategories, streamOrders, streamWithGradePrefix, uiStreams } from 'app/constants/streams.constants';

export function getStreamsByGrade(streams, streamGrade) {
  return streams.filter((stream) =>
    stream.grade == streamGrade
  );
}

export function findStreamByStreamName(streams, streamName) {
  return streams.find(stream => stream.name == streamName);
}

export function getGradeFromStream(streams, streamName) {
  return findStreamByStreamName(streams, streamName)?.grade;
}

export function extractStreamFromStreamsByKey(streams, streamKey, byKey = 'key') {
  return streams.find(stream => stream[byKey] == streamKey);
}

export function getStreamsBy(streams, streamName) {
  return streams.find(stream => {
    return getStreamType(stream['name']) == streamName;
  });
}

export function getStreamsForGrade(grade) {
  /*
  @param grade: the grade for which to get streams
  */
  const streams = ['Substantial', 'Core', 'Challenge'];

  return _.chain(uiStreams.map(stream => {
    const newStream = { ...stream };
    if (streams.includes(newStream.uiName)) {
      newStream.grade = grade;
      newStream.name = newStream.name.replace('{}', grade);
    }

    newStream.key = newStream.name;

    return newStream;
  }))
    .sortBy('order')
    .sortBy('grade')
    .uniqBy('uiName')
    .value()
    .filter(function(el) {return el !== undefined;});
}

export const getGradeFromStreamName = function (stream) {
  if (stream) {
    if (stream.startsWith(streamWithGradePrefix))
      return stream.split('_')[1].replace('G', '') + '/';
    else if (stream.startsWith(standaloneELLStreamPrefix))
      return stream.split('_')[1].replace('ELL', '') + '/';
  }
  return stream && stream.startsWith(streamWithGradePrefix) ? stream.split('_')[1].replace('G', '') + '/' : '';
};

export const getGradeFromStandaloneAndStream = function (grade, stream) {
  if (grade == 0) {
    grade = getGradeFromStreamName(stream).replace('/', '');
  }
  return grade;
};

export const getStreamLevel = function (stream) {
  return stream ? stream.substring(stream.lastIndexOf('_') + 1) : stream;
};

export const getStreamName = function (stream) {
  if (stream === undefined || stream === null) {
    return emptyStream;
  }
  return streamMapping[getStreamLevel(stream.name ? stream.name : stream)];
};

export const getStreamNameForPopup = function(stream) {
  if (stream) {
    const streamName = getStreamName(stream);
    if (doesUINameHaveGrade(stream)) {
      if (stream.grade){
        return stream.grade + '/' + streamName;
      } else {
        return getGradeFromStreamName(stream) + streamName;
      }
    }
    return streamName;
  }
};

export const addStreamPopupNameToStudents = function (performanceDict) {
  return Object.entries(performanceDict).reduce((fullDict, [bk, items]) => {
    fullDict[bk] = {
      ...items,
      streamUI: getStreamNameForPopup(items.stream) || 'None',
    };
    return fullDict;
  }, {});
};

export const addGradeFromStreamsToStudents = function (performanceDict, streams) {
  return Object.entries(performanceDict).reduce((fullDict, [bk, items]) => {
    fullDict[bk] = {
      ...items,
      grade: getGradeFromStream(streams, items.stream),
    };
    return fullDict;
  }, {});
};

const getUIStreams = function (streams, grade) {
  return _.chain(streams)
    .map(function (stream) {
      if (((grade === '0' || grade === emptyGrade || !grade) && (stream.grade === 0 ||
        stream.grade == null)) || stream.grade == grade) {
        if (stream.name !== undefined && (stream.grade !== null || stream.order !== null)) {
          return _.extend(stream, { uiName: getStreamName(stream) || 'No Stream Yet' });
        }
      }
    })
    .sortBy('order')
    .sortBy('grade')
    .uniqBy('uiName')
    .value()
    .filter(function (el) { return el !== undefined; });
};

export const getWordsDropdownStreams = function(streams, grade) {
  if (grade === 'all') {
    return [{ value: 'All Streams', key: 'all' }];
  }

  const dropdown = _.map(getUIStreams(streams, grade), function(stream) {
    return {
      value: stream.uiName,
      key: stream.name
    };
  });

  return [{ value: 'All Streams', key: 'all' }].concat(dropdown);
};

const getUIGrades = function (streams, grade) {
  return _.chain(streams)
    .map(function (stream) {
      if (!stream.grade || stream.grade === 0) {
        return emptyGrade;
      }
      if (!grade) {
        return stream.grade;
      } else {
        if (typeof grade === 'string' && grade.slice(0, 1) === '0') {
          grade = grade.slice(1);
        }
        if (Math.abs(stream.grade - grade) === 1) {
          return stream.grade;
        }
      }
    })
    .sortBy()
    .uniq()
    .value()
    .filter(function (el) { return el !== undefined; });
};

export const getDropdownGrades = function(streams) {
  var grades =  _.map(getUIGrades(streams), function(grade) {
    return {
      value: grade,
      key: grade
    };
  });

  return [{ value: 'All Grades', key: 'all' }].concat(grades);
};


export const createStreamName = function (streamLevelName, grade) {
  if (streamOrderCategories.ELL.includes(streamLevelName)) {
    return standaloneELLStreamPrefix + streamOrders[streamLevelName] + '_' + streamLevelName;
  } else if (streamOrderCategories.Grade.includes(streamLevelName)) {
    return streamWithGradePrefix + grade + '_' + streamLevelName;
  } else if (streamOrderCategories.Academic.includes(streamLevelName)) {
    return academicStream;
  }
};

export const makeStandaloneStream = (streamName, grade, studentBK) => {
  const csUserBK = getStudentId(studentBK);

  return {
    csUserBusinessKey: csUserBK,
    curriculumKeyText: null,
    curriculumLesson: null,
    curriculumUnit: null,
    standaloneGrade: grade,
    streamName: streamName,
  };
};

export const getStreamType = (streamName) => {
  return streamName.split('_').pop() || streamName;
};

export const doesUINameHaveGrade = function(streamName) {
  return ['BelowGrade', 'AtGrade', 'AboveGrade'].includes(getStreamType(streamName));
};

export const isProfileEmpty = function (profile) {
  return profile && typeof profile == 'object' && Object.keys(profile).length == 0;
};
