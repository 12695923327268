import React, { useState } from 'react';

import { gsap } from 'gsap';

import { useSceneSettings } from 'app/components/multiple-choice/use-scene-settings.hook';
import { INTRO, FEEDBACK, QUESTION } from 'app/components/syn-ant-activity/constants/animation-steps.constant';
import { CORRECT_CLASS, HIDE_CLASS, INACTIVE_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';
import { PayoffDeer, deerTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-payoff-deer.component'; 
import { PayoffEagle, eagleTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-payoff-eagle.component'; 
import { PayoffLion, lionTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-payoff-lion.component'; 
import { PayoffPanther, pantherTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-payoff-panther.component'; 
import { ConstellationsTransition, planetsTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-transition.component';
import { BoxActive } from 'app/components/syn-ant-activity/constellations/boxes/box-active.component'; 
import { BoxInactive } from 'app/components/syn-ant-activity/constellations/boxes/box-inactive.component'; 
import { infoText } from 'app/components/syn-ant-activity/constellations/constants/info.constant';
import { introText } from 'app/components/syn-ant-activity/constellations/constants/intro.constant';
import { DropzoneActive } from 'app/components/syn-ant-activity/constellations/dropzones/dropzone-active.component'; 
import { DropzoneInactive } from 'app/components/syn-ant-activity/constellations/dropzones/dropzone-inactive.component'; 
import book from 'app/components/syn-ant-activity/constellations/images/book.svg'; 
import iconClose from 'app/components/syn-ant-activity/constellations/images/button_close.svg';
import iconIdea from 'app/components/syn-ant-activity/constellations/images/idea-icon.svg'; 
import star from 'app/components/syn-ant-activity/constellations/images/star-art.svg'; 
import { Intro } from 'app/components/syn-ant-activity/constellations/intro/intro.component';
import { SynAntActivity } from 'app/components/syn-ant-activity/syn-ant-activity.component';
import { SYNONYMS } from 'app/constants/syn-ant.constants';

import 'app/components/syn-ant-activity/constellations/constellations.scss';

export const Constellations = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const { sceneRef } = useSceneSettings();
  const [animationFinished, setAnimationFinished] = useState(false);

  const feedbackTransition = () =>
    <>
      {/* <ConstellationsTransition/> */}
    </>;
  
  const introScreen = () =>
    <>
      <div className="intro-screen animated">
        <Intro/>
      </div>
    </>;

  const articleScreen = () =>
    <>
      <div className="constellations-moon" />
    </>;

  const questionScreen = () =>
    <>
      {/* <Question/> */}
      <ConstellationsTransition/>
      <PayoffDeer />
      <PayoffEagle />
      <PayoffLion />
      <PayoffPanther />
    </>;

  const animations = {
    [INTRO]: () => {
      // Animation in component
    },

    [`${INTRO}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.passage-text', { opacity: 0, display: 'none' })
        .to('.constellations-intro', { opacity: 0, display: 'none' })
        .to('.explanation-next', { opacity: 0, display: 'none' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [QUESTION]: () => {
      const tl = gsap.timeline()
        .from('.question', { duration: 1, opacity: 0, display: 'none' })
        .call(()=> tl.kill());
      tl.play(0);

    },
    [FEEDBACK]: (foundAnswers, wordsCount) => {
      // Add animation here


      const tl = gsap.timeline({ onComplete: () => {
        gsap.to(`.box .${HIDE_CLASS}`, { duration: 0.5, opacity: 0, ease: 'sine.inOut' });

        const randomAnimal = Math.floor(Math.random() * 2);
        if (foundAnswers && foundAnswers.length === 4 && wordsCount === 8) {
          gsap.to('.activity-word, .dropzones, .boxes', { duration: 0.5, opacity: 0, ease: 'sine.inOut' });
          if (randomAnimal <= 1) {
            gsap.to('#payoff-deer', { duration: 0.5, display: 'block', ease: 'sine.inOut', onComplete: () => {
              deerTimeline.play(0);
            } });            
          } else if (randomAnimal <= 2) {
            gsap.to('#payoff-eagle', { duration: 0.5, display: 'block', ease: 'sine.inOut', onComplete: () => {
              eagleTimeline.play(0);
            } });            
          }
        }
        if (foundAnswers && foundAnswers.length === 3 && wordsCount === 6) { 
          gsap.to('.activity-word, .dropzones, .boxes', { duration: 0.5, opacity: 0, ease: 'sine.inOut' });
          if (randomAnimal <= 1) {
            gsap.to('#payoff-lion', { duration: 0.5, display: 'block', ease: 'sine.inOut', onComplete: () => {
              lionTimeline.play(0);
            } });            
          } else if (randomAnimal <= 2) {
            gsap.to('#payoff-panther', { duration: 0.5, display: 'block', ease: 'sine.inOut', onComplete: () => {
              pantherTimeline.play(0);
            } });
          }
        }
      }, })
        .to('#constellations-transition', { duration: 0.1, display: 'block', onComplete: () => {
          planetsTimeline.play(0);
        } })
        .add(gsap.to(`.${HIDE_CLASS}.word`, { duration: 0.3, backgroundColor: '#B6F4FF', ease: 'sine.inOut' }), 1.5)
        .add(gsap.to(`.${HIDE_CLASS} .starshape`, { duration: 0.3, fill: '#B6F4FF', ease: 'sine.inOut' }), 1.5)
        .add(gsap.to(`.${CORRECT_CLASS} .star-solid`, { duration: 0.3, opacity: 1, ease: 'sine.inOut' }), 1.5)
        .add(gsap.to(`.${CORRECT_CLASS} .star-dashed`, { duration: 0.3, opacity: 0, ease: 'sine.inOut' }), 1.5)
        .add(gsap.to(`.${INACTIVE_CLASS}`, { duration: 0.3, opacity: '0', ease: 'sine.inOut' }), 1.5)
        .add(gsap.to(`.${CORRECT_CLASS} #star-vibration`, { duration: 0.1, opacity: 1, ease: 'sine.inOut' }), 3)
        .add(gsap.to(`.${CORRECT_CLASS} .vibe1, .${CORRECT_CLASS} .vibe2`, { duration: 0.5, transformOrigin: '50% 50%', scale: 1.5, opacity: 0, stagger: 0.15, ease: 'sine.inOut' }), 3.1)
        .call(()=> {
          tl.kill();
        });
      tl.play(0);
    }, 
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <SynAntActivity
      ref={sceneRef}
      activityId={activityId}
      activityType={SYNONYMS}
      activityClassName="constellations"
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      onFinished={onFinished}
      FeedbackTransition={feedbackTransition}
      ArticleScreen={articleScreen}
      QuestionScreen={questionScreen}
      BoxActive={BoxActive}
      BoxInactive={BoxInactive}
      boxPreview={star}
      DropzoneActive={DropzoneActive}
      DropzoneInactive={DropzoneInactive}
      IntroScreen={introScreen}
      animate={animations}
      introText={introText}
      infoText={infoText}
      bookIcon={book}
      iconIdea={iconIdea}
      iconClose={iconClose}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
