/* eslint-disable jsx-a11y/aria-role */
import { Buffer } from 'buffer';

import React, { useEffect, useLayoutEffect, useRef, useState, forwardRef } from 'react';

import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SynAntDevTools from 'app/components/devtools/syn-ant/syn-ant-dev.component';
import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import { Loader } from 'app/components/loader/loader';
import { INTRO, FEEDBACK, QUESTION } from 'app/components/syn-ant-activity/constants/animation-steps.constant';
import { Container } from 'app/components/syn-ant-activity/dnd/container.component';
import backButton from 'app/components/syn-ant-activity/images/button_back.svg';
import { WordsModal } from 'app/components/syn-ant-activity/modal/words-modal.component';
import routesConstants from 'app/constants/routes.constant';
import { ANSWERS, SYNONYMS, ANTONYMS } from 'app/constants/syn-ant.constants';
import { SessionStorageService } from 'app/services/session-storage.service';
import closeButton from 'app/shared/images/teacher/x-button.svg';
import { activityDataActionCreators } from 'app/store/actions/activity-data.actions';

import 'app/components/syn-ant-activity/syn-ant-activity.scss';

export const SynAntActivity = forwardRef((props, forwardedRef) => {

  const { activityType, activityClassName, animate, clearAnimationFinished, animationFinished, 
    IntroScreen, QuestionScreen, FeedbackTransition, boxPreview, BoxActive, BoxInactive, 
    DropzoneInactive, DropzoneActive, introText, infoText, bookIcon, iconIdea, iconClose, removeCurrentActivity, onFinished } = props;

  const navigate = useNavigate();
  const storedStep = useRef();
  const dispatch = useDispatch();
  const { id: activityId } = useParams();

  const containerRef = useRef();
  const feedbackText = useRef();
  const teacherDemoSettings = SessionStorageService.getTeacherDoingActivity();
  const [dateStarted, setDateStarted] = useState(null);
  const [resultReported, setResultReported] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [foundAnswers, setFoundAnswers] = useState(true);
  const [step, setStep] = useState(null);
  const [currentActivity, setCurrentActivity]  = useState(null);
  const { activity, completedActivities }  = useSelector(state =>  state.activityData);
  const { kcInfo } = useSelector(state => state.userData);

  const backBtnbg = {
    backgroundImage: `url(${backButton})`
  };

  const backBtnTeacherbg = {
    backgroundImage: `url(${closeButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const goHome = () => {
    navigate(routesConstants.HOME, { replace: true });
  };

  const goToTeacher = () => {
    navigate(routesConstants.CLASSES, { replace : true });
  };

  const setActivityData = () => {
    const obj = Object.assign({}, activity);
    if(obj.distractors) { 
      const distractors = obj.distractors
        .sort(() => {return 0.5 - Math.random();})
        .slice(1, obj[activityType].length + 1)
        .map(item => 
        {
          item.word = Buffer.from(item.word, 'base64').toString(); 
          return item;
        });
      const correctWords = obj[activityType].map(item => 
      {
        item.word = Buffer.from(item.word, 'base64').toString(); 
        return item;
      });
      const words = shuffleArray(correctWords.concat(distractors));

      setCurrentActivity({
        ...obj,
        distractors,
        correctWords,
        words,
      });
    }
    
  };

  const shuffleArray = (array) => {
    let currentIndex = array.length,  randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  const createMarkup = (string) => {
    return { __html: string };
  };

  const next = (newStep) => {
    if(animate[`${step}To${newStep}`]) {
      animate[`${step}To${newStep}`]();
      storedStep.current = newStep;
    } else {
      setStep(newStep);
    }
  };

  useLayoutEffect (() => {
    if(step === INTRO) {
      setTimeout(animate[step], 1000);
    } if(step === FEEDBACK) {
      setTimeout(animate[step](foundAnswers, currentActivity.words.length), 200);
    } else if (step) {
      animate[step]();
    }
  }, [step]);

  useEffect(() => {
    if (activityId && (activityType == SYNONYMS || activityType == ANTONYMS)) dispatch(activityDataActionCreators.getActivity.request(`SAactivity/activityJSON/${activityId}`));

    return () => {
      setStep(null);
      storedStep.current = null;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setStep(null);
    setIsModalVisible(false);
    setCurrentActivity({});
    setDateStarted(null);
    setResultReported(false);
    if (activityId && (activityType == SYNONYMS || activityType == ANTONYMS)) dispatch(activityDataActionCreators.getActivity.request(`SAactivity/activityJSON/${activityId}`));
  }, [activityId]);

  useLayoutEffect(() => {
    if(storedStep.current) {
      setStep(storedStep.current);
      storedStep.current = null;
      clearAnimationFinished();
    }
  }, [animationFinished]); 

  useEffect(() => {
    if(activity && activity.id) {
      setActivityData();

      next(INTRO);
      setLoading(false);
      setDateStarted(new Date());
    }
  }, [activity]);

  useEffect(() => {
    setResultReported(!!completedActivities?.filter(id => parseInt(id) === parseInt(activityId)));
  }, [completedActivities]);

  const handleFeedback = (data) => {
    if(data && data.answers) {
      const answers = data.answers;
      const options = data.options;
      const foundWords = checkAnswers(answers);
      const notFoundWords = checkAnswers(options);
      containerRef.current.showFeedback(foundWords, notFoundWords);
      setStep(FEEDBACK);
      setFoundAnswers(checkAnswers(answers));

      if (!teacherDemoSettings)
        sendActivityObservation(foundWords.length / answers.length);

      // Remove activity from pending
      removeCurrentActivity(dispatch);

      if (currentActivity.words.length === 8 && foundWords.length === 4 ||
        currentActivity.words.length === 6 && foundWords.length === 3) {
        feedbackText.current = `Amazing! You found all of the ${activityType}!`;
      } else if (foundWords.length === 3 || foundWords.length === 2) {
        feedbackText.current = `Good! You got ${foundWords.length} out of ${answers.length} correct.`;
      } else if (foundWords.length === 1) {
        feedbackText.current = `You got ${foundWords.length} out of ${answers.length} correct.`;
      } else {
        feedbackText.current = `Good try. These are the correct ${activityType}.`;
      }
    }
  };

  const checkAnswers = (array, isCorrect = true) => {
    return array.filter(item => currentActivity.correctWords.find(word => {
      if(isCorrect) {
        return item.id == word.id;
      }
      return item.id !== word.id;
    }));
  };

  const sendActivityObservation = (score) => {
    const activityObservation = {
      activityId: currentActivity.id,
      activityTypeId: currentActivity.activityType.id,
      csLearningObjectBusinessKey: null,
      csLessonBusinessKey: null,
      csUserBusinessKey: kcInfo.sub,
      interactions: [],
      questId: SessionStorageService.getQuestId(),
      score: score,
      status: score >= 0.75 ? 'PASSED' : 'FAILED',
      timeSpent: new Date().getTime() - dateStarted.getTime(),
      wordId: currentActivity.word.id,
    };
    dispatch(activityDataActionCreators.setActivityObservation.request(activityObservation));
  };

  const submitAnswers = () => {
    containerRef.current.setUpData();
  };

  const checkIfAnswered = (listType, dropzones) => {
    let answered = false;
    if(listType === ANSWERS) {
      answered = dropzones.filter(item => item.id).length === dropzones.length;
    } else {
      const emptyCount = dropzones.filter(item => !item.id).length;
      answered = emptyCount === currentActivity.correctWords.length && emptyCount > 0;
    }

    setCanSubmit(answered);
  };

  return (
    !loading ?
      <ErrorBoundary
        FallbackComponent={ErrorScreen}
      >
        <SynAntDevTools
          sendActivityObservation={sendActivityObservation}
          onFinished={onFinished}
          activityId={activityId}
          navigate={navigate}
          currentActivity={currentActivity}
          removeCurrentActivity={removeCurrentActivity}
        />
        <div className={clsx('syn-ant', activityClassName, currentActivity && currentActivity.words && currentActivity.words.length === 8 ? 'activity-eight-options' : '')}  ref={forwardedRef}>
          <div className="container">
            {
              teacherDemoSettings ?
                <button style={backBtnTeacherbg} onClick={goToTeacher} aria-label="Go back to Teacher screen" className="back-button" />
                :
                <button style={backBtnbg} onClick={goHome} aria-label="Go back to Home screen" className="back-button" />
            }
            <button style={{ backgroundImage: `url(${bookIcon})` }}  disabled={step === INTRO} onClick={() => {setIsModalVisible(true);}}
              aria-label="Show words explanation" className="book" />
            {isModalVisible && <WordsModal setIsModalVisible={setIsModalVisible} activity={currentActivity} iconIdea={iconIdea} closeButton={iconClose}/>}
            {step === INTRO &&
              <>
                <IntroScreen />
                <p className="passage-text"><span role="text" dangerouslySetInnerHTML={createMarkup(introText)}/></p>
                <div className="explanation-next">
                  <button onClick={() => { next(QUESTION); }} aria-label="Next Card" className="button next-button">Next</button>
                </div>
              </>
            }
            { (step === QUESTION || step === FEEDBACK) &&
              <>
                <div className="activity-card question">
                  <QuestionScreen />
                  <p className="info-text">

                    <img className="info-icon" src={iconIdea} alt="" aria-hidden="true" />
                    { step === QUESTION && <span role="text" dangerouslySetInnerHTML={createMarkup(infoText)}/> }
                    { step === FEEDBACK && <span role="text" dangerouslySetInnerHTML={createMarkup(feedbackText.current)}/> }
                  </p>
                  <div className="activity-word">
                    {activity.word.word}
                  </div>
                  <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }} >
                    <Container 
                      ref={containerRef}
                      boxPreview={boxPreview}
                      BoxActive={BoxActive}
                      BoxInactive={BoxInactive}
                      DropzoneInactive={DropzoneInactive}
                      DropzoneActive={DropzoneActive}
                      words={currentActivity.words}
                      type={activityType}
                      correctWords={currentActivity.correctWords}
                      getFinishedData={handleFeedback}
                      checkIfAnswered={checkIfAnswered}
                    />
                  </DndProvider>
                  { step === QUESTION && <button onClick={() => { submitAnswers(); }} aria-label="Submit Answers" disabled={!canSubmit} className="button next-button">Submit</button>}
                  { step === FEEDBACK && 
                    <div className="feedback-next-container">
                      {resultReported ? <button onClick={() => { onFinished(navigate, dispatch); }} aria-label="Next Activity" className="button feedback-next-button next-button">Next</button> : null}
                    </div>
                  }
                </div>
                { step === FEEDBACK && <FeedbackTransition /> }
              </>
            }
          </div>

        </div>
      </ErrorBoundary>
      :
      <Loader />
  );
});

SynAntActivity.displayName = 'SynAntActivity';
