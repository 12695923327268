import { userSettingsActions } from 'app/store/actions/user-settings.actions';

export const initialState = {
  sound: {
    on: true,
  },
  date: {
    startDate: null,
    endDate: null,
  },
  teacherSettings: {
    optionDisplayed: false,
    open: false,
  },
  displayHeaderBackButton: false,
};

function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
  case userSettingsActions.TOGGLE_USER_SOUND:
    return {
      ...state,
      sound: {
        ...state.sound,
        on: !state.sound.on,
      },
    };
  case userSettingsActions.SET_USER_DATE:
    return {
      ...state,
      date: {
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      },
    };
  case userSettingsActions.SET_DISPLAY_BACK_BUTTON:
    return {
      ...state,
      displayHeaderBackButton: action.payload.buttonState,
    };
  case userSettingsActions.TOGGLE_SETTINGS:
    return {
      ...state,
      teacherSettings: {
        ...state.teacherSettings,
        open: !state.teacherSettings.open,
      },
    };
  case userSettingsActions.SET_DISPLAY_GEAR_ICON:
    return {
      ...state,
      teacherSettings: {
        ...state.teacherSettings,
        optionDisplayed: action.payload.displayed,
      },
    };
  default:
    return state;
  }
}

export default userSettingsReducer;
