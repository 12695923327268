import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import iconCorrect from 'app/shared/images/icon-correct.svg';
import iconIncorrect from 'app/shared/images/icon-incorrect.svg';
import { useHighlightWord } from 'app/util/word';
import 'app/components/feedback/feedback.scss';

export const Feedback = ({ answer, word, correctFeedback }) => {
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    if(correctFeedback || answer.feedback) {
      const str = Buffer.from(correctFeedback || answer.feedback, 'base64').toString();
      const newFeedback = str.replace(/[O, o]ption [a-z, A-Z] is [in]*correct[.!,\\-]/gm, '');
      setFeedback(useHighlightWord(word, newFeedback));
    }
  }, []);

  const createMarkup = (string) => {
    return { __html: string };
  };

  return (
    <div id={`feedback-${answer.isCorrect ? 'correct' : 'incorrect'}`} className={`feedback ${clsx({ correct: answer.isCorrect, incorrect: !answer.isCorrect })}`}>
      <div className="feedback-icon">
        <img src={answer.isCorrect ? iconCorrect : iconIncorrect} aria-hidden="true" alt="" />
      </div>
      <div className="feedback-container">
        <h1 className="feedback-title">Option {answer.label} is {answer.isCorrect ? 'correct' : 'incorrect'}</h1>
        <p className="feedback-text" dangerouslySetInnerHTML={createMarkup(feedback)} />
      </div>
    </div>
  );
};
