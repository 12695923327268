import React, { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setBatchNumber, setShowWords, wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { getStreamNameForPopup } from 'app/util/streams';
import { getNextBatchOfWords, getUpTo } from 'app/util/words';

import 'app/pages/words/components/words/noncore/nonCore.table.scss';

export const NonCoreTable = (props) => {
  const dispatch = useDispatch();

  const { scrollPercentShowMore, batchAmount } = props;

  const { getWordsByStreamStatus } = useSelector(state => state.apiReducer);

  const { words, showWords, batchNumber, filterMode } = useSelector(state => state.wordsData);
  const { isCore } = useSelector(state => state.wordsData.filters);

  const bodyRef = useRef();


  const handleScroll = (e) => {
    if (filterMode)
      return;

    const height = e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
    const scrollPercent = (e.currentTarget.scrollTop / height);

    if (showWords) {
      // We don't do +1 for get up to so we get last full batch
      if(getUpTo(batchNumber, batchAmount) < words.length && scrollPercent > scrollPercentShowMore) {
        dispatch(setShowWords(getNextBatchOfWords(words, batchNumber + 1, batchAmount)));
        dispatch(setBatchNumber(batchNumber + 1));
      }
    }
  };

  useEffect(() => {
    // First Time or Filter Changed
    if (words && isSuccess(getWordsByStreamStatus)) {
      dispatch(setShowWords(getNextBatchOfWords(words, 1, batchAmount)));
      dispatch(setBatchNumber(1));

      if (showWords) {
        bodyRef.current.scrollTo({
          top: 0,
          behavior: 'auto',
        });
      }
    }
  }, [getWordsByStreamStatus, words, batchAmount]);


  const handleShowWord = (e, wordID) => {
    e.preventDefault();
    dispatch(wordsDataActionCreators.getWordData.request(wordID));
  };

  return (
    <div className='table'>
      <table
        role="grid"
        aria-colcount="8"
        aria-describedby="students-table-description"
        summary='Table of words. Interact with row or element to select the student in the row for viewing.'
      >
        <thead>
          <tr className='streamAbove'>
            <th className="wordRow align-center" id="striving-head-description" scope="col" tabIndex="0">
              WORD PROPERTIES
            </th>
            <th className="noGradeRow align-center" id="core-head-description" scope="col" tabIndex="0">
              STANDALONE NO GRADE
            </th>
            <th className="gradeRow align-center" id="challenge-head-description" scope="col" tabIndex="0">
              STANDALONE STREAMS
            </th>
          </tr>
          <tr className="headerRow">
            <th className="word align-center" id="name-header" scope="col" tabIndex="0">
              WORD
            </th>
            <th className="pos align-center" id="partOfSpeech-header" scope="col" tabIndex="0">
              PART OF SPEECH
            </th>
            <th className="pos align-center" id="wordStreams-header" scope="col" tabIndex="0">
              STREAMS
            </th>
            <th className="academic align-center" id="academic-header" scope="col" tabIndex="0">
              ACADEMIC
            </th>
            <th className="el align-center" id="el-header" scope="col" tabIndex="0">
              EL
            </th>
            <th className="striving align-center" id="striving-header" scope="col" tabIndex="0">
              STRIVING
            </th>
            <th className="core align-center" id="core-header" scope="col" tabIndex="0">
              CORE
            </th>
            <th className="challgen align-center" id="challenge-header" scope="col" tabIndex="0">
              CHALLENGE
            </th>
          </tr>
        </thead>
        <tbody onScroll={handleScroll} ref={bodyRef} className={!isCore && isSuccess(getWordsByStreamStatus) && showWords ? '' : 'loading'}>
          {
            !isCore && isSuccess(getWordsByStreamStatus) && showWords ?
              showWords.map(word => {
                return (
                  <tr
                    key={`${word.id}-row`}
                    className='listItem'
                    onClick={e => handleShowWord(e, word.id)}
                  >
                    <td
                      key={`${word.id}-name`}
                      className="name align-left"
                      id={`word-${word.id}`}
                    >
                      {word.word}
                    </td>
                    <td
                      key={`${word.id}-partOfSpeech`}
                      className="partOfSpeech align-left"
                      id={`pos-${word.id}`}
                    >
                      {word.pos}
                    </td>
                    <td
                      key={`${word.id}-wordStreams`}
                      className="wordStreams align-left"
                      id={`wordStreams-${word.id}`}
                    >
                      {word.wordStreams ? word.wordStreams.split(',').map(stream => getStreamNameForPopup(stream)).join(', ') : '‎'}
                    </td>
                    <td
                      key={`${word.id}-academic`}
                      className="stream align-center"
                      id={`academic-${word.id}`}
                    >
                      {word.academic ? '\u25CF' : '‎'}
                    </td>
                    <td
                      key={`${word.id}-ell`}
                      className="stream align-center"
                      id={`el-${word.id}`}
                    >
                      {word.ell ? '\u25CF' : '‎'}
                    </td>
                    <td
                      key={`${word.id}-striving`}
                      className="stream align-center"
                      id={`striving-${word.id}`}
                    >
                      {word.belowGrade ? '\u25CF' : '‎'}
                    </td>
                    <td
                      key={`${word.id}-core`}
                      className="stream align-center"
                      id={`core-${word.id}`}
                    >
                      {word.atGrade ? '\u25CF' : '‎'}
                    </td>
                    <td
                      key={`${word.id}-challenge`}
                      className="stream align-center"
                      id={`challenge-${word.id}`}
                    >
                      {word.aboveGrade ? '\u25CF' : '‎'}
                    </td>
                  </tr>
                );
              })
              :
              <></>
          }
        </tbody>
      </table>
    </div>
  );
};
