import React from 'react';

import 'app/components/helper/helper-message/helper-message.scss';

export const HelperMessage = ({ messagesToShow, hideMessage }) => {
  return (
    <div className='messages'>
      {
        messagesToShow && messagesToShow.map((msg, index) => {
          return (
            <button
              key={`message-${index}`}
              id="message"
              className="message"
              onClick={() => hideMessage(msg)}
            >
              <h1>{msg}</h1>
            </button>
          );
        })
      }
    </div>
  );
};