import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const DoctorTransition = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .set('#bones > path', { rotate: -10, y:-830 })
      .to('#bones', { duration: 0.1, opacity: 1, delay: 0.1, ease: 'sine.inOut' })
      .to('#bones > path', { duration: 0.5, rotate: 0, y: 0, stagger: 0.05, delay: 0.3, ease: 'sine.in' })
      .add(gsap.to('#darken', { duration: 2, opacity: 0.9, ease: 'sine.inOut' }), 1)
      .to('#bones > path', { duration: 0.25, rotate: 10, y: 830, stagger: 0.01, ease: 'sine.in' })
      .add(gsap.to('#darken', { duration: 2, opacity: 0, ease: 'sine.inOut' }), 4);

    tl.play();
  });

  return (
    <svg id="bones-animation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <rect id="darken" width="1024" height="680" fill="#2d2d2d" opacity="0"/>
      <g id="bones" opacity="0">
        <path d="M828,666.51c1.64,6.66,10.36,6.74,14.89,6.27,5.11-.53,11.14,1.3,15.56,3.06a25.59,25.59,0,0,1,7,4c2.21,2,3.09,5.41,5.62,6.87a6.34,6.34,0,0,0,.53-.87,13.16,13.16,0,0,0,8.06,8.21c3.53-4.48-2.44-7.9.16-10.61,2-2.06,8.37-.07,10.54.29,3.73.64,9,1.93,12.34-.77,4.11-3.33,3.56-6.32.09-8.32a11.23,11.23,0,0,0-3.22-1.18,10.18,10.18,0,0,1-10.94.24,10.58,10.58,0,0,1-4.59-6.17c-.36-.29-.74-.56-1.16-.84-.69-.5-1.25-.92-1.72-1.25a10.15,10.15,0,0,1-.88,2.37,10.25,10.25,0,0,1-4.16,4.31l0-.56h0c0-.11,0-.22,0-.33a.41.41,0,0,1,0-.11c0-.1,0-.2,0-.31a.43.43,0,0,1,0-.11l.09-.55a.83.83,0,0,0,0-.15,2,2,0,0,0,0-.24,1.41,1.41,0,0,0,.06-.22l.06-.21c0-.09,0-.17.07-.26l.06-.19.14-.42a.75.75,0,0,0,.06-.13l.12-.33a1,1,0,0,0,.07-.15c0-.11.09-.2.13-.31l.06-.14.21-.44.29-.51c.08-.18.16-.35.25-.53s.11-.18.18-.27c.41-.63.81-1.26,1.17-1.93a9.67,9.67,0,0,1-.4-1.41l-.15-1.87a39.93,39.93,0,0,0-4.54-4.56c-4.08-3.68-27-17.72-30.67-9.44l0,0c-3.3.78-6.19,3.15-7.88,8.24,1.07.13,2.3,1.37,3.45,1.38C838.4,663.88,825.87,658,828,666.51Z" fill="#fff"/>
        <path d="M761.8,586.94l-33.42,46.61s.76,93.24,45.63,106,58.89-80.75,64.5-97.49S876.52,586.94,761.8,586.94Z" fill="#fff"/>
        <path d="M655.39,599.11a21,21,0,0,0-5.21-8.76,19.29,19.29,0,0,0-13.89-5.91c-3.51-1.08-6.84-.57-9.17,3.1a14.23,14.23,0,0,0-1.79,1.61c-6.41,6.73-5.66,18.19,1.67,25.58l.16.15.35.35c6.14,5.8,14.72,7,21,3.34,13.87,10.83,45.89,36.83,48.23,47.94.52,2.47,2.21,5.24,4.53,7.94a14.28,14.28,0,0,0,4.09,12.23c5.23,5.28,13.2,5.63,17.81.78a8.83,8.83,0,0,0,.68-.81,3,3,0,0,0,1.17-1.83c2.46-4.84,1.4-11.29-3-15.7a14.64,14.64,0,0,0-4.32-3C697.34,633,664.08,605.87,655.39,599.11Z" fill="#fff"/>
        <path d="M645.36,652.87C636.68,687.12,612.07,660,621,627.8c4.87-17.48,18.37,2.86,25.86,13.81.43,4-.21,6.21-.69,9.53" fill="#fff"/>
        <path d="M908.7,721.75A17.94,17.94,0,0,0,915.52,735a.77.77,0,0,0,.12.45c1.12,3.07,9.14,2.77,17.87-.63,7.33-2.87,12.85-7,13.76-10.1.35-.13.66-.28,1-.42,2.65,1.69,9.47,1,16.85-1.83,6.51-2.53,11.57-6.11,13.26-9a.23.23,0,0,0,.14,0,23.41,23.41,0,0,0,2-10.36c0-.13,0-.22,0-.32a3.72,3.72,0,0,0-.08-.67c-1.14-11.84-12.87-18.86-28-17.83-3.15.07-3.81-6.73-4.06-11.71l-24.06-65.45c-3.41-5.19-4.78-10.82-2.08-18.12,2.19-5.88,7.09-13.2,6.65-19.13,0-.15-.22-.2-.64-.16l-.07,0c-1.58-2.72-9.61-2.22-18.32,1.2-9,3.5-15.43,8.83-14.4,11.9h0c-1.16-3-9.43-2.63-18.46.92s-15.5,8.9-14.38,12a3,3,0,0,0,1.85,1.58.62.62,0,0,0-.24.1c2.18,5,6.64,8.48,12.76,10.19,1.1,2.28,11.09,28.14,19.48,49,7.08,21.36,16.25,47.54,16.9,50C910.17,711.41,908.44,716.68,908.7,721.75Z" fill="#fff"/>
        <path d="M507.12,639.4a19.81,19.81,0,0,0-9.69-2.41,19.25,19.25,0,0,0-13.88,5.91c-3.23,1.81-5.24,4.59-4.37,8.87a14.21,14.21,0,0,0-.17,2.45c.07,9.43,8.45,17,18.7,16.9h.22a3.62,3.62,0,0,0,.47,0c8.34-.35,15.24-5.75,17.19-12.91,17.24-2.43,57.72-7.31,67-1.15,2.06,1.37,5.16,2.1,8.65,2.33a13.48,13.48,0,0,0,11.28,5.68c7.32-.07,13.2-5.62,13.14-12.41a9.59,9.59,0,0,0-.06-1.07,3,3,0,0,0-.44-2.14,13.1,13.1,0,0,0-12.85-9,14,14,0,0,0-5.13,1C560,632.83,517.91,637.87,507.12,639.4Z" fill="#fff"/>
        <path d="M431.59,665.89a10.78,10.78,0,0,0-7.4-.43,2,2,0,0,0-.23-.06c-1.61-.26-3.38,2.53-4,6.22-.49,3.09,0,5.84,1.14,6.79,0,.14-.05.29-.07.44-1.36.55-2.69,3-3.19,6.11-.43,2.75-.08,5.22.79,6.41a.06.06,0,0,1,0,0,12.18,12.18,0,0,0,4,2.84.59.59,0,0,1,.14.06l.31.12c5.43,2.05,11.28-.47,14.44-5.86.71-1.09,3.83.1,6.06,1l34.26,5.4a10.92,10.92,0,0,1,8.41,3.06c2.06,2,4.08,5.15,6.77,6.23.07,0,.14,0,.21-.17l0,0c1.55,0,3.23-2.81,3.82-6.49s-.2-7.1-1.79-7.38h0c1.6.22,3.38-2.66,4-6.47s-.21-7.14-1.81-7.39a1.59,1.59,0,0,0-1.12.29s0-.06,0-.09a10.11,10.11,0,0,0-7.47,2.22c-1.25-.09-14.9-2.07-26-3.53-11-2-24.6-4.34-25.81-4.64A13,13,0,0,0,431.59,665.89Z" fill="#fff"/>
        <path d="M261.25,653.48c3.15,12.85,20,13,28.74,12.11,9.87-1,21.52,2.5,30.05,5.9,4.38,1.76,9.92,4.48,13.47,7.66,4.26,3.82,6,10.44,10.86,13.26a13.23,13.23,0,0,0,1-1.68c2.18,7,7.52,12.62,15.56,15.85,6.82-8.65-4.71-15.26.32-20.48,3.82-4,16.17-.15,20.36.56,7.21,1.24,17.38,3.71,23.84-1.49,7.94-6.43,6.87-12.21.16-16.07a21.18,21.18,0,0,0-6.21-2.28,19.66,19.66,0,0,1-21.13.45,20.36,20.36,0,0,1-8.87-11.91c-.7-.54-1.43-1.07-2.23-1.62-1.35-1-2.43-1.78-3.33-2.41a20.29,20.29,0,0,1-1.7,4.58,20,20,0,0,1-8,8.32c0-.35,0-.72.06-1.07,0,0,0,0,0,0,0-.21.05-.42.08-.62a.71.71,0,0,1,0-.21c0-.21,0-.4.07-.61a1.52,1.52,0,0,0,0-.21c.07-.36.13-.71.19-1.06a2.45,2.45,0,0,0,.08-.29c0-.16.07-.31.1-.47s.07-.27.11-.42.07-.28.12-.41a4.07,4.07,0,0,1,.13-.5c0-.12.08-.24.11-.36.1-.28.18-.55.28-.82a2.6,2.6,0,0,0,.1-.25c.08-.21.16-.41.24-.64A2.55,2.55,0,0,0,356,656c.08-.2.17-.39.25-.6s.08-.18.13-.26c.13-.29.26-.57.4-.85s.36-.67.56-1,.31-.68.48-1l.34-.52c.79-1.21,1.57-2.44,2.27-3.73a18.47,18.47,0,0,1-.77-2.73l-.3-3.61a76.81,76.81,0,0,0-8.76-8.81c-7.89-7.1-52.21-34.22-59.24-18.23l.07,0c-6.37,1.52-11.95,6.1-15.21,15.93,2.07.25,4.44,2.65,6.66,2.66C281.42,648.39,257.22,637.05,261.25,653.48Z" fill="#fff"/>
        <path d="M143.18,661.64a13.64,13.64,0,0,0-3.67-7.21,13.47,13.47,0,0,0-11.68-3.68c-3-.55-6,.28-8.45,3.82a17,17,0,0,0-1.77,1.64c-6.4,6.73-7,16.85-1.28,22.6l.13.12a3.31,3.31,0,0,0,.27.26c4.82,4.47,12.3,4.56,18.27.58,11.13,8.06,36.7,27.55,37.58,37.18a12.7,12.7,0,0,0,3.16,6.56,10.58,10.58,0,0,0,2.31,10.42c4.06,4.11,11.1,3.51,15.7-1.34.24-.25.48-.52.7-.8a3.68,3.68,0,0,0,1.22-1.76c2.71-4.6,2.46-10.22-.93-13.65a9.25,9.25,0,0,0-3.51-2.2C176.73,687,150.16,666.67,143.18,661.64Z" fill="#fff"/>
        <path d="M283.62,693.25c-8.69,34.25-33.29,7.16-24.31-25.08,4.87-17.48,18.36,2.87,25.86,13.83.41,4-.21,6.21-.69,9.51" fill="#fff"/>
        <path d="M237,715.06l19-26.46s-.43-52.94-25.91-60.16-33.44,45.85-36.62,55.35S171.9,715.06,237,715.06Z" fill="#fff"/>
        <path d="M69.53,665.36c-17.94,35.9-81.3-20.57-62.44-53.63,10.24-17.93,45.53,20,65.11,40.67,1.44,5.45.11,7.73-.73,11.54" fill="#fff"/>
        <path d="M1.67,697.21c-2.84-40,80.44-23.68,81,14.38C83,732.23,33.32,717.53,6,709.72c-4-4-4-6.59-5.25-10.29" fill="#fff"/>
        <path d="M955,689.27c8,3.27,14.72-6.14,17.67-11.41,3.33-6,9.75-11.12,14.9-14.58a32.53,32.53,0,0,1,9.33-4.58c3.69-.91,7.86.74,11.26-.9a7,7,0,0,0-.48-1.24c4.54,1.51,9.63.71,14.49-2.53-1.91-7.23-9.93-3.33-10.72-8.21-.6-3.71,6.26-9.15,8.29-11.23,3.47-3.57,8.77-8.32,8.55-14-.28-7-3.77-8.65-8.44-6.39a14.72,14.72,0,0,0-3.64,2.59,14,14,0,0,1-8,12.07,13,13,0,0,1-9.79.32c-.55.18-1.11.38-1.72.61-1,.39-1.89.68-2.58.93a13.59,13.59,0,0,1,1.76,2.75,14.18,14.18,0,0,1,1.25,7.78,6,6,0,0,1-.54-.46h0l-.32-.29-.1-.09-.28-.28a.52.52,0,0,1-.1-.11c-.17-.17-.33-.34-.49-.52l-.13-.15-.2-.24-.18-.23a2,2,0,0,1-.18-.23l-.21-.27a1.43,1.43,0,0,0-.14-.2l-.33-.48-.09-.15c-.08-.13-.16-.25-.23-.39a.71.71,0,0,1-.12-.18l-.21-.38-.09-.18c-.1-.19-.2-.37-.29-.56s-.21-.46-.31-.69-.24-.45-.35-.68-.09-.26-.14-.39c-.32-.93-.68-1.84-1.08-2.74a11.64,11.64,0,0,1-1.76-.63l-2-1.26A49.85,49.85,0,0,0,979,631c-6.85,1.65-38.56,15.94-32.87,26.16l.07,0c-1.69,4.18-1.46,9.13,2.46,14.81.95-1.07,3.15-1.46,4-2.71C960.16,675.93,944.67,685.09,955,689.27Z" fill="#fff"/>
        <path d="M445.93,631.47a20.36,20.36,0,0,0-10.08-.25,20.62,20.62,0,0,0-12.78,8.7c-2.89,2.42-4.4,5.55-2.77,9.5a14,14,0,0,0,.27,2.4c1.76,9.08,11.45,14.59,21.65,12.29l.22-.05c.16,0,.32-.07.47-.12,8.24-2.13,14.15-8.82,14.81-16.16,16.74-6,56.19-19.46,66.52-15.51,2.3.89,5.52.93,9,.4a13.45,13.45,0,0,0,12.24,3.06c7.29-1.63,12.16-8.27,10.89-14.8a8.46,8.46,0,0,0-.26-1,2.82,2.82,0,0,0-.81-2c-2.5-4.7-8.32-7.25-14.41-5.88a15.38,15.38,0,0,0-4.93,2.07C497.44,613.76,456.4,627.67,445.93,631.47Z" fill="#fff"/>
        <path d="M696,637.19c8.5,1.71,13.32-8.78,15.23-14.51,2.16-6.46,7.51-12.74,11.92-17.1a32.51,32.51,0,0,1,8.32-6.24c3.45-1.58,7.85-.74,10.89-3a7.24,7.24,0,0,0-.7-1.13c4.74.64,9.59-1.1,13.76-5.19-3.22-6.74-10.37-1.42-12.06-6.07-1.28-3.52,4.44-10.15,6-12.57,2.74-4.16,7.07-9.81,5.79-15.34-1.58-6.81-5.32-7.8-9.49-4.71a15,15,0,0,0-3.09,3.23A14,14,0,0,1,737,567.93a13,13,0,0,1-9.56,2.14l-1.58.92c-.94.58-1.73,1-2.36,1.4a13.39,13.39,0,0,1,2.24,2.38,14.08,14.08,0,0,1,2.68,7.4,5.45,5.45,0,0,1-.62-.35h0l-.36-.23-.12-.07c-.11-.08-.23-.15-.33-.23l-.12-.08-.58-.42-.15-.13a2.31,2.31,0,0,1-.24-.2l-.22-.18-.22-.2-.26-.23-.18-.17-.41-.41-.12-.13-.29-.33a.75.75,0,0,1-.15-.16c-.1-.11-.19-.23-.28-.34l-.13-.15c-.13-.17-.26-.33-.38-.5s-.29-.42-.44-.62a6.38,6.38,0,0,1-.46-.6,3.35,3.35,0,0,1-.21-.36c-.5-.85-1-1.68-1.58-2.49a12.11,12.11,0,0,1-1.84-.29l-2.22-.86a49.32,49.32,0,0,0-7.68,3c-6.43,2.9-34.92,22.85-27.42,31.83l.06,0c-.88,4.43.27,9.24,5.18,14.09.73-1.23,2.82-2,3.44-3.41C698.6,623.12,685.1,635,696,637.19Z" fill="#fff"/>
        <path d="M872.09,630.4c-8.68,34.25-33.28,7.16-24.31-25.08,4.87-17.48,18.36,2.87,25.86,13.82.42,4-.2,6.21-.68,9.52" fill="#fff"/>
        <path d="M262.51,598.59a10.78,10.78,0,0,0-6.44,3.66l-.23.07c-1.49.66-1.46,4,0,7.38,1.27,2.86,3.19,4.89,4.66,5.08.05.13.12.27.18.4-.85,1.21-.63,4,.64,6.87,1.13,2.54,2.76,4.42,4.14,5l0,0a12.1,12.1,0,0,0,4.94.18l.15,0,.32-.07c5.68-1.23,9.22-6.52,8.94-12.77,0-1.3,3.28-2,5.66-2.41l31.7-14.08a10.88,10.88,0,0,1,8.71-2c2.8.53,6.23,2.1,9.08,1.55.07,0,.1-.1.08-.26a.06.06,0,0,1,0,0c1.31-.83,1.19-4.11-.32-7.52s-4-5.86-5.52-5.23h0c1.46-.68,1.39-4.07-.18-7.59s-4.05-5.88-5.53-5.22a1.56,1.56,0,0,0-.78.85l-.05-.07a10.06,10.06,0,0,0-5.06,5.92c-1.11.6-13.64,6.36-23.73,11.16-10.33,4.27-23,9.72-24.19,10.13A13,13,0,0,0,262.51,598.59Z" fill="#fff"/>
        <path d="M168,533.37S4.43,458.49,45.11,613.2c0,0,8.93,80.66,127.15,22.48,0,0,25-52.64,87.05-44.36,0,0,36.24-32.86-9.58-85.22S240.72,533.37,168,533.37Z" fill="#fff"/>
        <path d="M791.17,522.09s-82.9,54.87,7.21,52.13c0,0,62.15,52.89,79.15,13.25s22.79-16.91,48.45-25.32,76.79-35.31,34.69-64.77c0,0-65.4,36.53-83.81,39s-78.48-14.33-78.48-14.33" fill="#fff"/>
        <path d="M552.47,608.15a43.89,43.89,0,0,0,20.22,8.74,41.94,41.94,0,0,0,32.16-7.78c7.63-2.74,13-8.06,12.53-17.66a35.28,35.28,0,0,0,1.22-5.26c3.06-20.5-12.55-40-34.85-43.51a4.11,4.11,0,0,1-.47-.07,9.23,9.23,0,0,0-1-.13c-18.22-2.27-35,6.93-41.74,21.8-38.26-1-127.83-5.13-145.84-21.87-4-3.73-10.49-6.44-18-8.21a30,30,0,0,0-22.53-16.44c-15.91-2.52-30.59,7.39-32.79,22.16-.13.79-.18,1.56-.24,2.36a6.78,6.78,0,0,0,.22,4.81c1.65,11.87,11.54,22,24.85,24.11a30.52,30.52,0,0,0,11.5-.32C435.36,603.16,528.52,607.55,552.47,608.15Z" fill="#fff"/>
        <path d="M570.77,514.19a12.32,12.32,0,0,1,3.94-5.4,12.08,12.08,0,0,1,9.85-2.37,5.71,5.71,0,0,1,6.25,3.27A13.75,13.75,0,0,1,592,511a12.53,12.53,0,0,1-2.08,17.48l-.12.09-.25.19a12.22,12.22,0,0,1-14.71-.28c-10,5.77-33.18,19.78-35.2,27.16a11.41,11.41,0,0,1-3.44,4.93,8.73,8.73,0,0,1-15.67,6.29c-.16-.21-.3-.43-.45-.65a2.47,2.47,0,0,1-.74-1.41A8.94,8.94,0,0,1,522,554.34a8.5,8.5,0,0,1,3.11-1.55C540.43,532.4,564.5,517.79,570.77,514.19Z" fill="#fff"/>
        <path d="M4.5,422.44a15.58,15.58,0,0,0-10.88-4.81,3.3,3.3,0,0,0-.3-.22c-2.26-1.31-6.82,2.35-10.2,8.16-2.83,4.88-3.93,9.74-2.88,11.94l-.39.69c-2.44.17-6.12,3.53-9,8.42-2.52,4.32-3.67,8.63-3.15,11.09l-.05.07a18.74,18.74,0,0,0,4.19,7l.17.18c.12.13.26.25.39.37,6.81,6.42,17.35,5.46,25.77-1.78,1.82-1.43,5.71,2.29,8.43,5.09l48.07,27.9c4.71,1.57,8.42,4.24,10.62,9.72,1.78,4.41,2.67,10.83,6,14.13.08.08.22,0,.43-.18l0,0c2.34.89,6.8-2.88,10.17-8.69,3.49-6,4.51-11.93,2.31-13.28h0c2.26,1.26,6.9-2.56,10.39-8.57s4.53-12,2.28-13.3a2.42,2.42,0,0,0-1.89-.13l.07-.15a15.57,15.57,0,0,0-12.78-.43c-1.84-.84-21.11-11.67-36.85-20.21-15.22-9.44-34.18-20.8-35.82-22A20.35,20.35,0,0,0,4.5,422.44Z" fill="#fff"/>
        <path d="M678.41,556.15s-23.57-43.73-40.37-2.87c0,0-11.45,19.26,24.28,26.12,0,0,14.27-7.43,26.56,4.71,0,0,13.45-1.2,12.08-20.36S694.43,568.25,678.41,556.15Z" fill="#fff"/>
        <path d="M731.84,402.42,730,403.77c-21.52-48.39-91.5-66-158.93-39.12-69.17,27.55-109.46,91.43-90,142.67,8.05,21.21,25.09,36.93,47.18,46.12l-16.59,12.31c-23.06-10.79-40.77-28.09-49.45-50.92-22.4-59,23.95-132.45,103.53-164.14,44.05-17.55,89-18.6,124.22-6.06Z" fill="#fff"/>
        <path d="M891.85,499.3s111.64,17.25,62.38-69.8c0,0-18.2-47.49-81.13,8.32,0,0-7,36.33-46.1,42,0,0-16.92,26.25,19.31,50.16S847.5,511.87,891.85,499.3Z" fill="#fff"/>
        <path d="M1028.09,483.22a10.8,10.8,0,0,0,6-3.52.37.37,0,0,0,.2-.07c1.37-.65,1.42-3.6.13-6.59-1.09-2.5-2.79-4.24-4.12-4.36,0-.12-.1-.22-.16-.34.81-1.11.67-3.59-.42-6.12-1-2.22-2.41-3.84-3.66-4.24v-.06a11.19,11.19,0,0,0-4.5,0c-.06,0-.1,0-.14,0l-.3.08c-5.21,1.3-8.56,6.18-8.45,11.74,0,1.16-3,1.91-5.22,2.37L978.19,486a10.57,10.57,0,0,1-8,2.12c-2.52-.37-5.62-1.64-8.23-1-.07,0-.09.1-.08.25v0c-1.22.79-1.19,3.73.12,6.71s3.52,5.07,4.88,4.46h0c-1.34.66-1.36,3.7,0,6.79s3.55,5.09,4.92,4.44a1.56,1.56,0,0,0,.73-.8l0,.08a10,10,0,0,0,4.76-5.49c1-.58,12.58-6.21,21.9-10.88,9.52-4.21,21.2-9.56,22.29-10A11.54,11.54,0,0,0,1028.09,483.22Z" fill="#fff"/>
        <path d="M638.46,499.69c3,12.45,20,12.41,28.86,11.42,10-1.11,21.71,2.16,30.29,5.36,4.4,1.66,9.95,4.23,13.49,7.28,4.25,3.65,5.85,10.07,10.74,12.75a11,11,0,0,0,1.08-1.64c2.08,6.75,7.4,12.17,15.45,15.22,7-8.5-4.5-14.78.68-19.92,3.93-3.91,16.36-.35,20.59.3,7.27,1.11,17.51,3.39,24.13-1.75,8.15-6.33,7.16-12,.44-15.63a21.79,21.79,0,0,0-6.23-2.14,20.6,20.6,0,0,1-21.38.72,19.18,19.18,0,0,1-8.78-11.48c-.69-.5-1.42-1-2.21-1.55-1.35-.9-2.44-1.69-3.33-2.3A20.35,20.35,0,0,1,732.21,509a9.12,9.12,0,0,1,.09-1,0,0,0,0,1,0,0c0-.21.05-.42.08-.62s0-.14,0-.21.06-.39.1-.58a.71.71,0,0,1,0-.21c.07-.35.13-.7.21-1,0-.1,0-.19.07-.29s.08-.3.12-.45.08-.27.11-.41a3.38,3.38,0,0,1,.13-.42c.05-.15.1-.32.15-.48s.08-.23.11-.35l.3-.8.1-.24c.08-.21.17-.4.26-.6a1.66,1.66,0,0,1,.12-.31l.28-.58c0-.09.09-.17.13-.26.15-.28.28-.56.43-.83s.38-.65.56-1,.34-.67.52-1l.35-.51c.84-1.18,1.62-2.4,2.36-3.65a18.07,18.07,0,0,1-.74-2.65c-.07-1.17-.15-2.34-.23-3.51a73.3,73.3,0,0,0-8.72-8.46c-7.86-6.81-52.2-32.61-59.6-17l.09.05c-6.47,1.56-12.21,6.09-15.67,15.68,2.1.22,4.45,2.52,6.69,2.5C659,494.49,634.67,483.76,638.46,499.69Z" fill="#fff"/>
        <path d="M642.05,543.76c-15.94,69.25-66.28,17.32-49.68-47.92,9-35.39,50.22,19.39,52.29,25.27s-2,15.69-2.82,22.36" fill="#fff"/>
        <path d="M432.73,482.74s77.46-60.18-11-50.76c0,0-65.1-47.38-78.85-7.11s-21.16,18.34-45.78,28.53-72.91,40.48-29.29,66.32c0,0,61.61-40.83,79.54-44.68s78.28,8.24,78.28,8.24" fill="#fff"/>
        <path d="M690.85,591.69a4.38,4.38,0,0,0,2.69-1.84.25.25,0,0,0,.09,0c.63-.34.46-1.91-.38-3.49-.7-1.34-1.65-2.28-2.31-2.34a1.14,1.14,0,0,1-.1-.19c.32-.58.09-1.9-.62-3.24a4.88,4.88,0,0,0-2.07-2.28h0a5.11,5.11,0,0,0-2.2,0l-.07,0-.14,0c-2.46.67-3.78,3.24-3.35,6.2.06.61-1.36,1-2.39,1.23l-13.39,7.2a4.43,4.43,0,0,1-3.77,1.1c-1.26-.21-2.86-.9-4.11-.59,0,0,0,0,0,.12a0,0,0,0,0,0,0c-.55.42-.34,2,.51,3.56s2.07,2.71,2.69,2.39h0c-.61.35-.42,2,.45,3.6s2.09,2.72,2.71,2.38a.63.63,0,0,0,.3-.41l0,0a4.36,4.36,0,0,0,2-2.9c.46-.3,5.74-3.24,10-5.68,4.38-2.2,9.73-5,10.24-5.2A5.51,5.51,0,0,0,690.85,591.69Z" fill="#fff"/>
        <path d="M501.25,529.47a11.72,11.72,0,0,0-3,7,.69.69,0,0,0-.13.2c-.81,1.47,1.11,4.07,4.29,5.81,2.67,1.46,5.37,1.84,6.65,1,.13.07.24.14.37.2,0,1.5,1.81,3.57,4.48,5,2.36,1.3,4.76,1.75,6.16,1.29l0,0a12.91,12.91,0,0,0,4-3,.91.91,0,0,0,.1-.13,2.85,2.85,0,0,0,.23-.26c3.82-4.59,3.58-11-.25-15.81-.74-1,1.47-3.66,3.13-5.51L544.48,494a11.82,11.82,0,0,1,5.79-7.14c2.54-1.37,6.19-2.31,8.14-4.57.05,0,0-.14-.09-.25s0,0,0,0c.58-1.5-1.42-4-4.59-5.76s-6.59-2.07-7.42-.62h0c.77-1.48-1.24-4.12-4.53-5.91s-6.63-2.08-7.43-.6a1.57,1.57,0,0,0-.12,1.16l-.09,0a10.69,10.69,0,0,0-.61,7.93c-.54,1.18-7.21,13.73-12.5,24-5.76,10-12.73,22.37-13.44,23.46A13.39,13.39,0,0,0,501.25,529.47Z" fill="#fff"/>
        <path d="M320.52,536.52c5,26-50.8,21.71-54.14-3.13-1.8-13.49,31.85-7.72,50.35-4.73,2.94,2.28,3.16,4,4.23,6.33" fill="#fff"/>
        <path d="M599.34,483.09C583.08,514.71,527.54,464,544.58,435c9.26-15.78,40.13,18.19,57.26,36.68,1.21,4.85,0,6.85-.76,10.2" fill="#fff"/>
        <path d="M714.83,585.63c-25.37-2-12.66-49,11.39-46,13,1.68,2.35,29.32-3.36,44.57-2.62,2-4.28,1.76-6.65,2.13" fill="#fff"/>
        <path d="M820.52,482.11a14.29,14.29,0,0,0,9-1.83,2.81,2.81,0,0,0,.29,0c2-.22,3.23-3.83,2.76-8.06-.4-3.54-1.86-6.41-3.51-7.1,0-.16,0-.33,0-.5,1.45-1,2.26-4.15,1.87-7.7-.35-3.15-1.54-5.75-3-6.78a.13.13,0,0,1,0-.06,15.65,15.65,0,0,0-5.71-1.85l-.19,0c-.13,0-.27,0-.4,0-7.12-.57-13.29,4-15.35,10.92-.51,1.42-4.6,1.07-7.55.73l-42.51,4.73a14.33,14.33,0,0,1-11-.73c-3.07-1.52-6.49-4.39-10-4.74-.09,0-.15.08-.2.26a.06.06,0,0,1,0,0c-1.86.46-3,4.09-2.51,8.31.49,4.37,2.48,7.73,4.46,7.54h0c-2,.26-3.18,4-2.69,8.34s2.49,7.77,4.49,7.55a2.05,2.05,0,0,0,1.23-.67l0,.1a12.9,12.9,0,0,0,8.19-4.76c1.53-.29,18.41-2.37,32.07-4.22,13.73-1.2,30.66-2.89,32.19-2.94A16.24,16.24,0,0,0,820.52,482.11Z" fill="#fff"/>
        <path d="M212.89,12.65S150,288.1,117.72,331s-91,72-44,126c49.87,57.29,35,80.31,100,60,0,0,32,50,45-79C229.44,331.64,344.81-11.59,344.81-11.59L228-12.46Z" fill="#fff"/>
        <path d="M78.44,374a13.87,13.87,0,0,0,8.48-4.34,1.38,1.38,0,0,0,.29-.08c2-.76,2.1-5,.35-9.47-1.47-3.74-3.82-6.46-5.69-6.78l-.21-.52c1.15-1.51,1-5.1-.44-8.85-1.31-3.33-3.3-5.84-5-6.59l0-.05a15.52,15.52,0,0,0-6.32-.51l-.2,0-.42.07C61.85,338.17,57,344.77,57,352.8c-.08,1.68-4.32,2.38-7.39,2.78L8.16,371.89A14,14,0,0,1-3.14,374c-3.56-.83-7.87-3.05-11.56-2.5-.1,0-.13.13-.12.34a.06.06,0,0,1,0,0c-1.73,1-1.76,5.22,0,9.68,1.82,4.61,4.84,7.74,6.79,7h0c-1.91.79-2,5.14-.19,9.75s4.87,7.78,6.81,7a2.1,2.1,0,0,0,1-1.05l0,.1a13,13,0,0,0,6.83-7.32c1.45-.72,17.86-7.41,31.09-13,13.49-4.91,30.07-11.2,31.6-11.66A16.64,16.64,0,0,0,78.44,374Z" fill="#fff"/>
        <path d="M463.39,412.59a24.66,24.66,0,0,0,19.67-3.52,3.32,3.32,0,0,0,.63,0c4.37-.26,7.49-10.28,7-22.4-.43-10.19-3.28-18.6-6.79-20.88,0-.47,0-.94-.05-1.41,3.27-2.68,5.41-11.39,5-21.57-.37-9-2.66-16.68-5.59-19.88a.43.43,0,0,1,0-.16A30.3,30.3,0,0,0,471,316.43l-.41-.08a8.57,8.57,0,0,0-.88-.18c-15.42-2.95-29.37,9-34.67,28.22-1.3,3.94-10.13,2.2-16.51.67l-93,5.52c-8.06,2.32-15.71,1.94-23.77-4.14-6.51-4.9-13.58-13.71-21.26-15.37-.19,0-.35.19-.46.71,0,0,0,.07,0,.1-4.11,1-7,11.07-6.45,23.19.53,12.53,4.45,22.46,8.78,22.32h0c-4.33.36-7.41,10.71-6.88,23.26s4.5,22.57,8.85,22.31a3.91,3.91,0,0,0,2.75-1.66,1.88,1.88,0,0,1,.06.29c7.22-.86,13.58-5.08,18.38-12.05,3.37-.52,40.3-3.28,70.23-6,30-.85,67-2.49,70.37-2.35C451,406.94,456.88,411,463.39,412.59Z" fill="#fff"/>
        <path d="M868.93,422.36a23.88,23.88,0,0,0,16.19,2,2.75,2.75,0,0,0,.49.14c3.5.78,7.8-5.07,9.6-13.07,1.53-6.73.81-12.84-1.54-15.09.06-.31.15-.61.21-.93,3.08-1,6.35-6.24,7.88-13,1.35-6,1-11.44-.79-14.17a.38.38,0,0,1,0-.1,27,27,0,0,0-8.48-6.8c-.12-.05-.21-.1-.31-.13a5.11,5.11,0,0,0-.67-.31c-11.64-5.29-24.84-.57-32.54,10.83-1.74,2.28-8.4-.78-13.16-3.16l-74.47-16.65c-6.79-.24-12.76-2.16-18-7.87-4.25-4.62-8.23-11.9-14-14.65-.14-.06-.31.05-.49.37,0,0,0,0,0,0-3.42-.26-7.52,5.7-9.32,13.71-1.87,8.28-.59,15.6,2.86,16.45h0c-3.48-.71-7.8,5.37-9.68,13.66s-.57,15.69,2.92,16.46a3.38,3.38,0,0,0,2.48-.48,1.21,1.21,0,0,1,0,.2A22.3,22.3,0,0,0,744.79,386c2.76.39,32.44,6.63,56.58,11.39,23.86,6,53.39,13,56,13.8A28.3,28.3,0,0,0,868.93,422.36Z" fill="#fff"/>
        <path d="M278.54,463c-49.25,24.64-79.36-73.61-31.26-94.8,26.12-11.46,37.78,49.84,44.59,83.42-2.59,6.44-5.94,7.89-9.91,11.19" fill="#fff"/>
        <path d="M1037.72,460,879.89,308.82s-108.4-70-24.5-93.27,30.08-22.68,63.33-48.55,57.69,27.13,42,63c-2,22,10,90.92,111,177Z" fill="#fff"/>
        <path d="M1071.72,399l17.34-11.36-65.15-103.87s-46.56-85-49.23-53c-.4,4.79-.33,11.41-3.81,15.22-.35.38-1.24,0-1.47.25-.68.86-.59,2.14-.76,3.26-.41,2.55-.94,5.08-1.46,7.61-1.55,7.52-.62,10.23,5,15.72a107,107,0,0,0,17.33,13.58C999.72,293,1026.89,323.35,1071.72,399Z" fill="#fff"/>
        <path d="M709.51,450.24a17.25,17.25,0,0,0,11.68,2.8c6.33-.89,11.41-5.59,14.36-12.33,3.23-3.61,4.83-8.77,2.75-16.1-.08-1.43-.21-2.86-.43-4.31-2.47-16.44-14-28.44-25.65-26.79l-.25,0a3.41,3.41,0,0,0-.54.1c-9.43,1.82-15.93,12.23-16.35,25-19.08,6.73-64.08,21.11-76.22,11.7-2.69-2.1-6.43-2.92-10.47-2.81-3.57-5.69-8.91-9.05-14.31-8.29-8.35,1.17-13.66,11.71-11.87,23.55.09.64.21,1.25.34,1.87a7.9,7.9,0,0,0,1,3.68c3.12,8.85,10,14.75,16.95,13.76a11.17,11.17,0,0,0,5.61-2.5C650.76,469.35,697.58,454.48,709.51,450.24Z" fill="#fff"/>
        <path d="M855.06,454.28s45.77-27.42-2.43-28.19c0,0-31.76-30-41.91-9.08s-12.63,8.52-26.57,12.39-42,17.07-20.28,33.94c0,0,35.91-18,45.82-18.89s45.46,10.05,45.46,10.05Z" fill="#fff"/>
        <path d="M865.18,300a10.85,10.85,0,0,0-.56-7.45.43.43,0,0,0,0-.23c0-1.63-3-3.07-6.83-3.23-3.19-.12-5.9.71-6.71,1.94h-.44c-.75-1.3-3.41-2.34-6.63-2.47-2.83-.12-5.28.53-6.36,1.55,0,0,0,0,0,0a11.75,11.75,0,0,0-2.32,4.37c0,.06,0,.11,0,.15a1.32,1.32,0,0,0-.07.32c-1.37,5.67,2,11.21,7.86,13.73,1.2.59.42,3.84-.24,6.18L842,349.65a10.7,10.7,0,0,1-2,8.74c-1.71,2.27-4.66,4.68-5.4,7.49,0,.07.06.13.22.2h0c.18,1.56,3.28,2.92,7.08,3.06s7.15-1,7.22-2.64h0c0,1.61,3.16,3.07,7.09,3.21s7.19-1,7.23-2.67A1.67,1.67,0,0,0,863,366h.1a10.29,10.29,0,0,0-3.26-7.2c-.07-1.26.08-15.12.07-26.35.57-11.21,1.08-25.06,1.2-26.31A12.57,12.57,0,0,0,865.18,300Z" fill="#fff"/>
        <path d="M604.74,300a24.42,24.42,0,0,0-3.37,16.09,2.15,2.15,0,0,0-.18.48c-1.08,3.45,4.33,8.21,12.1,10.63,6.53,2,12.64,1.78,15.07-.4l.9.28c.76,3.17,5.63,6.85,12.16,8.9,5.79,1.81,11.24,1.82,14.09.28l.1,0a27.34,27.34,0,0,0,7.46-8l.17-.3a6.77,6.77,0,0,0,.36-.65c6.24-11.28,2.71-24.87-7.91-33.47-2.11-1.92,1.5-8.37,4.27-13l22.93-73.44c.83-6.8,3.24-12.65,9.35-17.49,4.94-3.91,12.49-7.34,15.71-12.92.08-.14,0-.31-.32-.52,0,0,0,0,0,0,.55-3.41-5-8-12.76-10.4-8-2.5-15.4-1.77-16.53,1.62h0c1-3.45-4.64-8.25-12.69-10.76s-15.48-1.76-16.54,1.68a3.42,3.42,0,0,0,.26,2.52l-.2,0a22.61,22.61,0,0,0,2.36,17.07c-.63,2.73-9.38,32-16.18,55.89-8,23.48-17.46,52.57-18.5,55.15A28.59,28.59,0,0,0,604.74,300Z" fill="#fff"/>
        <path d="M887.84,202.79A22.27,22.27,0,0,0,902.58,196a2.72,2.72,0,0,0,.49-.13c3.37-1.15,4-8.85,1.32-17.21-2.22-7-6-12.29-9.17-13.11-.11-.33-.19-.65-.3-1,2.06-2.6,2.12-9.17-.09-16.2-2-6.24-5.16-11.08-8.05-12.67,0,0,0-.07,0-.12A25.38,25.38,0,0,0,876,133.82a3.11,3.11,0,0,0-.33,0c-.24,0-.49,0-.73.07-12.61,1.34-21.32,12.76-21.9,27.4-.29,3.05-7.52,3.78-12.79,4.12l-71.71,24.39c-5.88,3.41-11.94,4.83-19.37,2.36-6-2-13.16-6.6-19.47-6.06-.15,0-.24.21-.22.59,0,0,0,.05,0,.08-3,1.59-3.39,9.28-.75,17.65,2.73,8.65,7.64,14.73,11,13.68h0c-3.31,1.21-3.81,9.13-1.07,17.8s7.69,14.8,11.05,13.65a3.34,3.34,0,0,0,1.84-1.76,1.18,1.18,0,0,1,.1.19,22.09,22.09,0,0,0,12.15-12.49c2.54-1.11,30.92-11.2,53.83-19.69,23.31-7.23,52-16.54,54.62-17.19A26.94,26.94,0,0,0,887.84,202.79Z" fill="#fff"/>
        <path d="M483.36,202.17s-54,84.91,27.42,48c0,0,78,27.94,77.72-17.13S602.6,208,622.73,190s56.18-63.54,5.88-76.26c0,0-45.26,60.4-61.11,69.84S483,201.84,483,201.84Z" fill="#fff"/>
        <path d="M360.69,204.07s-176.56,12.61-71,124.94c0,0,44.31,64.28,121.06-40.1,0,0-2-56.31,55.8-78.15,0,0,16.72-44.74-46.93-67.85S424.08,170.24,360.69,204.07Z" fill="#fff"/>
        <path d="M146.34,253.9c14.83-32.06-21.36-26.52-34.7,4.15-7.23,16.65,16.08,9.34,28.81,5.55,2.83-2.82,3.74-4.94,5.46-7.82" fill="#fff"/>
        <path d="M613.21,280.62c-4.05,42.09-37.77,15.34-33-24.44,2.59-21.58,31.13,7.56,32.8,10.88s0,9.4.07,13.4" fill="#fff"/>
        <path d="M599.48,323.68c-8.72,41.38-39.25,11-30.09-28,5-21.15,30.09,11,31.39,14.46s-1,9.35-1.43,13.33" fill="#fff"/>
        <path d="M839.8,353.48c-14.38,39-39.7,5.12-25.23-31.46,7.85-19.84,27.66,15,28.43,18.53s-2.33,8.94-3.29,12.75" fill="#fff"/>
        <path d="M698.91,262.26a18.93,18.93,0,0,0-6.08-8.36,18.7,18.7,0,0,0-15.22-3.69,8.85,8.85,0,0,0-9.67,5,21.51,21.51,0,0,0-1.85,2.06,19.38,19.38,0,0,0,3.2,27l.18.13a2.82,2.82,0,0,0,.39.29,18.87,18.87,0,0,0,22.74-.41C708.08,293.3,743.86,315,747,326.41c.69,2.54,2.67,5.19,5.3,7.63a13.5,13.5,0,0,0,24.21,9.74c.26-.32.48-.65.71-1a3.86,3.86,0,0,0,1.14-2.19,13.81,13.81,0,0,0-4.05-16.2,13.21,13.21,0,0,0-4.81-2.41C745.79,290.45,708.6,267.83,698.91,262.26Z" fill="#fff"/>
        <path d="M986.05,137.82a30.8,30.8,0,0,0-16.64,2.06,30.31,30.31,0,0,0-17.19,18.7c-3.69,5.07-4.82,11-.43,17.69a31.78,31.78,0,0,0,1.47,4.24,31.41,31.41,0,0,0,40.86,16.7,2.52,2.52,0,0,1,.33-.14c.25-.1.48-.22.72-.33a30.66,30.66,0,0,0,17.29-32.6c24.84-14.94,83.74-48.66,102.39-44.12,4.15,1,9.45.29,15-1.52a21.89,21.89,0,0,0,32.84-26.71c-.25-.61-.55-1.19-.86-1.78a6.11,6.11,0,0,0-2.2-3.33,22.39,22.39,0,0,0-26.17-7,21.51,21.51,0,0,0-7.2,4.93C1062.88,93.45,1001.58,128.45,986.05,137.82Z" fill="#fff"/>
        <path d="M679,99.27a18.93,18.93,0,0,0-6.08-8.36,18.69,18.69,0,0,0-15.22-3.69,8.85,8.85,0,0,0-9.67,5,20.16,20.16,0,0,0-1.84,2.06,19.37,19.37,0,0,0,3.19,27l.18.13a3.58,3.58,0,0,0,.39.29,18.87,18.87,0,0,0,22.74-.41c15.48,8.94,51.26,30.64,54.38,42,.69,2.54,2.67,5.19,5.3,7.63a13.5,13.5,0,0,0,24.21,9.74c.26-.32.48-.65.71-1a3.83,3.83,0,0,0,1.14-2.19,13.8,13.8,0,0,0-4-16.2,13.29,13.29,0,0,0-4.81-2.41C725.87,127.46,688.69,104.84,679,99.27Z" fill="#fff"/>
        <path d="M329.12,167.84a19,19,0,0,0-9.51,4.07,18.67,18.67,0,0,0-7,14,8.84,8.84,0,0,0,2.77,10.55,19.37,19.37,0,0,0,28.65,5.17l.17-.14c.13-.1.25-.21.37-.32a18.89,18.89,0,0,0,4.67-22.26c12.16-13.1,41.28-43.15,53.11-43.65,2.63-.1,5.64-1.44,8.61-3.46a13.5,13.5,0,0,0,14.89-21.44c-.25-.32-.53-.61-.81-.91a3.76,3.76,0,0,0-1.87-1.59,13.81,13.81,0,0,0-16.7.33,13.13,13.13,0,0,0-3.42,4.15C367,128.42,336.71,159.63,329.12,167.84Z" fill="#fff"/>
        <path d="M554.27,153.3A21.22,21.22,0,0,0,558.56,164,20.85,20.85,0,0,0,574,172.09c4,1.56,8.17,1.26,11.81-2.83a21.57,21.57,0,0,0,6.45-31.78,1.39,1.39,0,0,1-.15-.19c-.11-.15-.23-.28-.35-.42a21,21,0,0,0-24.67-5.73c-14.3-13.86-47-47-47.31-60.19-.06-2.93-1.47-6.32-3.65-9.68a15,15,0,0,0-23.51-17.09c-.36.28-.69.58-1,.88a4.24,4.24,0,0,0-1.82,2,15.38,15.38,0,0,0,0,18.6,14.81,14.81,0,0,0,4.54,3.91C511.29,110.12,545.31,144.64,554.27,153.3Z" fill="#fff"/>
        <path d="M13.37,258.26a10.72,10.72,0,0,0,2.93,6.8c0,.08,0,.16.05.23.5,1.55,3.78,1.88,7.34.75,3-1,5.21-2.64,5.55-4.08l.42-.13c1.11,1,3.91,1.05,6.9.1,2.65-.85,4.7-2.27,5.37-3.59h0a12.3,12.3,0,0,0,.72-4.88.45.45,0,0,1,0-.16c0-.11,0-.22,0-.33-.6-5.78-5.48-9.87-11.72-10.26-1.3-.15-1.63-3.47-1.79-5.89l-10.57-33a10.88,10.88,0,0,1-1-8.88c.83-2.73,2.77-6,2.53-8.86,0-.08-.1-.11-.26-.11h0c-.69-1.39-4-1.63-7.52-.5S6,188.8,6.44,190.34h0c-.52-1.52-3.89-1.82-7.56-.64s-6.29,3.39-5.79,4.94a1.53,1.53,0,0,0,.76.86l-.08,0A10.08,10.08,0,0,0-.9,201.22C-.42,202.38,4,215.46,7.63,226c3.13,10.73,7.17,23.92,7.46,25.14A12.82,12.82,0,0,0,13.37,258.26Z" fill="#fff"/>
        <path d="M155.72,216.74s31.65-43.87-13.42-25.86c0,0-41.34-16.15-42.51,7.65s-8.36,13-19.77,22.12S47.8,253.2,74.61,260.82c0,0,26.24-31.08,35.08-35.79s46.2-8.11,46.2-8.11Z" fill="#fff"/>
        <path d="M789.71,337.34l-33.8-16.22s-29.66-53.78-8.56-75.23,59.12,27.79,67.67,35.63S854.13,301.15,789.71,337.34Z" fill="#fff"/>
        <path d="M501.42,260.39,475.62,297s7.54,67.17,47.73,72.32,45.56-63.49,49.23-76.07S601.77,250.13,501.42,260.39Z" fill="#fff"/>
        <path d="M33.61,321.28l36.6,14.39s62.06-16,63.13-47.83-63.58-25.58-75.67-26.52S14.67,245.3,33.61,321.28Z" fill="#fff"/>
        <path d="M769.48,130.48a27.75,27.75,0,0,0-.49,20.21,3.09,3.09,0,0,0-.12.62c-.57,4.39,7.71,9.28,18.51,10.92,9.08,1.39,17,0,19.68-3,.42.06.84.14,1.26.19,1.77,3.74,9.08,7.42,18.15,8.81,8,1.23,15.21.32,18.58-2l.14,0a31.58,31.58,0,0,0,7.84-11c.05-.14.11-.27.14-.39a6.72,6.72,0,0,0,.32-.85c5.43-14.83-2.54-30.82-18.58-39.52-3.25-2-.09-10.47,2.42-16.55L849.46,4.33c-.58-8.44,1.16-16,8-22.92C863-24.2,872.06-29.67,874.93-37c.07-.18-.11-.38-.54-.58,0,0-.06,0-.09-.06-.11-4.26-8.5-8.89-19.31-10.53-11.15-1.7-20.65.44-21.31,4.77h0c.47-4.37-8.11-9.28-19.29-11s-20.77.46-21.33,4.85a4.17,4.17,0,0,0,1,3h-.27c-.57,7.35,2,14.43,7.28,20.44-.16,3.45-4.47,40.7-7.56,71-4.73,30-10.06,67.13-10.8,70.45A33.69,33.69,0,0,0,769.48,130.48Z" fill="#fff"/>
        <path d="M93.69,64.07S-82.87,76.68,22.66,189c0,0,44.31,64.28,121.06-40.1,0,0-2-56.31,55.8-78.15,0,0,16.72-44.74-46.93-67.85S157.08,30.24,93.69,64.07Z" fill="#fff"/>
        <path d="M471.93-20.25l35.2,118.91S540.2,163.43,494.22,152,474,155,451.36,158.8s-20.26-29.42-3.37-43c6.74-10.43,19.19-48.14-6.15-118.92Z" fill="#fff"/>
        <path d="M439.73.86l-11.22.9,3.61,69.79s-.25,55.25,9.45,40c1.45-2.28,3.15-5.61,5.81-6.55.27-.1.58.36.76.28.56-.25.85-.91,1.22-1.42.87-1.16,1.79-2.28,2.7-3.4,2.72-3.33,3-4.94,1.78-9.21a62.18,62.18,0,0,0-4.66-11.55C446,73.59,441.12,51,439.73.86Z" fill="#fff"/>
        <path d="M426.56,44.09s11.56-68.84-30.3-22.26c0,0-57.06,4.93-44.81,33.14s-2.24,19.83-10.24,36.88-18.69,56.06,16.69,49.56c0,0,12.6-50.95,20.14-61.44S426.85,44.2,426.85,44.2Z" fill="#fff"/>
        <path d="M531.32,89.36c9.55,11.22,26.31,1.65,34.52-4.33,9.24-6.75,22.53-9.92,32.68-11.4,5.22-.75,12.06-1.19,17.18,0,6.13,1.4,11.13,7.14,17.37,7.19a14.57,14.57,0,0,0,.19-2.31c5.65,5.82,13.78,8.45,23.34,7.1,2.41-12.74-12.32-12.77-10-21,1.79-6.24,15.93-9.5,20.44-11.2,7.75-2.92,19.06-6.29,22.83-15.31,4.65-11.11.67-16.37-7.9-16.41A23.32,23.32,0,0,0,674.73,23c-4.09,7.76-12,13-20.68,12.7a21,21,0,0,1-14.77-7c-.95-.13-1.93-.26-3-.37-1.81-.17-3.3-.38-4.5-.51a25.9,25.9,0,0,1,.61,5.63,25.29,25.29,0,0,1-3.77,13.09,10.36,10.36,0,0,1-.48-1.13h0l-.25-.69-.09-.22c-.06-.23-.15-.45-.21-.66a.8.8,0,0,1-.07-.24c-.13-.4-.25-.79-.35-1.19,0-.11,0-.22-.08-.33s-.09-.36-.13-.54-.06-.31-.1-.48-.08-.33-.1-.5-.07-.39-.11-.59,0-.28-.07-.42c-.05-.34-.1-.66-.14-1l0-.3c0-.26,0-.51-.06-.77a1.69,1.69,0,0,1,0-.38c0-.26,0-.51,0-.76s0-.23,0-.34c0-.38,0-.74,0-1.1s0-.89,0-1.32,0-.88,0-1.32c0-.23,0-.48.07-.72.19-1.69.31-3.39.38-5.09a21,21,0,0,1-2.15-2.33c-.69-1.17-1.39-2.33-2.09-3.51a80.32,80.32,0,0,0-13.11-3.86C598,14.08,540.54,12.2,541.59,32.49h.12c-5.54,5.23-8.78,13.12-7.07,25,2.18-.95,5.73.12,7.92-1.16C548.72,72.53,519.08,75,531.32,89.36Z" fill="#fff"/>
        <path d="M874.2,131.15c9.76,10.88,26.89,1.59,35.28-4.21,9.45-6.55,23-9.63,33.4-11.07,5.34-.73,12.33-1.16,17.56,0,6.26,1.35,11.37,6.92,17.75,7a13.73,13.73,0,0,0,.19-2.24c5.77,5.65,14.08,8.19,23.85,6.88,2.46-12.35-12.59-12.38-10.18-20.35,1.84-6,16.28-9.22,20.9-10.87,7.91-2.84,19.47-6.11,23.34-14.86,4.74-10.78.68-15.88-8.07-15.92a25,25,0,0,0-7.45,1.25A23.61,23.61,0,0,1,999.64,79a22,22,0,0,1-15.09-6.75c-1-.13-2-.25-3.07-.35-1.85-.17-3.37-.37-4.6-.5a23.32,23.32,0,0,1,.62,5.46,23.59,23.59,0,0,1-3.86,12.7,10.59,10.59,0,0,1-.48-1.09,0,0,0,0,1,0,0c-.09-.23-.18-.44-.26-.67l-.09-.21c-.06-.22-.15-.43-.22-.64a2.17,2.17,0,0,1-.07-.24c-.13-.39-.25-.76-.36-1.14,0-.11,0-.22-.08-.33s-.08-.35-.13-.52-.06-.31-.1-.47a3.86,3.86,0,0,1-.1-.49l-.12-.56c0-.13,0-.28-.07-.41,0-.33-.09-.64-.14-1a2.83,2.83,0,0,1,0-.3,6,6,0,0,1-.07-.74,2.52,2.52,0,0,1-.05-.37c0-.25,0-.49,0-.74s0-.22,0-.33c0-.36,0-.71,0-1.06s0-.86,0-1.28,0-.85,0-1.28c0-.23,0-.47.08-.7.19-1.64.31-3.29.38-4.94a20.52,20.52,0,0,1-2.2-2.26c-.7-1.13-1.41-2.26-2.13-3.4A85.76,85.76,0,0,0,954,60.68c-11.62-2.56-70.32-4.37-69.26,15.31h.13c-5.67,5.07-9,12.73-7.24,24.23,2.24-.93,5.86.11,8.1-1.13C892,114.82,861.7,117.24,874.2,131.15Z" fill="#fff"/>
        <path d="M689.72-6c3.95,12.82,19,67,20,75s3,17,0,26-8,23,11,30,29,11,33,12,6,1,11-3,17-21,13-29-16-22-21-35-22-69-23-77-9-39-10-46,6-18,4-26-16-17-24-9-11,15-24,13-25,0-27,14c-3.81,26.71,29.06,26.65,32,34C686.72-22,685.72-19,689.72-6Z" fill="#fff"/>
      </g>
    </svg>
  );
};
