const SET_HELPER_STAGE_AND_MESSAGE = '[Helper Reducer] SET_HELPER_STAGE_AND_MESSAGE';
const SET_SHOW_HELPER_WORDS = '[Helper Reducer] SET_HELPER_STAGE_AND_MESSAGE';

export const helperStateActions = {
  SET_HELPER_STAGE_AND_MESSAGE,
  SET_SHOW_HELPER_WORDS,
};

export const setHelperStageAndSettings = (stage, settings) => {
  return {
    type: helperStateActions.SET_HELPER_STAGE_AND_MESSAGE,
    payload: { stage, settings },
  };
};

export const setShowHelperWords = (message) => {
  return {
    type: helperStateActions.SET_SHOW_HELPER_WORDS,
    payload: message,
  };
};

