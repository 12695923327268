import { createStreamName, getStreamLevel } from 'app/util/streams';

export const getWordsByStreamParams = function(grade, streamName, wordList) {
  let streamLevel;
  let stream;

  if (grade !== 'all' && streamName !== 'all') {
    streamLevel = getStreamLevel(streamName); 
    stream = createStreamName(streamLevel, grade);
  } else if (grade === 'all') {
    stream = 'all';
  } else {
    stream = grade + '-all_streams' || 'all';
  }

  let params = '';
  params += stream;
  if (wordList && wordList.length > 0) {
    params += '?wordList=' + wordList;
  }

  return params;
};

export const getUpTo = (batchNumber, batchAmount) => {
  return batchNumber * batchAmount;
};

export const getNextBatchOfWords = (words, batchNumber, batchAmount) => {
  const upTo = getUpTo(batchNumber, batchAmount);
  if (upTo > words.length)
    return words;
  else
    return words.slice(0, upTo);
};
