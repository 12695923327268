import { isSelectKey } from 'app/constants/keys.constant';
import store from 'app/store';
import { resetRequest } from 'app/store/actions/api-requests.action';
import { changeCurrentClass, changeCurrentStudent, classesDataActionCreators, getDemoStudentProfiles, resetClasses, resetStudents } from 'app/store/actions/classes-data.actions';
import { isDemoBK } from 'app/util/demo';

export const dispatchResetClasses = (dispatch) => {
  dispatch(resetClasses());
  dispatch(resetRequest('getStudentStandaloneProfilesStatus'));
  dispatch(resetRequest('getStudentCDAProfilesStatus'));
  dispatch(resetRequest('getTeacherSectionStatsStatus'));
};

export const handleClassSelectionWrapper = (dispatch) => {
  return (e, bk) => {
    handleClassSelection(dispatch, e, bk);
  };
};

export const handleClassSelection = (dispatch, e, bk) => {
  e.preventDefault();

  if (e.key && !isSelectKey(e.key))
    return;

  const { licenses, kcInfo } = store.getState().userData;

  dispatchResetClasses(dispatch);

  // Change current class key
  dispatch(changeCurrentClass(bk));

  const isDemo = isDemoBK(bk);

  // Reset loading status of classes loaded
  if (isDemo) {
    dispatch(getDemoStudentProfiles(kcInfo));
  } else if (!licenses.cda) {
    dispatch(classesDataActionCreators.getStandaloneStudentProfiles.request());
  } else {
    dispatch(classesDataActionCreators.getCDAStudentProfiles.request());
  }
};

export const handleStudentSelectionWrapper = (dispatch) => {
  return (e, bk) => {
    handleStudentSelection(dispatch, e, bk);
  };
};

export const handleStudentSelection = (dispatch, e, bk) => {
  e.preventDefault();
  if (e.key && !isSelectKey(e.key))
    return;

  dispatch(changeCurrentStudent(bk));
  dispatch(classesDataActionCreators.getUserStats.request());
};

export const handleBackButtonWrapper = (dispatch) => {
  return (e) => {
    handleBackButton(dispatch, e);
  };
};

export const handleBackButton = (dispatch, e) => {
  e.preventDefault();
  if (e.key && !isSelectKey(e.key))
    return;

  const { currentStudentBK, currentClassBK  } = store.getState().classesData;

  if (currentStudentBK != null) {
    // Curent page individual student
    dispatch(resetStudents());
  } else if (currentClassBK != null) {
    // Current page with all students for class
    dispatchResetClasses(dispatch);
  }
};
