import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
 
import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import { ReportingPageFooter } from 'app/components/footer/reporting-page-footer/reporting-page-footer.component';
import { ReportingPageHeader } from 'app/components/header/reporting-page-header/reporting-page-header.component';
import { Tabs } from 'app/components/tabs/tabs.component';
import { TAB_KEYS, TAB_VALUES } from 'app/constants/tabs.constants';
import { SessionStorageService } from 'app/services/session-storage.service';

import 'app/components/reporting/wrapper/reporting-page-wrapper.scss';

export const ReportingPageWrapperComponent = props => {
  const { children } = props;

  const teacherActivitySetting = SessionStorageService.getTeacherDoingActivity();

  return (
    <div className="reporting-page-content">
      <h1 className="sr-only">Reporting Page</h1>
      <ErrorBoundary
        FallbackComponent={ErrorScreen} 
      >
        <Tabs tabKey={TAB_KEYS.REPORTING_PAGE} defaultValue={teacherActivitySetting ? TAB_VALUES.REPORTING_PAGE.WORDS : TAB_VALUES.REPORTING_PAGE.STUDENTS}>
          <ReportingPageHeader/>
          <div  aria-live="polite" className="reporting">
            {children}
          </div>
        </Tabs> 
        <ReportingPageFooter/>
      </ErrorBoundary>
    </div>
  );
};
