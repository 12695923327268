import React, { Suspense, useEffect, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import { IntercomWrapper } from 'app/components/intercom/intercom';
import { Navbar } from 'app/components/navbar/navbar';
import { generateConfig } from 'app/components/navbar/navconfig';
import { ScreenRotate } from 'app/components/screen-rotate/screen-rotate.component';
import { SessionTimeout } from 'app/components/session-timeout/session-timeout';
import rootRoutes from 'app/routes';

import 'app/App.scss';

export var App = () => {
  const { isTeacher } = useSelector(state => state.userData?.kcInfo);
  const [navbarConfig, setNavbarConfig] = useState(undefined);
  const [isPortrait, setIsPortrait] = useState(false);

  const getScreenOrientation = () =>
  {
    if (window.innerHeight > window.innerWidth)
      setIsPortrait(true);
    else
      setIsPortrait(false);
  };

  useEffect(() => {
    setNavbarConfig(generateConfig(window.kc, isTeacher));
  }, [isTeacher]);

  useEffect(() => {
    getScreenOrientation();
    window.addEventListener('resize', getScreenOrientation);
  }, []);


  return (

    <div id="app_main" aria-live="polite">
      <Suspense fallback={<div className="loading">Loading...</div>}>
        <div aria-hidden={isPortrait} className="wrapper">
          {!!navbarConfig && <Navbar config={navbarConfig}/>}
          {/* eslint-disable-next-line no-undef */}
          <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME ?? '/'}>
            <ErrorBoundary
              FallbackComponent={ErrorScreen}
            >
              <Routes>
                {rootRoutes.map((route) => (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
          {isTeacher && <IntercomWrapper />}
        </div>
        <SessionTimeout />
        {/* Rotate screen if iPad is in portrait mode */}
        { isPortrait && <div className="iPad-orientation">
          <ScreenRotate />
        </div>}
      </Suspense>
    </div>

  );
};

export default App;
