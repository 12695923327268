import React, { useRef } from 'react';

import 'app/components/settings/limit-words/limit-words.setting.scss';

import { useSelector, useDispatch } from 'react-redux';

import ApiClient from 'app/api/api';
import { Loader } from 'app/components/loader/loader';
import { DEFAULT_LIMIT_WORDS } from 'app/constants/settings.constants';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { handleStyledCheckboxKeySelection } from 'app/util/handlers/settings';


export const LimitedActivitiesSetting = (props) => {

  const dispatch = useDispatch();

  const { triggerConfirm } = props;

  const { activitiesLimitation, students, currentClassBK } = useSelector(state => state.classesData);

  const numberInputRef = useRef();

  const unlimitedOptionRef = useRef();
  const numberOptionRef = useRef();

  const onRadioChange = (e) => {
    if (e.target == numberOptionRef.current)
      numberInputRef.current.disabled = false;
    else
      numberInputRef.current.disabled = true;
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    e.target.innerText = 'SAVING';
    e.target.disabled = true;

    const bks = [currentClassBK, ...Object.keys(students)];
    let counts;

    if (unlimitedOptionRef.current.checked)
      counts = -1;
    else 
      counts = numberInputRef.current.value;

    // If counts are 0, we want to default to 10
    counts = (counts == 0) ? DEFAULT_LIMIT_WORDS : counts;

    ApiClient.submitLimitWords(bks, counts).then(() => {
      triggerConfirm('Limit Words', true);
      dispatch(classesDataActionCreators.getActivitiesLimitation.request());
    }).catch(() => {
      triggerConfirm('Limit Words', false);
    });

  };

  return (
    activitiesLimitation != undefined ?
      <div id='menu' className='tab-pane' aria-describedby='limit-words-setting-descriptor'>
        <h3 id='limit-words-setting-descriptor'>Set the limit of new words per day for this class</h3>
        <ul role="radiogroup">

          <li className='listOption'>
            <label className='container' htmlFor='limitActivitiesUnlimited' >
              <input
                className='styled'
                type='radio'
                value='Unlimited'
                name='limitActivities'
                id='limitActivitiesUnlimited'
                onChange={onRadioChange}
                defaultChecked={activitiesLimitation < 0}
                ref={unlimitedOptionRef}
                tabIndex="-1"
              />
              <span className="checkmark" tabIndex="0"
                onKeyUp={e => handleStyledCheckboxKeySelection(e, unlimitedOptionRef.current)}
                aria-label="Radio Button" role="radio" aria-checked={activitiesLimitation < 0}
              />
              Unlimited
            </label>
          </li>

          <li className='listOption'>
            <label className='container' htmlFor='setLimitActivities' >
              <input
                className='styled'
                type='radio'
                value='SetLimit'
                name='limitActivities'
                id='setLimitActivities'
                ref={numberOptionRef}
                onChange={onRadioChange}
                defaultChecked={activitiesLimitation >= 0}
                aria-hidden="true"
                tabIndex="-1"
              />
              <span className="checkmark" tabIndex="0"
                onKeyUp={e => handleStyledCheckboxKeySelection(e, numberOptionRef.current)}
                aria-label="Radio Button" role="radio" aria-checked={activitiesLimitation >= 0}
              />  
              Set Limit:
              <input
                type='number'
                id='name'
                name='name'
                aria-label="Set Words Limit"
                min={1}
                ref={numberInputRef}
                defaultValue={activitiesLimitation < 0 ? DEFAULT_LIMIT_WORDS : activitiesLimitation }
                disabled={false}
              />
            </label>
            
          </li>

        </ul>
        <button className='confirmButton' onClick={handleConfirm}>Confirm</button>
      </div>
      :
      <Loader />
  );
};