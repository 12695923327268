import academic_icon from 'app/shared/images/stream-icons/ACADEMIC.svg';
import bridging_icon from 'app/shared/images/stream-icons/BRIDGING.svg';
import challenge_icon from 'app/shared/images/stream-icons/CHALLENGE.svg';
import core_icon from 'app/shared/images/stream-icons/CORE.svg';
import emerging_icon from 'app/shared/images/stream-icons/EMERGING.svg';
import expanding_icon from 'app/shared/images/stream-icons/EXPANDING.svg';
import striving_icon from 'app/shared/images/stream-icons/STRIVING.svg';

export const emptyGrade = 'None';
export const emptyStream = 'None';
export const NaN_stream = 'NaN';
export const streamWithGradePrefix = 'S_G';
export const standaloneELLStreamPrefix = 'S_ELL';
export const academicStream = 'S_Academic';
export const gradePrefix = 'G';
export const coreSuffix = 'th Grade';

export const streamMapping = {
  Emerging: 'Emerging-ELL Dev',
  Expanding: 'Expanding-Moderate',
  Bridging: 'Bridging-Light',
  BelowGrade: 'Substantial',
  AtGrade: 'Core',
  AboveGrade: 'Challenge',
  None: 'None',
  Across: 'Across',
  Academic: 'Academic'
};

export const DEFAULT_USER_STREAM = 'S_G5_AtGrade';
export const DEFAULT_USER_GRADE = 5;

export const uiStreams = [
  { id: 39826, name: 'S_ELL1_Emerging', grade: 0, order: 1, uiName: 'Emerging-ELL Dev', icon: emerging_icon, },
  { id: 39827, name: 'S_ELL2_Expanding', grade: 0, order: 2, uiName: 'Expanding-Moderate', icon: expanding_icon, },
  { id: 39828, name: 'S_ELL3_Bridging', grade: 0, order: 3, uiName: 'Bridging-Light', icon: bridging_icon, },
  { id: 39834, name: 'S_G{}_BelowGrade', order: 1, uiName: 'Substantial', icon: striving_icon, },
  { id: 39833, name: 'S_G{}_AtGrade', order: 2, uiName: 'Core', icon: core_icon, },
  { id: 39832, name: 'S_G{}_AboveGrade', order: 3, uiName: 'Challenge', icon: challenge_icon, },
  { id: 39825, name: 'S_Academic', grade: 0, uiName: 'Academic', icon: academic_icon, },
];

export const streamOrderCategories = {
  ELL: ['Emerging', 'Expanding', 'Bridging'],
  Grade: ['BelowGrade', 'AtGrade', 'AboveGrade'],
  Academic: ['Academic'],
};

export const streamOrders = {
  Emerging: 1,
  Expanding: 2,
  Bridging: 3,
  BelowGrade: 1,
  AtGrade: 2,
  AboveGrade: 3
};

export const grades = [
  'K',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
];

export const cklaStreams = [
  {
    id: 39839,
    name: 'S_G3_AtGrade',
    grade: 3,
    order: 2
  },
  {
    id: 40081,
    name: 'S_G4_AtGrade',
    grade: 4,
    order: 2
  },
  {
    id: 40084,
    name: 'S_G5_AtGrade',
    grade: 5,
    order: 2
  }
];
