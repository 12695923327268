import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { CONFIG } from 'app/config';
import 'app/shared/scripts/intercom';

export const IntercomWrapper = () => {

  const user = useSelector(state => state.userData.kcInfo);

  useEffect(() => {
    if (user.intercomHash) {
      window.intercomSettings = {
        vertical_padding: 100,
      };
      window.Intercom('boot', {
        app_id: CONFIG.INTERCOM_APPLICATION_ID,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        user_hash: user.intercomHash,
        created_at: (new Date()).getTime(),
      });
    }
  }, [user]);

  return (
    <></>
  );
};
