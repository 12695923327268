import { useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';

import clsx from 'clsx';

import { Box } from 'app/components/syn-ant-activity/dnd/box.component';
import { Dropzone } from 'app/components/syn-ant-activity/dnd/dropzone.component';
import { ANSWERS, OPTIONS } from 'app/constants/syn-ant.constants';

export const Container = forwardRef((
  { DropzoneInactive, DropzoneActive, BoxActive, BoxInactive, boxPreview, 
    type, correctWords, words, getFinishedData, checkIfAnswered }, ref) => {
  const [dropzones, setDropzones] = useState(correctWords);
  const [startzones, setStartZones] = useState(words);
  const [isDragBlocked, setDragBlocked] = useState(false);

  useImperativeHandle(ref, () => ({

    setUpData() {
      getFinishedData(
        {
          options: startzones,
          answers: dropzones
        }
      );
    },

    showFeedback(answers, options) {

      setStartZones(startzones.map((word) => {
        if(!options.find((w) => w.id == word.id)) {
          word['hideOption'] = true;
        }

        return word;
      }));

      setDropzones(dropzones.map((word) => {
        if(answers.find((w) => w.id == word.id)) {
          word['correct'] = true;
        } else {
          word['hideOption'] = true;
        }
        return word;
      }));

      setDragBlocked(true);

    }

  }));

  useEffect(() => {
    const bins = correctWords.map(item => {
      item = { id: null, word: null };
      return item;
    });
    setDropzones(bins);

    const startBins = words.map(item => {
      item = { id: item.id, word: item.word };
      return item;
    });
    setStartZones(startBins);
  }, [correctWords]);

  const handleDrop = useCallback((listType, dropList, setDropList, index, item) => {    
    resetDropzones(item, dropList);
    dropList[index] = item;
    setDropList([...dropList]);
    checkIfAnswered(listType, dropList);
  });

  const resetDropzones = useCallback((item, dropList, setDropList) => {
    const indexDelete = dropList.map(object => object.id).indexOf(item.id);
    
    if(indexDelete >= 0) {
      dropList[indexDelete] = { id: null, word: null };
    }    

    if (typeof setDropList === 'function')  setDropList([...dropList]);
  });

  return (
    <div>
      <div className={`dropzones ${clsx({ fourZones: dropzones.length > 3 })}`}>
        {dropzones.map((droppedWord, index) => (
          <Dropzone
            DropzoneInactive={DropzoneInactive}
            DropzoneActive={DropzoneActive}
            correctOption={droppedWord.correct}
            wordId={droppedWord.id}
            hideOption={droppedWord.hideOption}
            accept={type}
            onDrop={(item) => {
              handleDrop(ANSWERS, dropzones, setDropzones, index, item); 
              resetDropzones(item, startzones, setStartZones);
            }}
            key={index}
          >
            { droppedWord && droppedWord.id && 
              <Box
                boxImage={boxPreview}
                BoxActive={BoxActive}
                BoxInactive={BoxInactive}
                isDragBlocked={isDragBlocked}
                correctOption={droppedWord.correct}
                hideOption={droppedWord.hideOption}
                type={type}
                word={droppedWord.word}
                id={droppedWord.id}
                key={index}
              />
            }
          </Dropzone>
        ))}
      </div>

      <div className={`boxes ${clsx({ eightOptions: startzones.length > 6 })}`}>
        {startzones.map((word, index) => (
          
          <Dropzone
            DropzoneInactive={BoxInactive}
            DropzoneActive={BoxInactive}
            hideOption={word.hideOption}
            wordId={word.id}
            accept={type}
            onDrop={(item) => {
              handleDrop(OPTIONS, startzones, setStartZones, index, item); 
              resetDropzones(item, dropzones, setDropzones);
            }}
            key={index}
          >
            { word && word.id &&
              <Box
                boxImage={boxPreview}
                BoxActive={BoxActive}
                BoxInactive={BoxInactive}
                isDragBlocked={isDragBlocked}
                hideOption={word.hideOption}
                type={type}
                word={word.word}
                id={word.id}
                key={index}
              /> 
            }
          </Dropzone>
        ))}
      </div>
    </div>
  );
});

Container.displayName = 'Container';
