import { API_ACTION_TYPES, API_REQUEST_STATUS, TYPE_TO_STATE } from 'app/constants/api.constants';
import { SET_IDLE } from 'app/store/actions/api-requests.action';

const getInitialIdleStatuses = () => {
  const idleStatuses = {};
  Object.values(API_ACTION_TYPES).forEach(actionType => {
    idleStatuses[TYPE_TO_STATE[actionType]] = {
      status: API_REQUEST_STATUS.IDLE,
      error: undefined,
    };
  });
  return idleStatuses;
};

export const initialState = {
  ...getInitialIdleStatuses(),
};

export default function apiStatusReducer (state = initialState, action = {}) {
  const error = action.payload?.error;
  
  // Static API Handles
  switch(action.type) {
  case SET_IDLE: {
    const tempState = state;
    tempState[action.payload] = {
      status: API_REQUEST_STATUS.IDLE,
      error: undefined,
    };

    return {
      ...tempState,
    };
  }
  }

  // API Action Types
  for (const apiActionType of Object.values(API_ACTION_TYPES)) {
    switch (action.type) {
    case `${apiActionType}_FAILURE`:
      return {
        ...state,
        [TYPE_TO_STATE[apiActionType]]: {
          ...[TYPE_TO_STATE[apiActionType]],
          status: API_REQUEST_STATUS.FAIL,
          error: error,
        },
      };
    case `${apiActionType}_REQUEST`:
      return {
        ...state,
        [TYPE_TO_STATE[apiActionType]]: {
          ...[TYPE_TO_STATE[apiActionType]],
          status: API_REQUEST_STATUS.REQUEST,
          error: undefined,
        },
      };
    case `${apiActionType}_SUCCESS`:
      return {
        ...state,
        [TYPE_TO_STATE[apiActionType]]: {
          ...[TYPE_TO_STATE[apiActionType]],
          status:  API_REQUEST_STATUS.SUCCESS,
          error: false,
        },
      };
    }
  }
  return state;
}

// Status Checkers
export const isIdle = apiRequestStatus => apiRequestStatus.status === API_REQUEST_STATUS.IDLE;
export const isSuccess = apiRequestStatus => apiRequestStatus.status === API_REQUEST_STATUS.SUCCESS;
export const isFail = apiRequestStatus => apiRequestStatus.status === API_REQUEST_STATUS.FAIL;
export const isLoading = apiRequestStatus => apiRequestStatus.status === API_REQUEST_STATUS.REQUEST;
export const hasResolved = apiRequestStatus => {
  return apiRequestStatus.status === API_REQUEST_STATUS.SUCCESS || apiRequestStatus.status === API_REQUEST_STATUS.FAIL;
};
export const notPending = apiRequestStatus => { // Any status, just don't be pending!
  return apiRequestStatus.status !== API_REQUEST_STATUS.PENDING;
};
export const isPending = apiRequestStatus => apiRequestStatus.status === API_REQUEST_STATUS.PENDING;

// Error handlers
export const getError = apiRequestStatus => isFail(apiRequestStatus) ? apiRequestStatus.error : false;
