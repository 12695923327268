import React from 'react';

import clsx from 'clsx';
import { element, string } from 'prop-types';
import 'app/components/footer/footer.scss';

export const Footer = props => {
  const { children, classNames } = props;

  return (
    <footer className={clsx(
      'footer',
      classNames
    )}
    role="contentinfo">
      {children}
    </footer>
  );
};

Footer.defaultProps = {
  children: null,
  className: ''
};

Footer.propTypes = {
  children: element,
  classNames: string,
};


