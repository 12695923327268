import clsx  from 'clsx';
import { useDrag } from 'react-dnd';

import { CORRECT_CLASS, HIDE_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';
import { DragPreview } from 'app/components/syn-ant-activity/dnd/box-preview.component';

export const Box = ({ BoxInactive, BoxActive, boxImage, word, id, type, isDragBlocked, hideOption = false, correctOption = false }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      canDrag: !isDragBlocked,
      item: { id, word },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [type, id, isDragBlocked],
  );

  return (
    <>
      <div className="box" ref={drag} draggable={id}>
        {isDragging ? 
          <>
            <DragPreview boxImage={boxImage} word={word}/>
            <BoxInactive />
          </>
          : 
          <>
            <BoxActive classNames={`${correctOption ? CORRECT_CLASS : ''} ${hideOption ? HIDE_CLASS : ''}`} />
            <span className={clsx('word', hideOption ? HIDE_CLASS : '')}>{word}</span>
          </> 
        }
      
      </div>
    </>
    
  );
};