import Keycloak from 'keycloak-js';

import { CONFIG } from 'app/config';

const UPDATE_TOKEN_INTERVAL = 5 * 60 * 1000; // 5 minutes

const keycloakConfig = {
  realm: 'Amplify',
  url: CONFIG.AUTH_URL,
  clientId: 'vocab-react',
};

const keycloakInitConfig = {
  checkLoginIframe: false,
  onLoad: 'login-required',
};

class AuthService {
  constructor() {
    this._keycloakClient = Keycloak(keycloakConfig);
  }

  init() {
    return new Promise((resolve, reject) => {
      this._keycloakClient
        .init(keycloakInitConfig)
        .then(resolve)
        .catch(reject);
    }).then(() => {
      window.kc = this._keycloakClient;
      setInterval(() => {
        this._keycloakClient.updateToken(0).catch(() => this.logout());
      }, UPDATE_TOKEN_INTERVAL);
    });
  }

  getCurrentUser() {
    return this._keycloakClient.loadUserInfo();
  }

  getAccessToken() {
    return this._keycloakClient.token;
  }

  getUserBk() {
    return this._keycloakClient.subject;
  }

  getRoles() {
    return this._keycloakClient.realmAccess?.roles;
  }

  logout() {
    this._keycloakClient.logout();
  }

  logoutTo(url) {
    this._keycloakClient.logout({ redirectUri: url });
  }
}

export default new AuthService();
