import { profileDataActions } from 'app/store/actions/profile-data.actions';

export const initialState = {
  stats: {
    accuracyPercentage: null,
    activitiesCompleted: null,
    activitiesCorrect: null,
    expPercentToNextLevel: null,
    level: null,
    longestStreak: null,
    statsPerDay: [],
    statsPerMonth: [],
    statsPerWeek: [],
    wordsMastered: null
  },
  latestProfile: {
    csUserBusinessKey: null,
    streamName: null,
    standaloneGrade: null,
    curriculumGrade: null,
    curriculumLesson: null,
    curriculumUnit: null,
    curriculumKeyText: null,
    questId: null,
    wordListBusinessKey: [],
  },
  currentProfile: {
    csUserBusinessKey: null,
    streamName: null,
    standaloneGrade: null,
    curriculumGrade: null,
    curriculumLesson: null,
    curriculumUnit: null,
    curriculumKeyText: null,
    questId: null,
    wordListBusinessKey: [],
    arId: null,
  },
  masteredWords: {
    words: [],
    pageCurrent: 1,
    pageMax: 1,
    pageSize: 10,
  }
};

function profileDataReducer(state = initialState, action) {
  switch (action.type) {
  case profileDataActions.GET_USER_STATS.SUCCESS:
    return {
      ...state,
      ...action.payload,
    };

  case profileDataActions.GET_LATEST_PROFILE.SUCCESS:
    return {
      ...state,
      ...action.payload,
    };

  case profileDataActions.SET_AR_PROFILE.SUCCESS:
    return {
      ...state,
      currentProfile: action.payload,
    };

  case profileDataActions.SET_CKLA_PROFILE.SUCCESS:
    return {
      ...state,
      currentProfile: action.payload,
    };

  case profileDataActions.GET_MASTERED_WORDS.SUCCESS:
    return {
      ...state,
      masteredWords: action.payload,
    };

  default:
    return state;
  }
}

export default profileDataReducer;
