import React, { useState } from 'react';

import { gsap } from 'gsap';

import 'app/components/multiple-choice/sunkennotebooks/sunkennotebooks.scss';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { Title } from 'app/components/multiple-choice/sunkennotebooks/animated/title.component';
import { Transition } from 'app/components/multiple-choice/sunkennotebooks/animated/transition.component';
import bookCloseup from 'app/components/multiple-choice/sunkennotebooks/images/book-closeup.svg';
import bookSimplified from 'app/components/multiple-choice/sunkennotebooks/images/book-simplified.svg';
import bubbles from 'app/components/multiple-choice/sunkennotebooks/images/bubbles.svg';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

export const SunkenNotebooks = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const activityClassName = 'notebooks';

  const articleScreen = () => <>
    <div className="title-screen">
      <Title/>
    </div>
    <img src={bookSimplified} alt="" aria-hidden="true" className="notebook-bg" />
  </>;

  const questionScreen = () => <></>;

  const choicesScreen = () => <>
    <img src={bubbles} alt="" aria-hidden="true" className="notebook-bubbles" />
    <img src={bookCloseup} alt="" aria-hidden="true" className="notebook-bg-big" />
  </>;

  const feedbackScreen = () => <>
    <img src={bookSimplified} alt="" aria-hidden="true" className="notebook-bg-feedback" />
    <div className="feedback-animation">
      <Transition />
    </div>
  </>;

  const animations = {
    [ARTICLE]: function () {
      const tl = gsap.timeline()
        .to('.title-screen', { opacity: 0, delay: 6, display: 'none', duration: 0.5 })
        .fromTo('.notebook-bg', { opacity: 0, scale: 0 }, { scale: 1, opacity: 1, ease: 'sine.in', duration: 0.8 }, 'same-time')
        .fromTo('.activity-card', { opacity: 0, scale: 0 }, { scale: 1, opacity: 1, ease: 'sine.in', duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 0, opacity: 0, duration: 0.4 }, 'same-time')
        .to('.notebook-bg', { scale: 0, opacity: 0, duration: 0.4 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 1, opacity: 1, ease: 'sine.in', duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 0, opacity: 0, duration: 0.4 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 1, opacity: 1, ease: 'sine.in', duration: 0.8 }, 'same-time')
        .fromTo('.answers', { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 0.8 })
        .fromTo('.answer-button', { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 1, stagger: 0.3 })
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 0, opacity: 0, duration: 0.4 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 1, opacity: 1, ease: 'sine.in', duration: 0.8 }, 'same-time')
        .to('.feedback-animation', { duration: 0.1, opacity: 0, display: 'none', delay: 5 })
        .add(gsap.from('.feedback', { duration: 0.1, opacity: 0 }), 2.75)
        .from('.feedback-next-container', { duration: 0.5, opacity: 0 })
        .call(()=> tl.kill());
      tl.play(0);
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
