import AuthService from 'app/services/auth.service';

export const requestSuccessInterceptor = async config => {
  const token = AuthService.getAccessToken();
  config.headers.Authorization = `bearer ${token}`;
  return config;
};

export const requestErrorInterceptor = error => {
  return Promise.reject(error);
};
