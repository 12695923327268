import { Buffer } from 'buffer';

import { useDispatch } from 'react-redux';

import { isDevEnvironment } from 'app/config';


const MultipleChoiceDevTools = (props) => {

  const {
    handleClick,
    onFinished,
    navigate,
    currentActivity
  } = props;

  const dispatch = useDispatch();

  const devSubmitAnswer = (answer) => {
    handleClick(answer)({ button: 0 });
    onFinished(navigate, dispatch);
  };

  const getCorrectAnswerOption = () => {
    const correctAnswerLetter = Buffer.from(currentActivity.correctAnswer, 'base64').toString();
    return correctAnswerLetter.charCodeAt(0) - 'A'.charCodeAt(0);
  };

  const sendAnyAnswer = () => {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    const option = getRandomInt(currentActivity.answers.length);
    const answer = currentActivity.answers[option];
    devSubmitAnswer(answer);
  };

  const sendCorrectAnswer = () => {
    const answer = currentActivity.answers[getCorrectAnswerOption()];
    devSubmitAnswer(answer);
  };

  const sendIncorrectAnswer = () => {
    const incorrectAnswer = (getCorrectAnswerOption() + 1) % currentActivity.answers.length;
    const answer = currentActivity.answers[incorrectAnswer];
    devSubmitAnswer(answer);
  };


  return (
    isDevEnvironment() ?
      <div className='dev-wrapper'>
        <button className='devci-button' onClick={sendAnyAnswer}>DEV: ANSWER ANY</button>
        <button className='devci-button' onClick={sendCorrectAnswer}>DEV: ANSWER CORRECT</button>
        <button className='devci-button' onClick={sendIncorrectAnswer}>DEV: ANSWER INCORRECT</button>
      </div>
      :
      <></>
  );
};

export default MultipleChoiceDevTools;
