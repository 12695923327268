import React from 'react';

import clsx from 'clsx';
import { string } from 'prop-types';

export const InternetIcon = (props) => {
  const { classNames } = props;
  return (
    <svg className={clsx(classNames)} width={32} height={41} viewBox="0 0 32 41" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <path d="M21 6c1.333 1.333 2 2.667 2 4 0 1.333-.667 2.667-2 4" stroke="#FFF" strokeWidth={3} />
        <path d="M21 0c4 2.853 6 6.11 6 9.771 0 3.662-2 6.738-6 9.229M6 6c-1.333 1.333-2 2.667-2 4 0 1.333.667 2.667 2 4" stroke="#FFF" strokeWidth={3} />
        <path d="M6 0C2 2.853 0 6.11 0 9.771 0 13.433 2 16.51 6 19" stroke="#FFF" strokeWidth={3} />
        <path d="M13.51 19.134c.975 3.569 1.851 6.35 2.626 8.34.89 2.288 2.154 4.856 3.798 7.702-2.68-.454-4.824-.676-6.434-.676-1.603 0-3.737.22-6.4.67 1.573-2.718 2.826-5.284 3.764-7.695.82-2.107 1.702-4.887 2.646-8.341z" stroke="#FFF" strokeWidth={4} />
        <circle fill="#FFF" cx="13.5" cy="9.5" r="4.5" />
      </g>
    </svg>
  );
};

InternetIcon.propTypes = {
  classNames: string,
};
