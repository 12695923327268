import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import book from 'app/components/multiple-choice/zombie/images/zombie_booktitle.svg';

export const Title = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .set('#zombie-book', { y: 700, transformOrigin: '0% 100%', rotate: 45 })
      .to('#zombie-book', { duration: 0.5, y: 0, rotate: 0, delay: 1.5, ease: 'sine.out' })
      .to('#zombie-book', { duration: 0.1, y: 7, x: 3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 0, x: -3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 5, x: 0, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 10, x: 5, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 4, x: -2, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 7, x: 3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 0, x: 0, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 7, x: 3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 0, x: -3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 5, x: 0, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 0, x: 3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 10, x: -2, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 4, x: 3, ease: 'sine.in' })
      .to('#zombie-book', { duration: 0.1, y: 0, x: 0, ease: 'sine.in' })
      .to('#zombie-book', { duration: 1, y: 700, rotate: 45, ease: 'sine.in' });

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  }, []);

  return (
    <div id="zombie_title">
      <img id="zombie-book" className="title-zombie-book" src={book} alt="" aria-hidden="true" />
    </div>
  );
};
