import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import phone from 'app/components/multiple-choice/bestmeal/images/phone.svg';
import phoneScreen from 'app/components/multiple-choice/bestmeal/images/phone_screen.svg';
import phoneScreenWhite from 'app/components/multiple-choice/bestmeal/images/phone_screen_white.svg';
import restaurant from 'app/components/multiple-choice/bestmeal/images/title_bg.svg';

export const Title = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .fromTo('#restaurant', { scale: 0, opacity: 0 }, { scale:1, opacity: 1, ease: 'sine.in', duration: 0.8 })
      .fromTo('#phone', { rotation: 90, xPercent: 75, yPercent: 100 }, { rotation: 0, xPercent: -50, yPercent: -9, duration: 1 })
      .fromTo('#white', { opacity: 0, scaleY: 0.01, xPercent: -50 }, { opacity: 1, scaleY: 1, transformOrigin: '50% 50%', duration: 0.5, ease: 'power2.in' })
      .fromTo('#screen', { opacity: 0, xPercent: -50 }, { opacity: 1, duration: 0.5, ease: 'power2.in' })
      .to('.title-screen', { delay: 1.5, opacity: 0, display: 'none', duration: 0.5 })
      .to('#restaurant_title', { delay: 1, duration: 0.8, opacity: 0, display: 'none', ease: 'sine.out' });

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  }, []);

  return (
    <div id="restaurant_title">
      <img id="restaurant" className="title-background" alt="" src={restaurant} aria-hidden="true" />
      <img id="phone" className="title-phone" alt="" src={phone} aria-hidden="true" />
      <img id="white" className="title-phone phone-screen" alt="" src={phoneScreenWhite} aria-hidden="true" />
      <img id="screen" className="title-phone phone-screen" alt="" src={phoneScreen} aria-hidden="true" />
    </div>
  );
};
