import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { setWordsStreamFilter } from 'app/store/actions/words-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';
import { getWordsDropdownStreams } from 'app/util/streams';


export const WordsStreamDropdown = () => {
  const dispatch = useDispatch();

  const { streams } = useSelector(state => state.streamsReducer);
  const { streamOption, gradeOption } = useSelector(state => state.wordsData.filters);

  const { getWordsByStreamStatus } = useSelector(state => state.apiReducer);

  const [dropdownOptions, setDropdownOptions] = useState();

  useEffect(() => {
    if(streams) {
      setDropdownOptions(getWordsDropdownStreams(streams));
    }
  }, [streams]);

  useEffect(() => {
    if (dropdownOptions) {
      dispatch(setWordsStreamFilter(dropdownOptions[0]));
    }
  }, [dropdownOptions]);

  useEffect(() => {
    if(gradeOption)
      setDropdownOptions(getWordsDropdownStreams(streams, gradeOption.key));
  }, [gradeOption]);

  const setCurrentWordsStream = (e, key) => {
    e.preventDefault();

    const selectedOption = _.find(dropdownOptions, (el) => { return el.key == key; });
    dispatch(setWordsStreamFilter(selectedOption));
  };

  const isDisabled = () => {
    return isLoading(getWordsByStreamStatus);
  };

  return (
    dropdownOptions && streamOption ?
      <div className="wordsStreamDropdown">
        <DropdownWrapper
          isDisabled={isDisabled}
          menuOptions={dropdownOptions}
          dropdownName={streamOption.value}
          selectHandler={setCurrentWordsStream}
        />
      </div>
      :
      <p>Loading...</p>
  );
};
