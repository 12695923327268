import React, { useRef } from 'react';

import 'app/components/settings/disabled-activities/disabled-activities.setting.scss';
import { useSelector, useDispatch } from 'react-redux';

import ApiClient from 'app/api/api';
import { Loader } from 'app/components/loader/loader';
import { activities } from 'app/constants/activities.constants';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { handleStyledCheckboxKeySelection } from 'app/util/handlers/settings';

export const DisabledActivitiesSetting = (props) => {

  const dispatch = useDispatch();

  const { triggerConfirm } = props;

  const { disabledActivities, students, currentClassBK } = useSelector(state => state.classesData);

  const { getDisabledActivitiesStatus } = useSelector(state => state.apiReducer);

  const inputRefs = useRef({});

  const handleConfirm = (e) => {
    e.preventDefault();
    
    e.target.innerText = 'SAVING';
    e.target.disabled = true;

    const activitiesToSubmit = [];
    Object.entries(inputRefs.current).map(([id, el]) => {
      activitiesToSubmit.push({
        id: id,
        name: el.value,
        disabled: el.checked,
      });
    });

    const promises = [];
    promises.push(ApiClient.submitDisabledActivities(activitiesToSubmit, currentClassBK));
    Object.keys(students).forEach(bk => {
      promises.push(ApiClient.submitDisabledActivities(activitiesToSubmit, bk));
    });
    Promise.all(promises).then((resList) => {
      const succeeded = resList.every(res => res.status == 200);

      if (succeeded) {
        triggerConfirm('Disabled Activities', true);
        dispatch(classesDataActionCreators.getDisabledActivities.request());
      } else {
        triggerConfirm('Disabled Activities', false);  
      }
    }).catch(() => {
      triggerConfirm('Disabled Activities', false);
    });

  };

  return (
    (disabledActivities && isSuccess(getDisabledActivitiesStatus)) ?
      <div id="menu" className="tab-pane" aria-describedby='disabled-activities-descriptor'>
        <h3 id="disabled-activities-descriptor">Select the activity types that you want to deactivate for this class</h3>
        <ul className="activityList">
          {
            Object.entries(disabledActivities).map(([group, activitiesList]) => {
              return (
                <div key={group}>
                  <li key={group} className="label">
                    {activities[group]}
                  </li>
                  {
                    activitiesList.map((activityDict) => {
                      return (
                        <li key={activityDict.id} className="listOption">
                          <label className="container" key={activityDict.id} htmlFor={`disabledActivities-${activityDict.name}`}>
                            <input
                              className='styled'
                              key={`${activityDict.id}-in`}
                              type="checkbox"
                              value={activityDict.name}
                              defaultChecked={activityDict.disabled}
                              ref={el => (inputRefs.current[activityDict.id] = el)}
                              name={`disabledActivities-${activityDict.name}`}
                              id={`disabledActivities-${activityDict.name}`}
                              aria-hidden="true"
                              tabIndex="-1"
                            />
                            {/* Disable */}
                            <span key={`${activityDict.id}-span`} className="checkmark" tabIndex="0"
                              onKeyUp={e => handleStyledCheckboxKeySelection(e, inputRefs.current[activityDict.id])}
                              aria-label="checkmark" role="checkbox" aria-checked={activityDict.disabled}
                            />
                            {activities[activityDict.name]}
                          </label>
                        </li>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </ul>
        <button className="confirmButton" onClick={handleConfirm}>Confirm</button>
      </div>
      :
      <Loader />
  );
};
