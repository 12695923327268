import React, { useState } from 'react';

import { gsap } from 'gsap';

import 'app/components/multiple-choice/doctor/doctor.scss';
import { DoctorTitle } from 'app/components/multiple-choice/doctor/animated/doctor-title.component';
import { DoctorTransition } from 'app/components/multiple-choice/doctor/animated/doctor-transition.component';
import skeletonHand from 'app/components/multiple-choice/doctor/images/doctor_skeleton-hand.svg';
import skeletonKnee from 'app/components/multiple-choice/doctor/images/doctor_skeleton-knee.svg';
import skeletonTorso from 'app/components/multiple-choice/doctor/images/doctor_skeleton-torso.svg';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

export const Doctor = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const activityClassName = 'doctor';

  const articleScreen = () => <>
    <div className="full-screen" id="black-background"></div>
    <div className="full-screen animated" id="doctor-title-screen">
      <div id="title" className="intro-title" aria-hidden="true"><DoctorTitle/></div>
    </div>
    <div id="skeleton-hand"><img src={skeletonHand} alt="" aria-hidden="true" /></div>
    <div id="skeleton-torso"><img src={skeletonTorso} alt="" aria-hidden="true" /></div>
    <div id="skeleton-knee"><img src={skeletonKnee} alt="" aria-hidden="true" /></div>
  </>;

  const questionScreen = () => <>
  </>;

  const choicesScreen = () => <>
  </>;

  const feedbackScreen = () => <>
    <div aria-hidden="true" className="full-screen" id="transition">
      <DoctorTransition/>
    </div>
  </>;

  const animations = {
    [ARTICLE]: window.onload = function () {
      const tl = gsap.timeline()
        .to('#doctor-title-screen', { duration: 0.5, opacity: 0, delay: 5, ease: 'sine.inOut' })
        .to('#skeleton-hand, #skeleton-torso', { duration: 1, transform: 'translateX(0)', opacity: 1, ease: 'sine.inOut' })
        .add(gsap.fromTo('.activity-card', { opacity: 0, y: -700 }, { duration: 1, y: 0, opacity: 1, ease: 'sine.out' }), 5.5)
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { duration: 1, y: 700, opacity: 0, ease: 'sine.inOut' })
        .add(gsap.to('#skeleton-hand, #skeleton-torso', { duration: 1, x: -424, ease: 'sine.inOut' }), 0.5)
        .add(gsap.to('#skeleton-knee', { duration: 1, transform: 'translateX(0)', ease: 'sine.inOut' }), 0.5)
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .fromTo('.activity-card', { opacity: 0, y: -700 }, { duration: 1, y: 0, opacity: 1, ease: 'sine.inOut' })
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { duration: 1, y: 700, transformOrigin: '0 0' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .fromTo('.article', { opacity: 0, y: -700 }, { duration: 1, opacity: 1, y: 0, ease: 'sine.inOut' })
        .fromTo('.answer-button', { opacity: 0, y: -350 }, { duration: 0.5, opacity: 1, y: 0, stagger: 0.3 })
        .add(gsap.fromTo('.answers', { opacity: 0, y: -700 }, { duration: 1, opacity: 1, y: 0, ease: 'sine.inOut' }), 0.5)
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.article, .answers', { duration: 1, y: 700, transformOrigin: '0 0' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .from('.feedback, .feedback-next-container', { duration: 0.5, opacity: 0, delay: 3.5 })
        .call(()=> tl.kill());
      tl.play(0);
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
