/* eslint-disable jsx-a11y/aria-role */
import { Buffer } from 'buffer';

import React, { useEffect, useLayoutEffect, useState, useRef, forwardRef } from 'react';

import clsx from 'clsx';
import { string, elementType, object, func, bool } from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MultipleChoiceDevTools from 'app/components/devtools/multiple-choice/multiple-choice-dev.component';
import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import { Feedback } from 'app/components/feedback/feedback.component';
import { Loader } from 'app/components/loader/loader';
import { KEY_NAMES } from 'app/constants/keys.constant';
import {
  ARTICLE,
  QUESTION,
  CHOICES,
  FEEDBACK,
  FEEDBACK_CORRECT,
  FEEDBACK_INCORRECT,
} from 'app/constants/multiple-choice.constants';
import routesConstants from 'app/constants/routes.constant';
import 'app/components/multiple-choice/multiple-choice.scss';
import { SessionStorageService } from 'app/services/session-storage.service';
import backButton from 'app/shared/images/back_button.svg';
import closeButton from 'app/shared/images/teacher/x-button.svg';
import { activityDataActionCreators } from 'app/store/actions/activity-data.actions';
import { goToQueuedActivity } from 'app/util/handlers/activities';
import { makeId } from 'app/util/make-id';
import { useHighlightWord } from 'app/util/word';

export const MultipleChoice = forwardRef(({ activityClassName, animate, clearAnimationFinished, animationFinished, ArticleScreen,
  QuestionScreen, ChoicesScreen, FeedbackScreen, removeCurrentActivity, onFinished }, forwardedRef) => {

  const navigate = useNavigate();
  const storedStep = useRef();
  const dispatch = useDispatch();
  const { id: activityId } = useParams();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [focusedAnswer, setFocusedAnswer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [word, setWord] = useState(null);
  const [step, setStep] = useState(null);
  const [currentActivity, setCurrentActivity]  = useState({});
  const [correctFeedback, setCorrectFeedback] = useState(null);
  const [dateStarted, setDateStarted] = useState(null);
  const [resultReported, setResultReported] = useState(false);

  const { activity, completedActivities }  = useSelector(state =>  state.activityData);
  const { kcInfo } = useSelector(state => state.userData);

  const teacherDemoSettings = SessionStorageService.getTeacherDoingActivity();

  const correctClass = `${clsx({ correct: selectedAnswer && selectedAnswer.isCorrect, incorrect: selectedAnswer && !selectedAnswer.isCorrect })}`;

  const backBtnbg = {
    backgroundImage: `url(${backButton})`
  };

  const backBtnTeacherbg = {
    backgroundImage: `url(${closeButton})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const goHome = () => {
    navigate(routesConstants.HOME, { replace: true });
  };

  const goToTeacher = () => {
    navigate(routesConstants.CLASSES, { replace : true });
  };

  const setActivityData = () => {
    const obj = Object.assign({}, activity);
    if(obj.correctAnswer) {
      setWord(obj.word.word);
      obj.answers = [];
      obj.answers.push(...[{ label: 'A' },{ label: 'B' },{ label: 'C' }]);
      const correctAnswer = Buffer.from(obj.correctAnswer, 'base64').toString();
      for(let i = 0; i < obj.answers.length; i++) {
        obj.answers[i]['id'] = i;
        obj.answers[i]['text'] = obj['answerChoice' + obj.answers[i].label];
        obj.answers[i]['isCorrect'] = obj.answers[i].label === correctAnswer;
        obj.answers[i]['feedback'] = obj['feedback' + obj.answers[i].label];
        if (obj.answers[i]['isCorrect']) {
          setCorrectFeedback(obj.answers[i]['feedback']);
        }
      }
      obj.article = useHighlightWord(word, obj.article);
      obj.question = useHighlightWord(word, obj.question);
      setCurrentActivity(obj);
    }
    
  };

  const createMarkup = (string) => {
    return { __html: string };
  };

  const next = (newStep) => {
    if (newStep === storedStep.current) return;
    if(animate[`${step}To${newStep}`]) {
      animate[`${step}To${newStep}`]();
      storedStep.current = newStep;
    } else {
      setStep(newStep);
    }
  };

  const selectAnswer = (event, answer) => {
    switch (event.key) {
    case KEY_NAMES.ENTER:
    case KEY_NAMES.EMPTY:
      if(selectedAnswer.id === answer.id) {
        submitAnswer(answer);
      }
      setSelectedAnswer(answer);
      break;
    case KEY_NAMES.ESCAPE:
      setSelectedAnswer({});
      break;
    }
  };

  const sendActivityObservation = (isCorrect) => {
    const activityObservation = {
      activityId: currentActivity.id,
      activityTypeId: currentActivity.activityType.id,
      csLearningObjectBusinessKey: null,
      csLessonBusinessKey: null,
      csUserBusinessKey: kcInfo.sub,
      interactions: [],
      questId: SessionStorageService.getQuestId(),
      score: isCorrect ? 1 : 0,
      status: isCorrect ? 'PASSED' : 'FAILED',
      timeSpent: new Date().getTime() - dateStarted.getTime(),
      wordId: currentActivity.word.id,
    };
    dispatch(activityDataActionCreators.setActivityObservation.request(activityObservation));
  };

  const submitAnswer = (answer) => {
    if (answer && answer.feedback && !Object.keys(selectedAnswer).length) {

      if (!teacherDemoSettings)
        sendActivityObservation(answer.isCorrect);

      removeCurrentActivity(dispatch);

      next(FEEDBACK);
    }
  };

  const handleClick = (answer) => (e) => {
    if(e.button === 0) {
      setSelectedAnswer(answer);
      submitAnswer(answer);
    }
  };

  useLayoutEffect (() => {
    if(step === ARTICLE) {
      setTimeout(animate[step], 1000);
    } else if (step) {
      animate[step]();
    }
    if (step === FEEDBACK) {
      if (animate[FEEDBACK_CORRECT] && selectedAnswer.isCorrect) {
        animate[FEEDBACK_CORRECT]();
      }
      if (animate[FEEDBACK_INCORRECT] && !selectedAnswer.isCorrect) {
        animate[FEEDBACK_INCORRECT]();
      }
    }
  }, [step]);

  useEffect(() => {
    if (activityId) dispatch(activityDataActionCreators.getActivity.request(`/singleWordActivity/show/${activityId}`));

    return () => {
      setStep(null);
      storedStep.current = null;
    };
  }, []);

  useEffect(() => {
    setSelectedAnswer(null);
    setFocusedAnswer(null);
    setLoading(true);
    setWord(null);
    setStep(null);
    setCurrentActivity({});
    setCorrectFeedback(null);
    setDateStarted(null);
    setResultReported(false);
    if (activityId) dispatch(activityDataActionCreators.getActivity.request(`/singleWordActivity/show/${activityId}`));
  }, [activityId]);

  useLayoutEffect(() => {
    if(storedStep.current) {
      setStep(storedStep.current);
      storedStep.current = null;
      clearAnimationFinished();
    }
  }, [animationFinished]);

  useEffect(() => {
    if(activity && activity.id && completedActivities.includes(activity.id)){
      // If completed already, go next
      clearAnimationFinished();
      goToQueuedActivity(navigate);
    }

    if(activity && activity.id && !completedActivities.includes(activity.id)) {
      setActivityData();
      setSelectedAnswer({});
      next(ARTICLE);
      setLoading(false);
      setDateStarted(new Date());
    }
  }, [activity]);

  useEffect(() => {
    setResultReported(!!completedActivities?.filter(id => parseInt(id) === parseInt(activityId)));
  }, [completedActivities]);


  const getHint = () => {
    if(!focusedAnswer) {
      return null;
    }

    const selectedAnswerId = selectedAnswer && selectedAnswer.id;

    if (focusedAnswer.id !== selectedAnswerId) {
      return 'Press Enter or Space to select this answer.';
    }

    if(selectedAnswerId === focusedAnswer.id) {
      return `Option ${selectedAnswer.label}. Selected. Press Enter or Space to submit this answer. To deselect your answer press Escape.`;
    }

    return null;
  };

  return (
    !loading ?
      <ErrorBoundary
        FallbackComponent={ErrorScreen}
      >
        <MultipleChoiceDevTools
          handleClick={handleClick}
          onFinished={onFinished}
          activityId={activityId}
          navigate={navigate}
          currentActivity={currentActivity}
        />
        <div className={`multiple-choice ${activityClassName}`} ref={forwardedRef}>
          <div className="container">
            {
              teacherDemoSettings ?
                <button style={backBtnTeacherbg} onClick={goToTeacher} aria-label="Go back to Teacher screen" className="back-button" />
                :
                <button style={backBtnbg} onClick={goHome} aria-label="Go back to Home screen" className="back-button" />
            }
            {step === ARTICLE &&
            <>
              <ArticleScreen />
              <div className="activity-card passage">
                <div className="passage-container">
                  {currentActivity.header && <h1 className="passage-title"><span role="text">{currentActivity.header}</span></h1>}
                  <p className="passage-text"><span role="text" dangerouslySetInnerHTML={createMarkup(currentActivity.article)}/></p>
                  {currentActivity.headerOptional && <p className="passage-second-title"><span role="text">{currentActivity.headerOptional}</span></p>}
                </div>
                <div className="center">
                  <button onClick={() => { next(QUESTION); }} aria-label="Next Card" className="button next-button">Next</button>
                </div>
              </div>
            </>}

            {step === QUESTION &&
            <>
              <QuestionScreen />
              <div className="activity-card question">
                <div className="question-text">
                  <span role="text" dangerouslySetInnerHTML={createMarkup(currentActivity.question)}/>
                </div>
                <div className="center">
                  <button onClick={() => { next(CHOICES); }} aria-label="Next Card" className="button next-button">Next</button>
                </div>
              </div>
            </>}

            {step === CHOICES &&
            <div key={currentActivity.id} className="activity-card choices">
              <ChoicesScreen/>
              <div className="article">
                <span role="text" dangerouslySetInnerHTML={createMarkup(currentActivity.article)}/>
              </div>
              <div className="answers">
                <h1 className="prompt">
                  <span role="text" dangerouslySetInnerHTML={createMarkup(currentActivity.question)}/>
                </h1>
                <ul className="answers-container">
                  {currentActivity.answers.map((answer) => {
                    const radioBtnId = makeId('activity', activity.id, 'answer', answer.id);

                    return (
                      <li className="answer-item" key={answer.id}>
                        <label htmlFor={radioBtnId}
                          className={`button answer-button ${clsx({ 'selected': selectedAnswer && selectedAnswer.id === answer.id, focused: focusedAnswer && focusedAnswer.id === answer.id })}`}
                        >
                          <span aria-hidden="true">{answer.text}</span>
                          <div className="answer-label" aria-hidden="true">{answer.label}</div>

                          <span className="sr-only">
                            {`Option ${answer.label}. ${answer.text}`}
                          </span>

                          <input
                            type="radio"
                            name={makeId('activity', activity.id)}
                            id={radioBtnId}
                            value={answer.id}
                            checked={selectedAnswer.id === answer.id}
                            className="answer-input"
                            onFocus={() => setFocusedAnswer(answer)}
                            onBlur={() => setFocusedAnswer(null)}
                            onKeyUp={(event) => selectAnswer(event, answer)}
                            onChange={(event) => selectAnswer(event, answer)}
                            onMouseDown={handleClick(answer)}
                          />
                        </label>
                      </li>
                    );
                  })}
                </ul>

                <span aria-live="polite">
                  <span className="sr-only">{getHint()}</span>
                </span>
              </div>
            </div>}

            {step === FEEDBACK &&
            <div className={`activity-card feedback-card ${correctClass}`}>
              <FeedbackScreen isCorrect={selectedAnswer.isCorrect} />
              <Feedback answer={selectedAnswer} correctFeedback={correctFeedback} word={word} />
              <div className="feedback-next-container">
                {(resultReported || SessionStorageService.getTeacherDoingActivity()) ? <button onClick={() => onFinished(navigate, dispatch)} aria-label="Next Activity" className="button next-button">Next</button> : null}
              </div>
            </div>}

          </div>

        </div>
      </ErrorBoundary>
      :
      <Loader />
  );
});

MultipleChoice.displayName = 'MultipleChoice';

MultipleChoice.propTypes = {
  onFinished: func.isRequired,
  animationFinished: bool,
  clearAnimationFinished: func,
  animations: object,
  activityClassName: string.isRequired,
  FeedbackScreen: elementType,
  ArticleScreen: elementType,
  ChoicesScreen: elementType,
  QuestionScreen: elementType,
};
