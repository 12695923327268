import { CONFIG } from 'app/config';
import AuthService from 'app/services/auth.service';
import { EntitlementService } from 'app/services/entitlement.service';
import promiseHandler from 'app/util/promise-handler';

class LoginService {
  async createKeycloakClient() {
    await AuthService.init();

    const [error, userSession] = await promiseHandler(AuthService.getCurrentUser());
    if (error || !userSession) {
      AuthService.logout();
      throw error;
    }

    this.userSession = userSession;
  }

  async checkEntitlement() {
    const [assetsError, assets] = await promiseHandler(EntitlementService.getUserAssets());
    if (assetsError) {
      throw assetsError;
    }

    if (!EntitlementService.isEntitled(assets)) {
      window.location.href = CONFIG.NOT_LICENSED_PAGE_URL;
    }
  }

  setRole() {
    this.userSession.isTeacher = EntitlementService.isTeacherInRoles(
      AuthService.getRoles()
    );
    this.userSession.isStudent = EntitlementService.isStudentInRoles(
      AuthService.getRoles()
    );
  }

  async performLogin() {
    await this.createKeycloakClient();
    await this.checkEntitlement();
    this.setRole();

    return {
      userSession: this.userSession,
    };
  }
}

export default new LoginService();
