import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import LoginService from 'app/services/login.service';
import { userDataActionCreators, userDataActions } from 'app/store/actions/user-data.actions';
import { findUserLicenses, getUserLicenseType } from 'app/util/entitlement';
import promiseHandler from 'app/util/promise-handler';

function* getUserDataSaga() {
  try {
    const [loginError, userData] = yield promiseHandler(
      LoginService.performLogin()
    );

    if (loginError) throw loginError;

    const { userSession } = userData;

    const { isTeacher, isStudent } = userSession;

    yield put(
      userDataActionCreators.getUserData.success({
        kcInfo: { ...userSession, isTeacher, isStudent },
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      userDataActionCreators.getUserData.failure({
        error,
      })
    );
  }
}

function* getIntercomHashSaga() {
  try {
    const [intercomHashError, intercomHashObject] = yield promiseHandler(
      ApiClient.getIntercom()
    );

    // We can fail if we don't get the hash

    if (intercomHashError) throw intercomHashError;

    yield put(
      userDataActionCreators.getIntercomHash.success({
        key: intercomHashObject.data.key,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      userDataActionCreators.getIntercomHash.failure({
        error,
      })
    );
  }
}

function* getLicenses() {
  try {
    const [licensesError, licenses] = yield promiseHandler(
      ApiClient.getLicense()
    );

    if (licensesError) throw licensesError;

    const userLicenses = findUserLicenses(licenses.data);
    const userLicenseTypes = getUserLicenseType(userLicenses);

    yield put(
      userDataActionCreators.getLicenses.success({
        licenseTypes: userLicenseTypes,
        assets: licenses.data,
      })
    );

  } catch (error) {
    console.error(error);
    yield put(
      userDataActionCreators.getLicenses.failure({
        error,
      })
    );
  }
}


export default function* userDataSaga() {
  yield all([
    takeEvery(userDataActions.GET_USER_DATA.REQUEST, getUserDataSaga),
    takeEvery(userDataActions.GET_INTERCOM_HASH.REQUEST, getIntercomHashSaga),
    takeEvery(userDataActions.GET_LICENSES.REQUEST, getLicenses),
  ]);
}
