import React from 'react';

import { INACTIVE_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';

export const BoxInactive = ({ classNames }) => {
  return (
    <svg className={`${INACTIVE_CLASS} ${classNames || ''}`} width="134" height="176" viewBox="0 0 134 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M134 114C134 151 104 176 67 176C30 176 0 151 0 114C0 77 48 28 67 0C85 28 134 77 134 114Z" fill="#C8C8C8"/>
    </svg>

  );
};