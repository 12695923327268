import React from 'react';


import { Activity } from 'app/components/activity/activity.component';
import { AskQuery } from 'app/components/multiple-choice/askquery/askquery.component';
import { BestMeal } from 'app/components/multiple-choice/bestmeal/bestmeal.component';
import { Doctor } from 'app/components/multiple-choice/doctor/doctor.component';
import { GreatestMovie } from 'app/components/multiple-choice/greatestmovie/greatestmovie.component';
import { Keisha } from 'app/components/multiple-choice/keisha/keisha.component';
import { SunkenNotebooks } from 'app/components/multiple-choice/sunkennotebooks/sunkennotebooks.component';
import { Undelivered } from 'app/components/multiple-choice/undelivered/undelivered.component';
import { Zombie } from 'app/components/multiple-choice/zombie/zombie.component';
import { Constellations } from 'app/components/syn-ant-activity/constellations/constellations.component';
import { RisingTides } from 'app/components/syn-ant-activity/risingtides/risingtides.component';

const routesConstants = {
  HOME: '/home',
  CLASSES: '/reporting',
  TEACHER_GUIDE: '/teacherguide',
  INDEX: '/',
  ACTIVITY_ROUTES: [
    { path: 'blinditem', component: <Activity title='Vocab BlindItem Activity'/> },
    { path: '/mailbagk5', component: <Activity title='Vocab Mail Bag Activity'><Undelivered/></Activity> },
    { path: '/zombie', component: <Activity title='Vocab Zombie Activity'><Zombie/></Activity> },
    { path: 'asksteve', component: <Activity title='Vocab AskSteve Activity'/> },
    { path: '/bestmeal', component: <Activity title='Vocab BestMeal Activity'><BestMeal/></Activity> },
    { path: '/bestmealk5', component: <Activity title='Vocab BestMeal Activity'><BestMeal/></Activity> },
    { path: '/greatestmovie',component: <Activity title='Vocab Greatest Movie Activity'><GreatestMovie/></Activity> },
    { path: '/greatestmoviek5', component: <Activity title='Vocab Greatest Movie Activity'><GreatestMovie/></Activity> },
    { path: '/keisha', component: <Activity title='Vocab Keisha Activity'><Keisha/></Activity> },
    { path: '/keishak5', component: <Activity title='Vocab Keisha Activity'><Keisha/></Activity> },
    { path: '/doctor', component: <Activity  title='Vocab Doctor Activity'><Doctor/></Activity> },
    { path: '/doctork5', component: <Activity title='Vocab Doctor Activity'><Doctor/></Activity> },
    { path: 'hashtag', component: <Activity title='Vocab Hashtag Activity'/> },
    { path: 'mayi', component: <Activity title='Vocab MayI Activity'/> },
    { path: 'horoscope', component: <Activity title='Vocab Horoscope Activity'/> },
    { path: 'author', component: <Activity title='Vocab Author Activity'/> },
    { path: 'guillermo', component: <Activity title='Vocab Guillermo Activity' /> },
    { path: 'wordmatch', component: <Activity title='Vocab Wordmatch Activity'/> },
    { path: 'rootsexist', component: <Activity title='Roots Exist Activity' /> },
    { path: 'analogy', component: <Activity title='Vocab Analogy Activity'/> },
    { path: 'ordersynonyms', component: <Activity title='Vocab Order Synonyms Activity'/> },
    { path: 'orderantonyms', component: <Activity title='Vocab Order Antonyms Activity'/> },
    { path: 'dictionary', component: <Activity title='Vocab Dictionary Activity'/> },
    { path: 'redictionary', component: <Activity title='Vocab Dictionary Activity'/> },
    { path: 'findsynonyms', component: <Activity title='Vocab Find Synonyms Activity'><Constellations/></Activity> },
    { path: 'findsynonymsk5', component: <Activity title='Vocab Find Synonyms Activity'><Constellations/></Activity> },
    { path: 'findantonyms', component: <Activity title='Vocab Find Antonyms Activity'><RisingTides/></Activity> },
    { path: 'findantonymsk5', component: <Activity title='Vocab Find Antonyms Activity'><RisingTides/></Activity> },
    { path: '/askqueryk5', component: <Activity title='Vocab Ask Query Activity'><AskQuery/></Activity> },
    { path: '/sunkenshipk5', component: <Activity title='Vocab Sunken Notebooks Activity'><SunkenNotebooks /></Activity> },
    { path: '/zombiek5', component: <Activity title='Vocab Zombie Activity'><Zombie/></Activity> },
  ]
};

export default routesConstants;
