import React, { useEffect } from 'react';

import { gsap } from 'gsap';
import 'app/components/screen-rotate/screen-rotate.scss';

export const ScreenRotate = () => {

  useEffect(() => {
    const tl = gsap.timeline({ paused: true })
      .fromTo('#ipad-vocab', { rotate: 0 }, { duration: 1.5, rotate: -90, transformOrigin: '50% 50%', delay: 0.5, ease: 'back.out' })
      .add(gsap.fromTo('#arrow1', { opacity: 0, rotate: 90, transformOrigin: '100% 100%' }, { duration: 1, opacity: 1, rotate: 0 }), 0.5)
      .add(gsap.fromTo('#arrow2', { opacity: 0, rotate: 90, transformOrigin: '0% 0%' }, { duration: 1, opacity: 1, rotate: 0 }), 0.5)
      .add(gsap.fromTo('#tail1, #tail2', { strokeDashoffset: 100 }, { duration: 1, strokeDashoffset: 0, ease: 'sine.out' }), 0.5)
      // reset 
      .to('#ipad-vocab', { duration: 0.5, rotate: 0, delay: 3, ease: 'sine.inOut' })
      .add(gsap.to('#arrow1, #arrow2', { duration: 0.5, opacity: 0 }), 5)
      // play a second time and that's it
      .fromTo('#ipad-vocab', { rotate: 0 }, { duration: 1.5, rotate: -90, transformOrigin: '50% 50%', delay: 0.5, ease: 'back.out' })
      .add(gsap.fromTo('#arrow1', { opacity: 0, rotate: 90, transformOrigin: '100% 100%' }, { duration: 1, opacity: 1, rotate: 0 }), 6)
      .add(gsap.fromTo('#arrow2', { opacity: 0, rotate: 90, transformOrigin: '0% 0%' }, { duration: 1, opacity: 1, rotate: 0 }), 6)
      .add(gsap.fromTo('#tail1, #tail2', { strokeDashoffset: 100 }, { duration: 1, strokeDashoffset: 0, ease: 'sine.out' }), 6);

    tl.play(0);
  });

  return (
    <div className="iPad-content">
      <h1>Please rotate your screen</h1>
      <svg id="ipad-vocab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" width="340" height="340">
        <g id="arrow2" opacity="0">
          <path id="tail2" d="M297,296c0,23-12,35-33,35" fill="none" stroke="#14405c" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7.5" fillRule="evenodd" strokeDasharray="100" strokeDashoffset="100"/>
          <polyline id="point2" points="286 301 297 291 308 302" fill="none" stroke="#14405c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.5"/>
        </g>
        <g id="arrow1" opacity="0">
          <path id="tail1" d="M43,44C43,23,51,8,74,8" fill="none" stroke="#14405c" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7.5" fillRule="evenodd" strokeDasharray="100" strokeDashoffset="100"/>
          <polyline id="point1" points="54 39 43 49 32 38" fill="none" stroke="#14405c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.5"/>
        </g>
        <g id="tablet">
          <rect x="70" y="28" width="200" height="284" rx="11.08" fill="#14405c"/>
          <rect x="83" y="41" width="174" height="234" fill="#afe4ff"/>
          <circle cx="170" cy="293" r="10" fill="#758f9f"/>
        </g>
        <g id="eye-guy">
          <g id="limbs">
            <polyline points="167 131.5 150.5 114 166.5 95" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
            <polyline points="134.5 155 86.5 155 86.5 141" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="6"/>
            <polyline points="134.5 184 86.5 184 86.5 198" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="6"/>
            <polyline points="164.5 207 143.5 218.5 120.5 218.5" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="6"/>
          </g>
          <g id="body">
            <path d="M133.5,169.5A37.5,37.5,0,1,1,171,207,37.5,37.5,0,0,1,133.5,169.5Z" fill="#ffe748" stroke="#000" strokeWidth="6"/>
            <path d="M137.8,170.3a31.5,31.5,0,0,1,31.5-31.5c17.4,0-1.2,14.6-1.2,32s18.6,31.1,1.2,31.1A31.58,31.58,0,0,1,137.8,170.3Z" fill="#e4cc40" fillRule="evenodd"/>
            <path d="M138.5,170a32,32,0,1,1,32,32A32,32,0,0,1,138.5,170Z" fill="none" stroke="#fff" strokeWidth="6"/>
            <path d="M183,150.93c2.1-1.31,6.07,1.23,8.85,5.68s3.32,9.12,1.22,10.44-6.07-1.23-8.85-5.67S180.86,152.25,183,150.93Z" fill="#fff"/>
          </g>
        </g>
      </svg>
    </div>
  );
};
