import axios from 'axios';

import { requestErrorInterceptor, requestSuccessInterceptor } from 'app/api/interceptors';
import { getClassId, getDateRange, getProfileId, getStudentId, getStudentsBusinessKeys } from 'app/api/params-injectors';
import { CONFIG } from 'app/config';
import { getCurrentTimezoneName } from 'app/util/time';

axios.interceptors.request.use(requestSuccessInterceptor, requestErrorInterceptor);

export default class ApiClient {

  static async getIntercom() {
    return axios.get(`${CONFIG.THRID_PARTY_SECRETS_ROOT}/intercom/keycloakUserHash`);
  }

  static async getLicense(passedProfileId) {
    const profileId = getProfileId(passedProfileId);
    return axios.get(`${CONFIG.RETAIL_URL}/api/licensing/users/${profileId}/assets?expandLicenses=true&all=true`);
  }

  static async getStandaloneClasses(passedProfileId) {
    const profileId = getProfileId(passedProfileId);
    return axios.get(`${CONFIG.SELF_ENROLLMENT_ROOT}/staff/${profileId}/sections`);
  }

  static async getCDAClasses(passedProfileId) {
    const profileId = getProfileId(passedProfileId);
    return axios.get(`${CONFIG.SELF_ENROLLMENT_ROOT}/ims/oneroster/v1p1/users/${profileId}/classes`);
  }

  static async getStandaloneStudents(passedClassId) {
    const classId = getClassId(passedClassId);
    return axios.get(`${CONFIG.SELF_ENROLLMENT_ROOT}/sections/${classId}/students`);
  }

  static async getCDAStudents(passedClassId) {
    const classId = getClassId(passedClassId);
    return axios.get(`${CONFIG.SELF_ENROLLMENT_ROOT}/ims/oneroster/v1p1/classes/${classId}/students`);
  }

  static async getUserStats(passedUserId) {
    // This is for student experience
    const userId = getProfileId(passedUserId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/students/${userId}/performance?timeZone=${getCurrentTimezoneName()}`);
  }

  static async getStudentStats(passedStudentId) {
    // This is for teacher experience
    const userId = getStudentId(passedStudentId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/students/${userId}/performance?timeZone=${getCurrentTimezoneName()}`);
  }

  static async getLatestProfile(passedUserId) {
    // This is for the student experience
    const userId = getProfileId(passedUserId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/profiles/${userId}/latest`);
  }

  static async getLatestStudentProfile(passedUserId) {
    // This is for teacher experience
    const userId = getStudentId(passedUserId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/profiles/${userId}/latest`);
  }

  static async setCurrentProfile(currentProfile) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/profiles`, currentProfile);
  }

  static async getFirstEncounters(currentProfile) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/first-encounters`, currentProfile);
  }

  static async getActivity(activityURL) {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/activity-data?ksUrl=${(activityURL[0] === '/') ? activityURL.slice(1, activityURL.length) : activityURL}.json`);
  }

  static async getStudentsClasses(studentId) {
    const studentBk = getProfileId(studentId);
    return axios.get(`${CONFIG.SELF_ENROLLMENT_ROOT}/students/${studentBk}/sections`);
  }

  static async getStreams() {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/streams`);
  }

  static async setStream(streamData) {
    return axios.put(`${CONFIG.VOCAB_DIFFERENTIATION}/api/teacher/stream`, streamData);
  }

  static async getActivitiesLimitation(passedClassId) {
    const classId = getClassId(passedClassId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/settings/activities-limitation/section/${classId}`);
  }

  static async getDisabledActivities(passedClassId) {
    const classId = getClassId(passedClassId);
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/settings/disabled-activities/section/${classId}`);
  }

  static async getSectionStats(passedClassId, passedUserId, dateStart, dateEnd) {
    const classId = getClassId(passedClassId);
    const userId = getProfileId(passedUserId);
    const currentTimezone = getCurrentTimezoneName();

    const date = getDateRange();

    // date start is 14 days ago
    dateStart = dateStart || date.startDate.getTime() || new Date(new Date().setDate(new Date().getDate() - 14)).getTime();
    dateEnd = dateEnd || date.endDate.getTime() || new Date().getTime();

    // active students
    const activeStudents = getStudentsBusinessKeys();

    return activeStudents.length > 0 ?
      axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/teacher/section-stats/${classId}?userId=${userId}&timeZone=${currentTimezone}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
        activeStudents)
      :
      undefined;
  }

  static async getMasteredWords(page, size) {
    const userId = getProfileId();
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/${userId}/mastered-words-names?page=${page}&size=${size}`);
  }

  static async submitDisabledActivities(activitiesList, id) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/settings/disabled-activities/${id}`, activitiesList);
  }

  static async submitLimitWords(bks, count) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/settings/activities-limitation`, {
      businessKeys: bks,
      count,
    });
  }

  static async getKSWordListByBusinessKeys(wordListBusinessKeys) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/word-list/ks-word-lists`, wordListBusinessKeys);
  }

  static async getWordsByStream(getParams) {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/words/by-stream/${getParams}`);
  }

  static async getWordsByUnitSubunit(unitID, subunitID) {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/first-encounters/byUnitSubunit?unit=${unitID}&subunit=${subunitID}`);
  }

  static async getWordData(wordID) {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/words/${wordID}`);
  }

  static async getUnit() {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/unit`);
  }

  static async getSubunit() {
    return axios.get(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/subunit`);
  }

  static async getCklaProfile() {
    const userId = getProfileId();
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/profiles/v2/ckla/${userId}`);
  }

  static async getArProfile(questId, teacherGrade) {
    const userId = getProfileId();
    const teacherGradeParam = teacherGrade ? `&teacherGrade=${teacherGrade}` : '';
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/profiles/v2/ar/${userId}?questId=${questId}${teacherGradeParam}`);
  }

  static async getCklaFe(profile) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/v2/ckla/first-encounters`, profile);
  }

  static async getArFe(profile) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/knowledge/v2/ar/first-encounters`, profile);
  }

  static async getGameState(arId) {
    return axios.get(`${CONFIG.READING_API_URL}/games/v3/gameState?userId=${arId}&gameId=vocab`);
  }

  static async setGameState(arId, gameState) {
    return axios.post(`${CONFIG.READING_API_URL}/games/v3/gameState?userId=${arId}&gameId=vocab`, gameState);
  }

  static async sendActivityObservation(activityObservation) {
    return axios.post(`${CONFIG.VOCAB_DIFFERENTIATION}/api/activities`, activityObservation);
  }
}
