import React, { forwardRef } from 'react';

import composeClassNames from 'clsx';

export const TabPanels = forwardRef(
  ({ as: Element = 'div', className, children, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs_panels';
    return (
      <Element className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
        {children}
      </Element>
    );
  }
);

TabPanels.displayName = 'TabPanels';