import React, { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import escapeRegExp from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { App } from 'app/App';
import { Loader } from 'app/components/loader/loader';
import { ENVIRONMENT } from 'app/config';
import { usePII } from 'app/hooks/use-pii.hook';
import { getCKLAStreams, streamsDataActionCreators } from 'app/store/actions/streams-data.actions';
import { userDataActionCreators } from 'app/store/actions/user-data.actions';
import { changeUserDate } from 'app/store/actions/user-settings.actions';
import { isIdle, isLoading, isSuccess } from 'app/store/reducers/api-status.reducer';
import { offsetDateDays } from 'app/util/time';

const Root = () => {
  const { isTeacher, isStudent } = useSelector(state => state.userData.kcInfo);

  // Get the PII for use in datadog rum
  usePII();

  const {
    getUserDataStatus,
    getLicensesStatus,
    getStreamsStatus,
  }  = useSelector(state => state.apiReducer);

  const { licenses } = useSelector(state => state.userData);

  const dispatch = useDispatch();

  // Record datadog rum session
  useEffect(() => {
    const packageJson = require('../package.json');

    datadogRum.init({
      applicationId: '337d3471-c8fa-4fb3-aa16-f8d4c621e50a',
      clientToken: 'pub7e61c0dac981b5f06526b8b144eefad6',
      site: 'datadoghq.com',
      service:'vocab-react',
      
      // Specify a version number to identify the deployed version of your application in Datadog 
      version: packageJson.version,
      env: ENVIRONMENT,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel:'mask-user-input',
      beforeSend: (event) => {
        if (event.action && event.action.target && event.action.target.name) {
          // Datadog RUM is initialized before keycloak login, so we check this before scrubbing user info
          if (window.kc && window.kc.tokenParsed) {
            // eslint-disable-next-line camelcase
            // We can scrub all of the user data here
            event.action.target.name = event.action.target.name.toLowerCase();
            
            for (const pii of window.informationToScrub) {
              if (pii) {
                event.action.target.name = event.action.target.name.replace(new RegExp(escapeRegExp(pii), 'gi'), '***');
              }
            }

          }
        }
      }
    });
  }, []);

  // Set initial date range for 1 week to retrieve data
  useEffect(() => {
    const endDate = new Date();
    const startDate = offsetDateDays(endDate, -7);
    dispatch(changeUserDate({ startDate, endDate }));
  }, []);

  useEffect(() => {
    // Initial get the user data. We get role, etc.. from here.
    if (isIdle(getUserDataStatus) && !isLoading(getUserDataStatus)) {
      dispatch(userDataActionCreators.getUserData.request());
    }
  }, [dispatch, getUserDataStatus]);

  useEffect(() => {
    if (licenses)
      if (isSuccess(getUserDataStatus) && isIdle(getStreamsStatus)) {
        if (licenses.ckla)
          dispatch(getCKLAStreams());
        else
          dispatch(streamsDataActionCreators.getStreams.request());
      }
  }, [dispatch, getUserDataStatus, getStreamsStatus, licenses]);

  useEffect(() => {
    // Get intercom for teacher
    if (isSuccess(getUserDataStatus) && isTeacher)
      dispatch(userDataActionCreators.getIntercomHash.request());
  }, [dispatch, getUserDataStatus, isTeacher, isStudent]);

  useEffect(() => {
    // Get licenses for the user
    if (isIdle(getLicensesStatus) && isSuccess(getUserDataStatus)) {
      dispatch(userDataActionCreators.getLicenses.request());
    }
  }, [dispatch, getLicensesStatus, getUserDataStatus]);

  // @TODO add logic for users who are not entitled or not enrolled to class https://amplify-education.atlassian.net/browse/AQUA-31771
  if (isSuccess(getUserDataStatus) && isSuccess(getLicensesStatus)) {
    return <App />;
  }

  // @todo fix loading logic
  return <Loader />;
};

export default Root;
