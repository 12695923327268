import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import { QueryTitle } from 'app/components/multiple-choice/askquery/animated/titlescreen.component';
import backgroundMain from 'app/components/multiple-choice/askquery/images/background-main.svg';
import balloonCatLarge from 'app/components/multiple-choice/askquery/images/balloon_cat-large.svg';
import balloonCatSmall from 'app/components/multiple-choice/askquery/images/balloon_cat-small.svg';
import balloonQueryLarge from 'app/components/multiple-choice/askquery/images/balloon_query-large.svg';
import balloonQuerySmall from 'app/components/multiple-choice/askquery/images/balloon_query-small.svg';
import catHead from 'app/components/multiple-choice/askquery/images/cat-head.svg';
import queryLeftUp from 'app/components/multiple-choice/askquery/images/query_left-up.svg';
import queryStraight from 'app/components/multiple-choice/askquery/images/query_straight.svg';

export const MessageTitleScreen = () => {
  const noShow = { opacity: 0, scale: 0 };
  const show = { scale: 1, opacity: 1 };

  useEffect(() => {
    const tl = gsap.timeline()
      .set('.next-button', { display: 'none' })
      .to('#queryTitle', { ...noShow, duration: 0.5, delay: 7 })
      // Article animation
      .to('#queryTitle2', { ...show, ease: 'ease-in', duration: 0.8 })
      .to('#backgroundMain', { ...show, ease: 'ease-in', duration: 0.8, })
      // Cat comes in
      .to('#catHead', { ...show, x: 80, ease: 'back.out(1.5)', duration: 1, delay: 0.25 })
      .to('#balloonCatSmall', { ...show, duration: 0.25 })
      .to('#catChatSmallText', { ...show, duration: 0.5 })
      // Query comes in
      .to('#queryLeftUp', { ...show, left: 800, ease: 'back.out(1.5)', duration: 1, delay: 1 })
      .to('#balloonQuerySmall', { ...show, duration: 0.25 })
      .to('#queryChatSmallText', { ...show, duration: 0.5 })
      // @TODO: Next button in article for article part 2
      // Cat moves off screen query moves up
      .to('#catChatSmallWrapper', { ...noShow, y: -200, duration: 0.5, delay: 2 })
      .to('#queryChatSmallWrapper', { y: -300, duration: 0.5 })
      // Cat appears at the bottom screen again
      .to('#catHeadLarge', { ...show, x: 80, ease: 'back.out(1.5)', duration: 1, delay: 1 })
      .to('#balloonCatLarge', { ...show, duration: 0.25 })
      .to('.activity-card', { ...show, opacity: 1, duration: 0.1 })
      .to('.passage-title', { ...show, width: 630, duration: 0.5 })
      .to('#next-button-to-passage', { top: 578, duration: 0.5 }, 'next-passage-1')
      // This is where we pause \\
      .to('#next-button-to-passage', { ...noShow, duration: 0.5, y: '-200%', display: 'none' })
      .to('#queryChatSmallText', { ...noShow, duration: 0.5, y: '-200%', }, 'chat-same-gone-1')
      .to('.passage-title', { ...noShow, duration: 0.5, y: '-200%', }, 'chat-same-gone-1')
      .to('#balloonCatLarge', { ...noShow, duration: 0.5, y: '-200%' }, 'chat-same-gone-1')
      .to('#balloonQuerySmall', { ...noShow, duration: 0.5, y: '-200%' }, 'chat-same-gone-1')
      .to('#catHeadLarge', { y: -350, ease: 'back.out(1.5)', }, 'chat-same-gone-1')
      .to('#queryLeftUp', { ...noShow, duration: 0.5, x: 0, y: -400, }, 'chat-same-gone-1')
      // Query comes back
      .to('#queryChatLargeWrapper', { ...show, duration: 0.1 })
      .to('#queryStraight', { ...show, ease: 'back.out(1.5)', x: -415, y: 0, duration: 0.5 })
      .fromTo('#balloonQueryLarge', { scale: 0, opacity: 0, display: 'none' }, { ...show, display: 'inherit', x: 100, y: 160, duration: 0.5 })
      // Show Passage Text
      .to('.passage-text', { ...show, top: 222, left: 140, display: 'flex' })
      .to('.next-button', { ...show, display: 'block', top: 578 })
      .call(() => { tl.kill(); });

    tl.addPause('next-passage-1+=0.5');


    document.getElementById('next-button-to-passage').onclick = function () {
      tl.paused(false);
    };

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <div className="title-screen animated">
      <div id="queryTitle" aria-hidden="true"><QueryTitle /></div>
      <div id="queryTitle2" aria-hidden="true">
        <div id="backgroundMain"><img src={backgroundMain} aria-hidden="true" /></div>
        <div id="catChatSmallWrapper">
          <div id="balloonCatSmall"><img src={balloonCatSmall} aria-hidden="true" /></div>
          <div id="catHead" ><img src={catHead} aria-hidden="true" /></div>
          <p x='63' y='110' id="catChatSmallText">Hey Query!</p>
        </div>
        <div id="queryChatSmallWrapper">
          <div id="balloonQuerySmall"><img src={balloonQuerySmall} aria-hidden="true" /></div>
          <div id="queryLeftUp"><img src={queryLeftUp} aria-hidden="true" /></div>
          <p x='63' y='110' id="queryChatSmallText"><span>Yes, how may I help?</span></p>
        </div>
        <div id="catChatLargeWrapper">
          <div id="balloonCatLarge"><img src={balloonCatLarge} aria-hidden="true" /></div>
          <div id="catHeadLarge"><img src={catHead} aria-hidden="true" /></div>
          <button id="next-button-to-passage" className='button'>Next</button>
          {/* The text we use here is the .passage-title */}
        </div>
        <div id="queryChatLargeWrapper">
          <div id="balloonQueryLarge"><img src={balloonQueryLarge} aria-hidden="true" /></div>
          <div id="queryStraight"><img src={queryStraight} aria-hidden="true" /></div>
          {/* Use next from MC */}
          {/* The text we use here is the .passage-text */}
        </div>
      </div>
    </div>
  );
};
