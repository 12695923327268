import React from 'react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';

import 'wicg-inert';

// eslint-disable-next-line no-restricted-imports
import './index.css';
import store from 'app/store';
import Root from 'root';

const container = document.getElementById('root');

render(
  <Provider store={store}>
    <Root />
  </Provider>,
  container
);
