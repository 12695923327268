
const SET_ACTIVE_TAB = '[Tabs Data] SET_ACTIVE_TAB';

export const tabStateActions = {
  SET_ACTIVE_TAB,
};

export const setActiveTab = (payload) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: payload,
  };
};
