import React, { useEffect } from 'react';

import clsx from 'clsx';
import { gsap } from 'gsap';

import { ConstellationsDirections, directionsTimeline } from 'app/components/syn-ant-activity/constellations/animated/constellations-directions.component';
import { ConstellationsTitle } from 'app/components/syn-ant-activity/constellations/animated/constellations-title.component';

import 'app/components/syn-ant-activity/constellations/intro/intro.scss';

export const Intro = ({ classNames }) => {

  useEffect(() => {
    const tl = gsap.timeline();
    tl
      .to('.constellations-title', { duration: 0.5, opacity: 1, display: 'block', ease: 'sine.inOut' })
      .to('#constellations-directions', { duration: 0.5, opacity: 1, display: 'block', delay: 6, ease: 'sine.inOut' })
      .add(gsap.to('.constellations-intro-moon', { top: '18%', left: '2%', duration: 1, ease: 'sine.inOut' }), 6.5)
      .to('.constellations-intro-bulb, .constellations .passage-text', { opacity: 1, duration: 1, ease: 'sine.inOut', onStart: ()=> {
        directionsTimeline.play(0);
      } })
      .to('.next-button', { opacity: 1, duration: 0.5, delay: 3, ease: 'sine.inOut' })
      .call(()=> tl.kill());
    tl.play(0);
  });

  return (
    <div className={clsx('constellations-intro', classNames)}>
      <ConstellationsTitle classNames="constellations-title" />
      <ConstellationsDirections classNames="constellations-intro-example"/>
    </div>
  );
};
