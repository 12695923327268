import { combineReducers } from 'redux';

import activityDataReducer from 'app/store/reducers/activity-data.reducer';
import apiStatusReducer from 'app/store/reducers/api-status.reducer';
import classesDataReducers from 'app/store/reducers/classes-data.reducer';
import gameStateReducer from 'app/store/reducers/game-state.reducer';
import helperStateReducer from 'app/store/reducers/helper-data.reducer';
import profileDataReducer from 'app/store/reducers/profile-data.reducer';
import streamsDataReducer from 'app/store/reducers/streams-data.reducer';
import tabStateReducer from 'app/store/reducers/tabs-state.reducer';
import userDataReducer from 'app/store/reducers/user-data.reducer';
import userSettingsReducer from 'app/store/reducers/user-settings.reducer';
import wordsDataReducer from 'app/store/reducers/words-data.reducer';

const rootReducer = combineReducers({
  userData: userDataReducer,
  userSettings: userSettingsReducer,
  apiReducer: apiStatusReducer,
  streamsReducer: streamsDataReducer,
  classesData: classesDataReducers,
  profileData: profileDataReducer,
  wordsData: wordsDataReducer,
  activityData: activityDataReducer,
  gameState: gameStateReducer,
  helperState: helperStateReducer,
  tabState: tabStateReducer,
});

export default rootReducer;
