import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { cklaStreams } from 'app/constants/streams.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_STREAMS = createRequestTypes(API_ACTION_TYPES.GET_STREAMS);

export const streamDataActions = {
  GET_STREAMS,
};

export const streamsDataActionCreators = {
  getStreams: { ...createActions(GET_STREAMS) },
};

// Static 

const GET_CKLA_STREAMS = '[Streams Reducer] GET_CKLA_STREAMS';

export const streamsStaticActions = {
  GET_CKLA_STREAMS,
};

export const getCKLAStreams = () => {
  return {
    type: GET_CKLA_STREAMS,
    payload: cklaStreams,
  };
};
