import React, { useEffect, useState } from 'react';

import { string } from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import routesConstants from 'app/constants/routes.constant';
import { SessionStorageService } from 'app/services/session-storage.service';
import { activityDataStaticActions } from 'app/store/actions/activity-data.actions';
import { setFinishedPlay, setNoFirstEncounters } from 'app/store/actions/words-data.actions';
import { goToQueuedActivity } from 'app/util/handlers/activities';

import 'app/components/activity/activity.scss';

export const Activity = props => {
  const { title } = props;

  const { id: activityId } = useParams();

  const { isTeacher } = useSelector(state => state.userData?.kcInfo);

  const [isAllowed, setIsAllowed] = useState(true);
  const [currentActivityId, setCurrentActivityId] = useState(null);

  const isActivityInFEs = (id, pendingActivities) => {
    if (pendingActivities) {
      return pendingActivities.find(fe => {
        return fe.wordActivity.id === parseInt(id);
      });
    }
    return false;
  };

  useEffect(() => {
    const pendingActivities = SessionStorageService.getPendingActivities();
    setIsAllowed(isTeacher || isActivityInFEs(activityId, pendingActivities));
    document.title = title;
  }, []);

  useEffect(() => {
    setIsAllowed(true);
    setCurrentActivityId(activityId);
  }, [activityId]);

  const resetFirstEncounters = (dispatch) => {
    dispatch(setNoFirstEncounters());
    SessionStorageService.setPendingActivities([]);
  };

  const removeCurrentActivity = (dispatch) => {
    // Change pending activities after we submit the score
    const pendingActivities = SessionStorageService.getPendingActivities();
    const newPendingActivities = pendingActivities.filter(pa => pa.wordActivity.id !== parseInt(currentActivityId));
    SessionStorageService.setPendingActivities(newPendingActivities);

    const teacherDemoSettings = SessionStorageService.getTeacherDoingActivity();

    if ((!newPendingActivities || !newPendingActivities.length) && !teacherDemoSettings) {
      // Reset completedActivities if we finish play or when we load back into home and last activity submits
      dispatch(activityDataStaticActions.resetCompletedActivities());
      // Set that we are finished playing
      dispatch(setFinishedPlay(true));
      // Reset the first encounters so that we can get a new set of FEs if there are any
      resetFirstEncounters(dispatch);
    }
  };

  return (
    isAllowed ?
      <main aria-live="polite" className="activity">
        <ErrorBoundary
          FallbackComponent={ErrorScreen}
        >
          {React.cloneElement(props.children, { onFinished: goToQueuedActivity, activityId: currentActivityId, removeCurrentActivity: removeCurrentActivity })}
        </ErrorBoundary>
      </main>
      :
      <Navigate to={routesConstants.HOME} />
  );
};

Activity.propTypes = {
  title: string
};
