import { useEffect, useRef } from 'react';

export default function useOnKeyDown(handler, targetKey) {
  const ref = useRef();

  useEffect(
    () => {
      const listener = ({ key }) => {
        if (key === targetKey) {
          handler(key, true);
        }
      };

      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    },
    [ref, handler],
  );

  return ref;
}
