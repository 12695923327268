import React from 'react';

import 'app/components/stats/progress-column-charts/progress-column-charts.scss';
import { array } from 'prop-types';

import Bar from 'app/components/stats/progress-column-charts/bar.component';

export const ProgressColumnCharts = ({ statsToDisplay }) => {
  return (
    <div className="chart">
      <Bar
        data={
          statsToDisplay.map((item, index) => ({
            index: index,
            date: item.date ? item.date : item.dateStart,
            total: item.total,
            correct: item.correct
          }))}
        width={600}
        height={350}
        top={20}
        bottom={90}
        left={50}
        right={0}
      />
      
      <div className="chart-legend">
        <div className="chart-legend-item chart-legend-item-total">
          <span>total activities</span>
        </div>

        <div className="chart-legend-item">
          <span>correct activities</span>
        </div>
      </div>
    </div>
  );
};

ProgressColumnCharts.propTypes = {
  statsToDisplay: array.isRequired
};
