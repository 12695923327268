import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ActivitiesList } from 'app/components/activities-list/activities-list.component';
import { ErrorScreen } from 'app/components/error-screen/error-screen.component';
import { HomePageFooter } from 'app/components/footer/home-page-footer/home-page-footer.component';
import { GoButton } from 'app/components/go-button/go-button.component';
import { HomePageHeader } from 'app/components/header/home-page-header/home-page-header.component';
import { HelperBox } from 'app/components/helper/helper-box/helper-box.component';
import { MasteredWordsWrapper } from 'app/components/marginals/mastered-words-wrapper/mastered-words-wrapper.component';
import { NextButton } from 'app/components/next-button/next-button.component';
import { HomeStats } from 'app/components/stats/home-stats/home-stats.component';
import { ArrowTab } from 'app/components/tabs/arrow-tab.component';
import { TabPanel } from 'app/components/tabs/tab-panel.component';
import { TabPanels } from 'app/components/tabs/tab-panels.component';
import { Tabs } from 'app/components/tabs/tabs.component';
import { EXTERNAL_SOURCES } from 'app/constants/external-sources.constant';
import { STAGES } from 'app/constants/messages.constants';
import { TAB_KEYS, TAB_VALUES } from 'app/constants/tabs.constants';
import { useFirstEncounters } from 'app/hooks/use-first-encounters.hook';
import { useHelperMessages } from 'app/hooks/use-helper-messages.hook';
import useLevelBackground from 'app/hooks/use-level-background.hook';
import { useQuestStatus } from 'app/hooks/use-quest-status.hook';
import { useUrlParams } from 'app/hooks/use-url-params.hook';
import { SessionStorageService } from 'app/services/session-storage.service';
import backButton from 'app/shared/images/back_button.svg';
import { activityDataStaticActions } from 'app/store/actions/activity-data.actions';
import { setHelperStageAndSettings } from 'app/store/actions/helper-data.action';
import { profileDataActionCreators } from 'app/store/actions/profile-data.actions';
import { setFinishedPlay } from 'app/store/actions/words-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { getRedirectUrl } from 'app/util/url';

import 'app/pages/home/home.scss';

const HomePage = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const playBlockRef = useRef();

  const {  stats } = useSelector(state => state.profileData);
  const { firstEncounters } = useSelector(state => state.wordsData);
  const { getUserStatsStatus, getCklaFirstEncountersStatus, getArFirstEncountersStatus } = useSelector(state => state.apiReducer);
  const haveGottenFirstEncounters = isSuccess(getCklaFirstEncountersStatus) || isSuccess(getArFirstEncountersStatus);

  const [pendingActivities, setPendingActivities] = useState(null);

  useUrlParams();
  useLevelBackground(playBlockRef);
  useFirstEncounters();
  useQuestStatus();

  // Start showing messages
  useEffect(() => {
    dispatch(setHelperStageAndSettings(STAGES.WELCOME));
  }, []);

  // Mark that we haven't started playing
  useEffect(() => {
    dispatch(setFinishedPlay(false));
  }, []);

  // Get words and apply level background
  useEffect(() => {
    SessionStorageService.setPendingActivities(null);
    SessionStorageService.setTeacherDoingActivity(null);
    dispatch(profileDataActionCreators.getUserStats.request());
    if (SessionStorageService.getExternalSource() !== EXTERNAL_SOURCES.READING)
      setPendingActivities(SessionStorageService.getPendingActivities());
  }, []);

  useEffect(() => {
    const currentPendingActivities = SessionStorageService.getPendingActivities();
    if (currentPendingActivities && currentPendingActivities.length) {
      // Ensure that we don't already have words pending from the session
      setPendingActivities(currentPendingActivities);
    } else if (haveGottenFirstEncounters && firstEncounters) {
      // Otherwise rely on first encounters
      if (!firstEncounters.length) {
        dispatch(activityDataStaticActions.resetCompletedActivities());
        dispatch(setFinishedPlay(true));
      }

      SessionStorageService.setPendingActivities(firstEncounters);
      setPendingActivities(SessionStorageService.getPendingActivities());
    }
  }, [firstEncounters, haveGottenFirstEncounters]);


  const focusHeaderButton = () => {
    document.getElementById('mastered_words_button').classList.add('focused');
  };

  const blurHeaderButton = () => {
    document.getElementById('mastered_words_button').classList.remove('focused');
  };

  const { messages, hideMessage, showRandomMessage, readyToDropChest } = useHelperMessages();

  return (
    <>
      <div id="slider-container-outer" className={clsx('home-page-content', isModalVisible && 'gray-outed')}>
        <h1 className="sr-only">Home Page</h1>
        <ErrorBoundary
          FallbackComponent={ErrorScreen}
          onReset={() => {navigate('/home');} }
        >
          <Tabs id="header_tabs" tabKey={TAB_KEYS.HOME_PAGE} defaultValue="home">
            <HomePageHeader setIsModalVisible={setIsModalVisible}/>
            <TabPanels id="slider-container" className="home-page-items slider-container-transition">
              <TabPanel value={TAB_VALUES.HOME_PAGE.HOME} className="play-block slider-item" ref={playBlockRef}>
                {SessionStorageService.getExternalSource() === EXTERNAL_SOURCES.READING ?
                  <button style={{
                    backgroundImage: `url(${backButton})`
                  }} onClick={() => window.location = getRedirectUrl()} aria-label="Go back to Metagame" className="back-button" />
                  : null}
                <HelperBox classNames="helper-box outer" messages={messages} hideMessage={hideMessage} showRandomMessage={showRandomMessage} />
                <GoButton firstEncounters={pendingActivities} readyToDropChest={readyToDropChest}/>
                <ActivitiesList classNames="activities-box" firstEncounters={pendingActivities} readyToDropChest={readyToDropChest} />
                <div className="next-button-box">
                  <ArrowTab value={TAB_VALUES.HOME_PAGE.STATS} aria-label="Open Stats Tab" className="next-button">
                    <NextButton buttonId="next-to-stats"/>
                  </ArrowTab>
                </div>
              </TabPanel>
              <TabPanel className="stats-block slider-item" value={TAB_VALUES.HOME_PAGE.STATS}>
                <HelperBox classNames="helper-box outer" messages={messages} hideMessage={hideMessage} showRandomMessage={showRandomMessage} />
                <div className="next-button-box">
                  <ArrowTab value={TAB_VALUES.HOME_PAGE.HOME} aria-label="Open Home Tab" className="next-button">
                    <NextButton isBack/>
                  </ArrowTab>
                </div>
                {isSuccess(getUserStatsStatus) && <HomeStats classNames="stats-box" stats={stats}/>}
              </TabPanel>
            </TabPanels>
          </Tabs>
          <button
            className="sr-only"
            onFocus={() => focusHeaderButton()}
            onBlur={() => blurHeaderButton()}
            onClick={() => setIsModalVisible(true)} >
            Open Mastered Words Popup
          </button>
          <HomePageFooter/>
        </ErrorBoundary>
      </div>
      {isModalVisible && <MasteredWordsWrapper setIsModalVisible={setIsModalVisible}/>}
    </>
  );
};

export default HomePage;

