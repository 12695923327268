import React, { forwardRef, useEffect } from 'react';

import composeClassNames from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { DescendantProvider } from 'app/components/tabs/descendant-provider.component';
import { TabsProvider } from 'app/components/tabs/tabs-context';
import { setActiveTab } from 'app/store/actions/tabs-state.actions';


export const Tabs = forwardRef(
  ({ as: Element = 'div', tabKey, className, id, children, defaultValue, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs';

    const dispatch = useDispatch();

    const { activeTabs } = useSelector(state => state.tabState);
    const currentActiveTab = activeTabs ? activeTabs[tabKey] : (defaultValue || null);

    const handleSetActiveTab = (activeTab) => {
      dispatch(setActiveTab({ tabKey, activeTab }));
    };
    
    useEffect(() => {
      dispatch(setActiveTab({ tabKey, activeTab: defaultValue || null }));
    }, []);

    return (
      <Element className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
        <TabsProvider
          value={{
            id,
            activeTab: currentActiveTab,
            setActiveValue: handleSetActiveTab 
          }}
        >
          <DescendantProvider>
            {children}
          </DescendantProvider>
        </TabsProvider>
      </Element>
    );
  }
);

Tabs.displayName = 'Tabs';