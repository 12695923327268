import { API_REQUEST_STATUS } from 'app/constants/api.constants';

export const createRequestTypes = base => {
  return [API_REQUEST_STATUS.REQUEST, API_REQUEST_STATUS.SUCCESS, API_REQUEST_STATUS.FAILURE].reduce(
    (acc, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    Object.keys(API_REQUEST_STATUS)
  );
};
  
export const action = (type, payload = {}) => ({ type, ...payload });

export const createActions = requestTypes => ({
  request: payload => action(requestTypes[API_REQUEST_STATUS.REQUEST], { payload }),
  success: payload => action(requestTypes[API_REQUEST_STATUS.SUCCESS], { payload }),
  failure: error => action(requestTypes[API_REQUEST_STATUS.FAILURE], { payload: error }),
});
  