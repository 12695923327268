import { DEFAULT_LIMIT_WORDS } from 'app/constants/settings.constants';
import { classesDataActions, CHANGE_CURRENT_CLASS, CHANGE_CURRENT_STUDENT, GET_DEMO_STUDENT_PROFILES, RESET_STUDENTS, RESET_CLASSES } from 'app/store/actions/classes-data.actions';
import { innerMergeStudentsDict } from 'app/util/classes';

export const initialState = {
  activeClasses: null,
  currentClassBK: null,
  currentStudentBK: null,
  students: null,
  activitiesLimitation: null,
  disabledActivities: null,
};

function classesDataReducers(state = initialState, action) {
  switch (action.type) {
  case classesDataActions.GET_STANDLONE_CLASSES_SECTIONS.SUCCESS:
    return {
      ...state,
      activeClasses: action.payload.activeClasses,
    };

  case classesDataActions.GET_CDA_CLASSES_SECTIONS.SUCCESS:
    return {
      ...state,
      activeClasses: action.payload.activeClasses,
    };

  case classesDataActions.GET_STUDENTS_CLASSES.SUCCESS:
    return {
      ...state,
      activeClasses: action.payload.activeClasses,
    };

  case classesDataActions.GET_STUDENT_STANDALONE_PROFILES.SUCCESS:
    return {
      ...state,
      students: action.payload.students,
    };

  case classesDataActions.GET_STUDENT_CDA_PROFILES.SUCCESS:
    return {
      ...state,
      students: action.payload.students,
    };

  case classesDataActions.GET_ACTIVITIES_LIMITATION.SUCCESS: {
    const limitations = action.payload.activitiesLimitation;
    return {
      ...state,
      activitiesLimitation: limitations == 0 ? DEFAULT_LIMIT_WORDS : limitations,
    };
  }

  case classesDataActions.GET_DISABLED_ACTIVITIES.SUCCESS:
    return {
      ...state,
      disabledActivities: action.payload.disabledActivities,
    };

  case classesDataActions.GET_TEACHER_SECTION_STATS.SUCCESS:
    return {
      ...state,
      students: innerMergeStudentsDict(state.students, action.payload.performanceDict, 'stats'),
    };

  case classesDataActions.GET_STUDENT_LATEST_PROFILE_FOR_TEACHER.SUCCESS:
    return {
      ...state,
      students: innerMergeStudentsDict(state.students, action.payload.latestProfile, 'latestProfile'),
    };
  case classesDataActions.GET_STUDENT_STATS.SUCCESS:
    return {
      ...state,
      studentStats: innerMergeStudentsDict(state.students, { [state.currentStudentBK]: action.payload.studentStats }, 'studentStats'),
    };
  case classesDataActions.GET_LATEST_STUDENT_PROFILE.SUCCESS:
    return {
      ...state,
      studentStats: innerMergeStudentsDict(state.students, { [state.currentStudentBK]: action.payload.latestProfile }, 'latestProfile'),
    };
    //* **** Non-Fetch *****//

  case CHANGE_CURRENT_CLASS:
    return {
      ...state,
      currentClassBK: action.payload,
    };
  case CHANGE_CURRENT_STUDENT:
    return {
      ...state,
      currentStudentBK: action.payload,
    };

  case GET_DEMO_STUDENT_PROFILES: {
    const demoStudent = {
      [action.payload.businessKey]: action.payload,
    };

    return {
      ...state,
      students: demoStudent,
    };
  }

  case RESET_STUDENTS:
    return {
      ...state,
      currentStudentBK: null,
    };

  case RESET_CLASSES:
    return {
      ...state,
      currentClassBK: null,
      students: null,
    };

  default:
    return state;
  }
}

export default classesDataReducers;
