import { Buffer } from 'buffer';

import React, { useRef, useEffect } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'app/components/loader/loader';
import { WordDetail } from 'app/components/word-modal/word-detail.component';
import { activityMapping } from 'app/constants/activities.constants';
import { KEY_NAMES } from 'app/constants/keys.constant';
import routesConstants from 'app/constants/routes.constant';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';
import { SessionStorageService } from 'app/services/session-storage.service';
import { resetRequest } from 'app/store/actions/api-requests.action';
import { resetWordDetails, wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { getStreamNameForPopup } from 'app/util/streams';

import 'app/components/word-modal/word.modal.scss';

export const WordModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getWordDataStatus } = useSelector(state => state.apiReducer);

  const { wordData } = useSelector(state => state.wordsData);

  const inputRefs = useRef({});
  const popupRef = useRef();

  useEffect(() => {
    SessionStorageService.setTeacherDoingActivity(null);
    SessionStorageService.setPendingActivities([]);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleWordChange = (e, wordID) => {
    if (typeof e == 'object')
      e.preventDefault();

    dispatch(wordsDataActionCreators.getWordData.request(wordID));
  };

  const handleActivitiesSelection = (e) => {
    if (typeof e == 'object')
      e.preventDefault();

    // If activity is already selected, unselect it
    if (e.currentTarget.classList.contains('selected'))
      e.currentTarget.classList.remove('selected');
    else
      e.currentTarget.classList.add('selected');
  };

  const handleCloseModal = (e) => {
    if (typeof e == 'object')
      e.preventDefault();

    dispatch(resetRequest('getWordDataStatus'));
    dispatch(resetWordDetails());
  };

  const goToNextActivity = () => {
    SessionStorageService.setTeacherDoingActivity({ wordId: wordData.id });

    const pendingActivities = SessionStorageService.getPendingActivities();
    let url = routesConstants.HOME;
    if (pendingActivities && pendingActivities.length) {
      const nextActivity = pendingActivities[0].wordActivity;
      url = `/${nextActivity.activityType.toLowerCase()}/${nextActivity.id}`;
    }
    navigate(url, { replace: true });
  };

  const launchActivities = (e) => {
    e.preventDefault();

    // Get selected activities list ids
    const activityIdsList = [];

    Object.entries(inputRefs.current).forEach(([id, el]) => {
      if (el.classList.contains('selected'))
        activityIdsList.push(id);
    });

    // Use ids to get activities list
    const activitiesList = wordData.activities.filter(activity => {
      return activityIdsList.includes(String(activity.id));
    }).map(activity => {
      return { wordActivity: activity };
    });

    SessionStorageService.setPendingActivities(activitiesList);
    goToNextActivity();
  };

  useOnKeyDown(handleCloseModal, KEY_NAMES.ESCAPE);
  useOnClickOutside(popupRef, handleCloseModal);

  return (
    <div className="wordModal">
      <div className="background-opacity" />
      <div name="popupWordModal" role="dialog" aria-labelledby="confirm-modal-header" aria-describedby="confirm-modal-description" className='wordPopupModal' id='word-popup-modal' ref={popupRef}>
        {
          isSuccess(getWordDataStatus) && wordData ?
            <React.Fragment>
              <section className='wordDetails'>
    
                <div className="wordName" id="word-name">{wordData.word}</div>
    
                <WordDetail infoLabel="PART OF SPEECH" infoValue={wordData && wordData.partOfSpeech && wordData.partOfSpeech.name} />
                <WordDetail infoLabel="STREAMS" infoValue={wordData && wordData.stream && wordData.stream.length > 0 && wordData.stream && _.map(wordData.stream, e => getStreamNameForPopup(e.name)).join(', ')} />
                <WordDetail infoLabel="DEFINITION" infoValue={wordData && wordData.definition} />
                <WordDetail infoLabel="CONTEXT SENTENCE" infoValue={wordData && wordData.contextSentence} />
                <WordDetail infoLabel="WORD IN SPANISH" infoValue={wordData && wordData.wordInSpanish} />
                <WordDetail infoLabel="SPANISH INFINITIVE" infoValue={wordData && wordData.spanishInfinitive} />
                <WordDetail infoLabel="ENGLISH INFINITIVE" infoValue={wordData && wordData.englishInfinitive} />
                <WordDetail infoLabel="ROOT" infoValue={wordData && wordData.root} />
                <WordDetail infoLabel="FAMILY" infoValue={wordData && wordData.family} />
                <WordDetail infoLabel="SOURCE" infoValue={wordData && wordData.keyText && wordData.keyText.name} />
                <WordDetail infoLabel="SYNONYMS" infoValue={
                  wordData && wordData.synonyms && wordData.synonyms.length > 0 &&
                  wordData.synonyms.map(word => {
                    return (
                      <button className='link' key={word.id} onClick={e => handleWordChange(e, word.wordId)}>
                        <p>{`${Buffer.from(word.word, 'base64').toString()} `}</p>
                      </button>
                    );
                  })} />
                <WordDetail infoLabel="ANTONYMS" infoValue={
                  wordData && wordData.antonyms && wordData.antonyms.length > 0 &&
                  wordData.antonyms.map(word => {
                    return (
                      <button className='link' key={word.id} onClick={e => handleWordChange(e, word.wordId)}>
                        <p>{`${Buffer.from(word.word, 'base64').toString()} `}</p>
                      </button>
                    );
                  })
                } />
    
              </section>
    
              <section className="wordActivities">
                <div className="activityListLabel">SELECT ACTIVITIES FOR PREVIEW</div>
                <div className="activities">
                  {
                    wordData.activities.map(activity => {
                      return (
                        <div className="activityList" id={`card-${activity.id}`} key={`card-${activity.id}`}>
                          <button className="activityItem" key={`item-${activity.id}`} ref={el => (inputRefs.current[activity.id] = el)} onClick={handleActivitiesSelection}>
                            <div className="activityListLabel" key={`list-label-${activity.id}`}>
                              <span className="activityType" id={`activity-name-${activity.id}`} key={`activity-label-name-${activity.id}`}>{activityMapping[activity.activityType].name}: </span>
                              <span className="activityDescription" id={`activity-description-${activity.id}`} key={`activity-label-description-${activity.id}`}>{activityMapping[activity.activityType].description}</span>
                            </div>
                            <div className="standard" id={`actovoty-standard-${activity.id}`}>
                              {activity.standards.length > 1 ? 'Standards:' : 'Standard:'}
                              {activity.standards.length ? _.map(activity.standards, (standardDict) => standardDict['name']).join(', ') : 'n/a'}
                            </div>
                          </button>
                        </div>
                      );
                    })
                  }
                </div>
                <button id="launchButton" className="button orange bold" onClick={launchActivities}>
                  {wordData.activities.length ? 'LAUNCH' : 'NO ACTIVITIES'}
                </button>
              </section>
    
            </React.Fragment>
            :
            <Loader />
        }
        <button className="closeBtn" alt="Close" onClick={handleCloseModal} />
      </div>
    </div>
  );
};