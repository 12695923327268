import React from 'react';

import 'app/components/helper/helper-box/helper-box.scss';
import { useSelector } from 'react-redux';

import 'app/components/helper/helper-dude/helper-dude.scss';

export const HelperDude = ({ showRandomMessage }) => {
  const { stats } = useSelector(state => state.profileData);

  const levelToString = function (level) {
    if (level > 47) {
      return 'NA';
    } else {
      return String(level).length === 1 ? '0' + level : String(level);
    }
  };

  return (
    <div className='helperWrapper'>
      {stats.level &&
      <div className='helper'>
        <img
          className='limbs'
          aria-hidden={true}
          alt=""
          draggable={false}
          src={require(`../../../shared/images/helper/static/limbs_${levelToString((Math.floor(Math.random() * 4) + 1))}.png`)}
        />
        <div
          id='dugdeBtn'
          className='body'
          onClick={() => showRandomMessage()}
          onKeyDown={() => showRandomMessage()}
          role='button'
          tabIndex='0'
        >
          <img
            id='dudeBtnImg'
            className='body'
            aria-label='Show new helper message'
            draggable={false}
            src={require(`../../../shared/images/helper/body_${levelToString(stats.level)}.png`)}
          />
        </div>
      </div>
      }
    </div>
  );
};

