import React from 'react';

export const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g stroke="#FFF" fill="none" fillRule="evenodd">
        <circle strokeWidth="5" cx="28" cy="28" r="25.5"/>
        <circle strokeWidth="4" cx="28" cy="24" r="9"/>
        <path d="M7 45c5.089-3.81 8.672-6.196 10.75-7.159 4.478-2.074 7.933-1.79 10.75-1.79 2.978 0 5.916-.527 10.75 1.79 1.948.934 5.531 3.32 10.75 7.159" strokeWidth="4"/>
      </g>
    </svg>
  );
};
