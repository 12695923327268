export const STANDALONE_GRADES = {
  'FIRST': 1,
  'SECOND': 2,
  'THIRD': 3,
  'FOURTH': 4,
  'FIFTH': 5,
  'SIXTH': 6,
  '06': 6,
  'SEVENTH': 7,
  '07': 7,
  'EIGHTH': 8,
  '08': 8,
  'NINTH': 9,
  'TENTH': 10,
  'ELEVENTH': 11,
  'TWELFTH': 12,
  'PREKINDERGARTEN': 1,
  'KINDERGARTEN': 1,
  'POSTGRADUATE': 1,
  'OTHER': 1
};

export const CURRICULUM_GRADES = {
  '6th Grade': 6,
  '7th Grade': 7,
  '8th Grade': 8
};
