import React from 'react';

import clsx from 'clsx';
import { element, string } from 'prop-types';

import 'app/components/header/subnav/subnav.scss';

export const SubNav = (props) => {
  const { children, classNames } = props;

  return (
    <ol className={clsx(
      'subnav',
      classNames
    )}
    role="banner">
      <div className="link-container">
        {children}
      </div>
    </ol>
  );
};

SubNav.propTypes = {
  children: element,
  classNames: string,
};
