import _ from 'lodash';

import { wordsDataActions, wordsStaticActions } from 'app/store/actions/words-data.actions';


export const initialState = {
  firstEncounters: [],
  finishedPlay: undefined,
  wordList: undefined,
  words: undefined,
  wordData: undefined,
  lessons: undefined,
  showWords: undefined,
  showLessons: undefined,
  batchNumber: 1,
  filterMode: false,
  filters: {
    gradeOption: undefined,
    streamOption: undefined,
    unitOption: undefined,
    subUnitOption: undefined,
    viewOption: undefined,
    isCore: undefined,
  },
  unit: undefined,
  subunit: undefined,
};

function wordsDataReducer(state = initialState, action) {
  switch (action.type) {
  case wordsDataActions.GET_CKLA_FIRST_ENCOUNTERS.SUCCESS:
    return {
      ...state,
      firstEncounters: action.payload
    };
  case wordsDataActions.GET_AR_FIRST_ENCOUNTERS.SUCCESS:
    return {
      ...state,
      firstEncounters: action.payload
    };
  case wordsDataActions.GET_KS_WORD_LIST.SUCCESS:
    return {
      ...state,
      wordList: action.payload,
    };

  case wordsDataActions.GET_WORDS_BY_STREAM.SUCCESS:
    return {
      ...state,
      words: action.payload,
    };
  case wordsDataActions.GET_WORDS_BY_UNIT_SUBUNIT.SUCCESS:
    return {
      ...state,
      lessons: action.payload,
    };
  case wordsDataActions.GET_WORD_DATA.SUCCESS:
    return {
      ...state,
      wordData: action.payload,
    };
  case wordsDataActions.GET_UNIT_SUBUNIT.SUCCESS:
    return {
      ...state,
      unit: action.payload[0],
      subunit: action.payload[1],
    };

  // Non fetching
  case wordsStaticActions.SET_FINISHED_PLAY: {
    return {
      ...state,
      finishedPlay: action.payload,
    };
  }
  case wordsStaticActions.RESET_WORD_DETAILS: {
    return {
      ...state,
      wordData: null,
    };
  }
  case wordsStaticActions.SET_ACTIVE_WORDS_LIST_BK: {
    return {
      ...state,
      wordsListBK: action.payload,
    };
  }
  case wordsStaticActions.SET_FILTER_WORDS_MODE: {
    return {
      ...state,
      filterMode: action.payload,
    };
  }
  case wordsStaticActions.SET_BATCH_NUMBER: {
    return {
      ...state,
      batchNumber: action.payload,
    };
  }
  case wordsStaticActions.SET_WORDS: {
    return {
      ...state,
      words: action.payload,
    };
  }
  case wordsStaticActions.SET_SHOW_WORDS: {
    return {
      ...state,
      showWords: _.sortBy(action.payload, 'word'),
    };
  }
  case wordsStaticActions.SET_LESSONS: {
    return {
      ...state,
      lessons: action.payload,
    };
  }
  case wordsStaticActions.SET_SHOW_LESSONS: {
    return {
      ...state,
      showLessons: _.sortBy(action.payload, 'lesson'),
    };
  }
  case wordsStaticActions.SET_WORDS_GRADE_FILTER: {
    return {
      ...state,
      filters: {
        ...state.filters,
        gradeOption: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_WORDS_STREAM_FILTER: {
    return {
      ...state,
      filters: {
        ...state.filters,
        streamOption: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_WORDS_UNIT_FILTER: {
    return {
      ...state,
      filters: {
        ...state.filters,
        unitOption: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_WORDS_SUBUNIT_FILTER: {
    return {
      ...state,
      filters: {
        ...state.filters,
        subUnitOption: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_VIEW_OPTION_FILTER: {
    return {
      ...state,
      filters: {
        ...state.filters,
        viewOption: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_IS_CORE: {
    return {
      ...state,
      filters: {
        ...state.filters,
        isCore: action.payload,
      },
    };
  }
  case wordsStaticActions.SET_NO_FIRST_ENCOUNTERS: {
    return {
      ...state,
      firstEncounters: [],
    };
  }

  default:
    return state;
  }
}

export default wordsDataReducer;
