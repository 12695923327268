import { emptyStream } from 'app/constants/streams.constants';

const MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
  'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getMonthName = dateStr => {
  // get month name from date string knowing string is in format yyyy-mm-dd
  // Date-only strings (e.g. "1970-01-01") are treated as UTC
  // while date-time strings (e.g. "1970-01-01T12:00") are treated as local.
  // append a time "T00:00:01" to the dateStr so the date object is in local time.
  const date = new Date(`${dateStr}T00:00:01`);
  return MONTH_NAME[date.getMonth()];
};

const getDayFromDate = dateStr => {
  const date = new Date(`${dateStr}T00:00:01`);
  return date.getDate();
};

const getDayWeekAfter = date => {
  return new Date(date.getTime() + 24*60*60*1000*6);
};

const getWeekRange = dateStr => {
  const date = new Date(`${dateStr}T00:00:01`);
  return `${date.getDate()}-${getDayWeekAfter(date).getDate()}`;
};

export const getStatsPerDay = stats => {
  if (!stats || !stats.statsPerDay || !stats.statsPerDay.length) return;
  return stats.statsPerDay.slice(0, 7).map(item => {
    return {
      ...item,
      date: `${getMonthName(item.date)} ${getDayFromDate(item.date)}`
    };
  }).reverse();
};

export const getStatsPerWeek = stats => {
  if (!stats || !stats.statsPerWeek || !stats.statsPerWeek.length) return;
  return stats.statsPerWeek.slice(0, 7).map(item => {
    return {
      ...item,
      date: `${getMonthName(item.dateStart)} ${getWeekRange(item.dateStart)}`
    };
  }).reverse();
};

export const getStatsPerMonth = stats => {
  if (!stats || !stats.statsPerMonth || !stats.statsPerMonth.length) return;
  return stats.statsPerMonth.slice(0, 7).map(item => {
    return {
      ...item,
      date: `${getMonthName(item.date)}`
    };
  }).reverse();
};

export const getAccuracy = (percent, stream) => {
  if (!stream || stream == emptyStream)
    return 'NA';
  return `${percent}%`;
};

export const getAccuracyColor = accuracyPercent => {
  if (!accuracyPercent || accuracyPercent == 'NaN')
    return '';

  const accuracy = parseFloat(accuracyPercent.replace('%', ''));

  if (isNaN(accuracy) || accuracy < 55)
    return 'red';
  else if (accuracy <= 75)
    return 'yellow';
  else
    return '';


};
