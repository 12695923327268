import { tabStateActions } from 'app/store/actions/tabs-state.actions';

export const initialState = {
  activeTabs: {},
};

function tabStateReducer(state = initialState, action) {
  switch (action.type) {
  case tabStateActions.SET_ACTIVE_TAB:
    return {
      ...state,
      activeTabs: {
        ...state.activeTabs,
        [action.payload.tabKey]: action.payload.activeTab,
      },
    };
  default:
    return state;
  }
}

export default tabStateReducer;