import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import { directionsTimeline, RisingTidesDirections } from 'app/components/syn-ant-activity/risingtides/animated/directions.component';

export const intro = new gsap.timeline({ paused: true, repeat: 1 });

export const Intro = () => {

  useEffect(() => {

    var waterBg = new gsap.timeline({ paused: true, repeat: 1 });
    waterBg
      // step 1
      .add(gsap.to('#water', { 
        duration: 0.5, 
        attr: { 
          d: 'M966 609C936.597 608.945 907.22 607.276 878 604C855 601.44 824.46 594.38 799 593C762 591 694 605 658 612C607 621.93 549.22 608 507 599C474 592 433 592 406 595C344.85 601.79 291.2 610.28 246 606C198.5 601.5 111 594 68 595C51.73 595.38 19.7 605 0 605V746H1024V604C1005 607 983 609 966 609Z'
        }, 
        ease: 'none' 
      }), 0)
      .add(gsap.to('#swellA', { 
        duration: 0.5, 
        attr: { 
          d: 'M222.5 610.5C242.5 609.5 298.75 611.85 336.5 607.5C374.25 603.15 437.5 598.5 450.5 599.5C427.5 601.5 411.7 609.09 413 613C415.5 620.5 433 623 478 616C452 623 403 634 380 638C356 642.17 293 643 266 638C236.14 632.47 191.5 619.5 169.5 618.5C182.5 617.5 254.3 626.7 260 621C263.5 617.5 236.5 609.5 222.5 610.5Z'
        }, 
        ease: 'none' 
      }), 0)
      .add(gsap.to('#swellB', { 
        duration: 0.5, 
        attr: { 
          d: 'M527 630C563 634 600.68 640.51 626 644C655 648 706 647.62 743 640C780 632.38 819 620.76 823 620C782 627 765 624 761 617C758.29 612.25 779 606 800.5 602.5C773.5 598.5 712.62 607.5 686.12 612.73C651.78 619.57 602.5 619.5 574.5 619.11C601.5 624.44 609.35 626.59 606.5 630.11C601.5 636.17 540 629.24 527 630Z'
        }, 
        ease: 'none' 
      }), 0)
      .add(gsap.to('#shineA', { 
        duration: 0.5, 
        attr: { 
          d: 'M450.5 599.5C428 596 376 600 335.39 606.62C298.83 612.62 244.87 609.62 224.07 610.43C261 616 315.33 613.5 337 611C359.42 608.41 401 600 450.5 599.5Z'
        }, 
        ease: 'none' 
      }), 0)
      .add(gsap.to('#shineB', { 
        duration: 0.5, 
        attr: { 
          d: 'M688 610C643 618.76 602.33 618.33 574.5 619.11C610.513 623.864 646.997 623.827 683 619C716.74 614.63 748 602 800.5 602.5C781 597 717.94 604.17 688 610Z'
        }, 
        ease: 'none' 
      }), 0)
      // step 2
      .add(gsap.to('#water', { 
        duration: 0.5, 
        attr: { 
          d: 'M967.5 588.5C935.04 594.76 901.5 598 880 598C854.091 597.844 828.214 596.175 802.5 593C760.67 587.42 728 580 689 581C635 582.38 589 603 516 603C486 603 443.79 593.44 417 588C353 575 289 586 245 592C191.72 599.27 126 591 81 586C64.82 584.2 22 583 0 584V746H1024V583C1005.03 582.95 986.104 584.793 967.5 588.5Z'
        }, 
        ease: 'none' 
      }), 0.5)
      .add(gsap.to('#swellA', { 
        duration: 0.5, 
        attr: { 
          d: 'M223.5 600.5C239.5 597.5 307.5 586.5 345.5 586.5C383.5 586.5 445.23 599.19 454.15 601.5C431.09 602.65 407.5 602.5 408 607.98C408.41 612.49 425 615.98 458 617.98C444 620.98 400.8 618.98 377 613.98C360.5 610.48 321.84 606.06 295 608.98C249 613.98 172 620.98 150 619.98C163 618.98 245.85 611.89 250 604.98C251.5 602.5 237.5 599.5 223.5 600.5Z'
        }, 
        ease: 'none' 
      }), 0.5)
      .add(gsap.to('#swellB', { 
        duration: 0.5, 
        attr: { 
          d: 'M504 624C526 626 604.3 617.67 630 614C658 610 698.13 610.08 731 617C769 625 810 626 819.5 620.5C796.5 624.5 758.08 617.42 757 612C756 607 792 606 806.86 605.38C785.68 600.05 734.5 590.5 699.5 589.5C664.5 588.5 596.76 600.5 574 605.7C588 606 602.12 605.05 600 610C598.5 613.5 517 623 504 624Z'
        }, 
        ease: 'none' 
      }), 0.5)
      .add(gsap.to('#shineA', { 
        duration: 0.5, 
        attr: { 
          d: 'M454.15 601.52C437 595 383.5 586.52 345.5 586.52C307.5 586.52 239.5 597.52 223.5 600.52C270.5 595.52 321 592.02 345 592.02C369 592.02 403 594 454.15 601.52Z'
        }, 
        ease: 'none' 
      }), 0.5)
      .add(gsap.to('#shineB', { 
        duration: 0.5, 
        attr: { 
          d: 'M699.5 589.5C664.5 588.5 605 598 574 605.7C609.84 601.85 660 595 697.5 594.5C731.5 594.05 770.72 601.5 806.86 605.38C785.68 600.05 734.5 590.5 699.5 589.5Z'
        }, 
        ease: 'none' 
      }), 0.5)
      // step 3
      .add(gsap.to('#water', { 
        duration: 0.5, 
        attr: { 
          d: 'M969 586C946 585 903 588 880 593C857.52 597.89 830 597 803 599C760.91 602.12 712 597 682 591C629 580.41 574.77 584.88 515 598C474 607 439 608 411 604C346.35 594.76 297 579 228 587C174.58 593.19 128 598 83 597C66.73 596.64 22 592 0 588V746H1024V589C1004.83 588.84 987.85 586.82 969 586Z'
        }, 
        ease: 'none' 
      }), 1)
      .add(gsap.to('#swellA', { 
        duration: 0.5, 
        attr: { 
          d: 'M200.5 597.5C216.5 594.5 299.6 590.29 337 597C374.4 603.71 420 609 452.15 610.52C426 613 397 615 396 618C394.57 622.3 410 621 440 627C426 628 385.8 624 362 619C345.5 615.5 307 615 283 615C236.73 615 174 619 150 620C163 619 244 612 247 603C247.92 600.23 214.5 596.5 200.5 597.5Z'
        }, 
        ease: 'none' 
      }), 1)
      .add(gsap.to('#swellB', { 
        duration: 0.5, 
        attr: { 
          d: 'M510 621C532 623 597 619 623 619C650 619 684.81 618.81 718 624C750 629 776 631 786 630C767 627 747 625 746 623C743.72 618.44 787.27 617.27 801.86 614.38C780.12 612.38 722.4 604.89 687.86 598.82C653.32 592.75 572.76 595.52 550 600.7C564 601 599.12 602.05 597 607C595.5 610.5 523 620 510 621Z'
        }, 
        ease: 'none' 
      }), 1)
      .add(gsap.to('#shineA', { 
        duration: 0.5, 
        attr: { 
          d: 'M452.15 610.52C429 610 376.69 601.37 339.29 594.66C301.89 587.95 229 589 200.5 597.5C247.5 592.5 314.2 595.75 337.82 599.98C361.44 604.21 411 613 452.15 610.52Z'
        }, 
        ease: 'none' 
      }), 1)
      .add(gsap.to('#shineB', { 
        duration: 0.5, 
        attr: { 
          d: 'M687.91 598.82C653.43 592.76 594 590 550 600.7C585.84 596.85 642.82 597.06 680 602C713.73 606.49 765.56 616.1 801.86 614.38C780.12 612.36 722.4 604.89 687.91 598.82Z'
        }, 
        ease: 'none' 
      }), 1)
      // step 4
      .add(gsap.to('#water', { 
        duration: 1, 
        attr: { 
          d: 'M968 612.5C937 610.95 917.5 608.5 878.5 600.5C855.78 595.84 826.83 596.62 801.5 599.5C757.5 604.5 713.5 619.75 674.5 620.5C622.5 621.5 571.22 603.28 511.5 597.5C480.5 594.5 443.6 600.95 416.5 604.5C355.5 612.5 293.5 610.5 248.5 604.5C195.2 597.39 119.5 597.5 74.5 606.5C58.5 609.69 19.7 612 0 612V746H1024V612.5C1005.35 613.5 986.653 613.5 968 612.5Z'
        }, 
        ease: 'sine.out' 
      }), 1.5)
      .add(gsap.to('#swellA', { 
        duration: 1, 
        attr: { 
          d: 'M232.5 611.5C252.5 610.5 299.5 613.5 337.5 613.5C375.5 613.5 437.5 604.5 450.5 605.5C427.5 607.5 411.7 617.09 413 621C415.5 628.5 436 627 467 621C453 624 412 638 387 644C363.34 649.68 300.5 654.5 271.5 645.5C242.5 636.5 191.5 619.5 169.5 618.5C182.5 617.5 251.9 631.12 258.5 626.5C268.5 619.5 246.5 610.5 232.5 611.5Z'
        }, 
        ease: 'sine.out' 
      }), 1.5)
      .add(gsap.to('#swellB', { 
        duration: 1, 
        attr: { 
          d: 'M516.5 627.5C552.5 631.5 593.5 640.16 618.5 645.5C643.5 650.84 707.5 650.12 744.5 642.5C781.5 634.88 806 626.76 810 626C787 629 769.84 636.43 763 626C760 621.43 778 614 800.5 608.5C773.5 604.5 713.5 621.5 686.5 622.5C651.5 623.8 602.5 616.5 574.5 616.11C601.5 621.44 609.35 626.59 606.5 630.11C601.5 636.17 529.5 626.74 516.5 627.5Z'
        }, 
        ease: 'sine.out' 
      }), 1.5)
      .add(gsap.to('#shineA', { 
        duration: 1, 
        attr: { 
          d: 'M450.5 605.5C436 603 374.5 612.5 336.5 612.5C299.5 612.5 254.87 610.65 234.07 611.43C267.83 613.5 318.69 616.5 340.5 616.5C363.07 616.5 405.46 611 450.5 605.5Z'
        }, 
        ease: 'sine.out' 
      }), 1.5)
      .add(gsap.to('#shineB', { 
        duration: 1, 
        attr: { 
          d: 'M686.5 622.5C640.67 624 602.33 615.33 574.5 616.11C608 620 641.23 626.55 677.67 627.67C711.67 628.67 759 612 800.5 608.5C784.75 601.75 717 621.5 686.5 622.5Z'
        }, 
        ease: 'sine.out' 
      }), 1.5);
    
    var waterFg = new gsap.timeline({ paused: true });
    waterFg
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M908 642C872.82 652.35 831 659 799 649C762.72 637.66 722.78 632.22 684 628C638 623 586 629 540 638C481.67 649.41 393.22 642.63 357 635C319 627 252.94 629.41 213 651C176 671 119.06 667.65 82 650C61 640 21 631 0 628V746H1024V630C996 624 942 632 908 642Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M908 661C872 650 832.28 643.06 799 639C758 634 722.69 633 684 638C638 644 586.87 652.26 540 653C477 654 388.49 627.24 355 643C321 659 256.65 682.47 213 670C171 658 119 639 82 636C58.82 634.12 24 643 0 648V746H1024V646C995 653 941.89 671.36 908 661Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M908 645C872 634 832.53 633 799 633C755 633 725 636 684 643C638.27 650.81 575.15 652.19 529 644C467 633 411 634 353 663C319.39 679.8 256.46 663.14 213 650C170 637 117.51 629.19 82 640C59 647 24 654 0 659V746H1024V658C995 665 941.89 655.36 908 645Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M908 620C866 618 829 631 794 634C750.16 637.76 680.68 637.66 640 629C593 619 569 619 514 638C468.27 653.8 413 663.69 353 639C319 625 254 612 213 623C169.61 634.64 119.12 646 82 646C54 646 24 627 0 628V746H1024V631C988 623 943.4 621.69 908 620Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M934 619C889 622 848 635 813 638C769.16 641.76 698.68 633.66 658 625C611 615 577 631 522 650C476.27 665.8 424 652.69 364 628C330 614 253.41 624 213 637C157 655 118 646 82 635C52.74 626.06 24 622 0 623V746H1024V623C988 619 969.36 616.64 934 619Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M936 637C898 643 869.11 648.1 813 638C763 629 695 621 658 640C621 659 567.85 669.35 512 653C471 641 428.35 620 364 628C324 633 252.58 658.53 211 650C172 642 140.39 634.4 103 630C69 626 24 628 0 629V746H1024V627C986 627 971 631.47 936 637Z'
        }, 
        ease: 'none' 
      })
      // 8
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M936 637C894 637 867.11 633.1 811 623C761 614 720 656 658 656C617 656 572.85 643.35 517 627C476 615 418.35 626.19 356 644C314 656 256.58 637.53 215 629C176 621 139.64 621 102 621C67 621 23 627 0 635V746H1024V630C993 635 971.44 637 936 637Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M936 623C894 616 861.66 613.16 809 635C768 652 718.76 657.54 661 635C620 619 570 616 514 622C468.22 626.91 417.34 656 356 635C321 623 259.41 615.77 217 614C169 612 136.52 615.87 100 625C64 634 22 636 0 631V746H1024V630C993 635 971 628.83 936 623Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M936 623C901 633 865.5 663.63 809 656C772 651 739 633 678 622C628 613 570 638 514 644C468.22 648.91 428 631.42 364 621C321 614 258 616 218 621C166.43 627.45 141 633 100 635C62.94 636.81 22 630 0 625V746H1024V630C1003 622 970.08 613.26 936 623Z'
        }, 
        ease: 'none' 
      })
      .to('#fg-water', { 
        duration: 0.5, 
        attr: { 
          d: 'M936 657C893 664 862.93 656.49 809 638C774 626 721 616 672 632C625.38 647.22 571 658 517 641C474.66 627.67 428.83 619.53 364 621C320 622 258 633 218 638C166.43 644.45 139.94 644.46 100 635C62 626 21 626 0 632V746H1024V630C1003 636 971 651.31 936 657Z'
        }, 
        ease: 'none' 
      });

    var buoyBob = new gsap.timeline({ paused: true });
    buoyBob
      .fromTo('#buoy', { transformOrigin: '50% 75%', rotate: -5, y: 500 }, { duration: 1, rotate: 0, y: 10, ease: 'sine.out' })
      .to('#buoy', { duration: 0.5, rotate: 5, y: 0, ease: 'sine.out' })
      .to('#buoy', { duration: 0.5, rotate: 0, y: -10, ease: 'sine.in' })
      .to('#buoy', { duration: 0.5, rotate: -5, y: 0, ease: 'sine.out' })
      .to('#buoy', { duration: 0.5, rotate: 0, y: 10, ease: 'sine.in' })
      .to('#buoy', { duration: 0.5, rotate: 5, y: 0, ease: 'sine.out' })
      .to('#buoy', { duration: 0.5, rotate: 0, y: -10, ease: 'sine.in' })
      .to('#buoy', { duration: 0.5, rotate: -5, y: 0, ease: 'sine.out' })
      .to('#buoy', { duration: 1, rotate: 0, y: 0, ease: 'sine.inOut' });

    var intro = new gsap.timeline({ onStart: () => {
      waterBg.play(0);
      waterFg.play(0);
      buoyBob.play(0);
    } });
    intro
      .fromTo('#sea', { y: 400 }, { duration: 1, y: 0, ease: 'sine.out' })
      .fromTo('#maintitle', { transformOrigin: '50% 50%', opacity: 0, scale: 0.1 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'sine.out' })
      .fromTo('#subtitle', { transformOrigin: '50% 50%', opacity: 0, scale: 0.1 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'sine.out' })
      .fromTo('#seagull', { opacity: 0, scale: 0.05, x: -300, y: -200 }, { duration: 3, opacity: 1, scale: 1, x: 0, y: 0, ease: 'sine.out' })
      .add(gsap.fromTo('#wing-l', { transformOrigin: '95% 60%', rotate: 20 }, { duration: 0.3, rotate: -20, yoyo: true, repeat: 16, ease: 'sine.inOut' }), 0)
      .add(gsap.fromTo('#wing-r', { transformOrigin: '5% 40%', rotate: -20 }, { duration: 0.3, rotate: 20, yoyo: true, repeat: 16, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#wing-l', { duration: 0.2, rotate: 0, ease: 'sine.inOut' }), 5.1)
      .add(gsap.to('#wing-r', { duration: 0.2, rotate: 0, ease: 'sine.inOut' }), 5.1)
      .to('.risingtides-directions', { duration: 1, opacity: 1, display: 'block', delay: 0.7, ease: 'sine.inOut' })
      .add(gsap.to('.passage-text', { duration: 0.5, opacity: 1, display: 'block', ease: 'sine.inOut' }), 6)
      .add(gsap.to('.explanation-next', { duration: 0.5, opacity: 1, display: 'block', ease: 'sine.inOut' }), 6.5)
      .add(gsap.to('.risingtides-intro', { duration: 0.5, opacity: 0, display: 'none', ease: 'sine.inOut' }), 6.5)
      .call(()=> directionsTimeline.play(0));

  });

  return (
    <><div className="risingtides-intro">
      <svg width="1024" height="680" viewBox="0 0 1024 680" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="paint0_linear_4_56" x1="250.13" y1="714.94" x2="1195.55" y2="-638.66" gradientUnits="userSpaceOnUse">
            <stop offset="0.02" stopColor="#7BE5DC" />
            <stop offset="0.05" stopColor="#83E8DF" />
            <stop offset="0.2" stopColor="#A4F2ED" />
            <stop offset="0.34" stopColor="#BCF9F7" />
            <stop offset="0.47" stopColor="#CAFDFD" />
            <stop offset="0.59" stopColor="#CFFFFF" />
          </linearGradient>
        </defs>
        <path id="sky" d="M1024 0H0V680H1024V0Z" fill="url(#paint0_linear_4_56)" />
        <g id="buoy">
          <path d="M512.41 678.61C558.879 678.61 596.55 640.939 596.55 594.47C596.55 548.001 558.879 510.33 512.41 510.33C465.941 510.33 428.27 548.001 428.27 594.47C428.27 640.939 465.941 678.61 512.41 678.61Z" fill="#990062" />
          <path d="M515.16 308.56L499.11 308.83L487.89 530.33L531.06 529.6L515.16 308.56Z" fill="#990062" />
          <path d="M427.89 579.44L596.14 576.58" stroke="#7C0056" strokeWidth="9.5" strokeMiterlimit="10" strokeLinecap="round" />
          <path d="M540.73 510.39L477.33 511.46" stroke="#7C0056" strokeWidth="9.5" strokeMiterlimit="10" strokeLinecap="round" />
          <path d="M528.24 363.66L485.07 364.4" stroke="#7C0056" strokeWidth="9.5" strokeMiterlimit="10" strokeLinecap="round" />
          <g>
            <path id="Vector_6" d="M524.71 358.44L489.64 359.04C487.756 359.072 485.883 358.732 484.13 358.04C482.377 357.348 480.777 356.318 479.422 355.007C478.067 353.697 476.984 352.133 476.234 350.404C475.484 348.674 475.081 346.814 475.05 344.93L474.53 314.67C474.498 312.785 474.838 310.913 475.53 309.16C476.222 307.407 477.253 305.807 478.563 304.452C479.873 303.097 481.437 302.014 483.167 301.264C484.896 300.513 486.756 300.111 488.64 300.08L491.03 298.24C495.616 294.716 501.261 292.85 507.044 292.948C512.827 293.046 518.406 295.102 522.87 298.78L523.71 299.48C525.596 299.447 527.469 299.786 529.223 300.477C530.978 301.168 532.579 302.199 533.934 303.509C535.29 304.819 536.375 306.384 537.126 308.114C537.876 309.844 538.279 311.705 538.31 313.59L538.82 343.85C538.852 345.734 538.512 347.607 537.82 349.36C537.128 351.113 536.098 352.713 534.787 354.068C533.477 355.423 531.913 356.506 530.184 357.256C528.455 358.006 526.595 358.409 524.71 358.44Z" fill="#990062" />
            <path d="M538.333 316.097L474.562 317.177L475.017 344.073L538.788 342.993L538.333 316.097Z" fill="#ED98D3" />
            <path d="M524.001 316.34L513.103 316.524L513.558 343.42L524.457 343.236L524.001 316.34Z" fill="#FFCCF2" />
          </g>
        </g>
        <g id="title">
          <g id="subtitle">
            <path d="M256.8 187.2C258 187.2 258.5 188.4 258.5 189.5C258.5 190.7 257.9 191.9 256.8 191.9H249.4V220.7C249.4 221.8 248.1 222.4 246.9 222.4C245.6 222.4 244.4 221.8 244.4 220.7V191.9H237C235.9 191.9 235.3 190.8 235.3 189.5C235.3 188.4 235.8 187.2 237 187.2H256.8Z" fill="#003760" />
            <path d="M271.6 220.6C271.6 221.4 270.4 222.3 269.1 222.3C267.6 222.3 266.6 221.4 266.6 220.6V187.6C266.6 186.6 267.6 186 269.1 186C270.4 186 271.6 186.7 271.6 187.6V202.9C272.5 201 274.7 199.3 277.7 199.3C282.2 199.3 286.1 203.6 286.1 208.8V220.7C286.1 221.8 284.8 222.4 283.6 222.4C282.4 222.4 281.1 221.8 281.1 220.7V208.8C281.1 206.3 278.9 203.8 276.3 203.8C274 203.8 271.7 205.7 271.7 208.8V220.6H271.6Z" fill="#003760" />
            <path d="M314.7 208.1C314.7 212.1 312.5 212.2 310.3 212.2H300.2V213.3C300.2 216.5 302.7 218.5 306.4 218.5C309.8 218.5 311 216.7 312.3 216.7C313.5 216.7 314.3 218.1 314.3 219C314.3 220.4 311.2 222.6 306.3 222.6C299.8 222.6 295.2 218.8 295.2 213V208.4C295.2 203.4 299.7 199.1 305.2 199.1C310.3 199.2 314.7 202.9 314.7 208.1ZM308.2 209.1C309.6 209.1 310 208.8 310 207.7C310 205.2 307.9 203.1 305.2 203.1C302.6 203.1 300.3 205 300.3 207.6V209.1H308.2Z" fill="#003760" />
            <path d="M336 220.2C336 220.1 336 219.9 336 219.8L345.6 188.7C346 187.5 347.4 187 348.8 187C350.2 187 351.7 187.6 352.1 188.7L361.6 219.8C361.6 219.9 361.7 220.1 361.7 220.2C361.7 221.4 359.9 222.3 358.5 222.3C357.7 222.3 357 222 356.8 221.2L354.8 214.4H342.9L340.9 221.2C340.7 222 340 222.3 339.1 222.3C337.8 222.3 336 221.4 336 220.2ZM353.6 210.3L348.8 193.8L344 210.3H353.6Z" fill="#003760" />
            <path d="M374.8 220.6C374.8 221.4 373.6 222.3 372.3 222.3C370.8 222.3 369.8 221.4 369.8 220.6V201.2C369.8 200.2 370.8 199.6 372.3 199.6C373.5 199.6 374.6 200.3 374.6 201.2V202.9C375.6 201 377.8 199.3 381 199.3C385.5 199.3 389.4 203.6 389.4 208.8V220.7C389.4 221.8 388.2 222.4 386.9 222.4C385.7 222.4 384.4 221.8 384.4 220.7V208.8C384.4 206.3 382.2 203.8 379.6 203.8C377.3 203.8 375 205.7 375 208.8V220.6H374.8Z" fill="#003760" />
            <path d="M404.2 214.8C404.2 217.1 405.1 218 407.6 218H409.3C410.7 218 411.5 219.1 411.5 220.2C411.5 221.3 410.8 222.4 409.3 222.4H407.6C402.1 222.4 399.2 220.1 399.2 214.9V190.8C399.2 189.8 400.5 189.2 401.7 189.2C402.9 189.2 404.2 189.9 404.2 190.8V199.6H410.1C411 199.6 411.5 200.5 411.5 201.5C411.5 202.5 411 203.4 410.1 203.4H404.2V214.8Z" fill="#003760" />
            <path d="M438.4 208.7V213.1C438.4 218.1 433.7 222.7 428.5 222.7C423.2 222.7 418.6 218.2 418.6 213.1V208.7C418.6 203.7 423.1 199.2 428.5 199.2C433.7 199.2 438.4 203.5 438.4 208.7ZM433.3 208.7C433.3 206.2 431.1 203.7 428.4 203.7C425.7 203.7 423.5 206.2 423.5 208.7V213.1C423.5 215.7 425.7 218.2 428.4 218.2C431.1 218.2 433.3 215.7 433.3 213.1V208.7Z" fill="#003760" />
            <path d="M452.6 220.6C452.6 221.4 451.4 222.3 450.1 222.3C448.6 222.3 447.6 221.4 447.6 220.6V201.2C447.6 200.2 448.6 199.6 450.1 199.6C451.3 199.6 452.4 200.3 452.4 201.2V202.9C453.4 201 455.6 199.3 458.8 199.3C463.3 199.3 467.2 203.6 467.2 208.8V220.7C467.2 221.8 466 222.4 464.7 222.4C463.5 222.4 462.2 221.8 462.2 220.7V208.8C462.2 206.3 460 203.8 457.4 203.8C455.1 203.8 452.8 205.7 452.8 208.8V220.6H452.6Z" fill="#003760" />
            <path d="M474.6 202.3C474.5 202.1 474.4 201.9 474.4 201.6C474.4 200.3 476.4 199.2 477.7 199.2C478.4 199.2 479 199.5 479.2 200.1L485.2 215.7L490.4 200.1C490.7 199.5 491.3 199.2 491.8 199.2C493 199.2 495.2 200.3 495.2 201.7C495.2 201.9 495.2 202.1 495.1 202.3L488 221.1C485.8 226.8 483.5 233.1 476.7 233.1C475.4 233.1 474.3 232.8 474.3 230.9C474.3 229.7 474.7 228.7 475.7 228.7C478.1 228.7 481.9 226.3 482.6 220.8L474.6 202.3Z" fill="#003760" />
            <path d="M517.3 208.6C517.3 205.6 515.2 203.7 512.8 203.7C510.5 203.7 508.3 205.4 508.3 208.7V220.6C508.3 221.4 507.1 222.3 505.8 222.3C504.3 222.3 503.3 221.4 503.3 220.6V201.2C503.3 200.2 504.3 199.6 505.8 199.6C507 199.6 508.1 200.3 508.1 201.2V202.9C509.2 201 511.3 199.3 514.5 199.3C517.4 199.3 519.7 201.3 520.8 204.1C522.5 200.8 525.3 199.3 528 199.3C532.3 199.3 536.4 202.7 536.4 208.8V220.7C536.4 221.5 535.2 222.4 533.9 222.4C532.6 222.4 531.4 221.5 531.4 220.7V208.8C531.4 205.5 529.1 203.8 526.9 203.8C524.6 203.8 522.4 205.6 522.4 208.7V220.7C522.4 221.9 521.2 222.3 519.9 222.3C518.7 222.3 517.4 221.8 517.4 220.7V208.6H517.3Z" fill="#003760" />
            <path d="M558.7 220.2C558.7 220.1 558.7 219.9 558.7 219.8L568.3 188.7C568.7 187.5 570.1 187 571.5 187C572.9 187 574.4 187.6 574.8 188.7L584.3 219.8C584.3 219.9 584.4 220.1 584.4 220.2C584.4 221.4 582.6 222.3 581.2 222.3C580.4 222.3 579.7 222 579.5 221.2L577.5 214.4H565.6L563.6 221.2C563.4 222 562.7 222.3 561.8 222.3C560.4 222.3 558.7 221.4 558.7 220.2ZM576.3 210.3L571.5 193.8L566.7 210.3H576.3Z" fill="#003760" />
            <path d="M611.4 187.6V220.6C611.4 221.4 610.3 222.3 608.9 222.3C607.7 222.3 606.6 221.4 606.6 220.6V218.9C605.6 220.9 603 222.6 600 222.6C595.5 222.6 591.6 218 591.6 213V208.6C591.6 203.4 595.5 199.1 600.2 199.1C603.2 199.1 605.4 200.9 606.4 202.7V187.4C606.4 186.4 607.5 185.8 608.9 185.8C610.3 185.8 611.4 186.7 611.4 187.6ZM596.6 213.1C596.6 215.7 598.8 218.2 601.4 218.2C604.2 218.2 606.3 215.4 606.3 214.2V208.7C606.3 205.6 603.8 203.7 601.4 203.7C598.7 203.7 596.5 206.2 596.5 208.7V213.1H596.6Z" fill="#003760" />
            <path d="M619.6 202.3C619.5 202.1 619.4 201.7 619.4 201.5C619.4 200.3 621.4 199.3 622.7 199.3C623.4 199.3 624 199.5 624.2 200.2L629.9 215.4L635.6 200.3C635.8 199.6 636.3 199.3 637.1 199.3C638.2 199.3 640.4 200.3 640.4 201.5C640.4 201.8 640.3 202.1 640.2 202.3L632.1 221.7C631.8 222.4 630.8 222.7 629.8 222.7C628.7 222.7 627.8 222.4 627.5 221.7L619.6 202.3Z" fill="#003760" />
            <path d="M666.9 208.1C666.9 212.1 664.7 212.2 662.5 212.2H652.4V213.3C652.4 216.5 654.9 218.5 658.6 218.5C662 218.5 663.2 216.7 664.5 216.7C665.7 216.7 666.5 218.1 666.5 219C666.5 220.4 663.4 222.6 658.5 222.6C652 222.6 647.4 218.8 647.4 213V208.4C647.4 203.4 651.9 199.1 657.4 199.1C662.5 199.2 666.9 202.9 666.9 208.1ZM660.3 209.1C661.7 209.1 662.1 208.8 662.1 207.7C662.1 205.2 660 203.1 657.3 203.1C654.7 203.1 652.4 205 652.4 207.6V209.1H660.3Z" fill="#003760" />
            <path d="M680.8 220.6C680.8 221.4 679.6 222.3 678.3 222.3C676.8 222.3 675.8 221.4 675.8 220.6V201.2C675.8 200.2 676.8 199.6 678.3 199.6C679.5 199.6 680.6 200.3 680.6 201.2V202.9C681.6 201 683.8 199.3 687 199.3C691.5 199.3 695.4 203.6 695.4 208.8V220.7C695.4 221.8 694.2 222.4 692.9 222.4C691.7 222.4 690.4 221.8 690.4 220.7V208.8C690.4 206.3 688.2 203.8 685.6 203.8C683.3 203.8 681 205.7 681 208.8V220.6H680.8Z" fill="#003760" />
            <path d="M710.2 214.8C710.2 217.1 711.1 218 713.6 218H715.3C716.7 218 717.5 219.1 717.5 220.2C717.5 221.3 716.8 222.4 715.3 222.4H713.6C708.1 222.4 705.2 220.1 705.2 214.9V190.8C705.2 189.8 706.5 189.2 707.7 189.2C708.9 189.2 710.2 189.9 710.2 190.8V199.6H716.1C717 199.6 717.5 200.5 717.5 201.5C717.5 202.5 717 203.4 716.1 203.4H710.2V214.8V214.8Z" fill="#003760" />
            <path d="M725.6 213.2V201.2C725.6 200.3 726.8 199.5 728.1 199.5C729.4 199.5 730.6 200.3 730.6 201.2V213.2C730.6 215.7 732.8 218.2 735.5 218.2C738.2 218.2 740.4 215.8 740.4 213.2V201.2C740.4 200.3 741.6 199.6 742.9 199.6C744.3 199.6 745.3 200.4 745.3 201.2V213.2C745.3 218.2 740.8 222.7 735.4 222.7C730 222.7 725.6 218.2 725.6 213.2Z" fill="#003760" />
            <path d="M766.1 203.7C763.2 203.7 760.3 206.4 760.3 210.8V220.6C760.3 221.4 759.1 222.3 757.7 222.3C756.3 222.3 755.3 221.4 755.3 220.6V201.2C755.3 200.2 756.3 199.6 757.7 199.6C758.9 199.6 760 200.3 760 201.2V203.3C761 201.3 763.3 199.3 766.1 199.3H767.6C768.6 199.3 769.5 200.3 769.5 201.5C769.5 202.7 768.6 203.8 767.6 203.8H766.1V203.7Z" fill="#003760" />
            <path d="M795.5 208.1C795.5 212.1 793.3 212.2 791.1 212.2H781V213.3C781 216.5 783.5 218.5 787.2 218.5C790.6 218.5 791.8 216.7 793.1 216.7C794.3 216.7 795.1 218.1 795.1 219C795.1 220.4 792 222.6 787.1 222.6C780.6 222.6 776 218.8 776 213V208.4C776 203.4 780.5 199.1 786 199.1C791 199.2 795.5 202.9 795.5 208.1ZM788.9 209.1C790.3 209.1 790.7 208.8 790.7 207.7C790.7 205.2 788.6 203.1 785.9 203.1C783.3 203.1 781 205 781 207.6V209.1H788.9Z" fill="#003760" />
          </g>
          <g id="maintitle">
            <path d="M203 64.5C203 62.5 204.5 60.8 206.8 60.8H227.7C241.6 60.8 253 66.2001 253 83.2001C253 96.3001 246.3 102.8 237.5 105L252.9 133.9C253.2 134.4 253.3 135.1 253.3 135.5C253.3 138.5 249.6 141.4 246.3 141.4C244.3 141.4 242.8 140.1 241.7 138L225.7 106.6H214.5V137.3C214.5 139.8 211.6 141.1 208.7 141.1C205.8 141.1 203 139.8 203 137.3V64.5ZM214.5 71.1V97.3H227.7C235.9 97.3 241.3 93.8001 241.3 84.2001C241.3 74.6 236 71.1 227.8 71.1H214.5Z" fill="#990062" />
            <path d="M284.5 69.9001C284.5 73.1001 281.5 75.9001 277.8 75.9001C274.1 75.9001 271.1 73.0001 271.1 69.9001C271.1 66.6001 274.1 63.9001 277.8 63.9001C281.5 63.8001 284.5 66.6001 284.5 69.9001ZM283.6 92.8001V137.4C283.6 139.3 281.1 141.2 277.8 141.2C274.5 141.2 272.1 139.2 272.1 137.4V92.8001C272.1 90.6001 274.5 89.1001 277.8 89.1001C281.1 89.0001 283.6 90.6001 283.6 92.8001Z" fill="#990062" />
            <path d="M329.4 127.2C329.4 115.6 302.9 122.5 302.9 104.2C302.9 96.6 307.9 88.4 321.7 88.4C329.9 88.4 338.2 91.4 338.2 94.9C338.2 96.6 336.5 100.5 333.8 100.5C331.5 100.5 328.1 97.3 321.9 97.3C315.4 97.3 313 100.9 313 103.7C313 113.4 339.7 107 339.7 126.8C339.7 136.2 332.8 142.1 320.3 142.1C309.4 142.1 301.4 137.5 301.4 133.3C301.4 131.3 303 128.1 305.4 128.1C308.3 128.1 310.6 133.2 320 133.2C327.3 133.1 329.4 130.2 329.4 127.2Z" fill="#990062" />
            <path d="M370.2 69.9001C370.2 73.1001 367.2 75.9001 363.5 75.9001C359.8 75.9001 356.8 73.0001 356.8 69.9001C356.8 66.6001 359.8 63.9001 363.5 63.9001C367.2 63.8001 370.2 66.6001 370.2 69.9001ZM369.3 92.8001V137.4C369.3 139.3 366.8 141.2 363.5 141.2C360.2 141.2 357.8 139.2 357.8 137.4V92.8001C357.8 90.6001 360.2 89.1001 363.5 89.1001C366.8 89.0001 369.3 90.6001 369.3 92.8001Z" fill="#990062" />
            <path d="M401.8 137.3C401.8 139.2 399 141.1 396 141.1C392.6 141.1 390.3 139.1 390.3 137.3V92.8C390.3 90.6 392.6 89.1 396 89.1C398.9 89.1 401.2 90.6 401.2 92.8V96.8C403.4 92.5 408.6 88.4 415.9 88.4C426.2 88.4 435 98.2 435 110.1V137.4C435 139.9 432.1 141.2 429.2 141.2C426.6 141.2 423.5 139.9 423.5 137.4V110C423.5 104.3 418.6 98.6 412.4 98.6C407.1 98.6 401.8 102.9 401.8 110V137.3V137.3Z" fill="#990062" />
            <path d="M466.7 126.4C465.6 127.2 464.6 128.1 464.6 129.5C464.6 137.6 498 129 498 149.3C498 160.9 487.3 165.9 474.9 165.9C462 165.9 451.5 160.3 451.5 150.7C451.5 144.9 455.4 141.1 460 138.9C456.9 137.6 454.8 135.6 454.8 131.6C454.8 127.5 457 125.3 460.3 123.2C456.2 120 453.8 115.1 453.8 109.3V106.2C453.8 95.6 461.9 88.2 474.3 88.2C479.4 88.2 483.8 89.5 487.1 91.9C489.4 86.6 491.9 84.5 494.2 84.5C497.2 84.5 498.9 86.8 498.9 88.5C498.9 89.7 498 90.7 497 91.3C495.5 92 493.3 93.2 491.5 95.7C493.8 98.6 495 102.3 495 106.3V109.4C495 119.9 486.4 127.7 474.2 127.7C471.5 127.7 468.9 127.3 466.7 126.4ZM469.7 141.3C465.6 142.8 462.3 145.5 462.3 149.5C462.3 154.9 467.5 158.2 474.7 158.2C481.7 158.2 486.7 154.9 486.7 149.6C486.8 142.4 476.7 141.7 469.7 141.3ZM483.8 106.4C483.8 100.5 480.4 95.7 474.3 95.7C468.4 95.7 464.9 100.4 464.9 106.4V109.4C464.9 115.7 468.3 120.4 474.4 120.4C480.2 120.4 483.8 115.8 483.8 109.5V106.4Z" fill="#990062" />
            <path d="M591 60.8C593.6 60.8 594.8 63.4 594.8 66C594.8 68.9 593.5 71.5 591 71.5H574V137.4C574 139.9 571 141.2 568.2 141.2C565.2 141.2 562.4 139.9 562.4 137.4V71.4H545.4C543 71.4 541.5 68.9 541.5 66C541.5 63.5 542.7 60.7 545.4 60.7H591V60.8Z" fill="#990062" />
            <path d="M623.6 69.9001C623.6 73.1001 620.6 75.9001 616.9 75.9001C613.2 75.9001 610.2 73.0001 610.2 69.9001C610.2 66.6001 613.2 63.9001 616.9 63.9001C620.7 63.8001 623.6 66.6001 623.6 69.9001ZM622.8 92.8001V137.4C622.8 139.3 620.3 141.2 617 141.2C613.7 141.2 611.3 139.2 611.3 137.4V92.8001C611.3 90.6001 613.7 89.1001 617 89.1001C620.2 89.0001 622.8 90.6001 622.8 92.8001Z" fill="#990062" />
            <path d="M687.2 61.7V137.3C687.2 139.2 684.8 141.1 681.5 141.1C678.6 141.1 676.2 139.1 676.2 137.3V133.4C674 138 668 142 661 142C650.7 142 641.6 131.5 641.6 120.1V110C641.6 98.1 650.5 88.3 661.3 88.3C668.2 88.3 673.2 92.4 675.6 96.7V61.7C675.6 59.5 678.1 58 681.4 58C684.8 58 687.2 59.5 687.2 61.7ZM653.2 120.2C653.2 126.1 658.3 131.9 664.3 131.9C670.7 131.9 675.6 125.4 675.6 122.8V110C675.6 102.8 669.9 98.6 664.4 98.6C658.2 98.6 653.2 104.3 653.2 110V120.2V120.2Z" fill="#990062" />
            <path d="M751 108.7C751 117.8 745.9 118.2 741 118.2H717.8V120.7C717.8 128.1 723.6 132.7 732.1 132.7C739.9 132.7 742.5 128.5 745.5 128.5C748.3 128.5 750 131.7 750 133.7C750 137 742.9 142.1 731.7 142.1C716.7 142.1 706.2 133.3 706.2 120.2V109.7C706.2 98.2 716.4 88.4 729.1 88.4C740.9 88.4 751 96.7 751 108.7ZM736.1 111C739.3 111 740.2 110.3 740.2 107.7C740.2 101.9 735.4 97.1 729.1 97.1C723 97.1 717.9 101.4 717.9 107.4V111H736.1Z" fill="#990062" />
            <path d="M794 127.2C794 115.6 767.5 122.5 767.5 104.2C767.5 96.6 772.5 88.4 786.3 88.4C794.5 88.4 802.8 91.4 802.8 94.9C802.8 96.6 801.1 100.5 798.4 100.5C796.1 100.5 792.7 97.3 786.5 97.3C780 97.3 777.6 100.9 777.6 103.7C777.6 113.4 804.3 107 804.3 126.8C804.3 136.2 797.4 142.1 784.9 142.1C774 142.1 766 137.5 766 133.3C766 131.3 767.6 128.1 770 128.1C772.9 128.1 775.2 133.2 784.6 133.2C791.9 133.1 794 130.2 794 127.2Z" fill="#990062" />
            <path d="M834.9 101.3C834.9 104.8 831.9 107.9 828.4 107.9C824.5 107.9 821.8 104.8 821.8 101.3C821.8 97.6999 824.6 94.7 828.4 94.7C832 94.7 834.9 97.6999 834.9 101.3ZM834.9 135.2C834.9 138.7 831.9 141.8 828.4 141.8C824.5 141.8 821.8 138.7 821.8 135.2C821.8 131.6 824.6 128.6 828.4 128.6C832 128.6 834.9 131.6 834.9 135.2Z" fill="#990062" />
          </g>
        </g>
        <g id="seagull">
          <g id="wing-r">
            <path d="M342.19 347.4C336.36 345.56 330.47 343.71 324.19 343.4C318.6 343.1 312.81 343.99 307.06 344.87L306.2 345C303.114 344.954 300.034 345.29 297.03 346C291.03 348 288.48 349.83 284.03 352C280.34 353.8 277.7 360.25 276.03 364C274.89 366.56 273.03 374 280.08 373.24C282.92 372.83 286.08 371.7 288.26 371.24C292.26 370.35 296.33 369.47 300.26 368.36C310.042 365.805 320.208 365.049 330.26 366.13C342.26 367.53 353.26 372.19 365.06 374.13C376.86 376.07 390.66 374.88 401.46 366.13L342.19 347.4Z" fill="#E8E9EA" />
            <path d="M302 348C302.29 348 306.19 346.7 306.47 346.66C312.22 345.77 318.01 344.89 323.61 345.19C329.92 345.54 335.8 347.39 341.61 349.19L399.53 367.48C400.16 367.03 400.79 366.56 401.4 366.07L342.19 347.4C336.36 345.56 330.47 343.71 324.19 343.4C318.6 343.1 312.81 343.99 307.06 344.87L305.3 345.14C301 346 301.94 347.43 302 348Z" fill="white" />
            <path d="M365 374.11C360.845 373.377 356.729 372.433 352.67 371.28C355.403 371.071 358.107 370.581 360.74 369.82C364.6 368.765 367.982 366.417 370.32 363.17C371.534 361.296 372.184 359.113 372.19 356.88L401.4 366.11C390.62 374.86 376.87 376.07 365 374.11Z" fill="#58595B" />
            <path d="M372.41 359L399.55 367.57C400.18 367.12 400.81 366.65 401.42 366.16L372.64 357C372.622 357.672 372.545 358.341 372.41 359Z" fill="#6D6E71" />
          </g>
          <g id="wing-l">
            <path d="M265 358C268.08 353.24 271 346 263.42 339.69L239.29 324.75C234.35 321.69 229.37 318.61 224.1 316.7C218.16 314.55 212.01 313.95 205.93 313.37L144.1 307.43C150.61 319.71 162.8 326.2 174.47 329.03C186.14 331.86 198.03 331.86 209.63 335.26C218.32 337.81 225.92 344.15 233.51 350.26C236.95 353.01 243.35 360.26 247.06 362.46C254 367 262.93 361.2 265 358Z" fill="#E8E9EA" />
            <path d="M205.37 315.2C211.46 315.78 217.6 316.38 223.54 318.53C228.81 320.43 233.8 323.53 238.74 326.58L262.86 341.52C263.4 341.12 263.96 340.74 264.52 340.37L239.29 324.75C234.35 321.69 229.37 318.61 224.1 316.7C218.16 314.55 212.01 313.95 205.93 313.37L144.1 307.43C144.46 308.11 144.84 308.77 145.24 309.43L205.37 315.2Z" fill="white" />
            <path d="M188.19 331.23C183.538 330.672 178.918 329.871 174.35 328.83C162.75 326.01 150.64 319.58 144.1 307.43L145.32 307.36L180.83 310.77C180.594 318.278 183.226 325.593 188.19 331.23Z" fill="#58595B" />
            <path d="M180.84 312.84C180.84 312.21 180.84 311.58 180.84 310.96L144.11 307.43C144.47 308.11 144.87 308.77 145.27 309.43L180.84 312.84Z" fill="#6D6E71" />
          </g>
          <g id="body">
            <path d="M273 383.19C271.753 384.206 270.386 385.065 268.93 385.75C267.507 386.395 266.003 386.845 264.46 387.09C264.545 387.48 264.553 387.883 264.483 388.276C264.412 388.669 264.265 389.044 264.05 389.381C263.834 389.717 263.555 390.007 263.227 390.235C262.899 390.463 262.53 390.625 262.14 390.71C261.75 390.795 261.347 390.803 260.954 390.733C260.561 390.662 260.186 390.515 259.85 390.3C259.513 390.084 259.223 389.805 258.995 389.477C258.767 389.149 258.605 388.78 258.52 388.39C258.419 387.896 258.439 387.384 258.58 386.9C257.725 386.726 256.888 386.472 256.08 386.14C255.009 385.667 254.001 385.062 253.08 384.34C252.787 384.608 252.443 384.815 252.069 384.951C251.696 385.086 251.299 385.146 250.902 385.128C250.505 385.11 250.116 385.014 249.756 384.845C249.396 384.676 249.073 384.439 248.805 384.145C248.537 383.852 248.33 383.508 248.194 383.134C248.059 382.761 247.999 382.364 248.017 381.967C248.035 381.57 248.131 381.181 248.3 380.821C248.469 380.461 248.707 380.138 249 379.87C248.003 378.252 247.248 376.497 246.76 374.66C239.76 380.15 232.43 386.01 225.52 391.3C225.52 391.13 231.82 407.3 248.33 411.59C258.09 414.1 268.79 413.59 277.54 408.59C274.366 400.506 272.824 391.873 273 383.19Z" fill="#D1D3D4" />
            <path d="M231.91 386.38C232.627 390.997 234.626 395.32 237.681 398.855C240.735 402.391 244.722 404.997 249.186 406.376C253.65 407.755 258.412 407.852 262.928 406.656C267.445 405.46 271.534 403.018 274.73 399.61C275.443 402.674 276.378 405.682 277.53 408.61C268.78 413.61 258.08 414.12 248.32 411.61C231.81 407.35 225.49 391.15 225.51 391.32C227.61 389.73 229.75 388.07 231.91 386.38Z" fill="#58595B" />
            <g>
              <path d="M261 389.86C260.81 391.16 260.61 392.45 260.42 393.75C260.221 393.807 260.042 393.921 259.906 394.077C259.769 394.233 259.68 394.425 259.65 394.63C259.616 394.836 259.643 395.047 259.729 395.237C259.814 395.428 259.954 395.589 260.13 395.7L259.13 402.31C258.23 403.46 256.6 405.67 256.06 406.4C256 406.48 256.06 406.59 256.13 406.57L257.04 406.31C257.312 406.242 257.599 406.278 257.845 406.412C258.091 406.545 258.278 406.765 258.37 407.03L258.74 407.86C258.747 407.87 258.757 407.879 258.768 407.885C258.78 407.89 258.792 407.893 258.805 407.893C258.818 407.893 258.83 407.89 258.842 407.885C258.853 407.879 258.863 407.87 258.87 407.86L259.47 407.17C259.635 406.944 259.879 406.789 260.153 406.735C260.428 406.681 260.712 406.733 260.95 406.88L261.74 407.39C261.81 407.44 261.89 407.34 261.86 407.25C261.55 406.37 260.59 403.62 260.07 402.3C260.4 400.13 260.72 397.96 261.07 395.79C261.271 395.734 261.451 395.621 261.589 395.464C261.726 395.308 261.816 395.115 261.847 394.909C261.877 394.703 261.848 394.492 261.761 394.303C261.675 394.113 261.536 393.952 261.36 393.84C261.55 392.56 261.75 391.29 261.94 390.02C262 389.47 261.05 389.26 261 389.86Z" fill="#F7941E" />
              <g>
                <path d="M258.78 404L258.24 406.87C258.084 406.601 257.829 406.404 257.53 406.32L258.78 404Z" fill="#F17C29" />
                <path d="M259.57 407.08L259.9 404.17L260.4 406.77C260.248 406.764 260.096 406.789 259.954 406.842C259.812 406.895 259.681 406.976 259.57 407.08Z" fill="#F17C29" />
              </g>
            </g>
            <g>
              <path d="M249.58 383.42L246.88 386.21C246.683 386.143 246.471 386.134 246.269 386.184C246.067 386.233 245.884 386.339 245.74 386.49C245.594 386.639 245.494 386.826 245.453 387.031C245.413 387.235 245.432 387.447 245.51 387.64C243.95 389.253 242.393 390.867 240.84 392.48C239.43 392.89 236.84 393.74 235.98 394.02C235.89 394.02 235.86 394.17 235.98 394.2L236.87 394.52C237.131 394.619 237.345 394.814 237.469 395.064C237.593 395.314 237.619 395.602 237.54 395.87L237.36 396.77C237.358 396.782 237.359 396.794 237.362 396.806C237.366 396.817 237.373 396.828 237.382 396.836C237.391 396.844 237.402 396.849 237.414 396.852C237.426 396.854 237.438 396.854 237.45 396.85L238.34 396.64C238.605 396.553 238.892 396.568 239.146 396.683C239.4 396.798 239.601 397.004 239.71 397.26L240.06 398.14C240.06 398.21 240.21 398.14 240.24 398.09C240.5 397.19 241.31 394.39 241.65 393.02L246.22 388.29C246.417 388.357 246.629 388.366 246.831 388.317C247.033 388.267 247.217 388.161 247.36 388.01C247.506 387.862 247.606 387.674 247.647 387.469C247.687 387.265 247.668 387.053 247.59 386.86C248.5 385.93 249.41 384.99 250.31 384.05C250.69 383.61 250 383 249.58 383.42Z" fill="#F7941E" />
              <g>
                <path d="M239.63 393.64L237.52 395.64C237.551 395.335 237.462 395.03 237.27 394.79L239.63 393.64Z" fill="#F17C29" />
                <path d="M238.49 396.61L240.49 394.43L239.39 396.83C239.263 396.734 239.117 396.665 238.963 396.627C238.808 396.589 238.647 396.584 238.49 396.61Z" fill="#F17C29" />
              </g>
            </g>
            <path d="M249 379.91C247.252 377.065 246.248 373.825 246.08 370.49C245.77 363.96 248.33 357.57 251.89 352.07C256.573 344.575 263.392 338.654 271.47 335.07C279.56 331.68 289.03 331.29 297.08 334.77C300.24 336.14 303.25 338.18 304.96 341.17C306.67 344.16 306.79 348.23 304.58 350.87C301.4 354.67 295.4 354.02 290.95 356.19C287.25 357.99 284.83 361.66 283.16 365.41C281.49 369.16 280.38 373.18 278.36 376.75C276.244 380.665 272.948 383.814 268.94 385.75C267.517 386.395 266.013 386.845 264.47 387.09C264.555 387.48 264.563 387.883 264.493 388.276C264.422 388.669 264.275 389.044 264.06 389.381C263.844 389.717 263.565 390.007 263.237 390.235C262.91 390.463 262.54 390.625 262.15 390.71C261.76 390.795 261.357 390.803 260.964 390.733C260.571 390.662 260.196 390.515 259.86 390.3C259.523 390.084 259.233 389.805 259.005 389.477C258.777 389.149 258.615 388.78 258.53 388.39C258.429 387.896 258.45 387.384 258.59 386.9C257.735 386.726 256.898 386.472 256.09 386.14C255.019 385.667 254.012 385.062 253.09 384.34C252.797 384.608 252.453 384.815 252.08 384.951C251.706 385.086 251.309 385.146 250.912 385.128C250.515 385.11 250.126 385.014 249.766 384.845C249.406 384.676 249.083 384.439 248.815 384.145C248.547 383.852 248.34 383.508 248.205 383.134C248.069 382.761 248.009 382.364 248.027 381.967C248.045 381.57 248.141 381.181 248.31 380.821C248.479 380.461 248.717 380.138 249.01 379.87L249 379.91Z" fill="#F4F4F4" />
            <path d="M261.85 340.89C262.42 341.24 268.85 337.66 269.91 337.26C272.76 336.132 275.71 335.275 278.72 334.7C284.709 333.554 290.904 334.223 296.51 336.62C299.67 337.98 302.69 340.03 304.4 343.02C305.06 344.18 305.475 345.463 305.62 346.79L306.18 346.7C306.327 344.782 305.899 342.863 304.95 341.19C303.24 338.19 300.23 336.19 297.07 334.79C289.02 331.31 279.55 331.7 271.46 335.09C268 336.562 264.765 338.515 261.85 340.89Z" fill="white" />
            <path d="M294 344.23C293.82 344.909 293.845 345.626 294.072 346.29C294.299 346.955 294.718 347.537 295.276 347.963C295.835 348.389 296.507 348.64 297.207 348.685C297.908 348.729 298.606 348.564 299.214 348.212C299.821 347.859 300.31 347.334 300.619 346.704C300.928 346.073 301.043 345.365 300.949 344.669C300.856 343.973 300.558 343.32 300.094 342.794C299.629 342.267 299.019 341.89 298.34 341.71C297.43 341.469 296.462 341.599 295.649 342.072C294.835 342.544 294.242 343.32 294 344.23Z" fill="white" />
            <path d="M294.83 344.44C294.69 344.96 294.708 345.509 294.88 346.019C295.052 346.529 295.372 346.976 295.799 347.304C296.225 347.632 296.74 347.826 297.277 347.862C297.814 347.897 298.349 347.773 298.815 347.504C299.281 347.235 299.657 346.834 299.896 346.351C300.134 345.869 300.224 345.327 300.153 344.793C300.083 344.259 299.856 343.759 299.502 343.354C299.147 342.95 298.68 342.659 298.16 342.52C297.464 342.336 296.724 342.435 296.1 342.795C295.476 343.154 295.02 343.746 294.83 344.44Z" fill="#414042" />
            <path d="M299 343.52C298.954 343.694 298.979 343.878 299.069 344.034C299.159 344.189 299.307 344.303 299.48 344.35C299.61 344.385 299.748 344.381 299.875 344.338C300.003 344.295 300.115 344.215 300.197 344.108C300.279 344.001 300.327 343.872 300.336 343.738C300.345 343.604 300.314 343.47 300.246 343.353C300.179 343.237 300.078 343.143 299.957 343.083C299.837 343.024 299.701 343.002 299.567 343.02C299.434 343.037 299.309 343.095 299.208 343.184C299.107 343.273 299.034 343.39 299 343.52Z" fill="white" />
            <path d="M299.23 349.19C300.23 350.3 301.68 351.51 302.69 352.62C305.99 353.52 308.69 354.12 311.95 355.02C313.133 355.282 314.273 355.706 315.34 356.28C315.837 356.515 316.275 356.86 316.621 357.287C316.967 357.715 317.213 358.215 317.34 358.75C317.63 357.178 317.518 355.558 317.015 354.04C316.511 352.523 315.632 351.157 314.46 350.07C312.117 347.98 309.126 346.759 305.99 346.61C305.99 346.61 301.53 348.4 299.23 349.19Z" fill="#F7941E" />
            <path d="M299.23 349.19C301.741 350.097 304.319 350.809 306.94 351.32C309.771 351.793 312.456 352.909 314.79 354.58C315.916 355.489 316.816 356.646 317.42 357.96C317.393 358.263 317.346 358.563 317.28 358.86C317.153 358.325 316.907 357.825 316.561 357.397C316.215 356.97 315.777 356.625 315.28 356.39C314.214 355.811 313.073 355.384 311.89 355.12L302.66 352.62C301.72 351.56 300.15 350.24 299.23 349.19Z" fill="#F77C0C" />
            <path d="M306.78 347.83C307.038 347.939 307.317 347.991 307.597 347.98C307.877 347.97 308.151 347.898 308.4 347.77C308.013 347.501 307.567 347.329 307.1 347.27C306.973 347.23 306.837 347.23 306.71 347.27C306.648 347.294 306.598 347.34 306.566 347.399C306.535 347.457 306.526 347.525 306.54 347.59C306.557 347.647 306.588 347.698 306.63 347.74C306.672 347.782 306.724 347.813 306.78 347.83Z" fill="#3C2415" />
            <path d="M306 346.61C309.137 346.759 312.127 347.98 314.47 350.07C315.835 351.358 316.804 353.01 317.26 354.83C316.14 352.83 314.8 350.6 312.92 349.33C310.724 347.915 308.228 347.035 305.63 346.76L306 346.61Z" fill="#FBB040" />
          </g>
        </g>
        <g id="sea">
          <path id="water" d="M968 612.5C937 610.95 917.5 608.5 878.5 600.5C855.78 595.84 826.83 596.62 801.5 599.5C757.5 604.5 713.5 619.75 674.5 620.5C622.5 621.5 571.22 603.28 511.5 597.5C480.5 594.5 443.6 600.95 416.5 604.5C355.5 612.5 293.5 610.5 248.5 604.5C195.2 597.39 119.5 597.5 74.5 606.5C58.5 609.69 19.7 612 0 612V746H1024V612.5C1005.35 613.5 986.653 613.5 968 612.5Z" fill="#0072B2" />
          <path id="swellA" d="M232.5 611.5C252.5 610.5 299.5 613.5 337.5 613.5C375.5 613.5 437.5 604.5 450.5 605.5C427.5 607.5 411.7 617.09 413 621C415.5 628.5 436 627 467 621C453 624 412 638 387 644C363.34 649.68 300.5 654.5 271.5 645.5C242.5 636.5 191.5 619.5 169.5 618.5C182.5 617.5 251.9 631.12 258.5 626.5C268.5 619.5 246.5 610.5 232.5 611.5Z" fill="#4F9EC8" />
          <path id="shineA" d="M450.5 605.5C436 603 374.5 612.5 336.5 612.5C299.5 612.5 254.87 610.65 234.07 611.43C267.83 613.5 318.69 616.5 340.5 616.5C363.07 616.5 405.46 611 450.5 605.5Z" fill="white" />
          <path id="swellB" d="M516.5 627.5C552.5 631.5 593.5 640.16 618.5 645.5C643.5 650.84 707.5 650.12 744.5 642.5C781.5 634.88 806 626.76 810 626C787 629 769.84 636.43 763 626C760 621.43 778 614 800.5 608.5C773.5 604.5 713.5 621.5 686.5 622.5C651.5 623.8 602.5 616.5 574.5 616.11C601.5 621.44 609.35 626.59 606.5 630.11C601.5 636.17 529.5 626.74 516.5 627.5Z" fill="#4F9EC8" />
          <path id="shineB" d="M686.5 622.5C640.67 624 602.33 615.33 574.5 616.11C608 620 641.23 626.55 677.67 627.67C711.67 628.67 759 612 800.5 608.5C784.75 601.75 717 621.5 686.5 622.5Z" fill="white" />
          <path id="fg-water" d="M908 621C875 619 827 636 799 644C756.42 656.17 720.27 647.54 683 636C641 623 600 622.3 540 621C494 620 413 639 376 640C331 641.22 258.39 624.06 213 623C170 622 115 660 74 658C57.74 657.21 21 654 0 644V746H1024V646C999 637 947.74 623.41 908 621Z" fill="#01599B" />
        </g>
      </svg>
    </div>
    <RisingTidesDirections />
    </>
  );
};
