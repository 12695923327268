import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_ACTIVITY = createRequestTypes(API_ACTION_TYPES.GET_ACTIVITY);
const SET_ACTIVITY_OBSERVATION = createRequestTypes(API_ACTION_TYPES.SET_ACTIVITY_OBSERVATION);

export const activityDataActions = {
  GET_ACTIVITY,
  SET_ACTIVITY_OBSERVATION,
};

export const activityDataActionCreators = {
  getActivity: { ...createActions(GET_ACTIVITY) },
  setActivityObservation: { ...createActions(SET_ACTIVITY_OBSERVATION) },
};

// Static

export const RESET_COMPELTED_ACTIVITIES = '[Activity Observation] RESET_COMPELTED_ACTIVITIES';

export const resetCompletedActivities = () => {
  return {
    type: RESET_COMPELTED_ACTIVITIES,
  };
};

export const activityDataStaticActions = {
  resetCompletedActivities,
};
