import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { activityDataActionCreators, activityDataActions } from 'app/store/actions/activity-data.actions';
import promiseHandler from 'app/util/promise-handler';

function* getActivity(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getActivity(action.payload));

    if (error) throw error;

    yield put(activityDataActionCreators.getActivity.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(
      activityDataActionCreators.getActivity.failure({
        error,
      })
    );
  }
}

function* setActivityObservation(action) {
  try {
    const [error] = yield promiseHandler(ApiClient.sendActivityObservation(action.payload));

    if (error) throw error;

    yield put(activityDataActionCreators.setActivityObservation.success(action.payload.activityId));
  } catch (error) {
    yield put(
      activityDataActionCreators.getActivity.failure({
        error,
      })
    );
  }
}

export default function* activityDataSaga() {
  yield all([
    takeEvery(activityDataActions.GET_ACTIVITY.REQUEST, getActivity),
    takeEvery(activityDataActions.SET_ACTIVITY_OBSERVATION.REQUEST, setActivityObservation),
  ]);
}
