import { flatMap, flattenDeep, map, toLower } from 'lodash';
import { useSelector } from 'react-redux';

export const usePII = () => {
  // User PII
  const { kcInfo } = useSelector(state => state.userData);
  const userPII = [kcInfo.name, kcInfo.preferred_username, kcInfo.given_name, kcInfo.family_name, kcInfo.email];

  // Classes PII
  const { activeClasses } = useSelector(state => state.classesData);
  const classPII = (activeClasses) ? map(Object.values(activeClasses), 'name') : [];

  // Students PII
  const { students } = useSelector(state => state.classesData);
  const studentsPII = (students) ? flatMap(Object.values(students), (student) => {
    return [student.email, student.username, student.firstName, student.lastName, student.nickname, student.nameUI];
  }) : [];

  const PII = map(flattenDeep([userPII, classPII, studentsPII]), toLower);

  // Larger comes first so we remove exact matches first
  PII.sort((a, b) => {
    return a.length >= b.length ? -1 : 1;
  });
  window.informationToScrub = PII;
};
