import { usePreview } from 'react-dnd-preview';

export const DragPreview = ({ boxImage, word }) => {
  const { display, style } = usePreview();
  if (!display) {
    return null;
  }


  return (<div className="box" style={style}>
    <img src={boxImage} alt=""/>
    <span className="word">{word}</span>
  </div>); 
};