import React from 'react';

import { useSelector } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { getMenuOptionsFromActiveClasses } from 'app/util/classes';

export const ClassesDropdown = (props) => {
  const { activeClasses, currentClassBK } = useSelector(state => state.classesData);
  const currentClass = activeClasses[currentClassBK];

  const { handleClassSelection } = props;

  const menuOptions = getMenuOptionsFromActiveClasses(activeClasses);

  return (
    currentClass && currentClass.name ?
      <DropdownWrapper
        PIIField='classes'
        selectHandler={handleClassSelection}
        dropdownName={currentClass.name}
        menuOptions={menuOptions}
      />
      :
      <></>
  );
};
