import { ASSET_BUSINESS_KEYS } from 'app/constants/entitlement.constant';

export const findUserLicenses = function(assets) {
  const userLicenseAssets = {};
  userLicenseAssets['paidAsset'] = assets.find((assetKeys) => {return assetKeys.businessKey == ASSET_BUSINESS_KEYS.paidAssetKey;});
  userLicenseAssets['trialAsset'] = assets.find((assetKeys) => {return assetKeys.businessKey == ASSET_BUSINESS_KEYS.trialAssetKey;});
  userLicenseAssets['nationalAsset'] = assets.find((assetKeys) => {return assetKeys.businessKey == ASSET_BUSINESS_KEYS.nationalAssetKey;});
  userLicenseAssets['readingAsset'] = assets.find((assetKeys) => {return assetKeys.businessKey == ASSET_BUSINESS_KEYS.readingAssetKey;});
  userLicenseAssets['vocabReadingAsset'] = assets.find((assetKeys) => {return assetKeys.businessKey == ASSET_BUSINESS_KEYS.vocabReadingAssetKey;});
  // Word list
  userLicenseAssets['wordListBusinessKeys'] = [];
  assets.forEach((asset) => {
    if (asset && asset.metadata && asset.metadata.vocabWordListBusinessKey) {
      userLicenseAssets['wordListBusinessKeys'].push(asset.metadata.vocabWordListBusinessKey);
    }
  });
  return userLicenseAssets;
};

export const getUserLicenseType = function(userLicenseAssets) {

  const { paidAsset, trialAsset, nationalAsset, readingAsset, vocabReadingAsset, wordListBusinessKeys } = userLicenseAssets;

  const userLicenseType = {};

  userLicenseType['cda'] = !!nationalAsset;
  userLicenseType['paid'] = !!paidAsset || !!readingAsset || !!nationalAsset;
  userLicenseType['trial'] = !!trialAsset;
  userLicenseType['reading'] = !!readingAsset && location.href.includes('externalSource=reading');
  userLicenseType['vocabReading'] = !!vocabReadingAsset;
  userLicenseType['ckla'] = !!wordListBusinessKeys.length && !userLicenseType.cda;

  userLicenseType['wordListBusinessKeys'] = wordListBusinessKeys;

  return userLicenseType;
};