import React, { useRef } from 'react';

import { arrayOf, func, object } from 'prop-types';

import { KEY_NAMES } from 'app/constants/keys.constant';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';
import triangle_svg from 'app/shared/images/teacher/arrow_down.svg';
import 'app/components/dropdowns/split-dropdown-wrapper/split-wrapper-dropdown.scss';

export const SplitDropdownWrapper = (props) => {
  /*
    @param menuOptions list[dict] - dict contains the following dict structure:
    - key - String used as the key and will be passed onClick
    - value - String to show on the menu
    - icon - Icon to show in the right side
    - iconValue - Value to match the icon on the right half of the div

    @param selectHandler func - must accept the event and key 
  */
  
  const { selectHandler, dropdownHead, menuOptions } = props;

  const dropdownMenu = useRef();
  const dropdownMenuButton = useRef();

  const openDropdown = () => {
    dropdownMenu.current.classList.add('open');
    dropdownMenuButton.current.ariaExpanded = true;
  };

  const closeDropdown = () => {
    dropdownMenu.current.classList.remove('open');
    dropdownMenuButton.current.ariaExpanded = false;
  };

  const toggleDropwdown = (e) => {
    e.preventDefault();

    const classList = dropdownMenu.current.classList;

    if (classList.contains('open'))
      closeDropdown();
    else
      openDropdown();
  };

  useOnKeyDown(closeDropdown, KEY_NAMES.ESCAPE);
  useOnClickOutside(dropdownMenuButton, closeDropdown);

  return (
    <>
      <button 
        aria-label="Select Classes"
        aria-expanded="false"
        className="splitDropdownButton"
        id="selectSplitDropdown"
        ref={dropdownMenuButton}
        onClick={toggleDropwdown}
        aria-haspopup="true"
        aria-controls='split-wrapper-dropdown-menu'
      >
        <div className='leftSplit'>
          <span className='selectName'>
            {dropdownHead.left.value}
          </span>
        </div>
        <div className='rightSplit'>
          <div className='selectedStream'>
            {
              dropdownHead.right.icon ?
                <img className='selectedIcon' src={dropdownHead.right.icon} alt="Stream " />
                :
                <></>
            }
            <span className='selectedText'>{dropdownHead.right.uiName}</span>
          </div>
          <span className="carret">
            <img src={triangle_svg} alt="carrot" />
          </span>

          <div
            id="split-wrapper-dropdown-menu"
            className="split-dropdown-menu"
            ref={dropdownMenu}
            role="menu"
          >
            {
              menuOptions.map(menuOption => {
                return (
                  <div
                    key={menuOption.key}
                    onClick={(e) => selectHandler(e, menuOption.key)}
                    onKeyPress={(e) => selectHandler(e, menuOption.key)}
                    className="spanMenuOption"
                    tabIndex="0"
                    value={menuOption.key}
                    role="button"
                    aria-controls='confirm-modal'
                  >
                    <span>
                      <img className='menuOptionIcon' src={menuOption.icon} alt="Stream - "/>
                      <span className='menuOptionText' >{menuOption.uiName}</span>
                    </span>
                  </div>
                );
              })
            }
          </div>
        </div>
      </button>
    </>
  );
};

SplitDropdownWrapper.propTypes = {
  selectHandler: func,
  dropdownHead: object,
  menuOptions: arrayOf(object),
};
