import { activityDataActions, RESET_COMPELTED_ACTIVITIES } from 'app/store/actions/activity-data.actions';

export const initialState = {
  activity: {},
  completedActivities: []
};

function activityDataReducer(state = initialState, action) {
  switch (action.type) {
  case activityDataActions.GET_ACTIVITY.SUCCESS:
    return {
      ...state,
      activity: action.payload
    };

  case activityDataActions.SET_ACTIVITY_OBSERVATION.SUCCESS:
    return {
      ...state,
      completedActivities: [...state.completedActivities, action.payload]
    };
  case RESET_COMPELTED_ACTIVITIES:
    return {
      ...state,
      completedActivities: [],
    };
  default:
    return state;
  }
}

export default activityDataReducer;
