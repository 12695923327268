import React, { useEffect, useState } from 'react';


import { gsap } from 'gsap';

import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { Ooze } from 'app/components/multiple-choice/zombie/animated/ooze.component';
import { Title } from 'app/components/multiple-choice/zombie/animated/title.component';
import { ZombieDripping, zomDrip } from 'app/components/multiple-choice/zombie/animated/zombie-dripping.component';
import { ZombieHungry, zomDrool } from 'app/components/multiple-choice/zombie/animated/zombie-hungry.component';
import bookBg from 'app/components/multiple-choice/zombie/images/book-bg.svg';
import bookFg from 'app/components/multiple-choice/zombie/images/book-fg.svg';
import thumbsDown from 'app/components/multiple-choice/zombie/images/thumbs-down.svg';
import thumbsUp from 'app/components/multiple-choice/zombie/images/thumbs-up.svg';
import houseBg from 'app/components/multiple-choice/zombie/images/zombie_background.svg';
import {
  ARTICLE,
  QUESTION,
  CHOICES,
  FEEDBACK,
  FEEDBACK_CORRECT,
  FEEDBACK_INCORRECT,
} from 'app/constants/multiple-choice.constants';

import 'app/components/multiple-choice/zombie/zombie.scss';

export const Zombie = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const activityClassName = 'zombie';

  useEffect(() => {
    setAnimationFinished(false);
  }, [activityId]);


  const articleScreen = () =>
    <>
      <div id="house-bg" className="zombie-house-bg"><img src={houseBg} aria-hidden="true" /></div>
      <div className="title-screen animated">
        <div className="zombie-title-book"><Title/></div>
      </div>
      <div id="book-bg" className="zombie-book-bg"><img src={bookBg} aria-hidden="true" /></div>
      <div id="book-fg" className="zombie-book-fg"><img src={bookFg} aria-hidden="true" /></div>
      <div id="zombie-dripping"><ZombieDripping/></div>
    </>;

  const questionScreen = () =>
    <>
      <div className="zombie-book-fg"><img src={bookFg} alt="" aria-hidden="true" /></div>
      <div id="zombie-hungry"><ZombieHungry/></div>
    </>;

  const choicesScreen = () =>
    <>
      <div className="zombie-book-fg"><img src={bookFg} alt="" aria-hidden="true" /></div>
      <div id="doo-dads">
        <div id="doodad-dripping"><ZombieDripping/></div>
        <div id="doodad-hungry"><ZombieHungry/></div>
      </div>
    </>;

  const feedbackScreen = () =>
    <>
      <div className="zombie-book-fg"><img src={bookFg} alt="" aria-hidden="true" /></div>
      <div id="thumbs-up" className="thumbs-up"><img src={thumbsUp} alt="" aria-hidden="true" /></div>
      <div id="thumbs-down" className="thumbs-down"><img src={thumbsDown} alt="" aria-hidden="true" /></div>
      <div id="ooze"><Ooze/></div>
    </>;

  const animations = {
    [ARTICLE]: function () {
      const tl = gsap.timeline()
        .to('.container', { duration: 0.5, opacity: 0 })
        .set('#book-bg', { y: 700, rotate: 10, transformOrigin: '0% 100%' })
        .set('#book-fg', { y: 700, rotate: 10, transformOrigin: '0% 105%' })
        .to('.container', { duration: 0.5, opacity: 1, ease: 'ease-out' })
        .to('#book-bg, #book-fg', { duration: 1, y: 0, rotate: 0, delay: 3, ease: 'sine.out' })
        .fromTo('#zombie-dripping', { opacity: 0, scale: 0.25, transformOrigin: '50% 40%' }, { duration: 2, opacity: 1, scale: 1, ease: 'elastic.out', onComplete: ()=> { zomDrip.play(0); } }, 'same-time')
        .fromTo('.activity-card', { opacity: 0, scale: 0.25 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'sine.out' }, 'same-time')
        .call(() => tl.kill());
      tl.play();
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('#zombie-dripping', { duration: 0.5, opacity: 0, scale: 0.25, ease: 'sine.in' }, 'same-time')
        .to('.activity-card', { duration: 0.5, opacity: 0, scale: 0.25, ease: 'sine.in' }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .fromTo('#zombie-hungry', { opacity: 0, scale: 0.25, transformOrigin: '50% 50%' }, { duration: 2, opacity: 1, scale: 1, ease: 'elastic.out', onComplete: ()=> { zomDrool.play(0); } }, 'same-time')
        .fromTo('.activity-card', { opacity: 0, scale: 0.25 }, { duration: 0.5, opacity: 1, scale: 1, ease: 'sine.out' }, 'same-time')
        .call(()=> tl.kill());
      tl.play();
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('#zombie-hungry', { duration: 0.5, opacity: 0, scale: 0.25, ease: 'sine.in' }, 'same-time')
        .to('.activity-card', { duration: 0.5, opacity: 0, scale: 0.25, ease: 'sine.in' }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .fromTo('.article, #doo-dads', { opacity: 0, scale: 0 }, { duration: 0.8, opacity: 1, scale: 1, transformOrigin: '50% 50%', ease: 'sine.out', onComplete: ()=> {
          zomDrip.play(0);
          zomDrool.play(0);
        } })
        .fromTo('.answer-button', { opacity: 0, scale: 0 }, { duration: 1, opacity: 1, scale: 1, transformOrigin: '50% 50%', stagger: 0.3 })
        .add(gsap.fromTo('.answers', { opacity: 0, scale: 0 }, { duration: 0.8, opacity: 1, scale: 1, transformOrigin: '50% 50%' }), 0.5)
        .call(()=> {
          tl.kill();
        });
      tl.play();
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.article, .answers, #doo-dads', { opacity: 0, scale: 0, duration: 0.5 })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .from('.feedback', { duration: 0.1, opacity: 0, delay: 2 })
        .from('.feedback-next-container', { duration: 0.5, opacity: 0, delay: 1.5 })
        .call(()=> tl.kill());
      tl.play();
    },

    [FEEDBACK_INCORRECT]: function () {
      const tl = gsap.timeline()
        .fromTo('.thumbs-down', { transformOrigin: '100% 100%', rotate: 45, x: 200, y: 200, opacity: 0 }, { duration: 3, rotate: 0, x: 0, y:0, opacity: 1, delay: 4, ease: 'elastic.out' })
        .call(() => tl.kill);
      tl.play();
    },

    [FEEDBACK_CORRECT]: function () {
      const tl = gsap.timeline()
        .fromTo('.thumbs-up', { transformOrigin: '100% 100%', rotate: -45, y: 300, opacity: 0 }, { duration: 3, rotate: 0, y: 0, opacity: 1, delay: 4, ease: 'elastic.out' })
        .call(() => tl.kill);
      tl.play();
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
