import { memo } from 'react';

import { useDrop } from 'react-dnd';

import { HIDE_CLASS, CORRECT_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';

export const Dropzone = memo(function Dropzone({
  DropzoneInactive,
  DropzoneActive,
  accept,
  onDrop,
  correctOption = false,
  hideOption = false,
  wordId,
  children
}) { 

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    canDrop: () => !wordId,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <div ref={drop} data-testid="dustbin" className="dropzone">
      {isActive
        ? <DropzoneActive classNames={ 'drop-active' } />
        : <DropzoneInactive classNames={`${correctOption ? CORRECT_CLASS : ''} ${hideOption ? HIDE_CLASS : ''}`} />}
      {children}
    </div>
  );
});