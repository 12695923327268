import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { GradeDropdown } from 'app/components/dropdowns/words/grades.component';
import { WordsStreamDropdown } from 'app/components/dropdowns/words/streams.component';
import { SubunitDropdown } from 'app/components/dropdowns/words/subunit.component';
import { UnitDropdown } from 'app/components/dropdowns/words/unit.component';
import { WordSelectFilter } from 'app/components/text-areas/words/word-select.filter';
import { setFilterWordsMode, setIsCore, setShowLessons, setShowWords, wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isIdle, isLoading } from 'app/store/reducers/api-status.reducer';
import { getNextBatchOfWords } from 'app/util/words';

import 'app/pages/words/components/filter/words.filter.scss';

export const WordsFilter = (props) => {
  const dispatch = useDispatch();

  const [lastTimeoutID, setLastTimeoutID] = useState();

  const { licenses } = useSelector(state => state.userData); 
  const { wordList, words, lessons, batchNumber, wordsListBK } = useSelector(state => state.wordsData);
  const { streams } = useSelector(state => state.streamsReducer);
  const { gradeOption, streamOption, isCore } = useSelector(state => state.wordsData.filters);

  const { getUnitSubunitStatus, getWordsByStreamStatus } = useSelector(state => state.apiReducer);

  const { batchAmount } = props;

  useEffect(() => {
    // Set whether to show the core page
    if (licenses) {
      if (licenses.ckla) {
        dispatch(setIsCore(false));
      } else if (licenses.cda) {
        dispatch(setIsCore(true));
      } else {
        dispatch(setIsCore(false));
      }
    }
  }, [licenses, wordList]);

  useEffect(() => {
    // Execute once on startup, so we can check by status
    // Prepare non-core units and subunits
    if (isCore && isIdle(getUnitSubunitStatus))
      dispatch(wordsDataActionCreators.getUnitSubunit.request());
  }, [isCore, getUnitSubunitStatus]);

  useEffect(() => {
    // Prepare words
    if (!isCore && streams && gradeOption && streamOption && !isLoading(getWordsByStreamStatus)) {
      // We need to make sure that if we are ckla, that we have the core words list before making the request
      if (
        (licenses.ckla && wordList && wordList.length > 0 && wordsListBK) ||
        (!licenses.ckla)
      ) {

        dispatch(wordsDataActionCreators.getWordsByStream.request({ grade: gradeOption.key, streamName: streamOption.key, wordList: wordsListBK }));
      }
    }
  }, [streams, gradeOption, streamOption, isCore, wordList, licenses, wordsListBK]);

  const wordSearchCallback = (e) => {
    const wordToSearch = e.target.value;
    dispatch(setFilterWordsMode(true));

    setLastTimeoutID(setTimeout(() => {
      if (!isCore) {
        if (wordToSearch === '') {
          dispatch(setShowWords(getNextBatchOfWords(words, batchNumber + 1, batchAmount)));
          dispatch(setFilterWordsMode(false));
        } else {
          dispatch(setShowWords(_.filter(words, function(word) {
            return word.word.indexOf(wordToSearch) === 0;
          })));
        }
      } else {
        if (wordToSearch === '') {
          dispatch(setShowLessons(lessons));
          dispatch(setFilterWordsMode(false));
        } else {
          dispatch(setShowLessons(
            _.map(lessons, lesson => {
              return {
                ...lesson,
                firstEncounterWords: _.filter(lesson.firstEncounterWords, fe => {
                  return fe.word.name.indexOf(wordToSearch) == 0;
                }),
              };
            })
          ));
        }
      }
    }, 300));

    clearTimeout(lastTimeoutID);

  };


  return (
    <div className="wordsFilter">
      <WordSelectFilter typingCallback={wordSearchCallback} />
      {
        isCore ?
          <div className='corePage filterRow'>
            <UnitDropdown />
            <SubunitDropdown />
          </div>
          :
          <div className='nonCorePage filterRow'>
            <GradeDropdown />
            <WordsStreamDropdown/>
          </div>
      }
    </div>
  );
};