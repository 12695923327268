import { EXTERNAL_SOURCES } from 'app/constants/external-sources.constant';
import { SESSION_STORAGE_KEYS } from 'app/constants/session-storage.constant';
import AuthService from 'app/services/auth.service';

const setObject = (key, obj) => {
  const userBk = AuthService.getUserBk();
  const currentItem = JSON.parse(sessionStorage.getItem(key));
  sessionStorage.setItem(
    key,
    JSON.stringify(
      {
        ...currentItem,
        [userBk]: obj,
      }
    )
  );
};

const getObject = key => {
  const userBk = AuthService.getUserBk();
  return JSON.parse(sessionStorage.getItem(key))?.[userBk];
};

const getPendingActivities = () => {
  return getObject(SESSION_STORAGE_KEYS.PENDING_ACTIVITIES) ?? [];
};

const setPendingActivities = pendingActivities => {
  if (pendingActivities) {
    setObject(SESSION_STORAGE_KEYS.PENDING_ACTIVITIES, pendingActivities);
  }
};

const getExternalSource = () => {
  return getObject(SESSION_STORAGE_KEYS.EXTERNAL_SOURCE);
};

const setExternalSource = externalSource => {
  if (externalSource) {
    setObject(SESSION_STORAGE_KEYS.EXTERNAL_SOURCE, externalSource);
  }
  const current = getExternalSource();
  if (!current) {
    setObject(SESSION_STORAGE_KEYS.EXTERNAL_SOURCE, EXTERNAL_SOURCES.CKLA_HUB);
  }
};

const getQuestId = () => {
  return getObject(SESSION_STORAGE_KEYS.QUEST_ID);
};

const setQuestId = questId => {
  if (questId) {
    setObject(SESSION_STORAGE_KEYS.QUEST_ID, questId);
  }
};

const setRedirectLink = redirectLink => {
  if (redirectLink) {
    setObject(SESSION_STORAGE_KEYS.REDIRECT_LINK, redirectLink);
  }
};

const getRedirectLink = () => {
  return getObject(SESSION_STORAGE_KEYS.REDIRECT_LINK);
};

const setTeacherGrade = teacherGrade => {
  if (teacherGrade) {
    setObject(SESSION_STORAGE_KEYS.TEACHER_GRADE, teacherGrade);
  }
};

const getTeacherGrade = () => {
  return getObject(SESSION_STORAGE_KEYS.TEACHER_GRADE);
};

const setTeacherDoingActivity = teacherDemoActivitySettings => {
  return setObject(SESSION_STORAGE_KEYS.TEACHER_ACTIVITY_DEMO, teacherDemoActivitySettings);
};

const getTeacherDoingActivity = () => {
  return getObject(SESSION_STORAGE_KEYS.TEACHER_ACTIVITY_DEMO) || false;
};

const setGameStateSent = (arID, questStatus, sentStatus) => {
  const wasSent = getGameStateSent();
  return setObject(SESSION_STORAGE_KEYS.GAME_STATE_SEEN, { ...wasSent, [arID]: { ...wasSent[arID], [questStatus]: sentStatus } });
};

const getGameStateSent = () => {
  return getObject(SESSION_STORAGE_KEYS.GAME_STATE_SEEN) || {};
};

export const SessionStorageService = {
  getPendingActivities,
  setPendingActivities,
  getExternalSource,
  setExternalSource,
  getQuestId,
  setQuestId,
  setRedirectLink,
  getRedirectLink,
  getTeacherGrade,
  setTeacherGrade,
  setTeacherDoingActivity,
  getTeacherDoingActivity,
  setGameStateSent,
  getGameStateSent,
};
