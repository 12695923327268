import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const zomDrool = new gsap.timeline({ paused: true });

export const ZombieHungry = () => {

  useEffect(() => {
    zomDrool
      .to('#dripping1', { duration: 2, scaleY: 1.4, y: 20, ease: 'sine.inOut' })
      .to('#dripping1', { duration: 1, scaleY: 1, y: 0, ease: 'sine.inOut' })
      .add(gsap.to('#dripping2', { duration: 0.5, scaleY: 0.7, y: 20, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#dripping2', { duration: 2, scaleY: 1.25, ease: 'sine.inOut' }), 0.5)
      .add(gsap.to('#dripping2', { duration: 0.5, scaleY: 1, y: 0, ease: 'sine.inOut' }), 2.5)
      .add(gsap.to('#zombie-mouth', { duration: 0.75, transformOrigin: '50% 100%', scaleY: 0.8, yoyo: true, repeat: 1, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#zombie-mouth', { duration: 0.75, transformOrigin: '50% 100%', scaleY: 0.75, yoyo: true, repeat: 1, ease: 'sine.inOut' }), 1.5);

  });

  return (
    <svg id="hungry-zombie" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 374" width="236" height="374">
      <g>
        <g>
          <path d="M122.29,103v.8a24,24,0,1,0,48.09,0h0a24.05,24.05,0,1,0,48.1,0,24.67,24.67,0,0,0-8.88-19c.55-1.52,4.57-13.23-2.31-24.28a27.9,27.9,0,0,0-15-11.57,24.23,24.23,0,0,0-23.93-22.13,23.64,23.64,0,0,0-8.4,1.54,23.75,23.75,0,0,0-37.69-2.09Z" fill="#aeaeae"/>
          <path d="M140.32,17.87A23.89,23.89,0,0,1,160,28.27a23.64,23.64,0,0,1,8.4-1.54,24.23,24.23,0,0,1,23.93,22.13,27.9,27.9,0,0,1,15,11.57c6.88,11.05,2.86,22.76,2.31,24.28a24.67,24.67,0,0,1,8.88,19,24.05,24.05,0,1,1-48.1,0v0a24,24,0,1,1-48.09,0V26.18a23.79,23.79,0,0,1,18-8.31m0-6.14a30,30,0,0,0-22.67,10.44l-1.5,1.73v79.85c0,16.91,13.54,30.67,30.19,30.67a30,30,0,0,0,24-12.16,30,30,0,0,0,24.05,12.16c16.64,0,30.18-13.76,30.18-30.67a30.83,30.83,0,0,0-8.12-20.94c1.24-5.63,2.11-15.82-4-25.62a33.38,33.38,0,0,0-14.7-12.77,30.71,30.71,0,0,0-9-15.77,29.83,29.83,0,0,0-20.39-8.06,29.3,29.3,0,0,0-6.21.66,30,30,0,0,0-21.85-9.52Z" fill="#909090"/>
          <path d="M198.22,90.82a3.06,3.06,0,0,1-3.05-2.76,17.49,17.49,0,0,0-31.73-8.88,3.07,3.07,0,0,1-4.93-3.65,23.62,23.62,0,0,1,42.76,11.93,3.06,3.06,0,0,1-2.75,3.35Z" fill="#909090"/>
          <path d="M130.92,38.93a3.06,3.06,0,0,1-2.1-5.3,20.68,20.68,0,0,1,22-4.09,20.48,20.48,0,0,1,8.68-7.23,20.12,20.12,0,0,1,8.43-1.66,3.07,3.07,0,0,1-.06,6.14h0A14.21,14.21,0,0,0,162,27.93a14.82,14.82,0,0,0-7.18,7,3.08,3.08,0,0,1-4.31,1.27A14.58,14.58,0,0,0,133,38.1,3.1,3.1,0,0,1,130.92,38.93Z" fill="#909090"/>
          <path d="M131.12,61.57a3,3,0,0,1-1.67-.5,3.07,3.07,0,0,1-.89-4.25c1.2-1.85,7.85-11.12,20.31-12.12a27.4,27.4,0,0,1,20,6.9,26.62,26.62,0,0,1,12.74-7.77,27,27,0,0,1,16.23.79,3.07,3.07,0,0,1-2.14,5.75,20.74,20.74,0,0,0-12.5-.61,21.09,21.09,0,0,0-11.56,8.15,3,3,0,0,1-2.37,1.31,3.1,3.1,0,0,1-2.48-1.08c-.71-.84-7.21-8.14-17.48-7.32-9.81.79-15.1,8.48-15.67,9.36A3.07,3.07,0,0,1,131.12,61.57Z" fill="#909090"/>
          <path d="M208.17,81.79c3.33-14.41-4.37-27.31-14.59-29.9-10-2.52-18.88,5.64-19.61,6.32,1.57-.65,10.64-4.26,20.07.17C202.66,62.43,208.18,71.59,208.17,81.79Z" fill="#fbf8d3"/>
          <path d="M187.23,40.38a21.6,21.6,0,0,0-7.17-8.05c-1.44-.95-8.55-5.65-16.09-2.8a15.63,15.63,0,0,0-6.82,5.25c.84-.48,7.61-4.16,15-1.05a17.13,17.13,0,0,1,8.52,8.06Z" fill="#fbf8d3"/>
          <path d="M153.11,24a22.38,22.38,0,0,0-26.74,0l1,5.5A22.21,22.21,0,0,1,151,26.68Z" fill="#fbf8d3"/>
          <path d="M164.82,59.77a20,20,0,0,0-12.39-6.65c-10.31-1.22-17.17,6.74-17.7,7.38l2.92,5.83a23.47,23.47,0,0,1,27.17-6.56Z" fill="#fbf8d3"/>
          <path d="M151.34,38.9a15.33,15.33,0,0,0-7.44-2.83c-8.68-.8-14.55,6.74-15,7.38q.66,2.58,1.3,5.17a27.23,27.23,0,0,1,21.18-9.72Z" fill="#fbf8d3"/>
          <path d="M193,88a15.06,15.06,0,0,0-24.36-11.2,22.93,22.93,0,0,1,15.12,2.1A22.68,22.68,0,0,1,193,88Z" fill="#fbf8d3"/>
          <path d="M117.82,224.39A104.28,104.28,0,0,0,222.1,120.11H13.54A104.28,104.28,0,0,0,117.82,224.39Z" fill="#5f9898"/>
          <path d="M117.82,28.09c2.28,0,4.53.07,6.77.2a104,104,0,0,1-3.85-12.42c-1,0-1.94,0-2.92,0A104.29,104.29,0,0,0,13.54,120.11C13.54,69.29,60.23,28.09,117.82,28.09Z" fill="#8ee4e4"/>
          <path d="M221.18,93.87c-42.92-1.53-82.09-27.37-96.59-65.58-2.24-.13-4.49-.2-6.77-.2-57.59,0-104.28,41.2-104.28,92s46.69,92,104.28,92,104.28-41.2,104.28-92C222.1,111.58,223.64,101.7,221.18,93.87Z" fill="#6eb5b6"/>
          <path d="M221.18,132.37H209.84a18.41,18.41,0,0,0-18.41,18.41v34.5C207.28,171.34,218.08,152.93,221.18,132.37Z" fill="#5f9898"/>
          <path d="M136.23,107.84a18.39,18.39,0,0,0,18.4,18.4,6.12,6.12,0,0,0,6.13-6.13V107.84H136.23Z" fill="#5f9898"/>
          <path d="M99.42,107.84A18.39,18.39,0,0,1,81,126.24a6.13,6.13,0,0,1-6.14-6.13V107.84H99.42Z" fill="#5f9898"/>
          <path d="M14.47,132.37H25.81a18.4,18.4,0,0,1,18.4,18.41v34.5C28.36,171.34,17.57,152.93,14.47,132.37Z" fill="#5f9898"/>
          <path d="M44.21,169.18c0,23.73,32.93,42.94,73.61,42.94s73.61-19.21,73.61-42.94H203.7s-4,43.17-85.88,49.07c-68.89,5-79.74-49.07-79.74-49.07Z" fill="#5f9898"/>
          <path d="M99.42,89.44a12.27,12.27,0,0,0-24.54,0v18.4a12.27,12.27,0,1,0,24.54,0Z" fill="#004242"/>
          <path d="M160.76,89.44a12.27,12.27,0,1,0-24.53,0v18.4a12.27,12.27,0,1,0,24.53,0Z" fill="#004242"/>
          <path d="M176.35,83a60.66,60.66,0,0,1,2.12,25.8c-1.19,8.27-4.23,15.81-1,18.37a5,5,0,0,0,5.48.19c6.18-4.21-4.68-24.52,1.59-28.86a5.33,5.33,0,0,1,5.71,0c5.6,4-4.22,21.44,1.73,26.07a5.79,5.79,0,0,0,6.44.29c5.28-4-5.36-20.81.67-26.34,1.89-1.74,5.23-2.2,6.92-1,3.67,2.69-1.8,12.47,2.3,15.87,1.84,1.52,5.16,1.39,6.34,0,1.6-1.87-1.23-5.32-2.11-12.21a31.56,31.56,0,0,1,0-8,117.78,117.78,0,0,1-17.41-3.35A118.19,118.19,0,0,1,176.35,83Z" fill="#5f9898"/>
          <path d="M41.22,89.71C40.6,88.12,38.05,87,36,88a4,4,0,0,0-2.32,3.78,3.8,3.8,0,0,0,2.9,3.49C39.2,95.68,42.13,92,41.22,89.71Z" fill="#5f9898"/>
          <path d="M42.68,82.73a3.83,3.83,0,0,0,4.64-.87c1.73-2.08-.18-5.08-.29-5.24a5.7,5.7,0,0,0-3.48-2.32,12.67,12.67,0,0,0,3.19-4.07c.78-1.59,1.66-3.4.87-4.66-1-1.58-4.29-1.74-6.1-.29-1.2,1-1.45,2.41-1.74,4.07a14.27,14.27,0,0,0,.29,6.11A5.76,5.76,0,0,0,36,74.59c-.52.09-2.38.43-2.9,1.74C32.45,78,34,80.54,36.28,81a4.36,4.36,0,0,0,4.36-2A3.91,3.91,0,0,0,42.68,82.73Z" fill="#5f9898"/>
          <path d="M87.07,61.4a3.06,3.06,0,0,0,3.06,3.06H149q-3.16-2.94-6-6.13H90.13A3.07,3.07,0,0,0,87.07,61.4Z" fill="#5f9898"/>
          <path d="M87.07,46.84a3.06,3.06,0,0,0,3.06,3.06h46q-2.16-3-4.08-6.13H90.13A3.06,3.06,0,0,0,87.07,46.84Z" fill="#5f9898"/>
          <path d="M117.82,15.82c1,0,2,0,2.92,0a104,104,0,0,0,3.85,12.42,104.3,104.3,0,0,0,93.74,67.2l.85,0A104.29,104.29,0,1,1,117.82,15.82m0-6.13A110.41,110.41,0,0,0,39.75,198.18a110.42,110.42,0,0,0,188.49-78.07,111,111,0,0,0-3.09-26L224,89.53l-4.69-.14h-.43l-.38,0a97.91,97.91,0,0,1-88.22-63.25,97.31,97.31,0,0,1-3.61-11.68L125.6,9.86l-4.69-.12c-1.1,0-2.11-.05-3.09-.05Z" fill="#004242"/>
        </g>
        <g id="dripping2">
          <path d="M173.55,248.26h-.15a6.75,6.75,0,0,1-6.75-6.75v-85c6.83-2.44,13.6-4.75,13.6-4.75l.06,89.76A6.76,6.76,0,0,1,173.55,248.26Z" fill="#41753d"/>
          <path d="M173.55,247h-.15a6.76,6.76,0,0,1-6.75-6.76v-85c6.83-2.44,13.1-4.25,13.1-4.25l.56,89.26A6.76,6.76,0,0,1,173.55,247Z" fill="#77ff73"/>
          <path d="M177.84,240.93a3.43,3.43,0,0,1-3.1,3.52" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
        <g id="dripping1">
          <path d="M68.51,154.33,68.58,259a6.76,6.76,0,0,1-6.76,6.76h-.15A6.76,6.76,0,0,1,54.91,259V153.58Z" fill="#41753d"/>
          <path d="M68.51,153.05l.07,104.64a6.76,6.76,0,0,1-6.76,6.75h-.15a6.76,6.76,0,0,1-6.76-6.75V152.3Z" fill="#77ff73"/>
          <path d="M65.6,258s.7,4.28-3.25,4.13" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
        </g>
        <g id="zombie-mouth">
          <path d="M154.63,132.37H81a30.68,30.68,0,0,0,0,61.35h73.61a30.68,30.68,0,0,0,0-61.35Z" fill="#004242"/>
          <path d="M154.63,144.64a12.26,12.26,0,0,0,12-9.76v-.06a30.66,30.66,0,0,0-12-2.45H142.36A12.27,12.27,0,0,0,154.63,144.64Z" fill="#fbf8d3"/>
          <path d="M130.09,144.64a12.27,12.27,0,0,0,12.27-12.27H117.82A12.27,12.27,0,0,0,130.09,144.64Z" fill="#fbf8d3"/>
          <path d="M81,144.64a12.27,12.27,0,0,0,12.27-12.27H81a30.71,30.71,0,0,0-12,2.45A12.27,12.27,0,0,0,81,144.64Z" fill="#fbf8d3"/>
          <path d="M105.55,144.64a12.27,12.27,0,0,0,12.27-12.27H93.29A12.26,12.26,0,0,0,105.55,144.64Z" fill="#fbf8d3"/>
          <path d="M117.82,163.05A67.35,67.35,0,0,0,64.65,189,30.59,30.59,0,0,0,81,193.72h73.61A30.5,30.5,0,0,0,171,189h0A67.35,67.35,0,0,0,117.82,163.05Z" fill="#70ae91"/>
          <path d="M154.63,181.45a12.26,12.26,0,0,1,12,9.76v.06a30.66,30.66,0,0,1-12,2.45H142.36A12.27,12.27,0,0,1,154.63,181.45Z" fill="#fbf8d3"/>
          <path d="M130.09,181.45a12.27,12.27,0,0,1,12.27,12.27H117.82A12.27,12.27,0,0,1,130.09,181.45Z" fill="#fbf8d3"/>
          <path d="M81,181.45a12.27,12.27,0,0,1,12.27,12.27H81a30.71,30.71,0,0,1-12-2.45A12.28,12.28,0,0,1,81,181.45Z" fill="#fbf8d3"/>
          <path d="M105.55,181.45a12.27,12.27,0,0,1,12.27,12.27H93.29A12.26,12.26,0,0,1,105.55,181.45Z" fill="#fbf8d3"/>
        </g>
      </g>
    </svg>
  );
};
