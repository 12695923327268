import React, { useState, useRef, useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary'; 
import { useDispatch } from 'react-redux';

import 'app/components/settings/teacher.settings.scss';

import { ErrorScreenModal } from 'app/components/error-screen/error-screen-modal.component';
import { ConfirmationSetting } from 'app/components/settings/confirmation/confirmation.setting';
import { DisabledActivitiesSetting } from 'app/components/settings/disabled-activities/disabled-activities.setting';
import { LimitedActivitiesSetting } from 'app/components/settings/limit-words/limit-words.settings';
import { TabList } from 'app/components/tabs/tab-list.component';
import { TabPanel } from 'app/components/tabs/tab-panel.component';
import { TabPanels } from 'app/components/tabs/tab-panels.component';
import { Tab } from 'app/components/tabs/tab.component';
import { Tabs } from 'app/components/tabs/tabs.component';
import { KEY_NAMES } from 'app/constants/keys.constant';
import { TAB_KEYS, TAB_VALUES } from 'app/constants/tabs.constants';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';
import { toggleSettings } from 'app/store/actions/user-settings.actions';

export const TeacherSettingsPopup = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmTimeout, setConfirmTimeout] = useState(false);

  const [showConfirm, setShowConfirm] =  useState(false);
  const [confirmSettings, setConfirmSettings] = useState('');

  const modalRef = useRef();

  const disabledTabRef = useRef();
  const limitedTabRef = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(confirmTimeout);
    };
  }, []);

  const toggleSettingsHandler = () => {
    dispatch(toggleSettings());
  };

  useOnClickOutside(modalRef, toggleSettingsHandler);
  useOnKeyDown(toggleSettingsHandler, KEY_NAMES.ESCAPE);

  const triggerConfirm = (uiMsg, isSuccess) => {
    setConfirmSettings({ uiMessage: uiMsg, isSuccess });

    const revertPageTimeout = setTimeout(() => {
      setShowConfirm(false);
    }, 2500);
    setConfirmTimeout(revertPageTimeout);

    setShowConfirm(true);
  };

  const tabs = [
    {
      elem: <DisabledActivitiesSetting triggerConfirm={triggerConfirm} />,
      value: 'disabledTab',
      ref: disabledTabRef,
    },
    {
      elem: <LimitedActivitiesSetting triggerConfirm={triggerConfirm} />,
      value: 'limitedTab',
      ref: limitedTabRef,
    }
  ];

  return (
    <div className='settingsModal modal'>
      <div className="background-opacity" />
      <div className="settingsPopup">
        <ErrorBoundary
          FallbackComponent={ErrorScreenModal} 
          onReset={() => {dispatch(toggleSettings());} }> 
          <Tabs tabKey={TAB_KEYS.TEACHER_SETTIGNS} defaultValue="disabledTab" className="settingsPopupContent" aria-modal="true" role="dialog" id="settings-popup" ref={modalRef}>
            <div className="top-pane">
              <TabList className="nav nav-tabs">
                <Tab value={TAB_VALUES.TEACHER_SETTINGS.DISABLED_TAB} ref={disabledTabRef}>
              Disable Activities
                </Tab>
                <Tab value={TAB_VALUES.TEACHER_SETTINGS.LIMITED_TAB} ref={limitedTabRef}>
              Limit Words
                </Tab>
              </TabList>
            </div>
            { showConfirm ? 
              <div className="tab-content">
                <ConfirmationSetting confirmSettings={confirmSettings}/>
              </div>
              :
              <TabPanels className="tab-content">
                {tabs.map((panel, i) => <TabPanel key={i} value={panel.value}> { panel.elem } </TabPanel>)}
              </TabPanels>
            }
            <button className="closeButton" onClick={() => dispatch(toggleSettings())} aria-label='Close Settings Modal Window' />
          </Tabs>        
        </ErrorBoundary>
      </div>

    </div>
  );
};
