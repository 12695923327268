import React from 'react';

export const Bulb = ({ classNames }) => {
  return (
    <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames}>
      <circle cx="35.5" cy="35.5" r="33.5" fill="#FFF17C" stroke="black" strokeWidth="4"/>
      <circle cx="35" cy="38" r="11" fill="black"/>
      <path d="M29 44H40V46.5C40 49.5376 37.5376 52 34.5 52C31.4624 52 29 49.5376 29 46.5V44Z" fill="black"/>
      <rect x="30" y="54" width="10" height="2" rx="1" fill="black"/>
      <rect x="51" y="35" width="7" height="4" rx="2" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.5 21.5C34.3954 21.5 33.5 20.6046 33.5 19.5V16.5C33.5 15.3954 34.3954 14.5 35.5 14.5C36.6046 14.5 37.5 15.3954 37.5 16.5V19.5C37.5 20.6046 36.6046 21.5 35.5 21.5Z" fill="black"/>
      <rect x="14" y="35" width="7" height="4" rx="2" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1925 21.2143C19.9901 20.3589 21.3301 20.3121 22.1855 21.1098L24.5086 23.2761C25.3639 24.0737 25.4107 25.4137 24.6131 26.2691C23.8155 27.1244 22.4755 27.1712 21.6201 26.3736L19.297 24.2073C18.4417 23.4096 18.3949 22.0696 19.1925 21.2143Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.6132 21.2143C50.8156 20.3589 49.4755 20.3121 48.6202 21.1098L46.2971 23.2761C45.4417 24.0737 45.3949 25.4137 46.1926 26.2691C46.9902 27.1244 48.3302 27.1712 49.1855 26.3736L51.5087 24.2073C52.364 23.4096 52.4108 22.0696 51.6132 21.2143Z" fill="black"/>
    </svg>
  );
};
