import { chain } from 'lodash';

import { DEFAULT_TEACHER_GRADE, DEMO_SECTION_BK_PREFIX, DEMO_SECTION_NAME } from 'app/constants/demo-section.constant';
import { CURRICULUM_GRADES, STANDALONE_GRADES } from 'app/constants/service-grades.constant';
import store from 'app/store';

export const getTeacherDemoSection = function(teacherBK, type) {
  if (teacherBK == undefined) {
    teacherBK = store.getState().userData?.kcInfo?.sub;
  }
  // @todo: type should be set in the scope of login
  if (type == undefined) {
    const isCDA = store.getState().userData?.licenses.cda;

    if (isCDA)
      type = 'CURRICULUM';
    else
      type = 'STANDALONE';
  }

  let grade = DEFAULT_TEACHER_GRADE[type];
  if (type == 'STANDALONE') {
    grade = STANDALONE_GRADES[grade] || grade;
  } else if (type == 'CURRICULUM') {
    grade = CURRICULUM_GRADES[grade] || grade;
  }

  return {
    [`${DEMO_SECTION_BK_PREFIX}${teacherBK}`] : {
      grades: [grade],
      sectionGrade: grade,
      name: DEMO_SECTION_NAME,
    }
  };
};

export const getDemoSectionStudent = function(userInfo) {
  userInfo = userInfo || store.getState().userData?.kcInfo;

  return {
    firstName: userInfo.given_name,
    lastName: userInfo.family_name,
    username: userInfo.preferred_username,
    businessKey: userInfo.sub,
    email: userInfo.email,
    primary: true,
    nameUI: `${userInfo.family_name}, ${userInfo.given_name}`,
  };
};

export const convertStandaloneMembersData = function(data) {
  return data.members.map((member) => {
    return {
      key: member.businessKey,
      firstName: member.firstName,
      lastName: member.lastName
    };
  });
};

export const convertCurriculumMembersData = function(data) {
  return data.map((member) => {
    return {
      key: member.sourcedId,
      firstName: member.givenName,
      lastName: member.familyName
    };
  });
};

export const getTeacherAsStudent = function(profile) {
  convertStandaloneMembersData({
    members: [profile]
  });
};


export const convertStandaloneClasses = function (classesArray) {
  return classesArray.reduce((classes, classObject) => {
    const grades = classObject.grades.map(grade => {
      return STANDALONE_GRADES[grade];
    });
    classes[classObject.businessKey] = {
      name: classObject.name,
      grades: grades,
      sectionGrade: getStudentGradeFromSectionGrades(grades),
    };
    return classes;
  }, {}
  );
};

export const convertCDAClasses = function (classesArray) {
  return classesArray.reduce((classes, classObject) => {
    const grades = classObject.grades.map(grade => {
      return STANDALONE_GRADES[grade] || CURRICULUM_GRADES[grade];
    });
    classes[classObject.sourcedId] = {
      name: classObject.title,
      grades: grades,
      sectionGrade: getStudentGradeFromSectionGrades(grades),
    };
    return classes;
  }, {}
  );
};

export const mergeTeacherAndClassData = function (classes, demoClass) {
  return Object.assign({}, classes, demoClass);
};

export const innerMergeStudentsDict = function(oldStudents, toAdd, underKey) {
  // Inner merge on keys preserving data in values dicts.
  // Example:
  // oldStudents = {'a': {'a':1}, 'b': {'b':2} }
  // toAdd = {'a': {'b':2}, 'b': {'a':1}}
  // underKey = 'newData'
  /*
  @return {
    "a": {
      "a": 1,
      newData: {
        "b": 2
      }
    },
    "b": {
      "b": 2,
      newData: {
        "a": 1
      },
    }
  }
  */
  return Object.entries(oldStudents).reduce((dict, [bk, data]) => {
    dict[bk] = data;
    if(underKey)
      dict[bk][underKey] = toAdd[bk];
    else 
      dict[bk] = { ...data, ...toAdd[bk] };
    return dict;
  }, {});
};

export const getActiveClasses = function (classesArray) {
  return classesArray.filter(classObject => classObject.status == 'active');
};

export const getInactiveClasses = function (classesArray) {
  return classesArray.filter(classObject => classObject.status != 'active');
};

const isCDAStudent = function(student) {
  return student.sourcedId && !student.businessKey;
};

export const convertCDAStudentData = function(student) {
  return {
    ...student,
    businessKey: student.sourcedId,
    firstName: student.givenName,
    lastName: student.familyName,
  };
};

export const addStudentNamesToDict = function(students) {
  return Object.entries(students).reduce((fullDict, [bk, items]) => {
    fullDict[bk] = {
      ...items,
      nameUI: `${items.lastName}, ${items.firstName}`,
    };
    return fullDict;
  }, {});
};

export const transformStudentsToDict = function (studentsArray, bkKeyName) {
  return studentsArray.reduce((sDict, student) => {
    student = isCDAStudent(student) ? convertCDAStudentData(student) : student;
    sDict[student[bkKeyName]] = { ...student };
    return sDict;
  }, {});
};

export const getStudentGradeFromSectionGrades = (grades) => {
  return Math.min(...grades);
};

export const getMenuOptionsFromActiveClasses = function (activeClasses) {
  return chain(Object.entries(activeClasses).map(([bk, classData]) => {
    return {
      key:bk,
      value: classData.name,
      demo: bk && bk.includes(DEMO_SECTION_BK_PREFIX),
    };
  }))
    .sortBy([(o) => o.value.toLowerCase()])
    .sortBy('demo')
    .value();
};

export const getMenuOptionsFromActiveStudents = function (activeStudents) {
  return chain(Object.entries(activeStudents).map(([bk, studentData]) => {
    return {
      key:bk,
      value: `${studentData.lastName}, ${studentData.firstName}`,
    };
  }))
    .sortBy('value')
    .value();
};
