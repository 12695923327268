import styled from 'styled-components';

export const Button = styled.button`
  width: 55px;
  height: 38px;
  padding: 8px 12px 7px 15px;
  border-radius: 2.9px;
  border: solid 1px #bdbdbd;

  &:first-of-type {
    margin-left: 22px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`;

export const Input = styled.input`
  width: 44px;
  height: 38px;
  border-radius: 2.9px;
  border: solid 1px #bdbdbd;
  text-align: center;
`;