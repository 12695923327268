import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const Countdown = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .to('#transition', { duration: 0.5, opacity: 1, ease: 'sine.out' })
      .to('#red-right', { duration: 0.5, rotate: 180, transformOrigin: '100% 50%', ease: 'none' })
      .to('#red-left', { duration: 0.5, rotate: 180, transformOrigin: '0% 50%', ease: 'none' })
      .to('#yellow-right', { duration: 0.5, rotate: 180, transformOrigin: '100% 50%', ease: 'none' })
      .to('#yellow-left', { duration: 0.5, rotate: 180, transformOrigin: '0% 50%', ease: 'none' })
      .to('#green-right', { duration: 0.5, rotate: 180, transformOrigin: '100% 50%', ease: 'none' })
      .to('#green-left', { duration: 0.5, rotate: 180, transformOrigin: '0% 50%', ease: 'none' })
      .add(gsap.to('#three', { duration: 0.01, opacity: 0 }), 1.5)
      .add(gsap.to('#two', { duration: 0.01, opacity: 1 }), 1.5)
      .add(gsap.to('#two', { duration: 0.01, opacity: 0 }), 2.5)
      .add(gsap.to('#one', { duration: 0.01, opacity: 1 }), 2.5);

    tl.play();

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg id="greatest-countdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <defs>
        <clipPath id="clip-path">
          <rect id="clip-right" x="512" width="370" height="680" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="clip-left" x="142" width="370" height="680" fill="none"/>
        </clipPath>
      </defs>
      <title>GreatestMovie</title>
      <g id="transition" opacity="0">
        <rect id="transition-bg" x="142" width="740" height="680" fill="#fff"/>
        <g clipPath="url(#clip-path)">
          <path id="red-right" d="M511,845C232.1,845,6,618.9,6,340S232.1-165,511-165Z" fill="red"/>
        </g>
        <g clipPath="url(#clip-path-2)">
          <path id="red-left" d="M511-165c278.9,0,505,226.1,505,505S789.9,845,511,845Z" fill="red"/>
        </g>
        <g clipPath="url(#clip-path)">
          <path id="yellow-right" d="M511,845C232.1,845,6,618.9,6,340S232.1-165,511-165Z" fill="#ff0"/>
        </g>
        <g clipPath="url(#clip-path-2)">
          <path id="yellow-left" d="M511-165c278.9,0,505,226.1,505,505S789.9,845,511,845Z" fill="#ff0"/>
        </g>
        <g clipPath="url(#clip-path)">
          <path id="green-right" d="M511,845C232.1,845,6,618.9,6,340S232.1-165,511-165Z" fill="lime"/>
        </g>
        <g clipPath="url(#clip-path-2)">
          <path id="green-left" d="M511-165c278.9,0,505,226.1,505,505S789.9,845,511,845Z" fill="lime"/>
        </g>
        <g id="transition-fg">
          <g id="crosshairs">
            <line x1="512" x2="512" y2="680" fill="none" stroke="#7c7c7c" strokeMiterlimit="10" strokeWidth="6"/>
            <line x1="142" y1="340" x2="882" y2="340" fill="none" stroke="#7c7c7c" strokeMiterlimit="10" strokeWidth="6"/>
            <circle cx="512" cy="340" r="284.99" fill="none" stroke="#7c7c7c" strokeMiterlimit="10" strokeWidth="6"/>
            <circle cx="512" cy="340" r="258.87" fill="none" stroke="#7c7c7c" strokeMiterlimit="10" strokeWidth="6"/>
          </g>
          <g id="sprockets-l">
            <rect width="142" height="680"/>
            <rect x="15" y="8" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="144" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="212" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="280" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="348" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="416" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="484" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="552" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="620" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="15" y="76" width="110" height="48" rx="6" fill="#fff"/>
          </g>
          <g id="sprockets-r">
            <rect x="882" width="142" height="680"/>
            <rect x="899" y="8" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="144" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="212" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="280" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="348" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="416" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="484" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="552" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="620" width="110" height="48" rx="6" fill="#fff"/>
            <rect x="899" y="76" width="110" height="48" rx="6" fill="#fff"/>
          </g>
        </g>
        <path id="three" d="M436.75,453.73q-28.53-18.19-29.92-50.4H438q1.39,25.56,21,39.55t53.9,14q34.29,0,52.5-14.87t18.2-42.88q0-27.3-18-40.25t-55.82-12.95a241.54,241.54,0,0,0-44.8,3.85V329.83q12.24,2.1,33.25,2.1,33.24,0,53.55-13.82T572,281.53q0-21.69-16.8-34.12T509,235q-31.86,0-51.63,14.53t-21.17,39h-31.5a56.3,56.3,0,0,1,14.17-35.87q13.47-15.57,36.75-24.15T509,219.93q29,0,51.8,7.88t35.35,22.05q12.6,14.17,12.6,32,0,19.26-14.88,34.13t-40.77,21.17q67.2,9.81,67.2,61.6,0,34.32-28.35,53.73t-78.4,19.42Q465.28,471.93,436.75,453.73Z"/>
        <path id="two" opacity="0" d="M432.73,420.48q21.69-22.05,67.55-45.85,27.65-14.7,43.4-26.95t22.75-25a59.17,59.17,0,0,0,7-28.88q0-28-16.8-42.87T508.33,236q-32.55,0-51.28,16.28t-19.42,44.62h-31.5q4.2-35.7,32.2-56.35t71.75-20.65q29.74,0,52.32,9.1t35.18,25.9a62.48,62.48,0,0,1,12.6,38.5,63.42,63.42,0,0,1-8.75,32.73q-8.76,14.88-28.88,29.92T516.73,390q-24.15,13-37.63,22.4t-20,18.73a52.37,52.37,0,0,0-8.57,21.17h164.5v16.1H408.23Q411,442.54,432.73,420.48Z"/>
        <path id="one" opacity="0" d="M493.15,277.33h-63.7V263.68q25.89-.69,44.45-11a61.21,61.21,0,0,0,27-29.23h28v245h-35.7Z"/>
      </g>
    </svg>
  );
};
