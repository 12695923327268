import React from 'react';

import clsx from 'clsx';
import { func, string } from 'prop-types';

export const RightArrow = ({ classNames, onClick }) => {
  return (
    <button className={clsx(classNames)} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74">
        <g fill="none" fillRule="evenodd">
          <circle stroke="#14405C" strokeWidth="5" fill="#FFF" cx="35" cy="35" r="32"/>
          <path d="m56.056 41.972-27.82 13.91A5 5 0 0 1 21 51.41V23.59a5 5 0 0 1 7.236-4.472l27.82 13.91a5 5 0 0 1 0 8.944z" fill="#14405C"/>
        </g>
      </svg>
    </button>
  );
};

RightArrow.propTypes = {
  classNames: string,
  onClick: func,
};
