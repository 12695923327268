import React from 'react';

import 'app/components/settings/confirmation/confirmation.setting.scss';
import { useSelector } from 'react-redux';

import success_icon from 'app/shared/images/teacher/settings/settings-check.svg';
import fail_icon from 'app/shared/images/teacher/settings/settings-fail.svg';

export const ConfirmationSetting = (props) => {

  const { confirmSettings } = props;

  const { activeClasses, currentClassBK } = useSelector(state => state.classesData);
  const currentClass =activeClasses[currentClassBK] ;

  return (
    <div id='menu' className='tab-pane confirmation' role="dialog" aria-describedby='status-of-settings-update'>
      <div className='centeredConfirmation'>
        <img src={!confirmSettings.isSuccess ? fail_icon : success_icon} className='statusIcon' alt={!confirmSettings.isSuccess ? 'Failed!' : 'Success!'}/>
        <h3>{currentClass.name}</h3>
        <h3>{confirmSettings.uiMessage}</h3>
        <h3 id="status-of-settings-update">{!confirmSettings.isSuccess ? 'Saving settings failed!' : 'Settings saved!' }</h3>
      </div>
    </div>
  );
};
