import React from 'react';

import loading from 'app/images/loading_activity.gif';
import 'app/components/loader/loader.scss';

export const Loader = () => {
  return (
    <div className="loadingActivity">
      <img src={loading} alt="Loading..." className="loading" />
    </div>
  );
};
