import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

import 'app/components/text-areas/words/word-select.filter.scss';
import { setFilterWordsMode } from 'app/store/actions/words-data.actions';
import { isLoading, isSuccess } from 'app/store/reducers/api-status.reducer';

export const WordSelectFilter = (props) => {

  const { classNames, typingCallback } = props;

  const dispatch = useDispatch();

  const inputRef = useRef();

  const { getWordsByStreamStatus, getWordsByUnitSubunitStatus } = useSelector(state => state.apiReducer);

  useEffect(() => {
    if (inputRef.current && isSuccess(getWordsByStreamStatus) || isSuccess(getWordsByUnitSubunitStatus)) {
      dispatch(setFilterWordsMode(false));
      inputRef.current.value = '';
    }
  }, [getWordsByStreamStatus, getWordsByUnitSubunitStatus]);

  return (
    <div className={clsx('searchWordsWrapper', classNames)}>
      <input
        disabled={isLoading(getWordsByUnitSubunitStatus) || isLoading(getWordsByStreamStatus)}
        type='text'
        id='wordSelectFilter'
        name='name'
        className={`searchWords ${isLoading(getWordsByUnitSubunitStatus) || isLoading(getWordsByStreamStatus) ? 'disabled' : ''}`}
        defaultValue=''
        ref={inputRef}
        onChange={typingCallback}
      />
    </div>
  );
};
