import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SessionStorageService } from 'app/services/session-storage.service';
import { resetRequest } from 'app/store/actions/api-requests.action';
import { gameStateActionCreators } from 'app/store/actions/game-state.actions';
import { isLoading, isSuccess } from 'app/store/reducers/api-status.reducer';
import { getRedirectUrl } from 'app/util/url';

const emptyGameState = {
  vocab: {
    UserId: null,
    GameVersion: '2.0',
    ContentVersion: '2.0',
    GameData: {
      questProgress: {}
    }
  }
};

const ONE_HOUR = 3600000;

export const useQuestStatus = () => {
  const dispatch = useDispatch();
  
  const { gameState } = useSelector(state => state.gameState);
  const { currentProfile } = useSelector(state => state.profileData);
  const { getGameStateStatus, setGameStateStatus, getArFirstEncountersStatus } = useSelector(state => state.apiReducer);
  const { finishedPlay } = useSelector(state => state.wordsData);

  useEffect(() => {
    const questId = String(SessionStorageService.getQuestId());
    const wasSent = SessionStorageService.getGameStateSent()[questId] || {};
    if (!(wasSent && 'started' in wasSent))
      SessionStorageService.setGameStateSent(questId, 'started', false);
    if (!(wasSent && 'finished' in wasSent))
      SessionStorageService.setGameStateSent(questId, 'finished', false);
  }, []);

  useEffect(() => {
    if (isSuccess(getArFirstEncountersStatus) && !isLoading(getGameStateStatus)) {
      dispatch(gameStateActionCreators.getGameState.request({ arId: currentProfile.arId }));
    }
  }, [getArFirstEncountersStatus]);

  const wasStatusSent = (questId, status)  => {
    const wasSent = SessionStorageService.getGameStateSent();
    return wasSent && questId in wasSent && status in wasSent[questId] && wasSent[questId][status];
  };

  const getQuestStatus = (finishedPlay) => {
    return finishedPlay ? 'finished' : 'started';
  };

  const ensureVocabGameState = () => {
    if (!('vocab' in gameState) || !('GameData' in gameState.vocab)) {
      emptyGameState.vocab.UserId = currentProfile.arId;
      gameState.vocab = emptyGameState.vocab;
    }
  };

  const ensureQuestTimestamp = (questId) => {
    if (!('questTimestamps' in gameState.vocab.GameData)) {
      gameState.vocab.GameData.questTimestamps = {};
    }
    if (!(questId in gameState.vocab.GameData.questTimestamps)) {
      gameState.vocab.GameData.questTimestamps[questId] = {
        started_timestamp: null,
        completed_timestamp: null
      };
    }
  };

  const ensureQuestProgress = () => {
    if (!('questProgress' in gameState.vocab.GameData)) {
      gameState.vocab.GameData.questProgress = {};
    }
  };

  const gameStateDoesNotHaveDataForQuestStatus = (questId, status) => {
    const questTimestampCheck = `${status == 'finished' ? 'completed' : 'started'}_timestamp`;

    const haveAlreadyFinished = gameState.vocab.GameData.questProgress[questId] == 'finished';

    const questProgressNotHaveStatusForQuest = !gameState.vocab.GameData.questProgress[questId] ||
                                            gameState.vocab.GameData.questProgress[questId] !== status;

    const questTimestampNotHasStatusForQuest = !gameState.vocab.GameData.questTimestamps[questId] ||
                                        !gameState.vocab.GameData.questTimestamps[questId][questTimestampCheck];

    return !haveAlreadyFinished && (questProgressNotHaveStatusForQuest || questTimestampNotHasStatusForQuest);
  };

  const updateGameStateProgress = (questId, status) => {
    if (
      gameStateDoesNotHaveDataForQuestStatus(questId, status)
    ) {
      let timestamp = Date.now();
      if (!finishedPlay) {
        gameState.vocab.GameData.questTimestamps[questId].started_timestamp = timestamp;
      } else if (finishedPlay) {
        if (!gameState.vocab.GameData.questTimestamps[questId].started_timestamp) {
          gameState.vocab.GameData.questTimestamps[questId].started_timestamp = timestamp;
        }
        if (timestamp - gameState.vocab.GameData.questTimestamps[questId].started_timestamp > ONE_HOUR) {
          timestamp = gameState.vocab.GameData.questTimestamps[questId].started_timestamp + ONE_HOUR;
        }
        gameState.vocab.GameData.questTimestamps[questId].completed_timestamp = timestamp;
      }
      gameState.vocab.GameData.questProgress[questId] = status;

      dispatch(gameStateActionCreators.setGameState.request({ arId: currentProfile.arId, gameState }));
      SessionStorageService.setGameStateSent(questId, status, true);
    }
  };

  useEffect(() => {
    const questId = String(SessionStorageService.getQuestId());
    if (questId &&
        isSuccess(getGameStateStatus) && gameState && gameState.vocab && gameState.vocab.GameData && gameState.vocab.GameData.questProgress &&
        gameState.vocab.GameData.questProgress[questId] == 'finished') {
      window.location = getRedirectUrl();
    }
  }, [getGameStateStatus, gameState]);

  useEffect(() => {
    const status = getQuestStatus(finishedPlay);
    const questId = String(SessionStorageService.getQuestId());
    const pendingActivities = SessionStorageService.getPendingActivities();
    if (!wasStatusSent(questId, status) && isSuccess(getGameStateStatus) && pendingActivities && gameState) {
      ensureVocabGameState();
      ensureQuestTimestamp(questId);
      ensureQuestProgress(questId);
      updateGameStateProgress(questId, status);
    }
  }, [getGameStateStatus, finishedPlay]);

  useEffect(() => {
    const questId = String(SessionStorageService.getQuestId());
    if (wasStatusSent(questId, 'finished') && isSuccess(setGameStateStatus)) {
      window.location = getRedirectUrl();
    } else if (wasStatusSent(questId, 'started') && isSuccess(setGameStateStatus)) {
      dispatch(resetRequest('setGameStateStatus'));
    }
  }, [setGameStateStatus, finishedPlay]);
};
