import _ from 'lodash';

export function arrayContainsItemFromAnother(array1, array2) {
  return array1.some(r => array2.includes(r));
}

export function getNextItemCycled(arr, activeItm) {
  const activeIndex = arr.findIndex((el) => el.type === activeItm.type);
  if (arr.length - 1 < activeIndex + 1) {
    return arr[0];
  } else {
    return arr[activeIndex + 1];
  }
}

export function getPrevItemCycled(arr, activeItm) {
  const activeIndex = arr.findIndex((el) => el.type === activeItm.type);
  if (activeIndex - 1 < 0) {
    return arr[arr.length - 1];
  } else {
    return arr[activeIndex - 1];
  }
}

export function sortStudentsByKey(students, key, ascending = false, forceLowerCase = true) {
  /*
  Sort students based on their details attributes
  @param students list[list[businessKey, details]] - the students list
  @param key string - the key to search the dict by. goes into lodash _.get to select element
  @param ascending - whether to ascending the output (inverse sort)

  @example
  students = [ ['a', {propa: 2}], ['b', {propa: 1}] ]
  key = propa

  result: ['b', {propa: 1}], [ ['a', {propa: 2}] ]

  @example
  students = [ ['a', {propa: 2, sub: {propa: 3}}], ['b', {propa: 2, sub: {propa: 2}}] ]
  key = propa.sub

  result: [ ['b', {propa: 2, sub: {propa: 2}}], ['a', {propa: 2, sub: {propa: 3}}] ]
  */

  const sorted = students.sort((a, b) => {
    const aRaw = _.get(a[1], key);
    const bRaw = _.get(b[1], key);

    const aVal = (typeof aRaw == 'string' && forceLowerCase) ? aRaw.toLowerCase() : aRaw;
    const bVal = (typeof bRaw == 'string' && forceLowerCase) ? bRaw.toLowerCase() : bRaw;

    const retVal = (
      typeof aVal == 'string' && typeof bVal == 'string'
        ?
        aVal.localeCompare(bVal)
        :
        aVal < bVal ? -1 : (aVal > bVal ? 1 : 0)
    );

    return ascending ? retVal : -retVal;
  });

  return sorted;
}

export const doesUserHaveUsageFromStats = (studentStats) => {
  return Boolean(studentStats.stats.stream);
};

export const getAverageAccuracy = (students) => {
  return _.chain(students)
    .filter(doesUserHaveUsageFromStats)
    .map('stats.accuracyPercentage')
    .map((percent) => parseFloat(percent))
    .filter((percent) => !isNaN(percent) && percent >= 0)
    .mean();
};
