import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import { Bulb } from 'app/components/syn-ant-activity/constellations/intro/animated/bulb.component';
import { Moon } from 'app/components/syn-ant-activity/constellations/intro/animated/moon.component';

export const directionsTimeline = new gsap.timeline({ paused: true });

export const ConstellationsDirections = ({ classNames }) => {

  useEffect(() => {

    directionsTimeline
      .add(gsap.to('#choice_1', { duration: 0.5, x: 26, y: 349, ease: 'sine.inOut' }), 1)
      .add(gsap.to('#choice_2', { duration: 0.5, x: 119, y: 297, ease: 'sine.inOut' }), 1.5)
      .add(gsap.to('#choice_3 > path', { duration: 0.5, attr: { fill: '#B6F4FF' }, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#vibe1, #vibe2', { duration: 0.5, transformOrigin: '50% 50%', scale: 1.5, opacity: 0, stagger: 0.15, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#vibe1-2, #vibe2-2', { duration: 0.5, transformOrigin: '50% 50%', scale: 1.5, opacity: 0, stagger: 0.15, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#connect_solid, #connect_solid_2', { duration: 0.5, opacity: 1, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#dropzone-active-2', { duration: 0.2, opacity: 1, ease: 'sine.inOut' }), 1.25)
      .add(gsap.to('#dropzone-active-1', { duration: 0.2, opacity: 1, ease: 'sine.inOut' }), 1.75);

    return () => {
      if (directionsTimeline) directionsTimeline.kill();
    };
  });

  return (
    <div className="constellations-tutorial">
      <svg id="constellations-directions" className={classNames} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680" width="1024" height="680">
        <defs>
          <radialGradient id="radial-gradient" cx="0" cy="680" r="1" gradientTransform="matrix(0, 622.7, 937.71, 0, -637117.09, 340)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#512699"/>
            <stop offset="0.67" stopColor="#2c0049"/>
            <stop offset="1"/>
          </radialGradient>
        </defs>
        <title>directions</title>
        <g id="background_sky" data-name="background sky">
          <rect width="1024" height="680" fill="url(#radial-gradient)"/>
          <path d="M85.91,612.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M884.91,393.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M204.91,380.4l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M75.91,125.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M204.91,214.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M67.91,331.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M729.91,603.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M953.16,92.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M913.91,222.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M953.16,621.4l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M822.91,128.4l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M292,586.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M273.66,295.37l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M758.66,498.37l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M688.66,147.37l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M381.77,105.87l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M254.91,108.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M605.91,67.9l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M491.91,638.4l5,2.75-5,2.75-2.75,5-2.75-5-5-2.75,5-2.75,2.75-5Z" fill="#582ba5"/>
          <path d="M825.16,274.37l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M347.77,512.37l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
          <path d="M667.66,320.87l6.4,3.5-6.4,3.5-3.5,6.4-3.5-6.4-6.4-3.5,6.4-3.5,3.5-6.4Z" fill="#9262e5"/>
        </g>
        <g id="constellation-example">
          <g id="vibration-2">
            <path id="vibe1" d="M919,486.71l.22.41.42.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42l-48.07,26.3-.42.22L919,549l-26.29,48.08a4.81,4.81,0,0,1-8.43,0L858,549l-.22-.41-.41-.22-48.08-26.3a4.8,4.8,0,0,1,0-8.42l48.08-26.29.41-.23.22-.41,26.29-48.07a4.81,4.81,0,0,1,8.43,0Z" fill="none" stroke="#fefce3" strokeWidth="2"/>
            <path id="vibe2" d="M919,486.71l.22.41.42.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42l-48.07,26.3-.42.22L919,549l-26.29,48.08a4.81,4.81,0,0,1-8.43,0L858,549l-.22-.41-.41-.22-48.08-26.3a4.8,4.8,0,0,1,0-8.42l48.08-26.29.41-.23.22-.41,26.29-48.07a4.81,4.81,0,0,1,8.43,0Z" fill="none" stroke="#fefce3" strokeWidth="2"/>
          </g>
          <g id="vibration-1">
            <path id="vibe1-2" d="M683,483.71l.22.41.42.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42l-48.07,26.3-.42.22L683,546l-26.29,48.08a4.81,4.81,0,0,1-8.43,0L622,546l-.22-.41-.41-.22-48.08-26.3a4.8,4.8,0,0,1,0-8.42l48.08-26.29.41-.23.22-.41,26.29-48.07a4.81,4.81,0,0,1,8.43,0Z" fill="none" stroke="#fefce3" strokeWidth="2"/>
            <path id="vibe2-2" d="M683,483.71l.22.41.42.23,48.07,26.29a4.8,4.8,0,0,1,0,8.42l-48.07,26.3-.42.22L683,546l-26.29,48.08a4.81,4.81,0,0,1-8.43,0L622,546l-.22-.41-.41-.22-48.08-26.3a4.8,4.8,0,0,1,0-8.42l48.08-26.29.41-.23.22-.41,26.29-48.07a4.81,4.81,0,0,1,8.43,0Z" fill="none" stroke="#fefce3" strokeWidth="2"/>
          </g>
          <path id="connect_dashed" d="M852.21,484.09l-72.55-75.34" fill="none" stroke="#fff17c" strokeWidth="4.5" strokeDasharray="7.2 7.2"/>
          <path id="connect_dashed_2" d="M685.38,481.79l72.66-68" fill="none" stroke="#fff17c" strokeWidth="4.5" strokeDasharray="7.2 7.2"/>
          <path id="connect_solid" d="M852.21,484.09l-72.55-75.34" fill="none" stroke="#fff" strokeWidth="4.5" opacity="0"/>
          <path id="connect_solid_2" d="M685.38,481.79l72.66-68" fill="none" stroke="#fff" strokeWidth="4.5" opacity="0"/>
          <g id="dropzone_inactive_1">
            <path id="Vector" d="M887.83,602.26a13.25,13.25,0,0,1-11.62-6.89L853,552.87l-42.5-23.24a13.23,13.23,0,0,1,0-23.25L853,483.13l23.24-42.5a13.25,13.25,0,0,1,23.25,0l23.24,42.5,42.5,23.25a13.25,13.25,0,0,1,0,23.25l-42.5,23.24-23.24,42.5a13.23,13.23,0,0,1-11.66,6.89Z" fill="#2c0049"/>
            <path d="M887.83,602.26a13.08,13.08,0,0,1-8.07-2.72l2.75-3.56a8.67,8.67,0,0,0,5.32,1.78Zm5.49-1.17-1.85-4.1a8.67,8.67,0,0,0,4-3.78l.06-.1,3.94,2.16v.1A13.13,13.13,0,0,1,893.32,601.09Zm-18.36-8-3.46-6.32,4-2.16,3.45,6.32ZM903,589l-4-2.16,3.45-6.31,3.95,2.16Zm-34.92-8.51-3.46-6.31,4-2.16,3.45,6.31Zm41.83-4.12-4-2.16,3.45-6.32,4,2.16Zm-48.74-8.51-3.46-6.32,4-2.16,3.45,6.32Zm55.65-4.13-4-2.16,3.45-6.31,4,2.16Zm-62.56-8.51-1.27-2.3-2.85-1.56,2.16-4,4,2.19,1.89,3.47Zm69.13-2.66-2.16-4,6.32-3.46,2.16,3.95Zm-79.57-4.66-6.31-3.45,2.16-4,6.33,3.4ZM936,545.6l-2.16-4,6.31-3.46,2.16,4Zm-104.84-4.66-6.32-3.45,2.16-4,6.32,3.46Zm117.47-2.25-2.16-4,6.32-3.45,2.16,3.94ZM818.53,534l-6.32-3.45,2.16-4,6.32,3.46Zm142.74-2.25-2.16-4,3.93-2.15a9.18,9.18,0,0,0,1.77-1.26l3,3.32a13.62,13.62,0,0,1-2.64,1.88Zm-155.42-6.32a13.15,13.15,0,0,1-2.28-7.46v-.74l4.5.23V518a8.65,8.65,0,0,0,1.5,4.93Zm166.06-5.23-4.44-.73a9.48,9.48,0,0,0,.12-1.5,8.79,8.79,0,0,0-1-4.08l4-2.09a13.5,13.5,0,0,1,1.49,6.17,13.16,13.16,0,0,1-.17,2.26Zm-161.74-7.94-3.4-3a13.41,13.41,0,0,1,3.7-3l2.83-1.56,2.16,3.95-2.83,1.55a8.82,8.82,0,0,0-2.46,2.09ZM962.39,510l-6.31-3.46,2.16-3.94,6.31,3.45Zm-140.61-4.65-2.16-4,6.32-3.46,2.16,3.95Zm128-2.26-6.32-3.46,2.16-3.94,6.32,3.45Zm-115.34-4.65-2.16-4,6.31-3.46,2.16,3.95Zm102.71-2.26-6.32-3.45,2.16-4,6.31,3.45Zm-90.08-4.65-2.16-3.95,6.32-3.46,2.16,4Zm77.44-2.26-5.1-2.79-1.3-2.37,3.95-2.16.66,1.21,3.95,2.16Zm-65.72-7.35-3.94-2.16,3.45-6.32,4,2.16Zm55.87-4.13-3.46-6.31,4-2.16,3.45,6.31Zm-49-8.51-3.94-2.15,3.45-6.32,4,2.16Zm42.05-4.12-3.46-6.32,4-2.16L911.7,463Zm-35.14-8.51-3.94-2.16,3.45-6.32,3.95,2.16Zm28.23-4.12-3.46-6.32,3.95-2.16,3.45,6.32ZM879.5,444l-4-2.16.66-1.2a13.22,13.22,0,0,1,4.79-5l2.32,3.86a8.69,8.69,0,0,0-3.16,3.32Zm14.4-3.32a8.53,8.53,0,0,0-5.07-2.38l.49-4.47a13,13,0,0,1,7.73,3.63Z" fill="#fff17c"/>
          </g>
          <g id="dropzone-active-1" opacity="0">
            <path id="dropzone_active" d="M887.83,600a10.87,10.87,0,0,1-9.65-5.72L854.62,551.2l-43.07-23.56a11,11,0,0,1,0-19.3l43.07-23.56,23.56-43.08a11,11,0,0,1,19.3,0L921,484.79l43.08,23.56a11,11,0,0,1,0,19.3L921,551.21l-23.56,43.08a10.83,10.83,0,0,1-9.61,5.71Z" fill="#7857b0" stroke="#fff" strokeMiterlimit="10" strokeWidth="4.5"/>
          </g>
          <g id="dropzone_inactive_2">
            <path d="M651.83,599.26a13.25,13.25,0,0,1-11.62-6.89L617,549.87l-42.5-23.24a13.23,13.23,0,0,1,0-23.25L617,480.13l23.24-42.5a13.25,13.25,0,0,1,23.25,0l23.24,42.5,42.5,23.25a13.25,13.25,0,0,1,0,23.25l-42.5,23.24-23.24,42.5a13.23,13.23,0,0,1-11.66,6.89Z" fill="#2c0049"/>
            <path d="M651.83,599.26a13.08,13.08,0,0,1-8.07-2.72l2.75-3.56a8.61,8.61,0,0,0,5.32,1.78Zm5.49-1.17-1.85-4.1a8.67,8.67,0,0,0,4-3.78l.06-.1,3.94,2.16v.1A13.13,13.13,0,0,1,657.32,598.09Zm-18.36-8-3.46-6.32,4-2.16,3.45,6.32ZM667,586l-4-2.16,3.45-6.31,3.95,2.16Zm-34.92-8.51-3.46-6.31,4-2.16,3.45,6.31Zm41.83-4.12-4-2.16,3.45-6.32,4,2.16Zm-48.74-8.51-3.46-6.32,4-2.16,3.45,6.32Zm55.65-4.13-4-2.16,3.45-6.31,4,2.16Zm-62.56-8.51-1.27-2.3-2.85-1.56,2.16-4,4,2.19,1.89,3.47Zm69.13-2.66-2.16-4,6.32-3.46,2.16,3.95Zm-79.57-4.66-6.31-3.45,2.16-4,6.33,3.4ZM700,542.6l-2.16-4,6.31-3.46,2.16,4Zm-104.84-4.66-6.32-3.45,2.16-4,6.32,3.46Zm117.47-2.25-2.16-4,6.32-3.45,2.16,3.94ZM582.53,531l-6.32-3.45,2.16-4,6.32,3.46Zm142.74-2.25-2.16-4,3.93-2.15a9.18,9.18,0,0,0,1.77-1.26l3,3.32a13.62,13.62,0,0,1-2.64,1.88Zm-155.42-6.32a13.15,13.15,0,0,1-2.28-7.46v-.74l4.5.23V515a8.65,8.65,0,0,0,1.5,4.93Zm166.06-5.23-4.44-.73a9.48,9.48,0,0,0,.12-1.5,8.79,8.79,0,0,0-1-4.08l4-2.09a13.5,13.5,0,0,1,1.49,6.17,13.16,13.16,0,0,1-.17,2.26Zm-161.74-7.94-3.4-3a13.41,13.41,0,0,1,3.7-3l2.83-1.56,2.16,3.95-2.83,1.55a8.82,8.82,0,0,0-2.46,2.09ZM726.39,507l-6.31-3.46,2.16-3.94,6.31,3.45Zm-140.61-4.65-2.16-4,6.32-3.46,2.16,3.95Zm128-2.26-6.32-3.46,2.16-3.94,6.32,3.45Zm-115.34-4.65-2.16-4,6.31-3.46,2.16,3.95Zm102.71-2.26-6.32-3.45,2.16-4,6.31,3.45Zm-90.08-4.65-2.16-3.95,6.32-3.46,2.16,4Zm77.44-2.26-5.1-2.79-1.3-2.37,3.95-2.16.66,1.21,3.95,2.16Zm-65.72-7.35-3.94-2.16,3.45-6.32,4,2.16Zm55.87-4.13-3.46-6.31,4-2.16,3.45,6.31Zm-49-8.51-3.94-2.15,3.45-6.32,4,2.16Zm42.05-4.12-3.46-6.32,4-2.16L675.7,460Zm-35.14-8.51-3.94-2.16,3.45-6.32,3.95,2.16Zm28.23-4.12-3.46-6.32,3.95-2.16,3.45,6.32ZM643.5,441l-4-2.16.66-1.2a13.22,13.22,0,0,1,4.79-5l2.32,3.86a8.69,8.69,0,0,0-3.16,3.32Zm14.4-3.32a8.53,8.53,0,0,0-5.07-2.38l.49-4.47a13.06,13.06,0,0,1,7.73,3.63Z" fill="#fff17c"/>
          </g>
          <g id="dropzone-active-2" opacity="0">
            <path id="dropzone_active_4" d="M651.83,597a10.87,10.87,0,0,1-9.65-5.72L618.62,548.2l-43.07-23.56a11,11,0,0,1,0-19.3l43.07-23.56,23.56-43.08a11,11,0,0,1,19.3,0L685,481.79l43.08,23.56a11,11,0,0,1,0,19.3L685,548.21l-23.56,43.08a10.83,10.83,0,0,1-9.61,5.71Z" fill="#7857b0" stroke="#fff" strokeMiterlimit="10" strokeWidth="4.5"/>
          </g>
          <path id="sun" d="M769.1,453.76l-12.68,10.49-7.7-14.55L733,454.55l-1.55-16.39-16.38-1.55,4.84-15.73-14.55-7.7,10.49-12.68-10.49-12.68,14.55-7.7-4.84-15.73,16.38-1.55L733,346.45l15.73,4.85,7.7-14.55,12.68,10.49,12.68-10.49,7.7,14.55,15.74-4.85,1.54,16.39,16.39,1.55-4.84,15.73,14.54,7.7-10.5,12.68,10.49,12.68-14.54,7.7,4.84,15.73-16.39,1.55-1.54,16.39-15.74-4.85-7.7,14.55Z" fill="#fff17c"/>
          <g id="type">
            <text transform="translate(740.61 406.54)" fontSize="22" fontFamily="OpenSans, Open Sans" fontWeight="600">small</text>
          </g>
        </g>
        <g id="choice_3">
          <path id="Star_27" d="M943.94,134.59l43.85,24a8,8,0,0,1,0,14l-43.85,24-24,43.84a8,8,0,0,1-14,0l-24-43.84-43.85-24a8,8,0,0,1,0-14l43.85-24,24-43.85a8,8,0,0,1,14,0Z" fill="#fefce3"/>
          <g id="Type-2">
            <text transform="translate(884.34 173.65)" fontSize="21" fontFamily="OpenSans, Open Sans">green</text>
          </g>
        </g>
        <g id="choice_2">
          <path id="Star_28" d="M800.54,190.09l43.85,24a8,8,0,0,1,0,14l-43.85,24-24,43.84a8,8,0,0,1-14,0l-24-43.84-43.84-24a8,8,0,0,1,0-14l43.84-24,24-43.85a8,8,0,0,1,14,0Z" fill="#fefce3"/>
          <g id="Type_2">
            <text transform="translate(751.81 229.15)" fontSize="21" fontFamily="OpenSans, Open Sans">tiny</text>
          </g>
        </g>
        <g id="choice_1">
          <path id="Star_29" d="M656.94,135.09l43.85,24a8,8,0,0,1,0,14l-43.85,24-24,43.84a8,8,0,0,1-14,0l-24-43.84-43.85-24a8,8,0,0,1,0-14l43.85-24,24-43.85a8,8,0,0,1,14,0Z" fill="#fefce3"/>
          <g id="Type_3">
            <text transform="translate(603.73 174.15)" fontSize="21" fontFamily="OpenSans, Open Sans">little</text>
          </g>
        </g>
      </svg>
      <Moon classNames="constellations-intro-moon" />
      <Bulb classNames="constellations-intro-bulb" />
    </div>
    
  );
};
