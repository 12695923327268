import { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { constantMessages, STAGES } from 'app/constants/messages.constants';
import { SessionStorageService } from 'app/services/session-storage.service';
import { setHelperStageAndSettings } from 'app/store/actions/helper-data.action';
import { isSuccess } from 'app/store/reducers/api-status.reducer';


export const useHelperMessages = () => {
  const dispatch = useDispatch();
  const [messagesToShow, setMessagesToShow] = useState([]);
  const [readyToDropChest, setReadyToDropChest] = useState(false);

  const messageDelay = 2000;

  const msgRef = useRef(messagesToShow);
  msgRef.current = messagesToShow;

  const { stage } = useSelector(state => state.helperState);
  const { getCklaFirstEncountersStatus, getArFirstEncountersStatus } = useSelector(state => state.apiReducer);

  const getMultipleRandomMessages = (messages, num) => {
    const shuffled = [...messages].sort(() => 0.5 - Math.random());
  
    return shuffled.slice(0, num);
  };
  
  const nextStage = (stage, delay) => {
    setTimeout(() => dispatch(setHelperStageAndSettings(stage)), delay || messageDelay);
  };

  const nextMessage = (messages, delay) => {
    if (!(messages && messages.length > 0)) return;

    // Phase out old mesasges if too many
    if (msgRef.current.length >= 2) {
      hideMessage(msgRef.current[0], msgRef.current);
    }
    
    // Add new message
    const newMsgs = messages;
    const newMsg = newMsgs.shift();

    setMessagesToShow([...msgRef.current, newMsg]);
    setTimeout(() => nextMessage(newMsgs), (delay || messageDelay));
  };

  const showMessageFromStage = (messages, delay, numMessages) => {
    const toShow = getMultipleRandomMessages(messages, numMessages || 1);
    setTimeout(() => nextMessage(toShow, delay), (delay || messageDelay));
  };

  const extractTextFromMessages = (messages) => {
    return messages.map(msg =>msg.msg);
  };

  useEffect(() => {
    // Wait for any loaders before proceeding out of the load stage
    const hasLoadedActivities = (isSuccess(getCklaFirstEncountersStatus) || isSuccess(getArFirstEncountersStatus));
    const hasActivities = (SessionStorageService.getPendingActivities() && SessionStorageService.getPendingActivities().length > 0);

    // If we are loading, transition stage when finished
    if ((hasLoadedActivities || hasActivities) && stage == 'loadingWords') {
      if (!hasActivities)
        nextStage(STAGES.NO_WORDS, 0);
      else
        nextStage(STAGES.DAILY_DELIVERY, 0);
    }
  }, [getCklaFirstEncountersStatus, getArFirstEncountersStatus, stage]);

  useEffect(() => {
    const hasActivities = (SessionStorageService.getPendingActivities() && SessionStorageService.getPendingActivities().length > 0);
    const messages = constantMessages[stage];
    switch(stage) {
    case STAGES.WELCOME: {
      showMessageFromStage(messages, 1);

      if (!isSuccess(getCklaFirstEncountersStatus) || !isSuccess(getArFirstEncountersStatus)) {
        nextStage(STAGES.LOADING_WORDS);
      } else {
        nextStage(STAGES.DAILY_DELIVERY);
      }
      break;
    }
    case STAGES.LOADING_WORDS: {
      if (hasActivities)
        break;

      showMessageFromStage(messages);
      break;
    }
    case STAGES.DAILY_DELIVERY : {
      showMessageFromStage(messages, 1);
      
      setTimeout(() => setReadyToDropChest(true), 2000);

      nextStage(STAGES.READY_TO_GO);
      break;
    }
    case STAGES.READY_TO_GO: {
      showMessageFromStage(messages);
      nextStage(STAGES.DAILY_RANDOM);
      break;
    }
    case STAGES.NO_WORDS: {
      showMessageFromStage(extractTextFromMessages(messages));
      break;
    }
    }
  }, [stage]);

  const hideMessage = (message, messages) => {
    const messagesToFilter = messages || messagesToShow; 
    const newMessages = messagesToFilter.filter(el => el != message);
    setMessagesToShow(newMessages);
  };

  const showRandomMessage = () => {
    if (stage == STAGES.DAILY_RANDOM) {
      const messages = constantMessages[stage];
      const toShow = getMultipleRandomMessages(messages, 1);
      setTimeout(() => nextMessage(toShow), 0);
    }
  };

  return { messages : messagesToShow, hideMessage: hideMessage, showRandomMessage, readyToDropChest };


};