import { useRef, useMemo } from 'react';

export const useSceneSettings = () => {
  const sceneRef = useRef(null);

  const makeInert = () => {
    const el = sceneRef.current;

    if (el) {
      el.setAttribute('inert', '');
    }
  };

  const makeActive = () => {
    const el = sceneRef.current;

    if (el) {
      el.removeAttribute('inert');
    }
  };

  const settings = useMemo(
    () => ({
      sceneRef,
      makeInert,
      makeActive,
    }),
    [],
  );

  return settings;
};
