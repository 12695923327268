import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { URL_PARAM_KEYS } from 'app/constants/url-param-keys.constants';
import { SessionStorageService } from 'app/services/session-storage.service';

/**
 * Save search params in session store, to have an ability to handle even if they will disappear from URL and store
 */
export const useUrlParams = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    SessionStorageService.setExternalSource(searchParams.get(URL_PARAM_KEYS.EXTERNAL_SOURCE));
    SessionStorageService.setRedirectLink(searchParams.get(URL_PARAM_KEYS.KC_IDP_HINT));
    SessionStorageService.setQuestId(searchParams.get(URL_PARAM_KEYS.QUEST_ID));
    SessionStorageService.setTeacherGrade(searchParams.get(URL_PARAM_KEYS.TEACHER_GRADE));
  }, []);
};
