import store from 'app/store';

export const getProfileId = passedProfileId => {
  const profileId =  passedProfileId || store.getState().userData?.kcInfo?.sub || null;

  if (!profileId) {
    throw new Error('No profile id to get classes for!');
  }

  return profileId;
};

export const getStudentId = passedProfileId => {
  const profileId =  passedProfileId || store.getState().classesData?.currentStudentBK || null;

  if (!profileId) {
    throw new Error('No id to get for the student!');
  }

  return profileId;
};

export const getClassId = passedClassId => {
  const classId = passedClassId || store.getState().classesData?.currentClassBK || null;

  if (!classId) {
    throw new Error('No profile id to get students for!');
  }

  return classId;
};

export const getStudentsBusinessKeys = () => {
  const students = store.getState().classesData.students || {};
  return Object.keys(students);
};

export const getDateRange = () => {
  return store.getState().userSettings.date;
};
