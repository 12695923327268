import React from 'react';

export const MasteredWordsRibbon = () => {
  return (
    <div className="mastered-words-ribbon">
      <svg width="140" height="220" viewBox="0 0 140 220" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-11 -10)" fill="none" fillRule="evenodd">
          <path d="M68.248 110.528 97.06 226.082c6.35-13.875 12.394-20.916 17.583-22.21 5.228-1.304 14.26 2.118 27.136 11.423L112.882 99.4l-44.634 11.129z" stroke="#14405C" strokeWidth="5" fill="#BFDEF2"/>
          <path d="M93.88 110.528 65.07 226.082c-6.352-13.875-12.395-20.916-17.583-22.21-5.228-1.304-14.26 2.118-27.137 11.423L49.247 99.4l44.633 11.129z" stroke="#14405C" strokeWidth="5" fill="#BFDEF2"/>
          <path d="M61.74 13.958a5.414 5.414 0 0 1 6.77.813l5.1 5.258a10.467 10.467 0 0 0 7.377 3.189c2.7.04 5.415-.952 7.5-2.99l5.298-5.457a5.397 5.397 0 0 1 3.803-1.644 5.414 5.414 0 0 1 5.361 4.212l1.334 5.862a10.467 10.467 0 0 0 4.646 6.557 10.467 10.467 0 0 0 8.633 1.164l7.658-2.317a4.9 4.9 0 0 1 3.744.372 4.916 4.916 0 0 1 2.313 5.975l-1.795 5.065c-.968 2.734-.736 5.6.42 8.026a10.467 10.467 0 0 0 7.475 5.787l6.743 1.31a4.683 4.683 0 0 1 2.998 1.985 4.698 4.698 0 0 1-.897 6.244l-4.28 3.546a10.467 10.467 0 0 0-3.755 7.105 10.467 10.467 0 0 0 2.37 7.679 10.5 10.5 0 0 0 1.386 1.386l4.279 3.546a4.683 4.683 0 0 1 1.68 3.179 4.698 4.698 0 0 1-3.78 5.05h-.001l-6.743 1.31a10.467 10.467 0 0 0-6.702 4.435 10.467 10.467 0 0 0-1.193 9.378l1.795 5.066a4.9 4.9 0 0 1-.197 3.757 4.916 4.916 0 0 1-5.86 2.589l-7.658-2.317a10.467 10.467 0 0 0-7.997.794 10.467 10.467 0 0 0-5.282 6.927l-1.333 5.862a5.397 5.397 0 0 1-2.396 3.38 5.414 5.414 0 0 1-6.77-.813l-5.1-5.257a10.467 10.467 0 0 0-7.376-3.189c-2.7-.04-5.414.952-7.5 2.99l-5.298 5.457a5.397 5.397 0 0 1-3.803 1.644 5.414 5.414 0 0 1-5.361-4.212l-1.334-5.862a10.467 10.467 0 0 0-4.646-6.557 10.467 10.467 0 0 0-8.633-1.164l-7.658 2.317a4.9 4.9 0 0 1-3.743-.372 4.916 4.916 0 0 1-2.313-5.975l1.794-5.065c.968-2.734.736-5.6-.42-8.026a10.467 10.467 0 0 0-7.475-5.787l-6.743-1.31a4.683 4.683 0 0 1-2.998-1.985 4.698 4.698 0 0 1 .897-6.244l4.28-3.546a10.467 10.467 0 0 0 3.755-7.105 10.467 10.467 0 0 0-2.37-7.679 10.5 10.5 0 0 0-1.386-1.386l-4.279-3.546a4.683 4.683 0 0 1-1.68-3.179 4.698 4.698 0 0 1 3.78-5.05h.001l6.743-1.31a10.467 10.467 0 0 0 6.702-4.435 10.467 10.467 0 0 0 1.193-9.378l-1.795-5.066a4.9 4.9 0 0 1 .197-3.757 4.916 4.916 0 0 1 5.86-2.589l7.658 2.317c2.775.84 5.628.475 7.997-.794a10.467 10.467 0 0 0 5.282-6.927l1.333-5.862a5.397 5.397 0 0 1 2.396-3.38z" stroke="#14405C" strokeWidth="5" fill="#BFDEF2"/>
          <circle stroke="#31729B" strokeWidth="8" fill="#ECF8FF" cx="81.148" cy="75.41" r="38.623"/>
          <path d="M80.433 50.385a3.5 3.5 0 0 1 4.263 1.766l5.57 11.286 12.832 2.084a3.489 3.489 0 0 1 2.302 1.37 3.5 3.5 0 0 1-.362 4.6l-9.013 8.785 1.984 12.848a3.489 3.489 0 0 1-.592 2.612 3.5 3.5 0 0 1-4.486 1.077L81.79 90.956l-11.606 5.857c-.855.45-1.812.51-2.668.245a3.5 3.5 0 0 1-2.41-3.934l2.127-12.406-9.156-9.228a3.489 3.489 0 0 1-1.057-2.461 3.5 3.5 0 0 1 2.996-3.508l12.456-1.81 5.947-11.56a3.489 3.489 0 0 1 2.014-1.766z" stroke="#31729B" strokeWidth="3" fill="#FFEC42"/>
        </g>
      </svg>
    </div>
  );
};
