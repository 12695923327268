
export const activities = {
  'WordMatch': 'Word Match',
  'ReDictionary': 'ReDictionary',
  'Phrase': 'Phrase',
  'Definitions': 'Definitions',
  'POS': 'POS',
  'Etymology': 'Etymology',
  'SynonymAntonym': 'Synonym & Antonym',
  'FindSynonyms': 'Find Synonyms',
  'OrderSynonyms': 'Order Synonyms',
  'OrderAntonyms': 'Order Antonyms',
  'FindAntonyms': 'Find Antonyms',
  'NarrativeMC':'Narrative MC',
  'BlindItem': 'Blind Item',
  'Zombie': 'Zombie',
  'AskSteve': 'Ask Steve',
  'BestMeal': 'Best Meal',
  'GreatestMovie': 'Greatest Movie',
  'Keisha': 'Keisha',
  'Author': 'Author',
  'Doctor': 'Doctor',
  'Guillermo': 'Guillermo',
  'Hashtag': 'Hashtag',
  'Horoscope': 'Horoscope',
  'Mayi': 'May I',
  'Analogy': 'Analogy',
  'RootsExist': 'Roots Exist',
  'Water': 'Water',
  'Fire': 'Fire',
  'Earth': 'Earth',
  'Life': 'Life',
  'Power': 'Power',
  'Stars': 'Stars',
  'DoctorK5': 'Doctor K-5',
  'BestMealK5': 'Best Meal K-5',
  'ZombieK5': 'Zombie K-5',
  'GreatestMovieK5': 'Greatest Movie K-5',
  'KeishaK5': 'Keisha K-5',
  'AskQueryK5': 'Ask Query K-5',
  'MailBagK5': 'Mail Bag K-5',
  'SunkenShipK5': 'Sunken Ship K-5',
  'FindAntonymsK5': 'Find Antonyms K-5',
  'FindSynonymsK5': 'Find Synonyms K-5'
};

export const activityMapping = function() {
  const synAntDescription = 'Distinguish among the connotations of similar words.';
  const narrativeMcDescription = 'Use context as a clue to the meaning of a word or phrase.';
  const wordMatchDescription = 'Prepare ELL students for the words they will encounter in our lessons.';
  const analogyDescription = 'Use the relationship between words to better understand each.';
  const definitionDescription = 'Consult reference materials to clarify the precise meaning of a word.';
  const posDescription = 'Consult reference materials to determine the part of speech of a word.';
  const etymologyDescription = 'Consult reference materials to determine the etymology of a word.';
  const phraseDescription = 'Consult reference materials to clarify the precise meaning of a phrase.';
  const rootsExistDescription = 'Use Greek or Latin roots as clues to the meaning of a word';

  return {
    FindSynonyms: {
      name: 'Find Synonyms',
      description: synAntDescription
    },
    FindAntonyms: {
      name: 'Find Antonyms',
      description: synAntDescription
    },
    OrderSynonyms: {
      name: 'Order Synonyms',
      description: synAntDescription
    },
    OrderAntonyms: {
      name: 'Order Antonyms',
      description: synAntDescription
    },
    Mayi: {
      name: 'May I',
      description: narrativeMcDescription
    },
    BlindItem: {
      name: 'Blind Item',
      description: narrativeMcDescription
    },
    AskSteve: {
      name: 'Ask Steve',
      description: narrativeMcDescription
    },
    BestMeal: {
      name: 'Best Meal',
      description: narrativeMcDescription
    },
    GreatestMovie: {
      name: 'Greatest Movie',
      description: narrativeMcDescription
    },
    Zombie: {
      name: 'Zombie',
      description: narrativeMcDescription
    },
    Keisha: {
      name: 'Keisha',
      description: narrativeMcDescription
    },
    Author: {
      name: 'Author',
      description: narrativeMcDescription
    },
    Doctor: {
      name: 'Doctor',
      description: narrativeMcDescription
    },
    Guillermo: {
      name: 'Guillermo',
      description: narrativeMcDescription
    },
    Hashtag: {
      name: 'Hashtag',
      description: narrativeMcDescription
    },
    Horoscope: {
      name: 'Horoscope',
      description: narrativeMcDescription
    },
    WordMatch: {
      name: 'Word Match',
      description: wordMatchDescription
    },
    Analogy: {
      name: 'Analogy',
      description: analogyDescription
    },
    Definitions: {
      name: 'ReDictionary',
      description: definitionDescription
    },
    POS: {
      name: 'ReDictionary',
      description: posDescription
    },
    Etymology: {
      name: 'ReDictionary',
      description: etymologyDescription
    },
    Phrase: {
      name: 'ReDictionary',
      description: phraseDescription
    },
    Life: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    Earth: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    Fire: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    Power: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    Stars: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    Water: {
      name: 'Roots Exist',
      description: rootsExistDescription
    },
    DoctorK5: {
      name: 'Doctor K-5',
      description: narrativeMcDescription
    },
    BestMealK5: {
      name: 'Best Meal K-5',
      description: narrativeMcDescription
    },
    ZombieK5: {
      name: 'Zombie K-5',
      description: narrativeMcDescription
    },
    GreatestMovieK5: {
      name: 'Greatest Movie K-5',
      description: narrativeMcDescription
    },
    KeishaK5: {
      name: 'Keisha K-5',
      description: narrativeMcDescription
    },
    AskQueryK5: {
      name: 'Ask Query K-5',
      description: narrativeMcDescription
    },
    MailBagK5: {
      name: 'Mail Bag K-5',
      description: narrativeMcDescription
    },
    SunkenShipK5: {
      name: 'Sunken Ship K-5',
      description: narrativeMcDescription
    },
    FindAntonymsK5: {
      name: 'Find Antonyms K-5',
      description: synAntDescription
    },
    FindSynonymsK5: {
      name: 'Find Synonyms K-5',
      description: synAntDescription
    }
  };
}();