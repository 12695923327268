import React, { useState } from 'react';

import { gsap } from 'gsap';

import 'app/components/multiple-choice/greatestmovie/greatestmovie.scss';
import { Countdown } from 'app/components/multiple-choice/greatestmovie/animated/countdown.component';
import { TheaterTitle } from 'app/components/multiple-choice/greatestmovie/animated/theater-title.component';
import audience from 'app/components/multiple-choice/greatestmovie/images/audience.svg';
import bigClapper from 'app/components/multiple-choice/greatestmovie/images/big-clapper.svg';
import clapperTop from 'app/components/multiple-choice/greatestmovie/images/clapper-top.svg';
import drink from 'app/components/multiple-choice/greatestmovie/images/drink.svg';
import popcornMachine from 'app/components/multiple-choice/greatestmovie/images/popcorn-machine.svg';
import shopBg from 'app/components/multiple-choice/greatestmovie/images/shop-bg.svg';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { useSceneSettings } from 'app/components/multiple-choice/use-scene-settings.hook';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

export const GreatestMovie = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const { sceneRef, makeInert, makeActive } = useSceneSettings();
  const [animationFinished, setAnimationFinished] = useState(false);

  const activityClassName = 'greatestmovie';
  const settings = { onStart: makeInert, onComplete: makeActive };

  const articleScreen = () => <>
    <div className="title-screen animated">
      <TheaterTitle/>
    </div>
    <div id="shopBg" className="greatestmovie-background"><img src={shopBg} alt="" aria-hidden="true" /></div>
    <div id="drink" className="greatestmovie-right"><img src={drink} alt="" aria-hidden="true" /></div>
    <div id="popcornMachine" className="greatestmovie-left"><img src={popcornMachine} alt="" aria-hidden="true" /></div>
  </>;

  const questionScreen = () => <>
    <div id="shopBg" className="greatestmovie-background"><img src={shopBg} alt="" aria-hidden="true" /></div>
    <div id="popcornMachine" className="greatestmovie-left"><img src={popcornMachine} alt="" aria-hidden="true" /></div>
    <div id="bigClapper" className="greatestmovie-clapper"><img src={bigClapper} alt="" aria-hidden="true" /></div>
    <div id="drink" className="greatestmovie-right"><img src={drink} alt="" aria-hidden="true" /></div>
  </>;

  const choicesScreen = () => <>
    <img id="clapperTop" className="greatestmovie-clappertop" src={clapperTop} alt="" aria-hidden="true" />
  </>;

  const feedbackScreen = () => <>
    <div aria-hidden="true" className="feedback-animation">
      <Countdown/>
    </div>
    <div id="audience" className="greatestmovie-background"><img src={audience} alt="" aria-hidden="true" /></div>
  </>;

  const animations = {
    [ARTICLE]: function () {
      const tl = gsap.timeline(settings)
        .fromTo('.activity-card', { scale: 0, opacity: 0 }, { delay: 6, scale:1, opacity: 1, ease: 'ease-out', duration: 0.8 }, 'same-time')
        .fromTo('#shopBg', { scale: 0, opacity: 0 }, { delay: 6, scale:1, opacity: 1, ease: 'ease-out', duration: 0.8 }, 'same-time')
        .fromTo('#drink', { x: '100%' }, { delay: 6, x: 0, duration: 0.8 }, 'same-time')
        .fromTo('#popcornMachine', { x: '-100%' }, {  delay: 6, x: 0, duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());
      tl.play();
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline(settings)
        .to('#popcornMachine', { x: '-100%', duration: 0.8 }, 'same-time')
        .to('.activity-card', { y: '-100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [QUESTION]: function () {
      const tl = gsap.timeline(settings)
        .to('#shopBg', { opacity: 1, duration: 0 })
        .to('#drink', { x: '-5%', duration: 0.8 }, 'same-time')
        .fromTo('#popcornMachine', { x: '-100%' }, {  x: 0, duration: 0.8 }, 'same-time')
        .fromTo('#bigClapper', { scale: 0, opacity: 0 }, { scale:1, opacity: 1, ease: 'ease-out', duration: 0.8 }, 'same-time')
        .fromTo('.activity-card', { scale: 0, opacity: 0 }, { scale:1, opacity: 1, ease: 'ease-out', duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());
      tl.play();
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline(settings)
        .to('#shopBg', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#drink', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#popcornMachine', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#bigClapper', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('.activity-card', { y: '-100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [CHOICES]: function () {
      const tl = gsap.timeline(settings)
        .set('.answers-container', { overflowY: 'hidden' })
        .fromTo('#clapperTop', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 0.8 }, 'same-time')
        .fromTo('.article', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 0.8 }, 'same-time')
        .fromTo('.answers', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 0.8 }, 'same-time')
        .fromTo('.answer-button', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 1, stagger: 0.3 })
        .set('.answers-container', { overflowY: 'auto' })
        .call(()=> tl.kill());
      tl.play();
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline(settings)
        .to('#clapperTop', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('.article', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('.answers', { y: '-100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline(settings)
        .fromTo('.feedback-animation', { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .fromTo('.feedback-animation', { opacity: 1 }, { delay: 3, display: 'none', opacity: 0, duration: 0.3 })
        .fromTo('#audience', { scale: 0 }, { scale:1, ease: 'ease-out', duration: 0.8 })
        .fromTo('.feedback', { y: '200%' }, { y: 0, duration: 0.8 })
        .fromTo('.feedback-next-container', { y: 300 }, { y: 0, duration: 0.8 })
        .call(()=> tl.kill());
      tl.play();
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      ref={sceneRef}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
