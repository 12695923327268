import React from 'react';

import { useSelector } from 'react-redux';

import { ViewsDropdown } from 'app/components/dropdowns/words/views.component';
import { WordModal } from 'app/components/word-modal/word-modal.component';
import { WordsFilter } from 'app/pages/words/components/filter/words.filter';
import { CoreTable } from 'app/pages/words/components/words/core/core.table';
import { NonCoreTable } from 'app/pages/words/components/words/noncore/nonCore.table';

const batchAmount = 45;
const scrollPercentShowMore = 0.95;

export const WordsTable = () => {

  const { wordData } = useSelector(state => state.wordsData);
  const { isCore } = useSelector(state => state.wordsData.filters);

  return (
    <div className="reportingPage">
      <div className="centeredPage">
        <div className="wordModal">
          {
            wordData ?
              <WordModal />
              :
              <></>
          }
        </div>
        <div className="wordsPage">
          <div className="viewHeader">
            <ViewsDropdown />
          </div>
          <div className="optionsHeader">
            <WordsFilter batchAmount={batchAmount} />
          </div>
          {
            isCore ? 
              <div id="coreTable">
                <CoreTable />
              </div>
              :
              <div id="nonCoreTable">
                <NonCoreTable scrollPercentShowMore={scrollPercentShowMore} batchAmount={batchAmount} />
              </div>
          }
        </div>
      </div>
    </div>
  );
};
