import React from 'react';

import 'app/components/header/header.scss';

import clsx from 'clsx';
import { element, string } from 'prop-types';

export const Header = props => {
  const { children, classNames } = props;

  return (
    <header className={clsx(
      'header',
      classNames
    )}>
      {children}
    </header>
  );
};

Header.defaultProps = {
  children: null,
  classNames: '',
};

Header.propTypes = {
  children: element,
  classNames: string,
};
