import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { Loader } from 'app/components/loader/loader';
import { setActiveWordsListBK, setIsCore, setViewFilter } from 'app/store/actions/words-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';

const cdaViewOptions = [
  { value: true, name: 'ELA Unit Words' },
  { value: false, name: 'ELA Extra Words' },
];

const otherViewOptions = [
  { value: false, name: 'ELA Extra Words' },
];

export const ViewsDropdown = () => {
  const dispatch = useDispatch();

  const { licenses } = useSelector(state => state.userData);
  const { wordList } = useSelector(state => state.wordsData);
  const { viewOption } = useSelector(state => state.wordsData.filters);

  const { getWordsByStreamStatus, getWordsByUnitSubunitStatus } = useSelector(state => state.apiReducer);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [views, setViews] = useState(false);

  useEffect(() => {
    if (views) {
      if (licenses.ckla) {
        setDropdownOptions(
          views.map((unit) => {
            return { key: unit.businessKey, value: unit.name };
          })
        );
      } else {
        setDropdownOptions(
          views.map((unit) => {
            return { key: unit.value, value: unit.name };     
          })
        );
      }
    }
  }, [views, licenses]);

  useEffect(() => {
    // First time, after we get the dropdown options init
    if (dropdownOptions && dropdownOptions.length > 0) {
      if (licenses.ckla)
        // If we are in ckla and we have options, we want to use the first option
        dispatch(setActiveWordsListBK(dropdownOptions[0].key));
      
      dispatch(setViewFilter(dropdownOptions[0]));
    }
  }, [dropdownOptions, licenses]);

  useEffect(() => {
    // Set the units options
    if (licenses) {
      if (licenses.ckla) {
        if (wordList != undefined) {
          setViews(wordList);
        }
      } else if (licenses.cda) {
        setViews(cdaViewOptions);
      } else {
        setViews(otherViewOptions);
      }
    }
  }, [licenses, wordList]);

  const selectView = (e, key) => {
    e.preventDefault();

    dispatch(setViewFilter(_.find(dropdownOptions, e => e.key == key)));
    
    if (typeof key == 'boolean')
      dispatch(setIsCore(key));
    else {
      // We are in CKLA mode and we are getting a Business Key
      dispatch(setIsCore(false));
      dispatch(setActiveWordsListBK(key));
    }

  };

  const isDisabled = () => {
    return isLoading(getWordsByStreamStatus) || isLoading(getWordsByUnitSubunitStatus);
  };

  return (
    dropdownOptions && dropdownOptions.length > 0 && viewOption ?
      <div className="wordsViewDropdown">
        <DropdownWrapper
          isDisabled={isDisabled}
          menuOptions={dropdownOptions}
          dropdownName={viewOption.value}
          selectHandler={selectView}
        />
      </div>
      :
      <Loader />
  );
};
