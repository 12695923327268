import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';
import { getDemoSectionStudent } from 'app/util/classes';

const GET_LATEST_STUDENT_PROFILE = createRequestTypes(API_ACTION_TYPES.GET_LATEST_STUDENT_PROFILE);
const GET_STANDLONE_CLASSES_SECTIONS = createRequestTypes(API_ACTION_TYPES.GET_STANDLONE_CLASSES_SECTIONS);
const GET_CDA_CLASSES_SECTIONS = createRequestTypes(API_ACTION_TYPES.GET_CDA_CLASSES_SECTIONS);
const GET_STUDENT_STANDALONE_PROFILES = createRequestTypes(API_ACTION_TYPES.GET_STUDENT_STANDALONE_PROFILES);
const GET_STUDENT_CDA_PROFILES = createRequestTypes(API_ACTION_TYPES.GET_STUDENT_CDA_PROFILES);
const GET_STUDENTS_CLASSES = createRequestTypes(API_ACTION_TYPES.GET_STUDENTS_CLASSES);
const GET_DISABLED_ACTIVITIES = createRequestTypes(API_ACTION_TYPES.GET_DISABLED_ACTIVITIES);
const GET_ACTIVITIES_LIMITATION = createRequestTypes(API_ACTION_TYPES.GET_ACTIVITIES_LIMITATION);
const GET_TEACHER_SECTION_STATS = createRequestTypes(API_ACTION_TYPES.GET_TEACHER_SECTION_STATS);
const GET_STUDENT_LATEST_PROFILE_FOR_TEACHER = createRequestTypes(API_ACTION_TYPES.GET_STUDENT_LATEST_PROFILE_FOR_TEACHER);
const GET_STUDENT_STATS = createRequestTypes(API_ACTION_TYPES.GET_STUDENT_STATS);

export const classesDataActions = {
  GET_LATEST_STUDENT_PROFILE,
  GET_STANDLONE_CLASSES_SECTIONS,
  GET_CDA_CLASSES_SECTIONS,
  GET_STUDENT_STANDALONE_PROFILES,
  GET_STUDENT_CDA_PROFILES,
  GET_STUDENTS_CLASSES,
  GET_DISABLED_ACTIVITIES,
  GET_ACTIVITIES_LIMITATION,
  GET_TEACHER_SECTION_STATS,
  GET_STUDENT_LATEST_PROFILE_FOR_TEACHER,
  GET_STUDENT_STATS,
};

export const classesDataActionCreators = {
  getLatestStudentProfile: { ...createActions(GET_LATEST_STUDENT_PROFILE) },
  getStandaloneClassesSections: { ...createActions(GET_STANDLONE_CLASSES_SECTIONS) },
  getCDAClassesSections: { ...createActions(GET_CDA_CLASSES_SECTIONS) },
  getStandaloneStudentProfiles: { ...createActions(GET_STUDENT_STANDALONE_PROFILES) },
  getCDAStudentProfiles: { ...createActions(GET_STUDENT_CDA_PROFILES) },
  getStudentsClasses: { ...createActions(GET_STUDENTS_CLASSES) },
  getDisabledActivities: { ...createActions(GET_DISABLED_ACTIVITIES) },
  getActivitiesLimitation: { ...createActions(GET_ACTIVITIES_LIMITATION) },
  getTeacherSectionStats: { ...createActions(GET_TEACHER_SECTION_STATS) },
  getStudentLatestProfileForTeacher: { ...createActions(GET_STUDENT_LATEST_PROFILE_FOR_TEACHER) },
  getUserStats: { ...createActions(GET_STUDENT_STATS), },
};

// Non fetch actions

export const CHANGE_CURRENT_CLASS = '[Classes Data] CHANGE_STUDENT_CLASS';
export const CHANGE_CURRENT_STUDENT = '[Classes Data] CHANGE_STUDENT_STUDENT';
export const GET_DEMO_STUDENT_PROFILES = '[Classes Data] GET_DEMO_STUDENT_PROFILES';
export const RESET_STUDENTS = '[Classes Data] RESET_STUDENTS';
export const RESET_CLASSES = '[Classes Data] RESET_CLASSES';

export function changeCurrentClass(currentClassBK) {
  return {
    type: CHANGE_CURRENT_CLASS,
    payload: currentClassBK,
  };
}

export function changeCurrentStudent(studentBK) {
  return {
    type: CHANGE_CURRENT_STUDENT,
    payload: studentBK,
  };
}

export function getDemoStudentProfiles(userInfo) {
  return {
    type: GET_DEMO_STUDENT_PROFILES,
    payload: getDemoSectionStudent(userInfo),
  };
}

export function resetStudents() {
  return {
    type: RESET_STUDENTS,
  };
}

export function resetClasses() {
  return {
    type: RESET_CLASSES,
  };
}
