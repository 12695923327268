import React, { useRef } from 'react';

import clsx from 'clsx';
import _ from 'lodash';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';

import 'app/components/footer/export-button/export-button.scss';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { getAverageAccuracy, sortStudentsByKey } from 'app/util/array';
import { isDemoBK } from 'app/util/demo';
import { getGradeForStudentClass } from 'app/util/grade';
import { getAccuracy } from 'app/util/stats';
import { getStreamName } from 'app/util/streams';

const CSV_METADATA = 'data:text/csv;charset=utf-8,';

export const ExportButton = props => {

  const { classNames, label } = props;

  const currentPage = () => {
    const studentsPanel = document.getElementById('panel_students');
    const wordsPanel = document.getElementById('panel_words');

    if (!studentsPanel || !wordsPanel)
      return 'loading';

    if (studentsPanel.classList.contains('active'))
      return 'students';
    else if (wordsPanel.classList.contains('active'))
      return 'words';
    return 'none';
  };

  // Students \\
  const {
    getStudentStandaloneProfilesStatus,
    getStudentCDAProfilesStatus,
    getTeacherSectionStatsStatus,
    getWordsByStreamStatus,
    getWordsByUnitSubunitStatus,
  } = useSelector(state => state.apiReducer);

  const { students, currentClassBK, activeClasses } = useSelector(state => state.classesData);

  const studentsLoaded = currentClassBK != null && (isSuccess(getStudentStandaloneProfilesStatus) || isSuccess(getStudentCDAProfilesStatus) || isDemoBK(currentClassBK));
  const studentsList = students && Object.entries(students);

  // Words \\
  const { showWords, words, filterMode, showLessons } = useSelector(state => state.wordsData);
  const { unitOption, subUnitOption, gradeOption, streamOption } = useSelector(state => state.wordsData.filters);
  const { isCore } = useSelector(state => state.wordsData.filters);

  // Status \\
  const hasStudentData = studentsLoaded && isSuccess(getTeacherSectionStatsStatus) && studentsList;
  const hasWordData = (isSuccess(getWordsByStreamStatus) || isSuccess(getWordsByUnitSubunitStatus));

  const csvRef = useRef();

  function _streamsToString(streams) {
    return _.chain(streams)
      .map('name')
      .map(function (stream) {
        return getStreamName(stream);
      })
      .compact()
      .value()
      .join(', ');
  }

  const getStudentsAsCSV = (separator) => {
    const currentClass = activeClasses[currentClassBK];

    const now = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' });

    const csvBuilder = {
      filename: `${currentClass.name}_${now}`,
      content: '',
    };

    const overallAccuracy = getAverageAccuracy(
      Object.values(students)
    );

    // Name
    csvBuilder.content += `${currentClass.name} ${now} - Overall Accuracy is ${parseFloat(overallAccuracy).toFixed(2)}%`;
    csvBuilder.content += '\n';

    // Headers
    csvBuilder.content += ['FIRSTNAME', 'LASTNAME', 'TODAY', 'MASTERY', 'ACCURACY', 'GRADE', 'STREAM'].join(separator);
    csvBuilder.content += '\n';

    // Body
    const sortedFirstName = sortStudentsByKey(Object.entries(students), 'lastName', true, true);
    sortStudentsByKey(sortedFirstName, 'lastName', true, true).forEach(studentsArr => {
      const student = studentsArr[1];

      csvBuilder.content += [
        student.firstName,
        student.lastName,
        `${student.stats.activitiesCorrectToday}/${student.stats.activitiesCompletedToday}`,
        `${student.stats.wordsMastered}/${student.stats.wordsDelivered}`,
        getAccuracy(student.stats.accuracyPercentage, student.stats.stream),
        getGradeForStudentClass({ activeStudent: student, activeClass: currentClass }),
        student.stats.streamUI
      ].join(separator);
      csvBuilder.content += '\n';
    });

    return csvBuilder;
  };

  const getNonCoreAsCSV = (separator, csvBuilder) => {
    // Name
    csvBuilder.content += `"Words - Grade ${gradeOption.value}, Stream ${streamOption.value}"`;
    csvBuilder.content += '\n';

    // Headers
    csvBuilder.content += ['WORD', 'PART OF SPEECH', 'IS ACADEMIC', 'IS ELL', 'STRIVING STREAM GRADES',
      'CORE STREAM GRADES', 'CHALLENGE STREAM GRADES'].join(separator);
    csvBuilder.content += '\n';

    const wordsToUse = filterMode ? showWords : words;

    wordsToUse.forEach(word => {
      csvBuilder.content += [
        word.word,
        word.pos,
        word.academic,
        word.ell,
        word.belowGrade ? `"${word.belowGrade}"` : 'null',
        word.atGrade ? `"${word.atGrade}"` : 'null',
        word.aboveGrade ? `"${word.aboveGrade}"` : 'null',
      ].join(separator);
      csvBuilder.content += '\n';
    });

    return csvBuilder;
  };

  const getCoreAsCSV = (separator, csvBuilder) => {
    // Name
    csvBuilder.content += `"Words - ${unitOption.value}, ${subUnitOption.value}"`;
    csvBuilder.content += '\n';

    // Headers
    csvBuilder.content += ['"LESSON"', '"WORD"', '"PART OF SPEECH"', '"STREAMS"'].join(separator);
    csvBuilder.content += '\n';

    showLessons.forEach(function (lesson) {
      lesson.firstEncounterWords.forEach(function (fe) {
        csvBuilder.content += [
          lesson.lesson,
          fe.word.name,
          fe.word.pos,
          '"' + _streamsToString(fe.word.stream) + '"',
        ].join(separator);
        csvBuilder.content += '\n';
      });
    });

    return csvBuilder;
  };

  const getWordsAsCSV = (separator) => {

    const filenameDesc = isCore ?
      `Words - ${unitOption.value}, ${subUnitOption.value}`
      :
      `Words - Grade ${gradeOption.value}, Stream ${streamOption.value}`;

    const now = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' });

    const csvBuilder = {
      filename: `${filenameDesc}, ${now}`,
      content: '',
    };

    const csvWordsBuilder = isCore ? getCoreAsCSV(separator, csvBuilder) : getNonCoreAsCSV(separator, csvBuilder);

    return csvWordsBuilder;
  };

  const convertToCSV = () => {
    const csvData = (currentPage() == 'words') ? getWordsAsCSV(',') : getStudentsAsCSV(',');

    const csvLink = csvRef.current;

    csvLink.setAttribute('href', encodeURI(CSV_METADATA + csvData.content));
    csvLink.setAttribute('download', csvData.filename);

    csvLink.click();
  };

  return (
    ((hasStudentData && currentPage() == 'students') || (hasWordData && currentPage() == 'words')) ?
      <>
        <button className={clsx('export-button vcentered', classNames)} onClick={convertToCSV}>{label}</button>
        {/* eslint-disable-next-line */}
        <a aria-hidden='true' tabIndex="-1" ref={csvRef} style={{ display: 'none' }}>csv download</a>
      </>
      :
      <></>
  );
};

ExportButton.propTypes = {
  classNames: string,
  label: string
};
