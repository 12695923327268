import React, { useState } from 'react';

import { gsap } from 'gsap';

import { Title } from 'app/components/multiple-choice/bestmeal/animated/title.component';
import forkSpagetti from 'app/components/multiple-choice/bestmeal/images/fork_spaghetti.svg';
import forkTomato from 'app/components/multiple-choice/bestmeal/images/fork_tomato.svg';
import header from 'app/components/multiple-choice/bestmeal/images/header.svg';
import knife from 'app/components/multiple-choice/bestmeal/images/knife.svg';
import platterBurger from 'app/components/multiple-choice/bestmeal/images/platter_burger.svg';
import platterCheesecake from 'app/components/multiple-choice/bestmeal/images/platter_cheesecake.svg';
import platterMuscles from 'app/components/multiple-choice/bestmeal/images/platter_muscles.svg';
import platterPasta from 'app/components/multiple-choice/bestmeal/images/platter_pasta.svg';
import burger from 'app/components/multiple-choice/bestmeal/images/square_burger.svg';
import cheesecake from 'app/components/multiple-choice/bestmeal/images/square_cheesecake.svg';
import fork from 'app/components/multiple-choice/bestmeal/images/square_fork.svg';
import cake from 'app/components/multiple-choice/bestmeal/images/square_layer-cake.svg';
import pasta from 'app/components/multiple-choice/bestmeal/images/square_olives.svg';
import spoon from 'app/components/multiple-choice/bestmeal/images/square_spoon.svg';
import threeDots from 'app/components/multiple-choice/bestmeal/images/three-dots_white.svg';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { useSceneSettings } from 'app/components/multiple-choice/use-scene-settings.hook';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

import 'app/components/multiple-choice/bestmeal/bestmeal.scss';

export const BestMeal = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const { sceneRef, makeInert, makeActive } = useSceneSettings();
  const [animationFinished, setAnimationFinished] = useState(false);

  const activityClassName = 'bestmeal';
  const settings = { onStart: makeInert, onComplete: makeActive };

  const articleScreen = () => <>
    <div className="title-screen animated">
      <Title/>
    </div>
    <div id="header" className="bestmeal-header"><img src={header} alt="" aria-hidden="true" /></div>
    <div id="forkSpagetti" className="bestmeal-left"><img src={forkSpagetti} alt="" aria-hidden="true" /></div>
    <div id="knife" className="bestmeal-right"><img src={knife} alt="" aria-hidden="true" /></div>
  </>;

  const questionScreen = () => <>
    <div id="header2" className="bestmeal-header"><img src={header} alt="" aria-hidden="true" /></div>
    <div id="forkTomato" className="bestmeal-left"><img src={forkTomato} alt="" aria-hidden="true" /></div>
    <div id="knife2" className="bestmeal-right"><img src={knife} alt="" aria-hidden="true" /></div>
  </>;

  const choicesScreen = () => <>
    <img id="plate" className="bestmeal-burger" src={platterBurger} alt="" aria-hidden="true" />
    <img id="dots" className="bestmeal-dots" src={threeDots} alt="" aria-hidden="true" />
  </>;

  const feedbackScreen = () => <>
    <div className="feedback-animation-out">
      <img className="feedback-plate"  src={platterPasta} alt="" aria-hidden="true" />
      <img className="feedback-plate" src={platterMuscles} alt="" aria-hidden="true" />
      <img className="feedback-plate" src={platterCheesecake} alt="" aria-hidden="true" />
    </div>
    <div className="bestmeal-left">
      <img className="feedback-square" src={pasta} alt="" aria-hidden="true" />
      <img className="feedback-square" src={cheesecake} alt="" aria-hidden="true" />
      <img className="feedback-square" src={fork} alt="" aria-hidden="true" />
    </div>

    <div className="bestmeal-right">
      <img className="feedback-square" src={cake} alt="" aria-hidden="true" />
      <img className="feedback-square" src={burger} alt="" aria-hidden="true" />
      <img className="feedback-square" src={spoon} alt="" aria-hidden="true" />
    </div>
  </>;

  const animations = {
    [ARTICLE]: () => {
      const tl = gsap.timeline(settings)
      // Title Screen animation
        .fromTo('.container', { opacity: 0 }, { opacity: 1, ease: 'sine.out', duration: 0.8  })
      // Article animation
        .fromTo('#header', { opacity: 0, yPercent: 100 }, { delay: 3.5, opacity: 1, yPercent: 0, duration: 0.8 }, 'same-time')
        .fromTo('#forkSpagetti', { opacity: 0, yPercent: 100 }, { delay: 3.5, opacity: 1, yPercent: 0, duration: 0.8 }, 'same-time')
        .fromTo('#knife', { opacity: 0, yPercent: 100 }, { delay: 3.5, opacity: 1, yPercent: 0, duration: 0.8 }, 'same-time')
        .fromTo('.activity-card', { yPercent: 100 }, { delay: 3.5, display: 'flex', opacity: 1, yPercent: 0, duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());

      tl.play();
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline(settings)
        .to('#forkSpagetti', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#knife', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('.activity-card', { y: '-100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [QUESTION]: function () {
      const tl = gsap.timeline(settings)
        .fromTo('#header2', { opacity: 0 }, { opacity: 1, duration: 0 })
        .fromTo('#forkTomato', { opacity: 0, y: '100%' }, { opacity: 1, y: '0', duration: 0.8 }, 'same-time')
        .fromTo('#knife2', { opacity: 0, y: '100%' }, { opacity: 1, y: '0', duration: 0.8 }, 'same-time')
        .fromTo('.activity-card', { y: '100%' }, { display: 'flex', opacity: 1, y: '0', duration: 0.8 }, 'same-time')
        .call(()=> tl.kill());
      tl.play();
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline(settings)
        .to('#header2', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#forkTomato', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('#knife2', { y: '-100vh', duration: 0.8 }, 'same-time')
        .to('.activity-card', { y: '-100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [CHOICES]: function () {
      const tl = gsap.timeline(settings)
        .set('.answers-container', { overflowY: 'hidden' })
        .fromTo('.bestmeal-burger, .bestmeal-dots', { x: -300, opacity: 0 }, { x: 0, opacity: 1, duration: 0.5, ease: 'sine.out' })
        .fromTo('.article', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 0.8, ease: 'sine.out' })
        .fromTo('.answers', { opacity: 0, y: '100%' }, { opacity: 1, y: 0, duration: 0.8, ease: 'sine.out' })
        .fromTo('.answer-button', { opacity: 0, translateY: 700 }, { opacity: 1, translateY: 0, duration: 1, stagger: 0.3, ease: 'sine.out' })
        .set('.answers-container', { overflowY: 'auto' })
        .call(()=> tl.kill());
      tl.play();
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline(settings)
        .to('.bestmeal-burger, .bestmeal-dots', { duration: 0.5, x: -300, delay: 0.25, ease: 'sine.in' })
        .add(gsap.to('.article, .answers', { duration: 0.8, y: -680, ease: 'sine.in' }), 0.5)
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline(settings)
        .set('.feedback-plate', { x: '-100%', y: '-100%' })
        .fromTo('.bestmeal-left, .bestmeal-right, .feedback-next-container', { opacity: 0, y: '100%' }, { duration: 0.8, opacity: 1, y: 0, delay: 0.25, ease: 'sine.out' })
        .set('.feedback-animation-out', { opacity: 1 })
        .fromTo('.feedback-plate', { x: '-100%', y: '-100%' }, { duration: 1, x: 0, y: 0, stagger: 0.3, ease: 'sine.out' })
        .to('.feedback', { duration: 0.25, opacity: 1 })
        .to('.feedback-plate', { duration: 1, x: '-100%', y: '-100%', stagger: 0.3, ease: 'sine.in' })
        .to('.feedback-animation-out', { duration: 0.5, opacity: 0 })
        .from('.next-button', { duration: 0.5, opacity: 0 })
        .call(()=> tl.kill());
      tl.play();
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      ref={sceneRef}
      animationFinished ={animationFinished}
      clearAnimationFinished ={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
