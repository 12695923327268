import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from 'app/components/date/date-picker/date-picker';
import { ClassesDropdown } from 'app/components/dropdowns/classes/classes-dropdown.component';
import { TeacherSettingsPopup } from 'app/components/settings/teacher.settings';
import { CONFIG } from 'app/config';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { isIdle, isLoading, isSuccess } from 'app/store/reducers/api-status.reducer';
import { sortStudentsByKey } from 'app/util/array';
import { isDemoBK } from 'app/util/demo';
import { getGradeForStudentClass } from 'app/util/grade';
import { getAccuracy, getAccuracyColor } from 'app/util/stats';
import { getStreamsBy, getStreamsForGrade, getStreamType } from 'app/util/streams';

import 'app/components/reporting/students/students.page.scss';

export const StudentsPage = (props) => {

  const { handleClassSelection, handleStudentSelection } = props;

  const { students, currentClassBK, activeClasses } = useSelector(state => state.classesData);
  const { streams } = useSelector(state => state.streamsReducer);
  const {
    getStudentStandaloneProfilesStatus,
    getStudentCDAProfilesStatus,
    getTeacherSectionStatsStatus,
  } = useSelector(state => state.apiReducer);
  const { startDate, endDate } = useSelector(state => state.userSettings.date);
  const { open } = useSelector(state => state.userSettings.teacherSettings);

  const studentsLoading = currentClassBK != null && (isLoading(getStudentStandaloneProfilesStatus) || isLoading(getStudentCDAProfilesStatus) || isDemoBK(currentClassBK));
  const studentsLoaded = currentClassBK != null && (isSuccess(getStudentStandaloneProfilesStatus) || isSuccess(getStudentCDAProfilesStatus) || isDemoBK(currentClassBK));

  const dispatch = useDispatch();

  // Get activities limitation and disabled activities
  useLayoutEffect(() => {
    if (studentsLoaded && isIdle(getTeacherSectionStatsStatus) && currentClassBK && students) {
      dispatch(classesDataActionCreators.getActivitiesLimitation.request());
      dispatch(classesDataActionCreators.getDisabledActivities.request());
      dispatch(classesDataActionCreators.getTeacherSectionStats.request(streams));
    }
  }, [studentsLoaded, currentClassBK, streams, getTeacherSectionStatsStatus]);

  useEffect(() => {
    if (students)
      dispatch(classesDataActionCreators.getTeacherSectionStats.request(streams));
  }, [startDate, endDate]);

  const studentsList = students && Object.entries(students) || [];

  // Sorting
  /*
  * @TODO: Make table list a general component and use it in classes. Ensures consistent styles / easier accessibility.
  */
  const nameCarrot = useRef();
  const [sort, setSort] = useState();
  const [ascending, setAscending] = useState(true);

  const setSortBy = (e) => {
    e.preventDefault();

    let carrot;
    if (e.target.classList.contains('carrot'))
      carrot = e.target;
    else
      carrot = e.target.lastChild;

    const carrotLast = sort == null ? nameCarrot.current : sort;

    // Clear arrows if different column
    if (carrotLast != null && carrotLast.id != carrot.id) {
      carrotLast.classList.remove('down');
      carrotLast.classList.remove('up');
    }

    setSort(carrot);
    const down = carrot.classList.contains('down');
    const up = carrot.classList.contains('up');

    if (down) {
      carrot.classList.remove('down');
      carrot.classList.add('up');
      setAscending(true);
    } else if (up) {
      carrot.classList.remove('up');
      carrot.classList.add('down');
      setAscending(false);
    } else {
      carrot.classList.add('down');
      carrot.classList.remove('up');
      setAscending(false);
    }
  };

  const sortList = (list) => {
    if (sort != null)
      switch (sort.id) {
      case 'name-carrot':
        return sortStudentsByKey(list, 'nameUI', ascending);
      case 'today-carrot':
        return sortStudentsByKey(list, 'stats.activitiesCorrectToday', ascending);
      case 'mastery-carrot':
        return sortStudentsByKey(list, 'stats.wordsMastered', ascending);
      case 'accuracy-carrot':
        return sortStudentsByKey(list, 'stats.accuracyPercentage', ascending);
      case 'grade-carrot':
        return sortStudentsByKey(list, 'stats.grade', ascending);
      case 'stream-carrot':
        return sortStudentsByKey(list, 'stats.streamUI', ascending);
      }
    return sortStudentsByKey(list, 'nameUI', ascending);
  };

  const iconedItems = activeClasses[currentClassBK] && getStreamsForGrade(activeClasses[currentClassBK].sectionGrade) || undefined;


  const readyToShow = (studentsLoaded && isSuccess(getTeacherSectionStatsStatus) && studentsList);

  const summary =  readyToShow ?
    activeClasses.length > 0 ?
      `Students In Class ${activeClasses[currentClassBK].name}. Interact with row or element to select the student in the row for viewing.`
      :
      'Empty Class'
    :
    '';

  return (
    <div className="reportingPage">
      <div className="studentsPage">
        <div className="centeredPage">
          <div className="optionsHeader">
            <ClassesDropdown handleClassSelection={handleClassSelection} />
            <DatePicker />
          </div>

          <div className='table'>
            <table
              role="grid"
              aria-colcount="6"
              aria-describedby="students-table-description"
              summary={summary}
            >
              <thead>
                <tr className='studentTableHeader title' aria-describedby="Select individual header to sort by it">
                  <th className="usernameItem align-left" onClick={setSortBy} id="name" scope="col" tabIndex="0" aria-details='Select to sort'>
                    Name
                    <span className="carrot up" ref={nameCarrot} id="name-carrot" aria-hidden="true" />
                  </th>
                  <th className="ratioItem align-center" onClick={setSortBy} id="today" scope="col" tabIndex="0" aria-details='Select to sort'>
                    Today
                    <span className="carrot" id="today-carrot" aria-hidden="true" />
                  </th>
                  <th className="masteredItem align-center" onClick={setSortBy} id="mastery" scope="col" tabIndex="0">
                    Mastery
                    <span className="carrot" id="mastery-carrot" aria-hidden="true" />
                  </th>
                  <th className="accuracyItem align-center" onClick={setSortBy} id="accuracy" scope="col" tabIndex="0">
                    Accuracy
                    <span className="carrot" id="accuracy-carrot" aria-hidden="true" />
                  </th>
                  <th className="gradeItem align-center" onClick={setSortBy} id="grade" scope="col" tabIndex="0">
                    Grade
                    <span className="carrot" id="grade-carrot" aria-hidden="true" />
                  </th>
                  <th className="streamItem align-left" onClick={setSortBy} id="stream" scope="col" tabIndex="0">
                    Stream
                    <span className="carrot" id="stream-carrot" aria-hidden="true" />
                  </th>
                </tr>
              </thead>

              <tbody className={readyToShow ? '' : 'loading'}>
                {
                  studentsLoading || studentsList.length > 0
                    ?
                    readyToShow
                      ?
                      sortList(studentsList).map(([bk, student]) => {
                        return (
                          <tr
                            key={`${bk}-row`}
                            onClick={(e) => handleStudentSelection(e, bk)}
                            onKeyUp={(e) => handleStudentSelection(e, bk)}
                            className='listItem'
                            aria-label={`Select to open ${student.nameUI} stats`}
                            tabIndex="0"
                          >
                            <td
                              key={`${bk}-username`}
                              className="usernameItem"
                              data-dd-action-name="**student**"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className="text align-left">
                                {student.nameUI}
                              </button>
                            </td>
                            <td
                              key={`${bk}-ratio`}
                              className="ratioItem"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className="text align-center">
                                {student.stats.activitiesCorrectToday}/{student.stats.activitiesCompletedToday}
                              </button>
                            </td>
                            <td
                              key={`${bk}-mastered`}
                              className="masteredItem"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className="text align-center">
                                {student.stats.wordsMastered}/{student.stats.wordsDelivered}
                              </button>
                            </td>
                            <td
                              key={`${bk}-accuracy`}
                              className="accuracyItem"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className={`text align-center ${getAccuracyColor(getAccuracy(student.stats.accuracyPercentage, student.stats.stream))}`}>
                                {getAccuracy(student.stats.accuracyPercentage, student.stats.stream)}
                              </button>
                            </td>
                            <td
                              key={`${bk}-grade`}
                              className="gradeItem"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className="text align-center">
                                {getGradeForStudentClass({ activeStudent: student, activeClass: activeClasses[currentClassBK] })}
                              </button>
                            </td>
                            <td
                              key={`${bk}-stream`}
                              className="streamItem"
                            >
                              <button tabIndex="-1" onClick={(e) => handleStudentSelection(e, bk)} className="text align-left">
                                {
                                  iconedItems && student.stats && student.stats.stream && getStreamsBy(iconedItems, getStreamType(student.stats.stream)) ?
                                    <img className='streamIcon' src={
                                      getStreamsBy(iconedItems, getStreamType(student.stats.stream)).icon
                                    } alt='Stream - ' />
                                    :
                                    <></>
                                }
                                <span className='selectedText'>{student.stats.streamUI}</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                      :
                      <></>
                    :
                    <tr key='empty-class' className="emptyClassTr">
                      <td colSpan={6} className='emptyClassTd'>
                        <center>
                          <div className="emptyClassCard center-vertical">
                            <h1>These are no students in this class</h1>
                            <hr />
                            <p>You can go to <a href={CONFIG.MY_ACCOUNT}>My Account</a> to add classes and invite your students using a class code.</p>
                          </div>
                        </center>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        {open &&
          <TeacherSettingsPopup />
        }
      </div>
    </div>
  );


};