import React, { useState, useRef } from 'react';

import 'app/components/confirm-modal/confirm.modal.scss';
import { KEY_NAMES } from 'app/constants/keys.constant';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';

export const ConfirmModal = (props) => {

  const { header, description, data, confirmCallback } = props;
  /*
  confirmCallback must accept (event, confirmed?, data piped through prop to callback)
  */

  const [optionClicked, setOptionClicked] = useState(false);

  const modalRef = useRef();

  const confirmedHandler = (e) => {
    setOptionClicked(true);
    confirmCallback(e, true, data);
  };

  const cancelHandler = (e) => {
    confirmCallback(e, false, data);
  };

  useOnKeyDown(cancelHandler, KEY_NAMES.ESCAPE);
  useOnClickOutside(modalRef, cancelHandler);

  return (
    <>
      <div className="background-opacity" />
      <div name="confirm" role="dialog" aria-labelledby="confirm-modal-header" aria-describedby="confirm-modal-description" className='confirmModal' id='confirm-modal' ref={modalRef}>
        <h3 name={header} id="confirm-modal-header">
          {header}
        </h3>

        <p id="confirm-modal-description" className="text">{description}</p>

        {!optionClicked ?
          <div className='bottom-button-group'>
            <button
              id='confirm-modal-cancel'
              aria-label={`Cancel: ${header}`}
              onClick={ cancelHandler }
            >Cancel</button>
            <button
              id='confirm-modal-confirmation'
              aria-label={`Confirm: ${header}`}
              onClick={ confirmedHandler }
              aria-controls="confirm-modal-stream-change-text"
            >Confirm</button>
          </div>
          :
          <div>
            <button
              id='confirm-modal-stream-change-placeholder'
              aria-label='Submitting Stream Change'
              disabled
            >
              <div role='alert' id='confirm-modal-stream-change-text'>Submitting</div>
            </button>
          </div>
        }
      </div>
    </>
  );
};