import { takeEvery, put, all } from 'redux-saga/effects';

import ApiClient from 'app/api/api';
import { profileDataActionCreators, profileDataActions } from 'app/store/actions/profile-data.actions';
import promiseHandler from 'app/util/promise-handler';

function* getUserStats() {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getUserStats());

    if (error) throw error;

    const stats = response.data;

    yield put(profileDataActionCreators.getUserStats.success({ stats }));
  } catch (error) {
    console.error(error);
    yield put(
      profileDataActionCreators.getUserStats.failure({
        error,
      })
    );
  }
}

function* getLatestProfile() {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getLatestProfile());

    if (error) throw error;

    const latestProfile = response.data;

    yield put(profileDataActionCreators.getLatestProfile.success({ latestProfile }));
  } catch (error) {
    console.error(error);
    yield put(
      profileDataActionCreators.getLatestProfile.failure({
        error,
      })
    );
  }
}

function* setCklaProfile(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getCklaProfile(action.payload));

    if (error) throw error;

    const profile = response.data;
    yield put(profileDataActionCreators.setCklaProfile.success({
      csUserBusinessKey: profile.csUserBusinessKey,
      standaloneGrade: profile.standaloneGrade,
      streamName: profile.streamName,
    }));
  } catch (error) {
    console.error(error);
    yield put(
      profileDataActionCreators.setCklaProfile.failure({
        error,
      })
    );
  }
}

function* setArProfile(action) {
  try {
    const [error, response] = yield promiseHandler(ApiClient.getArProfile(action.payload.questId, action.payload.teacherGrade));

    if (error) throw error;

    const profile = response.data;

    yield put(profileDataActionCreators.setArProfile.success({
      csUserBusinessKey: profile.csUserBusinessKey,
      standaloneGrade: profile.standaloneGrade,
      streamName: profile.streamName,
      questId: profile.questId,
      arId: profile.arId,
    }));
  } catch (error) {
    console.error(error);
    yield put(
      profileDataActionCreators.setArProfile.failure({
        error,
      })
    );
  }
}

function* getMasteredWords(action) {
  const { page, size } = action.payload;
  try {
    const [error, response] = yield promiseHandler(ApiClient.getMasteredWords(page, size));

    if (error) throw error;

    const masteredWords = response.data;

    yield put(profileDataActionCreators.getMasteredWords.success(masteredWords));
  } catch (error) {
    console.error(error);
    yield put(
      profileDataActionCreators.getMasteredWords.failure({
        error
      })
    );
  }
}

export default function* profileDataSaga() {
  yield all([
    takeEvery(profileDataActions.GET_USER_STATS.REQUEST, getUserStats),
    takeEvery(profileDataActions.GET_LATEST_PROFILE.REQUEST, getLatestProfile),
    takeEvery(profileDataActions.SET_CKLA_PROFILE.REQUEST, setCklaProfile),
    takeEvery(profileDataActions.SET_AR_PROFILE.REQUEST, setArProfile),
    takeEvery(profileDataActions.GET_MASTERED_WORDS.REQUEST, getMasteredWords),
  ]);
}
