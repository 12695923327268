import React, { useRef } from 'react';

import { arrayOf, func, object, string } from 'prop-types';

import 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper.scss';
import { KEY_NAMES } from 'app/constants/keys.constant';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';
import triangle_svg from 'app/shared/images/teacher/arrow_down.svg';

export const DropdownWrapper = (props) => {
  /*
    @param menuOptions list[dict] - dict contains the following dict structure:
    - key - String used as the key and will be passed onClick
    - value - String to show on the menu

    @param selectHandler func - must accept the event and key 

    @param dropdownName string - must be a string of what to display in the button
  */
  
  const { selectHandler, dropdownName, menuOptions, isDisabled, PIIField } = props;

  const dropdownMenu = useRef();
  const dropdownMenuButton = useRef();

  const openDropdown = () => {
    dropdownMenu.current.classList.add('open');
    dropdownMenuButton.current.ariaExpanded = true;
  };

  const closeDropdown = () => {
    dropdownMenu.current.classList.remove('open');
    dropdownMenuButton.current.ariaExpanded = false;
  };

  const isDropdownDisabled = () => {
    return (isDisabled != undefined && typeof isDisabled == 'function' && isDisabled());
  };

  const toggleDropwdown = (e) => {
    e.preventDefault();

    const classList = dropdownMenu.current.classList;

    if (classList.contains('open'))
      closeDropdown();
    else
      openDropdown();
  };

  const selectHandlerWrapper = (e, key) => {
    closeDropdown();

    if(isDropdownDisabled()) {
      return;
    }

    return selectHandler(e, key);
  };

  useOnKeyDown(closeDropdown, KEY_NAMES.ESCAPE);
  useOnClickOutside(dropdownMenuButton, closeDropdown);

  return (
    <div className='dropdown-wrapper'>
      <button 
        aria-label="Select Classes"
        aria-expanded="false"
        className={`selectDropdownButton ${isDropdownDisabled() ? 'disabled' : ''}`}
        id="selectDropdown"
        ref={dropdownMenuButton}
        onClick={toggleDropwdown}
        aria-haspopup="true"
        aria-controls='wrapper-dropdown-menu'
        disabled={isDropdownDisabled()}
      >
        <span
          className="selectName"
          data-dd-action-name={PIIField && `**open-${PIIField}**` }
        >
          {dropdownName}
        </span>
        <span className="carret">
          <img aria-hidden="true" src={triangle_svg} alt='carrot'/>
        </span>
      </button>

      <div
        id="wrapper-dropdown-menu"
        className="dropdown-menu"
        ref={dropdownMenu}
        role="menu"
      >
        {
          menuOptions.map(menuOption => {
            return (
              <button
                key={menuOption.key}
                onClick={(e) => selectHandlerWrapper(e, menuOption.key)}
                value={menuOption.key}
              >
                <span
                  data-dd-action-name={PIIField && `**option-${PIIField}**` }
                >
                  {menuOption.value}
                </span>
              </button>
            );
          })
        }
      </div>
    </div>
  );
};

DropdownWrapper.propTypes = {
  selectHandler: func,
  dropdownName: string,
  menuOptions: arrayOf(object),
};
