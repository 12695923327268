export const ENVIRONMENTS = {
  PROD: 'prod',
  STAGE: 'staging',
  CI: 'ci',
  LOCAL: 'local'
};

function getEnvironment() {
  const hostName = window.location.hostname;

  if (hostName.includes(ENVIRONMENTS.LOCAL)) {
    return ENVIRONMENTS.LOCAL;
  } else if (hostName.includes(ENVIRONMENTS.CI)) {
    return ENVIRONMENTS.CI;
  } else if (hostName.includes(ENVIRONMENTS.STAGE)) {
    return ENVIRONMENTS.STAGE;
  }

  return ENVIRONMENTS.PROD;
}

export const isDevEnvironment = () => {
  return ['ci', 'local'].includes(getEnvironment());
};

var ExternalURLs = {
  prod: {
    MY_AMPLIFY: 'https://my.amplify.com',
    API_GATEWAY: 'https://api.learning.amplify.com'
  },
  staging: {
    MY_AMPLIFY: 'https://my-staging.stage.learning.amplify.com',
    API_GATEWAY: 'https://api-staging.stage.learning.amplify.com'
  },
  devci: {
    MY_AMPLIFY: 'https://my-devci.poc.learning.amplify.com',
    API_GATEWAY: 'https://api-devci.poc.learning.amplify.com'
  },
  local: {
    MY_AMPLIFY: 'https://my-devci.poc.learning.amplify.com',
    API_GATEWAY: 'https://api-devci.poc.learning.amplify.com'
  }
};

const Config = {
  prod: {
    PUBLIC_URL: 'https://vocabulary.amplify.com/',
    AUTH_URL: ExternalURLs.prod.MY_AMPLIFY + '/auth',
    RETAIL_URL: ExternalURLs.prod.API_GATEWAY + '/retail',
    THRID_PARTY_SECRETS_ROOT: ExternalURLs.prod.API_GATEWAY + '/third-party-secrets',
    VOCAB_DIFFERENTIATION: ExternalURLs.prod.API_GATEWAY + '/vocab-diff-alone',
    SELF_ENROLLMENT_ROOT: ExternalURLs.prod.API_GATEWAY + '/onboarding-service',
    PDF_S3_BUCKET: 'http://s3.amazonaws.com/amplify-vocab-pdf/',
    INTERCOM_APPLICATION_ID: 'l71howyu',
    NOT_LICENSED_PAGE_URL: 'https://my.amplify.com/help/en/articles/3366059-ckla-and-vocabulary',
    READING_API_URL: 'https://aquaapi.amplify.com',
    READING: 'https://reading.amplify.com',
    MY_ACCOUNT: ExternalURLs.prod.MY_AMPLIFY + '/account/classes',
    CUSTOMER_SUBDOMAIN_PARENT: '.reading.amplify.com',
  },
  staging: {
    PUBLIC_URL: '',
    AUTH_URL: ExternalURLs.staging.MY_AMPLIFY + '/auth',
    RETAIL_URL: ExternalURLs.staging.API_GATEWAY + '/retail',
    THRID_PARTY_SECRETS_ROOT: ExternalURLs.staging.API_GATEWAY + '/third-party-secrets',
    VOCAB_DIFFERENTIATION: ExternalURLs.staging.API_GATEWAY + '/vocab-diff-alone',
    SELF_ENROLLMENT_ROOT: ExternalURLs.staging.API_GATEWAY + '/onboarding-service',
    PDF_S3_BUCKET: 'http://s3.amazonaws.com/amplify-vocab-pdf/',
    INTERCOM_APPLICATION_ID: 'xjse019y',
    NOT_LICENSED_PAGE_URL: 'https://my.amplify.com/help/en/articles/3366059-ckla-and-vocabulary',
    READING_API_URL: 'https://aquaapi.ar.staging.amplify.com',
    READING: 'https://reading.ar.staging.amplify.com',
    MY_ACCOUNT: ExternalURLs.staging.MY_AMPLIFY + '/account/classes',
    CUSTOMER_SUBDOMAIN_PARENT: '.reading.ar.staging.amplify.com',
  },
  ci: {
    PUBLIC_URL: 'https://vocabulary-devci.poc.learning.amplify.com/',
    AUTH_URL: ExternalURLs.devci.MY_AMPLIFY + '/auth',
    RETAIL_URL: ExternalURLs.devci.API_GATEWAY + '/retail',
    THRID_PARTY_SECRETS_ROOT: ExternalURLs.devci.API_GATEWAY + '/third-party-secrets',
    VOCAB_DIFFERENTIATION: ExternalURLs.devci.API_GATEWAY + '/vocab-diff-alone',
    SELF_ENROLLMENT_ROOT: ExternalURLs.devci.API_GATEWAY + '/onboarding-service',
    PDF_S3_BUCKET: 'http://s3.amazonaws.com/amplify-vocab-pdf/',
    INTERCOM_APPLICATION_ID: 'xjse019y',
    NOT_LICENSED_PAGE_URL: 'https://my.amplify.com/help/en/articles/3366059-ckla-and-vocabulary',
    READING_API_URL: 'https://aquaapi.ar.ci.amplify.com',
    READING: 'https://reading.ar.ci.amplify.com',
    MY_ACCOUNT: ExternalURLs.devci.MY_AMPLIFY + '/account/classes',
    CUSTOMER_SUBDOMAIN_PARENT: '.reading.ar.ci.amplify.com',
  },
  local: {
    PUBLIC_URL: 'http://local.amplify.com:3000/',
    AUTH_URL: ExternalURLs.local.MY_AMPLIFY + '/auth',
    RETAIL_URL: ExternalURLs.devci.API_GATEWAY + '/retail',
    THRID_PARTY_SECRETS_ROOT: ExternalURLs.local.API_GATEWAY + '/third-party-secrets',
    VOCAB_DIFFERENTIATION: ExternalURLs.local.API_GATEWAY + '/vocab-diff-alone',
    // VOCAB_DIFFERENTIATION: 'http://local.amplify.com:9090',
    SELF_ENROLLMENT_ROOT: ExternalURLs.local.API_GATEWAY + '/onboarding-service',
    PDF_S3_BUCKET: 'http://s3.amazonaws.com/amplify-vocab-pdf/',
    INTERCOM_APPLICATION_ID: 'xjse019y',
    NOT_LICENSED_PAGE_URL: 'https://my.amplify.com/help/en/articles/3366059-ckla-and-vocabulary',
    READING_API_URL: 'https://aquaapi.ar.ci.amplify.com',
    READING: 'https://reading.ar.ci.amplify.com',
    MY_ACCOUNT: ExternalURLs.local.MY_AMPLIFY + '/account/classes',
    CUSTOMER_SUBDOMAIN_PARENT: '.reading.ar.ci.amplify.com',
  }
};

export const ENVIRONMENT = getEnvironment();
export const CONFIG = Config[ENVIRONMENT];
