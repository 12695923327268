export const URL_PARAM_KEYS = {
  GRADE: 'grade',
  UNIT: 'unitTitle',
  LESSON: 'lessonNumber',
  KEY_TEXT: 'keyTextTitle',
  QUEST_ID: 'questId',
  EXTERNAL_SOURCE: 'externalSource',
  KC_IDP_HINT: 'kc_idp_hint',
  TEACHER_GRADE: 'teacherGrade',
};
