import { useDispatch } from 'react-redux';

import { isDevEnvironment } from 'app/config';


const SynAntDevTools = (props) => {

  const {
    sendActivityObservation,
    onFinished,
    navigate,
    currentActivity,
    removeCurrentActivity
  } = props;

  const dispatch = useDispatch();

  const sendScore = (score) => () => {
    sendActivityObservation(score);
    removeCurrentActivity(dispatch);
    onFinished(navigate, dispatch);
  };
  
  return (
    isDevEnvironment() ?
      <div className='dev-wrapper'>
        { Array(currentActivity.correctWords ? currentActivity.correctWords.length+1 : 0).fill(null).map((u, i) => {
          return <button
            key={i}
            className='devci-button'
            onClick={sendScore(parseFloat(i)/parseFloat(currentActivity.correctWords.length))}
          >
            {`DEV: SCORE ${(parseFloat(i)/parseFloat(currentActivity.correctWords.length)).toFixed(2)}`}
          </button>;
        })
        }
      </div>
      :
      <></>
  );
};

export default SynAntDevTools;
