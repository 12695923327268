import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { KEY_NAMES } from 'app/constants/keys.constant';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import useOnKeyDown from 'app/hooks/use-on-key-down.hook';
import iconBack from 'app/shared/images/icon-back.svg';
import iconClose from 'app/shared/images/icon-close.svg';
import iconLogout from 'app/shared/images/icon-logout.svg';
import 'app/components/session-timeout/session-modal.component.scss';

export const SessionModal = ({ onLogout, onCancel }) => {
  const modalRef = useRef(null);
  const goBackButtonRef = useRef(null);

  const handleLogout = () => {
    onLogout();
  };

  const handleCancel = () => {
    onCancel();
  };

  useOnClickOutside(modalRef, handleCancel);
  useOnKeyDown(handleCancel, KEY_NAMES.ESCAPE);

  useEffect(() => {
    goBackButtonRef.current.focus();
  }, [goBackButtonRef.current]);


  return (
    <div className="session-modal-wrapper" ref={modalRef}>
      <div className="session-modal" aria-labelledby="session-modal-header">
        <h1 id="session-modal-header" className="session-modal-header">Are you still there?</h1>
        <p className="session-modal-prompt">Wow, it&apos;s been almost an hour!<br/>Want to log off and take a break?</p>

        <button
          className="button back-button"
          ref={goBackButtonRef}
          onClick={handleCancel}
          aria-label="Keep playing Amplify Reading"
        >
          <img aria-hidden="true" className="icon" src={iconBack} alt="" />
            Go back
        </button>

        <button className="button logout-button" onClick={handleLogout}>
          <img aria-hidden="true" className="icon" src={iconLogout} alt="" />
            Log out
        </button>

        <button className="close-button" onClick={handleCancel} aria-label="Close dialog">
          <img aria-hidden="true" src={iconClose} alt="" />
        </button>
      </div>
    </div>
  );
};

SessionModal.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
