import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Header } from 'app/components/header/header.component';
import { SubNav } from 'app/components/header/subnav/subnav.component';
import { TabList } from 'app/components/tabs/tab-list.component';
import { Tab } from 'app/components/tabs/tab.component';
import { TAB_KEYS } from 'app/constants/tabs.constants';
import triangle_svg from 'app/shared/images/teacher/arrow_down.svg';
import 'app/components/header/reporting-page-header/reporting-page-header.scss';
import { handleBackButtonWrapper } from 'app/util/handlers/classes';


export const ReportingPageHeader = () => {
  const { displayHeaderBackButton } = useSelector(state => state.userSettings);
  const { activeTabs } = useSelector(state => state.tabState);
  const areInStudentsTab = activeTabs && activeTabs[TAB_KEYS.REPORTING_PAGE] == 'students';

  const dispatch = useDispatch();

  return (
    <Header>
      <SubNav classNames="reporting-page-header">
        <>
          {
            displayHeaderBackButton && areInStudentsTab &&
                <button onClick={handleBackButtonWrapper(dispatch)} id="reporting-back-ancor" aria-label='Go Back to Last Page'>
                  <img src={triangle_svg} className='reporting-back-button' alt="Back" aria-hidden="true"/>
                </button>
          }
          <TabList className="nav nav-tabs">
            <Tab value="students"  className='nav-item-style'>
              Students
            </Tab>
            <Tab value="words"  className='nav-item-style'>
              Words
            </Tab>
          </TabList>
        </>
      </SubNav>
    </Header>
  );
};
