const TOGGLE_USER_SOUND = '[User Settings] TOGGLE_USER_SOUND';
const SET_USER_DATE = '[User Settings] SET_USER_DATE';
const SET_DISPLAY_BACK_BUTTON = '[TXP User Settings] DISPLAY_BACK_BUTTON';
const SET_DISPLAY_GEAR_ICON = '[TXP User Settings] SET_DISPLAY_GEAR_ICON';
const TOGGLE_SETTINGS = '[TXP User Settings] TOGGLE_SETTINGS';

export const userSettingsActions = {
  TOGGLE_USER_SOUND,
  SET_USER_DATE,
  SET_DISPLAY_BACK_BUTTON,
  SET_DISPLAY_GEAR_ICON,
  TOGGLE_SETTINGS,
};

export const toggleSoundSetting = () => {
  return {
    type: TOGGLE_USER_SOUND,
  };
};

export const changeUserDate = (payload) => {
  return {
    type: SET_USER_DATE,
    payload: {
      startDate: payload.startDate,
      endDate: payload.endDate,
    },
  };
};

export const setDisplayBackButton = (payload) => {
  return {
    type: SET_DISPLAY_BACK_BUTTON,
    payload: {
      buttonState: payload.buttonState,
    },
  };
};

export const setGearIcon = (payload) => {
  return {
    type: SET_DISPLAY_GEAR_ICON,
    payload: {
      displayed: payload.displayed,
    },
  };
};

export const toggleSettings = () => {
  return {
    type: TOGGLE_SETTINGS,
  };
};
