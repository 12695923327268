import jstz from 'jstz';

export const getCurrentTimezoneName = () => {
  return jstz.determine().name();
};

export const utcTimeToDays = (dateTime) => {
  // Converts Date.getTime to total days
  return dateTime / (1000 * 3600 * 24);
};

export const offsetDateDays = (date, days) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
};

export const offsetDateMonths = (date, months) => {
  return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
};

export const offsetDateYears = (date, years) => {
  return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
};

export const isTimeValid = (startDate, endDate) => {
  return endDate > startDate;
};

export const shiftWindowForward = (startDate, endDate, interval) => {
  // If interval is none, use the date difference in terms of days
  // @param dateRange is list [startDate, endDate]
  // @return [new start date shifted, new end date shifted]
  if (!interval) 
    interval = utcTimeToDays(endDate - startDate);

  let newStart = offsetDateDays(startDate, interval);
  let newEnd = offsetDateDays(endDate, interval);

  const now = new Date();

  if (newEnd > now){
    const offsetNewStart = utcTimeToDays(newEnd - now);

    newEnd = now;
    newStart = offsetDateDays(newEnd, -offsetNewStart);
  }
  
  return {
    startDate: newStart,
    endDate: newEnd,
  };
};

export const shiftWindowBack = (startDate, endDate, interval) => {
  // If interval is none, use the date difference in terms of days
  // @param dateRange is list [startDate, endDate]
  // @return [new start date shifted, new end date shifted]
  if (!interval) 
    interval = utcTimeToDays(endDate - startDate);
  
  return shiftWindowForward(startDate, endDate, -interval);
};
