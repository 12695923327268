import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const KeishaIncorrect = () => {

  useEffect(() => {
    const tl = gsap.timeline()
      .set('#keisha-sad', { y: 100, x: 100 })
      .to('#keisha-sad', { duration: 0.5, opacity: 1, y: 0, x: 0, delay: 5, ease: 'sine.out' })
      .to('#sad-torso, #sad-neck, #sad-arm', { duration: 0.75, y: 15, yoyo: true, repeat: 3, ease: 'sine.inOut' })
      .add(gsap.to('#sad-head', { duration: 0.75, y: 15, yoyo: true, repeat: 3, ease: 'sine.inOut' }), 5.6)
      .add(gsap.to('#sad-pupils', { duration: 0.75, y: -3, yoyo: true, repeat: 3, ease: 'sine.inOut' }), 5.7)
      .to('#sad-head', { duration: 0.4, transformOrigin: '50% 95%', rotate: -3, y: 20, ease: 'sine.inOut' })
      .add(gsap.to('#sad-torso, #sad-neck, #sad-arm', { duration: 0.4, y: 15, ease: 'sine.inOut' }), 8.6)
      .add(gsap.to('#sad-pupils', { duration: 0.4, y: 8, x: 14, ease: 'sine.inOut' }), 8.6)
      .add(gsap.to('#sad-brows', { duration: 0.4, y: 14, ease: 'sine.inOut' }), 8.6)
      .add(gsap.to('#mouth-straight', { duration: 0.4, attr: { d: 'M247.65,351.53C252,321,283,313,306.79,330.73' }, ease: 'sine.inOut' }), 8.6);

    tl.play(0);

    return () => {
      if (tl) tl.kill();
    };
  });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="keisha-rxn" viewBox="0 0 400 680" width="400" height="680">
      <g id="keisha-sad" opacity="0">
        <g id="sad-arm">
          <polyline points="39.51 695.45 153.91 467.5 234.31 505.27 145.29 682.66" fill="#ddf2ed"/>
          <polyline points="137.6 683.54 122.2 530.68 153.91 467.5 234.31 505.27 145.29 682.66" fill="#d2e3df"/>
          <circle cx="186.06" cy="498.66" r="44.46" fill="#d2e3df"/>
        </g>
        <g id="sad-neck">
          <path d="M314.41,446.42l-5.05-31.62a25.59,25.59,0,0,0-29.31-21.24l-11.95,1.91a25.6,25.6,0,0,0-21.25,29.31l5.05,31.62Z" fill="#ba673b"/>
          <path d="M246.54,422.79l-1.75-11c-2.19-13.73,7.55-26.69,21.75-29l11.08-1.77c14.2-2.26,27.49,7,29.68,20.75l1.92,12.88s-18.35,8.14-30.87,10.14C266.62,426.75,246.54,422.79,246.54,422.79Z" fill="#2e1926"/>
          <path d="M314.06,445.11l-62.36,9.58s10.8,32.94,35,29.07C312.55,479.64,314.06,445.11,314.06,445.11Z" fill="#ba673b"/>
        </g>
        <g id="sad-torso">
          <g>
            <g>
              <path d="M242.4,436.28l92.47-21.39c27.15-6.28,51.62,3.72,65.13,22.68v11L193,496.46C192.36,469.25,211.05,443.53,242.4,436.28Z" fill="#7058a8"/>
              <path d="M400,511.53v62.92l-189,43.7c-3.39-22.26-6.56-43.08-9.25-60.77Z" fill="#7058a8"/>
              <path d="M400,680s-154,.09-179.74,0v-1L400,637.4Z" fill="#7058a8"/>
              <path d="M400,637.4,220.26,679c-3.11-20.58-6.25-41.24-9.22-60.82l189-43.7Z" fill="#9193e8"/>
              <path d="M193.45,502.61a58,58,0,0,1-.45-6.15l207-47.88v62.95L201.79,557.38C196.87,525.11,193.54,503.32,193.45,502.61Z" fill="#9193e8"/>
            </g>
            <polygon points="345.31 412.72 400 630.35 400 400.07 345.31 412.72" fill="#ddf2ed"/>
            <polygon points="400 631.91 344.55 413.23 320.33 418.83 382.45 680 400 680 400 631.91" opacity="0.2"/>
          </g>
          <polygon points="232.56 438.79 283.56 680 183.23 680 151.04 457.65 232.56 438.79" fill="#ddf2ed"/>
        </g>
        <g id="sad-head">
          <path d="M410.8,317.27C442.35,235.6,467,57.7,295.93,55.28c-108.64-1.53,42,332.09,42,332.09S386.22,380.91,410.8,317.27Z" fill="#2e1926"/>
          <ellipse cx="391.93" cy="259.94" rx="38.44" ry="39.77" transform="translate(126.48 648.16) rotate(-89.19)" fill="#ba673b"/>
          <path d="M136.79,167.68c3-24.84,21.34-87.82,130-86.29,111.27,1.57,127.25,59.5,126.68,99.82H393l-1.49,105.34C390.58,355.19,332.21,410,261.17,409h0c-71-1-127.83-57.47-126.86-126.13l1.63-115.23Z" fill="#ba673b"/>
          <path d="M372.58,235.9c.7-49.62-18.84-112.09-130.12-113.66-54.48-.77-86.25,14.67-104.77,33.4l.1-7,.85,0c3-24.85,21.34-87.83,130-86.29C379.93,64,395.9,121.91,395.33,162.22h-.44l-.82,58.15Z" fill="#2e1926"/>
          <g id="eyeballs">
            <ellipse cx="303.62" cy="272.11" rx="32.61" ry="38.41" transform="translate(27.24 571.86) rotate(-89.19)" fill="#fff"/>
            <ellipse cx="189.79" cy="270.5" rx="32.61" ry="38.41" transform="translate(-83.37 456.45) rotate(-89.19)" fill="#fff"/>
          </g>
          <g id="sad-pupils">
            <circle cx="289.6" cy="273.71" r="24.46" fill="#2e1926"/>
            <circle cx="176.77" cy="271.61" r="24.46" fill="#2e1926"/>
          </g>
          <g id="sad-brows">
            <path d="M323.72,212.73c-9.22,1.18-18.27-.66-26.71-4.14s-16.11-8.64-21.83-16L270,194.79c1.16,5.75,4.48,10.83,8.52,14.87a44.6,44.6,0,0,0,30.87,12.8c5.71,0,11.66-1.24,16.54-4.49Z" fill="#2e1926"/>
            <path d="M211.45,191c-5.45,7.54-13,12.87-21.29,16.66s-17.31,5.84-26.59,5l-2,5.31a29.87,29.87,0,0,0,16.67,4,44.51,44.51,0,0,0,30.45-13.77c3.91-4.16,7.07-9.34,8-15.14Z" fill="#2e1926"/>
          </g>
          <path d="M372.41,229.56c-1.37,3.55-1,7.51-.43,10.92s1.68,6.6,2.48,9.43a26.77,26.77,0,0,1,1.36,7.1,6,6,0,0,1-.34,2.08,6.77,6.77,0,0,1-1,1.87c-2.9,4.42-7,9.16-10.47,15.47-1.62,3.18-3.31,7-3.19,11.55a12.43,12.43,0,0,0,1.92,6.35,11.65,11.65,0,0,0,4.31,3.94l2.39-2.08a11.94,11.94,0,0,1-.6-4.07,7.18,7.18,0,0,1,.95-3,28.66,28.66,0,0,1,4.54-5.49c3.86-4,8.71-8.2,12.82-14.28a21,21,0,0,0,2.93-6.68,19.85,19.85,0,0,0,.22-7.3A28.79,28.79,0,0,0,386,244.6c-3.66-5.85-7.79-9.55-10.37-15Z" fill="#2e1926"/>
          <path d="M246.15,55.9S393.45,29.47,409.85,158L267.49,87.09,216,85.57l-.49-21.39Z" fill="#2e1926"/>
          <ellipse cx="277.58" cy="24.64" rx="69.54" ry="94.08" transform="translate(249.02 301.85) rotate(-89.19)" fill="#2e1926"/>
          <path id="mouth-straight" d="M237.66,340.1C255,332,279,323,296.8,319.31" fill="none" stroke="#680900" strokeMiterlimit="10" strokeWidth="4"/>
          <g id="sad-goggles">
            <polygon points="131.79 165.48 440.17 165.94 440.67 130.41 132.34 126.05 131.79 165.48" fill="#7769c8"/>
            <polygon points="295.13 167.79 386.18 169.07 379.23 129.54 295.68 128.36 295.13 167.79" fill="#504596"/>
            <path d="M131.79,165l156,2.2s3.23-14.63,3.2-19.53-3.48-19.9-3.48-19.9l-155.2-2.2Z" fill="#504596"/>
            <circle cx="315.58" cy="148.73" r="48.35" fill="#7769c8"/>
            <circle cx="315.59" cy="148.35" r="24.81" fill="#ffae00"/>
            <circle cx="191.67" cy="146.98" r="48.35" fill="#7769c8"/>
            <circle cx="190.63" cy="146.58" r="24.81" fill="#ffae00"/>
            <rect x="188.35" y="119.92" width="10" height="47.8" transform="translate(-47.6 146.99) rotate(-37.4)" fill="#ffdd80"/>
            <rect x="310.22" y="125.46" width="10" height="47.39" transform="translate(-16.59 256.45) rotate(-43.24)" fill="#ffdd80"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
