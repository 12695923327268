import { API_ACTION_TYPES } from 'app/constants/api.constants';
import { createActions, createRequestTypes } from 'app/store/actions/base-request.action';

const GET_USER_DATA = createRequestTypes(API_ACTION_TYPES.GET_USER_DATA);
const GET_INTERCOM_HASH = createRequestTypes(API_ACTION_TYPES.GET_INTERCOM_HASH);
const GET_LICENSES = createRequestTypes(API_ACTION_TYPES.GET_LICENSES);

// Fetch based actions
export const userDataActions = {
  GET_USER_DATA,
  GET_INTERCOM_HASH,
  GET_LICENSES,
};

export const userDataActionCreators = {
  getUserData: { ...createActions(GET_USER_DATA) },
  getIntercomHash: { ...createActions(GET_INTERCOM_HASH) },
  getLicenses: { ...createActions(GET_LICENSES) },
};
