
export const TAB_KEYS = {
  TEACHER_SETTIGNS: 'TEACHER_SETTINGS_TAB',
  HOME_PAGE: 'HOME_PAGE_TAB',
  REPORTING_PAGE: 'REPORTING_PAGE_TAB'
};

export const TAB_VALUES = {
  REPORTING_PAGE: {
    WORDS: 'words',
    STUDENTS: 'students',
  },
  TEACHER_SETTINGS: {
    DISABLED_TAB: 'disabledTab',
    LIMITED_TAB: 'limitedTab',
  },
  HOME_PAGE: {
    HOME: 'home',
    STATS: 'stats',
  },
};
