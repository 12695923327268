import React from 'react';

export const WordDetail = ({ infoLabel, infoValue }) => {
  const lowerIV = infoLabel.replace(/\s/g, '-').toLowerCase();
  return (
    <>
      {
        infoValue ? 
          <React.Fragment>
            <div id={`modal-label-${lowerIV}`} className="infoLabel">{infoLabel}</div>
            <div id={`modal-value-${lowerIV}`} className="infoValue">{infoValue}</div>
          </React.Fragment>
          :
          <></>
      }
    </>
  );
};
