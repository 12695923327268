import React, { useState } from 'react';

import 'app/components/stats/home-stats/home-stats.scss';
import clsx from 'clsx';
import { string } from 'prop-types';

import { NextIcon } from 'app/components/icons/next-icon/next-icon.component';
import { Loader } from 'app/components/loader/loader';
import { ProgressColumnCharts } from 'app/components/stats/progress-column-charts/progress-column-charts.component';
import { ProgressPieCharts } from 'app/components/stats/progress-pie-charts/progress-pie-charts.component';
import { getNextItemCycled, getPrevItemCycled } from 'app/util/array';
import { getStatsPerDay, getStatsPerMonth, getStatsPerWeek } from 'app/util/stats';

export const HomeStats = props => {
  const { classNames, stats } = props;
  const chartTypes = [
    {
      type: 'progress',
      label: 'Progress',
      component: <ProgressPieCharts stats={stats}/>,
    },
    {
      type: 'day',
      label: 'Day',
      component: <ProgressColumnCharts statsToDisplay={getStatsPerDay(stats)}/>,
    },
    {
      type: 'week',
      label: 'Week',
      component: <ProgressColumnCharts statsToDisplay={getStatsPerWeek(stats)}/>,
    },
    {
      type: 'month',
      label: 'Month',
      component: <ProgressColumnCharts statsToDisplay={getStatsPerMonth(stats)}/>,
    },
  ];

  const [currentChart, setCurrentChart] = useState(chartTypes[0]);

  return (
    <div className={clsx('charts', classNames)}>
      <div className="chart-switcher">
        <div className="left-arrow">
          <button onClick={() => setCurrentChart(getPrevItemCycled(chartTypes, currentChart))}>
            <NextIcon/>
          </button>
        </div>
        {stats ?
          chartTypes.map(chartType => {
            return <div className="chart-type" key={chartType.type}>
              <button
                className={clsx(chartType.type === currentChart.type ? 'active' : '')}
                onClick={() => setCurrentChart(chartType)}
              >
                {chartType.label}
              </button>
            </div>;
          })
          :
          <Loader />
        }
        <div className="right-arrow">
          <button onClick={() => setCurrentChart(getNextItemCycled(chartTypes, currentChart))}>
            <NextIcon/>
          </button>
        </div>
      </div>
      <div>{currentChart.component}</div>
    </div>
  );
};

HomeStats.propTypes = {
  classNames: string,
};
