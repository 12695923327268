import React, { useEffect, useState } from 'react';

import 'app/pages/reporting/reporting.page.scss';

import { useDispatch , useSelector } from 'react-redux';

import { ClassesPage } from 'app/components/reporting/classes/classes.page';
import { StudentPage } from 'app/components/reporting/student/student.page';
import { StudentsPage } from 'app/components/reporting/students/students.page';
import { TabPanel } from 'app/components/tabs/tab-panel.component';
import { TabPanels } from 'app/components/tabs/tab-panels.component';
import { WordsPage } from 'app/pages/words/words.page';
import { classesDataActionCreators } from 'app/store/actions/classes-data.actions';
import { profileDataActionCreators } from 'app/store/actions/profile-data.actions';
import { setDisplayBackButton, setGearIcon } from 'app/store/actions/user-settings.actions';
import { isIdle } from 'app/store/reducers/api-status.reducer';
import { handleClassSelectionWrapper, handleStudentSelectionWrapper } from 'app/util/handlers/classes';

export const ReportingPage = () => {

  const dispatch = useDispatch();

  const {
    getLicensesStatus,
    getStandaloneClassesStatus,
    getCDAClassesStatus
  } = useSelector(state => state.apiReducer);

  const { latestProfile } = useSelector(state => state.profileData);

  const { kcInfo, licenses } = useSelector(state => state.userData);
  const { isTeacher, isStudent } = kcInfo;

  const { currentClassBK, currentStudentBK } = useSelector(state => state.classesData);

  const { getLatestProfileStatus } = useSelector(state => state.apiReducer);

  useEffect(() => {

    if(isIdle(getLatestProfileStatus)) {
      dispatch(profileDataActionCreators.getLatestProfile.request());
    }

  }, [getLatestProfileStatus, latestProfile]);

  useEffect(() => {
    if (licenses != null) {
      if (isTeacher) {
        // Figure out the classes we want to get
        if (licenses.cda && isIdle(getCDAClassesStatus)) {
          dispatch(classesDataActionCreators.getCDAClassesSections.request());
        } else if (!licenses.cda && isIdle(getStandaloneClassesStatus)) {
          dispatch(classesDataActionCreators.getStandaloneClassesSections.request());
        }
      }
    }
  }, [getLicensesStatus,
    getStandaloneClassesStatus, getCDAClassesStatus,
    isTeacher, isStudent, licenses]);

  const [page, setPage] = useState(<></>);

  useEffect(() => {

    if(currentClassBK) {
      if (currentStudentBK) {
        dispatch(setDisplayBackButton({ buttonState: true }));
        dispatch(setGearIcon({ displayed: false }));
        setPage(
          <StudentPage
            handleStudentSelection = {handleStudentSelectionWrapper(dispatch)}
          />
        );
      } else {
        dispatch(setDisplayBackButton({ buttonState: true }));
        dispatch(setGearIcon({ displayed: true }));
        setPage(
          <StudentsPage 
            handleClassSelection = {handleClassSelectionWrapper(dispatch)}
            handleStudentSelection = {handleStudentSelectionWrapper(dispatch)}
          />
        );
      }
    } else {
      dispatch(setDisplayBackButton({ buttonState: false }));
      dispatch(setGearIcon({ displayed: false }));
      setPage(
        <ClassesPage
          handleClassSelection = {handleClassSelectionWrapper(dispatch)}
        />
      );
    }
  }, [currentClassBK, currentStudentBK]);

  return (
    <>
      <TabPanels>
        <TabPanel value="students">
          {page}
        </TabPanel>
        <TabPanel value="words">
          <WordsPage />
        </TabPanel>
      </TabPanels>
    </>
  );

};


export default ReportingPage;
