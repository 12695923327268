import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const Ooze = () => {

  useEffect(() => {
    const oozeCover = gsap.timeline({ paused: true })
      .fromTo('#slime-drip10', { y: -750 }, { duration: 1, y: 50, ease: 'sine.Out' })
      .add(gsap.fromTo('#slime-drip5', { y: -750 }, { duration: 1.25, y: 50, ease: 'sine.Out' }), 0.1)
      .add(gsap.fromTo('#slime-drip2', { y: -750 }, { duration: 0.75, y: 50, ease: 'sine.Out' }), 0.1)
      .add(gsap.fromTo('#slime-drip4', { y: -750 }, { duration: 1, y: 50, ease: 'sine.Out' }), 0.15)
      .add(gsap.fromTo('#slime-drip9', { y: -750 }, { duration: 0.75, y: 50, ease: 'sine.Out' }), 0.37)
      .add(gsap.fromTo('#slime-drip3', { y: -750 }, { duration: 0.75, y: 50, ease: 'sine.Out' }), 0.4)
      .add(gsap.fromTo('#slime-drip7', { y: -750 }, { duration: 1, y: 50, ease: 'sine.Out' }), 0.5)
      .add(gsap.fromTo('#slime-drip1', { y: -750 }, { duration: 0.62, y: 50, ease: 'sine.Out' }), 0.7)
      .add(gsap.fromTo('#slime-drip6', { y: -750 }, { duration: 0.75, y: 50, ease: 'sine.Out' }), 0.75)
      .add(gsap.fromTo('#slime-drip8', { y: -750 }, { duration: 0.5, y: 50, ease: 'sine.Out' }), 1);

    const oozeReveal = gsap.timeline({ paused: true })
      .to('#slimey-drip10', { duration: 1, y: 760, ease: 'sine.Out' })
      .add(gsap.to('#slimey-drip5', { duration: 1.25, y: 760, ease: 'sine.Out' }), 0)
      .add(gsap.to('#slimey-drip2', { duration: 0.75, y: 760, ease: 'sine.Out' }), 0.1)
      .add(gsap.to('#slimey-drip4', { duration: 1, y: 760, ease: 'sine.Out' }), 0.15)
      .add(gsap.to('#slimey-drip9', { duration: 0.75, y: 760, ease: 'sine.Out' }), 0.37)
      .add(gsap.to('#slimey-drip3', { duration: 0.75, y: 760, ease: 'sine.Out' }), 0.4)
      .add(gsap.to('#slimey-drip7', { duration: 1, y: 760, ease: 'sine.Out' }), 0.5)
      .add(gsap.to('#slimey-drip1', { duration: 0.62, y: 760, ease: 'sine.Out' }), 0.7)
      .add(gsap.to('#slimey-drip6', { duration: 0.75, y: 760, ease: 'sine.Out' }), 0.75)
      .add(gsap.to('#slimey-drip8', { duration: 0.37, y: 760, ease: 'sine.Out' }), 1);

    const tl = gsap.timeline()
      .to('#stage1', { duration: 0.5, opacity: 1 })
      .add(oozeCover.play(0))
      .to('#stage2', { duration: 0.1, opacity: 1 })
      .to('#stage1', { duration: 0.1, opacity: 0 })
      .add(oozeReveal.play(0));

    tl.play(0);

    return () => {
      if (tl) tl.kill();
      if (oozeCover) oozeCover.kill();
      if (oozeReveal) oozeReveal.kill();
    };
  });

  return (
    <svg id="oozing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 680">
      <g id="stage1" opacity="0">
        <g id="slime-drip3" transform="translate(0 -750)">
          <rect x="201" y="-106" width="106" height="842" rx="53" transform="translate(508 630) rotate(-180)" fill="#41753d"/>
          <rect x="200" y="-116" width="108" height="842" rx="54" transform="translate(508 610) rotate(-180)" fill="#77ff73"/>
          <path d="M259.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C276.13,709.61,268.54,712.35,259.56,712.35Z" fill="#fff"/>
          <path d="M290,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,290,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip4" transform="translate(0 -750)">
          <rect x="308" y="-106" width="106" height="842" rx="53" transform="translate(722 630) rotate(-180)" fill="#41753d"/>
          <rect x="307" y="-116" width="108" height="842" rx="54" transform="translate(722 610) rotate(-180)" fill="#77ff73"/>
          <path d="M366.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C383.13,709.61,375.54,712.35,366.56,712.35Z" fill="#fff"/>
          <path d="M397,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,397,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip5" transform="translate(0 -750)">
          <rect x="415" y="-106" width="106" height="842" rx="53" transform="translate(936 630) rotate(180)" fill="#41753d"/>
          <rect x="414" y="-116" width="108" height="842" rx="54" transform="translate(936 610) rotate(180)" fill="#77ff73"/>
          <path d="M473.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C490.13,709.61,482.54,712.35,473.56,712.35Z" fill="#fff"/>
          <path d="M504,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,504,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip6" transform="translate(0 -750)">
          <rect x="522" y="-106" width="106" height="842" rx="53" transform="translate(1150 630) rotate(180)" fill="#41753d"/>
          <rect x="521" y="-116" width="108" height="842" rx="54" transform="translate(1150 610) rotate(180)" fill="#77ff73"/>
          <path d="M580.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C597.13,709.61,589.54,712.35,580.56,712.35Z" fill="#fff"/>
          <path d="M611,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,611,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip7" transform="translate(0 -750)">
          <rect x="629" y="-106" width="106" height="842" rx="53" transform="translate(1364 630) rotate(-180)" fill="#41753d"/>
          <rect x="628" y="-116" width="108" height="842" rx="54" transform="translate(1364 610) rotate(-180)" fill="#77ff73"/>
          <path d="M687.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C704.13,709.61,696.54,712.35,687.56,712.35Z" fill="#fff"/>
          <path d="M718,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,718,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip8" transform="translate(0 -750)">
          <rect x="736" y="-106" width="106" height="842" rx="53" transform="translate(1578 630) rotate(-180)" fill="#41753d"/>
          <rect x="735" y="-116" width="108" height="842" rx="54" transform="translate(1578 610) rotate(-180)" fill="#77ff73"/>
          <path d="M794.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C811.13,709.61,803.54,712.35,794.56,712.35Z" fill="#fff"/>
          <path d="M825,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,825,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip9" transform="translate(0 -750)">
          <rect x="843" y="-106" width="106" height="842" rx="53" transform="translate(1792 630) rotate(180)" fill="#41753d"/>
          <rect x="842" y="-116" width="108" height="842" rx="54" transform="translate(1792 610) rotate(180)" fill="#77ff73"/>
          <path d="M901.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C918.13,709.61,910.54,712.35,901.56,712.35Z" fill="#fff"/>
          <path d="M932,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,932,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip10" transform="translate(0 -750)">
          <rect x="950" y="-106" width="106" height="842" rx="53" transform="translate(2006 630) rotate(180)" fill="#41753d"/>
          <rect x="949" y="-116" width="108" height="842" rx="54" transform="translate(2006 610) rotate(180)" fill="#77ff73"/>
          <path d="M1008.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C1025.13,709.61,1017.54,712.35,1008.56,712.35Z" fill="#fff"/>
        </g>
        <g id="slime-drip2" transform="translate(0 -750)">
          <rect x="94" y="-106" width="106" height="842" rx="53" transform="translate(294 630) rotate(180)" fill="#41753d"/>
          <rect x="93" y="-116" width="108" height="842" rx="54" transform="translate(294 610) rotate(180)" fill="#77ff73"/>
          <path d="M152.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,1,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C169.13,709.61,161.54,712.35,152.56,712.35Z" fill="#fff"/>
          <path d="M183,666.28a4.28,4.28,0,0,1-4.27-4.28V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,183,666.28Z" fill="#fff"/>
        </g>
        <g id="slime-drip1" transform="translate(0 -750)">
          <rect x="-13" y="-106" width="106" height="842" rx="53" transform="translate(80 630) rotate(180)" fill="#41753d"/>
          <rect x="-14" y="-116" width="108" height="842" rx="54" transform="translate(80 610) rotate(180)" fill="#77ff73"/>
          <path d="M45.56,712.35c-.92,0-1.87,0-2.82-.08a4.28,4.28,0,1,1,.52-8.54c7.84.48,14.29-1.5,19.18-5.9,6.43-5.79,9-14.65,9.29-21a4.27,4.27,0,0,1,8.54.38c-.35,8.09-3.72,19.43-12.11,27C62.13,709.61,54.54,712.35,45.56,712.35Z" fill="#fff"/>
          <path d="M76,666.28A4.28,4.28,0,0,1,71.73,662V650a4.27,4.27,0,1,1,8.54,0v12A4.28,4.28,0,0,1,76,666.28Z" fill="#fff"/>
        </g>
      </g>
      <g id="stage2" opacity="0" transform="translate(0 -40)">
        <g id="slimey-drip1">
          <path d="M-14,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M62.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C52.84,19.9,46.57,23.38,40,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,0,0,13.2,18c5.32,6.1,15,12.85,26.32,13.53C47.6,32,55.24,28.41,62.25,22.8Z" fill="#fff"/>
          <path d="M9.71,6.83a4.28,4.28,0,0,0,.66-6L2.88-8.56a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0L3.7,6.15A4.28,4.28,0,0,0,9.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip2">
          <path d="M93,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M169.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C159.84,19.9,153.57,23.38,147,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,120.2,18c5.32,6.1,15,12.85,26.32,13.53C154.6,32,162.24,28.41,169.25,22.8Z" fill="#fff"/>
          <path d="M116.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,116.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip3">
          <path d="M200,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M276.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C266.84,19.9,260.57,23.38,254,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,0,0,227.2,18c5.32,6.1,15,12.85,26.32,13.53C261.6,32,269.24,28.41,276.25,22.8Z" fill="#fff"/>
          <path d="M223.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,223.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip4">
          <path d="M307,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M383.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C373.84,19.9,367.57,23.38,361,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,0,0,334.2,18c5.32,6.1,15,12.85,26.32,13.53C368.6,32,376.24,28.41,383.25,22.8Z" fill="#fff"/>
          <path d="M330.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,330.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip5">
          <path d="M414,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M490.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C480.84,19.9,474.57,23.38,468,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,0,0,441.2,18c5.32,6.1,15,12.85,26.32,13.53C475.6,32,483.24,28.41,490.25,22.8Z" fill="#fff"/>
          <path d="M437.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,437.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip6">
          <path d="M521,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M597.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C587.84,19.9,581.57,23.38,575,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,548.2,18c5.32,6.1,15,12.85,26.32,13.53C582.6,32,590.24,28.41,597.25,22.8Z" fill="#fff"/>
          <path d="M544.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,544.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip7">
          <path d="M628,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M704.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C694.84,19.9,688.57,23.38,682,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,655.2,18c5.32,6.1,15,12.85,26.32,13.53C689.6,32,697.24,28.41,704.25,22.8Z" fill="#fff"/>
          <path d="M651.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,651.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip8">
          <path d="M735,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M811.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C801.84,19.9,795.57,23.38,789,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,762.2,18c5.32,6.1,15,12.85,26.32,13.53C796.6,32,804.24,28.41,811.25,22.8Z" fill="#fff"/>
          <path d="M758.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,758.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip9">
          <path d="M842,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M918.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18C908.84,19.9,902.57,23.38,896,23c-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,869.2,18c5.32,6.1,15,12.85,26.32,13.53C903.6,32,911.24,28.41,918.25,22.8Z" fill="#fff"/>
          <path d="M865.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,865.71,6.83Z" fill="#fff"/>
        </g>
        <g id="slimey-drip10">
          <path d="M949,733V-31c0-29.82,24.18,46,54,46h0c29.82,0,54-75.82,54-46V733Z" fill="#77ff73"/>
          <path d="M1025.25,22.8c.72-.57,1.46-1.17,2.15-1.82a4.28,4.28,0,1,0-5.54-6.53l-.2.18c-5.82,5.27-12.09,8.75-18.66,8.37-8.64-.51-16.18-5.82-20.37-10.6A4.27,4.27,0,1,0,976.2,18c5.32,6.1,15,12.85,26.32,13.53C1010.6,32,1018.24,28.41,1025.25,22.8Z" fill="#fff"/>
          <path d="M972.71,6.83a4.28,4.28,0,0,0,.66-6l-7.49-9.38a4.27,4.27,0,0,0-6-.66,4.26,4.26,0,0,0-.66,6h0l7.49,9.37A4.28,4.28,0,0,0,972.71,6.83Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  );
};
