import React from 'react';

import { number } from 'prop-types';

import 'app/components/progress-bar/progress-bar.scss';

export const ProgressBar = ({ percents = 0 }) => (
  <div className="progress-bar">
    <div className="progress-bar-level" style={{ width: `${percents}%` }} />
  </div>
);

ProgressBar.propTypes = {
  percents: number,
};
