import React, { forwardRef, useContext, useRef } from 'react';

import clsx from 'clsx';

import TabsContext from 'app/components/tabs/tabs-context';
import { useComposedRefs } from 'app/hooks/use-composed-refs.hook';
import { makeId } from 'app/util/make-id'; 

export const ArrowTab = forwardRef(
  ({ as: Element = 'button', className, children, value, ...props }, forwardedRef) => {
    const INTERNAL_CLASS_NAME = 'tabs_tab';
    const { activeTab, setActiveValue } = useContext(TabsContext);
    const panelId = makeId('panel', value);
    const buttonId = makeId('button', value);

    const ownRef = useRef();
    const ref = useComposedRefs(ownRef, forwardedRef);

    const handleClick = () => {
      setActiveValue(value);
    };

    return (
      <Element
        role="tab"
        onClick={handleClick}
        id={buttonId}
        aria-selected={activeTab === value}
        aria-controls={panelId}
        className={clsx(INTERNAL_CLASS_NAME, className, { active: activeTab === value })}
        ref={ref}
        {...props}
      >
        {children}
      </Element>
    );
  }
);

ArrowTab.displayName = 'ArrowTab';
ArrowTab.contextType = TabsContext;
