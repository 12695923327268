import React from 'react';

import { useSelector } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { getMenuOptionsFromActiveStudents } from 'app/util/classes';

export const StudentsDropdown = (props) => {
  const { students, currentStudentBK } = useSelector(state => state.classesData);
  const currentStudent = students[currentStudentBK];

  const { handleStudentSelection } = props;

  const menuOptions = getMenuOptionsFromActiveStudents(students);

  return (
    <DropdownWrapper
      PIIField='students'
      selectHandler={handleStudentSelection}
      dropdownName={`${currentStudent.lastName}, ${currentStudent.firstName}`}
      menuOptions={menuOptions}
    />
  );
};
