import React, { useState } from 'react';

import { gsap } from 'gsap';

import 'app/components/multiple-choice/undelivered/undelivered.scss';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { Title } from 'app/components/multiple-choice/undelivered/animated/title.component';
import { Transition } from 'app/components/multiple-choice/undelivered/animated/transition.component';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

export const Undelivered = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const activityClassName = 'undelivered';

  const articleScreen = () => <>
    <div className="title-screen">
      <Title/>
    </div>
  </>;

  const questionScreen = () => <></>;

  const choicesScreen = () => <></>;

  const feedbackScreen = () => <>
    <div className="feedback-animation">
      <Transition />
    </div>
  </>;

  const animations = {
    [ARTICLE]: function () {
      const tl = gsap.timeline()
      // Article animation
        .fromTo('.activity-card', { y: '-120%', opacity: 0 }, { delay: 11, display: 'flex', opacity: 1, y: 0, duration: 0.8 })
        .call(()=> tl.kill());
      tl.play();
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { y: '100vh', duration: 0.8, clearProps: 'transform' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .fromTo('.activity-card', { y: '-100%' }, { y: 0, duration: 0.8 })
        .call(()=> tl.kill());
      tl.play();
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { y: '100vh', duration: 0.8 })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .fromTo('.article', { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 0.8 })
        .fromTo('.answers', { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 0.8 })
        .fromTo('.answer-button', { opacity: 0, y: '-100%' }, { opacity: 1, y: 0, duration: 1, stagger: 0.3 })
        .call(()=> tl.kill());
      tl.play();
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.article', { y: '100vh', duration: 0.8 }, 'same-time')
        .to('.answers', { y: '100vh', duration: 0.8 }, 'same-time')
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play();
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .fromTo('.feedback, .feedback-next-container', { autoAlpha: 0 }, { duration: 0.1, autoAlpha: 1, delay: 4 })
        .call(()=> tl.kill());
      tl.play();
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
