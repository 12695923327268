import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { isSuccess } from 'app/store/reducers/api-status.reducer';

function between(x, min, max) {
  return x >= min && x <= max;
}

export default function useLevelBackground(ref) {
  const { getUserStatsStatus } = useSelector(state => state.apiReducer);
  const { stats } = useSelector(state => state.profileData);

  useEffect(() => {
    if (isSuccess(getUserStatsStatus)) {
      const { level } = stats;
      if (between(level, 1, 47)) {
        ref.current.style.backgroundImage = `url(/backgrounds/bg_${level}.png)`;
      } else {
        ref.current.style.backgroundImage = 'url(/backgrounds/bg_NA.png)';
      }
    }
  }, [getUserStatsStatus, ref]);

}
