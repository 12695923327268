import React, { useRef } from 'react';

import * as d3 from 'd3';
import ReactTooltip from 'react-tooltip';

import { Rect } from 'app/components/stats/progress-column-charts/rect.component';
import { XAxis } from 'app/components/stats/progress-column-charts/x-axis.component';
import { YAxis } from 'app/components/stats/progress-column-charts/y-axis.component';

function getMaxYValue(data) {
  const maxValue = d3.max(data, d => d.total);
  if (maxValue < 6) {
    return 6;
  }
  return maxValue;
}

export const Bar = ({ data, bottom, left, height, right, top, width }) => {
  const ref = useRef(null);
  const x = d3
    .scaleBand()
    .range([0, width - left - right])
    .domain(data.map(d => d.date))
    .padding(0.1);

  const y = d3
    .scaleLinear()
    .range([height - top - bottom, 0])
    .domain([0, getMaxYValue(data)]);

  return (
    <>
      <svg width={width} height={height} ref={ref}>
        <XAxis
          scale={x}
          top={top}
          bottom={bottom}
          left={left}
          right={right}
          height={height}
        />
        <YAxis
          scale={y}
          top={top}
          bottom={bottom}
          left={left}
          right={right}
        />
        <g transform={`translate(${left}, ${top})`}>
          {data.map((d, index) => (
            <Rect
              key={index}
              data={d}
              x={x}
              y={y}
              top={top}
              bottom={bottom}
              height={height}
              data-tip
              data-for={d.date}
            />
          ))}
        </g>
      </svg>
      {data.map((d, index) => (
        <ReactTooltip
          id={d.date}
          key={index}
          className="bar-tooltip"
          place="top"
          effect="solid"
        >
          <p><b>{d.date}</b></p>
          <p><b>{d.total}</b> activities</p>
          <p><b>{d.correct}</b> correct</p>
        </ReactTooltip>
      ))}
    </>
  );
};

export default Bar;
