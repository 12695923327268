import { isSelectKey } from 'app/constants/keys.constant';

export const handleStyledCheckboxKeySelection = (e, ref) => {
  e.preventDefault();

  if (isSelectKey(e.key)) {
    ref.checked = !ref.checked;
  }

};
