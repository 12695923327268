import React, { useEffect, useState } from 'react';


import { find } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { setWordsGradeFilter } from 'app/store/actions/words-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';
import { getDropdownGrades } from 'app/util/streams';


export const GradeDropdown = () => {
  const dispatch = useDispatch();

  const { streams } = useSelector(state => state.streamsReducer);
  const { gradeOption } = useSelector(state => state.wordsData.filters);

  const { getWordsByStreamStatus } = useSelector(state => state.apiReducer);

  const [dropdownOptions, setDropdownOptions] = useState();

  useEffect(() => {
    if(streams) {
      setDropdownOptions(getDropdownGrades(streams));
    }
  }, [streams]);

  useEffect(() => {
    if (dropdownOptions) {
      dispatch(setWordsGradeFilter(dropdownOptions[0]));
    }
  }, [dropdownOptions]);

  const setCurrentGrade = (e, key) => {
    e.preventDefault();

    const selectedOption = find(dropdownOptions, (el) => { return el.key == key; });
    dispatch(setWordsGradeFilter(selectedOption));
  };

  const isDisabled = () => {
    return isLoading(getWordsByStreamStatus);
  };

  return (
    dropdownOptions && gradeOption ?
      <div className="wordsGradeDropdown">
        <DropdownWrapper
          isDisabled={isDisabled}
          menuOptions={dropdownOptions}
          dropdownName={String(gradeOption.value)}
          selectHandler={setCurrentGrade}
        />
      </div>
      :
      <p>Loading...</p>
  );
};
