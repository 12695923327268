import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setShowLessons, wordsDataActionCreators } from 'app/store/actions/words-data.actions';
import { isSuccess } from 'app/store/reducers/api-status.reducer';
import { getStreamName } from 'app/util/streams';

import 'app/pages/words/components/words/core/core.table.scss';

export const CoreTable = () => {
  const dispatch = useDispatch();

  const { isCore, unitOption, subUnitOption } = useSelector(state => state.wordsData.filters);
  const { lessons, showLessons } = useSelector(state => state.wordsData);

  const { getWordsByUnitSubunitStatus } = useSelector(state => state.apiReducer);

  useEffect(() => {
    // First Time or Filter Changed
    if (isCore && unitOption && subUnitOption)
      dispatch(wordsDataActionCreators.getWordsByUnitSubunit.request({ unitID: unitOption.key, subunitID: subUnitOption.key }));
  }, [isCore, unitOption, subUnitOption]);

  useEffect(() => {
    if (lessons)
      dispatch(setShowLessons(lessons));
  }, [lessons]);


  const getStreamRow = (streams) => {
    const row = streams.map(stream => getStreamName(stream.name)).join(', ');
    return row;
  };

  const handleShowWord = (e, wordID) => {
    e.preventDefault();
    dispatch(wordsDataActionCreators.getWordData.request(wordID));
  };

  const lessonDataWrapper = (lessons) => {
    /*
    Returns the lessons and the general key for the lessons to use. This can be ammended to get any general data from
    lessons.

    Accepts: Lessons Array
    Returns:
    [Lessons Array, key]
    */
    return lessons.map(lesson => {return { ...lesson, key: `${lesson.lesson}-${lesson.subunit.id}-${lesson.unit.id}` };});
  };

  return (
    <div className='table' id='coreTable'>
      <table
        role="grid"
        aria-describedby="students-table-description"
        summary='Table of words. Interact with row or element to select the student in the row for viewing.'
      >
        <thead>
          <tr className="headerRow">
            <th className="word align-left" id="name-header" scope="col" tabIndex="0">
              WORD
            </th>
            <th className="word align-left" id="name-header" scope="col" tabIndex="0">
              PART OF SPEECH
            </th>
            <th className="word align-left" id="name-header" scope="col" tabIndex="0">
              STREAMS
            </th>
          </tr>
        </thead>
        <tbody className={isCore && showLessons && isSuccess(getWordsByUnitSubunitStatus) ? '' : 'loading'}>
          {
            isCore && showLessons && isSuccess(getWordsByUnitSubunitStatus) ?
              lessonDataWrapper(showLessons).map(lesson => {
                return (
                  lesson.firstEncounterWords.length != 0 ?
                    <React.Fragment key={`${lesson.key}-wrapper`}>
                      <tr key={`${lesson.key}-lesson-row`} className='lessonRow'>
                        <td key={`${lesson.key}-lesson-name`}>Lesson {lesson.lesson} : {lesson.curriculum.name} : {lesson.unit.name} : {lesson.subunit.name}</td>
                      </tr>
                      { lesson.firstEncounterWords.map(fr => {
                        return (
                          <tr key ={`${fr.id}-${lesson.key}-word`} onClick={e => handleShowWord(e, fr.word.id)}>
                            <td key ={`${fr.id}-${lesson.key}-name`}>{fr.word.name}</td>
                            <td key ={`${fr.id}-${lesson.key}-pos`}>{fr.word.pos}</td>
                            <td key ={`${fr.id}-${lesson.key}-stream`}>{getStreamRow(fr.word.stream)}</td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                    :
                    <React.Fragment key={`${lesson.key}-wrapper`} />
                );
              })
              : 
              <></>
          }
        </tbody>
      </table>
    </div>
  );
};
