import routesConstants from 'app/constants/routes.constant';

// Missing keycloak, need to add wherever imported
export const generateConfig = (keycloak, isTeacher) => {
  return {
    appName: 'vocabulary',
    adultsView: true,
    _keycloak: keycloak,
    appLogoWords: [
      {
        word: 'Amplify'
      },
      {
        word: 'Vocabulary',
        colorGray: true
      }
    ],
    appLinks: [
      {
        linkUrl: routesConstants.INDEX,
        text: 'Index',
        displayed: false,
      },
      {
        linkUrl: routesConstants.HOME,
        text: 'Play',
        displayed: isTeacher,
        linkType: 'EXTERNAL_LINK',
      },
      {
        linkUrl: routesConstants.CLASSES,
        text: 'Reporting',
        displayed: isTeacher,
        linkType: 'EXTERNAL_LINK',
      },
      {
        linkUrl: routesConstants.TEACHER_GUIDE,
        text: 'Teacher Guide',
        displayed: isTeacher,
        linkType: 'EXTERNAL_LINK',
      },
    ]
  };
};
