/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';

import { CONFIG } from 'app/config';
import cover35 from 'app/shared/images/guide/35cover.png';
import cover68 from 'app/shared/images/guide/68cover.png';
import imageWrapper from 'app/shared/images/guide/guide-helper.png';
import pdfIcon from 'app/shared/images/guide/pdf-icon.png';
import banner from 'app/shared/images/guide/vocab-banner.png';

import 'app/pages/teacherguide/teacherguide.page.scss';

const TeacherGuidePage = () => {

  const openNewTab = function (e, url) {
    e.preventDefault();

    window.open(CONFIG.PDF_S3_BUCKET + url, '_blank');
  };

  const focusOn = function (e, ref) {
    e.preventDefault();
    
    // We have to use this offset option because of the nav element
    const y = ref.current.getBoundingClientRect().top + guideBody.current.scrollTop - 55;

    guideBody.current.scrollTo({ top: y, behavior: 'smooth' });
  };

  const guideBody = useRef();
  const overviewRef = useRef();
  const guidesRef = useRef();

  return (
    <div id="guide" ref={guideBody}>
      <div className="banner">
        <img src={banner} alt="Teacher Guide Banner"/>
      </div>
      <header className="guideHeader">
        <div className="imageWrapper">
          <img src={imageWrapper} alt="Guide Helper" />
        </div>
        <h1>Vocab App User Guide</h1>
      </header>
      <hr />
      <section className="guideBody">
        <aside className="guideContent">
          <h3>Contents</h3>
          <p onKeyPress={e => focusOn(e, overviewRef)} onClick={e => focusOn(e, overviewRef)} className="buttonFocus">Overview</p>
          <p onKeyPress={e => focusOn(e, guidesRef)} onClick={e => focusOn(e, guidesRef)} className="buttonFocus">Guides</p>
        </aside>
        <div className="guideSection guideOverview" ref={overviewRef}>
          <h2 id="overview">Overview</h2>
          <p>The Vocab App was designed to foster a love of language as students progress toward mastery of at least 500
            new words during each year. The activities in the app engage students in academically rigorous activities
            that adapt to different learning levels and appeal to different learning styles.
          </p>
          <p>Research has shown that to master a word, a student needs to encounter it many times, preferably in a
            variety of media and contexts. To ensure that all students can work toward mastering vocabulary, the app
            provides all students with multiple encounters with vocabulary words, in a wide range of activities, while
            students practice a variety of vocabulary skills. In addition, the app is adaptive and contains multiple
            levels, or streams, to address different learning needs. In the Amplify ELA curriculum, students will
            encounter a word in the core texts, in activities, in interactive multimedia, in teacher talk, in games,
            and in audio and video shorts that all further illustrate word meanings.
          </p>
          <p>There are also activities that focus on words for English Language Learners. These standards-aligned
            vocabulary activities require students to match a word&apos;s English  definition, Spanish translation, context
            sentence, audio pronunciation, and visual definition.
          </p>
          <p>The majority of words in the app represent tier 2 vocabulary sourced from the Reveal words identified in the
            core texts students study in the ELA curriculum. Additional words in the app are chosen from grade level
            academic word lists. The words are selected because students are likely to encounter these words across
            texts, or because these words are integral to understanding key concepts.
          </p>
        </div>
        <div className="guideSection" ref={guidesRef}>
          <h2 id="guides">Guides</h2>
          <div className="guideDocs">
            <div className="guideItem">
              <div className="imageWrapper">
                <img src={cover35} alt="Guides"/>
              </div>
              <div className="buttonWrapper">
                <button onClick={e => openNewTab(e, 'G3-5_AmplifyCKLA_VocabApp.pdf')}>
                  Grades 3-5
                  <img src={pdfIcon} alt="Grades 3-5 Cover" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="guideItem">
              <div className="imageWrapper">
                <img src={cover68} alt="Grades 6-8 Cover" aria-hidden="true" />
              </div>
              <div className="buttonWrapper">
                <button onClick={e => openNewTab(e, 'G6-8_AmplifyELA_VocabApp.pdf')}>
                  Grades 6-8
                  <img src={pdfIcon} alt=" - PDF" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <hr />
        <div className="footerInfo">
          <p className="logo orange">Amplify</p>
          <p> © { new Date().getFullYear() } Amplify Education, Inc. All rights reserved. | (800) 823-1969 | </p>
          <p><a className="help orange" href="mailto:help@amplify.com">Contact Us</a></p>
        </div>
      </footer>
    </div>
  );
};

export default TeacherGuidePage;
