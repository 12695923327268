import { getGradeFromStreamName } from 'app/util/streams';

export const getGradeForStudentClass = (props) => {
  /*
  * Grade Selection:
  * Section Grade (?)
  * (ELA? -> ELA Section Grade [investigating this]) (?)
  * Get Grade from Latest Profile (?)
  * Get Grade from Stats
  * Get Grade from Stream (?)
  * Grade 3 
  */
  const { activeStudent, activeClass } = props;

  if(!activeClass)
    return 3;

  const streamGrade = activeStudent && activeStudent.stats && activeStudent.stats.stream && getGradeFromStreamName(activeStudent.stats.stream).replace('/', '');
  if (activeClass.sectionGrade)
    return (activeClass.sectionGrade);
  else if (activeStudent.latestProfile && activeStudent.latestProfile.standaloneGrade)
    return (activeStudent.stats.grade);
  else if (activeStudent.stats.grade)
    return (activeStudent.stats.grade);
  else if (streamGrade)
    return (streamGrade);
  else
    return (3);

};
