import React, { useState } from 'react';

import { gsap } from 'gsap';

import { useSceneSettings } from 'app/components/multiple-choice/use-scene-settings.hook';
import { INTRO, FEEDBACK, QUESTION } from 'app/components/syn-ant-activity/constants/animation-steps.constant';
import { CORRECT_CLASS, HIDE_CLASS, INACTIVE_CLASS } from 'app/components/syn-ant-activity/constants/classes.constant';
import { RisingResults, timelineOneCorrect, timelineTwoCorrect, timelineAllCorrect } from 'app/components/syn-ant-activity/risingtides/animated/results.component'; 
import { RisingTransition, seagullTimeline } from 'app/components/syn-ant-activity/risingtides/animated/transition.component'; 
import { BoxActive } from 'app/components/syn-ant-activity/risingtides/boxes/box-active.component'; 
import { BoxInactive } from 'app/components/syn-ant-activity/risingtides/boxes/box-inactive.component'; 
import { infoText } from 'app/components/syn-ant-activity/risingtides/constants/info.constant';
import { introText } from 'app/components/syn-ant-activity/risingtides/constants/intro.constant';
import { DropzoneActive } from 'app/components/syn-ant-activity/risingtides/dropzones/dropzone-active.component'; 
import { DropzoneInactive } from 'app/components/syn-ant-activity/risingtides/dropzones/dropzone-inactive.component'; 
import book from 'app/components/syn-ant-activity/risingtides/images/book.svg';
import iconClose from 'app/components/syn-ant-activity/risingtides/images/button_close.svg';
import drop from 'app/components/syn-ant-activity/risingtides/images/droplet-art.svg';
import iconIdea from 'app/components/syn-ant-activity/risingtides/images/icon_idea.svg';
import { intro, Intro } from 'app/components/syn-ant-activity/risingtides/intro/intro.component'; 
import { SynAntActivity } from 'app/components/syn-ant-activity/syn-ant-activity.component';
import { ANTONYMS } from 'app/constants/syn-ant.constants';
import 'app/components/syn-ant-activity/risingtides/risingtides.scss';

export const RisingTides = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const { sceneRef, makeInert, makeActive } = useSceneSettings();
  const [animationFinished, setAnimationFinished] = useState(false);
  const settings = { onStart: makeInert, onComplete: makeActive };

  const feedbackTransition = () =>
    <>
      
    </>;

  const introScreen = () =>
    <>
      <div className="intro-screen animated">
        <Intro/>
      </div>
    </>;

  const questionScreen = () =>
    <>
      <RisingResults/>
      <div className="rising-transition">
        <RisingTransition/>
      </div>
    </>;

  const animations = {
    [INTRO]: () => {
      intro.call(() => { intro.kill(); });
      intro.play(0);
    },
    [`${INTRO}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.explanation-next, .passage-text, .risingtides-directions', { duration: 0.5, opacity: 0, display: 'none' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },
    [QUESTION]: () => {
      const tl = gsap.timeline(settings)
        .fromTo('.question', { opacity: 0 }, { duration: 0.5, opacity: 1 })
        .fromTo('.info-text', { opacity: 0 }, { duration: 0.5, opacity: 1 })
        .to('.next-button', { opacity: 1, duration: 0.5, ease: 'sine.inOut' })
        .call(() => { tl.kill(); });
      tl.play(0);
    },
    [FEEDBACK]: (foundAnswers) => {
      // Add animation here
      const tl = gsap.timeline({ onComplete: () => {
        if (foundAnswers && foundAnswers.length == 1) {
          timelineOneCorrect.play(0);
          gsap.to(`.${HIDE_CLASS}`, { duration: 1, opacity: 0, ease: 'sine.inOut' });
          gsap.to('.activity-word', { duration: 2, y: -60, ease: 'sine.inOut' });
        } else if (foundAnswers && foundAnswers.length == 2) {
          timelineTwoCorrect.play(0);
          gsap.to(`.${HIDE_CLASS}`, { duration: 1, opacity: 0, ease: 'sine.inOut' });
          gsap.to('.activity-word', { duration: 2, y: -160, ease: 'sine.inOut' });
        } else {
          timelineAllCorrect.play(0);
          gsap.to('.boxes', { duration: 2, y: -180, ease: 'sine.inOut' });
          gsap.to(`.${HIDE_CLASS}`, { duration: 1, opacity: 0, ease: 'sine.inOut' });
          gsap.to('.dropzones', { duration: 2, padding: 0, y: -200, ease: 'sine.inOut' });
          gsap.to('.dropzones .dropzone:nth-child(1), .dropzones .dropzone:nth-child(4)', { duration: 2, y: -60, ease: 'sine.inOut' });
          gsap.to('.activity-word', { duration: 2, y: -560, ease: 'sine.inOut' });
          gsap.to('.activity-word', { duration: 3, y: -250, delay: 2, ease: 'elastic.out' });
          gsap.to('#foreground-buoy', { duration: 3, y: -300, delay: 2, ease: 'elastic.out' });
        }
        gsap.to('.next-button', { duration: 0.1, x: -390, y: -113, delay: 3, ease: 'sine.inOut' });
        gsap.to('.next-button', { duration: 1, opacity: 1, delay: 4, ease: 'sine.inOut' });
      } })
        .to('.rising-transition', { duration: 0.1, display: 'block', onComplete: () => {
          seagullTimeline.play(0);
        } })
        .to('.rising-transition', { duration: 0.5, opacity: 0, display: 'none', delay: 4, ease: 'sine.inOut' })
        .add(gsap.to('.box-inactive', { duration: 0.3, opacity: '0', ease: 'sine.inOut' }), 3.5)
        .add(gsap.to(`.${HIDE_CLASS} .dropshape`, { duration: 0.2, fill: '#E07012', ease: 'sine.inOut' }), 3.5)
        .add(gsap.to(`.${HIDE_CLASS} .shineshape`, { duration: 0.2, fill: '#F4B484', ease: 'sine.inOut' }), 3.5)
        .add(gsap.to(`.${HIDE_CLASS}.word`, { duration: 0.2, backgroundColor: '#E07012', ease: 'sine.inOut' }), 3.5)
        .add(gsap.to(`.${CORRECT_CLASS} .dropzone-solid`, { duration: 0.3, opacity: 1, ease: 'sine.inOut' }), 3.5)
        .add(gsap.to(`.${CORRECT_CLASS} .dropzone-dashed`, { duration: 0.3, opacity: 0, ease: 'sine.inOut' }), 3.5)
        .to(`.${INACTIVE_CLASS}`, { duration: 0.3, opacity: '0', ease: 'sine.inOut' })
        .to(`.${CORRECT_CLASS} .bursting > line`, { duration: 0.5, strokeDashoffset: -40, ease: 'sine.inOut' })
        .call(() => { tl.kill(); });
      tl.play(0);
      // feedback transition play
    },   
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <SynAntActivity 
      ref={sceneRef}
      activityId={activityId}
      activityType={ANTONYMS}
      activityClassName="risingtides"
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      onFinished={onFinished}
      FeedbackTransition={feedbackTransition}
      QuestionScreen={questionScreen}
      BoxActive={BoxActive}
      BoxInactive={BoxInactive}
      boxPreview={drop}
      DropzoneActive={DropzoneActive}
      DropzoneInactive={DropzoneInactive}
      IntroScreen={introScreen}
      animate={animations}
      introText={introText}
      infoText={infoText}
      iconIdea={iconIdea}
      bookIcon={book}
      iconClose={iconClose}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
