import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DropdownWrapper } from 'app/components/dropdowns/dropdown-wrapper/dropdown-wrapper';
import { setSubunitFilter } from 'app/store/actions/words-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';

export const SubunitDropdown = () => {
  const dispatch = useDispatch();

  const { subunit } = useSelector(state => state.wordsData);
  const { subUnitOption } = useSelector(state => state.wordsData.filters);

  const { getWordsByUnitSubunitStatus } = useSelector(state => state.apiReducer);

  const [dropdownOptions, setDropdownOptions] = useState();
  
  useEffect(() => {
    if (subunit) {
      const dropdownOptions = _.map(subunit, iunit => {
        return {
          key: iunit.id,
          value: iunit.name,
        };
      });
      dropdownOptions.unshift({ key: '', value: 'All' });
      setDropdownOptions(dropdownOptions);
    }
  }, [subunit]);

  useEffect(() => {
    if (dropdownOptions)
      dispatch(setSubunitFilter(dropdownOptions[4]));
  }, [dropdownOptions]);

  const selectUnit = (e, key) => {
    e.preventDefault();
    dispatch(setSubunitFilter(_.find(dropdownOptions, e => e.key == key)));
  };

  const isDisabled = () => {
    return isLoading(getWordsByUnitSubunitStatus);
  };

  return (
    dropdownOptions && subUnitOption ?
      <div className="wordsSubUnitDropdown">
        <DropdownWrapper
          isDisabled={isDisabled}
          menuOptions={dropdownOptions}
          dropdownName={subUnitOption.value}
          selectHandler={selectUnit}
        />
      </div>
      : 
      <></>
  );
};
