import React, { useRef } from 'react';

import ReactTooltip from 'react-tooltip';

const rectWidth = 32;

export const Rect = ({ data, x, y, height, top, bottom }) => {
  const ref = useRef(null);

  return (
    <>
      <ReactTooltip id={data.date} place="top" effect="solid">
      Tooltip for the register button
      </ReactTooltip>
      <g data-tip="true" data-for={data.date} ref={ref} onClick={() => ReactTooltip.show(ref.current)} onBlur={() => ReactTooltip.hide()} tabIndex={-1}>
        <g transform={`translate(${x(data.date) + rectWidth / 2}, ${y(data.total)})`}>
          <rect
            width={rectWidth}
            height={height - bottom - top - y(data.total)}
            fill="#14405c"
          />
        </g>
        <g transform={`translate(${x(data.date) + rectWidth}, ${y(data.correct)})`}>
          <rect
            width={rectWidth}
            height={height - bottom - top - y(data.correct)}
            fill="#fff"
            stroke="#14405c"
            strokeWidth={2}
          />
        </g>
      </g>
    </>

  );
};
