import React, { useEffect, useState } from 'react';

import { SessionModal } from 'app/components/session-timeout/session-modal.component';
import AuthService from 'app/services/auth.service';

export const SessionTimeout = () => {
  const [showModal, setShowModal] = useState(false);
  let timeout;

  // 60000 milliseconds = 1 minute
  const TIMER_INTERVAL = 3300000; // 55 minutes

  const resetTimeout = () => {
    timeout = setTimeout(() => {
      if(!showModal) {
        setShowModal(true);
        resetTimeout(); 
      }
    }, TIMER_INTERVAL);
  };

  useEffect(() => {
    resetTimeout();
    return () => {
      clearTimeout(timeout); 
    };
  }, [showModal]);

  const onCancel = () => {
    setShowModal(false);
  };
    
  const onLogout = () => {
    setShowModal(false);
    AuthService.logout();
  };

  return (
    <>
      {showModal && 
        <SessionModal 
          onCancel={onCancel}
          onLogout={onLogout} 
        />}
    </>
  );
};