import React, { useEffect } from 'react';

import { gsap } from 'gsap';

export const zomDrip = new gsap.timeline({ paused: true });

export const ZombieDripping = () => {

  useEffect(() => {
    zomDrip
      .add(gsap.to('#drip1', { duration: 2, scaleY: 1.4, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#drip1', { duration: 1, scaleY: 1, ease: 'sine.inOut' }), 2)
      .add(gsap.to('#drip2', { duration: 0.5, scaleY: 0.8, ease: 'sine.inOut' }), 0)
      .add(gsap.to('#drip2', { duration: 2, scaleY: 1.25, ease: 'sine.inOut' }), 0.5)
      .add(gsap.to('#drip2', { duration: 0.5, scaleY: 1, ease: 'sine.inOut' }), 2.5)
      .add(gsap.to('#eye-clip', { duration: 0.5, x: 15, y: 15, ease: 'sine.inOut' }), 0.5)
      .add(gsap.to('#eye-clip', { duration: 0.5, x: 0, y: 0, ease: 'sine.inOut' }), 2);

  });

  return (
    <svg id="dripping-zombie" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 374" height="374" width="236">
      <defs>
        <clipPath id="clip-path">
          <polygon id="eye-clip" points="140.37 51.5 105.55 75.36 133.81 113.33 168.63 89.47 140.37 51.5" fill="none"/>
        </clipPath>
      </defs>
      <g id="zombie-dripping">
        <g>
          <path d="M186.14,199.68C234.57,165.8,246,101.5,211.76,56.06L36.39,178.74C70.67,224.19,137.71,233.56,186.14,199.68Z" fill="#5f9898"/>
          <path d="M170.82,33a23.4,23.4,0,0,0-4.54-10.84,25.38,25.38,0,0,0-31.13-7.41,25.39,25.39,0,0,0-33-3.58,24,24,0,0,0-6,6.09,25.34,25.34,0,0,0-22.49,3.19,23.41,23.41,0,0,0-10,17,25.24,25.24,0,0,0-19.91,3.9A23.35,23.35,0,0,0,33.57,61a25,25,0,0,0-7.89,3.66A23.26,23.26,0,0,0,19,96a23.22,23.22,0,0,0-2.3,30.8,24.57,24.57,0,0,0,9.3,7.52,23.22,23.22,0,0,0,4,20.17,25.36,25.36,0,0,0,34.55,5.41,23.26,23.26,0,0,0,5.34-33.31h0A25.38,25.38,0,0,0,104.4,132a23.27,23.27,0,0,0,5.34-33.32l-.47-.63c-.76-1-.6-.8.47.63a25.37,25.37,0,0,0,34.55,5.41,23.28,23.28,0,0,0,5.34-33.32h0a25.36,25.36,0,0,0,34.55,5.41,23.26,23.26,0,0,0,5.34-33.31A25.16,25.16,0,0,0,170.82,33Z" fill="#aeaeae"/>
          <path d="M32,134.74l-.13.09a3.18,3.18,0,0,1-4.33-.83,22.26,22.26,0,0,1,2.76-28.45A24.26,24.26,0,0,1,51.8,99.4,23.56,23.56,0,0,1,59.55,85c8.26-7.06,21.23-7.7,31.53-1.57A2.9,2.9,0,0,1,92,87.59a3.26,3.26,0,0,1-4.34,1c-8-4.74-17.83-4.38-24,.89-5.54,4.74-5.92,11.74-5.91,13.78a3,3,0,0,1-1.38,2.54,3.31,3.31,0,0,1-3,.35A17.92,17.92,0,0,0,34.7,109.7a16.39,16.39,0,0,0-1.88,21A2.94,2.94,0,0,1,32,134.74Z" fill="#909090"/>
          <path d="M66.3,51.19a26.51,26.51,0,0,0-2.87.17,22.5,22.5,0,0,0-2.69.47,18.6,18.6,0,0,0-2.46.78,16.13,16.13,0,0,0-2.2,1.1,11.52,11.52,0,0,0-1,.66h0c-.05,0,.07,0,.06,0h0l0,0-.07,0-.14.11-.29.21a5.27,5.27,0,0,0-.45.37c-.13.11-.27.23-.41.37s-.29.26-.43.41-.53.56-.78.86-.5.6-.72.91-.44.65-.64,1a14.27,14.27,0,0,0-1,2.06L50,61a2.39,2.39,0,0,1-3,1.26l-.23-.1c-.45-.23-.93-.42-1.41-.61s-1-.34-1.48-.49a15.47,15.47,0,0,0-1.53-.36c-.52-.11-1.05-.16-1.58-.22s-1.08-.07-1.63-.07h-.85c-.26,0-.56,0-.85,0a37,37,0,0,0-3.7.35,9.67,9.67,0,0,1,3-2.55c.3-.16.59-.31.93-.46l.45-.19.5-.16a13.86,13.86,0,0,1,2-.45,14.46,14.46,0,0,1,2.08-.16,16.17,16.17,0,0,1,2.09.12,16,16,0,0,1,4,1.06l-3.32,1.39a14.9,14.9,0,0,1,2.24-4.27c.33-.43.68-.86,1.05-1.26s.78-.77,1.2-1.13a7.79,7.79,0,0,1,.65-.52,8.48,8.48,0,0,1,.7-.5c.24-.15.52-.35.71-.45l.68-.38c.5-.25,1-.48,1.51-.68a14.27,14.27,0,0,1,6.35-.88,13.56,13.56,0,0,1,3,.63,11.07,11.07,0,0,1,1.38.56A8,8,0,0,1,66.3,51.19Z" fill="#909090"/>
          <path d="M75.49,68.1a31.51,31.51,0,0,0-5.3-.21,30.77,30.77,0,0,0-5.11.63A26.32,26.32,0,0,0,60.3,70a23.9,23.9,0,0,0-2.22,1.07A23.18,23.18,0,0,0,56,72.37l-.13.08h0l0,0h0l0,0-.07,0-.27.19c-.18.13-.38.26-.53.38s-.6.46-.9.73c-.62.52-1.2,1.06-1.76,1.63s-1.06,1.17-1.56,1.78a21.47,21.47,0,0,0-1.36,1.92c-.42.67-.82,1.34-1.18,2s-.67,1.42-1,2.15-.54,1.46-.76,2.21-.39,1.5-.52,2.21l0,.13a2.39,2.39,0,0,1-2.7,1.9l-.22,0a28.06,28.06,0,0,0-3.07-.5,27.23,27.23,0,0,0-3.13-.16,25.31,25.31,0,0,0-6.24.81,23.22,23.22,0,0,0-3,1c-.26.1-.5.21-.74.32s-.51.22-.76.33c-.48.21-1,.44-1.45.7A29.88,29.88,0,0,0,21.77,94c-.94.62-1.85,1.32-2.77,2a16.59,16.59,0,0,1,1.79-3,19.21,19.21,0,0,1,2.4-2.64c.44-.41.92-.8,1.41-1.18a7.9,7.9,0,0,1,.74-.52c.26-.17.51-.34.78-.49a23.39,23.39,0,0,1,3.33-1.64,25.62,25.62,0,0,1,7.28-1.68,28.13,28.13,0,0,1,7.44.4l-3,2a20.71,20.71,0,0,1,.57-2.8,27.79,27.79,0,0,1,.88-2.67,26,26,0,0,1,2.66-5A24.4,24.4,0,0,1,49.1,72.4a23.29,23.29,0,0,1,2.27-1.83c.38-.28.82-.56,1.24-.83.21-.14.39-.24.58-.35l.29-.17.18-.11.16-.09a24.92,24.92,0,0,1,5.36-2.18,22.29,22.29,0,0,1,2.81-.6,24.62,24.62,0,0,1,8.37.18A20.71,20.71,0,0,1,75.49,68.1Z" fill="#909090"/>
          <path d="M19.13,126.36A20.57,20.57,0,0,1,18,100.41a23.34,23.34,0,0,1,23.38-8.54c-1.76.32-12.12,2.36-18.7,11.85A26.77,26.77,0,0,0,19.13,126.36Z" fill="#fbf8d3"/>
          <path d="M19.51,92.55a21.14,21.14,0,0,1-1-16.17c.81-2.31,4-9.94,12.5-13.07a21.68,21.68,0,0,1,14.8.14c-8-.84-15.57,2.75-19.12,8.93a17,17,0,0,0-.95,14.17A11.79,11.79,0,0,0,22,88.93,10.89,10.89,0,0,0,19.51,92.55Z" fill="#fbf8d3"/>
          <path d="M35.41,56.94A23.4,23.4,0,0,1,46.78,41.78,24.22,24.22,0,0,1,61.94,39.2c.49,1.23,1,2.47,1.48,3.7a26.51,26.51,0,0,0-13.49,3.31,25.4,25.4,0,0,0-9,8.38,14,14,0,0,0-2.77.81A13.59,13.59,0,0,0,35.41,56.94Z" fill="#fbf8d3"/>
          <path d="M169.4,38.6a3.21,3.21,0,0,1-4.27-.49,18,18,0,0,0-21.3-4.76,16.7,16.7,0,0,0-9.31,16A3,3,0,0,1,133.26,52a3.26,3.26,0,0,1-2.94.48c-2-.64-9-2.46-15.43,1.16-7.09,4-10.51,12.93-8.29,21.7a3.15,3.15,0,0,1-6.1,1.55c-2.88-11.34,1.76-23,11.29-28.39a25.5,25.5,0,0,1,16.59-2.66A22.84,22.84,0,0,1,141.1,28a24.41,24.41,0,0,1,29,6.27,2.92,2.92,0,0,1-.52,4.22Z" fill="#909090"/>
          <path d="M63.58,37.47l.75.69c.47.46,1.19,1.13,2.06,2,1.75,1.73,4.2,4.25,7,7.35s6,6.78,9.35,10.8c.84,1,1.67,2,2.51,3.08s1.7,2.1,2.54,3.17c1.7,2.14,3.38,4.35,5.06,6.56s3.33,4.44,4.91,6.66c.81,1.11,1.59,2.21,2.36,3.31s1.53,2.17,2.26,3.24c2.95,4.29,5.59,8.36,7.77,11.91s3.92,6.56,5.09,8.71c.59,1.06,1,1.92,1.33,2.5l.45.9-.74-.69c-.48-.46-1.2-1.12-2.07-2-1.75-1.74-4.2-4.26-7-7.36s-6-6.77-9.35-10.8c-.84-1-1.67-2-2.51-3.07s-1.7-2.1-2.54-3.18c-1.7-2.14-3.37-4.35-5.06-6.56s-3.32-4.44-4.9-6.66c-.81-1.11-1.59-2.21-2.36-3.31S79,62.56,78.23,61.48c-2.94-4.28-5.59-8.36-7.77-11.9S66.54,43,65.37,40.87c-.6-1.06-1-1.93-1.33-2.5Z" fill="#929292"/>
          <path d="M76.28,44.2A8,8,0,0,1,76,42.81a11.33,11.33,0,0,1-.13-1.44,12.3,12.3,0,0,1,.31-2.94A13,13,0,0,1,79,32.89a14,14,0,0,1,1.14-1.17l.59-.52c.15-.14.44-.33.66-.5a7,7,0,0,1,.71-.49c.23-.15.47-.3.71-.43.49-.28,1-.52,1.49-.75s1-.4,1.57-.57A14.64,14.64,0,0,1,87.44,28a15.78,15.78,0,0,1,3.3-.32l-2.4,2.61a14.57,14.57,0,0,1,.2-4,15.18,15.18,0,0,1,.52-1.95,12.89,12.89,0,0,1,1.88-3.57l.31-.41.33-.35a10,10,0,0,1,.74-.72,9.83,9.83,0,0,1,3.46-2,33,33,0,0,0-1.5,3.28c-.09.26-.2.53-.27.77l-.26.78a14.32,14.32,0,0,0-.44,1.51A13.46,13.46,0,0,0,93,25.19a12.8,12.8,0,0,0-.12,1.51c0,.51,0,1,0,1.51s.09,1,.17,1.48a2.28,2.28,0,0,1-1.9,2.57l-.24,0h-.26a16,16,0,0,0-2.36.33c-.39.08-.77.17-1.16.28s-.75.24-1.13.37-.73.29-1.09.46l-.53.26-.5.26c-.16.1-.29.15-.5.3l-.3.2-.15.1-.07,0,0,0h0l0,0h0c-.33.22-.65.46-1,.71a14,14,0,0,0-1.77,1.68,17.14,17.14,0,0,0-1.53,2,19.87,19.87,0,0,0-1.29,2.31A26.24,26.24,0,0,0,76.28,44.2Z" fill="#909090"/>
          <path d="M90.14,57.84a18.92,18.92,0,0,1-.07-5.21,21.81,21.81,0,0,1,1.28-5.21,19.52,19.52,0,0,1,1.14-2.51A18.41,18.41,0,0,1,94,42.52a23.4,23.4,0,0,1,3.8-4.23l.14-.12.16-.14.26-.21c.18-.14.32-.27.53-.43.4-.3.81-.61,1.2-.87A22.87,22.87,0,0,1,102.55,35,25.38,25.38,0,0,1,108,32.9,28.35,28.35,0,0,1,113.8,32c1,0,1.94,0,2.91,0a21.37,21.37,0,0,1,2.94.34l-2.87,2.08a25,25,0,0,1,1.9-6.93,23.84,23.84,0,0,1,3.91-6.15,21.93,21.93,0,0,1,2.65-2.54c.23-.2.48-.38.72-.57a8.3,8.3,0,0,1,.74-.51c.54-.34,1.07-.65,1.6-.93a20.3,20.3,0,0,1,3.36-1.38,16.89,16.89,0,0,1,3.49-.71c-1,.61-2,1.23-2.87,1.89a31.48,31.48,0,0,0-2.56,2.1c-.41.37-.78.74-1.14,1.11l-.57.59-.54.58a23.6,23.6,0,0,0-1.92,2.46,22.9,22.9,0,0,0-2.72,5.47,24.54,24.54,0,0,0-1.27,5.88A2.38,2.38,0,0,1,119,36.94l-.21,0-.14,0c-.74-.1-1.54-.17-2.34-.21s-1.61,0-2.42,0-1.62.11-2.42.22-1.59.27-2.37.45a22.75,22.75,0,0,0-2.32.65c-.76.27-1.52.54-2.25.88s-1.44.7-2.14,1.1c-.36.2-.67.41-1,.61-.17.09-.36.24-.54.36l-.27.19-.07.05,0,0h0l0,0h0l-.13.09c-.65.47-1.29,1-1.9,1.51a23.22,23.22,0,0,0-1.74,1.7,24.79,24.79,0,0,0-3,3.91,29.11,29.11,0,0,0-3.64,9.4Z" fill="#909090"/>
          <path d="M13.85,131.77c5.15,16.67,11.2,31.93,22.54,47,30.25,40.1,94,45.14,142.45,11.26S242,96.15,211.76,56.06C200.42,41,186.64,29.3,168.44,24.33Z" fill="#6eb5b6"/>
          <path d="M180.6,129.66c5,6.68,13.73,8.85,19.43,4.87s6.24-12.62,1.2-19.3Z" fill="#004242"/>
          <path d="M117.5,192.26c5.71-4,6.24-12.62,1.21-19.3L98.08,187.4C103.11,194.07,111.8,196.25,117.5,192.26Z" fill="#004242"/>
          <path d="M158.77,163.39c5.7-4,6.24-12.62,1.2-19.29l-20.63,14.43C144.38,165.21,153.07,167.38,158.77,163.39Z" fill="#004242"/>
          <path d="M179.4,149c5.7-4,6.24-12.62,1.2-19.3L160,144.1C165,150.77,173.7,153,179.4,149Z" fill="#004242"/>
          <path d="M132.25,96.89c6.05,8,17.87,9.68,26.42,3.7a5.91,5.91,0,0,0,1.51-8.45l-7.3-9.68-5.16,3.61Z" fill="#5f9898"/>
          <path d="M101.3,118.54c6.06,8,4,19.37-4.52,25.35A6.46,6.46,0,0,1,88,142.66L80.67,133l5.16-3.61Z" fill="#5f9898"/>
          <path d="M90.35,104a12.91,12.91,0,0,0-17.62-2.46,11.84,11.84,0,0,0-3,16.9l11,14.52a12.91,12.91,0,0,0,17.62,2.46,11.85,11.85,0,0,0,3-16.9Z" fill="#004242"/>
          <g clipPath="url(#clip-path)">
            <path d="M141.93,67.94a12.92,12.92,0,0,0-17.62-2.47,11.84,11.84,0,0,0-3,16.9l11,14.52a12.92,12.92,0,0,0,17.62,2.47,11.85,11.85,0,0,0,3-16.9Z" fill="#004242"/>
          </g>
          <path d="M201.24,115.27,98.08,187.4a6.48,6.48,0,0,1-8.84-1.24,6,6,0,0,1,1.51-8.48l103.17-72.12a6.46,6.46,0,0,1,8.83,1.24A5.93,5.93,0,0,1,201.24,115.27Z" fill="#004242"/>
          <path d="M180.6,129.66a12.92,12.92,0,0,0,17.62,2.47,11.84,11.84,0,0,0,3-16.9Z" fill="#fbf8d3"/>
          <path d="M98.08,187.4a12.89,12.89,0,0,0,17.61,2.46,11.84,11.84,0,0,0,3-16.9Z" fill="#fbf8d3"/>
          <path d="M139.34,158.53A12.91,12.91,0,0,0,157,161a11.82,11.82,0,0,0,3-16.89Z" fill="#fbf8d3"/>
          <path d="M160,144.1a12.91,12.91,0,0,0,17.62,2.46,11.84,11.84,0,0,0,3-16.9Z" fill="#fbf8d3"/>
          <path d="M218.28,66.28,208.75,73c-8.55,6-10.57,17.33-4.53,25.35l20.54,27.22C229.79,105.2,227.91,84.33,218.28,66.28Z" fill="#5f9898"/>
          <path d="M44.47,187.88,54,181.21c8.55-6,20.38-4.33,26.43,3.69L101,212.13C79.35,210.45,59.32,202.27,44.47,187.88Z" fill="#5f9898"/>
          <path d="M91.39,199.42c14.12,18.73,53.24,14.52,87.45-9.42s50.46-58.45,36.34-77.18l10.31-7.22s22.29,36.44-43,89.24c-55,44.46-96.26,8.19-96.26,8.19Z" fill="#5f9898"/>
          <path d="M165.1,24.24a22.39,22.39,0,0,0-40.93,9.7,29.29,29.29,0,0,1,17.48-13.46A29.59,29.59,0,0,1,165.1,24.24Z" fill="#fbf8d3"/>
          <path d="M131.6,14.13A23.22,23.22,0,0,0,116,8.21c-2.54,0-11,.56-16.78,7.41a19.63,19.63,0,0,0-4.43,13.6,18.18,18.18,0,0,1,29-11.25,11.81,11.81,0,0,1,7.85-3.84Z" fill="#fbf8d3"/>
          <path d="M91.52,17.68c-1.43-.14-10.87-1-18.49,5.73A22.29,22.29,0,0,0,65.8,36.5L69,39a24.49,24.49,0,0,1,7.43-11.33,26.21,26.21,0,0,1,11-5.62,12.75,12.75,0,0,1,1.66-2.29A13.78,13.78,0,0,1,91.52,17.68Z" fill="#fbf8d3"/>
          <polygon points="168.44 24.33 156.98 35.66 168.86 35.05 161.1 46.16 175.17 45.69 169.77 58.73 183.4 54.16 183.42 69.7 194.77 63.01 201.69 73.34 207.5 60.68 216.41 63.08 208.65 52.39 168.44 24.33" fill="#5f9898"/>
          <path d="M61.88,35.21a128.85,128.85,0,0,0-21.76,20c-3.6,4.38-17.53,22-22.07,47.44C13.36,129,20.91,151.12,27.91,165a77.3,77.3,0,0,0,8.35,13.87C70.53,224.28,137.58,233.66,186,199.78s59.9-98.18,25.62-143.63h0C177.35,10.71,110.3,1.33,61.88,35.21Z" fill="none"/>
          <path d="M226.67,90.24c15,57.94-18.92,114.05-77.58,125.87-114.33,23-135.24-84.34-135.24-84.34L166.28,22.15S214.91,44.7,226.67,90.24Z" fill="none" stroke="#004242" strokeMiterlimit="10" strokeWidth="7"/>
        </g>
        <g id="drip1">
          <path d="M14.26,296.69h0a7.44,7.44,0,0,1-7.43-7.44V138c0-9.91,14.87-14.15,14.87-14.15V289.25A7.44,7.44,0,0,1,14.26,296.69Z" fill="#41753d"/>
          <path d="M14.26,294.51h0a7.44,7.44,0,0,1-7.43-7.44V135.87c0-9.91,14.87-14.16,14.87-14.16V287.07A7.44,7.44,0,0,1,14.26,294.51Z" fill="#77ff73"/>
          <path d="M19.07,287.8A4.7,4.7,0,0,1,14.26,292" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
        </g>
        <g id="drip2">
          <path d="M29.14,284.06h0a7.44,7.44,0,0,1-7.44-7.44V125.41c0-9.91,14.88-14.15,14.88-14.15V276.62A7.44,7.44,0,0,1,29.14,284.06Z" fill="#41753d"/>
          <path d="M29.14,281.88h0a7.44,7.44,0,0,1-7.44-7.43V123.24c0-12,14.88-12.25,14.88-12.25V274.45A7.44,7.44,0,0,1,29.14,281.88Z" fill="#77ff73"/>
          <path d="M33.94,275.17a4.7,4.7,0,0,1-4.8,4.18" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5"/>
        </g>
      </g>
    </svg>
  );
};
