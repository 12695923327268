import React, { useState } from 'react';

import { gsap } from 'gsap';

import 'app/components/multiple-choice/keisha/keisha.scss';
import { KeishaCorrect } from 'app/components/multiple-choice/keisha/animated/keisha-correct.component';
import { KeishaIncorrect } from 'app/components/multiple-choice/keisha/animated/keisha-incorrect.component';
import { KeishaTitle } from 'app/components/multiple-choice/keisha/animated/keisha-title.component';
import { KeishaTransition } from 'app/components/multiple-choice/keisha/animated/keisha-transition.component';
import keishaGear from 'app/components/multiple-choice/keisha/images/gear_lavender.svg';
import keishaGearSmall from 'app/components/multiple-choice/keisha/images/gear_small.svg';
import robotFrame from 'app/components/multiple-choice/keisha/images/robot-frame.svg';
import { MultipleChoice } from 'app/components/multiple-choice/multiple-choice.component';
import { ARTICLE, QUESTION, CHOICES, FEEDBACK } from 'app/constants/multiple-choice.constants';

export const Keisha = ({ activityId, onFinished, onComplete, removeCurrentActivity }) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const activityClassName = 'keisha';

  const articleScreen = () => <>
    <div className="full-screen animated" id="keisha-title-screen">
      <div id="title" className="intro-title" aria-hidden="true"><KeishaTitle/></div>
    </div>
    <div id="keisha-gear"><img src={keishaGear} alt="" aria-hidden="true" /></div>
  </>;

  const questionScreen = () => <>
    <div id="keisha-gear1"><img src={keishaGear} alt="" aria-hidden="true" /></div>
  </>;

  const choicesScreen = () => <>
    <div id="keishaBg" className="full-screen"><img src={robotFrame} alt="" aria-hidden="true" /></div>
    <div id="keisha-gear2"><img src={keishaGear} alt="" aria-hidden="true" /></div>
    <div id="keisha-gear-small"><img src={keishaGearSmall} alt="" aria-hidden="true" /></div>
  </>;

  const feedbackScreen = () => <>
    <div id="keishaBg" className="full-screen"><img src={robotFrame} alt="" aria-hidden="true" /></div>
    <div className="keisha-right" id="keisha-correct"><KeishaCorrect/></div>
    <div className="keisha-right" id="keisha-incorrect"><KeishaIncorrect/></div>
    <div aria-hidden="true" className="full-screen" id="transition">
      <KeishaTransition/>
    </div>
  </>;

  const animations = {
    [ARTICLE]: function () {
      const tl = gsap.timeline()
        .fromTo('.container', { scale: 0.5 }, { duration: 1, scale: 1, transformOrigin: '50% 50%', ease: 'sine.inOut' })
        .fromTo('.activity-card', { scale: 0, opacity: 0 }, { duration: 0.5, scale: 1, transformOrigin: '0 0', opacity: 1, delay: 4, ease: 'sine.out' })
        .fromTo('#keisha-gear', { x: 30, y: 30, scale: 0.1, opacity: 0 }, { duration: 1, scale: 1, x: 0, y: 0, rotate: '+=360', opacity: 1, ease: 'sine.inOut' })
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${ARTICLE}To${QUESTION}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { duration: 0.8, scale: 0, opacity: 0, transformOrigin: '0 0' })
        .add(gsap.to('#keisha-title-screen', { duration: 0.5, opacity: 0, ease: 'sine.inOut' }), 0)
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [QUESTION]: function () {
      const tl = gsap.timeline()
        .fromTo('.activity-card', { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, transformOrigin: '0 0', ease: 'ease-out', duration: 0.8 })
        .add(gsap.fromTo('#keisha-gear1', { top: '20%', left: '17%' }, { duration: 1, rotate: '+=360', opacity: 1, top: '25%', left: '23%', ease: 'sine.inOut' }), 0)
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${QUESTION}To${CHOICES}`]: () => {
      const tl = gsap.timeline()
        .to('.activity-card', { scale: 0, transformOrigin: '0 0', duration: 0.8 })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [CHOICES]: function () {
      const tl = gsap.timeline()
        .to('#keishaBg', { duration: 0.5, transformOrigin: '50% 57%', scale: 1.43, ease: 'sine.inOut' })
        .fromTo('.article', { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: '0 0', duration: 0.8 })
        .fromTo('.answer-button', { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: '0 0', duration: 1, stagger: 0.3 })
        .add(gsap.fromTo('#keisha-gear2', { top: '25%', left: '23%' }, { duration: 1, rotate: '+=360', opacity: 1, top: '5%', left: '28%', ease: 'sine.inOut' }), 0)
        .add(gsap.fromTo('#keisha-gear-small', { scale: 0.1, opacity: 0, top: '26%', left: '25%' }, { duration: 1, scale: 1, rotate: '+=360', opacity: 1, top: '6%', left: '20%', ease: 'sine.inOut' }), 0.5)
        .add(gsap.fromTo('.answers', { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: '0 0', duration: 0.8 }), 0.5)
        .call(()=> tl.kill());
      tl.play(0);
    },

    [`${CHOICES}To${FEEDBACK}`]: () => {
      const tl = gsap.timeline()
        .to('.article, .answers', { opacity: 0, scale: 0, transformOrigin: '0 0', duration: 0.5 })
        .fromTo('#keishaBg', { scale: 1.43 }, { duration: 0.5, transformOrigin: '50% 57%', scale: 1, ease: 'sine.inOut' })
        .call(()=> {
          setAnimationFinished(true);
          tl.kill();
        });
      tl.play(0);
    },

    [FEEDBACK]: function () {
      const tl = gsap.timeline()
        .from('.feedback', { scale: 0, transformOrigin: '0 0', delay: 4, duration: 0.5 })
        .from('.feedback-next-container', { opacity: 0, delay: 2, duration: 0.5 })
        .call(()=> tl.kill());
      tl.play(0);
    }
  };

  const clearAnimationFinished = () => {
    setAnimationFinished(false);
  };

  return (
    <MultipleChoice
      activityId={activityId}
      animationFinished={animationFinished}
      clearAnimationFinished={clearAnimationFinished}
      activityClassName={activityClassName}
      onFinished={onFinished}
      FeedbackScreen={feedbackScreen}
      ArticleScreen={articleScreen}
      ChoicesScreen={choicesScreen}
      QuestionScreen={questionScreen}
      animate={animations}
      onComplete={onComplete}
      removeCurrentActivity={removeCurrentActivity}
    />
  );
};
