import React, { useEffect, useRef, useState } from 'react';

import { func } from 'prop-types';
import 'app/components/mastered-words-modal/mastered-words-modal.scss';
import { useDispatch, useSelector } from 'react-redux';

import { LeftArrow } from 'app/components/mastered-words-modal/arrows/left-arrow.component';
import { RightArrow } from 'app/components/mastered-words-modal/arrows/right-arrow.component';
import { MasteredWordsRibbon } from 'app/components/mastered-words-modal/mastered-words-ribbon/mastered-words-ribbon';
import useOnClickOutside from 'app/hooks/use-on-click-outside.hook';
import { profileDataActionCreators } from 'app/store/actions/profile-data.actions';
import { isLoading } from 'app/store/reducers/api-status.reducer';

export const MasteredWordsModal = (props) => {
  const { setIsModalVisible } = props;
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { masteredWords } = useSelector(state => state.profileData);
  const { getMasteredWords } = useSelector(state => state.apiReducer);

  const hideModal = () => {
    setIsModalVisible(false);
  };

  useOnClickOutside(modalRef, hideModal);

  useEffect(() => {
    setPage(1);
    dispatch(profileDataActionCreators.getMasteredWords.request({ page: 1, size: 10 }));
  }, []);

  useEffect(() => {
    dispatch(profileDataActionCreators.getMasteredWords.request({ page, size: 10 }));
  }, [page]);

  return (
    <div className="mastered-words-modal-content">
      {page !== 1 && <LeftArrow classNames="mastered-words-arrow mastered-words-arrow-left" onClick={() => setPage(page - 1)} />}
      <div className="mastered-words-modal">
        <div className="mastered-words-modal-column">
          {isLoading(getMasteredWords) ?
            'Loading...'
            : masteredWords.words?.slice(0, 5).map(word =>
              <div className="mastered-word" key={word.id}>{word.name}</div>
            )}
        </div>
        <MasteredWordsRibbon />
        <div className="mastered-words-modal-divider" />
        <div className="mastered-words-modal-column">
          {isLoading(getMasteredWords) ?
            'Loading...'
            : masteredWords.words?.slice(5, 10).map(word =>
              <div className="mastered-word" key={word.id}>{word.name}</div>
            )}
        </div>
      </div>
      <button className="close-button" onClick={() => setIsModalVisible(false)}>X</button>
      {!(page >= masteredWords.pageMax)
        && <RightArrow classNames="mastered-words-arrow mastered-words-arrow-right" onClick={() => setPage(page + 1)} />}
    </div>
  );
};

MasteredWordsModal.propTypes = {
  setIsModalVisible: func,
};
