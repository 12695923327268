import React, { useEffect, useRef } from 'react';

import * as d3 from 'd3';

function wrapText(text) {
  text.each(function() {
    const text = d3.select(this);
    const words = text.text().split(' ');
    // save coordinates if current text field
    const y = text.attr('y');
    const dy = parseFloat(text.attr('dy') || 0);
    text.text(null);  // delete data from text field

    // needed for offset calculation
    let lineNumber = 0;
    const lineHeight = 1.2; // ems

    // iterate through all words and create tspan for each
    words.map(word => {
      return text.append('tspan').attr('y', y).attr('x', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
    });
  });
}

export const XAxis = ({ bottom, left, height, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    const axisGenerator = d3.axisBottom(scale);
    d3.select(axis.current).call(axisGenerator)
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').remove())
      .call(g => g.selectAll('text').call(wrapText, 70));
  });

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};
