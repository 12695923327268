import React from 'react';

import clsx from 'clsx';
import { string } from 'prop-types';

export const Chest = (props) => {
  const { classNames } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="171" height="108" viewBox="0 0 171 108" className={clsx(classNames)} id="chest">
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <path stroke="#14405C" strokeWidth="7" fill="#BFDEF2" d="M3.5 3.5h163v101H3.5z"/>
        <path fill="#ECF8FF" d="M26.5 27.5h117v52h-117z"/>
        <path d="M160.5 8.5H9.5v90h151z"/>
        <circle stroke="#31729B" strokeWidth="5" fill="#FFF38A" cx="85" cy="54" r="18.5"/>
        <circle fill="#14405C" cx="85.5" cy="50.5" r="6.5"/>
        <path fill="#14405C" d="M83 51h5v13h-5z"/>
      </g>
    </svg>
  );
};

Chest.propTypes = {
  classNames: string
};
